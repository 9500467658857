import { MEDIA_TYPE } from 'constant';
import i18next from 'i18next';
import kebabCase from 'lodash/kebabCase';
import merge from 'lodash/merge';
import { buildExtensionAndFullNameFromUser } from 'models/User';
import { buildDeviceEndpointString } from '../../../utility';
import defaultCsvBlobValues from './default';

/**
 * @name getDownloadValues
 * @description  Generates the download values, including the name and a downloadable CSV URL.
 *
 * @param [object]
 * @property data - The phone number data
 * @property phoneNumber - The phone number
 * @property isNumberAssignedToAccount - Whether the number is assigned to the account
 * @property assignedUsers - The list of assigned users
 *
 * @returns Object containing the name and URL for download.
 */
export const getDownloadValues = ({
  data,
  phoneNumber,
  isNumberAssignedToAccount,
  assignedDevices,
  assignedUsers,
}: {
  data: PhoneNumber;
  phoneNumber: string;
  isNumberAssignedToAccount: boolean;
  assignedDevices: Array<any>;
  assignedUsers: Array<any>;
}) => {
  if (!data || !phoneNumber) {
    return { name: '', url: '' };
  }

  return {
    name: i18next.t('numbers:containers.numbers_in_use.section.e911_endpoints.download.file.name', {
      phone_number: kebabCase(phoneNumber),
    }),
    url: URL.createObjectURL(
      new Blob(getCsvBlob({ data, isNumberAssignedToAccount, assignedDevices, assignedUsers }), {
        type: MEDIA_TYPE.TYPE.TEXT.CSV.WITH_CHARSET,
      }),
    ),
  };
};

/**
 * @name getCsvBlob
 * @description Build the CSV data string.
 *
 * @param [object]
 * @property data - The phone number data
 * @property isNumberAssignedToAccount - Whether the number is assigned to the account
 * @property assignedUsers - The list of assigned users
 *
 * @returns CSV data blob.
 */
export const getCsvBlob = ({
  data,
  isNumberAssignedToAccount,
  assignedDevices = [],
  assignedUsers = [],
}: {
  data: PhoneNumber;
  isNumberAssignedToAccount: boolean;
  assignedDevices?: Array<any>;
  assignedUsers?: Array<any>;
}): Array<BlobPart> => {
  const {
    id,
    e911: {
      street_address,
      extended_address,
      locality,
      region,
      postal_code,
      customer_name,
      notification_contact_emails,
    },
  } = merge({}, defaultCsvBlobValues, data);

  const accountLevelString = isNumberAssignedToAccount
    ? i18next.t(
        'numbers:containers.numbers_in_use.section.e911_endpoints.download.file.is_number_assigned_to_account.true',
      )
    : i18next.t(
        'numbers:containers.numbers_in_use.section.e911_endpoints.download.file.is_number_assigned_to_account.false',
      );

  const seatLevelString = assignedUsers.length
    ? assignedUsers.map((user) => buildExtensionAndFullNameFromUser(user)).join('; ')
    : '';

  const deviceLevelString = assignedDevices.length
    ? assignedDevices.map((device) => buildDeviceEndpointString(device)).join('; ')
    : '';

  const notificationEmailsString = notification_contact_emails.join('; ');

  const csvRow = [
    `"${id}"`,
    `${street_address || i18next.t('common:missing_address')}`,
    extended_address,
    locality,
    region,
    postal_code,
    customer_name,
    `"${notificationEmailsString}"`,
    accountLevelString,
    `"${seatLevelString}"`,
    `"${deviceLevelString}"`,
  ].join(',');

  return [
    i18next
      .t('numbers:containers.numbers_in_use.section.e911_endpoints.download.file.columns')
      .concat(`${csvRow}\n`),
  ];
};
