import MuiDialogContent from '@mui/material/DialogContent';
import { ForwardedRef, forwardRef } from 'react';
import defaultProps from './default';
import { DialogContentProps as Props } from './definition';

const DialogContent = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const { children, dialogType, isSelectable, ...rest } = {
    ...defaultProps,
    ...props,
  };

  const classes = [];
  if (dialogType !== 'default') {
    classes.push(`MuiDialogContent-${dialogType}`);
  }
  if (!isSelectable) {
    classes.push('MuiDialogContent-no-select');
  }

  return (
    <MuiDialogContent ref={ref} classes={{ root: classes.join(' ') }} {...rest}>
      {children}
    </MuiDialogContent>
  );
});

export default DialogContent;
