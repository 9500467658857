import { STAR_KEY } from 'constant';
import i18next from 'i18next';
import { DEFAULT_KEY } from '../../constants';

const translations = () => ({
  key: {
    label: i18next.t('phone_system:containers.callflows.select_key_dialog.menu.key_select.label'),
  },
  keys: [
    {
      value: DEFAULT_KEY,
      label: i18next.t('common:default'),
    },
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: 'timeout', label: i18next.t('common:timeout') },
    { value: '*', label: STAR_KEY },
  ],
  title: i18next.t('phone_system:containers.callflows.select_key_dialog.menu.title'),
});

export default translations;
