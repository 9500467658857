import i18next from 'i18next';
import { CALLFLOW_ACTION } from '../constants';

/**
 * Format based on Monster UI / Kazoo UI
 */
export const buildCallflowName = (callflow: Callflow, users: UserResponse = []): string => {
  if (!callflow?.id) {
    return i18next.t('phone_system:containers.callflows.general.not_found');
  }

  if (callflow.name) {
    return callflow.name;
  }
  if (callflow.numbers.length) {
    const user = users.find((user) => user.id === callflow.user_id);

    if (user) {
      return `${callflow.numbers[0]}, ${user.first_name} ${user.last_name}`;
    }

    return callflow.numbers[0];
  }

  return i18next.t('phone_system:containers.callflows.general.unname');
};

export const callflowFilter = (callflow: Callflow, isHideBridgeCallflow?: boolean) => {
  const filters =
    !callflow.featurecode && !callflow.numbers.includes(CALLFLOW_ACTION.NO_MATCH.NUMBER);

  if (!isHideBridgeCallflow) {
    return filters;
  }

  return filters && !callflow.modules?.includes('ooma_cellular');
};
