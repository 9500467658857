import get from 'lodash/get';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { DEFAULT_ICON } from '../../../Icon/index';
import { StyledIconButtonProps as Props } from './definition';

const styles = css`
  opacity: 0.14;
`;

const StyledIconButton = styled.div<Props>`
  ${({ isdropdownopen, backgroundcolor, theme }) => css`
    cursor: pointer;

    > div {
      ${mixin.transition([
        { property: 'background-color', duration: 250 },
        { property: 'opacity', duration: 250 },
      ])};
      position: absolute;
      width: ${DEFAULT_ICON.SUBSET}px;
      height: ${DEFAULT_ICON.SUBSET}px;
      background-color: ${get(theme, backgroundcolor)};
      border-radius: 50%;
      opacity: 0;
      z-index: 0;

      ${(isdropdownopen as string) === 'true' &&
      css`
        ${styles};
      `}
    }

    &:hover > div {
      ${styles};
    }
  `}
`;

export default StyledIconButton;
