import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import { UsernameInputProps as Props } from './definition';
import { getUsernameInputType } from './utility';

const UsernameInput = ({ isAdmin, name }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const watchFields = {
    seatId: watch('seat.id'),
    seatType: watch('seat.seat_type'),
  };
  const isDisabled = !!watchFields.seatId;

  return (
    <HookFormInputWrapper name={name}>
      {({ ref, isDirty, feedback, onChange, ...formProps }) => (
        <LabeledInput
          id="input-basics-username"
          label={
            isAdmin
              ? t(`phone_system:containers.seats.section.basics.username.label`)
              : t(`phone_system:containers.seats.section.basics.extension.label`)
          }
          inputProps={{
            /** Username field is disabled if the seat has already been created */
            ...formProps,
            disabled: isDisabled,
            placeholder: isAdmin
              ? t(`phone_system:containers.seats.section.basics.username.placeholder`)
              : t(`phone_system:containers.seats.section.basics.extension.placeholder`),
            type: getUsernameInputType(isAdmin, watchFields.seatType, isDisabled),
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              onChange(e);
              setValue('seat.presence_id', e.target.value, { shouldDirty: true });
            },
          }}
          feedback={feedback}
          isDirty={isDirty}
        />
      )}
    </HookFormInputWrapper>
  );
};

export default UsernameInput;
