import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Loading from 'shared/components/Loading';
import { EnhancedForm } from '../../../../shared/components/EnhancedForm';
import { HorizontalTabs } from './components/HorizontalTabs';
import { DynamicFormProps as Props, FormData } from './definition';
import { dynamicFormUtility } from './utility';

/** This component is temporary designed for usage in Advanced Provisioner app only. Target to refactor as a global component. */
export const DynamicForm = ({
  isLoading,
  actionRowProps,
  breadcrumbData,
  data,
  deleteButton,
  macAddress,
  schema,
  template,
  onCancel,
  onFormSubmit,
  onSaveOverride,
}: Props) => {
  const { t } = useTranslation();

  const defaultValues = useMemo(
    () => dynamicFormUtility.generateDefaultValuesFromTemplate(template),
    [template],
  );

  const onFormSubmitTransform = async (formData: FormData) => {
    const transformedFormData = dynamicFormUtility.transformFormDataToRequestPayload(formData);
    await onFormSubmit?.(transformedFormData);
  };

  const navBlockPathWhitelist = useMemo(
    () =>
      template
        ? Object.entries(template)
            .filter(([, { iterate }]) => iterate !== 0)
            .map(([key]) => key)
        : [],
    [template],
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!template) {
    return <Box>{t('advanced_provisioner:dynamic_form.undefined_template_message')}</Box>;
  }

  return (
    <EnhancedForm<FormData>
      actionRowProps={actionRowProps}
      breadcrumbData={breadcrumbData}
      data={data}
      defaultValues={defaultValues}
      deleteButton={deleteButton}
      schema={schema}
      navBlockPathWhitelist={navBlockPathWhitelist}
      onCancel={onCancel}
      onFormSubmit={onFormSubmitTransform}
      onSaveOverride={onSaveOverride}
    >
      {(formProps) => (
        <HorizontalTabs formProps={formProps} template={template} macAddress={macAddress} />
      )}
    </EnhancedForm>
  );
};
