import { MarginType } from 'shared/utility/definition';

export const CONSTANTS = {
  MARGIN: {
    DEFAULT: {
      [MarginType.none]: 0,
      [MarginType.xsmall]: 1,
      [MarginType.small]: 3,
      [MarginType.medium]: 6,
      [MarginType.xmedium]: 9,
      [MarginType.large]: 12,
    },
    TOP: {
      [MarginType.none]: 0,
      [MarginType.xsmall]: 0.66,
      [MarginType.small]: 1.33,
      [MarginType.medium]: 2,
      [MarginType.xmedium]: 2.66,
      [MarginType.large]: 6,
    },
  },
};
