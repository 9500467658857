import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { ADD_KEY } from 'constant';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import { FormInput } from '../../definition';
import ConferenceEditView from './EditView';
import ConferenceListView from './ListView';

type Props = TabPanelProps<FormInput>;

export default function TabConference({ id = ADD_KEY }: Props) {
  const routes = useRoutes([
    { path: '/', element: <ConferenceListView id={id} /> },
    { path: '/:id/', element: <ConferenceEditView /> },
  ]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
}
