import { FunctionComponent } from 'react';
import Textarea from 'shared/components/Textarea';
import * as utility from 'shared/utility';
import Labeled from '../..';
import defaultProps from './default';
import { LabeledTextareaProps as Props } from './definition';
import StyledLabeledTextarea from './style';

const LabeledTextarea: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    id,
    hasDirtyIcon,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    inputProps,
    inputWidth,
    feedback,
    tooltip,
  }: Props = {
    ...defaultProps,
    ...props,
  };
  const forId = id || utility.generateId('textarea');

  return (
    <Labeled
      id={forId}
      type="input"
      hasDirtyIcon={hasDirtyIcon}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={labelProps}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      feedback={feedback}
      tooltip={tooltip}
      styledComponent={StyledLabeledTextarea}
    >
      <Textarea id={forId} {...inputProps} width={inputWidth} />
    </Labeled>
  );
};

export default LabeledTextarea;
