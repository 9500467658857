import { themeColor } from 'theme/local';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      color: themeColor.core.color.blue.draggable,
      borderBottom: `1px solid ${themeColor.brio.info.highlight}`,
      fontWeight: 600,
      lineHeight: '20px',
      textDecoration: 'none',
      transition: theme.transitions.create(['border-color'], {
        duration: theme.transitions.duration.standard,
      }),
      '&:hover': {
        borderBottomColor: themeColor.core.color.blue.draggable,
      },
    },
  },
};
