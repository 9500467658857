import { FunctionComponent } from 'react';
import Header from '../Header';
import defaultProps from './default';
import { PageProps as Props } from './definition';
import StyledPage from './style';

const Page: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { children, label } = { ...defaultProps, ...props };
  return (
    <StyledPage>
      <Header label={label} />
      {children}
    </StyledPage>
  );
};

export default Page;
