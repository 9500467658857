import Fade from '@mui/material/Fade';
import { SnackbarProvider as Provider, SnackbarProviderProps as Props } from 'notistack';
import Alert from 'shared/components/Alert';

const SnackbarProvider = ({ children }: Props) => (
  <Provider
    Components={{
      error: Alert,
      info: Alert,
      success: Alert,
      warning: Alert,
    }}
    TransitionComponent={Fade}
    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    autoHideDuration={5000}
    maxSnack={6}
    transitionDuration={{ enter: 300, exit: 0 }}
  >
    {children}
  </Provider>
);

export default SnackbarProvider;
