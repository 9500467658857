import { ADD_KEY } from 'constant';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import DevicesEditView from './EditView';
import DevicesListView from './ListView';

type Props = {
  id: string;
};

export default function TabDevices({ id = ADD_KEY }: Props) {
  const routes = useRoutes([
    { path: '/', element: <DevicesListView id={id} /> },
    { path: '/:id/', element: <DevicesEditView /> },
  ]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
}
