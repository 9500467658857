import i18next from 'i18next';
import { Column } from 'react-table';
import { Justify } from 'shared/components/Table';

export const columns = (): Array<Column> => [
  {
    Header: i18next.t('common:number') as string,
    accessor: 'id',
    id: `confirm_selection_dialog.number.${Justify.Left}`,
  },
];

export const getButtonProps = (isDelete: boolean): Record<string, string> =>
  isDelete
    ? {
        color: 'error',
        label: i18next.t('common:delete'),
        variant: 'contained',
      }
    : {
        color: 'primary',
        label: i18next.t('common:confirm'),
        variant: 'contained',
      };
