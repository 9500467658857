import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const AppsStore = {
  // GET
  Fetch: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${accountId}/apps_store`,
  FetchById: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER, id: string) =>
    `accounts/${accountId}/apps_store/${id}`,
  FetchBlocklist: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `accounts/${accountId}/apps_store/blacklist`,

  // POST
  UpdateBlocklist: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `accounts/${accountId}/apps_store/blacklist`,
};
