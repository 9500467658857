import { useMemo } from 'react';
import Tooltip from 'shared/components/Tooltip';
import { InteractionBarType } from '../..';
import Icon from './components/Icon';
import Label from './components/Label';
import { ActionProps as Props } from './definition';
import StyledAction from './style';

export { CONSTANTS as ACTION_CONSTANTS } from './style';

const Action = ({ hasPadding, isDisabled, isWide, id, icon, label, type, onClick }: Props) => {
  const action = useMemo(() => {
    const iconComponent = icon ? <Icon name={icon.name} isFlipped={icon.isFlipped} /> : <></>;
    return (
      <StyledAction
        hasPadding={hasPadding}
        isDisabled={isDisabled}
        isWide={isWide}
        aria-disabled={isDisabled}
        data-test-id={`btn-${id}`}
        type={type}
        onClick={onClick}
      >
        {type === InteractionBarType.DEFAULT ? (
          <>
            {iconComponent}
            {label && <Label value={label} />}
          </>
        ) : (
          iconComponent
        )}
      </StyledAction>
    );
  }, [hasPadding, isDisabled, isWide, id, icon, label, type, onClick]);

  return type === InteractionBarType.DEFAULT ? action : <Tooltip title={label}>{action}</Tooltip>;
};

export default Action;
