import { FieldValueType } from 'apps/AdvancedProvisioner/shared/components/DynamicForm/definition';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOptions } from '../definition';

export const useGetSelectOptions = (options?: SelectOptions, inheritValue?: FieldValueType) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!options || options.length < 1) {
      return [];
    }

    // The inheritValue could sometimes be number, but the options values are string (vice versa). Therefore, we need to convert the values to string to compare them.
    const inheritLabel = options.find(({ value }) => `${value}` === `${inheritValue}`)?.text;

    return [
      {
        label: t(
          'advanced_provisioner:dynamic_form.edit_form.dynamic_input.select.options.inherit_label',
          {
            inheritLabel: inheritLabel ? ` (${inheritLabel})` : '',
          },
        ),
        value: '',
      },
      ...options.map(({ text: label, value }) => ({
        label,
        value: typeof value === 'number' ? value.toString() : value, // ADVPROV-TODO: Improvement in the ticket to remove conversion to string: https://oomacorp.atlassian.net/browse/NEMO-1659
      })),
    ];
  }, [inheritValue, options, t]);
};
