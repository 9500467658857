import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { EditFormProps } from 'apps/PhoneSystem/definition';
import { ADD_KEY } from 'constant';
import {
  schema,
  useDeleteConferenceMutation,
  useFetchConferenceByIdQuery,
  useUpdateConferenceMutation,
} from 'models/Conference';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import { defaultValues } from './default';
import { FormInput } from './definition';
import Form from './Form';
import { transformConferenceForForm, transformConferenceForSaving } from './utility';

export { transformConferenceForSaving };

const Edit = ({ id, handleDeleteSuccess, handleSaveSuccess }: EditFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();

  const { data, isLoading } = useFetchConferenceByIdQuery({ id });
  const [updateConference] = useUpdateConferenceMutation();
  const [deleteConference] = useDeleteConferenceMutation();

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const {
    formState: { dirtyFields },
    handleSubmit,
    reset,
    setError,
  } = formMethods;

  const isPageDirty = checkFormEntities(dirtyFields);

  const onDelete = async () => {
    try {
      await deleteConference({ id }).unwrap();
      handleDeleteSuccess?.();
    } catch (exception) {
      showToast.delete.error();
    }
  };

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    try {
      await updateConference({ id, body: transformConferenceForSaving(data) });
      handleSaveSuccess?.();
    } catch (exception) {
      showErrorMessage({ isFromException: true, errors: exception, setError });
    }
  };

  useEffect(() => {
    if (data) {
      reset(transformConferenceForForm(data)); // Populates form components once data is loaded.
    }
  }, [data, reset]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditForm
      isPageDirty={isPageDirty}
      entityLabel={t('phone_system:containers.conferences.label')}
      entityName={data?.name}
      formMethods={formMethods}
      onSave={handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }))}
      {...(id !== ADD_KEY ? { onDelete } : {})}
    >
      <Form />
    </EditForm>
  );
};

export default Edit;
