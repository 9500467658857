const defaultProps = {
  hasAdd: false,
  hasBottomBorder: true,
  hasBottomMargin: false,
  hasDelete: false,
  hasFilter: false,
  hasHelp: false,
  hasSave: false,
  hasSearch: false,
  hasPadding: {
    bottom: true,
    side: true,
    top: true,
  },
  isDirty: false,
  isDeleteDisabled: false,
  breadcrumbData: [],
  tableType: 0, // TODO: Review circular dependencies (reimplement `TableType.Default`)
};

export default defaultProps;
