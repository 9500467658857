import { REGEX } from 'constant';
import { useTranslation } from 'react-i18next';
import { LabeledEditableList } from 'shared/components/Labeled';

interface Props {
  data: {
    [key: string]: {};
  };
  onChange: any;
  isDirty?: boolean;
}

export const convertToBlocklistNumbers = (numbers: Array<string> = []) =>
  numbers.reduce((acc, curr) => {
    const newItem = {} as any;
    newItem[curr] = {};
    return { ...acc, ...newItem };
  }, {});

const LabeledNumbersList = ({ data, isDirty, onChange }: Props): JSX.Element => {
  const { t } = useTranslation();

  const listData = {
    columns: [
      {
        header: {
          text: t('phone_system:containers.call_blocking.field.number_list.numbers.title'),
        },
        input: {
          validationMask: (value: string) => REGEX.PHONE_NUMBER.test(value),
        },
      },
    ],
    rows: (Object.keys(data) || []).map((item: string) => [item]),
  };

  return (
    <LabeledEditableList
      isDirty={isDirty}
      editableListProps={{
        data: listData,
        onUpdate: (data: any) => onChange(convertToBlocklistNumbers(data)),
      }}
      labelWidth="none"
    />
  );
};

export default LabeledNumbersList;
