import { joiResolver } from '@hookform/resolvers/joi';
import merge from 'lodash/merge';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import Icon from 'shared/components/Icon';
import { DirtyIcon, InfoIcon } from 'shared/components/Labeled';
import { v4 as uuidv4 } from 'uuid';
import defaultProps from './default';
import { DialogState, EditableTableProps as Props, ItemID } from './definition';
import StyledEditableTable from './style';

export { default as Row } from './components/Row';
export type { RowProps } from './components/Row';

enum DialogTypes {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

const EditableTable = <T extends ItemID>(props: Props<T>): JSX.Element => {
  const {
    isDirty,
    data,
    info,
    text,
    width,
    hasRowDelete,
    components: {
      dialog,
      dialog: { component: Dialog },
      dialog: { delete: { component: DeleteDialog } = { component: () => null } },
      row,
      row: { component: Row, title },
    },
  } = { ...defaultProps, ...props };

  const INIT_DIALOG_STATE = {
    create: false,
    delete: false,
    update: false,
  };
  const [isDialogOpen, setIsDialogOpen] = useState<DialogState>(INIT_DIALOG_STATE);
  const [list, setList] = useState<T[]>([]);

  const { t } = useTranslation();

  /**
   * Isolated form context for dialog component
   * */
  const formMethods = useForm<any>({
    defaultValues: dialog.defaultValues,
    resolver: joiResolver(dialog.schema()),
  });

  const { getValues, reset, trigger } = formMethods;

  useEffect(() => {
    setList(data);
  }, [data]);

  const handleDialogAction = async (
    closeResponse: { reason: DialogActionsCloseReasons },
    type?: DialogTypes,
  ) => {
    const { onUpdate } = row;
    const { onCancel, onSave } = dialog;

    if (closeResponse.reason === 'cancelClicked') {
      onCancel();
      setIsDialogOpen(INIT_DIALOG_STATE);
    }

    if (closeResponse.reason === 'deleteClicked') {
      setIsDialogOpen((prevState: DialogState) => ({ ...prevState, update: false, delete: true }));
    }

    if (closeResponse.reason === 'saveClicked') {
      const isValid = await trigger();

      if (onUpdate && type === DialogTypes.UPDATE) {
        if (isValid) {
          onUpdate(getValues());
        }
        setIsDialogOpen((prevState: DialogState) => ({
          ...prevState,
          update: !isValid,
        }));
      }

      if (type === DialogTypes.CREATE) {
        const newId = uuidv4();
        if (isValid) {
          onSave({ ...getValues(), newId });
        }
        setIsDialogOpen((prevState: DialogState) => ({
          ...prevState,
          create: !isValid,
        }));
      }
    }
  };

  const handleDialogState = (action: DialogTypes, openDialog?: boolean, id?: string): void => {
    const { setId } = row;
    const { onCancel } = dialog;
    if (action === DialogTypes.UPDATE && openDialog) {
      reset(
        merge(
          {},
          dialog.defaultValues,
          list.find((item) => item.id === id || item.newId === id),
        ),
      );
    }

    if (action === DialogTypes.CREATE && openDialog) {
      reset(dialog.defaultValues);
    }
    setIsDialogOpen((prevState: DialogState) => ({ ...prevState, [action]: !!openDialog }));

    if (setId) {
      setId(id);
    }
    if (!openDialog) {
      onCancel();
    }
  };

  return (
    <StyledEditableTable hasHeading={!!text.heading}>
      {list && list.length > 0 ? (
        <div>
          {text.heading && <header>{text.heading}</header>}
          {text.columns && <Row key="row-subheader" data={text.columns} width={width} />}
          <main>
            {list.map((item: any) => (
              <Row
                key={`row-${item.id || item.newId}`}
                data={title ? [item[title]] : Object.values(item)}
                width={width}
                onDelete={
                  hasRowDelete &&
                  (() => handleDialogState(DialogTypes.DELETE, true, item.id || item.newId))
                }
                onUpdate={() => handleDialogState(DialogTypes.UPDATE, true, item.id || item.newId)}
              />
            ))}
          </main>
        </div>
      ) : null}
      <footer>
        <Button
          disabled={false}
          color="secondary"
          startIcon={<Icon name="plus-circle-outlined" size={18} />}
          onClick={() => {
            handleDialogState(DialogTypes.CREATE, true);
          }}
        >
          {text.button}
        </Button>
        {info && <InfoIcon isInline tooltip={info} />}
        {isDirty && <DirtyIcon isInline isDirty={isDirty} />}
      </footer>
      {Dialog && (
        <FormProvider {...formMethods}>
          <Dialog
            open={isDialogOpen.create}
            title={dialog.title?.create}
            list={list}
            renderActions={
              <DialogActions
                saveButtonProps={dialog.button.save.props}
                onAction={(closeResponse) => handleDialogAction(closeResponse, DialogTypes.CREATE)}
              />
            }
            onClose={() => handleDialogState(DialogTypes.CREATE, false)}
          />
          <Dialog
            open={isDialogOpen.update}
            title={dialog.title?.update}
            data={getValues()}
            list={list}
            renderActions={
              <DialogActions
                onAction={(closeResponse) => handleDialogAction(closeResponse, DialogTypes.UPDATE)}
                saveButtonProps={dialog.button.save.props}
                leftButtons={
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => handleDialogAction({ reason: 'deleteClicked' })}
                    data-test-id="btn-dialog-action-delete"
                    {...dialog.button?.delete?.props}
                  >
                    {t('common:delete')}
                  </Button>
                }
              />
            }
            onClose={() => {
              handleDialogState(DialogTypes.UPDATE, false);
            }}
          />
          <DeleteDialog
            showDialog={isDialogOpen.delete}
            onCancel={() =>
              setIsDialogOpen((prevState: DialogState) => ({
                ...prevState,
                update: true,
                delete: false,
              }))
            }
            onConfirm={() => {
              dialog.onDelete(getValues('id') || getValues('newId'));
              setIsDialogOpen(INIT_DIALOG_STATE);
            }}
          />
        </FormProvider>
      )}
    </StyledEditableTable>
  );
};

export default EditableTable;
