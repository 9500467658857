import { LOCAL_STORAGE } from 'constant';
import { useFetchAccountByIdQuery } from 'models/Account';

const useAccount = () => {
  const { data } = useFetchAccountByIdQuery({
    id: localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID) as string,
  });

  return data
    ? {
        isReseller: data.is_reseller,
        isSuperDuper: data.superduper_admin,
        accountId: data.id,
        accountName: data.name,
      }
    : {};
};

export default useAccount;
