import { SeatFormProps as Props } from 'apps/PhoneSystem/definition';
import { FormContext } from 'apps/shared/components/FormContext';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import useTabs from 'apps/shared/hooks/useTabs';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { areTabFieldsDirty } from '../utility'; // TODO: Review implementation
import { fields, Section } from './components';

const Form = ({ hasFormActionRow }: Props) => {
  const { t } = useTranslation();
  const contextName = useContext(FormContext);
  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const items = [
    {
      hash: 'basics',
      label: t('phone_system:containers.voicemails.section.basics.label'),
      component: <Section.Basics />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.basics, contextName),
      isError: areTabFieldsDirty(errors, fields.basics, contextName),
    },
    {
      hash: 'extension_dialling',
      label: t('phone_system:containers.voicemails.section.extension_dialling.label'),
      component: <Section.Extension />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.extension, contextName),
      isError: areTabFieldsDirty(errors, fields.extension, contextName),
    },
    {
      hash: 'advanced_options',
      label: t('phone_system:containers.voicemails.section.advanced_options.label'),
      component: <Section.Advanced />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.advanced, contextName),
      isError: areTabFieldsDirty(errors, fields.advanced, contextName),
    },
    {
      hash: 'recipients',
      label: t('phone_system:containers.voicemails.section.recipients.label'),
      component: <Section.Recipients />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.recipients, contextName),
      isError: areTabFieldsDirty(errors, fields.recipients, contextName),
    },
    {
      hash: 'greeting_media',
      label: t('phone_system:containers.voicemails.section.greeting_media.label'),
      component: <Section.Greeting />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.greeting, contextName),
      isError: areTabFieldsDirty(errors, fields.greeting, contextName),
    },
  ];

  const { Tabs, TabPanels } = useTabs({
    items,
    options: { hasFormActionRow },
  });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Form;
