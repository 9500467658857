import { FunctionComponent } from 'react';
import Header from '../Header';
import defaultProps from './default';
import { DrawerLowerProps as Props } from './definition';
import StyledDrawerLower from './style';

const DrawerLower: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isOpen, height, headerProps }: Props = {
    ...defaultProps,
    ...props,
  };

  return (
    <StyledDrawerLower isOpen={isOpen} height={height}>
      <div>
        <Header {...headerProps} isVertical width={height} />
      </div>
    </StyledDrawerLower>
  );
};

export default DrawerLower;
