import { CHARACTER } from 'constant';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { CardHeaderTitleProps as Props } from './definition';

const CardHeaderTitle: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    data: { agentName, agentExtension, label },
  } = props;
  const { t } = useTranslation();

  return (
    <Box
      className="MuiCardHeaderTitle-root"
      data-test-id="call-center-component-agent-card-component-card-header-title"
    >
      <Box>
        <Box component="span">{label ?? CHARACTER.EMDASH}</Box>
        <Box component="span">{agentName}</Box>
      </Box>
      <Box>
        <Box component="span">
          {agentExtension
            ? t(
                'call_center:containers.dashboard.component.agent_card.component.card_header_title.ext',
                { extension: agentExtension },
              )
            : CHARACTER.EMDASH}
        </Box>
      </Box>
    </Box>
  );
};

export default CardHeaderTitle;
