import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import defaults from './defaults';
import { EmptySMSViewProps as Props } from './definition';
import { StyledEmptySMSView, StyledTitle } from './style';

const EmptySMSView: FunctionComponent<Props> = (props: Props) => {
  const { handleCreateDialogOpen, isCreateDisabled } = {
    ...defaults,
    ...props,
  };
  const { t } = useTranslation();

  return (
    <StyledEmptySMSView>
      <Icon set="custom" name="sms" />
      <StyledTitle>{t('phone_system:containers.seats.sms.empty_sms.message')}</StyledTitle>
      <Tooltip
        title={
          isCreateDisabled
            ? t('phone_system:containers.seats.sms.empty_sms.create_sms_box.empty_phone_number')
            : ''
        }
      >
        <div>
          <Button
            disabled={isCreateDisabled}
            variant="contained"
            size="medium"
            onClick={handleCreateDialogOpen}
            style={isCreateDisabled ? { pointerEvents: 'none' } : {}} // For tooltip to work on disabled button: https://mui.com/material-ui/react-tooltip/#disabled-elements
          >
            {t('phone_system:containers.seats.sms.empty_sms.create_sms_box.action.label')}
          </Button>
        </div>
      </Tooltip>
    </StyledEmptySMSView>
  );
};

export default EmptySMSView;
