import { BaseSyntheticEvent, FunctionComponent, useMemo } from 'react';
import Box from 'shared/components/Box';
import Card from 'shared/components/Card';
import DropdownButton from 'shared/components/DropdownButton';
import QueueCardItem from './components/QueueCardItem';
import defaultProps from './default';
import { QueueCardDataType, QueueCardProps as Props } from './definition';
import { getConstants } from './utility';

export type { QueueCardData } from './definition';

const QueueCard: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { hasMenu, isSelected, id, data, cardProps, headerProps, onClick, ...rest } = {
    ...defaultProps,
    ...props,
  };
  const { items, menu } = getConstants(onClick);

  const content = useMemo(
    () => (
      <>
        {Object.entries(items).map(([key, { icon, label }]: Array<any>) => {
          let value = data[key as QueueCardDataType];

          if (key === QueueCardDataType.ACTIVE_AGENTS_CURRENT) {
            const current = data[QueueCardDataType.ACTIVE_AGENTS_CURRENT];
            const total = data[QueueCardDataType.ACTIVE_AGENTS_TOTAL];
            value = `${current}/${total}`;
          }

          return <QueueCardItem key={key} data={{ icon, label, value }} />;
        })}
      </>
    ),
    [data, items],
  );

  return (
    <Box onClick={() => onClick.default?.(id as string)}>
      <Card
        {...rest}
        content={content}
        cardProps={{
          className: `MuiCard-queue${isSelected ? ' MuiCard-queue-selected' : ''}`,
          variant: 'outlined',
          ...cardProps,
          'data-test-id': 'call-center-component-queue-card',
        }}
        contentProps={{
          className: 'MuiCardContent-queue',
        }}
        headerProps={{
          className: 'MuiCardHeader-queue',
          action: hasMenu ? (
            <DropdownButton
              hasIconButton
              buttonProps={{ color: 'neutral' }}
              items={menu}
              onClick={(e: BaseSyntheticEvent) => onClick.edit?.button(e)}
            />
          ) : null,
          ...headerProps,
        }}
      />
    </Box>
  );
};

export default QueueCard;
