import * as mixin from 'shared/utility/mixin';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { StyledAccountListProps as Props } from './definition';

const margin = (props: Props): FlattenSimpleInterpolation => {
  let margin = 0;

  if (!props.isRoot) {
    if (!!props.listDepth && props.currentLevel === props.listDepth) {
      margin = props.listDepth;
    } else if (!!props.currentLevel) {
      margin = props.currentLevel;
    }
  }

  return css`
    margin-left: ${margin * 12}px;
  `;
};

const StyledAccountList = styled.div<Props>`
  ${(props) => css`
    > ul {
      max-height: 340px;
      background-color: ${props.theme.core.background.grey.light.list};
      border-top: 1px solid ${props.theme.core.background.grey.light.border};
      list-style: none;
      overflow: auto;

      li {
        ${mixin.flex({ justifyContent: 'space-between' })};
        height: 40px;

        &:not(:first-of-type) {
          border-top: 1px solid ${props.theme.core.background.grey.light.border};
        }

        &:first-of-type:not(:last-of-type) {
          ${mixin.borderRadius({ radius: 'half half 0 0' })};
        }

        &:last-of-type:not(:first-of-type) {
          ${mixin.borderRadius({ radius: '0 0 half half' })};
        }

        &:first-of-type:last-of-type {
          ${mixin.borderRadius({ radius: 'half' })};
        }

        &:hover {
          background-color: ${props.theme.core.background.grey.medium};
        }

        a {
          ${margin(props)};
        }
      }
    }
  `}
`;

export default StyledAccountList;
