import kebabCase from 'lodash/kebabCase';
import { FunctionComponent, memo } from 'react';
import Icon from 'shared/components/Icon';
import { HeadingProps as Props } from './definition';
import StyledHeading from './style';

const Heading: FunctionComponent<Props> = memo(
  (props: Props): JSX.Element => {
    const { isOpen, name, onClick } = props;
    return (
      <StyledHeading
        isOpen={isOpen}
        onClick={() => onClick()}
        data-test-id={`heading-${kebabCase(name)}`}
      >
        <Icon name="chevron-up-round" />
        <span>{name}</span>
      </StyledHeading>
    );
  },
);

export default Heading;
