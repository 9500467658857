import * as mixin from 'shared/utility/mixin';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { FLOW_ROOT_CONSTANTS } from '../../../FlowRoot';
import { StyledFlowChildBodyProps, StyledFlowChildProps as Props } from './definition';

const CONSTANTS = {
  WIDTH: '160px',
};

const hover = (mode: string, props: any): FlattenSimpleInterpolation => css`
  ${mode === 'child' &&
  css`
    ${mixin.boxShadow({ offsetY: 2, blurRadius: 5, alpha: 50 })};
    border-color: ${props.theme.core.color.blue.draggable};
    border-style: solid;

    > div > span {
      color: ${props.theme.core.color.blue.draggable};
    }

    > div svg,
    > div > div svg {
      fill: ${props.theme.core.color.blue.draggable};
    }
  `}

  ${mode === 'arrow' &&
  css`
    svg {
      fill: ${props.theme.core.color.blue.draggable};
    }
  `}
`;

export const StyledFlowChild = styled.div.attrs({ className: 'flow-child' })<Props>`
  ${(props) => css`
    ${mixin.flex()};
    width: 168px;
    height: ${props.hasHead ? '118px' : '98px'};
    margin: 8px auto 0;

    > div {
      ${props.isHoverNode &&
      css`
        &.flow-child {
          ${hover('child', props)};
        }
      `};

      ${props.isHoverAll &&
      css`
        &.flow-child,
        & + div .flow-child {
          ${hover('child', props)};
        }

        & + div .flow-arrow {
          ${hover('arrow', props)};
        }
      `};

      ${props.isNodeInvalid &&
      css`
        &.flow-child {
          ${mixin.boxShadow({ offsetY: 2, blurRadius: 5, alpha: 50 })};
          border-color: ${props.theme.core.color.red};
          border-style: solid;
        }
      `};
    }
  `};
`;

export const StyledFlowChildBody = styled.div.attrs({
  className: 'flowchild-body',
})<StyledFlowChildBodyProps>`
  ${(props) => css`
    ${mixin.borderRadius()};
    ${mixin.boxShadow({ offsetY: 1, blurRadius: 3 })};
    ${mixin.transition([
      { property: 'background-color', duration: 'medium' },
      { property: 'border-color', duration: 'medium' },
      { property: 'box-shadow', duration: 'medium' },
    ])};
    display: grid;
    grid-template: 12px 1fr 18px / 12px 1fr 12px;
    width: ${CONSTANTS.WIDTH};
    height: 90px;
    margin: 0 auto;
    background-color: ${props.theme.core.background.white.off};
    border: ${FLOW_ROOT_CONSTANTS.DASHED_BORDER.SIZE} solid transparent;
    user-select: none;

    ${props.isDragging &&
    props.isDroppable &&
    css`
      border-color: ${props.theme.core.color.blue.draggable};
      border-style: dashed;
    `};

    ${props.isDroppable &&
    css`
      &:hover {
        border-style: solid;
      }
    `}

    > div svg {
      fill: ${props.theme.whitelabel.secondary.color};
    }
  `}
`;

export default StyledFlowChild;
