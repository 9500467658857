import { HEADING_CONSTANTS } from 'apps/PhoneSystem/containers/Account/components/Heading';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { INTERACTION_BAR_CONSTANTS } from '../InteractionBar';
import { StyledActionContainerProps as Props } from './definition';

export const CONSTANTS = {
  BORDER: 1,
  HEIGHT: {
    HEADING: '35px',
    SEARCH: '40px',
  },
  WIDTH: '200px',
};

const StyledActionContainer = styled.aside.attrs({
  className: 'component-callflow-actions-container',
})<Props>`
  ${(props) => css`
    z-index: 10;

    > div {
      ${mixin.borderRadius({ radius: 8 })};
      ${mixin.boxShadow({ offsetY: 1, blurRadius: 3 })};
      width: ${CONSTANTS.WIDTH};
      max-height: ${`calc(100vh - (${HEADING_CONSTANTS.HEIGHT} + ${
        ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT
      } + ${INTERACTION_BAR_CONSTANTS.HEIGHT.DEFAULT}px + ${props.theme.spacing(9)}))`};
      padding: ${`calc(${props.theme.spacing(0.5)})`};
      background-color: ${props.theme.core.color.white};
      border: ${CONSTANTS.BORDER}px solid ${props.theme.core.border.color};
      user-select: none;

      h3 {
        ${mixin.font({ size: 16, weight: 700 })};
        width: 100%;
        height: ${CONSTANTS.HEIGHT.HEADING};
        line-height: ${CONSTANTS.HEIGHT.HEADING};
        margin: 0;
        text-align: center;

        // search box
        & + div {
          height: 40px;
          padding: 0 0 8px;
        }
      }
    }
  `};
`;

export default StyledActionContainer;
