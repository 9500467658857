import { ChangeEvent, FunctionComponent } from 'react';
import SearchInput from 'shared/components/SearchInput';
import { DrawerContentUpperProps as Props } from './definition';
import StyledDrawerContentUpper from './style';

const DrawerContentUpper: FunctionComponent<Props> = ({ setSearchFilter }: Props): JSX.Element => (
  <StyledDrawerContentUpper>
    <SearchInput
      iconSize="small"
      inputProps={{
        width: 'auto',
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSearchFilter(e.target.value);
        },
      }}
      onReset={() => setSearchFilter('')}
    />
  </StyledDrawerContentUpper>
);

export default DrawerContentUpper;
