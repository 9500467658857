import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const PhoneNumber = {
  // GET
  Fetch: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers?paginate=${isPaginated}`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers/${id}`,
  FetchAvailableByPrefix: (prefix: string, quantity = 15, offset = 0) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers?prefix=${prefix}&quantity=${quantity}&offset=${offset}`,
  FetchInServiceAndPortIn: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers?paginate=${isPaginated}&filter_state=["in_service","port_in"]`,
  FetchClassifiers: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers/classifiers`,

  // PUT
  Activate: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers/collection/activate`,
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers`,
  MoveCollectionTo: (id: string) => `accounts/${id}/phone_numbers/collection/activate`,
  PurchaseById: (id: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers/${id}/activate`,
  AddNumbersToCollection: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers/collection`,

  // POST
  CheckAvailableCollection: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers/check`,
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers/${id}`,
  DeleteList: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/phone_numbers/collection/?hard=true`,
};
