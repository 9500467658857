import Bullet from './components/Bullet';
import { FeedbackItemProps as Props } from './definition';
import StyledFeedbackItem from './style';

const FeedbackItem = ({ isPrompt, password, text, regex }: Props) => {
  const isError = password ? !regex?.test(password) : false;

  return (
    <StyledFeedbackItem $isError={isError}>
      {!isPrompt && <Bullet hasPassword={Boolean(password)} isError={isError} />}
      {text}
    </StyledFeedbackItem>
  );
};

export default FeedbackItem;
