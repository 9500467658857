import { useNavigation } from 'apps/PhoneSystem/shared/useNavigation';
import HorizontalNav from 'apps/shared/components/HorizontalNav';
import { FunctionComponent, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useRoutes } from 'react-router-dom';
import Error from 'shared/components/Error';
import Loading from 'shared/components/Loading';
import Heading from './components/Heading';
import Settings from './Settings';
import metaSettings from './Settings/meta';
import SiteLocations from './SiteLocations';
import metaSiteLocations from './SiteLocations/meta';
import StyledAccount from './style';

const Account: FunctionComponent = (): JSX.Element => {
  const SettingsWithNav = useNavigation(Settings);
  const { t } = useTranslation();

  const routes = useRoutes([
    { path: '/', element: <Navigate to={`./${metaSettings.slug}`} replace /> },
    { path: `/${metaSettings.slug}/*`, element: <SettingsWithNav /> },
    { path: `/${metaSiteLocations.slug}/*`, element: <SiteLocations /> },
    { path: '*', element: <Error /> },
  ]);

  const isSiteLocationsEdit = new URL(document.URL).pathname.includes(`${metaSiteLocations.slug}/`);

  return (
    <Suspense fallback={<Loading />}>
      <StyledAccount isSiteLocationsEdit={isSiteLocationsEdit}>
        {!isSiteLocationsEdit && (
          <>
            <Heading heading={t('phone_system:containers.account.label')} />
            <HorizontalNav items={[metaSettings, metaSiteLocations]} />
          </>
        )}
        {routes}
      </StyledAccount>
    </Suspense>
  );
};

export default Account;
