import debounce from 'lodash/debounce';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Icon from 'shared/components/Icon';
import Input from 'shared/components/Input';
import Tooltip from 'shared/components/Tooltip';
import defaultProps from './default';
import { SearchInputProps as Props } from './definition';

const DEBOUNCE_WAIT = 300; // in milliseconds

const SearchInput = (props: Props) => {
  const {
    hasAutoFocus,
    hasDebounce,
    isContainerVisible,
    iconSize,
    inputProps: { value, onChange, ...restInputProps },
    tooltip,
    onReset,
  }: Props = {
    ...defaultProps,
    ...props,
  };
  const inputRef = useRef<HTMLElement>();
  const [searchValue, setSearchValue] = useState<string>((value as string) ?? '');
  const themeColor = 'core.select.border';

  const on = {
    change: debounce(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
      },
      hasDebounce ? DEBOUNCE_WAIT : 0,
    ),
    reset: () => {
      onReset?.();
      setSearchValue('');
    },
  };

  useEffect(() => {
    setSearchValue((value as string) ?? '');
  }, [value]);

  useEffect(() => {
    if (hasAutoFocus) {
      inputRef.current?.focus();
    }
  }, [hasAutoFocus, isContainerVisible]);

  return (
    <Input
      inputRef={inputRef}
      value={searchValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        on.change(e);
        setSearchValue(e.target.value);
      }}
      {...restInputProps}
      startAdornment={
        <Tooltip title={tooltip}>
          <Icon
            className="adornment-start is-visible"
            name="search"
            size={iconSize === 'small' ? 20 : 24}
            themeColor={themeColor}
          />
        </Tooltip>
      }
      endAdornment={
        <Icon
          className={`adornment-end is-clickable${searchValue ? ' is-visible' : ''}`}
          name="cancel"
          subset={16}
          themeColor={themeColor}
          onClick={on.reset}
        />
      }
    />
  );
};

export default SearchInput;
