import i18next from 'i18next';
import { Cell, TABLE_CONSTANTS } from 'shared/components/Table';
import TableCellAccount from '../components/TableCellAccount';

const COLUMN_WIDTH = 400;

export const columns = () => [
  {
    Header: i18next.t('accounts_manager:containers.accounts.table.column.account_name'),
    Cell: (data: Cell<JSX.Element>) => <TableCellAccount column="name" data={data} />,
    accessor: 'name',
    width: COLUMN_WIDTH,
  },
  {
    Header: i18next.t('accounts_manager:containers.accounts.table.column.account_realm'),
    Cell: (data: Cell<JSX.Element>) => <TableCellAccount column="realm" data={data} />,
    accessor: 'realm',
    width: 600,
  },
  {
    Header: i18next.t('accounts_manager:containers.accounts.table.column.account_id'),
    Cell: (data: Cell<JSX.Element>) => <TableCellAccount column="id" data={data} />,
    accessor: 'id',
    width: COLUMN_WIDTH,
  },
  {
    Cell: (data: Cell<JSX.Element>) => <TableCellAccount column="edit" data={data} />,
    accessor: 'edit',
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
  },
  {
    Cell: (data: Cell<JSX.Element>) => <TableCellAccount column="button" data={data} />,
    accessor: 'button',
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.LARGE,
  },
];
