import { EditorState } from 'draft-js';

const defaultProps = {
  isDirty: false,
  editorState: EditorState.createEmpty(),
  onEditorStateChange: () => {},
  maxLength: 500,
};

export default defaultProps;
