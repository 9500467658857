import { FunctionComponent } from 'react';
import EditableList from 'shared/components/EditableList';
import Labeled from '../..';
import defaultProps from './default';
import { LabeledEditableListProps as Props } from './definition';
import StyledLabeledEditableList from './style';

const LabeledEditableList: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasDirtyIcon,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    editableListProps,
    feedback,
    tooltip,
    tooltipWidth,
    dataTestId,
  }: Props = {
    ...defaultProps,
    ...props,
  };

  return (
    <Labeled
      type="editableList"
      hasDirtyIcon={hasDirtyIcon}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={labelProps}
      labelWidth={labelWidth}
      inputWidth="auto"
      feedback={feedback}
      tooltip={tooltip}
      tooltipWidth={tooltipWidth}
      styledComponent={StyledLabeledEditableList}
      dataTestId={dataTestId}
    >
      <EditableList {...editableListProps} />
    </Labeled>
  );
};

export default LabeledEditableList;
