import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MapFrameProps as Props } from './definition';
import { StyledMapFrame } from './style';

const DEFAULT_TOOLTIP_CONTENT = '';

const MapFrame: FunctionComponent<Props> = (props: Props) => {
  const { selectedTimeZone, svgRef } = props;
  const { t } = useTranslation();

  return (
    <StyledMapFrame data-tip={DEFAULT_TOOLTIP_CONTENT}>
      <svg ref={svgRef} />
      <p>
        {`${t(
          `common:component.time_zone_map_picker_dialog.time_zone_map_picker.map_frame.selected_city`,
        )}: `}
        <span>{selectedTimeZone?.city ? selectedTimeZone.city : String.fromCharCode(8212)}</span>
      </p>
      <p>
        {`${t(
          `common:component.time_zone_map_picker_dialog.time_zone_map_picker.map_frame.nearest_time_zone`,
        )}: `}
        <span>
          {selectedTimeZone?.selectedTimeZone
            ? selectedTimeZone.selectedTimeZone
            : String.fromCharCode(8212)}
        </span>
      </p>
    </StyledMapFrame>
  );
};

export default memo(MapFrame);
