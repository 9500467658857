import styled, { css } from 'styled-components';
import { StyledTimeOfDaySelectProps as Props } from './definition';

const StyledTimeOfDaySelect = styled.div<Props>`
  ${(props) => css`
    .super-select > div:nth-child(3) {
      min-height: auto;
    }
  `};
`;

export default StyledTimeOfDaySelect;
