import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useTranslation } from 'react-i18next';
import { LabeledSwitch } from 'shared/components/Labeled';
import { FormFields } from '../../fields';

const UsePostMethodSwitch = () => {
  const { t } = useTranslation();

  return (
    <HookFormInputWrapper name={FormFields.UsePostMethod} isCheckbox>
      {({ ref, isDirty, ...formProps }) => (
        <LabeledSwitch
          id="switch-use-post-method"
          isDirty={isDirty}
          label={t(
            'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.use_post_method.label',
          )}
          switchProps={{
            ...formProps,
          }}
          tooltip={t(
            'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.use_post_method.tooltip',
          )}
          hasMargin
        />
      )}
    </HookFormInputWrapper>
  );
};

export default UsePostMethodSwitch;
