import {
  createArrayValue,
  createTranslateChangeHandler,
} from 'apps/PhoneSystem/components/EditForm/helper';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['smtp_permission_list', 'custom_smtp_email_address', 'smtp_email_address'];

const SMTPSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div role="row">
      <div role="cell">
        {/* SMTP permission list */}
        <HookFormInputWrapper name="smtp_permission_list">
          {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
            <LabeledInput
              label={t('phone_system:containers.faxes.section.smtp.smtp_permission_list.label')}
              inputProps={{
                value: createArrayValue(value),
                onChange: createTranslateChangeHandler(onChange),
                ...formProps,
                placeholder: t(
                  'phone_system:containers.faxes.section.smtp.smtp_permission_list.placeholder',
                ),
                id: 'input-smtp-permission-list',
              }}
              isDirty={isDirty}
              feedback={feedback}
            />
          )}
        </HookFormInputWrapper>

        {/* custom SMTP address */}
        <HookFormInputWrapper name="custom_smtp_email_address">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              label={t('phone_system:containers.faxes.section.smtp.custom_smtp_address.label')}
              inputProps={{
                ...formProps,
                placeholder: t(
                  'phone_system:containers.faxes.section.smtp.custom_smtp_address.placeholder',
                ),
                id: 'input-smtp-custom-address',
              }}
              isDirty={isDirty}
              feedback={feedback}
            />
          )}
        </HookFormInputWrapper>

        {/* SMTP email address */}
        <HookFormInputWrapper name="smtp_email_address">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              label={t('phone_system:containers.faxes.section.smtp.smtp_email_address.label')}
              inputProps={{
                ...formProps,
                disabled: true,
                id: 'input-smtp-email',
              }}
              isDirty={isDirty}
              feedback={feedback}
            />
          )}
        </HookFormInputWrapper>
      </div>
    </div>
  );
};

export default SMTPSection;
