import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import { Props } from './definition';

const JsonErrorDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { open, onClose, errors } = props;
  const { t } = useTranslation();

  const renderedErrors = JSON.stringify(
    errors,
    (key, value) => (['number', 'string'].includes(typeof value) ? `<span>${value}</span>` : value),
    4,
  );

  return (
    <Dialog
      open={open}
      title={t('common:error')}
      renderActions={
        <DialogActions
          rightButtons={
            <Button color="primary" variant="contained" onClick={onClose}>
              {t('common:done')}
            </Button>
          }
        />
      }
      onClose={onClose}
    >
      <p>{t('phone_system:containers.callflows.error_dialog.content.0')}</p>
      <pre dangerouslySetInnerHTML={{ __html: renderedErrors }}></pre>
    </Dialog>
  );
};

export default JsonErrorDialog;
