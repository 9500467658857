import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

const connectivity = {
  // GET
  FetchAll: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/connectivity`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/connectivity/${id}`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/connectivity`,

  // POST
  UpdateById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/connectivity/${id}`,
};

export default connectivity;
