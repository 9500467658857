import BasicsSection, { fields as basicsFields } from './BasicsSection';
import ExtensionSection, { fields as extensionFields } from './ExtensionSection';
import OptionsSection, { fields as optionsFields } from './OptionsSection';

const fields = {
  basics: basicsFields,
  extension: extensionFields,
  options: optionsFields,
};

const Section = {
  Basics: BasicsSection,
  Extension: ExtensionSection,
  Options: OptionsSection,
};

export { fields, Section };
