import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import { HeaderProps as Props } from './definition';
import StyledHeader from './style';

export { default as defaultProps } from './default';
export type { HeaderProps } from './definition';
export { CONSTANTS as DRAWER_HEADER_CONSTANTS } from './style';

const Header: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    isSmall,
    isTextLeft,
    isVertical,
    width,
    height,
    text,
    onClose,
    onCollapse,
  }: Props = props;

  const icon = <Icon name="chevron-left" subset={16} />;

  return (
    <StyledHeader
      isSmall={isSmall}
      isTextLeft={isTextLeft}
      isVertical={isVertical}
      width={width}
      height={height}
    >
      <span>
        <h3 onClick={() => isVertical && onCollapse((isOpen: any) => !isOpen)}>{text}</h3>
        <span onClick={() => onCollapse((isOpen: any) => !isOpen)}>
          {icon}
          {icon}
        </span>
      </span>
      {onClose && <Icon name="add" subset={16} size={18} onClick={onClose} />}
    </StyledHeader>
  );
};

export default Header;
