import MediaSelect from 'apps/PhoneSystem/shared/MediaSelect';
import { FormContext } from 'apps/shared/components/FormContext';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isCompoundFieldDirty } from '../../../utility';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['media.unavailable', 'media.temporary_unavailable'];

const GreetingSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    formState: { dirtyFields },
    resetField,
    watch,
  } = useFormContext();
  const contextName = useContext(FormContext);
  const watchMediaUnavailable = watch(`${contextName}media.unavailable`);
  const watchMediaTemporaryUnavailable = watch(`${contextName}media.temporary_unavailable`);

  useEffect(() => {
    if (watchMediaUnavailable === undefined) {
      resetField(`${contextName}media.unavailable`);
    }
  }, [watchMediaUnavailable, resetField, contextName]);

  useEffect(() => {
    if (watchMediaTemporaryUnavailable === undefined) {
      resetField(`${contextName}media.temporary_unavailable`);
    }
  }, [watchMediaTemporaryUnavailable, resetField, contextName]);

  return (
    <div role="row">
      <div role="cell">
        <MediaSelect
          label={t(
            'phone_system:containers.voicemails.section.greeting_media.unavailable_message.label',
          )}
          name="media.unavailable"
          isBuildInMediaIncluded={false}
          isClearable={isCompoundFieldDirty(dirtyFields, `${contextName}media.unavailable`)}
          id="select-greetings-unavailable-message"
        />

        <MediaSelect
          label={t(
            'phone_system:containers.voicemails.section.greeting_media.temporary_message.label',
          )}
          name="media.temporary_unavailable"
          isBuildInMediaIncluded={false}
          isClearable={isCompoundFieldDirty(
            dirtyFields,
            `${contextName}media.temporary_unavailable`,
          )}
          id="select-greetings-temporary-message"
        />
      </div>
    </div>
  );
};

export default GreetingSection;
