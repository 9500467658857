// Account Mutations
import { AppsStore } from 'api/appsStore';
import { RequestMethod } from 'definition';
import { kazooApi, TAGS } from 'services/kazoo';

export const appsStoreMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    updateAppsBlocklist: builder.mutation<AppsStore, { accountId: string; body: string[] }>({
      query: ({ accountId, body }) => ({
        url: AppsStore.UpdateBlocklist(accountId),
        method: RequestMethod.Post,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.APPSSTORE }],
    }),
  }),
});
