export enum MarginType {
  'none',
  'xsmall',
  'small',
  'medium',
  'xmedium',
  'large',
}

export type WidthType =
  | 'none'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'xmedium'
  | 'large'
  | 'xlarge'
  | 'auto';

export interface Width {
  [key: string]: WidthType;
}

export interface GetWidth {
  /** Size of element */
  width?: Width;
  /** Type of element */
  type?: string;
  /** Offset */
  offset?: number;
  /** Is margin to be explicitly added? */
  hasMargin?: boolean;
  /** Is the dirty icon being displayed? */
  hasDirtyIcon?: boolean;
  /** Is the info icon being displayed? */
  hasInfoIcon?: boolean;
  /** Is the return value in pixels? */
  isReturnPixels?: boolean;
  /** Is there a left margin? */
  leftMargin?: MarginType;
}
