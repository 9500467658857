import produce from 'immer';
import kebabCase from 'lodash/kebabCase';
import { FeatureCodeName, FieldValue, FormInput } from '../definition';
import { getFeatureCodeMeta } from './featureCodes';

export const getToastMessage = ({
  errors,
  intro,
  outro,
}: {
  errors: Array<string>;
  intro: string;
  outro: string;
}) => (
  <>
    <div>{intro}</div>
    <ul>
      {errors.map((message: string) => (
        <li key={kebabCase(message)}>{message}</li>
      ))}
    </ul>
    <div>{outro}</div>
  </>
);

export const getFieldNames = (label: string) =>
  Object.values(FieldValue).reduce((acc: Record<string, string>, suffix: FieldValue) => {
    acc[suffix] = `${kebabCase(label)}${suffix !== FieldValue.Key ? `.${suffix}` : ''}`;
    return acc;
  }, {});

export const getDuplicateNumbers = (formData: FormInput, value: Record<string, string>) => {
  const numbers = {
    enabled: Object.entries(formData).reduce(
      (acc: Record<string, string>, [field, { is_enabled, number }]: Array<string | any>) => {
        if (is_enabled) {
          acc[field] = number;
        }
        return acc;
      },
      {},
    ),
    get duplicated() {
      return Array.from(
        new Set(
          Object.values({ ...numbers.enabled, ...value }).filter(
            (number: string, i: number, a: Array<string>) => a.indexOf(number) !== i,
          ),
        ),
      );
    },
    get sorted() {
      return numbers.duplicated.sort((a: string, b: string) => (a > b ? 1 : -1));
    },
  };

  return numbers.sorted;
};

/**
 * @description The function creates a feature callflow object which the key is the feature code name and the value is the callflow.
 * @param target The target object the feature code callflow will be added to. This param will be immute.
 */
export const createFeatureCodeCallflowObject = (target: Record<string, any>, callflow: any) =>
  produce(target, (targetDraft) => {
    if (callflow.featurecode) {
      if (callflow.featurecode.name === FeatureCodeName.VoicemailActionDirect) {
        const encodedNumber = callflow.featurecode.number.toString().replace('\\', '');
        targetDraft[callflow.featurecode.name] = produce(callflow, (draft: any) => {
          draft.featurecode.number = encodedNumber;
        });
      } else {
        targetDraft[callflow.featurecode.name] = { ...callflow };
      }
    }
  });

export const filterData = (data: any) =>
  data?.reduce(
    (acc: Record<string, any>, callflow: any) => createFeatureCodeCallflowObject(acc, callflow),
    {},
  );

export const isFeatureCodeDirty = (name: FeatureCodeName, dirtyFields: Record<string, any>) => {
  const { label } = getFeatureCodeMeta()[name];
  const { key: fieldName } = getFieldNames(label);
  return fieldName in dirtyFields;
};
