const defaultProps = {};

export default defaultProps;

export const defaultValues = {
  name: '',
  owner_id: '',
  caller_id: '',
  caller_name: '',
  fax_header: '',
  fax_identity: '',
  fax_timezone: undefined,

  custom_smtp_email_address: '',
  smtp_email_address: '',
  retries: 3,

  smtp_permission_list: [],
  media: {
    fax_option: false,
  },
  notifications: {
    inbound: {
      email: {
        send_to: [],
      },
    },
    outbound: {
      email: {
        send_to: [],
      },
    },
  },
};

export const defaultCallerIdValue = (t: any) => ({
  label: t('phone_system:containers.faxes.section.caller_id.caller_id_select.placeholder'),
  value: '',
});
