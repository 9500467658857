import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import merge from 'lodash/merge';
import { Fragment, memo, useMemo } from 'react';
import { Cell } from 'react-table';
import { getClassName } from 'shared/utility';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { SelectionPosition } from '../definition';
import DragIcon from '../DragIcon';
import EditIcon from '../EditIcon';
import defaultProps from './default';
import { RowDataProps as Props } from './definition';
import StyledRowData from './style';
import { getSelectionCell } from './utility';

const RowData = memo((props: Props) => {
  const {
    hasDragIcon,
    hasEditIcon,
    isSelected,
    row: {
      cells,
      index,
      original: { id, key, hideEditIcon },
      getRowProps,
    },
    selection,
    onChangeSelection,
  } = merge({}, defaultProps, props);

  const { attributes, listeners, transform, transition, isDragging, setNodeRef } = useSortable({
    id: id ?? key,
  });

  const selectionCell = useMemo(
    () =>
      getSelectionCell({
        isSelected,
        index,
        selection: { position: selection?.position, type: selection?.type },
        onChangeSelection,
      }),
    [isSelected, index, selection?.position, selection?.type, onChangeSelection],
  );

  return (
    <StyledRowData
      ref={setNodeRef}
      {...getRowProps()}
      isDragging={isDragging}
      data-test-id={TEST_ID.COMMON.PREFIX.ROW.DATA.DEFAULT}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      {isDragging ? (
        <td>&nbsp;</td>
      ) : (
        <>
          {hasDragIcon && (
            <td>
              <DragIcon {...attributes} {...listeners} />
            </td>
          )}
          {selection?.position === SelectionPosition.Left && selectionCell}
          {cells.map(
            ({ column: { id, isHidden, maxWidth, minWidth, width }, getCellProps, render }: Cell) =>
              isHidden ? (
                <Fragment key={getCellProps().key} />
              ) : (
                <td
                  {...getCellProps(
                    width
                      ? {
                          style: {
                            maxWidth,
                            minWidth,
                            width,
                          },
                        }
                      : {},
                  )}
                  {...getClassName(id)}
                >
                  {render('Cell')}
                </td>
              ),
          )}
          {selection?.position === SelectionPosition.Right && selectionCell}
          {hasEditIcon && <td className="cell-action">{!hideEditIcon && <EditIcon id={id} />}</td>}
        </>
      )}
    </StyledRowData>
  );
});

export default RowData;
