import { PageSize } from './components/TableRowsPerPage/definition';

const defaultProps = {
  hasPadding: {
    bottom: true,
    side: true,
    top: true,
  },
  isVisible: {
    bar: true,
    container: true,
    content: true,
    info: true,
    rowsPerPage: false,
  },
  dataSize: 5,
  table: {
    state: {
      pageIndex: 0,
      pageSize: PageSize.Ten,
    },
    gotoPage: () => {},
    setPageSize: () => {},
  },
};

export default defaultProps;
