import { BaseSyntheticEvent } from 'react';
import { ComponentProps } from 'shared/definition';

interface Props extends ComponentProps {
  /** Is the filename below the input field? */
  isFileNameBelow?: boolean;
}

export enum UploadFileType {
  AUDIO = 'audio',
  CSV = 'csv',
  FILE = 'file',
}

export interface RemoteFile {
  downloadUrl: string;
  name: string;
  size: number;
}

export interface FileUploadProps extends Props {
  /** Does the component have top/bottom margin? */
  hasMargin?: boolean;
  /** Is the component for downloading the file only? */
  isDownloadOnly?: boolean;
  /** Accept */
  accept?: string;
  /** Top and Bottom deviders */
  fileBorder?: boolean;
  /** File type */
  fileType?: UploadFileType;
  /** Label */
  label?: string;
  /** Name */
  name: string;
  /** Remote file that will be displayed on load */
  remoteFile?: RemoteFile;
  /** File */
  value?: File;
  /** onChange event handler */
  onChange: (e?: BaseSyntheticEvent) => void;
  /** onDelete callback */
  onDelete?: (file?: File) => void;
  /** onDownloadStart callback */
  onDownloadStart?: () => void;
}

export interface StyledFileUploadProps extends Props {
  disabled: boolean;
}

export interface Attribute {
  iconName: IconName;
  label: string;
}

export type IconName = 'document-csv' | 'document-audio' | 'document';
