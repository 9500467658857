import TabCallflow from './TabCallflow';
import TabConference from './TabConference';
import TabDevices from './TabDevices';
import TabFaxbox from './TabFaxbox';
import TabSeat from './TabSeat';
import TabSMS from './TabSMS';
import TabVoicemail from './TabVoicemail';

export const Tab = {
  Callflow: TabCallflow,
  Conference: TabConference,
  Devices: TabDevices,
  Faxbox: TabFaxbox,
  Seat: TabSeat,
  Voicemail: TabVoicemail,
  SMS: TabSMS,
};
