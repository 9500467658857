import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { StyledTextProps as Props } from './definition';

const StyledText = styled(Box).attrs({ component: 'span' })<Props>`
  ${({ $isMissing, theme }) => css`
    ${$isMissing &&
    css`
      color: ${theme.core.states.error.main};
      font-style: italic;
    `}
  `}
`;

export default StyledText;
