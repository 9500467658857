import { selectTreeNodeCount } from 'models/Callflow/store/selectors';
import { FunctionComponent, RefObject, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useForceUpdate from 'shared/hooks/useForceUpdate';
import CallflowContext from '../CallflowContext';
import CallflowTree from './components/CallflowTree';
import FlowContainerInner from './components/FlowContainerInner';
import FlowRoot from './components/FlowRoot';
import defaultProps from './default';
import { FlowContainerProps as Props } from './definition';
import StyledFlowContainer from './style';

const FlowContainer: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { refCallflow, refZoom, isForceUpdate } = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const {
    callflow: { isDirty, id: callflowId },
    isPreview,
  } = useContext(CallflowContext);
  const forceUpdate = useForceUpdate(isForceUpdate);
  const count = useSelector((state: any) => selectTreeNodeCount(state, callflowId));
  const refNodeCount = useRef<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refNodeCount.current >= count) {
      const current = refCallflow?.current ?? ref.current;
      if (current) {
        current.scrollLeft = (current.scrollWidth - current.clientWidth) / 2;
      }
    }
  }, [ref, refCallflow, refNodeCount, count, forceUpdate]);

  return (
    <StyledFlowContainer ref={ref} isPreview={isPreview}>
      <FlowContainerInner refZoom={refZoom as RefObject<HTMLDivElement>} isPreview={isPreview}>
        <>
          {isDirty && <h2>{t('phone_system:containers.callflows.dirty_message')}</h2>}
          <FlowRoot />
          <CallflowTree refNodeCount={refNodeCount} />
        </>
      </FlowContainerInner>
    </StyledFlowContainer>
  );
};

export default FlowContainer;
