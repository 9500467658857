import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class ConferenceActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.CONFERENCE,
      module: CallFlowModuleKey.CONFERENCE,
      section: CallFlowSection.CONFERENCE,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'conference-filled',
      },
      label: i18next.t('phone_system:containers.callflows.actions.conference.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.conference.tooltip'),
    });
  }
}

export default ConferenceActionConfig;
