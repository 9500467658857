// TimeOfDay Mutations
import { TimeOfDay } from 'api/timeOfDay';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const timeOfDayMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createTimeOfDay: builder.mutation<TimeOfDay, Partial<TimeOfDay>>({
      query: ({ body }) => ({
        url: TimeOfDay.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.TIMEOFDAY, id: 'LIST' }],
    }),
    updateTimeOfDayById: builder.mutation<TimeOfDay, UpdateTimeOfDayParams>({
      query: ({ id, body }) => ({
        url: TimeOfDay.UpdateById(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.TIMEOFDAY, id }],
    }),
    deleteTimeOfDayById: builder.mutation<TimeOfDay, ByIdParam>({
      query: ({ id }) => ({
        url: TimeOfDay.DeleteById(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.TIMEOFDAY, id }],
    }),
  }),
});
