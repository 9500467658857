import { DEFAULT_REGEX } from '../../../SelectKeyDialog/constants';
import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  regex: DEFAULT_REGEX,
  use_absolute_mode: 'true',
};
