import styled from 'styled-components';

const CONSTANTS = {
  GRID: {
    COLUMN: ['224px', '36px'],
  },
};

const StyledIncomingCall = styled.div`
  display: grid;
  grid-template: 1fr / ${CONSTANTS.GRID.COLUMN[0]} ${CONSTANTS.GRID.COLUMN[1]} ${CONSTANTS.GRID
      .COLUMN[1]};
  align-items: center;

  div:nth-child(2),
  div:nth-child(3) {
    justify-self: center;
  }
`;

export default StyledIncomingCall;
