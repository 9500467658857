import { FunctionComponent } from 'react';
import defaults from './defaults';
import { EmptySMSViewContainerProps as Props } from './definition';
import EmptySMSView from './presenter';

const EmptySMSViewContainer: FunctionComponent<Props> = (props: Props) => {
  const { handleCreateDialogOpen, isCreateDisabled } = { ...defaults, ...props };

  return (
    <EmptySMSView
      handleCreateDialogOpen={handleCreateDialogOpen}
      isCreateDisabled={isCreateDisabled}
    />
  );
};

export default EmptySMSViewContainer;
