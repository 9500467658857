import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  pin: '',
  retries: 'default',
  interdigit: 'default',
  max_digits: 'default',
  preconnect_audio: 'dialtone',
  use_account_caller_id: false,
};
