import { ComponentProps } from 'shared/definition';

export enum ImportType {
  Bulk = 'Bulk',
  Individual = 'Individual',
}

type Props = ComponentProps;

export interface AddNumbersProps extends Props {
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSave: (validNumbers: string[], inValidNumbers: string[]) => void;
}

export type StyledAddNumbersProps = Props;
