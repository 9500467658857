import Joi from 'joi';
import { messages } from 'shared/utility/validation';

const schema = () =>
  Joi.object({
    nodeId: Joi.string(),
    add: Joi.array().items(Joi.string().optional().allow('')),
    remove: Joi.array().items(Joi.string().optional().allow('')),
  }).messages(messages());

export default schema;
