import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { LOCAL_STORAGE } from 'constant';
import { RootState } from 'definition';
import { FunctionComponent, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UploadFileType } from 'shared/components/FileUpload/definition';
import LabeledFileUpload from 'shared/components/Labeled/components/LabeledFileUpload';
import { UploadFormProps as Props } from './definition';

const UploadFormSection: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data } = props;
  const { t } = useTranslation();
  const { id: accountId } = useSelector((state: RootState) => state.account);
  const { setValue } = useFormContext();

  const remoteFile = useMemo(() => {
    let file;
    if (data?.description) {
      const baseURL = window.nemo?.api.crossbar;
      const authToken = localStorage.getItem(LOCAL_STORAGE.TOKEN);

      if (!baseURL) {
        throw new Error('BASE URL MISSING!');
      }
      if (!authToken) {
        throw new Error('AUTH TOKEN MISSING!');
      }

      const apiUrl = `${baseURL}${baseURL.substring(baseURL.length - 1) !== '/' ? '/' : ''}`;
      const downloadUrl = `${apiUrl}accounts/${accountId}/media/${data.id}/raw?auth_token=${authToken}`;

      file = {
        downloadUrl,
        name: data.description ?? '',
        size: data.content_length ?? 0,
      };
    }

    return file;
  }, [accountId, data]);

  return (
    <HookFormInputWrapper name="file">
      {({ ref, onChange, value, ...formProps }) => (
        <LabeledFileUpload
          {...formProps}
          id="upload-media-file"
          label={t('phone_system:containers.media.field.file_input.title')}
          inputWidth="large"
          isLabelAbove
          inputProps={{
            name: 'upload-media-file',
            value,
            remoteFile,
            fileType: UploadFileType.AUDIO,
            onChange: (file) => {
              onChange?.(file);
            },
            onDelete: () => {
              setValue('description', undefined);
            },
          }}
        />
      )}
    </HookFormInputWrapper>
  );
};

export default UploadFormSection;
