import { input } from 'shared/components/Input/style';
import * as utility from 'shared/utility';
import { Width } from 'shared/utility/definition';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledDaysOfWeekProps as Props } from './definition';

const StyledDaysOfWeek = styled.div<Props>`
  ${(props) => css`
    ${input(props, 'large')};
    ${mixin.borderRadius()};
    ${mixin.flex()};
    width: ${utility.getWidth({
      width: ('large' as unknown) as Width,
      type: 'default',
      isReturnPixels: true,
    })};

    > span {
      ${mixin.transition([
        { property: 'background-color', duration: 200 },
        { property: 'color', duration: 200 },
      ])};
      display: block;
      --size: 38px;
      width: calc(var(--size) + 2px);
      height: var(--size);
      line-height: var(--size);
      color: ${props.theme.whitelabel.secondary.color};
      cursor: pointer;
      text-align: center;
      user-select: none;

      &:first-of-type {
        ${mixin.borderRadius({ radius: 'half 0 0 half' })};
      }

      &:last-of-type {
        ${mixin.borderRadius({ radius: '0 half half 0' })};
      }

      &:hover {
        background-color: ${props.theme.core.background.grey.light.color};
      }

      &:not(:first-of-type) {
        border-left: 1px solid ${props.theme.core.input.border.default};
      }

      &.is-selected {
        background-color: ${props.theme.whitelabel.secondary.color};
        color: ${props.theme.core.color.white};
        font-weight: 600;
      }
    }
  `}
`;

export default StyledDaysOfWeek;
