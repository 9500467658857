import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  endless_playback: false,
  id: '',
  terminators: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'],
  advanced: false,
  var: '',
};
