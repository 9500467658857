import { ComponentProps } from 'shared/definition';

export enum ImageType {
  Logo = 'logo',
}

type Props = ComponentProps;

export interface ImageProps extends Props {
  /** Image type */
  type: ImageType;
  /** Image URL */
  src: string;
  /** Alternative text description */
  alt?: string;
}

export type StyledImageProps = Props;
