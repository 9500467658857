// Blocklist/index.ts
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { blocklistMutations } from './Mutations';
import { blocklistQueries } from './Queries';

export const schema = () =>
  Joi.object({
    name: Joi.string().required(),
  })
    .unknown(true)
    .messages(messages());

export const { useFetchBlocklistsQuery, useFetchBlocklistByIdQuery } = blocklistQueries;

export const {
  useUpdateBlocklistMutation,
  useDeleteBlocklistMutation,
  useCreateBlocklistMutation,
  usePatchBlocklistMutation,
} = blocklistMutations;
