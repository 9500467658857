import { Configs } from 'api/configs';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';
import { ConfigsResponse } from './types';

export const configsQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchConfigsById: builder.query<ConfigsResponse, ByIdParam>({
      query: ({ id }) => ({
        url: Configs.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => [{ type: TAGS.CONFIGS, id }],
    }),
  }),
});
