import { BizUser } from 'api/bizUser';
import { LOCAL_STORAGE } from 'constant';
import { RequestMethod } from 'definition';
import merge from 'lodash/merge';
import { bizUserApi, responseHandler } from 'services/bizUser';
import { ResetLoginAttemptsParams, ResetLoginAttemptsResponse } from './types';

const CONSTANTS = {
  TENANT: 'ooma_enterprise',
};

export const bizUserMutations = bizUserApi.injectEndpoints({
  endpoints: (builder) => ({
    resetLoginAttempts: builder.mutation<ResetLoginAttemptsResponse, ResetLoginAttemptsParams>({
      query: ({ body }) => ({
        body: { data: merge({}, { tenant: CONSTANTS.TENANT }, body) },
        method: RequestMethod.Post,
        url: BizUser.ResetLoginAttempts(localStorage.getItem(LOCAL_STORAGE.TOKEN) as string),
        responseHandler,
      }),
    }),
  }),
});
