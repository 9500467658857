import { EndpointItemType, EndpointType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import { useFetchDevicesQuery } from 'models/Device';
import { useFetchGroupsQuery } from 'models/Group';
import { useFetchUsersQuery } from 'models/User';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import Table from 'shared/components/Table';
import Typography from 'shared/components/Typography';
import TEST_ID from 'shared/utility/testing/constants/testId';
import TableTabs from '../TableTabs';
import defaultProps from './default';
import { TableSelectorProps as Props } from './definition';
import useUpdateFields from './hooks/useUpdateFields';
import { StyledTableSelector } from './style';
import translations from './translations';

const TableSelector = (props: Props) => {
  const {
    disableSortBy,
    hasActionRow,
    hasAdmins,
    hasPagination,
    hasPaginationPadding,
    hasUsersExtension,
    isRightTableDraggable,
    manualSortBy,
    columnsSelected,
    confirmations,
    id,
    included,
    labels,
    rowCount,
    selectionFieldArray,
    selectionFieldArray: { fields = [], remove },
    selectionTableType,
    width,
    addItemHandler,
    reorderItemsHandler,
  } = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { dialog } = translations();
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');
  const [pendingItem, setPendingItem] = useState<{
    endpointType: EndpointType;
    item: EndpointItemType;
  } | null>(null);

  const { data: devicesData = [] } = useFetchDevicesQuery();
  const { data: groupsData = [] } = useFetchGroupsQuery();
  const { data: usersData = [] } = useFetchUsersQuery();

  const onDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }): void => {
    /**
     * If user agrees to the terms of the dialog, then only the
     * device should be included and user should be removed
     */
    if (pendingItem && closeResponse.reason === 'saveClicked') {
      const indexes: Array<number> = [];

      // If adding one item, then delete the other item
      switch (pendingItem.endpointType) {
        case EndpointType.Device:
          indexes.push(
            fields.findIndex((field: GroupEndpoint) => field.key === pendingItem.item.owner_id),
          );
          break;
        case EndpointType.User: {
          // need to find owner ids of all the devices
          const usersDeviceIds = devicesData
            .filter((device) => device.owner_id === pendingItem.item.id)
            .map((device) => device.id);

          fields.forEach((field: GroupEndpoint, i: number) => {
            if (usersDeviceIds.includes(field.key)) {
              indexes.push(i);
            }
          });
          break;
        }
        default:
          break;
      }

      if (indexes.length) {
        remove(indexes);
      }

      addItemHandler(pendingItem.item, pendingItem.endpointType);
    }

    setPendingItem(null);
    setConfirmationMessage('');
  };

  useUpdateFields({
    hasUsersExtension,
    data: { devices: devicesData, groups: groupsData, users: usersData },
    selectionFieldArray,
  });

  return (
    <>
      <StyledTableSelector width={width}>
        <TableTabs
          hasAdmins={hasAdmins}
          hasUsersExtension={hasUsersExtension}
          confirmations={confirmations}
          fields={fields}
          included={included}
          labels={labels}
          rowCount={rowCount}
          setPendingItem={setPendingItem}
          setConfirmationMessage={setConfirmationMessage}
          addItemHandler={addItemHandler}
          {...(id ? { id: `${id}-left` } : {})}
        />
        <Box data-test-id={TEST_ID.PHONE_SYSTEM.SHARED.SHARED_DIALOG.SELECTED_TABLE_CONTAINER}>
          <Table
            hasSearch
            hasActionRow={hasActionRow}
            hasEditIcon={false}
            hasDragIcon={isRightTableDraggable}
            hasPagination={hasPagination}
            hasPaginationPadding={hasPaginationPadding}
            hasSideMargin={false}
            disableSortBy={disableSortBy}
            manualSortBy={manualSortBy}
            columns={columnsSelected}
            data={fields}
            dragWidth={width?.right}
            searchPlaceholder={t('common:component.table_selector.search_placeholder')}
            type={selectionTableType}
            handleDragEnd={reorderItemsHandler}
            {...(id ? { id: `${id}-right` } : {})}
          />
        </Box>
      </StyledTableSelector>
      <Dialog
        open={Boolean(confirmationMessage)}
        title={dialog.title}
        renderActions={<DialogActions onAction={onDialogAction} />}
        onClose={onDialogAction}
      >
        <Typography>{confirmationMessage}</Typography>
      </Dialog>
    </>
  );
};

export default TableSelector;
