const TEST_ID = {
  COMMON: {
    PREFIX: {
      BUTTON: 'btn',
      DIALOG: 'dialog',
      EDIT_ICON: 'edit-icon-test',
      EDITABLE_LIST_INPUT: 'editable-list-input',
      ICON: 'icon',
      ROW: {
        DATA: {
          DEFAULT: 'row-data',
          get SELECTION_CELL() {
            return `${TEST_ID.COMMON.PREFIX.ROW.DATA.DEFAULT}-selection-cell`;
          },
        },
        DRAG: 'row-drag',
      },
      SHARE_CHECKMARK: 'share',
      SHARED: 'shared',
    },
    MOCK_ROOT: 'mock-root',
    EDIT_ICON_PREFIX: 'edit-icon-test', // deprecated
    FILE_UPLOAD_INPUT: {
      DOWNLOAD_BUTTON: 'upload-download-button',
      DELETE_BUTTON: 'upload-delete-button',
      FILE_ICON: 'upload-file-icon',
    },
    SAVE_BUTTON: 'btn-save',
    SHARE_CHECKMARK_PREFIX: 'share', // deprecated
    SHARED: {
      TABLE: {
        CONTAINER: {
          get DEFAULT() {
            return `${TEST_ID.COMMON.PREFIX.SHARED}-table-container`;
          },
        },
        PAGINATION: {
          get PAGINATION_BAR() {
            return `${TEST_ID.COMMON.PREFIX.SHARED}-table-pagination-pagination-bar`;
          },
        },
      },
    },
    DIRTY_ICON: 'icon-error-filled',
  },
  ACCOUNTS_MANAGER: {
    EDIT: {
      CONTAINER: 'accounts-manager-edit-container',
    },
    LIST: {
      CONTAINER: 'accounts-manager-list-container',
    },
  },
  PHONE_SYSTEM: {
    ACCOUNT: {
      SETTINGS: {
        IDP_DIALOG: {
          DELETE: {
            CONFIRM_DIALOG: 'delete-idp-confirm-dialog',
          },
        },
        CELLULAR_ENABLEMENT_SECTION: {
          WARNING_DIALOG: 'warning-dialog',
        },
        TEAMCHAT_ENABLEMENT_SECTION: {
          DATA_RESTORATION_DIALOG: 'data-restoration-dialog',
        },
      },
    },
    CALLFLOW: {
      CONTAINER: 'callflow-container', // deprecated
      DRAWER: 'callflow-drawer', // deprecated
    },
    MODULE: {
      CALLFLOW: {
        CONTAINER: 'callflow-container',
        DRAWER: 'callflow-drawer',
      },
    },
    SHARED: {
      SHARED_DIALOG: {
        SELECTED_TABLE_CONTAINER: 'shared-selected-table-container',
        SELECTABLE_TABLE_CONTAINER: 'shared-selectable-table-container',
        ADD_MEMBER_ICON: 'shared-add-member-icon',
        REMOVE_MEMBER_ICON: 'shared-remove-member-icon',
      },
    },
  },
  NUMBERS: {
    NUMBERS_IN_USE: {
      EDIT: {
        APPLY_SITE_LOCATION_DIALOG: {
          PAGINATION_WRAPPER: 'pagination-wrapper',
        },
      },
    },
  },
};

export default TEST_ID;
