import { ADD_KEY } from 'constant';

export const initialState = {
  /**
   * {
      [callflowId]: {
      root: "abc123", // which is the root callflow id within the nodes
      isReadOnly: false, // Is this callflow currently being edited, or is it just a view?
      nodes: {
        "abc123": {
          data: {...},
          module: 'user',
          children: ["otherChildNodeId"]

          // There properties will be UI specific
          actionName:
          key:
          parent: ?
          caption: ?
          key_caption: ?

          isActivated: false,
          isDroppable: true,
        },
        "xyz456": {...}
      ]
   * }
  */
};

export const newCallFlow: Callflow = {
  id: ADD_KEY,
  isReadOnly: false,
  isActivated: false,
  isNew: true,
  isDirty: false,
  root: '',
  name: '',
  ui_is_main_number_cf: true,
  numbers: [],
  contact_list: {
    exclude: false,
  },
  patterns: [],
  metadata: {},
  nodes: {},
  flow: undefined,
};
