import { joiResolver } from '@hookform/resolvers/joi';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useFetchDevicesQuery } from 'models/Device';
import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import DeviceSelectWrapper from './components/DeviceSelectWrapper';
import defaultProps, { defaultValues } from './default';
import { Data, DeviceCallflowDialogProps as Props, DeviceNodeData } from './definition';
import schema from './schema';

const DeviceDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { data: deviceData, isLoading } = useFetchDevicesQuery();
  const { handleSubmit, control, watch, formState, reset } = useForm<Data>({
    mode: 'onChange',
    defaultValues,
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;
  const watchAdvanced = watch('advanced');
  const watchId = watch('id');

  const submitHandler = (formData: Data) => {
    const selected = deviceData?.find(({ id }) => id === formData.id);
    const data = { ...formData };

    if (!data.advanced) {
      data.var = '';
    }

    const nodeData: DeviceNodeData = {
      metadata: {
        name: selected.name,
        pvt_type: 'device',
      },
      data,
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    const updatedData = enhancedFormUtility.transformDataToFormData(
      { ...data, advanced: Boolean(data?.var) },
      defaultValues,
    );

    reset(updatedData);
  }, [data, reset]);

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.device.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <DeviceSelectWrapper control={control} id={watchId} />

      <HookFormInputWrapper name="timeout" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.device.add.ring_duration',
            )}
            inputWidth="small"
            inputProps={{
              type: 'number',
              ...formProps,
              id: 'input-device-ring-duration',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="can_call_self" control={control} isCheckbox>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            isDirty={isDirty}
            feedback={feedback}
            isCheckboxLeft
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.device.add.allow_self_call',
            )}
            indentWidth="small"
            checkboxProps={{
              ...formProps,
              id: 'input-device-allow-self-call',
            }}
            tooltip={t(
              'phone_system:containers.callflows.callflow_action_dialog.device.add.tooltip',
            )}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="advanced" control={control} isCheckbox>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            hasMargin
            isDirty={isDirty}
            feedback={feedback}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.device.add.advanced',
            )}
            indentWidth="small"
            checkboxProps={{
              ...formProps,
              id: 'checkbox-device-advanced',
            }}
          />
        )}
      </HookFormInputWrapper>

      {watchAdvanced && (
        <HookFormInputWrapper name="var" control={control}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              isLabelAbove
              label={t('phone_system:containers.callflows.callflow_action_dialog.device.add.var')}
              inputWidth="small"
              inputProps={{
                ...formProps,
                id: 'input-device-var',
              }}
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default DeviceDialog;
