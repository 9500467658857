import { RowArray } from 'apps/shared/definition';
import { Dispatch, SetStateAction } from 'react';

export enum Justify {
  Center = 'justify-center',
  Left = 'justify-left',
  Right = 'justify-right',
}

export enum SelectionPosition {
  Left = 'left',
  Right = 'right',
}

export enum SelectionType {
  Multiple = 'multiple',
  None = 'none',
  Single = 'single',
}

export interface Selection {
  /** Does the selection cell have a border? */
  hasBorder?: boolean;
  /** Default selected row indices */
  indices?: Array<number>;
  /** Position of the row selection (left|right) */
  position?: SelectionPosition;
  /** Type of row selection (single [radio] | multiple [checkbox] | none) */
  type: SelectionType;
  /** State handler */
  setRows: Dispatch<SetStateAction<RowArray>>;
}
