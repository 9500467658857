import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class SetCallerIdActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.SET_CALLER_ID,
      module: CallFlowModuleKey.SET_CALLER_ID,
      section: CallFlowSection.CALLER_ID,
      rules: [],
      iconProps: {
        name: 'phone-settings-filled',
      },
      label: i18next.t('phone_system:containers.callflows.actions.set_caller_id.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.set_caller_id.tooltip'),
    });
  }

  static subtitle = (nodeData: any) => nodeData?.value;
}

export default SetCallerIdActionConfig;
