import i18next from 'i18next';

const translations: any = () => ({
  table: {
    columns: {
      device: {
        header: i18next.t('phone_system:containers.e911info.table.column.device_nickname'),
      },
      e911: {
        address: {
          header: i18next.t('phone_system:containers.e911info.table.column.e911_address.header'),
          missing: i18next.t(
            'phone_system:containers.e911info.table.column.e911_address.value.missing',
          ),
        },
        callerId: {
          name: {
            header: i18next.t(
              'phone_system:containers.e911info.table.column.e911_caller_id_name.header',
            ),
            missing: i18next.t(
              'phone_system:containers.e911info.table.column.e911_caller_id_name.value.missing',
            ),
          },
          number: {
            header: i18next.t(
              'phone_system:containers.e911info.table.column.e911_caller_id_number.header',
            ),
            missing: i18next.t(
              'phone_system:containers.e911info.table.column.e911_caller_id_number.value.missing',
            ),
          },
        },
      },
      location: {
        header: i18next.t('phone_system:containers.e911info.table.column.seat_location'),
      },
      seat: {
        header: i18next.t('phone_system:containers.e911info.table.column.seat.header'),
        notAssigned: i18next.t('phone_system:containers.e911info.table.column.seat.not_assigned'),
      },
      source: {
        header: {
          info: i18next.t('phone_system:containers.e911info.table.column.source.header.info'),
          text: i18next.t('phone_system:containers.e911info.table.column.source.header.text'),
        },
        type: {
          account: i18next.t('phone_system:containers.e911info.table.column.source.type.account'),
          device: i18next.t('phone_system:containers.e911info.table.column.source.type.device'),
          seat: i18next.t('phone_system:containers.e911info.table.column.source.type.seat'),
        },
      },
    },
    title: i18next.t('phone_system:containers.e911info.table.title'),
  },
});

export default translations;
