import { ACTION_START } from '../../constants';
import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  action: ACTION_START,
  extra_metadata: [],
  format: 'mp3',
  method: 'put',
  record_on_answer: false,
  record_on_bridge: false,
  should_follow_transfer: true,
  time_limit: 600,
  url: '',
};
