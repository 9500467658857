import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { style } from '../style';
import { StyledDirtyIconProps as Props } from './definition';

const StyledDirtyIcon = styled(Box)<Props>`
  ${(props) => {
    const { $isError, theme } = props;

    return css`
      ${style(props).default};
      --color: ${$isError ? theme.core.color.red : theme.core.color.green.default};

      > span {
        margin: ${theme.spacing(-2, 0, 0)};
        color: var(--color);
        font-weight: normal;
        transform: scale(2);
      }

      > svg {
        ${style(props).svg};
        fill: var(--color);
      }
    `;
  }}
`;

export default StyledDirtyIcon;
