import { components } from 'react-select';
import Icon from 'shared/components/Icon';
import { IndicatorProps } from '../definition';

const ClearIndicator = (props: IndicatorProps) => (
  <components.ClearIndicator {...props}>
    <Icon hasHover name="cancel" subset={16} size={14} />
  </components.ClearIndicator>
);

export default ClearIndicator;
