import i18next from 'i18next';
import { Trans } from 'react-i18next';

/**
 * location_type values:
 * t('phone_system:containers.account.submodule.site_locations.options.location_type.home')
 * t('phone_system:containers.account.submodule.site_locations.options.location_type.office')
 * t('phone_system:containers.account.submodule.site_locations.options.location_type.other')
 * t('phone_system:containers.account.submodule.site_locations.options.location_type.warehouse')
 */

const translations = () => ({
  columns: {
    display_name: {
      header: i18next.t(
        'phone_system:containers.account.submodule.site_locations.table.column.location_name',
      ),
    },
    location_type: {
      header: i18next.t(
        'phone_system:containers.account.submodule.site_locations.table.column.type',
      ),
      value: (type?: string) =>
        type !== undefined
          ? i18next.t(
              `phone_system:containers.account.submodule.site_locations.options.location_type.${type}`,
            )
          : '—',
    },
    address: {
      header: i18next.t(
        'phone_system:containers.account.submodule.site_locations.table.column.address',
      ),
    },
    address_country: {
      header: i18next.t(
        'phone_system:containers.account.submodule.site_locations.table.column.country',
      ),
    },
    address_state: {
      header: i18next.t(
        'phone_system:containers.account.submodule.site_locations.table.column.state_province',
      ),
    },
    address_city: {
      header: i18next.t(
        'phone_system:containers.account.submodule.site_locations.table.column.city',
      ),
    },
    address_postal_code: {
      header: i18next.t(
        'phone_system:containers.account.submodule.site_locations.table.column.postal_code',
      ),
    },
    billable_seats: {
      header: i18next.t(
        'phone_system:containers.account.submodule.site_locations.table.column.billable_seats',
      ),
    },
    default: {
      header: i18next.t('common:default'),
      info: i18next.t(
        'phone_system:containers.account.submodule.site_locations.table.column.default.info',
      ),
    },
  },
  table: {
    action_row: {
      button: {
        label: i18next.t('phone_system:containers.account.submodule.site_locations.add.label'),
      },
    },
    no_items: (
      <Trans
        i18nKey="phone_system:containers.account.submodule.site_locations.table.no_items"
        components={{ underline: <span className="underline" /> }}
      />
    ),
  },
});

export default translations;
