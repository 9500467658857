import get from 'lodash/get';
import { UseFormSetError } from 'react-hook-form';

export const createTranslateChangeHandler = (baseChangeHandler: (event: any) => void) => (
  event: any,
) => {
  const stringValue = event.target.value.trim();
  let splitValue = stringValue.split(',').map((email: string) => email.trim());
  if (stringValue.endsWith(',') && stringValue.length === 1) {
    splitValue = [];
  } else if (stringValue.length === 0) {
    splitValue = [];
  }

  const translatedEvent = { ...event, target: { ...event.target, value: splitValue } };
  baseChangeHandler(translatedEvent);
};

export const createArrayValue = (value: any) =>
  value && Array.isArray(value) ? value.join(',') : '';

/**
 * The data structure for errors for the Phone Number API is
 *  	"data": {
 *      "cnam.display_name": {
			    "minLength": {
				     "message": "String must be at least 1 characters",
				     "target": 1,
				     "cause": "",
				     "value": ""
			    }
		   },
     }
 */
const handleError = (responseData: { [key: string]: any }, setError: UseFormSetError<any>) => {
  let hasError = false;
  Object.keys(responseData).forEach((errorKey) => {
    const errorObject = get(responseData, errorKey, null);
    if (errorObject) {
      Object.keys(errorObject).forEach((currentKey) => {
        const currentError = errorObject[currentKey];
        const regExp = new RegExp('%2E', 'g');
        const keyString = errorKey.replace(regExp, '.');
        setError(keyString, currentError);
      });
    }
  });
};

export const testResponseArrayForErrors = (resultArray: Array<{ [key: string]: any }>) => {
  let counter = 0;
  resultArray.forEach((currentResponse) => {
    testForResponseError(currentResponse) && counter++;
  });
  return counter;
};

export const testForResponseError = (
  responseData: { [key: string]: any },
  setError?: UseFormSetError<any>,
) => {
  let returnValue = false;
  const errorData = get(responseData, 'error.data', null);
  if (errorData) {
    returnValue = true;
    if (setError) {
      handleError(errorData, setError);
    }
  }
  return returnValue;
};
