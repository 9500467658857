import { CallFlowActionInterface, CallFlowSection } from 'models/Callflow/definition';
import { FunctionComponent, memo } from 'react';
import Section from './components/Section';
import defaultProps from './default';
import { SectionActionType, SectionsProps as Props } from './definition';
import StyledSections from './style';

const Sections: FunctionComponent<Props> = memo(
  (props: Props): JSX.Element => {
    const { actions, isDragging }: Props = { ...defaultProps, ...props };

    const filteredSections = actions.reduce(
      (sections: SectionActionType, action: CallFlowActionInterface) => {
        if (!sections.hasOwnProperty(action.section)) {
          sections[action.section] = [];
        }
        sections[action.section].push(action);
        return sections;
      },
      {} as SectionActionType,
    );
    const filteredSectionKeys = Object.keys(filteredSections) as CallFlowSection[];

    return (
      <StyledSections isDragging={isDragging}>
        {filteredSectionKeys.map((section: CallFlowSection, i: number) => (
          <Section key={section} index={i} section={section} actions={filteredSections[section]} />
        ))}
      </StyledSections>
    );
  },
);

export default Sections;
