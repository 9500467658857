import { joiResolver } from '@hookform/resolvers/joi';
import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import defaultProps, { defaultValues } from './default';
import {
  CallerIdDynamicCidDialogProps as Props,
  CallerIdDynamicCidNodeData,
  Data,
} from './definition';
import schema from './schema';

const CallerIdDynamicCidDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave }: Props = { ...defaultProps, ...props };
  const {
    getValues,
    formState: { isDirty },
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });

  useEffect(() => {
    const nodeData: CallerIdDynamicCidNodeData = {
      data: getValues(),
    };
    onSave(nodeData, isDirty);
  }, []);

  return <></>;
};

export default CallerIdDynamicCidDialog;
