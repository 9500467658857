import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  debug: false,
  method: 'GET',
  req_body_format: 'form',
  req_format: 'twiml',
  req_timeout: '5',
  voice_url: '',
};
