import * as utility from 'shared/utility';
import { WidthType } from 'shared/utility/definition';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledLabeledProps as Props } from './definition';

const StyledLabeled = styled.div<Props>`
  ${({
    hasInfo,
    hasMargin,
    hasSmallMargin,
    isDirty,
    isLabelAbove,
    inputWidth,
    labelWidth,
    leftMargin,
    sizeOffset,
    theme,
    type,
  }) => css`
    ${mixin.flex({
      justifyContent: 'flex-start',
      ...(isLabelAbove ? { direction: 'column', alignItems: 'flex-start' } : {}),
    })};
    width: ${utility.getWidth({
      hasDirtyIcon: isDirty,
      hasInfoIcon: hasInfo,
      leftMargin,
      offset: sizeOffset,
      type: type as string,
      width: {
        input: inputWidth as WidthType,
        label: isLabelAbove ? 'auto' : (labelWidth as WidthType),
      },
    })};

    ${(hasMargin || hasSmallMargin) &&
    css`
      margin: ${theme.spacing(0, 0, hasSmallMargin ? 2 : 6)};
    `};

    > div {
      ${mixin.flex({ alignItems: 'flex-start', justifyContent: 'flex-start' })};

      ${isLabelAbove &&
      css`
        width: inherit;
      `}

      > div:first-of-type {
        ${mixin.width({
          offset: sizeOffset as number,
          type: type as string,
          width: inputWidth as WidthType,
        })};
      }
    }
  `};
`;

export default StyledLabeled;
