import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import { field } from './definition';

const NotificationsSection: FunctionComponent = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <div role="row">
        <div role="cell">
          {/* Queue Name */}
          <HookFormInputWrapper name={field.notificationsHangup}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.notifications_hangup.label')}
                tooltip={t(
                  'call_center:containers.manage_queues.fields.notifications_hangup.tooltip',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'call_center:containers.manage_queues.fields.notifications_hangup.placeholder',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Queue Name */}
          <HookFormInputWrapper name={field.notificationsPickup}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.notifications_pickup.label')}
                tooltip={t(
                  'call_center:containers.manage_queues.fields.notifications_pickup.tooltip',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'call_center:containers.manage_queues.fields.notifications_pickup.placeholder',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Queue Name */}
          <HookFormSelectWrapper
            name={field.notificationsMethod}
            options={[
              { label: 'GET', value: 'GET' },
              { label: 'POST', value: 'POST' },
            ]}
          >
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.notifications_method.label')}
                tooltip={t(
                  'call_center:containers.manage_queues.fields.notifications_method.tooltip',
                )}
                selectProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>
    </>
  );
};

export default NotificationsSection;
