import CallerIdSection, {
  fields as callerIdFields,
  schema as callerIdSchema,
} from './CallerIdSection';
import OptionsSection, {
  fields as optionsFields,
  schema as optionsIdSchema,
} from './OptionsSection';

const Section = {
  CallerId: CallerIdSection,
  Options: OptionsSection,
};

const fields = {
  callerId: callerIdFields,
  options: optionsFields,
};

const schema = {
  callerId: callerIdSchema,
  options: optionsIdSchema,
};

export { Section, fields, schema };
