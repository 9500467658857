import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';
import { Metadata } from '../store/definition';

class QueueActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.QUEUE,
      module: CallFlowModuleKey.QUEUE,
      section: CallFlowSection.CALL_CENTER,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'person-pending-3',
      },
      label: i18next.t('phone_system:containers.callflows.actions.queue.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.queue.tooltip'),
    });
  }

  static subtitle = (nodeData: any, metadata: Metadata) => {
    let subtitle = metadata?.name + '';

    if (nodeData.var) {
      subtitle += ` {${nodeData.var}}`;
    }

    if (nodeData.enter_as_callback) {
      subtitle += `: ${i18next.t('phone_system:containers.callflows.actions.queue.subtitle')}`;
    }

    return subtitle;
  };
}

export default QueueActionConfig;
