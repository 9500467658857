/* eslint-disable react/no-unstable-nested-components */
import { getMediaType } from 'apps/PhoneSystem/shared/utility';
import { TableCellMaskedValue, TableCellMediaInput } from 'apps/shared/components/TableCell';
import { getInputValue } from 'apps/shared/components/TableCell/utility';
import { ADD_KEY, CHARACTER } from 'constant';
import { useFetchMediaByIdQuery, useFetchMediasQuery } from 'models/Media';
import { useFetchMenusQuery } from 'models/Menu';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Table, { Cell, Justify, TABLE_CONSTANTS } from 'shared/components/Table';
import { MediaType } from '../../../definition';

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useFetchMenusQuery();
  const { data: mediaData, isLoading: isLoadingMedia } = useFetchMediasQuery();

  const columns = [
    {
      Header: t('phone_system:containers.menu.table.column.name'),
      accessor: 'name',
    },
    {
      Header: t('phone_system:containers.menu.table.column.greeting_message'),
      accessor: ({ media: { greeting } }: any) =>
        greeting
          ? mediaData?.find((media: any) => media.id === greeting)?.name
          : t('phone_system:containers.menu.table.column.greeting_message_not_set'),
      Cell: ({
        row: {
          original: {
            media: { greeting },
          },
        },
      }: any) => {
        const { data, isLoading } = useFetchMediaByIdQuery({ id: greeting }, { skip: !greeting });
        const message = t('phone_system:containers.menu.table.column.greeting_message_not_set');

        if (isLoading) {
          return <Loading />;
        }

        return data?.name ?? message;
      },
    },
    {
      Header: t('phone_system:containers.menu.table.column.media_type'),
      accessor: ({ media: { greeting } }: any) =>
        mediaData?.find((media: any) => media.id === greeting)?.media_source,
      Cell: ({
        row: {
          original: {
            media: { greeting },
          },
        },
      }: any) => {
        const { data, isLoading } = useFetchMediaByIdQuery({ id: greeting }, { skip: !greeting });

        if (isLoading) {
          return <Loading />;
        }

        return getMediaType()[data?.media_source as MediaType];
      },
    },
    {
      Header: t('phone_system:containers.menu.table.column.input'),
      Cell: ({
        row: {
          original: {
            media: { greeting },
          },
        },
      }: any) =>
        greeting ? <TableCellMediaInput id={greeting} maxWidth={600} /> : CHARACTER.EMDASH,
      accessor: ({ media: { greeting } }: any) => {
        const data = mediaData?.find((media: any) => media.id === greeting);
        return data ? getInputValue(data) : '';
      },
      width: 600,
    },
    {
      Header: t('phone_system:containers.menu.table.column.retries'),
      accessor: 'retries',
      id: `menu.retries.${Justify.Right}`,
      width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
    },
    {
      Header: t('phone_system:containers.menu.table.column.pin'),
      Cell: ({ value }: Cell<JSX.Element>) => <TableCellMaskedValue value={value} />,
      accessor: 'pin',
      disableGlobalFilter: true,
      width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
    },
  ];

  if (isLoading || isLoadingMedia) {
    return <Loading />;
  }

  return (
    <Table
      title={t('phone_system:containers.menu.table.title')}
      columns={columns}
      data={data ?? []}
      addButton={{
        hasAdd: true,
        label: t('phone_system:containers.menu.table.action_row.button.add_menu.label'),
        onAdd: () => navigate(`./${ADD_KEY}`),
      }}
      hasSearch
    />
  );
};

export default List;
