import { useEffect, useMemo, useRef, useState } from 'react';
import defaultProps from './default';
import { OverflowListProps as Props } from './definition';
import StyledOverflowList from './style';
import { getMore, getTextWidth, SEPARATOR } from './utility';

const OverflowList = (props: Props) => {
  const { values, ...rest } = { ...defaultProps, ...props };
  const listValues = useMemo(() => [...values], [values]);
  const refTarget = useRef<HTMLSpanElement>(null);
  const [cellWidth, setCellWidth] = useState<number>(0);
  const [display, setDisplay] = useState<Record<string, string>>({
    more: '',
    text: '',
  });

  useEffect(() => {
    setCellWidth(refTarget.current?.parentElement?.offsetWidth as number);
  }, []);

  useEffect(() => {
    if (cellWidth) {
      let more = '';
      let text = listValues.join(SEPARATOR);
      let value = text;

      while (getTextWidth(value) > cellWidth) {
        listValues.pop();
        more = getMore(values.length - listValues.length);
        text = listValues.join(SEPARATOR);
        value = `${text}${more}`;
      }

      setDisplay({ text, more });
    }
  }, [cellWidth, listValues, values.length]);

  return (
    <StyledOverflowList
      ref={refTarget}
      {...rest}
      data-test-id="apps-shared-table-cell-overflow-list"
    >
      {cellWidth && (
        <>
          {display.text}
          {display.more && <span>{display.more}</span>}
        </>
      )}
    </StyledOverflowList>
  );
};

export default OverflowList;
