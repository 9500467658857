/**
 * @name setAttrs
 * @description Set New Relic custom attributes for `visitingAccountId`,
 *              `visitingAccountName`, and `visitingUserId`.
 *
 * @param [object]
 * @property accountId
 * @property accountName
 * @property userId
 *
 * @returns true/false (on success).
 */
export const setAttrs = ({
  accountId,
  accountName,
  userId,
}: {
  accountId?: string;
  accountName?: string;
  userId?: string;
}) => {
  let isSuccess = false;

  if (accountId && accountName && userId && typeof window.newrelic === 'object') {
    window.newrelic.setCustomAttribute('visitingAccountId', accountId, true);
    window.newrelic.setCustomAttribute('visitingAccountName', accountName, true);
    window.newrelic.setCustomAttribute('visitingUserId', userId, true);
    isSuccess = true;
  }

  return isSuccess;
};
