import { Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import { Edit } from './Edit';

export const AccountSettings = () => {
  const routes = useRoutes([
    { path: `/`, element: <Navigate to="./lines" replace /> }, // ADVPROV-TODO: make the default tab dynamic
    { path: `/:activeTab/*`, element: <Edit /> },
  ]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};
