import { DynamicForm } from 'apps/AdvancedProvisioner/shared/components/DynamicForm';
import { useSaveAndRebootDialog } from 'apps/AdvancedProvisioner/shared/hooks/useSaveAndRebootDialog';
import { getInheritTemplate, hideTabs } from 'apps/AdvancedProvisioner/utility';
import {
  useFetchAccountSettingsQuery,
  useFetchAccountTemplateQuery,
  useUpdateAccountMutation,
} from 'models/AdvancedProvisioner';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { schema } from './schema';

export const Edit = () => {
  const {
    data: accountSettingsData,
    isLoading: isLoadingAccountSettings,
  } = useFetchAccountSettingsQuery();
  const navigate = useNavigate();
  const {
    data: accountTemplateData,
    isLoading: isLoadingAccountTemplate,
  } = useFetchAccountTemplateQuery();
  const [updateAccount] = useUpdateAccountMutation();

  const template = useMemo(
    () => getInheritTemplate(hideTabs(accountTemplateData?.template), accountTemplateData?.cluster),
    [accountTemplateData?.template, accountTemplateData?.cluster],
  );

  const onCancel = useCallback(() => {
    navigate('/apps/provisioner');
  }, [navigate]);

  const { SaveAndRebootDialog, formSaveOverrideProps } = useSaveAndRebootDialog({
    isRebootDisabled: false,
    isReminderMode: true,
  });

  return (
    <>
      <DynamicForm
        isLoading={isLoadingAccountSettings || isLoadingAccountTemplate}
        breadcrumbData={['']}
        data={accountSettingsData?.settings}
        schema={schema}
        template={template}
        onCancel={onCancel}
        onFormSubmit={async (formData) => {
          await updateAccount({ body: { settings: formData } }).unwrap();
        }}
        {...formSaveOverrideProps}
      />
      <SaveAndRebootDialog rebootHandler={async () => {}} />
    </>
  );
};
