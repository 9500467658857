import { ComponentProps } from 'shared/definition';

export enum FeedbackType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

interface Props extends ComponentProps {
  /** Type of feedback message to display */
  type: FeedbackType;
}

export type FeedbackProps = Props;

export type StyledFeedbackProps = Props;
