import i18next from 'i18next';
import { FeatureCodeGroupName } from './definition';

export const getHeading = (name: FeatureCodeGroupName) =>
  ({
    [FeatureCodeGroupName.CallForward]: i18next.t(
      'phone_system:containers.feature_codes.group.call_forward.label',
    ),
    [FeatureCodeGroupName.DoNotDisturb]: i18next.t(
      'phone_system:containers.feature_codes.group.do_not_disturb.label',
    ),
    [FeatureCodeGroupName.FollowMe]: i18next.t(
      'phone_system:containers.feature_codes.group.follow_me.label',
    ),
    [FeatureCodeGroupName.HotDesking]: i18next.t(
      'phone_system:containers.feature_codes.group.hot_desking.label',
    ),
    [FeatureCodeGroupName.Misc]: i18next.t(
      'phone_system:containers.feature_codes.group.misc.label',
    ),
    [FeatureCodeGroupName.Parking]: i18next.t(
      'phone_system:containers.feature_codes.group.parking.label',
    ),
  }[name]);
