import { useDroppable } from '@dnd-kit/core';
import { UseDroppableArguments } from '@dnd-kit/core/dist/hooks/useDroppable';
import { FunctionComponent, PropsWithChildren } from 'react';

const Droppable: FunctionComponent<PropsWithChildren<UseDroppableArguments>> = (
  props: PropsWithChildren<UseDroppableArguments>,
) => {
  const { children, ...rest } = props;
  const { setNodeRef } = useDroppable(rest);

  return <div ref={setNodeRef}>{children}</div>;
};

export default Droppable;
