import Edit from 'apps/PhoneSystem/containers/Devices/Edit';
import DeviceSelect from 'apps/PhoneSystem/shared/DeviceSelect';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogLink, DialogType } from 'shared/components/Dialog';
import { useToast } from 'shared/hooks/useToast';
import { onEmbeddedEditSaveSuccess } from '../../../../utility';
import { DeviceSelectWrapperProps as Props } from './definition';

const DeviceSelectWrapper: FunctionComponent<Props> = ({ id, control }: Props): JSX.Element => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { t } = useTranslation();
  const { showToast } = useToast();

  return (
    <>
      <div role="row" className="split split-with-link">
        <div role="cell">
          <DeviceSelect
            isLabelAbove
            name="id"
            label={t('phone_system:containers.callflows.callflow_action_dialog.device.add.device')}
            control={control}
          />
        </div>
        <div role="cell">{id && <DialogLink onClick={() => setIsEditDialogOpen(true)} />}</div>
      </div>
      <Dialog
        open={isEditDialogOpen}
        title={t('phone_system:containers.callflows.callflow_action_dialog.device.edit.title')}
        type={DialogType.CallflowActions}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <Edit
          id={id}
          handleSaveSuccess={() => onEmbeddedEditSaveSuccess({ showToast, setIsEditDialogOpen })}
          handleDeleteSuccess={() => setIsEditDialogOpen(false)}
          handleCancel={() => setIsEditDialogOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default DeviceSelectWrapper;
