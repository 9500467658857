import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { MENU_OPTIONS } from '../../constants';

const schema = () =>
  Joi.object({
    key: Joi.string()
      .valid(...MENU_OPTIONS)
      .required()
      .messages(messages()),
  });

export default schema;
