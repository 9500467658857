import { DataTestId } from 'definition';
import { ComponentProps } from 'shared/definition';
import { WidthType } from 'shared/utility/definition';
import { SelectOption } from '../Select';

export enum FieldType {
  Input,
  Select,
  Textarea,
}

export enum Justify {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export type ListRow = string[] | SelectOption[];
export type List = ListRow[];

export interface EditableListInput {
  /** Is the input a textarea element? */
  isTextarea?: boolean;
  /** Placeholder text */
  placeholder?: string;
  /** Width of input/textarea */
  width?: WidthType;
  /** Initial height of textarea */
  height?: number;
  /** Confirm the input mask being used */
  validationMask?: (value: string) => boolean;
}

export interface EditableListSelect {
  /** Is the selected filtered (i.e., are chosen values removed from this select)? */
  isFiltered?: boolean;
  /** Does this select contain the value to be added to the list (if there are multiple selects present)? */
  isValue?: boolean;
  /** Select options */
  options?: SelectOption[];
  /** Option value */
  value?: SelectOption;
  /** Width of select */
  size?: WidthType;
  /** onChange event handler */
  onChange?: (selected?: any) => void;
}

export interface Column {
  /** Header text/justification */
  header: {
    text: string;
    justify?: Justify;
  };
  /** Is this column (inline) editable? */
  isEditable?: boolean;
  /** Column input (optional) */
  input?: EditableListInput;
  /** Column select (optional) */
  select?: EditableListSelect[];
  /** Column width */
  width?: number;
}

export interface Data {
  /** Columns that appear in the list */
  columns: Array<Column>;
  /** Row data */
  rows: List;
}

type Props = ComponentProps;

export interface EditableListProps extends Props, DataTestId {
  /** Data object */
  data: Data;
  /** Should use WindowedSelect Component? */
  isWindowedSelect?: boolean;
  /** onUpdate event handler */
  onUpdate: (data: any) => void;
}

export type StyledEditableListProps = Props;
