import { ComponentProps } from 'shared/definition';
import { ActionProps } from './components/Action/definition';

export enum InteractionBarElementType {
  DUPLICATE = 'DUPLICATE',
  EDIT = 'EDIT',
  REDO = 'REDO',
  UNDO = 'UNDO',
  ZOOM_IN = 'ZOOM_IN',
  ZOOM_OUT = 'ZOOM_OUT',
  SEPARATOR = 'SEPARATOR',
}

export enum InteractionBarType {
  DEFAULT = 'DEFAULT',
  QUICK_VIEW = 'QUICK_VIEW',
}

export interface InteractionBarElement extends ActionProps {
  /** Type of element */
  element: InteractionBarElementType;
}

interface Props extends ComponentProps {
  /** Interaction bar type (default or quickview)? */
  type: InteractionBarType;
  /** onAction event handler(s) */
  onAction?: Partial<Record<InteractionBarElementType, () => void>>;
}

export interface InteractionBarProps extends Props {
  /** Is the duplicate button enabled? */
  hasDuplicate?: boolean;
  hasUndo?: boolean;
  hasRedo?: boolean;
}

export interface StyledInteractionBarProps extends Props {}
