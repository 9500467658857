import { ComponentProps } from 'shared/definition';

export enum PageSize {
  Ten = 10,
  TwentyFive = 25,
  Fifty = 50,
  OneHundred = 100,
}

export interface TableRowsPerPageProps extends ComponentProps {
  /** Number of items/rows per page */
  pageSize: PageSize;
  /** setPageSize handler */
  setPageSize: (page: number) => void;
}
