// Faxbox/index.ts
import { REGEX } from 'constant';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { faxboxMutations } from './Mutations';
import { faxboxQueries } from './Queries';

export const schema = () =>
  Joi.object({
    // Basic
    name: Joi.string().min(1).max(128).required(),
    notifications: Joi.object({
      inbound: Joi.object({
        email: Joi.object({
          send_to: Joi.array().items(Joi.string().email({ tlds: { allow: false } })),
        }).unknown(true),
      }).unknown(true),
      outbound: Joi.object({
        email: Joi.object({
          send_to: Joi.array().items(Joi.string().email({ tlds: { allow: false } })),
        }).unknown(true),
      }).unknown(true),
    }).unknown(true),

    // Caller ID
    caller_name: Joi.string().empty(''),
    fax_header: Joi.string().empty(''),
    fax_identity: Joi.string().empty(''),

    // Caller SMTP
    smtp_permission_list: Joi.array().items(Joi.string().regex(REGEX.FAXBOX.SMTP_PERMISSION_LIST)),
    custom_smtp_email_address: Joi.string().empty(''),

    // Caller Options
    retries: Joi.number().integer().min(0).max(4),
    fax_timezone: Joi.string(),

    media: Joi.object({
      fax_option: Joi.boolean(),
    }).unknown(true),
  })
    .unknown(true)
    .messages(messages());

export const {
  useFetchFaxboxesQuery,
  useFetchFaxboxByIdQuery,
  useFetchFaxboxesByOwnerIdQuery,
} = faxboxQueries;

export const {
  useUpdateFaxboxMutation,
  useDeleteFaxboxMutation,
  useCreateFaxboxMutation,
} = faxboxMutations;
