import TextTruncate from 'react-text-truncate';
import defaultProps from './default';
import { InputLabelProps as Props } from './definition';
import { StyledInputLabel } from './style';

export { CONSTANTS as INPUT_LABEL_CONSTANTS } from './constant';

const InputLabel = (props: Props) => {
  const { children, isAbove, leftMargin, rightMargin, ...rest } = {
    ...defaultProps,
    ...props,
  };

  return (
    <StyledInputLabel
      $isAbove={isAbove}
      $leftMargin={leftMargin}
      $rightMargin={rightMargin}
      {...rest}
    >
      {children?.constructor === String ? (
        <TextTruncate element="span" line={2} text={children} truncateText="…" />
      ) : (
        children
      )}
    </StyledInputLabel>
  );
};

export default InputLabel;
