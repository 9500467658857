import { FunctionComponent } from 'react';
import Card from 'shared/components/Card';
import defaultProps from './default';
import { ImageProps as Props } from './definition';

const Image: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { type, alt, src } = { ...defaultProps, ...props };

  return (
    <Card
      cardProps={{
        classes: { root: `MuiCard-image MuiCard-image-${type}` },
        'data-test-id': 'tablecell-image',
      }}
      content={<img src={src} alt={alt} />}
    />
  );
};

export default Image;
