import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  audio: 'one-way',
  endpoints: [],
  name: '',
  timeout: 5,
};

export const endpointDefaultValues = {
  delay: '0',
  timeout: '20',
};
