import { AppMeta } from 'apps/shared/definition';
import { useFetchAppsQuery } from 'models/AppsStore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAppListing, getAppListingSearch } from './utility';

const useAppListing = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const [apps, setApps] = useState<Array<AppMeta>>([]);
  const { data } = useFetchAppsQuery();

  useEffect(() => {
    if (data && language) {
      setApps(getAppListing(data, language));
    }
  }, [data, language]);

  return { appCount: apps.length, searchApps: getAppListingSearch(apps) };
};

export default useAppListing;
