import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import * as mixin from '../../../../utility/mixin';

const StyledBreadcrumb = styled(Box)`
  ${({ theme }) => css`
    ${mixin.flex({ direction: 'column', alignItems: 'flex-start' })};
    margin: 0 auto 0 0;

    ul {
      ${mixin.flex({ justifyContent: 'flex-start' })};
      text-wrap: nowrap;

      li {
        margin: ${theme.spacing(0, 0.66, 0, 0)};
        list-style-type: none;

        &:not(:first-of-type) {
          color: ${theme.brio.text.secondary};

          &:not(:last-of-type):after {
            content: ', ';
          }
        }
      }
    }
  `}
`;

export default StyledBreadcrumb;
