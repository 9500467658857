import { SECONDS_IN_DAY } from 'constant';
import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';

export const schema = () =>
  Joi.object({
    flow: {
      data: {
        default_callback_timeout: Joi.number()
          .optional()
          .integer()
          .min(0)
          .max(SECONDS_IN_DAY * 1000)
          .empty()
          .allow('')
          .messages(
            messages({
              'number.max': i18next.t('common:validation.general.number_max_custom', {
                max: SECONDS_IN_DAY,
              }),
            }),
          ),
        default_ringback_timeout: Joi.number()
          .optional()
          .integer()
          .min(0)
          .max(SECONDS_IN_DAY * 1000)
          .empty()
          .allow('')
          .messages(
            messages({
              'number.max': i18next.t('common:validation.general.number_max_custom', {
                max: SECONDS_IN_DAY,
              }),
            }),
          ),
      },
    },
  })
    .unknown(true)
    .messages(messages());
