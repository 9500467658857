import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';

const Callback: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box style={{ transform: 'translateY(2px)' }}>
      <Tooltip
        title={t('call_center:containers.dashboard.component.table_cell_incoming_call.callback')}
        placement="right"
      >
        <Icon name="phone-callback-filled" size={20} themeColor="brio.greyscale.600" />
      </Tooltip>
    </Box>
  );
};

export default Callback;
