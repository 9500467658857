import * as utility from 'shared/utility';
import styled, { css } from 'styled-components';
import { StyledSeparatorProps as Props } from './definition';

const StyledSeparator = styled.div<Props>`
  ${(props) => css`
    width: 1px;
    height: inherit;
    background-color: ${utility.alphafy(props.theme.core.divider.default, 0.5)};
  `};
`;

export default StyledSeparator;
