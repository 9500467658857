import { useDroppable } from '@dnd-kit/core';
import CallflowContext from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import { DragSourceType, DropType } from 'models/Callflow/store/definition';
import { FunctionComponent, memo, useContext } from 'react';
import Droppable from 'shared/components/Droppable';
import Arrow from './components/Arrow';
import Plus from './components/Plus';
import { FlowArrowProps as Props } from './definition';
import StyledFlowArrow from './style';

const FlowArrow: FunctionComponent<Props> = memo(
  (props: Props): JSX.Element => {
    const {
      isDragging,
      parentNode,
      node: {
        isInsertable,
        parentId,
        data: { nodeId },
      },
    } = props;
    const {
      isPreview,
      callflow: { id: callflowId },
    } = useContext(CallflowContext);
    const { over, setNodeRef } = useDroppable({ id: callflowId });

    const id = `${parentId ? `${parentId}-` : ''}arrow-${nodeId}`;
    const isActivated = isDragging && isInsertable;
    const isOver = over?.id === id;

    const droppableProps = {
      id,
      disabled: !isInsertable || isPreview,
      data: {
        id: nodeId,
        source: DragSourceType.TREE,
        type: DropType.ARROW,
      },
    };

    const { isMultinary, children } = { ...{ isMultinary: false, children: [] }, ...parentNode };

    return (
      <StyledFlowArrow ref={setNodeRef} isActivated={isActivated} isOver={isOver}>
        <Droppable {...droppableProps}>
          <Arrow isMultinary={isMultinary && Object.keys(children).length > 1} />
          <Plus isActivated={isActivated} />
        </Droppable>
      </StyledFlowArrow>
    );
  },
);

export default FlowArrow;
