import { buildFullNameFromUser } from 'models/User';

/**
 * @name checkForSmsBoxOwner
 * @description Checks for the owner of a phone number in the SMS data and retrieves the user/owner's information
 *
 * @param smsData - The SMS data
 * @param usersData - The Users data
 * @param phoneNumber - The phone number for which the user/owner needs to be identified
 * @returns - The full name and username of the phone number owner, or an empty string if the owner is not found
 */
export const checkForSmsBoxOwner = (
  smsData: SMSResponse | undefined,
  usersData: UserResponse | undefined,
  phoneNumber: string | undefined,
) => {
  if (smsData && usersData && phoneNumber) {
    let phoneNumberOwnerInfo = <User>null;
    // TODO: Optimize SMS fetch and remove this loop
    const phoneNumberOwner = smsData.find(
      (item: SMS) => item.numbers && item.numbers.includes(phoneNumber),
    );

    // find Owner's info
    if (phoneNumberOwner) {
      phoneNumberOwnerInfo = usersData.find((user: User) => user.id === phoneNumberOwner.owner);
    }

    if (phoneNumberOwnerInfo) {
      return `${buildFullNameFromUser(phoneNumberOwnerInfo)}, ${phoneNumberOwnerInfo.username}`;
    }
  }
  return '';
};

/**
 * @name submitByCondition
 * @description Submits data based on specified conditions, optionally creating SMS and updating phone number
 *
 * @param shouldCreateSms - Whether to create an SMS
 * @param shouldUpdatePhoneNumber - Whether to update the phone number
 * @param id - id
 * @param smsSaveData - Data for creating an SMS
 * @param saveData - Data for updating the phone number
 * @param createSMS - Function to create an SMS
 * @param updatePhoneNumber - Function to update the phone number
 * @returns - Submission result
 */

export const updateEntities = async (
  shouldCreateSms: boolean,
  shouldUpdatePhoneNumber: boolean,
  id: string,
  smsSaveData: any,
  saveData: any,
  createSms: any,
  updatePhoneNumber: any,
) => {
  try {
    if (shouldCreateSms) {
      await createSms({ body: smsSaveData }).unwrap();
    }
    if (shouldUpdatePhoneNumber) {
      await updatePhoneNumber({ id, body: saveData }).unwrap();
    }
    return { isSuccessfullySubmitted: true };
  } catch (error) {
    return { isSuccessfullySubmitted: false, error };
  }
};
