import { useExitConfirmationDialog } from '../../hooks/useExitConfirmationDialog';
import { EnhancedFormCore } from '../EnhancedFormCore';
import { EnhancedFormProps as Props } from './definition';
import { useFormNavigation } from './hooks/useFormNavigation';

export const EnhancedForm = <Data extends Record<string, any>>(props: Props<Data>) => {
  const { children, navBlockPathWhitelist } = props;
  const formNavProps = useFormNavigation();

  const { onIsDirtyChange } = useExitConfirmationDialog({
    isDirty: false,
    pathWhitelist: navBlockPathWhitelist,
  });

  return (
    <EnhancedFormCore {...formNavProps} {...props} onIsDirtyChange={onIsDirtyChange}>
      {children}
    </EnhancedFormCore>
  );
};
