import styled from 'styled-components';
import { HEADER_CONSTANTS } from '../Header';

const StyledPage = styled.div`
  display: grid;
  grid-template: ${HEADER_CONSTANTS.HEIGHT} 1fr / 1fr;
  height: 100vh;
  overflow-x: clip;

  > div {
    width: 100vw;
    height: calc(100vh - ${HEADER_CONSTANTS.HEIGHT});
  }
`;

export default StyledPage;
