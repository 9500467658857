import i18next from 'i18next';

const translations = () => ({
  title: i18next.t(
    'phone_system:containers.callflows.callflow_action_dialog.application_variables.add.title',
  ),
  variable_type: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.application_variables.add.variable_type',
    ),
  },
  variable_types: [
    {
      value: 'user',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.application_variables.variable_types.user',
      ),
    },
    {
      value: 'vmbox',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.application_variables.variable_types.vmbox',
      ),
    },
    {
      value: 'device',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.application_variables.variable_types.device',
      ),
    },
    {
      value: 'media',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.application_variables.variable_types.media',
      ),
    },
    {
      value: 'menu',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.application_variables.variable_types.menu',
      ),
    },
    {
      value: 'queue',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.application_variables.variable_types.queue',
      ),
    },
    {
      value: 'callflow',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.application_variables.variable_types.callflow',
      ),
    },
    {
      value: 'custom',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.application_variables.variable_types.custom',
      ),
    },
  ],
});

export default translations;
