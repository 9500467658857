import { useEffect, useRef } from 'react';

/**
 * @name useOutsideClick
 * @description Invokes an action when a click is made outside of the referenced element.
 *
 * @param callback Callback function to invoke on outside click
 * @param selector Selector of secondary element exempt from click
 *
 * @returns Reference returned by useRef
 */
const useOutsideClick = (callback: () => void, selector?: string): any => {
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    // TODO: Memoized this callback
    const handleCallback = (e: MouseEvent) => {
      const target = e.target as Node;

      if (
        ref.current &&
        !ref.current.contains(target) &&
        (selector ? !document.querySelector(selector)?.contains(target) : true)
      ) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleCallback);

    return () => {
      document.removeEventListener('mousedown', handleCallback);
    };
  }, [ref, selector, callback]);

  return ref;
};

export default useOutsideClick;
