import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['hunt_allow', 'hunt_deny'];

const ExtensionSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div role="row">
      <div role="cell">
        {/* Whitelist of numbers */}
        <HookFormInputWrapper name="hunt_allow">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              id="input-extension-whitelist"
              label={t('phone_system:containers.menu.section.extension.whitelist.label')}
              inputProps={{
                ...formProps,
                placeholder: t(
                  'phone_system:containers.menu.section.extension.whitelist.placeholder',
                ),
              }}
              isDirty={isDirty}
              feedback={feedback}
              tooltip={t('phone_system:containers.menu.section.extension.whitelist.tooltip')}
            />
          )}
        </HookFormInputWrapper>

        {/* Blocklist of numbers */}
        <HookFormInputWrapper name="hunt_deny">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              id="input-extension-blocklist"
              label={t('phone_system:containers.menu.section.extension.blocklist.label')}
              inputProps={{
                ...formProps,
                placeholder: t(
                  'phone_system:containers.menu.section.extension.blocklist.placeholder',
                ),
              }}
              isDirty={isDirty}
              feedback={feedback}
              tooltip={t('phone_system:containers.menu.section.extension.blocklist.tooltip')}
            />
          )}
        </HookFormInputWrapper>
      </div>
    </div>
  );
};

export default ExtensionSection;
