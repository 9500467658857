import { sortSeatType } from 'apps/PhoneSystem/shared/utility';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export const useSeatType = ({
  accountConfigsData: { seat_types } = {},
}: {
  accountConfigsData: any;
}) => {
  const navigate = useNavigate();

  const getSeatTypeOptions = useCallback(
    () =>
      seat_types
        ? Object.keys(seat_types)
            .map((value: string) => ({
              label: seat_types[value]?.display_name,
              value,
              onClick: () => navigate(`./add?seat_type=${value}`),
            }))
            .sort(sortSeatType)
        : [],
    [seat_types, navigate],
  );

  const seatTypes = useMemo(() => seat_types ?? [], [seat_types]);

  return { seatTypes, getSeatTypeOptions };
};
