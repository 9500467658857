import { joiResolver } from '@hookform/resolvers/joi';
import { ADD_KEY, REMOVE_KEY } from 'constant';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CallflowActionsDialog from '../CallflowActionDialog';
import EditableInputList from './components/EditableInputList';
import defaultProps, { defaultValues } from './default';
import {
  AgentRequiredSkillsDialogProps as Props,
  AgentRequiredSkillsNodeData,
  Data,
} from './definition';
import schema from './schema';

const AgentRequiredSkillsDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  // when adding new input field, react form does not re-render
  const [, updateState] = useState();
  // @ts-ignore
  const forceUpdate = useCallback(() => updateState({}), []); // dubious...
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    getValues,
    setValue,
  } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });

  useEffect(() => {
    getValues(ADD_KEY).length === 0 && addHandler(ADD_KEY);
    getValues(REMOVE_KEY).length === 0 && addHandler(REMOVE_KEY);
  }, []);

  const submitHandler = (formData: Data) => {
    const nodeData: AgentRequiredSkillsNodeData = {
      data: {
        ...formData,
        [ADD_KEY]: formData[ADD_KEY].filter((value: string) => value !== ''),
        [REMOVE_KEY]: formData[REMOVE_KEY].filter((value: string) => value !== ''),
      },
    };
    onSave(nodeData, isDirty);
  };

  const addHandler = (skillKey: any) => {
    const values = getValues(skillKey);
    values.push('');
    setValue(skillKey, values);
    forceUpdate();
  };

  const removeHandler = (skillKey: any, index: number) => {
    const values = getValues(skillKey);
    values.splice(index, 1);
    values.length === 0 && values.push('');
    setValue(skillKey, values);
    forceUpdate();
  };

  return (
    <CallflowActionsDialog
      data={data}
      title={t(
        'phone_system:containers.callflows.callflow_action_dialog.agent_required_skills.title',
      )}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <p>
        {t(
          'phone_system:containers.callflows.callflow_action_dialog.agent_required_skills.add.explanation',
        )}
      </p>
      <EditableInputList
        control={control}
        label={t('common:add')}
        skillKey={ADD_KEY}
        values={getValues(ADD_KEY)}
        onAddHandler={addHandler}
        onRemoveHandler={removeHandler}
      />
      <EditableInputList
        control={control}
        label={t('common:remove')}
        tooltip={t(
          'phone_system:containers.callflows.callflow_action_dialog.agent_required_skills.remove.tooltip',
        )}
        skillKey={REMOVE_KEY}
        values={getValues(REMOVE_KEY)}
        onAddHandler={addHandler}
        onRemoveHandler={removeHandler}
      />
    </CallflowActionsDialog>
  );
};

export default AgentRequiredSkillsDialog;
