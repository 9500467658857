import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ExternalLink from 'shared/components/ExternalLink';
import Icon from 'shared/components/Icon';
import * as utility from 'shared/utility';
import DashboardPanel from '../DashboardPanel';
import { DashboardPanelKnowledgeProps as Props } from './definition';
import { getLinkData } from './utility';

const DashboardPanelKnowledge: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const linkData = useMemo(() => getLinkData(t), [t]);
  return (
    <DashboardPanel type="knowledge">
      <div>
        {linkData.map((item: any, i: number) => (
          <ExternalLink key={utility.generateKey(i, item.title)} url={item.url}>
            <>
              <Icon name={item.icon.name} size={item.icon.size} />
              <span>{item.title}</span>
            </>
          </ExternalLink>
        ))}
      </div>
    </DashboardPanel>
  );
};

export default DashboardPanelKnowledge;
