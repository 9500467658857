import { AppIconSet, AppIconSubset } from './definition';

export const defaultState = {
  size: 40,
};

const defaultProps = {
  hasShadow: false,
  set: 'legacy' as AppIconSet,
  subset: 512 as AppIconSubset,
};

export default defaultProps;
