import { SetStateAction } from 'react';

/** @description The utility to show the edit button for menu dropdown when suitable */
export const onSelectedIdChanged = ({
  selectedId,
  setShowEditButton,
  setEditId,
}: {
  selectedId: string;
  setShowEditButton: (value: SetStateAction<boolean>) => void;
  setEditId: (value: SetStateAction<string>) => void;
}) => {
  if (selectedId) {
    setShowEditButton(true);
    setEditId(selectedId);
  }
};
