import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import kebabCase from 'lodash/kebabCase';
import { useFetchQueueQuery } from 'models/Queues';
import { FunctionComponent, useEffect, useState } from 'react';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import defaultProps from './default';
import { QueueSelectProps as Props } from './definition';
import StyledQueueSelect from './style';

const QueueSelect: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { name, label, control, isLabelAbove, tooltip }: Props = { ...defaultProps, ...props };
  const [queues, setQueues] = useState<Array<SelectOption>>([]);
  const { data, isLoading } = useFetchQueueQuery();

  useEffect(() => {
    if (data) {
      setQueues(data.map((queue) => ({ value: queue.id, label: queue.name })));
    }
  }, [data]);

  if (isLoading) return <Loading />;
  return (
    <StyledQueueSelect>
      <HookFormSelectWrapper name={name} control={control} options={queues}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove={isLabelAbove}
            label={label}
            tooltip={tooltip}
            selectProps={{
              ...formProps,
              id: `select-queue-${kebabCase(label)}`,
            }}
          />
        )}
      </HookFormSelectWrapper>
    </StyledQueueSelect>
  );
};

export default QueueSelect;
