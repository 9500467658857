import { EditPanel } from 'apps/shared/components/StyledEditForm';
import useTabs from 'apps/shared/hooks/useTabs';
import { MEDIA_TYPE } from 'constant';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Dialog, { DialogActions, DialogType } from 'shared/components/Dialog';
import {
  DialogType as FileDownloadDialogType,
  Download,
  FileDownloadDialogProps as Props,
} from './definition';
import { buildItems } from './utility';

export { DialogType as FileDownloadDialogType } from './definition';

const FileDownloadDialog = ({ isOpen, data, type, onClose }: Props) => {
  const [download, setDownload] = useState<Download>({ name: '', url: '' });
  const { t } = useTranslation();
  const items = useMemo(() => buildItems({ data, type }), [data, type]);
  const { Tabs, TabPanels, activeTab, resetActiveTab } = useTabs({ items });

  useEffect(() => {
    if (isOpen) {
      resetActiveTab();
    }
  }, [isOpen]);

  useEffect(() => {
    setDownload({
      name: items[activeTab]?.label,
      url: URL.createObjectURL(
        new Blob([items[activeTab]?.value], { type: MEDIA_TYPE.TYPE.TEXT.PLAIN.WITH_CHARSET }),
      ),
    });
  }, [activeTab, items]);

  return (
    <Dialog
      open={isOpen}
      title={
        type === FileDownloadDialogType.Log
          ? t('common:component.file_download_dialog.log_file')
          : t('common:component.file_download_dialog.config_files')
      }
      type={DialogType.DownloadFile}
      renderActions={
        <DialogActions
          leftButtons={
            <Button
              // @ts-ignore
              component="a"
              download={download.name}
              href={download.url}
              color="primary"
              variant="outlined"
            >
              {t('common:component.file_download_dialog.button.download_file')}
            </Button>
          }
          rightButtons={
            <Button color="primary" variant="contained" onClick={onClose}>
              {t('common:component.file_download_dialog.button.dismiss')}
            </Button>
          }
        />
      }
      onClose={onClose}
    >
      <EditPanel hasPadding={{ bottom: false, top: false }}>
        {Tabs}
        {TabPanels}
      </EditPanel>
    </Dialog>
  );
};

export default FileDownloadDialog;
