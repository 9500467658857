import * as utility from 'shared/utility';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledIconLinkProps as Props } from './definition';

const StyledIconLink = styled.a<Props>`
  ${(props) => css`
    ${mixin.flex({ justifyContent: 'space-between' })};
    ${mixin.font({ size: 12 })};
    width: 100%;
    --height: 24px;
    height: var(--height);
    line-height: var(--height);

    > span {
      &:first-of-type {
        ${mixin.transition({ property: 'color' })};
        display: inline-block;
        width: 100%;
        padding: 0 18px;
        color: ${props.theme.core.color.black};
        cursor: pointer;
        user-select: none;
        white-space: nowrap;

        &:hover {
          ${mixin.hover({ letterSpacing: -0.0135 })};
        }
      }

      &:last-of-type {
        ${mixin.transition({ property: 'transform' })};
        padding: 0 18px;
        border-left: 1px solid ${props.theme.core.background.grey.light.border};
        transform: ${`rotateX(${props.isIconRotated ? -180 : 0}deg)`};

        > svg {
          ${mixin.transition({ property: 'fill' })};
          fill: ${props.theme.core.background.grey.dark};
          transform: translateY(2px);
        }

        ${props.isIconDisabled
          ? css`
              > svg {
                fill: ${utility.alphafy(props.theme.core.background.grey.dark, 0.25)};
              }
            `
          : css`
              cursor: pointer;

              &:hover > svg {
                fill: ${props.theme.whitelabel.primary.color};
              }
            `}

        ${props.isIconRotated &&
        css`
          > svg {
            fill: ${props.theme.whitelabel.primary.color};
          }
        `}
      }
    }
  `};
`;

export default StyledIconLink;
