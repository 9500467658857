import { Component, createElement, ErrorInfo } from 'react';
import { ErrorBoundaryProps as Props, State } from './definition';

// We have to keep this as a class based component to access api 'componentDidCatch'
// until react natively provides the function based api
class ErrorBoundary extends Component<Props, State> {
  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    const onError = this.props.onError;
    this.setState({ error, info });

    if (onError) {
      onError({ error, info });
    }
  }

  render() {
    const { children, renderOnError } = this.props;
    const { error, info } = this.state;

    if (error) {
      const errorComponentProps = { error, info };

      if (!renderOnError) {
        return null;
      }

      if (typeof renderOnError === 'function') {
        return renderOnError(errorComponentProps);
      }

      return createElement(renderOnError, errorComponentProps);
    }

    return children;
  }
}

export default ErrorBoundary;
