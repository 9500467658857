import { EditFormProps } from 'apps/PhoneSystem/definition';
import { DevicePickerFormFields } from 'apps/shared/hooks/useDevicePicker';
import { CallRestriction } from 'definition';

export type DeviceEditProps = EditFormProps;
export type StyledDeviceEditProps = EditFormProps;

export enum FormFields {
  CallForwardDirectCallsOnly = 'call_forward.direct_calls_only',
  CallForwardEnabled = 'call_forward.enabled',
  CallForwardFailover = 'call_forward.failover',
  CallForwardKeepCallerId = 'call_forward.keep_caller_id',
  CallForwardNumber = 'call_forward.number',
  CallForwardRequireKeypress = 'call_forward.require_keypress',
  CallForwardSubstitute = 'call_forward.substitute',
  ContactListExclude = 'contact_list.exclude',
  DeviceType = 'device_type',
  Enabled = 'enabled',
  ExcludeFromQueues = 'exclude_from_queues',
  FollowMe = 'follow_me',
  IgnoreCompletedElsewhere = 'ignore_completed_elsewhere',
  MacAddress = 'mac_address',
  MediaFaxOption = 'media.fax.option',
  Name = 'name',
  ProvisionEndpointModel = 'provision.endpoint_model',
  RingtonesExternal = 'ringtones.external',
  RingtonesInternal = 'ringtones.internal',
  SipRoute = 'sip.route',
  SipUsername = 'sip.username',
  SuppressUnregisterNotifications = 'suppress_unregister_notifications',
  TempDeviceBrand = '_temp_device_brand',
  TempDeviceModel = '_temp_device_model',
}

export enum CodecSections {
  Audio = 'audio',
  Video = 'video',
}

export interface DeviceFormMap {
  [deviceType: string]: {
    [fieldName: string]: boolean;
  };
}

export type FormInput = {
  name: string;
  device_type: string;
  enabled: boolean;
  follow_me: boolean;
  presence_id: string;
  should_create_caller_id: boolean;
  outbound_flags: string[] | string;
  ignore_completed_elsewhere: boolean;
  exclude_from_queues: boolean;
  suppress_unregister_notifications: boolean;

  /** Deprecated fields */
  make?: string;
  model?: string;

  /** Temporary fields used only in RHF */
  [DevicePickerFormFields.TempDeviceBrand]?: string;
  [DevicePickerFormFields.TempDeviceModel]?: string;

  caller_id: {
    internal: {
      name: string;
      number: string;
    };
    external: {
      name: string;
      number: string;
    };
    emergency: {
      name: string;
      number: string;
    };
  };
  provision?: {
    endpoint_brand: string;
    endpoint_family: string;
    endpoint_model: string;
  };
  sip: {
    invite_format: string;
    expire_seconds: string;
    method: string;
    username: string;
    password: string;
  };
  mac_address?: string;
  media: {
    peer_to_peer: string;
    encryption: {
      methods?: string[] | null;
    };
    video: {
      codecs: string[];
    };
    audio: {
      codecs: string[];
    };
    fax: {
      option: boolean;
    };
  };
  music_on_hold: {
    media_id?: string;
  };
  call_restriction: CallRestriction;
  contact_list: {
    exclude: boolean;
  };
  ringtones: {
    internal: string;
    external: string;
  };
  extra_info: {
    provisioner: string;
  } | null;

  // when `device_type` value is 'landline'...
  call_forward?: {
    number?: string;
    direct_calls_only: boolean;
    enabled: boolean;
    failover: boolean;
    ignore_early_media: boolean;
    keep_caller_id: boolean;
    require_keypress: boolean;
    substitute: boolean;
  };
};
