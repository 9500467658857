import get from 'lodash/get';
import { useFetchAccountQuery } from 'models/Account';
import { useTranslation } from 'react-i18next';
import Typography from 'shared/components/Typography';
import { TeamChatEnabledCheckbox } from './components/TeamChatEnableCheckbox';
import { FormFields } from './definition';

const TeamChatEnablementSection = () => {
  const { data: accountData } = useFetchAccountQuery();
  const { t } = useTranslation();

  const initialTeamChatIsEnabled = get(accountData, FormFields.TeamChatIsEnabled);

  return (
    <>
      <Typography variant="h2">
        {t(
          'phone_system:containers.account.submodule.settings.section.team_chat_enablement.heading',
        )}
      </Typography>
      <Typography variant="inherit" sx={{ whiteSpace: 'pre-line' }}>
        {t(
          'phone_system:containers.account.submodule.settings.section.team_chat_enablement.subheading',
        )}
      </Typography>
      <TeamChatEnabledCheckbox initialTeamChatIsEnabled={initialTeamChatIsEnabled} />
    </>
  );
};

export default TeamChatEnablementSection;
