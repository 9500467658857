import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class DistinctiveRingActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.DISTINCTIVE_RING,
      module: CallFlowModuleKey.DISTINCTIVE_RING,
      section: CallFlowSection.ADVANCED,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'ringing-filled',
      },
      label: i18next.t('phone_system:containers.callflows.actions.distinctive_ring.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.distinctive_ring.tooltip'),
    });
  }

  static subtitle = (nodeData: any) => nodeData?.alert_info;
}

export default DistinctiveRingActionConfig;
