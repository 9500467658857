import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { ACTION_COMPOSE, VOICEMAIL } from '../../constants';

const schema = () => {
  return Joi.object({
    action: Joi.string().valid(ACTION_COMPOSE),
    callerid_match_login: Joi.boolean(),
    id: Joi.string().required(),
    interdigit_timeout: Joi.number(),
    max_message_length: Joi.number()
      .integer()
      .min(VOICEMAIL.MIN_MESSAGE_LENGTH)
      .max(VOICEMAIL.MAX_MESSAGE_LENGTH),
    nodeId: Joi.string(),
    single_mailbox_login: Joi.boolean(),
    advanced_route: Joi.boolean(),
    var: Joi.when(Joi.ref('advanced_route'), {
      is: Joi.boolean().valid(true),
      then: Joi.string().required(),
      otherwise: Joi.optional(),
    }),
  }).messages(messages());
};

export default schema;
