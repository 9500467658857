import { SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from 'constant';

export const getWindowUnit = (seconds: number) =>
  seconds % SECONDS_IN_HOUR === 0 ? 'hours' : 'minutes';

export const convertWindowToFormUnits = (seconds: number, unit: string = 'minutes') =>
  unit === 'minutes' ? seconds / SECONDS_IN_MINUTE : seconds / SECONDS_IN_HOUR;

export const convertWindowToSeconds = (seconds: number, unit: string = 'minutes') =>
  unit === 'minutes' ? seconds * SECONDS_IN_MINUTE : seconds * SECONDS_IN_HOUR;

export const extractWindowValueAndUnit = (windowSeconds: number = 0) => {
  if (!windowSeconds) return [undefined, undefined];
  const windowUnit = getWindowUnit(windowSeconds);
  const windowValue = convertWindowToFormUnits(windowSeconds, windowUnit);
  return [windowValue, windowUnit];
};
