import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import CertificateDownloadRow from '../CertificateDownloadRow';
import { PublicKeyRowProps as Props } from './definition';

const PublicKeyRow: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { publicKey, signRequest } = props;

  return (
    <CertificateDownloadRow
      signRequest={signRequest}
      label={t('phone_system:containers.account.submodule.settings.section.sso.public_key')}
      certificate={publicKey}
      fileName={t(
        'phone_system:containers.account.submodule.settings.section.sso.public_key_file_name',
      )}
    />
  );
};

export default PublicKeyRow;
