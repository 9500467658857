import { FieldErrors, FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { getDeepKeys } from 'shared/utility';

/**
 * @name checkFormEntitiesFromFields
 * @description Check either the form dirty fields list or error list from the field array
 *              and return true if the given list is populated.
 *
 * @param entities
 * @param fieldToCheck
 *
 * @returns Flag
 */
export const checkFormEntitiesFromFields = (
  entities: FieldErrors<FieldValues> | FieldNamesMarkedBoolean<FieldValues>,
  fieldToCheck: Array<string>,
  context = '',
) =>
  getDeepKeys(entities).filter((field: string) => `${context}${fieldToCheck}`.includes(field))
    .length > 0;
