import produce from 'immer';

export const prepareDataForSave = (data: any): any =>
  produce(data, (draft: any) => {
    const { default_callback_timeout, default_ringback_timeout } = draft?.flow?.data || {};
    if (default_callback_timeout === '' || default_ringback_timeout === '') {
      // Remove keys from the object if the fields are empty, allowing the backend to use its default system values
      if (default_callback_timeout === '') {
        delete draft.flow.data.default_callback_timeout;
      }
      if (default_ringback_timeout === '') {
        delete draft.flow.data.default_ringback_timeout;
      }
    }
  });
