import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import TEST_ID from 'shared/utility/testing/constants/testId';
import NavItem, { Item } from './components/NavItem';
import RightItems from './components/RightItems';
import defaultProps from './default';
import { HorizontalNavProps as Props } from './definition';
import StyledHorizontalNav from './style';

export { NAV_ITEM_CONSTANTS as HORIZONTAL_NAV_CONSTANTS } from './components/NavItem';

const HorizontalNav: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { hasIndent, items, rightItems } = {
    ...defaultProps,
    ...props,
  };
  const [activeItem, setActiveItem] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = (slug: string): void => {
    setActiveItem(slug);
    navigate(slug);
  };

  useEffect(() => {
    const slugs = items.map(({ slug }: Item): string => slug);
    const path = location.pathname.split('/');
    setActiveItem(path.find((pathElement) => slugs.includes(pathElement)));
  }, [items, location]);

  return (
    <StyledHorizontalNav
      hasIndent={hasIndent}
      data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-horizontal-nav`}
    >
      {items.map(
        ({ info, label, slug }: Item): JSX.Element => (
          <NavItem
            key={slug}
            isActive={activeItem === slug}
            label={t(label)}
            info={t(info as string)}
            slug={slug}
            onClick={() => handleClick(slug)}
          />
        ),
      )}
      {rightItems.length > 0 && <RightItems items={rightItems} />}
    </StyledHorizontalNav>
  );
};

export default HorizontalNav;
