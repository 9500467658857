import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { DRAWER_CONSTANTS } from 'shared/components/Drawer';
import { QUICKVIEW_CONSTANTS } from '../..';
import QuickViewDialog from '../QuickViewDialog';
import { QuickViewDialogType } from '../QuickViewDialog/definition';
import QuickViewCallflow from './components/QuickViewCallflow';
import { CALLFLOW_CONTAINER_CONSTANTS } from './components/QuickViewCallflow/components/CallflowContainer';
import { QuickViewContainerProps as Props, Width } from './definition';
import StyledQuickViewContainer from './style';
import { determineExpandedDelta } from './utility';

const QuickViewContainer: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    isMainDrawerOpen,
    callflowIds,
    expandedCallflowIds,
    onCallflowClosed,
    onEditClicked,
    setExpandedCallflowIds,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<Width>({ tab: '100%', total: '100%' });
  const refTabCount = useRef<number>(0);
  const refExpandedDelta = useRef<string[]>([]);
  const refHasExpandedChanged = useRef<boolean>(false);

  useEffect(() => {
    /**
     * count:      Total number of expanded quickview callflows
     * totalWidth: Calculate the total tab width
     * tabWidth:   Calculate the width of each tab (remember, the tabs are transformed -90 degrees), depending on the number of visible quickview callflows
     */
    refTabCount.current = callflowIds.length - expandedCallflowIds.length;
    const totalWidth = CALLFLOW_CONTAINER_CONSTANTS.HEIGHT.DEFAULT;
    const tabWidth =
      refTabCount.current > 1
        ? `calc(${totalWidth} / ${refTabCount.current} - ${DRAWER_CONSTANTS.SHADOW_OFFSET.HORIZONTAL}px)`
        : totalWidth;

    setWidth({ tab: tabWidth, total: totalWidth });
  }, [callflowIds, expandedCallflowIds]);

  const handleCollapse = (isOpen: boolean, callflowId: string): void => {
    setExpandedCallflowIds((ids: string[]) => {
      if (isOpen) {
        return ids.filter((id: string) => id !== callflowId);
      }

      if (ids.length < QUICKVIEW_CONSTANTS.MAXIMUM.EXPANDED) {
        return [...ids, callflowId];
      }

      setIsDialogOpen(true);
      return ids;
    });
  };

  determineExpandedDelta(callflowIds, expandedCallflowIds, refExpandedDelta, refHasExpandedChanged);

  return (
    <>
      <StyledQuickViewContainer>
        {callflowIds
          .sort(
            (a: string, b: string): number =>
              expandedCallflowIds.indexOf(b) - expandedCallflowIds.indexOf(a),
          )
          .map((callflowId: string, index: number) => {
            const isOpen = expandedCallflowIds.includes(callflowId);
            return (
              <QuickViewCallflow
                key={callflowId}
                hasExpandedChanged={refHasExpandedChanged.current}
                isMainDrawerOpen={isMainDrawerOpen}
                callflowId={callflowId}
                tab={{ isOpen, index, count: refTabCount.current, width: width.tab }}
                onClose={() => onCallflowClosed(callflowId)}
                onCollapse={() => handleCollapse(isOpen, callflowId)}
                onEditClicked={() => {
                  onCallflowClosed(callflowId);
                  onEditClicked();
                }}
              />
            );
          })}
      </StyledQuickViewContainer>
      <QuickViewDialog
        type={QuickViewDialogType.expanded}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default QuickViewContainer;
