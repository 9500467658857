import { useDroppable } from '@dnd-kit/core';
import { DialogAction } from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowActionsDialog/definition';
import { getCallflowActionConfig } from 'models/Callflow/actions';
import { CallFlowKey } from 'models/Callflow/definition';
import { DragSourceType, DropType } from 'models/Callflow/store/definition';
import { maybeShowActionDialog } from 'models/Callflow/store/slice';
import { FunctionComponent, memo, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Droppable from 'shared/components/Droppable';
import CallflowContext from '../../../../../CallflowContext';
import CallFlowPreview from './components/CallFlowPreview';
import FlowChildDelete from './components/FlowChildDelete';
import FlowChildHead from './components/FlowChildHead';
import FlowChildLabel from './components/FlowChildLabel';
import FlowChildSubtitle from './components/FlowChildSubtitle';
import defaultProps from './default';
import { FlowChildProps as Props } from './definition';
import StyledFlowChild, { StyledFlowChildBody } from './style';

const FlowChild: FunctionComponent<Props> = memo(
  (props: Props): JSX.Element => {
    const {
      node,
      node: {
        data: nodeData,
        data: { nodeId },
        module,
        isDroppable,
        key,
        isActivated: isDragging,
        actionName,
      },
      parentNode,
    }: Props = {
      ...defaultProps,
      ...props,
    };

    const {
      isPreview,
      callflow: { id: callflowId },
    } = useContext(CallflowContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [hasDeleteOptions, setHasDeleteOptions] = useState<boolean>(false);
    const [isHoverNode, setIsHoverNode] = useState<boolean>(false);
    const [isHoverAll, setIsHoverAll] = useState<boolean>(false);
    const [isNodeInvalid, setIsNodeInvalid] = useState<boolean>(false);
    const [isCallFlowDialogOpen, setIsCallFlowDialogOpen] = useState<boolean>(false);
    const deleteNodeRef = useRef<HTMLDivElement>(null);
    const callflow = getCallflowActionConfig(actionName);
    const { setNodeRef } = useDroppable({ id: callflowId });

    const shouldSkipCallFlow = actionName !== CallFlowKey.CALLFLOW;
    const hasHead = !!(key && parentNode && parentNode?.isMultinary);

    const droppableProps = {
      id: nodeId,
      disabled: !isDroppable || isPreview,
      data: {
        id: nodeId,
        source: DragSourceType.TREE,
        type: DropType.NODE,
      },
    };

    // click anywhere outside of the (open) delete options to trigger closing
    useEffect(() => {
      const handleDeleteOptionsClose = (e: any) => {
        if (deleteNodeRef?.current && !deleteNodeRef.current.contains(e.target)) {
          setHasDeleteOptions(false);
        }
      };
      document.addEventListener('mousedown', handleDeleteOptionsClose);
      return () => {
        document.removeEventListener('mousedown', handleDeleteOptionsClose);
      };
    }, [deleteNodeRef]);

    const handlePreview = (): void => {
      const action: DialogAction = { id: callflowId, data: nodeData, type: callflow.key };
      dispatch(maybeShowActionDialog(action));
    };

    return (
      <StyledFlowChild
        ref={setNodeRef}
        hasDeleteOptions={hasDeleteOptions}
        hasHead={hasHead}
        isDragging={isDragging}
        isDroppable={isDroppable}
        isHoverAll={isHoverAll}
        isHoverNode={isHoverNode}
        isNodeInvalid={isNodeInvalid}
      >
        {!shouldSkipCallFlow && isCallFlowDialogOpen && (
          <CallFlowPreview
            isCallFlowDialogOpen
            id={nodeData.id}
            setIsCallFlowDialogOpen={setIsCallFlowDialogOpen}
          />
        )}
        {!node ? (
          t('phone_system:containers.callflows.callflow_actions.module_unimplemented', { module })
        ) : (
          <Droppable {...droppableProps}>
            {hasHead && <FlowChildHead node={node} parentNode={parentNode} />}
            <StyledFlowChildBody isDragging={isDragging} isDroppable={isDroppable}>
              <FlowChildDelete
                callflowId={callflowId}
                node={node}
                isPreview={isPreview}
                deleteNodeRef={deleteNodeRef}
                hasDeleteOptions={hasDeleteOptions}
                setHasDeleteOptions={setHasDeleteOptions}
                setIsHoverAll={setIsHoverAll}
                setIsHoverNode={setIsHoverNode}
              />
              <FlowChildLabel node={node} handlePreview={handlePreview} isPreview={isPreview} />
              <FlowChildSubtitle
                node={node}
                handlePreview={handlePreview}
                setIsCallFlowDialogOpen={setIsCallFlowDialogOpen}
              />
            </StyledFlowChildBody>
          </Droppable>
        )}
      </StyledFlowChild>
    );
  },
);

export default FlowChild;
