import Edit from 'apps/PhoneSystem/containers/Media/Edit';
import { ADD_KEY } from 'constant';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogLink, DialogType } from '../../../../shared/components/Dialog';
import { DialogState } from './definition';

export const MediaSelector = ({
  hasCreateButton = false,
  hasEditButton = false,
  selectComponent,
  selectedId,
  onSave = (id) => {},
  onDelete = () => {},
}: {
  hasCreateButton?: boolean;
  hasEditButton?: boolean;
  selectComponent: ReactElement;
  selectedId: string;
  onSave?: (id: string) => void;
  onDelete?: () => void;
}) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<DialogState | false>(false);

  return (
    <>
      <div role="row" className="split split-with-link">
        <div role="cell">{selectComponent}</div>
        <div role="cell">
          {hasEditButton && <DialogLink onClick={() => setIsDialogOpen(DialogState.Edit)} />}
        </div>
      </div>

      {/* Create Media */}
      {hasCreateButton && (
        <div role="row" className="one-column is-stack">
          <div role="cell">
            <DialogLink
              indentWidth="large"
              text={t('phone_system:containers.menu.section.basics.create_media.label')}
              type="add"
              onClick={() => {
                setIsDialogOpen(DialogState.Create);
              }}
            />
          </div>
        </div>
      )}

      <Dialog
        open={!!isDialogOpen}
        title={
          isDialogOpen === 'create'
            ? t('phone_system:containers.callflows.callflow_action_dialog.media.add.create_media')
            : t('phone_system:containers.callflows.callflow_action_dialog.media.edit.edit_media')
        }
        type={DialogType.XLarge}
        onClose={() => setIsDialogOpen(false)}
      >
        <Edit
          id={isDialogOpen === 'edit' ? selectedId : ADD_KEY}
          isEmbedded
          handleCancel={() => setIsDialogOpen(false)}
          handleDeleteSuccess={() => {
            onDelete();
            setIsDialogOpen(false);
          }}
          handleSaveSuccess={(params) => {
            setIsDialogOpen(false);
            if (params?.id) {
              onSave(params.id);
            }
          }}
        />
      </Dialog>
    </>
  );
};
