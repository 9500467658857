import { KOSHER_APPS } from 'apps/shared/constant';
import { AppMeta, KosherApp } from 'apps/shared/definition';
import { APPS_META } from '../constant';

/**
 * @name getAppListing
 * @description Return a listing of available apps for the current account.
 *
 * @param data
 * @param language
 *
 * @returns Apps listing.
 */
export const getAppListing = (data: any, language: string) =>
  data && language
    ? data
        .filter(({ name }: { name: string }) =>
          KOSHER_APPS.some((app: KosherApp) => name === app.name && app.isEnabled),
        )
        .map((app: any) => {
          const meta = APPS_META.find((meta: AppMeta) => meta.name === app.name);

          if (meta && !meta.i18n && app?.i18n) {
            meta.i18n = app.i18n[language];
          }

          return meta;
        })
    : [];

/**
 * @name getAppListingSearch
 * @description Return a function that will search the listing of available apps.
 *
 * @param data
 *
 * @returns Search function.
 */
export const getAppListingSearch = (data: any = []) => (search: string) =>
  data
    .filter(({ i18n: { label } }: AppMeta) =>
      search !== '' ? label?.toLocaleLowerCase().includes(search?.toLocaleLowerCase()) : true,
    )
    .sort((a: AppMeta, b: AppMeta) => (a.i18n.label > b.i18n.label ? 1 : -1));
