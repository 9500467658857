export enum TabState {
  DIRTY = 'dirty',
  ERROR = 'error',
}
export interface FormProps {
  /** One of the render props of the EnhancedForm */
  defaultValues: Record<string, any>;
  /**
   * @description An objects that define the tabs for the form.
   * @usage Follow the `Tabs` type to pass in the object. The keys of the object must match the keys of the `formFields` prop.
   * @example
   * {
   *  basic: {
   *    hash: 'basic',
   *    label: 'Basic',
   *    component: <BasicSection />,
   *  },
   *  advanced: {
   *    hash: 'advanced',
   *    label: 'Advanced',
   *    component: <AdvancedSection />,
   *  },
   * }
   */
  tabs: Tabs;
  /**
   * @description Define the form fields for this form here that will be appeared in the UI and considered for dirty and error state handling.
   * @usage Accept an object of `{ [tab_name]: ['field_path_1','field_path_2','nested.field_path_1'] }`. The keys must match to the keys of `tabs` prop.
   * @example
   * {
   *  // [tab_name]: ['field_path_1','field_path_2','nested.field_path_1']
   *  basic: ['first_name'],
   *  advanced: ['sso_enabled', 'contact.number', 'contact.email']
   * }
   */
  formFields: FormFields;
}

interface Tab {
  hash: string;
  label: string;
  component: JSX.Element;
}

interface TabConfig {
  isDirty: boolean;
  isError: boolean;
}

export type Tabs = Record<string, Tab>;

// First type for simple form. Second type for nested form (i.e., forms with horizontal tabs)
type SimpleFormField = Record<string, string[]>;
export type FormFields = SimpleFormField;

export type TabsConfigs = Tabs & Record<string, TabConfig>;

export interface useEnsureFormDefaultValueParams {
  defaultValues: Record<string, any>;
  contextName: string;
  formFields: FormFields;
}
