import { URL } from 'constant';
import { useFetchDevicesQuery as useFetchAdvancedProvisionerDevicesQuery } from 'models/AdvancedProvisioner';
import { useFetchDevicesQuery, useFetchDeviceStatusesQuery } from 'models/Device';
import { useFetchUsersQuery } from 'models/User';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import { SubaccountDeviceAlert } from './components/SubaccountDeviceAlert';
import { useOnSearch } from './hooks/useOnSearch';
import { StyledTable } from './style';
import { columns, transformData } from './utility';

export const List = () => {
  const { data: devicesData, isLoading: isLoadingDevices } = useFetchDevicesQuery();
  const { data: usersData, isLoading: isLoadingUsers } = useFetchUsersQuery();
  const {
    data: apDevicesData,
    isLoading: isLoadingApDevices,
  } = useFetchAdvancedProvisionerDevicesQuery();
  const {
    data: deviceRegistrationsData,
    isLoading: isLoadingDeviceRegistrations,
  } = useFetchDeviceStatusesQuery();
  const {
    matchedSubaccountId,
    onSearch,
    setMatchedSubaccountId,
    isLoading: isLoadingSubaccountDeviceSearch,
  } = useOnSearch();

  const data = useMemo(() => transformData(apDevicesData, devicesData, usersData) ?? [], [
    apDevicesData,
    devicesData,
    usersData,
  ]);

  if (isLoadingApDevices || isLoadingDeviceRegistrations || isLoadingDevices || isLoadingUsers) {
    return <Loading />;
  }

  return (
    <StyledTable>
      <Table
        hasSearch
        hasPagination={{ rowsPerPage: true }}
        columns={columns(deviceRegistrationsData)}
        data={data}
        title={
          <Trans
            i18nKey="advanced_provisioner:containers.devices.table.title"
            components={{ span: <span /> }}
            values={{ url: URL.EXTERNAL.ADVANCED_PROVISIONER }}
          />
        }
        onSearch={(keyword: string) => onSearch({ data, keyword })}
        {...(matchedSubaccountId
          ? {
              renderNoItemsFooter: () => <></>,
              renderTablePostfix: (keyword) =>
                isLoadingSubaccountDeviceSearch ? (
                  <Loading />
                ) : (
                  <SubaccountDeviceAlert
                    accountId={matchedSubaccountId}
                    macAddress={keyword}
                    setMatchedSubaccountId={setMatchedSubaccountId}
                  />
                ),
            }
          : {})}
        {...(isLoadingSubaccountDeviceSearch
          ? {
              renderNoItemsFooter: () => <></>,
              renderTablePostfix: (keyword) => <Loading hasMargin />,
            }
          : {})}
      />
    </StyledTable>
  );
};
