import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import defaultProps from './default';
import { DaysOfWeekProps as Props } from './definition';
import StyledDaysOfWeek from './style';

export { defaultProps } from './default';

const DaysOfWeek: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onChange }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const WEEKDAYS = {
    sunday: {
      long: t('phone_system:containers.time_of_day.wdays.sunday.long'),
      short: t('phone_system:containers.time_of_day.wdays.sunday.short'),
    },
    monday: {
      long: t('phone_system:containers.time_of_day.wdays.monday.long'),
      short: t('phone_system:containers.time_of_day.wdays.monday.short'),
    },
    tuesday: {
      long: t('phone_system:containers.time_of_day.wdays.tuesday.long'),
      short: t('phone_system:containers.time_of_day.wdays.tuesday.short'),
    },
    wensday: {
      // kazoo spelling
      long: t('phone_system:containers.time_of_day.wdays.wednesday.long'),
      short: t('phone_system:containers.time_of_day.wdays.wednesday.short'),
    },
    thursday: {
      long: t('phone_system:containers.time_of_day.wdays.thursday.long'),
      short: t('phone_system:containers.time_of_day.wdays.thursday.short'),
    },
    friday: {
      long: t('phone_system:containers.time_of_day.wdays.friday.long'),
      short: t('phone_system:containers.time_of_day.wdays.friday.short'),
    },
    saturday: {
      long: t('phone_system:containers.time_of_day.wdays.saturday.long'),
      short: t('phone_system:containers.time_of_day.wdays.saturday.short'),
    },
  };

  const handleDayClick = (i: number): void => {
    const WEEKDAY_KEYS = Object.keys(WEEKDAYS);
    const days = [...data];
    const day = WEEKDAY_KEYS[i];
    days.includes(day) ? days.splice(days.indexOf(day), 1) : days.push(day);
    onChange(
      days.sort((a: string, b: string) => WEEKDAY_KEYS.indexOf(a) - WEEKDAY_KEYS.indexOf(b)),
    );
  };

  return (
    <StyledDaysOfWeek>
      {Object.entries(WEEKDAYS).map(
        ([key, label]: any[], i: number): JSX.Element => (
          <span
            key={key}
            className={data.includes(key) ? 'is-selected' : ''}
            title={label.long}
            onClick={() => handleDayClick(i)}
          >
            {label.short}
          </span>
        ),
      )}
    </StyledDaysOfWeek>
  );
};

export default DaysOfWeek;
