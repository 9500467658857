import { MediaType } from 'apps/PhoneSystem/definition';
import { CHARACTER } from 'constant';

/**
 * @name getInputValue
 * @description Get the input text value, based on the media source.
 *
 * @param data
 *
 * @returns Text value.
 */
export const getInputValue = (data: any): string => {
  let text = '';

  if (data.media_source === MediaType.Recording) {
    text = CHARACTER.EMDASH;
  }

  if (data.media_source === MediaType.Tts) {
    text = data.tts.text;
  }

  if (data.media_source === MediaType.Upload) {
    text = data.description?.split('\\')?.pop();
  }

  return text;
};
