import { useDraggable } from '@dnd-kit/core';
import { UseDraggableArguments } from '@dnd-kit/core/dist/hooks/useDraggable';
import { CSS } from '@dnd-kit/utilities';
import { FunctionComponent, PropsWithChildren } from 'react';

const Draggable: FunctionComponent<PropsWithChildren<UseDraggableArguments>> = (
  props: PropsWithChildren<UseDraggableArguments>,
) => {
  const { children, ...rest } = props;
  const { attributes, listeners, transform, setNodeRef } = useDraggable(rest);

  const style = transform ? { transform: CSS.Translate.toString(transform) } : {};

  return (
    <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
      {children}
    </div>
  );
};

export default Draggable;
