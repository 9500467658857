import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import Box from '../Box';

const StyledRadio = styled(Box)`
  ${({ theme }) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};
    margin: ${theme.spacing(0, 0, 1.33, -1.33)};
    transform: translateY(-1px);
    user-select: none;
  `};
`;

export default StyledRadio;
