import { ADD_KEY } from 'constant';
import { useFetchGroupsQuery } from 'models/Group';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import useUsersList from '../shared/hooks/useUsersList';
import { columns } from './utility';

const List = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: groupsData, isLoading } = useFetchGroupsQuery();
  const { usersList, isLoading: isLoadingUsersList } = useUsersList();

  const data = useMemo(
    () =>
      groupsData && usersList
        ? groupsData.map((group: Group) => ({
            ...group,
            endpointsCombined: usersList[group.id],
          }))
        : [],
    [groupsData, usersList],
  );

  if (isLoading || isLoadingUsersList) {
    return <Loading />;
  }

  return (
    <Table
      hasSearch
      columns={columns()}
      data={data}
      addButton={{
        hasAdd: true,
        label: t('phone_system:containers.groups.table.action_row.button.add_group.label'),
        onAdd: () => navigate(`./${ADD_KEY}`),
      }}
      title={t('phone_system:containers.groups.table.title')}
    />
  );
};

export default List;
