import { useShowMasqueradingToast } from 'apps/shared/hooks/useShowMasqueradingToast';
import { useFetchAccountByIdQuery } from 'models/Account';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Alert from 'shared/components/Alert';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import { onGoToAccount } from './utility';

export const SubaccountDeviceAlert = ({
  accountId,
  macAddress,
  setMatchedSubaccountId,
}: {
  accountId: string;
  macAddress: string;
  setMatchedSubaccountId: (value: string | undefined) => void;
}) => {
  const dispatch = useDispatch();
  const { showMasqueradingToast } = useShowMasqueradingToast();
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const { data: { name: accountName } = {} } = useFetchAccountByIdQuery(
    { id: accountId },
    { skip: !accountId },
  );

  return (
    <Box marginTop={2}>
      <Alert
        action={
          <Button
            color="info"
            onClick={() =>
              onGoToAccount({
                accountId,
                accountName,
                dispatch,
                macAddress,
                setMatchedSubaccountId,
                setSearchParams,
                showMasqueradingToast,
              })
            }
          >
            {t(
              'advanced_provisioner:containers.devices.table.subaccount_device_alert.action_label',
            )}
          </Button>
        }
        message={
          <Trans
            i18nKey="advanced_provisioner:containers.devices.table.subaccount_device_alert.message"
            values={{ accountName }}
          />
        }
        severity="info"
      />
    </Box>
  );
};
