import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import { FormFields } from '../../fields';

const UniqueIdClaimInput = () => {
  const { t } = useTranslation();
  return (
    <HookFormInputWrapper name={FormFields.UniqueIdClaim}>
      {({ ref, isDirty, feedback, ...formProps }) => (
        <LabeledInput
          id="input-unique-id-claim"
          isDirty={isDirty}
          feedback={feedback}
          label={t(
            'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.unique_id_claim.label',
          )}
          inputProps={{
            ...formProps,
            placeholder: t(
              'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.unique_id_claim.label',
            ),
          }}
          tooltip={t(
            'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.unique_id_claim.tooltip',
          )}
        />
      )}
    </HookFormInputWrapper>
  );
};

export default UniqueIdClaimInput;
