import { useFetchAccountChildrenByIdQuery } from 'models/Account';
import { Fragment } from 'react';
import IconLink from './components/IconLink';
import { AccountListProps as Props, ChildAccountListProps } from './definition';
import StyledAccountList from './style';

const ChildAccountList = ({
  currentLevel = 1,
  id,
  listOpenDepth,
  maxLevel,
  rootId,
  showDescendants,
  onClick,
  setChildParent,
  setListOpenDepth,
  setShowDescendants,
}: ChildAccountListProps) => {
  const { data: childData } = useFetchAccountChildrenByIdQuery({ id });

  return (
    <AccountList
      key={id}
      setShowDescendants={setShowDescendants}
      showDescendants={showDescendants}
      currentLevel={currentLevel}
      setListOpenDepth={setListOpenDepth}
      listOpenDepth={listOpenDepth}
      rootId={rootId}
      data={childData}
      onClick={onClick}
      isRoot={false}
      maxLevel={maxLevel}
      setChildParent={setChildParent}
      parentId={id}
    />
  );
};

const AccountList = ({
  isRoot,
  currentLevel = 0,
  data,
  listOpenDepth,
  maxLevel,
  parentId,
  rootId,
  showDescendants,
  onClick,
  setChildParent,
  setListOpenDepth,
  setShowDescendants,
}: Props) => {
  const handleToggleDescendants = (id: string, rootId: string) => {
    setListOpenDepth?.(rootId, currentLevel, id);
    setShowDescendants?.(id);
  };

  return (
    <StyledAccountList
      isRoot={isRoot}
      listDepth={rootId && listOpenDepth ? listOpenDepth[rootId] : 0}
      currentLevel={currentLevel}
      maxLevelOpened={maxLevel?.()}
    >
      {data && !!data.length && (
        <ul>
          {data?.map((child: any) => (
            <Fragment key={child.id}>
              <li>
                <IconLink
                  isIconDisabled={!child.descendants_count}
                  isIconRotated={showDescendants[child.id] === 'open'}
                  label={child.name}
                  onClick={{
                    icon: () => {
                      handleToggleDescendants(child.id, rootId ?? child.id);
                      setChildParent?.(child.id, rootId ?? '', data);
                    },
                    label: () => onClick(child, parentId ?? ''),
                  }}
                />
              </li>
              {child.descendants_count > 0 && showDescendants[child.id] === 'open' && (
                <div key={`${child.id}-decsendant-list`}>
                  <ChildAccountList
                    currentLevel={currentLevel ? currentLevel + 1 : 1}
                    id={child.id}
                    listOpenDepth={listOpenDepth}
                    maxLevel={maxLevel}
                    rootId={isRoot ? child.id : rootId}
                    showDescendants={showDescendants}
                    onClick={onClick}
                    setChildParent={setChildParent}
                    setListOpenDepth={setListOpenDepth}
                    setShowDescendants={setShowDescendants}
                  />
                </div>
              )}
            </Fragment>
          ))}
        </ul>
      )}
    </StyledAccountList>
  );
};

export default AccountList;
