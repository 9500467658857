import dialogDefaultProps from '../../default';
import { Data } from './definition';

export default { ...dialogDefaultProps };

export const defaultValues: Data = {
  endpoints: [],
  ignore_forward: true,
  name: '',
  repeats: '1',
  ringback: '',
  strategy: 'simultaneous',
  timeout: '20',
};

export const defaultEndpointValues = {
  delay: '0',
  timeout: '20',
};
