import { useNavigation } from 'apps/PhoneSystem/shared/useNavigation';
import Page from 'apps/shared/components/Page';
import { FunctionComponent, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import EditNumber from '../Numbers/containers/NumbersInUse/Edit';
import Edit from './containers/Edit';
import List from './containers/List';
import AssignedNumbersList from './containers/List/components/AssignedNumbersList';
import { SipTrunkingProps as Props } from './definition';

const SipTrunking: FunctionComponent<Props> = ({ label }: Props): JSX.Element => {
  const AssignedNumbersListWithNav = useNavigation(AssignedNumbersList);
  const LinkToListing = <Navigate to="/apps/sip-trunking" replace />;
  const EditNumberWithNav = useNavigation(EditNumber, 'numberId');
  const routes = useRoutes([
    {
      path: '/',
      children: [
        { index: true, element: <List /> },
        { path: ':id', element: <Edit /> },
        {
          path: 'assigned/',
          children: [
            { index: true, element: LinkToListing },
            {
              path: ':id',
              element: <AssignedNumbersListWithNav />,
            },
            {
              path: ':id/numbers_in_use/:sip/:numberId',
              element: <EditNumberWithNav />,
            },
          ],
        },

        { path: '*', element: LinkToListing },
      ],
    },
  ]);

  return (
    <Page label={label}>
      <Suspense fallback={<Loading />}>{routes}</Suspense>
    </Page>
  );
};
export default SipTrunking;
