import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledApplicationVariableForm = styled(Box)`
  ${({ theme }) => css`
    > div {
      ${mixin.flex({ justifyContent: 'flex-start' })};

      > div {
        margin: ${theme.spacing(0, 2, 2, 0)};

        .MuiInputLabel-root {
          display: none;
        }
      }

      > svg {
        margin: ${theme.spacing(-2, 0, 0)};
      }
    }
  `}
`;
