import i18next from 'i18next';

const translations = () => ({
  columns: {
    delay: {
      header: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.ring_group.selected.delays',
      ),
    },
    name: {
      header: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.ring_group.selected.ring_group',
      ),
    },
    timeout: {
      header: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.ring_group.selected.timeouts',
      ),
    },
  },
  confirmations: {
    deviceExists: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.ring_group.table_selector.confirmation_dialog.message.device_exists',
    ),
    ownerExists: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.ring_group.table_selector.confirmation_dialog.message.owner_exists',
    ),
  },
  name: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.ring_group.name.label',
    ),
  },
  repeats: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.ring_group.repeats.label',
    ),
  },
  ringback: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.ring_group.ringback.label',
    ),
    options: [{ value: '', label: i18next.t('common:default') }],
  },
  strategy: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.ring_group.strategy.label',
    ),
  },
  strategies: [
    {
      value: 'simultaneous',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.ring_group.strategies.simultaneous',
      ),
    },
    {
      value: 'single',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.ring_group.strategies.single',
      ),
    },
  ],
  title: i18next.t('phone_system:containers.callflows.callflow_action_dialog.ring_group.title'),
});

export default translations;
