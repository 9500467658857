import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import { SaveAndRebootProps as Props } from './definition';

export const SaveAndRebootDialog = ({
  isOpen = false,
  isReminderMode,
  onClose,
  rebootHandler,
  saveHandler,
}: Props) => {
  const { t } = useTranslation();

  const handleDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        if (!isReminderMode) {
          await saveHandler?.();
        }
        onClose?.();
        break;
      case 'saveClicked':
        await saveHandler?.();
        if (!isReminderMode) {
          await rebootHandler?.();
        }
        onClose?.();
        break;
      default:
        // Handle any other cases here
        break;
    }
  };

  const dialogLabels = useMemo(
    () =>
      isReminderMode
        ? {
            title: t('advanced_provisioner:dialog.save_and_reboot_reminder.title'),
            content: t('advanced_provisioner:dialog.save_and_reboot_reminder.content'),
            saveButtonLabel: t(
              'advanced_provisioner:dialog.save_and_reboot_reminder.save_button.label',
            ),
            cancelButtonLabel: t(
              'advanced_provisioner:dialog.save_and_reboot_reminder.cancel_button.label',
            ),
          }
        : {
            title: t('advanced_provisioner:dialog.save_and_reboot.title'),
            content: t('advanced_provisioner:dialog.save_and_reboot.content'),
            saveButtonLabel: t('advanced_provisioner:dialog.save_and_reboot.save_button.label'),
            cancelButtonLabel: t('advanced_provisioner:dialog.save_and_reboot.cancel_button.label'),
          },
    [isReminderMode, t],
  );

  return (
    <Dialog
      open={isOpen}
      title={dialogLabels.title}
      renderActions={
        <DialogActions
          saveButtonProps={{
            color: 'success',
            label: dialogLabels.saveButtonLabel,
            variant: 'contained',
          }}
          onAction={(closeResponse) => handleDialogAction(closeResponse)}
          cancelLabel={dialogLabels.cancelButtonLabel}
        />
      }
      onClose={onClose}
    >
      <Box component="span">{dialogLabels.content}</Box>
    </Dialog>
  );
};
