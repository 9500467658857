import { FieldComponent, PlacesData } from './definition';

type AddressComponents = {
  types: Array<string>;
};

/**
 * The *_ADDRESS_COMPONENT_ORDER constants state the order of the
 * street/extended address fields that are likely to be accepted as
 * the e911 address. Be cautious about changing the order.
 */
const STREET_ADDRESS_COMPONENT_ORDER = ['street_number', 'route'];
const EXTENDED_ADDRESS_COMPONENT_ORDER = ['subpremise'];

export const generateFieldData = (
  placeResult: PlacesData,
  fieldsComponentMapping: Array<FieldComponent>,
): Array<{ fieldName?: string; value?: string }> =>
  fieldsComponentMapping.map(({ fieldName, hasShortName = false, valueProvider, type }) => {
    let value;

    if (typeof valueProvider === 'function') {
      value = valueProvider(placeResult, type as string);
    } else {
      const { long_name, short_name } =
        placeResult?.address_components?.find(({ types }) => types?.includes(valueProvider)) || {};
      value = hasShortName ? short_name : long_name;
    }

    return { fieldName, value };
  });

export const addressProvider = (placeResult: PlacesData, type: string): string => {
  const ADDRESS_COMPONENT_ORDER =
    type === 'extended_address' ? EXTENDED_ADDRESS_COMPONENT_ORDER : STREET_ADDRESS_COMPONENT_ORDER;

  return placeResult?.address_components
    ?.filter(({ types }: AddressComponents) =>
      ADDRESS_COMPONENT_ORDER.some((value: string) => types.includes(value)),
    )
    .sort(
      ({ types: typeA }: AddressComponents, { types: typeB }: AddressComponents) =>
        ADDRESS_COMPONENT_ORDER.indexOf(typeA[0]) - ADDRESS_COMPONENT_ORDER.indexOf(typeB[0]),
    )
    .map(({ long_name }: { long_name: string }) => long_name)
    .join(' ');
};
