import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import isEmpty from 'lodash/isEmpty';
import { getCallflowActionConfig } from 'models/Callflow/actions';
import { CallFlowKey } from 'models/Callflow/definition';
import { DragSourceType } from 'models/Callflow/store/definition';
import {
  disableTreeDestinations,
  enableTreeDestinations,
  maybeShowActionDialog,
  updateTreeOrder,
} from 'models/Callflow/store/slice';
import { FunctionComponent, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import ActionContainer from '../ActionContainer';
import Action from '../ActionContainer/components/Sections/components/Section/components/Actions/components/Action';
import CallflowContext from '../CallflowContext';
import FlowContainer from '../FlowContainer';
import { DragAndDropContainerProps as Props } from './definition';

const DragAndDropContainer: FunctionComponent<Props> = ({ refZoom }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );
  const {
    callflow,
    callflow: { id },
  } = useContext(CallflowContext);
  const [activeNode, setActiveNode] = useState<any>(null);
  const [isOver, setIsOver] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (e: DragStartEvent) => {
    const { current: active } = e.active.data;
    const activeNodeId = active?.id;

    let node = null;
    setIsDragging(true);

    if (active?.source === DragSourceType.TREE) {
      node = {
        type: DragSourceType.TREE,
        ...callflow.nodes[activeNodeId],
      };
    }

    if (active?.source === DragSourceType.ACTION) {
      node = {
        type: DragSourceType.ACTION,
        actionName: e.active.id,
        action: active.action,
      };
    }

    if (node) {
      setActiveNode(node);
      return dispatch(enableTreeDestinations({ id, activeNode: node }));
    }
  };

  const handleDragEnd = (e: DragEndEvent) => {
    dispatch(disableTreeDestinations({ id }));
    setActiveNode(null);
    setIsDragging(false);

    // Dropped outside a dropzone
    if (!e.over) {
      return;
    }

    const { current: active } = e.active.data;
    const { current: over } = e.over.data;
    const activeNodeId = active?.id;
    const targetNodeId = over?.id;

    if (active?.source === DragSourceType.TREE) {
      return dispatch(updateTreeOrder({ id, activeNodeId, targetNodeId, type: over?.type }));
    }

    if (active?.source === DragSourceType.ACTION) {
      const actionName = e.active.id as CallFlowKey;
      const action = getCallflowActionConfig(actionName);
      const type = action.key;

      return dispatch(
        maybeShowActionDialog({ id, data: { action, targetNodeId, type: over?.type }, type }),
      );
    }
  };

  const handleDragOver = (e: DragOverEvent) => {
    setIsOver(!isEmpty(e.over));
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      modifiers={[snapCenterToCursor]}
      sensors={sensors}
    >
      <FlowContainer refZoom={refZoom} />
      <ActionContainer isDragging={isDragging} />
      <DragOverlay>
        {activeNode && activeNode.type === DragSourceType.ACTION && (
          <Action isOver={isOver} actionData={activeNode.action} />
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default DragAndDropContainer;
