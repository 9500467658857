import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledForgotInfoProps as Props } from './definition';

const StyledForgotInfo = styled(Box)<Props>`
  ${({ theme }) => css`
    ${mixin.flex({ justifyContent: 'space-between' })};
    margin: ${theme.spacing(2, 0, 3)};
  `}
`;

export default StyledForgotInfo;
