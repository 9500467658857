import { meta as metaSpareNumbers } from 'apps/Numbers/containers/SpareNumbers';
import { meta as appMeta } from 'apps/Numbers/meta';
import { BuyNumbers } from 'apps/shared/components/BuyNumbers';
import { useFetchAccountQuery, useFetchRootAccountQuery } from 'models/Account';
import { FunctionComponent } from 'react';
import AddNumbers from './components/AddNumbers';
import DownloadIcon from './components/DownloadIcon';
import { UpperActionRowProps as Props } from './definition';
import StyledUpperActionRow from './styles';

const UpperActionRow: FunctionComponent<Props> = ({ origin, setOrigin }: Props): JSX.Element => {
  const { data: activeAccountData } = useFetchAccountQuery();
  const { data: rootAccountData } = useFetchRootAccountQuery();

  const isRootAccountSuperAdmin = rootAccountData?.superduper_admin;
  const isRootAccountAllowedToAddNumbers = rootAccountData?.wnm_allow_additions;
  const isActiveAccountAllowedToAddNumbers = activeAccountData?.wnm_allow_additions;

  const showAddNumbers = isRootAccountSuperAdmin
    ? isRootAccountAllowedToAddNumbers
    : isActiveAccountAllowedToAddNumbers;

  return (
    <StyledUpperActionRow>
      <DownloadIcon />
      {showAddNumbers && <AddNumbers origin={metaSpareNumbers.slug} />}
      <BuyNumbers
        flowOnCompleteRoute={`/apps/${appMeta.slug}/${metaSpareNumbers.slug}`}
        origin={origin}
        setOrigin={setOrigin}
      />
    </StyledUpperActionRow>
  );
};

export default UpperActionRow;
