export const getClassName = (
  isFormElement: boolean,
  isHeaderElement: boolean,
  isOpen: boolean,
  color: string,
) => {
  const className = {
    button: {
      base: 'MuiButton-dropdown',
      element: {
        form: isFormElement ? ' MuiButton-dropdown-form-element' : '',
        header: isHeaderElement ? ' MuiButton-dropdown-header-element' : '',
      },
      open: isOpen ? ' MuiButton-dropdown-open' : '',
      get value() {
        return `${className.button.base}${className.button.element.form}${className.button.element.header}${className.button.open}`;
      },
    },
    item: {
      base: 'MuiMenuItem-',
      element: {
        form: !isHeaderElement ? `color-${isFormElement ? 'default' : color}` : '',
        header: isHeaderElement ? 'header' : '',
      },
      get selected() {
        return ` ${className.item.base}selected`;
      },
      get value() {
        return `${className.item.base}${className.item.element.form}${className.item.element.header}`;
      },
    },
  };

  return className;
};
