import { Justify } from 'shared/components/Table/components/definition';
import { css, FlattenSimpleInterpolation } from 'styled-components';

/**
 * @name justify
 * @description Mixin generating justification/alignment classes and properties.
 *
 * @param [{ isFlex, isHeader }={}]
 *
 * @returns CSS classes
 */
export const justify = ({
  isFlex,
  isHeader,
}: { isFlex?: boolean; isHeader?: boolean } = {}): FlattenSimpleInterpolation => {
  const property = isFlex ? 'justify-content' : 'text-align';

  const alignSpan = (isHeader: boolean | undefined, align: string) => css`
    ${isHeader &&
    css`
      > span:last-of-type {
        text-align: ${align};
      }
    `};
  `;

  return css`
    ${`&.${Justify.Center}`} {
      ${isFlex ? `${property}: center` : `${property}: center`};
      ${alignSpan(isHeader, 'center')};
    }

    ${`&.${Justify.Left}`} {
      ${isFlex ? `${property}: flex-start` : `${property}: left`};
      ${alignSpan(isHeader, 'left')};
    }

    ${`&.${Justify.Right}`} {
      ${isFlex ? `${property}: flex-end` : `${property}: right`};
      ${alignSpan(isHeader, 'right')};
    }
  `;
};
