import styled, { css } from 'styled-components';
import { StyledListProps as Props } from './definition';

const StyledList = styled.ul<Props>`
  ${({ theme }) => css`
    padding: 0 0 0 ${theme.spacing(2.3333)};
  `};
`;

export default StyledList;
