import produce from 'immer';

export const transformDataForSaving = (data: any) => {
  if (!data) {
    return data;
  }

  return produce(data, (draft: any) => {
    draft.media.exit_media = !draft.suppress_media;
    draft.media.invalid_media = !draft.suppress_media;
    draft.media.transfer_media = !draft.suppress_media;
  });
};
