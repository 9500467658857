import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledListItemUpperProps as Props } from './definition';

export const CONSTANTS = {
  HEIGHT: '36px',
};

const StyledListItemUpper = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};
    ${mixin.transition({ property: 'color', duration: 'fast' })};
    position: relative;
    padding: 0 ${props.theme.spacing(3)} 0 ${props.theme.spacing(1)};
    background-color: inherit;
    cursor: pointer;
    user-select: none;
    z-index: 10;

    &,
    &::before {
      height: ${CONSTANTS.HEIGHT};
    }

    &::before {
      ${mixin.borderRadius({ radius: 'half' })};
      ${mixin.transition({ property: 'opacity' })};
      content: '';
      width: 5px;
      margin: 0 8px 0 0;
      background-color: ${props.theme.core.color.red};
      z-index: 10;
      opacity: 0;
    }

    > svg {
      ${mixin.transition({ property: 'opacity', duration: 'fast' })};
      opacity: 0;
      margin: 0 0 0 auto;
    }

    ${props.isOpen
      ? css`
          ${mixin.hover()};
          margin: 0 auto 0 0;
          cursor: default;

          &::before,
          svg {
            opacity: 1;
          }

          &:hover {
            color: ${props.theme.core.color.black};
          }
        `
      : css`
          svg {
            transform: rotate(180deg);
          }

          &:hover {
            color: ${props.theme.core.color.red};

            svg {
              opacity: 1;
            }
          }
        `}
  `};
`;

export default StyledListItemUpper;
