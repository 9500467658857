import { joiResolver } from '@hookform/resolvers/joi';
import { metaPhoneSystem } from 'apps/meta';
import PasswordResetDialog from 'apps/shared/components/PasswordResetDialog';
import VersionTag from 'apps/shared/components/VersionTag';
import { setCurrentAccount } from 'apps/shared/utility/account';
import { LOCAL_STORAGE } from 'constant';
import md5 from 'crypto-js/md5';
import { StatusCodes } from 'http-status-codes';
import get from 'lodash/get';
import {
  clearAnnouncementData,
  clearE911NotificationData,
  clearRememberMeAuthData,
  setCredentials,
  setLocalStorageAuth,
  setRememberMeAuthData,
  useLoginMutation,
  useResetMutation,
} from 'models/Auth';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import Loading from 'shared/components/Loading';
import LoginForm from './components/LoginForm';
import loginSchema from './components/LoginForm/schema';
import Logo from './components/Logo';
import ResetForm from './components/ResetForm';
import resetSchema from './components/ResetForm/schema';
import { defaultLoginValues, defaultResetValues } from './default';
import {
  DialogType,
  FormInput,
  LoginError,
  LoginProps as Props,
  ResetFormInput,
  ResetSubmitData,
} from './definition';
import StyledLogin from './style';

export const DEFAULT_PATH = `/apps/${metaPhoneSystem.slug}`;

const Login: FunctionComponent<Props> = (): JSX.Element => {
  const [isLoginVisible, setIsLoginVisible] = useState<boolean>(true);
  const [isPasswordResetSent, setIsPasswordResetSent] = useState<boolean>(false);
  const [isRemembered, setIsRemembered] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>();
  const [login, { isLoading: isLoginLoading, error, reset: resetLoginError }] = useLoginMutation();
  const [
    reset,
    { isLoading: isResetLoading, isError: isResetError, error: resetError, reset: resetResetError },
  ] = useResetMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const errors = {
    no_privilege: {
      title: t('common:auth.no_privilege.title'),
      message: t('common:auth.no_privilege.message'),
      cancel_label: t('common:dismiss'),
    },
    sso_enabled: {
      title: t('common:auth.sso_enabled.title'),
      message: t('common:auth.sso_enabled.message'),
      cancel_label: t('common:dismiss'),
    },
    sso_enabled_reset: {
      title: t('common:auth.sso_enabled_reset.title'),
      message: t('common:auth.sso_enabled_reset.message'),
      cancel_label: t('common:dismiss'),
    },
  };
  const [dialogText, setDialogText] = useState<DialogType>(errors.no_privilege);

  const loginFormMethods = useForm<FormInput>({
    defaultValues: defaultLoginValues,
    resolver: joiResolver(loginSchema()),
  });

  const resetFormMethods = useForm<ResetFormInput>({
    defaultValues: defaultResetValues,
    resolver: joiResolver(resetSchema()),
  });

  const { handleSubmit: handleLoginSubmitMethod, reset: resetLoginFormMethod } = loginFormMethods;
  const { handleSubmit: handleResetSubmitMethod, reset: resetResetFormMethod } = resetFormMethods;

  useEffect(() => {
    const username = localStorage.getItem(LOCAL_STORAGE.AUTH.USERNAME) || '';
    const account_name = localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_NAME) || '';
    const rememberMeAuthData = {
      ...defaultLoginValues,
      username,
      account_name,
    };
    resetLoginFormMethod(rememberMeAuthData);
  }, [resetLoginFormMethod]);

  useEffect(() => {
    setDialogText(errors.no_privilege);

    if (error && 'data' in error) {
      const { message } = error?.data as LoginError;
      const { status } = error;

      if (status === StatusCodes.FORBIDDEN) {
        setDialogText(errors.sso_enabled);
        setIsDialogOpen(true);
      } else {
        setErrMsg(message);
      }
    }
    if (resetError && 'data' in resetError) {
      const { message } = resetError?.data as LoginError;
      const { status } = resetError;

      if (status === StatusCodes.FORBIDDEN) {
        setDialogText(errors.sso_enabled_reset);
        setIsDialogOpen(true);
      } else {
        setErrMsg(message);
      }
    }
    if (isLoginVisible) {
      setIsPasswordResetSent(false);
    }
  }, [error, isLoginVisible, resetError]);

  const handleLoginSubmit = async (data: FormInput) => {
    const user = await login({
      body: {
        account_name: data.account_name,
        credentials: md5(`${data.username}:${data.password}`).toString(),
      },
    }).unwrap();

    const userPrivilegeLevel = get(user, 'data.priv_level');

    if (userPrivilegeLevel != 'admin') {
      resetLoginFormMethod(defaultLoginValues);
      setIsDialogOpen(userPrivilegeLevel !== 'admin');
      return;
    }

    setLocalStorageAuth({
      auth_token: user.auth_token,
      user_id: user.data.owner_id,
      account_id: user.data.account_id,
    });

    clearE911NotificationData();
    clearAnnouncementData();

    if (isRemembered) {
      setRememberMeAuthData({
        username: data.username,
        account_name: data.account_name,
      });
    } else {
      clearRememberMeAuthData();
    }

    dispatch(setCredentials(user));
    setCurrentAccount(dispatch, user.data.account_id);
    navigate(DEFAULT_PATH);
  };

  const handleResetSubmit = async (data: ResetSubmitData) => {
    await reset({
      body: {
        account_name: data.reset_account_name,
        username: data.reset_username,
        ui_url: `${window.location.origin}/login`,
      },
    }).unwrap();

    if (!isResetError) {
      setIsPasswordResetSent(true);
    }
  };

  const resetForms = (isLoginVisible: boolean) => {
    resetLoginFormMethod(defaultLoginValues);
    resetResetFormMethod(defaultResetValues);
    resetLoginError();
    resetResetError();
    setErrMsg(undefined);
    setIsLoginVisible(isLoginVisible);
  };

  const handleDialogAction = async (isLoginVisible: boolean) => {
    resetForms(isLoginVisible);
    setIsDialogOpen(false);
  };

  let content = isLoginVisible ? (
    <FormProvider {...loginFormMethods}>
      <LoginForm
        error={errMsg}
        onSubmit={handleLoginSubmitMethod(handleLoginSubmit)}
        onClick={resetForms}
        onClickRememberMe={() => setIsRemembered(!isRemembered)}
      />
    </FormProvider>
  ) : (
    <FormProvider {...resetFormMethods}>
      <ResetForm
        isResetConfirmation={isPasswordResetSent}
        error={errMsg}
        onSubmit={handleResetSubmitMethod(handleResetSubmit)}
        onClick={resetForms}
      />
    </FormProvider>
  );

  if (isLoginLoading || isResetLoading) content = <Loading />;

  return (
    <>
      <StyledLogin>
        <div>
          <Logo />
        </div>
        <div>{content}</div>
        <div>
          <VersionTag hasAuxColor />
        </div>
      </StyledLogin>
      <Dialog
        hasClose={false}
        open={isDialogOpen}
        title={dialogText.title}
        renderActions={
          <DialogActions
            hasCancelOnly
            cancelLabel={dialogText.cancel_label}
            onAction={handleDialogAction.bind(null, isLoginVisible)}
          />
        }
      >
        <p>{dialogText.message}</p>
      </Dialog>
      <PasswordResetDialog />
    </>
  );
};

export default Login;
