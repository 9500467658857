import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledDownloadButton = styled.a`
  ${(props) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};
    color: inherit;
    text-decoration: none;

    svg {
      margin: 0 ${props.theme.spacing(2)} 0 0;
    }
  `}
`;
