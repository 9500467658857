import { FunctionComponent } from 'react';
import Labeled from 'shared/components/Labeled';
import * as utility from 'shared/utility';
import DaysOfWeek from './components/DaysOfWeek';
import defaultProps from './default';
import { LabeledDaysOfWeekProps as Props } from './definition';
import StyledLabeledDaysOfWeek from './style';

const LabeledDaysOfWeek: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    id,
    hasDirtyIcon,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    daysOfWeekProps,
    feedback,
    tooltip,
  }: Props = {
    ...defaultProps,
    ...props,
  };
  const forId = id || utility.generateId('daysofweek');

  return (
    <Labeled
      id={forId}
      type="input"
      hasDirtyIcon={hasDirtyIcon}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={labelProps}
      labelWidth={labelWidth}
      inputWidth="large"
      feedback={feedback}
      tooltip={tooltip}
      styledComponent={StyledLabeledDaysOfWeek}
    >
      <DaysOfWeek id={forId} {...daysOfWeekProps} />
    </Labeled>
  );
};

export default LabeledDaysOfWeek;
