import { ExitConfirmationDialogContext } from 'apps/store/contexts';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'shared/hooks/useToast';
import { FormNavigationHandlers, OnSaveSuccessParam } from './definition';

export const useFormNavigation = (): FormNavigationHandlers => {
  const { showToast } = useToast();
  const navigate = useNavigate();

  const exitConfirmationDialogCtx = useContext(ExitConfirmationDialogContext);

  return {
    onCancelSuccess: () => {
      navigate('..');
    },
    onDeleteSuccess: () => {
      showToast.delete.success();
      exitConfirmationDialogCtx.onIsDirtyChange?.(false); // Clear the nav blocker
      navigate('..');
    },
    onSaveSuccess: ({ shouldRedirect = false, navigationOverride }: OnSaveSuccessParam = {}) => {
      showToast.success();

      if (shouldRedirect) {
        exitConfirmationDialogCtx.onIsDirtyChange?.(false); // Clear the nav blocker
        if (navigationOverride) {
          navigate(navigationOverride.path, navigationOverride.options);
        } else {
          navigate('..');
        }
      }
    },
  };
};
