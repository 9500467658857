const defaults = {
  tableData: [],
  phoneNumbers: [],
  usersData: [],
  isCreateDialogOpen: false,
  selectedPhoneNumber: '',
  handlePhoneNumberSelect: () => {},
  handleCreateDialogOpen: () => {},
  handleCreateDialogClose: () => {},
  handleCreateDialogAction: async () => {},
  hasActionRow: false,
};

export default defaults;
