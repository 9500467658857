import { joiResolver } from '@hookform/resolvers/joi';
import CallflowActionsDialog from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowActionsDialog/components/CallflowActionDialog';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchTimeOfDayQuery } from 'models/TimeOfDay';
import { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import { DEFAULT_KEY } from '../../constants';
import defaultProps from './default';
import { TimeOfDayKeyDialogForm, TimeOfDayKeyDialogProps as Props } from './definition';
import schema from './schema';

const TimeOfDayKeyDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose } = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const [timeOfDayOptions, setTimeOfDayOptions] = useState<SelectOption[]>([]);
  const { data: timeOfDayData, isLoading } = useFetchTimeOfDayQuery();
  const { control, handleSubmit, formState } = useForm<TimeOfDayKeyDialogForm>({
    mode: 'onChange',
    defaultValues: {
      key: data?.key ? data.key : DEFAULT_KEY,
    },
    resolver: joiResolver(schema()),
  });
  const defaultSelectOptions = [
    {
      value: DEFAULT_KEY,
      label: t('phone_system:containers.callflows.select_key_dialog.time_of_day.options.default'),
    },
    ...timeOfDayOptions,
  ];
  const { isDirty } = formState;

  useEffect(() => {
    if (timeOfDayData) {
      setTimeOfDayOptions(
        timeOfDayData.map((timeOfDay) => ({ value: timeOfDay.id, label: timeOfDay.name })),
      );
    }
  }, [timeOfDayData]);

  const submitHandler = async (formData: TimeOfDayKeyDialogForm) => {
    onSave(formData.key, isDirty);
  };

  return (
    <CallflowActionsDialog
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.select_key_dialog.time_of_day.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper name="key" control={control} options={defaultSelectOptions}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.select_key_dialog.time_of_day.key_select.label',
            )}
            labelWidth="small"
            inputWidth="small"
            selectProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default TimeOfDayKeyDialog;
