import { ACTION_DIRECT_PARK } from '../../constants';
import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  action: ACTION_DIRECT_PARK,
  custom_presence_id: false,
  max_slot_number: '0',
  slots: {},
};
