import { SelectOption } from '../../../Select';

/**
 * @name getOptionByValue
 * @description Get the filter option by value.
 *
 * @property filterOptions
 * @property value
 *
 * @returns Filter option
 */
export const getOptionByValue = (options: Array<SelectOption>, value: string) =>
  options.find((option: SelectOption) => option.value === value);
