import * as mixin from 'shared/utility/mixin';
import styled from 'styled-components';
import { StyledDrawerProps as Props } from './definition';

const StyledDrawer = styled.div<Props>`
  ${mixin.borderRadius()};
  ${mixin.flex()};
  ${mixin.transition([
    { property: 'left', duration: 'fastest' },
    { property: 'width', duration: 'fastest' },
  ])};
  overflow: hidden;
`;

export default StyledDrawer;
