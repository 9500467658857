import { CHARACTER } from 'constant';
import i18next from 'i18next';
import { AgentCardMenuItemType, AgentCardStatusType } from './definition';

/**
 * @name getConstants
 * @description Get the menu/status constants.
 *
 * @param onClick
 *
 * @returns Constants object
 */
export const getConstants = (onClick: Record<string, () => void>) => ({
  menu: {
    [AgentCardMenuItemType.LOG_IN_AGENT]: {
      icon: 'log-in',
      label: i18next.t('call_center:containers.dashboard.component.agent_card.menu.log_in_agent'),
      onClick: onClick.logInAgent,
    },
    [AgentCardMenuItemType.LOG_OUT_AGENT]: {
      icon: 'log-out',
      label: i18next.t('call_center:containers.dashboard.component.agent_card.menu.log_out_agent'),
      onClick: onClick.logOutAgent,
    },
    [AgentCardMenuItemType.RESTART_AGENT]: {
      icon: 'cached',
      label: i18next.t('call_center:containers.dashboard.component.agent_card.menu.restart_agent'),
      onClick: onClick.restartAgent,
    },
  },
  status: {
    [AgentCardStatusType.CONNECTED]: {
      icon: 'call-filled',
      label: {
        content: i18next.t(
          'call_center:containers.dashboard.component.agent_card.status.connected.content',
        ),
        header: i18next.t(
          'call_center:containers.dashboard.component.agent_card.status.connected.header',
        ),
      },
      menu: [AgentCardMenuItemType.RESTART_AGENT, AgentCardMenuItemType.LOG_OUT_AGENT],
    },
    [AgentCardStatusType.CONNECTING]: {
      icon: 'ringing-filled',
      label: {
        content: CHARACTER.EMDASH,
        header: i18next.t(
          'call_center:containers.dashboard.component.agent_card.status.connecting',
        ),
      },
      menu: [AgentCardMenuItemType.RESTART_AGENT, AgentCardMenuItemType.LOG_OUT_AGENT],
    },
    [AgentCardStatusType.LOGGED_IN]: {
      icon: '',
      label: {
        content: CHARACTER.EMDASH,
        header: i18next.t('call_center:containers.dashboard.component.agent_card.status.logged_in'),
      },
      menu: [AgentCardMenuItemType.LOG_IN_AGENT],
    },
    [AgentCardStatusType.LOGGED_OUT]: {
      icon: 'log-in',
      label: {
        content: CHARACTER.EMDASH,
        header: i18next.t(
          'call_center:containers.dashboard.component.agent_card.status.logged_out',
        ),
      },
      menu: [AgentCardMenuItemType.LOG_IN_AGENT],
    },
    [AgentCardStatusType.OUTBOUND]: {
      icon: 'clock-filled',
      label: {
        content: i18next.t(
          'call_center:containers.dashboard.component.agent_card.status.outbound.content',
        ),
        header: i18next.t(
          'call_center:containers.dashboard.component.agent_card.status.outbound.header',
        ),
      },
      menu: [AgentCardMenuItemType.RESTART_AGENT, AgentCardMenuItemType.LOG_OUT_AGENT],
    },
    [AgentCardStatusType.PAUSED]: {
      icon: 'pause',
      label: {
        content: CHARACTER.EMDASH,
        header: i18next.t('call_center:containers.dashboard.component.agent_card.status.paused'),
      },
      menu: [AgentCardMenuItemType.RESTART_AGENT, AgentCardMenuItemType.LOG_OUT_AGENT],
    },
    [AgentCardStatusType.READY]: {
      icon: 'headset-mic',
      label: {
        content: CHARACTER.EMDASH,
        header: i18next.t('call_center:containers.dashboard.component.agent_card.status.ready'),
      },
      menu: [AgentCardMenuItemType.RESTART_AGENT, AgentCardMenuItemType.LOG_OUT_AGENT],
    },
    [AgentCardStatusType.WRAP_UP]: {
      icon: 'clock-filled',
      label: {
        content: i18next.t(
          'call_center:containers.dashboard.component.agent_card.status.wrap_up.content',
        ),
        header: i18next.t(
          'call_center:containers.dashboard.component.agent_card.status.wrap_up.header',
        ),
      },
      menu: [AgentCardMenuItemType.RESTART_AGENT, AgentCardMenuItemType.LOG_OUT_AGENT],
    },
  },
});

/**
 * @name getStatus
 * @description Get the agent status object and type.
 *
 * @param [dataStatus]
 *
 * @returns Status data object and type
 */
export const getStatus = (
  dataStatus?: AgentStatus,
): { status: AgentStatus; statusType: AgentCardStatusType } => {
  const status = { status: AgentCardStatusType.LOGGED_OUT, timestamp: 0, ...dataStatus };
  return { status: status as AgentStatus, statusType: status.status as AgentCardStatusType };
};
