/* eslint-disable no-param-reassign */
import { DeviceType } from 'apps/PhoneSystem/definition';
import {
  DevicePickerFormFields,
  getModelValue,
  getOptionNotSet,
} from 'apps/shared/hooks/useDevicePicker';
import { macAddressMask } from 'apps/shared/utility';
import produce from 'immer';
import { createContext } from 'react';
import { DeviceFormMap, FormFields, FormInput } from '../definition';

export const DataContext = createContext({});

/**
 * @name transformDeviceForSaving
 * @description Transform device properties before saving to the database.
 *
 * @param data
 *
 * @returns data.
 */
export const transformDeviceForSaving = (data: FormInput): FormInput =>
  produce(data, (draft) => {
    // remove temporary fields
    delete draft.make; // deprecated field
    delete draft.model; // deprecated field
    delete draft[DevicePickerFormFields.TempDeviceBrand];
    delete draft[DevicePickerFormFields.TempDeviceModel];

    if (
      ![DeviceType.Landline, DeviceType.Cellphone, DeviceType.Smartphone].includes(
        data.device_type as DeviceType,
      )
    ) {
      delete draft.call_forward;
    }

    if (data.provision?.endpoint_brand === getOptionNotSet().value) {
      delete draft.provision;
    }

    if (data.music_on_hold.media_id === '') {
      draft.music_on_hold = {};
    }

    if (data.outbound_flags && typeof data.outbound_flags === 'string') {
      draft.outbound_flags = data.outbound_flags.split(',').map((f) => f.trim());
    }
  });

/**
 * @name transformDataForForm
 * @description Transform device data for initializing the form.
 *
 * @param data
 *
 * @returns data.
 */
export const transformDataForForm = (data: FormInput): any =>
  produce(data, (draft) => {
    if (draft) {
      const { outbound_flags, mac_address } = draft;

      if (Array.isArray(outbound_flags)) {
        draft.outbound_flags = outbound_flags?.join(',');
      }

      if (mac_address) {
        draft.mac_address = macAddressMask(mac_address);
      }

      if (draft.media?.fax) {
        const options = draft.media.fax.option;
        // @ts-ignore
        draft.media.fax.option = options === true || options === 'true';
      }

      draft[DevicePickerFormFields.TempDeviceBrand] =
        data.provision?.endpoint_brand ?? getOptionNotSet().value;
      draft[DevicePickerFormFields.TempDeviceModel] = getModelValue({
        hasEndpointProperties: true,
        data: data.provision,
      });
    }
  });

export const DeviceFormFieldsGenerator = {
  map: {
    [DeviceType.Cellphone]: {
      [FormFields.CallForwardKeepCallerId]: true,
      [FormFields.CallForwardNumber]: true,
      [FormFields.CallForwardRequireKeypress]: true,
      [FormFields.ExcludeFromQueues]: true,
      [FormFields.FollowMe]: true,
      [FormFields.IgnoreCompletedElsewhere]: false,
      [FormFields.MacAddress]: false,
      [FormFields.MediaFaxOption]: false,
      [FormFields.RingtonesExternal]: false,
      [FormFields.RingtonesInternal]: false,
      [FormFields.SipRoute]: false,
      [FormFields.SipUsername]: false,
      [FormFields.SuppressUnregisterNotifications]: false,
    },
    [DeviceType.Fax]: {
      [FormFields.CallForwardKeepCallerId]: false,
      [FormFields.CallForwardNumber]: false,
      [FormFields.CallForwardRequireKeypress]: false,
      [FormFields.ExcludeFromQueues]: false,
      [FormFields.FollowMe]: false,
      [FormFields.IgnoreCompletedElsewhere]: false,
      [FormFields.MacAddress]: true,
      [FormFields.MediaFaxOption]: true,
      [FormFields.RingtonesExternal]: false,
      [FormFields.RingtonesInternal]: false,
      [FormFields.SipRoute]: false,
      [FormFields.SipUsername]: false,
      [FormFields.SuppressUnregisterNotifications]: true,
    },
    [DeviceType.Landline]: {
      [FormFields.CallForwardKeepCallerId]: false,
      [FormFields.CallForwardNumber]: false,
      [FormFields.CallForwardRequireKeypress]: false,
      [FormFields.ExcludeFromQueues]: true,
      [FormFields.FollowMe]: true,
      [FormFields.IgnoreCompletedElsewhere]: false,
      [FormFields.MacAddress]: false,
      [FormFields.MediaFaxOption]: false,
      [FormFields.RingtonesExternal]: false,
      [FormFields.RingtonesInternal]: false,
      [FormFields.SipRoute]: false,
      [FormFields.SipUsername]: false,
      [FormFields.SuppressUnregisterNotifications]: false,
    },
    [DeviceType.SipDevice]: {
      [FormFields.CallForwardKeepCallerId]: false,
      [FormFields.CallForwardNumber]: false,
      [FormFields.CallForwardRequireKeypress]: false,
      [FormFields.ExcludeFromQueues]: true,
      [FormFields.FollowMe]: true,
      [FormFields.IgnoreCompletedElsewhere]: true,
      [FormFields.MacAddress]: true,
      [FormFields.MediaFaxOption]: true,
      [FormFields.RingtonesExternal]: true,
      [FormFields.RingtonesInternal]: true,
      [FormFields.SipRoute]: false,
      [FormFields.SipUsername]: false,
      [FormFields.SuppressUnregisterNotifications]: true,
    },
    [DeviceType.SipUri]: {
      [FormFields.CallForwardKeepCallerId]: false,
      [FormFields.CallForwardNumber]: false,
      [FormFields.CallForwardRequireKeypress]: false,
      [FormFields.ExcludeFromQueues]: true,
      [FormFields.FollowMe]: true,
      [FormFields.IgnoreCompletedElsewhere]: false,
      [FormFields.MacAddress]: false,
      [FormFields.MediaFaxOption]: false,
      [FormFields.RingtonesExternal]: false,
      [FormFields.RingtonesInternal]: false,
      [FormFields.SipRoute]: true,
      [FormFields.SipUsername]: true,
      [FormFields.SuppressUnregisterNotifications]: false,
    },
    [DeviceType.Smartphone]: {
      [FormFields.CallForwardKeepCallerId]: true,
      [FormFields.CallForwardNumber]: true,
      [FormFields.CallForwardRequireKeypress]: true,
      [FormFields.ExcludeFromQueues]: true,
      [FormFields.FollowMe]: true,
      [FormFields.IgnoreCompletedElsewhere]: false,
      [FormFields.MacAddress]: false,
      [FormFields.MediaFaxOption]: false,
      [FormFields.RingtonesExternal]: false,
      [FormFields.RingtonesInternal]: false,
      [FormFields.SipRoute]: false,
      [FormFields.SipUsername]: false,
      [FormFields.SuppressUnregisterNotifications]: false,
    },
    [DeviceType.Softphone]: {
      [FormFields.CallForwardKeepCallerId]: false,
      [FormFields.CallForwardNumber]: false,
      [FormFields.CallForwardRequireKeypress]: false,
      [FormFields.ExcludeFromQueues]: true,
      [FormFields.FollowMe]: true,
      [FormFields.IgnoreCompletedElsewhere]: true,
      [FormFields.MacAddress]: false,
      [FormFields.MediaFaxOption]: true,
      [FormFields.RingtonesExternal]: false,
      [FormFields.RingtonesInternal]: false,
      [FormFields.SipRoute]: false,
      [FormFields.SipUsername]: false,
      [FormFields.SuppressUnregisterNotifications]: false,
    },
  } as DeviceFormMap,

  shouldGenerate: (formField: FormFields | undefined, deviceType: DeviceType | undefined) => {
    const { map } = DeviceFormFieldsGenerator;

    return Boolean(deviceType && formField && map[deviceType]?.[formField]);
  },
};
