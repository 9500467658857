import { WidthType } from 'shared/utility/definition';

const defaultProps = {
  hasDirtyIcon: true,
  isBuildInMediaIncluded: true,
  isClearable: true,
  labelWidth: 'large' as WidthType,
};

export default defaultProps;
