import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledModelProps as Props } from './definition';

export default styled.li<Props>`
  ${({ hasImage, isActive, theme }) => css`
    ${mixin.borderRadius()};
    ${mixin.transition({ property: 'border', duration: 'fast' })};
    display: grid;
    grid-template: 1fr 30px / 1fr;
    width: 121px;
    padding: ${theme.spacing(1, 0)};
    border: 1px solid transparent;
    cursor: pointer;
    list-style: none;

    ${isActive
      ? css`
          border-color: ${theme.brio.secondary.main};
          cursor: default;
        `
      : css`
          &:hover {
            border-color: ${theme.brio.greyscale[400]};
          }
        `}

    > div {
      ${mixin.flex()};
      padding: ${theme.spacing(0, 4)};

      img {
        display: ${hasImage ? 'inline' : 'none'};
        width: auto;
        height: auto;
        --max-size: 72px;
        max-width: var(--max-size);
        max-height: var(--max-size);
        object-fit: contain;
      }
    }

    span {
      ${mixin.flex()};
      ${mixin.font({ size: 14 })};
      text-align: center;
      text-overflow: ellipsis;
      text-transform: uppercase;
    }
  `};
`;
