import AdvancedSection, { fields as advancedFields } from './AdvancedSection';
import BasicsSection, { fields as basicsFields } from './BasicsSection';
import ExtensionSection, { fields as extensionFields } from './ExtensionSection';
import GreetingSection, { fields as greetingFields } from './GreetingSection';
import RecipientsSection, { fields as recipientsFields } from './RecipientsSection';

const fields = {
  advanced: advancedFields,
  basics: basicsFields,
  extension: extensionFields,
  greeting: greetingFields,
  recipients: recipientsFields,
};

const Section = {
  Advanced: AdvancedSection,
  Basics: BasicsSection,
  Extension: ExtensionSection,
  Greeting: GreetingSection,
  Recipients: RecipientsSection,
};

export { fields, Section };
