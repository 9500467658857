import { MarginType, Width, WidthType } from './definition';

export const value: Record<string, Record<string, any>> = {
  getWidth: {
    default: {
      xsmall: 60,
      small: 120,
      medium: 180,
      large: 260,
      auto: '100%',
    },
    button: {
      large: 150,
      auto: 'auto',
    },
    editableList: {
      large: 340,
      auto: 'auto',
    },
    featureCode: {
      large: 180,
    },
    input: {
      xsmall: 60,
      small: 120,
      medium: 180,
      large: 260,
      xlarge: 360,
      auto: '100%',
    },
    label: {
      none: 0,
      small: 12,
      medium: 130,
      xmedium: 155,
      large: 180,
      xlarge: 210,
      auto: '100%',
    },
    picker: {
      small: 120,
      medium: 180,
      large: 260,
      auto: '100%',
    },
    radioGroup: {
      small: 130,
      medium: 180,
      large: 320,
      auto: '100%',
    },
    select: {
      xsmall: 60,
      small: 120,
      medium: 180,
      large: 260,
      xlarge: 360,
      auto: '100%',
    },
    text: {
      small: 130,
      medium: 180,
      large: 260,
      auto: '100%',
    },
  },
};

export const props: Record<string, Record<string, boolean | number | string | Width>> = {
  getWidth: {
    width: {
      input: 'large' as WidthType,
      label: 'large' as WidthType,
    },
    type: 'input',
    offset: 0,
    hasMargin: false,
    hasDirtyIcon: false,
    hasInfoIcon: false,
    isReturnPixels: true,
    leftMargin: MarginType.none,
  },
};
