import { metaNumbers } from 'apps/meta';
import { meta as metaNumbersInUse } from 'apps/Numbers/containers/NumbersInUse';
import { RouterLink } from 'apps/shared/components/RouterLink';
import { CHARACTER, REGEX } from 'constant';
import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { Trans } from 'react-i18next';
import Button from 'shared/components/Button';
import { SeverityType } from '../definition';

/**
 * @name doPlusSign
 * @description Add or remove the prefix plus `+` sign from given phone number.
 *
 * @param number
 *
 * @returns Phone number.
 */
export const doPlusSign = (number: string) => ({
  // eslint-disable-next-line no-nested-ternary
  add: number ? (number.startsWith(CHARACTER.PLUS) ? number : `${CHARACTER.PLUS}${number}`) : '',
  remove: number?.replace(CHARACTER.PLUS, ''),
});

/**
 * @name getAddressString
 * @description Build the full address value.
 *
 * @param numberData - E911 data object
 *
 * @returns Address value.
 */
export const getAddressString = (numberData: any) =>
  Object.values(
    (({ extended_address, locality, postal_code, region, street_address }) => ({
      // Note: Maintain the order of properties so that they correctly join in the returned string
      street_address,
      extended_address,
      locality,
      region,
      postal_code,
    }))(numberData?.e911 ?? {}),
  )
    .filter((val) => val)
    .join(', ');

/**
 * @name isValidNumber
 * @description Determine validity of the given phone number.
 *
 * @param number - Phone number
 *
 * @returns Boolean
 */
export const isValidNumber = (number?: string): boolean =>
  number ? REGEX.PHONE_NUMBER.test(number) : false;

/**
 * @name getActionButton
 * @description Get the address alert actions button object.
 *
 * @param [object]
 * @property number
 * @property severity
 * @property setIsNumbersInUsePickerOpen
 *
 * @returns Action buttons object.
 */
export const getActionButton = ({
  number,
  severity,
  setIsNumbersInUsePickerOpen,
}: {
  number: string;
  severity: SeverityType;
  setIsNumbersInUsePickerOpen: (isOpen: boolean) => void;
}) => ({
  editAddress: (
    <RouterLink target="_blank" to={`/apps/${metaNumbers.slug}/${metaNumbersInUse.slug}/${number}`}>
      <Button color={severity}>
        {i18next.t(
          'phone_system:containers.seats.section.caller_id.components.address_alert.button.label.edit_address',
        )}
      </Button>
    </RouterLink>
  ),
  selectNumber: (
    <Button color={severity} onClick={() => setIsNumbersInUsePickerOpen(true)}>
      {i18next.t(
        'phone_system:containers.seats.section.caller_id.components.address_alert.button.label.select_number',
      )}
    </Button>
  ),
});

/**
 * @name getNumberMeta
 * @description Collect the phone number values into one `meta` object.
 *
 * @param data
 * @param number
 *
 * @returns Meta object.
 */
export const getNumberMeta = (data: any, number: string) => {
  const numberData = data?.numbers[doPlusSign(number).add];

  return {
    number: numberData ? merge({}, numberData, { id_unsigned: doPlusSign(number).remove }) : {},
    get address() {
      return getAddressString(this.number);
    },
    get severity() {
      if (!isValidNumber(number) || !this.number.id) {
        return SeverityType.Error;
      }
      return this.address ? SeverityType.Info : SeverityType.Warning;
    },
  };
};

/**
 * @name assignAlertValues
 * @description Determine and assign the alert action, message, and severity values.
 *
 * @param [object]
 * @property isAccountLevel
 * @property data: { account, numbers, seat }
 * @property number
 * @property getE911NumberMeta
 * @property setAlert
 * @property setIsNumbersInUsePickerOpen
 */
export const assignAlertValues = ({
  isAccountLevel,
  data,
  number,
  setAlert,
  setIsNumbersInUsePickerOpen,
}: {
  isAccountLevel: boolean;
  data: Record<string, any>;
  number?: string;
  setAlert: (alert: any) => void;
  setIsNumbersInUsePickerOpen: (isOpen: boolean) => void;
}) => {
  let alert = { action: undefined, message: undefined, severity: undefined };

  if (!isEmpty(data.account)) {
    let action;
    let message;

    if (number) {
      const meta = getNumberMeta(data.numbers, number);
      const actionButton = getActionButton({
        number: meta.number.id_unsigned,
        severity: meta.severity,
        setIsNumbersInUsePickerOpen,
      });

      switch (meta.severity) {
        // non-system caller id number
        case 'error':
          action = actionButton.selectNumber;
          message = i18next.t(
            'phone_system:containers.seats.section.caller_id.components.address_alert.message.core.default.0',
          );
          break;

        // system caller id number w/ e911 address
        case 'info':
          action = actionButton.editAddress;
          message = (
            <Trans
              i18nKey="phone_system:containers.seats.section.caller_id.components.address_alert.message.core.default.1"
              values={{ address: meta.address }}
            />
          );
          break;

        case 'warning':
          if (meta.number.used_by) {
            // system caller id number w/o e911 address
            action = actionButton.editAddress;
            message = i18next.t(
              'phone_system:containers.seats.section.caller_id.components.address_alert.message.core.default.2',
            );
          } else {
            // system (spare) caller id number w/o e911 address
            action = actionButton.selectNumber;
            message = i18next.t(
              'phone_system:containers.seats.section.caller_id.components.address_alert.message.core.default.3',
            );
          }
          break;

        default:
          break;
      }

      alert = { action, message, severity: meta.severity } as any;
    } else {
      // by default, inherit account e911 info
      let { caller_id: { emergency: e911 = {} } = {} } = data.account;
      let messageIntro = i18next.t(
        'phone_system:containers.seats.section.caller_id.components.address_alert.message.intro.admin',
      );

      // if at the account level, we do not want to inherit e911 values
      if (isAccountLevel) {
        e911 = { name: undefined, number: undefined };
      }

      // if seat exists, inherit seat e911 info
      if (data.seat?.caller_id?.emergency?.number) {
        e911 = {
          name: data.seat.caller_id.emergency.name,
          number: data.seat.caller_id.emergency.number,
        };
        messageIntro = i18next.t(
          'phone_system:containers.seats.section.caller_id.components.address_alert.message.intro.seat',
        );
      }

      const meta = getNumberMeta(data.numbers, e911.number);
      const actionButton = getActionButton({
        number: meta.number.id_unsigned,
        severity: meta.severity,
        setIsNumbersInUsePickerOpen,
      });

      switch (meta.severity) {
        case 'error': {
          action = actionButton.selectNumber;

          if (!e911.number) {
            /** Inherited w/o caller id number. */

            message = i18next.t(
              'phone_system:containers.seats.section.caller_id.components.address_alert.message.core.default.4',
            );
          } else {
            /** Inherited w/ non-system caller id number. */

            const values = {
              ...e911,
              intro: messageIntro,
              message: i18next.t(
                'phone_system:containers.seats.section.caller_id.components.address_alert.message.core.default.0',
              ),
            };

            message = e911.name ? (
              // inherited w/ caller id name
              <Trans
                i18nKey="phone_system:containers.seats.section.caller_id.components.address_alert.message.core.e911.default.0"
                values={values}
              />
            ) : (
              // inherited w/o caller id name
              <Trans
                components={{ em: <em /> }}
                i18nKey="phone_system:containers.seats.section.caller_id.components.address_alert.message.core.e911.number.0"
                values={values}
              />
            );
          }

          break;
        }
        case 'info': {
          /** Inherited w/ system caller id number and E911 address. */

          const values = {
            ...e911,
            intro: messageIntro,
            address: meta.address,
          };

          action = actionButton.editAddress;
          message = e911.name ? (
            // inherited w/ caller id name
            <Trans
              i18nKey="phone_system:containers.seats.section.caller_id.components.address_alert.message.core.e911.default.1"
              values={values}
            />
          ) : (
            // inherited w/o caller id name
            <Trans
              components={{ em: <em /> }}
              i18nKey="phone_system:containers.seats.section.caller_id.components.address_alert.message.core.e911.number.1"
              values={values}
            />
          );

          break;
        }
        case 'warning': {
          if (meta.number.used_by) {
            /** Inherited w/ system caller id number w/o E911 address. */

            const values = {
              ...e911,
              intro: messageIntro,
              message: i18next.t(
                'phone_system:containers.seats.section.caller_id.components.address_alert.message.core.default.2',
              ),
            };

            action = actionButton.editAddress;
            message = e911.name ? (
              // inherited w/ caller id name
              <Trans
                i18nKey="phone_system:containers.seats.section.caller_id.components.address_alert.message.core.e911.default.2"
                values={values}
              />
            ) : (
              // inherited w/o caller id name
              <Trans
                components={{ em: <em /> }}
                i18nKey="phone_system:containers.seats.section.caller_id.components.address_alert.message.core.e911.number.2"
                values={values}
              />
            );
          } else {
            /** Inherited w/ system (spare) caller id number w/o E911 address. */

            const values = {
              ...e911,
              intro: messageIntro,
              message: i18next.t(
                'phone_system:containers.seats.section.caller_id.components.address_alert.message.core.default.3',
              ),
            };

            action = actionButton.selectNumber;
            message = e911.name ? (
              // inherited w/ caller id name
              <Trans
                i18nKey="phone_system:containers.seats.section.caller_id.components.address_alert.message.core.e911.default.3"
                values={values}
              />
            ) : (
              // inherited w/o caller id name
              <Trans
                components={{ em: <em /> }}
                i18nKey="phone_system:containers.seats.section.caller_id.components.address_alert.message.core.e911.number.3"
                values={values}
              />
            );
          }

          break;
        }
        default:
          break;
      }

      alert = { action, message, severity: meta.severity } as any;
    }
  }

  if (alert.action && alert.message && alert.severity) {
    setAlert(alert);
  }

  return alert; // for testing
};
