import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogActions, DialogType } from 'shared/components/Dialog';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { BridgeNumberChangeAction } from '../../definition';
import { WarningDialogProps } from './definition';

const WarningDialog = (props: WarningDialogProps) => {
  const { action, open, onClose, onAction } = props;

  const { t } = useTranslation();

  const dialogMessage = useMemo(() => {
    const messages: { [key in BridgeNumberChangeAction]?: string } = {
      [BridgeNumberChangeAction.Switch]: t(
        'phone_system:containers.account.submodule.settings.section.cellular_enablement.bridge_number.warning_dialog.switch',
      ),
      [BridgeNumberChangeAction.Unassign]: t(
        'phone_system:containers.account.submodule.settings.section.cellular_enablement.bridge_number.warning_dialog.unassign',
      ),
    };

    return messages[action];
  }, [action, t]);

  return (
    <Dialog
      open={open}
      dataTestId={TEST_ID.PHONE_SYSTEM.ACCOUNT.SETTINGS.CELLULAR_ENABLEMENT_SECTION.WARNING_DIALOG}
      renderActions={
        <DialogActions
          cancelLabel={t('common:cancel')}
          saveButtonProps={{
            color: 'error',
            label: t(
              'phone_system:containers.account.submodule.settings.section.cellular_enablement.bridge_number.warning_dialog.continue',
            ),
            variant: 'contained',
          }}
          onAction={onAction}
        />
      }
      title={t(
        'phone_system:containers.account.submodule.settings.section.cellular_enablement.bridge_number.warning_dialog.title',
      )}
      type={DialogType.Large}
      onClose={onClose}
    >
      {dialogMessage}
    </Dialog>
  );
};

export default WarningDialog;
