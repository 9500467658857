import { ExitConfirmationDialogContext } from 'apps/store/contexts';
import { DEFAULT_APP, LOCAL_STORAGE } from 'constant';
import get from 'lodash/get';
import { useFetchAccountQuery } from 'models/Account';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon from 'shared/components/Icon';
import useConfig from 'shared/hooks/useConfig';
import useSamlSso, { SSO_ENABLED } from 'shared/hooks/useSamlSso';
import {
  AppPicker,
  CurrentAccount,
  CurrentUser,
  LogoutDialog,
  ProfileSwitcher,
} from './components';
import defaultProps from './default';
import { HeaderProps as Props } from './definition';
import logo from './images/logo.png';
import StyledHeader from './style';

export { CONSTANTS as HEADER_CONSTANTS } from './components/constant';

const Header = (props: Props) => {
  const { label } = { ...defaultProps, ...props };
  const { data: accountData } = useFetchAccountQuery();
  const config = useConfig();
  const navigate = useNavigate();
  const currentAccountId = useSelector((state: any) => state.account.id);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState<boolean>(false);
  const [isNotRootAccount, setIsNotRootAccount] = useState<boolean>(false);
  const { currentUser, registrations, error } = useSamlSso(config);
  const isSsoEnabled = get(accountData, SSO_ENABLED, false);
  const showProfileSwitcher = isSsoEnabled && !isNotRootAccount && registrations.length > 1;
  const { createExitConfirmationDialogHandler } = useContext(ExitConfirmationDialogContext);

  useEffect(() => {
    const rootAccountId = localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID);
    setIsNotRootAccount(currentAccountId !== rootAccountId);
  }, [currentAccountId]);

  const onClick = {
    logo: () => navigate(`/apps/${DEFAULT_APP}`),
    logout: createExitConfirmationDialogHandler?.(() => setIsLogoutDialogOpen(true)),
  };

  return (
    <>
      <StyledHeader>
        <div aria-hidden="true" onClick={onClick.logo}>
          <img src={logo} alt="" />
        </div>
        <h1>{label}</h1>
        <nav>
          <AppPicker />
          <CurrentAccount isNotRootAccount={isNotRootAccount} />
          {showProfileSwitcher ? (
            <ProfileSwitcher
              currentUser={currentUser}
              registrations={registrations}
              error={error}
            />
          ) : (
            <CurrentUser />
          )}
          <div aria-hidden="true" onClick={onClick.logout}>
            <Icon name="log-out" />
          </div>
        </nav>
      </StyledHeader>
      <LogoutDialog isDialogOpen={isLogoutDialogOpen} setIsDialogOpen={setIsLogoutDialogOpen} />
    </>
  );
};

export default Header;
