export default {
  kazoo: {
    agent: {
      state: {
        connected: 'rgb(50,129,76)', // #32814c
        connecting: 'rgb(43,71,198)', // #2b47c6
        logged_in: 'rgb(118,116,116)', // #767474
        logged_out: 'rgb(118,116,116)', // #767474
        outbound: 'rgb(183,95,1)', // #b75f01
        paused: 'rgb(214,0,0)', // #d60000
        ready: 'rgb(1,125,183)', // #017db7
        wrapup: 'rgb(183,95,1)', // #b75f01
      },
    },
  },
};
