import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { TERMINATORS } from '../../constants';

const schema = () =>
  Joi.object({
    endless_playback: Joi.boolean().required(),
    id: Joi.string().allow(''),
    nodeId: Joi.string(),
    terminators: Joi.array().items(Joi.string().valid(...TERMINATORS)),
    advanced: Joi.boolean().optional(),
    var: Joi.when(Joi.ref('advanced'), {
      is: Joi.boolean().valid(true),
      then: Joi.string().required(),
      otherwise: Joi.optional(),
    }),
  }).messages(messages());

export default schema;
