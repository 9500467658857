import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledMaintenanceProps as Props } from './definition';

const StyledMaintenance = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex({ alignItems: 'flex-start', justifyContent: 'flex-start' })};

    &:not(:first-of-type) {
      margin: 10px 0 0;
    }

    > span {
      margin: 0 0 0 16px;

      > a,
      span {
        display: block;
      }

      a {
        ${mixin.transition({ property: 'border-color' })};
        color: ${props.theme.core.color.blue.draggable};
        font-weight: 600;
        border-bottom: 2px solid transparent;

        &:hover {
          border-bottom-color: ${props.theme.core.color.blue.draggable};
        }
      }

      span {
        margin: 5px 0 0;
        color: ${(props) => props.theme.core.color.black};
      }
    }

    > svg {
      width: 24px;
      fill: ${props.theme.core.color.blue.draggable};
      transform: translateY(-4px);
    }
  `}
`;

export default StyledMaintenance;
