// Callflow Queries
import { Callflow } from 'api/callflow';
import { getAccountId } from 'apps/shared/utility/account/getAccountId';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';
import store from '../../store';
import { userQueries } from '../User/Queries';
import { buildCallflowName, callflowFilter } from './utility';

const callflowResponseHandler = async (
  callflows: Array<any> | Callflow,
  isHideBridgeCallflow?: boolean,
) => {
  const { data: users = [] } = await store.dispatch(
    userQueries.endpoints.fetchUsers.initiate({ accountId: getAccountId() }),
  );

  return Array.isArray(callflows)
    ? Promise.all(
        callflows
          .filter((callflow: Callflow) => callflowFilter(callflow, isHideBridgeCallflow))
          .map(
            async (callflow: Callflow): Promise<Callflow> => ({
              hasName: Boolean(callflow.name),
              name: buildCallflowName(callflow, users),
              ...callflow,
            }),
          ),
      )
    : new Promise((resolve, reject) => {
        const callflow = callflows;

        if (!callflow.id) {
          return reject(callflow?.message);
        }

        const name = buildCallflowName(callflow, users);

        return resolve({ name, hasName: !!callflow.name, ...callflow });
      });
};

export const callflowQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchCallflows: builder.query<CallflowResponse, void>({
      query: () => ({
        url: Callflow.Fetch(),
        responseHandler: async (results) =>
          callflowResponseHandler(await responseHandler(results), true),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.CALLFLOW as const, id })),
              { type: TAGS.CALLFLOW, id: 'LIST' },
            ]
          : [{ type: TAGS.CALLFLOW, id: 'LIST' }],
    }),
    fetchCallflowById: builder.query<Callflow, ByIdParam>({
      query: ({ id }) => ({
        url: Callflow.FetchById(id),
        responseHandler: async (results) => callflowResponseHandler(await responseHandler(results)),
      }),
      structuralSharing: false,
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.CALLFLOW, id }];
      },
    }),
    fetchCallflowsByOwnerUsername: builder.query<CallflowResponse, { username: string }>({
      query: ({ username }) => ({
        url: Callflow.FetchByOwnerUsername(username),
        responseHandler: async (results) => callflowResponseHandler(await responseHandler(results)),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.CALLFLOW as const, id })),
              { type: TAGS.CALLFLOW, id: 'LIST' },
            ]
          : [{ type: TAGS.CALLFLOW, id: 'LIST' }],
    }),
    /** This query set the `structuralSharing` to `false` to make sure even if the newly fetched data is the same, it will return a new reference to trigger re-render. */
    forceFetchCallflowById: builder.query<Callflow, ByIdParam>({
      query: ({ id }) => ({
        url: Callflow.FetchById(id),
        responseHandler: async (results) => callflowResponseHandler(await responseHandler(results)),
      }),
      structuralSharing: false,
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.CALLFLOW, id }];
      },
    }),
  }),
});
