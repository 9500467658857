import { FunctionComponent } from 'react';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import RemovableTable from 'shared/components/RemovableTable';
import defaultProps from './default';
import { ConfirmSelectionDialogProps as Props } from './definition';
import { columns, getButtonProps } from './utility';

const ConfirmSelectionDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    isDelete,
    isOpen,
    confirmData,
    customColumns,
    dialogMessage,
    dialogTitle,
    header,
    confirmButtonProps,
    onConfirm,
    setConfirmData,
  } = { ...defaultProps, ...props };

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        setConfirmData([]);
        break;
      case 'saveClicked':
        onConfirm(confirmData);
        break;
      default:
        break;
    }
  };

  const handleRemoveRow = (removeRow: Record<string, any>) =>
    setConfirmData(
      confirmData.filter((currentRow: Record<string, any>) => currentRow !== removeRow),
    );

  return (
    <Dialog
      open={isOpen}
      title={dialogTitle}
      onClose={() => setConfirmData([])}
      renderActions={
        <DialogActions
          saveButtonProps={confirmButtonProps || getButtonProps(isDelete)}
          onAction={handleDialogAction}
        />
      }
    >
      <p>{dialogMessage}</p>
      <RemovableTable
        actionIcon={{
          color: 'core.states.error.main',
          name: 'minus-circle-outlined',
        }}
        columns={customColumns ?? columns()}
        data={confirmData}
        header={header}
        rowActionHandler={handleRemoveRow}
      />
    </Dialog>
  );
};

export default ConfirmSelectionDialog;
