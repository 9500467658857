import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

const StyledDivider = styled(Box)`
  ${({ theme }) => css`
    ${mixin.flex()}
    margin: ${theme.spacing(7, 0)};

    hr {
      flex: 1;
      width: 100%;
      margin: 0 auto;
      border-top: 2px solid ${theme.core.border.color};
      border-bottom: none;

      &:first-of-type {
        margin-right: ${theme.spacing(7)};
      }

      &:last-of-type {
        margin-left: ${theme.spacing(7)};
      }
    }

    span {
      text-transform: uppercase;
      user-select: none;
    }
  `}
`;

export default StyledDivider;
