import { FunctionComponent } from 'react';
import Card from 'shared/components/Card';
import DropdownButton from 'shared/components/DropdownButton';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import CardContent from './components/CardContent';
import CardHeaderTitle from './components/CardHeaderTitle';
import { AgentCardDataType } from './components/definition';
import defaultProps from './default';
import { AgentCardMenuItemType, AgentCardProps as Props, AgentCardStatusType } from './definition';
import { getConstants, getStatus } from './utility';

export { AgentCardStatusType } from './definition';

const AgentCard: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    id,
    data,
    data: { agent, queue, stats },
    cardProps,
    headerProps,
    onClick,
    ...rest
  } = {
    ...defaultProps,
    ...props,
  };

  const { status, statusType } = getStatus(data.status);
  const constants = getConstants(onClick);
  const { menu: menuConstants } = constants;
  const statusConstants = constants.status[statusType];

  return (
    <Card
      {...rest}
      cardProps={{
        className: `MuiCard-agent MuiCard-agent-${statusType}`,
        variant: 'outlined',
        ...cardProps,
        'data-test-id': 'call-center-dashboard-dashboard-agents-agent-card',
      }}
      contentProps={{
        className: 'MuiCardContent-agent',
      }}
      headerProps={{
        className: `MuiCardHeader-agent MuiCardHeader-agent-${statusType}`,
        action: (
          <DropdownButton
            hasIconButton
            isDarkMode
            items={statusConstants?.menu.map((item: AgentCardMenuItemType) => menuConstants[item])}
            buttonProps={{ color: 'neutral' }}
          />
        ),
        avatar:
          statusType === AgentCardStatusType.LOGGED_IN ? (
            <Loading hasLabel={false} color="brio.common.white" />
          ) : (
            <Icon name={statusConstants?.icon} />
          ),
        title: (
          <CardHeaderTitle
            data={{
              agentName: `${agent?.first_name} ${agent?.last_name}`,
              agentExtension: agent?.username,
              label: statusConstants?.label.header,
            }}
          />
        ),
        ...headerProps,
      }}
      content={
        <CardContent
          data={{
            [AgentCardDataType.HAS_CALLBACK]: queue?.is_callback,
            [AgentCardDataType.ACTIVE_CALL]: status?.caller_id_name,
            [AgentCardDataType.AGENT_SKILLS]: agent?.skills,
            [AgentCardDataType.CALLS_MISSED]: stats?.missed_calls,
            [AgentCardDataType.CALLS_TAKEN]: stats?.answered_calls,
            [AgentCardDataType.CALLS_TOTAL]: stats?.total_calls,
            [AgentCardDataType.LABEL]: status?.pause_alias ?? statusConstants?.label.content,
            [AgentCardDataType.PRIORITY]: queue?.caller_priority,
            [AgentCardDataType.STATUS_TYPE]: statusType,
            [AgentCardDataType.TIMESTAMP]: status.timestamp,
          }}
        />
      }
    />
  );
};

export default AgentCard;
