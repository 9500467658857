import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import Box from '../Box';
import { ErrorType, StyledErrorProps as Props } from './definition';

const StyledError = styled(Box)<Props>`
  ${({ type, theme }) => css`
    h1 {
      ${mixin.font({ size: 16, lineHeight: 1.25, weight: 600 })};
      width: 100%;
      text-align: center;
      text-wrap: balance;
      user-select: none;
    }

    ${type === ErrorType.Default &&
    css`
      margin: ${theme.spacing(5, 0)};
    `}

    ${type === ErrorType.Iframe &&
    css`
      ${mixin.borderRadius()};
      padding: ${theme.spacing(6)};
      border: 2px solid ${theme.whitelabel.primary.color};

      h1 {
        ${mixin.font({ size: 21, lineHeight: 1.3, weight: 600 })};
        color: ${theme.whitelabel.primary.color};
      }
    `}
  `}
`;

export default StyledError;
