import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledOptionProps as Props } from './definition';

const StyledOption = styled.div<Props>`
  ${({ isSelected, theme }) => css`
    ${mixin.borderRadius({ radius: 4 })};
    ${mixin.flex({ justifyContent: 'flex-start' })};

    --height: 36px;
    --margin: 4px;

    width: calc(100% - var(--margin) * 2);
    height: var(--height);
    line-height: var(--height);
    margin: var(--margin);
    padding: 0 calc(var(--margin) * 1.5);
    color: ${theme.core.color.black};

    ${isSelected &&
    css`
      background-color: ${theme.brio.action.hover};
    `}

    &:hover {
      background-color: ${theme.brio.action.selected};
    }

    &:not(:last-of-type) {
      margin: var(--margin) var(--margin) 0;
    }

    > div {
      ${mixin.flex()};

      --width: 50px;
      --height: 32px;

      width: var(--width);
      height: var(--height);
      margin: 0 ${theme.spacing(2)} 0 0;

      img {
        max-width: var(--width);
        max-height: var(--height);
      }
    }
  `};
`;

export default StyledOption;
