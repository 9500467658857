import { CHARACTER } from 'constant';
import { useFetchMediaByIdQuery } from 'models/Media';
import Loading from 'shared/components/Loading';
import { TableCellTruncatedValue } from '..';
import { getInputValue } from '../utility';
import { MediaInputProps as Props } from './definition';

const MediaInput = ({ id, maxWidth }: Props) => {
  const { data, isLoading } = useFetchMediaByIdQuery({ id: id as string }, { skip: !id });

  if (isLoading) {
    return <Loading />;
  }

  return data ? (
    <TableCellTruncatedValue maxWidth={maxWidth} text={getInputValue(data)} />
  ) : (
    <>{CHARACTER.EMDASH}</>
  );
};

export default MediaInput;
