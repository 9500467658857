export interface BridgeNumberSelectProps {
  initialNumber?: string;
}

export interface GetBridgeNumberChangeActionParam {
  initialNumber?: string;
  nextNumber: string;
}

export enum BridgeNumberChangeAction {
  Assign = 'assign',
  Switch = 'switch',
  Unassign = 'unassign',
  Unmodified = 'unmodified',
}

export interface UseUpsertBridgeCallflowParam {
  action?: BridgeNumberChangeAction;
  bridgeNumber?: string;
  createBridgeCallflow?: () => Promise<any>;
  deleteBridgeCallflow?: () => Promise<any>;
  initialNumber?: string;
  updateBridgeCallflow?: () => Promise<any>;
}
