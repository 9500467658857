import { EndpointType } from 'apps/PhoneSystem/definition';
import { TableType, TABLE_CONSTANTS } from 'shared/components/Table';

const defaultProps = {
  hasActionRow: false,
  hasPagination: {
    bar: true,
    container: true,
    content: true,
    info: false,
    rowsPerPage: false,
  },
  hasPaginationPadding: {
    bottom: true,
    side: false,
    top: true,
  },
  isRightTableDraggable: false,
  disableSortBy: false,
  manualSortBy: false,
  confirmations: {
    deviceExists: '',
    ownerExists: '',
  },
  included: [EndpointType.User, EndpointType.Device, EndpointType.Group],
  rowCount: TABLE_CONSTANTS.ROW_COUNT,
  selectionTableType: TableType.SelectionDefault,
};

export default defaultProps;
