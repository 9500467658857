import { SESSION_STORAGE } from 'constant';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import { MarginType } from 'shared/utility/definition';
import { PageSize, TableRowsPerPageProps as Props } from './definition';
import { getRowsPerPages } from './utility';

const TableRowsPerPage = memo(({ pageSize, setPageSize }: Props) => {
  const { t } = useTranslation();

  const options = Object.values(PageSize)
    .filter((value) => !Number.isNaN(Number(value)))
    .map((option) => ({
      label: option.toString(),
      value: option.toString(),
    }));
  const defaultValue = getRowsPerPages(pageSize, options);

  return (
    <LabeledSelect
      hasMargin={false}
      label={t('common:component.table.pagination.table_rows_per_page.label')}
      labelProps={{
        rightMargin: MarginType.xsmall,
      }}
      labelWidth="auto"
      selectProps={{
        isSearchable: false,
        hasBorder: false,
        defaultValue,
        options,
        size: 'xsmall',
        onChange: (option: SelectOption | null) => {
          setPageSize(Number(option?.value));
          sessionStorage.setItem(SESSION_STORAGE.TABLE.PAGINATION.SIZE, option?.value);
        },
      }}
      selectWidth="xsmall"
    />
  );
});

export default TableRowsPerPage;
