import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

const agents = {
  // GET
  FetchAll: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/agents?paginate=${isPaginated}`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/agents/${id}`,
  FetchStats: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/agents/stats`,
  FetchStatus: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/agents/status`,
  FetchStatusById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/agents/${id}/status`,

  // POST
  RestartById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/agents/${id}/restart`,
  UpdateById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/agents/${id}/status`,
};

export default agents;
