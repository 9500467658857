import styled, { css } from 'styled-components';
import { StyledAddMediaDialogProps as Props } from './definition';

const StyledAddMediaDialog = styled.div<Props>`
  ${(props) => css`
    ${props.hasMargin &&
    css`
      margin: 0 0 36px;
    `}
  `}
`;

export default StyledAddMediaDialog;
