import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const Callflow = {
  // GET
  Fetch: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/callflows?paginate=${isPaginated}`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/callflows/${id}`,

  // TODO: find out a way to encode the URLs on the base query creation level
  // Note: If the encodeURIComponent is decided to be removed later, re-test and update of unit tests of of all references is necessary to make sure nothing is broken. (e.g., bridge number is using this API to get the callflow)
  FetchByOwnerUsername: (username: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/callflows?by_contain_number=${encodeURIComponent(
      username,
    )}`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/callflows`,
  CreateByAccountId: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `accounts/${accountId}/callflows`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/callflows/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/callflows/${id}`,
};
