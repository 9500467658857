import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { HTTP_METHODS, REQUEST_BODY_FORMATS, REQUEST_FORMATS } from '../../constants';

const schema = () =>
  Joi.object({
    debug: Joi.boolean(),
    method: Joi.string().valid(...HTTP_METHODS),
    nodeId: Joi.string(),
    req_body_format: Joi.string().valid(...REQUEST_BODY_FORMATS),
    req_format: Joi.string().valid(...REQUEST_FORMATS),
    req_timeout: Joi.number().min(0),
    voice_url: Joi.string().uri().required(),
  }).messages(messages());

export default schema;
