import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { style } from '../../../style';
import { StyledAppPickerBodyProps as Props } from './definition';

const CONSTANTS = {
  APP_COUNT: {
    MAX: 8,
    MIN: 4,
  },
};

const width = (appCount = CONSTANTS.APP_COUNT.MIN) => {
  const calcWidth = (count: number) => css`
    width: ${`calc((100px * ${count}) + 20px)`};
  `;

  return css`
    ${calcWidth(
      appCount > CONSTANTS.APP_COUNT.MIN && appCount < CONSTANTS.APP_COUNT.MAX
        ? appCount
        : CONSTANTS.APP_COUNT.MIN,
    )};

    ${appCount >= CONSTANTS.APP_COUNT.MAX &&
    css`
      ${calcWidth(CONSTANTS.APP_COUNT.MAX)};
    `};
  `;
};

const StyledAppPickerBody = styled(Box)<Props>`
  ${({ $appCount, $isOpen: isOpen, theme }) => css`
    ${style.item({ isOpen, display: 'flex', theme }).toggleable.body};
    flex-direction: column;
    ${width()};
    padding: ${theme.spacing(4)};

    > div {
      &:first-of-type {
        align-self: flex-end;
      }

      &:last-of-type {
        ${mixin.flex({
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: `${theme.spacing(4)} ${theme.spacing(5)}`,
          wrap: 'wrap',
        })};
        margin: ${theme.spacing(4, 0)};
      }
    }

    > span {
      align-self: flex-end;
    }

    @media screen and (min-width: 1200px) {
      ${width($appCount <= 5 ? $appCount : 5)};
    }

    @media screen and (min-width: 1300px) {
      ${width($appCount <= 6 ? $appCount : 6)};
    }

    @media screen and (min-width: 1400px) {
      ${width($appCount <= 7 ? $appCount : 7)};
    }

    @media screen and (min-width: 1500px) {
      ${width($appCount)};
    }
  `}
`;

export default StyledAppPickerBody;
