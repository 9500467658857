import theme from '../core';

export default {
  styleOverrides: {
    root: {
      '&.MuiCircularProgress-loading-large': {
        margin: theme.spacing(4, 0, 0),
      },
    },
  },
};
