import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  enforce_call_restriction: true,
  permit_custom_callflow: false,
};
