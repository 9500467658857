import StyledEditForm from 'apps/shared/components/StyledEditForm';
import { useExitConfirmationDialog } from 'apps/shared/hooks/useExitConfirmationDialog';
import useIsMounted from 'apps/shared/hooks/useIsMounted';
import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useActionRow } from 'shared/hooks/useActionRow';
import { DialogContext } from 'store/contexts';
import { EditFormProps as Props } from './definition';

const EditForm = ({
  isPageDirty,
  children,
  deleteButton = {},
  entityLabel,
  entityName = '',
  formMethods,
  onSave,
  onCancel,
}: Props) => {
  const {
    label: deleteLabel,
    confirmMessage: deleteConfirmMessage,
    isDisabled: isDeleteDisabled,
    tooltip: deleteTooltip,
    onClick,
  } = deleteButton;
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const dialogCtx = useContext(DialogContext);

  const {
    formState: { isSubmitSuccessful },
    reset,
  } = formMethods;

  const handleCancelClick = onCancel ?? (() => navigate('..'));

  const onConfirmDelete = () => {
    dialogCtx.onOpen({
      open: true,
      title: t('phone_system:containers.shared.edit_form.delete.title', { entity: entityLabel }),
      confirmMessage:
        deleteConfirmMessage ||
        t('phone_system:containers.shared.edit_form.delete.text', {
          name: entityName,
        }),
      onConfirm: () => onClick && onClick(),
    });
  };

  const onSaveHandler = async () => {
    setIsSaving(true);
    await onSave?.();
    if (isMounted()) {
      setIsSaving(false);
    }
  };

  // This resets the form state like dirty after saving successfully
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  useExitConfirmationDialog({ isDirty: isPageDirty });

  const { ActionRow, actionRowProps } = useActionRow({
    hasDelete: !!onClick,
    hasSave: true,
    isDeleteDisabled,
    isDirty: isPageDirty,
    isSaveDisabled: isSaving,
    breadcrumbData: [
      {
        text: entityLabel,
        url: handleCancelClick,
      },
      {
        text: entityName,
      },
    ],
    deleteLabel,
    deleteTooltip,
    onCancel: handleCancelClick,
    onDelete: onConfirmDelete,
    onSave: onSaveHandler,
  });

  return (
    <StyledEditForm onSubmit={(e: BaseSyntheticEvent) => e.preventDefault()}>
      <ActionRow {...actionRowProps} />
      <FormProvider {...formMethods}>{children}</FormProvider>
    </StyledEditForm>
  );
};

export default EditForm;
