import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useAutofillFieldsFromAddressField } from 'apps/shared/hooks/useAutofillFieldsFromAddressField';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddressAutoComplete from 'shared/components/AddressAutoComplete';
import { addressProvider } from 'shared/components/AddressAutoComplete/utility';
import { LabeledInput } from 'shared/components/Labeled';
import { FormInput } from '../../definition';
import ApplySiteLocationDialog from './components/ApplySiteLocationDialog';

type Props = TabPanelProps<FormInput>;

/**
 * TODO Field mapping
 */
export const fields = [
  'e911.street_address',
  'e911.extended_address',
  'e911.region',
  'e911.locality',
  'e911.postal_code',
  'e911.customer_name',
  'e911.notification_contact_emails',
];

const fieldsComponentMapping = [
  {
    fieldName: 'e911.street_address',
    type: 'street_address',
    valueProvider: addressProvider,
  },
  {
    fieldName: 'e911.extended_address',
    type: 'extended_address',
    valueProvider: addressProvider,
  },
  { fieldName: 'e911.postal_code', valueProvider: 'postal_code' },
  {
    fieldName: 'e911.region',
    hasShortName: true,
    valueProvider: 'administrative_area_level_1',
  },
  { fieldName: 'e911.locality', valueProvider: 'locality' },
];

const Emergency911Section: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const autofillFieldsFromAddressField = useAutofillFieldsFromAddressField(
    setValue,
    fieldsComponentMapping,
  );

  return (
    <>
      <h3>{t('numbers:containers.numbers_in_use.section.e911.description')}</h3>
      <div role="row">
        <div role="cell">
          <ApplySiteLocationDialog />
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Search Address */}
          <div id="map" />
          <AddressAutoComplete
            control={control}
            onChange={autofillFieldsFromAddressField}
            label={t('numbers:containers.numbers_in_use.section.e911.field.search_address.label')}
            inputProps={{
              adornment: {
                type: 'icon',
                position: 'start',
                value: 'search',
              },
            }}
            tooltip={t('numbers:containers.numbers_in_use.section.e911.field.search_address.info')}
          />
        </div>
      </div>

      <div role="row" className="overline">
        <div role="cell">
          {/* Street Address */}
          <HookFormInputWrapper name="e911.street_address">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t('numbers:containers.numbers_in_use.section.e911.field.street_address')}
                inputProps={{
                  placeholder: t(
                    'numbers:containers.numbers_in_use.section.e911.field.street_address',
                  ),
                  ...formProps,
                }}
                labelProps={{ required: true }}
                feedback={feedback}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* Additional Address Info */}
          <HookFormInputWrapper name="e911.extended_address">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'numbers:containers.numbers_in_use.section.e911.field.extended_address.label',
                )}
                inputProps={{
                  placeholder: t(
                    'numbers:containers.numbers_in_use.section.e911.field.extended_address.placeholder',
                  ),
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Locality (City) */}
          <HookFormInputWrapper name="e911.locality">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t('numbers:containers.numbers_in_use.section.e911.field.locality')}
                inputProps={{
                  placeholder: t('numbers:containers.numbers_in_use.section.e911.field.locality'),
                  ...formProps,
                }}
                feedback={feedback}
                isDirty={isDirty}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* Region (State/Province) */}
          <HookFormInputWrapper name="e911.region">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t('numbers:containers.numbers_in_use.section.e911.field.region')}
                inputProps={{
                  placeholder: t('numbers:containers.numbers_in_use.section.e911.field.region'),
                  ...formProps,
                }}
                feedback={feedback}
                isDirty={isDirty}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Postal Code */}
          <HookFormInputWrapper name="e911.postal_code">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t('numbers:containers.numbers_in_use.section.e911.field.postal_code')}
                inputProps={{
                  placeholder: t(
                    'numbers:containers.numbers_in_use.section.e911.field.postal_code',
                  ),
                  ...formProps,
                }}
                feedback={feedback}
                isDirty={isDirty}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Customer Name */}
          <HookFormInputWrapper name="e911.customer_name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t('numbers:containers.numbers_in_use.section.e911.field.customer_name')}
                inputProps={{
                  placeholder: t(
                    'numbers:containers.numbers_in_use.section.e911.field.customer_name',
                  ),
                  ...formProps,
                }}
                feedback={feedback}
                isDirty={isDirty}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* Contact Emails */}
          <HookFormInputWrapper name="e911.notification_contact_emails">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'numbers:containers.numbers_in_use.section.e911.field.notification_contact_emails.label',
                )}
                inputProps={{
                  placeholder: t(
                    'numbers:containers.numbers_in_use.section.e911.field.notification_contact_emails.placeholder',
                  ),
                  ...formProps,
                }}
                feedback={feedback}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
    </>
  );
};

export default Emergency911Section;
