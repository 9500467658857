import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import { FeedbackProps as Props, FeedbackType } from './definition';
import StyledFeedback from './style';

export { FeedbackType } from './definition';

const Feedback: FunctionComponent<Props> = (
  { type }: Props = { type: FeedbackType.ERROR },
): JSX.Element => {
  const { t } = useTranslation();

  const feedback = {
    [FeedbackType.ERROR]: {
      icon: {
        name: 'warning-filled',
        size: 16,
        color: 'core.color.orange',
      },
      message: t('common:auth.login.invalid_credentials'),
    },
    [FeedbackType.SUCCESS]: {
      icon: {
        name: 'radiobutton-checked-outlined',
        size: 16,
        color: 'core.color.green.dark',
      },
      message: t('common:auth.reset.dialog.success_message'),
    },
  };
  const { icon, message } = feedback[type];

  return (
    <StyledFeedback type={type}>
      <Icon name={icon.name} size={icon.size} themeColor={icon.color} />
      <Box component="span">{message}</Box>
    </StyledFeedback>
  );
};

export default Feedback;
