import { SelectOption } from 'shared/components/Select';
import { ShowToast } from 'shared/hooks/useToast';

type DataType = { id: string; username?: string; name?: string };

export const selectOptionMapper = (data: DataType): SelectOption => ({
  value: data.id,
  label: data.name ? data.name : data.username ? data.username : '',
});

export const onEmbeddedEditSaveSuccess = ({
  showToast,
  setIsEditDialogOpen,
}: {
  showToast: ShowToast;
  setIsEditDialogOpen: (value: boolean) => void;
}) => {
  showToast.success();
  setIsEditDialogOpen(false);
};
