import styled, { css } from 'styled-components';
import StyledLabeled from '../../style';
import { StyledLabeledButtonProps as Props } from './definition';

const StyledLabeledButton = styled(StyledLabeled)<Props>`
  ${(props) => css`
    > div > div > div {
      margin-left: -4px;
    }
  `}
`;

export default StyledLabeledButton;
