import { RouterLink } from 'apps/shared/components/RouterLink';
import Icon from 'shared/components/Icon';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { EditIconProps as Props } from './definition';

const EditIcon = ({ id, path }: Props) => {
  const icon = <Icon hasHover color="secondary" name="edit" />;

  return id || path ? (
    <RouterLink
      className="icon"
      data-test-id={`${TEST_ID.COMMON.PREFIX.EDIT_ICON}${id ? `-${id}` : ''}`}
      to={path ?? `./${id}`}
    >
      {icon}
    </RouterLink>
  ) : (
    icon
  );
};

export default EditIcon;
