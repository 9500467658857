import { ADD_KEY } from 'constant';
import { useFetchMediasQuery } from 'models/Media';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import { columns } from './utility';

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data = [], isLoading } = useFetchMediasQuery();

  const filteredData = useMemo(() => data.filter(({ is_prompt }: any) => !is_prompt), [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table
      hasSearch
      addButton={{
        hasAdd: true,
        label: t('phone_system:containers.media.table.action_row.button.add_media.label'),
        onAdd: () => navigate(`./${ADD_KEY}`),
      }}
      columns={columns()}
      data={filteredData}
      title={t('phone_system:containers.media.table.title')}
    />
  );
};

export default List;
