import { CONSTANTS as HORIZONTAL_NAV_CONSTANTS } from 'apps/shared/components/HorizontalNav/components/NavItem/style';
import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { StyledAdvancedProvisionerProps as Props } from './definition';

export const StyledAdvancedProvisioner = styled(Box)<Props>`
  ${({ $hasHorizontalNav }) => css`
    display: grid;
    height: 100%;
    overflow: clip;

    ${$hasHorizontalNav &&
    css`
      grid-template: ${HORIZONTAL_NAV_CONSTANTS.HEIGHT} 1fr / 1fr;
    `}
  `}
`;
