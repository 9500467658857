import { triggerDownload } from 'apps/shared/utility';
import { MEDIA_TYPE } from 'constant';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import { useFetchAccountQuery } from 'models/Account';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useFetchSeatsQuery } from 'models/Seat';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import Tooltip from 'shared/components/Tooltip';
import { useBuildCallflows } from './hooks/useBuildCallflows';
import { getCsvBlob } from './utility';

const DownloadIcon = () => {
  const { t } = useTranslation();
  const [isBuildingDownloadValues, setIsBuildingDownloadValues] = useState<boolean>(false);
  const getCallFlows = useBuildCallflows();

  const {
    data: { ooma_cellular_bridge: bridgeNumber = '', name: accountName } = {},
  } = useFetchAccountQuery();
  const { data: phoneNumbersData } = useFetchPhoneNumbersQuery();
  const { data: seatsData } = useFetchSeatsQuery();

  const hasNumbersData = !isEmpty(phoneNumbersData?.numbers);

  const getDownloadValues = useCallback(async () => {
    setIsBuildingDownloadValues(true);
    const callflows = await getCallFlows();

    return accountName && callflows && phoneNumbersData && seatsData
      ? {
          name: t('numbers:action_row.download.file.name', {
            name: kebabCase(accountName),
          }),
          url: URL.createObjectURL(
            new Blob(
              getCsvBlob({ callflowsData: callflows, phoneNumbersData, seatsData, bridgeNumber }),
              {
                type: MEDIA_TYPE.TYPE.TEXT.CSV.WITH_CHARSET,
              },
            ),
          ),
        }
      : { name: '', url: '' };
  }, [accountName, bridgeNumber, phoneNumbersData, seatsData, getCallFlows, t]);

  const onClick = async () => {
    triggerDownload(await getDownloadValues());
    setIsBuildingDownloadValues(false);
  };

  return isBuildingDownloadValues ? (
    <Loading hasLabel={false} />
  ) : (
    <Tooltip
      title={
        hasNumbersData
          ? t('numbers:action_row.download.tooltip.download_all_numbers')
          : t('numbers:action_row.download.tooltip.no_available_numbers')
      }
    >
      <Icon
        name="download"
        {...(hasNumbersData ? { hasHover: true, onClick } : { color: 'disabled' })}
      />
    </Tooltip>
  );
};

export default DownloadIcon;
