import cloneDeep from 'lodash/cloneDeep';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as utility from 'shared/utility';
import Dialog, { DialogActions, DialogActionsCloseReasons } from '../Dialog';
import Icon from '../Icon';
import { SelectOption } from '../Select';
import TableCellBody from './components/TableCell/Body';
import TableCellFoot from './components/TableCell/Foot';
import defaultProps from './default';
import { Column, EditableListProps as Props, FieldType, Justify } from './definition';
import StyledEditableList from './style';
import { getFieldType, reset } from './utility';

export { default as defaultProps } from './default';
export { Justify as EditableListJustify } from './definition';
export type {
  Column as EditableListColumn,
  Data as EditableListData,
  EditableListProps,
} from './definition';

const EditableList: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    isWindowedSelect = false,
    data: { columns, rows },
    dataTestId,
    onUpdate,
    ...rest
  } = { ...defaultProps, ...props };

  const [list, setList] = useState<any>(rows);
  const [isAddDisabled, setIsAddDisabled] = useState<boolean>(true);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [input, setInput] = useState<string[]>(reset(columns).input);
  const [select, setSelect] = useState<SelectOption[]>(reset(columns).select);
  const [rowIndex, setRowIndex] = useState<number>();
  const { t } = useTranslation();
  const type = getFieldType(columns);

  const item = {
    add: (columns: any) => {
      const _list = cloneDeep(list);

      // assign the chosen value(s) to the list
      switch (type) {
        case FieldType.Select:
          (_list as SelectOption[][]).push([...select]);
          break;
        case FieldType.Textarea: {
          const values = input[0] // TODO: Implement (looped) column indices with `input` variable
            .split(/[\n,]+/)
            .filter((e) => e.length > 0)
            .map((e) => [e.trim()]);
          (_list as string[][]).push(...[...values]);
          break;
        }
        case FieldType.Input:
        default:
          (_list as string[][]).push([...input]);
          break;
      }

      // reset the state values (which load the value to be added)
      setInput(reset(columns).input);
      setSelect(reset(columns).select);

      // update the list (locally and parent/calling component)
      setList(_list);
      onUpdate(_list);

      setIsAddDisabled(true);
    },
    // TODO: Update delete to use id rather than index
    delete: (i: number) => {
      if (type === FieldType.Select) {
        const filter = (item: SelectOption[], j: number) => i !== j;
        setList((list: any) => (list as SelectOption[][]).filter(filter));
        onUpdate((list as SelectOption[][]).filter(filter));
      } else {
        const filter = (item: string[], j: number) => i !== j;
        setList((list: any) => (list as string[][]).filter(filter));
        onUpdate((list as string[][]).filter(filter));
      }

      setIsAddDisabled(true);
    },
  };

  const handleChange = {
    input: (e: any, i: number, validationMask?: (value: string) => boolean) => {
      const { value } = e.target;

      // confirm that the input value conforms to the given input validation mask
      if (!validationMask || validationMask?.(value)) {
        setInput((input: any) => {
          const _input = [...input];
          _input[i] = value;
          setIsAddDisabled(!_input.every((value: string) => value !== ''));
          return _input;
        });
      }
    },

    select: (i: number, selected: any) => {
      setSelect((select: any) => {
        const _select = [...select];
        _select[i] = selected;
        setIsAddDisabled(
          columns.length > 1 ? !_select.every((option: SelectOption) => option !== null) : false, // TODO: Rework logic
        );
        return _select;
      });
    },
  };

  const handleDialog = {
    action: async (closeResponse: { reason: DialogActionsCloseReasons }) => {
      switch (closeResponse.reason) {
        case 'deleteClicked':
          item.delete(rowIndex as number);
          break;
        case 'cancelClicked':
        default:
          setRowIndex(undefined);
          break;
      }
      setIsDialogOpen(false);
    },
    state: (isOpen = false, index?: number): void => {
      setRowIndex(typeof index === 'undefined' ? undefined : index);
      setIsDialogOpen(isOpen);
    },
  };

  useEffect(() => {
    setList(rows);
  }, [rows]);

  return (
    <StyledEditableList {...rest} data-test-id={dataTestId ?? defaultProps.dataTestId}>
      {columns.length > 0 && (
        <>
          <thead>
            <tr>
              {columns.map(({ header, width }: any, i: number) => (
                <th
                  key={utility.generateKey(i, 'head-th')}
                  className={header.justify || Justify.Left}
                  style={{ width }}
                >
                  {header.text}
                </th>
              ))}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((row: any, i: number) => (
              <tr id={utility.generateKey(i, 'body-tr')} key={utility.generateKey(i, 'body-tr')}>
                {columns.map((column: Column, j: number) => (
                  <TableCellBody
                    key={utility.generateKey(j, `${i}-body-tr-td`)}
                    column={column}
                    columnIndex={j}
                    list={list}
                    row={row}
                  />
                ))}
                <td>
                  <Icon
                    hasHover
                    color="error"
                    name="minus-circle-outlined"
                    size={22}
                    onClick={() => handleDialog.state(true, i)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              {columns.map((column: Column, i: number) => (
                <TableCellFoot
                  isWindowedSelect
                  column={column}
                  columnIndex={i}
                  key={utility.generateKey(i, 'foot-td')}
                  list={list}
                  state={{
                    input,
                    select,
                    setSelect,
                  }}
                  handleChange={handleChange}
                  reset={reset(columns)}
                />
              ))}
              <td>
                <Icon
                  hasHover
                  name="plus-circle-outlined"
                  size={22}
                  onClick={() => !isAddDisabled && item.add(columns)}
                />
              </td>
            </tr>
          </tfoot>
        </>
      )}
      <Dialog
        open={isDialogOpen}
        title={t('common:component.editable_list.dialog.title')}
        renderActions={<DialogActions hasDelete onAction={handleDialog.action} />}
        onClose={() => handleDialog.state()}
      >
        <span>{t('common:component.editable_list.dialog.content')}</span>
      </Dialog>
    </StyledEditableList>
  );
};

export default EditableList;
