import theme from '../core';

export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      '&.MuiList-select-filter': {
        backgroundColor: theme.palette.common.white,
      },
      '&.MuiList-snackbar': {
        padding: 0,
        '.MuiListItem-root': {
          padding: 0,
          alignItems: 'flex-start',
          lineHeight: '24px',
          span: {
            '&:first-of-type': {
              padding: theme.spacing(0, 1.5, 0, 0.33),
              fontSize: 24,
              transform: 'translateY(-1px)',
            },
          },
        },
      },
    },
  },
};
