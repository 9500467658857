import { AppMeta } from 'apps/shared/definition';
import CallCenter from '.';

export const meta: AppMeta = {
  component: CallCenter,
  icon: 'call-center-1',
  name: 'callcenter-ooma',
  slug: 'call-center',
};

export const appUrl = `/apps/${meta.slug}`;
