import * as utility from 'shared/utility';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { MessageType, StyledInputMessageProps as Props } from './definition';

const StyledInputMessage = styled.div<Props>`
  ${(props) => css`
    ${props.isAbove &&
    css`
      margin: 0 0 16px;
    `};

    > div {
      ${mixin.flex({ isInline: true, alignItems: 'flex-start', justifyContent: 'flex-start' })};
      margin: 4px 0 0;

      &:first-of-type {
        margin-top: ${props.isAbove ? 2 : 8}px;
      }

      > svg {
        fill: ${utility.getFeedbackColor(props, props.type as MessageType)};
        min-width: 24px;
      }

      > span {
        ${mixin.font({ lineHeight: 1.3, weight: 400 })};
        color: ${utility.getFeedbackColor(props, props.type as MessageType)};
        margin: -2px 0 0 2px;

        ${props.isNoWrap &&
        css`
          white-space: nowrap;
        `};
      }
    }
  `}
`;

export default StyledInputMessage;
