import QueueCard, { QueueCardData } from 'apps/CallCenter/shared/components/QueueCard';
import { BaseSyntheticEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CONSTANTS from '../../constants';
import NoItemsText, { DashboardItemType } from '../NoItemsText';
import { DashboardQueuesProps as Props } from './definition';
import StyledDashboardQueues from './style';

const DashboardQueues: FunctionComponent<Props> = ({
  allQueuesSummary,
  queuesList,
  selectedQueueId,
  setSelectedQueueId,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { title, ...rest } = allQueuesSummary as QueueCardData;
  const navigate = useNavigate();

  return (
    <StyledDashboardQueues data-test-id="call-center-dashboard-dashboard-queues">
      {!!queuesList && queuesList?.length > 0 ? (
        <>
          {allQueuesSummary && (
            <QueueCard
              hasMenu={false}
              isSelected={CONSTANTS.ALL_QUEUES_ID === selectedQueueId}
              data={{ ...rest }}
              id={CONSTANTS.ALL_QUEUES_ID}
              headerProps={{
                title: t('call_center:containers.dashboard.section.queues.all_queues'),
              }}
              onClick={{
                default: () => setSelectedQueueId(CONSTANTS.ALL_QUEUES_ID),
              }}
            />
          )}
          {queuesList
            .sort((a: QueueCardData, b: QueueCardData) => a.title.localeCompare(b.title))
            .map(({ hasMenu, isSelected, id, title, ...rest }: QueueCardData) => (
              <QueueCard
                key={id}
                hasMenu={hasMenu}
                isSelected={id === selectedQueueId}
                data={{ ...rest }}
                id={id as string}
                headerProps={{ title }}
                onClick={{
                  default: () => setSelectedQueueId(id as string),
                  edit: {
                    button: (e: BaseSyntheticEvent) => {
                      e.stopPropagation();
                    },
                    menu: () => navigate(`../manage-queues/${id}`),
                  },
                  hide: () => {},
                }}
              />
            ))}
        </>
      ) : (
        <NoItemsText
          type={DashboardItemType.QUEUES}
          value={t('call_center:containers.dashboard.section.queues.no_queues_to_display')}
        />
      )}
    </StyledDashboardQueues>
  );
};

export default DashboardQueues;
