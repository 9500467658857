import styled, { css } from 'styled-components';
import { StyledDrawerContentUpperProps as Props } from './definition';

const StyledDrawerContentUpper = styled.div<Props>`
  ${(props) => css`
    padding: 0 ${props.theme.spacing(2)};
  `};
`;

export default StyledDrawerContentUpper;
