import { BRIDGE_NUMBER_SELECT_UNSET_OPTION } from '../constant';
import { BridgeNumberChangeAction, GetBridgeNumberChangeActionParam } from '../definition';

/**
 * @name getBridgeCallflowData
 * @description Get the callflow data for creating or updating a bridge callflow
 * @param bridgeNumber
 * @returns the callflow data for creating or updating a bridge callflow
 */
export const getBridgeCallflowData = (bridgeNumber?: string) => ({
  flow: {
    data: {},
    module: 'ooma_cellular',
    children: {
      _: {
        data: { id: '/system_media/fault-can_not_be_completed_at_this_time' },
        module: 'play',
        children: {},
      },
    },
  },
  numbers: [bridgeNumber],
  patterns: [],
  metadata: {},
});

/**
 * @name hasNumber
 * @description Check if the input number is a valid number. If the number is falsy or equal to the unset value, it is not a valid number.
 * @param number
 * @returns whether the input number is a valid number
 */
export const hasNumber = (number?: string) =>
  !!number && number !== BRIDGE_NUMBER_SELECT_UNSET_OPTION.value;

/**
 * @name getBridgeNumberChangeAction
 * @description Get the action to take when the bridge number changes
 * @param initialNumber
 * @param nextNumber
 * @returns bridge number change action
 */
export const getBridgeNumberChangeAction = ({
  initialNumber,
  nextNumber,
}: GetBridgeNumberChangeActionParam): BridgeNumberChangeAction => {
  const hasInitialNumber = hasNumber(initialNumber);
  const hasNextNumber = hasNumber(nextNumber);

  if (nextNumber === initialNumber) {
    return BridgeNumberChangeAction.Unmodified;
  }

  // Assign new number
  if (!hasInitialNumber && hasNextNumber) {
    return BridgeNumberChangeAction.Assign;
  }

  if (hasInitialNumber) {
    // Unassign number
    if (!hasNextNumber) {
      return BridgeNumberChangeAction.Unassign;
    }
    // Switch number
    if (initialNumber !== nextNumber) {
      return BridgeNumberChangeAction.Switch;
    }
  }

  // Should not reach here but just in case
  return BridgeNumberChangeAction.Unmodified;
};
