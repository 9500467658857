import { MessageType } from 'shared/components/InputMessage/definition';
import { WidthType } from 'shared/utility/definition';
import labeledProps from '../../default';

const defaultProps = {
  ...labeledProps,
  label: '__BUTTON_LABEL__',
  labelWidth: 'large' as WidthType,
  labelProps: {},
  message: {
    type: 'secondary' as MessageType,
  },
  tooltipWidth: 'small' as WidthType,
};

export default defaultProps;
