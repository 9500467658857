import { GroupBase } from 'react-select';
import Select, { Props } from '../Select';
import { WindowedMenuList } from './components/WindowedMenuList';

export const WindowedSelect = <
  Option,
  IsMulti extends boolean,
  GroupType extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, GroupType>,
) => <Select {...{ ...props, overrideComponents: { MenuList: WindowedMenuList } }} />;
