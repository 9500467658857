import MoveToDropdownButton from 'apps/shared/components/MoveToDropdownButton';
import { RowArray, SetRowArray } from 'apps/shared/definition';
import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Trans } from 'react-i18next';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import { SelectOption } from 'shared/components/Select';
import { maybeTooltipActionRowButton } from 'shared/hooks/useActionRow/utility';
import { DidObject } from './definition';

/**
 * @name buildActionRowButtons
 * @description Build the ActionRow buttons (element)
 *
 * @param [object]
 * @property selectedRows
 * @property serverName
 * @property servers
 * @property setConfirmData
 * @property setIsAssignDialogOpen
 * @property setIsDialogUnassign
 * @property setMoveToIndex
 *
 * @returns ActionRow buttons element
 */
export const buildActionRowButtons = ({
  selectedRows,
  serverName,
  servers = [],
  setConfirmData,
  setIsAssignDialogOpen,
  setIsDialogUnassign,
  setMoveToIndex,
}: {
  selectedRows: RowArray;
  serverName: string;
  servers: Array<any>;
  setConfirmData: SetRowArray;
  setMoveToIndex: (target: number) => void;
  setIsDialogUnassign: (value: boolean) => void;
  setIsAssignDialogOpen: (isOpen: boolean) => void;
}): JSX.Element => {
  const hasSelectedRows = selectedRows.length > 0;
  const availableServers = servers
    .filter(({ server_name }: Record<string, any>) => server_name !== serverName)
    .map(
      ({ server_name }: Record<string, any>): SelectOption => ({
        label: server_name,
        value: servers.findIndex((server) => server.server_name === server_name),
      }),
    );

  return (
    <>
      <Button
        color="primary"
        disabled={false}
        variant="outlined"
        startIcon={<Icon name="plus-circle-outlined" size={18} />}
        onClick={() => setIsAssignDialogOpen(true)}
      >
        {i18next.t(
          'sip_trunking:containers.default.components.assigned_numbers_list.action_row.assign.label',
        )}
      </Button>
      {maybeTooltipActionRowButton({
        hasSelectedRows,
        component: (
          <Button
            color="error"
            disabled={!hasSelectedRows}
            startIcon={<Icon name="minus-circle-outlined" size={18} />}
            variant="outlined"
            onClick={() => {
              setConfirmData(selectedRows);
              setIsDialogUnassign(true);
            }}
          >
            {i18next.t(
              'sip_trunking:containers.default.components.assigned_numbers_list.action_row.unassign.label',
            )}
          </Button>
        ),
        tooltip: i18next.t(
          'sip_trunking:containers.default.components.assigned_numbers_list.action_row.unassign.tooltip',
        ),
      })}
      <MoveToDropdownButton
        hasSelectedRows={hasSelectedRows}
        items={availableServers.map(({ label, value }: SelectOption) => ({
          label,
          value,
          onClick: () => {
            setConfirmData(selectedRows);
            setMoveToIndex(value);
            setIsDialogUnassign(false);
          },
        }))}
        info={i18next.t(
          'sip_trunking:containers.default.components.assigned_numbers_list.action_row.move.info',
        )}
        noItemsText={i18next.t(
          'sip_trunking:containers.default.components.assigned_numbers_list.action_row.move.no_items_text',
        )}
        tooltip={i18next.t(
          'sip_trunking:containers.default.components.assigned_numbers_list.action_row.move.disabled_tooltip',
        )}
      />
    </>
  );
};

/**
 * @name getDialogProps
 * @description Build the ConfirmSelectionDialog component props
 *
 * @param [object]
 * @property isDialogUnassign
 * @property serverName
 * @property confirmData
 * @property handle
 * @property setConfirmData
 *
 * @returns {object}
 */
export const getDialogProps = ({
  isDialogUnassign,
  confirmData,
  serverName: { from, to },
  on,
  setConfirmData,
}: {
  isDialogUnassign: boolean;
  confirmData: RowArray;
  serverName: Record<string, string>;
  on: Record<string, (parameter: any) => void>;
  setConfirmData: SetRowArray;
}) => ({
  isDelete: isDialogUnassign,
  isOpen: !isEmpty(confirmData),
  confirmData,
  dialogMessage: (
    <Box component="span">
      {!isDialogUnassign ? (
        <Trans
          i18nKey="sip_trunking:containers.default.components.assigned_numbers_list.dialog.move.prompt.0"
          components={{ bold: <strong /> }}
          values={{
            count: confirmData.length,
            name: { from, to },
          }}
        />
      ) : (
        <Trans
          i18nKey="sip_trunking:containers.default.components.assigned_numbers_list.dialog.unassign.prompt.0"
          values={{
            count: confirmData.length,
          }}
        />
      )}
    </Box>
  ),
  dialogTitle: !isDialogUnassign
    ? i18next.t(
        'sip_trunking:containers.default.components.assigned_numbers_list.dialog.move.title',
      )
    : i18next.t(
        'sip_trunking:containers.default.components.assigned_numbers_list.dialog.unassign.title',
      ),
  header: {
    text: from ? (
      <Trans
        i18nKey="common:component.confirm_selection_dialog.from"
        components={{ bold: <strong /> }}
        values={{ name: from }}
      />
    ) : undefined,
  },
  confirmButtonProps: isDialogUnassign
    ? {
        color: 'error',
        label: i18next.t(
          'sip_trunking:containers.default.components.assigned_numbers_list.dialog.unassign.confirm',
        ),
        variant: 'contained',
      }
    : {
        color: 'primary',
        label: i18next.t('common:confirm'),
        variant: 'contained',
      },
  onConfirm: !isDialogUnassign ? on.move : on.unassign,
  setConfirmData,
});

/**
 * @name getDidObjects
 * @description Get the matching (to) and nonmatching (from) DID objects
 *
 * @param [object]
 * @property didList
 * @property didObject
 *
 * @returns DID object
 */
export const getDidObjects = ({
  didList,
  didObject,
}: {
  didList: Array<string>;
  didObject: DidObject;
}): Record<string, DidObject> => {
  const match: Record<string, DidObject> = {
    to: {},
    from: { ...didObject },
  };

  didList.forEach((did: string) => {
    if (did in didObject) {
      match.to[did] = didObject[did];
      delete match.from[did];
    }
  });

  return match;
};

/**
 * @name hasNumberAnSmsBox
 * @description Checks if a phone number has an associated SMS Box
 *
 * @param smsData - The SMS data
 * @param phoneNumber - The phone number
 *
 * @returns - Returns true if the phone number has an associated SMS Box; otherwise, returns false
 */
export const hasNumberAnSmsBox = (
  smsData: SMSResponse | undefined,
  phoneNumber: string | undefined,
) => {
  if (!smsData || !phoneNumber) {
    return false;
  }
  return Boolean(smsData.find((item: SMS) => item.numbers && item.numbers.includes(phoneNumber)));
};
