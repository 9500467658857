import MediaSelect from 'apps/PhoneSystem/shared/MediaSelect';
import { FormContext } from 'apps/shared/components/FormContext';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import get from 'lodash/get';
import { FunctionComponent, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LabeledSelect from 'shared/components/Labeled/components/LabeledSelect';
import { defaultValues } from '../../default';
import { CodecSections, FormInput } from '../../definition';
import { DataContext } from '../../utility';
import CodecEnabledCheckbox from './components/CodecEnabledCheckbox';
import { getCheckboxGroupInitialValues } from './utility/getCheckboxGroupInitialValues';

interface Props extends TabPanelProps<FormInput> {
  apiData?: any;
}

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  `media.${CodecSections.Audio}.codecs`,
  `media.${CodecSections.Video}.codecs`,
  'music_on_hold',
  'media.peer_to_peer',
  'media.encryption.methods',
];

/**
 * These const could be exported out into a global location
 * once we have more constant data of the same format.
 *
 * To add another codec;
 * - Add to associated Media list
 * - Add translation to list of codec translations
 *
 * t('common:supported.codecs.audio.opus')
 * t('common:supported.codecs.audio.celt@32000h')
 * t('common:supported.codecs.audio.g7221@32000h')
 * t('common:supported.codecs.audio.g7221@16000h')
 * t('common:supported.codecs.audio.g722')
 * t('common:supported.codecs.audio.speex@32000h')
 * t('common:supported.codecs.audio.speex@16000h')
 * t('common:supported.codecs.audio.pcmu')
 * t('common:supported.codecs.audio.pcma')
 * t('common:supported.codecs.audio.g729')
 * t('common:supported.codecs.audio.gsm')
 * t('common:supported.codecs.audio.celt@48000h')
 * t('common:supported.codecs.audio.celt@64000h')
 *
 * video:
 * t('common:supported.codecs.video.vp8')
 * t('common:supported.codecs.video.h264')
 * t('common:supported.codecs.video.h263')
 * t('common:supported.codecs.video.h261')
 */
const SUPPORTED_AUDIO_CODECS = [
  'OPUS',
  'CELT@32000h',
  'G7221@32000h',
  'G7221@16000h',
  'G722',
  'speex@32000h',
  'speex@16000h',
  'PCMU',
  'PCMA',
  'G729',
  'GSM',
  'CELT@48000h',
  'CELT@64000h',
];

const SUPPORTED_VIDEO_CODECS = ['VP8', 'H264', 'H263', 'H261'];

const AudioVideoSection: FunctionComponent<Props> = ({
  apiData: propApiData,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const contextFormData = useContext(FormContext);
  const contextApiData = useContext(DataContext);
  const apiData = propApiData || contextApiData;

  const audioValues = get(
    apiData,
    `media.${CodecSections.Audio}.codecs`,
    getCheckboxGroupInitialValues(contextFormData, defaultValues.media.audio.codecs),
  );
  const videoValues = get(
    apiData,
    `media.${CodecSections.Video}.codecs`,
    getCheckboxGroupInitialValues(contextFormData, defaultValues.media.video.codecs),
  );

  const secureRtpOptions = [
    {
      value: '',
      label: t('phone_system:containers.devices.section.audio_video.secure_rtp_options.not_set'),
    },
    { value: 'srtp', label: 'SRTP' },
    { value: 'zrtp', label: 'ZRTP' },
  ];

  const peerToPeerAudioOptions = [
    {
      value: 'auto',
      label: t('phone_system:containers.devices.section.audio_video.peer_to_peer_audio.automatic'),
    },
    {
      value: 'always',
      label: t('phone_system:containers.devices.section.audio_video.peer_to_peer_audio.always'),
    },
    {
      value: 'never',
      label: t('phone_system:containers.devices.section.audio_video.peer_to_peer_audio.never'),
    },
  ];

  return (
    <>
      <h2>{t('phone_system:containers.devices.section.audio_video.music_on_hold')}</h2>
      <div role="row">
        <div role="cell">
          {/* Music on Hold */}
          {/*
            TODO:
              For some reason this would crash seats edit device
              name: music_on_hold.media_id
          */}
          <MediaSelect name="music_on_hold.media_id" isClearable={false} />
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Peer to Peer Audio */}
          <HookFormSelectWrapper name="media.peer_to_peer" options={peerToPeerAudioOptions}>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSelect
                label={t(
                  'phone_system:containers.devices.section.audio_video.peer_to_peer_audio.label',
                )}
                selectProps={{
                  ...formProps,
                  id: 'input-av-peer-to-peer-audio',
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormSelectWrapper>
        </div>

        <div role="cell">
          {/* Secure RTP */}
          <Controller
            name={`${contextFormData}media.encryption.methods`}
            render={({
              field: { ref, onChange, value, ...rest },
              fieldState: { isDirty },
            }: any) => {
              const adjValue = !value || value.length === 0 ? '' : value[0];
              const currentSelection = secureRtpOptions.find((c) => c.value === adjValue);

              const handleSelectChange = (selectedOption: any | null) => {
                onChange(selectedOption?.value === '' ? [] : [selectedOption?.value]);
              };

              return (
                <LabeledSelect
                  label={t('phone_system:containers.devices.section.audio_video.secure_rtp')}
                  labelWidth="large"
                  selectWidth="small"
                  selectProps={{
                    ...rest,
                    value: currentSelection,
                    onChange: handleSelectChange,
                    options: secureRtpOptions,
                    id: 'select-av-secure-RTP',
                  }}
                  isDirty={isDirty}
                />
              );
            }}
          />
        </div>
      </div>

      <div role="row">
        <div role="cell">
          <h2>{t('phone_system:containers.devices.section.audio_video.codecs.audio.label')}</h2>
          {SUPPORTED_AUDIO_CODECS.map((codec) => (
            <CodecEnabledCheckbox
              section={CodecSections.Audio}
              name={codec}
              key={codec}
              initialValues={audioValues}
            />
          ))}
        </div>

        <div role="cell">
          <h2>{t('phone_system:containers.devices.section.audio_video.codecs.video.label')}</h2>
          {SUPPORTED_VIDEO_CODECS.map((codec) => (
            <CodecEnabledCheckbox
              section={CodecSections.Video}
              name={codec}
              key={codec}
              initialValues={videoValues}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AudioVideoSection;
