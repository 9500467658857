import i18next from 'i18next';

const translations = () => ({
  components: {
    days_of_week: {
      label: i18next.t(
        'phone_system:containers.time_of_day.section.options.components.days_of_week.label',
      ),
      tooltip: i18next.t(
        'phone_system:containers.time_of_day.section.options.components.days_of_week.tooltip',
      ),
    },
    on: {
      wdays: {
        placeholder: i18next.t('common:component.select.placeholder.select'),
      },
    },
  },
  fields: {
    name: {
      label: i18next.t('phone_system:containers.time_of_day.section.options.field.name.label'),
    },
    start_date: {
      label: i18next.t(
        'phone_system:containers.time_of_day.section.options.field.start_date.label',
      ),
    },
    time_window_start: {
      label: i18next.t(
        'phone_system:containers.time_of_day.section.options.field.time_window_start.label',
      ),
    },
    time_window_stop: {
      label: i18next.t(
        'phone_system:containers.time_of_day.section.options.field.time_window_stop.label',
      ),
    },
    is_all_day_event: {
      label: i18next.t(
        'phone_system:containers.time_of_day.section.options.field.is_all_day_event.label',
      ),
    },
    cycle: {
      label: i18next.t('phone_system:containers.time_of_day.section.options.field.cycle.label'),
    },
    month: {
      label: i18next.t('phone_system:containers.time_of_day.section.options.field.month.label'),
    },
    interval: {
      label: i18next.t('phone_system:containers.time_of_day.section.options.field.interval.label'),
      suffix: {
        months: i18next.t(
          'phone_system:containers.time_of_day.section.options.field.interval.suffix.months',
        ),
        weeks: i18next.t(
          'phone_system:containers.time_of_day.section.options.field.interval.suffix.weeks',
        ),
      },
    },
    ordinal: {
      label: i18next.t('phone_system:containers.time_of_day.section.options.field.ordinal.label'),
    },
    days: {
      label: i18next.t('phone_system:containers.time_of_day.section.options.field.days.label'),
    },
    end_date: {
      label: i18next.t('phone_system:containers.time_of_day.section.options.field.end_date.label'),
    },
    has_no_end_date: {
      label: i18next.t(
        'phone_system:containers.time_of_day.section.options.field.has_no_end_date.label',
      ),
    },
    enabled: {
      label: i18next.t('phone_system:containers.time_of_day.section.options.field.enabled.label'),
    },
  },
});

export default translations;
