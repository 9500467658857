import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const Menu = {
  // GET
  Fetch: (isPaginated: boolean = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/menus?paginate=${isPaginated}`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/menus/${id}`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/menus`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/menus/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/menus/${id}`,
};
