import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import { CONSTANTS as HORIZONTAL_NAV_CONSTANTS } from 'apps/shared/components/HorizontalNav/components/NavItem/style';
import styled from 'styled-components';
import { UPPER_ACTION_ROW_CONSTANTS } from './components/UpperActionRow/styles';

const StyledNumbers = styled.div`
  display: grid;
  grid-template: ${UPPER_ACTION_ROW_CONSTANTS.HEIGHT} ${HORIZONTAL_NAV_CONSTANTS.HEIGHT} 1fr / 1fr;
  height: 100%;
  overflow: clip;

  > div:last-of-type {
    width: 100vw;
    height: ${`calc(100vh - (${HEADER_CONSTANTS.HEIGHT} + ${UPPER_ACTION_ROW_CONSTANTS.HEIGHT} + ${HORIZONTAL_NAV_CONSTANTS.HEIGHT}))`};
  }
`;

export default StyledNumbers;
