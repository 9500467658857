import { SnackbarMessage, useSnackbar, VariantType } from 'notistack';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import List from 'shared/components/List';
import { Options } from './definition';

export type { ShowToast } from './definition';

export const useToast = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const showToast = useMemo(() => {
    const showSnackbar = (
      variant: VariantType,
      message?: SnackbarMessage,
      { isBulletList }: Options = {},
    ) => {
      const DISPLAY_OFFSET = 250;

      if (message) {
        if (Array.isArray(message)) {
          if (isBulletList) {
            enqueueSnackbar(
              <List isBulleted classes={{ root: 'MuiList-snackbar' }} items={message} />,
              { variant },
            );
          } else {
            message.forEach((item: string, i: number) => {
              setTimeout(() => {
                enqueueSnackbar(item, { variant });
              }, i * DISPLAY_OFFSET);
            });
          }
        } else {
          enqueueSnackbar(message, { variant });
        }
      }
    };

    return {
      error: (message?: SnackbarMessage, { isBulletList }: Options = {}) =>
        showSnackbar('error', message ?? t('common:error'), { isBulletList }),
      info: (message?: SnackbarMessage, { isBulletList }: Options = {}) =>
        showSnackbar('info', message, { isBulletList }),
      success: (message?: SnackbarMessage, { isBulletList }: Options = {}) =>
        showSnackbar('success', message ?? t('common:saved'), { isBulletList }),
      warning: (message?: SnackbarMessage, { isBulletList }: Options = {}) =>
        showSnackbar('warning', message, { isBulletList }),
      get delete() {
        return {
          error: (message?: SnackbarMessage, { isBulletList }: Options = {}) =>
            showToast.error(message ?? t('common:delete_error'), { isBulletList }),
          success: (message?: SnackbarMessage, { isBulletList }: Options = {}) =>
            showToast.success(message ?? t('common:delete_success'), { isBulletList }),
        };
      },
    };
  }, [enqueueSnackbar, t]);

  return { showToast };
};
