import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

const excludedFields: { [key: string]: string[] } = { members: ['id', 'name'] };

const hasAnyTrueFields = (object: { [key: string]: string[] }, excludedFields: string[] = []) =>
  Object.keys(object).some(
    (key: string) => !excludedFields.includes(key) && get(object, key, false),
  );

const flattenDirtyArray = (arrayToFlatten: Array<any>, key: string) =>
  excludedFields[key]
    ? arrayToFlatten.some((object) => hasAnyTrueFields(object, excludedFields[key]))
    : arrayToFlatten.some((value) => value);

export const areTabFieldsDirty = (
  dirtyFields: { [key: string]: any },
  tabFields: string[],
  contextName: string = '',
) =>
  tabFields.some((field: string) =>
    isFieldDirty(get(dirtyFields, `${contextName}${field}`, false), field),
  );

export const flattenDirtyObject = (objectToFlatten: { [key: string]: any }): any =>
  Object.keys(objectToFlatten).some((key: string) => {
    const fieldValue = objectToFlatten[key];
    if (isObject(fieldValue)) {
      return flattenDirtyObject(fieldValue);
    } else if (isArray(fieldValue)) {
      return flattenDirtyArray(fieldValue, key);
    } else {
      return fieldValue;
    }
  });

export const isCompoundFieldDirty = (dirtyFields: { [key: string]: any }, fieldName: string) =>
  isFieldDirty(get(dirtyFields, fieldName, false), fieldName.split('.').pop());

export const isFieldDirty = (fieldValue: { [key: string]: any }, fieldKey: string = '') => {
  if (isArray(fieldValue)) {
    return flattenDirtyArray(fieldValue, fieldKey);
  } else if (isObject(fieldValue)) {
    return flattenDirtyObject(fieldValue);
  } else {
    return fieldValue;
  }
};
