import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { style } from '../mixin';
import { getValue } from '../utility';
import { StyledDrawerUpperProps as Props } from './definition';

const StyledDrawerUpper = styled.div<Props>`
  ${({ hasPadding, isOpen, height, width, theme }) => css`
    padding: 0;

    > div {
      ${mixin.borderRadius()};
      ${mixin.transition([{ property: 'opacity', duration: 'fastest' }])};
      width: ${width}px;
      height: ${getValue(height)};
      margin: 0 0 0 -${width}px;
      opacity: 0;

      .MuiDivider-root {
        margin: ${theme.spacing(0.5, 2, 2)};
      }

      > div {
        ${mixin.borderRadius({ radius: '0 0 default default' })};
        padding: ${theme.spacing(0, hasPadding ? 2 : 0)};
      }
    }

    ${isOpen &&
    css`
      ${style};

      > div {
        margin: 0;
        opacity: 1;
      }
    `}
  `}
`;

export default StyledDrawerUpper;
