export const citiesToTimeZoneMap: Record<string, string> = {
  'Africa/Abidjan': 'Casablanca, Monrovia',
  'Africa/Accra': 'Casablanca, Monrovia',
  'Africa/Addis_Ababa': 'Nairobi',
  'Africa/Algiers': 'West Central Africa',
  'Africa/Asmara': 'Kuwait, Riyadh',
  'Africa/Bamako': 'Casablanca, Monrovia',
  'Africa/Bangui': 'West Central Africa',
  'Africa/Banjul': 'Casablanca, Monrovia',
  'Africa/Bissau': 'Casablanca, Monrovia',
  'Africa/Blantyre': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Brazzaville': 'West Central Africa',
  'Africa/Bujumbura': 'Harare, Pretoria',
  'Africa/Cairo': 'Cairo',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Africa/Conakry': 'Casablanca, Monrovia',
  'Africa/Dakar': 'Casablanca, Monrovia',
  'Africa/Dar_es_Salaam': 'Nairobi',
  'Africa/Djibouti': 'Nairobi',
  'Africa/Douala': 'West Central Africa',
  'Africa/El_Aaiun': 'Lisbon',
  'Africa/Freetown': 'Casablanca, Monrovia',
  'Africa/Gaborone': 'Harare, Pretoria',
  'Africa/Harare': 'Harare, Pretoria',
  'Africa/Johannesburg': 'Harare, Pretoria',
  'Africa/Juba': 'Nairobi',
  'Africa/Kampala': 'Nairobi',
  'Africa/Khartoum': 'Cairo',
  'Africa/Kigali': 'Harare, Pretoria',
  'Africa/Kinshasa': 'West Central Africa',
  'Africa/Lagos': 'Cape Verde Islands',
  'Africa/Libreville': 'Cape Verde Islands',
  'Africa/Lome': 'Casablanca, Monrovia',
  'Africa/Luanda': 'Cape Verde Islands',
  'Africa/Lubumbashi': 'Harare, Pretoria',
  'Africa/Lusaka': 'Harare, Pretoria',
  'Africa/Malabo': 'West Central Africa',
  'Africa/Maputo': 'Harare, Pretoria',
  'Africa/Maseru': 'Harare, Pretoria',
  'Africa/Mbabane': 'Harare, Pretoria',
  'Africa/Mogadishu': 'Nairobi',
  'Africa/Monrovia': 'Hawaii',
  'Africa/Nairobi': 'Nairobi',
  'Africa/Ndjamena': 'West Central Africa',
  'Africa/Niamey': 'West Central Africa',
  'Africa/Nouakchott': 'Casablanca, Monrovia',
  'Africa/Ouagadougou': 'Casablanca, Monrovia',
  'Africa/Porto-Novo': 'Cape Verde Islands',
  'Africa/Tripoli': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Africa/Tunis': 'West Central Africa',
  'Africa/Windhoek': 'Cape Verde Islands',
  'America/Anchorage': 'Alaska',
  'America/Araguaina': 'Brasilia',
  'America/Argentina': 'Buenos Aires, Georgetown',
  'America/Asuncion': 'Caracas, La Paz',
  'America/Atikokan': 'Central Time',
  'America/Bahia': 'Brasilia',
  'America/Belem': 'Brasilia',
  'America/Belize': 'Central America',
  'America/Blanc-Sablon': 'Caracas, La Paz',
  'America/Boa_Vista': 'Caracas, La Paz',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Boise': 'Mountain Time',
  'America/Cambridge_Bay': 'Saskatchewan',
  'America/Campo_Grande': 'Caracas, La Paz',
  'America/Cancun': 'Guadalajara, Mexico City, Monterrey',
  'America/Caracas': 'Caracas, La Paz',
  'America/Cayenne': 'Brasilia',
  'America/Chicago': 'Central Time',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Coral_Harbour': 'Central Time',
  'America/Costa_Rica': 'Central America',
  'America/Creston': 'Pacific Time',
  'America/Cuiaba': 'Caracas, La Paz',
  'America/Danmarkshavn': 'Azores',
  'America/Dawson_Creek': 'Pacific Time',
  'America/Denver': 'Saskatchewan',
  'America/Detroit': 'Eastern Time',
  'America/Edmonton': 'Central America',
  'America/Eirunepe': 'Bogota, Lima, Quito',
  'America/El_Salvador': 'Central America',
  'America/Fortaleza': 'Brasilia',
  'America/Glace_Bay': 'Santiago',
  'America/Godthab': 'Greenland',
  'America/Goose_Bay': 'Brasilia',
  'America/Guatemala': 'Central America',
  'America/Guayaquil': 'Bogota, Lima, Quito',
  'America/Guyana': 'Caracas, La Paz',
  'America/Halifax': 'Santiago',
  'America/Havana': 'Eastern Time',
  'America/Hermosillo': 'Tijuana',
  'America/Indiana': 'Caracas, La Paz',
  'America/Iqaluit': 'Eastern Time',
  'America/Jamaica': 'Bogota, Lima, Quito',
  'America/Juneau': 'Alaska',
  'America/Kentucky': 'Eastern Time',
  'America/La_Paz': 'Caracas, La Paz',
  'America/Lima': 'Bogota, Lima, Quito',
  'America/Los_Angeles': 'Pacific Time',
  'America/Maceio': 'Brasilia',
  'America/Managua': 'Central America',
  'America/Manaus': 'Caracas, La Paz',
  'America/Matamoros': 'Guadalajara, Mexico City, Monterrey',
  'America/Mazatlan': 'Chihuahua, La Paz, Mazatlan',
  'America/Menominee': 'Central Time',
  'America/Merida': 'Bogota, Lima, Quito',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Moncton': 'Santiago',
  'America/Monterrey': 'Guadalajara, Mexico City, Monterrey',
  'America/Montevideo': 'Montevideo',
  'America/Montreal': 'Eastern Time',
  'America/Nassau': 'Caracas, La Paz',
  'America/New_York': 'Eastern Time',
  'America/Nome': 'Alaska',
  'America/Ojinaga': 'Chihuahua, La Paz, Mazatlan',
  'America/Panama': 'Bogota, Lima, Quito',
  'America/Pangnirtung': 'Eastern Time',
  'America/Paramaribo': 'Brasilia',
  'America/Phoenix': 'Arizona',
  'America/Port_of_Spain': 'Caracas, La Paz',
  'America/Port-au-Prince': 'Caracas, La Paz',
  'America/Porto_Velho': 'Caracas, La Paz',
  'America/Puerto_Rico': 'Caracas, La Paz',
  'America/Rankin_Inlet': 'Central Time',
  'America/Recife': 'Brasilia',
  'America/Regina': 'Saskatchewan',
  'America/Resolute': 'Central Time',
  'America/Rio_Branco': 'Bogota, Lima, Quito',
  'America/Santa_Isabel': 'Dawson, Yukon',
  'America/Santarem': 'Brasilia',
  'America/Santiago': 'Santiago',
  'America/Santo_Domingo': 'Caracas, La Paz',
  'America/Sao_Paulo': 'Brasilia',
  'America/Scoresbysund': 'Azores',
  'America/Sitka': 'Alaska',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Tegucigalpa': 'Central America',
  'America/Thule': 'Brasilia',
  'America/Tijuana': 'Tijuana',
  'America/Toronto': 'Eastern Time',
  'America/Vancouver': 'Pacific Time',
  'America/Whitehorse': 'Pacific Time',
  'America/Winnipeg': 'Central Time',
  'America/Yakutat': 'Alaska',
  'America/Yellowknife': 'Saskatchewan',
  'Arctic/Longyearbyen': 'Greenland',
  'Asia/Aden': 'Nairobi',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Amman': 'Jerusalem',
  'Asia/Anadyr': 'Kamchatka, Marshall Islands',
  'Asia/Aqtau': 'Ekaterinburg',
  'Asia/Aqtobe': 'Ekaterinburg',
  'Asia/Ashgabat': 'Baku, Tbilisi, Yerevan',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Beirut': 'Jerusalem',
  'Asia/Bishkek': 'Almaty, Novosibirsk',
  'Asia/Brunei': 'Kuala Lumpur, Singapore',
  'Asia/Choibalsan': 'Irkutsk, Ulaanbaatar',
  'Asia/Chongqing': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Damascus': 'Jerusalem',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Dili': 'Seoul',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Dushanbe': 'Islamabad, Karachi, Tashkent',
  'Asia/Harbin': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Hebron': 'Jerusalem',
  'Asia/Ho_Chi_Minh': 'Bangkok, Hanoi, Jakarta',
  'Asia/Hong_Kong': 'Taipei',
  'Asia/Hovd': 'Bangkok, Hanoi, Jakarta',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Jakarta': 'Bangkok, Hanoi, Jakarta',
  'Asia/Jayapura': 'Seoul',
  'Asia/Jerusalem': 'Jerusalem',
  'Asia/Kabul': 'Kabul',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kashgar': 'Almaty, Novosibirsk',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Khandyga': 'Seoul',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Kuching': 'Kuala Lumpur, Singapore',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Makassar': 'Kuala Lumpur, Singapore',
  'Asia/Manila': 'Taipei',
  'Asia/Muscat': 'Abu Dhabi, Muscat',
  'Asia/Nicosia': 'Jerusalem',
  'Asia/Novokuznetsk': 'Krasnoyarsk',
  'Asia/Novosibirsk': 'Krasnoyarsk',
  'Asia/Omsk': 'Almaty, Novosibirsk',
  'Asia/Oral': 'Ekaterinburg',
  'Asia/Phnom_Penh': 'Bangkok, Hanoi, Jakarta',
  'Asia/Pontianak': 'Bangkok, Hanoi, Jakarta',
  'Asia/Pyongyang': 'Seoul',
  'Asia/Qatar': 'Kuwait, Riyadh',
  'Asia/Qyzylorda': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Riyadh': 'Kuwait, Riyadh',
  'Asia/Sakhalin': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Samarkand': 'Islamabad, Karachi, Tashkent',
  'Asia/Seoul': 'Seoul',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Taipei': 'Taipei',
  'Asia/Tashkent': 'Islamabad, Karachi, Tashkent',
  'Asia/Tbilisi': 'Baku, Tbilisi, Yerevan',
  'Asia/Tehran': 'Kabul',
  'Asia/Thimphu': 'Astana, Dhaka',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Ulaanbaatar': 'Irkutsk, Ulaanbaatar',
  'Asia/Urumqi': 'Almaty, Novosibirsk',
  'Asia/Ust-Nera': 'Yakutsk',
  'Asia/Vientiane': 'Bangkok, Hanoi, Jakarta',
  'Asia/Vladivostok': 'Vladivostok',
  'Asia/Yakutsk': 'Yakutsk',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Yerevan': 'Baku, Tbilisi, Yerevan',
  'Atlantic/Canary': 'Canary Islands',
  'Atlantic/Reykjavik': 'Azores',
  'Atlantic/South_Georgia': 'Greenland',
  'Atlantic/Stanley': 'Santiago',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Broken_Hill': 'Adelaide',
  'Australia/Darwin': 'Darwin',
  'Australia/Hobart': 'Hobart',
  'Australia/Melbourne': 'Hobart',
  'Australia/Perth': 'Perth',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Europe/Athens': 'Athens, Minsk',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Berlin': 'Greenland',
  'Europe/Bratislava': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Bucharest': 'Bucharest',
  'Europe/Budapest': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Chisinau': 'Bucharest',
  'Europe/Copenhagen': 'Greenland',
  'Europe/Dublin': 'Dublin',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Istanbul': 'Bucharest',
  'Europe/Kaliningrad': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Kiev': 'Bucharest',
  'Europe/Lisbon': 'Lisbon',
  'Europe/Ljubljana': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/London': 'Edinburgh, London',
  'Europe/Luxembourg': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Madrid': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Mariehamn': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Minsk': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Moscow': 'Istanbul, Moscow, St. Petersburg, Volgograd',
  'Europe/Oslo': 'Greenland',
  'Europe/Paris': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Podgorica': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Prague': 'Greenland',
  'Europe/Riga': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Rome': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Samara': 'Abu Dhabi, Muscat',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Simferopol': 'Bucharest',
  'Europe/Skopje': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sofia': 'Bucharest',
  'Europe/Stockholm': 'Greenland',
  'Europe/Tallinn': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Tirane': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Uzhgorod': 'Bucharest',
  'Europe/Vienna': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Vilnius': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Volgograd': 'Abu Dhabi, Muscat',
  'Europe/Warsaw': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Zagreb': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Zaporozhye': 'Istanbul, Moscow, St. Petersburg, Volgograd',
  'Europe/Zurich': 'Brussels, Copenhagen, Madrid, Paris',
  'Indian/Antananarivo': 'Nairobi',
  'Indian/Kerguelen': 'Islamabad, Karachi, Tashkent',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Efate': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Galapagos': 'Central America',
  'Pacific/Guadalcanal': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Noumea': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Port_Moresby': 'Brisbane',
  'Pacific/Tongatapu': "Nuku'alofa",
};
