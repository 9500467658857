import i18next from 'i18next';

const translations = () => ({
  audio: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.page_group.audio.label',
    ),
  },
  audio_way_options: [
    {
      value: 'one-way',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.page_group.audio.option_one',
      ),
    },
    {
      value: 'two-way',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.page_group.audio.option_two',
      ),
    },
  ],
  confirmations: {
    deviceExists: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.page_group.table_selector.confirmation_dialog.message.device_exists',
    ),
    ownerExists: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.page_group.table_selector.confirmation_dialog.message.owner_exists',
    ),
  },
  columns: {
    name: {
      header: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.page_group.selected.page_group',
      ),
    },
  },
  name: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.page_group.name.label',
    ),
  },
  title: i18next.t('phone_system:containers.callflows.callflow_action_dialog.page_group.title'),
});

export default translations;
