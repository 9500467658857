import { api } from 'api/advancedProvisioner';
import { unmaskMacAddress } from 'apps/shared/utility';
import { responseHandler, service, TAGS } from 'services/advancedProvisioner';
import {
  AccountSettingsData,
  AccountTemplateData,
  DeviceData,
  Devices,
  DeviceTemplateData,
  FetchDeviceFilesByMacAddressParams,
  FetchDeviceTemplateByModelParams,
  Phones,
  SubaccountDevicesData,
} from './types';

export const advancedProvisionerQueries = service.injectEndpoints({
  endpoints: (builder) => ({
    fetchAccountSettings: builder.query<AccountSettingsData, void>({
      query: () => ({
        url: api.FetchAccountSettings(),
        responseHandler,
      }),
      providesTags: (result, error) => [{ type: TAGS.ACCOUNT_SETTINGS }],
    }),
    fetchAccountTemplate: builder.query<AccountTemplateData, void>({
      query: () => ({
        url: api.FetchAccountTemplate(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.ACCOUNT_TEMPLATE }],
    }),
    fetchDevices: builder.query<Devices, void>({
      query: () => ({
        url: api.FetchDevices(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.DEVICE, id: 'LIST' }],
    }),
    fetchDeviceByMacAddress: builder.query<DeviceData, string>({
      query: (macAddress) => ({
        url: api.FetchDeviceByMacAddress(macAddress),
        responseHandler,
      }),
      providesTags: (result, error, macAddress) => [
        { type: TAGS.DEVICE, id: unmaskMacAddress(macAddress) },
      ],
    }),
    fetchDeviceFilesByMacAddress: builder.query<any, FetchDeviceFilesByMacAddressParams>({
      query: ({ type, macAddress }) => ({
        url: api.FetchDeviceFilesByMacAddress(type, macAddress),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.FILES }],
    }),
    fetchDeviceTemplateByModel: builder.query<DeviceTemplateData, FetchDeviceTemplateByModelParams>(
      {
        query: ({ brand, family, model }) => ({
          url: api.FetchDeviceTemplateByModel({ brand, family, model }),
          responseHandler,
        }),
        providesTags: (result, error, { brand, family, model }) => [
          { type: TAGS.DEVICE_TEMPLATE, id: `${brand}/${family}/${model}` },
        ],
      },
    ),
    fetchPhones: builder.query<Phones, void>({
      query: () => ({
        url: api.FetchPhones(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.PHONES }],
    }),
    fetchSubaccountDevice: builder.query<SubaccountDevicesData, { macAddress: string }>({
      query: ({ macAddress }) => ({
        url: api.FetchSubaccountDevice(macAddress),
        responseHandler,
      }),
    }),
  }),
});
