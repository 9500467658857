import TableCellShareIcon from 'apps/PhoneSystem/shared/TableCellSharedIcon';
import { getFullNameFromUserListByUserId } from 'models/User';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import { LabeledRadioGroup } from 'shared/components/Labeled';
import Table, { Cell, Justify, TABLE_CONSTANTS } from 'shared/components/Table';
import EmptySMSView from './components/EmptySMSView';
import PhoneNumber from './components/PhoneNumber';
import { CREATING_SEAT_USERNAME_FALLBACK_KEY } from './constants';
import defaults from './defaults';
import { SMSListViewProps as Props } from './definition';

const SMSListView: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();

  const {
    tableData,
    phoneNumbers,
    usersData,
    isCreateDialogOpen,
    selectedPhoneNumber,
    handlePhoneNumberSelect,
    handleCreateDialogOpen,
    handleCreateDialogClose,
    handleCreateDialogAction,
    hasActionRow = false,
  } = {
    ...defaults,
    ...props,
  };

  const columns = useMemo(
    () => [
      {
        Header: t('phone_system:containers.seats.sms.table.column.shared_vmbox.header'),
        Cell: ({ value }: Cell<JSX.Element>) => <TableCellShareIcon data={tableData} id={value} />,
        accessor: 'id',
        disableSortBy: true,
        id: `seats.sms.share.${Justify.Center}`,
        width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
      },
      {
        Header: t('phone_system:containers.seats.sms.table.column.assigned_to.header'),
        accessor: ({ owner }: any) => {
          if (owner === CREATING_SEAT_USERNAME_FALLBACK_KEY) {
            return t(
              'phone_system:containers.seats.sms.table.column.assigned_to.creating_seat_owner_name',
            );
          }
          return getFullNameFromUserListByUserId(owner, usersData);
        },
        id: 'owner',
      },
      {
        Header: t('phone_system:containers.seats.sms.table.column.sms_number.header'),
        accessor: 'phone_number',
      },
    ],
    [usersData, tableData],
  );

  const renderPhoneNumberPicker = () => {
    if (phoneNumbers.length === 1) {
      return <PhoneNumber value={phoneNumbers[0]} />;
    }

    return (
      <LabeledRadioGroup
        id="sms-select-phone-number"
        isLabelAbove
        label={t('phone_system:containers.seats.sms.empty_sms.create_sms_box.dialog.message')}
        radioGroupProps={{ onChange: handlePhoneNumberSelect, value: selectedPhoneNumber }}
        radioProps={phoneNumbers.map((phoneNumber) => ({
          label: phoneNumber,
          value: phoneNumber,
        }))}
      />
    );
  };

  return (
    <>
      <Dialog
        open={isCreateDialogOpen}
        title={t('phone_system:containers.seats.sms.empty_sms.create_sms_box.dialog.title')}
        onClose={handleCreateDialogClose}
        renderActions={
          <DialogActions
            saveButtonProps={{
              label: t(
                'phone_system:containers.seats.sms.empty_sms.create_sms_box.dialog.action.label',
              ),
              variant: 'contained',
              disabled: !selectedPhoneNumber && phoneNumbers.length > 1,
            }}
            onAction={handleCreateDialogAction}
          />
        }
      >
        {renderPhoneNumberPicker()}
      </Dialog>
      {tableData.length > 0 ? (
        <Table
          hasActionRow={hasActionRow}
          columns={columns}
          data={tableData}
          initialState={{ sortBy: { id: 'owner' } }}
        />
      ) : (
        <EmptySMSView
          handleCreateDialogOpen={handleCreateDialogOpen}
          isCreateDisabled={!phoneNumbers.length}
        />
      )}
    </>
  );
};

export default SMSListView;
