import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { AVAILABILITY_OPTIONS } from '../../constants';

const schema = () =>
  Joi.object({
    key: Joi.string()
      .valid(...AVAILABILITY_OPTIONS)
      .required()
      .messages(messages()),
  });

export default schema;
