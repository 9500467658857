import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { NAV_ITEM_CONSTANTS } from './components/NavItem';
import { StyledHorizontalNavProps as Props } from './definition';

const StyledHorizontalNav = styled.div<Props>`
  ${({ hasIndent, theme }) => css`
    ${mixin.flex({ justifyContent: 'flex-start', alignItems: 'stretch' })};
    position: relative;
    width: 100%;
    height: ${NAV_ITEM_CONSTANTS.HEIGHT};
    padding: ${`0 ${theme.spacing(5)}${hasIndent ? ` 0 ${HEADER_CONSTANTS.LOGO.WIDTH}` : ''}`};
    border-bottom: 1px solid ${theme.core.background.grey.light.border};
    z-index: 100;
  `}
`;

export default StyledHorizontalNav;
