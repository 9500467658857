import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { ADD_KEY } from 'constant';
import uniqBy from 'lodash/uniqBy';
import {
  useFetchDevicesByOwnerIdQuery,
  useFetchDeviceStatusesQuery,
  usePatchDeviceMutation,
} from 'models/Device';
import { useFormContext } from 'react-hook-form';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import { FormInput } from '../../../definition';
import { columns, showDevice } from './utility';

type Props = TabPanelProps<FormInput>;

const DevicesListView = ({ id = ADD_KEY }: Props) => {
  const {
    getValues,
    formState: { dirtyFields },
  } = useFormContext();
  const { data, isLoading } = useFetchDevicesByOwnerIdQuery({ id });
  const {
    data: deviceStatusesData,
    error: deviceStatusesError,
    isLoading: isLoadingDeviceStatuses,
  } = useFetchDeviceStatusesQuery();
  const [patchDevice] = usePatchDeviceMutation();

  if (isLoading || isLoadingDeviceStatuses) {
    return <Loading />;
  }

  const tableData = uniqBy(
    [...Object.values(getValues('device')), ...((data as Array<any>) || [])],
    'id',
  ).filter((item) =>
    showDevice(getValues(`device.${item.id}._toDelete`), item.id, dirtyFields?.device),
  );

  return (
    <Table
      hasActionRow={false}
      columns={columns({
        isLoadingDeviceStatuses,
        deviceStatusesData,
        deviceStatusesError,
        patchDevice,
      })}
      data={tableData}
    />
  );
};

export default DevicesListView;
