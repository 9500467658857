import { value } from 'shared/utility/default';
import { WidthType } from 'shared/utility/definition';
import styled, { css } from 'styled-components';
import * as utility from '../../utility';
import { inputStyle } from '../Input/style';
import { StyledTextareaProps as Props } from './definition';

const doCalc = (value: number | string) =>
  `calc(${value}${typeof value === 'number' ? 'px' : ''} - (1px * 2))`;

const StyledTextarea = styled.textarea<Props>`
  ${(props) => {
    const width = {
      max: `${value.getWidth.input[props.width as WidthType]}px`,
      min: utility.getWidth({
        type: 'input',
        width: { input: props.width as WidthType, label: 'none' },
      }),
    };

    return css`
      ${inputStyle.wrapper(props)};
      min-height: ${doCalc(props.minheight)};
      max-height: ${doCalc(props.maxheight)};
      padding: ${props.theme.spacing(1.5)} ${props.theme.spacing(2)};
      line-height: 1.3em;
      resize: ${props.resize};

      ${props.width === 'auto'
        ? css`
            width: ${doCalc(width.min)};
          `
        : css`
            min-width: ${doCalc(width.min)};
          `};

      :focus,
      :focus-visible {
        outline: none;
      }
    `;
  }};
`;

export default StyledTextarea;
