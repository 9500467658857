import { BaseSyntheticEvent, memo } from 'react';
import Switch from 'shared/components/Switch';
import useToggleEnabled from '../../../hooks/useToggleEnabled';
import { BlocklistData, TableCellEnabledSwitchProps as Props } from './definition';

const TableCellEnabledSwitch = memo(({ data }: Props) => {
  const { toggleBlocklist, isLoading } = useToggleEnabled();

  const onChange = async (isEnabled: boolean, data: BlocklistData) => {
    await toggleBlocklist(isEnabled, data);
  };

  return (
    <Switch
      checked={data?.isBlockingEnabled}
      disabled={isLoading}
      onChange={(e: BaseSyntheticEvent) => onChange(e.target.checked, data)}
      data-test-id="phone-system-call-blocking-list-table-cell-enabled-switch"
    />
  );
});

export default TableCellEnabledSwitch;
