import { useNavigation } from 'apps/PhoneSystem/shared/useNavigation';
import { ContainerMeta } from 'apps/shared/definition';
import { FunctionComponent } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Edit from './Edit';
import List from './List';
import AgentsEdit from './List/components/AgentsEdit';
import AgentsList from './List/components/AgentsList';

export const meta: ContainerMeta = {
  // t('call_center:containers.manage_queues.label')
  icon: 'manage_queues',
  label: 'call_center:containers.manage_queues.label',
  slug: 'manage-queues',
};

const ManageQueues: FunctionComponent = (): JSX.Element => {
  const LinkToManageQueue = <Navigate to="/apps/call-center/manage-queues" replace />;
  const AgentsListWithNav = useNavigation(AgentsList);
  const AgentsEditWithNav = useNavigation(AgentsEdit);

  const routes = useRoutes([
    {
      path: '/',
      children: [
        { index: true, element: <List /> },
        { path: ':id', element: <Edit /> },
        { path: '*', element: LinkToManageQueue },
      ],
    },
    {
      path: 'agents/',
      children: [
        { index: true, element: LinkToManageQueue },
        { path: ':id', element: <AgentsListWithNav /> },
        { path: ':id/:agentId', element: <AgentsEditWithNav /> },
      ],
    },
  ]);

  return <>{routes}</>;
};

export default ManageQueues;
