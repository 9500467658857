import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';

const StyledAppPicker = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    border-left: 1px solid ${theme.core.divider.default};
  `}
`;

export default StyledAppPicker;
