import produce from 'immer';
import { FormFields } from '../../components/SsoSection/definition';
import { FormInput } from '../../definition';

/**
 * @description This utility update the voicemail pin_pass_sync flag and remove the pin_pass_sync field from the update account request body
 * @param dirtyFields The dirty fields of the form
 * @param pin_pass_sync The value of the pin_pass_sync flag
 * @param updateVoicemailConfigs The mutation to update the voicemail config
 * @param updateAccountByIdBody The update account request body
 */
export const updatePinPassSync = async ({
  dirtyFields,
  pin_pass_sync,
  updateVoicemailConfigs,
  updateAccountByIdBody,
}: {
  dirtyFields: Record<string, any>;
  pin_pass_sync?: boolean;
  updateVoicemailConfigs: any;
  updateAccountByIdBody: FormInput;
}) => {
  // Update voicemail config pin_pass_sync field
  if (dirtyFields[FormFields.PinPassLink]) {
    await updateVoicemailConfigs({ pin_pass_sync });
  }

  // Remove pin_pass_sync from update account request body
  updateAccountByIdBody = produce(updateAccountByIdBody, (draft) => {
    delete draft[FormFields.PinPassLink];
  });

  return updateAccountByIdBody;
};
