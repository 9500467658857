import { E911Row } from '../../../definition';
import translations from '../../../translations';
import { sortData } from '../../../utility';

/**
 * @name buildRow
 * @description Build a header/data row for use in the CSV file.
 *
 * @param row
 *
 * @returns CSV row.
 */
export const buildRow = (row: Array<string | undefined>) =>
  row.length > 0 ? `${row.join(',')}\n` : '';

/**
 * @name getCsvBlob
 * @description Generates a CSV blob from E911 seats/devices data.
 *
 * @param [object]
 * @property data - Array of E911 seats/devices data
 * @property locations - Object containing account locations
 *
 * @returns Array of BlobPart representing CSV data.
 */
export const getCsvBlob = ({ data = [] }: { data?: Array<E911Row> }): Array<BlobPart> => {
  const { table } = translations();

  return data.length > 0
    ? [
        buildRow([
          table.columns.seat.header,
          table.columns.device.header,
          table.columns.location.header,
          table.columns.e911.callerId.name.header,
          table.columns.e911.callerId.number.header,
          table.columns.e911.address.header,
          table.columns.source.header.text,
        ]),
        ...data
          .sort(sortData)
          .map(({ seatName, deviceName, e911, locationName, sourceName }: E911Row) =>
            buildRow([
              `"${seatName}"`, // escape commas
              deviceName,
              locationName,
              e911.name,
              e911.number,
              e911.address,
              sourceName,
            ]),
          ),
      ]
    : [];
};
