import CallflowContext from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import {
  ABSOLUTE_MODE_DEFAULT,
  DEFAULT_KEY,
} from 'apps/PhoneSystem/containers/Callflows/Edit/components/SelectKeyDialog/constants';
import { CallFlowKey } from 'models/Callflow/definition';
import { maybeShowSelectKeyDialog } from 'models/Callflow/store/slice';
import { useFetchTimeOfDayQuery } from 'models/TimeOfDay';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Props } from './definition';
import { StyledFlowChildHead } from './style';

const FlowChildHead = ({ node, parentNode }: Props) => {
  const { key } = node;
  const {
    callflow: { id: callflowId },
    isPreview,
  } = useContext(CallflowContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: timeOfDayData, isLoading: isLoadingTimeOfDay } = useFetchTimeOfDayQuery();

  const handleKeyDialog = () => {
    const data = {
      usedKeys: Object.keys(parentNode.children),
      key: node.key,
    };
    const type = parentNode.callflow.key;
    dispatch(maybeShowSelectKeyDialog({ id: callflowId, data, parentNode, type }));
  };

  const keyDialogNameMapper = (key: string, callFlowKey: CallFlowKey) => {
    switch (callFlowKey) {
      case CallFlowKey.TIME_OF_DAY:
        const selected = timeOfDayData?.find((time: any) => time.id === key);
        return key === DEFAULT_KEY
          ? t('phone_system:containers.callflows.select_key_dialog.time_of_day.options.default')
          : selected?.name;
      case CallFlowKey.WAIT_TIME:
        return key === DEFAULT_KEY
          ? t('phone_system:containers.callflows.select_key_dialog.wait_time.options.default')
          : t(
              'phone_system:containers.callflows.select_key_dialog.wait_time.options.threshold_header',
              { seconds: key },
            );
      default:
        /**
         * Keys may be a single-digit numeric (eg: 1-9), a multi-character,
         * hyphenated, lowercase alphabetic string (eg: available, unavailable),
         * or in the case of default, a single underscore character (_).
         */
        const transKey = /^[a-z\-\_]+$/.test(key)
          ? key === DEFAULT_KEY
            ? ABSOLUTE_MODE_DEFAULT
            : key
          : undefined;

        /**
         * t('phone_system:containers.callflows.flowchild_head.available')
         * t('phone_system:containers.callflows.flowchild_head.default')
         * t('phone_system:containers.callflows.flowchild_head.match')
         * t('phone_system:containers.callflows.flowchild_head.nomatch')
         * t('phone_system:containers.callflows.flowchild_head.timeout')
         * t('phone_system:containers.callflows.flowchild_head.unavailable')
         */
        return !!transKey ? t(`phone_system:containers.callflows.flowchild_head.${transKey}`) : key;
    }
  };

  return (
    <StyledFlowChildHead onClick={handleKeyDialog} isPreview={isPreview}>
      {!isLoadingTimeOfDay && keyDialogNameMapper(key, parentNode.callflow.key)}
    </StyledFlowChildHead>
  );
};

export default FlowChildHead;
