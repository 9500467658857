import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { LIST_ITEM_LOWER_CONSTANTS } from './components/ListItemLower';
import { LIST_ITEM_UPPER_CONSTANTS } from './components/ListItemUpper';
import { StyledListItemProps as Props } from './definition';

const StyledListItem = styled.div<Props>`
  ${(props) => css`
    ${mixin.transition({ property: 'height', duration: 'fast' })};
    height: ${LIST_ITEM_UPPER_CONSTANTS.HEIGHT};
    background-color: inherit;
    overflow: hidden;

    ${props.isOpen &&
    css`
      height: ${`calc(${LIST_ITEM_UPPER_CONSTANTS.HEIGHT} + ${LIST_ITEM_LOWER_CONSTANTS.HEIGHT} + (${LIST_ITEM_LOWER_CONSTANTS.MARGIN} * 2))`};
    `}
  `};
`;

export default StyledListItem;
