import { BuyNumberFlowType } from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import DropdownButton from 'shared/components/DropdownButton';
import { MarginType } from 'shared/utility/definition';
import { getFlowMeta } from '../BuyNumberFlow/utility';
import { BuyNumberButtonProps as Props } from './definition';

const BuyNumberButton = ({ hasMargin, variant, setFlowType, setOrigin }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const mapOption = (value: BuyNumberFlowType) => {
    const isLocal = value === BuyNumberFlowType.Local;
    return {
      label: isLocal
        ? t('common:component.buy_number_button.options.local')
        : t('common:component.buy_number_button.options.toll_free'),
      value,
      onClick: () => {
        setFlowType(value);
        navigate(
          isLocal
            ? getFlowMeta()[BuyNumberFlowType.Local][0].route.default
            : getFlowMeta()[BuyNumberFlowType.TollFree][0].route.default,
        );
        setOrigin?.(location.pathname);
      },
    };
  };

  return (
    <DropdownButton
      hasMargin={hasMargin}
      buttonProps={{
        variant: variant ?? 'outlined',
      }}
      items={[BuyNumberFlowType.TollFree, BuyNumberFlowType.Local].map((value) => mapOption(value))}
      label={t('common:component.buy_number_button.label')}
      leftMargin={MarginType.small}
    />
  );
};

export default BuyNumberButton;
