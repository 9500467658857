import { DEFAULT_TIMEZONE_OPTION_VALUE } from 'constant';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogLink from 'shared/components/Dialog/components/DialogLink';
import { LabeledSelect } from 'shared/components/Labeled';
import { OptionTypeBase } from 'shared/components/Select/definition';
import defaultProps, { defaultLabeledSelectProps } from './default';
import { LabeledSelectTimeZoneProps as Props } from './definition';
import { getKazooTimezoneWithDst, getTimezoneWithDstMap, sortTimezoneOptions } from './utility';

const LabeledSelectTimeZone = (props: Props) => {
  const {
    hasAccountDefault,
    hasDefaultOptionUndefined,
    selectProps: { ref, name, value, ...rest },
    onMapToggleClick,
  } = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const [options, setOptions] = useState<any>([]);

  const timezone = useMemo(() => getTimezoneWithDstMap(), []);

  const selectZone = (value: OptionTypeBase) =>
    options.find(
      (zone: OptionTypeBase) =>
        zone.value === value || zone.label === getKazooTimezoneWithDst(value),
    );

  useEffect(() => {
    const baseOptions = hasAccountDefault
      ? [
          {
            label: t('common:component.labeled_select_time_zone.default_option'),
            value: hasDefaultOptionUndefined ? undefined : DEFAULT_TIMEZONE_OPTION_VALUE,
          },
        ]
      : [];

    setOptions(
      [
        ...baseOptions,
        ...Object.keys(timezone).map((key) => ({ label: key, value: timezone[key] })),
      ].sort(sortTimezoneOptions),
    );
  }, []);

  return (
    <>
      <LabeledSelect
        {...defaultLabeledSelectProps}
        {...props}
        selectProps={{
          options,
          styles: {
            valueContainer: (provided: any) => ({
              ...provided,
              cursor: 'text',
            }),
          },
          value: selectZone(value as OptionTypeBase),
          ...rest,
        }}
      />
      <DialogLink
        icon="language"
        indentWidth="large"
        iconSize={20}
        onClick={onMapToggleClick}
        isBelow
        text={t('common:component.labeled_select_time_zone.map_link')}
      />
    </>
  );
};

export default memo(LabeledSelectTimeZone);
