import {
  datePickerDefaultProps as datePickerProps,
  timePickerDefaultProps as timePickerProps,
} from 'shared/components/Picker';
import { WidthType } from 'shared/utility/definition';
import labeledProps from '../../default';

const defaultProps = {
  ...labeledProps,
  pickerWidth: 'large' as WidthType,
  label: '__PICKER_LABEL__',
  labelWidth: 'large' as WidthType,
  labelProps: {},
  datePickerProps,
  timePickerProps,
};

export default defaultProps;
