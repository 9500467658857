import { LOCAL_STORAGE, MILLISECONDS_IN_SECOND } from 'constant';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { FunctionComponent } from 'react';
import { Navigate, Outlet, ScrollRestoration } from 'react-router-dom';
import { PrivateRouteProps as Props } from './definition';

const PrivateRoute: FunctionComponent<Props> = (): JSX.Element => {
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (jwt_decode<JwtPayload>(token).exp || 0) * MILLISECONDS_IN_SECOND < Date.now() ? (
    <Navigate to="/login" />
  ) : (
    <>
      <Outlet />
      <ScrollRestoration />
    </>
  );
};

export default PrivateRoute;
