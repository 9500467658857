import { api } from 'api/advancedProvisioner';
import { unmaskMacAddress } from 'apps/shared/utility';
import { RequestMethod } from 'definition';
import { responseHandler, service, TAGS } from 'services/advancedProvisioner';
import {
  AccountSettingsData,
  Devices,
  UpdateAccountSettingsParams,
  UpdateDeviceByMacAddressParams,
} from './types';

export const advancedProvisionerMutations = service.injectEndpoints({
  endpoints: (builder) => ({
    updateDeviceByMacAddress: builder.mutation<Devices, UpdateDeviceByMacAddressParams>({
      query: (params) => ({
        url: api.UpdateByMacAddress(params?.mac_address),
        method: RequestMethod.Post,
        body: { data: params, generate: true }, // ADVPROV-TODO: investigate why generate is needed
        responseHandler,
      }),
      invalidatesTags: (result, error, { mac_address }) => [
        { type: TAGS.DEVICE, id: unmaskMacAddress(mac_address) },
      ],
    }),
    updateAccount: builder.mutation<AccountSettingsData, UpdateAccountSettingsParams>({
      query: ({ body }) => ({
        url: api.UpdateAccount(),
        method: RequestMethod.Post,
        body: { data: body, generate: true, create_if_missing: true }, // ADVPROV-TODO: investigate why generate is needed
        responseHandler,
      }),
      invalidatesTags: () => [{ type: TAGS.ACCOUNT_SETTINGS }],
    }),
  }),
});
