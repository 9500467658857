import { themeColor } from 'theme';
import { MENU_CONSTANTS } from '.';
import theme from '../core';

const CONSTANTS = {
  BORDER: `1px solid ${themeColor.core.background.grey.light.border}`,
  WIDTH: {
    INFO_ICON: '18px',
    MIN: '104px', // via Brio library
  },
};

export default {
  defaultProps: {
    disableRipple: true,
    elevation: 0,
  },
  styleOverrides: {
    root: {
      minWidth: CONSTANTS.WIDTH.MIN,
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      color: theme.palette.common.black,
      fontSize: 13,
      fontWeight: 600,
      transition: theme.transitions.create(['background-color', 'color'], {
        duration: theme.transitions.duration.shorter,
      }),
      '&:hover': {
        color: theme.palette.common.black,
        '&.MuiMenuItem-color-secondary': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.white,
        },
        '&.MuiMenuItem-color-success': {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.common.white,
        },
        '&.MuiMenuItem-color-neutral': {
          backgroundColor: theme.palette.neutral.main,
          color: theme.palette.common.black,
        },
      },
      '&.MuiMenuItem-header, &.MuiMenuItem-info, &.MuiMenuItem-noitemstext': {
        borderRadius: 0,
      },
      '&.MuiMenuItem-header, &.MuiMenuItem-info': {
        fontWeight: 500,
      },
      '&.MuiMenuItem-header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 40,
        padding: theme.spacing(0, 3),
        backgroundColor: themeColor.core.background.grey.light.list,
        '&:hover, &.MuiMenuItem-selected': {
          backgroundColor: themeColor.core.background.grey.medium,
          color: theme.palette.common.black,
          fontWeight: 600,
          letterSpacing: '-0.0125em',
        },
        '&:not(:first-of-type)': {
          borderTop: CONSTANTS.BORDER,
        },
        '&.MuiMenuItem-selected': {
          cursor: 'default',
        },
      },
      '&.MuiMenuItem-info, &.MuiMenuItem-noitemstext': {
        maxWidth: MENU_CONSTANTS.WIDTH.MAX,
        margin: 0,
        backgroundColor: 'transparent',
        cursor: 'default',
        whiteSpace: 'normal',
        '&:hover': {
          color: theme.palette.common.black,
        },
        span: {
          display: 'inline-block',
          lineHeight: '1.25em',
        },
      },
      '&.MuiMenuItem-info': {
        display: 'flex',
        alignItems: 'center',
        margin: `0 0 ${MENU_CONSTANTS.GAP}`,
        padding: theme.spacing(1.5, 1, 2),
        '> span': {
          width: `calc(${MENU_CONSTANTS.WIDTH.MAX} - (${theme.spacing(2)} * 2 + ${
            CONSTANTS.WIDTH.INFO_ICON
          } + ${theme.spacing(1)}))`,
        },
        '> svg': {
          margin: theme.spacing(0, 2, 0, 1),
        },
      },
      '&.MuiMenuItem-noitemstext': {
        '> span': {
          color: 'rgba(0, 0, 0, 0.4)',
          fontStyle: 'italic',
          fontWeight: 'normal',
        },
        '&.MuiMenuItem-noitemstext-with-border': {
          paddingTop: 0,
          '> span': {
            display: 'inline-block',
            width: '100%',
            padding: theme.spacing(2, 0, 1),
            borderTop: CONSTANTS.BORDER,
          },
        },
      },
      '&.MuiMenuItem-search': {
        margin: `0 0 ${MENU_CONSTANTS.GAP}`,
        padding: 0,
        borderColor: 'transparent',
      },
      '&.MuiMenuItem-select-filter': {
        flexDirection: 'column',
        height: 36,
        padding: 0,
        div: {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          margin: 0,
          '.MuiCheckbox-root': {
            padding: theme.spacing(1, 1.5, 1, 3),
          },
          '.MuiInputLabel-root': {
            '&, span': {
              cursor: 'pointer',
              display: 'inline-block',
              width: '100%',
              fontWeight: 400,
            },
          },
        },
        '&:first-of-type': {
          marginBottom: theme.spacing(1.5),
          '&::after': {
            content: "''",
            display: 'block',
            width: '100%',
            margin: theme.spacing(0.66, 0),
            borderBottom: CONSTANTS.BORDER,
          },
        },
        '&:hover': {
          backgroundColor: 'rgba(0 0 0 / 8%)',
        },
      },
    },
  },
};
