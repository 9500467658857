import { MarginType } from 'shared/utility/definition';
import styled, { css } from 'styled-components';
import { INPUT_LABEL_CONSTANTS } from '../InputLabel';
import { StyledDropdownButtonProps as Props } from './definition';

const margin = ({ hasMargin, isBelow, leftMargin, theme }: Props) => {
  const margin = {
    top: isBelow ? `-${theme.spacing(3)}` : 0,
    right: 0,
    bottom: hasMargin ? theme.spacing(6) : 0,
    left: theme.spacing(INPUT_LABEL_CONSTANTS.MARGIN.DEFAULT[leftMargin as MarginType]),
  };
  return css`
    margin: ${margin.top} ${margin.right} ${margin.bottom} ${margin.left};
  `;
};

const StyledDropdownButton = styled.div<Props>`
  ${(props) => css`
    ${margin(props)};
    position: relative;
  `}
`;

export default StyledDropdownButton;
