import Box from 'shared/components/Box';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled from 'styled-components';
import { TAB_CONSTANTS } from 'theme/mui/components';

const StyledTabPanel = styled(Box)`
  height: calc(100% - (${ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT} + ${TAB_CONSTANTS.HEIGHT_INNER}px));
`;

export default StyledTabPanel;
