import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { getOptionNotSet } from '../../utility';
import { DeviceTextProps as Props } from './definition';
import StyledDeviceText from './style';

const DeviceText = ({ data: { brand, model } }: Props) => {
  const { t } = useTranslation();

  return brand && brand !== getOptionNotSet().value && model ? (
    <StyledDeviceText data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-usedevicepicker-devicetext`}>
      <Box component="span">{brand}</Box>
      <Box component="span">—</Box>
      <Box component="span">{model}</Box>
    </StyledDeviceText>
  ) : (
    <>{t('common:component.device_picker.device_not_set')}</>
  );
};

export default DeviceText;
