import Joi from 'joi';
import { messages } from 'shared/utility/validation';

const schema = () =>
  Joi.object({
    enforce_call_restriction: Joi.boolean(),
    nodeId: Joi.string(),
    permit_custom_callflow: Joi.boolean(),
  }).messages(messages());

export default schema;
