import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { ACTION_LOGIN } from '../../constants';

const schema = () =>
  Joi.object({
    action: Joi.string().valid(ACTION_LOGIN),
    id: Joi.string().required(),
    nodeId: Joi.string(),
  }).messages(messages());

export default schema;
