import { ACTION_CHECK } from '../../constants';
import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  action: ACTION_CHECK,
  callerid_match_login: false,
  interdigit_timeout: 2000,
  max_message_length: 500,
  single_mailbox_login: false,
};
