import MuiDialogActions from '@mui/material/DialogActions';
import Box from 'shared/components/Box';
import defaultProps from './default';
import { DialogActionsProps as Props } from './definition';
import { buildButtons } from './utility';

export type { DialogActionsCloseReasons } from './definition';

const DialogActions = (props: Props) => {
  const {
    hasCancelOnly,
    hasDelete,
    cancelButtonProps,
    cancelLabel,
    deleteLabel,
    dialogType,
    leftButtons,
    rightButtons,
    saveButtonProps,
    onAction,
    ...rest
  } = {
    ...defaultProps,
    ...props,
  };

  const buttons = buildButtons({
    cancelButtonProps,
    cancelLabel,
    deleteLabel,
    hasCancelOnly,
    onAction,
    saveButtonProps,
  });

  const buttonGroup = {
    left: leftButtons,
    right: rightButtons ?? (
      <>
        {buttons.cancel}
        {!hasCancelOnly ? hasDelete ? buttons.delete : buttons.save : <></>}
      </>
    ),
  };

  const classes = [];
  if (dialogType !== 'default') {
    classes.push(`MuiDialogActions-${dialogType}`);
  }

  return (
    <MuiDialogActions classes={{ root: classes.join(' ') }} {...rest}>
      <>
        <Box>{buttonGroup.left}</Box>
        <Box>{buttonGroup.right}</Box>
      </>
    </MuiDialogActions>
  );
};

export default DialogActions;
