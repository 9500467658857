import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useState } from 'react';
import { LabeledInput } from 'shared/components/Labeled';
import { InputProps } from '../definition';
import { InheritTooltipWrapper } from '../InheritTooltipWrapper';

export const PasswordInput = ({
  fieldKey,
  template: { text, tooltip, type, value, isInheritFromAccount },
}: InputProps): JSX.Element => {
  const [hidePassword, setHidePassword] = useState(true);
  return (
    <HookFormInputWrapper name={fieldKey}>
      {({ ref, isDirty, feedback, ...formProps }) => (
        <InheritTooltipWrapper
          inheritValue={value}
          inputType={type}
          isInheritFromAccount={isInheritFromAccount}
          tooltip={tooltip}
          fieldValue={formProps.value}
          fieldKey={fieldKey}
        >
          {(tooltip) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              label={text}
              tooltip={tooltip}
              inputProps={{
                ...formProps,
                $hasNoAutofill: true,
                type: hidePassword ? 'password' : 'text',
                onClick: () => {
                  setHidePassword(false);
                },
                onBlur: () => {
                  setHidePassword(true);
                },
                placeholder: text,
              }}
            />
          )}
        </InheritTooltipWrapper>
      )}
    </HookFormInputWrapper>
  );
};
