import { defaultValues } from 'apps/PhoneSystem/containers/Voicemails/Edit/default';
import Form from 'apps/PhoneSystem/containers/Voicemails/Edit/Form';
import { transformDataForForm } from 'apps/PhoneSystem/containers/Voicemails/Edit/utility';
import FormActionRow from 'apps/shared/components/FormActionRow';
import { FormContext } from 'apps/shared/components/FormContext';
import { ADD_KEY } from 'constant';
import cloneDeep from 'lodash/cloneDeep';
import { useFetchVoicemailByIdQuery } from 'models/Voicemail';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import { isModified } from 'shared/utility';
import { DialogContext } from 'store/contexts';
import { useCleanUpUnmodifiedEntities } from '../../hooks/useCleanupUnmodifiedEntities';
import DataProvider from '../../SeatEntityDataProvider';

const VoicemailEditView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getValues, reset, setValue } = useFormContext();
  const dialogCtx = useContext(DialogContext);
  const { id } = useParams() as any;

  const { setOnCleanUp } = useCleanUpUnmodifiedEntities();

  const callDeleteVoicemail = async () => {
    if (id.startsWith(ADD_KEY)) {
      const values = getValues();
      delete values.voicemail[id];
      reset({ ...values }, { keepDirty: true });
    } else {
      setValue(`voicemail.${id}._toDelete`, true, { shouldDirty: true });
    }
    navigate('..');
  };

  useEffect(() => {
    if (id === ADD_KEY) {
      const deleteUnmodifiedVoicemail = (idToDelete: string, initialValues: any) => {
        const currentValues = getValues();
        if (initialValues && !isModified(initialValues, currentValues.voicemail[idToDelete])) {
          delete currentValues.voicemail[idToDelete];
          reset({ ...currentValues }, { keepDirty: true });
        }
      };

      // Add the timestamp to allow for multiple items to be created at a single time.
      const newId = `${ADD_KEY}-${Date.now()}`;
      navigate(`../${newId}`, { replace: true });

      const values = getValues();
      values.voicemail[newId] = cloneDeep({ ...defaultValues, id: newId });
      setOnCleanUp(() => deleteUnmodifiedVoicemail(newId, values.voicemail[newId]));

      reset({ ...values }, { keepDirty: true });
    }
  }, [id]);

  const renderPageWithData = (data?: any) => {
    const onDeleteVoicemail = () => {
      dialogCtx.onOpen({
        open: true,
        title: t('phone_system:containers.seats.voicemail.edit.actions.delete_voicemail_box.label'),
        confirmMessage: `${t('phone_system:containers.shared.edit_form.delete.text', {
          name: data?.name,
        })} ${t('common:delete_on_save')}`,
        onConfirm: callDeleteVoicemail,
      });
    };

    return (
      <>
        <FormActionRow
          button={{
            primary: {
              label: t(
                'phone_system:containers.seats.voicemail.edit.actions.delete_voicemail_box.label',
              ),
              onClick: onDeleteVoicemail,
            },
          }}
          title={
            data?.name
              ? t('phone_system:containers.seats.voicemail.edit.actions.editing_voicemail_box', {
                  name: data.name,
                })
              : t('phone_system:containers.seats.voicemail.edit.actions.editing_new_voicemail_box')
          }
        />
        <Form hasFormActionRow />
      </>
    );
  };

  if (id === ADD_KEY) {
    return <Loading hasMargin />;
  }

  return (
    <FormContext.Provider value={`voicemail.${id}.`}>
      {id.startsWith(ADD_KEY) ? (
        renderPageWithData()
      ) : (
        <DataProvider
          id={id}
          fetchFunction={useFetchVoicemailByIdQuery}
          entity="voicemail"
          defaultValues={defaultValues}
          transformData={transformDataForForm}
        >
          {(data: any) => renderPageWithData(data)}
        </DataProvider>
      )}
    </FormContext.Provider>
  );
};

export default VoicemailEditView;
