import { WidthType } from 'shared/utility/definition';
import labeledProps from '../../default';

const defaultProps = {
  ...labeledProps,
  label: '__TEXT_LABEL__',
  labelWidth: 'large' as WidthType,
  labelProps: {},
  text: '__DEFAULT_TEXT__',
  textWidth: 'auto' as WidthType,
};

export default defaultProps;
