import findIndex from 'lodash/findIndex';
import merge from 'lodash/merge';
import { BaseSyntheticEvent, Children, cloneElement, isValidElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DirtyIcon } from 'shared/components/Labeled';
import { StyledTab, StyledTabPanel, StyledTabs, StyledTabsWrapper } from './components';
import defaultProps from './default';
import { TabItem, useHorizontalTabsProps as Props } from './definition';

export type { TabItem as HorizontalTabItem };

const useHorizontalTabs = (props: Props) => {
  const {
    items,
    options: { hasBottomMargin, hasMenuOffset, hasScroll, isCentered, control },
    onChange,
  } = merge({}, defaultProps, props);
  const { activeTab } = useParams();
  const [index, setIndex] = useState(
    Math.max(
      0,
      findIndex(items, ({ hash }) => hash === activeTab), // if the param activeTab is not found, select the first tab
    ),
  );

  const on = {
    change: (e: BaseSyntheticEvent<Event>, i: number) => {
      onChange(e, items[i].hash);
      setIndex(i);
    },
  };

  const Tabs = (
    <StyledTabsWrapper
      $hasMargin={hasBottomMargin}
      $hasMenuOffset={hasMenuOffset}
      $hasScroll={hasScroll}
      $isCentered={isCentered}
    >
      <StyledTabs
        allowScrollButtonsMobile={hasScroll}
        scrollButtons={hasScroll}
        value={index}
        variant={hasScroll ? 'scrollable' : 'standard'}
        onChange={on.change}
      >
        {items.map(({ isDirty = false, isError = false, hash, label }: TabItem) => (
          <StyledTab
            key={hash}
            label={label}
            icon={<DirtyIcon isDirty={isDirty} isError={isError} isDot />}
          />
        ))}
      </StyledTabs>
    </StyledTabsWrapper>
  );

  const TabPanels = items.map(({ component }, i: number) => {
    const componentWithProps = Children.map(component, (child) =>
      isValidElement(child) ? cloneElement(child, { control, ...child.props }) : child,
    );

    return (
      <StyledTabPanel
        hidden={index !== i}
        key={`usehorizontaltabs-tabpanel-${items[index].hash}-${i}`}
      >
        {index === i && componentWithProps}
      </StyledTabPanel>
    );
  });

  return { Tabs, TabPanels };
};

export default useHorizontalTabs;
