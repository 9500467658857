import MuiCardActions, { CardActionsProps as Props } from '@mui/material/CardActions';
import { FunctionComponent } from 'react';

export type { CardActionsProps } from '@mui/material/CardActions';

const CardActions: FunctionComponent<Props> = (props: Props): JSX.Element => (
  <MuiCardActions {...props} data-test-id="shared-card-component-card-actions" />
);

export default CardActions;
