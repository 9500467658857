import { useDroppable } from '@dnd-kit/core';
import CallflowContext from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { DragSourceType, DropType } from 'models/Callflow/store/definition';
import {
  addCallFlowNumber,
  removeCallFlowNumber,
  updateCallFlowName,
} from 'models/Callflow/store/slice';
import { FunctionComponent, memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Droppable from 'shared/components/Droppable';
import Icon from 'shared/components/Icon';
import AddNumberDialog from './components/AddNumberDialog';
import EditCallflowNameDialog from './components/EditCallflowMetaDialog';
import { Form } from './components/EditCallflowMetaDialog/definition';
import { DialogState } from './definition';
import StyledFlowRoot from './style';

export { CONSTANTS as FLOW_ROOT_CONSTANTS } from './style';

const FlowRoot: FunctionComponent = memo(
  (): JSX.Element => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t } = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState<DialogState>({
      addNumber: false,
      editCallflowName: false,
    });
    const {
      isPreview,
      callflow,
      callflow: { isActivated: isDragging, isDroppable, id: callflowId },
    } = useContext(CallflowContext);
    const { setNodeRef } = useDroppable({ id: callflowId });

    const droppableProps = {
      id: 'root',
      disabled: !isDroppable || !isEmpty(callflow.nodes) || isPreview,
      data: {
        id: callflow.root,
        source: DragSourceType.TREE,
        type: DropType.ROOT,
      },
    };

    const addNumber = (value: any) => {
      if (value) {
        dispatch(addCallFlowNumber({ id, number: value }));
      }
      setIsDialogOpen((isDialogOpen: DialogState) => ({
        ...isDialogOpen,
        addNumber: false,
      }));
    };

    const removeNumber = (number: string, index: number) => {
      if (number) {
        dispatch(removeCallFlowNumber({ id, number, index }));
      }
    };

    const saveCallflowName = (form?: Form) => {
      if (form) {
        dispatch(updateCallFlowName({ id, data: form }));
      }

      setIsDialogOpen((isDialogOpen: DialogState) => ({
        ...isDialogOpen,
        editCallflowName: false,
      }));
    };

    const callflowName =
      callflow.name.trim() === ''
        ? t('phone_system:containers.callflows.callflow_edit_dialog.default_name')
        : callflow.name;

    return (
      <StyledFlowRoot
        ref={setNodeRef}
        isDragging={isDragging}
        isDroppable={isDroppable && isEmpty(callflow.nodes)}
      >
        <Droppable {...droppableProps}>
          <div
            onClick={() =>
              !isPreview &&
              setIsDialogOpen((isDialogOpen: DialogState) => ({
                ...isDialogOpen,
                editCallflowName: true,
              }))
            }
            data-test-id="callflow-container"
          >
            <span>{callflowName}</span>
            {!isPreview && <Icon name="edit" hasHover />}
          </div>
          <div>
            {[...callflow.numbers].map((item: string, i: number) => (
              <div key={`${i}-${item}`} data-test-id={`callflow-numbers-${item}-${i}`}>
                <span>{item}</span>
                {!isPreview && (
                  <Icon
                    name="cancel-outlined"
                    size={12}
                    onClick={removeNumber.bind(null, item, i)}
                  />
                )}
              </div>
            ))}
            {!isPreview && (
              <div
                onClick={() =>
                  setIsDialogOpen((isDialogOpen: DialogState) => ({
                    ...isDialogOpen,
                    addNumber: true,
                  }))
                }
                data-test-id="click-to-add-number"
              >
                <span>
                  {t('phone_system:containers.callflows.callflow_edit_dialog.add_number.link')}
                </span>
              </div>
            )}
          </div>
        </Droppable>
        <AddNumberDialog isOpen={isDialogOpen.addNumber} onClose={addNumber} />
        <EditCallflowNameDialog
          isOpen={isDialogOpen.editCallflowName}
          data={pick(callflow, ['name', 'contact_list'])}
          onClose={saveCallflowName}
        />
      </StyledFlowRoot>
    );
  },
);

export default FlowRoot;
