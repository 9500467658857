import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import { PlusProps as Props } from './definition';
import StyledPlus from './style';

const Plus: FunctionComponent<Props> = ({ isActivated }: Props): JSX.Element => (
  <StyledPlus isActivated={isActivated}>
    <Icon name="plus" set="custom" size={22} />
  </StyledPlus>
);

export default Plus;
