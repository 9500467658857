import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled from 'styled-components';
import { StyledNoItemsTextProps as Props } from './definition';

const StyledNoItemsText = styled(Box).attrs({ component: 'span' })<Props>`
  ${mixin.font({ style: 'italic' })};
  margin: 0 auto;
  user-select: none;
`;

export default StyledNoItemsText;
