import { useEffect } from 'react';
import { SelectOption } from 'shared/components/Select';
import { updateSelectParams } from './definition';

const useSetSelectValue = ({ itemName, options, selectRef }: updateSelectParams) => {
  useEffect(() => {
    if (itemName && options && selectRef?.current) {
      selectRef.current.setValue(
        options.find(({ label }: SelectOption) => label === itemName),
        'select-option',
      );
    }
  }, [itemName, options, selectRef]);
};

export default useSetSelectValue;
