export enum FormFields {
  ExtraInfoProvisioner = 'extra_info.provisioner',
  Provision = 'provision',
  ProvisionEndpointBrand = 'provision.endpoint_brand',
  ProvisionEndpointFamily = 'provision.endpoint_family',
  ProvisionEndpointModel = 'provision.endpoint_model',
  TempDeviceBrand = '_temp_device_brand',
  TempDeviceModel = '_temp_device_model',
}

export interface DeviceValues {
  /** Device brand */
  brand?: string;
  /** Device family */
  family?: string;
  /** Device model */
  model?: string;
}
