import { joiResolver } from '@hookform/resolvers/joi';
import SeatSelect from 'apps/PhoneSystem/shared/SeatSelect';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useFetchSeatsQuery } from 'models/Seat';
import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, SeatCallFlowDialogProps as Props, SeatNodeData } from './definition';
import schema from './schema';

const SeatDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { data: seatData, isLoading } = useFetchSeatsQuery();
  const { handleSubmit, control, formState, watch, reset } = useForm<Data>({
    defaultValues,
    resolver: joiResolver(schema()),
  });

  const { isDirty } = formState;
  const watchAdvanced = watch('advanced');

  const submitHandler = (formData: Data) => {
    const selected = seatData?.find(({ id }) => id === formData.id);
    const data = { ...formData };

    if (!data.advanced) {
      data.var = '';
    }

    const nodeData: SeatNodeData = {
      metadata: {
        name: `${selected.first_name} ${selected.last_name}`,
        pvt_type: 'user',
      },
      data,
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    const updatedData = enhancedFormUtility.transformDataToFormData(
      { ...data, advanced: Boolean(data?.var) },
      defaultValues,
    );

    reset(updatedData);
  }, [data, reset]);

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.seat.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <SeatSelect
        isLabelAbove
        name="id"
        label={t('phone_system:containers.callflows.callflow_action_dialog.seat.add.user')}
        control={control}
      />

      <HookFormInputWrapper name="timeout" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.seat.add.ring_duration',
            )}
            inputWidth="small"
            inputProps={{
              type: 'number',
              ...formProps,
              id: 'input-seat-ring-duration',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="can_call_self" control={control} isCheckbox>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            isDirty={isDirty}
            feedback={feedback}
            isCheckboxLeft
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.seat.add.allow_self_call.label',
            )}
            tooltip={t(
              'phone_system:containers.callflows.callflow_action_dialog.seat.add.allow_self_call.tooltip',
            )}
            indentWidth="small"
            checkboxProps={{
              ...formProps,
              id: 'checkbox-seat-allow-self-call',
            }}
          />
        )}
      </HookFormInputWrapper>
      <HookFormInputWrapper name="advanced" control={control} isCheckbox>
        {({ ref, isDirty, ...formProps }) => (
          <LabeledCheckbox
            hasMargin
            isDirty={isDirty}
            label={t('phone_system:containers.callflows.callflow_action_dialog.seat.add.advanced')}
            indentWidth="small"
            checkboxProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormInputWrapper>

      {watchAdvanced && (
        <HookFormInputWrapper name="var" control={control}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              labelWidth="xsmall"
              label={t(
                'phone_system:containers.callflows.callflow_action_dialog.seat.add.route_variable',
              )}
              inputWidth="xmedium"
              inputProps={{
                ...formProps,
              }}
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default SeatDialog;
