// Account Mutations
import { Auth } from 'api/auth';
import { RequestMethod } from 'definition';
import { kazooApi } from 'services/kazoo';

export const authMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: Auth.login(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
    }),
    reset: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: Auth.reset(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
    }),
    validateResetId: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: Auth.reset(),
        method: RequestMethod.Post,
        body: { data: { ...body } },
      }),
    }),
  }),
});
