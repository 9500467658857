import { FunctionComponent, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import SideNavItem from '../SideNavItem';
import { SideNavItemProps as ItemProps } from '../SideNavItem/definition';
import defaultProps from './default';
import { SecondaryItemsProps as Props } from './definition';
import StyledSecondaryItems from './style';

const SecondaryItems: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { items }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const [height, setHeight] = useState<number | string>('auto');
  const isOpen = height !== 0;

  /**
   * t('phone_system:global.show_less')
   * t('phone_system:global.show_more')
   */

  return (
    <StyledSecondaryItems>
      <SideNavItem
        isOpen={isOpen}
        isToggle
        icon={{ name: 'chevron-down-round', size: 21 }}
        label={`phone_system:global.show_${isOpen ? 'less' : 'more'}`}
        onClick={() => setHeight((height: number | string) => (height === 0 ? 'auto' : 0))}
      />
      {/** @ts-ignore */}
      <AnimateHeight duration={150} easing="ease" height={height}>
        {items
          .filter(({ isPrimary }: ItemProps): boolean => !isPrimary)
          .map(
            ({ icon, label, slug }: ItemProps): JSX.Element => (
              <SideNavItem key={label} icon={icon} label={label} slug={`./${slug}`} />
            ),
          )}
      </AnimateHeight>
    </StyledSecondaryItems>
  );
};

export default SecondaryItems;
