import merge from 'lodash/merge';
import { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Icon from '../Icon';
import Menu from '../Menu';
import SearchInput from '../SearchInput';
import IconButton from './components/IconButton';
import MenuItem from './components/MenuItem';
import MenuItemInfo from './components/MenuItemInfo';
import MenuItemNoItemsText from './components/MenuItemNoItemsText';
import defaultProps from './default';
import { DropdownButtonProps as Props, DropdownItem } from './definition';
import StyledDropdownButton from './style';
import { getClassName } from './utility';

export type { DropdownItem } from './definition';

const DropdownButton: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasMargin,
    hasSearch,
    isBelow,
    isDisabled,
    isFormElement,
    isHeaderElement,
    isMenuRight,
    buttonProps,
    icon,
    hasIconButton,
    iconButtonTooltip,
    isDarkMode,
    info,
    items,
    label,
    leftMargin,
    noItemsText,
    selected,
    onClick,
    ...rest
  }: Props = merge({}, defaultProps, props);

  const { t } = useTranslation();
  const filterRef = useRef<HTMLInputElement>();
  const [filter, setFilter] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = !!anchorEl;

  const horizontal = isMenuRight ? 'left' : 'right';
  const menuProps: Record<string, Record<string, string>> = {
    anchorOrigin: { vertical: 'bottom', horizontal },
    transformOrigin: { vertical: 'top', horizontal },
  };

  const className = getClassName(
    isFormElement,
    isHeaderElement,
    isOpen,
    buttonProps.color as string,
  );
  const handleClose = () => setAnchorEl(null);

  const buttonElement = hasIconButton ? (
    <IconButton
      isDarkMode={isDarkMode}
      isDropdownOpen={isOpen}
      icon={icon ?? 'kebab-default'}
      tooltip={iconButtonTooltip}
      onClick={(e: any) => setAnchorEl(e.currentTarget)}
    />
  ) : (
    <Button
      disabled={isDisabled}
      className={className.button.value}
      startIcon={icon !== '' ? <Icon name={icon ?? 'plus-circle-outlined'} size={16} /> : null}
      endIcon={<Icon name="dropdown-down" size={19} />}
      onClick={(e: any) => setAnchorEl(e.currentTarget)}
      {...buttonProps}
    >
      <span className="MuiButton-dropdown-label">{label}</span>
    </Button>
  );

  useEffect(() => {
    if (filter === '') {
      filterRef.current?.focus();
    }
  }, [filter]);

  return (
    <StyledDropdownButton
      hasMargin={hasMargin}
      isBelow={isBelow}
      leftMargin={leftMargin}
      onClick={onClick}
      {...rest}
      data-test-id="shared-dropdown-button"
    >
      {buttonElement}
      {items && (
        <Menu
          classes={isHeaderElement ? { root: 'MuiMenu-header' } : {}}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          {...menuProps}
        >
          {hasSearch && (
            <MenuItem className="MuiMenuItem-search" onKeyDown={(e) => e.stopPropagation()}>
              <SearchInput
                iconSize="small"
                inputProps={{
                  className: 'MuiInputBase-small',
                  autoFocus: true,
                  inputRef: filterRef,
                  value: filter,
                  placeholder: t('common:search'),
                  onChange: (e: ChangeEvent<HTMLInputElement>) => setFilter(e.target.value),
                }}
                onReset={() => setFilter('')}
              />
            </MenuItem>
          )}
          {info && <MenuItemInfo value={info} />}
          {items.length > 0 ? (
            <div className="MuiMenu-list-container">
              {items
                .filter((item: DropdownItem) =>
                  item.label.toLowerCase().includes(filter.toLowerCase()),
                )
                .map((item: DropdownItem, i: number) => {
                  const isSelected = selected ? selected === item.id : false;
                  return (
                    <MenuItem
                      key={i}
                      tooltip={item.tooltip}
                      className={`${className.item.value}${
                        isSelected ? className.item.selected : ''
                      }`}
                      onClick={() => {
                        if (!isSelected) {
                          item.onClick();
                          handleClose();
                        }
                      }}
                    >
                      {item.icon && (
                        <Icon className="MuiSvgIcon-dropdown-button-left" name={item.icon} />
                      )}
                      {item.label}
                      {isSelected && (
                        <Icon className="MuiSvgIcon-dropdown-button-right" name="check" />
                      )}
                    </MenuItem>
                  );
                })}
            </div>
          ) : (
            <MenuItemNoItemsText isInfo={!!info} value={noItemsText} />
          )}
        </Menu>
      )}
    </StyledDropdownButton>
  );
};

export default DropdownButton;
