import styled, { css } from 'styled-components';
import StyledLabeled from '../../style';
import { StyledLabeledTextareaProps as Props } from './definition';

const StyledLabeledTextarea = styled(StyledLabeled)<Props>`
  ${(props) => css`
    label {
      margin-top: 6px;
    }
  `}
`;

export default StyledLabeledTextarea;
