import { FunctionComponent } from 'react';
import Box from 'shared/components/Box';
import Typography from 'shared/components/Typography';
import defaultProps from './default';
import { LogoProps as Props } from './definition';
import StyledLogo from './style';

const Logo: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { logo, message }: Props = { ...defaultProps, ...props };

  return (
    <StyledLogo logo={logo}>
      <Box />
      <Typography variant="h3">{message}</Typography>
    </StyledLogo>
  );
};

export default Logo;
