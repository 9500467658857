import dialogDefaultProps from '../../default';
import { Data } from './definition';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues: Data = {
  id: '',
  var: '',
  /**
   * this value needs to be stored in seconds
   * eg: 15 minutes = 900 seconds or 15 hours = 54000 seconds
   */
  window: '15',
  route_to_another_queue: false,
  window_unit: 'minutes',
};
