import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const Conference = {
  // GET
  Fetch: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/conferences?paginate=${isPaginated}`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/conferences/${id}`,
  FetchByOwnerId: (id: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/conferences?filter_owner_id=${id}`,
  Participants: (id: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/conferences/${id}/participants`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/conferences`,
  // Action: (id: string) => `accounts/${ACCOUNT_URL_PLACEHOLDER}/conferences/${id}`,
  ParticipantsAction: (id: string, participantId: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/conferences/${id}/participants/${participantId}`,
  ParticipantsBulkAction: (id: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/conferences/${id}/participants`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/conferences/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/conferences/${id}`,
};
