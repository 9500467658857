import {
  service as advancedProvisionerApi,
  TAGS as ADV_PROV_TAGS,
} from 'services/advancedProvisioner';
import { bizProvApi } from 'services/bizProv';
import { bizUserApi, TAGS as BIZ_USER_TAGS } from 'services/bizUser';
import { iamApi, TAGS as IAM_TAGS } from 'services/iam';
import { kazooApi, TAGS as KAZOO_TAGS } from 'services/kazoo';
import {
  service as legacyProvisionerApi,
  TAGS as LEGACY_PROV_TAGS,
} from 'services/legacyProvisioner';
import { accountSlice } from './Account';
import { authSlice } from './Auth';
import { undoableCallflowReducer } from './Callflow/store/slice';
import { deviceSlice } from './Device/slice';

export const reducer = {
  auth: authSlice.reducer,
  account: accountSlice.reducer,
  callflows: undoableCallflowReducer,
  device: deviceSlice.reducer,
  [advancedProvisionerApi.reducerPath]: advancedProvisionerApi.reducer,
  [bizProvApi.reducerPath]: bizProvApi.reducer,
  [bizUserApi.reducerPath]: bizUserApi.reducer,
  [iamApi.reducerPath]: iamApi.reducer,
  [kazooApi.reducerPath]: kazooApi.reducer,
  [legacyProvisionerApi.reducerPath]: legacyProvisionerApi.reducer,
};

// Utilities that put in this object will be automatically triggered when switching accounts
export const invalidateCacheUtilities = {
  advancedProvisioner: advancedProvisionerApi.util.invalidateTags(Object.values(ADV_PROV_TAGS)),
  bizUser: bizUserApi.util.invalidateTags(Object.values(BIZ_USER_TAGS)),
  iam: iamApi.util.invalidateTags(Object.values(IAM_TAGS)),
  kazoo: kazooApi.util.invalidateTags(Object.values(KAZOO_TAGS)),
  provisioner: legacyProvisionerApi.util.invalidateTags(Object.values(LEGACY_PROV_TAGS)),
};
