import Icon from 'shared/components/Icon';
import TEST_ID from 'shared/utility/testing/constants/testId';
import defaultProps from './default';
import { DownloadButtonProps as Props } from './definition';
import { StyledDownloadButton } from './style';

export default (props: Props) => {
  const { fileUrl, fileName, children, size, onDownloadStart } = {
    ...defaultProps,
    ...props,
  };

  return (
    <StyledDownloadButton
      href={fileUrl}
      download={fileName}
      data-test-id={TEST_ID.COMMON.FILE_UPLOAD_INPUT.DOWNLOAD_BUTTON}
      onClick={onDownloadStart}
    >
      <Icon hasHover name="download" size={size} />
      {children}
    </StyledDownloadButton>
  );
};
