import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { ACTION_CONSTANTS } from './components/Action';
import { StyledInteractionBarProps as Props } from './definition';

const StyledInteractionBar = styled.nav.attrs({
  className: 'component-callflow-interaction-bar',
})<Props>`
  ${(props) => css`
    ${mixin.borderRadius()};
    ${mixin.boxShadow({ offsetY: 1, blurRadius: 3 })};
    ${mixin.flex()};
    height: ${ACTION_CONSTANTS.HEIGHT[props.type]}px;
    background-color: ${props.theme.core.color.white};
    z-index: 10;
  `};
`;

export default StyledInteractionBar;
