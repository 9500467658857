import { AppMeta } from 'apps/shared/definition';
import { AdvancedProvisioner } from '.';

export const meta: AppMeta = {
  component: AdvancedProvisioner,
  icon: 'provisioner',
  name: 'provisioner',
  slug: 'provisioner',
};

export const appUrl = `/apps/${meta.slug}`;
