// Device Queries
import { Device } from 'api/device';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const deviceQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchDevices: builder.query<DeviceResponse, void>({
      query: () => ({
        url: Device.Fetch(),
        responseHandler,
      }),
      extraOptions: { maxRetries: 1 },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.DEVICE as const, id })),
              { type: TAGS.DEVICE, id: 'LIST' },
            ]
          : [{ type: TAGS.DEVICE, id: 'LIST' }],
    }),
    fetchDeviceById: builder.query<Device, ByIdParam>({
      query: ({ id }) => ({
        url: Device.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.DEVICE, id }];
      },
    }),
    fetchDeviceStatuses: builder.query<Device, void>({
      query: () => ({
        url: Device.FetchStatuses(),
        responseHandler,
      }),
      transformResponse: (response: any) =>
        response.reduce((acc: any, item: any) => {
          acc[item.device_id] = item.registered;
          return acc;
        }, {}),
      providesTags: (result) => [{ type: TAGS.DEVICE }],
    }),
    fetchDevicesByOwnerId: builder.query<DeviceResponse, ByIdParam>({
      query: ({ id }) => ({
        url: Device.FetchByOwnerId(id),
        responseHandler,
      }),
      extraOptions: { maxRetries: 1 },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.DEVICE as const, id })),
              { type: TAGS.DEVICE, id: 'LIST' },
            ]
          : [{ type: TAGS.DEVICE, id: 'LIST' }],
    }),
  }),
});
