const CONSTANTS = {
  ALL_QUEUES_ID: 'ALL_QUEUES',
  POLLING_INTERVAL: 2000,
  QUERY: {
    get OPTIONS() {
      return {
        pollingInterval: CONSTANTS.POLLING_INTERVAL,
      };
    },
  },
};

export default CONSTANTS;
