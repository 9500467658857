import { useTranslation } from 'react-i18next';
import { VersionTagProps as Props } from './definition';
import StyledVersionTag from './style';

const VersionTag = ({ hasAuxColor, isSmall }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledVersionTag $hasAuxColor={hasAuxColor} $isSmall={isSmall}>
      {`${t('common:version')} ${APP_VERSION}`}
    </StyledVersionTag>
  );
};

export default VersionTag;
