import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import connectivity from 'api/connectivity';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';
import { Connectivity, ConnectivityResponse } from './types';

export const connectQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchConnectivityById: builder.query<ConnectivityResponse, ByIdParam>({
      query: ({ id }) => ({
        url: connectivity.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => [{ type: TAGS.CONNECTIVITY, id }],
    }),

    fetchAllConnectivity: builder.query<ConnectivityResponse, void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const ConnectivityAllResult = await fetchWithBQ(connectivity.FetchAll());
        if (ConnectivityAllResult.error) {
          return { error: ConnectivityAllResult.error as FetchBaseQueryError };
        }

        const connections = ConnectivityAllResult.data as any;

        const results = await connections.data.reduce(
          async (previousConnectionPromise: any, connectionId: string) => {
            const connection = await previousConnectionPromise;
            const result = await fetchWithBQ(connectivity.FetchById(connectionId));
            connection.push((result.data as Connectivity).data);

            return connection;
          },
          Promise.resolve([]),
        );

        return results
          ? { data: results as Connectivity }
          : { error: results.error as FetchBaseQueryError };
      },
      providesTags: (result, error) => [{ type: TAGS.CONNECTIVITY }],
    }),
  }),
});
