// Faxbox Mutations
import { Faxbox } from 'api/faxbox';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const faxboxMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createFaxbox: builder.mutation<Faxbox, Partial<Faxbox>>({
      query: ({ body }) => ({
        url: Faxbox.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.FAXBOX, id: 'LIST' }],
    }),
    updateFaxbox: builder.mutation<Faxbox, UpdateFaxboxParams>({
      query: ({ id, body }) => ({
        url: Faxbox.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.FAXBOX, id }],
    }),
    deleteFaxbox: builder.mutation<Faxbox, ByIdParam>({
      query: ({ id }) => ({
        url: Faxbox.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: [{ type: TAGS.FAXBOX, id: 'LIST' }],
    }),
  }),
});
