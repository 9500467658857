import { defaultCallRestrictionValues } from 'apps/shared/hooks/useCallRestrictionsSection/components/CallRestrictionsSection';

export const defaultValues = {
  seat: {
    acdc_agent_priority: -128,
    acdc_min_call_duration: '0',
    call_forward: {
      enabled: false,
      keep_caller_id: true,
      number: '',
      require_keypress: false,
      substitute: false,
    },
    call_restriction: defaultCallRestrictionValues,
    caller_id: {
      emergency: {
        name: '',
        number: '',
      },
      external: {
        name: '',
        number: '',
      },
      internal: {
        name: '',
        number: '',
      },
    },
    confirm_password: '',
    fax_to_email_enabled: true,
    is_setup: false,
    location: 'default',
    media_extension: '',
    music_on_hold: { media_id: '' },
    password: '',
    phone_number: '',
    presence_id: '',
    seat_type: 'default',
    should_create_caller_id: true,
    username: '',
    vm_to_email_enabled: true,
  },
  conference: {},
  device: {},
  faxbox: {},
  sms: {},
  voicemail: {},
};
