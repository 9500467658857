import { metaNumbers } from 'apps/meta';
import {
  ResultStatus,
  useAccountActiveAddresses,
} from 'apps/shared/hooks/useAccountActiveAddresses';
import useLocalStorage from 'apps/shared/hooks/useLocalStorage';
import { FORMAT, LOCAL_STORAGE } from 'constant';
import { addMonths, format } from 'date-fns';
import { useFetchAccountQuery, usePatchAccountByIdMutation } from 'models/Account';
import { selectAccountId } from 'models/Account/slice';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import useE911Dialogs, { Dialogs } from './useE911Dialogs';

const E911NotificationDialog: FunctionComponent = (): JSX.Element => {
  const [state, dialogDispatch] = useE911Dialogs();
  const [termsAccepted, setTermsAccepted] = useLocalStorage<string[]>(
    LOCAL_STORAGE.E911_NOTIFICATION,
    [],
  );
  const {
    data: accountData,
    isSuccess: isAccountDataSuccess,
    isFetching: isAccountDataFetching,
  } = useFetchAccountQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const accountId = useSelector<string, string>(selectAccountId);
  const { data, isSuccess, isFetching } = useAccountActiveAddresses();
  const [patchAccountById] = usePatchAccountByIdMutation();

  // Check if active accountId has already acknowledged E911 dialog and memoize the value
  const e911DialogIsAcknowledge = useMemo(() => termsAccepted.includes(accountId), [
    termsAccepted,
    accountId,
  ]);

  // Add accounId to acknowledged Array on local storage if it is already not present
  const addIdOnLocalStorage = () => {
    if (e911DialogIsAcknowledge) {
      return;
    }
    setTermsAccepted([...termsAccepted, accountId]);
  };

  useEffect(() => {
    // Check if user has already seen the notification, do not render the dialog again.
    if (e911DialogIsAcknowledge) {
      return;
    }

    // If all active numbers does not contain address, show this dialog.
    if (data === ResultStatus.E911_ADDRESSES_MISSING && !isAccountDataFetching && !isFetching) {
      dialogDispatch(Dialogs.SET_WARNING);
    }

    // If all active numbers have address, check if the review was done over 6 months ago from today,
    // show this dialog
    if (isSuccess && data === ResultStatus.E911_ADDRESSES_PRESENT && isAccountDataSuccess) {
      const { e911_timestamp } = accountData;

      const expireDate = e911_timestamp
        ? addMonths(new Date(e911_timestamp), 6)
        : new Date(e911_timestamp);

      if (expireDate < new Date()) {
        dialogDispatch(Dialogs.SET_REVIEW);
      }
    }
  }, [
    isSuccess,
    data,
    isAccountDataSuccess,
    accountData,
    e911DialogIsAcknowledge,
    isFetching,
    isAccountDataFetching,
  ]);

  const ACTION_BUTTONS = {
    REMIND_LATER: (
      <Button
        onClick={() => {
          addIdOnLocalStorage();
          dialogDispatch(Dialogs.RESET_ALL);
        }}
        color="secondary"
        variant="outlined"
        data-test-id="btn-dialog-action-remind-later"
      >
        {t('common:remind_me_later')}
      </Button>
    ),
    REVIEW: (
      <Button
        onClick={async () => {
          addIdOnLocalStorage();
          dialogDispatch(Dialogs.RESET_ALL);
          if (state.reviewDialog) {
            await patchAccountById({
              id: accountId,
              body: { e911_timestamp: format(new Date(), FORMAT.TIMESTAMP) },
            });
          }
          dialogDispatch(Dialogs.RESET_ALL);
          navigate(`/apps/${metaNumbers.slug}`);
        }}
        color="success"
        variant="contained"
        data-test-id="btn-dialog-action-review"
      >
        {t('common:review')}
      </Button>
    ),
  };

  const reviewDialog = (
    <Dialog
      open={state.reviewDialog}
      title={t('common:component.e911_notification.review_dialog.title')}
      hasClose={false}
      renderActions={
        <DialogActions
          rightButtons={
            <>
              {ACTION_BUTTONS.REMIND_LATER}
              {ACTION_BUTTONS.REVIEW}
            </>
          }
        />
      }
    >
      <span>{t('common:component.e911_notification.review_dialog.content')}</span>
    </Dialog>
  );

  const warningDialog = (
    <Dialog
      open={state.warningDialog}
      title={t('common:component.e911_notification.warning_dialog.title')}
      hasClose={false}
      renderActions={
        <DialogActions
          rightButtons={
            <>
              {ACTION_BUTTONS.REMIND_LATER}
              {ACTION_BUTTONS.REVIEW}
            </>
          }
        />
      }
    >
      <span>{t('common:component.e911_notification.warning_dialog.content')}</span>
    </Dialog>
  );

  if (state.reviewDialog) {
    return reviewDialog;
  }
  if (state.warningDialog) {
    return warningDialog;
  }
  return <></>;
};

export default E911NotificationDialog;
