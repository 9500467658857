import { MILLISECONDS_IN_SECOND } from 'constant';
import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';
import { dateToGregorian } from 'shared/utility/date';

const INIT_DURATION = {
  years: 0,
  months: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

const useDuration = (timestamp: number): Duration => {
  const [duration, setDuration] = useState<Duration>(INIT_DURATION);

  useEffect(() => {
    const calculateDuration = () => {
      setDuration(
        intervalToDuration({
          start: 0,
          end: (dateToGregorian(new Date()) - timestamp) * MILLISECONDS_IN_SECOND,
        }),
      );
    };

    // Calculate duration initial value
    calculateDuration();

    const interval = setInterval(calculateDuration, 1000);

    return () => {
      setDuration(INIT_DURATION);
      clearInterval(interval);
    };
  }, [timestamp]);

  return duration;
};

export default useDuration;
