import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class MenuActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.MENU,
      module: CallFlowModuleKey.MENU,
      section: CallFlowSection.BASIC,
      rules: [
        {
          type: 'quantity',
          maxSize: 9999,
        },
      ],
      iconProps: {
        name: 'apps-filled',
      },
      label: i18next.t('phone_system:containers.callflows.actions.menu.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.menu.tooltip'),
    });
  }
}

export default MenuActionConfig;
