// Notification Queries
import { Notification } from 'api/notification';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const notificationQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchNotificationById: builder.query<Notification, NotificationParams>({
      query: ({ accountId, typeId }) => ({
        url: Notification.FetchById(accountId, typeId),
        responseHandler,
      }),
      providesTags: (result, error, { typeId }) => {
        return [{ type: TAGS.NOTIFICATION, typeId }];
      },
    }),
  }),
});
