import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class CallerIdResetPrependActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.CALLER_ID_RESET_PREPEND,
      module: CallFlowModuleKey.CALLER_ID_RESET_PREPEND,
      section: CallFlowSection.CALLER_ID,
      hasDialog: false,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'cached',
      },
      label: i18next.t('phone_system:containers.callflows.actions.caller_id_reset_prepend.name'),
      tooltip: i18next.t(
        'phone_system:containers.callflows.actions.caller_id_reset_prepend.tooltip',
      ),
    });
  }
}

export default CallerIdResetPrependActionConfig;
