import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import { DirtyIcon } from 'shared/components/Labeled';
import defaultProps from './default';
import { DialogLinkProps as Props } from './definition';
import StyledDialogLink from './style';

const DialogLink: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasMargin,
    isBelow,
    isDirty,
    isDisabled,
    icon: customIcon,
    iconSize: customIconSize,
    indentWidth,
    text,
    type,
    onClick,
  } = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();

  const ICON: Record<string, Record<string, number | string>> = {
    add: {
      name: 'plus-circle-outlined',
      size: 20,
    },
    edit: {
      name: 'edit',
      size: 22,
    },
  };

  const icon = customIcon ?? (ICON[type].name as string);
  const iconSize = customIconSize ?? (ICON[type].size as number);

  return (
    <StyledDialogLink
      hasMargin={hasMargin}
      isBelow={isBelow}
      isDisabled={isDisabled}
      indentWidth={indentWidth}
      onClick={onClick}
    >
      <Icon name={icon} size={iconSize} />
      <Box component="span">{text ?? t('common:edit')}</Box>
      {isDirty && <DirtyIcon isInline isDirty={isDirty} />}
    </StyledDialogLink>
  );
};

export default DialogLink;
