import { components } from 'react-select';
import Icon from 'shared/components/Icon';
import { IndicatorProps } from '../definition';

const DropdownIndicator = (props: IndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon name="dropdown-down" size={21} />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
