import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from 'shared/components/Box';
import AddressAlert from './components/AddressAlert';
import defaultProps from './default';
import { FormAddressAlertRowProps as Props } from './definition';

const FormAddressAlertRow = (props: Props) => {
  const { hasMargin, isAccountLevel, field, seatId, setIsNumbersInUsePickerOpen } = {
    ...defaultProps,
    ...props,
  };
  const { getValues, watch } = useFormContext();
  const [number, setNumber] = useState<string>(getValues(field));
  const debouncedSetNumber = debounce(setNumber, 300);
  const watchNumber = watch(field);

  useEffect(() => {
    debouncedSetNumber(watchNumber);
  }, [watchNumber, debouncedSetNumber]);

  return (
    <Box className="one-column" role="row">
      <Box role="cell">
        <AddressAlert
          hasMargin={hasMargin}
          isAccountLevel={isAccountLevel}
          number={number}
          seatId={seatId}
          setIsNumbersInUsePickerOpen={setIsNumbersInUsePickerOpen}
        />
      </Box>
    </Box>
  );
};

export default FormAddressAlertRow;
