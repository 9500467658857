import { themeColor } from 'theme';

export default {
  '.MuiCardContent-root': {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    height: '100%',
    padding: 0,
    '> div': {
      height: 'inherit',
      h2: {
        alignSelf: 'flex-start',
      },
      '&:first-of-type': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '35%',
        minWidth: 150,
        margin: '0 5% 0 0',
        '.MuiTypography-h2': {
          margin: 0,
        },
        '> span': {
          fontSize: 60,
          color: themeColor.core.text.dark,
        },
      },
      '&:last-of-type': {
        width: 'calc(60%)',
        justifyContent: 'flex-start',
      },
    },
  },
};
