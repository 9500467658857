import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';

const StyledMissingValue = styled(Box).attrs({ component: 'span' })`
  ${({ theme }) => css`
    color: ${theme.core.states.error.main};
    font-style: italic;
  `};
`;

export default StyledMissingValue;
