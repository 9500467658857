import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { LOCAL_STORAGE, URL } from 'constant';
import jwt_decode from 'jwt-decode';
import { dynamicBaseQuery } from './utility/dynamicBaseQuery';

export enum TAGS {
  ACCOUNT_SETTINGS = 'ADV_PROV_ACCOUNT_SETTINGS',
  ACCOUNT_TEMPLATE = 'ADV_PROV_ACCOUNTS_TEMPLATE',
  DEVICE = 'ADV_PROV_DEVICE',
  DEVICE_TEMPLATE = 'ADV_PROV_DEVICE_TEMPLATE',
  FILES = 'ADV_PROV_FILES',
  PHONES = 'ADV_PROV_PHONES',
}

export const responseHandler = async (response: any) => {
  try {
    const data = await response.json();
    return data.data;
  } catch (e) {
    return {};
  }
};

let clusterId = '';
const baseQuery = dynamicBaseQuery(
  fetchBaseQuery({
    baseUrl: URL.EXTERNAL.ADVANCED_PROVISIONER,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
      if (token) {
        headers.set('X-Auth-Token', token);

        if (!clusterId) {
          const { account_id }: Record<string, any> = jwt_decode(token) ?? {};
          clusterId =
            (
              await (
                await fetch(`${window.nemo?.api?.crossbar}accounts/${account_id}/configs/cluster`, {
                  method: 'GET',
                  headers: { 'X-Auth-Token': token },
                })
              ).json()
            ).data?.cluster_id ?? '';
        }
      }

      headers.set('X-Kazoo-Cluster-ID', clusterId);
      return headers;
    },
  }),
  true,
);

export const service = createApi({
  reducerPath: 'advanced_provisioner_api',
  baseQuery: retry(baseQuery, { maxRetries: 0 }),
  endpoints: () => ({}),
  tagTypes: Object.values(TAGS),
});
