import CallflowContext from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import { useFetchCallflowByIdQuery } from 'models/Callflow';
import { getSubtitle } from 'models/Callflow/actions';
import { CallFlowKey } from 'models/Callflow/definition';
import { selectCallflowMetadata } from 'models/Callflow/store/selectors';
import { buildCallflowName } from 'models/Callflow/utility';
import { useFetchUsersQuery } from 'models/User';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loading from 'shared/components/Loading';
import Tooltip from 'shared/components/Tooltip';
import { Props } from './definition';
import { StyledFlowChildSubtitle } from './style';
import { getSubTitleClass, hasDialog } from './utility';

const FlowChildSubtitle = ({ node, handlePreview, setIsCallFlowDialogOpen }: Props) => {
  const { actionName, data: nodeData, isActivated: isDragging } = node;
  const { id } = nodeData;
  const { callflow } = node;
  const {
    callflow: { id: callflowId },
    isPreview,
  } = useContext(CallflowContext);
  const { t } = useTranslation();
  const metadata = useSelector((state) => selectCallflowMetadata(state, callflowId, nodeData.id));
  const shouldSkipCallFlow = actionName !== CallFlowKey.CALLFLOW;
  const { data: callFlowData, isLoading: isLoadingCallFlow } = useFetchCallflowByIdQuery(
    { id },
    { skip: shouldSkipCallFlow },
  );
  const { data: usersData } = useFetchUsersQuery();
  // When the title has a separate click handler
  // const _subtitle = subtitle?.length > 5 ? `${subtitle.substring(0, 5)} ...` : subtitle;
  const isLoopingCallflow = callFlowData?.id === callflowId;
  const subTitleClass = getSubTitleClass(callflow?.module);
  let subtitle = getSubtitle(actionName, nodeData, metadata);
  let subtitleWrapper = callflow?.hasDialog ? (
    <span className={subTitleClass} onClick={() => callflow?.hasDialog && handlePreview()}>
      {subtitle}
    </span>
  ) : (
    <>{subtitle}</>
  );

  if ([CallFlowKey.CALLFLOW].includes(callflow?.key)) {
    subtitle = isLoadingCallFlow ? (
      <Loading />
    ) : (
      <span onClick={() => !!callFlowData && !isLoopingCallflow && setIsCallFlowDialogOpen(true)}>
        {buildCallflowName(callFlowData, usersData)}
      </span>
    );

    subtitleWrapper = <span className={subTitleClass}>{subtitle}</span>;
  }

  return (
    <StyledFlowChildSubtitle
      isDragging={isDragging}
      isPreview={isPreview}
      hasDialog={hasDialog(callflow?.hasDialog, isLoopingCallflow)}
    >
      {callflow?.key ? (
        <Tooltip title={subtitle}>{subtitleWrapper}</Tooltip>
      ) : (
        <div>{t('phone_system:containers.callflows.callflow_actions.unsupported')}</div>
      )}
    </StyledFlowChildSubtitle>
  );
};

export default FlowChildSubtitle;
