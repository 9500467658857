import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { RouterLink } from 'apps/shared/components/RouterLink';
import { uniqueId } from 'lodash';
import { convertToKebabCase } from 'shared/utility';
import Box from '../Box';
import { Breadcrumb, BreadcrumbsProps as Props } from './definition';

export type { Breadcrumb } from './definition';

const Breadcrumbs = ({ data, breadcrumbsProps }: Props) =>
  data && (
    <MuiBreadcrumbs {...breadcrumbsProps} data-test-id="shared-breadcrumbs">
      {data
        .filter(({ text }: Breadcrumb) => Boolean(text))
        .map(({ text, url }: Breadcrumb) => {
          const attrs = {
            key: `shared-breadcrumbs-segment-${
              typeof text === 'string' ? convertToKebabCase(text) : uniqueId()
            }`,
            get 'data-test-id'() {
              return attrs.key;
            },
          };

          let link;
          if (url) {
            link =
              typeof url === 'string' ? (
                <RouterLink to={url} {...attrs}>
                  {text}
                </RouterLink>
              ) : (
                <Box onClick={url} {...attrs}>
                  {text}
                </Box>
              );
          }

          return link ?? <Typography {...attrs}>{text}</Typography>;
        })}
    </MuiBreadcrumbs>
  );

export default Breadcrumbs;
