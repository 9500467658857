import SharedDialog from 'apps/PhoneSystem/shared/SharedDialog';
import { SharedName } from 'apps/PhoneSystem/shared/SharedDialog/definition';
import { FormContext } from 'apps/shared/components/FormContext';
import { HookFormFieldArrayWrapper, HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { FunctionComponent, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledSwitch } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../../definition';
import { isCompoundFieldDirty } from '../../../utility';
import { checkSmsShareability, getPhoneNumberFieldName } from '../../utility/index';

type Props = TabPanelProps<FormInput>;

const MAX_SELECTED_USERS = 60;

const OptionsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const fieldPathPrefix = useContext(FormContext);
  const watchPhoneNumber = watch(getPhoneNumberFieldName(fieldPathPrefix));
  const { data: phoneNumbersData } = useFetchPhoneNumbersQuery();
  const isShareableSms = checkSmsShareability(watchPhoneNumber, phoneNumbersData);

  return (
    <div role="row">
      <div role="cell">
        {/* Phone number */}
        <HookFormInputWrapper name="phone_number">
          {({ ref, isDirty, ...formProps }) => (
            <LabeledInput
              label={t('phone_system:containers.sms.section.options.phone_number.label')}
              data-test-id="input-options-phone-number"
              id="input-options-phone-number"
              isDirty={isDirty}
              inputProps={{
                disabled: true,
                ...formProps,
              }}
            />
          )}
        </HookFormInputWrapper>
        {isShareableSms && (
          <>
            {/* Shared SMS */}
            <HookFormInputWrapper name="shared_box" isSwitch>
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledSwitch
                  id="checkbox-options-shared-sms"
                  isSwitchLeft
                  isDirty={isDirty}
                  feedback={feedback}
                  label={t('phone_system:containers.sms.section.options.shared_sms.label')}
                  switchProps={{
                    ...formProps,
                  }}
                />
              )}
            </HookFormInputWrapper>

            <HookFormFieldArrayWrapper
              name="members"
              watchField="shared_box"
              customIsDirty={isCompoundFieldDirty}
            >
              {({ ref, isDirty, feedback, watchValue, ...sharedSMSProps }) => (
                <SharedDialog
                  sharedName={SharedName.Sms}
                  hasDialogLink={watchValue}
                  isDirty={isDirty}
                  feedback={feedback}
                  maxSelectedUsers={MAX_SELECTED_USERS}
                  {...sharedSMSProps}
                />
              )}
            </HookFormFieldArrayWrapper>
          </>
        )}
      </div>
    </div>
  );
};

export default OptionsSection;
