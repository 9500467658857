import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { isPinPassLinkEnabled } from 'apps/shared/utility/featureFlag';
import { ConfigsId, useFetchConfigsByIdQuery } from 'models/Configs';
import { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogActionsCloseReasons } from 'shared/components/Dialog';
import { LabeledSwitch } from 'shared/components/Labeled';
import { FormFields } from '../../definition';
import { PinPassUnlinkDialog, SsoDisableDialog } from './components';
import { DialogType, IsDialogOpen } from './definition';

const EnableSsoSwitch = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [isDialogOpen, setIsDialogOpen] = useState<IsDialogOpen>({
    [DialogType.PinPassUnlink]: false,
    [DialogType.SsoDisable]: false,
  });

  const { data: voicemailConfigsData } = useFetchConfigsByIdQuery(
    { id: ConfigsId.Voicemail },
    { skip: !isPinPassLinkEnabled() },
  );

  const onSsoSwitchOff = () => {
    setValue(FormFields.SsoEnabled, false, { shouldDirty: true });
  };

  const onDialog = (type: DialogType) => ({
    close: (isSsoSwitchOff?: boolean) => {
      setIsDialogOpen((isOpen: IsDialogOpen) => ({
        ...isOpen,
        [type]: false,
      }));

      if (isSsoSwitchOff) {
        onSsoSwitchOff();
      }
    },
    open: () =>
      setIsDialogOpen((isOpen: IsDialogOpen) => ({
        ...isOpen,
        [type]: true,
      })),
  });

  const onDialogAction = {
    [DialogType.PinPassUnlink]: async (closeResponse: { reason: DialogActionsCloseReasons }) => {
      switch (closeResponse.reason) {
        case 'cancelClicked':
          onSsoSwitchOff();
          break;
        case 'saveClicked':
          setValue(FormFields.PinPassLink, false, { shouldDirty: true });
          break;
        default:
          break;
      }
      onDialog(DialogType.PinPassUnlink).close();
    },
    [DialogType.SsoDisable]: async (closeResponse: { reason: DialogActionsCloseReasons }) => {
      if (closeResponse.reason === 'saveClicked') {
        onSsoSwitchOff();
      }
      onDialog(DialogType.SsoDisable).close();
    },
  };

  return (
    <>
      <HookFormInputWrapper isCheckbox name={FormFields.SsoEnabled}>
        {({ ref, isDirty, onChange, ...formProps }) => (
          <LabeledSwitch
            isDirty={isDirty}
            label={t(
              'phone_system:containers.account.submodule.settings.section.sso.enable_sso.label',
            )}
            labelWidth="auto"
            switchProps={{
              ...formProps,
              onChange: ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                const isPinPassLink = () =>
                  isPinPassLinkEnabled() && voicemailConfigsData?.pin_pass_sync;

                if (checked && isPinPassLink()) {
                  onDialog(DialogType.PinPassUnlink).open();
                }

                if (!checked && !isDirty) {
                  onDialog(DialogType.SsoDisable).open();
                } else {
                  onChange(checked);
                }
              },
            }}
          />
        )}
      </HookFormInputWrapper>

      <PinPassUnlinkDialog
        isDialogOpen={isDialogOpen.pinPassUnlink}
        onClose={() => onDialog(DialogType.PinPassUnlink).close(true)}
        onDialogAction={onDialogAction.pinPassUnlink}
      />
      <SsoDisableDialog
        isDialogOpen={isDialogOpen.ssoDisable}
        onClose={onDialog(DialogType.SsoDisable).close}
        onDialogAction={onDialogAction.ssoDisable}
      />
    </>
  );
};

export default EnableSsoSwitch;
