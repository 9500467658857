import { queuesMutations } from './Mutations';
import { queueQueries } from './Queries';

export const {
  useFetchQueueByIdQuery,
  useFetchQueueQuery,
  useFetchQueuesStatsSummaryQuery,
  useFetchQueuesWithDetailsQuery,
  useFetchQueueUsersQuery,
} = queueQueries;

export const {
  useAddCallEavesdropMutation,
  useAddEavesdropMutation,
  useCreateQueueMutation,
  useDeleteMemberMutation,
  useDeleteQueueMutation,
  useUpdateQueueMutation,
  useUpdateQueueUserMutation,
} = queuesMutations;
