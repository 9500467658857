import MuiMenuItem from '@mui/material/MenuItem';
import { FunctionComponent } from 'react';
import Tooltip from 'shared/components/Tooltip';
import { CustomMenuItemProps as Props } from './definition';

const MenuItem: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { tooltip, ...rest } = props;
  const menuItem = <MuiMenuItem {...rest} />;

  return tooltip ? <Tooltip title={tooltip}>{menuItem}</Tooltip> : menuItem;
};

export default MenuItem;
