import { REGEX } from 'constant';
import { useFormContext } from 'react-hook-form';
import FeedbackItem from './components/FeedbackItem';
import StyledComplexPasswordValidationFeedback from './style';
import { translations } from './translation';

const ComplexPasswordValidationFeedback = () => {
  const { prompt, criteria } = translations();
  const { watch } = useFormContext();

  const watchFields = {
    password: watch('seat.password'),
  };

  return (
    <StyledComplexPasswordValidationFeedback>
      <FeedbackItem isPrompt text={prompt} />
      {/* At least ten (10) characters in length */}
      <FeedbackItem
        password={watchFields.password}
        regex={REGEX.PASSWORD.CRITERIA.LENGTH}
        text={criteria[0]}
      />
      {/* At least one (1) uppercase character */}
      <FeedbackItem
        password={watchFields.password}
        regex={REGEX.PASSWORD.CRITERIA.UPPERCASE}
        text={criteria[1]}
      />
      {/* At least one (1) lowercase character */}
      <FeedbackItem
        password={watchFields.password}
        regex={REGEX.PASSWORD.CRITERIA.LOWERCASE}
        text={criteria[2]}
      />
      {/* At least one (1) digit */}
      <FeedbackItem
        password={watchFields.password}
        regex={REGEX.PASSWORD.CRITERIA.DIGIT}
        text={criteria[3]}
      />
    </StyledComplexPasswordValidationFeedback>
  );
};

export default ComplexPasswordValidationFeedback;
