import InputLabel from '@mui/material/InputLabel';
import { WidthType } from 'shared/utility/definition';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { CONSTANTS } from './constant';
import { StyledInputLabelProps as Props } from './definition';

export const StyledInputLabel = styled(InputLabel)<Props>`
  ${({ $isAbove, $leftMargin, $rightMargin, width, theme }) => css`
    ${$isAbove
      ? css`
          height: 1em;
          margin: ${theme.spacing(0, 0, 1.33)};
        `
      : css`
          ${mixin.flex({ alignItems: 'center', justifyContent: 'flex-end' })}
          ${mixin.width({ type: 'label', width: width as WidthType })};
          height: 100%;
          text-align: right;
          white-space: unset;

          ${width !== 'none' &&
          css`
            margin: ${theme.spacing(0, CONSTANTS.MARGIN.DEFAULT[$rightMargin], 0, 0)};
          `}

          // this property needs to be last
          margin-left: ${theme.spacing(CONSTANTS.MARGIN.DEFAULT[$leftMargin])};
        `};
  `}
`;
