import i18next from 'i18next';
import kebabCase from 'lodash/kebabCase';
import merge from 'lodash/merge';
import Button from 'shared/components/Button';
import { DialogActionsCloseReasons } from './definition';

/**
 * @name buildButtons
 * @description Build the dialog action buttons.
 *
 * @param [object]
 * @property hasCancelOnly
 * @property cancelLabel
 * @property deleteLabel
 * @property saveButtonProps
 * @property onAction
 *
 * @returns Button object
 */
export const buildButtons = ({
  hasCancelOnly,
  cancelButtonProps,
  cancelLabel,
  deleteLabel,
  saveButtonProps,
  onAction,
}: {
  hasCancelOnly?: boolean;
  cancelButtonProps?: Record<string, any>;
  cancelLabel?: string;
  deleteLabel?: string;
  saveButtonProps?: Record<string, any>;
  onAction: ({ reason }: { reason: DialogActionsCloseReasons }) => void;
}) => ({
  cancel: (
    <Button
      {...(cancelButtonProps ?? {
        color: 'secondary',
        variant: hasCancelOnly ? 'contained' : 'outlined',
      })}
      data-test-id={`btn-dialog-action-${kebabCase(cancelLabel || 'cancel')}`}
      onClick={() => onAction({ reason: 'cancelClicked' })}
    >
      {cancelLabel ?? i18next.t('common:cancel')}
    </Button>
  ),
  delete: (
    <Button
      color="error"
      data-test-id="btn-dialog-action-delete"
      variant="contained"
      onClick={() => onAction({ reason: 'deleteClicked' })}
    >
      {deleteLabel ?? i18next.t('common:delete')}
    </Button>
  ),
  save: (
    <Button
      data-test-id="btn-dialog-action-save-changes"
      onClick={() => onAction({ reason: 'saveClicked' })}
      {...merge({}, { color: 'success', variant: 'contained' } as any, saveButtonProps)}
    >
      {saveButtonProps?.label ?? i18next.t('common:save_changes')}
    </Button>
  ),
});
