import { RouteIds } from 'apps/AdvancedProvisioner/containers/Devices/definition';
import Form from 'apps/shared/components/EnhancedFormCore/components/Form';
import { FormFields } from 'apps/shared/components/EnhancedFormCore/components/Form/definition';
import FormActionRow from 'apps/shared/components/FormActionRow';
import { FormContext } from 'apps/shared/components/FormContext';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EditFormProps as Props } from './definition';
import { useDynamicTabs } from './hooks/useDynamicTabs';
import { useFilteredSectionTemplate } from './hooks/useFilteredSectionTemplate';
import { useGetFormContextValue } from './hooks/useGetFormContextValue';

export const EditForm = ({
  tabKey,
  tabName,
  iterate,
  template: rawTemplate,
  formProps: { defaultValues },
}: Props) => {
  const { iterate: currentIterate } = useParams<RouteIds>();
  const { hasFormActionRow, formActionRowIterate, formContextValue } = useGetFormContextValue({
    tabKey,
    iterate,
    currentIterate,
  });

  const template = useFilteredSectionTemplate(rawTemplate);

  const tabs = useDynamicTabs({ template });

  const formFields = useMemo(() => {
    const composedFormFields: FormFields = {};
    Object.entries(template).forEach(([sectionKey, { data }]) => {
      composedFormFields[sectionKey] = Object.keys(data).map((key) => `${sectionKey}.${key}`);
    });
    return composedFormFields;
  }, [template]);

  const displayTabName = tabName.toLowerCase();

  return (
    <FormContext.Provider value={formContextValue}>
      {hasFormActionRow && (
        <FormActionRow
          title={
            <Trans
              i18nKey="advanced_provisioner:containers.devices.edit_form.form_action_row.title"
              values={{
                tabName: displayTabName,
                iterate: formActionRowIterate,
              }}
            />
          }
        />
      )}
      <Form defaultValues={defaultValues} formFields={formFields} tabs={tabs} />
    </FormContext.Provider>
  );
};
