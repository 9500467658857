import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledHeadingProps as Props } from './definition';

export const CONSTANTS = {
  HEIGHT: '60px',
};

const StyledHeading = styled.div<Props>`
  ${(props) => css`
    height: ${CONSTANTS.HEIGHT};
    line-height: ${CONSTANTS.HEIGHT};
    padding: 0 30px;
    border-bottom: 1px solid ${props.theme.core.background.grey.light.border};

    > span {
      ${mixin.font({ size: 19, weight: 600 })};
      user-select: none;
    }
  `}
`;

export default StyledHeading;
