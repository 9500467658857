import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { MILLISECONDS_IN_SECOND } from 'constant';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledCheckbox from 'shared/components/Labeled/components/LabeledCheckbox';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'retries',
  'timeout',
  'max_extension_length',
  'allow_record_from_offnet',
  'suppress_media',
];

const OptionsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div role="row">
      <div role="cell">
        {/* Number of retries on misdial */}
        <HookFormInputWrapper name="retries">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              label={t('phone_system:containers.menu.section.options.field.retries.label')}
              inputProps={{
                ...formProps,
                type: 'number',
              }}
            />
          )}
        </HookFormInputWrapper>

        {/* Timeout for Dialing (in seconds) */}
        <HookFormInputWrapper name="timeout">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              label={t('phone_system:containers.menu.section.options.field.timeout.label')}
              inputProps={{
                ...formProps,
                type: 'number',
                value: formProps.value / MILLISECONDS_IN_SECOND,
                onChange: (e) => {
                  const output = parseInt(e.target.value, 10);
                  return formProps.onChange(isNaN(output) ? 0 : output * MILLISECONDS_IN_SECOND);
                },
              }}
            />
          )}
        </HookFormInputWrapper>

        {/* Extension Number Length */}
        <HookFormInputWrapper name="max_extension_length">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              label={t(
                'phone_system:containers.menu.section.options.field.max_extension_length.label',
              )}
              inputProps={{
                ...formProps,
                type: 'number',
              }}
              tooltip={t(
                'phone_system:containers.menu.section.options.field.max_extension_length.tooltip',
              )}
            />
          )}
        </HookFormInputWrapper>

        {/* Allow outside Recording */}
        <HookFormInputWrapper name="allow_record_from_offnet" isCheckbox>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledCheckbox
              isDirty={isDirty}
              feedback={feedback}
              label={t(
                'phone_system:containers.menu.section.options.field.allow_record_from_offnet.label',
              )}
              checkboxProps={{
                ...formProps,
              }}
              tooltip={t(
                'phone_system:containers.menu.section.options.field.allow_record_from_offnet.tooltip',
              )}
            />
          )}
        </HookFormInputWrapper>

        {/* Suppress playing of invalid entry */}
        <HookFormInputWrapper name="suppress_media" isCheckbox>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledCheckbox
              isDirty={isDirty}
              feedback={feedback}
              label={t('phone_system:containers.menu.section.options.field.suppress_media.label')}
              checkboxProps={{
                ...formProps,
              }}
              tooltip={t(
                'phone_system:containers.menu.section.options.field.suppress_media.tooltip',
              )}
            />
          )}
        </HookFormInputWrapper>
      </div>
    </div>
  );
};

export default OptionsSection;
