import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFlowChildLabelProps as Props } from './definition';

export const StyledFlowChildLabel = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex()};
    grid-area: 2 / 2 / 3 / 3;

    span {
      ${mixin.transition({ property: 'color', duration: 'medium' })};
      margin: 0 0 0 8px;
      color: ${props.theme.core.background.grey.dark};
      color: ${props.theme.whitelabel.secondary.color};
      flex-basis: calc(50% - 24px);
      text-align: center;
      line-height: 1.2em;
    }

    svg {
      ${mixin.transition({ property: 'fill', duration: 'medium' })};
    }

    ${!props.isDragging &&
    props.hasDialog &&
    !props.isPreview &&
    css`
      cursor: pointer;

      &:hover {
        span {
          ${mixin.hover({ letterSpacing: -0.018 })};
          color: ${props.isDragging
            ? props.theme.whitelabel.secondary.color
            : props.theme.core.color.blue.draggable};

          ${props.isDragging &&
          css`
            font-weight: normal;
            white-space: normal;
          `};
        }

        svg {
          fill: ${props.isDragging
            ? props.theme.whitelabel.secondary.color
            : props.theme.core.color.blue.draggable};
        }
      }
    `}
  `}
`;
