import { AppComponent } from 'apps/shared/definition';
import { Dispatch, SetStateAction } from 'react';
import { ComponentProps } from 'shared/definition';

export enum DeviceType {
  Ata = 'ata',
  Browserphone = 'browserphone',
  Cellphone = 'cellphone',
  Fax = 'fax',
  Landline = 'landline',
  SipDevice = 'sip_device',
  SipUri = 'sip_uri',
  Smartphone = 'smartphone',
  Softphone = 'softphone',
}

export enum EndpointType {
  Device = 'device',
  Email = 'email',
  Group = 'group',
  User = 'user',
}

export enum MediaType {
  Recording = 'recording',
  Tts = 'tts',
  Undefined = 'undefined',
  Upload = 'upload',
}

export interface ToastOptions {
  hasToast?: boolean;
  toastMessage?: string | Array<string>;
}

export type HandleDeleteSuccessParam = ToastOptions;

export interface HandleSaveSuccessParam extends ToastOptions {
  shouldRedirect?: boolean;
  id?: string;
  navigationOverride?: {
    options?: any;
    path: string;
  };
}

export interface EditFormProps extends ComponentProps {
  id: string;
  isEmbedded?: boolean;
  handleCancel?: () => void;
  handleCancelSuccess?: () => void;
  handleDeleteSuccess?: (param?: HandleDeleteSuccessParam) => void;
  handleSaveSuccess?: (param?: HandleSaveSuccessParam) => void;
  setItemName?: Dispatch<SetStateAction<string | undefined>>;
}

export type EndpointItemType = Device | User | Group;

export type GroupEndpoint = {
  endpoint_type: EndpointType;
  delay: string;
  timeout: string;
  id?: string;
  key?: string;
  name?: string;
};

export interface SeatFormProps {
  /** Does the form panel include the action row component? */
  hasFormActionRow?: boolean;
  apiData?: any;
}

interface Props extends ComponentProps {
  /** Does this module's edit/main page scroll? */
  hasScroll: boolean;
}

export interface PhoneSystemProps extends Props, AppComponent {}

export type StyledPhoneSystemProps = Props;
