import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { FORMAT } from 'constant';
import { FunctionComponent, useState } from 'react';
import Icon from 'shared/components/Icon';
import Input from 'shared/components/Input';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import defaultProps from './default';
import { DatePickerProps as Props } from './definition';

export { default as datePickerDefaultProps } from './default';
export type { DatePickerProps } from './definition';

const DatePicker: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { id, disabled, value, width, renderInput, onChange, ...rest }: Props = {
    ...defaultProps,
    ...props,
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const refPicker = useOutsideClick(() => setIsOpen(false), 'div[role="dialog"]');

  return (
    <div ref={refPicker}>
      <MuiDatePicker
        inputFormat={FORMAT.DATE}
        open={isOpen}
        value={value || null}
        onChange={onChange}
        renderInput={({ InputProps, ...rest }: any) => (
          <Input
            {...rest}
            id={id}
            classes={{ root: 'MuiInputBase-picker MuiInputBase-picker-date' }}
            disabled={disabled}
            endAdornment={<Icon name="calendar" size={20} />}
            width={width}
            onClick={() => setIsOpen((isOpen: boolean) => (disabled ? false : !isOpen))}
          />
        )}
        {...rest}
      />
    </div>
  );
};

export default DatePicker;
