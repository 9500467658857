import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import useFormNumbersInUsePicker from 'apps/shared/hooks/useFormNumbersInUsePicker';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import Typography from 'shared/components/Typography';
import { FormFields } from '../../definition';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  FormFields.CallerIdExternalName,
  FormFields.CallerIdExternalNumber,
  FormFields.CallerIdEmergencyName,
  FormFields.CallerIdEmergencyNumber,
];

export { schema } from './schema';

const CallerIdSection = () => {
  const { t } = useTranslation();
  const { formNumbersInUsePicker } = useFormNumbersInUsePicker();

  return (
    <>
      <Typography variant="h2">
        {t(
          'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.heading.outbound_calls',
        )}
      </Typography>
      <Box role="row">
        <Box role="cell">
          {/* Outbound Caller ID Name */}
          <HookFormInputWrapper name={FormFields.CallerIdExternalName}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.field.caller_id_name.label',
                  ),
                  ...formProps,
                }}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.field.caller_id_name.label',
                )}
              />
            )}
          </HookFormInputWrapper>
        </Box>
        {/* Outbound Caller ID Number */}
        <Box role="cell">
          <HookFormInputWrapper name={FormFields.CallerIdExternalNumber}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.field.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.field.caller_id_number.label',
                )}
              />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>

      <Typography variant="h2">
        {t(
          'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.heading.emergency_calls',
        )}
      </Typography>
      <Box role="row">
        <Box role="cell">
          {/* Emergency Caller ID Name */}
          <HookFormInputWrapper name={FormFields.CallerIdEmergencyName}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.field.caller_id_name.label',
                  ),
                  ...formProps,
                }}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.field.caller_id_name.label',
                )}
              />
            )}
          </HookFormInputWrapper>
        </Box>
        <Box role="cell">
          {/* Emergency Caller ID Number */}
          <HookFormInputWrapper name={FormFields.CallerIdEmergencyNumber}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.field.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.field.caller_id_number.label',
                )}
              />
            )}
          </HookFormInputWrapper>
          {formNumbersInUsePicker}
        </Box>
      </Box>
    </>
  );
};

export default CallerIdSection;
