import StyledEditForm from 'apps/shared/components/StyledEditForm';
import useIsMounted from 'apps/shared/hooks/useIsMounted';
import { ExitConfirmationDialogContext } from 'apps/store/contexts';
import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useActionRow } from 'shared/hooks/useActionRow';
import { DialogContext } from 'store/contexts';
import { EditFormProps as Props } from './definition';

const EditForm = ({
  isPageDirty,
  breadcrumb,
  children,
  deleteButton: { isDisabled: isDeleteDisabled, onClick } = {},
  formMethods,
  onCancel,
  onSave,
}: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const dialogCtx = useContext(DialogContext);
  const { createExitConfirmationDialogHandler } = useContext(ExitConfirmationDialogContext);

  const {
    formState: { isSubmitSuccessful },
    reset,
  } = formMethods;

  const handleCancelClick = onCancel
    ? createExitConfirmationDialogHandler?.(onCancel)
    : () => navigate('..');

  const onConfirmDelete = () => {
    dialogCtx.onOpen({
      open: true,
      confirmMessage: (
        <Trans i18nKey="call_center:components.edit_form.delete_queue_dialog.content" />
      ),
      title: t('call_center:components.edit_form.delete_queue_dialog.title', {
        title: breadcrumb[1],
      }),
      onConfirm: () => onClick?.(),
    });
  };

  const onSaveHandler = async () => {
    try {
      setIsSaving(true);
      await onSave?.();
      if (isMounted()) {
        setIsSaving(false);
      }
    } catch (error) {
      setIsSaving(false);
    }
  };

  // This resets the form state like dirty after saving successfully
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  const { ActionRow, actionRowProps } = useActionRow({
    hasDelete: !!onClick,
    hasSave: true,
    isDeleteDisabled,
    isDirty: isPageDirty,
    isSaveDisabled: isSaving,
    breadcrumbData: [
      {
        text: breadcrumb[0],
        url: handleCancelClick,
      },
      {
        text: breadcrumb[1],
      },
    ],
    deleteLabel: t('call_center:components.edit_form.delete_queue_dialog.button'),
    onCancel: handleCancelClick,
    onDelete: onConfirmDelete,
    onSave: onSaveHandler,
  });

  return (
    <StyledEditForm onSubmit={(e: BaseSyntheticEvent) => e.preventDefault()}>
      <ActionRow {...actionRowProps} />
      <FormProvider {...formMethods}>{children}</FormProvider>
    </StyledEditForm>
  );
};

export default EditForm;
