import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import { DragIconProps as Props } from './definition';

const DragIcon: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isDragging, ...rest } = props;

  return (
    <Icon
      hasHover
      name="grip-dots-vertical"
      color="secondary"
      style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
      {...rest}
    />
  );
};

export default DragIcon;
