import { MEDIA_TYPE } from 'constant';
import { IdpFormState } from '../definition';
import { FormFields } from '../fields';

const NAMEID_FORMAT = 'urn:oasis:names:c:SAML:2.0:nameid-format:persistent';

export const convertStringToX509File = (certString = '', filename = 'sso_idp_cert.cer') => {
  if (!certString) {
    return null; // Must be null instead of undefined since react-hook-form reset() will skip resetting field with undefined
  }
  const blob = new Blob([certString], { type: MEDIA_TYPE.TYPE.TEXT.PLAIN.DEFAULT });
  const file = new File([blob], filename, { type: MEDIA_TYPE.TYPE.TEXT.PLAIN.DEFAULT });
  return file;
};

export const convertX509FileToString = async (file: File | null) => {
  if (!file) {
    return null; // Must be null instead of undefined since react-hook-form reset() will skip resetting field with undefined
  }

  const getCertStringFromFile = () => {
    let certString;
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        certString = reader.result;
        resolve(certString as string);
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsText(file);
    });
  };
  const certString = await getCertStringFromFile();

  return certString;
};

/** This function transform local form state (front-end) to match remote api request body schema (back-end) */
export const transformIdpResponseToFormState = (idpData?: IdpTransformedResponse) => {
  // IDP info
  const { idp } = idpData ?? {};

  const idpFormState: Partial<IdpFormState> = {
    [FormFields.Id]: idp?.id,
    [FormFields.SsoUrl]: idp?.idpEndpoint,
    [FormFields.X509Certificate]: convertStringToX509File(idp?.verificationKey, 'sso_idp_cert.cer'),
    [FormFields.UniqueIdClaim]: idp?.uniqueIdClaim || '',
    [FormFields.UseNameIdForEmail]: idp?.useNameIdForEmail ?? false,
    [FormFields.EmailClaim]: idp?.emailClaim || '',
    [FormFields.UsernameClaim]: idp?.usernameClaim || '',
    [FormFields.UsePostMethod]: idp?.usePostRequest ?? false,
    [FormFields.SignRequest]: idp?.signRequest ?? false,
  };

  return idpFormState;
};

/** This function transform remote api response (backend) to local form state (frontend). */
export const transformFormStateToIdpRequestBody = async (
  formState: IdpFormState,
  ssoEnabled = false,
) => {
  // Empty fields needed to be converted to null as per backend team requested
  const body: IdpRequestBody = {
    ssoEnabled,
    idp: {
      idpEndpoint: formState[FormFields.SsoUrl],
      nameIdFormat: NAMEID_FORMAT,
      verificationKey:
        (await convertX509FileToString(formState[FormFields.X509Certificate])) || null,
      uniqueIdClaim: formState[FormFields.UniqueIdClaim] || null,
      useNameIdForEmail: formState[FormFields.UseNameIdForEmail] ?? false,
      emailClaim: formState[FormFields.EmailClaim] || null,
      usernameClaim: formState[FormFields.UsernameClaim] || null,
      usePostRequest: formState[FormFields.UsePostMethod] ?? false,
      signRequest: formState[FormFields.SignRequest] ?? false,
    },
  };

  if (formState[FormFields.Id]) {
    body.idp.id = formState[FormFields.Id];
  }

  // Remove emailClaim if useNameIdForEmail is true
  if (formState[FormFields.UseNameIdForEmail]) {
    delete body.idp.emailClaim;
  }

  return body;
};
