import { WidthType } from 'shared/utility/definition';
import { DialogLinkType } from './definition';

const defaultProps = {
  indentWidth: 'none' as WidthType,
  type: 'edit' as DialogLinkType,
  onClick: () => {},
};

export default defaultProps;
