import { JSXElementConstructor } from 'react';
import { InputProps } from '../components/definition';
import { PasswordInput } from '../components/PasswordInput';
import { SelectInput } from '../components/SelectInput';
import { TextInput } from '../components/TextInput';
import { FieldType, GetInputParams } from '../definition';

const inputMap: Record<string, JSXElementConstructor<InputProps>> = {
  [FieldType.Text]: TextInput,
  [FieldType.Password]: PasswordInput,
  [FieldType.Select]: SelectInput,
};

export const getInput = ({ type }: GetInputParams) => {
  if (!inputMap[type]) {
    console.warn(
      `DynamicInput: The input type "${type}" is not supported. Please change the type.`,
    );
    return () => null;
  }
  return inputMap[type];
};
