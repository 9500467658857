export const Notification = {
  // GET
  // Fetch: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/notifications`,
  FetchById: (accountId: string, typeId: string) => `accounts/${accountId}/notifications/${typeId}`,

  // PUT
  Create: (accountId: string, typeId: string) => `accounts/${accountId}/notifications/${typeId}`,

  // POST
  Update: (accountId: string, typeId: string) => `accounts/${accountId}/notifications/${typeId}`,

  // DELETE
  Delete: (accountId: string, typeId: string) => `accounts/${accountId}/notifications/${typeId}`,
};
