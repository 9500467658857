import { DeviceType } from 'apps/PhoneSystem/definition';
import i18next from 'i18next';
import { cloneDeep } from 'lodash';
import { defaultCallflow } from '../../Callflows/Edit/default';
import { SeatData } from './definition';

export const getAddDeviceItems = (navigate: (navigateTo: string) => void) =>
  [
    {
      icon: 'cell-phone',
      label: i18next.t(`phone_system:containers.seats.devices.options.cellphone`),
      type: DeviceType.Cellphone,
    },
    {
      icon: 'fax-default-outlined',
      label: i18next.t(`phone_system:containers.seats.devices.options.fax`),
      type: DeviceType.Fax,
    },
    {
      icon: 'call-outlined',
      label: i18next.t(`phone_system:containers.seats.devices.options.landline`),
      type: DeviceType.Landline,
    },
    {
      icon: 'sip-device',
      label: i18next.t(`phone_system:containers.seats.devices.options.sip_device`),
      type: DeviceType.SipDevice,
    },
    {
      icon: 'sip-uri',
      label: i18next.t(`phone_system:containers.seats.devices.options.sip_uri`),
      type: DeviceType.SipUri,
    },
    {
      icon: 'smartphone-iphone',
      label: i18next.t(`phone_system:containers.seats.devices.options.smartphone`),
      type: DeviceType.Smartphone,
    },
    {
      icon: 'dialpad',
      label: i18next.t(`phone_system:containers.seats.devices.options.softphone`),
      type: DeviceType.Softphone,
    },
  ].map(({ icon, label, type }: Record<string, string>) => ({
    icon,
    label,
    onClick: () => navigate(`./add?type=${type}`),
  }));

/**
 * @name createNewCallflowBody
 * @description Creates the body for the Create Callflow's request
 *
 * @param accountConfigData - The account config data
 * @param seatRequestData - The seat request data
 * @param entitiesData - The entities data
 *
 * @returns The new callflow body
 */
export const createNewCallflowBody = (
  accountConfigData: AccountConfigsResponse | undefined,
  seatRequestData: Seat,
  entitiesData: SeatData,
) => {
  const newCallflow = cloneDeep(defaultCallflow);
  const { conference, voicemail, seat } = entitiesData;
  const conferenceId = Object.keys(conference)[0];

  newCallflow.numbers = [seatRequestData.username];
  if (seatRequestData.phone_number) {
    newCallflow.numbers.push(seatRequestData.phone_number);
  }

  // TODO: Use hash map to support multiple seat types callflow
  if (
    accountConfigData?.seat_types[seatRequestData.seat_type]?.display_name === 'Conference' &&
    conferenceId
  ) {
    // create a Conference Callflow
    newCallflow.flow.module = 'conference';
    newCallflow.flow.data = { id: conferenceId };
    delete newCallflow.flow.children;
  } else {
    // create a Seat -> Voicemail Callflow
    newCallflow.flow.data = { id: seat.id };

    if (voicemail) {
      newCallflow.flow.children._.data = { id: voicemail.id };
    }
  }

  return newCallflow;
};
