import Box from 'shared/components/Box';
import { TABLE_CONSTANTS } from 'shared/components/Table';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledTruncatedValueProps as Props } from './definition';

const StyledTruncatedValue = styled(Box)<Props>`
  ${({ $isTooltipVisible, $maxWidth }) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};
    height: calc(${TABLE_CONSTANTS.HEIGHT.CELL.TBODY} - 1px);

    ${$isTooltipVisible &&
    $maxWidth &&
    css`
      &,
      span {
        width: ${$maxWidth}px;
      }

      > span {
        height: 16px;
        line-height: 1em;
        text-overflow: ellipsis;
        overflow: hidden;
        transform: translateY(1px);
      }
    `}

    > span {
      display: inline-block;
    }
  `}
`;

export default StyledTruncatedValue;
