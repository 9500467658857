import StyledLabeled from 'shared/components/Labeled/style';
import * as mixin from 'shared/utility/mixin';
import styled from 'styled-components';
import { StyledLabeledFeatureCodeProps as Props } from './definition';

const StyledLabeledFeatureCode = styled(StyledLabeled)<Props>`
  > div {
    ${mixin.flex()};
  }
`;

export default StyledLabeledFeatureCode;
