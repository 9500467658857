import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { RowArray } from 'apps/shared/definition';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { ChangeEvent, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeaderGroup,
  Row,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useActionRow } from 'shared/hooks/useActionRow';
import { getClassName } from 'shared/utility';
import Box from '../Box';
import { SelectionPosition, SelectionType } from './components/definition';
import Pagination from './components/Pagination';
import RowData from './components/RowData';
import RowDrag from './components/RowDrag';
import defaultProps from './default';
import { SortOrder, TableProps as Props } from './definition';
import { usePaginationIndexStorage, useSortColumnStorage, useTableSearchParam } from './hooks';
import StyledTableWrapper from './style';
import {
  filterExpandedRowIds,
  filterSubRows,
  getColSpan,
  getExpandedRowId,
  getSelectionCell,
  maybeSetPageSize,
  MINIMUM_FILTER_LENGTH,
} from './utility';

export type { Cell, Column } from 'react-table';
export { Justify, SelectionPosition, SelectionType } from './components/definition';
export { default as EditIcon } from './components/EditIcon';
export type { DragIndex } from './definition';
export { default as StyledTableWrapper } from './style';
export { CONSTANTS as TABLE_CONSTANTS } from './style/constants';
export { TableType } from './style/definition';
export { SHARE_COLUMN_KEYWORD } from './utility';
export { SortOrder };

const Table = (props: Props) => {
  const {
    hasActionRow,
    hasActionRowMargin,
    hasActionRowPadding,
    hasActionRowBorder,
    hasAutoResetPage,
    hasAutoSort,
    hasBorderRadius,
    hasCellPadding,
    hasDragIcon,
    hasEditIcon,
    hasHeadings,
    hasHelp,
    hasOuterBorder,
    hasPagination,
    hasPaginationPadding,
    hasSave,
    hasSearch,
    hasSideMargin,
    hasSubRowFilter,
    isBreadcrumbAbove,
    isScrollable,
    actionRowButtons,
    actionRowButtonAlignment,
    addButton,
    columns,
    data,
    dataTestId,
    disableSortBy,
    dragWidth,
    filter,
    help,
    id: tableId,
    initialState,
    manualSortBy,
    noItemsFoundText,
    noItemsMessage,
    pageSize: pageSizeRequested,
    paginationInfoText,
    saveButton,
    searchPlaceholder,
    onSearch,
    selection,
    title,
    type,
    handleDragEnd,
    renderNoItemsFooter,
    renderTablePostfix,
  }: Props = { ...defaultProps, ...props };

  const { t } = useTranslation();
  const expandedRowIds = useRef<Array<Array<string>>>([]);
  const selectedRowIndicesRef = useRef<Array<number>>([]);
  const [isHeaderChecked, setIsHeaderChecked] = useState<boolean>(false);
  const [dragRowId, setDragRowId] = useState<string | null>(null);
  const items = useMemo(() => data?.map(({ id, key }: any) => id ?? key), [data]);
  const { hasSortColumn, sortColumn, initSortColumn, setSortColumn } = useSortColumnStorage({
    tableId,
  });

  // destructure here as default merge overwrites selection default values
  const {
    indices: selectionIndices,
    position: selectionPosition,
    type: selectionType,
    setRows: setSelectedRows,
  } = useMemo(() => merge({}, defaultProps.selection, selection), [selection]);

  const tableOptions = useMemo(() => {
    const { sortBy, ...rest } = merge({}, initialState, { sortBy: {} });

    return {
      autoResetExpanded: false,
      autoResetPage: hasAutoResetPage,
      columns,
      data,
      defaultColumn: { width: 'auto' },
      disableSortBy,
      initialState: {
        sortBy: hasAutoSort
          ? [
              {
                id: hasSortColumn ? sortColumn?.id : sortBy.id ?? columns[0]?.accessor,
                desc: hasSortColumn ? sortColumn?.isSortedDesc : sortBy.desc ?? false,
              },
            ]
          : [],
        ...rest,
      },
      manualSortBy,
      ...(hasSubRowFilter
        ? {
            globalFilter: (rows: Array<Row>, columnIds: Array<string>, filterValue: string) => {
              const filteredRows = filterSubRows(rows, columnIds, filterValue, {
                filteredRowIds: [],
              });
              expandedRowIds.current.push(getExpandedRowId(filteredRows, filterValue));
              return filteredRows;
            },
          }
        : {}),
    };
  }, [
    hasAutoResetPage,
    hasAutoSort,
    hasSortColumn,
    hasSubRowFilter,
    columns,
    data,
    disableSortBy,
    expandedRowIds,
    initialState,
    manualSortBy,
    sortColumn,
  ]);

  const table = useTable(tableOptions, useGlobalFilter, useSortBy, useExpanded, usePagination);
  const {
    headerGroups,
    page,
    rows,
    state: { globalFilter, pageIndex, pageSize },
    getTableBodyProps,
    getTableProps,
    gotoPage,
    prepareRow,
    setGlobalFilter,
    setPageSize,
    toggleAllRowsExpanded,
    toggleRowExpanded,
  } = table;

  const { initPaginationIndex, setPaginationIndex } = usePaginationIndexStorage({
    tableId,
    gotoPage,
  });

  const { ActionRow, actionRowProps } = useActionRow({
    hasBottomMargin: hasActionRowMargin,
    hasBottomBorder: hasActionRowBorder,
    hasPadding: hasActionRowPadding,
    hasHelp,
    hasSearch,
    hasSave,
    isBreadcrumbAbove,
    isDirty: false,
    addButton,
    breadcrumbData: title ? [{ text: title }] : [],
    buttonAlignment: actionRowButtonAlignment,
    filter,
    globalFilter,
    help,
    leftButtons: actionRowButtons?.left,
    rightButtons: actionRowButtons?.right,
    saveButton,
    searchPlaceholder,
    onSearch,
    tableType: type,
    setGlobalFilter,
  });

  /** BEGIN: Drag and Drop Logic */

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const dragRow = useMemo(() => {
    let _row = null;
    if (dragRowId) {
      const row = rows.find(({ original }: any) => original.id === dragRowId) as Row;
      prepareRow(row);
      _row = row;
    }
    return _row;
  }, [dragRowId, rows, prepareRow]);

  const onDrag = {
    cancel: () => setDragRowId(null),
    end: ({ active, over }: any) => {
      if (active.id !== over.id) {
        handleDragEnd?.({ old: items.indexOf(active.id), new: items.indexOf(over.id) });
      }
      setDragRowId(null);
    },
    start: ({ active: { id } }: any) => setDragRowId(id),
  };

  /** END: Drag and Drop Logic */

  /** BEGIN: Selected Row Logic */

  const on = {
    checkbox: {
      change: {
        global: ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
          selectedRowIndicesRef.current = checked ? rows.map(({ index }) => index) : [];
          setIsHeaderChecked(checked);
          setSelectedRows?.(checked ? rows.map((row) => row.original) : []);
        },
        row: ({ index, original }: Row) => ({
          target: { checked },
        }: ChangeEvent<HTMLInputElement>) => {
          if (checked) {
            if (selectionType === SelectionType.Single) {
              selectedRowIndicesRef.current = [];
            }
            selectedRowIndicesRef.current.push(index);
          } else {
            selectedRowIndicesRef.current = selectedRowIndicesRef.current.filter(
              (i: number) => i !== index,
            );
          }

          setSelectedRows?.((selectedRows: RowArray) => {
            let rows = [...selectedRows];
            if (checked) {
              if (selectionType === SelectionType.Single) {
                rows = [];
              }
              rows.push(original);
            } else {
              rows = rows.filter((row) => !isEqual(row, original));
            }
            return rows;
          });

          return checked;
        },
      },
    },
    pagination: {
      click: (index: number) => setPaginationIndex(index),
    },
  };

  const selectionCell = useMemo(
    () =>
      getSelectionCell({
        isChecked: isHeaderChecked,
        rows,
        selection: { position: selectionPosition, type: selectionType },
        onChange: on.checkbox.change,
      }),
    [isHeaderChecked, rows, selectionPosition, selectionType, on.checkbox.change],
  );

  useEffect(() => {
    selectedRowIndicesRef.current = selectionIndices;
    setIsHeaderChecked(false);
  }, [data]);

  /** Set search filter when search params in URL exists */
  useTableSearchParam(setGlobalFilter);

  /** END: Selected Row Logic */

  useEffect(() => {
    initPaginationIndex(pageIndex);
  }, [data, pageIndex, initPaginationIndex]);

  useEffect(() => {
    const {
      initialState: { sortBy },
    } = tableOptions;
    initSortColumn({ sortBy: sortBy[0] });
  }, [data, tableOptions, initSortColumn]);

  useEffect(() => {
    // close everything on global filter change (always)
    toggleAllRowsExpanded(false);

    if (hasSubRowFilter && globalFilter?.length > MINIMUM_FILTER_LENGTH) {
      filterExpandedRowIds(expandedRowIds).forEach((rowId: Array<string>) => {
        toggleRowExpanded(rowId);
      });
    }
  }, [hasSubRowFilter, expandedRowIds, globalFilter, toggleAllRowsExpanded, toggleRowExpanded]);

  useEffect(() => {
    maybeSetPageSize(
      hasPagination?.rowsPerPage,
      hasPagination?.bar,
      pageSizeRequested,
      rows?.length,
      setPageSize,
    );
  }, [hasPagination.bar, hasPagination.rowsPerPage, pageSizeRequested, rows.length, setPageSize]);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={onDrag.end}
      onDragStart={onDrag.start}
      onDragCancel={onDrag.cancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <StyledTableWrapper
        hasBorderRadius={hasBorderRadius}
        hasCellPadding={hasCellPadding}
        hasOuterBorder={hasOuterBorder}
        hasSideMargin={hasSideMargin}
        isScrollable={isScrollable}
        selection={{ ...defaultProps.selection, ...selection }}
        type={type}
      >
        {hasActionRow && <ActionRow {...actionRowProps} />}
        <Box className="component-table-container" data-test-id={dataTestId}>
          <table {...getTableProps()}>
            {hasHeadings && (
              <thead>
                {headerGroups.map(({ headers, getHeaderGroupProps }: HeaderGroup) => (
                  <tr {...getHeaderGroupProps()}>
                    {hasDragIcon && <th>&nbsp;</th>}
                    {selectionPosition === SelectionPosition.Left && selectionCell}
                    {headers.map(
                      ({
                        isHidden,
                        isSorted,
                        isSortedDesc,
                        id,
                        maxWidth,
                        minWidth,
                        width,
                        getHeaderProps,
                        getSortByToggleProps,
                        render,
                      }: HeaderGroup) => {
                        const { style, onClick } = getSortByToggleProps();

                        const onSortClick = (e: any) => {
                          onClick?.(e);
                          setSortColumn({ isSortedDesc, id });
                        };

                        return isHidden ? (
                          <Fragment key={getHeaderProps().key} />
                        ) : (
                          <th
                            {...getHeaderProps(
                              merge(
                                {},
                                { style, onClick: onSortClick },
                                width
                                  ? {
                                      style: {
                                        maxWidth,
                                        minWidth,
                                        width,
                                        whiteSpace: width === 'auto' ? 'nowrap' : 'normal',
                                      },
                                    }
                                  : {},
                              ),
                            )}
                          >
                            <div {...getClassName(id)}>
                              {!disableSortBy && (
                                <span {...(isSorted ? { className: 'has-sort' } : {})}>
                                  <span
                                    className={`sort-${
                                      !isSorted || isSortedDesc ? SortOrder.Desc : SortOrder.Asc
                                    }`}
                                  />
                                </span>
                              )}
                              <span>{render('Header')}</span>
                            </div>
                          </th>
                        );
                      },
                    )}
                    {selectionPosition === SelectionPosition.Right && selectionCell}
                    {hasEditIcon && <th className="cell-action">&nbsp;</th>}
                  </tr>
                ))}
              </thead>
            )}
            {rows.length ? (
              <tbody {...getTableBodyProps()}>
                <SortableContext items={items} strategy={verticalListSortingStrategy}>
                  {page.map((row: Row) => {
                    prepareRow(row);
                    const {
                      index,
                      original: { id, key },
                    }: any = row;

                    return (
                      <RowData
                        hasDragIcon={hasDragIcon}
                        hasEditIcon={hasEditIcon}
                        isSelected={selectedRowIndicesRef.current.includes(index)}
                        key={`row-data-${id ?? key}`}
                        row={row}
                        selection={selection}
                        onChangeSelection={on.checkbox.change.row(row)}
                      />
                    );
                  })}
                </SortableContext>
              </tbody>
            ) : (
              renderNoItemsFooter?.() ?? (
                <tfoot>
                  <tr>
                    <td
                      colSpan={getColSpan(columns.length, {
                        hasDragIcon,
                        hasEditIcon,
                        hasSelection: selectionType !== SelectionType.None,
                      })}
                    >
                      {noItemsMessage ?? noItemsFoundText ?? t('common:table.no_items_found')}
                    </td>
                  </tr>
                </tfoot>
              )
            )}
          </table>
          {renderTablePostfix?.(globalFilter)}
        </Box>
        <Pagination
          hasPadding={hasPaginationPadding}
          isVisible={hasPagination}
          dataSize={rows.length}
          infoText={paginationInfoText}
          table={{ pageIndex, pageSize, setPageSize }}
          onClick={on.pagination.click}
        />
      </StyledTableWrapper>
      <DragOverlay>
        {dragRowId && (
          <StyledTableWrapper type={type}>
            <table {...getTableProps()}>
              <tbody>
                <RowDrag row={dragRow} width={dragWidth} />
              </tbody>
            </table>
          </StyledTableWrapper>
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default Table;
