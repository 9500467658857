import { SEAT_TYPE } from 'constant';

/**
 * @name getUsernameInputType
 * @description Determines the input type for the username based on user's privileges, seat type, and disabled status
 *
 * @param isAdmin - Indicates whether the user is an admin or not
 * @param seatType - The type of seat the user has
 * @param isDisabled - Indicates whether the input field is disabled or not
 * @returns The input type for the username. Either 'text' or 'number'
 */
export const getUsernameInputType = (isAdmin: boolean, seatType: string, isDisabled: boolean) =>
  isAdmin || (seatType === SEAT_TYPE.MS_TEAMS.id && isDisabled) ? 'text' : 'number';
