import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import kebabCase from 'lodash/kebabCase';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import Input from 'shared/components/Input';
import InputLabel from 'shared/components/InputLabel';
import { DirtyIcon, InfoIcon } from 'shared/components/Labeled';
import defaultProps from './default';
import { EditableInputListProps as Props } from './definition';
import StyledEditableInputList from './style';

const EditableInputList = (props: Props) => {
  const { control, values, onAddHandler, onRemoveHandler, skillKey, label, tooltip } = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();

  return (
    <StyledEditableInputList>
      <div>
        <InputLabel width="auto">{label}</InputLabel>
        {tooltip && <InfoIcon isInline tooltip={tooltip} />}
      </div>
      <div>
        {values.map((skill: string, index: number) => {
          const isLastItem = values.length - 1 === index;
          const hasHover = !(skill === '' && isLastItem);

          return (
            <div key={`${skillKey}.${index}`}>
              <HookFormInputWrapper control={control} name={`${skillKey}.${index}`}>
                {({ ref, isDirty, ...formProps }) => (
                  <>
                    <Input
                      id={`editable-input-${kebabCase(skillKey)}`}
                      placeholder={t(
                        'phone_system:containers.callflows.callflow_action_dialog.agent_required_skills.skill',
                      )}
                      width="medium"
                      {...formProps}
                    />
                    <Icon
                      hasHover={hasHover}
                      id="icon-minus-circle-outlined"
                      name="minus-circle-outlined"
                      size={22}
                      themeColor={`core.states.${hasHover ? 'error.main' : 'disabled'}`}
                      onClick={hasHover ? onRemoveHandler.bind(null, skillKey, index) : () => {}}
                    />
                    {isLastItem && (
                      <Icon
                        hasHover
                        id="icon-plus-circle-outlined"
                        name="plus-circle-outlined"
                        size={22}
                        onClick={onAddHandler.bind(null, skillKey)}
                      />
                    )}
                    {isDirty && <DirtyIcon isInline isDirty={isDirty} />}
                  </>
                )}
              </HookFormInputWrapper>
            </div>
          );
        })}
      </div>
    </StyledEditableInputList>
  );
};

export default EditableInputList;
