import Box from 'shared/components/Box';
import { SectionProps } from './definition';

export const Section = ({ children, sectionKey, template = {} }: SectionProps) => (
  <>
    {Object.entries(template).map(([key, fieldTemplate]) => {
      const fieldKey = `${sectionKey}.${key}`;
      return (
        <Box key={fieldKey} role="row">
          <Box role="cell">{children({ fieldKey, fieldTemplate })}</Box>
        </Box>
      );
    })}
  </>
);
