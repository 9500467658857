import Queues from 'api/queues';
import { RequestMethod } from 'definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const queuesMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createQueue: builder.mutation<QueueResponse, { body: any }>({
      query: ({ body }) => ({
        url: Queues.create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: [{ type: TAGS.QUEUES, id: 'LIST' }],
    }),
    addEavesdrop: builder.mutation<QueueResponse, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: Queues.addEavesdrop(id),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.QUEUES }],
    }),
    addCallEavesdrop: builder.mutation<QueueResponse, { body: any }>({
      query: ({ body }) => ({
        url: Queues.addCallEavesdrop(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: [{ type: TAGS.QUEUES }],
    }),
    updateQueue: builder.mutation<QueueResponse, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: Queues.updateById(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.QUEUES, id }],
    }),
    updateQueueUser: builder.mutation<QueueResponse, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: Queues.updateUsers(id),
        method: RequestMethod.Post,
        body: { data: body },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.QUEUES, id },
        { type: TAGS.AGENTS },
      ],
    }),
    deleteQueue: builder.mutation<Queue, { id: string }>({
      query: ({ id }) => ({
        url: Queues.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.QUEUES, id }],
    }),
    deleteMember: builder.mutation<QueueResponse, { id: string; callId: string }>({
      query: ({ id, callId }) => ({
        url: Queues.DeleteMember(id, callId),
        method: RequestMethod.Delete,
      }),
    }),
  }),
});
