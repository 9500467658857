import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';

type QueryResult = {
  isError: boolean;
  isLoading: boolean;
  data: ResultStatus;
  isSuccess: boolean;
  isFetching: boolean;
};

export enum ResultStatus {
  E911_ADDRESSES_PRESENT,
  E911_ADDRESSES_MISSING,
  NO_PHONE_NUMBERS,
}

export const useAccountActiveAddresses = (): QueryResult => {
  const { data, isError, isSuccess, isFetching, isLoading } = useFetchPhoneNumbersQuery();
  let result = ResultStatus.NO_PHONE_NUMBERS;

  if (data && isSuccess && !isFetching) {
    const {
      usage: { assigned },
    } = data;

    if (assigned) {
      const val = Object.values(assigned).every((number: any) => number?.e911?.street_address);
      result = val ? ResultStatus.E911_ADDRESSES_PRESENT : ResultStatus.E911_ADDRESSES_MISSING;
    }
  }

  return {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data: result,
  };
};
