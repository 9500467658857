import { SvgIconProps } from '@mui/material/SvgIcon';
import { ComponentProps } from 'shared/definition';

export enum FlippedType {
  Both = 'both',
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export type IconSet = 'brio' | 'custom';
export type IconSubset = 16 | 24;

export interface Flipped {
  /** Is the icon flipped both horizontally and vertically? */
  [FlippedType.Both]?: boolean;
  /** Is the icon flipped horizontally? */
  [FlippedType.Horizontal]?: boolean;
  /** Is the icon flipped vertically? */
  [FlippedType.Vertical]?: boolean;
}

interface Props extends SvgIconProps {
  /** Icon scale */
  scale?: number;
}

export interface IconProps extends ComponentProps, Props {
  /** Display hover effects? */
  hasHover?: boolean;
  /** Is the icon flipped? */
  isFlipped?: Flipped;
  /** Icon to display */
  name: string;
  /** Icon set to display */
  set?: string | IconSet;
  /** Icon subset to display */
  subset?: number | IconSubset;
  /** Icon size (for use with square icons) */
  size?: number;
  /** Icon width (for use with rectangular icons) */
  width?: number;
  /** Icon height (for use with rectangular icons) */
  height?: number;
  /** Override the icon fill color */
  themeColor?: string;
}

export interface StyledIconProps extends Props {
  /** Display hover effects? */
  hashover?: string;
  /** Override the icon fill color */
  themecolor: string;
}

export interface IconSvgProps {
  /** SVG elements */
  element?: JSX.Element | null;
  /** Icon width */
  width?: number;
  /** Icon height */
  height?: number;
  /** viewBox value */
  viewBox?: string;
}
