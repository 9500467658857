import { FilterGroup, useFilter } from 'apps/shared/hooks/useFilter';
import { FilterInputType } from 'apps/shared/hooks/useFilter/components/FilterDialogContent/definition';
import { useFetchAccountConfigsQuery } from 'models/Account';
import { useFetchDevicesQuery, useFetchDeviceStatusesQuery } from 'models/Device';
import { useFetchSeatsQuery } from 'models/Seat';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownButton from 'shared/components/DropdownButton';
import { FilterOption, FilterValues } from 'shared/components/FilterSelect';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import Table from 'shared/components/Table';
import DownloadIcon from './components/DownloadIcon';
import { FilterType } from './definition';
import { useAccountLocations } from './hooks/useAccountLocations';
import { useSeatType } from './hooks/useSeatType';
import { columns } from './utility';

const List = () => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<any>([]);
  const { locationOptions, getLocationLabelById } = useAccountLocations();

  const { data, error, isLoading, isSuccess } = useFetchSeatsQuery();
  const { data: devicesData, isLoading: isLoadingDevices } = useFetchDevicesQuery();
  const {
    data: accountConfigsData,
    isLoading: isLoadingAccountConfigs,
  } = useFetchAccountConfigsQuery();
  const {
    data: deviceStatusesData,
    error: deviceStatusesError,
    isLoading: isLoadingDeviceStatuses,
    isSuccess: isSuccessDeviceStatuses,
  } = useFetchDeviceStatusesQuery();

  const { seatTypes, getSeatTypeOptions } = useSeatType({ accountConfigsData });
  const { filter } = useFilter({
    data,
    inputConfig: {
      filterDefaults: {
        [FilterType.SeatLocation]: [
          FilterOption.All,
          ...locationOptions.map(({ value }: SelectOption) => value),
        ],
      },
      filterGroup: FilterGroup.Seats,
      items: {
        [FilterType.SeatLocation]: {
          compareFunction: (filterValues: FilterValues, { location }) =>
            filterValues.selected?.[FilterType.SeatLocation]?.includes(location),
          filterType: FilterType.SeatLocation,
          heading: t('phone_system:containers.seats.filter.heading.seat_location'),
          inputType: FilterInputType.Select,
          getBreadcrumbItemLabel: getLocationLabelById,
          selectInputConfig: {
            isMulti: false,
            options: locationOptions,
          },
        },
      },
    },
    setTableData,
  });

  const tableColumns = useMemo(
    () =>
      columns({
        isLoading,
        isLoadingDevices,
        isLoadingDeviceStatuses,
        isSuccess,
        isSuccessDeviceStatuses,
        devicesData,
        deviceStatusesData,
        deviceStatusesError,
        error,
        seatTypes,
        getLocationLabelById,
      }),
    [
      isLoading,
      isLoadingDeviceStatuses,
      isLoadingDevices,
      isSuccess,
      isSuccessDeviceStatuses,
      deviceStatusesData,
      deviceStatusesError,
      devicesData,
      error,
      seatTypes,
      getLocationLabelById,
    ],
  );

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table
      hasSearch
      actionRowButtons={{ right: <DownloadIcon data={tableData} /> }}
      addButton={{
        hasAdd: !isLoadingAccountConfigs,
        component: (
          <DropdownButton
            items={getSeatTypeOptions()}
            label={t('phone_system:containers.seats.table.action_row.button.add_seat.label')}
          />
        ),
      }}
      columns={tableColumns}
      data={tableData}
      filter={filter}
      hasPagination={{ rowsPerPage: true }}
      title={t('phone_system:containers.seats.table.title')}
    />
  );
};

export default List;
