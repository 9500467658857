import { WidthType } from 'shared/utility/definition';
import { Resize } from './definition';

const defaultProps = {
  minHeight: 40,
  maxHeight: 600,
  minRows: 3,
  maxRows: 12,
  resize: Resize.Vertical,
  width: 'large' as WidthType,
};

export default defaultProps;
