import {
  FieldValueType,
  SelectOptions,
} from 'apps/AdvancedProvisioner/shared/components/DynamicForm/definition';
import { useGetSelectOptions } from '../../../EditForm/components/DynamicInput/components/SelectInput/hooks';
import { getSelectLabel } from './utility';

export const TableCellSelectValue = ({
  inheritValue,
  options,
  value,
}: {
  inheritValue: FieldValueType | undefined;
  options: SelectOptions | undefined;
  value: FieldValueType;
}) => {
  const selectOptions = useGetSelectOptions(options, inheritValue);

  return <>{getSelectLabel(selectOptions, value)}</>;
};
