// Blocklist Mutations
import { Blocklist } from 'api/blocklist';
import { RequestMethod } from 'definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const blocklistMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createBlocklist: builder.mutation<Blocklist, CreateBlocklistParams>({
      query: ({ body }) => ({
        url: Blocklist.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: [{ type: TAGS.BLOCKLIST, id: 'LIST' }],
    }),
    updateBlocklist: builder.mutation<Blocklist, { id: string; body: Partial<Blocklist> }>({
      query: ({ id, body }) => ({
        url: Blocklist.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.BLOCKLIST, id }],
    }),
    patchBlocklist: builder.mutation<Blocklist, { id: string; body: Partial<Blocklist> }>({
      query: ({ id, body }) => ({
        url: Blocklist.Update(id),
        method: RequestMethod.Patch,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.BLOCKLIST, id }],
    }),
    deleteBlocklist: builder.mutation<Blocklist, { id: string }>({
      query: ({ id }) => ({
        url: Blocklist.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.BLOCKLIST, id }],
    }),
  }),
});
