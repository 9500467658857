import { CHARACTER } from 'constant';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import defaultProps from './default';
import { DirtyIconProps as Props } from './definition';
import StyledDirtyIcon from './style';

const DirtyIcon = (props: Props) => {
  const { isDirty, isDot, isError, isInline, tooltip } = { ...defaultProps, ...props };
  const { t } = useTranslation();

  return isDirty || isError ? (
    <StyledDirtyIcon $isError={isError} $isInline={isDot || isInline}>
      {isDot ? (
        <Box component="span">{CHARACTER.DOT}</Box>
      ) : (
        <Tooltip className="small" title={tooltip ?? t('common:dirty_icon.default.field')}>
          <Icon name="error-filled" size={20} />
        </Tooltip>
      )}
    </StyledDirtyIcon>
  ) : (
    <></>
  );
};

export default DirtyIcon;
