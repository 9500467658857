import * as utility from 'shared/utility';
import { themeColor } from 'theme';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      margin: 0,
      transition: theme.transitions.create(['color'], {
        duration: theme.transitions.duration.shortest,
      }),
      '.MuiTypography-root': {
        color: themeColor.core.color.black_141414,
        fontSize: 14,
      },
      '&.MuiFormControlLabel-is-inactive .MuiTypography-root': {
        color: utility.alphafy(themeColor.core.color.black_141414, 0.33),
      },
    },
  },
};
