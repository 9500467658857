import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';

const StyledDashboardIncomingCalls = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border-right: 1px solid ${theme.core.background.grey.light.border};

    .MuiInputBase-root {
      width: 100%;
      margin: 0;
    }

    // TODO: Relocate to shared Table component
    table tbody tr td {
      line-height: 16px !important;
    }
  `}
`;

export default StyledDashboardIncomingCalls;
