import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

const StyledCallflowDrawer = styled.div.attrs({ className: 'component-callflow-drawer' })`
  ${({ theme }) => css`
    ${mixin.flex({ justifyContent: 'flex-start', alignItems: 'flex-start' })};
    margin: ${theme.spacing(0, 0, 0, 3)};
    z-index: 100;
  `};
`;

export default StyledCallflowDrawer;
