import { INPUT_CONSTANTS } from 'shared/components/Input';
import { Justify, TableType, TABLE_CONSTANTS } from 'shared/components/Table';
import styled, { css } from 'styled-components';
import * as mixin from '../../utility/mixin';
import { StyledActionRowProps as Props } from './definition';

export const CONSTANTS = {
  GAP: {
    HORIZONTAL: '30px',
    VERTICAL: '18px',
  },
  HEIGHT: {
    get DEFAULT() {
      return `calc(40px + ${CONSTANTS.GAP.VERTICAL} * 2)`;
    },
    get BREADCRUMB_ABOVE() {
      return `calc(${CONSTANTS.HEIGHT.DEFAULT} + 18px)`;
    },
  },
};

const StyledActionRow = styled.div<Props>`
  ${({ hasBottomBorder, hasBottomMargin, hasPadding, buttonAlignment, tableType, theme }) => css`
    > div {
      ${mixin.flex({ justifyContent: 'flex-end' })};
      width: 100%;
      height: ${CONSTANTS.HEIGHT.DEFAULT};
      margin: ${hasBottomMargin ? theme.spacing(0, 0, 2) : 0};
      padding: ${hasPadding?.top ? CONSTANTS.GAP.VERTICAL : 0}
        ${hasPadding?.side ? CONSTANTS.GAP.HORIZONTAL : 0}
        ${hasPadding?.bottom ? CONSTANTS.GAP.VERTICAL : 0};
      background-color: ${theme.core.color.white};

      ${hasBottomBorder &&
      css`
        border-bottom: ${TABLE_CONSTANTS.BORDER};
      `}

      > :not(div:first-child) {
        margin-left: ${theme.spacing(2.66)};

        &.button-group {
          ${mixin.flex({ justifyContent: 'flex-end' })};

          > *:not(:first-child) {
            margin-left: ${theme.spacing(2.66)};
          }

          ${buttonAlignment?.left === Justify.Left &&
          css`
            &.button-group-left {
              margin: 0 auto 0 0;
            }
          `}
        }
      }

      ${[TableType.Selector, TableType.SelectionDefault, TableType.SelectionDraggable].includes(
        tableType as TableType,
      ) &&
      css`
        justify-content: flex-start;
        height: calc(${INPUT_CONSTANTS.HEIGHT} + ${CONSTANTS.GAP.VERTICAL});
        padding: 0 0 ${CONSTANTS.GAP.VERTICAL};
        border-bottom: none;

        /**
         * TODO: Relocate the below styling to their
         *       respective theme component files
         */

        .MuiTypography-root {
          display: none;
        }

        .MuiButtonBase-root {
          font-size: 15px;
        }

        .MuiInputBase-root {
          width: 100%;
          margin: 0;
        }
      `}
    }
  `};
`;

export default StyledActionRow;
