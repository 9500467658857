import { useFetchAccountChildrenByIdQuery } from 'models/Account';
import { FunctionComponent, useEffect, useState } from 'react';
import AccountList from '../AccountList';
import defaultProps from './default';
import { AccountListLocalProps as Props, listDepth } from './definition';

export const handleDepth = (
  id: string,
  currentLevel: number,
  currentId: string,
  listOpenDepth: listDepth,
  showDescendants: any,
) => {
  let triggeredListDepth = listOpenDepth[id];
  if (!triggeredListDepth) {
    triggeredListDepth = 1;
  } else if (triggeredListDepth && currentLevel !== triggeredListDepth) {
    triggeredListDepth = currentLevel;
  } else if (showDescendants[currentId] === 'open') {
    triggeredListDepth -= 1;
  } else {
    triggeredListDepth += 1;
  }
  return triggeredListDepth;
};

export const maxLevel = (listOpenDepth: listDepth) => {
  const levelArray =
    listOpenDepth && !!Object.keys(listOpenDepth).length ? Object.values(listOpenDepth) : [0];
  return Math.max(...levelArray);
};

const AccountListLocal: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { accountId: id, search, onClick, setChildParentData }: Props = {
    ...defaultProps,
    ...props,
  };
  const { data } = useFetchAccountChildrenByIdQuery({ id });
  const [filtered, setFiltered] = useState<any[]>([]);
  const [listOpenDepth, setListOpenDepth] = useState<listDepth>({});
  const [showDescendants, setShowDescendants] = useState<any>({});

  useEffect(() => {
    const filterSearch = (account: any) => {
      const lcSearch = search.toLowerCase().trim();
      return (
        search === '' ||
        account.id.toLowerCase().includes(lcSearch) ||
        account.name.toLowerCase().includes(lcSearch) ||
        account.realm.toLowerCase().includes(lcSearch)
      );
    };
    data && setFiltered(data.filter((account: any) => filterSearch(account)));
  }, [data, search]);

  return (
    <AccountList
      key={id}
      data={filtered}
      onClick={onClick}
      isRoot
      listOpenDepth={listOpenDepth || {}}
      maxLevel={() => maxLevel(listOpenDepth)}
      setChildParent={setChildParentData}
      setListOpenDepth={(id, currentLevel, currentId) => {
        setListOpenDepth({
          ...listOpenDepth,
          [id]: handleDepth(id, currentLevel, currentId, listOpenDepth, showDescendants),
        });
      }}
      showDescendants={showDescendants || {}}
      setShowDescendants={(id) =>
        setShowDescendants({
          ...showDescendants,
          [id]: !showDescendants[id] || showDescendants[id] === 'closed' ? 'open' : 'closed',
        })
      }
    />
  );
};

export default AccountListLocal;
