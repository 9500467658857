import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { useToast } from 'shared/hooks/useToast';
import defaultProps from './default';
import { CopyToClipboardProps as Props } from './definition';
import { StyledCopyToClipboardButton } from './style';

const CopyToClipboardButton: FunctionComponent<Props> = (props: Props) => {
  const { text, size } = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { showToast } = useToast();

  const onHandleCopy = () => {
    navigator.clipboard.writeText(text);
    showToast.success(t('common:copy_success'));
  };

  return (
    <StyledCopyToClipboardButton data-test-id={`copy-${text}`} onClick={onHandleCopy}>
      <Tooltip title={t('common:copy')}>
        <Icon name="file-copy-outlined" size={size} hasHover />
      </Tooltip>
    </StyledCopyToClipboardButton>
  );
};

export default CopyToClipboardButton;
