import { useDeleteCallflowMutation } from 'models/Callflow';
import { useCallback } from 'react';
import useLazyFetchBridgeCallflow from '../useFetchBridgeCallflow';

const useDeleteBridgeCallflow = (initialNumber: string) => {
  const [deleteCallflow] = useDeleteCallflowMutation();
  const fetchBridgeCallflow = useLazyFetchBridgeCallflow(initialNumber || '');

  const deleteBridgeCallflow = useCallback(async () => {
    const bridgeCallflow = await fetchBridgeCallflow();

    return deleteCallflow({
      id: bridgeCallflow?.id,
    }).unwrap();
  }, [deleteCallflow, fetchBridgeCallflow]);

  return deleteBridgeCallflow;
};

export default useDeleteBridgeCallflow;
