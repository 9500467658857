import SummaryNumbers from 'apps/shared/components/AddNumberFlow/components/SummaryNumbers';
import { SummaryList } from 'apps/shared/components/AddNumberFlow/definition';
import ConfirmSelectionDialog from 'apps/shared/components/ConfirmSelectionDialog';
import { RowArray } from 'apps/shared/definition';
import { getCollectionList, getTableDataFromJson } from 'apps/shared/utility';
import { useFetchAccountChildrenQuery, useFetchAccountQuery } from 'models/Account';
import {
  useDeleteListMutation,
  useFetchPhoneNumbersQuery,
  useMoveCollectionToMutation,
} from 'models/PhoneNumber';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import Table, { Justify, SelectionType } from 'shared/components/Table';
import useAccount from 'shared/hooks/useAccount';
import { useToast } from 'shared/hooks/useToast';
import { dummyChildAccount } from './default';
import { ChildAccount } from './definition';
import { buildActionRowButtons, columns, getDialogProps } from './utility';

const List = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [isDialogDelete, setIsDialogDelete] = useState<boolean>(false);
  const [confirmData, setConfirmData] = useState<RowArray>([]);
  const [moveTarget, setMoveTarget] = useState<ChildAccount>(dummyChildAccount);
  const [selectedRows, setSelectedRows] = useState<RowArray>([]);
  const [summaryList, setSummaryList] = useState<SummaryList>({
    success: [],
    error: [],
  });

  const { isReseller, isSuperDuper } = useAccount();
  const { data, isLoading } = useFetchPhoneNumbersQuery();
  const { data: accountData } = useFetchAccountQuery();
  const { data: accountChildrenData = [] } = useFetchAccountChildrenQuery();
  const [deleteList] = useDeleteListMutation();
  const [moveTo] = useMoveCollectionToMutation();
  const hasSummaryItems = summaryList.success.length > 0 || summaryList.error.length > 0;
  const table = {
    columns: useMemo(() => columns(), []),
    data: useMemo(() => getTableDataFromJson(data), [data]),
  };

  const onDelete = useCallback(async () => {
    try {
      const dataList = getCollectionList({ data: confirmData, accessor: 'id' });
      const response: any = await deleteList(dataList).unwrap();
      const responseData = response?.data;

      if (response.error) {
        throw response.error;
      }

      if (Object.keys(responseData?.error ?? {}).length > 0) {
        const successKeys = responseData.success ? Object.keys(responseData.success) : [];
        const errorKeys = Object.keys(responseData.error);
        setSummaryList({
          success: successKeys,
          error: errorKeys,
        });
      } else {
        showToast.success(
          t('numbers:containers.spare_numbers.delete.confirm.success', {
            itemCount: confirmData.length,
          }),
        );
      }

      setSelectedRows([]);
    } catch (exception) {
      showToast.error(t('numbers:containers.spare_numbers.delete.confirm.error'));
    } finally {
      setConfirmData([]);
    }
  }, [confirmData, showToast, deleteList, t]);

  const onMove = useCallback(async () => {
    try {
      const dataList = getCollectionList({ data: confirmData, accessor: 'id' });
      const response: any = await moveTo({ id: moveTarget.id, ...dataList });

      if (response.error) {
        throw response.error;
      }

      showToast.success(
        t('numbers:containers.spare_numbers.move.confirm.success', {
          accountName: moveTarget.name,
        }),
      );

      setSelectedRows([]);
    } catch (exception) {
      showToast.error(t('numbers:containers.spare_numbers.move.confirm.error'));
    } finally {
      setConfirmData([]);
    }
  }, [confirmData, moveTarget.id, moveTarget.name, showToast, moveTo, t]);

  if (isLoading) {
    return <Loading hasLargeProgress hasLabel={false} />;
  }

  return (
    <>
      <Table
        hasSearch
        hasEditIcon={false}
        actionRowButtons={{
          left: buildActionRowButtons({
            hasMove: isReseller || isSuperDuper,
            accountData: accountChildrenData,
            selectedRows,
            setConfirmData,
            setIsDialogDelete,
            setMoveTarget,
          }),
        }}
        actionRowButtonAlignment={{
          left: Justify.Left,
        }}
        columns={table.columns}
        data={table.data}
        selection={{
          type: SelectionType.Multiple,
          setRows: setSelectedRows,
        }}
        title={t('numbers:containers.spare_numbers.table.title')}
      />
      <ConfirmSelectionDialog
        {...getDialogProps({
          isDelete: isDialogDelete,
          accountName: {
            from: accountData?.name,
            to: moveTarget?.name,
          },
          confirmData,
          on: {
            delete: onDelete,
            move: onMove,
          },
          setConfirmData,
        })}
      />
      <SummaryNumbers
        description={t('numbers:containers.spare_numbers.dialog.summary_numbers.description')}
        isOpen={hasSummaryItems}
        importedList={summaryList}
        onClose={() => {
          setSummaryList({
            success: [],
            error: [],
          });
        }}
        title={t('numbers:containers.spare_numbers.dialog.summary_numbers.title')}
      />
    </>
  );
};

export default List;
