import { themeColor } from 'theme/local';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      color: theme.palette.common.black,
      fontWeight: 600,
      lineHeight: '1em',
      userSelect: 'none',
      '&.MuiInputLabel-select-filter': {
        display: 'inline-block',
        height: 36,
        padding: '2px 12px',
        color: theme.palette.common.black,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '36px',
        transform: 'none',
      },
      '.MuiFormLabel-asterisk': {
        color: themeColor.whitelabel.primary.color,
      },
    },
  },
};
