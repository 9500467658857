import { ADD_KEY } from 'constant';
import { useFetchDirectoriesQuery } from 'models/Directory';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Table, { Cell, Justify } from 'shared/components/Table';

enum SortBy {
  FirstName = 'first_name',
  LastName = 'last_name',
}

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useFetchDirectoriesQuery();

  const sortByTypes = {
    [SortBy.FirstName]: t('phone_system:containers.directories.field.sort_by.options.first_name'),
    [SortBy.LastName]: t('phone_system:containers.directories.field.sort_by.options.last_name'),
  };

  const columns = useMemo(
    () => [
      {
        Header: t('phone_system:containers.directories.table.column.name'),
        accessor: 'name',
      },
      {
        id: `directories.number_of_users.${Justify.Right}`,
        Header: t('phone_system:containers.directories.table.column.number_of_users'),
        accessor: 'num_users',
        width: 200,
      },
      {
        id: `directories.min_input.${Justify.Right}`,
        Header: t('phone_system:containers.directories.table.column.min_input'),
        accessor: 'min_dtmf',
        width: 200,
      },
      {
        id: `directories.max_input.${Justify.Right}`,
        Header: t('phone_system:containers.directories.table.column.max_input'),
        accessor: 'max_dtmf',
        width: 200,
      },
      {
        Header: t('phone_system:containers.directories.table.column.sort_by'),
        Cell: ({ value }: Cell<Record<SortBy, string>>) => sortByTypes[value as SortBy],
        accessor: 'sort_by',
        width: 400,
      },
    ],
    [],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table
      title={t('phone_system:containers.directories.table.title')}
      columns={columns}
      data={data ?? []}
      addButton={{
        hasAdd: true,
        label: t('phone_system:containers.directories.table.action_row.button.add_directory.label'),
        onAdd: () => navigate(`./${ADD_KEY}`),
      }}
      hasSearch
    />
  );
};

export default List;
