import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Feedback } from 'shared/components/Labeled';
import { ComponentProps } from 'shared/definition';
import { Member } from './utility';

export enum SharedName {
  Sms,
}

export type FieldValue = Record<string, any>;

type Props = ComponentProps;

export interface SharedDialogProps extends Props {
  hasDialogLink: boolean;
  isDirty?: boolean;
  feedback?: Feedback;
  maxSelectedUsers?: number;
  qualifiedName: string;
  sharedName?: SharedName;
  value: Array<Member>;
  setValue: UseFormSetValue<FieldValues>;
}

export type StyledSharedDialogProps = Props;
