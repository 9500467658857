import { EndpointType } from 'apps/PhoneSystem/definition';
import { FunctionComponent, useState } from 'react';
import { LabeledEditableList } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import useEndpoints from '../../../../../shared/hooks/useEndpoints';
import { LabeledEndpointsListProps as Props } from './definition';
import { convertToEndpointType, getListData } from './utility';

const LabeledEndpointsList: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isDirty, data, feedback, onChange } = props;
  const [endpointType, setEndpointType] = useState<EndpointType>(EndpointType.User);
  const { endpoints, isLoading } = useEndpoints();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LabeledEditableList
      isDirty={isDirty}
      feedback={feedback}
      labelWidth="none"
      editableListProps={{
        isWindowedSelect: true,
        data: getListData({
          data,
          endpoints,
          endpointType,
          setEndpointType,
        }),
        onUpdate: (data: any) => onChange?.(convertToEndpointType(data, endpoints)),
        dataTestId: 'phone-system-groups-edit-labeled-endpoints-list',
      }}
    />
  );
};

export default LabeledEndpointsList;
