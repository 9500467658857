import { useEffect, useState } from 'react';

const usePersistentToggle = ({
  initState = true,
  storageKey,
}: {
  initState?: boolean;
  storageKey: string;
}) => {
  const [toggle, setToggle] = useState<boolean>(initState);

  const onClick = () => {
    setToggle((toggle: boolean) => {
      if (toggle) {
        localStorage.setItem(storageKey, 'false');
      } else {
        localStorage.setItem(storageKey, 'true');
      }
      return !toggle;
    });
  };

  useEffect(() => {
    const storageValue = localStorage.getItem(storageKey);
    if (storageValue) {
      setToggle(storageValue === initState.toString());
    }
  }, [initState, storageKey]);

  return { toggle, onClick };
};

export default usePersistentToggle;
