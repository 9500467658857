export default {
  styleOverrides: {
    root: {
      '&, *': {
        boxSizing: 'content-box !important',
      },
      '.MuiTypography-root': {
        lineHeight: 1.15,
      },
    },
  },
};
