import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { Breakpoint } from '../../style';
import { StyledLogoProps as Props } from './definition';

const CONSTANTS = {
  HEIGHT: '62px',
};

const StyledLogo = styled.div<Props>`
  ${({ logo, theme }) => css`
    position: relative;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    z-index: 1;

    @media (min-width: ${Breakpoint.LARGE}) {
      position: absolute;
      top: 50%;
      right: ${theme.spacing(9.33)};
      width: 100%;
    }

    > div {
      height: ${CONSTANTS.HEIGHT};
      margin: 0 auto;
      background-image: url(${logo});
      background-position: center;
      background-repeat: no-repeat;

      @media (min-width: ${Breakpoint.LARGE}) {
        margin: ${theme.spacing(0, 0, 0, 8)};
      }
    }

    h3 {
      ${mixin.font({ size: 24, weight: 'lighter' })};
      margin: ${theme.spacing(3.33, 0, 0)};
      color: ${theme.core.color.white};
    }
  `}
`;

export default StyledLogo;
