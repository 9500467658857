import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFormActionRowProps as Props } from './definition';

export const CONSTANTS = {
  HEIGHT: '50px',
  WIDTH: {
    LINK: '199px',
  },
};

const StyledFormActionRow = styled.div<Props>`
  ${({ theme }) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};
    margin: ${theme.spacing(5, 5, -5)};

    > div {
      ${mixin.flex({ justifyContent: 'flex-start' })};
      height: ${CONSTANTS.HEIGHT};

      &:first-of-type {
        width: ${CONSTANTS.WIDTH.LINK};
        padding-left: ${theme.spacing(3.5)};

        > div {
          ${mixin.flex()};
          ${mixin.transition({ property: 'color' })};
          color: ${theme.core.color.black};
          cursor: pointer;
          font-weight: 600;
          text-decoration: none;
          white-space: nowrap;

          &:hover {
            color: ${theme.whitelabel.primary.color};
          }

          > svg {
            margin: ${theme.spacing(0, 1, 0, 0)};
            transform: translateY(1px);
          }
        }
      }

      &:last-of-type {
        ${mixin.borderRadius({ radius: 'half half 0 0' })};
        width: calc(100% - ${CONSTANTS.WIDTH.LINK});
        padding: ${theme.spacing(0, 5)};
        background-color: ${theme.core.color.grey.pewter};
        overflow: hidden;

        > span {
          margin: 0 auto 0 0;
          color: ${theme.core.color.white};
          font-weight: 700;
          white-space: nowrap;
        }

        > *:not(:first-child):not(:last-child) {
          margin: ${theme.spacing(0, 2.66)};
        }
      }
    }
  `}
`;

export default StyledFormActionRow;
