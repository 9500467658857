import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { ADD_KEY } from 'constant';
import uniqBy from 'lodash/uniqBy';
import { useFetchFaxboxesByOwnerIdQuery } from 'models/Faxbox';
import { useFormContext } from 'react-hook-form';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import { FormInput } from '../../../definition';
import { columns } from './utility';

type Props = TabPanelProps<FormInput>;

const FaxboxListView = ({ id = ADD_KEY }: Props) => {
  const { getValues } = useFormContext();
  const { data, isLoading } = useFetchFaxboxesByOwnerIdQuery({ id });

  if (isLoading) {
    return <Loading />;
  }

  const tableData = uniqBy(
    [...Object.values(getValues('faxbox')), ...(data as Array<any>)],
    'id',
  ).filter((item) => !getValues(`faxbox.${item.id}._toDelete`));

  return (
    <Table hasActionRow={false} columns={columns()} data={tableData || []} title="faxbox_table" />
  );
};

export default FaxboxListView;
