import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import { ListItemLowerProps as Props } from './definition';
import StyledListItemLower from './style';

export { CONSTANTS as LIST_ITEM_LOWER_CONSTANTS } from './style';

const ListItemLower: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isOpen, callflowId, isQuickViewDisabled, onQuickViewClick, onEditClicked } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    onEditClicked();
    return (() => navigate(`../${callflowId}`))();
  };

  return (
    <StyledListItemLower isOpen={isOpen}>
      <Button
        disabled={isQuickViewDisabled}
        variant="outlined"
        size="drawer"
        startIcon={<Icon name="visibility-on-filled" />}
        onClick={() => onQuickViewClick(callflowId)}
      >
        {t('phone_system:containers.callflows.quick_view.button.label')}
      </Button>
      <Button
        variant="outlined"
        size="drawer"
        startIcon={<Icon name="edit" />}
        onClick={handleClick}
      >
        {t('common:edit')}
      </Button>
    </StyledListItemLower>
  );
};

export default ListItemLower;
