import { REGEX } from 'constant';
import Joi from 'joi';
import { SelectOption } from 'shared/components/Select';
import { messages } from 'shared/utility/validation';
import { NumberType } from './definition';

export const schema = (options: Record<NumberType, SelectOption>) => {
  const typeRef = Joi.ref('numberType');

  return Joi.object({
    extension: Joi.when(typeRef, {
      is: Joi.string().valid(options.extension.value),
      then: Joi.string().regex(REGEX.CALLFLOW.EXTENSION).required(),
      otherwise: Joi.optional(),
    }),
    spare: Joi.when(typeRef, {
      is: Joi.string().valid(options.spare.value),
      then: Joi.string().required(),
      otherwise: Joi.optional(),
    }),
  })
    .unknown(true)
    .messages(messages());
};
