import { ComponentProps } from 'shared/definition';

export enum AgentActionType {
  LOG_IN_AGENT = 'logInAgent',
  LOG_OUT_AGENT = 'logOutAgent',
  RESTART_AGENT = 'restartAgent',
}

export enum AgentStatusType {
  END_WRAPUP = 'end_wrapup',
  LOGIN = 'login',
  LOGOUT = 'logout',
  OUTBOUND = 'outbound',
  PAUSED = 'paused',
  RESUME = 'resume',
}

export interface DashboardAgentsProps extends ComponentProps {
  /** Agents listing */
  agents?: AgentsResponse;
  /** Agents status data */
  agentsStatus?: AgentsStatus;
  /** Currently selected queue */
  queue?: Queue;
  /** Queues stats summary data */
  queuesSummary?: QueuesStatsSummary;
}
