import * as mixin from 'shared/utility/mixin';
import { css } from 'styled-components';
import { StyledTableProps as Props } from '../../definition';
import { CONSTANTS } from '../constants';
import { TableType } from '../definition';

export const selector = ({ theme, type }: Props) => css`
  ${[TableType.Selector, TableType.SelectionDefault, TableType.SelectionDraggable].includes(
    type as TableType,
  ) &&
  css`
    height: calc(
      (${CONSTANTS.HEIGHT.CELL.TBODY} * ${CONSTANTS.ROW_COUNT}) + ${CONSTANTS.HEIGHT.CELL.THEAD} +
        (${CONSTANTS.HEIGHT.BORDER} * 4)
    );

    table {
      ${mixin.borderRadius()};
      padding: 0;
      border: ${CONSTANTS.BORDER};

      --row-height: ${CONSTANTS.HEIGHT.CELL.TBODY};

      tbody {
        max-height: calc(var(--row-height) * ${CONSTANTS.ROW_COUNT});

        tr {
          &:last-of-type td {
            border-bottom: none;
          }

          td {
            height: var(--row-height);

            &:first-of-type svg {
              margin: 0 ${theme.spacing(1.5)} 0 0;
            }

            &:last-of-type {
              width: ${CONSTANTS.WIDTH.ICON.ACTION} !important;
              padding: 0;
              border-left: ${CONSTANTS.HEIGHT.BORDER} solid ${theme.core.border.color};
              text-align: center;
            }
          }
        }
      }
    }

    ${[TableType.SelectionDefault, TableType.SelectionDraggable].includes(type as TableType) &&
    css`
      table {
        ${mixin.flex({ direction: 'column' })};

        thead,
        tbody {
          tr {
            ${mixin.flex({ justifyContent: 'flex-start' })};

            th,
            td {
              &:first-of-type {
                flex-grow: 1;
              }
            }
          }
        }

        tbody {
          overflow: hidden auto;

          tr {
            ${mixin.flex({ justifyContent: 'flex-start' })};

            td {
              height: var(--row-height);
            }
          }
        }

        thead tr th:last-of-type {
          width: ${CONSTANTS.WIDTH.ICON.ACTION} !important;
        }
      }
    `}

    ${type === TableType.SelectionDraggable &&
    css`
      table {
        thead,
        tbody {
          tr {
            th,
            td {
              &:first-of-type {
                width: ${CONSTANTS.WIDTH.ICON.DRAG};
                padding: 0 ${theme.spacing(1.33)};
                flex-grow: 0;
              }

              &:nth-of-type(2) {
                padding-left: 0;
                flex-grow: 1;

                svg {
                  margin: 0 ${theme.spacing(1.5)} 0 0;
                }
              }

              &:not(:first-of-type):not(:last-of-type):not(:nth-of-type(2)) {
                width: ${CONSTANTS.WIDTH.INPUT} !important;
                padding: 0 ${theme.spacing(2)};
              }
            }
          }
        }
      }
    `}
  `}
`;
