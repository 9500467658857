import { TextareaAutosizeProps as MUITextareaAutosizeProps } from '@mui/material/TextareaAutosize';
import { ComponentProps } from 'shared/definition';
import { WidthType } from 'shared/utility/definition';

export enum Resize {
  Both = 'both',
  Horizontal = 'horizontal',
  None = 'none',
  Vertical = 'vertical',
}

interface Props extends ComponentProps, MUITextareaAutosizeProps {
  /* Resize value */
  resize?: Resize;
  /** Width of textarea */
  width?: WidthType;
}

export interface TextareaProps extends Props {
  /* Maximum height of textarea box */
  maxHeight?: number;
  /* Minimum height of textarea box */
  minHeight?: number;
}

export interface StyledTextareaProps extends Props {
  /* Maximum height of textarea box */
  maxheight: number;
  /* Minimum height of textarea box */
  minheight: number;
}
