import { ADD_KEY } from 'constant';
import { useFetchAccountHeroAppsQuery } from 'models/Account';
import { useFetchSeatHeroAppsByIdQuery } from 'models/Seat';

export const useHeroAppsList = (id: string) => {
  /**
   * When adding a new user:
   * • use accountHeroAppsData to display the list of available apps
   *   (seatHeroAppsData will not exist until after creation)
   * When editing an existing user:
   * • use seatHeroAppsData to display the list of available apps
   * */
  const { data: accountHeroAppsData = [] } = useFetchAccountHeroAppsQuery();
  const { data: seatHeroAppsData = [] } = useFetchSeatHeroAppsByIdQuery(
    { id },
    { skip: id === ADD_KEY },
  );

  return (seatHeroAppsData.length > 0 ? seatHeroAppsData : accountHeroAppsData).filter(
    ({ available }: any) => available,
  );
};
