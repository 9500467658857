import { defaultCallRestrictionValues } from 'apps/shared/hooks/useCallRestrictionsSection/components/CallRestrictionsSection';

export const defaultValues = {
  name: '',
  device_type: 'sip_device',
  enabled: true,
  follow_me: true,
  presence_id: '',
  should_create_caller_id: true,
  outbound_flags: '',
  ignore_completed_elsewhere: false,
  exclude_from_queues: false,
  suppress_unregister_notifications: false,

  caller_id: {
    internal: {
      name: '',
      number: '',
    },
    external: {
      name: '',
      number: '',
    },
    emergency: {
      name: '',
      number: '',
    },
  },
  sip: {
    invite_format: 'contact',
    expire_seconds: '360',
    method: 'password',
    username: undefined,
    password: undefined,
  },
  mac_address: '',
  media: {
    peer_to_peer: 'auto',
    encryption: {
      methods: [],
    },
    video: {
      codecs: [],
    },
    audio: {
      codecs: ['G722', 'G729', 'PCMU'],
    },
    fax: {
      option: false,
    },
  },
  music_on_hold: {
    media_id: '',
  },

  contact_list: {
    exclude: false,
  },
  ringtones: {
    internal: '',
    external: '',
  },

  call_forward: {
    direct_calls_only: false,
    enabled: true,
    failover: false,
    ignore_early_media: true,
    keep_caller_id: true,
    number: '',
    require_keypress: true,
    substitute: true,
  },

  call_restriction: defaultCallRestrictionValues,
};
