import { useEffect, useRef } from 'react';

export const useCleanUpUnmodifiedEntities = () => {
  const onCleanupRef = useRef<() => void>(() => {});

  useEffect(() => {
    return () => {
      onCleanupRef.current();
    };
  }, []);

  const setOnCleanUp = (cleanUp: () => void) => {
    onCleanupRef.current = cleanUp;
  };

  return {
    setOnCleanUp,
  };
};
