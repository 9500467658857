import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class WebhookActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.WEBHOOK,
      module: CallFlowModuleKey.WEBHOOK,
      section: CallFlowSection.ADVANCED,
      rules: [],
      iconProps: {
        name: 'backup',
      },
      label: i18next.t('phone_system:containers.callflows.actions.webhook.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.webhook.tooltip'),
    });
  }
}

export default WebhookActionConfig;
