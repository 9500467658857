import { getRootAccountId } from 'apps/shared/utility/account/getRootAccountId';
import { useSelector } from 'react-redux';

/**
 * Return the masquerading account id if exist
 * @returns the masquerading account id
 */
export const useMasqueradingAccountId = () => {
  const rootAccountId = getRootAccountId();
  const currentId: string = useSelector((state: any) => state.account.id);

  if (!rootAccountId) {
    return null;
  }

  return currentId !== rootAccountId ? currentId : null;
};
