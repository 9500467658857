export enum DataSource {
  Tts = 'tts',
  Upload = 'upload',
}

export interface FormInput {
  id: string;
  name: string;
  media_source: string;
  streamable: boolean;
  description: string;
  content_length: number;
  file: FileList;
  tts: {
    voice: string;
    text: string;
  };
}
