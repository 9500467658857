import Box from 'shared/components/Box';
import * as utility from 'shared/utility';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

const StyledFeatureCodeGroup = styled(Box).attrs({ role: 'cell' })`
  ${({ theme }) => css`
    width: ${utility.getWidth({
      type: 'featureCode',
      width: { input: 'large', label: 'large' },
      offset: 70,
    })};

    h2 {
      ${mixin.font({ size: 16, weight: 600 })};
      width: 100%;
      margin: ${theme.spacing(3, 0, 3)};
      padding: ${theme.spacing(0, 0, 2)};
      border-bottom: 1px solid ${theme.core.input.border.default};
      user-select: none;
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      h2 {
        margin-top: ${theme.spacing(1)};
      }
    }
  `}
`;

export default StyledFeatureCodeGroup;
