import Joi from 'joi';
import { FormFields } from '../fields';

export const idpSchema = {
  [FormFields.EmailClaim]: Joi.string().allow('').optional(),
  [FormFields.Id]: Joi.string().allow('').optional(),
  [FormFields.SignRequest]: Joi.boolean().optional(),
  [FormFields.SsoUrl]: Joi.string()
    .uri({ domain: { minDomainSegments: 2, tlds: false } })
    .required(),
  [FormFields.UniqueIdClaim]: Joi.string().allow('').optional(),
  [FormFields.UseNameIdForEmail]: Joi.boolean().optional(),
  [FormFields.UsePostMethod]: Joi.boolean().optional(),
  [FormFields.UsernameClaim]: Joi.string().allow('').optional(),
  [FormFields.X509Certificate]: Joi.required().disallow(null),
};
