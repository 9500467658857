import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import GlobalStyle from './index.style';
import './index.style.css';
import reportWebVitals from './reportWebVitals';
import store from './store';

createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>,
);

/**
 * If you want to measure performance in your app, pass a function
 * to log results (e.g., reportWebVitals(console.log)) or send to an
 * analytics endpoint. Learn more: `https://bit.ly/CRA-vitals`
 */
reportWebVitals();
