import Joi from 'joi';
import { messages } from 'shared/utility/validation';

const schema = () => {
  return Joi.object({
    id: Joi.string().required(),
    nodeId: Joi.string(),
  }).messages(messages());
};

export default schema;
