import { useFetchRootUserQuery } from 'models/User';
import { FunctionComponent } from 'react';
import Loading from 'shared/components/Loading';
import { CurrentUserProps as Props } from './definition';
import StyledCurrentUser from './style';

const CurrentUser: FunctionComponent<Props> = (): JSX.Element => {
  const { data, isLoading } = useFetchRootUserQuery();

  if (isLoading) return <Loading />;

  return (
    <StyledCurrentUser>
      <span>
        {data?.first_name} {data?.last_name}
      </span>
    </StyledCurrentUser>
  );
};

export default CurrentUser;
