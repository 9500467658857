import { AncestorData, RealmData } from './definition';

/**
 * @name getRealmData
 * @description Create the realm ancestor array
 *
 * @param accountData
 * @param ancestorData
 *
 * @returns Realm array
 */
export const getRealmData = ({
  accountData,
  ancestorData,
}: {
  accountData: AncestorData;
  ancestorData: Array<AncestorData>;
}) => {
  let data: Array<RealmData> = [];

  if (ancestorData?.length > 0) {
    data = ancestorData?.map(({ name, realm }: RealmData) => ({ name, realm }));
  }

  if (accountData?.name && accountData?.realm) {
    const { name, realm } = accountData;
    data.push({ name, realm });
  }

  return data;
};
