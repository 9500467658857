import useSeatTypes from 'apps/PhoneSystem/shared/hooks/useSeatTypes';
import { SEAT_TYPE } from 'constant';
import { useFetchAccountSiteLocationUsersQuery } from 'models/Account';
import { FunctionComponent, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import DownloadIcon from 'shared/components/DownloadIcon';
import defaultProps from './default';
import { Seat, TableCellDownloadProps as Props } from './definition';
import translations from './translations';

const TableCellDownload: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    location: { id: locationId, display_name: locationName },
  } = { ...defaultProps, ...props };
  const { data } = useFetchAccountSiteLocationUsersQuery({ locationId });
  const [csvData, setCsvData] = useState<Seat[]>([]);
  const seatTypes = useSeatTypes();
  const { csv } = translations();

  useEffect(() => {
    if (data) {
      setCsvData(
        /**
         * Update the data appearing in the CSV file:
         * • Filter out (non-billable) admin seats
         * • Replace seat type id with seat type display name
         * • Sort listing by last name
         */
        data
          .filter((item: Seat) => item.seat_type !== SEAT_TYPE.admin.id)
          .map((item: Seat) => ({
            ...item,
            seat_type: seatTypes[item.seat_type],
          }))
          .sort((a: Seat, b: Seat) => a.last_name.localeCompare(b.last_name)),
      );
    }
  }, [data, seatTypes]);

  const headers = [
    { key: 'last_name', label: csv.headers.last_name },
    { key: 'first_name', label: csv.headers.first_name },
    { key: 'email', label: csv.headers.email },
    { key: 'username', label: csv.headers.username },
    { key: 'seat_type', label: csv.headers.seat_type },
    { key: 'timezone', label: csv.headers.timezone },
  ];

  return csvData ? (
    <CSVLink
      className="icon"
      data={csvData}
      headers={headers}
      filename={`Seats at ${locationName}`}
    >
      <DownloadIcon />
    </CSVLink>
  ) : (
    <></>
  );
};

export default TableCellDownload;
