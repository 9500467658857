import styled, { css } from 'styled-components';
import { StyledMapFrameProps as Props } from './definition';

export const StyledMapFrame = styled.div<Props>`
  ${(props) => css`
    > svg .geography:hover {
      fill: ${props.theme.core.color.black};
    }

    p span {
      font-weight: 600;
    }
  `}
`;
