import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class LanguageActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.CHANGE_LANGUAGE,
      module: CallFlowModuleKey.CHANGE_LANGUAGE,
      section: CallFlowSection.CALL_SETTINGS,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'language',
      },
      label: i18next.t('phone_system:containers.callflows.actions.language.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.language.tooltip'),
    });
  }

  static subtitle = (nodeData: any) => nodeData?.language;
}

export default LanguageActionConfig;
