import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import { memo, useCallback } from 'react';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import { EditIcon } from 'shared/components/Table';
import UseAccountButton from '../UseAccountButton';
import { ChildCount } from './components/ChildCount';
import { TableCellAccountProps as Props } from './definition';
import StyledTableCellAccount from './style';

const TEXT_COLUMNS = ['name', 'realm', 'id'];

const TableCellAccount = memo(
  ({
    column,
    data: {
      row: {
        canExpand,
        isExpanded,
        depth,
        originalSubRows = [],
        subRows,
        toggleAllRowsExpanded,
        toggleRowExpanded,
        values: account,
      },
      state,
    },
  }: Props) => {
    const childCount = state?.globalFilter ? subRows.length : originalSubRows.length;

    /**
     * Leaving lazy loading of children/subRows here for now
     * as we may revisit this in the future
     *
     * const { data: childData } = useFetchAccountChildrenByIdQuery({ id: account.id });
     * const childCount = childData?.length ?? 0;
     */

    const onClick = useCallback(() => {
      if (TEXT_COLUMNS.includes(column)) {
        /**
         * Leaving this here for now (see above)
         *
         * // append sub rows to current row
         * if (setData && childCount > 0 && subRows.length === 0) {
         *   await setData((data: any) => {
         *     const _data = cloneDeep(data);
         *     set(_data, getPath(account.id, _data), childData);
         *     return _data;
         *   });
         * }
         */

        toggleRowExpanded?.();
      }
    }, [column, toggleRowExpanded]);

    return (
      <StyledTableCellAccount
        canExpand={canExpand}
        isExpanded={isExpanded}
        className={column === 'button' ? 'hover-opacity' : ''}
        column={column}
        data-test-id="table-cell-account"
        depth={depth}
        onClick={onClick}
      >
        {TEXT_COLUMNS.includes(column) ? (
          <>
            {column === 'name' && (
              <Box>{canExpand && <Icon name="chevron-right-outlined" size={16} />}</Box>
            )}
            <Box component="span">
              {column === 'realm' ? (
                <TableCellTruncatedValue maxWidth={600} text={account?.[column]} />
              ) : (
                account?.[column]
              )}
              {column === 'name' && <ChildCount isExpanded={isExpanded} count={childCount} />}
            </Box>
          </>
        ) : (
          <>
            {column === 'edit' && <EditIcon id={account.id} />}
            {column === 'button' && (
              <UseAccountButton
                account={{
                  id: account.id,
                  name: account.name,
                }}
                toggleAllRowsExpanded={toggleAllRowsExpanded}
              />
            )}
          </>
        )}
      </StyledTableCellAccount>
    );
  },
);

export default TableCellAccount;
