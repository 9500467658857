import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { FilterOption, FilterValues } from 'shared/components/FilterSelect';
import { FilterInputConfig } from '../../components/FilterDialogContent/definition';
import { getInitialFiltersValue } from '../../utility';
import { FilterSharedUtilities, FilterUtilities } from './definition';

export type { FilterSharedUtilities, FilterUtilities };

/**
 * @name useFilterUtilities
 * @description The hook that returns the filter utilities.
 *
 * @param inputConfig The input config to describe all filter inputs
 */
export const useFilterUtilities = <Data extends any[] = any[]>(
  inputConfig: FilterInputConfig<Data>,
): FilterUtilities<Data> => {
  const { filterDefaults, filterGroup } = inputConfig;

  return useMemo(() => {
    const defaultUtilities: FilterSharedUtilities<Data> = {
      getBreadcrumb: (filterValues: FilterValues) =>
        Object.entries(filterValues.applied).reduce<string[]>(
          (prev, [key, applied]) =>
            applied.length !== filterDefaults[key].length
              ? prev.concat(
                  applied
                    .filter((value: string) => value && value !== FilterOption.All)
                    .map(
                      (value: string) =>
                        inputConfig.items[key].getBreadcrumbItemLabel?.(value) ?? '',
                    ),
                )
              : prev,
          [],
        ),
      getInitialFiltersValue: () => getInitialFiltersValue(filterGroup, filterDefaults),
      onApplyFilter: (filterValues: FilterValues) => {
        filterValues.applied = filterValues.selected;
      },
      onFilterOpen: (filterValues: FilterValues) => {
        filterValues.selected = filterValues.applied;
      },
      onResetSelectedValues: (filterValues: FilterValues) => {
        filterValues.selected = filterDefaults;
      },
      filterData: (data, filterValues) =>
        data.filter((d) =>
          Object.values(inputConfig.items).reduce<boolean>(
            (prev, { filterType, compareFunction }) =>
              prev &&
              (!isEqual(filterValues.selected[filterType], filterDefaults[filterType]) // Filter is active
                ? compareFunction(filterValues, d, filterDefaults[filterType])
                : true),
            true,
          ),
        ) as Data,
    };

    return { ...defaultUtilities, ...{ filterDefaults, filterGroup } };
  }, [filterDefaults, filterGroup, inputConfig.items]);
};
