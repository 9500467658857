import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, HeaderGroup, Row, useTable } from 'react-table';
import Icon from 'shared/components/Icon';
import { InfoIcon } from 'shared/components/Labeled';
import { StyledTableWrapper } from 'shared/components/Table';
import { getClassName } from 'shared/utility';
import { TableType } from '../Table/style/definition';
import HeaderIcon from './components/HeaderIcon';
import defaultProps from './default';
import { RemovableTableProps as Props } from './definition';

const RemovableTable: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    actionIcon: icon,
    columns,
    data,
    noDataMessage,
    footer,
    header,
    rowActionHandler,
  }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const actionIcon = {
    isVisible: icon.isVisible ?? defaultProps.actionIcon.isVisible,
    color: icon.color ?? defaultProps.actionIcon.color,
    name: icon.name ?? defaultProps.actionIcon.name,
  };

  const colSpan = columns.length + (actionIcon.isVisible ? 1 : 0);
  const table = useTable({ columns, data });
  const { headerGroups, rows, getTableProps, getTableBodyProps, prepareRow } = table;

  return (
    <StyledTableWrapper
      hasActionIcon={actionIcon.isVisible}
      hasFooter={!!footer}
      type={TableType.Removable}
    >
      <div className="component-table-container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(({ headers, getHeaderGroupProps }: HeaderGroup) => (
              <tr {...getHeaderGroupProps()}>
                {headers.map(({ id, getHeaderProps, render }: HeaderGroup) => (
                  <th {...getHeaderProps()} colSpan={colSpan} {...getClassName(id)}>
                    {header?.icon && (
                      <HeaderIcon
                        name={header.icon.name}
                        color={header.icon.color}
                        size={header.icon.size}
                      />
                    )}
                    <span>{header?.text || render('Header')}</span>
                    {header?.info && <InfoIcon className="medium" isInline tooltip={header.info} />}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {data.length ? (
              rows.map((row: Row) => {
                prepareRow(row);
                const { cells, original, getRowProps }: Row = row;
                return (
                  <tr {...getRowProps()}>
                    {cells.map(({ column: { id }, getCellProps, render }: Cell) => (
                      <td {...getCellProps()} {...getClassName(id)}>
                        {render('Cell')}
                      </td>
                    ))}
                    {actionIcon.isVisible && (
                      <td>
                        <Icon
                          hasHover
                          name={actionIcon.name as string}
                          size={22}
                          themeColor={actionIcon.color}
                          onClick={() => rowActionHandler(original)}
                        />
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr role="row" className="is-empty">
                <td role="cell" colSpan={colSpan}>
                  <span>{noDataMessage ?? t('common:table.no_items_found')}</span>
                </td>
              </tr>
            )}
          </tbody>
          {!!footer && (
            <tfoot>
              <tr>
                <td colSpan={colSpan}>{footer.component}</td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </StyledTableWrapper>
  );
};

export default RemovableTable;
