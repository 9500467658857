import { FunctionComponent } from 'react';
import Box from 'shared/components/Box';
import MenuItem from 'shared/components/DropdownButton/components/MenuItem';
import Icon from 'shared/components/Icon';
import { MenuItemInfoProps as Props } from './definition';

const MenuItemInfo: FunctionComponent<Props> = ({ value }: Props): JSX.Element => (
  <MenuItem className="MuiMenuItem-info" data-test-id="shared-dropdown-button-menu-item-info">
    <Icon name="info-filled" size={18} />
    <Box component="span">{value}</Box>
  </MenuItem>
);

export default MenuItemInfo;
