import { useReducer } from 'react';

type State = {
  reviewDialog: boolean;
  warningDialog: boolean;
};

export enum Dialogs {
  SET_REVIEW,
  SET_WARNING,
  RESET_ALL,
}

const initialState: State = { reviewDialog: false, warningDialog: false };

const reducer = (state: State, action: Dialogs): State => {
  switch (action) {
    case Dialogs.SET_REVIEW:
      return { ...initialState, reviewDialog: true };
    case Dialogs.SET_WARNING:
      return { ...initialState, warningDialog: true };
    case Dialogs.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};
const useE911Dialogs = () => useReducer(reducer, initialState);
export default useE911Dialogs;
