import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';

const StyledSamlSsoLogin = styled(Box)`
  ${({ theme }) => css`
    padding: ${theme.spacing(0, 0, 3)};
    text-align: center;
  `};
`;

export default StyledSamlSsoLogin;
