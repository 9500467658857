import { TEMP_PROPERTY } from '../../../..';

export const buildPropName = (property: string, hasPrefix: boolean = true): string =>
  `${hasPrefix ? 'seat.' : ''}${TEMP_PROPERTY[property]}`;

export const buildFieldName = ({
  id,
  hasPrefix = false,
  isKey = false,
}: {
  [key: string]: boolean | string;
}): string => `${buildPropName('IS_IN_HERO_APPS', !!hasPrefix)}${!!isKey ? '-' : '.'}${id}`;
