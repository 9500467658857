import { joiResolver } from '@hookform/resolvers/joi';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { voicemailQueries } from 'models/Voicemail/Queries';
import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, VoicemailCallflowDialogProps as Props, VoicemailNodeData } from './definition';
import schema from './schema';
import VoicemailSelectWrapper from './VoicemailSelectWrapper';

const VoicemailDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, handleSubmit, formState, watch, reset } = useForm<Data>({
    mode: 'onChange',
    defaultValues,
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;

  const submitHandler = async (formData: Data) => {
    const data = { ...formData };
    const { id } = data;

    let name = '';

    if (!data.advanced_route) {
      data.var = '';
    }
    if (id !== '') {
      const fetchByIdResponse: any = await dispatch(
        voicemailQueries.endpoints.fetchVoicemailById.initiate({ id }),
      );
      name = fetchByIdResponse.data.name;
    }
    const nodeData: VoicemailNodeData = {
      metadata: {
        name,
        max_message_length: data.max_message_length,
        var: data.var,
        pvt_type: 'voicemail',
      },
      data,
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    const updatedData = enhancedFormUtility.transformDataToFormData(
      { ...data, advanced_route: Boolean(data?.var) },
      defaultValues,
    );

    reset(updatedData);
  }, [data, reset]);

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.voicemail.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <VoicemailSelectWrapper control={control} id={watch('id')} />
      {/* Max Voicemail Message Length */}
      <HookFormInputWrapper control={control} name="max_message_length">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.voicemail.max_message_length.label',
            )}
            inputProps={{
              type: 'number',
              ...formProps,
            }}
            inputWidth="small"
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
          />
        )}
      </HookFormInputWrapper>

      {/* Checkbox For Route Variable */}
      <HookFormInputWrapper control={control} name="advanced_route" isCheckbox>
        {({ ref, isDirty, ...formProps }) => (
          <LabeledCheckbox
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.voicemail.advanced_option',
            )}
            checkboxProps={{
              ...formProps,
            }}
            isDirty={isDirty}
            indentWidth="small"
            isCheckboxLeft
            hasMargin
          />
        )}
      </HookFormInputWrapper>
      {watch('advanced_route') && (
        // Route Variable
        <HookFormInputWrapper control={control} name="var">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              label={t(
                'phone_system:containers.callflows.callflow_action_dialog.voicemail.route_variable.label',
              )}
              inputProps={{
                ...formProps,
              }}
              isLabelAbove
              feedback={feedback}
              isDirty={isDirty}
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default VoicemailDialog;
