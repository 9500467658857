import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import { ResetButtonProps as Props } from './definition';

const ResetButton = ({ isVisible, onClick }: Props) => {
  const { t } = useTranslation();

  return isVisible ? (
    <Button
      classes={{ root: 'MuiButton-small' }}
      color="secondary"
      id="btn-filter-reset"
      variant="outlined"
      onClick={onClick}
    >
      {t('common:reset')}
    </Button>
  ) : (
    <></>
  );
};

export default ResetButton;
