import { useMemo } from 'react';
import TEST_ID from 'shared/utility/testing/constants/testId';
import DeviceFamily from './components/DeviceFamily';
import { DeviceListProps as Props } from './definition';
import StyledDeviceList from './style';

export type { Family as DeviceFamily } from './definition';

const DeviceList = ({ model = '', families, onClick }: Props) => {
  const sortedFamilies = useMemo(
    () => families.sort((a: any, b: any) => (a.label > b.label ? 1 : -1)),
    [families],
  );

  return families.length > 0 ? (
    <StyledDeviceList
      data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-usedevicepicker-dialog-device-list`}
    >
      {sortedFamilies.map(({ label, options }: any) => (
        <DeviceFamily
          key={`device-family-${label}`}
          label={label}
          model={model}
          models={options}
          onClick={onClick}
        />
      ))}
    </StyledDeviceList>
  ) : (
    <></>
  );
};

export default DeviceList;
