import { LOCAL_STORAGE } from 'constant';
import { useEffect, useState } from 'react';

const useHideOnlineAgents = () => {
  const [hasOnlineAgents, setHasOnlineAgents] = useState<boolean>(false);

  const onHideOnlineAgentClick = () => {
    setHasOnlineAgents((hasOnlineAgents: boolean) => {
      if (hasOnlineAgents) {
        localStorage.removeItem(LOCAL_STORAGE.HIDE.ONLINE_AGENTS);
      } else {
        localStorage.setItem(LOCAL_STORAGE.HIDE.ONLINE_AGENTS, 'true');
      }
      return !hasOnlineAgents;
    });
  };

  useEffect(() => {
    setHasOnlineAgents(localStorage.getItem(LOCAL_STORAGE.HIDE.ONLINE_AGENTS) === 'true');
  }, []);

  return { hasOnlineAgents, onHideOnlineAgentClick };
};

export default useHideOnlineAgents;
