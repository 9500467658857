import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  can_call_self: false,
  delay: 0,
  dial_strategy: 'simultaneous',
  id: '',
  strategy: 'simultaneous',
  timeout: '20',
  advanced: false,
  var: '',
};
