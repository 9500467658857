import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioProps } from 'shared/components/Radio';
import { FormContext } from '../../FormContext';
import { RadioGroupWrapperProps as Props } from './definition';

const RadioGroupWrapper: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { children, control, name, options } = props;
  const formContext = useFormContext();
  const { control: controlContext } = formContext || {};

  return (
    <FormContext.Consumer>
      {(formContext) => {
        const contextName = !formContext ? name : `${formContext}${name}`;

        return (
          <Controller
            control={control || controlContext}
            name={contextName}
            render={({ field: { value, onChange, ...rest }, fieldState: { isDirty } }: any) => {
              const _value = value ?? options[0].value;
              const currentSelection = options.find(
                (option: RadioProps) => option.value === _value,
              );
              const handleRadioChange = (selectedValue: RadioProps) => onChange(selectedValue);

              return children({
                isDirty,
                value: currentSelection.value,
                onChange: handleRadioChange,
                ...rest,
              });
            }}
          />
        );
      }}
    </FormContext.Consumer>
  );
};

export default RadioGroupWrapper;
