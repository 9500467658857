import { addressProvider } from 'shared/components/AddressAutoComplete/utility';
import { FormFields } from '../../definition';

export const FIELDS_COMPONENT_MAPPING = [
  { fieldName: FormFields.AddressCity, valueProvider: 'locality' },
  { fieldName: FormFields.AddressCountry, valueProvider: 'country' },
  {
    fieldName: FormFields.AddressLine1,
    valueProvider: addressProvider,
    type: 'street_address',
  },
  {
    fieldName: FormFields.AddressLine2,
    valueProvider: addressProvider,
    type: 'extended_address',
  },
  { fieldName: FormFields.AddressPostalCode, valueProvider: 'postal_code' },
  {
    fieldName: FormFields.AddressState,
    valueProvider: 'administrative_area_level_1',
    hasShortName: true,
  },
];
