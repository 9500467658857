import { useMemo } from 'react';
import { Tab } from '../../Tab';
import { isCompoundFieldDirty } from '../utility';
import { useGetHorizontalTabsParams, useGetHorizontalTabsResult } from './definition';

export const useGetHorizontalTabs = ({
  template,
  formProps,
  errors,
  dirtyFields,
}: useGetHorizontalTabsParams): useGetHorizontalTabsResult =>
  useMemo(() => {
    const tabs = Object.entries(template)
      .filter(([key, { iterate }]) => iterate !== 0) // Filter out tab with iterate equal to 0
      .map(([key, value]) => ({
        component: <Tab formProps={formProps} tabKey={key} template={value} />,
        key,
        label: value.text,
      }));

    return tabs.map((tab) => ({
      component: tab.component,
      hash: tab.key,
      isDirty: isCompoundFieldDirty(dirtyFields, tab.key),
      isError: isCompoundFieldDirty(errors, tab.key),
      label: tab.label,
    }));
  }, [dirtyFields, errors, formProps, template]);
