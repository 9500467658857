import { useCallback, useRef, useState } from 'react';
import { SaveAndRebootDialog as RawSaveAndRebootDialog } from './components/SaveAndRebootDialog';
import { SaveHandler } from './components/SaveAndRebootDialog/definition';

interface SaveAndRebootDialogProps {
  rebootHandler: () => Promise<void>;
}

interface useSaveAndRebootDialogParams {
  isRebootDisabled?: boolean;
  isReminderMode?: boolean;
}

export const useSaveAndRebootDialog = ({
  isRebootDisabled,
  isReminderMode,
}: useSaveAndRebootDialogParams) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const saveHandler = useRef<SaveHandler>(() => Promise.resolve());
  const isDialogEnabled = !isRebootDisabled;

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const setSaveHandler = (handler: SaveHandler) => {
    saveHandler.current = handler;
  };

  const onOpen = (handler: SaveHandler) => {
    if (isDialogEnabled) {
      setSaveHandler(handler);
      setIsOpen(true);
    }
  };

  const SaveAndRebootDialog = useCallback(
    ({ rebootHandler }: SaveAndRebootDialogProps) => (
      <RawSaveAndRebootDialog
        isOpen={isOpen}
        isReminderMode={isReminderMode}
        onClose={onClose}
        rebootHandler={rebootHandler}
        saveHandler={saveHandler.current}
      />
    ),
    [isOpen, onClose, isReminderMode],
  );

  const context: {
    SaveAndRebootDialog: (props: SaveAndRebootDialogProps) => JSX.Element;
    formSaveOverrideProps?: {
      onSaveOverride: (onSave: SaveHandler) => () => void;
    };
  } = {
    SaveAndRebootDialog,
  };

  if (isDialogEnabled) {
    context.formSaveOverrideProps = {
      onSaveOverride: (onSave) => () => {
        onOpen(onSave);
      },
    };
  }

  return context;
};
