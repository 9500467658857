import MuiBox, { BoxProps as Props } from '@mui/material/Box';
import { ForwardedRef, forwardRef } from 'react';

export type { BoxProps } from '@mui/material/Box';

const Box = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => (
  <MuiBox ref={ref} {...props} />
));

export default Box;
