import { FunctionComponent } from 'react';
import DashboardPanel from '../DashboardPanel';
import { DashboardPanelReportingProps as Props } from './definition';

const DashboardPanelReporting: FunctionComponent<Props> = (): JSX.Element => {
  return (
    <DashboardPanel type="reporting">
      <></>
    </DashboardPanel>
  );
};

export default DashboardPanelReporting;
