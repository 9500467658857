import PickNumbers from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow/components/PickNumbers';
import SearchForNumbers from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow/components/SearchForNumbers';
import WaitForServer from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow/components/WaitForServer';
import { RowArray } from 'apps/shared/definition';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteObject, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import defaultProps from './default';
import {
  BuyNumberFlowComponent,
  BuyNumberFlowProps as Props,
  BuyNumberFlowType,
  BuyNumbersFlowComponent,
} from './definition';
import { getFlowMeta } from './utility';

export { BuyNumberFlowAction, BuyNumberFlowType } from './definition';
export { default as meta } from './meta';
export { getFlowMeta };

const BuyNumberFlow = (props: Props) => {
  const {
    hasNoRedirectOnCancel,
    hasNoRedirectOnComplete,
    flowOnCompleteRoute,
    flowType,
    origin,
    onFlowComplete,
    setFlowType,
  } = { ...defaultProps, ...props };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const [, setErrorCount] = useState<number>(0);
  const [areaCode, setAreaCode] = useState<string>('');
  const [prefix, setPrefix] = useState<string>('');
  const [availableNumbers, setAvailableNumbers] = useState<RowArray>([]);
  const [selectedNumbers, setSelectedNumbers] = useState<RowArray>([]);
  const [flowMeta, setFlowMeta] = useState<Array<BuyNumbersFlowComponent>>([]);

  useEffect(() => {
    setAvailableNumbers([]);
    setSelectedNumbers([]);
    setFlowMeta(
      flowType === BuyNumberFlowType.Local
        ? getFlowMeta()[BuyNumberFlowType.Local]
        : getFlowMeta()[BuyNumberFlowType.TollFree],
    );
  }, [flowOnCompleteRoute, flowType]);

  /**
   * TODO: The states could be moved to router parameters to make it less
   * complicated. This component needs to be refactored.
   */
  const createBuyFlowState = () => {
    const metaData = flowMeta.find(
      ({ route }) => location.pathname.indexOf(route.default) > -1,
    ) as BuyNumbersFlowComponent;

    return {
      ...metaData,
      areaCode,
      availableNumbers,
      flowType,
      prefix,
      selectedNumbers,
      navigateToNext: () => navigate((metaData.route.next ?? flowOnCompleteRoute) as string),
      setAreaCode,
      setAvailableNumbers,
      setErrorCount,
      setPrefix,
      setSelectedNumbers,
    };
  };

  const flowState = useMemo(createBuyFlowState, [
    areaCode,
    availableNumbers,
    flowOnCompleteRoute,
    flowType,
    flowMeta,
    location.pathname,
    prefix,
    selectedNumbers,
    navigate,
  ]);

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    const { route } = flowState;
    switch (closeResponse.reason) {
      case 'cancelClicked':
        setSelectedNumbers([]);
        if (route.cancel) {
          navigate(route.cancel);
        } else {
          setAreaCode('');
          setPrefix('');
          setFlowType(BuyNumberFlowType.Default);
          if (origin) {
            navigate(origin);
          }
        }
        break;
      case 'saveClicked':
        navigate((route?.next ?? flowOnCompleteRoute) as string);
        break;
      default:
        // Handle any other cases here
        break;
    }
  };

  const routeMap: Array<RouteObject> = useMemo(
    () =>
      flowMeta.map(({ component, route }) => ({
        path: route.default,
        element: (
          <>
            {component === BuyNumberFlowComponent.Search && <SearchForNumbers {...flowState} />}
            {component === BuyNumberFlowComponent.Pick && <PickNumbers {...flowState} />}
            {component === BuyNumberFlowComponent.Activate && (
              <WaitForServer
                {...flowState}
                hasNoRedirectOnComplete={hasNoRedirectOnComplete}
                flowOnCompleteRoute={flowOnCompleteRoute}
                onFlowComplete={onFlowComplete}
                setFlowType={setFlowType}
              />
            )}
          </>
        ),
      })),
    [
      hasNoRedirectOnComplete,
      flowMeta,
      flowOnCompleteRoute,
      flowState,
      onFlowComplete,
      setFlowType,
    ],
  );

  const confirmButtonEnabled =
    (flowState.requiresAreaCode && areaCode.trim().length === 3) ??
    (flowState && selectedNumbers.length > 0);

  return (
    <Dialog
      hasTitle={flowState.component !== BuyNumberFlowComponent.Activate}
      open={flowType !== BuyNumberFlowType.Default && location.pathname !== flowOnCompleteRoute}
      renderActions={
        flowState.buttonLabel?.confirm ?? flowState.buttonLabel?.cancel ? (
          <DialogActions
            cancelLabel={flowState.buttonLabel?.cancel ?? t('common:cancel')}
            saveButtonProps={{
              color: 'primary',
              disabled: !confirmButtonEnabled,
              label: flowState.buttonLabel?.confirm ?? t('common:confirm'),
              variant: 'contained',
            }}
            onAction={handleDialogAction}
          />
        ) : (
          <></>
        )
      }
      title={flowState.title ?? ''}
      type={DialogType.Medium}
      onClose={() => {
        setAreaCode('');
        setPrefix('');
        if (hasNoRedirectOnCancel) {
          setFlowType(BuyNumberFlowType.Default);
        }
        if (origin) {
          navigate(origin);
        }
      }}
    >
      {useRoutes(routeMap)}
    </Dialog>
  );
};

export default BuyNumberFlow;
