// Directory/index.ts
import i18next from 'i18next';
import Joi from 'joi';
import omit from 'lodash/omit';
import { userMutations } from 'models/User/Mutations';
import { userQueries } from 'models/User/Queries';
import { messages } from 'shared/utility/validation';
import store from 'store';
import { directoryMutations } from './Mutations';
import { directoryQueries } from './Queries';

const DTMF_MAX = 2 ** 32 / 2 - 1;
const CONSTANTS = {
  INPUT: {
    MAX_DTMF: {
      MIN: 0,
      MAX: DTMF_MAX,
    },
    MIN_DTMF: {
      MIN: 1,
      MAX: DTMF_MAX,
    },
  },
};

export { CONSTANTS as DIRECTORY_CONSTANTS };

export const schema = () =>
  Joi.object({
    name: Joi.string().required().min(1),
    users: Joi.array().items(
      Joi.object({
        callflow_id: Joi.string(),
        user_id: Joi.string(),
      }),
    ),
    min_dtmf: Joi.number()
      .integer()
      .min(CONSTANTS.INPUT.MIN_DTMF.MIN)
      .max(CONSTANTS.INPUT.MIN_DTMF.MAX)
      .messages({
        'number.min': i18next.t('common:validation.general.number_min'),
        'number.max': i18next.t('common:validation.general.number_max_custom', {
          max: CONSTANTS.INPUT.MIN_DTMF.MAX,
        }),
      }),
    max_dtmf: Joi.number()
      .integer()
      .min(CONSTANTS.INPUT.MAX_DTMF.MIN)
      .max(CONSTANTS.INPUT.MAX_DTMF.MAX)
      .messages({
        'number.greater': i18next.t('common:validation.general.min_greater_max'),
        'number.max': i18next.t('common:validation.general.number_max_custom', {
          max: CONSTANTS.INPUT.MAX_DTMF.MAX,
        }),
      }),
    confirm_match: Joi.boolean(),
    search_fields: Joi.string().valid('both', 'first_name', 'last_name'),
    sort_by: Joi.string().valid('first_name', 'last_name'),
    id: Joi.string(),
  })
    .unknown(true)
    .messages(messages());

export const { useFetchDirectoriesQuery, useFetchDirectoryByIdQuery } = directoryQueries;
export const {
  useCreateDirectoryMutation,
  useUpdateDirectoryMutation,
  useDeleteDirectoryMutation,
} = directoryMutations;

export const addUserToDirectory = async (
  directoryId: string,
  userId: string,
  callflowId: string,
) => {
  const { data } = await store.dispatch(
    userQueries.endpoints.fetchUserById.initiate({ id: userId }),
  );

  const updatedDirectories = { ...data.directories } as any;
  updatedDirectories[directoryId] = callflowId;

  return store.dispatch(
    userMutations.endpoints.updateUser.initiate({
      id: userId,
      body: { ...data, directories: updatedDirectories },
    }),
  );
};

/**
 * Remove a user and the associated callflow from a directory. This is done by removing the directory-callflow
 * pair from the user's directory property
 *
 * @param directoryId
 * @param userId
 * @param callflowId
 */
export const removeUserFromDirectory = async (directoryId: string, userId: string) => {
  const { data } = await store.dispatch(
    userQueries.endpoints.fetchUserById.initiate({ id: userId }),
  );

  return store.dispatch(
    userMutations.endpoints.updateUser.initiate({
      id: userId,
      body: { ...data, directories: omit(data.directories, directoryId) },
    }),
  );
};
