import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { ADD_KEY } from 'constant';
import {
  schema,
  useDeleteVoicemailMutation,
  useFetchSharedVoicemailsQuery,
  useFetchVoicemailByIdQuery,
  useUpdateVoicemailMutation,
} from 'models/Voicemail';
import { FunctionComponent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import { defaultValues } from './default';
import { FormInput, VoicemailEditProps as Props } from './definition';
import Form from './Form';
import { transformDataForForm, transformVoicemailForSaving } from './utility';

const Edit: FunctionComponent<Props> = ({
  id,
  handleSaveSuccess,
  handleDeleteSuccess,
  handleCancel,
}: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();

  const { data } = useFetchVoicemailByIdQuery({ id });
  const { data: sharedData } = useFetchSharedVoicemailsQuery();
  const hasDelete = Boolean(id !== ADD_KEY && handleDeleteSuccess);

  const [updateVoicemail] = useUpdateVoicemailMutation();
  const [deleteVoicemail] = useDeleteVoicemailMutation();

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const {
    setError,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = formMethods;

  const isPageDirty = checkFormEntities(dirtyFields);

  // Populates form components once data is loaded.
  useEffect(
    () =>
      reset(enhancedFormUtility.transformDataToFormData(transformDataForForm(data), defaultValues)),
    [data, sharedData, reset],
  );

  const onSubmit: SubmitHandler<FormInput> = async (body) => {
    try {
      await updateVoicemail({ id, body: transformVoicemailForSaving(body) }).unwrap();
      handleSaveSuccess?.();
    } catch (exception) {
      showErrorMessage({ isFromException: true, errors: exception, setError });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteVoicemail({ id }).unwrap();
      handleDeleteSuccess?.();
    } catch (exception) {
      showToast.delete.error();
    }
  };

  return (
    <EditForm
      entityLabel={t('phone_system:containers.voicemails.label')}
      entityName={data?.name}
      onSave={handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }))}
      onDelete={hasDelete ? handleDelete : undefined}
      isPageDirty={isPageDirty}
      formMethods={formMethods}
      onCancel={handleCancel}
    >
      <Form />
    </EditForm>
  );
};

export default Edit;
