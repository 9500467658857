import { logout } from 'models/Auth';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import { LogoutDialogProps as Props } from './definition';

const LogoutDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isDialogOpen, setIsDialogOpen }: Props = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    setIsDialogOpen(false);
    if (closeResponse.reason === 'saveClicked') {
      logout();
      navigate('/login');
    }
  };

  return (
    <Dialog
      key="dialog-logout-confirmation"
      open={isDialogOpen}
      title={t('common:auth.logout.dialog.title')}
      onClose={() => setIsDialogOpen(false)}
      renderActions={
        <DialogActions
          saveButtonProps={{
            color: 'secondary',
            variant: 'contained',
            label: t('common:auth.logout.dialog.action'),
          }}
          onAction={handleDialogAction}
        />
      }
    >
      <span>{t('common:auth.logout.dialog.content')}</span>
    </Dialog>
  );
};

export default LogoutDialog;
