import { EditPanel } from 'apps/shared/components/StyledEditForm';
import { useExitConfirmationDialog } from 'apps/shared/hooks/useExitConfirmationDialog';
import {
  useCreateCallflowMutation,
  useDeleteCallflowMutation,
  useUpdateCallflowMutation,
} from 'models/Callflow';
import { useFetchFeatureCodesQuery } from 'models/FeatureCode';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from 'shared/components/Box';
import Loading from 'shared/components/Loading';
import Typography from 'shared/components/Typography';
import { useActionRow } from 'shared/hooks/useActionRow';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import FeatureCodeGroup, { FeatureCodeGroupName } from './components/FeatureCodeGroup';
import { FeatureCodeMeta, FeatureCodeName, FieldValue, FormInput } from './definition';
import StyledFeatureCodes from './style';
import {
  filterData,
  getDuplicateNumbers,
  getFieldNames,
  getToastMessage,
  isFeatureCodeDirty,
} from './utility';
import { getFeatureCodeCallflow, getFeatureCodeMeta } from './utility/featureCodes';

export { meta } from './meta';

const FeatureCodes = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { data, isLoading } = useFetchFeatureCodesQuery();
  const [duplicateNumbers, setDuplicateNumbers] = useState<Array<string>>([]);

  const [createCallflow] = useCreateCallflowMutation();
  const [updateCallflow] = useUpdateCallflowMutation();
  const [deleteCallflow] = useDeleteCallflowMutation();

  const formMethods = useForm<FormInput>();
  const {
    formState: { dirtyFields },
    handleSubmit,
    reset,
    setError,
    watch,
  } = formMethods;

  const isDirty = checkFormEntities(dirtyFields);
  useExitConfirmationDialog({ isDirty });

  const submit = {
    default: async (formData: any) => {
      try {
        const mutation: Array<Array<any>> = [[], [], []];

        Object.values(formData).forEach(({ is_enabled, id, name, number }: any) => {
          if (isFeatureCodeDirty(name, dirtyFields)) {
            if (is_enabled) {
              const body = getFeatureCodeCallflow(number)[name as FeatureCodeName];

              if (id) {
                mutation[1].push(updateCallflow({ id, body }));
              } else {
                mutation[2].push(createCallflow({ body }));
              }
            } else if (id) {
              mutation[0].push(deleteCallflow({ id }));
            }
          }
        });

        Promise.all(mutation.map((action) => Promise.all(action)))
          .then((response: any) => {
            const responses: any = [].concat(...response).filter((res: any) => 'error' in res);
            if (responses.length > 0) {
              const errors = responses.reduce((acc: Array<string>, value: any) => {
                acc.push(
                  t('phone_system:containers.feature_codes.error.swap.number', {
                    number: value.error.data.numbers.unique.cause,
                  }),
                );
                return acc;
              }, []);

              showToast.error(
                getToastMessage({
                  errors,
                  intro: t('common:error'),
                  outro: t('phone_system:containers.feature_codes.error.swap.outro'),
                }),
              );
            } else {
              showToast.success();
            }
          })
          .catch((errors) => {
            showErrorMessage({ errors, setError });
          });
      } catch (exception) {
        showErrorMessage({ isFromException: true, errors: exception, setError });
      }
    },
    error: (errors: any) => showErrorMessage({ errors, setError }),
  };

  const on = {
    cancel: () => navigate('..'),
    change: {
      input: (value: Record<string, string>) => {
        setDuplicateNumbers(getDuplicateNumbers(watch(), value));
      },
    },
    save: handleSubmit(submit.default, submit.error),
  };

  const { ActionRow, actionRowProps } = useActionRow({
    hasDelete: false,
    hasSave: true,
    isDirty: isDirty && duplicateNumbers.length === 0,
    breadcrumbData: [
      {
        text: t('phone_system:containers.feature_codes.label'),
      },
    ],
    onCancel: on.cancel,
    onSave: on.save,
  });

  useEffect(() => {
    const objData = filterData(data);
    const featureCodeMeta = getFeatureCodeMeta();
    const resetData: Record<string, any> = {};

    Object.entries(featureCodeMeta).forEach(([name, value]: Array<string | FeatureCodeMeta>) => {
      const featureCode = objData?.[name as FeatureCodeName];
      const { defaultValue, label } = value as FeatureCodeMeta;
      const { key } = getFieldNames(label);

      resetData[key] = {
        [FieldValue.Name]: name,
        [FieldValue.IsEnabled]: Boolean(featureCode),
        [FieldValue.Number]: featureCode?.featurecode?.number ?? defaultValue.toString(),
        ...(featureCode?.id ? { [FieldValue.Id]: featureCode.id } : {}),
      };
    });

    reset(resetData);
  }, [data, reset]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...formMethods}>
      <Box>
        <ActionRow {...actionRowProps} />
        <EditPanel isFeatureCodesPanel>
          <StyledFeatureCodes>
            {duplicateNumbers.length > 0 && (
              <Box role="row">
                <Typography>
                  {t('phone_system:containers.feature_codes.text.duplicate_numbers.0', {
                    count: duplicateNumbers.length,
                    numbers: duplicateNumbers.join(', '),
                  })}
                </Typography>
              </Box>
            )}
            <Box role="row">
              <FeatureCodeGroup
                name={FeatureCodeGroupName.CallForward}
                onChange={on.change.input}
              />
              <FeatureCodeGroup
                name={FeatureCodeGroupName.DoNotDisturb}
                onChange={on.change.input}
              />
            </Box>
            <Box role="row">
              <FeatureCodeGroup name={FeatureCodeGroupName.FollowMe} onChange={on.change.input} />
              <FeatureCodeGroup name={FeatureCodeGroupName.HotDesking} onChange={on.change.input} />
            </Box>
            <Box role="row">
              <FeatureCodeGroup name={FeatureCodeGroupName.Misc} onChange={on.change.input} />
              <FeatureCodeGroup name={FeatureCodeGroupName.Parking} onChange={on.change.input} />
            </Box>
          </StyledFeatureCodes>
        </EditPanel>
      </Box>
    </FormProvider>
  );
};

export default FeatureCodes;
