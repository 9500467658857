import TableCellShareIcon from 'apps/PhoneSystem/shared/TableCellSharedIcon';
import { useFetchSMSQuery } from 'models/SMS';
import { getFullNameFromUserListByUserId, useFetchUsersQuery } from 'models/User';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import Table, {
  Cell,
  Justify,
  SHARE_COLUMN_KEYWORD,
  TABLE_CONSTANTS,
} from 'shared/components/Table';

const List = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchSMSQuery();
  const { data: usersData, isLoading: isLoadingUsers } = useFetchUsersQuery();

  const columns = useMemo(
    () => [
      {
        Header: t('phone_system:containers.sms.table.column.shared_box'),
        Cell: ({ value }: Cell<JSX.Element>) => <TableCellShareIcon data={data} id={value} />,
        accessor: 'id',
        id: `sms.${SHARE_COLUMN_KEYWORD}.${Justify.Center}`,
        disableSortBy: true,
        width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
      },
      {
        Header: t('phone_system:containers.seats.sms.table.column.assigned_to.header'),
        accessor: ({ owner }: any) => getFullNameFromUserListByUserId(owner, usersData),
        id: 'owner',
        width: 600,
      },
      {
        Header: t('phone_system:containers.seats.sms.table.column.sms_number.header'),
        accessor: ({ numbers }: any) => numbers[0],
      },
    ],
    [data, usersData, t],
  );

  if (isLoading || isLoadingUsers) {
    return <Loading />;
  }

  return (
    <Table
      hasSearch
      columns={columns}
      data={data ?? []}
      initialState={{ sortBy: { id: 'owner' } }}
      title={t('phone_system:containers.sms.table.title')}
    />
  );
};

export default List;
