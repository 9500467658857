import { DEFAULT_TIMEZONE_OPTION_VALUE } from 'constant';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LabeledSelectTimeZone } from 'shared/components/Labeled';
import { OptionTypeBase } from 'shared/components/Select/definition';
import TimeZoneMapPickerDialog from 'shared/components/TimeZoneMapPickerDialog';
import { FormContext } from '../../FormContext';
import defaultProps from './default';
import { TimeZoneSelectWrapperProps as Props } from './definition';

const TimeZoneSelectWrapper = (props: Props) => {
  const { hasAccountDefault, hasDefaultOptionUndefined, control, id, label, name, tooltip } = {
    ...defaultProps,
    ...props,
  };
  const formContext = useFormContext();
  const { control: controlContext } = formContext ?? {};

  const [isMapDialogOpen, setIsMapDialogOpen] = useState<boolean>(false);

  const onMapToggleClick = () => {
    setIsMapDialogOpen(true);
  };

  const onMapPickerDialogClose = () => {
    setIsMapDialogOpen(false);
  };

  return (
    <FormContext.Consumer>
      {(formContext) => {
        const contextName = formContext ? `${formContext}${name}` : name;

        return (
          <Controller
            control={control ?? controlContext}
            name={contextName}
            render={({ field: { onChange, value, ...rest }, fieldState: { isDirty } }: any) => {
              const onMapPickerDialogSave = ({
                timeZoneCityName,
              }: {
                timeZoneCityName: string;
              }) => {
                if (timeZoneCityName) {
                  onChange(timeZoneCityName);
                }
              };

              const onSelectChange = (selectedOption: OptionTypeBase) => {
                if (hasDefaultOptionUndefined) {
                  onChange(selectedOption?.value);
                } else {
                  onChange(
                    selectedOption?.value === ''
                      ? DEFAULT_TIMEZONE_OPTION_VALUE
                      : selectedOption?.value,
                  );
                }
              };

              return (
                <>
                  <LabeledSelectTimeZone
                    hasAccountDefault={hasAccountDefault}
                    hasDefaultOptionUndefined={hasDefaultOptionUndefined}
                    isDirty={isDirty}
                    id={id}
                    label={label}
                    selectProps={{
                      value: !hasDefaultOptionUndefined && !value ? '' : value,
                      onChange: onSelectChange,
                      ...rest,
                    }}
                    tooltip={tooltip}
                    onMapToggleClick={onMapToggleClick}
                  />
                  <TimeZoneMapPickerDialog
                    onClose={onMapPickerDialogClose}
                    onSave={onMapPickerDialogSave}
                    open={isMapDialogOpen}
                  />
                </>
              );
            }}
          />
        );
      }}
    </FormContext.Consumer>
  );
};

export default TimeZoneSelectWrapper;
