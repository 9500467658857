import { themeColor } from '../..';
import theme from '../core';

export const CONSTANTS = {
  HEIGHT: {
    INDICATOR: 5,
    TAB: 50,
    get TOTAL() {
      return CONSTANTS.HEIGHT.INDICATOR + CONSTANTS.HEIGHT.TAB;
    },
  },
};

export default {
  styleOverrides: {
    root: {
      height: CONSTANTS.HEIGHT.TOTAL,
      minHeight: CONSTANTS.HEIGHT.TOTAL,
      '.MuiTabs-scroller': {
        '.MuiTabs-indicator': {
          display: 'inline-block',
          width: '80%',
          height: CONSTANTS.HEIGHT.INDICATOR,
          backgroundColor: themeColor.whitelabel.primary.color,
          borderRadius: CONSTANTS.HEIGHT.INDICATOR / 2,
        },
      },
      '.MuiTabScrollButton-horizontal': {
        width: theme.spacing(5),
        '&:not(.Mui-disabled)': {
          opacity: 1,
        },
        '.MuiSvgIcon-root': {
          width: 32,
          height: 32,
          transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.standard,
          }),
          '&:hover': {
            color: themeColor.whitelabel.primary.color,
          },
        },
      },
    },
    vertical: {
      position: 'relative',
      height: 'auto',
      minHeight: 'auto,',
      borderBottom: 'none',
      zIndex: 10,
      '.MuiTabs-scroller': {
        '.MuiButtonBase-root': {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          position: 'relative',
          width: 200,
          minWidth: 0,
          minHeight: 42,
          margin: 0,
          padding: theme.spacing(0.5, 2, 0.5, 4),
          color: theme.palette.common.black,
          fontSize: theme.typography.fontSize,
          fontWeight: 600,
          letterSpacing: 0,
          lineHeight: 1.3,
          opacity: 1,
          textAlign: 'left',
          textTransform: 'none',
          transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.standard,
          }),
          userSelect: 'none',
          zIndex: 10,
          '&:not(.Mui-selected):hover': {
            color: themeColor.whitelabel.primary.color,
          },
          '&.Mui-selected': {
            color: theme.palette.common.black,
            fontWeight: 700,
            letterSpacing: '-0.0125em',
          },
        },
        '.MuiTabs-indicator': {
          display: 'block',
          width: 200,
          padding: theme.spacing(0, 0, 0, 1.33),
          borderRadius: '3px 0 0 3px',
          backgroundColor: themeColor.core.background.grey.light.color,
          border: `1px solid ${themeColor.core.border.color}`,
          borderRight: 'none',
          '&::before': {
            content: '""',
            display: 'block',
            width: CONSTANTS.HEIGHT.INDICATOR,
            height: 32,
            backgroundColor: themeColor.whitelabel.primary.color,
            borderRadius: CONSTANTS.HEIGHT.INDICATOR / 2,
            transform: 'translateY(4px)',
          },
        },
      },
    },
  },
};
