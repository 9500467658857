import { useMemo } from 'react';
import { BridgeNumberChangeAction, UseUpsertBridgeCallflowParam } from '../../definition';
import useCreateBridgeCallflow from './useCreateBridgeCallflow';
import useDeleteBridgeCallflow from './useDeleteBridgeCallflow';
import useUpdateBridgeCallflow from './useUpdateBridgeCallflow';

const useUpsertBridgeCallflow = ({
  createBridgeCallflow,
  updateBridgeCallflow,
  deleteBridgeCallflow,
  action = BridgeNumberChangeAction.Unmodified,
  initialNumber,
  bridgeNumber,
}: UseUpsertBridgeCallflowParam) => {
  const defaultCreateBridgeCallflow = useCreateBridgeCallflow(bridgeNumber);
  const defaultDeleteBridgeCallflow = useDeleteBridgeCallflow(initialNumber || '');
  const defaulUpdateBridgeCallflow = useUpdateBridgeCallflow(initialNumber, bridgeNumber);

  const operation = useMemo(() => {
    const operations: {
      [key in BridgeNumberChangeAction]: () => Promise<any>;
    } = {
      [BridgeNumberChangeAction.Assign]: createBridgeCallflow || defaultCreateBridgeCallflow,
      [BridgeNumberChangeAction.Unassign]: deleteBridgeCallflow || defaultDeleteBridgeCallflow,
      [BridgeNumberChangeAction.Switch]: updateBridgeCallflow || defaulUpdateBridgeCallflow,
      [BridgeNumberChangeAction.Unmodified]: () => Promise.resolve(),
    };

    return operations[action];
  }, [
    action,
    createBridgeCallflow,
    defaulUpdateBridgeCallflow,
    defaultCreateBridgeCallflow,
    defaultDeleteBridgeCallflow,
    deleteBridgeCallflow,
    updateBridgeCallflow,
  ]);

  return operation;
};

export default useUpsertBridgeCallflow;
