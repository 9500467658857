import Box from 'shared/components/Box';
import { WindowedMenuListProps as Props } from './definition';
import StyledWindowedMenuList from './style';
import { getHeight } from './utility';

export const WindowedMenuList = ({ children, maxHeight }: Props) => {
  if (Array.isArray(children)) {
    const itemCount = children.length;
    return (
      <StyledWindowedMenuList height={getHeight(itemCount, maxHeight)} itemCount={itemCount}>
        {({ index, style }) => <Box style={style}>{children[index]}</Box>}
      </StyledWindowedMenuList>
    );
  }

  return children;
};
