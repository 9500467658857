import AppIcon from 'apps/shared/components/AppIcon';
import truncate from 'lodash/truncate';
import { useNavigate } from 'react-router-dom';
import Box from 'shared/components/Box';
import { maybeTooltipComponent } from 'shared/utility';
import { CONSTANTS } from './constant';
import { AppLinkProps as Props } from './definition';
import StyledAppLink from './style';

const AppLink = ({ isCurrent, meta, setIsOpen }: Props) => {
  const {
    icon = '',
    i18n: { label },
    slug,
    url,
    onClick: onClickOverride,
  } = meta;
  const navigate = useNavigate();
  const truncatedLabel = truncate(label, { length: CONSTANTS.LABEL.LENGTH.MAX });

  let onClick = () => {
    if (url) {
      window.open(url, '_blank');
    } else {
      navigate(`/apps/${slug}`);
    }
  };

  if (onClickOverride) {
    onClick = onClickOverride.bind(meta);
  }

  return (
    <StyledAppLink
      $isCurrent={isCurrent}
      onClick={() => {
        onClick();
        setIsOpen(false);
      }}
    >
      {icon && label && (
        <>
          <AppIcon name={icon} size={CONSTANTS.ICON.SIZE} />
          {maybeTooltipComponent({
            component: <Box component="span">{truncatedLabel}</Box>,
            tooltip: label !== truncatedLabel ? label : undefined,
          })}
        </>
      )}
    </StyledAppLink>
  );
};

export default AppLink;
