import { iamMutations } from './Mutations';
import { iamQueries } from './Queries';

export const { useFetchIdpQuery, useLazyFetchIdpQuery } = iamQueries;

export const {
  useCreateIdpMutation,
  useDeleteIdpMutation,
  useUpdateIdpMutation,
  useEnableSSOMutation,
} = iamMutations;
