import { Fields as CallRestrictionsFields } from 'apps/PhoneSystem/components/CallRestrictionsSection/definition';
import AudioVideoSection, { fields as audioVideoFields } from './AudioVideoSection';
import BasicsSection, { fields as basicsFields } from './BasicsSection';
import CallerIdSection, { fields as callerIdFields } from './CallerIdSection';
import OptionsSection, { fields as optionsFields } from './OptionsSection';
import SIPSection, { fields as sipFields } from './SIPSection';

const fields = {
  audioVideo: audioVideoFields,
  basics: basicsFields,
  callerId: callerIdFields,
  callRestrictions: CallRestrictionsFields,
  options: optionsFields,
  sip: sipFields,
};

const Section = {
  AudioVideo: AudioVideoSection,
  Basics: BasicsSection,
  CallerId: CallerIdSection,
  Options: OptionsSection,
  SIP: SIPSection,
};

export { fields, Section };
