import { APPS_CONSTANTS } from 'apps';
import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const enum Breakpoint {
  LARGE = '1024px',
  MEDIUM = '768px',
}

const StyledLogin = styled(Box)`
  ${(props) => css`
    ${mixin.flex({ direction: 'column', justifyContent: 'flex-start' })};
    min-width: ${Breakpoint.MEDIUM};
    height: 100vh;
    background-color: ${props.theme.whitelabel.primary.color};

    @media (min-width: ${Breakpoint.LARGE}) {
      ${mixin.flex()};
    }

    > div {
      position: relative;
      width: 100%;

      &:first-of-type {
        height: 200px;
        background-color: ${props.theme.whitelabel.secondary.color};

        @media (min-width: ${Breakpoint.LARGE}) {
          width: 57%;
          height: 100vh;
        }

        &::after {
          content: '';
          position: absolute;
          top: 140px;
          left: 0;
          width: 100%;
          height: 240px;
          background-color: inherit;
          transform: skew(0, -6deg);
          z-index: 0;

          @media (min-width: ${Breakpoint.LARGE}) {
            top: auto;
            left: auto;
            right: -120px;
            width: 240px;
            height: inherit;
            transform: skew(-6deg);
          }
        }
      }

      &:nth-of-type(2) {
        height: auto;

        @media (min-width: ${Breakpoint.LARGE}) {
          width: 43%;
          height: 100vh;
        }
      }

      &:last-of-type {
        position: absolute;
        right: ${APPS_CONSTANTS.GAP};
        bottom: ${APPS_CONSTANTS.GAP};
        width: fit-content;
      }
    }
  `}
`;

export default StyledLogin;
