import MoveToDropdownButton from 'apps/shared/components/MoveToDropdownButton';
import { RowArray, SetRowArray } from 'apps/shared/definition';
import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Trans } from 'react-i18next';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import { maybeTooltipActionRowButton } from 'shared/hooks/useActionRow/utility';
import { ChildAccount } from './definition';

/**
 * @name columns
 * @description Get the list of columns for the spare numbers table.
 *
 * @returns List of columns.
 */
export const columns = () => [
  {
    Header: i18next.t('numbers:containers.spare_numbers.table.column.number'),
    accessor: 'id',
  },
];

/**
 * @name buildActionRowButtons
 * @description Build the ActionRow buttons (element).
 *
 * @param [object]
 * @property hasMove
 * @property accountData,
 * @property selectedRows,
 * @property setConfirmData,
 * @property setIsDialogDelete,
 * @property setMoveTarget,
 *
 * @returns ActionRow buttons element.
 */
export const buildActionRowButtons = ({
  hasMove,
  accountData,
  selectedRows,
  setConfirmData,
  setIsDialogDelete,
  setMoveTarget,
}: {
  hasMove: boolean;
  accountData: Array<ChildAccount>;
  selectedRows: RowArray;
  setConfirmData: SetRowArray;
  setIsDialogDelete: (newValue: boolean) => void;
  setMoveTarget: (newTarget: ChildAccount) => void;
}): JSX.Element => {
  const hasSelectedRows = selectedRows.length > 0;

  return (
    <>
      {maybeTooltipActionRowButton({
        hasSelectedRows,
        component: (
          <Button
            color={hasSelectedRows ? 'error' : 'primary'}
            disabled={!hasSelectedRows}
            variant="outlined"
            startIcon={<Icon name="close" size={18} />}
            onClick={() => {
              setConfirmData(selectedRows);
              setIsDialogDelete(true);
            }}
          >
            {i18next.t('common:delete')}
          </Button>
        ),
        tooltip: i18next.t('numbers:containers.spare_numbers.delete.disabled_tooltip'),
      })}
      {hasMove && (
        <MoveToDropdownButton
          hasSelectedRows={hasSelectedRows}
          items={accountData.map((account: ChildAccount) => ({
            label: account.name,
            value: account.id,
            onClick: () => {
              setConfirmData(selectedRows);
              setMoveTarget(account);
              setIsDialogDelete(false);
            },
          }))}
          tooltip={i18next.t('numbers:containers.spare_numbers.move.disabled_tooltip')}
        />
      )}
    </>
  );
};

/**
 * @name getDialogProps
 * @description Get the delete/move delete confirmation dialog props.
 *
 * @param [object]
 * @property isDelete,
 * @property accountName,
 * @property confirmData,
 * @property onDelete,
 * @property onMove,
 * @property setConfirmData,
 *
 * @returns Dialog props.
 */
export const getDialogProps = ({
  isDelete,
  accountName,
  confirmData,
  on,
  setConfirmData,
}: {
  isDelete: boolean;
  accountName: Record<string, string>;
  confirmData: RowArray;
  on: Record<string, (param: any) => void>;
  setConfirmData: SetRowArray;
}) => ({
  isDelete,
  isOpen: !isEmpty(confirmData),
  confirmData,
  dialogMessage: (
    <Box component="span">
      {isDelete ? (
        i18next.t('numbers:containers.spare_numbers.delete.confirm.message', {
          count: confirmData.length,
        })
      ) : (
        <Trans
          components={{ bold: <strong /> }}
          i18nKey="numbers:containers.spare_numbers.move.confirm.message"
          values={{
            accountNameFrom: accountName.from,
            accountNameTo: accountName.to,
            count: confirmData.length,
          }}
        />
      )}
    </Box>
  ),
  dialogTitle: isDelete
    ? i18next.t('numbers:containers.spare_numbers.delete.confirm.title')
    : i18next.t('numbers:containers.spare_numbers.move.confirm.title'),
  header: {
    text: accountName.from ? (
      <>
        {i18next.t('common:from')} <strong>{accountName.from}</strong>
      </>
    ) : undefined,
  },
  onConfirm: isDelete ? on.delete : on.move,
  setConfirmData,
});
