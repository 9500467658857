import { checkFormEntitiesFromFields } from 'apps/CallCenter/utils';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import { useCallRestrictionsSection } from 'apps/shared/hooks/useCallRestrictionsSection';
import useTabs from 'apps/shared/hooks/useTabs';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fields, Section } from '../../../components/section';

const Form = () => {
  const { t } = useTranslation();
  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const { component: callbacksSection, fields: callbacksFields } = useCallRestrictionsSection({
    hasCallbacksEnabled: true,
    fields: fields.callbacks,
  });

  const items = {
    basics: {
      isDirty: checkFormEntitiesFromFields(dirtyFields, fields.basic),
      isError: checkFormEntitiesFromFields(errors, fields.basic),
      component: <Section.Basics />,
      hash: 'basics',
      label: t('call_center:containers.manage_queues.sections.basic'),
    },
    callbacks: {
      isDirty: checkFormEntitiesFromFields(dirtyFields, callbacksFields),
      isError: checkFormEntitiesFromFields(errors, callbacksFields),
      component: callbacksSection,
      hash: 'callbacks',
      label: t('call_center:containers.manage_queues.sections.callbacks'),
    },
    notifications: {
      isDirty: checkFormEntitiesFromFields(dirtyFields, fields.notifications),
      isError: checkFormEntitiesFromFields(errors, fields.notifications),
      component: <Section.Notifications />,
      hash: 'notifications',
      label: t('call_center:containers.manage_queues.sections.notifications'),
    },
  };

  const { Tabs, TabPanels } = useTabs({ items: Object.values(items) });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Form;
