import { getTimezoneLabelsWithDst } from 'shared/utility/date/index';
import { citiesToTimeZoneMap } from '../data/citiesToTimeZonesMap';

export const getCitiesToTimezoneWithDstMap = () =>
  Object.entries(citiesToTimeZoneMap).reduce<any>((citiesToTimeZoneMapWithDst, zone) => {
    const { name, label } = getTimezoneLabelsWithDst(zone);

    citiesToTimeZoneMapWithDst[name] = label;
    return citiesToTimeZoneMapWithDst;
  }, {});
