import isEmpty from 'lodash/isEmpty';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogType } from 'shared/components/Dialog';
import Loading from 'shared/components/Loading';
import defaultProps from '../../default';
import { CallFlowActionsDialogProps as Props } from '../../definition';
import StyledCallflowActionsDialog from '../../style';

const CallflowActionsDialog: FunctionComponent<Props> = (props: Props) => {
  const { isLoading, children, data, title, type, handleClose } = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();

  return (
    <StyledCallflowActionsDialog
      open
      renderActions={
        <DialogActions
          saveButtonProps={{
            color: 'secondary',
            label: isEmpty(data) ? t('common:add') : t('common:update'),
          }}
          onAction={handleClose}
        />
      }
      title={title}
      type={type as DialogType}
      onClose={handleClose}
    >
      {isLoading ? <Loading /> : children}
    </StyledCallflowActionsDialog>
  );
};

export default CallflowActionsDialog;
