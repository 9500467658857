import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledDeviceFamilyProps as Props } from './definition';

const StyledDeviceFamily = styled(Box)<Props>`
  ${({ theme }) => css`
    h2 {
      ${mixin.font({ size: 16, weight: 600 })};
      width: 100%;
      margin: ${theme.spacing(5, 0, 2.5)};
      padding: ${theme.spacing(0, 0, 2)};
      border-bottom: 1px solid ${theme.core.input.border.default};
      user-select: none;
    }

    ul {
      ${mixin.flex({
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: theme.spacing(1),
        wrap: 'wrap',
      })};
    }

    &:first-of-type h2 {
      margin-top: ${theme.spacing(1)};
    }
  `};
`;

export default StyledDeviceFamily;
