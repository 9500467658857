import { Flipped, FlippedType } from './definition';

export const buildClasses = (isFlipped: Flipped): Record<string, string> => {
  const CLASS = {
    FLIPPED: 'MuiSvgIcon-flipped',
  };
  const className = [];

  if (Object.values(isFlipped).some((value: boolean) => value)) {
    className.push(CLASS.FLIPPED);
    Object.values(FlippedType).forEach((type: FlippedType) => {
      if (isFlipped[type]) {
        className.push(`${CLASS.FLIPPED}-${type}`);
      }
    });
  }

  return { root: className.join(' ') };
};
