import {
  metaAccountsManager,
  metaAdvancedProvisioner,
  metaAdvancedReporting,
  metaBulkManager,
  metaCallCenter,
  metaCallLogs,
  metaKeyman,
  metaMsTeams,
  metaNumbers,
  metaPhoneSystem,
  metaQStats,
  metaSipTrunking,
  metaVQMon,
} from 'apps/meta';
import { KOSHER_APPS } from '../shared/constant';
import { AppMeta, KosherApp } from '../shared/definition';

export { CONSTANTS as APPS_CONSTANTS, style } from '../style';

export const getApps = (storeData: any, language: string) => {
  const implementedApps = [
    metaAccountsManager,
    metaAdvancedProvisioner,
    metaAdvancedReporting,
    metaBulkManager,
    metaCallLogs,
    metaCallCenter,
    metaKeyman,
    metaMsTeams,
    metaNumbers,
    metaPhoneSystem,
    metaQStats,
    metaSipTrunking,
    metaVQMon,
  ];

  const enabledApps = storeData
    .filter(({ name }: { name: string }) =>
      KOSHER_APPS.some((app: KosherApp) => name === app.name && app.isEnabled),
    )
    .map((app: any) => {
      const filteredMeta = implementedApps.find((meta: AppMeta) => meta.name === app.name);

      if (filteredMeta && !filteredMeta.i18n) {
        filteredMeta.i18n = app.i18n[language];
      }

      return filteredMeta;
    })
    .sort((a: any, b: any) => (a.i18n.label > b.i18n.label ? 1 : -1));

  const redirectApps = implementedApps.filter(
    (meta: AppMeta) => !enabledApps.find((enabledApp: KosherApp) => enabledApp.name === meta.name),
  );

  return { enabledApps, redirectApps };
};
