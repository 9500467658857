import { useUpdateCallflowMutation } from 'models/Callflow';
import { useCallback } from 'react';
import { getBridgeCallflowData } from '../../utility';
import useLazyFetchBridgeCallflow from '../useFetchBridgeCallflow';

const useUpdateBridgeCallflow = (initialNumber?: string, bridgeNumber?: string) => {
  const [updateCallflow] = useUpdateCallflowMutation();
  const fetchBridgeCallflow = useLazyFetchBridgeCallflow(initialNumber || '');

  const updateBridgeCallflow = useCallback(async () => {
    const bridgeCallflow = await fetchBridgeCallflow();

    return updateCallflow({
      id: bridgeCallflow?.id,
      body: getBridgeCallflowData(bridgeNumber),
    }).unwrap();
  }, [bridgeNumber, fetchBridgeCallflow, updateCallflow]);

  return updateBridgeCallflow;
};

export default useUpdateBridgeCallflow;
