import { REGEX } from 'constant';
import { onSearchParam, ShouldSearchSubaccountDeviceParam } from './defintion';

export const shouldSearchSubaccountDevice = ({
  data,
  keyword,
}: ShouldSearchSubaccountDeviceParam) =>
  REGEX.MAC_ADDRESS.test(keyword) &&
  !data?.some((d) => d.mac_address.toLowerCase() === keyword.toLowerCase());

/**
 * @description To initiate a remote search for subaccount devices by mac address when indicated.
 * */
export const onSearchSubaccountDevice = async ({
  fetchSubaccountDeviceQuery,
  matchedSubaccountId,
  setMatchedSubaccountId,
  shouldSearchSubaccountDevice,
  keyword,
}: onSearchParam) => {
  // Searching subaccounts devices by mac address if devices in current account does not match
  if (shouldSearchSubaccountDevice) {
    const { data: { account_id = '' } = {} } =
      (await fetchSubaccountDeviceQuery({
        macAddress: keyword,
      })) ?? {};
    if (account_id) {
      setMatchedSubaccountId(account_id);
    } else {
      setMatchedSubaccountId(undefined);
    }
  } else if (matchedSubaccountId) {
    setMatchedSubaccountId(undefined);
  }
};
