import 'draft-js/dist/Draft.css';
import { FunctionComponent } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled, { css } from 'styled-components';
import { StyledRichTextViewerProps as Props } from './definition';

const StyledEditor: FunctionComponent<Props> = (props: Props) => {
  return (
    <div className={props.className}>
      <Editor {...props} />
    </div>
  );
};

const StyledRichTextViewer = styled(StyledEditor)`
  ${(props) => css`
    .DraftEditor-root {
      margin-top: -1em;
    }
  `};
`;

export default StyledRichTextViewer;
