import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { columnize } from 'apps/shared/utility';
import { BaseSyntheticEvent, FunctionComponent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from 'shared/components/Labeled';
import { TEMP_PROPERTY } from '../../../constant';
import { SectionProps as Props } from '../definition';
import AppCheckbox from './components/AppCheckbox';
import { EnableAllState } from './definition';
import { getStateValue } from './utility';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields: string[] = [TEMP_PROPERTY.IS_IN_APPS_STORE];

const AppExchangeSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const [enableAllState, setEnableAllState] = useState<EnableAllState>('none');
  const [appsStoreIsEnabled, setAppsStoreIsEnabled] = useState<{ [key: string]: boolean }>({});
  const dataAppsStore: { [key: string]: any } = {
    display: getValues(TEMP_PROPERTY.APPS_STORE),
    isEnabled: getValues(TEMP_PROPERTY.IS_IN_APPS_STORE),
  };

  useEffect(() => {
    if (dataAppsStore.display) {
      setAppsStoreIsEnabled((appsStoreIsEnabled: any) => {
        // create object to track the enabled state of each app
        const isEnabled = dataAppsStore.display.reduce((acc: any, app: any) => {
          acc[app.id] = dataAppsStore.isEnabled[app.id];
          return acc;
        }, {});

        setEnableAllState(getStateValue(isEnabled));

        return isEnabled;
      });
    }
  }, [dataAppsStore.display]);

  const handleAppClick = (e: BaseSyntheticEvent, id: string): void => {
    setValue(`${TEMP_PROPERTY.IS_IN_APPS_STORE}.${id}`, e.target.checked, { shouldDirty: true });

    setAppsStoreIsEnabled((appsStoreIsEnabled: any) => {
      // update object which tracks the enabled state of each app
      const isEnabled = {
        ...appsStoreIsEnabled,
        [id]: e.target.checked,
      };

      setEnableAllState(getStateValue(isEnabled));

      return isEnabled;
    });
  };

  const handleEnableAllClick = (): void => {
    setEnableAllState((enableAllState: EnableAllState) => {
      const state = enableAllState !== 'all' ? 'all' : 'none';
      dataAppsStore.display
        .map((app: any) => `${TEMP_PROPERTY.IS_IN_APPS_STORE}.${app.id}`)
        .forEach((field: string) => setValue(field, state === 'all', { shouldDirty: true }));
      return state;
    });
  };

  const sortApps = (i18n: any, appsStore: any) =>
    Object.values(appsStore).sort((a: any, b: any) =>
      a.i18n[i18n.language].label > b.i18n[i18n.language].label ? 1 : -1,
    );

  return (
    <>
      <div role="row">
        <div role="cell">
          {/* Enable all checkbox */}
          <LabeledCheckbox
            hasMargin
            checkboxProps={{
              checked: enableAllState === 'all',
              indeterminate: enableAllState === 'indeterminate',
              onClick: () => handleEnableAllClick(),
            }}
            indentWidth="none"
            label={t(
              'accounts_manager:containers.accounts.section.app_exchange.field.enable_all_apps.label',
            )}
          />
        </div>
      </div>

      <div className="three-column overline" role="row">
        {/* App checkboxes */}
        {dataAppsStore.display &&
          columnize(
            sortApps(i18n, dataAppsStore.display),
            (app: any) => (
              <HookFormInputWrapper
                key={app.id}
                isSwitch
                name={`${TEMP_PROPERTY.IS_IN_APPS_STORE}.${app.id}`}
              >
                {({ ref, isDirty, name, ...formProps }) => (
                  <AppCheckbox
                    checkboxProps={{
                      onClick: (e: BaseSyntheticEvent) => handleAppClick(e, app.id),
                      ...formProps,
                    }}
                    iconProps={{ name: app.name, phase: app.phase, hasShadow: true }}
                    isDirty={isDirty}
                    label={app.i18n[i18n.language].label}
                    name={app.id}
                  />
                )}
              </HookFormInputWrapper>
            ),
            {},
            3,
          )}
      </div>
    </>
  );
};

export default AppExchangeSection;
