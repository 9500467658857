import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledPlusProps as Props } from './definition';

const StyledPlus = styled.span.attrs({ className: 'callflow-plus' })<Props>`
  ${(props) => css`
    ${mixin.borderRadius()};
    ${mixin.flex()};
    ${mixin.transition([
      { property: 'opacity', duration: 'medium' },
      { property: 'transform', duration: 'medium' },
    ])};
    align-self: center;
    justify-self: center;
    position: relative;
    --size: 32px;
    width: var(--size);
    height: var(--size);
    background-color: ${props.theme.core.color.blue.draggable};
    opacity: 0;

    svg {
      fill: ${props.theme.core.color.white};
    }

    ${props.isActivated &&
    css`
      opacity: 1;
      z-index: 10;
    `};
  `};
`;

export default StyledPlus;
