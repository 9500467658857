/**
 * @name getSubTitleClass
 * @description Gets the subtitle class based on the callflow module
 *
 * @param callflowModule - The callflow module
 * @returns - The subtitle class, which is 'is-preview' if the callflow module is 'callflow', otherwise no class
 */
export const getSubTitleClass = (callflowModule: string) =>
  callflowModule === 'callflow' ? 'is-preview' : '';

/**
 * @name hasDialog
 * @description Checks if a callflow has a dialog
 *
 * @param callflowHasDialog - Indicates whether the callflow has a dialog
 * @param isLoopingCallflow - Indicates whether the callflow is looping
 * @returns - `true` if the callflow has a dialog and is not a looping callflow, otherwise `false`
 */
export const hasDialog = (callflowHasDialog: boolean, isLoopingCallflow: boolean) =>
  callflowHasDialog && !isLoopingCallflow;
