// FeatureCode Queries
import { FeatureCode } from 'api/featureCode';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

const callflowResponseHandler = async (callflows: any) =>
  callflows.filter((callflow: Callflow) => callflow.featurecode);

export const featureCodeQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchFeatureCodes: builder.query<any, void>({
      query: () => ({
        url: FeatureCode.Fetch(),
        responseHandler: async (results) => callflowResponseHandler(await responseHandler(results)),
      }),
      providesTags: () => [{ type: TAGS.FEATURECODE, id: 'LIST' }],
    }),
  }),
});
