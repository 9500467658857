import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class CallerIdMatchActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.MATCH_CALLER_ID,
      module: CallFlowModuleKey.MATCH_CALLER_ID,
      section: CallFlowSection.CALLER_ID,
      rules: [
        {
          type: 'quantity',
          maxSize: 10,
        },
      ],
      iconProps: {
        name: 'phone-match-filled',
      },
      label: i18next.t('phone_system:containers.callflows.actions.caller_id_match.name'),
    });
  }
}

export default CallerIdMatchActionConfig;
