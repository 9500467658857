import Edit from 'apps/PhoneSystem/containers/Media/Edit';
import { ADD_KEY } from 'constant';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogLink, DialogType } from 'shared/components/Dialog';
import { useToast } from 'shared/hooks/useToast';
import { onEmbeddedEditSaveSuccess } from '../../../utility';
import defaultProps from './default';
import { AddMediaDialogProps as Props } from './definition';
import StyledAddMediaDialog from './style';

const AddMediaDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasEditButton,
    hasMargin,
    editId,
    selectComponent,
    addClickHandler,
    editClickHandler,
    setItemName,
  }: Props = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { showToast } = useToast();

  const title =
    editId === ADD_KEY
      ? t('phone_system:containers.callflows.callflow_action_dialog.media.add.create_media')
      : t('phone_system:containers.callflows.callflow_action_dialog.media.edit.edit_media');

  return (
    <StyledAddMediaDialog hasMargin={hasMargin}>
      <div role="row" className="split split-with-link">
        <div role="cell">{selectComponent}</div>
        <div role="cell">
          {hasEditButton && (
            <DialogLink
              type="edit"
              onClick={() => {
                editClickHandler && editClickHandler();
                setIsEditDialogOpen(true);
              }}
            />
          )}
        </div>
      </div>

      <div role="row">
        <div role="cell">
          <DialogLink
            type="add"
            isBelow
            text={t(
              'phone_system:containers.callflows.callflow_action_dialog.media.add.add_new_media',
            )}
            onClick={() => {
              addClickHandler && addClickHandler();
              setIsEditDialogOpen(true);
            }}
          />
        </div>
      </div>

      <Dialog
        open={isEditDialogOpen}
        title={title}
        type={DialogType.CallflowActions}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <Edit
          id={editId}
          handleCancel={() => setIsEditDialogOpen(false)}
          handleSaveSuccess={() => onEmbeddedEditSaveSuccess({ showToast, setIsEditDialogOpen })}
          handleDeleteSuccess={() => setIsEditDialogOpen(false)}
          setItemName={setItemName}
        />
      </Dialog>
    </StyledAddMediaDialog>
  );
};

export default AddMediaDialog;
