import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { StyledChildCountProps as Props } from './definition';

const StyledChildCount = styled(Box).attrs({ component: 'span' })<Props>`
  ${({ $isExpanded, theme }) => css`
    margin: ${theme.spacing(0, 0, 0, 2)};
    font-style: italic;

    ${!$isExpanded &&
    css`
      color: ${theme.core.background.grey.dark};
    `};
  `}
`;

export default StyledChildCount;
