import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { style } from '../../../style';
import { StyledAccountHeaderProps as Props } from './definition';

const StyledAccountHeader = styled(Box)<Props>`
  ${({ $isLoading, $isTreeVisible: isOpen, theme }) => css`
    ${$isLoading
      ? css`
          padding: ${theme.spacing(0, 4)};
        `
      : css`
          ${style.item({ isOpen, theme }).default};
          ${style.item({ isOpen, theme }).toggleable.head};

          > div {
            ${mixin.flex({ direction: 'column', alignItems: 'flex-start' })};

            span {
              ${mixin.font({ lineHeight: '1.25' })};
              color: ${theme.core.background.grey.dark};
              user-select: none;
              white-space: nowrap;

              &:last-of-type {
                font-weight: 600;
              }
            }
          }
        `}
  `}
`;

export default StyledAccountHeader;
