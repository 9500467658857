import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { FeedbackType, StyledFeedbackProps as Props } from './definition';

const StyledFeedback = styled(Box)<Props>`
  ${({ theme, type }) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};
    padding: ${theme.spacing(0, 0, 4)};
    color: ${type === FeedbackType.ERROR ? theme.core.color.orange : theme.core.color.green.dark};

    svg {
      margin-right: ${theme.spacing(1.5)};
    }
  `}
`;

export default StyledFeedback;
