import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchDevicesQuery } from 'models/Device';
import { FunctionComponent, useEffect, useState } from 'react';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import defaultProps from './default';
import { DeviceSelectProps as Props } from './definition';
import StyledDeviceSelect from './style';

const DeviceSelect: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { name, label, control, isLabelAbove, tooltip }: Props = { ...defaultProps, ...props };
  const [devices, setDevices] = useState<Array<SelectOption>>([]);
  const { data, isLoading } = useFetchDevicesQuery();

  useEffect(() => {
    if (data) {
      setDevices(data.map((device) => ({ value: device.id, label: device.name })));
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledDeviceSelect>
      <HookFormSelectWrapper control={control} name={name} options={devices}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            isWindowed
            feedback={feedback}
            isLabelAbove={isLabelAbove}
            label={label}
            tooltip={tooltip}
            selectProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormSelectWrapper>
    </StyledDeviceSelect>
  );
};

export default DeviceSelect;
