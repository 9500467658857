import {
  TableCellCheckmarkIcon,
  TableCellImage,
  TableCellManageLink,
} from 'apps/shared/components/TableCell';
import { ImageType } from 'apps/shared/components/TableCell/Image/definition';
import i18next from 'i18next';
import capitalize from 'lodash/capitalize';
import { RadioProps } from 'shared/components/Radio';
import { Cell, EditIcon, Justify, TABLE_CONSTANTS } from 'shared/components/Table';
import { manufacturers } from '../containers/Edit/definition';
import { StyledTrunkNameCell } from '../style';

export const columns = () => ({
  assignedNumbers: {
    dialog: [
      {
        Header: i18next.t(
          'sip_trunking:containers.default.components.assigned_numbers_list.table.column.numbers',
        ),
        accessor: 'id',
      },
    ],
    list: [
      {
        Header: i18next.t(
          'sip_trunking:containers.default.components.assigned_numbers_list.table.column.numbers',
        ),
        accessor: 'id',
      },
      {
        Header: i18next.t(
          'sip_trunking:containers.default.components.assigned_numbers_list.table.column.sms',
        ),
        Cell: ({ value }: Cell<JSX.Element>) => <TableCellCheckmarkIcon isVisible={value} />,
        accessor: 'hasSmsBox',
        disableSortBy: true,
        id: `sms.${Justify.Center}`,
        width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
      },
      {
        Header: ' ',
        Cell: ({
          row: {
            original: { id, name },
          },
        }: Cell<{ id: string; name: string }>) => (
          <EditIcon path={`./numbers_in_use/${name}/${id}`} />
        ),
        id: `edit.${Justify.Center}`,
        width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.XSMALL,
      },
    ],
  },
  trunks: [
    {
      Header: i18next.t('sip_trunking:containers.all_trunks.table.column.trunk_name'),
      Cell: ({
        row: {
          original: {
            server: { server_name, trunkImageUrl },
          },
        },
      }: Cell<{ server: { server_name: string; trunkImageUrl: string } }>) => (
        <StyledTrunkNameCell>
          <TableCellImage type={ImageType.Logo} src={trunkImageUrl} alt={server_name} />
          <span>{server_name}</span>
        </StyledTrunkNameCell>
      ),
      accessor: 'server.server_name',
      width: 300,
    },
    {
      Header: i18next.t('sip_trunking:containers.all_trunks.table.column.server'),
      accessor: 'account.auth_realm',
      width: 400,
    },
    {
      Header: i18next.t('sip_trunking:containers.all_trunks.table.column.assigned_numbers'),
      Cell: ({
        value,
        row: {
          original: { id },
        },
      }: Cell<{ id: string }>) => <TableCellManageLink linkTo={`assigned/${id}`} value={value} />,
      accessor: 'server.assignedNumbersCount',
      id: `sip_trunking.assigned_numbers.${Justify.Center}`,
      width: 200,
    },
    {
      accessor: 'server.assignedNumbers',
      isHidden: true,
    },
  ],
});

export const getAuthRadioOptions: () => RadioProps[] = () => {
  return [
    {
      label: i18next.t('sip_trunking:containers.all_trunks.field.username_password'),
      value: 'Password',
    },
    {
      label: i18next.t('sip_trunking:containers.all_trunks.field.ip_address'),
      value: 'IP',
    },
  ];
};

export const getTrunkId = (id: string, index: number) => `${id}_${index}`;

export const getTrunkImageUrl = (serverType: string) =>
  `/images/sip_trunk/${serverType?.replace('.', '').replace(' ', '').toLowerCase() ?? 'other'}.png`;

// TODO: use ImmerJs
export const transformConnectivityDataForTable = (
  connectivityData: any,
  phoneNumbers: Record<string, any>,
) =>
  (connectivityData?.[0]?.servers || []).map((server: any, index: number) => {
    const assignedNumbers = Object.keys(server.DIDs).filter((did: string) => phoneNumbers[did]);

    return {
      server: {
        ...server,
        assignedNumbers,
        assignedNumbersCount: assignedNumbers.length,
        trunkImageUrl: getTrunkImageUrl(server.server_type),
        options: {
          ...server.options,
          flags: server.options?.flags?.[0],
          compatibilityMode: server.options.media_handling === 'process',
        },
        auth: {
          ...server.auth,
          // hacky fix to correct the value coming from API
          auth_method:
            server?.auth?.auth_method === 'password'
              ? capitalize(server?.auth?.auth_method)
              : server?.auth?.auth_method,
        },
      },
      account: connectivityData?.[0].account,
      // Note: this ID is very important to exist for table to work properly
      id: getTrunkId(connectivityData?.[0].id, index),
    };
  });

export const manufacturersWithImage: any = manufacturers.map((manufacturer) => ({
  image: getTrunkImageUrl(manufacturer.value),
  ...manufacturer,
}));

export const extractTrunkId = (id: string): number => {
  const arr = id.split('_');
  arr.shift();
  return Number(arr.join(' '));
};
