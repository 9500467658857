export enum AgentCardDataType {
  HAS_CALLBACK = 'hasCallback',
  ACTIVE_CALL = 'activeCall',
  AGENT_NAME = 'agentName',
  AGENT_EXTENSION = 'agentExtension',
  AGENT_SKILLS = 'agentSkills',
  CALLS_MISSED = 'callsMissed',
  CALLS_TAKEN = 'callsTaken',
  CALLS_TOTAL = 'callsTotal',
  LABEL = 'label',
  PRIORITY = 'priority',
  STATUS_TYPE = 'statusType',
  TIMESTAMP = 'timestamp',
}
