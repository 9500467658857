import { getMediaType } from 'apps/PhoneSystem/shared/utility';
import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import { getInputValue } from 'apps/shared/components/TableCell/utility';
import { CHARACTER } from 'constant';
import i18next from 'i18next';
import { Cell } from 'shared/components/Table';
import { MediaType } from '../../../definition';

export const columns = () => [
  {
    Header: i18next.t('phone_system:containers.media.table.column.name'),
    accessor: 'name',
    width: 400,
  },
  {
    Header: i18next.t('phone_system:containers.media.table.column.media_type'),
    Cell: ({ value }: Cell<Record<MediaType, string>>) => getMediaType()[value as MediaType],
    accessor: 'media_source',
    width: 200,
  },
  {
    Header: i18next.t('phone_system:containers.media.table.column.input'),
    Cell: ({ row: { original } }: any) => {
      const text = getInputValue(original);
      return text ? <TableCellTruncatedValue maxWidth={800} text={text} /> : CHARACTER.EMDASH;
    },
    accessor: (row: any) => getInputValue(row),
    width: 800,
  },
];
