import AddNumbersFlow from 'apps/shared/components/AddNumberFlow';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import { AddNumbersProps as Props } from './definition';

const AddNumbers: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { origin } = props;
  const [showAddNumbersFlow, setShowAddNumbersFlow] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => {
          setShowAddNumbersFlow(true);
        }}
      >
        {t('numbers:action_row.add_number.label')}
      </Button>
      {showAddNumbersFlow && (
        <AddNumbersFlow setShowAddNumbersFlow={setShowAddNumbersFlow} origin={origin} />
      )}
    </div>
  );
};

export default AddNumbers;
