import produce from 'immer';
import isEmpty from 'lodash/isEmpty';

/**
 * @name getCallflowSeatId
 * @description Find first seat/user action block (and user id)
 *              by recursively traversing callflow tree.
 *
 * @param flow
 *
 * @returns User id or undefined.
 */
export const getCallflowSeatId = (flow: any): string | undefined => {
  const recursion = (flow: any): string | undefined => {
    // if `flow` is undefined
    if (isEmpty(flow)) {
      return;
    }

    // if current `flow` object is a user module
    if (flow.module === 'user') {
      return flow.data.id;
    }

    // iterate children and recursively search for user module
    let seatId;
    if (!isEmpty(flow.children)) {
      Object.values(flow.children).some((value: unknown) => {
        seatId = getCallflowSeatId(value);
        return seatId ?? undefined;
      });
    }
    return seatId;
  };

  let seatId: string | undefined;

  try {
    seatId = recursion(flow);
  } catch (exception) {
    console.error(exception);
  }

  return seatId;
};

/**
 * @name maybeFindCallflowSeatId
 * @description If the first action block of the callflow is not of type `user`,
 *              then the callflow tree needs to be traversed, looking for the
 *              first valid user/seat action block; once found, the user id is
 *              assigned to the respective callflow object as property `seat_id`
 *              (differentiating it from the original `user_id` property).
 *
 *              FYI, the callflow object `user_id` property can be misleading; if
 *              the first action block were to be, for example, of type device, then
 *              the `user_id` value would actually contain the device id. To be more
 *              accurate, the `user_id` could potentially be renamed `first_action_id`.
 *
 * @param [object]
 * @property data
 * @property fetchCallflowById
 * @property getCallflowSeatIdCustom
 */
export const maybeFindCallflowSeatId = async ({
  data = [],
  fetchCallflowById,
  getCallflowSeatIdCustom = getCallflowSeatId,
}: {
  data?: Array<any>;
  fetchCallflowById: (param: any) => any;
  getCallflowSeatIdCustom?: (flow: any) => string | undefined;
}) =>
  produce(data, async (draft) => {
    await Promise.all(
      (data ?? []).map(({ id, modules }, index: number) => {
        if (modules?.length > 0 && modules[0] !== 'user') {
          return fetchCallflowById({ id })
            .then(({ data }: any) => {
              const seatId = getCallflowSeatIdCustom(data?.flow);

              if (seatId) {
                draft[index].seat_id = seatId;
              }
            })
            .catch((exception: Error) => {
              console.error('maybeFindCallflowSeatId', exception);
            });
        }
      }),
    );
  });
