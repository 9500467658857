import { HandleDeleteSuccessParam, HandleSaveSuccessParam } from 'apps/PhoneSystem/definition';
import { ExitConfirmationDialogContext } from 'apps/store/contexts';
import { FunctionComponent, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from 'shared/hooks/useToast';

/**
 * TODO:
 * • Create an "item deleted" toast which is triggered here (rather than all over the place),
 *   before the handleDeleteSuccess is called
 * • Consider renaming handleDeleteSuccess to (something like) handleAndNotifyDeleteSuccessful
 */

const withNavigation = (
  AugmentedComponent: FunctionComponent,
  shouldRedirect = false,
): FunctionComponent => (props: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const exitConfirmationDialogCtx = useContext(ExitConfirmationDialogContext);

  return (
    <AugmentedComponent
      id={id}
      handleCancelSuccess={() => navigate('..')}
      handleDeleteSuccess={({ hasToast = true, toastMessage }: HandleDeleteSuccessParam = {}) => {
        if (hasToast) {
          showToast.delete.success(toastMessage);
        }
        exitConfirmationDialogCtx.onIsDirtyChange?.(false); // Clean up nav blocker
        navigate('..');
      }}
      handleSaveSuccess={({ hasToast = true, toastMessage }: HandleSaveSuccessParam = {}) => {
        if (hasToast) {
          showToast.success(toastMessage);
        }
        exitConfirmationDialogCtx.onIsDirtyChange?.(false); // Clear the nav blocker
        if (shouldRedirect) {
          navigate('..');
        }
      }}
      {...props}
    />
  );
};

export default withNavigation;
