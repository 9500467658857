import { PARK_CONVERSION_FACTOR } from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowActionsDialog/constants';
import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';
import { Metadata } from '../store/definition';

class DirectParkActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.PARK,
      module: CallFlowModuleKey.PARK,
      section: CallFlowSection.ADVANCED,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'parking-outlined',
      },
      label: i18next.t('phone_system:containers.callflows.actions.direct_park.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.direct_park.tooltip'),
    });
  }

  static subtitle = (nodeData: any, metadata: Metadata) =>
    i18next.t('phone_system:containers.callflows.actions.direct_park.subtitle', {
      slots: nodeData.max_slot_number - PARK_CONVERSION_FACTOR,
    });
}

export default DirectParkActionConfig;
