import { FormFields as CellularEnablementFormFields } from './components/CellularEnablementSection/definition';
import { FormFields as AuthFormFields } from './components/SsoSection/definition';

export const defaultValues = {
  format_from_uri: false,
  max_connect_failures: '0',
  contact: {
    billing: {
      name: '',
      number: '',
      email: '',
    },
    technical: {
      same_as_billing: false,
      name: '',
      number: '',
      email: '',
    },
  },
  music_on_hold: {
    media_id: '',
  },
  caller_id: {
    internal: {
      name: '',
      number: '',
    },
    external: {
      name: '',
      number: '',
    },
    emergency: {
      name: '',
      number: '',
    },
  },
  ooma_info: {
    team_chat: {
      enabled: false,
    },
  },
  screen_pop_enabled: false,
  screen_pop_platforms: [],
  [CellularEnablementFormFields.BridgeNumber]: '',
  [AuthFormFields.PinPassLink]: true,
};
