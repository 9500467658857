// User Queries
import { User } from 'api/user';
import { LOCAL_STORAGE } from 'constant';
import { ByAccountIdParam, ByIdParam, ByIdWithAccountIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const userQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchUsers: builder.query<UserResponse, ByAccountIdParam | void>({
      query: ({ accountId }: { accountId?: string } = {}) => ({
        url: User.Fetch(accountId),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.USER as const, id })),
              { type: TAGS.USER, id: 'LIST' },
            ]
          : [{ type: TAGS.USER, id: 'LIST' }],
    }),
    fetchUserById: builder.query<User, ByIdWithAccountIdParam>({
      query: ({ id, accountId }) => ({
        url: User.FetchById({ accountId, id }),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.USER, id }];
      },
    }),
    fetchRootUser: builder.query<User, void>({
      query: () => ({
        url: User.FetchById({
          id: localStorage.getItem(LOCAL_STORAGE.AUTH.USER_ID) || '',
          accountId: localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID) || undefined,
        }),
        responseHandler,
      }),
      keepUnusedDataFor: 5,
    }),
    fetchDesktopAppZoneById: builder.query<User, ByIdParam>({
      query: ({ accountId, id }: { accountId?: string; id: string }) => ({
        url: User.FetchDesktopAppZoneById(accountId, id),
        responseHandler,
      }),
    }),
  }),
});
