import produce from 'immer';

export const filterCSVNumber = (
  existingNumbers: Record<string, any>,
  csvNumbers: Record<string, any>,
) => {
  return produce(existingNumbers, (draft) => {
    Object.keys(existingNumbers).forEach((existingNumber) => {
      if (Object.prototype.hasOwnProperty.call(csvNumbers, existingNumber)) {
        delete draft[existingNumber];
      }
    });
  });
};
