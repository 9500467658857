import { kazooApi, TAGS } from 'services/kazoo';
import { Configs } from '../../api/configs';
import { RequestMethod } from '../../definition';
import { Configs as ConfigsResponse, ConfigsId, ConfigsMutations } from './types';

export const configsMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    [ConfigsMutations.UpdateVoicemailConfigs]: builder.mutation<
      ConfigsResponse,
      { pin_pass_sync: boolean; accountId?: string }
    >({
      query: ({ pin_pass_sync, accountId }) => ({
        url: Configs.UpdateById(ConfigsId.Voicemail, accountId),
        method: RequestMethod.Patch,
        body: { data: { pin_pass_sync } },
      }),
      invalidatesTags: () => [{ type: TAGS.CONFIGS, id: ConfigsId.Voicemail }],
    }),
  }),
});
