import { InputTemplate } from 'apps/AdvancedProvisioner/shared/components/DynamicForm/definition';

export interface DynamicInputProps {
  template: InputTemplate;
  fieldKey: string;
}

export interface GetInputParams {
  type: string;
}

export enum FieldType {
  Select = 'select',
  Text = 'text',
  Password = 'password',
}
