import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledSwitch } from 'shared/components/Labeled';
import CallerInfoMatchList from './components/CallerInfoMatchRules';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['screen_pop_enabled', 'screen_pop_platforms'];

const CallerInfoMatchSection = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  return (
    <>
      <h2>
        {t('phone_system:containers.account.submodule.settings.section.caller_info_match.heading')}
      </h2>
      <p>
        {t(
          'phone_system:containers.account.submodule.settings.section.caller_info_match.subheading',
        )}
      </p>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="screen_pop_enabled" isSwitch>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSwitch
                id="switch-screen-pop-enabled"
                isSwitchLeft
                hasMargin
                indentWidth="none"
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'phone_system:containers.account.submodule.settings.section.caller_info_match.enabled_switch',
                )}
                switchProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>

          {watch('screen_pop_enabled') && <CallerInfoMatchList />}
        </div>
      </div>
    </>
  );
};

export default CallerInfoMatchSection;
