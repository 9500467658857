import { joiResolver } from '@hookform/resolvers/joi';
import CallflowActionsDialog from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowActionsDialog/components/CallflowActionDialog';
import { HookFormRadioGroupWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledRadioGroup } from 'shared/components/Labeled';
import { MATCH_VALUE, NO_MATCH_VALUE, REGEX_MODE_OPTIONS } from '../../../constants';
import defaultProps, { defaultValues } from './default';
import { Data, RegexModeProps as Props } from './definition';
import schema from './schema';

const getKey = (data: any) => {
  if (REGEX_MODE_OPTIONS.includes(data.key)) return data.key;
  if (data.key === '' || data.key === MATCH_VALUE) return MATCH_VALUE;
  return MATCH_VALUE;
};

const getUsedKeys = (data: any) => {
  if (REGEX_MODE_OPTIONS.includes(data.key)) return data.usedKeys;
  return [];
};

const RegexMode: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
      key: getKey(data),
      usedKeys: getUsedKeys(data),
    },
    resolver: joiResolver(schema()),
  });
  const options = [
    {
      label: t(
        'phone_system:containers.callflows.select_key_dialog.match_caller_id.regex.add.match',
      ),
      value: MATCH_VALUE,
    },
    {
      label: t(
        'phone_system:containers.callflows.select_key_dialog.match_caller_id.regex.add.no_match',
      ),
      value: NO_MATCH_VALUE,
    },
  ];
  const { isDirty } = formState;

  const submitHandler = async (formData: Data) => {
    onSave(formData.key, isDirty);
  };

  return (
    <CallflowActionsDialog
      title={t('phone_system:containers.callflows.select_key_dialog.match_caller_id.regex.title')}
      handleClose={onClose!.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormRadioGroupWrapper name="key" options={options} control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledRadioGroup
            isLabelAbove
            isDirty={isDirty}
            feedback={feedback}
            label={t('common:options')}
            radioGroupProps={{ ...formProps }}
            radioProps={options}
          />
        )}
      </HookFormRadioGroupWrapper>
    </CallflowActionsDialog>
  );
};

export default RegexMode;
