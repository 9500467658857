import { BaseSyntheticEvent } from 'react';
import { CardProps } from 'shared/components/Card/definition';
import { ComponentProps } from 'shared/definition';

export enum QueueCardDataType {
  ACTIVE_CALLS = 'activeCalls',
  ACTIVE_AGENTS_CURRENT = 'activeAgentsCurrent',
  ACTIVE_AGENTS_TOTAL = 'activeAgentsTotal',
  AVERAGE_WAIT_TIME = 'averageWaitTime',
  MISSED_CALLS = 'missedCalls',
  TOTAL_CALLS = 'totalCalls',
}

export interface QueueCardData {
  hasMenu?: boolean;
  isSelected?: boolean;
  activeAgentsCurrent: number;
  activeAgentsTotal: number;
  activeCalls: number;
  averageWaitTime: string;
  id?: string;
  missedCalls: number;
  title: string;
  totalCalls: number;
}

export interface QueueCardProps extends ComponentProps, Partial<CardProps> {
  /** Is the menu (icon) enabled */
  hasMenu?: boolean;
  /** Is the card selected? */
  isSelected?: boolean;
  /** Queue id */
  id: string;
  /** Data object */
  data: Record<QueueCardDataType, number | string>;
  /** onClick event handler object */
  onClick?: {
    default: (id: string) => void;
    edit?: {
      button: (e: BaseSyntheticEvent) => void;
      menu: () => void;
    };
    hide?: () => void;
  };
}
