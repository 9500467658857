import * as mixin from 'shared/utility/mixin';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { StyledCountListProps as Props } from './definition';

const scrollbar = (props: any): FlattenSimpleInterpolation => css`
  ${props &&
  css`
    overflow-y: auto;

    --scroll-size: 8px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;

      &:vertical {
        width: var(--scroll-size);
      }

      &:horizontal {
        height: var(--scroll-size);
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: calc(var(--scroll-size) / 2);
      background-color: ${props.theme.core.divider.default};
    }
  `}
`;

const StyledCountList = styled.ul<Props>`
  ${(props) => css`
    ${scrollbar(props)};
    width: 100%;
    height: ${`calc(100% - ${36 + (props.hasButtonMargin ? 20 : 0)}px)`};
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      ${mixin.flex()};
      ${mixin.borderRadius({ radius: 'half' })};
      ${mixin.stripe({ props })};
      padding: 4px 0;

      span {
        color: ${props.theme.core.text.dark};
        --width: 70px;

        &:first-of-type {
          ${mixin.font({ size: 28 })};
          width: var(--width);
          text-align: center;
        }

        &:last-of-type {
          ${mixin.font({ size: 18 })};
          width: calc(100% - var(--width));
        }
      }
    }
  `};
`;

export default StyledCountList;
