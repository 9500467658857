import { FunctionComponent } from 'react';
import Box from 'shared/components/Box';
import { DividerProps as Props } from './definition';
import StyledDivider from './style';

const Divider: FunctionComponent<Props> = ({ text }: Props): JSX.Element => (
  <StyledDivider>
    <hr />
    <Box component="span">{text}</Box>
    <hr />
  </StyledDivider>
);

export default Divider;
