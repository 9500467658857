import { CHARACTER } from 'constant';
import Icon from 'shared/components/Icon';
import { BulletProps as Props } from './definition';
import StyledBullet from './style';

const Bullet = ({ hasPassword, isError }: Props) => {
  const color = isError ? 'error' : 'success';
  const icon = isError ? 'close' : 'check';

  return hasPassword ? (
    <StyledBullet>
      <Icon color={color as any} name={icon} size={13} />
    </StyledBullet>
  ) : (
    <StyledBullet $isDefault>{CHARACTER.DOT}</StyledBullet>
  );
};

export default Bullet;
