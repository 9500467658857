import CallForward from 'apps/PhoneSystem/shared/CallForward';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledCheckbox from 'shared/components/Labeled/components/LabeledCheckbox';
import { FormInput } from '../../../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'call_forward.direct_calls_only',
  'call_forward.enabled',
  'call_forward.failover',
  'call_forward.keep_caller_id',
  'call_forward.number',
  'call_forward.require_keypress',
  'call_forward.substitute',
  'do_not_disturb.enabled',
];

const CallRoutingSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t('phone_system:containers.seats.section.call_routing.do_not_disturb')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper isCheckbox name="do_not_disturb.enabled">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                }}
                label={t(
                  'phone_system:containers.seats.section.call_routing.enable_do_not_disturb',
                )}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.seats.section.call_routing.call_forward')}</h2>
      <div role="row">
        <div role="cell">
          <CallForward />
        </div>
      </div>
    </>
  );
};

export default CallRoutingSection;
