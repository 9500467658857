export default {
  styleOverrides: {
    root: {
      '&[role=radiogroup]': {
        paddingTop: 4,
        '> div:not(:first-of-type)': {
          marginTop: -12,
        },
      },
    },
  },
};
