import { IdpFormState } from './definition';
import { FormFields } from './fields';

const defaultProps = {
  open: false,
  onClose: () => {},
  onSave: () => {},
  onDelete: () => {},
};

export default defaultProps;

export const defaultFormState: Partial<IdpFormState> = {
  [FormFields.SsoUrl]: '',
  [FormFields.X509Certificate]: null, // Must be null instead of undefined since react-hook-form reset() will skip resetting field with undefined
  [FormFields.UniqueIdClaim]: '',
  [FormFields.UseNameIdForEmail]: false,
  [FormFields.EmailClaim]: '',
  [FormFields.UsernameClaim]: '',
  [FormFields.UsePostMethod]: false,
  [FormFields.SignRequest]: false,
};
