import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Table, { TABLE_CONSTANTS } from 'shared/components/Table';
import { FormSectionDataList, FormSectionDataListObject } from '../../../../definition';
import { TableCellEdit } from './components/TableCellEdit';
import { TableCellNumber } from './components/TableCellNumber';
import { IterateTableProps as Props } from './definition';
import { useTableData } from './hooks';
import { StyledTable } from './style';
import { getAdvancedProvisionerTabTableColumns } from './utility';

export const IterateTable = ({ iterate, sectionTemplate, tabKey, tabName }: Props) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const data = getValues(tabKey) as FormSectionDataList | FormSectionDataListObject;

  const tableData = useTableData({ iterate, data });

  const columns = useMemo(
    () => [
      {
        Header: t(
          'advanced_provisioner:dynamic_form.components.tab.components.iterate_table.column.id',
          { name: tabName },
        ),
        Cell: TableCellNumber,
        accessor: 'id',
        id: 'id',
      },
      ...getAdvancedProvisionerTabTableColumns(tabKey, sectionTemplate), // ADV-PROV-TODO: These columns should be passed in as a prop when the DynamicForm needed to be used in across apps.
      {
        Header: '',
        Cell: TableCellEdit,
        accessor: 'edit',
        disableSortBy: true,
        width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.XSMALL,
      },
    ],
    [sectionTemplate, tabKey, tabName, t],
  );

  return (
    <StyledTable>
      <Table
        hasActionRow={false}
        hasEditIcon={false}
        hasPagination={{ rowsPerPage: true }}
        columns={columns}
        data={tableData}
      />
    </StyledTable>
  );
};
