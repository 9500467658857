import { RowArray } from 'apps/shared/definition';
import { FlowResult } from './components/WaitForServer/definition';

export enum BuyNumberFlowAction {
  Activate = 'activate-numbers',
  Pick = 'pick-numbers',
  Search = 'search-numbers',
}

export enum BuyNumberFlowComponent {
  Activate = 'ActivatingNumbers',
  Pick = 'PickNumbers',
  Search = 'SearchForNumbers',
}

export enum BuyNumberFlowType {
  Default = 'default',
  Local = 'local',
  TollFree = 'toll-free',
}

export interface BuyNumberFlowProps {
  hasNoRedirectOnCancel?: boolean;
  hasNoRedirectOnComplete?: boolean;
  /** Navigate to this route after buy numbers flow has completed */
  flowOnCompleteRoute?: string;
  flowType: BuyNumberFlowType;
  origin?: string;
  onFlowComplete?: FlowResult;
  setFlowType: (newFlow: BuyNumberFlowType) => void;
}

export interface BuyNumbersFlowComponent {
  requiresAreaCode?: boolean;
  requiresSelected?: boolean;
  buttonLabel?: {
    cancel?: string;
    confirm?: string;
  };
  component: BuyNumberFlowComponent;
  message?: string;
  route: {
    cancel?: string;
    default: string;
    next?: string;
  };
  title?: string;
}

export interface BuyNumbersFlowState extends BuyNumbersFlowComponent {
  areaCode: string;
  availableNumbers: RowArray;
  flowType: BuyNumberFlowType;
  prefix: string;
  selectedNumbers: RowArray;
  navigateToNext: () => void;
  setAreaCode: (newAreaCode: string) => void;
  setAvailableNumbers: (newAvailableNumbers: Array<Record<string, any>>) => void;
  setErrorCount: (newCount: number) => void;
  setPrefix: (newPrefix: string) => void;
  setSelectedNumbers: (newSelectedNumbers: Array<Record<string, any>>) => void;
}
