import { HASH_KEY, STAR_KEY } from 'constant';
import i18next from 'i18next';

const translations = () => ({
  collection_name: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.add.collection_name.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.add.collection_name.tooltip',
    ),
  },
  interdigit_timeout: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.add.interdigit_timeout.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.add.interdigit_timeout.tooltip',
    ),
  },
  max_digits: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.add.max_digits.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.add.max_digits.tooltip',
    ),
  },
  terminator: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.add.terminator.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.add.terminator.tooltip',
    ),
  },
  terminators: [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '*', label: STAR_KEY },
    { value: '#', label: HASH_KEY },
  ],
  timeout: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.add.timeout.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.add.timeout.tooltip',
    ),
  },
  title: i18next.t('phone_system:containers.callflows.callflow_action_dialog.collect_dtmf.title'),
});

export default translations;
