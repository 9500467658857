import CallflowContext from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import kebabCase from 'lodash/kebabCase';
import { DragSourceType } from 'models/Callflow/store/definition';
import { FunctionComponent, memo, useContext } from 'react';
import Draggable from 'shared/components/Draggable';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { ActionProps as Props } from './definition';
import StyledAction from './style';

const Action: FunctionComponent<Props> = memo(
  (props: Props): JSX.Element => {
    const {
      isOver,
      actionData: action,
      actionData: { hide, key, tooltip, label, iconProps },
    } = props;
    const {
      isPreview,
      callflow: { isActivated: isDragging },
    } = useContext(CallflowContext);

    const draggableProps = {
      id: key,
      disabled: isPreview,
      data: {
        action,
        source: DragSourceType.ACTION,
      },
    };

    return (
      <Draggable {...draggableProps}>
        <Tooltip title={!isDragging ? tooltip : ''} placement="left">
          <StyledAction
            isHidden={hide as boolean}
            isOver={isOver}
            data-test-id={`action-${kebabCase(label)}`}
          >
            <Icon {...iconProps} />
            <span>{label}</span>
          </StyledAction>
        </Tooltip>
      </Draggable>
    );
  },
);

export default Action;
