import { TimeZoneValue } from './definition';

const defaultProps = {
  mapScale: 0.65,
  frameWidth: 620,
  frameHeight: 450,
  onTimeZoneChanged: () => {},
};

export const defaultTimeZoneValue: TimeZoneValue = {
  city: '',
  selectedTimeZone: '',
};

export default defaultProps;
