import i18next from 'i18next';
import Joi from 'joi';

const schema = () =>
  Joi.object()
    .keys({
      username: Joi.string().required(),
      password: Joi.string().required(),
      account_name: Joi.string().required(),
    })
    .messages({
      'string.empty': i18next.t('common:auth.login.required'),
    });

export default schema;
