import { useFetchAccountQuery, usePatchAccountByIdMutation } from 'models/Account';
import { usePatchBlocklistMutation } from 'models/Blocklist';

const useToggleEnabled = () => {
  const { data: account, isLoading, refetch } = useFetchAccountQuery();
  const [patchAccountById, { isLoading: isLoadingAccount }] = usePatchAccountByIdMutation();
  const [patchBlocklist, { isLoading: isLoadingBlocklist }] = usePatchBlocklistMutation();

  const updateBlockListArray = (
    add: boolean,
    blacklistId: string,
    blockLists: Array<string> = [],
  ) => {
    const updatedBlockList: Set<string> = new Set([...blockLists]);

    // add the item, otherwise remove
    if (add) {
      updatedBlockList.add(blacklistId);
    } else {
      updatedBlockList.delete(blacklistId);
    }

    return [...updatedBlockList];
  };

  const toggleBlocklist = async (checked: boolean, blocklist: any) => {
    const updatedBlockList = updateBlockListArray(checked, blocklist.id, account?.blacklists);

    await patchAccountById({ id: account?.id, body: { blacklists: updatedBlockList } });
    await patchBlocklist({
      id: blocklist.id,
      body: {
        enabled: checked,
        blacklist: {
          enabled: checked,
        },
      },
    });

    refetch();
  };

  return {
    isLoading: isLoading || isLoadingAccount || isLoadingBlocklist,
    toggleBlocklist,
  };
};

export default useToggleEnabled;
