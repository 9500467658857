import { mediaMutations } from './Mutations';
import { mediaQueries } from './Queries';

export const { useFetchMediasQuery, useFetchMediaByIdQuery } = mediaQueries;
export const {
  useCreateMediaMutation,
  useUpdateMediaMutation,
  useDeleteMediaMutation,
  useUploadMediaMutation,
} = mediaMutations;

export const alterMutationBody = (body: Media) => {
  const { file, ...createBody } = body;
  let description = file ? file.name : body.description;

  return { ...createBody, description };
};
