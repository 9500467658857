import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const TimeOfDay = {
  // GET
  Fetch: (isPaginated: boolean = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/temporal_rules?paginate=${isPaginated}`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/temporal_rules/${id}`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/temporal_rules`,

  // POST
  UpdateById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/temporal_rules/${id}`,

  // DELETE
  DeleteById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/temporal_rules/${id}`,
};
