import { FormContext } from 'apps/shared/components/FormContext';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import useCurrentAccountId from 'apps/shared/hooks/useCurrentAccountId';
import { useFetchAccountDesktopAppZoneQuery } from 'models/Account';
import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useAccount from 'shared/hooks/useAccount';
import LabeledSelect from '../../../../shared/components/Labeled/components/LabeledSelect';
import { LabeledDesktopAppZoneProps as Props } from './definition';
import { createDesktopAvailableZones } from './utility';

const LabeledDesktopAppZoneSelect = ({ name }: Props) => {
  const { t } = useTranslation();
  const { isSuperDuper } = useAccount();
  const accountId = useCurrentAccountId();
  const contextName = useContext(FormContext);
  const { watch } = useFormContext();
  const watchDesktopZone = watch(`${contextName}${name}`);
  const { data } = useFetchAccountDesktopAppZoneQuery({ id: accountId });

  const desktopAvailableZonesOptions = useMemo(() => {
    return createDesktopAvailableZones(data?.zone_options, watchDesktopZone);
  }, [data?.zone_options, watchDesktopZone]);

  return (
    <HookFormSelectWrapper name={name} options={desktopAvailableZonesOptions}>
      {({ ref, isDirty, feedback, ...formProps }) => (
        <LabeledSelect
          feedback={feedback}
          isDirty={isDirty}
          label={t('common:desktop_app_zones.label')}
          selectProps={{
            ...formProps,
            isDisabled: !isSuperDuper,
          }}
          tooltip={
            isSuperDuper
              ? t('common:desktop_app_zones.tooltip.account_level.super_admin')
              : t('common:desktop_app_zones.tooltip.account_level.non_admin')
          }
        />
      )}
    </HookFormSelectWrapper>
  );
};

export default LabeledDesktopAppZoneSelect;
