import i18next from 'i18next';
import uniqueId from 'lodash/uniqueId';
import { ChangeEvent, useRef, useState } from 'react';
import { Breadcrumb } from 'shared/components/Breadcrumbs';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import SearchInput from 'shared/components/SearchInput';
import { convertToKebabCase, maybeTooltipComponent } from 'shared/utility';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { AddButton } from './definition';

/**
 * @name buildButtons
 * @description Build the action row buttons.
 *
 * @param [object]
 * @property hasDelete
 * @property isDeleteDisabled
 * @property isSaveDisabled
 * @property addButton
 * @property deleteLabel
 * @property deleteTooltip
 * @property handle
 * @property onCancel
 * @property onDelete
 * @property navigate
 *
 * @returns Button object.
 */
export const buildButtons = ({
  hasDelete = false,
  isDeleteDisabled = false,
  isSaveDisabled = false,
  addButton,
  deleteLabel,
  deleteTooltip,
  on,
  onCancel,
  onDelete,
  navigate,
}: {
  hasDelete?: boolean;
  isDeleteDisabled?: boolean;
  isSaveDisabled?: boolean;
  addButton?: AddButton;
  deleteLabel?: string;
  deleteTooltip?: string;
  on?: Record<string, any>;
  onCancel?: () => void;
  onDelete?: () => void;
  navigate?: (path: string) => void;
}): Record<string, JSX.Element> => {
  const button = {
    add: (
      <Button
        color="secondary"
        data-test-id="btn-add"
        startIcon={addButton?.icon ?? <Icon name="plus-circle-outlined" size={18} />}
        variant={hasDelete ? 'outlined' : 'contained'}
        onClick={addButton?.onAdd}
      >
        {addButton?.label ?? i18next.t('common:add')}
      </Button>
    ),
    cancel: (
      <Button
        color="secondary"
        data-test-id="btn-cancel"
        variant="outlined"
        onClick={() => (onCancel ? onCancel() : navigate?.('..'))}
      >
        {i18next.t('common:cancel')}
      </Button>
    ),
    delete: (
      /**
       * Tooltips will not display on disabled buttons; if disabled,
       * we'll swap out the button element for a generic div
       */
      <Button
        {...{
          disabled: isDeleteDisabled,
          color: 'error',
          'data-test-id': 'btn-delete',
          variant: 'outlined',
          ...(isDeleteDisabled ? { component: 'div' } : {}),
          ...(!isDeleteDisabled ? { onClick: onDelete } : {}),
        }}
      >
        {deleteLabel ?? i18next.t('common:delete')}
      </Button>
    ),
    save: (
      <Button
        color="success"
        data-test-id={TEST_ID.COMMON.SAVE_BUTTON}
        disabled={isSaveDisabled}
        variant="contained"
        onClick={on?.save}
      >
        {i18next.t('common:save')}
      </Button>
    ),
  };

  return {
    add: button.add,
    cancel: button.cancel,
    delete: maybeTooltipComponent({ component: button.delete, tooltip: deleteTooltip }),
    save: button.save,
  };
};

/**
 * @name buildIcons
 * @description Build the action row icons.
 *
 * @param [object]
 * @property filter
 * @property help
 *
 * @returns Component object.
 */
export const useBuildIcons = ({
  filter,
  help,
}: {
  filter?: Record<string, any>;
  help?: Record<string, any>;
}) => {
  const filterIconRef = useRef<SVGSVGElement>(null);
  const [filterIconPosition, setFilterIconPosition] = useState({ top: 0, right: 0 });
  const hasFilterValues = filter?.hasFilterValues.applied;

  const icon = {
    filter: (
      <Icon
        ref={filterIconRef}
        hasHover
        color={hasFilterValues ? 'info' : 'secondary'}
        id="icon-filter"
        name={`filter-alt-${hasFilterValues ? 'filled' : 'outlined'}`}
        size={26}
        onClick={() => {
          filter?.setIsDialogOpen((isOpen: boolean) => !isOpen);

          // The icon position has to be set onClick because it changes on mouse hover (due to icon size increase by hover animation) that make the dialog shift.
          const { top, right } = filterIconRef?.current?.getBoundingClientRect() ?? {
            top: 0,
            right: 0,
          };
          setFilterIconPosition({ top, right });
        }}
      />
    ),
    help: (
      <Icon
        hasHover
        color="secondary"
        id="icon-help"
        name="help-outlined"
        onClick={() => help?.setState((isOpen: boolean) => !isOpen)}
      />
    ),
  };

  return {
    filter: {
      component: icon.filter,
      /** The icon position used to calculate the position of the filter dialog */
      position: filterIconPosition,
    },
    help: {
      component: maybeTooltipComponent({ component: icon.help, tooltip: help?.iconTooltip }),
    },
  };
};

/**
 * @name buildSearch
 * @description Build the search box component
 *
 * @param [object]
 * @property breadcrumbData
 * @property placeholder
 * @property value
 * @property onChange
 * @property onReset
 *
 * @returns SearchInput component
 */
export const buildSearch = ({
  breadcrumbData: data,
  placeholder = '',
  value = '',
  onChange,
  onReset,
}: {
  breadcrumbData?: Array<Breadcrumb>;
  placeholder?: string;
  value?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onReset?: (filter: string) => void;
}): JSX.Element => {
  const text = data?.[data.length - 1]?.text;
  return (
    <SearchInput
      hasDebounce
      inputProps={{
        id: `input-search-${typeof text === 'string' ? convertToKebabCase(text) : uniqueId()}`,
        placeholder,
        value,
        onChange,
      }}
      onReset={() => onReset?.('')}
    />
  );
};

/**
 * @name maybeTooltipActionRowButton
 * @description Render the button with or without being wrapped in a tooltip
 *
 * @param hasSelectedRows
 * @param component
 * @param tooltip
 *
 * @returns Button element
 */
export const maybeTooltipActionRowButton = ({
  hasSelectedRows,
  component,
  tooltip,
}: {
  hasSelectedRows: boolean;
  component: JSX.Element;
  tooltip?: string;
}): JSX.Element => (hasSelectedRows ? component : maybeTooltipComponent({ component, tooltip }));
