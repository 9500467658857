import styled, { css } from 'styled-components';
import { StyledApplySiteLocationDialogProps as Props } from './definition';

const StyledApplySiteLocationDialog = styled.div<Props>`
  ${(props) => css`
    > div {
      margin: 0 0 ${props.theme.spacing(3)} ${props.theme.spacing(6)};
    }
  `};
`;

export default StyledApplySiteLocationDialog;
