import { useCallback, useEffect, useRef } from 'react';

// TODO: When update to React 18, remove this hook
/**
 * WARNING: This hook is 99% unneccesary as React internally check and stop setState if the component is unmount. This hook will keep a ref in an unmounted component so should be avoid.
 * The warning for memory leak is mostly a false positive and removed in React 18: https://github.com/facebook/react/pull/22114
 */
function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

export default useIsMounted;
