export default {
  '.MuiCardContent-root': {
    '> div': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      '> div': {
        '&:first-of-type': {
          flexBasis: '40%',
          transform: 'translateY(12px)',
        },
        '&:last-of-type:not(:first-of-type)': {
          flexBasis: '60%',
          margin: '0 0 0 40px',
        },
      },
      '&.has-maintenance > div:first-of-type': {
        transform: 'translateY(-20px)',
      },
    },
  },
};
