import i18next from 'i18next';

export const SEPARATOR = ', ';

export const getMore = (count: number) =>
  i18next.t('phone_system:shared.table_cell_overflow_list.more', { count });

export const getTextWidth = (text: string, font = 'normal 14px arial'): number => {
  const context = document.createElement('canvas').getContext('2d');
  let metrics;

  if (context) {
    context.font = font;
    metrics = context.measureText(text);
  }

  return metrics?.width ?? 0;
};
