/**
 * @name getIsBlockingEnabled
 * @description Checks if blocking is enabled for a specific blacklist
 *
 * @param blacklistId - The ID of the blacklist
 * @param blacklists - Array of blacklists
 * @returns - Whether blocking is enabled for the specified blacklist
 */
export const getIsBlockingEnabled = (
  blacklistId: string | undefined,
  blacklists: string[] | undefined,
) => {
  if (!blacklistId || !blacklists) {
    return false;
  }
  return blacklists.includes(blacklistId);
};
