import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { style } from '../style';
import { StyledInfoIconProps as Props } from './definition';

const StyledInfoIcon = styled(Box)<Props>`
  ${(props) => {
    const { $isInHeader, theme } = props;

    return css`
      ${style(props).default};

      > svg {
        ${style(props).svg};
        fill: ${theme.whitelabel.secondary.color};

        ${$isInHeader &&
        css`
          transform: translateY(${theme.spacing(0.66)});
        `}
      }
    `;
  }}
`;

export default StyledInfoIcon;
