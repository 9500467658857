import { useLazyFetchCallflowByIdQuery, useLazyFetchCallflowsQuery } from 'models/Callflow';
import { useCallback } from 'react';
import { maybeFindCallflowSeatId } from './utility';

export const getCallflows = async (
  fetchCallflows: () => any,
  fetchCallflowById: (param: any) => any,
  maybeFindCallflowSeatIdFn = maybeFindCallflowSeatId,
) => {
  try {
    const data = await fetchCallflows().unwrap();

    if (data) {
      return await maybeFindCallflowSeatIdFn({ data, fetchCallflowById });
    }
    return [];
  } catch (exception) {
    return [];
  }
};

export const useBuildCallflows = () => {
  const [fetchCallflows] = useLazyFetchCallflowsQuery();
  const [fetchCallflowById] = useLazyFetchCallflowByIdQuery();

  return useCallback(async () => getCallflows(fetchCallflows, fetchCallflowById), [
    fetchCallflows,
    fetchCallflowById,
  ]);
};
