import { IamKazooUser } from 'apps/shared/components/Auth/components/Login/components/SamlSsoLogin/definition';
import { setCurrentAccount } from 'apps/shared/utility/account';
import { NemoConfig, RequestMethod } from 'definition';
import get from 'lodash/get';
import {
  clearE911NotificationData,
  getIamCredentials,
  setCredentials,
  setIamCredentials,
  setIamTokens,
  setLocalStorageAuth,
} from 'models/Auth';
import { IamCredentials, IamTokens } from 'models/Auth/definition';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { KazooUserType } from 'shared/definition';
import { DropdownItem } from '../../components/DropdownButton/definition';
import { IamKazooUser as KazooUser, IamUser } from './definition';

const ACCOUNT_LOGIN_URL = '/login';
const ACCOUNT_FETCH_TOKENS_URL = '/api/v1/tokens';
const IAM_FETCH_REFRESH_TOKEN_URL = '/token/refresh';
const IAM_FETCH_KAZOO_TOKEN_URL = '/kazooAuthToken/provide';
const IAM_FETCH_USER_REGISTRATIONS = '/user-access/registrations';

export const URL_PARAM = Object.freeze({
  STATE: 'state',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  SESSION_ID: 'session_id',
  REGISTRATION_ID: 'registrationId',
  REFRESH_TOKEN_IAM: 'refreshToken',
});

export const SSO_ENABLED = 'sso_enabled';

const DEFAULT_PATH = '/apps/phone-system';

const useSamlSso = (config: NemoConfig) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [registrations, setRegistrations] = useState<DropdownItem[]>([]);
  const [currentUser, setCurrentUser] = useState<KazooUser>();

  useEffect(() => {
    const iamCredentials = getIamCredentials();
    const hasCredentials =
      get(iamCredentials, URL_PARAM.ACCESS_TOKEN, false) ||
      get(iamCredentials, URL_PARAM.REFRESH_TOKEN, false);

    if (hasCredentials) {
      getUserRegistrationsWithRefresh(iamCredentials);
    }
  }, []);

  const getAccountTokens = (sessionId: string, registrationId: number) => {
    const url = new URL(ACCOUNT_FETCH_TOKENS_URL, config.api.account);

    url.searchParams.append(URL_PARAM.SESSION_ID, sessionId);

    return fetchJson(url).then((tokens: IamTokens) =>
      getKazooAuthTokens({ ...tokens, registration_id: registrationId }),
    );
  };

  const getKazooAuthTokens = (iamCredentials: IamCredentials) => {
    const url = new URL(IAM_FETCH_KAZOO_TOKEN_URL, config.api.iam);

    url.searchParams.append(URL_PARAM.REGISTRATION_ID, String(iamCredentials.registration_id));

    return fetchJson(url, iamCredentials.access_token).then((kazooUser: IamKazooUser) =>
      loginUser(kazooUser, iamCredentials),
    );
  };

  const getIamRefreshToken = (tokens: IamTokens) => {
    const url = new URL(IAM_FETCH_REFRESH_TOKEN_URL, config.api.iam);

    url.searchParams.append(URL_PARAM.REFRESH_TOKEN_IAM, tokens.refresh_token);

    return fetchJson(url, tokens.access_token, RequestMethod.Post);
  };

  const getUserRegistrations = (accessToken: string) => {
    const url = new URL(IAM_FETCH_USER_REGISTRATIONS, config.api.iam);
    return fetchJson(url, accessToken);
  };

  const refreshUserRegistrations = (iamCredentials: IamCredentials) => {
    return getIamRefreshToken(iamCredentials).then(
      (tokens: { accessToken: string; refreshToken: string }) => {
        const iamTokens = {
          [URL_PARAM.ACCESS_TOKEN]: tokens.accessToken,
          [URL_PARAM.REFRESH_TOKEN]: tokens.refreshToken,
        } as IamTokens;

        setIamTokens(iamTokens);

        return getUserRegistrations(iamCredentials.access_token);
      },
    );
  };

  const getUserRegistrationsWithRefresh = (iamCredentials: IamCredentials) => {
    return refreshUserRegistrations(iamCredentials).then((registrations: IamUser[]) => {
      const transformedRegistrations = transformRegistrations(
        iamCredentials.registration_id,
        registrations,
      );
      setRegistrations(transformedRegistrations);
    });
  };

  const fetchJson = (url: URL, accessToken: string = '', method = RequestMethod.Get) => {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    accessToken && headers.append('Authorization', `Bearer ${accessToken}`);
    return fetch(url.toString(), { headers, method })
      .then((response) => response.json())
      .catch((error: Error) => setError(error.message));
  };

  const getLoginUrl = () => {
    const url = new URL(ACCOUNT_LOGIN_URL, config.api.account);
    const params = {
      ...config.saml_sso.account_params,
      redirect_uri: `${window.location.origin}${window.location.pathname}`,
    } as { [key: string]: string };

    Object.entries(params).map(([key, value]) => url.searchParams.append(key, value));

    return url;
  };

  const loginUser = (kazooUser: IamKazooUser, iamCredentials: IamCredentials) => {
    const userAuth = {
      auth_token: kazooUser.kazooAuthToken,
      user_id: kazooUser.kazooUserId,
      account_id: kazooUser.kazooAccountId,
    };
    const credentials = {
      auth_token: kazooUser.kazooAuthToken,
      data: {
        language: kazooUser.language,
        account_id: kazooUser.kazooAccountId,
        account_name: kazooUser.kazooAccountName,
        owner_id: kazooUser.kazooUserId,
      },
      // TODO: not sure what the deal with this function params is
      user: null,
    };

    setLocalStorageAuth(userAuth);
    setIamCredentials(iamCredentials);
    clearE911NotificationData();
    dispatch(setCredentials(credentials));
    setCurrentAccount(dispatch, kazooUser.kazooAccountId || '');
    navigate(DEFAULT_PATH);
  };

  const registrationChangeHandler = (registrationId: number) => {
    const iamCredentials = getIamCredentials();
    return getKazooAuthTokens({ ...iamCredentials, registration_id: registrationId }).then(() =>
      navigate(0),
    );
  };

  const transformRegistrations = (registrationId: number, registrations: IamUser[]) =>
    registrations
      .filter((registration: IamUser) => {
        if (registration.id === registrationId) {
          setCurrentUser(registration.resource.data as KazooUser);
        }
        return registration.resource.data.kazooUserType === KazooUserType.ADMIN;
      })
      .map((registration: IamUser) => {
        const { kazooUserId, kazooUserName, kazooAccountName } = registration.resource.data;
        return {
          id: kazooUserId,
          label: `${kazooUserName} - ${kazooAccountName}`,
          onClick: registrationChangeHandler.bind(null, registration.id),
        };
      });

  return {
    getAccountTokens,
    getKazooAuthTokens,
    getLoginUrl,
    currentUser,
    registrations,
    error,
  };
};

export default useSamlSso;
