import AgentCard from 'apps/CallCenter/shared/components/AgentCard';
import {
  useFetchAgentsStatsQuery,
  useRestartAgentMutation,
  useUpdateAgentMutation,
} from 'models/Agents';
import { useFetchUsersQuery } from 'models/User';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { LabeledSwitch } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import Typography from 'shared/components/Typography';
import { useToast } from 'shared/hooks/useToast';
import CONSTANTS from '../../constants';
import NoItemsText from '../NoItemsText';
import { DashboardItemType } from '../NoItemsText/definition';
import { AgentActionType, AgentStatusType, DashboardAgentsProps as Props } from './definition';
import useHideOnlineAgents from './hooks/useHideOnlineAgents';
import StyledDashboardAgents from './style';
import { getActiveAgentCount, getTotalAgents } from './utility';

const DashboardAgents: FunctionComponent<Props> = ({
  agents,
  agentsStatus,
  queue,
  queuesSummary,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { hasOnlineAgents, onHideOnlineAgentClick } = useHideOnlineAgents();
  const { data: dataAgentsStats, isLoading: isLoadingAgentsStats } = useFetchAgentsStatsQuery(
    undefined,
    CONSTANTS.QUERY.OPTIONS,
  );
  const { data: usersData, isLoading: isLoadingUsers } = useFetchUsersQuery();
  const [restartAgent] = useRestartAgentMutation();
  const [updateAgent] = useUpdateAgentMutation();

  // TODO: Possibly use getAgentsFromQueue / getAgentsFromAccount here?
  const totalAgents = getTotalAgents({ hasOnlineAgents, agents, agentsStatus, queue });

  // TODO: Possibly use getActiveAgents / getActiveCallsFromQueue here?
  const activeAgentCount = getActiveAgentCount(totalAgents, agentsStatus);

  const handleAction = async (actionType: AgentActionType, id: string) => {
    const agent = agents?.find(({ id: agentId }: Agent) => agentId === id);
    const name = `${agent?.first_name} ${agent?.last_name}`;

    switch (actionType) {
      case AgentActionType.LOG_IN_AGENT:
        try {
          await updateAgent({ id, body: { status: AgentStatusType.LOGIN } });
          showToast.success(
            t('call_center:containers.dashboard.section.agents.action.success.login', { name }),
          );
        } catch (exception) {
          showToast.error();
        }
        break;
      case AgentActionType.LOG_OUT_AGENT:
        try {
          await updateAgent({ id, body: { status: AgentStatusType.LOGOUT } });
          showToast.success(
            t('call_center:containers.dashboard.section.agents.action.success.logout', { name }),
          );
        } catch (exception) {
          showToast.error();
        }
        break;
      case AgentActionType.RESTART_AGENT:
        try {
          await restartAgent({ id });
          showToast.success(
            t('call_center:containers.dashboard.section.agents.action.success.restart', { name }),
          );
        } catch (exception) {
          showToast.error();
        }
        break;
      default:
        break;
    }
  };

  if (isLoadingAgentsStats || isLoadingUsers) {
    return <Loading />;
  }

  return (
    <StyledDashboardAgents data-test-id="call-center-dashboard-dashboard-agents">
      <Box>
        <Box>
          <Typography variant="h3">
            {queue?.name ?? t('call_center:containers.dashboard.section.agents.all_queues')}
          </Typography>
          <Typography>
            {t('call_center:containers.dashboard.section.agents.active_agents', {
              active: activeAgentCount,
              total: totalAgents.length,
            })}
          </Typography>
        </Box>
        <LabeledSwitch
          isSwitchLeft
          label={t('call_center:containers.dashboard.section.agents.hide_offline_agents')}
          switchProps={{
            checked: hasOnlineAgents,
            onClick: onHideOnlineAgentClick,
          }}
        />
      </Box>
      <Box>
        {totalAgents.length > 0 ? (
          totalAgents.map(({ id }: Agent) => (
            <AgentCard
              key={id}
              id={id}
              data={{
                agent: {
                  ...(agents?.find((agent: AgentResponse) => agent.id === id) as AgentResponse),
                  ...{
                    username: usersData?.find((user: User) => user.id === id)?.username as User,
                  },
                },
                queue: queuesSummary?.Handled.find(({ agent_id }: QueueHandled) => agent_id === id),
                stats: dataAgentsStats?.[id],
                status: agentsStatus?.[id],
              }}
              onClick={{
                [AgentActionType.LOG_IN_AGENT]: () =>
                  handleAction(AgentActionType.LOG_IN_AGENT, id),
                [AgentActionType.LOG_OUT_AGENT]: () =>
                  handleAction(AgentActionType.LOG_OUT_AGENT, id),
                [AgentActionType.RESTART_AGENT]: () =>
                  handleAction(AgentActionType.RESTART_AGENT, id),
              }}
            />
          ))
        ) : (
          <NoItemsText
            type={DashboardItemType.AGENTS}
            value={t('call_center:containers.dashboard.section.agents.no_agents_to_display')}
          />
        )}
      </Box>
    </StyledDashboardAgents>
  );
};

export default DashboardAgents;
