import Dialog from 'shared/components/Dialog';
import Row from './components/Row';

const defaultProps = {
  data: [],
  components: {
    dialog: {
      button: {
        save: { props: {} },
        delete: {
          props: {},
        },
      },
      component: Dialog,
      defaultValues: {},
      schema: () => {},
      title: {
        create: '__CREATE_ITEM__',
        delete: '__DELETE_ITEM__',
        update: '__UPDATE_ITEM__',
      },
      onCancel: () => {},
      onSave: () => {},
      onDelete: () => {},
    },
    row: {
      component: Row,
      title: '__EDITABLE_TABLE_ROW__',
      onDelete: (id: string): void => {},
      onUpdate: (data: any, id?: string): string => 'update',
    },
  },
  text: {
    button: '__EDITABLE_TABLE_BUTTON__',
    heading: '__EDITABLE_TABLE_HEADING__',
    nodata: '__EDITABLE_TABLE_NO_DATA__',
  },
  // info: 'Captain, why are we out here chasing comets? I think you\'ve let your personal feelings cloud your judgement.',
};

export default defaultProps;
