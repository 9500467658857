import Joi from 'joi';
import { messages } from 'shared/utility/validation';

const schema = () =>
  Joi.object({
    timezone: Joi.string().required(),
    rules: Joi.array().items(),
    nodeId: Joi.string(),
  }).messages(messages());

export default schema;
