import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import styled, { css } from 'styled-components';

const StyledSideNav = styled.aside`
  ${(props) => css`
    width: ${HEADER_CONSTANTS.LOGO.WIDTH};
    min-width: ${HEADER_CONSTANTS.LOGO.WIDTH};
    height: 100%;
    padding: 15px;
    background-color: ${props.theme.core.background.grey.light.color};
    overflow-y: auto;
  `}
`;

export default StyledSideNav;
