import i18next from 'i18next';
import { useToast } from 'shared/hooks/useToast';

export const useShowMasqueradingToast = () => {
  const { showToast } = useToast();

  const showMasqueradingToast = (accountName?: string) =>
    showToast.info(
      accountName
        ? i18next.t('common:component.current_account.masquerading_toast.start', {
            accountName,
          })
        : i18next.t('common:component.current_account.masquerading_toast.stop'),
    );

  return { showMasqueradingToast };
};
