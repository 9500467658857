import { CHARACTER } from 'constant';
import { QueueCardDataType } from './definition';

const defaultProps = {
  hasMenu: true,
  data: {
    [QueueCardDataType.ACTIVE_AGENTS_CURRENT]: CHARACTER.EMDASH,
    [QueueCardDataType.ACTIVE_AGENTS_TOTAL]: CHARACTER.EMDASH,
    [QueueCardDataType.ACTIVE_CALLS]: CHARACTER.EMDASH,
    [QueueCardDataType.AVERAGE_WAIT_TIME]: CHARACTER.EMDASH,
    [QueueCardDataType.MISSED_CALLS]: CHARACTER.EMDASH,
    [QueueCardDataType.TOTAL_CALLS]: CHARACTER.EMDASH,
  },
  onClick: {
    default: () => {},
    edit: {
      button: () => {},
      menu: () => {},
    },
    hide: () => {},
  },
};

export default defaultProps;
