import { FunctionComponent } from 'react';
import InputLabel from 'shared/components/InputLabel';
import InputMessage from 'shared/components/InputMessage';
import Radio, { RadioProps } from 'shared/components/Radio';
import RadioGroup from 'shared/components/RadioGroup';
import * as utility from 'shared/utility';
import { DirtyIcon, InfoIcon } from '../..';
import defaultProps from './default';
import { LabeledRadioGroupProps as Props } from './definition';
import StyledLabeledRadioGroup from './style';

const LabeledRadioGroup: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    id,
    hasDirtyIcon,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    radioGroupProps,
    radioProps,
    feedback,
    tooltip,
    tooltipWidth,
  }: Props = {
    ...defaultProps,
    ...props,
  };
  const forId = id || utility.generateId('labeled-radiogroup');

  return (
    <StyledLabeledRadioGroup isLabelAbove={isLabelAbove} inputWidth="auto" labelWidth={labelWidth}>
      <div>
        {label && (
          <InputLabel htmlFor={forId} {...labelProps} isAbove={isLabelAbove} width={labelWidth}>
            {label}
          </InputLabel>
        )}
        <div>
          {!!tooltip && <InfoIcon className={tooltipWidth} tooltip={tooltip} isInline />}
          {hasDirtyIcon && <DirtyIcon isDirty={isDirty} isInline />}
        </div>
      </div>
      <div>
        <div>
          <RadioGroup {...radioGroupProps}>
            {radioProps.map(({ label, value, ...rest }: RadioProps, i: number) => (
              <Radio key={`${label}-${i}`} {...rest} label={label} value={value || label} />
            ))}
          </RadioGroup>
          {feedback && <InputMessage type={feedback.type} message={feedback.message} />}
        </div>
      </div>
    </StyledLabeledRadioGroup>
  );
};

export default LabeledRadioGroup;
