import { RequestMethod } from 'definition';
import i18next from 'i18next';

const translations = () => ({
  method: {
    label: i18next.t('phone_system:containers.callflows.callflow_action_dialog.pivot.add.method'),
  },
  methods: [
    { value: RequestMethod.Post, label: RequestMethod.Post },
    { value: RequestMethod.Get, label: RequestMethod.Get },
  ],
  req_format: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.pivot.add.req_format',
    ),
  },
  req_formats: [
    {
      value: 'twiml',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.pivot.req_formats.twiml',
      ),
    },
    {
      value: 'kazoo',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.pivot.req_formats.kazoo',
      ),
    },
  ],
  title: i18next.t('phone_system:containers.callflows.callflow_action_dialog.pivot.title'),
  voice_url: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.pivot.add.voice_url',
    ),
  },
});

export default translations;
