import i18next from 'i18next';
import Box from 'shared/components/Box';
import { MissingValueProps as Props } from './definition';
import StyledMissingValue from './style';

const StreetAddress = ({ streetAddress }: Props) => {
  const missingAddress = i18next.t('common:missing_address') as string;
  const address = streetAddress ?? missingAddress;

  return address === missingAddress ? (
    <StyledMissingValue>{missingAddress}</StyledMissingValue>
  ) : (
    <Box component="span">{address}</Box>
  );
};

export default StreetAddress;
