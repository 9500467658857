import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { ChangeEvent, FunctionComponent, KeyboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'shared/components/Button';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import useConfig from 'shared/hooks/useConfig';
import SamlSsoLogin from '../SamlSsoLogin';
import Feedback, { FeedbackType } from './components/Feedback';
import ForgotInfo from './components/ForgotInfo';
import { LoginFormProps as Props } from './definition';
import StyledLoginForm from './style';

const LoginForm: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { error, onClick, onClickRememberMe, onSubmit } = props;
  const { t } = useTranslation();
  const config = useConfig();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const messageTypeParam = searchParams.get('message_type');

  const getFeedbackType = (() => {
    if (error) {
      return FeedbackType.ERROR;
    }
    if (messageTypeParam === 'reset_successful') {
      return FeedbackType.SUCCESS;
    }
    return undefined;
  })();

  const onKeyDown: KeyboardEventHandler<HTMLFormElement> = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <StyledLoginForm onKeyDown={onKeyDown}>
      {getFeedbackType && <Feedback type={getFeedbackType} />}

      <HookFormInputWrapper name="username">
        {({ isDirty, feedback, ...formProps }) => (
          <LabeledInput
            hasDirtyIcon={false}
            isLabelAbove
            inputWidth="auto"
            label={t('common:auth.label.username')}
            id="input-username"
            feedback={feedback}
            inputProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="password">
        {({ isDirty, feedback, ...formProps }) => (
          <LabeledInput
            hasDirtyIcon={false}
            isLabelAbove
            inputWidth="auto"
            label={t('common:auth.label.password')}
            id="input-password"
            feedback={feedback}
            inputProps={{
              type: `${isPasswordVisible ? 'text' : 'password'}`,
              adornment: {
                position: 'end',
                type: 'icon',
                value: `visibility-${isPasswordVisible ? 'off' : 'on'}-filled`,
                onClick: () => setIsPasswordVisible(!isPasswordVisible),
              },
              ...formProps,
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="account_name">
        {({ isDirty, feedback, ...formProps }) => (
          <LabeledInput
            hasDirtyIcon={false}
            isLabelAbove
            inputWidth="auto"
            label={t('common:auth.label.account_name')}
            id="input-account-name"
            feedback={feedback}
            inputProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormInputWrapper>

      <ForgotInfo>
        <>
          <LabeledCheckbox
            hasDirtyIcon={false}
            indentWidth="none"
            label={t('common:auth.login.remember_me')}
            id="input-remember-me"
            checkboxProps={{
              onChange: (e: ChangeEvent<HTMLInputElement>) => onClickRememberMe(e.target.checked),
            }}
          />
          <a onClick={() => onClick(false)}>{t('common:auth.login.forgot_info')}</a>
        </>
      </ForgotInfo>

      <Button color="error" size="login" variant="contained" onClick={onSubmit}>
        {t('common:auth.label.sign_in')}
      </Button>

      {config?.saml_sso && <SamlSsoLogin config={config} />}
    </StyledLoginForm>
  );
};

export default LoginForm;
