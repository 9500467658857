import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import Typography from 'shared/components/Typography';
import { DashboardQueuesToggleProps as Props } from './definition';
import StyledDashboardQueuesToggle from './style';

export { CONSTANTS as QUEUES_TOGGLE_CONSTANTS } from './style';

const DashboardQueuesToggle: FunctionComponent<Props> = ({
  hasQueues,
  onClick,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledDashboardQueuesToggle
      hasqueues={hasQueues.toString()}
      onClick={onClick}
      data-test-id="call-center-dashboard-dashboard-queues-toggle"
    >
      <Icon name="chevron-down-outlined" size={18} />
      <Typography>
        {hasQueues
          ? t('call_center:containers.dashboard.section.queues_toggle.hide_queues')
          : t('call_center:containers.dashboard.section.queues_toggle.show_queues')}
      </Typography>
    </StyledDashboardQueuesToggle>
  );
};

export default DashboardQueuesToggle;
