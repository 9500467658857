import {
  HookFormInputWrapper,
  HookFormRadioGroupWrapper,
  HookFormSelectWrapper,
} from 'apps/shared/components/HookForm';
import { inviteFormatOptions } from 'apps/SipTrunking/containers/Edit/definition';
import { getAuthRadioOptions, manufacturersWithImage } from 'apps/SipTrunking/utility';
import { FunctionComponent, ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from 'shared/components/Input';
import {
  LabeledInput,
  LabeledRadioGroup,
  LabeledSelect,
  LabeledSwitch,
  LabeledText,
} from 'shared/components/Labeled';

const BasicSection: FunctionComponent = (): ReactElement => {
  const { getValues, setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const authRadioOptions = getAuthRadioOptions();
  const authenticationTypeVal = watch('server.auth.auth_method');

  const resetAuthFields = (): void => {
    setValue('server.auth.auth_user', '');
    setValue('server.auth.auth_password', '');
    setValue('server.auth.ip', '');
  };

  return (
    <>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="id">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <Input inputProps={{ ...formProps }} hidden />
            )}
          </HookFormInputWrapper>

          {/* manufacturers */}
          <HookFormSelectWrapper name="server.server_type" options={manufacturersWithImage}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                feedback={feedback}
                label={t('sip_trunking:containers.all_trunks.field.pbx_manufacturer')}
                selectProps={{
                  ...formProps,
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
        <div role="cell">
          {/* Account Name */}
          <HookFormInputWrapper name="server.server_name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('sip_trunking:containers.all_trunks.field.pbx_nickname')}
                inputProps={{
                  ...formProps,
                  placeholder: t('sip_trunking:containers.all_trunks.field.pbx_nickname'),
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <h2>{t('sip_trunking:containers.all_trunks.sections.inbound_configuration')}</h2>
      <div role="row">
        <div role="cell">
          {/* Carriers Radio Group */}
          <HookFormRadioGroupWrapper name="server.auth.auth_method" options={authRadioOptions}>
            {({ ref, isDirty, onChange, ...formProps }) => {
              return (
                <LabeledRadioGroup
                  isLabelAbove
                  isDirty={isDirty}
                  label={t('sip_trunking:containers.all_trunks.field.authentication')}
                  radioGroupProps={{
                    ...formProps,
                    onChange: (value: any) => {
                      resetAuthFields();
                      onChange(value);
                    },
                  }}
                  radioProps={authRadioOptions}
                  tooltip={t('sip_trunking:containers.all_trunks.tooltips.authentication')}
                />
              );
            }}
          </HookFormRadioGroupWrapper>
        </div>
      </div>
      {authenticationTypeVal === 'Password' && (
        <div role="row">
          <div role="cell">
            <HookFormInputWrapper name="server.auth.auth_user">
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  isDirty={isDirty}
                  feedback={feedback}
                  label={t('sip_trunking:containers.all_trunks.field.username')}
                  inputProps={{
                    ...formProps,
                    placeholder: t('sip_trunking:containers.all_trunks.field.username'),
                  }}
                  labelProps={{ required: true }}
                />
              )}
            </HookFormInputWrapper>
          </div>
          <div role="cell">
            {/* Password */}
            <HookFormInputWrapper name="server.auth.auth_password">
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  isDirty={isDirty}
                  feedback={feedback}
                  label={t('sip_trunking:containers.all_trunks.field.password')}
                  inputProps={{
                    ...formProps,
                    type: 'text',
                    placeholder: t('sip_trunking:containers.all_trunks.field.password'),
                    autoComplete: 'new-password',
                  }}
                  labelProps={{ required: true }}
                />
              )}
            </HookFormInputWrapper>
          </div>
        </div>
      )}
      {authenticationTypeVal === 'IP' && (
        <div role="row">
          <div role="cell">
            {/* IP Address */}
            <HookFormInputWrapper name="server.auth.ip">
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  isDirty={isDirty}
                  feedback={feedback}
                  label={t('sip_trunking:containers.all_trunks.field.ip_address')}
                  inputProps={{
                    ...formProps,
                    placeholder: t('sip_trunking:containers.all_trunks.field.ip_address'),
                  }}
                  labelProps={{ required: true }}
                />
              )}
            </HookFormInputWrapper>
          </div>
        </div>
      )}
      <div role="row">
        <div role="cell">
          {/* Invite Format */}
          <HookFormSelectWrapper name="server.options.inbound_format" options={inviteFormatOptions}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                tooltip={t('sip_trunking:containers.all_trunks.tooltips.invite_format')}
                isDirty={isDirty}
                feedback={feedback}
                label={t('sip_trunking:containers.all_trunks.field.invite_format')}
                selectProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Outbound Flag */}
          <HookFormInputWrapper name="server.options.flags">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                tooltip={t('sip_trunking:containers.all_trunks.tooltips.outbound_flag')}
                label={t('sip_trunking:containers.all_trunks.field.outbound_flag')}
                inputProps={{
                  ...formProps,
                  placeholder: t('sip_trunking:containers.all_trunks.field.outbound_flag'),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row" className="overline">
        <div role="cell">
          <HookFormInputWrapper name="server.options.compatibilityMode" isCheckbox>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSwitch
                isDirty={isDirty}
                hasMargin
                tooltip={[
                  t('sip_trunking:containers.all_trunks.tooltips.compatibilityMode.line1'),
                  t('sip_trunking:containers.all_trunks.tooltips.compatibilityMode.line2'),
                  t('sip_trunking:containers.all_trunks.tooltips.compatibilityMode.line3'),
                ]}
                label={t('sip_trunking:containers.all_trunks.field.compatibility_mode')}
                switchProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Account ID */}
          <LabeledText
            label={t('sip_trunking:containers.all_trunks.field.server_realm')}
            tooltip={t('sip_trunking:containers.all_trunks.tooltips.server_realm')}
            text={getValues('account.auth_realm')}
          />
        </div>
      </div>
    </>
  );
};

export default BasicSection;
