import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { CONSTANTS } from './components/constant';
import { style } from './components/style';

const StyledHeader = styled.header`
  ${({ theme }) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};
    height: ${CONSTANTS.HEIGHT};
    background-color: ${theme.core.background.grey.light.color};
    border-bottom: 1px solid ${theme.core.divider.default};

    > * {
      ${style.default};
    }

    > div {
      ${mixin.flex()};
      width: ${CONSTANTS.LOGO.WIDTH};
      min-width: ${CONSTANTS.LOGO.WIDTH};
      background-color: ${theme.whitelabel.primary.color};
      cursor: pointer;

      img {
        width: 80px;
      }
    }

    h1 {
      ${mixin.font({ size: 24, weight: 600, lineHeight: CONSTANTS.HEIGHT })};
      flex-grow: 1;
      margin: 0;
      padding: ${theme.spacing(0, 4)};
      color: ${theme.core.text.dark};
      white-space: nowrap;
    }

    nav {
      ${mixin.flex({ alignItems: 'stretch' })};

      > div {
        border-left: 1px solid ${theme.core.divider.default};

        &:nth-child(3),
        &:nth-child(4) {
          ${mixin.flex()};
          padding: ${theme.spacing(0, 4)};
        }

        &:nth-child(4) {
          ${style.item({ theme }).default};
        }
      }
    }
  `}
`;

export default StyledHeader;
