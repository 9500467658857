import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';
import { Metadata } from '../store/definition';

class AgentPauseActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.AGENT_PAUSE,
      module: CallFlowModuleKey.AGENT_PAUSE,
      section: CallFlowSection.CALL_CENTER,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'pause',
      },
      label: i18next.t('phone_system:containers.callflows.actions.agent_pause.name'),
    });
  }

  static subtitle = (nodeData: any, metadata: Metadata) =>
    i18next.t('phone_system:containers.callflows.actions.agent_pause.subtitle', {
      timeout: nodeData?.timeout,
      presence_id: nodeData?.presence_id,
    });
}

export default AgentPauseActionConfig;
