import Joi from 'joi';
import { messages } from 'shared/utility/validation';

const schema = () =>
  Joi.object({
    caller_id_name: Joi.string(),
    caller_id_number: Joi.string(),
    nodeId: Joi.string(),
  }).messages(messages());

export default schema;
