import i18next from 'i18next';
import {
  BuyNumberFlowAction,
  BuyNumberFlowComponent,
  BuyNumberFlowType,
  BuyNumbersFlowComponent,
} from './definition';

const translations = () => ({
  button: {
    cancel: {
      pick: i18next.t('common:component.buy_number_flow.components.pick_numbers.cancel'),
    },
    confirm: {
      pick: i18next.t('common:component.buy_number_flow.components.pick_numbers.confirm'),
      search: i18next.t('common:component.buy_number_flow.components.search_for_numbers.confirm'),
    },
  },
  message: {
    activate: i18next.t('common:component.buy_number_flow.activating_numbers.message'),
    pick: i18next.t('common:component.buy_number_flow.components.pick_numbers.message'),
  },
  title: {
    pick: i18next.t('common:component.buy_number_flow.components.pick_numbers.title'),
    search: {
      local: i18next.t(
        'common:component.buy_number_flow.components.search_for_numbers.flow_type.title.local',
      ),
      tollFree: i18next.t(
        'common:component.buy_number_flow.components.search_for_numbers.flow_type.title.toll_free',
      ),
    },
  },
});

export const getFlowMeta = (): Record<string, Array<BuyNumbersFlowComponent>> => {
  const { button, message, title } = translations();

  return {
    [BuyNumberFlowType.Local]: [
      {
        buttonLabel: {
          confirm: button.confirm.search,
        },
        component: BuyNumberFlowComponent.Search,
        requiresAreaCode: true,
        route: {
          default: `${BuyNumberFlowType.Local}/${BuyNumberFlowAction.Search}`,
          next: `${BuyNumberFlowType.Local}/${BuyNumberFlowAction.Pick}`,
        },
        title: title.search.local,
      },
      {
        buttonLabel: {
          cancel: button.cancel.pick,
          confirm: button.confirm.pick,
        },
        component: BuyNumberFlowComponent.Pick,
        message: message.pick,
        requiresSelected: true,
        route: {
          cancel: `${BuyNumberFlowType.Local}/${BuyNumberFlowAction.Search}`,
          default: `${BuyNumberFlowType.Local}/${BuyNumberFlowAction.Pick}`,
          next: `${BuyNumberFlowType.Local}/${BuyNumberFlowAction.Activate}`,
        },
        title: title.pick,
      },
      {
        component: BuyNumberFlowComponent.Activate,
        message: message.activate,
        route: {
          default: `${BuyNumberFlowType.Local}/${BuyNumberFlowAction.Activate}`,
        },
      },
    ],
    [BuyNumberFlowType.TollFree]: [
      {
        buttonLabel: {
          confirm: button.confirm.search,
        },
        component: BuyNumberFlowComponent.Search,
        requiresAreaCode: true,
        route: {
          default: `${BuyNumberFlowType.TollFree}/${BuyNumberFlowAction.Search}`,
          next: `${BuyNumberFlowType.TollFree}/${BuyNumberFlowAction.Pick}`,
        },
        title: title.search.tollFree,
      },
      {
        buttonLabel: {
          cancel: button.cancel.pick,
          confirm: button.confirm.pick,
        },
        component: BuyNumberFlowComponent.Pick,
        message: message.pick,
        requiresSelected: true,
        route: {
          cancel: `${BuyNumberFlowType.TollFree}/${BuyNumberFlowAction.Search}`,
          default: `${BuyNumberFlowType.TollFree}/${BuyNumberFlowAction.Pick}`,
          next: `${BuyNumberFlowType.TollFree}/${BuyNumberFlowAction.Activate}`,
        },
        title: title.pick,
      },
      {
        component: BuyNumberFlowComponent.Activate,
        message: message.activate,
        route: {
          default: `${BuyNumberFlowType.TollFree}/${BuyNumberFlowAction.Activate}`,
        },
      },
    ],
  };
};
