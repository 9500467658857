import { TABLE_CONSTANTS } from 'shared/components/Table';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFileInfoProps as Props } from './definition';

export const StyledFileInfo = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};
    border-bottom: ${props.fileBorder ? TABLE_CONSTANTS.BORDER : 'none'};
    border-top: ${props.fileBorder ? TABLE_CONSTANTS.BORDER : 'none'};
    margin-top: ${props.fileBorder ? props.theme.spacing(4) : 'auto'};
    width: ${props.fileBorder ? '100%' : 'auto'};
  `}
`;
