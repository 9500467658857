import {
  HookFormInputWrapper,
  HookFormTimeZoneSelectWrapper,
} from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['retries', 'fax_timezone', 'media.fax_option'];

const OptionsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div role="row">
      <div role="cell">
        {/* Retries */}
        <HookFormInputWrapper name="retries">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              id="input-options-retries"
              label={t('phone_system:containers.faxes.section.options.retries.label')}
              tooltip={t('phone_system:containers.faxes.section.options.retries.tooltip')}
              inputProps={{
                ...formProps,
              }}
              isDirty={isDirty}
              feedback={feedback}
            />
          )}
        </HookFormInputWrapper>

        {/* Fax Time Zone */}
        <HookFormTimeZoneSelectWrapper
          name="fax_timezone"
          label={t('phone_system:containers.faxes.section.options.fax_timezone.label')}
          tooltip={t('phone_system:containers.faxes.section.options.fax_timezone.tooltip')}
          id="input-options-fax-timezone"
        />

        {/* T.38 */}
        <HookFormInputWrapper name="media.fax_option" isCheckbox>
          {({ ref, value, isDirty, feedback, ...formProps }) => (
            <LabeledCheckbox
              label={t('phone_system:containers.faxes.section.options.t_38.label')}
              tooltip={t('phone_system:containers.faxes.section.options.t_38.tooltip')}
              checkboxProps={{
                ...formProps,
                id: 'checkbox-options-T38',
              }}
              isDirty={isDirty}
              feedback={feedback}
            />
          )}
        </HookFormInputWrapper>
      </div>
    </div>
  );
};

export default OptionsSection;
