// Conference Queries
import { Conference } from 'api/conference';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const conferenceQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchConferences: builder.query<ConferenceResponse, void>({
      query: () => ({
        url: Conference.Fetch(),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.CONFERENCE as const, id })),
              { type: TAGS.CONFERENCE, id: 'LIST' },
            ]
          : [{ type: TAGS.CONFERENCE, id: 'LIST' }],
    }),
    fetchConferenceById: builder.query<Conference, ByIdParam>({
      query: ({ id }) => ({
        url: Conference.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.CONFERENCE, id }];
      },
    }),
    fetchConferencesByOwnerId: builder.query<ConferenceResponse, ByIdParam>({
      query: ({ id }) => ({
        url: Conference.FetchByOwnerId(id),
        responseHandler,
      }),
      extraOptions: { maxRetries: 1 },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.CONFERENCE as const, id })),
              { type: TAGS.CONFERENCE, id: 'LIST' },
            ]
          : [{ type: TAGS.CONFERENCE, id: 'LIST' }],
    }),
  }),
});
