import { NumberSelector } from 'apps/PhoneSystem/shared/NumberSelector';
import { OnNumberChangeParam } from 'apps/PhoneSystem/shared/NumberSelector/definition';
import { FunctionComponent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { DialogActionsCloseReasons } from 'shared/components/Dialog/definition';
import Typography from 'shared/components/Typography';
import { FormFields } from '../../definition';
import WarningDialog from './components/WarningDialog';
import { BridgeNumberChangeAction, BridgeNumberSelectProps as Props } from './definition';
import { getBridgeNumberChangeAction } from './utility';

const BridgeNumberSelect: FunctionComponent<Props> = (props: Props) => {
  const { initialNumber } = props;

  const { t } = useTranslation();
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const { setValue, resetField, watch } = useFormContext();

  const bridgeNumberChangeAction = watch(FormFields.TempBridgeNumberChangeAction);

  const onNumberChange = ({ number }: OnNumberChangeParam) => {
    const action = getBridgeNumberChangeAction({
      initialNumber,
      nextNumber: number,
    });

    setValue(FormFields.TempBridgeNumberChangeAction, action);

    if ([BridgeNumberChangeAction.Switch, BridgeNumberChangeAction.Unassign].includes(action)) {
      setIsWarningDialogOpen(true);
    }
  };

  const onClose = () => {
    resetField(FormFields.BridgeNumber);
    setIsWarningDialogOpen(false);
  };

  const onWarningDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onClose();
        break;
      case 'saveClicked':
        setIsWarningDialogOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Typography variant="h2">
        {t(
          'phone_system:containers.account.submodule.settings.section.cellular_enablement.heading',
        )}
      </Typography>
      <Typography variant="inherit" sx={{ whiteSpace: 'pre-line' }}>
        {t(
          'phone_system:containers.account.submodule.settings.section.cellular_enablement.subheading',
        )}
      </Typography>
      <Box role="row" className="one-column">
        <Box role="cell">
          <NumberSelector
            name={FormFields.BridgeNumber}
            fieldPath={FormFields.BridgeNumber}
            label={t(
              'phone_system:containers.account.submodule.settings.section.cellular_enablement.bridge_number.label',
            )}
            placeholder={t(
              'phone_system:containers.account.submodule.settings.section.cellular_enablement.bridge_number.placeholder',
            )}
            onNumberChange={onNumberChange}
            initialNumber={initialNumber || ''}
          />
          <WarningDialog
            open={isWarningDialogOpen}
            action={bridgeNumberChangeAction}
            onClose={onClose}
            onAction={onWarningDialogAction}
          />
        </Box>
      </Box>
    </>
  );
};

export default BridgeNumberSelect;
