import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogType } from 'shared/components/Dialog';
import Loading from 'shared/components/Loading';
import { LoadingNumbersProps as Props } from './definition';

const LoadingNumbers: FunctionComponent<Props> = ({ isOpen }: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Dialog hasClose={false} hasTitle={false} open={isOpen} type={DialogType.Medium}>
      <Loading
        hasMargin
        hasLargeProgress
        label={t('numbers:action_row.add_number.dialog.loading_numbers.label')}
      />
    </Dialog>
  );
};

export default LoadingNumbers;
