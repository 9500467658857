import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFeatureCodeProps as Props } from './definition';

export const CONSTANTS = {
  WIDTH: {
    ICON: 32,
  },
};

const StyledFeatureCode = styled(Box)<Props>`
  ${({ theme }) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};

    > div {
      ${mixin.flex()};
      /* background-color: ${theme.brio.ooma.lgrey}; */
      border: 1px solid ${theme.core.input.border.default};
      border-radius: 4px !important;

      --width: calc(40px - 1px);
      --height: calc(40px - 2px);

      > * {
        height: var(--height);
      }

      > div {
        &:first-of-type {
          ${mixin.flex()};
          width: var(--width);
          background-color: ${theme.core.background.grey.medium};
          font-size: 12px;
        }

        &.MuiInputBase-root {
          width: 100px;
          border-top: none;
          border-bottom: none;
          border-radius: 0 !important;

          input {
            height: inherit;

            /* &.Mui-disabled {
              background-color: ${theme.brio.ooma.lgrey};
            } */
          }
        }
      }

      .MuiCheckbox-root {
        width: var(--width);
      }
    }

    > svg {
      margin: ${theme.spacing(0, 0, 0, 2)};
    }
  `}
`;

export default StyledFeatureCode;
