import { FunctionComponent } from 'react';
import ListItem from './components/ListItem';
import { ListProps as Props } from './definition';
import StyledList from './style';

const List: FunctionComponent<Props> = ({ items }: Props): JSX.Element => (
  <StyledList data-test-id="shared-tooltip-list">
    {items.map((item: string) => (
      <ListItem key={`shared-tooltip-list-item-${item.toLocaleLowerCase()}`} item={item} />
    ))}
  </StyledList>
);

export default List;
