import { themeColor } from 'theme';
import theme from '../../core';

export default {
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.secondary.main,
  borderWidth: 1,
  color: theme.palette.secondary.main,
  fontWeight: 600,
  transition: theme.transitions.create(['background-color', 'color'], {
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  '&.Mui-disabled': {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${themeColor.core.color.grey_b5b5b5} !important`,
  },
  '&.MuiButton-outlinedSecondary': {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
  '&.MuiButton-outlinedError': {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  },
  '&.MuiButton-outlinedInfo': {
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.info.main,
      color: theme.palette.common.white,
    },
  },
  '&.MuiButton-outlinedSuccess': {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      borderColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },
  },
  '&.MuiButton-outlinedWarning': {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
      color: theme.palette.common.white,
    },
  },
};
