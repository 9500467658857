// Conference Mutations
import { Conference } from 'api/conference';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const conferenceMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createConference: builder.mutation<Conference, Partial<Conference>>({
      query: ({ body }) => ({
        url: Conference.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.CONFERENCE, id: 'LIST' }],
    }),
    updateConference: builder.mutation<Conference, UpdateConferenceParams>({
      query: ({ id, body }) => ({
        url: Conference.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.CONFERENCE, id }],
    }),
    deleteConference: builder.mutation<Conference, ByIdParam>({
      query: ({ id }) => ({
        url: Conference.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: [{ type: TAGS.CONFERENCE, id: 'LIST' }],
    }),
  }),
});
