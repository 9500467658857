import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledVersionTagProps as Props } from './definition';

const StyledVersionTag = styled(Box).attrs({ component: 'span' })<Props>`
  ${({ $hasAuxColor, $isSmall, theme }) => css`
    ${mixin.font({ size: $isSmall ? 14 : 16, weight: 600 })};
    color: ${$hasAuxColor ? theme.core.background.grey.medium : theme.core.color.grey.pewter};
    text-transform: uppercase;
  `}
`;

export default StyledVersionTag;
