import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledDeleteOptionsProps as Props } from './definition';

const TRANSITION = {
  DELAY: 150,
  DURATION: 200,
};

export const StyledDeleteOptions = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex({ direction: 'column', alignItems: 'flex-start' })};
    ${mixin.transition({ property: 'width', duration: 0, delay: TRANSITION.DELAY })};
    position: relative;
    width: 0;
    padding: 4px;
    overflow: hidden;
    z-index: 100;

    ${props.isVisible &&
    css`
      ${mixin.transition({ property: 'width', duration: 0 })};
      width: 240px;
    `}

    span {
      ${mixin.borderRadius()};
      ${mixin.boxShadow({ offsetY: 1, blurRadius: 2.5 })};
      ${mixin.transition([
        { property: 'color', duration: TRANSITION.DURATION },
        { property: 'opacity' },
      ])};
      display: inline-block;
      padding: 8px 12px;
      background-color: ${props.theme.core.background.white.off};
      cursor: pointer;
      opacity: 0;
      user-select: none;
      white-space: nowrap;

      &:hover {
        ${mixin.hover({ letterSpacing: -0.011 })};
        color: ${props.theme.core.color.blue.draggable};
      }

      &:nth-of-type(2) {
        margin: 8px 0 0;
      }

      ${props.isVisible &&
      css`
        opacity: 1;

        &:nth-of-type(1) {
          ${mixin.transition([
            { property: 'color', duration: TRANSITION.DURATION },
            { property: 'opacity' },
          ])};
        }

        &:nth-of-type(2) {
          ${mixin.transition([
            { property: 'color', duration: TRANSITION.DURATION },
            { property: 'opacity', delay: TRANSITION.DELAY },
          ])};
        }
      `}
    }
  `};
`;

export default StyledDeleteOptions;
