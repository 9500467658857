import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFlowRootProps as Props } from './definition';

export const CONSTANTS = {
  DASHED_BORDER: {
    SIZE: '3px',
  },
};

const StyledFlowRoot = styled.div<Props>`
  ${(props) => css`
    ${mixin.borderRadius()};
    --width: 400px;
    width: ${`calc(var(--width) + ${CONSTANTS.DASHED_BORDER.SIZE} * 2)`};
    margin: 0 auto;
    border: ${CONSTANTS.DASHED_BORDER.SIZE} dashed transparent;

    ${props.isDragging &&
    props.isDroppable &&
    css`
      border-color: ${props.theme.core.color.blue.draggable};
    `};

    > div {
      ${mixin.borderRadius()};
      ${mixin.boxShadow({ offsetY: 1, blurRadius: 3 })};
      ${mixin.flex({ direction: 'column' })};
      width: var(--width);
      margin: inherit;
      background-color: ${props.theme.whitelabel.secondary.color};

      > div {
        width: inherit;
        --height: 32px;

        &,
        &:first-of-type {
          svg {
            cursor: pointer;
            fill: ${props.theme.core.background.grey.dark};
          }
        }

        & svg {
          ${mixin.transition({ property: 'fill', duration: 'medium' })};
        }

        &:first-of-type {
          ${mixin.flex({ justifyContent: 'space-between' })};
          ${mixin.font({ size: 16, weight: 600 })};
          height: var(--height);
          padding: 0 8px 0 16px;
          color: ${props.theme.core.color.white};
          user-select: none;

          svg {
            ${mixin.transition({ property: 'transform', duration: 'medium' })};
            fill: ${props.theme.core.color.white};
          }
        }

        &:last-of-type {
          display: grid;
          grid-template: var(--height) / repeat(2, 1fr);
          grid-auto-rows: var(--height);
          --gap: 2px;
          grid-gap: var(--gap);
          padding: var(--gap);

          div {
            ${mixin.font({ size: 13 })};
            display: grid;
            grid-template: 1fr / 1fr auto 1fr;
            align-items: center;
            width: 100%;
            padding: 0 8px;
            background-color: ${props.theme.core.color.white};

            &:nth-last-of-type(2) {
              ${mixin.borderRadius({ radius: '0 0 0 4px' })};

              &:nth-of-type(even) {
                ${mixin.borderRadius({ radius: '0' })};
              }
            }

            &:nth-last-of-type(1) {
              ${mixin.borderRadius({ radius: '0 0 4px 0' })};

              &:nth-of-type(odd) {
                ${mixin.borderRadius({ radius: '0 0 4px 4px' })};
                width: calc(var(--width) - var(--gap) * 2);
                grid-column: span 2;
              }
            }

            &:last-of-type span {
              ${mixin.transition({ property: 'color', duration: 'medium' })};
              display: inline-block;
              cursor: pointer;
              user-select: none;

              &:hover {
                ${mixin.hover({ letterSpacing: -0.011 })};
                color: ${props.theme.core.color.blue.draggable};
              }
            }

            span {
              grid-column-start: 2;
              color: ${props.theme.whitelabel.secondary.color};
            }

            svg {
              margin-left: auto;
              fill: ${props.theme.whitelabel.secondary.color};

              &:hover {
                fill: ${props.theme.core.color.blue.draggable};
              }
            }
          }
        }
      }
    }
  `};
`;

export default StyledFlowRoot;
