import { themeColor } from 'theme/local';
import theme from 'theme/mui/core';

const CONSTANTS = {
  WIDTH: 100,
  HEIGHT: 60,
};

export default {
  margin: theme.spacing(2, 0),
  backgroundColor: 'white',
  border: `1px solid ${themeColor.core.border.color}`,
  borderRadius: 0,
  '&, .MuiCardContent-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: CONSTANTS.WIDTH,
    height: CONSTANTS.HEIGHT,
    padding: 0,
  },
  '.MuiCardContent-root': {
    img: {
      maxWidth: `calc(${CONSTANTS.WIDTH}px - ${theme.spacing(2)})`,
      maxHeight: `calc(${CONSTANTS.HEIGHT}px - ${theme.spacing(2)})`,
    },
  },
};
