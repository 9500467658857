import { checkForSmsBoxOwner } from 'apps/Numbers/containers/NumbersInUse/Edit/utility';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { ADD_KEY } from 'constant';
import merge from 'lodash/merge';
import { useFetchGroupsQuery } from 'models/Group';
import { useDeleteSMSMutation, useFetchSMSByIdQuery, useUpdateSMSMutation } from 'models/SMS';
import { useFetchUsersQuery } from 'models/User';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import { defaultValues } from './default';
import { FormInput, SMSEditProps as Props } from './definition';
import Form from './Form';
import { transformSMSForSaving } from './utility';

const Edit = ({ id, handleSaveSuccess, handleDeleteSuccess }: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { data: smsData, isLoading: isLoadingSmsData } = useFetchSMSByIdQuery({ id });
  const { data: usersData = [], isLoading: isLoadingUsersData } = useFetchUsersQuery();
  const { data: groupsData = [], isLoading: isLoadingGroupsData } = useFetchGroupsQuery();
  const [updateSMS] = useUpdateSMSMutation();
  const [deleteSMS] = useDeleteSMSMutation();
  const phoneNumber = smsData?.numbers[0] || '';

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
  });

  const {
    setError,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = formMethods;

  const isPageDirty = checkFormEntities(dirtyFields);

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (body) => {
      try {
        await updateSMS({ id, body: transformSMSForSaving(body) }).unwrap();
        handleSaveSuccess?.();
      } catch (exception) {
        showErrorMessage({ isFromException: true, errors: exception, setError });
      }
    },
    [id, handleSaveSuccess, setError, showErrorMessage, updateSMS],
  );

  const handleDelete = async () => {
    try {
      await deleteSMS({ id }).unwrap();
      handleDeleteSuccess?.({
        toastMessage: t('phone_system:containers.sms.edit.actions.delete_sms_box.toast.success', {
          phone_number: phoneNumber,
        }),
      });
    } catch (exception) {
      showToast.delete.error();
    }
  };

  useEffect(() => {
    // Populates form components once data is loaded.

    const nextFormState = merge({}, defaultValues, smsData, {
      phone_number: phoneNumber,
    });

    reset(nextFormState);
  }, [smsData, usersData, groupsData, phoneNumber, reset]);

  const getDeleteMessage = () => {
    if (smsData && usersData && phoneNumber) {
      return (
        <Trans
          i18nKey="phone_system:containers.sms.edit.actions.delete_sms_box.text"
          components={{ bold: <strong /> }}
          values={{
            phone_number: phoneNumber,
            user_owner: checkForSmsBoxOwner([smsData], usersData, phoneNumber),
          }}
        />
      );
    }
    return '';
  };

  if (isLoadingSmsData || isLoadingUsersData || isLoadingGroupsData) {
    return <Loading />;
  }

  return (
    <EditForm
      isDeleteDisabled={!(smsData && usersData && phoneNumber)}
      deleteConfirm={getDeleteMessage()}
      entityLabel={t('phone_system:containers.sms.label')}
      entityName={phoneNumber}
      formMethods={formMethods}
      isPageDirty={isPageDirty}
      onDelete={id !== ADD_KEY ? handleDelete : undefined}
      onSave={handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }))}
    >
      <Form />
    </EditForm>
  );
};

export default Edit;
