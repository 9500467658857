// SMS Queries
import { SMS } from 'api/sms';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const smsQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSMS: builder.query<SMSResponse, void>({
      query: () => ({
        url: SMS.Fetch(),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.SMS as const, id })),
              { type: TAGS.SMS, id: 'LIST' },
            ]
          : [{ type: TAGS.SMS, id: 'LIST' }],
    }),
    fetchSMSByOwnerId: builder.query<SMSResponse, ByIdParam>({
      query: ({ id }) => ({
        url: SMS.FetchByOwnerId(id),
        responseHandler,
      }),
      extraOptions: { maxRetries: 1 },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.SMS as const, id })),
              { type: TAGS.SMS, id: 'LIST' },
            ]
          : [{ type: TAGS.SMS, id: 'LIST' }],
    }),
    // This query will also fetch sms boxes that is shared to the user
    fetchSMSByUserId: builder.query<SMSResponse, ByIdParam>({
      query: ({ id }) => ({
        url: SMS.FetchByUserId(id),
        responseHandler,
      }),
      extraOptions: { maxRetries: 1 },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.SMS as const, id })),
              { type: TAGS.SMS, id: 'LIST' },
            ]
          : [{ type: TAGS.SMS, id: 'LIST' }],
    }),
    fetchSMSById: builder.query<SMS, ByIdParam>({
      query: ({ id }) => ({
        url: SMS.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.SMS, id }];
      },
    }),
  }),
});
