import { useMemo, useState } from 'react';
import JsonErrorDialog from 'shared/components/JsonErrorDialog';
import { useToast } from 'shared/hooks/useToast';
import { ErrorMessageArray, findErrorMessages } from 'shared/utility/error';
import { AlertContext, ApiToastType } from '.';

const AlertContextProvider = ({ children }: any) => {
  const { showToast } = useToast();
  const [error, setError] = useState<any | null>(null);
  const isModalOpen = Boolean(error);

  const on = useMemo(
    () => ({
      error: (error: any, props: ApiToastType) => {
        /**
         * If toast error, check if message can be pulled out,
         *  if yes, display message in toast
         *  if no, display generic toast
         * Otherwise, display error in modal
         */
        if (props?.error) {
          const errorMessages: ErrorMessageArray = [];

          findErrorMessages(error, errorMessages, false);

          if (errorMessages.length) {
            errorMessages.forEach(({ message }) => {
              showToast.error(message);
            });
          } else {
            showToast.error();
          }
        }

        if (!props?.error) {
          setError(error);
        }

        throw error;
      },
      success: (fulfilled: any, props: ApiToastType) => {
        if (props?.success) {
          showToast.success(props.success.message);
        }
        return fulfilled;
      },
    }),
    [showToast],
  );

  const value = useMemo(
    () => ({
      call: async (action: any, body: any, props: ApiToastType) =>
        action(body)
          .unwrap()
          .then((fulfilled: any) => on.success(fulfilled, props))
          .catch((error: any) => on.error(error, props)),
      openModal: (error: any) => setError(error),
    }),
    [on],
  );

  return (
    <AlertContext.Provider value={value}>
      <JsonErrorDialog errors={error} open={isModalOpen} onClose={() => setError(null)} />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
