import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledLabelProps as Props } from './definition';

const StyledLabel = styled.span.attrs({ className: 'component-action-label' })<Props>`
  ${(props) => css`
    ${mixin.font({ size: 10, weight: 'bold' })};
    ${mixin.transition({ property: 'color', duration: 'medium' })};
    color: ${props.theme.core.background.grey.dark};
    text-transform: uppercase;
    white-space: nowrap;
  `};
`;

export default StyledLabel;
