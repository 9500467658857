import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledPhoneSystemProps as Props } from './definition';

/**
 * StyledSinglePanel, as the name implies, implements styling for the "single panel"
 * edit pages (Call Blocking, Directories, Media) and is a separate styling implementation
 * than that used in the useTabs (tabbed) panels.
 */
export const StyledSinglePanel = styled.div`
  ${mixin.flex({ alignItems: 'center', direction: 'column', justifyContent: 'start' })};

  > div {
    ${mixin.flex({ direction: 'column', alignItems: 'flex-start' })};

    .row-split-button,
    .row-split-checkbox,
    .row-split-picker,  // TODO: replace with generic .row-split class
    .row-split-select {
      ${mixin.flex({ alignItems: 'flex-start', justifyContent: 'flex-start' })};
      margin: 0 0 36px;

      > button,
      > div {
        margin: 0;

        &:last-of-type {
          margin: 0 0 0 18px;
        }
      }
    }

    .row-split-button > button {
      min-width: 130px;
    }

    .row-split-checkbox > div:last-of-type {
      margin-top: 24px;
    }

    :last-of-type {
      padding-bottom: 30px;
    }
  }
`;

const StyledPhoneSystem = styled.main<Props>`
  ${(props) => css`
    ${mixin.flex({ alignItems: 'normal', justifyContent: 'flex-start' })};
    width: 100%;
    height: calc(100vh - ${HEADER_CONSTANTS.HEIGHT});

    > div {
      width: ${`calc(100% - ${HEADER_CONSTANTS.LOGO.WIDTH})`};
      max-width: ${`calc(100vw - ${HEADER_CONSTANTS.LOGO.WIDTH})`};
      overflow: ${props.hasScroll ? 'auto' : 'clip'};
    }
  `}
`;

export default StyledPhoneSystem;
