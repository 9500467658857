import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledTableProps as Props } from '../definition';
import { CONSTANTS } from './constants';
import { core } from './type/core';
import { removable } from './type/removable';
import { selector } from './type/selector';

const StyledTableWrapper = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex({ direction: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' })};
    max-width: 100vw;
    height: 100%;
    overflow: auto;

    > div {
      width: 100%;

      &.component-table-container {
        overflow: auto;

        ${!props.hasOuterBorder &&
        props.hasSideMargin &&
        css`
          width: calc(100% - ${CONSTANTS.PADDING.SIDE} * 2);
          margin-left: ${CONSTANTS.PADDING.SIDE};
          margin-right: ${CONSTANTS.PADDING.SIDE};
        `};

        // core styling
        ${core(props)};

        // RemovableTable
        ${removable(props)};

        // TableSelector/Selection
        ${selector(props)};
      }

      &.component-table-pagination {
        margin: auto 0 0;
      }
    }
  `}
`;

export default StyledTableWrapper;
