import { themeColor } from 'theme/local';

export default {
  styleOverrides: {
    root: {
      '&.MuiLinearProgress-agent': {
        height: 8,
        backgroundColor: themeColor.brio.greyscale[300],
        borderRadius: 4,
        '.MuiLinearProgress-bar': {
          backgroundColor: themeColor.brio.greyscale[300],
        },
        '&.MuiLinearProgress-agent-logged_in, &.MuiLinearProgress-agent-logged_out': {
          backgroundColor: themeColor.brio.greyscale[300],
          '.MuiLinearProgress-bar': {
            backgroundColor: themeColor.brio.greyscale[300],
          },
        },
        '&:not(.MuiLinearProgress-agent-logged_in):not(.MuiLinearProgress-agent-logged_out)': {
          backgroundColor: themeColor.brio.error.main,
          '.MuiLinearProgress-bar': {
            backgroundColor: themeColor.brio.info.highlight,
          },
        },
      },
    },
  },
};
