import CallStatusIcon from 'apps/PhoneSystem/shared/CallStatusIcon';
import { macAddressMask, unmaskMacAddress } from 'apps/shared/utility';
import i18next from 'i18next';
import { DeviceData, Devices } from 'models/AdvancedProvisioner/types';
import { getExtensionAndFullNameFromUserListByUserId } from 'models/User';
import Box from 'shared/components/Box';
import { Cell, Column, Justify, TABLE_CONSTANTS } from 'shared/components/Table';

export const columns: (deviceRegistrationsData: any) => Array<Column> = (
  deviceRegistrationsData,
) => [
  {
    Header: i18next.t(
      'advanced_provisioner:containers.devices.table.columns.Device_name',
    ) as string,
    accessor: 'name',
  },
  {
    Header: i18next.t(
      'advanced_provisioner:containers.devices.table.columns.Assigned_to',
    ) as string,
    accessor: 'assignedTo',
    id: 'owner',
    width: 200,
  },
  {
    Header: i18next.t('advanced_provisioner:containers.devices.table.columns.Make') as string,
    accessor: 'brand',
    width: 200,
  },
  {
    Header: i18next.t('advanced_provisioner:containers.devices.table.columns.Model') as string,
    accessor: 'model',
    width: 200,
  },
  {
    Header: i18next.t('advanced_provisioner:containers.devices.table.columns.Mac') as string,
    Cell: ({ value }: Cell<JSX.Element>) => <Box>{macAddressMask(value)}</Box>,
    accessor: 'mac_address',
    width: 200,
  },
  {
    Header: i18next.t('advanced_provisioner:containers.devices.table.columns.Status') as string,
    Cell: ({ row: { original: device } }: any) => (
      <CallStatusIcon currentDevice={device} deviceRegistrationsData={deviceRegistrationsData} />
    ),
    id: `devices.device_status.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.LARGE,
  },
];

export const transformData = (
  apDevicesData: Devices = [],
  devicesData: DeviceResponse = [],
  usersData: UserResponse = [],
) =>
  apDevicesData.reduce(
    (
      finalData: Array<
        DeviceData & { id: string; deviceId: string; mac_address: string; assignedTo: string }
      >,
      apDevice,
    ) => {
      const filteredByMatchingMacAddress = devicesData.find(
        ({ mac_address }) =>
          unmaskMacAddress(apDevice?.mac_address) === unmaskMacAddress(mac_address),
      );

      if (filteredByMatchingMacAddress) {
        const newProperties = {
          /* "id" is required to be unique key for the component and for creating
           * the edit screen route
           */
          id: unmaskMacAddress(filteredByMatchingMacAddress?.mac_address),
          deviceId: filteredByMatchingMacAddress?.id,
          mac_address: filteredByMatchingMacAddress?.mac_address?.toUpperCase(),
          assignedTo: getExtensionAndFullNameFromUserListByUserId(
            filteredByMatchingMacAddress?.owner_id,
            usersData,
          ),
        };

        finalData.push({
          ...filteredByMatchingMacAddress,
          ...apDevice,
          ...newProperties,
        });
      }

      return finalData;
    },
    [],
  );
