// Account Queries
import { IAM } from 'api/iam';
import { iamApi, responseHandler, TAGS } from 'services/iam';
import { transformIdpUrls } from './helpers';

export const iamQueries = iamApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchIdp: builder.query<IdpTransformedResponse | undefined, void>({
      query: () => ({
        url: IAM.FetchIdp(),
        responseHandler,
      }),
      transformResponse: (res: IdpResponse) => {
        if (!res.idp) {
          return undefined;
        }

        const newRes: IdpTransformedResponse = res;
        if (newRes.idp) {
          const iamHost = window.nemo?.api.iam || 'https://iam-cn.ingress.ooma.com'; // Fallback to default iam host
          const idpId = newRes.idp.id;
          newRes.idp.callbackUrl = transformIdpUrls(newRes.idp.acsCallbackUrlTemplate, {
            iamHost,
            idpId,
          });
          newRes.idp.issuerUrl = transformIdpUrls(newRes.idp.issuerUrlTemplate, { iamHost, idpId });
          newRes.idp.metadataUrl = transformIdpUrls(newRes.idp.metadataUrlTemplate, {
            iamHost,
            idpId,
          });
        }
        return newRes;
      },
      providesTags: () => {
        return [{ type: TAGS.Idp }];
      },
    }),
  }),
});
