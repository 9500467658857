import { MarginType } from 'shared/utility/definition';
import { ButtonProps } from '../Button';

const defaultProps = {
  hasMargin: false,
  hasSearch: false,
  isBelow: false,
  isDisabled: false,
  isFormElement: false,
  isHeaderElement: false,
  isMenuRight: false,
  buttonProps: {
    color: 'secondary',
    variant: 'contained',
  } as ButtonProps,
  leftMargin: MarginType.none,
};

export default defaultProps;
