import SvgIcon from '@mui/material/SvgIcon';
import get from 'lodash/get';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledIconProps as Props } from './definition';

const StyledSvgIcon = styled(SvgIcon)<Props>`
  ${(props) => css`
    width: ${props.width}px;
    height: ${props.height}px;
    fill: ${get(props.theme, props.themecolor)};
    transform: ${`scale(${props.scale})`};

    ${props.hashover === 'true' &&
    css`
      ${mixin.transition({ property: 'transform', duration: 'medium' })};
      cursor: pointer;

      &:hover {
        transform: scale(1.15);
      }
    `}

    g {
      transform-origin: 50% 50%;
    }

    &.MuiSvgIcon-rotate {
      ${mixin.transition({ property: 'transform', duration: 'medium' })};
      transform: ${`rotate(0deg) scale(${props.scale})`};

      &.MuiSvgIcon-rotate-open {
        transform: ${`rotate(-180deg) scale(${props.scale})`};
      }
    }

    &.adornment-end,
    &.adornment-start {
      ${mixin.transition([{ property: 'filter' }, { property: 'opacity' }])};
      opacity: 0;

      &.is-clickable {
        cursor: pointer;

        &:hover {
          filter: brightness(80%);
        }
      }

      &.is-visible {
        opacity: 1;
      }
    }
  `}
`;

export default StyledSvgIcon;
