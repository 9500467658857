/**
 * @name getSortedDesc
 * @description Get the (next-in-line) sorted descending state.
 *
 * @param isSortedDesc
 *
 * @returns Sorted descending state
 */
export const getSortedDesc = (isSortedDesc: boolean | undefined) => {
  switch (isSortedDesc) {
    case undefined:
      return false;
    case false:
      return true;
    case true:
      return undefined;
    default:
      return undefined;
  }
};
