import { joiResolver } from '@hookform/resolvers/joi';
import CallflowActionsDialog from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowActionsDialog/components/CallflowActionDialog';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledSelect } from 'shared/components/Labeled';
import defaultProps from './default';
import { AgentAvailabilityKeyDialogForm, Props } from './definition';
import schema from './schema';
import translations from './translations';

const AgentAvailabilityKeyDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose } = { ...defaultProps, ...props };
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<AgentAvailabilityKeyDialogForm>({
    mode: 'onChange',
    defaultValues: {
      key: data?.key,
    },
    resolver: joiResolver(schema()),
  });
  const { key, keys, title } = useMemo(() => translations(), []);

  const submitHandler = async (formData: AgentAvailabilityKeyDialogForm) => {
    onSave(formData.key, isDirty);
  };

  return (
    <CallflowActionsDialog
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper name="key" control={control} options={keys}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={key.label}
            labelWidth="small"
            inputWidth="small"
            selectProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default AgentAvailabilityKeyDialog;
