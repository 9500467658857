import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { EditFormProps } from 'apps/PhoneSystem/definition';
import { ADD_KEY } from 'constant';
import cloneDeep from 'lodash/cloneDeep';
import {
  schema,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useFetchGroupByIdQuery,
  useUpdateGroupMutation,
} from 'models/Group';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import { defaultValues } from './default';
import { FormInput } from './definition';
import Form from './Form';

const Edit = ({ id, handleSaveSuccess, handleDeleteSuccess }: EditFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { data, isLoading } = useFetchGroupByIdQuery({ id });

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const {
    setError,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = formMethods;
  const isPageDirty = checkFormEntities(dirtyFields);

  const [createGroup] = useCreateGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();
  const [deleteGroup] = useDeleteGroupMutation();

  const handle = {
    delete: async () => {
      try {
        await deleteGroup({ id }).unwrap();
        handleDeleteSuccess?.();
      } catch (exception) {
        showToast.delete.error();
      }
    },
    submit: async (body: FormInput) => {
      try {
        if (id === ADD_KEY) {
          await createGroup({ body }).unwrap();
        } else {
          await updateGroup({ id, body }).unwrap();
        }

        handleSaveSuccess?.({
          shouldRedirect: id === ADD_KEY,
          toastMessage:
            id === ADD_KEY
              ? t('phone_system:containers.groups.toast.success.add')
              : t('phone_system:containers.groups.toast.success.edit'),
        });
      } catch (exception) {
        showErrorMessage({ isFromException: true, errors: exception, setError });
      }
    },
  };

  useEffect(() => {
    reset(cloneDeep(data)); // Populates form components once data is loaded.
  }, [data, reset]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditForm
      isPageDirty={isPageDirty}
      entityLabel={t('phone_system:containers.groups.label')}
      entityName={data?.name}
      formMethods={formMethods}
      onSave={handleSubmit(handle.submit, (errors) => showErrorMessage({ errors, setError }))}
      onDelete={id !== ADD_KEY ? handle.delete : undefined}
    >
      <Form />
    </EditForm>
  );
};

export default Edit;
