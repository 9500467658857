import { advancedProvisionerMutations } from './Mutations';
import { advancedProvisionerQueries } from './Queries';

export const {
  useFetchAccountSettingsQuery,
  useFetchAccountTemplateQuery,
  useFetchDeviceByMacAddressQuery,
  useFetchDeviceFilesByMacAddressQuery,
  useFetchDevicesQuery,
  useFetchDeviceTemplateByModelQuery,
  useFetchPhonesQuery,
  useLazyFetchSubaccountDeviceQuery,
} = advancedProvisionerQueries;

export const {
  useUpdateDeviceByMacAddressMutation,
  useUpdateAccountMutation,
} = advancedProvisionerMutations;
