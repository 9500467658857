import { forwardRef } from 'react';
import defaultProps from './default';
import { InputProps as Props } from './definition';
import StyledInput from './style';

export type { InputProps } from './definition';
export { CONSTANTS as INPUT_CONSTANTS } from './style';

const Input = forwardRef<unknown, Props>(
  (_props, ref): JSX.Element => {
    const props = { ...defaultProps, ..._props };
    const { $hasNoAutofill, inputProps, type, value } = props;

    /**
     * Set min to zero for all number types (unless min is already
     * set in rest.inputProps); this code also assumes that there
     * would never be a case where we'd have a negative number
     */
    if (type === 'number' && value) {
      const numValue = Number(value);

      if (!inputProps?.min) {
        props.inputProps = { ...inputProps, min: 0 };
      }

      // negative numbers get changed to positive
      props.value = Math.abs(numValue).toString();
    }

    return (
      <StyledInput
        inputRef={ref}
        {...props}
        {...($hasNoAutofill ? { autoComplete: type === 'password' ? 'new-password' : 'off' } : {})}
      />
    );
  },
);

export default Input;
