import OptionsSection from './OptionsSection';
import optionsFields from './OptionsSection/fields';

const fields = {
  options: optionsFields,
};

const Section = {
  Options: OptionsSection,
};

export { fields, Section };
