import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { BreadcrumbProps as Props } from './definition';
import StyledBreadcrumb from './style';

const Breadcrumb = ({ breadcrumb, filterValues = [] }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledBreadcrumb>
      {breadcrumb}
      {filterValues.length > 0 && (
        <Box component="ul" aria-label="filter-values">
          <Box component="li">{t('common:filtered_by')}</Box>
          {filterValues
            .filter((filter: string) => filter) // filter empty/undefined values
            .sort((a: string, b: string) => a?.localeCompare(b))
            .map((filter: string) => (
              <Box component="li" key={`breadcrumb-filter-type-${filter}`}>
                {filter}
              </Box>
            ))}
        </Box>
      )}
    </StyledBreadcrumb>
  );
};

export default Breadcrumb;
