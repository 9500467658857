import { ContainerMeta } from 'apps/shared/definition';
import { Suspense } from 'react';
import { Navigate, useRoutes, useSearchParams } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Edit from './Edit';
import List from './List';

export const meta: ContainerMeta = {
  // t('phone_system:containers.seats.label')
  label: 'phone_system:containers.seats.label',
  icon: 'people-outlined',
  slug: 'seats',
};

const Seats = () => {
  const [searchParams] = useSearchParams();
  const seat_type = searchParams.get('seat_type');

  const routes = useRoutes([
    { path: '/', element: <List /> },

    // The default tab when viewing a seat will be the Seats tab.
    { path: '/:id', element: <Navigate to={`./seat?seat_type=${seat_type}`} replace /> },
    { path: '/:id/:activeTab/*', element: <Edit /> },
  ]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};

export default Seats;
