import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { columnize } from 'apps/shared/utility';
import { CallRestrictionOptionType } from 'definition';
import { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledSelect, LabeledSwitch } from 'shared/components/Labeled';
import { CallRestrictionsSectionProps as Props } from './definition';
import { getClassifierFormName, options } from './utility';

export { defaultValues as defaultCallRestrictionValues } from './default';
export { getClassifierFormName } from './utility';

export const CallRestrictionsSection = ({
  hasCallbacksEnabled,
  hasClosedGroups,
  classifiers,
  fields,
}: Props) => {
  const { t } = useTranslation();
  const { getValues, resetField, watch } = useFormContext();
  const { closedGroups, enabled }: Record<string, string> = fields;

  useEffect(() => {
    if (hasClosedGroups && !getValues(closedGroups)) {
      resetField(closedGroups, { defaultValue: CallRestrictionOptionType.Inherit });
    }
  }, [hasClosedGroups, closedGroups, getValues, resetField]);

  return (
    <>
      {hasClosedGroups && closedGroups && (
        <div role="row" className="one-column">
          <div role="cell">
            {/* Closed Groups */}
            <HookFormInputWrapper isCheckbox name={closedGroups}>
              {({ ref, value, isDirty, onChange, ...formProps }) => (
                <LabeledCheckbox
                  hasMargin
                  isDirty={isDirty}
                  checkboxProps={{
                    ...formProps,
                    checked: value === CallRestrictionOptionType.Deny,
                    onChange: (e: ChangeEvent<HTMLInputElement>, isChecked: boolean) =>
                      onChange(
                        isChecked
                          ? CallRestrictionOptionType.Deny
                          : CallRestrictionOptionType.Inherit,
                      ),
                  }}
                  label={t('common:component.call_restrictions_section.field.closed_groups.label')}
                />
              )}
            </HookFormInputWrapper>
          </div>
        </div>
      )}

      {hasCallbacksEnabled && enabled && (
        <>
          <div role="row" className="underline">
            <div role="cell">
              {/* Enable Callbacks */}
              <HookFormInputWrapper isSwitch name={enabled}>
                {({ ref, isDirty, ...formProps }) => (
                  <LabeledSwitch
                    isDirty={isDirty}
                    hasMargin
                    label={t(
                      'common:component.call_restrictions_section.field.enable_callbacks.label',
                    )}
                    switchProps={{
                      ...formProps,
                    }}
                  />
                )}
              </HookFormInputWrapper>
            </div>
          </div>

          <p>{t('common:component.call_restrictions_section.text.callback_permitted')}</p>
        </>
      )}

      <div role="row">
        {columnize(Object.entries(classifiers), ([name, { friendly_name: label }]) => {
          let defaultValue;

          if (hasCallbacksEnabled) {
            defaultValue = CallRestrictionOptionType.Allow;
          }
          if (hasClosedGroups) {
            defaultValue = CallRestrictionOptionType.Inherit;
          }

          return (
            <HookFormSelectWrapper
              defaultValue={defaultValue}
              key={name}
              name={getClassifierFormName({
                hasAction: hasClosedGroups,
                name,
                root: fields.classifiers,
              })}
              options={options({ hasInherit: hasClosedGroups })}
            >
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledSelect
                  isDirty={isDirty}
                  feedback={feedback}
                  label={label}
                  selectProps={{
                    ...formProps,
                    isDisabled: hasCallbacksEnabled ? !watch(enabled) : false,
                  }}
                />
              )}
            </HookFormSelectWrapper>
          );
        })}
      </div>
    </>
  );
};
