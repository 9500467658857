import { REGEX } from 'constant';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import { ErrorOption } from 'react-hook-form';
import { ErrorMessage, ErrorMessageArray } from './definition';

export type { ErrorMessage, ErrorMessageArray, ErrorOption };

export const findErrorMessages = (
  errorData: Record<string, {}>,
  resultArray: ErrorMessageArray,
  fromException?: boolean,
  baseKey?: string,
) => {
  if (!isObject(errorData)) {
    return;
  }

  if (get(errorData, 'message', false)) {
    resultArray.push({ errorKey: '', ...errorData });
    return;
  }
  Object.keys(errorData).map((errorDataKey) => {
    const message = get(errorData[errorDataKey], 'message');
    const errorKey = (REGEX.STRING.NUMERIC.test(errorDataKey) || fromException
      ? baseKey
      : `${baseKey}.${errorDataKey}`) as string;
    if (message) {
      resultArray.push({
        errorKey,
        ...errorData[errorDataKey],
      });
    } else {
      findErrorMessages(
        errorData[errorDataKey],
        resultArray,
        fromException,
        !baseKey ? errorDataKey : `${baseKey}.${errorDataKey}`,
      );
    }
  });
};
