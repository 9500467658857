import { CallFlowTree } from 'models/Callflow/store/definition';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';
import { ActionCreators } from 'redux-undo';

export const historyAction = (dispatch: Dispatch<SetStateAction<ActionCreators>>) => ({
  clear: () => {
    dispatch(ActionCreators.clearHistory());
  },
  redo: (id: string, callflowFuture: Array<Callflow>) => {
    if (callflowFuture.length > 0) {
      let idx = 0;
      while (
        idx < callflowFuture.length &&
        (callflowFuture[idx]?.entities[id]?.dialog || callflowFuture[idx]?.entities[id]?.keyDialog)
      ) {
        idx++;
      }
      dispatch(ActionCreators.jumpToFuture(idx));
    }
  },
  undo: (id: string, callflowPast: Array<Callflow>) => {
    if (callflowPast.length > 0) {
      let idx = callflowPast.length - 1;
      while (
        idx >= 0 &&
        (callflowPast[idx]?.entities[id]?.dialog || callflowPast[idx]?.entities[id]?.keyDialog)
      ) {
        idx--;
      }
      dispatch(ActionCreators.jumpToPast(idx));
    }
  },
});

export function getSaveAndDupHandler(
  handleSave: ({
    shouldRedirect,
  }?: any) => Promise<
    | { saveResult: string; newCallFlow: Partial<CallFlowTree> }
    | { saveResult: string; newCallFlow?: undefined }
  >,
  duplicateAndNavigate: (newCallflow?: Partial<Callflow>) => void,
  showToast: any,
  t: TFunction<'translation', undefined>,
) {
  return async () => {
    const saveResult = await handleSave({ shouldRedirect: false });

    if (saveResult.saveResult === 'success') {
      duplicateAndNavigate(saveResult.newCallFlow);
      showToast.success(t('phone_system:containers.callflows.success.new_copy_available'));
    }
  };
}
