import { AgentCardStatusType } from 'apps/CallCenter/shared/components/AgentCard';

/**
 * @name isActive
 * @description Determine if the agent is currently active.
 *
 * @param id
 * @param [agentsStatus]
 *
 * @returns Is active flag.
 */
export const isActive = (id: string, agentsStatus?: AgentsStatus): boolean => {
  const status = agentsStatus?.[id]?.status;
  return (
    typeof status !== 'undefined' &&
    status !== AgentCardStatusType.LOGGED_IN &&
    status !== AgentCardStatusType.LOGGED_OUT
  );
};

/**
 * @name getActiveAgentCount
 * @description Get the current count of active agents.
 *
 * @param totalAgents
 * @param [agentsStatus]
 *
 * @returns Active number of agents.
 */
export const getActiveAgentCount = (totalAgents: AgentsResponse, agentsStatus?: AgentsStatus) =>
  totalAgents.reduce((acc: number, { id }: Agent): number => {
    acc += isActive(id, agentsStatus) ? 1 : 0;
    return acc;
  }, 0);

/**
 * @name getTotalAgents
 * @description Get the total number of agents in the currently selected queue.
 *
 * @param [object]
 * @property [hasOnlineAgents]
 * @property [agents]
 * @property [agentsStatus]
 * @property [queue]
 *
 * @returns Total number of agents.
 */
export const getTotalAgents = ({
  hasOnlineAgents,
  agents,
  agentsStatus,
  queue,
}: {
  hasOnlineAgents: boolean;
  agents?: AgentsResponse;
  agentsStatus?: AgentsStatus;
  queue?: Queue;
}) =>
  (agents ?? [])
    .filter(({ queues }: Agent) => queues.length)
    .filter(({ queues }: Agent) => (queue?.id ? queues.includes(queue?.id) : true))
    .filter(({ id }: Agent) => (hasOnlineAgents ? isActive(id, agentsStatus) : true))
    .sort((a: Agent, b: Agent) => a.last_name.localeCompare(b.last_name));
