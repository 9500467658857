import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useNumbersInUsePicker from 'shared/hooks/useNumbersInUsePicker';
import { useToast } from 'shared/hooks/useToast';
import defaultArgs from './default';
import { FormNumbersInUsePickerArgs as Args } from './definition';

const useFormNumbersInUsePicker = (args: Args = {}) => {
  const { field, shouldDirty, toastMessage } = { ...defaultArgs, ...args };
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { setValue } = useFormContext();

  const { numbersInUsePicker, setIsNumbersInUsePickerOpen } = useNumbersInUsePicker({
    onApply: (number: any) => {
      setValue(field, number.id, { shouldDirty });
      showToast.success(toastMessage ?? t('common:component.numbers_in_use_picker.toast.success'));
    },
  });

  return {
    formNumbersInUsePicker: numbersInUsePicker,
    setIsNumbersInUsePickerOpen,
  };
};

export default useFormNumbersInUsePicker;
