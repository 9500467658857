import i18next from 'i18next';
import Checkbox from 'shared/components/Checkbox';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import { Justify, TABLE_CONSTANTS } from 'shared/components/Table';

export const columns = ({
  isLoadingDeviceStatuses,
  deviceStatusesData,
  deviceStatusesError,
  patchDevice,
}: {
  isLoadingDeviceStatuses: boolean;
  deviceStatusesData: any;
  deviceStatusesError: any;
  patchDevice: (param: any) => void;
}) => [
  {
    Header: i18next.t('phone_system:containers.seats.devices.table.column.name.header'),
    accessor: 'name',
  },
  {
    Header: i18next.t('phone_system:containers.seats.devices.table.column.type.header'),
    Cell: ({ row }: any) => (
      <>{i18next.t(`phone_system:containers.devices.type.${row.original.device_type}`)}</>
    ),
    accessor: 'device_type',
    width: 200,
  },
  {
    Header: i18next.t('phone_system:containers.seats.devices.table.column.presence.header'),
    Cell: ({ row }: any) => {
      if (isLoadingDeviceStatuses) {
        return <Loading />;
      }

      if (deviceStatusesError) {
        return i18next.t('phone_system:containers.seats.devices.table.column.presence.error');
      }

      let color = 'red'; // TODO: CallStatusIcon > getColor (?)
      if (deviceStatusesData[row.original.id]) {
        color = 'green.default';
      }
      if (!row.original.enabled) {
        color = 'grey.dark';
      }

      return <Icon name="call-filled" themeColor={`core.color.${color}`} />;
    },
    id: `seats.devices.presence.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
  },
  {
    Header: i18next.t('phone_system:containers.seats.devices.table.column.enabled.header'),
    Cell: ({
      row: {
        original: { enabled, id },
      },
    }: any) => (
      <Checkbox
        checked={enabled}
        onChange={() => {
          patchDevice({ id, body: { enabled: !enabled } });
        }}
      />
    ),
    id: `seats.devices.enabled.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
  },
];

/**
 * @name showDevice
 * @description Determines whether to show or not a device
 *
 * @param isDeletedDevice - The deletion status of the device
 * @param itemId - The Id of the device
 * @param dirtyDevices - An object containing dirty fields
 * @returns Returns true if the device should be shown, false otherwise
 */
export const showDevice = (
  isDeletedDevice: string | undefined,
  itemId: string | undefined,
  dirtyDevices: any,
) => Boolean(!isDeletedDevice && itemId && (!itemId.startsWith('add') || dirtyDevices?.[itemId]));
