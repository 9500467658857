import MuiDialogTitle from '@mui/material/DialogTitle';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import defaultProps from './default';
import { DialogTitleIconOnClose as OnClose, DialogTitleProps as Props } from './definition';

const DialogTitle = (props: Props) => {
  const { hasClose, children, dialogType, onClose, ...rest } = { ...defaultProps, ...props };

  const classes = [];
  if (dialogType !== 'default') {
    classes.push(`MuiDialogTitle-${dialogType}`);
  }
  if (!children) {
    classes.push(`MuiDialogTitle-short`);
  }

  return (
    <MuiDialogTitle classes={{ root: classes.join(' ') }} {...rest}>
      <Box component="span">{children}</Box>
      {hasClose && (
        <Box component="span" onClick={onClose as OnClose}>
          <Icon name="close" size={22} />
        </Box>
      )}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
