import { InteractionBarElement, InteractionBarElementType, InteractionBarType } from './definition';

const elements: Record<InteractionBarElementType, Partial<InteractionBarElement>> = {
  [InteractionBarElementType.DUPLICATE]: {
    element: InteractionBarElementType.DUPLICATE,
    id: 'duplicate',
    hasPadding: {
      left: true,
    },
    isWide: true,
    icon: {
      name: 'file-copy-outlined',
      scale: {
        default: 0.75,
        hover: 0.95,
      },
    },
  },
  [InteractionBarElementType.EDIT]: {
    element: InteractionBarElementType.EDIT,
    id: 'edit',
    hasPadding: {
      left: true,
    },
    isWide: true,
    icon: { name: 'edit' },
  },
  [InteractionBarElementType.REDO]: {
    element: InteractionBarElementType.REDO,
    id: 'redo',
    hasPadding: {
      right: true,
    },
    icon: {
      name: 'refresh',
      scale: {
        default: 0.75,
        hover: 0.95,
      },
    },
  },
  [InteractionBarElementType.UNDO]: {
    element: InteractionBarElementType.UNDO,
    id: 'undo',
    hasPadding: {
      left: true,
    },
    icon: {
      name: 'refresh',
      isFlipped: true,
      scale: {
        default: 0.75,
        hover: 0.95,
      },
    },
  },
  [InteractionBarElementType.ZOOM_IN]: {
    element: InteractionBarElementType.ZOOM_IN,
    id: 'zoom-in',
    hasPadding: {
      left: true,
    },
    icon: { name: 'zoom-in' },
  },
  [InteractionBarElementType.ZOOM_OUT]: {
    element: InteractionBarElementType.ZOOM_OUT,
    id: 'zoom-out',
    hasPadding: {
      right: true,
    },
    icon: { name: 'zoom-out' },
  },
  [InteractionBarElementType.SEPARATOR]: {
    element: InteractionBarElementType.SEPARATOR,
    id: 'separator',
  },
};

export const defaultElements: Record<InteractionBarType, Partial<InteractionBarElement>[]> = {
  [InteractionBarType.DEFAULT]: [
    elements[InteractionBarElementType.UNDO],
    elements[InteractionBarElementType.REDO],
    elements[InteractionBarElementType.SEPARATOR],
    elements[InteractionBarElementType.ZOOM_IN],
    elements[InteractionBarElementType.ZOOM_OUT],
    elements[InteractionBarElementType.SEPARATOR],
    elements[InteractionBarElementType.DUPLICATE],
  ],
  [InteractionBarType.QUICK_VIEW]: [
    elements[InteractionBarElementType.ZOOM_IN],
    elements[InteractionBarElementType.ZOOM_OUT],
    elements[InteractionBarElementType.SEPARATOR],
    elements[InteractionBarElementType.EDIT],
  ],
};

const defaultProps = {
  hasDuplicate: true,
};

export default defaultProps;
