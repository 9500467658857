import CallStatusIcon from 'apps/PhoneSystem/shared/CallStatusIcon';
import { TableCellCheckmarkIcon } from 'apps/shared/components/TableCell';
import { macAddressMask } from 'apps/shared/utility';
import i18next from 'i18next';
import { getExtensionAndFullNameFromUserListByUserId } from 'models/User';
import { Cell } from 'react-table';
import Loading from 'shared/components/Loading';
import { Justify, TABLE_CONSTANTS } from 'shared/components/Table';

export const columns = (isLoadingDeviceStatusesData: boolean, data: Record<string, any>) => [
  {
    Header: i18next.t('phone_system:containers.devices.table.column.device_assigned_to'),
    accessor: ({ owner_id }: any) =>
      getExtensionAndFullNameFromUserListByUserId(owner_id, data.users),
    id: 'owner',
    width: 400,
  },
  {
    Header: i18next.t('phone_system:containers.devices.table.column.device_nickname'),
    accessor: 'name',
    width: 400,
  },
  {
    Header: i18next.t('phone_system:containers.devices.table.column.device_type'),
    accessor: (row: any) => getDeviceType()[row.device_type],
    width: 300,
  },
  {
    Header: i18next.t('phone_system:containers.devices.table.column.device_mac'),
    Cell: ({ value }: Cell<JSX.Element>) => <div>{macAddressMask(value)}</div>,
    accessor: 'mac_address',
    width: 200,
  },
  {
    Header: i18next.t('phone_system:containers.devices.table.column.device_status'),
    Cell: ({ row: { original: device } }: any) =>
      isLoadingDeviceStatusesData ? (
        <Loading />
      ) : (
        <CallStatusIcon currentDevice={device} deviceRegistrationsData={data.deviceStatuses} />
      ),
    id: `devices.device_status.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
  },
  {
    Header: i18next.t('phone_system:containers.devices.table.column.device_enabled'),
    Cell: ({ value }: Cell<JSX.Element>) => <TableCellCheckmarkIcon isVisible={value} />,
    accessor: 'enabled',
    id: `devices.device_enabled.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
  },
];

/**
 * @name getDeviceType
 * @description Get the device type friendly names.
 *
 * @returns Device type name object.
 */
const getDeviceType = (): Record<string, string> => ({
  browserphone: i18next.t('phone_system:containers.devices.type.browserphone'),
  cellphone: i18next.t('phone_system:containers.devices.type.cellphone'),
  fax: i18next.t('phone_system:containers.devices.type.fax'),
  landline: i18next.t('phone_system:containers.devices.type.landline'),
  mobile: i18next.t('phone_system:containers.devices.type.mobile'),
  sip_device: i18next.t('phone_system:containers.devices.type.sip_device'),
  sip_uri: i18next.t('phone_system:containers.devices.type.sip_uri'),
  smartphone: i18next.t('phone_system:containers.devices.type.smartphone'),
  softphone: i18next.t('phone_system:containers.devices.type.softphone'),
});
