import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const Faxbox = {
  // GET
  Fetch: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/faxboxes?paginate=${isPaginated}`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/faxboxes/${id}`,
  FetchByOwnerId: (id: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/faxboxes?filter_owner_id=${id}`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/faxboxes`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/faxboxes/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/faxboxes/${id}`,
};
