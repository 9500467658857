import { useNavigation } from 'apps/PhoneSystem/shared/useNavigation';
import { ContainerMeta } from 'apps/shared/definition';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Edit from './Edit';
import List from './List';

export const meta: ContainerMeta = {
  // t('phone_system:containers.menu.label')
  label: 'phone_system:containers.menu.label',
  icon: 'grid-view-outlined',
  slug: 'menu',
};

const Menu = () => {
  const EditWithNav = useNavigation(Edit);
  const routes = useRoutes([
    { path: '/', element: <List /> },
    { path: '/:id', element: <EditWithNav /> },
  ]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};

export default Menu;
