import { getTimezoneLabelsWithDst } from 'shared/utility/date/index';
import { timezoneList } from '../data/data';
import { kazooTimezoneList } from '../data/kazooData';
import { Timezone, TimezoneOptions } from '../definition';

/**
 * @description Builds a map of timezones with daylight saving time (DST) information.
 *
 * @returns The map of timezones with DST information.
 */
export const getTimezoneWithDstMap = () =>
  Object.entries(timezoneList).reduce<Timezone>((timezonesMap, zone) => {
    const { name, label } = getTimezoneLabelsWithDst(zone);

    timezonesMap[label] = name;
    return timezonesMap;
  }, {});

/**
 * @description Retrieves the timezone label with DST for the specified Region/City from Kazoo data.
 *
 * @param regionAndCity - The Region/City for which to retrieve the timezone label with DST.
 *
 * @returns The timezone label with DST for the specified city.
 */
export const getKazooTimezoneWithDst = (regionAndCity: any): string => {
  const { label } = getTimezoneLabelsWithDst([regionAndCity, kazooTimezoneList[regionAndCity]]);

  return label;
};

/**
 * @description Sorts timezone options based on GMT offset and label.
 *
 * @param a - The first timezone to compare.
 * @param b - The second timezone to compare.
 *
 * @returns The comparison result.
 */
export const sortTimezoneOptions = (a: TimezoneOptions, b: TimezoneOptions) => {
  const aMatch = a.label.match(/GMT([+-]\d+):/);
  const bMatch = b.label.match(/GMT([+-]\d+):/);

  if (!aMatch || !bMatch) {
    return 0;
  }

  const aHour = parseInt(aMatch[1]);
  const bHour = parseInt(bMatch[1]);

  if (aHour === bHour) {
    return a.label.localeCompare(b.label);
  }
  return aHour - bHour;
};
