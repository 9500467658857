import Joi from 'joi';
import { messages } from 'shared/utility/validation';

export const schema = () =>
  Joi.object({
    name: Joi.string().required(),
    moh: Joi.string().allow(''),
    call_recording_url: Joi.string().allow(''),
    record_caller: Joi.boolean(),
    strategy: Joi.string().allow(''),
    agent_wrapup_time: Joi.number().required(),
    max_queue_size: Joi.number().required(),
    connection_timeout: Joi.number().required(),
    caller_exit_key: Joi.string()
      .allow('')
      .max(1)
      .pattern(/^[0-9#*]+$/),
    announcements: Joi.object({
      interval: Joi.number().required(),
    }).unknown(true),
    hide_in_dashboard: Joi.boolean(),
    enter_when_empty: Joi.boolean(),
    notifications: Joi.object({
      hangup: Joi.string().allow(''),
      pickup: Joi.string().allow(''),
      method: Joi.string().allow(''),
    }),
  })
    .unknown(true)
    .messages(messages());
