import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledEditableInputListProps as Props } from './definition';

const StyledEditableInputList = styled.div<Props>`
  ${(props) => css`
    margin: 0 0 12px;

    > div {
      &:first-of-type {
        ${mixin.flex({ justifyContent: 'flex-start' })};
        margin: 0 0 12px;

        .MuiInputLabel-root {
          margin: 0;
        }
      }

      &:last-of-type {
        > div {
          ${mixin.flex({ justifyContent: 'flex-start' })};

          > div {
            &:first-of-type {
              margin: 0 0 8px;

              > .MuiInputLabel-root {
                display: none;
              }
            }

            &:last-of-type:not(:first-of-type) {
              margin: -10px 0 0 12px;
            }
          }

          > svg {
            margin: -10px 0 0 12px;

            &:last-of-type:not(:first-of-type) {
              margin-left: 8px;
            }
          }
        }
      }
    }
  `};
`;

export default StyledEditableInputList;
