import MuiDialog from '@mui/material/Dialog';
import kebabCase from 'lodash/kebabCase';
import { BaseSyntheticEvent, ForwardedRef, forwardRef } from 'react';
import DialogContent from './components/DialogContent';
import DialogTitle from './components/DialogTitle';
import defaultProps from './default';
import { DialogProps as Props } from './definition';

export { default as DialogActions } from './components/DialogActions';
export { default as DialogLink } from './components/DialogLink';
export { DialogType } from './definition';
export type { DialogActionsCloseReasons, DialogProps, DialogState } from './definition';

const Dialog = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const {
    hasClose,
    hasTitle,
    isContentSelectable,
    children,
    title,
    type,
    renderActions,
    dataTestId,
    onClose,
    ...rest
  } = {
    ...defaultProps,
    ...props,
  };

  const classes = [];
  if (type !== 'default') {
    classes.push(`MuiDialog-${type}`);
  }

  return (
    <MuiDialog
      classes={{ root: classes.join(' ') }}
      data-test-id={dataTestId}
      onBackdropClick={(e: BaseSyntheticEvent) => onClose?.(e, 'backdropClick')}
      onClose={(e: BaseSyntheticEvent) => onClose?.(e, 'escapeKeyDown')}
      {...rest}
    >
      {hasTitle && (
        <DialogTitle
          hasClose={hasClose}
          data-test-id={`dialog-title-${kebabCase(title)}`}
          dialogType={type}
          role="heading"
          onClose={onClose}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent
        isSelectable={isContentSelectable}
        data-test-id="dialog-content-container"
        dialogType={type}
        ref={ref}
      >
        {children}
      </DialogContent>
      {renderActions}
    </MuiDialog>
  );
});

export default Dialog;
