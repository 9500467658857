import { useMemo, useState } from 'react';
import CONSTANTS from '../../constants';
import { selectedCallQueueStorage } from './utility/selectedCallQueueStorage';

export const useSelectedQueueId = (accountId: string) => {
  const [selectedQueueIdRaw, setSelectedQueueIdRaw] = useState<string>(
    selectedCallQueueStorage.getSelectedQueueId(accountId) ?? CONSTANTS.ALL_QUEUES_ID,
  );

  const selectedQueueId = useMemo(() => {
    return selectedCallQueueStorage.getSelectedQueueId(accountId) ?? selectedQueueIdRaw;
  }, [accountId, selectedQueueIdRaw]);

  const setSelectedQueueId = (id: string) => {
    setSelectedQueueIdRaw((prevId: string) => {
      const result = prevId === id ? CONSTANTS.ALL_QUEUES_ID : id;
      selectedCallQueueStorage.setSelectedQueueById(accountId, result);
      return result;
    });
  };

  return {
    selectedQueueId,
    setSelectedQueueId,
  };
};
