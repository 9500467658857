import { CHARACTER, MILLISECONDS_IN_SECOND, SECONDS_GREGORIAN_OFFSET } from 'constant';
import { Download } from 'definition';
import { StatusCodes } from 'http-status-codes';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { RowArray } from '../definition';

/**
 * @name columnize
 * @description Takes an array of data and renders it into "cell" elements
 *
 * @param {any[]} data Data array
 * @param {(item: any) => void} map Callback function used for rendering cell content
 * @param {{ [key: string]: number | string }} style Style object used for styling "cell" element
 * @param {number} columns Column count
 *
 * @return {JSX.Element[]} Array of elements
 */
export const columnize = (
  data: any[],
  map: (item: any) => void,
  style: { [key: string]: number | string } = {},
  columns = 2,
): JSX.Element[] => {
  const columnizedData = [...Array(columns).keys()].map((item: any) =>
    data.filter((_: any, i: number) => i % columns === item),
  );
  return columnizedData.map((column: any[], i: number) => (
    <div role="cell" key={`cell-${i}`} style={style}>
      {column.map(map)}
    </div>
  ));
};

/**
 * @name dateToGregorian
 * @description Converts a Date instance into a gregorian timestamp
 *
 * @param {Date} date Date instance
 *
 * @return {number} Gregorian timestamp
 */
export const dateToGregorian = (date: Date): number =>
  Math.floor(date.getTime() / MILLISECONDS_IN_SECOND) + SECONDS_GREGORIAN_OFFSET;

/**
 * @name formatDuration
 * @description Convert a duration object into 'hh:mm:ss' format.
 *
 * @param duration
 *
 * @returns Time in hh:mm:ss format or emdash
 */
export const formatDuration = (duration: any) => {
  const pad = (value: number): string => value.toString().padStart(2, '0');
  return duration
    ? `${pad(duration.hours)}:${pad(duration.minutes)}:${pad(duration.seconds)}`
    : CHARACTER.EMDASH;
};

/**
 * @name getCollectionList
 * @description Transform the confirm list (into a object-nested array).
 *
 * @param accessor
 * @param data
 *
 * @returns Object with nested numbers array.
 */
export const getCollectionList = ({ accessor, data }: { accessor?: string; data: RowArray }) => ({
  body: { numbers: data.map((row) => get(row, accessor ?? 'number')) },
});

/**
 * @name getTableDataFromJson
 * @description Create the table data from a JSON object.
 *
 * @param data
 *
 * @returns Data array.
 */
export const getTableDataFromJson = (data: any) => {
  const tableData = get(data, 'usage.spare', {});
  return tableData ? Object.keys(tableData).map((key) => tableData[key]) : [];
};

/**
 * @name gregorianToDate
 * @description Converts a Gregorian timestamp into a Date instance
 *
 * @param {Number} pTimestamp Gregorian timestamp
 *
 * @return {Date} Converted Date instance
 */
export const gregorianToDate = (pTimestamp: string | number): Date => {
  const timestamp = isString(pTimestamp) ? parseInt(pTimestamp) : pTimestamp;
  if (isNaN(timestamp) || !isNumber(timestamp)) {
    throw new Error('`timestamp` is not a valid Number');
  }
  return new Date((Math.floor(timestamp) - SECONDS_GREGORIAN_OFFSET) * MILLISECONDS_IN_SECOND);
};

/**
 * @name handleRequestError
 * @description Transform and pass errors from a response error to the given ReactHookForm setError function
 *
 * @param {any} err Error from the response object
 * @param {any} setError React Hook Form Set error Function
 *
 * @return {void}
 */
export const handleRequestError = (err: any, setError: any): void => {
  if (err.status === StatusCodes.BAD_REQUEST) {
    const { data } = err.data;
    Object.keys(data).forEach((name: any) =>
      Object.keys(data[name]).forEach((errMsg: any) => {
        setError(`seat.${name}`, {
          type: errMsg,
          message: data[name][errMsg].message,
        });
      }),
    );
  }
};

/**
 * @name macAddressMask
 * @description Format given string to a MAC address
 *
 * @param input Input String to be formatted
 * @return Formatted MAC address
 */
export const macAddressMask = (input: string): string => {
  const MAC_ADDRESS_WITH_MASK = 17;
  const setCharacters = new Set('0123456789abcdefABCDEF'.split(''));

  let res = '';
  let idxAtMask = -1;
  for (let idx = 0; idx < input.length; idx++) {
    const currChar = input.charAt(idx);
    if (setCharacters.has(currChar)) {
      if (idxAtMask === 1) {
        res += ':';
        idxAtMask = -1;
      }
      res += currChar;
      idxAtMask++;
    }
  }

  return res.length <= MAC_ADDRESS_WITH_MASK ? res : res.substr(0, MAC_ADDRESS_WITH_MASK);
};

/**
 * @name unmaskMacAddress
 * @description Function to convert masked mac address to unmasked mac address
 *
 * @param {string} macAddress Masked mac address string
 * @return {string} Unmasked mac address string
 */
export const unmaskMacAddress = (macAddress: string): string =>
  macAddress?.toLocaleLowerCase().replaceAll(':', '');

/**
 * @name validateNumber
 * @description Test if the given value is a valid number
 *
 * @param {number} value
 * @return {boolean}
 */
export const validateNumber = (value: number): boolean => !Number.isNaN(value) && isNumber(value);

/**
 * @name validatePhoneForE164
 * @description Test if the given value is a valid phone number for E164
 *
 * @param {number} value
 * @return {boolean}
 */
export const validatePhoneForE164 = (value: string) => {
  const regEx = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  return regEx.test(value);
};

/**
 * @name mergeArraysCustomizer
 * @description The customized handler to be used with mergeWith from Lodash. It overrides the array from objValue to srcValue, when merging two objects
 *
 * @param objValue - The value from the current object's key.
 * @param srcValue - The value from the new object's key.
 * @returns - The srcValue value for arrays.
 */
export const mergeArraysCustomizer = (objValue: any, srcValue: any) => {
  if (isArray(objValue) && isArray(srcValue)) {
    return srcValue;
  }
  return undefined;
};

/**
 * @name timeToTimeString
 * @description Formats the given time into the 'hh:mm:ss' pattern
 *
 * @param time - The time value to convert.
 * @returns The formatted time in the 'hh:mm:ss' pattern
 */
export const timeToTimeString = (time: number) => {
  const hours = Math.floor(time / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (time % 60).toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

/**
 * @name triggerDownload
 * @description Trigger the CSV download event.
 *
 * @param downloadValues
 */
export const triggerDownload = ({ name, url }: Download) => {
  const a = document.createElement('a');
  a.download = name;
  a.href = url;
  a.click();
};
