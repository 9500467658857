import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import MenuItem from 'shared/components/DropdownButton/components/MenuItem';
import { MenuItemNoItemsTextProps as Props } from './definition';

const MenuItemNoItemsText: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { isInfo, value = t('common:component.dropdown_button.no_items_text') } = props;
  const CLASSNAME = 'MuiMenuItem-noitemstext';

  return (
    <MenuItem
      className={`${CLASSNAME}${isInfo ? ` ${CLASSNAME}-with-border` : ''}`}
      data-test-id="shared-dropdown-button-menu-item-noitemstext"
    >
      <Box component="span">{value}</Box>
    </MenuItem>
  );
};

export default MenuItemNoItemsText;
