import { extractWindowValueAndUnit } from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowActionsDialog/components/WaitTimeDialog/utility';
import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';
import { Metadata } from '../store/definition';

class WaitTimeActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.WAIT_TIME,
      module: CallFlowModuleKey.WAIT_TIME,
      section: CallFlowSection.CALL_CENTER,
      rules: [
        {
          type: 'quantity',
          maxSize: 9999,
        },
      ],
      iconProps: {
        name: 'timer-outlined',
      },
      label: i18next.t('phone_system:containers.callflows.actions.wait_time.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.wait_time.tooltip'),
    });
  }

  static subtitle = (nodeData: any, metadata: Metadata) => {
    let subtitle = metadata?.name + '';

    if (nodeData.var) {
      subtitle += ` {${nodeData.var}}`;
    }

    if (nodeData.window) {
      const [window, window_unit] = extractWindowValueAndUnit(nodeData?.window);
      subtitle += ` {${window} ${window_unit}}`;
    }

    return subtitle;
  };
}

export default WaitTimeActionConfig;
