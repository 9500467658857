import { FunctionComponent } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled, { css } from 'styled-components';
import { StyledRichTextEditorProps as Props } from './definition';

const WysiwygEditor: FunctionComponent<Props> = (props: Props) => {
  return <Editor {...props} wrapperClassName={props.className} />;
};

export const StyledEditor = styled(WysiwygEditor)<Props>`
  ${(props) => css`
    .rdw-editor-toolbar {
      .rdw-option-wrapper {
        min-width: 30px;
        height: 30px;
      }
    }

    .rdw-editor-main {
      height: 300px;
      border: 1px solid #f1f1f1;
      padding: ${props.theme.spacing(0.5)} ${props.theme.spacing(3)};
      border-radius: 2px;
      background-color: #ffffff;
    }
  `};
`;

export const StyledFooter = styled.div`
  ${(props) => css`
    display: flex;
    justify-content: space-between;
    margin-top: ${props.theme.spacing(1.5)};
    line-height: ${props.theme.spacing(3)};

    > div {
      display: flex;

      &.new_line_instruction strong {
        margin: 0 ${props.theme.spacing(0.5)};
      }
    }
  `}
`;

export const StyledRichTextEditor = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default StyledRichTextEditor;
