import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class RingGroupActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.RING_GROUP,
      module: CallFlowModuleKey.RING_GROUP,
      section: CallFlowSection.BASIC,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'ring-group',
      },
      label: i18next.t('phone_system:containers.callflows.actions.ring_group.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.ring_group.tooltip'),
    });
  }

  static subtitle = (nodeData: any) => nodeData?.name;
}

export default RingGroupActionConfig;
