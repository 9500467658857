import React from 'react';
import { Link } from 'react-router-dom';
import { useUrlWithQueryParams } from './hooks';

export const RouterLink = React.forwardRef((props: any, ref) => {
  const { to, urlSuffix } = props;
  const url = useUrlWithQueryParams({ to });

  return <Link {...props} ref={ref} to={`${url}${urlSuffix ?? ''}`} />;
});
