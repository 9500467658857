const defaultProps = {};

export default defaultProps;

export const defaultLoginValues = {
  username: '',
  password: '',
  account_name: '',
};

export const defaultResetValues = {
  reset_username: '',
  reset_account_name: '',
};
