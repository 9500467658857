import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import { SetupIDProviderButtonProps as Props } from './definition';
import { StyledSetupIdpButton } from './style';

const SetupIdpButton: FunctionComponent<Props> = (props: Props) => {
  const { onClick, isCreate } = { ...props };
  const { t } = useTranslation();

  return (
    <StyledSetupIdpButton>
      <Button
        variant={isCreate ? 'contained' : 'outlined'}
        startIcon={<Icon name="edit" />}
        onClick={onClick}
      >
        {isCreate
          ? t(
              'phone_system:containers.account.submodule.settings.section.sso.setup_idp.setup_idp_button',
            )
          : t(
              'phone_system:containers.account.submodule.settings.section.sso.setup_idp.edit_idp_button',
            )}
      </Button>
    </StyledSetupIdpButton>
  );
};

export default SetupIdpButton;
