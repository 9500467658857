import { triggerDownload } from 'apps/shared/utility';
import { MEDIA_TYPE } from 'constant';
import { format } from 'date-fns';
import { Download } from 'definition';
import kebabCase from 'lodash/kebabCase';
import { useFetchAccountQuery } from 'models/Account';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { DownloadIconProps as Props } from './definition';
import { getCsvBlob } from './utility';

const DownloadIcon = ({ data = [] }: Props) => {
  const { t } = useTranslation();
  const downloadRef = useRef<Download>({ name: '', url: '' });

  const { data: accountData } = useFetchAccountQuery();

  useEffect(() => {
    if (data && accountData) {
      downloadRef.current = {
        name: t('phone_system:containers.e911info.action_row.download.file.name', {
          accountName: kebabCase(accountData.name),
          timestamp: format(new Date(), 'yyyyMMdd-HHmm'),
        }),
        url: URL.createObjectURL(
          new Blob(getCsvBlob({ data }), { type: MEDIA_TYPE.TYPE.TEXT.CSV.WITH_CHARSET }),
        ),
      };
    }
  }, [accountData, data, t]);

  return (
    <Tooltip title={t('phone_system:containers.e911info.action_row.download.tooltip')}>
      <Icon
        name="download"
        {...(data.length > 0
          ? { hasHover: true, onClick: () => triggerDownload(downloadRef.current) }
          : { color: 'disabled' })}
      />
    </Tooltip>
  );
};

export default DownloadIcon;
