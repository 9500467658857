import { FunctionComponent } from 'react';
import { AccountStatusProps as Props } from './definition';
import StyledAccountStatus from './style';

const AccountStatus: FunctionComponent<Props> = ({ date, status }: Props): JSX.Element => (
  <StyledAccountStatus>
    <span>{status}</span>
    <span>{date}</span>
  </StyledAccountStatus>
);

export default AccountStatus;
