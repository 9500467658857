import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledActionProps as Props } from './definition';

const StyledAction = styled.div<Props>`
  ${(props) => css`
    ${mixin.borderRadius({ radius: 'half' })};
    ${mixin.flex({ direction: 'column' })};
    ${mixin.transition({ property: 'border-color' })};
    height: 75px;
    padding: 10px;
    background-color: ${props.theme.core.background.grey.medium};
    border: 3px solid transparent;
    user-select: none;
    text-align: center;

    span {
      ${mixin.font({ size: 13 })};
      ${mixin.transition({ property: 'color' })};
      color: ${props.theme.core.background.grey.dark};
    }

    svg {
      ${mixin.transition({ property: 'fill' })};
      margin: 0 0 6px;
      fill: ${props.theme.core.background.grey.dark};
      transform: scale(0.8);
    }

    &:active,
    &:hover {
      border-color: ${props.theme.core.color.blue.draggable};

      span {
        color: ${props.theme.core.color.blue.draggable};
      }

      svg {
        fill: ${props.theme.core.color.blue.draggable};
      }
    }

    ${props.isHidden &&
    css`
      display: none;
    `};

    ${props.isOver &&
    css`
      &,
      &:active,
      &:hover {
        background-color: ${props.theme.core.color.blue.draggable};
        border-color: ${props.theme.core.color.blue.draggable};

        span {
          color: ${props.theme.core.color.white};
        }

        svg {
          fill: ${props.theme.core.color.white};
        }
      }
    `};
  `};
`;

export default StyledAction;
