// Seat Mutations

import { User } from 'api/user';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const seatMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createSeat: builder.mutation<Seat, Partial<Seat>>({
      query: ({ body }) => ({
        url: User.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [
        { type: TAGS.SEAT, id: 'LIST' },
        { type: TAGS.SEAT, id: 'LIST_BY_SEAT_TYPE' },
        { type: TAGS.USER, id: 'LIST' },
        { type: TAGS.ACCOUNT },
      ],
    }),
    updateSeat: builder.mutation<Seat, UpdateSeatParams>({
      query: ({ id, body }) => ({
        url: User.UpdateById(undefined, id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.SEAT, id },
        { type: TAGS.ACCOUNT },
        { type: TAGS.USER, id: 'LIST' },
      ],
    }),
    patchSeat: builder.mutation<Seat, UpdateSeatParams>({
      query: ({ id, body }) => ({
        url: User.UpdateById(undefined, id),
        method: RequestMethod.Patch,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.SEAT, id },
        { type: TAGS.ACCOUNT },
        { type: TAGS.USER, id: 'LIST' },
      ],
    }),
    patchSeatWithAccountId: builder.mutation<Seat, UpdateSeatParams>({
      query: ({ id, accountId, body }) => ({
        url: User.UpdateById(accountId, id),
        method: RequestMethod.Patch,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.SEAT, id }, { type: TAGS.ACCOUNT }],
    }),
    updateSeatHeroApps: builder.mutation<Seat, UpdateSeatParams>({
      query: ({ id, body }) => ({
        url: User.UpdateHeroAppsById(undefined, id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.SEAT, id }],
    }),
    deleteSeat: builder.mutation<Seat, ByIdParam>({
      query: ({ id }) => ({
        url: User.DeleteById(undefined, id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: [
        { type: TAGS.SEAT, id: 'LIST' },
        { type: TAGS.SEAT, id: 'LIST_BY_SEAT_TYPE' },
        { type: TAGS.USER, id: 'LIST' },
        { type: TAGS.ACCOUNT },
      ],
    }),
  }),
});
