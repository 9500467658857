import i18next from 'i18next';

export const columns = () => [
  {
    Header: i18next.t('phone_system:containers.seats.faxbox.table.column.name.header'),
    accessor: 'name',
  },
  {
    Header: i18next.t(
      'phone_system:containers.seats.faxbox.table.column.sends_notification_to.header',
    ),
    accessor: (props: any) => {
      const emailValue = [];
      if (Array.isArray(props.notifications?.inbound.email.send_to)) {
        emailValue.push(...props.notifications.inbound.email.send_to);
      }
      if (Array.isArray(props.notifications?.outbound.email.send_to)) {
        emailValue.push(...props.notifications.outbound.email.send_to);
      }
      return emailValue.join(', ');
    },
    id: 'faxbox_emails',
  },
];
