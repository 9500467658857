import { WidthType } from 'shared/utility/definition';
import labeledProps from '../../default';

const defaultProps = {
  ...labeledProps,
  inputWidth: 'large' as WidthType,
  inputProps: {},
  label: '__INPUT_LABEL__',
  labelWidth: 'large' as WidthType,
  labelProps: {},
};

export default defaultProps;
