import MuiTooltip, { TooltipProps as Props } from '@mui/material/Tooltip';
import List from './components/List';
import defaultProps from './default';

const Tooltip = ({ className = defaultProps.className, children, title, ...rest }: Props) =>
  title ? (
    <MuiTooltip
      classes={{ tooltip: `MuiTooltip-tooltip-${className}` }}
      title={Array.isArray(title) ? <List items={title} /> : title}
      {...rest}
    >
      {children}
    </MuiTooltip>
  ) : (
    children
  );

export default Tooltip;
