import ConfirmSelectionDialog from 'apps/shared/components/ConfirmSelectionDialog';
import { RowArray } from 'apps/shared/definition';
import {
  useFetchQueueQuery,
  useFetchQueueUsersQuery,
  useUpdateQueueUserMutation,
} from 'models/Queues';
import { useFetchUsersQuery } from 'models/User';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Loading from 'shared/components/Loading';
import Table, { Justify, SelectionType } from 'shared/components/Table';
import { useActionRow } from 'shared/hooks/useActionRow';
import { useToast } from 'shared/hooks/useToast';
import AssignAgentsDialog from './components/AssignNumberDialog';
import { AgentsListProps as Props } from './definition';
import {
  buildActionRowButtons,
  columns,
  getDialogProps,
  getQueueName,
  prepareTableData,
} from './utility';

const AgentsList = ({ id }: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState<boolean>(false);
  const [isUnassignDialogOpen, setIsUnassignDialogOpen] = useState<boolean>(false);
  const [confirmData, setConfirmData] = useState<RowArray>([]);
  const [selectedRows, setSelectedRows] = useState<RowArray>([]);

  const { data: queueAgentsData = [], isLoading: isLoadingQueueAgents } = useFetchQueueUsersQuery({
    id,
  });
  const { data: queuesData = [], isLoading: isLoadingQueues } = useFetchQueueQuery();
  const { data: usersData = [], isLoading: isLoadingUsers } = useFetchUsersQuery();
  const [updateQueueUser] = useUpdateQueueUserMutation();

  const queueName = useMemo(() => (queuesData ? getQueueName(id, queuesData) : ''), [
    queuesData,
    id,
  ]);

  const table = {
    columns: useMemo(() => columns(), []),
    data: useMemo(
      () => (queueAgentsData && usersData ? prepareTableData(queueAgentsData, usersData) : []),
      [queueAgentsData, usersData],
    ),
  };

  const onAssignAgents = async (agentsToAssign: RowArray) => {
    const newAgents = agentsToAssign.map((agent) => agent.id);
    const allAgents = queueAgentsData && [...newAgents, ...queueAgentsData];

    const response: any = await updateQueueUser({
      id,
      body: allAgents,
    });

    if (response.data) {
      showToast.success(
        t('call_center:containers.manage_queues.components.agents_list.dialog.assign.success.0', {
          count: Object.keys(newAgents).length,
        }),
      );
    } else {
      showToast.error(
        t('call_center:containers.manage_queues.components.agents_list.dialog.assign.error'),
      );
    }

    setConfirmData([]);
    setSelectedRows([]);
  };

  const onUnassignAgents = async (agentsToUnassign: RowArray) => {
    const agentsIdsToUnassign = agentsToUnassign.map((agent) => agent.id);

    const updatedAgents = queueAgentsData?.filter(
      (agent: any) => !agentsIdsToUnassign.includes(agent),
    );

    const response: any = await updateQueueUser({
      id,
      body: updatedAgents,
    });

    if (response.data) {
      showToast.success(
        t('call_center:containers.manage_queues.components.agents_list.dialog.unassign.success.0', {
          count: agentsIdsToUnassign?.length,
        }),
      );
    } else {
      showToast.error(
        t('call_center:containers.manage_queues.components.agents_list.dialog.unassign.error'),
      );
    }

    setSelectedRows([]);
    setConfirmData([]);
    setIsUnassignDialogOpen(false);
  };

  const { ActionRow, actionRowProps } = useActionRow({
    hasDelete: false,
    hasHelp: false,
    hasSave: false,
    breadcrumbData: [
      {
        text: t('call_center:containers.manage_queues.components.agents_list.title'),
        url: '/apps/call-center/manage-queues',
      },
      {
        text: queueName,
      },
    ],
  });

  if (isLoadingUsers || isLoadingQueueAgents || isLoadingQueues) {
    return <Loading />;
  }

  return (
    <Box>
      <ActionRow {...actionRowProps} />
      <Table
        hasSearch
        actionRowButtons={{
          left: buildActionRowButtons({
            selectedRows,
            setConfirmData,
            setIsAssignDialogOpen,
            setIsUnassignDialogOpen,
          }),
        }}
        actionRowButtonAlignment={{
          left: Justify.Left,
        }}
        columns={table.columns}
        data={table.data}
        selection={{
          type: SelectionType.Multiple,
          setRows: setSelectedRows,
        }}
        title=""
      />
      <AssignAgentsDialog
        isDialogOpen={isAssignDialogOpen}
        queueAgents={queueAgentsData}
        users={usersData}
        setIsDialogOpen={setIsAssignDialogOpen}
        onAssignAgents={onAssignAgents}
      />
      <ConfirmSelectionDialog
        {...getDialogProps({
          isUnassignDialogOpen,
          confirmData,
          queueName,
          on: { unassign: onUnassignAgents },
          setConfirmData,
        })}
      />
    </Box>
  );
};

export default AgentsList;
