import { DEFAULT_EMAIL_ADDRESS } from 'constant';
import { CallRestrictionOptionType, ClassifierSet } from 'definition';
import { TEMP_PROPERTY } from './constant';

const call_restriction = ['closed_groups', ...Object.values(ClassifierSet)].reduce(
  (acc: Record<string, Record<string, CallRestrictionOptionType>>, classifier: string) => {
    acc[classifier] = { action: CallRestrictionOptionType.Inherit };
    return acc;
  },
  {},
);

export const defaultValues = {
  account_type: '',
  name: '',
  realm: '',
  timezone: 'America/Vancouver',
  language: 'en-US',
  announcement: '',
  custom_notes: '',
  is_three_party_conference_immediate_merge_enabled: false,

  caller_id: {
    emergency: {
      name: '',
      number: '',
    },
    external: {
      name: '',
      number: '',
    },
    internal: {
      name: '',
      number: '',
    },
  },
  call_restriction,
  contact: {
    billing: {
      country: '',
      email: '',
      locality: '',
      name: '',
      number: '',
      postal_code: '',
      region: '',
      street_address: '',
    },
    technical: {
      email: '',
      name: '',
      number: '',
      same_as_billing: false,
    },
  },
  [TEMP_PROPERTY.NOTIFICATIONS]: {
    voicemail_to_email: {
      from: DEFAULT_EMAIL_ADDRESS,
    },
    fax_inbound_error_to_email: {
      enabled: false,
      from: DEFAULT_EMAIL_ADDRESS,
      use_voicemail_to_email_from: false,
    },
    deregister: {
      enabled: false,
      from: DEFAULT_EMAIL_ADDRESS,
      send: {
        email_addresses: [],
        type: {
          account_admins: true,
          specified: false,
        },
      },
      subject: '',
    },
    new_seat_created: {
      enabled: false,
      from: DEFAULT_EMAIL_ADDRESS,
      send: {
        email_addresses: [],
        type: {
          account_admins: true,
          specified: false,
        },
      },
      subject: '',
    },
  },
};
