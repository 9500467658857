import { EditorState } from 'draft-js';
import { FunctionComponent, useEffect, useState } from 'react';
import { htmlStringToDraftContentState } from '../RichTextEditor';
import defaultProps from './default';
import { RichTextViewerProps as Props } from './definition';
import StyledRichTextViewer from './style';

const RichTextViewer: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { text, wrapperId }: Props = { ...defaultProps, ...props };

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(htmlStringToDraftContentState(text).getCurrentContent()),
  );

  useEffect(
    () =>
      setEditorState(
        EditorState.createWithContent(htmlStringToDraftContentState(text).getCurrentContent()),
      ),
    [text],
  );

  return (
    <StyledRichTextViewer
      {...(wrapperId ? { wrapperId } : {})}
      toolbarHidden
      readOnly
      editorState={editorState}
      onEditorStateChange={setEditorState}
    />
  );
};

export default RichTextViewer;
