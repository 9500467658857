import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { DIAL_STRATEGIES } from '../../constants';

const schema = () => {
  return Joi.object({
    advanced: Joi.boolean(),
    can_call_self: Joi.boolean(),
    delay: Joi.number(),
    dial_strategy: Joi.string().valid(...DIAL_STRATEGIES),
    id: Joi.string().required(),
    nodeId: Joi.string(),
    strategy: Joi.string().valid(...DIAL_STRATEGIES),
    timeout: Joi.number().min(0).required(),
    var: Joi.when(Joi.ref('advanced'), {
      is: Joi.boolean().valid(true),
      then: Joi.string().required(),
      otherwise: Joi.string().allow('').optional(),
    }),
  }).messages(messages());
};

export default schema;
