import i18next from 'i18next';
import { QUICKVIEW_CONSTANTS } from '../..';

const translations = () => ({
  button: {
    cancel: {
      label: i18next.t('common:dismiss'),
    },
  },
  dialog: {
    expanded: {
      content: i18next.t('phone_system:containers.callflows.quick_view.dialog.expanded.content', {
        max: QUICKVIEW_CONSTANTS.MAXIMUM.EXPANDED,
      }),
      title: i18next.t('phone_system:containers.callflows.quick_view.dialog.expanded.title'),
    },
    minimized: {
      content: i18next.t('phone_system:containers.callflows.quick_view.dialog.minimized.content', {
        max: QUICKVIEW_CONSTANTS.MAXIMUM.MINIMIZED,
      }),
      title: i18next.t('phone_system:containers.callflows.quick_view.dialog.minimized.title'),
    },
  },
});

export default translations;
