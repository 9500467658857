import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { CONSTANTS } from '../constants';
import { StyledTabPanelProps } from './definition';

const StyledTabPanel = styled(Box)<StyledTabPanelProps>`
  ${({ $hasDescription, theme }) => css`
    margin: 0 0 ${CONSTANTS.HEIGHT};

    &:first-of-type:last-of-type {
      margin: 0;
    }

    &:last-of-type {
      margin: 0 0 calc(${CONSTANTS.HEIGHT} - 10vh);
    }

    ${!$hasDescription &&
    css`
      padding: ${theme.spacing(6, 0, 0)};
    `};

    > h2,
    > h3 {
      width: 100%;
      margin: ${theme.spacing(2, 0, 3)};
      padding: ${theme.spacing(0, 0, 2)};
      border-bottom: 1px solid ${theme.core.input.border.default};
      user-select: none;
    }

    > h2 {
      ${mixin.font({ size: 16, weight: 600 })};
    }

    > h3 {
      padding: ${theme.spacing(0, 0, 1)};

      &,
      > * {
        ${mixin.font({
          letterSpacing: '0.3px',
          lineHeight: '22px',
          size: 13,
          style: 'italic',
          weight: 400,
        })};
      }

      > * {
        display: block;
      }

      > strong {
        font-weight: 600;
      }

      &.space {
        margin-top: ${theme.spacing(4)};
      }
    }

    > p {
      ${mixin.font({ lineHeight: '1.2em', size: 14, style: 'italic' })};
      margin: ${theme.spacing(0, 0, 6)};
    }

    > pre {
      ${mixin.font({ family: '"Courier New", courier, monospace', lineHeight: 1.5, size: 13 })};
      margin: ${theme.spacing(0, 5, 2, 0)};
    }

    div[role='row'] {
      display: grid;
      grid-gap: ${theme.spacing(6)};

      &.one-column {
        grid-template: auto / 1fr;

        &:not(.is-stack) div[role='cell'] {
          ${mixin.flex({ alignItems: 'flex-start', justifyContent: 'flex-start' })}
        }
      }

      &, // default
      &.two-column {
        grid-template: auto / repeat(2, 1fr);
      }

      &.three-column {
        grid-template: auto / repeat(3, 1fr);
      }

      &.four-column {
        grid-template: auto / repeat(4, 1fr);
      }

      &.overline {
        padding-top: ${theme.spacing(6)};
        border-top: 1px solid ${theme.core.input.border.default};
      }

      &.underline {
        margin-bottom: ${theme.spacing(6)};
        border-bottom: 1px solid ${theme.core.input.border.default};
      }

      &.split {
        display: flex;

        &.split-with-link {
          align-items: center;
        }
      }

      div[role='cell'] {
        ${mixin.flex({
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        })}
      }
    }
  `}
`;

export default StyledTabPanel;
