import Agents from 'api/agents';
import { kazooApi, responseHandler, TAGS } from '../../services/kazoo';

export const queueQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAgents: builder.query<AgentsResponse, void>({
      query: () => ({
        url: Agents.FetchAll(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.AGENTS }],
    }),
    fetchAgentById: builder.query<Agent, { id: string }>({
      query: ({ id }: { id: string }) => ({
        url: Agents.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.AGENTS, id }];
      },
    }),
    fetchAgentsStats: builder.query<AgentsStats, void>({
      query: () => ({
        url: Agents.FetchStats(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.AGENTS }],
    }),
    fetchAgentsStatus: builder.query<AgentsStatus, void>({
      query: () => ({
        url: Agents.FetchStatus(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.AGENTS }],
    }),
    fetchAgentStatusById: builder.query<AgentStatus, { id: string }>({
      query: ({ id }: { id: string }) => ({
        url: Agents.FetchStatusById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.AGENTS, id }];
      },
    }),
  }),
});
