import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class DirectInwardSystemAccessActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.DISA,
      module: CallFlowModuleKey.DISA,
      section: CallFlowSection.CARRIER,
      rules: [
        {
          type: 'quantity',
          maxSize: 0,
        },
      ],
      iconProps: {
        name: 'phone-greeting-filled',
      },
      label: i18next.t(
        'phone_system:containers.callflows.actions.direct_inward_system_access.name',
      ),
      tooltip: i18next.t(
        'phone_system:containers.callflows.actions.direct_inward_system_access.tooltip',
      ),
    });
  }
}

export default DirectInwardSystemAccessActionConfig;
