import { FunctionComponent, useMemo } from 'react';
import EnhancedSelect from 'shared/components/EnhancedSelect';
import Input from 'shared/components/Input';
import { SelectOption } from 'shared/components/Select';
import Textarea from 'shared/components/Textarea';
import * as utility from 'shared/utility';
import { EditableListSelect } from '../../../definition';
import { filterOptions } from '../../../utility';
import { FootProps as Props } from './definition';

const Foot: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    isWindowedSelect,
    column: {
      input: { isTextarea = false, placeholder, validationMask } = {},
      select: _select,
      width,
    },
    columnIndex: index,
    list,
    state: { input, select, setSelect },
    handleChange,
    reset,
  } = props;

  const inputComponent = useMemo(
    () =>
      isTextarea ? (
        <Textarea
          value={input[index] as string}
          onChange={(e) => handleChange.input(e, index, validationMask)}
          placeholder={placeholder}
          width="auto"
          minHeight={40}
        />
      ) : (
        <Input
          value={input[index] as string}
          onChange={(e) => handleChange.input(e, index, validationMask)}
        />
      ),
    [isTextarea, handleChange, index, input, placeholder, validationMask],
  );

  return (
    <td style={width ? { width } : {}} data-test-id="shared-editablelist-tablecellfoot">
      {_select
        ? _select.map(
            (
              { isFiltered, options, value, size = 'auto', onChange }: EditableListSelect,
              i: number,
            ) => {
              const filteredOptions = filterOptions({
                isFiltered,
                columnIndex: index,
                list,
                options,
              });
              const maxIndex = (_select || []).length - 1;
              const isPrimary = maxIndex === i;
              const isSecondary = maxIndex > 0 && i < maxIndex;

              return (
                <EnhancedSelect
                  isDisabled={
                    typeof filteredOptions === 'undefined' || filteredOptions.length === 0
                  }
                  isWindowed={isWindowedSelect}
                  key={utility.generateKey(index, `${i}-select`)}
                  options={filteredOptions}
                  size={size}
                  value={isPrimary ? select[index] : value}
                  onChange={(selected: SelectOption) => {
                    if (isPrimary) {
                      handleChange.select(index, selected);
                    }
                    if (isSecondary) {
                      onChange?.(selected?.value);
                      setSelect?.(reset.select);
                    }
                  }}
                />
              );
            },
          )
        : inputComponent}
    </td>
  );
};

export default Foot;
