import { FunctionComponent } from 'react';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { IconButtonProps as Props } from './definition';
import StyledIconButton from './style';

const CONSTANTS = {
  MODE: {
    DARK: {
      COLOR: {
        BACKGROUND: 'core.color.white',
        ICON: 'core.color.white',
      },
    },
    LIGHT: {
      COLOR: {
        BACKGROUND: 'brio.text.secondary',
        ICON: 'brio.text.secondary',
      },
    },
  },
};

const IconButton: FunctionComponent<Props> = ({
  onClick,
  icon,
  isDropdownOpen,
  isDarkMode,
  tooltip,
}: Props): JSX.Element => (
  <StyledIconButton
    isdropdownopen={isDropdownOpen.toString()}
    backgroundcolor={
      isDarkMode ? CONSTANTS.MODE.DARK.COLOR.BACKGROUND : CONSTANTS.MODE.LIGHT.COLOR.BACKGROUND
    }
  >
    {/* Box element to apply hover state */}
    <Box />
    <Tooltip title={tooltip}>
      <Icon
        name={icon as string}
        onClick={onClick}
        themeColor={isDarkMode ? CONSTANTS.MODE.DARK.COLOR.ICON : CONSTANTS.MODE.LIGHT.COLOR.ICON}
      />
    </Tooltip>
  </StyledIconButton>
);

export default IconButton;
