import { ComponentProps } from 'shared/definition';

export enum DashboardItemType {
  AGENTS = 'agents',
  QUEUES = 'queues',
}

type Props = ComponentProps;

export interface NoItemsTextProps extends Props {
  /** Display type */
  type: DashboardItemType;
  /** Display text */
  value: string;
}

export interface StyledNoItemsTextProps extends Props {}
