import { JOI, REGEX, SEAT_TYPE, VALIDATION } from 'constant';
import i18next from 'i18next';
import Joi from 'joi';
import { schema as conferenceSchema } from 'models/Conference';
import { schema as deviceSchema } from 'models/Device';
import { schema as faxboxSchema } from 'models/Faxbox';
import { schema as voicemailSchema } from 'models/Voicemail';
import { messages } from 'shared/utility/validation';

export const schema = () =>
  Joi.object({
    seat: Joi.object({
      id: Joi.string(),
      seat_type: Joi.string().required(),
      username: Joi.when('seat_type', {
        is: SEAT_TYPE.admin.id,
        then: Joi.string(),
        otherwise: Joi.when('seat_type', {
          is: SEAT_TYPE.MS_TEAMS.id,
          then: Joi.string().regex(REGEX.STRING.USERNAME),
          otherwise: Joi.string().regex(REGEX.STRING.NUMERIC),
        }),
      }),
      first_name: Joi.string().required(),
      last_name: Joi.string().required(),
      email: Joi.string().email(JOI.EMAIL).required(),
      password: Joi.when('seat_type', {
        not: SEAT_TYPE.admin.id,
        then: Joi.when('_temp_is_complex_password', {
          is: Joi.boolean().valid(true),
          then: Joi.string()
            .regex(VALIDATION.SEAT.PASSWORD.REGEX)
            .min(VALIDATION.SEAT.PASSWORD.MIN), // unsync'd (with voicemail) passwords are alpha-numeric, excluding special characters
          otherwise: Joi.string()
            .regex(REGEX.STRING.NUMERIC)
            .min(VALIDATION.VOICEMAIL.PIN.MIN)
            .max(VALIDATION.VOICEMAIL.PIN.MAX) // sync'd (with voicemail) pins are strictly numeric
            .messages(
              messages({
                'string.pattern.base': i18next.t('common:validation.general.number_only'),
              }),
            ),
        }),
        otherwise: Joi.string(), // admin passwords are alpha-numeric, including special characters
      }).when('id', {
        is: Joi.exist(),
        then: Joi.optional().allow(''),
        otherwise: Joi.required(), // if an id does not exist, this is a new user and must have a password
      }),
      confirm_password: Joi.when('password', {
        is: Joi.exist(),
        then: Joi.when('seat_type', {
          not: SEAT_TYPE.admin.id,
          then: Joi.when('_temp_is_complex_password', {
            is: Joi.boolean().valid(true),
            then: Joi.string()
              .required()
              .valid(Joi.ref('password'))
              .regex(VALIDATION.SEAT.PASSWORD.REGEX)
              .min(VALIDATION.SEAT.PASSWORD.MIN),
            otherwise: Joi.string()
              .required()
              .valid(Joi.ref('password'))
              .regex(REGEX.STRING.NUMERIC)
              .messages(
                messages({
                  'any.only': i18next.t('common:validation.general.password_match'),
                }),
              ),
          }),
          otherwise: Joi.string()
            .required()
            .valid(Joi.ref('password'))
            .messages(
              messages({
                'any.only': i18next.t('common:validation.general.password_match'),
              }),
            ),
        }),
      }),
      call_forward: Joi.object({
        enabled: Joi.boolean(),
        number: Joi.when('enabled', {
          is: true,
          then: Joi.string().regex(REGEX.PHONE_NUMBER).required(),
          otherwise: Joi.when('failover', {
            is: true,
            then: Joi.string().regex(REGEX.PHONE_NUMBER).required(),
            otherwise: Joi.string().regex(REGEX.PHONE_NUMBER).optional().allow(''),
          }),
        }).messages(
          messages({
            'string.pattern.base': i18next.t('common:validation.general.phone_number'),
          }),
        ),
      }).unknown(true),
      acdc_min_call_duration: Joi.number()
        .min(0)
        .max(3600)
        .required()
        .messages(
          messages({
            'number.base': i18next.t('common:validation.general.required'),
          }),
        ),
    })
      .unknown(true)
      .messages(
        messages({
          'any.only': i18next.t('common:validation.general.password_match'),
        }),
      ),
    device: Joi.object().pattern(Joi.string(), Joi.object().concat(deviceSchema())),
    voicemail: Joi.object().pattern(Joi.string(), Joi.object().concat(voicemailSchema())),
    faxbox: Joi.object().pattern(Joi.string(), Joi.object().concat(faxboxSchema())),
    conference: Joi.object().pattern(Joi.string(), Joi.object().concat(conferenceSchema())),
  })
    .unknown(true)
    .messages(messages());
