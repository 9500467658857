import { joiResolver } from '@hookform/resolvers/joi';
import { KOSHER_APPS } from 'apps/shared/constant';
import { KosherApp } from 'apps/shared/definition';
import withNavigation from 'apps/shared/hocs/withNavigation';
import { useExitConfirmationDialog } from 'apps/shared/hooks/useExitConfirmationDialog';
import { ADD_KEY, DESKTOP_APP_DEFAULT_ZONE } from 'constant';
import produce from 'immer';
import Joi from 'joi';
import merge from 'lodash/merge';
import {
  useCreateAccountByIdMutation,
  useFetchAccountByIdQuery,
  useFetchAccountDesktopAppZoneQuery,
  useFetchAccountHeroAppsQuery,
  useUpdateAccountByIdMutation,
  useUpdateAccountDesktopAppZoneByIdMutation,
  useUpdateAccountHeroAppsByIdMutation,
} from 'models/Account';
import { selectAccountId } from 'models/Account/slice';
import { useFetchAppsQuery, useUpdateAppsBlocklistMutation } from 'models/AppsStore';
import { useCreateByAccountIdMutation } from 'models/Callflow';
import { CALLFLOW_ACTION } from 'models/Callflow/constants';
import { useUpdateVoicemailConfigsMutation } from 'models/Configs';
import { useFetchNotificationByIdQuery, useUpdateNotificationMutation } from 'models/Notification';
import { FunctionComponent, useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAccount from 'shared/hooks/useAccount';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { messages } from 'shared/utility/validation';
import EditForm from '../../../components/EditForm';
import { schema } from './components/section';
import { FormInput } from './components/section/definition';
import { I18NEXT_OPTIONS } from './components/section/NotificationsSection';
import { TEMP_PROPERTY, TEMP_PROPERTY_TRANSFORMERS } from './constant';
import { defaultValues } from './default';
import { EditProps as Props, HeroApp } from './definition';
import Form from './Form';
import { AccountField, useAccountInfo } from './hooks/useAccountInfo';
import { useHandleSaveError } from './hooks/useHandleSaveError';
import {
  aggregateData,
  deleteTemporaryProperties,
  onAccountSubmitSuccess,
  overrideAppMeta,
  transformAccountData,
  transformAccountDataToFormState,
  transformNotificationDataToFormState,
  unlinkPinPass,
  updateSection,
} from './utility';

/**
 * These additional (and temporary) account data object properties
 * are used to facilitate value gathering/validation, etc.
 */
export { TEMP_PROPERTY, TEMP_PROPERTY_TRANSFORMERS };

const Edit = ({ id: accountId = ADD_KEY, handleSaveSuccess }: Props) => {
  const { t, i18n } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { handleSaveError } = useHandleSaveError();
  const { isAccountFieldUnique } = useAccountInfo(accountId);
  const { isSuperDuper } = useAccount();
  const parentId = useSelector<string, string>(selectAccountId);

  const [createAccount] = useCreateAccountByIdMutation();
  const [createCallflow] = useCreateByAccountIdMutation();
  const [updateAccount] = useUpdateAccountByIdMutation();
  const [updateAccountHeroApps] = useUpdateAccountHeroAppsByIdMutation();
  const [updateAppsStoreBlocklist] = useUpdateAppsBlocklistMutation();
  const [updateNotification] = useUpdateNotificationMutation();
  const [updateAccountDesktopAppZone] = useUpdateAccountDesktopAppZoneByIdMutation();
  const [updateVoicemailConfigs] = useUpdateVoicemailConfigsMutation();

  const isAdd = accountId === ADD_KEY;
  const data: Record<string, any> = {};

  aggregateData({
    accountId,
    parentId,
    data,
    hook: {
      useFetchAccountByIdQuery,
      useFetchAccountDesktopAppZoneQuery,
      useFetchAccountHeroAppsQuery,
      useFetchAppsQuery,
      useFetchNotificationByIdQuery,
    },
  });

  const formMethods = useForm<FormInput>({
    defaultValues: merge(
      {},
      produce(defaultValues, (draft: any) => {
        draft[TEMP_PROPERTY.NOTIFICATIONS].deregister.subject = t(
          'accounts_manager:containers.accounts.section.notifications.template.deregister.subject',
          I18NEXT_OPTIONS,
        );
        draft[TEMP_PROPERTY.NOTIFICATIONS].new_seat_created.subject = t(
          'accounts_manager:containers.accounts.section.notifications.template.new_seat_created.subject',
          I18NEXT_OPTIONS,
        );
      }),
      isSuperDuper ? { account_type: '' } : {},
    ),
    reValidateMode: 'onSubmit',
    resolver: joiResolver(
      Joi.object({
        ...schema.basics({ isSuperDuper }),
        ...schema.callerId(),
        ...schema.contacts(),
        ...schema.notifications(),
      })
        .unknown(true)
        .messages(messages()),
    ),
  });

  const {
    formState: { dirtyFields },
    getValues,
    handleSubmit,
    reset,
    setError,
    trigger,
  } = formMethods;
  const isPageDirty = checkFormEntities(dirtyFields);

  useExitConfirmationDialog({ isDirty: isPageDirty });

  useEffect(() => {
    if (isAdd) {
      if (
        data.notification &&
        (data.notification.deregister || data.notification.new_seat_created)
      ) {
        const transformedData = transformNotificationDataToFormState(data.notification);

        reset(
          merge({}, getValues(), {
            [TEMP_PROPERTY.NOTIFICATIONS]: transformedData,
          }),
        );
      }
    } else if (accountId) {
      const transformedAccountData = transformAccountDataToFormState(data.account);
      const transformedNotificationData = transformNotificationDataToFormState(data.notification);

      reset(
        merge({}, getValues(), transformedAccountData, {
          [TEMP_PROPERTY.NOTIFICATIONS]: transformedNotificationData,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAdd,
    accountId,
    data.account,
    data.notification.deregister,
    data.notification.fax_inbound_error_to_email,
    data.notification.voicemail_to_email,
    data.notification.new_seat_created,
    getValues,
    reset,
  ]);

  useEffect(() => {
    if (data.accountHeroApps) {
      const heroAppsIsEnabled = data.accountHeroApps?.reduce(
        (acc: Record<string, boolean>, app: HeroApp) => {
          acc[app.id] = !isAdd ? app.available : false;
          return acc;
        },
        {},
      );

      const heroApps = data.accountHeroApps.map((app: HeroApp) => ({
        ...app,
        available: !isAdd ? app.available : false,
      }));

      reset(
        merge(
          {},
          getValues(),
          { [TEMP_PROPERTY.HERO_APPS]: heroApps },
          { [TEMP_PROPERTY.IS_IN_HERO_APPS]: heroAppsIsEnabled },
        ),
      );
    }
  }, [isAdd, data.accountHeroApps, getValues, reset]);

  useEffect(() => {
    reset(
      merge({}, getValues(), {
        [TEMP_PROPERTY.DESKTOP_APP_ZONE]: data.heroConfig?.default_zone ?? DESKTOP_APP_DEFAULT_ZONE,
      }),
    );
  }, [data.heroConfig, getValues, reset]);

  useEffect(() => {
    if (accountId) {
      // filter apps to display
      const appsStore = data.appsStoreCurrentAccount?.filter(({ name }: any) =>
        KOSHER_APPS.some((app: KosherApp) => name === app.name && app.isEnabled),
      );

      const overrideAppstore = overrideAppMeta(i18n, appsStore);

      // set flag for each app currently in the account "apps_store" object
      const appsStoreIsEnabled = data.appsStoreCurrentAccount?.reduce((acc: any, app: any) => {
        acc[app.id] = data.appsStoreAccount?.some((_app: any) => _app.id === app.id);
        return acc;
      }, {});

      reset(
        merge(
          {},
          getValues(),
          { [TEMP_PROPERTY.APPS_STORE]: overrideAppstore },
          { [TEMP_PROPERTY.IS_IN_APPS_STORE]: appsStoreIsEnabled },
        ),
      );
    }
  }, [
    accountId,
    data.appsStoreAccount,
    data.appsStoreCurrentAccount,
    i18n.language,
    getValues,
    reset,
  ]);

  const onSubmit: SubmitHandler<FormInput> = async (formData: FormInput) => {
    const { name, realm } = formData;
    let id = accountId;

    const isUnique = {
      [AccountField.Name]: isAccountFieldUnique(AccountField.Name, name),
      [AccountField.Realm]: isAccountFieldUnique(AccountField.Realm, realm),
    };

    if (isUnique.name && isUnique.realm) {
      if (isAdd && (await trigger())) {
        const response: Account = await createAccount({
          id: parentId,
          body: { name },
        });

        if (response.data) {
          id = response.data.data.id;
          await unlinkPinPass(updateVoicemailConfigs, id);
          await createCallflow({
            accountId: id,
            body: CALLFLOW_ACTION.NO_MATCH.DATA,
          });
          await createCallflow({
            accountId: id,
            body: CALLFLOW_ACTION.CONFERENCE_SERVICE.DATA,
          });
        } else {
          handleSaveError({ errorData: response.error.data.data, setError });
        }
      }

      await updateSection.desktopModules({
        id,
        data: formData,
        mutation: updateAccountHeroApps,
      });

      await updateSection.notifications({
        id,
        data: formData,
        dirtyFields,
        mutation: updateNotification,
      });

      await updateSection.desktopAppZone({
        id,
        data: formData,
        dirtyFields,
        heroConfigData: data.heroConfig,
        mutation: updateAccountDesktopAppZone,
      });

      // transform temporary properties
      const transformedAccountData = transformAccountData(formData, isSuperDuper);

      // remove temporary properties
      const accountData = deleteTemporaryProperties(transformedAccountData);

      const response: Account = await updateAccount({ id, body: accountData });

      if (response.data) {
        await updateSection.appExchange({
          id,
          data: formData,
          apps: data.appsStoreCurrentAccount,
          mutation: updateAppsStoreBlocklist,
        });

        reset(
          merge({}, formData, {
            announcement: accountData.announcement,
            custom_notes: accountData.custom_notes,
          }),
        );

        onAccountSubmitSuccess({ handleSaveSuccess, isAdd });

        if (isAdd) {
          showToast.success(
            t('accounts_manager:containers.accounts.toast.success.new_account_created'),
          );

          navigate(`../${id}`);
        }
      } else {
        handleSaveError({ errorData: response.error.data, setError });
      }
    } else {
      handleSaveError({ isUnique, setError });
    }
  };

  const onError: SubmitErrorHandler<FormInput> = (error) => {
    showToast.error();
    // console.error('**Form Error**', JSON.stringify(error));
  };

  return (
    <EditForm
      data-test-id={TEST_ID.ACCOUNTS_MANAGER.EDIT.CONTAINER}
      entityLabel={t('accounts_manager:containers.accounts.label')}
      entityName={
        isAdd
          ? t('accounts_manager:containers.accounts.breadcrumb.new_account')
          : data.account?.name
      }
      formMethods={formMethods}
      isPageDirty={isPageDirty}
      onSave={handleSubmit(onSubmit, onError)}
    >
      <Form id={accountId} />
    </EditForm>
  );
};

export default withNavigation(Edit as FunctionComponent);
