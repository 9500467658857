import { TableCellMaskedValue } from 'apps/shared/components/TableCell';
import i18next from 'i18next';
import { Cell, TABLE_CONSTANTS } from 'shared/components/Table';

export const columns = () => [
  {
    Header: i18next.t('phone_system:containers.seats.conference.table.column.name.header'),
    accessor: 'name',
  },
  {
    Header: i18next.t('phone_system:containers.seats.conference.table.column.pin.header'),
    Cell: ({ value }: Cell<Array<string>>) => <TableCellMaskedValue value={value} />,
    accessor: 'member.pins',
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
  },
];
