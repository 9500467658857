import { useTranslation } from 'react-i18next';
import TEST_ID from 'shared/utility/testing/constants/testId';
import Typography from '../Typography';
import defaultProps from './default';
import { ErrorProps as Props } from './definition';
import StyledError from './style';

export { ErrorType } from './definition';

const Error = (props: Props) => {
  const { message, type } = { ...defaultProps, ...props };
  const { t } = useTranslation();

  return (
    <StyledError type={type} data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-error`}>
      <Typography variant="h1">{message ?? t('common:component.error.message')}</Typography>
    </StyledError>
  );
};

export default Error;
