import { FormContext } from 'apps/shared/components/FormContext';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { LabeledEditableList, LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import LabeledCheckbox from 'shared/components/Labeled/components/LabeledCheckbox';
import QueueMembershipEditableList from './components/QueueMembershipEditableList';
import { FormInput } from './definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'acdc_agent_priority',
  'acdc_min_call_duration',
  'acdc_skills',
  'queues',
  'record_call',
];

const CallCenterOptions: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const agentPriorityOptions = [
    {
      value: -128,
      label: t('common:agent_priority.lowest'),
    },
    {
      value: -64,
      label: t('common:agent_priority.low'),
    },
    {
      value: 0,
      label: t('common:agent_priority.normal'),
    },
    {
      value: 64,
      label: t('common:agent_priority.high'),
    },
    {
      value: 128,
      label: t('common:agent_priority.highest'),
    },
  ];

  return (
    <>
      <h2>{t('phone_system:containers.seats.section.call_center_options.agent')}</h2>
      <Box role="row">
        <Box role="cell">
          {/* Record this agent */}
          <HookFormInputWrapper name="record_call" isCheckbox>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                hasMargin
                label={t(
                  'phone_system:containers.seats.section.call_center_options.record_this_agent',
                )}
                checkboxProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>

          {/* Agent Priority */}
          <HookFormSelectWrapper name="acdc_agent_priority" options={agentPriorityOptions}>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSelect
                label={t(
                  'phone_system:containers.seats.section.call_center_options.agent_priority.label',
                )}
                selectProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormSelectWrapper>

          {/* Queue wait time threshold */}
          <HookFormInputWrapper name="acdc_min_call_duration">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'phone_system:containers.seats.section.call_center_options.queues_wait_time_threshold.label',
                )}
                inputProps={{
                  ...formProps,
                  adornment: {
                    type: 'text',
                    position: 'end',
                    // TODO: Refactor translation in a maintenance ticket
                    value: t('common:seconds_bracketed'),
                  },
                }}
                labelProps={{ required: true }}
                tooltip={t(
                  'phone_system:containers.seats.section.call_center_options.queues_wait_time_threshold.tooltip',
                )}
              />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>

      <h2>{t('phone_system:containers.seats.section.call_center_options.skillset')}</h2>
      <Box role="row">
        <Box role="cell">
          {/* Agent Skills */}
          <FormContext.Consumer>
            {(formContext) => {
              return (
                <Controller
                  name={`${formContext}acdc_skills`}
                  render={({ field: { onChange, value }, fieldState: { isDirty } }: any) => {
                    return (
                      <LabeledEditableList
                        isDirty={isDirty}
                        editableListProps={{
                          data: {
                            columns: [
                              {
                                header: {
                                  text: t(
                                    'phone_system:containers.seats.section.call_center_options.agent_skills.title',
                                  ),
                                },
                              },
                            ],
                            rows: (value || []).map((item: string) => [item]),
                          },
                          onUpdate: (value) => onChange(value.flat(1)),
                        }}
                        labelWidth="none"
                      />
                    );
                  }}
                />
              );
            }}
          </FormContext.Consumer>
        </Box>
      </Box>

      <Box m={6} />

      <h2>
        {t('phone_system:containers.seats.section.call_center_options.queue_membership.text')}
      </h2>
      <Box role="row">
        <Box role="cell">
          {/* Queue membership */}
          <HookFormInputWrapper name="queues">
            {({ value, isDirty, onChange }) => (
              <QueueMembershipEditableList data={value} onChange={onChange} isDirty={isDirty} />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>
    </>
  );
};

export default CallCenterOptions;
