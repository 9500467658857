import isUndefined from 'lodash/isUndefined';
import { Metadata } from './store/definition';

export type QuantityRule = {
  type: 'quantity';
  maxSize: number;
};

export type IconProp = {
  name: string;
  set?: string;
};

export interface CallFlowActionInterface {
  key: CallFlowKey;
  module: CallFlowModuleKey;
  section: CallFlowSection;
  rules: QuantityRule[];
  iconProps: IconProp;
  label: string;
  hide?: boolean;
  tooltip?: string;
  subtitle?: (nodeData: any, metadata?: Metadata) => string;
  hasDialog?: boolean;
}

export abstract class CallFlowAction {
  key: CallFlowKey;
  module: CallFlowModuleKey;
  section: CallFlowSection;
  rules: QuantityRule[];
  iconProps: IconProp;
  label: string;
  tooltip?: string;
  hasDialog: boolean;

  protected constructor(params: CallFlowActionInterface) {
    this.key = params.key;
    this.module = params.module;
    this.section = params.section;
    this.rules = params.rules;
    this.iconProps = params.iconProps;
    this.label = params.label;
    this.tooltip = params?.tooltip;
    this.hasDialog = isUndefined(params.hasDialog) ? true : params.hasDialog;
  }

  static subtitle = (nodeData: any, metadata: Metadata): string => {
    if (metadata?.name) {
      return metadata.name + '';
    }
    return '';
  };
}

export enum CallFlowKey {
  // Basic
  SEAT = 'user[id=*]',
  CALLFLOW = 'callflow[id=*]',
  RING_GROUP = 'ring_group[]',
  MEDIA = 'play[id=*]',
  MENU = 'menu[id=*]',
  VOICEMAIL = 'voicemail[id=*,action=compose]',
  DEVICE = 'device[id=*]',
  DIRECTORY = 'directory[id=*]',
  // Advanced
  PIVOT = 'pivot[]',
  RESPONSE = 'response[]',
  MANUAL_PRESENCE = 'manual_presence[]',
  GROUP_PICKUP = 'group_pickup[]',
  PARK = 'park[action=direct_park]',
  WEBHOOK = 'webhook[]',
  CHECK_VOICEMAIL = 'voicemail[id=*,action=check]',
  MATCH_CALLER_ID = 'check_cid[]',
  APPLICATION_VARIABLE = 'kvs_set[]',
  DISTINCTIVE_RING = 'set_alert_info[]',
  // Time of Day
  TIME_OF_DAY = 'temporal_route[]',
  TIME_OF_DAY_DISABLE = 'temporal_route[action=disable]',
  TIME_OF_DAY_ENABLE = 'temporal_route[action=enable]',
  TIME_OF_DAY_RESET = 'temporal_route[action=reset]',
  // Call Center
  QUEUE = 'acdc_member[id=*]',
  QUEUE_LOGIN = 'acdc_queue[id=*,action=login]',
  QUEUE_LOGOUT = 'acdc_queue[id=*,action=logout]',
  AGENT_AVAILABILITY = 'acdc_agent_availability[id=*]',
  AGENT_LOGIN = 'acdc_agent[action=login]',
  AGENT_LOGOUT = 'acdc_agent[action=logout]',
  AGENT_PAUSE = 'acdc_agent[action=paused]',
  AGENT_RESUME = 'acdc_agent[action=resume]',
  AGENT_REQUIRED_SKILLS = 'acdc_required_skills[]',
  SET_CALL_PRIORITY = 'set_variable[]',
  WAIT_TIME = 'acdc_wait_time[id=*]',
  // Call Forwarding
  CALL_FORWARDING_ENABLE = 'call_forward[action=activate]',
  CALL_FORWARDING_DISABLE = 'call_forward[action=deactivate]',
  CALL_FORWARDING_UPDATE = 'call_forward[action=update]',
  // Caller-ID
  CALLER_ID_PREPEND = 'prepend_cid[action=prepend]',
  CALLER_ID_RESET_PREPEND = 'prepend_cid[action=reset]',
  CALLER_ID_DYNAMIC = 'dynamic_cid[]',
  SET_CALLER_ID = 'set_cid[]',
  // Call Recordings
  CALL_RECORDING_START = 'record_call[action=start]',
  CALL_RECORDING_STOP = 'record_call[action=stop]',
  // Call Settings
  COLLECT_DTMF = 'collect_dtmf[]',
  CHANGE_LANGUAGE = 'language[]',
  PAGE_GROUP = 'page_group[]',
  CALL_NOTIFICATION = 'call_notification[]',
  // Carrier
  DISA = 'disa[]',
  // Conference
  CONFERENCE = 'conference[id=*]',
  CONFERENCE_SERVICE = 'conference[]',
  // Fax
  FAX_BOX = 'faxbox[id=*]',
}

export enum CallFlowModuleKey {
  // Basic
  SEAT = 'user',
  CALLFLOW = 'callflow',
  RING_GROUP = 'ring_group',
  MEDIA = 'play',
  MENU = 'menu',
  VOICEMAIL = 'voicemail',
  DEVICE = 'device',
  DIRECTORY = 'directory',
  // Advanced
  PIVOT = 'pivot',
  RESPONSE = 'response',
  MANUAL_PRESENCE = 'manual_presence',
  GROUP_PICKUP = 'group_pickup',
  PARK = 'park',
  WEBHOOK = 'webhook',
  CHECK_VOICEMAIL = 'voicemail',
  MATCH_CALLER_ID = 'check_cid',
  APPLICATION_VARIABLE = 'kvs_set',
  DISTINCTIVE_RING = 'set_alert_info',
  // Time of Day
  TIME_OF_DAY = 'temporal_route',
  TIME_OF_DAY_DISABLE = 'temporal_route',
  TIME_OF_DAY_ENABLE = 'temporal_route',
  TIME_OF_DAY_RESET = 'temporal_route',
  // Call Center
  QUEUE = 'acdc_member',
  QUEUE_LOGIN = 'acdc_queue',
  QUEUE_LOGOUT = 'acdc_queue',
  AGENT_AVAILABILITY = 'acdc_agent_availability',
  AGENT_LOGIN = 'acdc_agent',
  AGENT_LOGOUT = 'acdc_agent',
  AGENT_PAUSE = 'acdc_agent',
  AGENT_RESUME = 'acdc_agent',
  AGENT_REQUIRED_SKILLS = 'acdc_required_skills',
  SET_CALL_PRIORITY = 'set_variable',
  WAIT_TIME = 'acdc_wait_time',
  // Call Forwarding
  CALL_FORWARDING_ENABLE = 'call_forward',
  CALL_FORWARDING_DISABLE = 'call_forward',
  CALL_FORWARDING_UPDATE = 'call_forward',
  // Caller-ID
  CALLER_ID_DYNAMIC = 'dynamic_cid',
  CALLER_ID_RESET_PREPEND = 'prepend_cid',
  CALLER_ID_PREPEND = 'prepend_cid',
  SET_CALLER_ID = 'set_cid',
  // Call Recordings
  CALL_RECORDING_START = 'record_call',
  CALL_RECORDING_STOP = 'record_call',
  // Call Settings
  COLLECT_DTMF = 'collect_dtmf',
  CHANGE_LANGUAGE = 'language',
  PAGE_GROUP = 'page_group',
  CALL_NOTIFICATION = 'call_notification',
  // Carrier
  DISA = 'disa',
  // Conference
  CONFERENCE = 'conference',
  CONFERENCE_SERVICE = 'conference',
  // Fax
  FAX_BOX = 'faxbox',
}

export enum CallFlowSection {
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
  TIME_OF_DAY = 'TIME_OF_DAY',
  CALL_CENTER = 'CALL_CENTER',
  CALL_FORWARDING = 'CALL_FORWARDING',
  CALLER_ID = 'CALLER_ID',
  CALL_RECORDING = 'CALL_RECORDING',
  CALL_SETTINGS = 'CALL_SETTINGS',
  CARRIER = 'CARRIER',
  CONFERENCE = 'CONFERENCE',
  FAX = 'FAX',
}
