import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import kebabCase from 'lodash/kebabCase';
import { useFetchVoicemailsQuery } from 'models/Voicemail';
import { FunctionComponent, useEffect, useState } from 'react';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import defaultProps from './default';
import { VoicemailSelectProps as Props } from './definition';
import StyledVoicemailSelect from './style';

const VoicemailSelect: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isLabelAbove, name, label, tooltip, labelWidth, control, hasDirtyIcon }: Props = {
    ...defaultProps,
    ...props,
  };
  const [voicemails, setVoicemail] = useState<Array<SelectOption>>([]);
  const { data, isLoading } = useFetchVoicemailsQuery();

  useEffect(() => {
    if (data) {
      setVoicemail(data.map((voicemail) => ({ value: voicemail.id, label: voicemail.name })));
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledVoicemailSelect>
      <HookFormSelectWrapper control={control} name={name} options={voicemails}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            feedback={feedback}
            hasDirtyIcon={hasDirtyIcon}
            isDirty={hasDirtyIcon && isDirty}
            isLabelAbove={isLabelAbove}
            isWindowed
            label={label}
            labelWidth={labelWidth}
            selectProps={{
              ...formProps,
              id: `select-voicemail-${kebabCase(label)}`,
            }}
            tooltip={tooltip}
          />
        )}
      </HookFormSelectWrapper>
    </StyledVoicemailSelect>
  );
};

export default VoicemailSelect;
