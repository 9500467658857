/** The form fields values map to the api server schema */
export enum FormFields {
  EmailClaim = 'emailClaim',
  Id = 'id',
  SignRequest = 'signRequest',
  SsoUrl = 'idpEndpoint',
  UniqueIdClaim = 'uniqueIdClaim',
  UseNameIdForEmail = 'useNameIdForEmail',
  UsePostMethod = 'usePostRequest',
  UsernameClaim = 'usernameClaim',
  X509Certificate = 'verificationKey',
}
