import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { mergeArraysCustomizer } from 'apps/shared/utility';
import { ADD_KEY, SEAT_TYPE } from 'constant';
import { mergeWith } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { ShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { ShowToast } from 'shared/hooks/useToast';
import {
  CreateFunction,
  DeleteFunction,
  DirtySeatData,
  EntityDescriptor,
  KeyObject,
  ResponseBody,
  SeatData,
  UpdateFunction,
} from '../definition';
import { ProcessEntitiesError, PROCESS_ENTITIES_ERRORS } from './definition';

const processEntitiesErrors = (errors: PromiseSettledResult<any>[]): ProcessEntitiesError => ({
  errors,
  name: PROCESS_ENTITIES_ERRORS,
  message: PROCESS_ENTITIES_ERRORS,
});

const checkAndThrowEntityError = (entities: PromiseSettledResult<any>[]) => {
  const rejectedResults = entities.filter((result: any) => result.status === 'rejected');

  if (rejectedResults.length > 0) {
    throw processEntitiesErrors(rejectedResults);
  }
};

/**
 * When adding a Device, Voicemail, Fax, or Conference, the "Add <D,V,F,C>" button
 * (AuxButton in useActionRow) should not display. We parse the URL parameters to
 * determine if the "Add <D,V,F,C>" should be shown.
 */
export const hasAuxButton = (params: any): boolean => {
  let hasAdd = false;
  let hasTab = false;
  Object.keys(params).forEach((currentKey) => {
    const currentValue = get(params, currentKey, '');
    hasAdd = hasAdd || (currentKey === '*' && currentValue.indexOf(`${ADD_KEY}-`) === 0);
    hasTab = hasTab || (currentKey === 'activeTab' && currentValue.length > 0);
  });
  return !(hasAdd && hasTab);
};

const updateEntities = async (dirtyIds: Array<string>, data: any, updateMethod: UpdateFunction) => {
  const toBeUpdated = data.filter((item: any) => !item._toDelete && dirtyIds.includes(item.id));

  return Promise.allSettled(
    toBeUpdated.map(
      (item: any) =>
        new Promise<void>((resolve, reject) => {
          updateMethod({ id: item.id, body: item })
            .unwrap()
            .then(resolve)
            .catch((e) => {
              const error = { ...e, id: item.id } as Error;
              reject(error);
            });
        }),
    ),
  );
};

const deleteEntities = (dirtyIds: Array<string>, data: any, deleteMethod: any) => {
  const promises: Array<Promise<any>> = [];

  const toBeDeleted = data.filter((item: any) => item._toDelete && dirtyIds.includes(item.id));
  toBeDeleted.forEach((item: any) => promises.push(deleteMethod({ id: item.id }).unwrap()));

  return Promise.all(promises);
};

// TODO: Change to immer.js
const createEntities = async (
  seatId: string,
  data: Array<any>,
  createMethod: CreateFunction,
  customOwnerIdKey?: string,
) =>
  Promise.allSettled(
    cloneDeep(data).map(
      (body) =>
        new Promise<void>((resolve, reject) => {
          const currentData = cloneDeep(body);
          if (customOwnerIdKey) {
            currentData[customOwnerIdKey] = seatId;
          } else {
            currentData.owner_id = seatId;
          }

          delete currentData.id;
          createMethod({ body: currentData })
            .unwrap()
            .then(resolve)
            .catch((e) => {
              const error = { ...e, id: body.id } as Error;
              reject(error);
            });
        }),
    ),
  );

const partitionObject = (obj: KeyObject, filterFn: any): Array<Object> => {
  return Object.keys(obj).reduce(
    (result: [KeyObject, KeyObject], key: string) => {
      result[filterFn(obj[key], key) ? 0 : 1][key] = obj[key];
      return result;
    },
    [{}, {}],
  );
};

const separateUpdateFromNew = (dataObject: KeyObject) => {
  const [newDevice, existingDevice] = partitionObject(dataObject, (obj: KeyObject, key: string) =>
    key.startsWith(ADD_KEY),
  );
  return [Object.values(newDevice), Object.values(existingDevice)];
};

export const createEntityFunctions = (
  updateEntity: UpdateFunction,
  createEntity: CreateFunction,
  deleteEntity: DeleteFunction,
) => ({
  updateEntity,
  createEntity,
  deleteEntity,
});

// TODO: Fix TS error from this function
export const entityUpdates = async (
  dirty: SeatData,
  entityData: SeatData,
  entityDescriptor: EntityDescriptor,
  responseBody: SeatData,
  showToast: ShowToast,
  showErrorMessage: ShowErrorMessage,
  updateResponseData: any,
  setError?: UseFormSetError<any>,
) => {
  try {
    const {
      customOwnerIdKey,
      functions: { createEntity, deleteEntity, updateEntity },
      transform,
    } = entityDescriptor;

    if (dirty) {
      const [newDevice, existingDevice] = separateUpdateFromNew(entityData);
      const transformedNew = transform ? newDevice.map(transform) : newDevice;
      const transformedExisting = transform ? existingDevice.map(transform) : existingDevice;
      const createdEntities = await createEntities(
        responseBody.seat.id,
        transformedNew,
        createEntity,
        customOwnerIdKey,
      );

      updateResponseData?.(createdEntities);

      // @ts-ignore
      checkAndThrowEntityError(createdEntities);

      const entityKeys = Object.keys(dirty);
      await deleteEntities(entityKeys, existingDevice, deleteEntity);
      const updatedEntities = await updateEntities(entityKeys, transformedExisting, updateEntity);

      // @ts-ignore
      checkAndThrowEntityError(updatedEntities);
    }
    return true;
  } catch (exception) {
    if ((exception as Error).name === PROCESS_ENTITIES_ERRORS) {
      const { errors } = exception as ProcessEntitiesError;
      errors.forEach(({ reason }: any) => {
        if (reason) {
          const { id, data } = reason;
          showErrorMessage({
            isFromException: true,
            errors: data,
            message: entityDescriptor.error,
            overrideErrorKey: (errorKey: any) => `${entityDescriptor.accessor}.${id}.${errorKey}`,
            setError,
          });
        }
      });
    } else {
      showToast.error(entityDescriptor.error);
    }
    return false;
  }
};

export const performEntityUpdates = async (
  entitiesToUpdate: Array<EntityDescriptor>,
  dirtyFields: DirtySeatData,
  data: SeatData,
  responseBody: ResponseBody,
  showToast: ShowToast,
  showErrorMessage: ShowErrorMessage,
  setError?: UseFormSetError<any>,
) => {
  const allUpdates = entitiesToUpdate.map(async (entityDescriptor: EntityDescriptor) => {
    const { accessor, update } = entityDescriptor;

    const updateResponseData = update
      ? (response: any) => {
          const responseData: any = {};

          response
            .filter(({ status, value }: any) => {
              let isSuccess = status === 'fulfilled';

              switch (accessor) {
                case 'sms': {
                  isSuccess = isSuccess && value?.id;
                  break;
                }
                default: {
                  isSuccess = isSuccess && value?.data?.id;
                  break;
                }
              }

              return isSuccess;
            })
            .forEach(({ value }: any) => {
              switch (accessor) {
                case 'sms': {
                  const smsId = value?.id;
                  responseData[smsId] = cloneDeep(value);
                  responseData[smsId].phone_number = responseData[smsId]?.numbers?.[0];
                  break;
                }
                default: {
                  responseData[value?.data?.id] = cloneDeep(value?.data);
                  break;
                }
              }
            });

          responseBody[accessor] = responseData;
        }
      : undefined;

    return entityUpdates(
      get(dirtyFields, accessor),
      get(data, accessor),
      entityDescriptor,
      responseBody,
      showToast,
      showErrorMessage,
      updateResponseData,
      setError,
    );
  });

  const updateResults: Array<boolean> = await Promise.all(allUpdates);

  let isSuccess = true;
  updateResults.forEach((result) => {
    isSuccess = result && isSuccess;
  });

  return isSuccess;
};

export const isSeatTypeAdmin = (value: string): boolean => value === SEAT_TYPE.admin.id;

export const getFormEntityValue = (
  values: FieldValues,
  entity: string,
  id: string,
  defaultValues: any,
  data: any,
  modifiedValues: { [key: string]: any },
) => {
  let entityValue = values?.[entity]?.[id];

  // Meehhhh
  // Would like a better way to know if the faxbox has
  // already been loaded into the state
  if (!entityValue) {
    entityValue = {};
  }
  // LOUIS-TODO: investigate why we need to check there is no id to reset form state and possible remove the check to apply to not only sms
  // REMARK: or the sms entity check may eventually be removed as the data type is going to be changed that doesn't need a transformation
  if (!entityValue.id || entity === 'sms') {
    entityValue = mergeWith({}, defaultValues, data, modifiedValues, mergeArraysCustomizer);
  }

  return enhancedFormUtility.transformDataToFormData(
    entityValue,
    defaultValues,
    mergeArraysCustomizer,
  );
};

/**
 * @description A utility to take the result of the dispatch action and trigger error or success actions depends on the result
 * @param result The result of the dispatch action
 * @param onError the callback when error is found in the result
 * @param onSuccess the callback when no error is found in the result
 */
export const handleDeleteResult = async ({
  result,
  onError,
  onSuccess,
}: {
  result: any;
  onError: () => void;
  onSuccess: () => void;
}) => {
  if (result?.payload?.error) {
    onError?.();
  } else {
    onSuccess?.();
  }
};
