import styled, { css } from 'styled-components';
import StyledForm from '../StyledForm';

const StyledLoginForm = styled(StyledForm)`
  ${({ theme }) => css`
    a {
      color: ${theme.core.color.red};

      &:hover {
        border-bottom-color: ${theme.core.color.red};
      }
    }

    input + div svg {
      cursor: pointer;
    }
  `}
`;

export default StyledLoginForm;
