import { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { ConfigsMutations } from '../../../models/Configs/types';

const endpointsSkippingUiMetadata: string[] = [ConfigsMutations.UpdateVoicemailConfigs];

export const injectUiMetadataToArgs = (args: string | FetchArgs, api: BaseQueryApi) => {
  if (
    !endpointsSkippingUiMetadata.includes(api.endpoint) &&
    Object.prototype.hasOwnProperty.call(args, 'body')
  ) {
    const requestPayloadBody = (args as FetchArgs).body;
    if (Object.prototype.hasOwnProperty.call(requestPayloadBody, 'data')) {
      requestPayloadBody.data.ui_metadata = {
        ui: 'nemo',
      };
    }
  }
};
