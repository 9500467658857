import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { useFetchInServiceAndPortInPhoneNumbersQuery } from 'models/PhoneNumber';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledSelect } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { SelectOption } from 'shared/components/Select';
import { defaultCallerIdValue } from '../../default';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['caller_id', 'caller_name', 'fax_header', 'fax_identity'];

const CallerIdSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const { data: inServiceData } = useFetchInServiceAndPortInPhoneNumbersQuery();
  const callerIdOptions: SelectOption[] =
    (inServiceData &&
      Object.keys(inServiceData?.numbers).map((key: string) => ({
        label: key,
        value: key,
      }))) ||
    [];
  callerIdOptions.unshift(defaultCallerIdValue(t));

  return (
    <div role="row">
      <div role="cell">
        {/* Caller ID */}
        <HookFormSelectWrapper name="caller_id" options={callerIdOptions}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledSelect
              isDirty={isDirty}
              feedback={feedback}
              label={t('phone_system:containers.faxes.section.caller_id.caller_id_select.label')}
              tooltip={t(
                'phone_system:containers.faxes.section.caller_id.caller_id_select.tooltip',
              )}
              selectProps={{
                ...formProps,
                defaultValue: defaultCallerIdValue(t),
                placeholder: t(
                  'phone_system:containers.faxes.section.caller_id.caller_id_select.placeholder',
                ),
              }}
            />
          )}
        </HookFormSelectWrapper>
        {/* Caller name */}
        <HookFormInputWrapper name="caller_name">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              label={t('phone_system:containers.faxes.section.caller_id.caller_name.label')}
              tooltip={t('phone_system:containers.faxes.section.caller_id.caller_name.tooltip')}
              inputProps={{
                ...formProps,
                placeholder: t(
                  'phone_system:containers.faxes.section.caller_id.caller_name.placeholder',
                ),
                id: 'input-caller-id-caller-name',
              }}
              feedback={feedback}
              isDirty={isDirty}
            />
          )}
        </HookFormInputWrapper>
        {/* Fax header */}
        <HookFormInputWrapper name="fax_header">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              label={t('phone_system:containers.faxes.section.caller_id.fax_header.label')}
              tooltip={t('phone_system:containers.faxes.section.caller_id.fax_header.tooltip')}
              inputProps={{
                ...formProps,
                placeholder: t(
                  'phone_system:containers.faxes.section.caller_id.fax_header.placeholder',
                ),
                id: 'input-caller-id-fax-header',
              }}
              isDirty={isDirty}
              feedback={feedback}
            />
          )}
        </HookFormInputWrapper>
        {/* Fax Identity */}
        <HookFormInputWrapper name="fax_identity">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              label={t('phone_system:containers.faxes.section.caller_id.fax_identity.label')}
              tooltip={t('phone_system:containers.faxes.section.caller_id.fax_identity.tooltip')}
              inputProps={{
                ...formProps,
                placeholder: t(
                  'phone_system:containers.faxes.section.caller_id.fax_identity.placeholder',
                ),
                id: 'input-caller-id-fax-identity',
              }}
              isDirty={isDirty}
              feedback={feedback}
            />
          )}
        </HookFormInputWrapper>
      </div>
    </div>
  );
};

export default CallerIdSection;
