import { schema } from 'normalizr';
import { TreeNode } from './TreeNode';

export const flowEntitySchema = new schema.Entity(
  'nodes',
  {},
  {
    /*
     * For the nodes we need to generate an id to keep them organized in the state, if possible we use the id of the
     * data used within the node. This is not always possible, currently in this case we are using the module. This
     * may need to change in the future.
     */
    idAttribute: (entity, parent, key) => entity.data.nodeId,
    processStrategy: (entity, parent, key) => ({
      ...new TreeNode(entity.module, entity.data, key, entity.children),
    }),
  },
);
