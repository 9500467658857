import { getLabel } from 'apps/PhoneSystem/containers/Groups/shared/utility';
import { EndpointType } from 'apps/PhoneSystem/definition';
import concat from 'lodash/concat';
import { FunctionComponent } from 'react';
import Loading from 'shared/components/Loading';
import useEndpoints from '../../../../../shared/hooks/useEndpoints';
import { EndpointsDialogContentProps as Props } from './definition';

const EndpointsDialogContent: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data } = props;
  const { endpoints, isLoading } = useEndpoints();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div data-test-id="phone-system-groups-edit-endpoints-dialog-content">
      {Object.keys(data)
        .map((key: string) => {
          const item = concat(endpoints.devices.data, endpoints.users.data).find(
            (item: any) => item?.id === key,
          );
          return getLabel({
            type: item?.device_type ? EndpointType.Device : EndpointType.User,
            item,
          });
        })
        .join('; ')}
    </div>
  );
};

export default EndpointsDialogContent;
