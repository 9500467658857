import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import kebabCase from 'lodash/kebabCase';
import { useFetchSeatsQuery } from 'models/Seat';
import { useMemo } from 'react';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import defaultProps from './default';
import { SeatSelectProps as Props } from './definition';
import StyledSeatSelect from './style';
import { createSeatsOptions } from './utility';

const SeatSelect = (props: Props) => {
  const { name, label, control, isLabelAbove, tooltip } = { ...defaultProps, ...props };
  const { data, isLoading } = useFetchSeatsQuery();

  const seatOptions = useMemo(() => createSeatsOptions(data), [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledSeatSelect>
      <HookFormSelectWrapper control={control} name={name} options={seatOptions}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isWindowed
            isDirty={isDirty}
            isLabelAbove={isLabelAbove}
            feedback={feedback}
            label={label}
            selectProps={{
              ...formProps,
              id: `select-seat-${kebabCase(label)}`,
            }}
            tooltip={tooltip}
          />
        )}
      </HookFormSelectWrapper>
    </StyledSeatSelect>
  );
};

export default SeatSelect;
