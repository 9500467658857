import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { UploadFileType } from 'shared/components/FileUpload/definition';
import LabeledFileUpload from 'shared/components/Labeled/components/LabeledFileUpload';
import { useToast } from 'shared/hooks/useToast';
import { INFO_LABEL_WIDTH } from '../../style';
import { convertStringToX509File } from '../SetupIdpDialog/utility';
import defaultValues from './default';
import { FileDownloadRowProps as Props } from './definition';

const CertificateDownloadRow = (props: Props) => {
  const { certificate, className, fileName, label, signRequest } = { ...defaultValues, ...props };
  const { t } = useTranslation();
  const { showToast } = useToast();

  const file = useMemo(
    () => (signRequest ? convertStringToX509File(certificate, `${fileName}.pem`) : null),
    [certificate, fileName, signRequest],
  );

  const onDownloadStart = () => {
    showToast.success(
      t(
        'phone_system:containers.account.submodule.settings.section.sso.certificate_download.success_message',
      ),
    );
  };

  if (!file) {
    return null;
  }

  return (
    <Box role="row" className={`one-column ${className}`}>
      <Box role="cell">
        <LabeledFileUpload
          label={label}
          labelWidth={INFO_LABEL_WIDTH}
          inputProps={{
            hasMargin: false,
            isDownloadOnly: true,
            fileType: UploadFileType.FILE,
            name: fileName,
            value: file,
            onChange: () => {},
            onDownloadStart,
          }}
        />
      </Box>
    </Box>
  );
};

export default CertificateDownloadRow;
