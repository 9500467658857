import { SeatFormProps as Props } from 'apps/PhoneSystem/definition';
import { FormContext } from 'apps/shared/components/FormContext';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import useTabs from 'apps/shared/hooks/useTabs';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { areTabFieldsDirty } from '../utility'; // TODO: Review implementation
import { fields, Section } from './components';

const Form = ({ hasFormActionRow }: Props) => {
  const { t } = useTranslation();
  const contextName = useContext(FormContext);
  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const items = [
    {
      hash: 'options',
      label: t('phone_system:containers.sms.section.options.label'),
      component: <Section.Options />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.options, contextName),
      isError: areTabFieldsDirty(errors, fields.options, contextName),
    },
  ];

  const { Tabs, TabPanels } = useTabs({
    items,
    options: { hasFormActionRow },
  });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Form;
