import { Numberify } from 'apps/shared/components/EnhancedFormCore/definition';
import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { SettingsSectionData } from 'models/AdvancedProvisioner/types';
import { FieldValueType, TabTemplate, Template } from '../definition';

/**
 * @description Check if the field is empty
 * @param field The field value
 * @returns True if the field is empty. False otherwise.
 */
export const isFieldEmpty = (field?: FieldValueType | null) =>
  field === '' || field === undefined || field === null;

const generateDefaultValuesForSection = (tabValue: TabTemplate) => {
  const defaultValues: Numberify<SettingsSectionData> = {};
  const { data: sectionData } = tabValue;
  Object.entries(sectionData).forEach(([sectionKey, sectionValue]) => {
    defaultValues[sectionKey] = {};
    const { data: fieldData } = sectionValue;
    Object.entries(fieldData).forEach(([fieldKey]) => {
      defaultValues[sectionKey][fieldKey] = '';
    });
  });
  return defaultValues;
};

/** Recursively remove empty and unwanted fields (mutating) */
const cleanUpEmptyFields = (field: any, parentField?: any, key?: string): any => {
  // If the field is an object, then we need to recursively clean up its children
  if (field && typeof field === 'object') {
    Object.keys(field).forEach((nextKey) => {
      cleanUpEmptyFields(field[nextKey], field, nextKey);
    });

    // Delete current object if there is no children
    if (key && Object.keys(field).length < 1) {
      delete parentField[key];
    }
  } else if (
    key &&
    (key === 'local_port' || isFieldEmpty(field)) // ADVPRO-TODO: Check if local_port should be removed here or when receiving the data
  ) {
    // Reached the leaf node. Removing field if it is empty.
    delete parentField[key];
  }
};

/** The following utilities are designed for advanced provisioner specifically */
export const dynamicFormUtility = {
  generateDefaultValuesFromTemplate: <Data extends Record<string, any>>(template?: Template) => {
    const defaultValues: Data = {} as Data;

    if (!template) {
      return defaultValues;
    }

    Object.entries(template).forEach(([tabKey, tabValue]) => {
      const { iterate } = tabValue;

      // If iterate is 0, then this is a tab that won't be displayed
      if (iterate === 0) {
        return;
      }

      if (iterate) {
        // Only if the iterate is greater than 0, then the tab should be displayed
        defaultValues[tabKey as keyof typeof defaultValues] = new Array(iterate)
          .fill({})
          .map(() => generateDefaultValuesForSection(tabValue)) as Data[string];
      } else {
        defaultValues[tabKey as keyof typeof defaultValues] = {} as Data[string];

        const item = generateDefaultValuesForSection(tabValue);
        defaultValues[tabKey as keyof typeof defaultValues] = item as Data[string];
      }
    });
    return defaultValues;
  },
  transformFormDataToRequestPayload: <Data extends Record<string, any>>(formData: Data) => {
    const payload = produce(formData, (draft) => {
      // Convert horizontal tabs array data into object
      Object.keys(draft).forEach((key) => {
        draft[key as keyof typeof draft] = { ...draft[key] };
      });
    });

    // Cleanup empty field. Using cloneDeep instead of immer since immer doesn't support removing object by key.
    const clonedPayload = cloneDeep(payload);
    cleanUpEmptyFields(clonedPayload);
    return clonedPayload;
  },
};
