import { themeColor } from 'theme/local';
import theme from '../../core';

const CONSTANTS = {
  FONT_WEIGHT: 600,
};

const getStyle = ({ color, fontWeight = 400 }: { color: string; fontWeight?: number }) => ({
  color: themeColor.brio[color].main,
  fontWeight,
});

export default {
  minWidth: 0,
  color: theme.palette.secondary.main,
  fontSize: `${theme.typography.fontSize}px !important`,
  fontWeight: CONSTANTS.FONT_WEIGHT,
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
  '&.Mui-disabled': {
    backgroundColor: 'transparent !important',
  },
  '&.MuiButton-textSecondary': getStyle({ color: 'secondary' }),
  '&.MuiButton-textError': getStyle({ color: 'error', fontWeight: CONSTANTS.FONT_WEIGHT }),
  '&.MuiButton-textInfo': getStyle({ color: 'info', fontWeight: CONSTANTS.FONT_WEIGHT }),
  '&.MuiButton-textSuccess': getStyle({ color: 'success', fontWeight: CONSTANTS.FONT_WEIGHT }),
  '&.MuiButton-textWarning': getStyle({ color: 'warning', fontWeight: CONSTANTS.FONT_WEIGHT }),
  '.MuiButton-startIcon': {
    margin: theme.spacing(0, 1.33, 0, 0),
  },
};
