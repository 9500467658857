import { KosherApp } from './definition';

export const KOSHER_APPS: KosherApp[] = [
  { name: 'accounts', isEnabled: true },
  { name: 'call-recording', isEnabled: false },
  { name: 'callcenter-ooma', isEnabled: true },
  { name: 'callflows', isEnabled: true },
  { name: 'cdrs', isEnabled: true },
  { name: 'csv-onboarding', isEnabled: true },
  { name: 'keyman', isEnabled: true },
  { name: 'msteams', isEnabled: true },
  { name: 'numbers', isEnabled: true },
  { name: 'pbxs', isEnabled: true },
  { name: 'provisioner', isEnabled: true },
  { name: 'qstats', isEnabled: true },
  { name: 'vqmon', isEnabled: true },
  { name: 'xarios', isEnabled: true },
];
