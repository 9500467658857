import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { ChangeEvent, Fragment } from 'react';
import FilterSelect, { FilterValues } from 'shared/components/FilterSelect';
import { LabeledCheckbox } from 'shared/components/Labeled';
import Typography from 'shared/components/Typography';
import {
  FilterDialogContentProps as Props,
  FilterInputItemConfig,
  FilterInputType,
} from './definition';

const renderInput = (
  filterValues: FilterValues,
  { inputType, filterType, heading, selectInputConfig }: FilterInputItemConfig,
  setFilterValues: any,
  filterDefaults: Record<string, Array<string>>,
) => {
  const map: { [key in FilterInputType]: any } = {
    [FilterInputType.Select]: () => {
      return (
        <Fragment key={filterType}>
          <Typography
            classes={{
              root: isEmpty(filterValues.selected[filterType]) ? 'MuiTypography-error' : '',
            }}
            variant="h2"
          >
            {heading}
          </Typography>
          <FilterSelect
            filter={{ defaults: filterDefaults, type: filterType, values: filterValues }}
            isMultiSelect={selectInputConfig?.isMulti}
            options={selectInputConfig?.options ?? []}
            setFilterValues={setFilterValues}
          />
        </Fragment>
      );
    },
    [FilterInputType.Checkbox]: () => (
      <Fragment key={filterType}>
        <LabeledCheckbox
          checkboxProps={{
            checked: !isEqual(filterValues.selected[filterType], filterDefaults[filterType]),
            onChange: ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
              setFilterValues((filterValues: FilterValues) =>
                produce(filterValues, (draft: any) => {
                  if (checked) {
                    draft.selected[filterType] = [filterType]; // Set to the filter type
                  } else {
                    draft.selected[filterType] = filterDefaults[filterType]; // Reset to default
                  }
                }),
              );
            },
          }}
          hasDirtyIcon={false}
          indentWidth="none"
          label={heading}
        />
      </Fragment>
    ),
  };
  return map[inputType];
};

const FilterDialogContent = ({ inputConfig, setFilterValues, filterValues }: Props) => (
  <>
    {Object.values(inputConfig.items).map((item) =>
      renderInput(filterValues, item, setFilterValues, inputConfig.filterDefaults)(),
    )}
  </>
);

export default FilterDialogContent;
