import produce from 'immer';
import { mergeWith } from 'lodash';
import get from 'lodash/get';
import { FormFields } from '../components/Form/definition';
import { Numberify } from '../definition';

/**
 * @description A function to recursively traverse an object and provide a transformer callback function to transform the data immutably
 * @param data the data to be recursively traverse
 * @param transformer the callback function that is triggered when a non-object value is found when traversing the data
 * @returns transformed data
 * @remark This function does not guarantee all data has a fallback value (i.e., defaultValues of a field is not provided). If the data is missing and being passed into the form, some of the form functions will not work properly (e.g., dirty state). Please use `enhancedFormUtility.ensureFormData` to ensure data is correct.
 * @example transformedDataToFormData(
 *  { first_name: "Tom", settings: { timeout: 5 } }, // Data from API
 *  { first_name: "", settings: { timeout: "", brand: "ABC" } } // defaultValues
 * ) // returns { first_name: "Tom", settings: { timeout: "5", brand: "ABC" } }
 *
 * // Usually call enhancedFormUtility.ensureFormData() after to ensure data is correct
 */
export const recursiveTransform = (
  data: Record<string, any>,
  transformer: (item: any, key: string) => void,
) => {
  const update = (item: Record<string, any>) => {
    if (item && typeof item === 'object') {
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === 'object') {
          update(item[key]);
        } else {
          transformer(item, key);
        }
      });
    }
  };
  return produce(data, (draft) => update(draft));
};

export const enhancedFormUtility = {
  /**
   * @description Transform data to form data when put into the form. This function will also merge the data with the default values as a fallback when data is missing.
   * @param data data to be put into the form
   * @param defaultValues default values of the data that will serve as a fallback if the data doesn't exist
   * @returns transformed data that could be put into form
   * @remark This function does not guarantee all data has a fallback value (i.e., defaultValues of a field is not provided). If the data is missing and being passed into the form, some of the form functions will not work properly (e.g., dirty state). Please use `enhancedFormUtility.ensureFormData` to ensure data is correct.
   * @example transformedDataToFormData(
   *  { first_name: "Tom", settings: { timeout: 5 } }, // Data from API
   *  { first_name: "", settings: { timeout: "", brand: "ABC" } } // defaultValues
   * ) // returns { first_name: "Tom", settings: { timeout: "5", brand: "ABC" } }
   *
   * // Usually call enhancedFormUtility.ensureFormData() after to ensure data is correct
   */
  transformDataToFormData: <Data>(
    data: Data | undefined,
    defaultValues: Numberify<Data>,
    mergeCustomizer?: (...args: any[]) => void,
  ): Numberify<Data> | undefined => {
    if (!data) {
      return data as undefined;
    }

    const transformedData = recursiveTransform(data, (item, key) => {
      if (typeof item[key] === 'number') {
        // Rule 1: Convert all number fields to string
        item[key] = item[key].toString();
      } else if (item[key] === undefined || item[key] === null) {
        // Rule 2: Delete all null and undefined field (since it will stop falling back to default value when using react-hook-form reset)
        delete item[key];
      }
    });

    return mergeWith({}, defaultValues, transformedData, mergeCustomizer);
  },
  showWarningForMissingEntities: (formEntities: any, formFields: FormFields) => {
    if (formEntities && formFields) {
      Object.keys(formFields).forEach((key) => {
        formFields[key].forEach((fieldPath) => {
          if (get(formEntities, fieldPath) === undefined || get(formEntities, fieldPath) === null) {
            // Developer message only. i18n not required.
            // eslint-disable-next-line no-console
            console.warn(
              `Missing value for field "${fieldPath}" or the value is undefined or null. Please check if the dirty state of the field is working and provide a correct defaultValue if it's not.`,
            );
          }
        });
      });
    }
  },

  /**
   * @description Check if there are falsy values in the form data and console.log. This is to prevent the form from breaking when the data is missing. Usually used after `enhancedFormUtility.transformDataToFormData` (or any data transformation/merging) and before passing data into the form to ensure data format.
   * @param formData Data that is going to be passed into the form
   * @param formFields Form fields defined in the form
   */
  ensureFormData: (formData: any, formFields: FormFields): void => {
    enhancedFormUtility.showWarningForMissingEntities(formData, formFields);
  },

  /**
   * @description Check if there are falsy values in the default value object and console.log. This is to prevent the form from breaking when the data is missing. Usually used after `enhancedFormUtility.transformDataToFormData` (or any data transformation/merging) and before passing data into the form to ensure data format.
   * @param formData Data that is going to be passed into the form
   * @param formFields Form fields defined in the form
   */
  ensureFormDefaultValues: (defaultValues: any, formFields: FormFields): void => {
    enhancedFormUtility.showWarningForMissingEntities(defaultValues, formFields);
  },
};
