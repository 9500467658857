import { useState } from 'react';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import { AppPickerBody, AppPickerHead } from './components';
import StyledAppPicker from './style';

const AppPicker = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useOutsideClick(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  return (
    <StyledAppPicker ref={ref}>
      <AppPickerHead isOpen={isOpen} setIsOpen={setIsOpen} />
      <AppPickerBody isOpen={isOpen} setIsOpen={setIsOpen} />
    </StyledAppPicker>
  );
};

export default AppPicker;
