import { useCreateCallflowMutation } from 'models/Callflow';
import { useCallback } from 'react';
import { getBridgeCallflowData } from '../../utility';

const useCreateBridgeCallflow = (bridgeNumber?: string) => {
  const [createCallflow] = useCreateCallflowMutation();

  const createBridgeCallflow = useCallback(
    async () =>
      createCallflow({
        body: getBridgeCallflowData(bridgeNumber),
      }).unwrap(),
    [bridgeNumber, createCallflow],
  );

  return createBridgeCallflow;
};

export default useCreateBridgeCallflow;
