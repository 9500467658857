// Conference/index.ts
import { REGEX } from 'constant';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { conferenceMutations } from './Mutations';
import { conferenceQueries } from './Queries';

export const schema = () =>
  Joi.object({
    name: Joi.string().min(1).max(128).required(),
    member: Joi.object({
      pins: Joi.array().items(Joi.string().regex(REGEX.MEMBER_PIN)),
      join_muted: Joi.boolean(),
      join_deaf: Joi.boolean(),
    }).unknown(true),
    play_entry_tone: Joi.boolean(),
    play_name: Joi.boolean(),
    play_exit_tone: Joi.boolean(),
    conference_numbers: Joi.array().items(Joi.string().regex(REGEX.MEMBER_PIN)),
  })
    .unknown(true)
    .messages(messages());

export const {
  useFetchConferencesQuery,
  useFetchConferenceByIdQuery,
  useFetchConferencesByOwnerIdQuery,
} = conferenceQueries;

export const {
  useCreateConferenceMutation,
  useUpdateConferenceMutation,
  useDeleteConferenceMutation,
} = conferenceMutations;
