import MuiCardHeader, { CardHeaderProps as Props } from '@mui/material/CardHeader';
import { FunctionComponent } from 'react';

export type { CardHeaderProps } from '@mui/material/CardHeader';

const CardHeader: FunctionComponent<Props> = (props: Props): JSX.Element => (
  <MuiCardHeader {...props} data-test-id="shared-card-component-card-header" />
);

export default CardHeader;
