import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { LOCAL_STORAGE } from 'constant';

export enum TAGS {
  Idp = 'Idp',
}

export const responseHandler = async (response: any) => {
  try {
    const data = await response.json();
    return data;
  } catch (e) {
    return {};
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${window.nemo?.api.iam}/`,
  prepareHeaders: (headers) => {
    headers.set('content-type', 'application/json');
    const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
    if (token) {
      headers.set('X-Auth-Token', token);
    }
    return headers;
  },
});

export const iamApi = createApi({
  reducerPath: 'iam_api',
  baseQuery: retry(baseQuery, { maxRetries: 0 }),
  endpoints: () => ({}),
  tagTypes: Object.values(TAGS),
});
