import { useTranslation } from 'react-i18next';
import { useToast } from 'shared/hooks/useToast';
import { Props } from './definition';

export const useHandleSaveError = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();

  const handleSaveError = ({ isUnique, errorData, setError }: Props['HandleSaveError']) => {
    if (
      !isUnique?.name ||
      !isUnique?.realm ||
      errorData?.name?.unique ||
      errorData?.realm?.unique
    ) {
      if (!isUnique?.name || errorData?.name?.unique) {
        setError('name', {
          type: 'custom',
          message: t(
            'accounts_manager:containers.accounts.section.basics.field.account_name.error',
          ),
        });
      }

      if (!isUnique?.realm || errorData?.realm?.unique) {
        setError('realm', {
          type: 'custom',
          message: t(
            'accounts_manager:containers.accounts.section.basics.field.account_realm.error',
          ),
        });
      }

      showToast.error();
    }
  };

  return { handleSaveError };
};
