import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { LOCAL_STORAGE } from 'constant';
import { dynamicBaseQuery } from './utility/dynamicBaseQuery';

export enum TAGS {
  ACCOUNT = 'Accounts',
  ACCOUNT_DESKTOP_APP_ZONE = 'AccountsDesktopAppZone',
  ACCOUNT_HERO_APPS = 'AccountsHeroApps',
  AGENTS = 'Agents',
  APPSSTORE = 'AppsStore',
  BLOCKLIST = 'Blocklists',
  CALLERINFOMATCH = 'CallerInfoMatch',
  CALLFLOW = 'Callflows',
  CONFERENCE = 'Conferences',
  CONFIGS = 'Configs',
  CONNECTIVITY = 'Connectivity',
  DEVICE = 'Devices',
  DIRECTORY = 'Directories',
  EXTENSION = 'Extensions',
  FAXBOX = 'Faxboxes',
  FEATURECODE = 'FeatureCode',
  GROUP = 'Groups',
  MEDIA = 'Medias',
  MENU = 'Menus',
  NOTIFICATION = 'Notifications',
  PHONENUMBER = 'PhoneNumbers',
  QUEUES = 'Queues',
  SEAT = 'Seats',
  SMS = 'SMS',
  TIMEOFDAY = 'TimeOfDays',
  USER = 'Users',
  VOICEMAIL = 'Voicemails',
}

const baseQuery = dynamicBaseQuery(
  fetchBaseQuery({
    baseUrl: window.nemo?.api.crossbar,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
      if (token) {
        headers.set('X-Auth-Token', token);
      }
      return headers;
    },
  }),
  false,
  true,
);

export const kazooApi = createApi({
  baseQuery: retry(baseQuery, { maxRetries: 0 }),
  endpoints: () => ({}),
  tagTypes: Object.values(TAGS),
});

export const responseHandler = async (response: any) => {
  const data = await response.json();
  return data.data;
};

export const childResponseHandler = async (response: any, id?: string) => {
  const data = await response.json();
  return data.data.map((res: any) => {
    return {
      ...res,
      parentId: id,
    };
  });
};
