import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class PageGroupActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.PAGE_GROUP,
      module: CallFlowModuleKey.PAGE_GROUP,
      section: CallFlowSection.CALL_SETTINGS,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'groups-round',
      },
      label: i18next.t('phone_system:containers.callflows.actions.page_group.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.page_group.tooltip'),
    });
  }

  static subtitle = (nodeData: any) => nodeData?.name;
}

export default PageGroupActionConfig;
