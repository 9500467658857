import { useLazyFetchSubaccountDeviceQuery } from 'models/AdvancedProvisioner';
import { useCallback, useState } from 'react';
import { onSearchSubaccountDevice, shouldSearchSubaccountDevice } from './utility';

export const useOnSearch = () => {
  const [fetchSubaccountDeviceQuery, { isFetching }] = useLazyFetchSubaccountDeviceQuery();
  const [matchedSubaccountId, setMatchedSubaccountId] = useState<string | undefined>();

  const onSearch = useCallback(
    async ({ data, keyword }) => {
      await onSearchSubaccountDevice({
        fetchSubaccountDeviceQuery,
        matchedSubaccountId,
        setMatchedSubaccountId,
        shouldSearchSubaccountDevice: shouldSearchSubaccountDevice({ data, keyword }),
        keyword,
      });
    },
    [fetchSubaccountDeviceQuery, matchedSubaccountId],
  );

  return {
    matchedSubaccountId,
    onSearch,
    isLoading: isFetching,
    setMatchedSubaccountId,
  };
};
