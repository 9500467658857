import { AppMeta } from 'apps/shared/definition';

export const meta: AppMeta = {
  isIframeApp: true,
  i18n: {
    label: 'Microsoft Teams',
  },
  icon: 'ms-teams',
  name: 'msteams',
  slug: 'msteams',
};
