// Group Queries
import { Group } from 'api/group';
import { ByIdWithAccountIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const groupQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchGroups: builder.query<GroupResponse, void>({
      query: () => ({
        url: Group.Fetch(),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.GROUP as const, id })),
              { type: TAGS.GROUP, id: 'LIST' },
            ]
          : [{ type: TAGS.GROUP, id: 'LIST' }],
    }),
    fetchGroupById: builder.query<Group, ByIdWithAccountIdParam>({
      query: ({ id, accountId }) => ({
        url: Group.FetchById({ accountId, id }),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.GROUP, id }];
      },
    }),
  }),
});
