import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { FilterValues } from 'shared/components/FilterSelect';
import FilterDialogContent from './components/FilterDialogContent';
import { FilterInputConfig } from './components/FilterDialogContent/definition';
import { FilterGroup } from './definition';
import { useFilterUtilities } from './hooks/useFilterUtilities';
import { setFiltersToSessionStorage } from './utility';

export { removeFiltersSession } from './utility';
export { FilterGroup };

export const useFilter = <Data extends any[]>({
  data,
  inputConfig,
  setTableData,
}: {
  data: Data | undefined;
  inputConfig: FilterInputConfig<Data>;
  setTableData: (data: Data) => void;
}) => {
  const {
    filterDefaults,
    filterGroup,
    filterData,
    getBreadcrumb,
    getInitialFiltersValue,
    onApplyFilter,
    onFilterOpen,
    onResetSelectedValues,
  } = useFilterUtilities<Data>(inputConfig);
  const [isApplyingFilter, setIsApplyingFilter] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<FilterValues>(getInitialFiltersValue());

  const resetSelectedValues = () => {
    setFilterValues((filterValues: FilterValues) =>
      produce(filterValues, (draft: FilterValues) => {
        onResetSelectedValues(draft);
      }),
    );
  };

  useEffect(() => {
    if (isFilterOpen && isEmpty(filterValues)) {
      setFilterValues((filterValues: FilterValues) =>
        produce(filterValues, (draft: FilterValues) => {
          onFilterOpen(draft);
        }),
      );
    }
  }, [isFilterOpen, filterValues, onFilterOpen]);

  // On first load, if exists, apply the filter from session storage
  useEffect(() => {
    if (data) {
      setIsApplyingFilter(true);
    }
  }, [data]);

  useEffect(() => {
    if (data && isApplyingFilter) {
      let filteredData = cloneDeep(data);

      if (!isEqual(filterDefaults, filterValues.selected)) {
        filteredData = filterData(data, filterValues);
      }

      setTableData(filteredData);

      setFilterValues((prevFilterValues) => {
        // Set the updated filter values in the React state
        const filterValues = produce(prevFilterValues, (draft: FilterValues) => {
          onApplyFilter(draft);
        });

        // Sync filters to session storage
        setFiltersToSessionStorage(filterGroup, filterValues);

        // Return the value to update the React state
        return filterValues;
      });

      setIsApplyingFilter(false);
    }
  }, [
    isApplyingFilter,
    data,
    filterDefaults,
    filterGroup,
    filterValues,
    filterData,
    onApplyFilter,
    setTableData,
  ]);

  return {
    filter: {
      hasFilter: true,
      hasFilterValues: {
        applied: !isEqual(filterDefaults, filterValues.applied),
        selected: !isEqual(filterDefaults, filterValues.selected),
      },
      isApplyDisabled: Object.values(filterValues.selected).every((item) => item.length === 0),
      isDialogOpen: isFilterOpen,
      breadcrumb: getBreadcrumb(filterValues),
      dialogContent: (
        <FilterDialogContent
          filterValues={filterValues}
          inputConfig={inputConfig}
          setFilterValues={setFilterValues}
        />
      ),
      filterValues,
      onApply: () => {
        setIsApplyingFilter(true);
        setIsFilterOpen(false);
      },
      onClose: () => setIsFilterOpen(false),
      onReset: () => resetSelectedValues(),
      setIsDialogOpen: setIsFilterOpen,
    },
  };
};
