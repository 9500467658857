export enum CallflowType {
  Conference = 'conference',
  Device = 'device',
  Faxbox = 'faxbox',
  PageGroup = 'page_group',
  RingGroup = 'ring_group',
  User = 'user',
  Voicemail = 'voicemail',
  Other = 'other',
}
