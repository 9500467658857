import { FunctionComponent } from 'react';
import Divider from 'shared/components/Divider';
import Header from '../Header';
import defaultProps from './default';
import { DrawerUpperProps as Props } from './definition';
import StyledDrawerUpper from './style';

const DrawerUpper: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { children, hasPadding, isOpen, width, height, headerProps }: Props = {
    ...defaultProps,
    ...props,
  };

  return (
    <StyledDrawerUpper hasPadding={hasPadding} isOpen={isOpen} width={width} height={height}>
      <div>
        <Header {...headerProps} />
        <Divider variant="middle" />
        <div>{children}</div>
      </div>
    </StyledDrawerUpper>
  );
};

export default DrawerUpper;
