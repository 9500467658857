import get from 'lodash/get';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledLoadingProps as Props } from './definition';

const StyledLoading = styled.div<Props>`
  ${({ hasBottomMargin, hasLargeProgress, hasMargin, color, labelSize, theme }) => css`
    ${mixin.flex({ direction: hasLargeProgress ? 'column' : 'row' })};
    color: ${get(theme, color as string)};
    user-select: none;

    ${hasBottomMargin &&
    css`
      margin: ${theme.spacing(0, 0, 5, 0)};
    `}

    ${hasMargin &&
    css`
      margin: ${theme.spacing(6)};
    `}

    .label {
      margin: ${hasLargeProgress ? theme.spacing(4, 0, 0) : theme.spacing(0, 0, 0, 1.33)};
      color: ${get(theme, color ?? 'brio.common.black')};
      font-size: ${labelSize ?? (hasLargeProgress ? 16 : 14)}px;
    }
  `}
`;

export default StyledLoading;
