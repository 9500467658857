import { getExtensionAndFullNameFromUserListByUserId } from 'models/User';

/**
 * @name createSeatsOptions
 * @description Creates an array of seat options for a select input
 *
 * @param seatData - The array of seat objects or undefined
 * @returns An array of seat options with value and label
 */
export const createSeatsOptions = (seatData: Seat[] | undefined) => {
  if (seatData) {
    return seatData.map((seat: { id: string }) => ({
      value: seat.id,
      label: getExtensionAndFullNameFromUserListByUserId(seat.id, seatData),
    }));
  }
  return [];
};
