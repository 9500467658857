import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  channel: 'a',
  value: '0',
  variable: 'call_priority',
};
