// Media Queries
import { Media } from 'api/media';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const mediaQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchMedias: builder.query<MediaResponse, void>({
      query: () => ({
        url: Media.Fetch(),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.MEDIA as const, id })),
              { type: TAGS.MEDIA, id: 'LIST' },
            ]
          : [{ type: TAGS.MEDIA, id: 'LIST' }],
    }),
    fetchMediaById: builder.query<Media, ByIdParam>({
      query: ({ id }) => ({
        url: Media.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.MEDIA, id }];
      },
    }),
  }),
});
