import i18next from 'i18next';

const translations = () => ({
  key: {
    label: i18next.t(
      'phone_system:containers.callflows.select_key_dialog.agent_availability.key_select.label',
    ),
  },
  keys: [
    {
      value: 'available',
      label: i18next.t(
        'phone_system:containers.callflows.select_key_dialog.agent_availability.keys.available',
      ),
    },
    {
      value: 'unavailable',
      label: i18next.t(
        'phone_system:containers.callflows.select_key_dialog.agent_availability.keys.unavailable',
      ),
    },
  ],
  title: i18next.t('phone_system:containers.callflows.select_key_dialog.agent_availability.title'),
});

export default translations;
