import { getFieldNames } from 'apps/PhoneSystem/containers/FeatureCodes/utility';
import { getFeatureCodeMeta } from 'apps/PhoneSystem/containers/FeatureCodes/utility/featureCodes';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { ChangeEvent, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Checkbox from 'shared/components/Checkbox';
import Icon from 'shared/components/Icon';
import Input from 'shared/components/Input';
import { maybeTooltipComponent } from 'shared/utility';
import ConfigDialog from './components/ConfigDialog';
import { defaultValues } from './default';
import { FeatureCodeProps as Props } from './definition';
import StyledFeatureCode from './style';

export type { FeatureCodeProps } from './definition';
export { CONSTANTS as FEATURE_CODE_CONSTANTS } from './style';

const FeatureCode = ({ id, name, onChange }: Props) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { dialog, label, startSymbol } = getFeatureCodeMeta()[name];
  const fieldName = getFieldNames(label);
  const watchFields = watch(fieldName.key);

  const on = useMemo(
    () => ({
      change: {
        checkbox: (isEnabled: boolean, value: string) => {
          setValue(fieldName.is_enabled, isEnabled);
          onChange(value ? { [fieldName.key]: value } : {});
        },
        input: (value: string) => {
          onChange({ [fieldName.key]: value });
        },
      },
      click: {
        icon: () => {
          if (watchFields?.id) {
            setIsDialogOpen((isDialogOpen: boolean) => !isDialogOpen);
          }
        },
      },
    }),
    [fieldName.is_enabled, fieldName.key, watchFields?.id, onChange, setValue],
  );

  return (
    <>
      <StyledFeatureCode>
        <Box>
          <Box>{startSymbol ?? defaultValues.startSymbol}</Box>

          <HookFormInputWrapper control={control} name={fieldName.number}>
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <Input
                {...formProps}
                id={id}
                data-test-id={`featurecode-input-${fieldName.key}`}
                disabled={!watchFields?.is_enabled}
                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                  on.change.input(value);
                  onChange(value);
                }}
              />
            )}
          </HookFormInputWrapper>

          <HookFormInputWrapper isCheckbox control={control} name={fieldName.is_enabled}>
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <Checkbox
                {...formProps}
                data-test-id={`featurecode-checkbox-${fieldName.key}`}
                onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
                  on.change.checkbox(checked, checked ? watchFields?.number : undefined);
                  onChange(checked);
                }}
              />
            )}
          </HookFormInputWrapper>
        </Box>
        {dialog &&
          maybeTooltipComponent({
            hasWrapper: false,
            component: (
              <Icon
                hasHover={Boolean(watchFields?.id)}
                name="settings-filled"
                size={20}
                onClick={on.click.icon}
                {...(!watchFields?.id ? { color: 'disabled' } : {})}
              />
            ),
            tooltip: !watchFields?.id
              ? t('phone_system:containers.feature_codes.dialog.config.icon.tooltip')
              : '',
          })}
      </StyledFeatureCode>
      {dialog && watchFields?.id && (
        <ConfigDialog
          isOpen={isDialogOpen}
          id={watchFields?.id}
          title={dialog.title}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
};

export default FeatureCode;
