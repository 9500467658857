import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledDevicePickerField = styled(Box)`
  ${({ theme }) => css`
    ${mixin.flex({ alignItems: 'flex-start' })};

    > div {
      &:nth-of-type(2),
      &:last-of-type {
        margin: -1px 0 0;
      }

      &:nth-of-type(2) {
        margin-right: ${theme.spacing(4)};
      }
    }
  `};
`;
