import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';
import { FunctionComponent } from 'react';
import defaultProps from './default';
import { SwitchProps as Props } from './definition';

export type { SwitchProps } from './definition';

const Switch: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { checked, label, ...rest } = { ...defaultProps, ...props };
  const control = <MuiSwitch checked={checked} {...rest} />;

  return label ? (
    <MuiFormControlLabel
      classes={{
        root: `MuiFormControlLabel-switch ${!checked ? 'MuiFormControlLabel-is-inactive' : ''}`,
      }}
      control={control}
      label={label}
    />
  ) : (
    control
  );
};

export default Switch;
