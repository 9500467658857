import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { ACTION_STOP, HTTP_VERBS } from '../../constants';

const schema = () =>
  Joi.object({
    action: Joi.string().valid(ACTION_STOP),
    extra_metadata: Joi.array(),
    method: Joi.string().valid(...HTTP_VERBS),
    nodeId: Joi.string(),
    record_on_answer: Joi.boolean(),
    record_on_bridge: Joi.boolean(),
    should_follow_transfer: Joi.boolean(),
    time_limit: Joi.number()
      .min(0)
      .required()
      .messages(
        messages({
          'number.base': i18next.t('common:validation.general.required'),
        }),
      ),
  }).messages(messages());

export default schema;
