import produce from 'immer';
import { useEffect } from 'react';
import { enhancedFormUtility } from '../../../utility';
import { useEnsureFormDefaultValueParams } from '../definition';

/**
 * @description A hook to ensure default values are set to the form. Triggered once on mount.
 * @param defaultValues
 * @param contextName usually used in a nested form to prefix the field path
 * @param formFields
 */
export const useEnsureFormDefaultValue = ({
  defaultValues,
  contextName,
  formFields,
}: useEnsureFormDefaultValueParams) => {
  useEffect(() => {
    const formFieldsWithContext = produce(formFields, (draft) => {
      if (contextName) {
        Object.keys(draft).forEach((key) => {
          draft[key] = draft[key]?.map((field) => `${contextName}${field}`);
        });
      }
    });
    enhancedFormUtility.ensureFormDefaultValues(defaultValues, formFieldsWithContext);
  }, []);
};
