import { CHARACTER } from 'constant';
import i18next from 'i18next';
import { Column, Justify } from 'shared/components/Table';
import { CallflowType } from './definition';

/**
 * @name getCallflowTypeLabel
 * @description Determines the type of call flow based on the provided first module.
 *
 * @param firstModule - The first module of the callflow
 *
 * @returns The type of call flow.
 */
export const getCallflowTypeLabel = (firstModule?: string) => {
  switch (firstModule?.toLowerCase()) {
    case CallflowType.Conference:
      return i18next.t('phone_system:containers.callflows.callflow_type.conference');
    case CallflowType.Device:
      return i18next.t('phone_system:containers.callflows.callflow_type.device');
    case CallflowType.Faxbox:
      return i18next.t('phone_system:containers.callflows.callflow_type.faxbox');
    case CallflowType.PageGroup:
      return i18next.t('phone_system:containers.callflows.callflow_type.page_group');
    case CallflowType.RingGroup:
      return i18next.t('phone_system:containers.callflows.callflow_type.ring_group');
    case CallflowType.User:
      return i18next.t('phone_system:containers.callflows.callflow_type.user');
    case CallflowType.Voicemail:
      return i18next.t('phone_system:containers.callflows.callflow_type.voicemail');
    default:
      return i18next.t('phone_system:containers.callflows.callflow_type.other');
  }
};

/**
 * @name columns
 * @description Get the list of columns for the callflows table
 *
 * @returns List of columns
 */
export const columns = (): Array<Column> => [
  {
    Header: i18next.t('phone_system:containers.callflows.table.column.name') as string,
    accessor: 'name',
    width: 600,
  },
  {
    Header: i18next.t('phone_system:containers.callflows.table.column.numbers_used') as string,
    accessor: (row: any) => row.numbers.join(', '),
    sortType: (rowA: any, rowB: any) => {
      const key = 'Numbers Used';
      const a = rowA.values[key] as string;
      const b = rowB.values[key] as string;
      return a.localeCompare(b);
    },
  },
  {
    Header: i18next.t('phone_system:containers.callflows.table.column.type') as string,
    accessor: (row: any) => getCallflowTypeLabel(row?.modules[0]),
    width: 200,
  },
  {
    Header: i18next.t('phone_system:containers.callflows.table.column.number_count') as string,
    id: `callflows.number_count.${Justify.Right}`,
    accessor: (row: any) => row.numbers.length ?? CHARACTER.EMDASH,
    disableGlobalFilter: true,
    width: 200,
  },
];
