import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledHeadingProps as Props } from './definition';

const StyledHeading = styled.h4<Props>`
  ${(props) => css`
    ${mixin.borderRadius({ radius: 'half' })};
    ${mixin.font({ size: 13, weight: 700 })};
    display: grid;
    grid-template: 1fr / 1fr auto 1fr;
    align-items: center;
    justify-items: center;
    height: 30px;
    margin: 2px 0 0;
    background-color: ${props.theme.core.background.grey.medium};
    color: ${props.theme.core.background.grey.dark};
    cursor: pointer;
    text-transform: uppercase;

    span {
      ${mixin.transition({ property: 'color' })};
    }

    svg {
      ${mixin.transition([
        { property: 'fill', duration: 'fast' },
        { property: 'transform', duration: 'fast' },
      ])};
      margin: 0 auto 0 6px;
      fill: ${props.theme.core.background.grey.dark};
      transform: rotate(90deg) scale(0.6);
    }

    &:hover {
      span {
        color: ${props.theme.whitelabel.primary.color};
      }

      svg {
        fill: ${props.theme.whitelabel.primary.color};
      }
    }

    ${props.isOpen &&
    css`
      span {
        color: ${props.theme.whitelabel.primary.color};
      }

      svg {
        fill: ${props.theme.whitelabel.primary.color};
        transform: rotate(180deg) scale(0.6);
      }

      &:hover {
        span {
          color: ${props.theme.core.color.black};
        }

        svg {
          fill: ${props.theme.core.color.black};
        }
      }
    `};
  `};
`;

export default StyledHeading;
