import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import RemovableTable from 'shared/components/RemovableTable';
import { Justify } from 'shared/components/Table';
import { SummaryNumbersProps as Props } from './definition';
import StyledDialogContent from './style';

const SummaryNumbers = ({
  description,
  isOpen,
  importedList: { success = [], error = [] },
  leftButtons,
  title,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const getTableData = (data: Array<string>) => data.map((id: string) => ({ id }));

  const successData = useMemo(() => getTableData(success), [success]);
  const errorData = useMemo(() => getTableData(error), [error]);

  const successColumn = useMemo(
    () => [
      {
        id: `summary-success-numbers.selected.${Justify.Left}`,
        Header: `${t('Success')} (${successData.length})`,
        accessor: 'id',
      },
    ],
    [successData],
  );

  const errorColum = useMemo(
    () => [
      {
        id: `summary-error-numbers.selected.${Justify.Left}`,
        Header: `${t('Error')} (${errorData.length})`,
        accessor: 'id',
      },
    ],
    [errorData],
  );

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onClose();
        break;
      default:
        // Handle any other cases here
        break;
    }
  };

  return (
    <Dialog
      hasTitle
      open={isOpen}
      renderActions={
        <DialogActions
          hasCancelOnly
          cancelLabel={t('common:done')}
          leftButtons={leftButtons}
          onAction={handleDialogAction}
        />
      }
      title={title}
      type={DialogType.Medium}
      onClose={onClose}
    >
      <StyledDialogContent>
        <p>{description}</p>
        <div>
          <RemovableTable
            actionIcon={{ isVisible: false }}
            columns={successColumn}
            data={successData}
            header={{
              icon: {
                name: 'radiobutton-checked-outlined',
              },
            }}
            noDataMessage=""
            rowActionHandler={() => {}}
          />
          <RemovableTable
            actionIcon={{
              isVisible: false,
              color: 'core.states.error.main',
              name: 'minus-circle-outlined',
            }}
            columns={errorColum}
            data={errorData}
            header={{
              icon: {
                name: 'error-outlined',
                color: 'error',
                size: 19,
              },
            }}
            noDataMessage=""
            rowActionHandler={() => {}}
          />
        </div>
      </StyledDialogContent>
    </Dialog>
  );
};

export default SummaryNumbers;
