import styled, { css } from 'styled-components';
import { StyledListItemProps as Props } from './definition';

const StyledListItem = styled.li<Props>`
  ${({ theme }) => css`
    &:not(:last-of-type) {
      margin: 0 0 ${theme.spacing(0.6666)};
    }

    &:last-of-type {
      margin: 0 0 ${theme.spacing(0.3333)};
    }
  `};
`;

export default StyledListItem;
