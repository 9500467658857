import Box from 'shared/components/Box';
import { DynamicInputProps as Props } from './definition';
import { getInput } from './utility';

export const DynamicInput = ({ fieldKey, template }: Props) => {
  const Input = getInput({ type: template.type });

  return (
    <Box role="row">
      <Box role="cell">
        <Input fieldKey={fieldKey} template={template} />
      </Box>
    </Box>
  );
};
