import MediaSelect, { SILENCE } from 'apps/PhoneSystem/shared/MediaSelect';
import { MediaSelector } from 'apps/PhoneSystem/shared/MediaSelector';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['hunt', 'media.greeting', 'name', 'record_pin'];

const BasicsSection: FunctionComponent<Props> = ({ setItemName }: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    control,
    formState: { dirtyFields },
    resetField,
    setValue,
    watch,
  } = useFormContext();
  const greetingMediaId = watch('media.greeting');
  const watchFields = {
    name: watch('name'),
  };

  useEffect(() => {
    if (greetingMediaId === undefined) {
      resetField('media.greeting');
    }
  }, [greetingMediaId, resetField]);

  useEffect(() => {
    setItemName?.(watchFields.name);
  }, [watchFields.name, setItemName]);

  return (
    <>
      <div role="row">
        <div role="cell">
          {/* Name */}
          <HookFormInputWrapper name="name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('phone_system:containers.menu.section.basics.field.name.label')}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'phone_system:containers.menu.section.basics.field.name.placeholder',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>

          {/* Prompt Record PIN */}
          <HookFormInputWrapper name="record_pin">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('phone_system:containers.menu.section.basics.field.record_pin.label')}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'phone_system:containers.menu.section.basics.field.record_pin.placeholder',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>

          {/* Allow caller to dial extensions */}
          <HookFormInputWrapper name="hunt" isCheckbox>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                hasMargin
                isBelow
                isDirty={isDirty}
                feedback={feedback}
                tooltip={t(
                  'phone_system:containers.menu.section.basics.field.allow_caller_to_dial_extensions.info',
                )}
                label={t(
                  'phone_system:containers.menu.section.basics.field.allow_caller_to_dial_extensions.label',
                )}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      {/* Select - Greeting Message */}
      <MediaSelector
        selectedId={greetingMediaId}
        hasEditButton={!!(greetingMediaId && greetingMediaId !== SILENCE.id)}
        hasCreateButton
        onSave={(id) => {
          setValue('media.greeting', id, { shouldValidate: true, shouldDirty: true });
        }}
        onDelete={() => {
          setValue('media.greeting', undefined, { shouldDirty: true });
        }}
        selectComponent={
          <MediaSelect
            isClearable={Boolean(dirtyFields?.media?.greeting)}
            control={control}
            hasSmallMargin
            isTypeIncluded
            isBuildInMediaIncluded={false}
            name="media.greeting"
            tooltip={t('phone_system:containers.menu.section.basics.field.greeting_message.info')}
            label={t('phone_system:containers.menu.section.basics.field.greeting_message.label')}
          />
        }
      />
    </>
  );
};

export default BasicsSection;
