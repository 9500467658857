import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledAddNumbersProps as Props } from './definition';

const StyledAddNumbers = styled.div<Props>`
  ${(props) =>
    css`
      p {
        ${mixin.font({
          size: 13,
          style: 'italic',
          weight: 400,
          letterSpacing: '0.3px',
          lineHeight: '22px',
        })};
        margin: 0 0 ${props.theme.spacing(3)};
      }
    `}
`;

export const StyledDownloadButton = styled(Box)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(3)};
  `}
`;
export default StyledAddNumbers;
