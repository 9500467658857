import i18next from 'i18next';
import { QueueCardDataType } from './definition';

export const getConstants = (onClick: any) => ({
  items: {
    [QueueCardDataType.ACTIVE_AGENTS_CURRENT]: {
      icon: 'groups-filled',
      label: i18next.t('call_center:containers.dashboard.component.queue_card.items.active_agents'),
    },
    [QueueCardDataType.ACTIVE_CALLS]: {
      icon: 'call-filled',
      label: i18next.t('call_center:containers.dashboard.component.queue_card.items.active_calls'),
    },
    [QueueCardDataType.AVERAGE_WAIT_TIME]: {
      icon: 'timer-filled',
      label: i18next.t(
        'call_center:containers.dashboard.component.queue_card.items.average_wait_time',
      ),
    },
    [QueueCardDataType.MISSED_CALLS]: {
      icon: 'block',
      label: i18next.t('call_center:containers.dashboard.component.queue_card.items.missed_calls'),
    },
    [QueueCardDataType.TOTAL_CALLS]: {
      icon: 'call-queue',
      label: i18next.t('call_center:containers.dashboard.component.queue_card.items.total_calls'),
    },
  },
  menu: [
    // TODO We will need this option in a future ticket
    // {
    //   icon: 'visibility-on-filled',
    //   label: i18next.t('call_center:containers.dashboard.component.queue_card.menu.hide_queue'),
    //   onClick: onClick.hide,
    // },
    {
      icon: 'edit',
      label: i18next.t('call_center:containers.dashboard.component.queue_card.menu.edit_queue'),
      onClick: onClick.edit?.menu,
    },
  ],
});
