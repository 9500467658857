import { joiResolver } from '@hookform/resolvers/joi';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { CollectDTMFDialogProps as Props, CollectDtmfNodeData, Data } from './definition';
import schema from './schema';
import translations from './translations';

const CollectDTMFDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = useForm<Data>({
    mode: 'onChange',
    defaultValues,
    resolver: joiResolver(schema()),
  });
  const {
    collection_name,
    interdigit_timeout,
    max_digits,
    terminator,
    terminators,
    timeout,
    title,
  } = useMemo(() => translations(), []);

  const submitHandler = (formData: Data) => {
    const nodeData: CollectDtmfNodeData = { data: formData };
    // Don't want to send collection_name && interdigit_timeout
    // to back-end empty, will cause an error.
    if (!nodeData.data?.collection_name) {
      delete nodeData.data.collection_name;
    }

    if (!nodeData.data?.interdigit_timeout) {
      delete nodeData.data.interdigit_timeout;
    }

    onSave(nodeData, isDirty);
  };

  useEffect(() => reset(enhancedFormUtility.transformDataToFormData(data, defaultValues)), [
    data,
    reset,
  ]);

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper name="collection_name" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={collection_name.label}
            tooltip={collection_name.tooltip}
            inputWidth="small"
            inputProps={{
              ...formProps,
              id: 'input-dtmf-collection-name',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="interdigit_timeout" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={interdigit_timeout.label}
            tooltip={interdigit_timeout.tooltip}
            inputWidth="small"
            inputProps={{
              type: 'number',
              ...formProps,
              id: 'input-dtmf-interdigit-timeout',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="max_digits" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={max_digits.label}
            tooltip={max_digits.tooltip}
            inputWidth="small"
            inputProps={{
              type: 'number',
              ...formProps,
              id: 'input-dtmf-max-digits',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormSelectWrapper name="terminator" options={terminators} control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={terminator.label}
            tooltip={terminator.tooltip}
            selectProps={{
              ...formProps,
              id: 'select-dtmf-terminator',
            }}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormInputWrapper name="timeout" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={timeout.label}
            tooltip={timeout.tooltip}
            inputWidth="small"
            inputProps={{
              type: 'number',
              ...formProps,
              id: 'input-dtmf-timeout',
            }}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default CollectDTMFDialog;
