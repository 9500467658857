import { Dispatch, SetStateAction } from 'react';
import { FilterValues } from 'shared/components/FilterSelect';
import { SelectOption } from 'shared/components/Select';
import { ComponentProps } from 'shared/definition';
import { FilterGroup } from '../../definition';

export enum FilterInputType {
  Select = 'select',
  Checkbox = 'checkbox',
}

type ArrayElement<
  ArrayType extends readonly unknown[]
> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export interface FilterInputItemConfig<Data extends any[] = any[]> {
  /** The compare function used to filter the data */
  compareFunction: (
    filterValues: FilterValues,
    data: ArrayElement<Data>,
    filterDefaults: Record<string, any>,
  ) => boolean;
  /** The type of the filter. Will be used as an identifier for rendering the filter */
  filterType: string;
  /** The header of the input */
  heading: string;
  /** The type of the filter input */
  inputType: FilterInputType;
  /** Get the select input option label, given the value */
  getBreadcrumbItemLabel: (value: string) => string;
  /** Configs for select type input */
  selectInputConfig?: {
    /** Is more than one option could be selected? */
    isMulti?: boolean;
    /** options for select input */
    options?: Array<SelectOption>;
  };
}

export interface FilterInputConfig<Data extends any[] = any[]> {
  /** @description
   * Filter default values used for resetting and comparing default state of filter.
   *
   * For select type input, it should be `[FilterOption.All, ...arrayOfOptionValue]`.
   *
   * For checkbox type input, it should be `[FilterOption.All, filterType]`. The checkbox is unchecked by default. When the box is checked, the value will be set to `[filterType]`. When it's unchecked, it set to this default value.
   * */
  filterDefaults: Record<string, any>;
  /** Filter group (used by session storage) */
  filterGroup: FilterGroup;
  /** The config for each filter input */
  items: { [filterType: string]: FilterInputItemConfig<Data> };
}

export interface FilterDialogContentProps extends ComponentProps {
  /** Filter object */
  filterValues: FilterValues;
  /** The config to build all input */
  inputConfig: FilterInputConfig;
  /** setFilterValues state handler */
  setFilterValues: Dispatch<SetStateAction<FilterValues>>;
}
