import { useFetchRootUserQuery } from 'models/User';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import DropdownButton from 'shared/components/DropdownButton';
import Loading from 'shared/components/Loading';
import { useToast } from 'shared/hooks/useToast';
import defaultProps from './default';
import { ProfileSwitcherProps as Props } from './definition';

const ProfileSwitcher = (props: Props) => {
  const { currentUser, error, registrations } = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { data, isLoading } = useFetchRootUserQuery();

  useEffect(() => {
    if (error) {
      showToast.error(error);
    }
  }, [error, showToast]);

  if (isLoading || !currentUser) {
    return <Loading />;
  }

  return (
    <Box component="form">
      <DropdownButton
        isHeaderElement
        items={registrations}
        info={t('common:component.profile_switcher.info_text')}
        label={`${data?.first_name} ${data?.last_name}`}
        selected={currentUser.kazooUserId}
      />
    </Box>
  );
};

export default ProfileSwitcher;
