import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormRadioGroupWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledRadioGroup } from 'shared/components/Labeled';
import { RadioProps } from 'shared/components/Radio';
import CallflowActionsDialog from '../../../CallflowActionsDialog/components/CallflowActionDialog';
import { DEFAULT_KEY, THRESHOLD_KEY } from '../../constants';
import defaultProps, { defaultValues } from './default';
import { WaitTimeKeyDialogForm, WaitTimeKeyDialogProps as Props } from './definition';
import schema from './schema';

const WaitTimeKeyDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose } = { ...defaultProps, ...props };
  const { usedKeys = [] } = data;
  const { t } = useTranslation();
  const { control, handleSubmit, watch, formState } = useForm<WaitTimeKeyDialogForm>({
    mode: 'onChange',
    defaultValues: {
      key: data.key === DEFAULT_KEY ? DEFAULT_KEY : defaultValues.key,
      threshold: ![DEFAULT_KEY, ''].includes(data.key) ? data.key : defaultValues.threshold,
    },
    resolver: joiResolver(schema()),
  });
  const watchKey = watch('key');
  const isDefaultDisabled = data.key !== DEFAULT_KEY && usedKeys.includes(DEFAULT_KEY);
  const defaultLabel = isDefaultDisabled
    ? t('phone_system:containers.callflows.select_key_dialog.wait_time.options.default_disabled')
    : t('phone_system:containers.callflows.select_key_dialog.wait_time.options.default');
  const options: RadioProps[] = [
    {
      label: t('phone_system:containers.callflows.select_key_dialog.wait_time.options.threshold'),
      value: THRESHOLD_KEY,
    },
    {
      label: defaultLabel,
      value: DEFAULT_KEY,
      disabled: isDefaultDisabled,
    },
  ];
  const { isDirty } = formState;

  const submitHandler = async (formData: WaitTimeKeyDialogForm) => {
    const key = formData.key === DEFAULT_KEY ? DEFAULT_KEY : formData.threshold.toString();
    onSave(key, isDirty);
  };

  return (
    <CallflowActionsDialog
      title={t('phone_system:containers.callflows.select_key_dialog.wait_time.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <p>{t('phone_system:containers.callflows.select_key_dialog.wait_time.content.0')}</p>
      <p>{t('phone_system:containers.callflows.select_key_dialog.wait_time.content.1')}</p>
      {watchKey !== DEFAULT_KEY && (
        <HookFormInputWrapper name="threshold" control={control}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isLabelAbove
              isDirty={isDirty}
              feedback={feedback}
              label={t('common:seconds')}
              inputWidth="small"
              inputProps={{
                ...formProps,
              }}
              type="number"
            />
          )}
        </HookFormInputWrapper>
      )}
      <HookFormRadioGroupWrapper name="key" options={options} control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledRadioGroup
            isLabelAbove
            isDirty={isDirty}
            feedback={feedback}
            label={t('common:options')}
            radioGroupProps={{ ...formProps }}
            radioProps={options}
          />
        )}
      </HookFormRadioGroupWrapper>
    </CallflowActionsDialog>
  );
};

export default WaitTimeKeyDialog;
