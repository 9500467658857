import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  id: '',
  route_to_another_queue: false,
  var: '',
};
