import { FunctionComponent, useCallback } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { Feedback } from 'shared/components/Labeled';
import { OptionTypeBase } from 'shared/components/Select/definition';
import { FormContext } from '../../FormContext';
import { SelectWrapperProps as Props } from './definition';

const SelectWrapper: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { children, control, name, options, defaultValue } = props;
  const formContext = useFormContext();
  const { control: controlContext } = formContext || {};

  const getCurrentSelection = useCallback(
    (isGrouped: boolean, isMultiSelect: boolean, value: any, options: readonly any[]) => {
      if (isGrouped) {
        let selected = null;

        options.forEach((group) => {
          if (group.options.some((option: any) => option.value === value)) {
            selected = group.options.find((option: any) => option.value === value);
          }
        });

        return selected;
      }

      if (isMultiSelect) {
        return options.filter((option: OptionTypeBase) => value.includes(option.value));
      }

      return options.filter((option: OptionTypeBase) => option.value === value);
    },
    [options],
  );

  return (
    <FormContext.Consumer>
      {(formContext) => {
        const contextName = !formContext ? name : `${formContext}${name}`;

        return (
          <Controller
            control={control || controlContext}
            name={contextName}
            defaultValue={defaultValue}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { isDirty, error },
            }: {
              field: ControllerRenderProps;
              fieldState: ControllerFieldState;
            }) => {
              const isGrouped = options.some((option) => option.hasOwnProperty('options'));
              const isMultiSelect = Array.isArray(value);
              const currentSelection = getCurrentSelection(
                isGrouped,
                isMultiSelect,
                value,
                options,
              );

              const handleSelectChange = (selectedOption: OptionTypeBase) => {
                let option = selectedOption?.value;

                if (isMultiSelect && Array.isArray(selectedOption)) {
                  option = selectedOption.map((option: any) => option.value);
                }

                return onChange(option);
              };

              const extended: {
                isDirty?: boolean;
                feedback?: Feedback;
              } = {
                isDirty,
              };

              if (error) {
                extended.feedback = {
                  type: 'error',
                  message: error.message || '',
                };
              }

              return children({
                value: currentSelection,
                options,
                onChange: handleSelectChange,
                ...extended,
                ...rest,
              });
            }}
          />
        );
      }}
    </FormContext.Consumer>
  );
};

export default SelectWrapper;
