import { smsMutations } from './Mutations';
import { smsQueries } from './Queries';

export const {
  useFetchSMSQuery,
  useFetchSMSByOwnerIdQuery,
  useFetchSMSByUserIdQuery,
  useFetchSMSByIdQuery,
} = smsQueries;
export const {
  useCreateSMSMutation,
  usePatchSMSMutation,
  useUpdateSMSMutation,
  useDeleteSMSMutation,
} = smsMutations;
