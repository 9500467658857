import Joi from 'joi';
import { messages } from 'shared/utility/validation';

const schema = () =>
  Joi.object({
    nodeId: Joi.string(),
    name: Joi.string().allow(''),
    id: Joi.string().required(),
  }).messages(messages());

export default schema;
