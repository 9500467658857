import { useFormContext } from 'react-hook-form';
import Box from 'shared/components/Box';
import Typography from 'shared/components/Typography';
import { FeatureCodeName } from '../../definition';
import { getFieldNames } from '../../utility';
import { getFeatureCodeMeta } from '../../utility/featureCodes';
import LabeledFeatureCode from './components/LabeledFeatureCode';
import { featureCodeGroups } from './constants';
import { FeatureCodeGroupProps as Props } from './definition';
import StyledFeatureCodeGroup from './style';
import { getHeading } from './utility';

export { FeatureCodeGroupName } from './definition';

const FeatureCodeGroup = ({ name, onChange }: Props) => {
  const {
    formState: { dirtyFields },
  } = useFormContext();

  const featureCodeMeta = getFeatureCodeMeta();

  return (
    <StyledFeatureCodeGroup>
      <Typography variant="h2">{getHeading(name)}</Typography>
      <Box>
        {featureCodeGroups[name].map((name: FeatureCodeName) => {
          const { dialog, label } = featureCodeMeta[name];
          const { key } = getFieldNames(label);

          const isDirty = Object.keys(dirtyFields).some((field: string) => field === key);

          return (
            <LabeledFeatureCode
              hasCogIcon={Boolean(dialog)}
              isDirty={isDirty}
              featureCodeProps={{ name, onChange }}
              key={name}
              label={label}
            />
          );
        })}
      </Box>
    </StyledFeatureCodeGroup>
  );
};

export default FeatureCodeGroup;
