export const kazooTimezoneList: Record<string, string> = {
  'Africa/Abidjan': 'Casablanca, Monrovia',
  'Africa/Accra': 'Casablanca, Monrovia',
  'Africa/Addis_Ababa': 'Nairobi',
  'Africa/Algiers': 'West Central Africa',
  'Africa/Asmera': 'Kuwait, Riyadh',
  'Africa/Bamako': 'Casablanca, Monrovia',
  'Africa/Bangui': 'West Central Africa',
  'Africa/Banjul': 'Casablanca, Monrovia',
  'Africa/Bissau': 'Casablanca, Monrovia',
  'Africa/Blantyre': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Brazzaville': 'West Central Africa',
  'Africa/Bujumbura': 'Harare, Pretoria',
  'Africa/Cairo': 'Cairo',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Africa/Ceuta': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Conakry': 'Casablanca, Monrovia',
  'Africa/Dakar': 'Casablanca, Monrovia',
  'Africa/Dar_es_Salaam': 'Nairobi',
  'Africa/Djibouti': 'Nairobi',
  'Africa/Douala': 'West Central Africa',
  'Africa/El_Aaiun': 'Lisbon',
  'Africa/Freetown': 'Casablanca, Monrovia',
  'Africa/Gaborone': 'Harare, Pretoria',
  'Africa/Harare': 'Harare, Pretoria',
  'Africa/Johannesburg': 'Harare, Pretoria',
  'Africa/Kampala': 'Nairobi',
  'Africa/Khartoum': 'Cairo',
  'Africa/Kigali': 'Harare, Pretoria',
  'Africa/Kinshasa': 'West Central Africa',
  'Africa/Lagos': 'Cape Verde Islands',
  'Africa/Libreville': 'Cape Verde Islands',
  'Africa/Lome': 'Casablanca, Monrovia',
  'Africa/Luanda': 'Cape Verde Islands',
  'Africa/Lubumbashi': 'Harare, Pretoria',
  'Africa/Lusaka': 'Harare, Pretoria',
  'Africa/Malabo': 'West Central Africa',
  'Africa/Maputo': 'Harare, Pretoria',
  'Africa/Maseru': 'Harare, Pretoria',
  'Africa/Mbabane': 'Harare, Pretoria',
  'Africa/Mogadishu': 'Nairobi',
  'Africa/Monrovia': 'Hawaii',
  'Africa/Nairobi': 'Nairobi',
  'Africa/Ndjamena': 'West Central Africa',
  'Africa/Niamey': 'West Central Africa',
  'Africa/Nouakchott': 'Casablanca, Monrovia',
  'Africa/Ouagadougou': 'Casablanca, Monrovia',
  'Africa/Porto-Novo': 'Cape Verde Islands',
  'Africa/Sao_Tome': 'Casablanca, Monrovia',
  'Africa/Timbuktu': 'Casablanca, Monrovia',
  'Africa/Tripoli': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Africa/Tunis': 'West Central Africa',
  'Africa/Windhoek': 'Cape Verde Islands',
  'America/Adak': 'Adak',
  'America/Anchorage': 'Alaska',
  'America/Anguilla': 'Caracas, La Paz',
  'America/Antigua': 'Caracas, La Paz',
  'America/Araguaina': 'Brasilia',
  'America/Aruba': 'Caracas, La Paz',
  'America/Asuncion': 'Caracas, La Paz',
  'America/Barbados': 'Caracas, La Paz',
  'America/Belem': 'Brasilia',
  'America/Belize': 'Central America',
  'America/Boa_Vista': 'Caracas, La Paz',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Boise': 'Mountain Time',
  'America/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Cambridge_Bay': 'Saskatchewan',
  'America/Cancun': 'Guadalajara, Mexico City, Monterrey',
  'America/Caracas': 'Caracas, La Paz',
  'America/Catamarca': 'Buenos Aires, Georgetown',
  'America/Cayenne': 'Brasilia',
  'America/Cayman': 'Bogota, Lima, Quito',
  'America/Chicago': 'Central Time',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Cordoba': 'Buenos Aires, Georgetown',
  'America/Costa_Rica': 'Central America',
  'America/Cuiaba': 'Caracas, La Paz',
  'America/Curacao': 'Caracas, La Paz',
  'America/Danmarkshavn': 'Azores',
  'America/Dawson': 'Pacific Time',
  'America/Dawson_Creek': 'Pacific Time',
  'America/Denver': 'Saskatchewan',
  'America/Detroit': 'Eastern Time',
  'America/Dominica': 'Caracas, La Paz',
  'America/Edmonton': 'Central America',
  'America/Eirunepe': 'Bogota, Lima, Quito',
  'America/El_Salvador': 'Central America',
  'America/Fortaleza': 'Brasilia',
  'America/Glace_Bay': 'Santiago',
  'America/Godthab': 'Greenland',
  'America/Goose_Bay': 'Brasilia',
  'America/Grand_Turk': 'Caracas, La Paz',
  'America/Grenada': 'Caracas, La Paz',
  'America/Guadeloupe': 'Caracas, La Paz',
  'America/Guatemala': 'Central America',
  'America/Guayaquil': 'Bogota, Lima, Quito',
  'America/Guyana': 'Caracas, La Paz',
  'America/Halifax': 'Santiago',
  'America/Havana': 'Eastern Time',
  'America/Hermosillo': 'Tijuana',
  'America/Indiana/Indianapolis': 'Eastern Time',
  'America/Indiana/Knox': 'Eastern Time',
  'America/Indiana/Marengo': 'Eastern Time',
  'America/Indiana/Vevay': 'Eastern Time',
  'America/Indianapolis': 'Eastern Time',
  'America/Inuvik': 'Saskatchewan',
  'America/Iqaluit': 'Eastern Time',
  'America/Jamaica': 'Bogota, Lima, Quito',
  'America/Jujuy': 'Buenos Aires, Georgetown',
  'America/Juneau': 'Alaska',
  'America/Kentucky/Louisville': 'Eastern Time',
  'America/Kentucky/Monticello': 'Eastern Time',
  'America/La_Paz': 'Caracas, La Paz',
  'America/Lima': 'Bogota, Lima, Quito',
  'America/Los_Angeles': 'Pacific Time',
  'America/Louisville': 'Pacific Time',
  'America/Maceio': 'Brasilia',
  'America/Managua': 'Central America',
  'America/Manaus': 'Caracas, La Paz',
  'America/Martinique': 'Caracas, La Paz',
  'America/Mazatlan': 'Chihuahua, La Paz, Mazatlan',
  'America/Mendoza': 'Buenos Aires, Georgetown',
  'America/Menominee': 'Central Time',
  'America/Merida': 'Bogota, Lima, Quito',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Miquelon': 'Greenland',
  'America/Monterrey': 'Guadalajara, Mexico City, Monterrey',
  'America/Montevideo': 'Montevideo',
  'America/Montreal': 'Eastern Time',
  'America/Montserrat': 'Caracas, La Paz',
  'America/Nassau': 'Caracas, La Paz',
  'America/New_York': 'Eastern Time',
  'America/Nipigon': 'Eastern Time',
  'America/Nome': 'Alaska',
  'America/Noronha': 'Greenland',
  'America/North_Dakota/Center': 'Central Time',
  'America/Panama': 'Bogota, Lima, Quito',
  'America/Pangnirtung': 'Eastern Time',
  'America/Paramaribo': 'Brasilia',
  'America/Phoenix': 'Arizona',
  'America/Port-au-Prince': 'Caracas, La Paz',
  'America/Port_of_Spain': 'Caracas, La Paz',
  'America/Porto_Velho': 'Caracas, La Paz',
  'America/Puerto_Rico': 'Caracas, La Paz',
  'America/Rainy_River': 'Central Time',
  'America/Rankin_Inlet': 'Central Time',
  'America/Recife': 'Brasilia',
  'America/Regina': 'Saskatchewan',
  'America/Rio_Branco': 'Bogota, Lima, Quito',
  'America/Rosario': 'Buenos Aires, Georgetown',
  'America/Santiago': 'Santiago',
  'America/Santo_Domingo': 'Caracas, La Paz',
  'America/Sao_Paulo': 'Brasilia',
  'America/Scoresbysund': 'Azores',
  'America/Shiprock': 'Saskatchewan',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/St_Kitts': 'Caracas, La Paz',
  'America/St_Lucia': 'Caracas, La Paz',
  'America/St_Thomas': 'Caracas, La Paz',
  'America/St_Vincent': 'Caracas, La Paz',
  'America/Swift_Current': 'Saskatchewan',
  'America/Tegucigalpa': 'Central America',
  'America/Thule': 'Brasilia',
  'America/Thunder_Bay': 'Eastern Time',
  'America/Tijuana': 'Tijuana',
  'America/Toronto': 'Eastern Time',
  'America/Tortola': 'Caracas, La Paz',
  'America/Vancouver': 'Pacific Time',
  'America/Whitehorse': 'Pacific Time',
  'America/Winnipeg': 'Central Time',
  'America/Yakutat': 'Alaska',
  'America/Yellowknife': 'Saskatchewan',
  'Antarctica/Casey': 'Perth',
  'Antarctica/Davis': 'Bangkok, Hanoi, Jakarta',
  'Antarctica/DumontDUrville': 'Brisbane',
  'Antarctica/Mawson': 'Islamabad, Karachi, Tashkent',
  'Antarctica/McMurdo': 'Kamchatka, Marshall Islands',
  'Antarctica/Palmer': 'Buenos Aires, Georgetown',
  'Antarctica/South_Pole': 'Kamchatka, Marshall Islands',
  'Antarctica/Syowa': 'Nairobi',
  'Antarctica/Vostok': 'Astana, Dhaka',
  'Arctic/Longyearbyen': 'Greenland',
  'Asia/Aden': 'Nairobi',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Amman': 'Jerusalem',
  'Asia/Anadyr': 'Kamchatka, Marshall Islands',
  'Asia/Aqtau': 'Ekaterinburg',
  'Asia/Aqtobe': 'Ekaterinburg',
  'Asia/Ashgabat': 'Baku, Tbilisi, Yerevan',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Bahrain': 'Baghdad',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Beirut': 'Jerusalem',
  'Asia/Bishkek': 'Almaty, Novosibirsk',
  'Asia/Brunei': 'Kuala Lumpur, Singapore',
  'Asia/Calcutta': 'Sri Jayawardenepura',
  'Asia/Choibalsan': 'Irkutsk, Ulaanbaatar',
  'Asia/Chongqing': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Damascus': 'Jerusalem',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Dili': 'Seoul',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Dushanbe': 'Islamabad, Karachi, Tashkent',
  'Asia/Gaza': 'Jerusalem',
  'Asia/Harbin': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Hong_Kong': 'Taipei',
  'Asia/Hovd': 'Bangkok, Hanoi, Jakarta',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Istanbul': 'Jerusalem',
  'Asia/Jakarta': 'Bangkok, Hanoi, Jakarta',
  'Asia/Jayapura': 'Seoul',
  'Asia/Jerusalem': 'Jerusalem',
  'Asia/Kabul': 'Kabul',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kashgar': 'Almaty, Novosibirsk',
  'Asia/Katmandu': 'Kathmandu',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Kuching': 'Kuala Lumpur, Singapore',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Asia/Macao': 'Kuala Lumpur, Singapore',
  'Asia/Macau': 'Kuala Lumpur, Singapore',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Makassar': 'Kuala Lumpur, Singapore',
  'Asia/Manila': 'Taipei',
  'Asia/Muscat': 'Abu Dhabi, Muscat',
  'Asia/Nicosia': 'Jerusalem',
  'Asia/Novosibirsk': 'Krasnoyarsk',
  'Asia/Omsk': 'Almaty, Novosibirsk',
  'Asia/Oral': 'Ekaterinburg',
  'Asia/Phnom_Penh': 'Bangkok, Hanoi, Jakarta',
  'Asia/Pontianak': 'Bangkok, Hanoi, Jakarta',
  'Asia/Pyongyang': 'Seoul',
  'Asia/Qyzylorda': 'Almaty, Novosibirsk',
  'Asia/Qatar': 'Kuwait, Riyadh',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Riyadh': 'Kuwait, Riyadh',
  'Asia/Saigon': 'Bangkok, Hanoi, Jakarta',
  'Asia/Sakhalin': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Samarkand': 'Islamabad, Karachi, Tashkent',
  'Asia/Seoul': 'Seoul',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Singapore': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Asia/Tashkent': 'Islamabad, Karachi, Tashkent',
  'Asia/Tbilisi': 'Baku, Tbilisi, Yerevan',
  'Asia/Tehran': 'Tehran',
  'Asia/Thimphu': 'Almaty, Novosibirsk',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Ujung_Pandang': 'Irkutsk, Ulaanbaatar',
  'Asia/Ulaanbaatar': 'Irkutsk, Ulaanbaatar',
  'Asia/Urumqi': 'Almaty, Novosibirsk',
  'Asia/Vientiane': 'Bangkok, Hanoi, Jakarta',
  'Asia/Vladivostok': 'Vladivostok',
  'Asia/Yakutsk': 'Yakutsk',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Yerevan': 'Baku, Tbilisi, Yerevan',
  'Atlantic/Azores': 'UTC',
  'Atlantic/Bermuda': 'Brasilia',
  'Atlantic/Canary': 'Canary Islands',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  'Atlantic/Faeroe': 'Edinburgh, London',
  'Atlantic/Jan_Mayen': 'Cairo',
  'Atlantic/Madeira': 'Lisbon',
  'Atlantic/Reykjavik': 'Azores',
  'Atlantic/South_Georgia': 'Greenland',
  'Atlantic/St_Helena': 'UTC',
  'Atlantic/Stanley': 'Santiago',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Broken_Hill': 'Adelaide',
  'Australia/Darwin': 'Darwin',
  'Australia/Hobart': 'Hobart',
  'Australia/Lindeman': 'Hobart',
  'Australia/Lord_Howe': 'Lord Howe',
  'Australia/Melbourne': 'Hobart',
  'Australia/Perth': 'Perth',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Europe/Andorra': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Athens': 'Athens, Minsk',
  'Europe/Belfast': 'Dublin',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Berlin': 'Greenland',
  'Europe/Bratislava': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Bucharest': 'Bucharest',
  'Europe/Budapest': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Chisinau': 'Bucharest',
  'Europe/Copenhagen': 'Greenland',
  'Europe/Dublin': 'Dublin',
  'Europe/Gibraltar': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Helsinki': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Istanbul': 'Bucharest',
  'Europe/Kaliningrad': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Kiev': 'Bucharest',
  'Europe/Lisbon': 'Lisbon',
  'Europe/Ljubljana': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/London': 'Edinburgh, London',
  'Europe/Luxembourg': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Madrid': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Malta': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Minsk': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Monaco': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Moscow': 'Istanbul, Moscow, St. Petersburg, Volgograd',
  'Europe/Nicosia': 'Athens, Minsk',
  'Europe/Oslo': 'Greenland',
  'Europe/Paris': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Prague': 'Greenland',
  'Europe/Riga': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Rome': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Samara': 'Abu Dhabi, Muscat',
  'Europe/San_Marino': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Simferopol': 'Bucharest',
  'Europe/Skopje': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sofia': 'Bucharest',
  'Europe/Stockholm': 'Greenland',
  'Europe/Tallinn': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Tirane': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Uzhgorod': 'Bucharest',
  'Europe/Vaduz': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Vatican': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Vienna': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Vilnius': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Warsaw': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Zagreb': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Zaporozhye': 'Istanbul, Moscow, St. Petersburg, Volgograd',
  'Europe/Zurich': 'Brussels, Copenhagen, Madrid, Paris',
  'Indian/Antananarivo': 'Nairobi',
  'Indian/Chagos': 'Astana, Dhaka',
  'Indian/Christmas': 'Krasnoyarsk',
  'Indian/Cocos': 'Yangon Rangoon',
  'Indian/Comoro': 'Nairobi',
  'Indian/Kerguelen': 'Islamabad, Karachi, Tashkent',
  'Indian/Mahe': 'Abu Dhabi, Muscat',
  'Indian/Maldives': 'Islamabad, Karachi, Tashkent',
  'Indian/Mauritius': 'Abu Dhabi, Muscat',
  'Indian/Mayotte': 'Nairobi',
  'Indian/Reunion': 'Abu Dhabi, Muscat',
  'Pacific/Apia': "Nuku'alofa",
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Chatham': 'Chatham',
  'Pacific/Easter': 'Central America',
  'Pacific/Efate': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Enderbury': "Nuku'alofa",
  'Pacific/Fakaofo': "Nuku'alofa",
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Funafuti': 'Fiji Islands',
  'Pacific/Galapagos': 'Central America',
  'Pacific/Gambier': 'Gambier',
  'Pacific/Guadalcanal': 'Midway Island, Samoa',
  'Pacific/Guam': 'Brisbane',
  'Pacific/Honolulu': 'Hawaii',
  'Pacific/Johnston': 'Hawaii',
  'Pacific/Kiritimati': 'Kiritimati',
  'Pacific/Kosrae': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Kwajalein': 'Fiji Islands',
  'Pacific/Majuro': 'Kamchatka, Marshall Islands',
  'Pacific/Marquesas': 'Marquesas',
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Nauru': 'Fiji Islands',
  'Pacific/Niue': 'Midway Island, Samoa',
  'Pacific/Norfolk': 'Norfolk',
  'Pacific/Noumea': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Pago_Pago': 'Midway Island, Samoa',
  'Pacific/Palau': 'Palau',
  'Pacific/Pitcairn': 'Alaska',
  'Pacific/Ponape': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Port_Moresby': 'Brisbane',
  'Pacific/Rarotonga': 'Hawaii',
  'Pacific/Saipan': 'Brisbane',
  'Pacific/Tahiti': 'Hawaii',
  'Pacific/Tarawa': 'Fiji Islands',
  'Pacific/Tongatapu': "Nuku'alofa",
  'Pacific/Truk': 'Brisbane',
  'Pacific/Wake': 'Fiji Islands',
  'Pacific/Wallis': 'Fiji Islands',
  'Pacific/Yap': 'Brisbane',
};
