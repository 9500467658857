import { BaseSyntheticEvent, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import JsonErrorDialog from 'shared/components/JsonErrorDialog';
import useSamlSso, { URL_PARAM } from 'shared/hooks/useSamlSso';
import Divider from './components/Divider';
import { Props } from './definition';
import StyledSamlSsoLogin from './style';

const SamlSsoLogin: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { config } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [error, setError] = useState<Record<string, any> | null>(null);
  const isModalOpen = Boolean(error);
  const { getKazooAuthTokens, getAccountTokens, getLoginUrl } = useSamlSso(config);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const stateIsValid =
      searchParams.has(URL_PARAM.STATE) &&
      searchParams.get(URL_PARAM.STATE) === config.saml_sso.account_params.state;

    if (stateIsValid) {
      const registrationId = Number(searchParams.get(URL_PARAM.REGISTRATION_ID));
      const accessToken = searchParams.get(URL_PARAM.ACCESS_TOKEN) as string;
      const refreshToken = searchParams.get(URL_PARAM.REFRESH_TOKEN) as string;
      const sessionId = searchParams.get(URL_PARAM.SESSION_ID) as string;

      // *.ooma.com domains
      if (accessToken) {
        getKazooAuthTokens({
          access_token: accessToken,
          refresh_token: refreshToken,
          registration_id: registrationId,
        }).catch((error) => setError(error));
      }

      // Non *.ooma.com domains
      if (sessionId) {
        getAccountTokens(sessionId, registrationId).catch((error) => setError(error));
      }
    }
  }, [location]);

  const closeModal = () => setError(null);

  const samlSsoClickHandler = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    window.open(getLoginUrl().toString(), '_parent');
  };

  return (
    <StyledSamlSsoLogin>
      <Divider text={t('common:or')} />
      <a href="#" onClick={samlSsoClickHandler}>
        {t(`common:auth.login.saml_sso`)}
      </a>
      <JsonErrorDialog open={isModalOpen} errors={error} onClose={closeModal} />
    </StyledSamlSsoLogin>
  );
};

export default SamlSsoLogin;
