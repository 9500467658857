import connectivity from 'api/connectivity';
import { RequestMethod } from 'definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';
import { ConnectivityResponse, CreateConnectivityParams, UpdateConnectivityParams } from './types';

export const connectivityMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createConnectivity: builder.mutation<ConnectivityResponse, CreateConnectivityParams>({
      query: ({ body }) => ({
        url: connectivity.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: () => [{ type: TAGS.CONNECTIVITY }],
    }),
    updateConnectivityById: builder.mutation<ConnectivityResponse, UpdateConnectivityParams>({
      query: ({ id, body }) => ({
        url: connectivity.UpdateById(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: () => [{ type: TAGS.CONNECTIVITY }],
    }),
  }),
});
