import { Trans } from 'react-i18next';
import { StyledDocumentLink } from './style';

const DocumentLink = () => (
  <StyledDocumentLink>
    <Trans i18nKey="phone_system:containers.account.submodule.settings.section.sso.setup_idp.edit_intro">
      The Identity Provider (IdP) has been set up. Follow the{' '}
      <a href="https://support.oomaenterprise.com" target="_blank" rel="noreferrer">
        document
      </a>{' '}
      to complete the configuration.
    </Trans>
  </StyledDocumentLink>
);

export default DocumentLink;
