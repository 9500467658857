import { ADD_KEY } from 'constant';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import VoicemailEditView from './EditView';
import VoicemailListView from './ListView';

type Props = {
  id: string;
};

export default function TabVoicemail({ id = ADD_KEY }: Props) {
  const routes = useRoutes([
    { path: '/', element: <VoicemailListView id={id} /> },
    { path: '/:id/', element: <VoicemailEditView /> },
  ]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
}
