import { themeColor } from '../..';
import theme from '../core';

export default {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      color: themeColor.core.divider.default,
      '&.Mui-checked': {
        color: theme.palette.secondary.main,
      },
      '&.MuiCheckbox-indeterminate': {
        color: theme.palette.secondary.main,
      },
    },
  },
};
