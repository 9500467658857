// Group/index.ts
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { groupMutations } from './Mutations';
import { groupQueries } from './Queries';

export const schema = () =>
  Joi.object({
    name: Joi.string().required().min(1).max(128),
    endpoints: Joi.object(),
  })
    .unknown(true)
    .messages(messages());

export const { useFetchGroupsQuery, useFetchGroupByIdQuery } = groupQueries;
export const {
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} = groupMutations;
