import { useShowMasqueradingToast } from 'apps/shared/hooks/useShowMasqueradingToast';
import { setCurrentAccount } from 'apps/shared/utility/account';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button';
import { maybeTooltipComponent } from 'shared/utility';
import { defaultProps } from './default';
import { UseAccountButtonProps as Props } from './definition';

const UseAccountButton = memo(
  (props: Props): JSX.Element => {
    const {
      account: { id, name },
      toggleAllRowsExpanded,
      buttonProps,
      navigateTo,
      tooltip,
    }: Props = { ...defaultProps, ...props };
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { showMasqueradingToast } = useShowMasqueradingToast();
    const navigate = useNavigate();

    const onClick = useCallback(() => {
      toggleAllRowsExpanded?.(false);
      setCurrentAccount(dispatch, id);
      showMasqueradingToast(name);
      if (navigateTo) {
        navigate(navigateTo);
      }
    }, [id, name, dispatch, showMasqueradingToast, toggleAllRowsExpanded]);

    const component = (
      <Button {...buttonProps} onClick={onClick}>
        {t('accounts_manager:component.use_account_button.label')}
      </Button>
    );

    return <>{maybeTooltipComponent({ component, tooltip })}</>;
  },
);

export default UseAccountButton;
