import { FormContext } from 'apps/shared/components/FormContext';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { useFetchAccountQuery } from 'models/Account';
import { FunctionComponent, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import LabeledSelect from 'shared/components/Labeled/components/LabeledSelect';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'sip.expire_seconds',
  'outbound_flags',
  'sip.invite_format',
  'sip.method',
  'sip.username',
  'sip.password',
];

const SIPSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchAccountQuery();
  const [hidePassword, setHidePassword] = useState(true);
  const { watch } = useFormContext();
  const contextName = useContext(FormContext);
  const showIP = watch(`${contextName}sip.method`) === 'ip';

  const inviteFormatOptions = [
    {
      value: 'username',
      label: t('phone_system:containers.devices.section.sip.invite_format.values.username'),
    },
    {
      value: 'npan',
      label: t('phone_system:containers.devices.section.sip.invite_format.values.npan'),
    },
    {
      value: 'e164',
      label: t('phone_system:containers.devices.section.sip.invite_format.values.e164'),
    },
    {
      value: '1npan',
      label: t('phone_system:containers.devices.section.sip.invite_format.values.1npan'),
    },
    {
      value: 'route',
      label: t('phone_system:containers.devices.section.sip.invite_format.values.route'),
    },
    {
      value: 'contact',
      label: t('phone_system:containers.devices.section.sip.invite_format.values.contact'),
    },
  ];

  const authMethodOptions = [
    {
      value: 'password',
      label: t('phone_system:containers.devices.section.sip.authentication_method.values.password'),
    },
    {
      value: 'ip',
      label: t('phone_system:containers.devices.section.sip.authentication_method.values.ip'),
    },
  ];

  return (
    <>
      <div role="row">
        <div role="cell">
          <LabeledInput
            label={t('phone_system:containers.devices.section.sip.realm')}
            inputProps={{
              value: isLoading ? '' : data?.realm,
              disabled: true,
              id: 'input-sip-realm',
            }}
          />

          {/* Expire Seconds */}
          <HookFormInputWrapper name="sip.expire_seconds">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.devices.section.sip.expire_seconds')}
                inputProps={{
                  ...formProps,
                  id: 'input-sip-expire-seconds',
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>

          {/* Outbound Flags */}
          <HookFormInputWrapper name="outbound_flags">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.devices.section.sip.outbound_flags.label')}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'phone_system:containers.devices.section.sip.outbound_flags.placeholder',
                  ),
                  id: 'input-sip-outbound-flags',
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          {/* Invite Format */}
          <HookFormSelectWrapper name="sip.invite_format" options={inviteFormatOptions}>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSelect
                label={t('phone_system:containers.devices.section.sip.invite_format.label')}
                selectWidth="medium"
                selectProps={{
                  ...formProps,
                  id: 'select-sip-invite-formate',
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.devices.section.sip.authentication')}</h2>
      <div role="row" className="no-margin">
        <div role="cell">
          {/* Authentication Method */}
          <HookFormSelectWrapper name="sip.method" options={authMethodOptions}>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSelect
                label={t('phone_system:containers.devices.section.sip.authentication_method.label')}
                id="input-sip-authentication-method"
                selectProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Username */}
          {showIP && (
            <HookFormInputWrapper name="sip.ip">
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  label={t('phone_system:containers.devices.section.sip.ip')}
                  id="input-sip-ip"
                  inputProps={{
                    ...formProps,
                  }}
                  isDirty={isDirty}
                  feedback={feedback}
                />
              )}
            </HookFormInputWrapper>
          )}
          {!showIP && (
            <HookFormInputWrapper name="sip.username">
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  label={t('phone_system:containers.devices.section.sip.username')}
                  id="input-sip-username"
                  inputProps={{
                    ...formProps,
                  }}
                  isDirty={isDirty}
                  feedback={feedback}
                />
              )}
            </HookFormInputWrapper>
          )}
        </div>

        <div role="cell">
          {/* password */}
          {!showIP && (
            <HookFormInputWrapper name="sip.password">
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  label={t('phone_system:containers.devices.section.sip.password')}
                  id="input-sip-password"
                  inputProps={{
                    ...formProps,
                    $hasNoAutofill: true,
                    type: hidePassword ? 'password' : undefined,
                    onClick: (e) => {
                      setHidePassword(false);
                    },
                    onBlur: () => {
                      setHidePassword(true);
                    },
                  }}
                  feedback={feedback}
                  isDirty={isDirty}
                />
              )}
            </HookFormInputWrapper>
          )}
        </div>
      </div>
    </>
  );
};

export default SIPSection;
