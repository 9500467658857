import { FunctionComponent } from 'react';
import Box from 'shared/components/Box';
import Checkbox from 'shared/components/Checkbox';
import InputMessage from 'shared/components/InputMessage';
import * as utility from 'shared/utility';
import { DirtyIcon, InfoIcon } from '../Icon';
import Label from './components/Label';
import defaultProps from './default';
import { LabeledCheckboxProps as Props } from './definition';
import StyledLabeledCheckbox from './style';

export { getStyle } from './style';

const LabeledCheckbox: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasDirtyIcon,
    hasMargin,
    hasSmallMargin, // TODO: Replace with marginSize
    hasValidationMargin,
    isBelow,
    isCheckboxLeft,
    isDirty,
    isDisabled,
    isInline,
    checkboxProps,
    feedback,
    groupLabel,
    groupLabelProps,
    groupLabelWidth,
    id,
    indentWidth,
    label,
    labelProps,
    labelTooltip,
    labelWidth,
    tooltip,
    tooltipWidth,
  }: Props = { ...defaultProps, ...props };
  const forId = id || utility.generateId('checkbox');

  const checkboxLabel = (
    <Label id={forId} label={label} props={labelProps} tooltip={labelTooltip} width={labelWidth} />
  );

  return (
    <StyledLabeledCheckbox
      hasGroupLabel={Boolean(groupLabel)}
      hasMargin={hasMargin}
      hasSmallMargin={hasSmallMargin}
      hasValidationMargin={hasValidationMargin}
      indentWidth={indentWidth}
      isBelow={isBelow}
      isCheckboxLeft={isCheckboxLeft}
      isDisabled={isDisabled}
      isInline={isInline}
      labelWidth={labelWidth}
      leftMargin={labelProps?.leftMargin}
    >
      {groupLabel && (
        <Label
          label={groupLabel}
          props={groupLabelProps}
          tooltip={labelTooltip}
          width={groupLabelWidth}
        />
      )}
      {!isCheckboxLeft && checkboxLabel}
      <Box>
        <Box>
          <Checkbox {...checkboxProps} disabled={isDisabled} id={forId} />
          {isCheckboxLeft && checkboxLabel}
          {tooltip && <InfoIcon isInline className={tooltipWidth} tooltip={tooltip} />}
          {hasDirtyIcon && <DirtyIcon isInline isDirty={isDirty} />}
        </Box>
        {feedback && <InputMessage message={feedback.message} type={feedback.type} />}
      </Box>
    </StyledLabeledCheckbox>
  );
};

export default LabeledCheckbox;
