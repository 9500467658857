import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { ACTION_UPDATE } from '../../constants';

const schema = () =>
  Joi.object({
    action: Joi.string().valid(ACTION_UPDATE),
    nodeId: Joi.string(),
  }).messages(messages());

export default schema;
