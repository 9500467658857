import { createMattermostUserParams } from './definition';

/**
 * @description The utility to create mattermost user if team chat is enabled for an account.
 */
export const createMattermostUser = async ({
  accountData,
  createMattermostUserMutation,
  onError,
  userId,
}: createMattermostUserParams): Promise<boolean> => {
  try {
    if (accountData?.ooma_info?.team_chat?.enabled) {
      await createMattermostUserMutation({
        userId,
      }).unwrap();
    }
    return true;
  } catch (e) {
    onError?.();
    return false;
  }
};
