import { ADD_KEY } from 'constant';

/**
 * @description Get the initial values for a checkbox group form field based on the edit mode (i.e., edit or add)
 * @param contextFormData The form context data for identifying nested form (e.g., device.add-1701765629457.)
 * @param defaultValue The default value for the field
 */
export const getCheckboxGroupInitialValues = (
  contextFormData: string | undefined,
  defaultValue: string[],
) => {
  const isCreateMode = contextFormData?.includes(`${ADD_KEY}-`);
  return isCreateMode ? defaultValue : [];
};
