import isEmpty from 'lodash/isEmpty';

/**
 * @name getClasses
 * @description Get custom classes for the `MuiFormControl` element.
 *
 * @param hasMultiSelect
 * @param filterValues
 *
 * @returns Classes object.
 */
export const getClasses = (hasMultiSelect: boolean | undefined, filterValues: Array<string>) => {
  const classes = [`MuiFormControl-${hasMultiSelect ? 'select' : 'autocomplete'}-filter`];

  if (isEmpty(filterValues)) {
    classes.push('MuiFormControl-error');
  }

  return classes.join(' ');
};
