export const AVAILABLE = 'available';
export const UNAVAILABLE = 'unavailable';
export const DEFAULT_KEY = '_';
export const THRESHOLD_KEY = 'threshold';
export const ABSOLUTE_CALLER_ID = 'caller-id';
export const ABSOLUTE_MODE_DEFAULT = 'default';
export const DEFAULT_REGEX = '.*';
export const MATCH_VALUE = 'match';
export const NO_MATCH_VALUE = 'nomatch';

export const AVAILABILITY_OPTIONS = [AVAILABLE, UNAVAILABLE];
export const MENU_OPTIONS = [
  DEFAULT_KEY,
  'timeout',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '*',
];
export const WAIT_TIME_OPTIONS = [THRESHOLD_KEY, DEFAULT_KEY];
export const ABSOLUTE_MODE_OPTIONS = [ABSOLUTE_CALLER_ID, ABSOLUTE_MODE_DEFAULT];
export const REGEX_MODE_OPTIONS = [MATCH_VALUE, NO_MATCH_VALUE];
