/* eslint-disable no-nested-ternary */
import truncate from 'lodash/truncate';
import { useEffect, useRef, useState } from 'react';
import Tooltip from 'shared/components/Tooltip';
import defaultProps from './default';
import { TruncatedValueProps as Props } from './definition';
import StyledTruncatedValue from './style';

const TruncatedValue = (props: Props) => {
  const { icon, maxWidth, text, truncateAfter } = { ...defaultProps, ...props };
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = textRef;

    if (!maxWidth) {
      setIsTooltipVisible(true);
    }

    if (current && maxWidth) {
      setIsTooltipVisible(current.clientWidth >= maxWidth);
    }
  }, [maxWidth]);

  return (
    <StyledTruncatedValue $isTooltipVisible={isTooltipVisible} $maxWidth={maxWidth}>
      {icon}
      {text && (
        <Tooltip title={isTooltipVisible ? text : ''}>
          <span ref={textRef}>
            {!maxWidth && text.length > truncateAfter
              ? truncate(text, { length: truncateAfter })
              : text}
          </span>
        </Tooltip>
      )}
    </StyledTruncatedValue>
  );
};

export default TruncatedValue;
