import { ForwardedRef, forwardRef } from 'react';
import { GroupBase, SelectInstance } from 'react-select';
import EnhancedSelect from 'shared/components/EnhancedSelect';
import * as utility from 'shared/utility';
import Labeled from '../..';
import defaultProps from './default';
import { LabeledSelectProps as Props } from './definition';
import StyledLabeledSelect from './style';

const LabeledSelect = forwardRef((props, ref) => {
  const {
    id,
    hasDirtyIcon,
    hasMargin,
    hasSmallMargin,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    selectProps,
    selectWidth,
    feedback,
    tooltip,
    tooltipWidth,
    isWindowed,
  } = {
    ...defaultProps,
    ...props,
  };

  const forId = id ?? utility.generateId('select');

  return (
    <Labeled
      feedback={feedback}
      hasDirtyIcon={hasDirtyIcon}
      hasMargin={hasMargin}
      hasSmallMargin={hasSmallMargin}
      id={forId}
      inputWidth={selectWidth}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={labelProps}
      labelWidth={labelWidth}
      styledComponent={StyledLabeledSelect}
      tooltip={tooltip}
      tooltipWidth={tooltipWidth}
      type="select"
    >
      <EnhancedSelect
        ref={ref}
        inputId={forId}
        isWindowed={isWindowed}
        size={selectWidth}
        {...selectProps}
      />
    </Labeled>
  );
}) as <Option, IsMulti extends boolean, GroupType extends GroupBase<Option> = GroupBase<Option>>(
  props: Props<Option, IsMulti, GroupType> & {
    ref?: ForwardedRef<SelectInstance<Option, IsMulti, GroupType>>;
  },
) => JSX.Element;

export default LabeledSelect;
