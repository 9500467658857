import Box from 'shared/components/Box';
import * as utility from 'shared/utility';
import Labeled from '../..';
import defaultProps from './default';
import { LabeledTextProps as Props } from './definition';
import StyledLabeledText from './style';

const LabeledText = (props: Props) => {
  const {
    id,
    hasDirtyIcon,
    hasMargin,
    hasNoWrap,
    hasRightMargin,
    hasSmallMargin,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    text,
    textWidth,
    feedback,
    tooltip,
    tooltipWidth,
    copyToClipboard,
  }: Props = {
    ...defaultProps,
    ...props,
  };

  const forId = id ?? utility.generateId('text');

  return (
    <Labeled
      copyToClipboard={copyToClipboard}
      feedback={feedback}
      hasDirtyIcon={hasDirtyIcon}
      hasMargin={hasMargin}
      hasNoWrap={hasNoWrap}
      hasRightMargin={hasRightMargin}
      hasSmallMargin={hasSmallMargin}
      id={forId}
      inputWidth={textWidth}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={labelProps}
      labelWidth={labelWidth}
      styledComponent={StyledLabeledText}
      tooltip={tooltip}
      tooltipWidth={tooltipWidth}
      type="text"
    >
      {typeof text === 'string' ? (
        <Box component="span" id={forId}>
          {text}
        </Box>
      ) : (
        text
      )}
    </Labeled>
  );
};

export default LabeledText;
