import useSetSelectValue from 'apps/PhoneSystem/hooks/useSetSelectValue';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import kebabCase from 'lodash/kebabCase';
import { useFetchDirectoriesQuery } from 'models/Directory';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { SelectInstance } from 'react-select';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import defaultProps from './default';
import { DirectorySelectProps as Props } from './definition';
import StyledDirectorySelect from './style';

const DirectorySelect: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasDirtyIcon,
    isLabelAbove,
    control,
    itemName,
    label,
    labelWidth,
    name,
    tooltip,
  }: Props = {
    ...defaultProps,
    ...props,
  };
  const [directories, setDirectory] = useState<Array<SelectOption>>([]);
  const { data, error, isLoading } = useFetchDirectoriesQuery();
  const selectRef = useRef<SelectInstance>(null);

  useEffect(() => {
    if (data) {
      setDirectory(data.map((directory) => ({ value: directory.id, label: directory.name })));
    }
  }, [data]);

  useSetSelectValue({ itemName, selectRef, options: directories });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledDirectorySelect>
      <HookFormSelectWrapper control={control} name={name} options={directories}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            feedback={feedback}
            isLabelAbove={isLabelAbove}
            labelWidth={labelWidth}
            label={label}
            tooltip={tooltip}
            selectProps={{
              ...formProps,
              id: `select-directory-${kebabCase(label)}`,
            }}
            hasDirtyIcon={hasDirtyIcon}
            isDirty={hasDirtyIcon && isDirty}
            ref={selectRef}
          />
        )}
      </HookFormSelectWrapper>
    </StyledDirectorySelect>
  );
};

export default DirectorySelect;
