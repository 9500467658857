import i18next from 'i18next';
import { CallFlowSection } from 'models/Callflow/definition';

const translations = () => ({
  // Determines Order of Sections
  sections: {
    [CallFlowSection.BASIC]: i18next.t('phone_system:containers.callflows.sections.basic'),
    [CallFlowSection.ADVANCED]: i18next.t('phone_system:containers.callflows.sections.advanced'),
    [CallFlowSection.TIME_OF_DAY]: i18next.t(
      'phone_system:containers.callflows.sections.time_of_day',
    ),
    [CallFlowSection.CALL_CENTER]: i18next.t(
      'phone_system:containers.callflows.sections.call_center',
    ),
    [CallFlowSection.CALL_FORWARDING]: i18next.t(
      'phone_system:containers.callflows.sections.call_forwarding',
    ),
    [CallFlowSection.CALLER_ID]: i18next.t('phone_system:containers.callflows.sections.caller_id'),
    [CallFlowSection.CALL_RECORDING]: i18next.t(
      'phone_system:containers.callflows.sections.call_recording',
    ),
    [CallFlowSection.CALL_SETTINGS]: i18next.t(
      'phone_system:containers.callflows.sections.call_settings',
    ),
    [CallFlowSection.CARRIER]: i18next.t('phone_system:containers.callflows.sections.carrier'),
    [CallFlowSection.CONFERENCE]: i18next.t(
      'phone_system:containers.callflows.sections.conference',
    ),
    [CallFlowSection.FAX]: i18next.t('phone_system:containers.callflows.sections.fax'),
  },
});

export default translations;
