import { RootState } from 'definition';
import isEmpty from 'lodash/isEmpty';
import { useFetchGroupsQuery } from 'models/Group';
import { groupQueries } from 'models/Group/Queries';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { getUsersFromEndpoints } from '../utility';
import useEndpoints from './useEndpoints';

type UsersList = Record<string, Array<string>>;

const useUsersList = () => {
  const { data: groupsData = [], isSuccess } = useFetchGroupsQuery();
  const { endpoints } = useEndpoints();
  const [usersList, setUsersList] = useState<UsersList>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const accountId = useSelector((state: RootState) => state.account.id);

  useEffect(() => {
    if ((isSuccess && isEmpty(groupsData)) || usersList) {
      setIsLoading(false);
    }
  }, [isSuccess, groupsData, usersList]);

  useEffect(() => {
    const fetchGroupEndpoints = async (id: string) => {
      const { data } = await store.dispatch(
        groupQueries.endpoints.fetchGroupById.initiate({ id, accountId }),
      );
      return { id, users: getUsersFromEndpoints(endpoints, data) };
    };

    const fetchData = async () => {
      const userPromises = groupsData.map(({ id }: Group) => fetchGroupEndpoints(id));
      const usersData = await Promise.all(userPromises);

      setUsersList(
        usersData.reduce((acc: UsersList, { id, users }: { id: string; users: Array<string> }) => {
          if (users.length > 0) {
            acc[id] = users;
          }
          return acc;
        }, {}),
      );
    };

    if (!isEmpty(groupsData) && !isEmpty(endpoints)) {
      fetchData();
    }
  }, [accountId, endpoints, groupsData]);

  return {
    isLoading,
    usersList: usersList ?? {},
  };
};

export default useUsersList;
