import { EndpointItemType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import { nameFormatter } from '../../utility';

/**
 * @name updateFields
 * @description Update the endpoint object (adding name).
 *
 * @param [object]
 * @property hasUsersExtension
 * @property data
 * @property fields
 * @property update
 *
 * @returns void
 */
export const updateFields = ({
  hasFields,
  hasUsersExtension,
  data,
  selectionFieldArray: { fields, update },
  updateFieldsCore,
}: {
  hasFields: boolean;
  hasUsersExtension: boolean;
  data: EndpointItemType;
  selectionFieldArray: any;
  updateFieldsCore?: () => void;
}): void => {
  const updateFields =
    updateFieldsCore ??
    fields.forEach((field: GroupEndpoint, i: number) => {
      const endpoint = data.find((endpoint: GroupEndpoint) => endpoint.id === field.key);
      if (endpoint) {
        update(i, {
          ...field,
          name: nameFormatter(field.endpoint_type, endpoint, hasUsersExtension),
        });
      }
    });

  if (hasFields && data) {
    updateFields?.();
  }
};
