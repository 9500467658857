import { joiResolver } from '@hookform/resolvers/joi';
import i18next from 'i18next';
import Joi from 'joi';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { messages } from 'shared/utility/validation';
import EmailClaimInput from '../../components/EmailClaimInput';
import NameIdFormat from '../../components/NameIdFormat';
import SignRequestSwitch from '../../components/SignRequestSwitch';
import SsoURLInput from '../../components/SsoUrlInput';
import UniqueIdClaimInput from '../../components/UniqueIdClaimInput';
import UseNameIdForEmailSwitch from '../../components/UseNameIdForEmailSwitch';
import UsePOSTMethodSwitch from '../../components/UsePostMethodSwitch';
import UsernameClaimInput from '../../components/UsernameClaimInput';
import X509CertificateInput from '../../components/X509CertificateInput';
import { defaultFormState } from '../../default';
import { IdpFormState } from '../../definition';
import { FormFields } from '../../fields';
import { idpSchema } from '../../schema';
import { StyledIdpForm } from '../../styles';

interface UseSetupIdpFormOptions {
  defaultValues?: Record<string, any>;
}

export const useSetupIdpForm = (options: UseSetupIdpFormOptions) => {
  const defaultValues = {
    ...defaultFormState,
    ...options.defaultValues,
  };

  const idpFormMethods = useForm<IdpFormState>({
    defaultValues,
    resolver: joiResolver(
      Joi.object(idpSchema)
        .unknown(true)
        .messages(
          messages({
            'any.invalid': i18next.t('common:validation.general.required'), // Overriding invalid error message to required field message as they are the same in this form
          }),
        ),
    ),
  });

  const isCreatingIdp = !idpFormMethods.watch(FormFields.Id);

  const FormBody = useCallback(() => {
    return (
      <FormProvider {...idpFormMethods}>
        <StyledIdpForm>
          <SsoURLInput />
          <NameIdFormat />
          <X509CertificateInput />
          <UniqueIdClaimInput />
          <UseNameIdForEmailSwitch />
          <EmailClaimInput />
          <UsernameClaimInput />
          <UsePOSTMethodSwitch />
          <SignRequestSwitch />
        </StyledIdpForm>
      </FormProvider>
    );
  }, [idpFormMethods]);

  const { formState: { isDirty, dirtyFields = {} } = {} } = idpFormMethods;

  const formMethods = {
    FormBody,
    isCreatingIdp,
    isFormDirty: isDirty && Object.keys(dirtyFields).length > 0,
    ...idpFormMethods,
  };

  return formMethods;
};
