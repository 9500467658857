import CallCenterSection, { fields as callCenterFields } from './CallCenterSection';
import CallerIdSection, { fields as callerIdFields } from './CallerIdSection';
import CallerInfoMatchSection, { fields as callerInfoMatchFields } from './CallerInfoMatchSection';
import CellularEnablementSection from './CellularEnablementSection';
import cellularEnablementFields from './CellularEnablementSection/fields';
import OptionsSection, { fields as optionsFields } from './OptionsSection';
import SsoSection from './SsoSection';
import ssoFields from './SsoSection/fields';
import TeamChatEnablementSection from './TeamChatEnablementSection';
import { teamChatEnablementFields } from './TeamChatEnablementSection/fields';

const fields = {
  callCenter: callCenterFields,
  callerId: callerIdFields,
  callerInfoMatch: callerInfoMatchFields,
  cellularEnablement: cellularEnablementFields,
  options: optionsFields,
  sso: ssoFields,
  teamChatEnablement: teamChatEnablementFields,
};

const Section = {
  CallCenter: CallCenterSection,
  CallerId: CallerIdSection,
  CallerInfoMatch: CallerInfoMatchSection,
  CellularEnablement: CellularEnablementSection,
  Options: OptionsSection,
  SSO: SsoSection,
  TeamChatEnablement: TeamChatEnablementSection,
};

export { fields, Section };
