import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import { CallForwardProps as Props } from './definition';

const CallForward = ({ numberLabel }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Device Phone Number */}
      <HookFormInputWrapper name="call_forward.number">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            hasSmallMargin
            isDirty={isDirty}
            feedback={feedback}
            inputProps={{
              ...formProps,
              placeholder: t('phone_system:shared.call_forward.number.placeholder'),
            }}
            label={numberLabel ?? t('phone_system:shared.call_forward.number.label')}
          />
        )}
      </HookFormInputWrapper>

      {/* Enabled Call Forward */}
      <HookFormInputWrapper isCheckbox name="call_forward.enabled">
        {({ ref, isDirty, ...formProps }) => (
          <LabeledCheckbox
            isDirty={isDirty}
            checkboxProps={{
              ...formProps,
            }}
            label={t('phone_system:shared.call_forward.enabled_call_forward')}
          />
        )}
      </HookFormInputWrapper>

      {/* Failover */}
      <HookFormInputWrapper isCheckbox name="call_forward.failover">
        {({ ref, isDirty, ...formProps }) => (
          <LabeledCheckbox
            hasSmallMargin
            isDirty={isDirty}
            checkboxProps={{
              ...formProps,
            }}
            label={t('phone_system:shared.call_forward.failover')}
          />
        )}
      </HookFormInputWrapper>

      {/* Bypass User's Phone */}
      <HookFormInputWrapper isCheckbox name="call_forward.substitute">
        {({ ref, isDirty, ...formProps }) => (
          <LabeledCheckbox
            isDirty={isDirty}
            checkboxProps={{
              ...formProps,
            }}
            label={t('phone_system:shared.call_forward.bypass_users_phone')}
          />
        )}
      </HookFormInputWrapper>

      {/* Direct Calls Only */}
      <HookFormInputWrapper isCheckbox name="call_forward.direct_calls_only">
        {({ ref, isDirty, ...formProps }) => (
          <LabeledCheckbox
            isDirty={isDirty}
            checkboxProps={{
              ...formProps,
            }}
            label={t('phone_system:shared.call_forward.direct_calls_only')}
          />
        )}
      </HookFormInputWrapper>

      {/* Keep Original Caller Id */}
      <HookFormInputWrapper isCheckbox name="call_forward.keep_caller_id">
        {({ ref, isDirty, ...formProps }) => (
          <LabeledCheckbox
            isDirty={isDirty}
            checkboxProps={{
              ...formProps,
            }}
            label={t('phone_system:shared.call_forward.keep_original_caller_id')}
          />
        )}
      </HookFormInputWrapper>

      {/* Require Key Press */}
      <HookFormInputWrapper isCheckbox name="call_forward.require_keypress">
        {({ ref, isDirty, ...formProps }) => (
          <LabeledCheckbox
            hasMargin
            isDirty={isDirty}
            checkboxProps={{
              ...formProps,
            }}
            label={t('phone_system:shared.call_forward.require_key_press')}
          />
        )}
      </HookFormInputWrapper>
    </>
  );
};

export default CallForward;
