import { getAccountId } from 'apps/shared/utility/account/getAccountId';
import { bizProvApi } from 'services/bizProv';
import { kazooApi, TAGS } from 'services/kazoo';
import {
  createMattermostUserDocument,
  deleteMattermostTeamDocument,
  deleteMattermostUserDocument,
  enableMattermostTeamDocument,
} from './gql/document';
import {
  CreateMattermostUserPayload,
  CreateMattermostUserResponse,
  DeleteMattermostTeamPayload,
  DeleteMattermostTeamResponse,
  DeleteMattermostUserPayload,
  DeleteMattermostUserResponse,
  EnableMattermostTeamPayload,
  EnableMattermostTeamResponse,
} from './types';

export const mattermostMutations = bizProvApi.injectEndpoints({
  endpoints: (builder) => ({
    enableMattermostTeam: builder.mutation<
      EnableMattermostTeamResponse,
      EnableMattermostTeamPayload
    >({
      query: ({ accountId } = {}) => ({
        document: enableMattermostTeamDocument,
        variables: {
          team: {
            accountId: accountId ?? getAccountId(),
            type: 'I', // This is a hardcoded value means "Invitation" that the endpoint required
          },
          accountId: accountId ?? getAccountId(),
        },
      }),
      onQueryStarted: async ({ accountId } = {}, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          // `onSuccess` side-effect
          // Invalidate account data cache
          dispatch(
            kazooApi.util.invalidateTags([
              { type: TAGS.ACCOUNT, id: accountId ?? getAccountId() },
              { type: TAGS.ACCOUNT },
            ]),
          );
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    createMattermostUser: builder.mutation<
      CreateMattermostUserResponse,
      CreateMattermostUserPayload
    >({
      query: ({ userId }) => ({
        document: createMattermostUserDocument,
        variables: {
          user: {
            userId,
          },
          accountId: getAccountId(),
        },
      }),
    }),
    deleteMattermostUser: builder.mutation<
      DeleteMattermostUserResponse,
      DeleteMattermostUserPayload
    >({
      query: ({ mattermostUserId }) => ({
        document: deleteMattermostUserDocument,
        variables: {
          user: {
            id: mattermostUserId,
          },
          accountId: getAccountId(),
        },
      }),
    }),
    deleteMattermostTeam: builder.mutation<
      DeleteMattermostTeamResponse,
      DeleteMattermostTeamPayload
    >({
      query: ({ accountId } = {}) => ({
        document: deleteMattermostTeamDocument,
        variables: {
          accountId: accountId ?? getAccountId(),
        },
      }),
      onQueryStarted: async ({ accountId } = {}, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          // `onSuccess` side-effect
          // Invalidate account data cache
          dispatch(
            kazooApi.util.invalidateTags([
              { type: TAGS.ACCOUNT, id: accountId ?? getAccountId() },
              { type: TAGS.ACCOUNT },
            ]),
          );
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
});
