import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { LabeledInput } from 'shared/components/Labeled';
import { InputProps } from '../definition';
import { InheritTooltipWrapper } from '../InheritTooltipWrapper';

export const TextInput = ({
  fieldKey,
  template: { text, tooltip, type, value, isInheritFromAccount },
}: InputProps): JSX.Element => {
  return (
    <HookFormInputWrapper name={fieldKey}>
      {({ ref, isDirty, feedback, ...formProps }) => (
        <InheritTooltipWrapper
          inheritValue={value}
          inputType={type}
          isInheritFromAccount={isInheritFromAccount}
          tooltip={tooltip}
          fieldValue={formProps.value}
        >
          {(tooltip) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              label={text}
              tooltip={tooltip}
              inputProps={{
                ...formProps,
                type,
                placeholder: text,
              }}
            />
          )}
        </InheritTooltipWrapper>
      )}
    </HookFormInputWrapper>
  );
};
