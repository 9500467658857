import * as mixin from 'shared/utility/mixin';
import { css, FlattenSimpleInterpolation } from 'styled-components';

export const style = ({
  $isInHeader,
  $isInline,
  theme,
}: any): Record<string, FlattenSimpleInterpolation> => ({
  default: css`
    ${mixin.flex($isInline ? { isInline: true } : { justifyContent: 'flex-end' })};

    ${$isInline
      ? css`
          margin: ${theme.spacing(0, 0, 0, $isInHeader ? 1 : 2)};
        `
      : css`
          width: 32px;
        `}
  `,
  svg: css`
    ${!$isInline &&
    css`
      transform: translateY(${theme.spacing(1.5)});
    `};
  `,
});
