// Account slice
import { createSlice } from '@reduxjs/toolkit';
import { getAccountId } from 'apps/shared/utility/account/getAccountId';
import { RootState } from 'definition';

export const initialState = () => ({
  id: getAccountId(),
});

export const accountSlice = createSlice({
  name: 'account',
  initialState: initialState(),
  reducers: {
    updateAccountId: (state, { payload }) => ({
      ...initialState,
      id: payload.id,
    }),
    resetAccountId: () => initialState(),
  },
});

export const { updateAccountId, resetAccountId } = accountSlice.actions;

export const selectAccountId = (state: RootState) => state.account.id;
