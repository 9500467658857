import { FunctionComponent } from 'react';
import { InfoIcon } from 'shared/components/Labeled';
import TEST_ID from 'shared/utility/testing/constants/testId';
import defaultProps from './default';
import { NavItemProps as Props } from './definition';
import StyledNavItem from './style';

export type { Item } from './definition';
export { CONSTANTS as NAV_ITEM_CONSTANTS } from './style';

const NavItem: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isActive, info, label, slug, onClick } = { ...defaultProps, ...props };

  const onNavItemClick = () => onClick(slug);

  return (
    <StyledNavItem
      isActive={isActive}
      onClick={onNavItemClick}
      data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-horizontal-nav-nav-item-${slug}`}
    >
      <div>
        <span>{label}</span>
        {info && <InfoIcon isInline isOutlined tooltip={info} />}
      </div>
      <div />
    </StyledNavItem>
  );
};

export default NavItem;
