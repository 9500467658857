import MuiCard from '@mui/material/Card';
import { FunctionComponent } from 'react';
import CardActions from './components/CardActions';
import CardContent from './components/CardContent';
import CardHeader from './components/CardHeader';
import { CardProps as Props } from './definition';

export type { CardProps } from './definition';

const Card: FunctionComponent<Props> = ({
  cardProps,
  actionsProps,
  contentProps,
  headerProps,
  actions,
  content,
}: Props): JSX.Element => (
  <MuiCard {...cardProps}>
    {headerProps && <CardHeader {...headerProps} />}
    <CardContent {...contentProps}>{content}</CardContent>
    {actions && <CardActions {...actionsProps}>{actions}</CardActions>}
  </MuiCard>
);

export default Card;
