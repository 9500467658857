import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export default styled(Box)`
  ${({ theme }) => css`
    ${mixin.flex({ wrap: 'nowrap' })};

    > span {
      &:first-of-type {
        text-transform: capitalize;
      }

      &:nth-of-type(2) {
        margin: ${theme.spacing(0, 1)};
      }

      &:last-of-type {
        text-transform: uppercase;
      }
    }
  `};
`;
