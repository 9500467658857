import { RowArray, SetRowArray } from 'apps/shared/definition';
import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Trans } from 'react-i18next';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import { Justify } from 'shared/components/Table';
import { maybeTooltipActionRowButton } from 'shared/hooks/useActionRow/utility';

/**
 * @name buildActionRowButtons
 * @description Build the ActionRow buttons (element)
 *
 * @param [object]
 * @property selectedRows
 * @property setConfirmData
 * @property setIsAssignDialogOpen
 * @property setIsUnassignDialogOpen
 *
 * @returns ActionRow buttons element
 */
export const buildActionRowButtons = ({
  selectedRows,
  setConfirmData,
  setIsAssignDialogOpen,
  setIsUnassignDialogOpen,
}: {
  selectedRows: RowArray;
  setConfirmData: SetRowArray;
  setIsUnassignDialogOpen: (value: boolean) => void;
  setIsAssignDialogOpen: (isOpen: boolean) => void;
}): JSX.Element => {
  const hasSelectedRows = selectedRows.length > 0;

  return (
    <>
      <Button
        color="primary"
        disabled={false}
        startIcon={<Icon name="plus-circle-outlined" size={18} />}
        variant="outlined"
        onClick={() => setIsAssignDialogOpen(true)}
      >
        {i18next.t(
          'call_center:containers.manage_queues.components.agents_list.action_row.assign.label',
        )}
      </Button>
      {maybeTooltipActionRowButton({
        hasSelectedRows,
        component: (
          <Button
            color="error"
            disabled={!hasSelectedRows}
            startIcon={<Icon name="minus-circle-outlined" size={18} />}
            variant="outlined"
            onClick={() => {
              setConfirmData(selectedRows);
              setIsUnassignDialogOpen(true);
            }}
          >
            {i18next.t(
              'call_center:containers.manage_queues.components.agents_list.action_row.unassign.label',
            )}
          </Button>
        ),
        tooltip: i18next.t(
          'call_center:containers.manage_queues.components.agents_list.action_row.unassign.tooltip',
        ),
      })}
    </>
  );
};

/**
 * @name columns
 * @description Get the list of columns for the agents table
 *
 * @returns List of columns
 */
export const columns = () => [
  {
    Header: i18next.t(
      'call_center:containers.manage_queues.components.agents_list.table.column.name',
    ) as string,
    accessor: 'name',
    width: 400,
  },
  {
    Header: i18next.t(
      'call_center:containers.manage_queues.components.agents_list.table.column.seat',
    ) as string,
    accessor: 'seat',
  },
];

/**
 * @name getAgentSeat
 * @description Retrieves the agent seat for a given agent ID
 *
 * @param agentId - The agent ID
 * @param usersData - The users data
 *
 * @returns - The agent seat
 */
export const getAgentSeat = (agentId: string, usersData: any) =>
  usersData?.find((user: any) => user.id === agentId) ?? '';

/**
 * @name getDialogProps
 * @description Get the unassign numbers dialog props
 *
 * @param [object]
 * @property isUnassignDialogOpen
 * @property confirmData
 * @property queueName
 * @property on
 * @property setConfirmData
 *
 * @returns Unassign numbers dialog props
 */
export const getDialogProps = ({
  isUnassignDialogOpen,
  confirmData,
  queueName,
  on,
  setConfirmData,
}: {
  isUnassignDialogOpen: boolean;
  confirmData: RowArray;
  queueName: string;
  on: Record<string, (parameter: any) => void>;
  setConfirmData: SetRowArray;
}) => ({
  isDelete: isUnassignDialogOpen,
  isOpen: !isEmpty(confirmData),
  confirmData,
  dialogMessage: (
    <Box component="span">
      <Trans
        i18nKey="call_center:containers.manage_queues.components.agents_list.dialog.unassign.prompt.0"
        values={{
          count: confirmData.length,
        }}
      />
    </Box>
  ),
  dialogTitle: i18next.t(
    'call_center:containers.manage_queues.components.agents_list.dialog.unassign.title',
  ),
  header: {
    text: (
      <Trans
        i18nKey="common:component.confirm_selection_dialog.from"
        components={{ bold: <strong /> }}
        values={{ name: queueName }}
      />
    ),
  },
  confirmButtonProps: {
    color: 'error',
    label: i18next.t(
      'call_center:containers.manage_queues.components.agents_list.dialog.unassign.confirm',
    ),
    variant: 'contained',
  },
  customColumns: [
    {
      Header: i18next.t('common:number') as string,
      accessor: (row: any) => `${row.seat}, ${row.name}`,
      id: `confirm_selection_dialog.number.${Justify.Left}`,
    },
  ],
  onConfirm: on.unassign,
  setConfirmData,
});

/**
 * @name getQueueName
 * @description Retrieves the queue name for a given queue ID
 *
 * @param queueId - The queue ID
 * @param queuesData - The queues data
 *
 * @returns - The queue name
 */
export const getQueueName = (id: string, queuesData: Array<Queue>) =>
  queuesData?.find((queue: Queue) => queue.id === id)?.name ?? '';

/**
 * @name prepareTableData
 * @description Prepares the data for the table
 *
 * @param agentsData - The agents data
 * @param usersData - The users data
 * @returns - The prepared data for the table
 */
export const prepareTableData = (queueAgentsData: Array<string>, usersData: any) =>
  queueAgentsData.map((agentId) => {
    const user = usersData?.find((user: any) => user.id === agentId);

    return user
      ? {
          name: `${user.first_name} ${user.last_name}`,
          seat: user.username,
          id: agentId,
        }
      : {};
  });
