import i18next from 'i18next';
import { Column } from 'shared/components/Table';

/**
 * @name prepareTableData
 * @description Prepares the data for the table
 *
 * @param usersData - The users data
 * @returns - The prepared data for the table
 */
export const prepareTableData = (users: User[]) =>
  users
    .filter((user: User) => user.priv_level !== 'admin')
    .map((user: User) => ({
      name: `${user.first_name} ${user.last_name}, ${user.username}`,
      id: user.id,
    }));

/**
 * @name columns
 * @description Prepares the list of columns for the users table
 *
 * @returns - The list of columns
 */
export const columns = (): Array<Column> => [
  {
    Header: i18next.t(
      'numbers:containers.numbers_in_use.section.sms_enablement.create_sms_box.dialog.table.column.name',
    ) as string,
    accessor: 'name',
  },
];
