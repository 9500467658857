import { SESSION_STORAGE } from 'constant';
import { OptionsType } from 'shared/components/Select/definition';

/**
 * @name getRowsPerPages
 * @description Retrieves the rows per page for a table, prioritizing the value stored in sessionStorage,
 *              then the provided `pageSize`, and finally falling back to the default option
 *
 * @param pageSize - The specified page size
 * @param defaultOption - The default option for the Select component
 * @returns - An object representing the selected page size
 */
export const getRowsPerPages = (pageSize: number, defaultOption: OptionsType) => {
  const sessionPaginationValue = sessionStorage.getItem(SESSION_STORAGE.TABLE.PAGINATION.SIZE);

  if (sessionPaginationValue) {
    return { label: sessionPaginationValue, value: sessionPaginationValue };
  }
  if (pageSize) {
    return { label: pageSize.toString(), value: pageSize.toString() };
  }
  return defaultOption[0];
};
