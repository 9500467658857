import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { TABS_CONSTANTS } from 'theme/mui/components';
import { CONSTANTS } from '../constants';
import { StyledTabPanelsProps as Props } from './definition';

const StyledTabPanels = styled(Box)<Props>`
  ${({ $hasFormActionRow, theme }) => css`
    ${mixin.borderRadius({ radius: 'half' })};
    width: calc(100% - ${CONSTANTS.WIDTH});
    --height: ${$hasFormActionRow ? `calc(100% - ${TABS_CONSTANTS.HEIGHT.TAB}px)` : '100%'};
    height: var(--height);
    min-height: var(--height);
    padding: ${theme.spacing(0, 5)};
    background-color: ${theme.core.background.grey.light.color};
    border: 1px solid ${theme.core.border.color};
    overflow: auto;
    transform: translateX(-1px);
  `}
`;

export default StyledTabPanels;
