import { RowArray } from 'apps/shared/definition';
import { useFetchUsersQuery } from 'models/User';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import Table, { SelectionPosition, SelectionType } from 'shared/components/Table';
import EmptySmsView from './components/EmptySmsView';
import SmsOwnerUser from './components/SmsOwnerUser';
import { columns, prepareTableData } from './utility';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['sms_owner', 'sms_owner_id'];

const SmsEnablementSection = () => {
  const { t } = useTranslation();
  const { getValues, setValue, watch } = useFormContext();
  const [selectedRows, setSelectedRows] = useState<RowArray>([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const { data: usersData } = useFetchUsersQuery();

  const tableData = useMemo(() => (usersData ? prepareTableData(usersData) : []), [usersData]);
  const watchSmsOwner = watch('sms_owner');

  const handleCreateDialogOpen = () => setIsCreateDialogOpen(true);
  const handleCreateDialogClose = () => setIsCreateDialogOpen(false);

  const handleCreateDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        handleCreateDialogClose();
        break;
      case 'saveClicked':
        handleCreateDialogClose();
        setValue('sms_owner', selectedRows[0]?.name, { shouldDirty: true });
        setValue('sms_owner_id', selectedRows[0]?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {watchSmsOwner ? (
        <SmsOwnerUser />
      ) : (
        <EmptySmsView handleCreateDialogOpen={handleCreateDialogOpen} />
      )}
      <Dialog
        open={isCreateDialogOpen}
        title={t(
          'numbers:containers.numbers_in_use.section.sms_enablement.create_sms_box.dialog.title',
        )}
        onClose={handleCreateDialogClose}
        renderActions={
          <DialogActions
            saveButtonProps={{
              label: t(
                'numbers:containers.numbers_in_use.section.sms_enablement.create_sms_box.dialog.action.label',
              ),
              variant: 'contained',
              disabled: !selectedRows.length,
            }}
            onAction={handleCreateDialogAction}
          />
        }
      >
        <p>
          <Trans
            i18nKey="numbers:containers.numbers_in_use.section.sms_enablement.create_sms_box.prompt"
            values={{
              phone_number: getValues('id'),
            }}
            components={{ bold: <strong /> }}
          />
        </p>
        <Table
          hasActionRowMargin
          hasBorderRadius
          hasOuterBorder
          hasSearch
          hasActionRowBorder={false}
          hasActionRowPadding={{
            side: false,
          }}
          hasEditIcon={false}
          hasPaginationPadding={{
            side: false,
          }}
          columns={columns()}
          data={tableData}
          selection={{
            hasBorder: true,
            position: SelectionPosition.Right,
            type: SelectionType.Single,
            setRows: setSelectedRows,
          }}
        />
      </Dialog>
    </>
  );
};

export default SmsEnablementSection;
