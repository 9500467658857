import { getGridColumnWidth } from 'apps/shared/utility/style';
import styled, { css } from 'styled-components';
import { StyledActionSectionProps as Props } from './definition';

// Configurables
const NUM_OF_COLUMNS: number = 2;
const GRID_GAP: string = '2px';

const StyledSection = styled.div<Props>`
  ${(props) => css`
    ${props.isHidden &&
    css`
      display: none;
    `};

    > div > div {
      display: grid;
      grid-template: 1fr / repeat(
          ${NUM_OF_COLUMNS},
          ${getGridColumnWidth(NUM_OF_COLUMNS, GRID_GAP)}
        );
      grid-gap: ${GRID_GAP};
      margin: 2px 0 0;
    }
  `};
`;

export default StyledSection;
