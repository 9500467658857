/**
 * @name getPageCount
 * @description Get the total number of pages
 *
 * @param {number} dataSize
 * @param {number} pageSize
 *
 * @returns {number}
 */
export const getPageCount = (dataSize: number, pageSize: number): number =>
  Math.ceil(dataSize / pageSize) ?? 0;
