import { FunctionComponent } from 'react';
import Icon, { FlippedType } from 'shared/components/Icon';
import { IconProps as Props } from './definition';

const InteractionBarIcon: FunctionComponent<Props> = ({ name, isFlipped }: Props): JSX.Element => (
  <span className="component-action-icon">
    <Icon name={name} isFlipped={{ [FlippedType.Horizontal]: isFlipped as boolean }} />
  </span>
);

export default InteractionBarIcon;
