import { getLabel } from 'apps/PhoneSystem/containers/Groups/shared/utility';
import { EndpointType } from 'apps/PhoneSystem/definition';
import i18next from 'i18next';
import { EditableListData, EditableListJustify } from 'shared/components/EditableList';
import { SelectOption } from 'shared/components/Select';
import { WidthType } from 'shared/utility/definition';

const assignIcon = (type: EndpointType): string =>
  type === EndpointType.Device ? 'desk-phone' : 'person-filled';

const assignLabel = (type: EndpointType, endpoints: any, id: string): string => {
  switch (type) {
    case EndpointType.Device: {
      const device = endpoints.devices.data?.find((device: any) => device.id === id);
      return device && getLabel({ type: EndpointType.Device, item: device });
    }
    case EndpointType.User:
    default: {
      const user = endpoints.users.data?.find((user: any) => user.id === id);
      return user && getLabel({ type: EndpointType.User, item: user });
    }
  }
};

const getEndpointType = (endpoints: any, id: string): EndpointType | undefined => {
  if (endpoints.devices.data?.find((device: any) => device.id === id)) {
    return EndpointType.Device;
  }
  if (endpoints.users.data?.find((user: any) => user.id === id)) {
    return EndpointType.User;
  }
  return undefined;
};

export const convertToEndpointType = (items: SelectOption[][], endpoints: any) =>
  items.reduce((acc, item, i) => {
    const endpoint: Record<string, { type: EndpointType | undefined; weight: number }> = {};
    const { value: id } = item[0];
    endpoint[id] = {
      type: getEndpointType(endpoints, id),
      weight: i + 1,
    };
    return { ...acc, ...endpoint };
  }, {});

export const getListData = ({
  data,
  endpoints,
  endpointType,
  setEndpointType,
}: {
  data: any;
  endpoints: any;
  endpointType: EndpointType;
  setEndpointType: any;
}): EditableListData => ({
  columns: [
    {
      header: {
        text: i18next.t(
          'phone_system:containers.groups.section.options.field.endpoints_list.endpoints.label',
        ),
        justify: EditableListJustify.Left,
      },
      select: [
        {
          options: [
            {
              label: i18next.t(
                'phone_system:containers.groups.section.options.field.endpoints_list.options.seats.label',
              ),
              value: EndpointType.User,
            },
            {
              label: i18next.t(
                'phone_system:containers.groups.section.options.field.endpoints_list.options.devices.label',
              ),
              value: EndpointType.Device,
            },
          ],
          size: 'medium' as WidthType,
          get value() {
            return this.options?.find((option: SelectOption) => option.value === endpointType);
          },
          onChange: (endpointType: EndpointType) => setEndpointType(endpointType),
        },
        {
          isFiltered: true,
          options: (() => {
            let options = [];

            // filter admins / return option with friendly/sort labels
            if (endpointType === EndpointType.User && endpoints.users.isSuccess) {
              options = endpoints.users.data
                ?.filter((item: any) => item.priv_level === 'user')
                .map((item: any) => ({
                  value: item.id,
                  label: getLabel({ type: EndpointType.User, item }),
                  sort: getLabel({ type: EndpointType.User, item, isSort: true }),
                }));
            }

            // return option with friendly/sort labels
            if (endpointType === EndpointType.Device && endpoints.devices.isSuccess) {
              options = endpoints.devices.data?.map((item: any) => ({
                value: item.id,
                label: getLabel({ type: EndpointType.Device, item }),
                sort: getLabel({ type: EndpointType.Device, item, isSort: true }),
              }));
            }

            return options;
          })(),
          size: 'xlarge' as WidthType,
        },
      ],
      width: 575,
    },
  ],
  rows: (Object.keys(data) || [])
    .map((key) => [
      {
        icon: assignIcon(data[key].type),
        label: assignLabel(data[key].type, endpoints, key),
        value: key,
      },
    ])
    .filter(([item]: SelectOption[]) => !!item.label),
});
