import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

const CONSTANTS = {
  HEIGHT: {
    ACTION_ROW: '76px',
  },
};

const StyledDashboardAgents = styled(Box)`
  ${({ theme }) => css`
    display: grid;
    grid-template: ${CONSTANTS.HEIGHT.ACTION_ROW} 1fr / 1fr;

    > div {
      &:first-of-type {
        ${mixin.flex({ justifyContent: 'space-between' })};
        padding: ${theme.spacing(2, 2.66)};

        > div {
          h3,
          p {
            user-select: none;
            white-space: nowrap;
          }

          h3 {
            font-size: 20px;
            line-height: 24px;
          }

          p {
            font-size: 14px;
          }
        }
      }

      &:last-of-type {
        ${mixin.flex({
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: theme.spacing(2.66),
          wrap: 'wrap',
        })};
        overflow-y: auto;
      }
    }
  `};
`;

export default StyledDashboardAgents;
