import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import StyledLabeled from '../../style';
import { StyledLabeledRadioGroupProps as Props } from './definition';

const StyledLabeledRadioGroup = styled(StyledLabeled)<Props>`
  ${(props) => css`
    margin: 0 0 12px;

    > div {
      &:first-of-type {
        ${mixin.flex({ direction: 'column', alignItems: 'flex-end' })};

        --height: 20px;

        > div {
          ${mixin.flex({ justifyContent: 'flex-end' })};
        }

        ${props.isLabelAbove
          ? css`
              justify-content: flex-start;
              flex-direction: row;
              margin: 0 0 8px;

              > label {
                margin: 0;

                span {
                  display: block;
                  height: var(--height);
                }
              }

              > div {
                justify-content: flex-start;
                width: auto;
                height: var(--height);
              }
            `
          : css`
              > div {
                margin: 12px 36px 0 0;
              }
            `}
      }
    }

    > div > div:last-of-type:not(:first-of-type) {
      margin: 14px 0 0;
    }
  `}
`;

export default StyledLabeledRadioGroup;
