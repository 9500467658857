import { LOCAL_STORAGE, URL_PARAM_MASQUERADING_ACCOUNT_KEY } from 'constant';
import { invalidateCacheUtilities } from 'models';
import { updateAccountId } from 'models/Account/slice';
import { Dispatch } from 'react';
import { getMasqueradingAccountId } from './getMasqueradingAccountId';

/**
 * Append account ID to current URL (as the masquerading account)
 *
 * @param {string} id Account ID
 *
 * @return {void}
 */
export const setMasqueradingAccountParam = (id: string): void => {
  const authAccountId = localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID);
  const currentQueryParamsObj = Object.fromEntries(
    new global.URLSearchParams(global.location.search),
  );
  const hasDifferentIds = id !== authAccountId;

  const getQueryParams = () => {
    let newQueryObj = {};
    if (hasDifferentIds) {
      newQueryObj = {
        ...currentQueryParamsObj,
        ...(hasDifferentIds && { [URL_PARAM_MASQUERADING_ACCOUNT_KEY]: id }),
      };
    } else {
      delete currentQueryParamsObj[URL_PARAM_MASQUERADING_ACCOUNT_KEY];
      newQueryObj = currentQueryParamsObj;
    }

    return `${Object.keys(newQueryObj).length > 0 ? '?' : ''}${new global.URLSearchParams(
      newQueryObj,
    ).toString()}`;
  };

  global.history.replaceState(null, '', `${window.location.pathname}${getQueryParams()}`);
};

export const setCurrentAccount = (dispatch: Dispatch<any>, id: string): void => {
  dispatch(updateAccountId({ id }));
  setMasqueradingAccountParam(id);

  Object.values(invalidateCacheUtilities).forEach((invalidate) => dispatch(invalidate));
};

/**
 * • If a masquerading account param is present on the URL and that (masquerading)
 *   account ID does not match the current account, update the current account
 * • If a masquerading account param is not present on the URL, and the authentication
 *   account ID does not match the current account, set the current account as the
 *   masquerading account (setting a param in the URL)
 *
 * @param {any} dispatch Reference to the Redux dispatch function
 * @param {string} id Account ID
 *
 * @return {void}
 */
export const checkMasqueradingAccountParam = (dispatch: Dispatch<any>, id: string): void => {
  const masqueradingAccountId = getMasqueradingAccountId();

  if (masqueradingAccountId) {
    if (id !== masqueradingAccountId) {
      dispatch(updateAccountId({ id: masqueradingAccountId }));
    }
  } else {
    setMasqueradingAccountParam(id);
  }
};
