import { useSearchAccountQuery } from 'models/Account';
import { FunctionComponent, useEffect, useState } from 'react';
import AccountList from '../AccountList';
import { handleDepth, maxLevel } from '../AccountListLocal/index';
import defaultProps from './default';
import { AccountListRemoteProps as Props, listDepth } from './definition';

const AccountListRemote: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { search, onClick }: Props = { ...defaultProps, ...props };
  const { data } = useSearchAccountQuery({ search });
  const [filtered, setFiltered] = useState<any[]>([]);
  const [listOpenDepth, setListOpenDepth] = useState<listDepth>({});
  const [showDescendants, setShowDescendants] = useState<any>({});

  useEffect(() => {
    data &&
      setFiltered(
        [...data.id, ...data.name, ...data.realm]
          .filter(
            (acc1: any, i: number, arr: any[]) =>
              arr.findIndex((acc2: any) => acc2.id === acc1.id) === i,
          )
          .sort((acc1: any, acc2: any) => (acc1.name > acc2.name ? 1 : -1)),
      );
  }, [data, search]);

  const onItemClick = (item: any) => onClick(item.id, item.name);

  return (
    <AccountList
      data={filtered}
      onClick={onItemClick}
      isRoot
      listOpenDepth={listOpenDepth || {}}
      maxLevel={() => maxLevel(listOpenDepth)}
      setListOpenDepth={(id, currentLevel, currentId) => {
        setListOpenDepth({
          ...listOpenDepth,
          [id]: handleDepth(id, currentLevel, currentId, listOpenDepth, showDescendants),
        });
      }}
      showDescendants={showDescendants || {}}
      setShowDescendants={(id) =>
        setShowDescendants({
          ...showDescendants,
          [id]: !showDescendants[id] || showDescendants[id] === 'closed' ? 'open' : 'closed',
        })
      }
    />
  );
};

export default AccountListRemote;
