import * as utility from 'shared/utility';
import * as mixin from 'shared/utility/mixin';
import { css } from 'styled-components';
import { SelectionPosition, SelectionType } from '../../components/definition';
import { StyledTableProps as Props } from '../../definition';
import { CONSTANTS } from '../constants';
import { justify } from './utility';

const style = {
  link: (color: string) => css`
    color: ${color};
    border-color: transparent;
    border-bottom-color: ${utility.alphafy(color, 0.4)};

    &:hover {
      border-bottom-color: ${color};
    }
  `,
};

export const core = ({
  hasBorderRadius,
  hasCellPadding,
  hasOuterBorder,
  selection,
  theme,
}: Props) => css`
  table {
    width: 100%;
    border-spacing: 0;

    ${hasOuterBorder &&
    css`
      margin: 0;
      border: ${CONSTANTS.BORDER};

      ${hasBorderRadius &&
      css`
        ${mixin.borderRadius()};
      `};
    `};

    tbody,
    tfoot,
    thead {
      width: 100%;

      tr {
        background-color: transparent;

        td,
        th {
          border-bottom: ${CONSTANTS.BORDER};
          white-space: nowrap;

          &.cell-action {
            width: ${CONSTANTS.WIDTH.ICON.ACTION};
            max-width: ${CONSTANTS.WIDTH.ICON.ACTION};
            min-width: calc(${CONSTANTS.WIDTH.ICON.ACTION} - 30px);
            padding: 0;
            text-align: center;
          }

          ${selection &&
          selection.type !== SelectionType.None &&
          css`
            ${`&:${selection.position === SelectionPosition.Left ? 'first' : 'last'}-of-type`} {
              width: 80px; // TODO: Add constant
            }
          `};
        }

        td {
          ${mixin.transition({ property: 'background-color', duration: 'medium' })};
          height: ${CONSTANTS.HEIGHT.CELL.TBODY};
          padding: ${hasCellPadding ? `0 ${CONSTANTS.PADDING.SORT}` : 0};
          word-break: break-all;

          &.cell-action > div {
            height: calc(${CONSTANTS.HEIGHT.CELL.TBODY} - 1px);
            margin: 0;
            transform: translateY(-1px);
            justify-content: center;
          }

          &.has-wrap {
            white-space: normal;
          }

          a:not(.icon) {
            ${mixin.transition({ property: 'border-bottom-color', duration: 'medium' })};
            text-decoration: none;
            border: ${CONSTANTS.BORDER};
            ${style.link(theme.core.color.blue.default)};

            &.error {
              ${style.link(theme.core.states.error.main)};
              font-style: italic;
            }
          }

          .hover-opacity > * {
            ${mixin.transition({ property: 'opacity', duration: 'medium' })};
            opacity: 0;
          }
        }

        th {
          padding: 0 ${CONSTANTS.PADDING.SORT};
          font-weight: 600;
          text-transform: uppercase;
          user-select: none;

          > div {
            ${justify({ isFlex: true, isHeader: true })};
            ${mixin.flex({ justifyContent: 'flex-start' })};
            min-height: ${CONSTANTS.HEIGHT.CELL.THEAD};

            > span {
              &:first-of-type:not(:last-of-type) {
                ${mixin.flex()};
                ${mixin.transition({ property: 'opacity' })};
                opacity: 0;

                &.has-sort {
                  opacity: 1;
                }

                .sort {
                  &-asc,
                  &-desc {
                    margin: ${theme.spacing(0, 1.66, 0, -4)};
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 7px solid black;
                  }

                  &-desc {
                    ${mixin.transition({ property: 'transform', duration: 'medium' })};
                    transform: rotate(180deg);
                  }
                }
              }

              &:last-of-type:not(:first-of-type) {
                line-height: 1em;
              }
            }
          }

          .emphasis {
            color: ${theme.whitelabel.primary.color};
          }
        }
      }
    }

    tbody tr {
      ${hasOuterBorder &&
      css`
        &:last-of-type td {
          border-bottom: 0;
        }
      `}

      &:hover td {
        background-color: ${theme.core.background.grey.medium};

        .hover-opacity > * {
          opacity: 1;
        }
      }

      td {
        ${justify()};
        ${mixin.transition({ property: 'background-color', duration: 'medium' })};
        line-height: calc(${CONSTANTS.HEIGHT.CELL.TBODY} - 1px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a.icon {
          display: inline-block;
        }

        a.icon,
        > svg {
          position: relative;
          top: ${theme.spacing(1)};
        }

        input {
          height: ${CONSTANTS.HEIGHT.INPUT};
        }

        ${selection &&
        selection.hasBorder &&
        selection.type !== SelectionType.None &&
        css`
          ${`&:${selection.position === SelectionPosition.Left ? 'first' : 'last'}-of-type`} {
            ${selection.position === SelectionPosition.Left &&
            css`
              border-right: ${CONSTANTS.BORDER};
            `}
            ${selection.position === SelectionPosition.Right &&
            css`
              border-left: ${CONSTANTS.BORDER};
            `}
          }
        `}
      }
    }

    tfoot tr td {
      ${mixin.font({ style: 'italic' })};
      height: ${CONSTANTS.HEIGHT.CELL.THEAD};
      line-height: ${CONSTANTS.HEIGHT.CELL.THEAD};
      color: ${theme.core.background.grey.dark};

      .underline {
        text-decoration: underline;
      }
    }

    thead tr th {
      min-height: ${CONSTANTS.HEIGHT.CELL.THEAD};
      line-height: ${CONSTANTS.HEIGHT.CELL.THEAD};

      > div > span:last-of-type {
        text-align: left;
      }
    }
  }
`;
