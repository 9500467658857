import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledAccountInfoProps as Props } from './definition';

const StyledAccountInfo = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex()};

    > svg {
      margin: 0 10px 0 4px;
    }

    > span {
      line-height: 1.25em;

      svg {
        margin: 0 2px 0 5px;
        transform: translateY(2px);
      }
    }
  `}
`;

export default StyledAccountInfo;
