import SummaryNumbers from 'apps/shared/components/AddNumberFlow/components/SummaryNumbers';
import { SummaryList } from 'apps/shared/components/AddNumberFlow/definition';
import BuyNumberButton from 'apps/shared/components/BuyNumbers/components/BuyNumberButton';
import BuyNumberFlow from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow';
import { FlowResult } from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow/components/WaitForServer/definition';
import { BuyNumberFlowType } from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow/definition';
import { triggerDownload } from 'apps/shared/utility';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button';
import { defaultProps } from './default';
import { BuyNumbersProps as Props } from './definition';
import { getDownloadValues } from './utility';

export const BuyNumbers = (props: Props) => {
  const { hasMargin, buttonVariant, origin, flowOnCompleteRoute, onFlowComplete, setOrigin } = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [flowType, setFlowType] = useState<BuyNumberFlowType>(BuyNumberFlowType.Default);
  const [importedList, setImportedList] = useState<SummaryList>({
    success: [],
    error: [],
  });

  const showResult: FlowResult = ({ success, error }) => {
    if (success.length || error.length) {
      setImportedList({
        success,
        error,
      });
    }
  };

  const onClick = async () => {
    triggerDownload(getDownloadValues(importedList));
  };

  const downloadButton = (
    <Button color="secondary" variant="outlined" onClick={onClick}>
      {t('numbers:action_row.add_number.dialog.summary_numbers.download.button.label')}
    </Button>
  );

  return (
    <>
      <BuyNumberButton
        hasMargin={hasMargin}
        setFlowType={setFlowType}
        setOrigin={setOrigin}
        variant={buttonVariant}
      />
      {flowType !== BuyNumberFlowType.Default && (
        <BuyNumberFlow
          hasNoRedirectOnCancel
          hasNoRedirectOnComplete
          flowOnCompleteRoute={flowOnCompleteRoute}
          flowType={flowType}
          origin={origin}
          setFlowType={setFlowType}
          onFlowComplete={(result) => {
            showResult(result);
            if (onFlowComplete) {
              onFlowComplete(result);
            } else if (flowOnCompleteRoute) {
              navigate(flowOnCompleteRoute);
            }
          }}
        />
      )}
      <SummaryNumbers
        description={t('numbers:action_row.add_number.dialog.summary_numbers.description')}
        importedList={importedList}
        isOpen={importedList.success.length > 0 || importedList.error.length > 0}
        leftButtons={downloadButton}
        title={t('numbers:action_row.add_number.dialog.summary_numbers.title')}
        onClose={() => {
          setImportedList({ success: [], error: [] });
        }}
      />
    </>
  );
};
