import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import Box from 'shared/components/Box';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled from 'styled-components';
import { TABS_CONSTANTS } from 'theme/mui/components';

export const StyledTable = styled(Box)`
  height: calc(
    100vh -
      (
        ${HEADER_CONSTANTS.HEIGHT} + ${ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT} +
          ${TABS_CONSTANTS.HEIGHT.TOTAL}px
      )
  );
`;
