import { FunctionComponent } from 'react';
import { HeadingProps as Props } from './definition';
import StyledHeading from './style';

export { CONSTANTS as HEADING_CONSTANTS } from './style';

const Heading: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { heading } = props;

  return (
    <StyledHeading>
      <span>{heading}</span>
    </StyledHeading>
  );
};

export default Heading;
