import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import { NAV_ITEM_CONSTANTS as HORIZONTAL_NAV_CONSTANTS } from 'apps/shared/components/HorizontalNav/components/NavItem';
import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { HEADING_CONSTANTS } from './components/Heading';

interface Props {
  isSiteLocationsEdit: boolean;
}

const StyledAccount = styled.div<Props>`
  ${({ isSiteLocationsEdit }) => css`
    display: grid;
    height: 100%;
    overflow: clip;

    ${!isSiteLocationsEdit &&
    css`
      grid-template-rows: ${HEADING_CONSTANTS.HEIGHT} ${HORIZONTAL_NAV_CONSTANTS.HEIGHT} 1fr;
    `}

    > div:last-of-type {
      width: calc(100vw - ${HEADER_CONSTANTS.LOGO.WIDTH});
      height: calc(
        100vh -
          (
            ${`${HEADER_CONSTANTS.HEIGHT} + ${
              !isSiteLocationsEdit
                ? `${HEADING_CONSTANTS.HEIGHT} + ${HORIZONTAL_NAV_CONSTANTS.HEIGHT}`
                : '0px'
            }`}
          )
      );
    }
  `}
`;

export const StyledAutocompletePopperFooter = styled(Box)`
  ${({ theme }) => css`
    ${mixin.flex({ alignItems: 'flex-start', justifyContent: 'flex-end' })};
    padding: ${theme.spacing(1.66)};

    p {
      margin: 0;
      padding-right: ${theme.spacing(1.66)};
    }
  `}
`;

export default StyledAccount;
