import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import { StyledAccountInfoProps as Props } from './definition';
import StyledAccountInfo from './style';

const AccountInfo: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledAccountInfo>
      <Icon name="info-filled" size={14} />
      <span>
        {t('common:component.current_account.info_text.0')}
        <Icon name="chevron-down" subset={16} size={12} />
        {t('common:component.current_account.info_text.1')}
      </span>
    </StyledAccountInfo>
  );
};

export default AccountInfo;
