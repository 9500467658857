import i18next from 'i18next';
import {
  CallflowModule,
  FeatureCodeCallflow,
  FeatureCodeMeta,
  FeatureCodeName,
} from '../../definition';
import { CallflowTemplate } from '../definition';

export const getFeatureCodeMeta = (): Record<FeatureCodeName, FeatureCodeMeta> => ({
  [FeatureCodeName.CallForwardActionActivate]: {
    defaultValue: 72,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.call_forward.codes.enable_call_forward',
    ),
  },
  [FeatureCodeName.CallForwardActionDeactivate]: {
    defaultValue: 73,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.call_forward.codes.disable_call_forward',
    ),
  },
  [FeatureCodeName.CallForwardActionToggle]: {
    defaultValue: 74,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.call_forward.codes.toggle_call_forward',
    ),
  },
  [FeatureCodeName.DoNotDisturbActionActivate]: {
    defaultValue: 78,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.do_not_disturb.codes.enable_do_not_disturb',
    ),
  },
  [FeatureCodeName.DoNotDisturbActionDeactivate]: {
    defaultValue: 79,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.do_not_disturb.codes.disable_do_not_disturb',
    ),
  },
  [FeatureCodeName.DoNotDisturbActionToggle]: {
    defaultValue: 76,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.do_not_disturb.codes.toggle_do_not_disturb',
    ),
  },
  [FeatureCodeName.FollowMeActionActivate]: {
    defaultValue: 88,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.follow_me.codes.enable_follow_me',
    ),
  },
  [FeatureCodeName.FollowMeActionDeactivate]: {
    defaultValue: 89,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.follow_me.codes.disable_follow_me',
    ),
  },
  [FeatureCodeName.FollowMeActionToggle]: {
    defaultValue: 86,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.follow_me.codes.toggle_follow_me',
    ),
  },
  [FeatureCodeName.CallForwardActionUpdate]: {
    defaultValue: 56,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.call_forward.codes.update_call_forward',
    ),
  },
  [FeatureCodeName.DirectedExtPickup]: {
    defaultValue: 87,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.misc.codes.directed_extension_pickup',
    ),
  },
  [FeatureCodeName.HotdeskActionLogin]: {
    defaultValue: 11,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.hot_desking.codes.enable_hot_desking',
    ),
  },
  [FeatureCodeName.HotdeskActionLogout]: {
    defaultValue: 12,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.hot_desking.codes.disable_hot_desking',
    ),
  },
  [FeatureCodeName.HotdeskActionToggle]: {
    defaultValue: 13,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.hot_desking.codes.toggle_hot_desking',
    ),
  },
  [FeatureCodeName.Intercom]: {
    defaultValue: 0,
    label: i18next.t('phone_system:containers.feature_codes.group.misc.codes.intercom'),
  },
  [FeatureCodeName.Move]: {
    defaultValue: 6683,
    label: i18next.t('phone_system:containers.feature_codes.group.misc.codes.move'),
    startSymbol: '',
  },
  [FeatureCodeName.ParkAndRetrieve]: {
    defaultValue: 3,
    dialog: {
      title: i18next.t(
        'phone_system:containers.feature_codes.group.parking.codes.park_and_retrieve.dialog.title',
      ),
    },
    label: i18next.t(
      'phone_system:containers.feature_codes.group.parking.codes.park_and_retrieve.label',
    ),
  },
  [FeatureCodeName.PrivacyModeFull]: {
    defaultValue: 67,
    label: i18next.t('phone_system:containers.feature_codes.group.misc.codes.privacy'),
  },
  [FeatureCodeName.Retrieve]: {
    defaultValue: 5,
    label: i18next.t('phone_system:containers.feature_codes.group.parking.codes.retrieve'),
  },
  [FeatureCodeName.Valet]: {
    defaultValue: 4,
    dialog: {
      title: i18next.t(
        'phone_system:containers.feature_codes.group.parking.codes.valet.dialog.title',
      ),
    },
    label: i18next.t('phone_system:containers.feature_codes.group.parking.codes.valet.label'),
  },
  [FeatureCodeName.VoicemailActionCheck]: {
    defaultValue: 97,
    label: i18next.t('phone_system:containers.feature_codes.group.misc.codes.check_voicemail'),
  },
  [FeatureCodeName.VoicemailActionDirect]: {
    defaultValue: '*',
    label: i18next.t('phone_system:containers.feature_codes.group.misc.codes.direct_to_voicemail'),
  },
  [FeatureCodeName.VoicemailSingleMailboxLogin]: {
    defaultValue: 98,
    label: i18next.t(
      'phone_system:containers.feature_codes.group.misc.codes.check_voicemail_auto_login',
    ),
  },
});

/**
 * @description This function encode the number as per feature code name
 * @param number the number to be encoded
 * @param name the feature code name
 * @returns The encoded number according to the feature code name
 */
export const encodeNumberByName = (
  name: FeatureCodeName,
  number: number | string | undefined = '',
) => {
  let encodedNumber = number.toString();

  if (name === FeatureCodeName.VoicemailActionDirect) {
    encodedNumber = number.toString().replace(/([*])/g, '\\$1');
  }

  return encodedNumber;
};

export const getFeatureCodeCallflow = (number: number | string) => {
  const CALLFLOW = [
    {
      hasNumbers: true,
      data: {
        action: 'activate',
      },
      module: CallflowModule.CallForward,
      name: FeatureCodeName.CallForwardActionActivate,
    },
    {
      hasNumbers: true,
      data: {
        action: 'deactivate',
      },
      module: CallflowModule.CallForward,
      name: FeatureCodeName.CallForwardActionDeactivate,
    },
    {
      hasPatterns: true,
      data: {
        action: 'toggle',
      },
      module: CallflowModule.CallForward,
      name: FeatureCodeName.CallForwardActionToggle,
    },
    {
      hasNumbers: true,
      data: {
        action: 'update',
      },
      module: CallflowModule.CallForward,
      name: FeatureCodeName.CallForwardActionUpdate,
    },
    {
      hasPatterns: true,
      data: {
        action: 'activate',
      },
      module: CallflowModule.DoNotDisturb,
      name: FeatureCodeName.DoNotDisturbActionActivate,
    },
    {
      hasPatterns: true,
      data: {
        action: 'deactivate',
      },
      module: CallflowModule.DoNotDisturb,
      name: FeatureCodeName.DoNotDisturbActionDeactivate,
    },
    {
      hasPatterns: true,
      data: {
        action: 'toggle',
      },
      module: CallflowModule.DoNotDisturb,
      name: FeatureCodeName.DoNotDisturbActionToggle,
    },
    {
      hasPatterns: true,
      data: {
        action: 'activate',
      },
      module: CallflowModule.FollowMe,
      name: FeatureCodeName.FollowMeActionActivate,
    },
    {
      hasPatterns: true,
      data: {
        action: 'deactivate',
      },
      module: CallflowModule.FollowMe,
      name: FeatureCodeName.FollowMeActionDeactivate,
    },
    {
      hasPatterns: true,
      data: {
        action: 'toggle',
      },
      module: CallflowModule.FollowMe,
      name: FeatureCodeName.FollowMeActionToggle,
    },

    {
      hasPatternsPlus: true,
      data: {
        type: 'extension',
      },
      module: CallflowModule.GroupPickupFeature,
      name: FeatureCodeName.DirectedExtPickup,
    },

    {
      hasNumbers: true,
      data: {
        action: 'login',
      },
      module: CallflowModule.Hotdesk,
      name: FeatureCodeName.HotdeskActionLogin,
    },
    {
      hasNumbers: true,
      data: {
        action: 'logout',
      },
      module: CallflowModule.Hotdesk,
      name: FeatureCodeName.HotdeskActionLogout,
    },
    {
      hasNumbers: true,
      data: {
        action: 'toggle',
      },
      module: CallflowModule.Hotdesk,
      name: FeatureCodeName.HotdeskActionToggle,
    },
    {
      hasPatterns: true,
      module: CallflowModule.Intercom,
      name: FeatureCodeName.Intercom,
    },
    {
      hasNumbersBare: true,
      module: CallflowModule.Move,
      name: FeatureCodeName.Move,
    },
    {
      hasPatterns: true,
      data: {
        action: 'auto',
      },
      module: CallflowModule.Park,
      name: FeatureCodeName.ParkAndRetrieve,
    },
    {
      hasPatterns: true,
      data: {
        mode: 'full',
      },
      module: CallflowModule.Privacy,
      name: FeatureCodeName.PrivacyModeFull,
    },
    {
      hasPatterns: true,
      data: {
        action: 'retrieve',
      },
      module: CallflowModule.Park,
      name: FeatureCodeName.Retrieve,
    },
    {
      hasNumbers: true,
      data: {
        action: 'park',
      },
      module: CallflowModule.Park,
      name: FeatureCodeName.Valet,
    },
    {
      hasPatterns: true,
      data: {
        action: 'check',
      },
      module: CallflowModule.Voicemail,
      name: FeatureCodeName.VoicemailActionCheck,
    },
    {
      hasPatterns: true,
      data: {
        action: 'compose',
      },
      module: CallflowModule.Voicemail,
      name: FeatureCodeName.VoicemailActionDirect,
    },
    {
      hasPatterns: true,
      data: {
        action: 'check',
        single_mailbox_login: true,
      },
      module: CallflowModule.Voicemail,
      name: FeatureCodeName.VoicemailSingleMailboxLogin,
    },
  ];

  const getCallflow = ({
    hasNumbers,
    hasNumbersBare,
    hasPatterns,
    hasPatternsEscaped,
    hasPatternsPlus,
    data = {},
    name,
    module,
    number: rawNumber,
  }: CallflowTemplate & { number: number | string }) => {
    const number = encodeNumberByName(name, rawNumber);

    return {
      flow: {
        data,
        module,
      },
      featurecode: {
        name,
        number,
      },
      ...(hasNumbers ? { numbers: [`*${number}`] } : {}),
      ...(hasNumbersBare ? { numbers: [number] } : {}),
      ...(hasPatterns ? { patterns: [`^\\*${number}([0-9]*)$`] } : {}),
      ...(hasPatternsEscaped ? { patterns: [`^\\*\\${number}([0-9]*)$`] } : {}),
      ...(hasPatternsPlus ? { patterns: [`^\\*${number}([0-9]+)$`] } : {}),
    };
  };

  return CALLFLOW.reduce((acc: Record<string, FeatureCodeCallflow>, callflow: CallflowTemplate) => {
    acc[callflow.name] = getCallflow({ ...callflow, number });
    return acc;
  }, {});
};
