import { forwardRef, FunctionComponent } from 'react';
import defaultProps from './default';
import { TextareaProps as Props } from './definition';
import StyledTextarea from './style';

export { Resize as TextareaResize } from './definition';

const Textarea: FunctionComponent<Props> = forwardRef<any, Props>(
  (props, ref): JSX.Element => {
    const { maxHeight, minHeight, ...rest }: Props = {
      ...defaultProps,
      ...props,
    };

    return (
      <StyledTextarea
        ref={ref}
        maxheight={maxHeight < minHeight ? minHeight : maxHeight}
        minheight={minHeight}
        {...rest}
        data-test-id="shared-textarea"
      />
    );
  },
);

export default Textarea;
