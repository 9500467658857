export const enableMattermostTeamDocument = `mutation EnableMattermostTeam($team: EnableMattermostTeamInput!) {
  mattermost {
    enableTeam(team: $team) {
      create_at
      createdUsers
      error
      id
      invite_id
      isRestored
      name
      update_at
    }
  }
}`;

export const createMattermostUserDocument = `mutation CreateMattermostUser($user: CreateMattermostUserInput!, $accountId: String!) {
  mattermost {
    createUser(user: $user, accountId: $accountId) {
      created {
        id
      }
    }
  }
}`;

export const deleteMattermostUserDocument = `mutation DeleteMattermostUser($user: DeleteMattermostUserInput!, $accountId: String!) {
  mattermost {
    deleteUser(user: $user, accountId: $accountId) {
      id
    }
  }
}`;

export const deleteMattermostTeamDocument = `mutation DeleteMattermostTeam($accountId: String) {
  mattermost {
    deleteTeam(accountId: $accountId) {
      id
    }
  }
}`;
