/* eslint-disable react/no-unstable-nested-components */
import { TableCellCheckmarkIcon } from 'apps/shared/components/TableCell';
import { ADD_KEY } from 'constant';
import { useFetchAccountBillingByIdQuery } from 'models/Account';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Table, { Cell, Justify, TABLE_CONSTANTS } from 'shared/components/Table';
import TableCellDownload from './components/TableCellDownload';
import translations from './translations';
import { fullAddressComposer, initData } from './utility';

export type { Location } from './definition';

const List = () => {
  const navigate = useNavigate();
  const { columns: column, table } = translations();
  const { data, isLoading } = useFetchAccountBillingByIdQuery();

  const columns = useMemo(
    () => [
      {
        Header: column.display_name.header,
        accessor: 'display_name',
      },
      {
        Header: column.address.header,
        accessor: fullAddressComposer,
      },
      {
        Header: () => column.billable_seats.header,
        accessor: (row: any) => row.seat_count ?? '—',
        id: `column.billable_seats.header.${Justify.Right}`,
        width: 200,
      },
      {
        Header: () => column.default.header,
        Cell: ({ value }: Cell<JSX.Element>) =>
          value === 'default' && <TableCellCheckmarkIcon info={column.default.info} />,
        accessor: (row: any) => row.id,
        id: `column.default.header.${Justify.Center}`,
        width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
      },
      {
        Header: '',
        Cell: ({ value }: Cell<JSX.Element>) =>
          value.seat_count > 0 && <TableCellDownload location={value} />,
        accessor: (row: any) => row,
        id: `column.download.header.${Justify.Center}`,
        width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
      },
    ],
    [column],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table
      hasSearch
      hasPagination={{ rowsPerPage: true }}
      addButton={{
        hasAdd: true,
        label: table.action_row.button.label,
        onAdd: () => navigate(`./${ADD_KEY}`),
      }}
      columns={columns}
      data={initData(data)}
      noItemsMessage={table.no_items}
    />
  );
};

export default List;
