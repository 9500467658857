import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { style } from '../style';
import { StyledCurrentAccountProps as Props } from './definition';

const StyledCurrentAccount = styled.div<Props>`
  ${({ isTreeVisible: isOpen, theme }) => css`
    ${mixin.flex({ direction: 'column', justifyContent: 'center' })};
    position: relative;

    > div {
      background-color: ${isOpen ? theme.core.background.grey.medium : 'inherit'};

      &:last-of-type {
        ${style.item({ isOpen, theme }).toggleable.body};
        min-width: 300px;

        --margin: ${theme.spacing(2)};

        > button {
          margin: var(--margin);
          margin-bottom: 0;
        }

        > div {
          &:first-of-type {
            margin: var(--margin);
          }

          &:nth-of-type(2) {
            margin: var(--margin);
            margin-top: 0;
          }
        }

        > span {
          ${mixin.flex({ justifyContent: 'flex-start' })};
          margin: var(--margin);
          margin-top: var(--margin);

          &:hover {
            ${mixin.hover({ letterSpacing: -0.0135 })};
          }

          > span {
            transform: translateY(-1px);
          }

          > svg {
            margin: ${theme.spacing(0, 2, 0, 0.33)};
          }
        }
      }
    }
  `}
`;

export default StyledCurrentAccount;
