import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import { FailoverMethod, FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['failover.method', 'failover.value'];

const FailoverSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const { watch, control } = useFormContext();

  return (
    <>
      <h3>{t('numbers:containers.numbers_in_use.section.failover.description')}</h3>
      <div role="row">
        <div role="cell">
          {/* Username */}
          <HookFormSelectWrapper
            name="failover.method"
            control={control}
            options={[
              {
                label: t('numbers:containers.numbers_in_use.section.failover.number.label'),
                value: FailoverMethod.e164,
              },
              {
                label: t('numbers:containers.numbers_in_use.section.failover.sip.label'),
                value: FailoverMethod.sip,
              },
              {
                label: t('numbers:containers.numbers_in_use.section.failover.inactive.label'),
                value: FailoverMethod.inactive,
              },
            ]}
          >
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSelect
                label={t('numbers:containers.numbers_in_use.section.failover.method.label')}
                selectWidth="medium"
                selectProps={{
                  ...formProps,
                  id: 'select-failover-method',
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormSelectWrapper>

          {watch('failover.method') === FailoverMethod.e164 && (
            <HookFormInputWrapper name="failover.e164" control={control}>
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  isDirty={isDirty}
                  feedback={feedback}
                  label={t('numbers:containers.numbers_in_use.section.failover.number.label')}
                  inputProps={{
                    placeholder: t(
                      'numbers:containers.numbers_in_use.section.failover.number.placeholder',
                    ),
                    ...formProps,
                  }}
                  labelProps={{ required: true }}
                />
              )}
            </HookFormInputWrapper>
          )}

          {watch('failover.method') === FailoverMethod.sip && (
            <HookFormInputWrapper name="failover.sip" control={control}>
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  isDirty={isDirty}
                  feedback={feedback}
                  label={t('numbers:containers.numbers_in_use.section.failover.sip.label')}
                  inputProps={{
                    placeholder: t(
                      'numbers:containers.numbers_in_use.section.failover.sip.placeholder',
                    ),
                    ...formProps,
                  }}
                  labelProps={{ required: true }}
                />
              )}
            </HookFormInputWrapper>
          )}
        </div>
      </div>
    </>
  );
};

export default FailoverSection;
