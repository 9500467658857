import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { StyledFeedbackItemProps as Props } from './definition';

const StyledFeedbackItem = styled(Box).attrs({ component: 'span' })<Props>`
  ${({ $isError, theme }) => css`
    display: block;
    margin: ${theme.spacing(0, 0, 0.5)};
    font-size: 12px;
    line-height: 16px;

    ${$isError &&
    css`
      color: ${theme.brio.error.main};
    `}
  `};
`;

export default StyledFeedbackItem;
