import { createSlice } from '@reduxjs/toolkit';

type DeviceState = {};

const InitialState = {};

export const deviceSlice = createSlice({
  name: 'device',
  initialState: InitialState as DeviceState,
  reducers: {},
});

export default deviceSlice.reducer;
