import { BuyNumbersFlowState as Props } from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow/definition';
import { useFetchAvailableNumbersByPrefixQuery } from 'models/PhoneNumber';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Loading from 'shared/components/Loading';
import RemovableTable from 'shared/components/RemovableTable';
import Typography from 'shared/components/Typography';
import { StyledPickNumbers } from './style';

const PickNumbers = (props: Props) => {
  const {
    areaCode,
    prefix,
    availableNumbers,
    selectedNumbers,
    setAvailableNumbers,
    setSelectedNumbers,
  } = props;
  const { t } = useTranslation();
  const { data, isLoading } = useFetchAvailableNumbersByPrefixQuery(
    { prefix: areaCode + prefix },
    { skip: !areaCode },
  );

  useEffect(() => {
    setAvailableNumbers(data || availableNumbers || []);
  }, [data]);

  const availableColumns = useMemo(
    () => [
      {
        Header: t(
          'common:component.buy_number_flow.components.pick_numbers.heading.available_numbers',
        ),
        accessor: 'number',
      },
    ],
    [],
  );

  const selectedColumns = useMemo(
    () => [
      {
        Header: t(
          'common:component.buy_number_flow.components.pick_numbers.heading.picked_numbers.text',
        ),
        accessor: 'number',
      },
    ],
    [],
  );

  const removeAvailableHandler = (removeRow: { [key: string]: any }) => {
    setAvailableNumbers(availableNumbers.filter((currentRow) => currentRow !== removeRow));
    setSelectedNumbers([...selectedNumbers, removeRow]);
  };

  const removeSelectedHandler = (removeRow: { [key: string]: any }) => {
    setAvailableNumbers([...availableNumbers, removeRow]);
    setSelectedNumbers(selectedNumbers.filter((currentRow) => currentRow !== removeRow));
  };

  if (isLoading) {
    return <Loading hasLargeProgress hasLabel={false} />;
  }

  return (
    <StyledPickNumbers>
      <Typography>
        {t('common:component.buy_number_flow.components.pick_numbers.message')}
      </Typography>
      <Box>
        <RemovableTable
          columns={availableColumns}
          data={availableNumbers}
          noDataMessage={
            availableNumbers.length === 0 && selectedNumbers.length === 0
              ? t('common:component.buy_number_flow.components.pick_numbers.no_numbers')
              : undefined
          }
          rowActionHandler={removeAvailableHandler}
        />
        <RemovableTable
          actionIcon={{
            color: 'core.states.error.main',
            name: 'minus-circle-outlined',
          }}
          columns={selectedColumns}
          data={selectedNumbers}
          header={{
            info: t(
              'common:component.buy_number_flow.components.pick_numbers.heading.picked_numbers.info',
            ),
          }}
          footer={{
            component: (
              <Box>
                <Box component="span">
                  {t('common:component.buy_number_flow.components.pick_numbers.heading.count')}
                </Box>
                <Box component="span">{selectedNumbers.length}</Box>
              </Box>
            ),
          }}
          noDataMessage={t(
            'common:component.buy_number_flow.components.pick_numbers.no_picked_numbers',
          )}
          rowActionHandler={removeSelectedHandler}
        />
      </Box>
    </StyledPickNumbers>
  );
};

export default PickNumbers;
