import { SelectOption } from 'shared/components/Select';
import { Column, FieldType, List, ListRow } from '../definition';

/**
 * @name filterOptions
 * @description Filter the column's select options of previously chosen items
 *
 * @param {Object} [object]
 * @property {number} index
 * @property {List} list
 * @property {SelectOption[]} columns
 * @property {boolean} isFiltered
 *
 * @return {SelectOption[]}
 */
export const filterOptions = ({
  isFiltered = false,
  options = [],
  columnIndex,
  list,
}: {
  isFiltered?: boolean;
  options?: SelectOption[];
  columnIndex: number;
  list: List;
}): SelectOption[] => {
  /**
   * Always sort the options, either with a custom sort value or with the label
   */
  options.sort((a: SelectOption, b: SelectOption) => {
    const { _a, _b } = a.sort && b.sort ? { _a: a.sort, _b: b.sort } : { _a: a.label, _b: b.label };
    return _a > _b ? 1 : -1;
  });

  if (!isFiltered) {
    return options;
  }

  /**
   * • Determine array of ids already in the chosen list
   * • Remove values already chosen
   * • Return filtered list of values
   */
  return options.filter(
    (option: SelectOption) =>
      !list.find((row: ListRow) => (row[columnIndex] as SelectOption).value === option.value),
  );
};

/**
 * @name getFieldType
 * @description Get the field type of the first column
 *
 * @param {Array<Column>} columns
 *
 * @return {FieldType}
 *
 * TODO: Usage of `columns[0]` assumes that the defined columns are either using
 * inputs OR selects (and not a mixture of both); this component SHOULD be capable
 * of a mixture of both inputs or selects across columns, with each column potentially
 * containing multiple inputs/selects. To be continued...
 */
export const getFieldType = (columns: Array<Column>): FieldType => {
  const column = columns[0];

  if (column.select) {
    return FieldType.Select;
  }
  if (column.input?.isTextarea) {
    return FieldType.Textarea;
  }

  return FieldType.Input;
};

/**
 * @name reset
 * @description Reset input/select add buffer
 *
 * @param {any} columns
 *
 * @return {Record<string, any>}
 */
export const reset = (columns: any): Record<string, any> => ({
  input: Array(columns.length).fill(''),
  select: Array(columns.length).fill(null),
});
