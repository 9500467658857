import { DRAWER_HEADER_CONSTANTS } from 'shared/components/Drawer/components/Header';
import styled, { css } from 'styled-components';
import { ACTION_CONTAINER_CONSTANTS } from '../../../ActionContainer';
import { StyledFlowContainerInnerProps as Props } from './definition';

const StyledFlowContainerInner = styled.div<Props>`
  ${(props) => css`
    padding: ${props.theme.spacing(6)};

    h2 {
      margin: 0 0 ${props.theme.spacing(4)};
      text-align: center;
    }

    ${props.hasZoom &&
    css`
      transform-origin: center top;
    `}

    ${!props.isPreview &&
    css`
      margin-left: ${`calc(${DRAWER_HEADER_CONSTANTS.HEIGHT}px + ${props.theme.spacing(1.33)})`};
      margin-right: ${`calc(${ACTION_CONTAINER_CONSTANTS.WIDTH} + ${props.theme.spacing(3)})`};
    `}
  `};
`;

export default StyledFlowContainerInner;
