import { TableCellMaskedValue } from 'apps/shared/components/TableCell';
import { useFetchConferencesQuery } from 'models/Conference';
import { getFullNameFromUserListByUserId, useFetchUsersQuery } from 'models/User';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import Table, { Cell } from 'shared/components/Table';

const List = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchConferencesQuery();
  const { data: usersData, isLoading: isLoadingUsers } = useFetchUsersQuery();

  const columns = useMemo(
    () => [
      {
        Header: t('phone_system:containers.conferences.table.column.name'),
        accessor: 'name',
        width: 400,
      },
      {
        Header: t('phone_system:containers.conferences.table.column.owner'),
        accessor: ({ owner_id }: any) => getFullNameFromUserListByUserId(owner_id, usersData),
      },
      {
        Header: t('phone_system:containers.conferences.table.column.pin'),
        Cell: ({ value }: Cell<{ pins: Array<string> }>) => (
          <TableCellMaskedValue value={value.pins} />
        ),
        accessor: 'member',
        disableGlobalFilter: true,
        width: 200,
      },
    ],
    [usersData, t],
  );

  if (isLoading || isLoadingUsers) {
    return <Loading />;
  }

  return (
    <Table
      title={t('phone_system:containers.conferences.table.title')}
      columns={columns}
      data={data ?? []}
      hasSearch
    />
  );
};

export default List;
