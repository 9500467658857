import merge from 'lodash/merge';
import { useMemo } from 'react';
import { useTableDataParams } from '../definition';

export const useTableData = ({ iterate, data }: useTableDataParams) =>
  useMemo(
    () =>
      new Array(iterate)
        .fill(null)
        .map((_, i) =>
          merge({}, { id: i }, Object.prototype.hasOwnProperty.call(data, i) ? data[i] : {}),
        ),
    [iterate, data],
  );
