import useDuration from 'apps/CallCenter/shared/hooks/useDuration';
import { formatDuration } from 'apps/shared/utility';
import { FunctionComponent } from 'react';
import Box from 'shared/components/Box';
import Typography from 'shared/components/Typography';
import { IncomingCallInfoProps as Props } from './definition';
import { StyledIncomingCallInfo } from './style';

const IncomingCallInfo: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { incomingCallLabel, priority, timestamp } = props;
  const duration = useDuration(timestamp);

  return (
    <StyledIncomingCallInfo priority={priority}>
      <Typography variant="overline" color="brio.greyscale.600" fontWeight="400" lineHeight={0}>
        {formatDuration(duration)}
      </Typography>
      <Box>
        {/* TODO Add tooltip + truncation | Remove style from Typography */}
        <Typography
          variant="body2"
          color="core.color.black"
          fontWeight="600"
          fontSize="14px"
          p={0}
          m={0}
        >
          {incomingCallLabel}
        </Typography>
      </Box>
    </StyledIncomingCallInfo>
  );
};

export default IncomingCallInfo;
