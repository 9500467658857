import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import { BodyProps as Props } from './definition';
import { getFieldValue } from './utility';

const Body: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    column: { isEditable = false, select, width },
    columnIndex: index,
    list,
    row,
  } = props;

  const { isFiltered = false, options = [] } = select?.[index] ?? {};
  const value = row[index];

  return (
    <td style={width ? { width } : {}} data-test-id="shared-editablelist-tablecellbody">
      {typeof value !== 'string' && value?.icon && <Icon name={value.icon} size={20} />}
      {getFieldValue({
        isEditable,
        isFiltered,
        columnIndex: index,
        list,
        options,
        value,
      })}
    </td>
  );
};

export default Body;
