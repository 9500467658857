import styled, { css } from 'styled-components';
import { StyledArrowProps as Props } from './definition';

const StyledArrow = styled.span.attrs({ className: 'callflow-arrow' })<Props>`
  ${(props) => css`
    ${props.isMultinary &&
    css`
      > svg {
        transform: translate3d(0, 6px, 0);
      }
    `}
  `};
`;

export default StyledArrow;
