import { CallflowContextProvider } from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import FlowContainer from 'apps/PhoneSystem/containers/Callflows/Edit/components/FlowContainer/index';
import { useFetchCallflowByIdQuery } from 'models/Callflow';
import { addCallFlow } from 'models/Callflow/store/slice';
import { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button';
import Dialog, { DialogActions, DialogType } from 'shared/components/Dialog';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import defaultProps from './default';
import { CallFlowPreviewProps as Props } from './definition';

const CallFlowPreview: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { id, isCallFlowDialogOpen, setIsCallFlowDialogOpen }: Props = {
    ...defaultProps,
    ...props,
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = useFetchCallflowByIdQuery({ id });
  const refCallflow = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data) {
      dispatch(addCallFlow(data));
    }
  }, [data, dispatch]);

  return (
    <Dialog
      ref={refCallflow}
      open={isCallFlowDialogOpen}
      renderActions={
        <DialogActions
          rightButtons={
            <Button
              size="drawer"
              variant="contained"
              startIcon={<Icon name="edit" size={18} />}
              onClick={() => {
                setIsCallFlowDialogOpen(false);
                navigate(`../${id}`);
              }}
            >
              {t(
                'phone_system:containers.callflows.callflow_action_dialog.callflow.preview.button',
              )}
            </Button>
          }
        />
      }
      title={t('phone_system:containers.callflows.callflow_action_dialog.callflow.preview.title')}
      type={DialogType.CallflowPreview}
      onClose={() => setIsCallFlowDialogOpen(false)}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <CallflowContextProvider isPreview callflow={data}>
          <FlowContainer refCallflow={refCallflow} />
        </CallflowContextProvider>
      )}
    </Dialog>
  );
};

export default CallFlowPreview;
