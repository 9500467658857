import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class ApplicationVariableActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.APPLICATION_VARIABLE,
      module: CallFlowModuleKey.APPLICATION_VARIABLE,
      section: CallFlowSection.ADVANCED,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'tune',
      },
      label: i18next.t('phone_system:containers.callflows.actions.application_variable.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.application_variable.tooltip'),
    });
  }
}

export default ApplicationVariableActionConfig;
