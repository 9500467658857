import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { DialogActionsCloseReasons } from 'shared/components/Dialog/definition';
import { LabeledCheckbox } from 'shared/components/Labeled';
import { FormFields } from '../../definition';
import WarningDialog from './components/WarningDialog';
import { TeamChatEnablementCheckboxProps as Props } from './definition';

export const TeamChatEnabledCheckbox: FunctionComponent<Props> = ({
  initialTeamChatIsEnabled,
}: Props) => {
  const { t } = useTranslation();
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

  const { resetField } = useFormContext();

  const onCheckboxChange = (checked: boolean) => {
    if (initialTeamChatIsEnabled && !checked) {
      setIsWarningDialogOpen(true);
    }
  };

  const onClose = () => {
    resetField(FormFields.TeamChatIsEnabled, { defaultValue: true });
    setIsWarningDialogOpen(false);
  };

  const onWarningDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onClose();
        break;
      case 'saveClicked':
        setIsWarningDialogOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <Box role="row">
      <Box role="cell">
        <HookFormInputWrapper name={FormFields.TeamChatIsEnabled}>
          {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
            <LabeledCheckbox
              isDirty={isDirty}
              feedback={feedback}
              indentWidth="small"
              label={t(
                'phone_system:containers.account.submodule.settings.section.team_chat_enablement.enabled_checkbox',
              )}
              checkboxProps={{
                ...formProps,
                onChange: (e, checked) => {
                  onChange(checked);
                  onCheckboxChange(checked);
                },
                checked: Boolean(value),
              }}
            />
          )}
        </HookFormInputWrapper>
        <WarningDialog
          isOpen={isWarningDialogOpen}
          onClose={onClose}
          onAction={onWarningDialogAction}
        />
      </Box>
    </Box>
  );
};
