import { selectCallflowDialogData } from 'models/Callflow/store/selectors';
import {
  dialogKeySet,
  dismissActionDialog,
  dismissSelectKeyDialog,
} from 'models/Callflow/store/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DialogActionsCloseReasons } from 'shared/components/Dialog';
import { DIALOG_COMPONENTS } from './components';

const SelectKeyDialog = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  if (!id) {
    throw new Error('id is required');
  }

  const dialogInfo = useSelector((state) => selectCallflowDialogData(state, id, 'keyDialog'));

  const onSave = (key: string, isDirty: boolean) => {
    dispatch(dialogKeySet({ id, key, isDirty }));
    dispatch(dismissSelectKeyDialog({ id }));
  };

  const onClose = (
    handleSubmit: any,
    submitHandler: any,
    closeResponse: { reason: DialogActionsCloseReasons },
  ) => {
    closeResponse.reason === 'saveClicked'
      ? handleSubmit(submitHandler)()
      : dispatch(dismissSelectKeyDialog({ id, isCancel: true })) &&
        dispatch(dismissActionDialog({ id, isCancel: true }));
  };

  if (dialogInfo?.type) {
    const SpecificDialog = DIALOG_COMPONENTS[dialogInfo.type];
    return <SpecificDialog {...dialogInfo} onSave={onSave} onClose={onClose} />;
  }

  return <></>;
};

export default SelectKeyDialog;
