import * as d3 from 'd3';
import { FunctionComponent, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { getTimezoneWithDstMap } from 'shared/components/Labeled/components/LabeledSelectTimeZone/utility/index';
import { getCitiesToTimezoneWithDstMap } from 'shared/components/TimeZoneMapPickerDialog/components/TimeZoneMapPicker/utility/index';
import MapFrame from './components/MapFrame';
import mapGeoData from './data/mapGeoData.json';
import defaultProps, { defaultTimeZoneValue } from './default';
import { TimeZoneMapPickerProps as Props, TimeZoneValue } from './definition';
import { StyledToolTipContent } from './style';

const TimeZoneMapPicker: FunctionComponent<Props> = (props: Props) => {
  const { mapScale, frameWidth, frameHeight, onTimeZoneChanged } = {
    ...defaultProps,
    ...props,
  };

  const svgRef = useRef() as MutableRefObject<SVGSVGElement>;

  const [hoverTimeZone, setHoverTimeZone] = useState<TimeZoneValue>(defaultTimeZoneValue);
  const [selectedTimeZone, setSelectedTimeZone] = useState<TimeZoneValue>(defaultTimeZoneValue);
  const timezoneList = useMemo(() => getTimezoneWithDstMap(), []);
  const citiesToTimeZoneMap = useMemo(() => getCitiesToTimezoneWithDstMap(), []);

  const onTimeZoneSelected = (data: TimeZoneValue = defaultTimeZoneValue) => {
    const { selectedTimeZone } = data;
    setSelectedTimeZone(data);
    onTimeZoneChanged(timezoneList[selectedTimeZone]);
  };

  useEffect(() => {
    if (svgRef) {
      const path = d3.geoPath();

      // Initialize the frame for the map
      const svg = d3.select(svgRef.current).style('width', frameWidth).style('height', frameHeight);

      // Clear the previous one if user select a time zone
      // TODO: handle the case of toggling time zone
      if (!!selectedTimeZone) {
        svg.selectAll('svg > *').remove();
      }

      // Draw the map and the cities
      svg
        .insert('g', '.graticule')
        .selectAll('path')
        .data(mapGeoData)
        .enter()
        .append('path')

        // Attributes
        // @ts-ignore: an issue to be fixed by @types/d3
        .attr('d', path)
        .attr('style', 'cursor:pointer')
        .attr('class', 'geography')
        .attr('opacity', 1)
        .attr('fill', (cityGeoData) => {
          if (selectedTimeZone.city === cityGeoData.id) {
            return '#000000';
          }
          return '#AAAAAA';
        })
        .attr('stroke-width', 2)
        .attr('stroke', '#FFFFFF')
        .attr('transform', `scale(${mapScale})`)

        // Event Listeners
        .on('mouseenter', (event, cityGeoData) => {
          const selectedTimeZone = citiesToTimeZoneMap[cityGeoData.id];
          setHoverTimeZone({ city: cityGeoData.id, selectedTimeZone });
        })
        .on('mouseleave', () => {
          setHoverTimeZone(defaultTimeZoneValue);
        })
        .on('click', (event, cityGeoData) => {
          if (selectedTimeZone.city === cityGeoData.id) {
            onTimeZoneSelected(defaultTimeZoneValue);
          } else {
            const selectedTimeZone = citiesToTimeZoneMap[cityGeoData.id];
            onTimeZoneSelected({
              city: cityGeoData.id,
              selectedTimeZone,
            });
          }
        })

        // Text
        .append('title')
        .text((cityGeoData) => cityGeoData.id);
    }
  }, [svgRef, selectedTimeZone]);

  return (
    <>
      <MapFrame svgRef={svgRef} selectedTimeZone={selectedTimeZone} />
      {/* ReactTooltip is used instead of MUI Tooltip for better animation performance */}
      <ReactTooltip multiline>
        {hoverTimeZone.city && (
          <StyledToolTipContent>
            {hoverTimeZone.city}
            <br />
            {hoverTimeZone.selectedTimeZone}
          </StyledToolTipContent>
        )}
      </ReactTooltip>
    </>
  );
};

export default TimeZoneMapPicker;
