import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAM_KEYWORD } from '../../utility';

export const useTableSearchParam = (setGlobalFilter: (filterValue: any) => void) => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get(SEARCH_PARAM_KEYWORD);
  useEffect(() => {
    if (search) {
      setGlobalFilter(search);
    }
  }, [search, setGlobalFilter]);
};
