import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class CallNotificationActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.CALL_NOTIFICATION,
      module: CallFlowModuleKey.CALL_NOTIFICATION,
      section: CallFlowSection.CALL_SETTINGS,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'notifications-active-outlined',
      },
      label: i18next.t('phone_system:containers.callflows.actions.call_notification.name'),
    });
  }
}

export default CallNotificationActionConfig;
