import { SESSION_STORAGE } from 'constant';

/**
 * @description The controller to handle the storage of selected call queues per account (in session storage)
 */
export const selectedCallQueueStorage = {
  getSelectedQueues: (): Record<string, string> | undefined => {
    const callQueueStorage = sessionStorage.getItem(SESSION_STORAGE.CALL_CENTER_CALL_QUEUES);

    return callQueueStorage ? JSON.parse(callQueueStorage) : undefined;
  },
  getSelectedQueueId: (accountId: string): string | undefined => {
    const selectedCallQueues = selectedCallQueueStorage.getSelectedQueues();

    return selectedCallQueues?.[accountId] ?? undefined;
  },
  setSelectedQueueById: (accountId: string, queueId: string) => {
    let selectedQueue = selectedCallQueueStorage.getSelectedQueues();

    if (!selectedQueue) {
      selectedQueue = {};
    }

    if (selectedQueue) {
      selectedQueue[accountId] = queueId;
      sessionStorage.setItem(
        SESSION_STORAGE.CALL_CENTER_CALL_QUEUES,
        JSON.stringify(selectedQueue),
      );
    }
  },
};
