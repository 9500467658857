import { CALLER_ID_MAX_LENGTH } from 'constant';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { CallerIdType, FormFields } from '../../definition';

export const schema = () => ({
  [FormFields.CallerId]: Joi.object({
    [CallerIdType.Emergency]: Joi.object({
      name: Joi.string().max(CALLER_ID_MAX_LENGTH).allow('', null),
      number: Joi.string().max(CALLER_ID_MAX_LENGTH).allow('', null),
    }).unknown(true),
    [CallerIdType.External]: Joi.object({
      name: Joi.string().max(CALLER_ID_MAX_LENGTH).allow('', null),
      number: Joi.string().max(CALLER_ID_MAX_LENGTH).allow('', null),
    }).unknown(true),
  })
    .unknown(true)
    .messages(messages()),
});
