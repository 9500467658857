import styled, { css } from 'styled-components';

const StyledOverflowList = styled.span`
  ${({ theme }) => css`
    > span {
      margin: ${theme.spacing(0, 0, 0, 1.66)};
      color: ${theme.brio.text.secondary};
    }
  `};
`;

export default StyledOverflowList;
