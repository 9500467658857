import {
  createArrayValue,
  createTranslateChangeHandler,
} from 'apps/PhoneSystem/components/EditForm/helper';
import SharedDialog from 'apps/PhoneSystem/shared/SharedDialog';
import {
  HookFormFieldArrayWrapper,
  HookFormInputWrapper,
  HookFormSelectWrapper,
} from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LabeledCheckbox,
  LabeledSelect,
  LabeledSwitch,
  LabeledTextarea,
} from 'shared/components/Labeled';
import { isCompoundFieldDirty } from '../../../utility';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'include_message_on_notify',
  'transcribe',
  'vm_message_forward_type',
  'after_forward_action',
  'play_envelope_action',
  'notify_email_addresses',
  'shared_vmbox',
  'members',
];

const RecipientsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  const afterForwardOptions = [
    {
      value: 'prompt',
      label: t(
        'phone_system:containers.voicemails.section.recipients.after_forwarding.options.prompt_user_for_action',
      ),
    },
    {
      value: 'save',
      label: t(
        'phone_system:containers.voicemails.section.recipients.after_forwarding.options.save_the_message',
      ),
    },
  ];

  const playEnvelopeOptions = [
    {
      value: 'play_before',
      label: t(
        'phone_system:containers.voicemails.section.recipients.play_envelope.options.before_the_message',
      ),
    },
    {
      value: 'play_after',
      label: t(
        'phone_system:containers.voicemails.section.recipients.play_envelope.options.after_the_message',
      ),
    },
    {
      value: 'skip',
      label: t('phone_system:containers.voicemails.section.recipients.play_envelope.options.skip'),
    },
  ];

  return (
    <div role="row">
      <div role="cell">
        {/* Attach messages to email */}
        <HookFormInputWrapper name="include_message_on_notify" isCheckbox>
          {({ ref, value, isDirty, feedback, ...formProps }) => (
            <LabeledCheckbox
              isDirty={isDirty}
              feedback={feedback}
              label={t(
                'phone_system:containers.voicemails.section.recipients.attach_messages_to_email.label',
              )}
              checkboxProps={{
                ...formProps,
                id: 'checkbox-recipients-attach-msg-to-email',
              }}
            />
          )}
        </HookFormInputWrapper>

        {/* Transcribe Messages */}
        <HookFormInputWrapper name="transcribe" isCheckbox>
          {({ ref, value, isDirty, feedback, ...formProps }) => (
            <LabeledCheckbox
              isDirty={isDirty}
              feedback={feedback}
              label={t(
                'phone_system:containers.voicemails.section.recipients.transcribe_messages.label',
              )}
              checkboxProps={{
                ...formProps,
                id: 'checkbox-recipients-transcribe-messages',
              }}
            />
          )}
        </HookFormInputWrapper>

        {/* Prepend message on forward */}
        <HookFormInputWrapper name="vm_message_forward_type">
          {({ ref, value, onChange, isDirty, feedback, ...formProps }) => {
            const onChangeHandler = (e: any, checked: boolean) => {
              return onChange(checked ? 'prepend_forward' : 'only_forward');
            };

            return (
              <LabeledCheckbox
                hasMargin
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'phone_system:containers.voicemails.section.recipients.prepend_message_on_forward.label',
                )}
                checkboxProps={{
                  onChange: onChangeHandler,
                  checked: value === 'prepend_forward',
                  ...formProps,
                  id: 'checkbox-recipients-prepend-forward',
                }}
              />
            );
          }}
        </HookFormInputWrapper>

        {/* After Forwarding */}
        <HookFormSelectWrapper name="after_forward_action" options={afterForwardOptions}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledSelect
              isDirty={isDirty}
              feedback={feedback}
              label={t(
                'phone_system:containers.voicemails.section.recipients.after_forwarding.label',
              )}
              selectProps={{
                ...formProps,
                id: 'select-recipients-after-forwarding',
              }}
            />
          )}
        </HookFormSelectWrapper>

        {/* Play Envelope */}
        <HookFormSelectWrapper name="play_envelope_action" options={playEnvelopeOptions}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledSelect
              isDirty={isDirty}
              feedback={feedback}
              label={t('phone_system:containers.voicemails.section.recipients.play_envelope.label')}
              selectProps={{
                ...formProps,
                id: 'select-recipients-play-envelope',
              }}
            />
          )}
        </HookFormSelectWrapper>

        {/* Email Recipients */}
        <HookFormInputWrapper name="notify_email_addresses" customIsDirty={isCompoundFieldDirty}>
          {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
            <LabeledTextarea
              isDirty={isDirty}
              feedback={feedback}
              label={t(
                'phone_system:containers.voicemails.section.recipients.email_recipients.label',
              )}
              inputProps={{
                value: createArrayValue(value),
                onChange: createTranslateChangeHandler(onChange),
                ...formProps,
                id: 'txtarea-recipients-email-recipients',
              }}
            />
          )}
        </HookFormInputWrapper>

        {/* Shared Voicemail */}
        <HookFormInputWrapper name="shared_vmbox" isSwitch>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledSwitch
              id="checkbox-recipients-shared_vmbox"
              isSwitchLeft
              isDirty={isDirty}
              feedback={feedback}
              label={t('phone_system:containers.voicemails.section.recipients.shared_vmbox.label')}
              switchProps={{
                ...formProps,
              }}
            />
          )}
        </HookFormInputWrapper>

        <HookFormFieldArrayWrapper
          name="members"
          watchField="shared_vmbox"
          customIsDirty={isCompoundFieldDirty}
        >
          {({ ref, isDirty, feedback, watchValue, ...sharedVMProps }) => (
            <SharedDialog
              hasDialogLink={watchValue}
              isDirty={isDirty}
              feedback={feedback}
              {...sharedVMProps}
            />
          )}
        </HookFormFieldArrayWrapper>
      </div>
    </div>
  );
};

export default RecipientsSection;
