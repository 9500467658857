import { FunctionComponent, memo, useCallback, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import Actions from './components/Actions';
import Heading from './components/Heading';
import { ActionSectionProps as Props } from './definition';
import StyledSection from './style';
import translations from './translations';

const Section: FunctionComponent<Props> = memo(
  (props: Props): JSX.Element => {
    const { index, actions = [], section } = props;
    const [height, setHeight] = useState<number | string>(index === 0 ? 'auto' : 0);
    const toggleHeader = useCallback(() => setHeight((height) => (height === 0 ? 'auto' : 0)), [
      height,
    ]);
    const { sections } = translations();

    return (
      <StyledSection>
        <Heading isOpen={height !== 0} name={sections[section]} onClick={toggleHeader} />
        {/** @ts-ignore */}
        <AnimateHeight duration={150} height={height}>
          <Actions actions={actions} />
        </AnimateHeight>
      </StyledSection>
    );
  },
);

export default Section;
