import { getImagePath } from 'apps/shared/hooks/useDevicePicker/utility';
import { useState } from 'react';
import Box from 'shared/components/Box';
import TEST_ID from 'shared/utility/testing/constants/testId';
import ImagePlaceholder from './components/ImagePlaceholder';
import { ModelProps as Props } from './definition';
import StyledModel from './style';

const Model = ({ data: { label, value }, model, onClick }: Props) => {
  const [hasImage, setHasImage] = useState<boolean>(false);

  return (
    <StyledModel
      hasImage={hasImage}
      isActive={value === model}
      data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-usedevicepicker-dialog-devicelist-devicefamily-devicemodel-${value}`}
      onClick={() => onClick(value)}
    >
      <Box>
        <img
          alt={value}
          src={getImagePath(value, true)}
          onLoad={() => setHasImage(true)}
          onError={() => setHasImage(false)}
        />
        {!hasImage && <ImagePlaceholder />}
      </Box>
      <Box component="span">{label}</Box>
    </StyledModel>
  );
};

export default Model;
