// Seat Queries
import { User } from 'api/user';
import { ByAccountIdParam, ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const seatQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSeats: builder.query<SeatResponse, void>({
      query: () => ({
        url: User.Fetch(),
        responseHandler,
      }),
      extraOptions: { maxRetries: 1 },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.SEAT as const, id })),
              { type: TAGS.SEAT, id: 'LIST' },
            ]
          : [{ type: TAGS.SEAT, id: 'LIST' }],
    }),
    fetchSeatById: builder.query<Seat, ByIdParam>({
      query: ({ id }) => ({
        url: User.FetchById({ id }),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.SEAT, id }];
      },
    }),
    fetchSeatHeroAppsById: builder.query<Seat, { accountId?: string; id: string }>({
      query: ({ accountId, id }) => ({
        url: User.FetchHeroAppsById(accountId, id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.SEAT, id }];
      },
    }),
    listBySeatType: builder.query<Seat, ByAccountIdParam>({
      query: ({ accountId }) => ({
        url: User.ListBySeatType(accountId),
        responseHandler,
      }),
      providesTags: [{ type: TAGS.SEAT, id: 'LIST_BY_SEAT_TYPE' }],
    }),
  }),
});
