import { CallRestrictionOptionType, Classifiers, ClassifierSet } from 'definition';

const classifiers = Object.values(ClassifierSet).reduce((acc: Classifiers, classifier: string) => {
  acc[classifier] = CallRestrictionOptionType.Allow;
  return acc;
}, {});

export const defaultValues = {
  name: '',
  call_recording_url: '',
  agent_wrapup_time: '30',
  announcements: {
    interval: '30',
    position_announcements_enabled: false,
    wait_time_announcements_enabled: false,
  },
  breakout: {
    classifiers,
    enabled: false,
  },
  caller_exit_key: '',
  connection_timeout: '0',
  enter_when_empty: false,
  hide_in_dashboard: false,
  max_queue_size: '0',
  moh: '',
  notifications: {
    method: 'GET',
    hangup: '',
    pickup: '',
  },
  record_caller: false,
  strategy: 'skills_based_round_robin',
  send_wait_time: false,
};
