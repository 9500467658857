import { AgentCardStatusType } from 'apps/CallCenter/shared/components/AgentCard';
import { QueueCardData } from 'apps/CallCenter/shared/components/QueueCard';
import i18next from 'i18next';
import CONSTANTS from '../constants';
import { WaitingSummarized } from '../definition';

/**
 * @name getAgentsFromQueue
 * @description Retrieves agents from the provided agentsData array that belong to the specified queueId
 *
 * @param queueId - The ID of the queue
 * @param agentsData - An array of Agent objects
 * @returns - An array of agents belonging to the specified queue
 */
const getAgentsFromQueue = (queueId: string, agentsData: Agent[]): Agent[] =>
  agentsData.filter(
    (agent: Agent) =>
      agent.queues.length && agent.queues.find((agentQueueId: string) => agentQueueId === queueId),
  );

/**
 * @name getAgentsFromAccount
 * @description Retrieves agents from the provided agentsData array that belong to the specified queueId
 *
 * @param agentsData - An array of Agent objects
 * @returns - An array of agents belonging to the Account
 */
export const getAgentsFromAccount = (agentsData: Agent[]): Agent[] =>
  agentsData.filter((agent: Agent) => agent.queues.length);

/**
 * @name getActiveAgents
 * @description Retrieves active agents from the provided agentsFromQueue array based on the agentsStatusData
 *
 * @param agentsFromQueue - An array of agents from a specific queue
 * @param agentsStatusData - An object containing the status of agents
 * @returns - An array of active agents from the specified queue
 */
const getActiveAgents = (agentsFromQueue: Agent[], agentsStatusData: AgentsStatus): Agent[] =>
  agentsFromQueue.filter(
    (agent: Agent) =>
      agentsStatusData[agent.id]?.status &&
      agentsStatusData[agent.id].status !== AgentCardStatusType.LOGGED_OUT,
  );

/**
 * @name getActiveCallsFromQueue
 * @description Retrieves the number of active calls for the specified queueId from the queuesSummaryData
 *
 * @param queueId - The ID of the queue
 * @param queuesSummaryData - An object containing summarized queue statistics
 * @returns - The number of active calls for the specified queue
 */
const getActiveCallsFromQueue = (queueId: string, queuesSummaryData: QueuesStatsSummary): number =>
  queuesSummaryData?.Handled.filter((handledCall: QueueHandled) => handledCall.queue_id === queueId)
    .length;

/**
 * @name getActiveCallsFromAccount
 * @description Retrieves the number of active calls for the Account
 *
 * @param queuesSummaryData - An object containing summarized queue statistics
 * @returns - The number of active calls for the specified Account
 */
const getActiveCallsFromAccount = (queuesSummaryData: QueuesStatsSummary): number =>
  queuesSummaryData?.Handled.length;

/**
 * @name getAverageWaitTime
 * @description Calculates the average wait time for queues based on the provided QueueSummarized object
 *
 * @param queueSummarized - An array of object containing summarized queue data
 * @returns - The average wait time formatted as 'mm:ss'
 */
export const getAverageWaitTime = (totalCalls: number, totalWaitTime: number): string => {
  if (totalCalls > 0 && totalWaitTime > 0) {
    const totalSeconds = totalWaitTime / totalWaitTime;
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }
  return '00:00';
};

/**
 * @name prepareQueuesDataForDashboard
 * @description Prepares queue data for a Dashboard - Queue Cards List
 *
 * @param agentsData - An array of Agent objects
 * @param agentsStatusData - An object containing the status of agents
 * @param queuesData - An array of Queue objects
 * @param queuesSummaryData - An object containing summarized queue statistics
 * @returns - An array of QueueCardData objects (prepared queue data for the dashboard)
 */
export const prepareQueuesDataForDashboard = (
  agentsData: Agent[],
  agentsStatusData: AgentsStatus,
  queuesData: Queue[],
  queuesSummaryData: QueuesStatsSummary,
): QueueCardData[] => {
  const queuesList = queuesData.map((queue: Queue) => {
    const agentsFromQueue = getAgentsFromQueue(queue.id, agentsData);
    const queueSummarized = queuesSummaryData?.Summarized[queue.id];

    return {
      id: queue.id,
      isSelected: false,
      hasMenu: true,
      title: queue.name,
      activeAgentsTotal: agentsFromQueue.length,
      activeAgentsCurrent: getActiveAgents(agentsFromQueue, agentsStatusData).length,
      averageWaitTime: getAverageWaitTime(
        queueSummarized?.total_calls,
        queueSummarized?.total_wait_time,
      ),
      totalCalls: queueSummarized?.total_calls || 0,
      missedCalls: queueSummarized?.abandoned_calls || 0,
      activeCalls: getActiveCallsFromQueue(queue.id, queuesSummaryData) || 0,
    };
  });

  return queuesList;
};

/**
 * @name prepareIncomingCallTableData
 * @description Prepare data for the Incoming Call Table
 *
 * @param [object] - Property object
 * @param queuesIncomingCalls - The array of waiting calls from queues
 * @param selectedQueueId - The selected queue ID for filtering calls
 * @param onCopyCallIdToClipboard - Event handler
 * @param onForfeitCallClick - Event handler
 *
 * @returns - The prepared data for the Incoming Call Table
 */
export const prepareIncomingCallTableData = ({
  queuesIncomingCalls,
  selectedQueueId,
  onCopyCallIdToClipboard,
  onForfeitCallClick,
}: {
  queuesIncomingCalls?: WaitingSummarized;
  selectedQueueId: string;
  onCopyCallIdToClipboard: (callId: string) => void;
  onForfeitCallClick: (queueId: string, callId: string) => void;
}) =>
  queuesIncomingCalls
    ? queuesIncomingCalls
        .filter(({ queue_id }: any) =>
          [CONSTANTS.ALL_QUEUES_ID, queue_id].includes(selectedQueueId),
        )
        .map(
          ({
            is_callback,
            average_wait_time_estimation,
            call_id,
            caller_id_name,
            caller_id_number,
            caller_priority,
            entered_position,
            entered_timestamp,
            id,
            queue_id,
            required_skills,
            ringing_events,
            status,
          }: any) => ({
            isCallback: is_callback,
            averageWaitTimeEstimation: average_wait_time_estimation,
            callId: call_id,
            callLabel: caller_id_name || caller_id_number,
            enteredPosition: entered_position,
            id,
            items: [
              {
                icon: 'file-copy-outlined',
                label: i18next.t(
                  'call_center:containers.dashboard.section.incoming_calls.dropdown.copy_call_id',
                ),
                tooltip: call_id,
                onClick: () => onCopyCallIdToClipboard(call_id),
              },
              {
                icon: 'minus-circle-outlined',
                label: i18next.t(
                  'call_center:containers.dashboard.section.incoming_calls.dropdown.forfeit_call',
                ),
                onClick: () => onForfeitCallClick(queue_id, call_id),
              },
            ],
            priority: caller_priority,
            queueId: queue_id,
            ringingEvents: ringing_events,
            skills: required_skills,
            status,
            timestamp: entered_timestamp,
          }),
        )
    : [];

/**
 * @name getQueuesSummary
 * @description Retrieves a summary of queues
 *
 * @param agentsData - Array of agent objects
 * @param agentsStatusData - Object containing agents' status information
 * @param queuesSummaryData - Object containing summarized queue statistics
 * @returns - Summary of queues as a QueueCardData object
 */
export const getQueuesSummary = (
  agentsData: Agent[],
  agentsStatusData: AgentsStatus,
  queuesSummaryData: QueuesStatsSummary,
): QueueCardData => {
  const callsSummary = Object.values(queuesSummaryData.Summarized).reduce(
    (acc, item) => {
      acc.totalCalls += item.total_calls;
      acc.missedCalls += item.abandoned_calls;
      acc.totalWaitTime += item.total_wait_time;
      return acc;
    },
    {
      totalCalls: 0,
      missedCalls: 0,
      totalWaitTime: 0,
    },
  );
  const agents = getAgentsFromAccount(agentsData);

  return {
    activeAgentsCurrent: getActiveAgents(agents, agentsStatusData).length,
    activeAgentsTotal: agents.length,
    activeCalls: getActiveCallsFromAccount(queuesSummaryData) || 0,
    averageWaitTime: getAverageWaitTime(callsSummary?.totalCalls, callsSummary?.totalWaitTime),
    missedCalls: callsSummary?.missedCalls || 0,
    title: '',
    totalCalls: callsSummary?.totalCalls || 0,
  };
};
