import { WidthType } from 'shared/utility/definition';

const defaultProps = {
  minutesStep: 5,
  value: new Date('2023-01-01T00:00:00'), // needs to be static or snapshots will mismatch
  width: 'large' as WidthType,
  onChange: () => {},
  renderInput: () => <></>,
};

export default defaultProps;
