import isEqual from 'lodash/isEqual';
import { MutableRefObject } from 'react';

/**
 * Determine if the expanded callflows have opened and/or closed
 *
 * @param {Array<string>} callflowIds - Callflow tabs (currently a maximum of four [4])
 * @param {Array<string>} expandedCallflowIds - Expanded callflows (currently a maximum of two [2])
 * @param {MutableRefObject<Array<string>>} refExpandedDelta - Difference in expanded callflows array
 * @param {MutableRefObject<Array<string>>} refHasExpandedChanged - Has the expanded callflows listing changed?
 */
export const determineExpandedDelta = (
  callflowIds: Array<string>,
  expandedCallflowIds: Array<string>,
  refExpandedDelta: MutableRefObject<Array<string>>,
  refHasExpandedChanged: MutableRefObject<boolean>,
): void => {
  const expandedDelta = callflowIds.filter((id: string) => expandedCallflowIds.includes(id));

  if (!isEqual(expandedDelta, refExpandedDelta.current)) {
    refExpandedDelta.current = expandedDelta;
    refHasExpandedChanged.current = true;
  } else {
    refHasExpandedChanged.current = false;
  }
};
