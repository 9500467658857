import { useFetchAccountByIdQuery } from 'models/Account';
import { selectAccountId } from 'models/Account/slice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { normalizeNameSegment } from '../../utility';

const useBuildSMTPAddress = () => {
  const parentId = useSelector<string, string>(selectAccountId);
  const { data: accountData, isLoading: isLoadingAccount } = useFetchAccountByIdQuery({
    id: parentId,
  });

  const buildRealm = useMemo(() => {
    if (isLoadingAccount || !accountData) {
      return () => '';
    }

    return (faxboxName = '') => {
      const names = {
        faxbox: normalizeNameSegment(faxboxName),
        account: normalizeNameSegment(accountData?.name),
        domain: '.fax.voxter.com',
      };

      return Object.values(names).join('');
    };
  }, [isLoadingAccount, accountData]);

  return buildRealm;
};

export default useBuildSMTPAddress;
