import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';
import { ByIdWithAccountIdParam } from 'models/definition';

export const User = {
  // GET
  Fetch: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `accounts/${accountId}/users/?paginate=false`, // TODO: Review GET param usage
  FetchById: ({ accountId = ACCOUNT_ID_URL_PLACEHOLDER, id }: ByIdWithAccountIdParam) =>
    `accounts/${accountId}/users/${id}`,
  FetchHeroAppsById: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER, id: string) =>
    `accounts/${accountId}/users/${id}/hero/apps`,
  ListBySeatType: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `accounts/${accountId}/users/list_by_seat_type`,
  FetchDesktopAppZoneById: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER, id: string) =>
    `accounts/${accountId}/users/${id}/hero_preferences`,

  // PUT
  Create: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${accountId}/users`,
  CreateDesktopAppZone: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER, id: string) =>
    `accounts/${accountId}/users/${id}/hero_preferences`,

  // PATCH / POST
  UpdateById: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER, id: string) =>
    `accounts/${accountId}/users/${id}`,
  UpdateHeroAppsById: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER, id: string) =>
    `accounts/${accountId}/users/${id}/hero/apps`,

  // DELETE
  DeleteById: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER, id: string) =>
    `accounts/${accountId}/users/${id}`,
};
