import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled, { css } from 'styled-components';
import { StyledDrawerContentLowerProps as Props } from './definition';

const getConstants = (theme: any) => ({
  PAGE: {
    MARGIN: `calc(${theme.spacing(3)} * 2)`, // wraps the callflow edit page elements
  },
  LIST: {
    HEIGHT: {
      DIVIDER: '13px', // horizontal line/margin between heading and search box
      HEADER: '36px', // heading
      SEARCH: '40px', // list search (standard field height)
    },
    MARGIN: `calc(${theme.spacing(2)} * 2)`, // wraps the callflow list items
  },
});

const StyledDrawerContentLower = styled.div<Props>`
  ${({ theme }) => {
    const CONSTANTS = getConstants(theme);

    return css`
      height: calc(
        100vh -
          (
            ${HEADER_CONSTANTS.HEIGHT} + ${ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT} +
              ${CONSTANTS.PAGE.MARGIN} + ${CONSTANTS.LIST.HEIGHT.HEADER} +
              ${CONSTANTS.LIST.HEIGHT.DIVIDER} + ${CONSTANTS.LIST.HEIGHT.SEARCH} +
              ${CONSTANTS.LIST.MARGIN}
          )
      );
      margin: ${theme.spacing(2, 1.33, 0, 0)};
      overflow-y: auto;
    `;
  }}
`;

export default StyledDrawerContentLower;
