import { themeColor } from 'theme';
import theme from '../core';

export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      width: 34,
      height: 20,
      margin: 0,
      padding: 0,
      '.MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: theme.transitions.duration.shorter,
        '&.Mui-checked': {
          transform: 'translateX(14px)',
          color: theme.palette.common.white,
          '+ .MuiSwitch-track': {
            backgroundColor: theme.palette.success.main,
            border: 0,
            opacity: 1,
          },
        },
      },
      '.MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
      },
      '.MuiSwitch-track': {
        backgroundColor: themeColor.core.border.color,
        borderRadius: 20 / 2,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
  },
};
