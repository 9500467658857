import Fade from '@mui/material/Fade';
import { themeColor } from 'theme';

export const CONSTANTS = {
  GAP: '4px',
  HEIGHT: {
    MAX: '390px',
  },
  WIDTH: {
    MAX: '260px',
    MIN: '300px',
  },
};

export default {
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    autoFocus: false,
    disableAutoFocusItem: true,
    elevation: 0,
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    TransitionComponent: Fade,
  },
  styleOverrides: {
    root: {
      '&.MuiMenu-header .MuiPaper-root': {
        minWidth: CONSTANTS.WIDTH.MIN,
        backgroundColor: themeColor.core.background.grey.medium,
        borderColor: themeColor.core.divider.default,
        borderRadius: '3px 0 3px 3px',
        borderTop: 'none',
        boxShadow: '0 6px 6px 0 rgb(0 0 0 / 8%)',
        overflowY: 'clip',
        transform: `translate3d(1px, 1px, 0)`,
        '.MuiMenu-list': {
          padding: 0,
          '.MuiMenu-list-container': {
            maxHeight: CONSTANTS.HEIGHT.MAX,
            borderTop: `1px solid ${themeColor.core.divider.default}`,
            overflowY: 'auto',
          },
        },
      },
      '.MuiMenu-list .MuiMenu-list-container li:not(:first-of-type)': {
        margin: `${CONSTANTS.GAP} 0 0`,
      },
    },
  },
};
