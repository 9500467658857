import produce from 'immer';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { getFormEntityValue } from '../helper';

interface Props {
  children: any;
  id: string;
  fetchFunction: any;
  entity: string;
  defaultValues: any;
  transformData?: (data: any) => { [key: string]: any };
}

export default function DataProvider({
  children,
  id,
  fetchFunction,
  entity,
  transformData,
  defaultValues,
}: Props) {
  const { data } = fetchFunction({ id });

  const modifiedValues = useMemo(() => (transformData ? transformData(data) : {}), [
    transformData,
    data,
  ]);

  const { getValues, reset } = useFormContext();

  useEffect(() => {
    const values = produce(getValues(), (draft) => {
      draft[entity][id] = getFormEntityValue(
        draft,
        entity,
        id,
        defaultValues,
        data,
        modifiedValues,
      );
    });

    reset(values, { keepDirty: true });
  }, [data]);

  return children(data);
}
