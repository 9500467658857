import { SESSION_STORAGE } from 'constant';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo } from 'react';
import { HeaderGroup } from 'react-table';
import { useStorage } from '../useStorage';
import { Entry } from './definition';
import { getSortedDesc } from './utility';

export const useSortColumnStorage = ({ tableId }: { tableId?: string } = {}) => {
  const KEY = SESSION_STORAGE.TABLE.SORT.COLUMN;
  const { entries, entryIndex, path, primary, updateEntries } = useStorage({ key: KEY, tableId });

  const hasSortColumn = useMemo(
    () => (entryIndex === -1 ? false : entries[entryIndex].isSortedDesc !== undefined),
    [entries, entryIndex],
  );

  const sortColumn = useMemo(
    () =>
      Array.isArray(entries)
        ? entries[entries.findIndex((entry: Entry) => entry.path === path)]
        : undefined,
    [entries, path],
  );

  const initSortColumn = useCallback(
    ({ sortBy }: { sortBy: Record<string, boolean | string> }) => {
      if (isEmpty(entries) || (!isEmpty(entries) && !path.includes(primary?.path))) {
        sessionStorage.setItem(
          KEY,
          JSON.stringify([{ isSortedDesc: sortBy?.desc, id: sortBy?.id, path }]),
        );
      }
    },
    [KEY, entries, path, primary?.path],
  );

  const setSortColumn = useCallback(
    ({ isSortedDesc: isDesc, id }: Partial<HeaderGroup>) => {
      updateEntries({ entry: { isSortedDesc: getSortedDesc(isDesc), id, path }, key: KEY });
    },
    [KEY, path, updateEntries],
  );

  return { hasSortColumn, sortColumn, initSortColumn, setSortColumn };
};
