import { FunctionComponent } from 'react';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import defaultProps from './default';
import { QuickViewDialogProps as Props } from './definition';
import translations from './translations';

const QuickViewDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isOpen, type, onClose } = { ...defaultProps, ...props };
  const { button, dialog } = translations();

  return (
    <Dialog
      open={isOpen}
      title={dialog[type].title}
      onClose={onClose}
      renderActions={
        <DialogActions hasCancelOnly cancelLabel={button.cancel.label} onAction={onClose} />
      }
    >
      <p>{dialog[type].content}</p>
    </Dialog>
  );
};

export default QuickViewDialog;
