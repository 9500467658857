import { themeColor } from 'theme';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      boxSizing: 'content-box',
      '&.MuiFormControl-autocomplete-filter, &.MuiFormControl-select-filter': {
        width: '100%',
        margin: theme.spacing(0, 0, 2),
        '&.MuiFormControl-error': {
          fieldset: {
            borderColor: themeColor.core.color.red,
          },
        },
        '.MuiInputBase-root': {
          borderRadius: 4,
          '&, > div[role=button]': {
            height: 40,
          },
          fieldset: {
            borderColor: `rgb(210, 210, 210)`,
            borderWidth: 1,
          },
        },
      },
      '&.MuiFormControl-autocomplete-filter': {
        '.MuiInputBase-root': {
          padding: theme.spacing(0, 1, 0, 2),
          button: {
            margin: theme.spacing(0.5, 0, 0),
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:first-of-type': {
              marginRight: 3,
            },
            '&:last-of-type': {
              marginRight: -3,
            },
            svg: {
              margin: 0,
              pointerEvents: 'none',
            },
          },
          input: {
            padding: 0,
          },
        },
      },
      '&.MuiFormControl-select-filter': {
        '.MuiInputBase-root': {
          'div[role=button]': {
            display: 'inline-block',
            padding: theme.spacing(0, 2),
            lineHeight: '40px',
          },
          svg: {
            position: 'absolute',
            right: theme.spacing(1),
            pointerEvents: 'none',
          },
        },
      },
    },
  },
};
