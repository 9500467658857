import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import { EmptySmsViewProps as Props } from './definition';

const EmptySmsView: FunctionComponent<Props> = ({ handleCreateDialogOpen }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('numbers:containers.numbers_in_use.section.sms_enablement.description')}</h3>
      <div role="row">
        <div role="cell">
          <Button variant="contained" size="medium" onClick={handleCreateDialogOpen}>
            {t(
              'numbers:containers.numbers_in_use.section.sms_enablement.create_sms_box_button.label',
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default EmptySmsView;
