import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { InteractionBarType } from '../..';
import { StyledActionProps as Props } from './definition';

export const CONSTANTS = {
  ICON: {
    SCALE: {
      DEFAULT: 0.85,
      HOVER: 1.05,
    },
  },
  HEIGHT: {
    DEFAULT: 50,
    QUICK_VIEW: 36,
  },
  WIDTH: {
    DEFAULT: 66,
    QUICK_VIEW: 54,
    EXTRA_WIDE: 76,
  },
};

const StyledAction = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex({ direction: 'column' })};
    ${mixin.transition({ property: 'background-color', duration: 'medium' })};
    width: ${props.type === InteractionBarType.DEFAULT && props.isWide
      ? CONSTANTS.WIDTH.EXTRA_WIDE
      : CONSTANTS.WIDTH[props.type]}px;
    height: ${CONSTANTS.HEIGHT[props.type]}px;
    padding: ${props.theme.spacing(1)};
    background-color: inherit;
    user-select: none;

    .component-action-icon {
      ${mixin.transition({ property: 'transform', duration: 'medium' })};
      transform: scale(${props.icon?.scale?.default ?? CONSTANTS.ICON.SCALE.DEFAULT});

      svg {
        ${mixin.transition({ property: 'fill', duration: 'medium' })};
        fill: ${props.theme.core.background.grey.dark};
      }

      ${props.type === InteractionBarType.DEFAULT &&
      css`
        margin: 0 0 2px;
      `};
    }

    &:first-of-type {
      ${mixin.borderRadius({ radius: 'default 0 0 default' })};
    }

    &:last-of-type {
      ${mixin.borderRadius({ radius: '0 default default 0' })};
    }

    ${!props.isDisabled
      ? css`
          cursor: pointer;

          &:hover {
            background-color: ${props.theme.whitelabel.secondary.color};

            .component-action-icon {
              transform: scale(${props.icon?.scale?.hover ?? CONSTANTS.ICON.SCALE.HOVER});

              svg {
                fill: ${props.theme.core.color.white};
              }
            }

            .component-action-label {
              color: ${props.theme.core.color.white};
            }
          }
        `
      : css`
          .component-action-icon svg {
            fill: ${props.theme.core.color.grey.disabled};
          }

          .component-action-label {
            color: ${props.theme.core.color.grey.disabled};
          }
        `}

    ${props.type === InteractionBarType.DEFAULT &&
    css`
      --padding: ${props.theme.spacing(2.5)};

      ${props.hasPadding?.left &&
      css`
        padding-left: var(--padding);
      `}

      ${props.hasPadding?.right &&
      css`
        padding-right: var(--padding);
      `}
    `};
  `};
`;

export default StyledAction;
