import styled, { css } from 'styled-components';
import * as mixin from '../../utility/mixin';
import { TABLE_CONSTANTS } from '../Table';
import { StyledEditableListProps as Props } from './definition';

const CONSTANTS = {
  TBODY: {
    CELL: '280px',
  },
};

const StyledEditableList = styled.table<Props>`
  ${({ theme }) => css`
    ${mixin.borderRadius({ radius: 'half' })}
    border-spacing: 0;

    --padding: ${theme.spacing(2)};

    tbody tr td,
    tfoot tr td,
    thead tr th {
      padding: 0 var(--padding);
      background-color: ${theme.core.color.white};
      border-top: ${TABLE_CONSTANTS.BORDER};
      border-left: ${TABLE_CONSTANTS.BORDER};

      &:last-of-type {
        width: ${TABLE_CONSTANTS.WIDTH.ICON.ACTION};
        min-width: ${TABLE_CONSTANTS.WIDTH.ICON.ACTION};
        border-right: ${TABLE_CONSTANTS.BORDER};
        text-align: center;
      }
    }

    tbody tr td,
    thead tr th {
      height: ${TABLE_CONSTANTS.HEIGHT.CELL.TBODY};
    }

    tbody tr,
    tfoot tr {
      td:not(:last-of-type) {
        min-width: ${`calc(${CONSTANTS.TBODY.CELL} - var(--padding) * 2)`};
      }
    }

    tbody tr td:first-of-type {
      ${mixin.flex({ justifyContent: 'flex-start' })};

      svg {
        margin: ${theme.spacing(0, 1.5, 0, 0)};
      }
    }

    tfoot tr td {
      height: ${TABLE_CONSTANTS.HEIGHT.CELL.THEAD};
      padding: calc(var(--padding) / 2) var(--padding);
      border-bottom: ${TABLE_CONSTANTS.BORDER};

      &:first-of-type {
        ${mixin.borderRadius({ radius: '0 0 0 half' })}
      }

      &:last-of-type {
        ${mixin.borderRadius({ radius: '0 0 half 0' })}
      }

      > * {
        display: inline-block;

        &:not(:last-of-type) {
          margin: ${theme.spacing(0, 1.5, 0, 0)};
        }
      }
    }

    thead tr th {
      font-weight: 600;

      &:first-of-type {
        ${mixin.borderRadius({ radius: 'half 0 0 0' })}
      }

      &:last-of-type {
        ${mixin.borderRadius({ radius: '0 half 0 0' })}
      }

      &.center {
        text-align: center;
      }

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }
    }
  `}
`;

export default StyledEditableList;
