import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class CallerIdDynamicActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.CALLER_ID_DYNAMIC,
      module: CallFlowModuleKey.CALLER_ID_DYNAMIC,
      section: CallFlowSection.CALLER_ID,
      hasDialog: false,
      rules: [],
      iconProps: {
        name: 'phone-dynamic-filled',
      },
      label: i18next.t('phone_system:containers.callflows.actions.caller_id_dynamic.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.caller_id_dynamic.tooltip'),
    });
  }
}

export default CallerIdDynamicActionConfig;
