import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';
import { Metadata } from '../store/definition';

class ResponseActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.RESPONSE,
      module: CallFlowModuleKey.RESPONSE,
      section: CallFlowSection.ADVANCED,
      rules: [
        {
          type: 'quantity',
          maxSize: 0,
        },
      ],
      iconProps: {
        name: 'reply-outlined',
      },
      label: i18next.t('phone_system:containers.callflows.actions.response.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.response.tooltip'),
    });
  }

  static subtitle = (nodeData: any, metadata: Metadata) =>
    i18next.t('phone_system:containers.callflows.actions.response.subtitle', {
      code: nodeData?.code,
    });
}

export default ResponseActionConfig;
