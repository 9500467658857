import { FunctionComponent } from 'react';
import DrawerLower from './components/DrawerLower';
import DrawerUpper from './components/DrawerUpper';
import defaultProps from './default';
import { DrawerProps as Props } from './definition';
import StyledDrawer from './style';

export { CONSTANTS as DRAWER_CONSTANTS } from './components/constant';
export { style } from './components/mixin';
export { default as drawerProps } from './default';

const Drawer: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { testId, children, hasPadding, isOpen, width, height, style, headerProps }: Props = {
    ...defaultProps,
    ...props,
  };

  return (
    <StyledDrawer data-test-id={testId} className={isOpen ? 'is-open' : ''} style={style?.main}>
      <DrawerUpper
        hasPadding={hasPadding}
        isOpen={isOpen}
        width={width}
        height={height}
        headerProps={headerProps}
      >
        {children}
      </DrawerUpper>
      <DrawerLower isOpen={isOpen} height={height} headerProps={headerProps} style={style?.tab} />
    </StyledDrawer>
  );
};

export default Drawer;
