import { KeyValue, NormalizedData, Variable } from './definition';

export const extractKeys = (values: KeyValue): number[] => [
  ...new Set([...Object.entries(values).map((value) => +value[0].split('-')[1])]),
];

export const normalizeData = (data: Variable[]): NormalizedData[] =>
  data.map((key: any) => ({
    key: key[0],
    type: key[1].type,
    value: key[1].value,
  }));

export const extractDefaultVariables = (dataNormalized: NormalizedData[]) =>
  dataNormalized.reduce(
    (previousValue: KeyValue, currentValue: NormalizedData, currentIndex: number) => ({
      ...previousValue,
      [`key-${currentIndex}`]: currentValue.key,
      [`type-${currentIndex}`]: currentValue.type,
      [`value-${currentIndex}`]: currentValue.value,
    }),
    {},
  );

export const formatFormData = (keys: number[], values: KeyValue): Variable =>
  keys.reduce(
    (previousValue: Variable, key: number) => ({
      ...previousValue,
      [values[`key-${key}`]]: {
        type: values[`type-${key}`],
        value: values[`value-${key}`],
      },
    }),
    {},
  );

export const extractMaxKey = (arr: number[]) => (arr.length ? Math.max(...arr) + 1 : 0);
