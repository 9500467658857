import i18next from 'i18next';

const translations = () => ({
  presence_id: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.manual_presence.add.id',
    ),
  },
  status: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.manual_presence.add.status',
    ),
  },
  statuses: [
    { value: 'idle', label: i18next.t('common:phone_status.idle') },
    { value: 'ringing', label: i18next.t('common:phone_status.ringing') },
    { value: 'busy', label: i18next.t('common:phone_status.busy') },
  ],
  title: i18next.t(
    'phone_system:containers.callflows.callflow_action_dialog.manual_presence.title',
  ),
});

export default translations;
