import i18next from 'i18next';
import Joi from 'joi';
import { THRESHOLD_KEY, WAIT_TIME_OPTIONS } from '../../constants';

const schema = () =>
  Joi.object({
    key: Joi.string()
      .valid(...WAIT_TIME_OPTIONS)
      .required()
      .messages({
        'string.empty': i18next.t('common:validation.general.required'),
        'any.only': i18next.t('common:validation.general.required'),
      }),
    threshold: Joi.when(Joi.ref('key'), {
      is: Joi.boolean().valid(THRESHOLD_KEY),
      then: Joi.number()
        .min(0)
        .required()
        .messages({
          'string.empty': i18next.t('common:validation.general.required'),
          'number.base': i18next.t('common:validation.general.required'),
        }),
      otherwise: Joi.optional(),
    }),
  });

export default schema;
