import { RouterLink } from 'apps/shared/components/RouterLink';
import { columns, transformConnectivityDataForTable } from 'apps/SipTrunking/utility';
import { useFetchAllConnectivityQuery } from 'models/Connectivity';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';

// TODO: fix app component lazy chunk loading
// TODO: test fetchAllConnectivity query
// TODO: Confirm sip channel data property with Aisling
// TODO: add 2nd ip address when available
// TODO: check pagination position - @Paul to work
// TODO: show data count - @Paul to work
const List: FunctionComponent = (): JSX.Element => {
  const { data: allConnectivityData, isLoading } = useFetchAllConnectivityQuery();
  const { data: phoneNumbersData } = useFetchPhoneNumbersQuery();
  const { t } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table
      title={t('sip_trunking:containers.all_trunks.label')}
      columns={columns().trunks}
      data={transformConnectivityDataForTable(allConnectivityData, phoneNumbersData?.numbers)}
      hasSearch
      addButton={{
        hasAdd: true,
        component: (
          <Button
            // TODO: fix this later
            // @ts-ignore
            component={RouterLink}
            to="add"
            color="secondary"
            variant="contained"
            startIcon={<Icon name="plus-circle-outlined" size={18} />}
          >
            {t('sip_trunking:component.table.action_row.button.add_trunk.label')}
          </Button>
        ),
      }}
    />
  );
};

export default List;
