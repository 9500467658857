import { api } from 'api/provisioner';
import { responseHandler, service, TAGS } from 'services/legacyProvisioner';
import { Phones } from './types';

export const provisionerQueries = service.injectEndpoints({
  endpoints: (builder) => ({
    fetchPhones: builder.query<Phones, void>({
      query: () => ({
        url: api.FetchPhones(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.PHONES }],
    }),
  }),
});
