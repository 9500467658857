import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { style } from '../../../style';
import { StyledAppPickerHeadProps as Props } from './definition';

const StyledAppPickerHead = styled(Box)<Props>`
  ${({ $isOpen: isOpen, theme }) => css`
    ${style.item({ isOpen, theme }).default};
    ${style.item({ isOpen, theme }).toggleable.head};
  `}
`;

export default StyledAppPickerHead;
