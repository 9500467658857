import i18next from 'i18next';
import { CallerInfoMatchRules } from '../CallerInfoMatchManageDialog/definition';

export const getSearchByValue = (rule_name: string, search_url: string) => {
  if (
    rule_name.endsWith('ByNumber') ||
    search_url.includes('{{base64_number}}') ||
    search_url.includes('{{number}}')
  ) {
    return 'number';
  }
  return 'name';
};

export const getDisplayName = (rule_name: string, display_name: string) => {
  const customSuffix = i18next.t(
    'phone_system:containers.account.submodule.settings.section.caller_info_match.custom',
  );
  const isDisplayNameCustom = display_name.includes(customSuffix);
  const isRuleCustom = rule_name === 'custom';

  if (isRuleCustom && !isDisplayNameCustom) {
    return display_name.concat(' ', customSuffix);
  }
  return display_name;
};

export const getPresetRules = (): CallerInfoMatchRules => {
  return {
    googleByName: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.google_by_name',
      ),
      rule_name: 'googleByName',
      search_url: 'https://www.google.com/search?q={{name}}',
      test_url: 'https://www.google.com',
    },
    googleByNumber: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.google_by_number',
      ),
      rule_name: 'googleByNumber',
      search_url: 'https://www.google.com/search?q={{number}}',
      test_url: 'https://www.google.com',
    },
    facebookByName: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.facebook_by_name',
      ),
      rule_name: 'facebookByName',
      search_url: 'https://www.facebook.com/search/top?q={{name}}',
      test_url: 'https://www.facebook.com/login/',
    },
    linkedinByName: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.linkedin_by_name',
      ),
      rule_name: 'linkedinByName',
      search_url: 'https://www.linkedin.com/search/results/all/?keywords={{name}}',
      test_url: 'https://www.linkedin.com/login',
    },
    salesforceClassicByName: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.salesforce_classic_by_name',
      ),
      rule_name: 'salesforceClassicByName',
      search_url:
        'https://<instance name>.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?searchType=2&str={{name}}',
      test_url: 'https://login.salesforce.com/',
    },
    salesforceClassicByNumber: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.salesforce_classic_by_number',
      ),
      rule_name: 'salesforceClassicByNumber',
      search_url:
        'https://<instance name>.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?searchType=2&str={{number}}',
      test_url: 'https://login.salesforce.com/',
    },
    salesforceLightningByName: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.salesforce_lightning_by_name',
      ),
      rule_name: 'salesforceLightningByName',
      search_url: 'https://<instance name>.lightning.force.com/one/one.app#{{base64_name}}',
      test_url: 'https://login.salesforce.com/',
    },
    salesforceLightningByNumber: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.salesforce_lightning_by_number',
      ),
      rule_name: 'salesforceLightningByNumber',
      search_url: 'https://<instance name>.lightning.force.com/one/one.app#{{base64_number}}',
      test_url: 'https://login.salesforce.com/',
    },
    hubspotByName: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.hubspot_by_name',
      ),
      rule_name: 'hubspotByName',
      search_url:
        'https://app.hubspot.com/contacts/<organization number>/contacts/list/view/all/?query={{name}}',
      test_url: 'https://app.hubspot.com/login',
    },
    hubspotByNumber: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.hubspot_by_number',
      ),
      rule_name: 'hubspotByNumber',
      search_url:
        'https://app.hubspot.com/contacts/<organization number>/contacts/list/view/all/?query={{number}}',
      test_url: 'https://app.hubspot.com/login',
    },
    zohoByName: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.zoho_by_name',
      ),
      rule_name: 'zohoByName',
      search_url: 'https://crm.zoho.com/crm/org <organization number>/search?searchword={{name}}',
      test_url: 'https://accounts.zoho.com/signin',
    },
    zohoByNumber: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.zoho_by_number',
      ),
      rule_name: 'zohoByNumber',
      search_url: 'https://crm.zoho.com/crm/org <organization number>/search?searchword={{number}}',
      test_url: 'https://accounts.zoho.com/signin',
    },
    leadMasterByNumber: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.leadmaster_by_number',
      ),
      rule_name: 'leadMasterByNumber',
      search_url:
        'https://app4.leadmastercrm.com/lm_sp2.asp?w=<organization number>&phone={{number}}',
      test_url: 'https://websvr1app4.leadmastercrm.com/index_logon_dlg.asp',
    },
    clioByName: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.clio_by_name',
      ),
      rule_name: 'clioByName',
      search_url: 'https://app.clio.com/nc/#/search?query={{name}}',
      test_url: 'https://account.clio.com/login',
    },
    clioByNumber: {
      display_name: i18next.t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.rules.clio_by_number',
      ),
      rule_name: 'clioByNumber',
      search_url: 'https://app.clio.com/nc/#/search?query={{number}}',
      test_url: 'https://account.clio.com/login',
    },
    custom: {
      display_name: '',
      rule_name: 'custom',
      search_url: '',
      test_url: '',
    },
  };
};
