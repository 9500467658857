import { joiResolver } from '@hookform/resolvers/joi';
import withNavigation from 'apps/shared/hocs/withNavigation';
import EditForm from 'apps/SipTrunking/components/EditForm';
import { extractTrunkId, transformConnectivityDataForTable } from 'apps/SipTrunking/utility';
import { ADD_KEY } from 'constant';
import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import merge from 'lodash/merge';
import { selectAccountId } from 'models/Account/slice';
import {
  useCreateConnectivityMutation,
  useFetchAllConnectivityQuery,
  useUpdateConnectivityByIdMutation,
} from 'models/Connectivity';
import { Connectivity } from 'models/Connectivity/types';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { FieldErrors, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import { EditFormProps as Props } from '../../definition';
import { defaultFormValues, defaultTrunkData, FormInput, schema } from './definition';
import Form from './Form';

const getCurrentTrunkData = (
  allConnectivityData: any,
  paramId: string,
  phoneNumbers: Record<string, any>,
) =>
  find(
    transformConnectivityDataForTable(allConnectivityData, phoneNumbers),
    (currentData) => currentData.id === paramId,
  );

// TODO: fix 'any'
const Edit: FunctionComponent<any> = ({
  id: paramId,
  handleDeleteSuccess,
  handleSaveSuccess,
}: Props): JSX.Element => {
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { t } = useTranslation();
  const accountId = useSelector<string, string>(selectAccountId);
  const { data: allConnectivityData, isLoading } = useFetchAllConnectivityQuery();
  const { data: phoneNumbersData } = useFetchPhoneNumbersQuery();
  const [updateConnectivity] = useUpdateConnectivityByIdMutation();
  const [createConnectivity] = useCreateConnectivityMutation();
  const realm = allConnectivityData?.[0]?.account?.auth_realm;
  const data = getCurrentTrunkData(allConnectivityData, paramId, phoneNumbersData?.numbers);
  const isAdd = paramId === ADD_KEY;

  const formMethods = useForm<FormInput>({
    resolver: joiResolver(schema()),
    defaultValues: defaultFormValues,
  });

  const {
    formState: { dirtyFields },
    handleSubmit,
    reset,
    setError,
  } = formMethods;

  const isPageDirty = checkFormEntities(dirtyFields);

  const onDelete = async () => {
    try {
      const updatedConnectivityData: Connectivity = produce(
        allConnectivityData?.[0],
        ({ servers }: { servers: Array<Record<any, any>> }) => {
          const trunkId = extractTrunkId(paramId);
          if (trunkId > -1) {
            servers.splice(trunkId, 1);
          }
        },
      );

      const response: any = await updateConnectivity({
        id: updatedConnectivityData.id,
        body: updatedConnectivityData,
      });

      if (response.data) {
        handleDeleteSuccess?.();
      }
    } catch (exception) {
      showErrorMessage({ isFromException: true, errors: exception, setError });
    }
  };

  const onError: SubmitErrorHandler<FormInput> = useCallback(
    (errors: FieldErrors) => showErrorMessage({ errors, setError }),
    [setError, showErrorMessage],
  );

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (formData: FormInput) => {
      try {
        const newTrunkData = produce(
          merge({}, defaultTrunkData, formData.server),
          ({ options, auth }: any) => {
            /* eslint-disable no-param-reassign */
            options.media_handling = options.compatibilityMode ? 'process' : 'bypass';
            delete options.compatibilityMode;

            if (auth) {
              if (auth.auth_method === 'IP') {
                delete auth.auth_user;
                delete auth.auth_password;
              } else {
                delete auth.ip;
              }
            }

            options.flags = Array.isArray(options.flags) ? options.flags : [options.flags];
          },
        );

        const connectivityData = cloneDeep(allConnectivityData?.[0]);

        if (isAdd) {
          connectivityData.servers.push(newTrunkData);
          handleSaveSuccess?.({ hasToast: false, shouldRedirect: true });
          showToast.success(t('sip_trunking:containers.all_trunks.save.success'));
        } else {
          const updatedServers = connectivityData.servers.map((server: any, index: number) =>
            index === extractTrunkId(formData.id) ? newTrunkData : server,
          );
          connectivityData.servers = updatedServers;
          showToast.success();
        }

        await updateConnectivity({
          id: connectivityData.id,
          body: connectivityData,
        }).unwrap();
      } catch (exception) {
        showErrorMessage({ isFromException: true, errors: exception, setError });
      }
    },
    [
      isAdd,
      allConnectivityData,
      showToast,
      handleSaveSuccess,
      setError,
      showErrorMessage,
      t,
      updateConnectivity,
    ],
  );

  useEffect(() => {
    // Fill form data from API response when editing a Trunk
    if (data) {
      reset(data);
    } else if (realm) {
      // This updates realm when creating a new trunk
      reset(
        merge({}, defaultFormValues, {
          account: {
            auth_realm: realm,
          },
        }),
      );
    }
  }, [data?.id, realm, reset]);

  // This creates the connectivity data when no account trunk is found
  useEffect(() => {
    (async () => {
      const connectivityData = cloneDeep(allConnectivityData?.[0]);
      if (isAdd && !isLoading && !connectivityData) {
        try {
          const newConnectivityData = {
            account: {
              credits: {
                prepay: '0.00',
              },
              trunks: 0,
              inbound_trunks: 0,
              auth_realm: realm,
            },
            billing_account_id: accountId,
            DIDs_Unassigned: {},
            servers: [],
          };

          await createConnectivity({
            accountId,
            body: newConnectivityData,
          });
        } catch (exception) {
          showErrorMessage({ errors: exception, setError });
        }
      }
    })();
  }, [
    isAdd,
    isLoading,
    accountId,
    allConnectivityData,
    realm,
    createConnectivity,
    setError,
    showErrorMessage,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditForm
      deleteButton={{
        label: t('sip_trunking:containers.all_trunks.button.delete'),
        onClick: (!isAdd && onDelete) || undefined,
      }}
      isPageDirty={isPageDirty}
      entityLabel={t('sip_trunking:containers.all_trunks.label')}
      entityName={
        (data?.server?.server_name as string) || t('sip_trunking:containers.all_trunks.new_pbx')
      }
      onSave={handleSubmit(onSubmit, onError)}
      formMethods={formMethods}
    >
      <Form id={paramId} data={data} />
    </EditForm>
  );
};

export default withNavigation(Edit, true);
