import { Location } from 'apps/PhoneSystem/containers/Account/SiteLocations/List';
import { SelectOption } from 'shared/components/Select';

export const initLocationOptions = ({ locations }: { [key: string]: Location }) =>
  Object.entries(locations)
    .map(
      ([id, location]: [string, any]): SelectOption => ({
        label: location.display_name,
        value: id,
      }),
    )
    .sort((a: SelectOption, b: SelectOption) => a.label?.localeCompare(b.label));

/**
 * @name buildCallerIdName
 * @description Builds a caller ID name based on first name and last name
 *
 * @param firstName - The user's first name
 * @param lastName - The user's last name
 * @returns - The caller ID name
 */
export const buildCallerIdName = (firstName: string | undefined, lastName: string | undefined) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return firstName || lastName || '';
};
