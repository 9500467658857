import { RouterLink } from 'apps/shared/components/RouterLink';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { ManageLinkProps as Props } from './definition';

const ManageLink = ({ linkTo, value }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Box component="span" mr={3}>
        {value}
      </Box>
      <RouterLink to={linkTo}>{t('common:manage')}</RouterLink>
    </>
  );
};

export default ManageLink;
