import {
  createArrayValue,
  createTranslateChangeHandler,
} from 'apps/PhoneSystem/components/EditForm/helper';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['name', 'member.pins'];

const BasicsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div role="row">
      <div role="cell">
        {/* Confernece Name */}
        <HookFormInputWrapper name="name">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              id="input-basic-conference-name"
              label={t('phone_system:containers.conferences.section.basics.name')}
              inputProps={{
                ...formProps,
              }}
              feedback={feedback}
              isDirty={isDirty}
            />
          )}
        </HookFormInputWrapper>

        {/* Assign To */}
        {/*
        <SeatSelect
          name="owner_id"
          label={t('phone_system:containers.conferences.section.basics.assign_to')}
        />
        */}

        {/* PINs */}
        <HookFormInputWrapper name="member.pins">
          {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
            <LabeledInput
              label={t('phone_system:containers.conferences.section.basics.pin.label')}
              inputProps={{
                value: createArrayValue(value),
                onChange: createTranslateChangeHandler(onChange),
                ...formProps,
                placeholder: t(
                  'phone_system:containers.conferences.section.basics.pin.placeholder',
                ),
                id: 'input-basic-pins',
              }}
              feedback={feedback}
              isDirty={isDirty}
            />
          )}
        </HookFormInputWrapper>
      </div>
    </div>
  );
};

export default BasicsSection;
