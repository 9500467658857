import MuiInputLabel from '@mui/material/InputLabel';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiOutlinedInput from '@mui/material/OutlinedInput';
import MuiSelect from '@mui/material/Select';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import { FilterOption, useFilterOptions } from '../../hooks/useFilterOptions';
import { FilterValues } from '../definition';
import { SelectProps as Props } from './definition';
import { getClasses, getIconComponent, getRenderValue } from './utility';

const Select = ({ hasAllOption, filter: { type, values }, options, setFilterValues }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { filterOptions } = useFilterOptions({ hasAllOption, options });

  const id = `${type}-filter-select-select-label`;
  const selectedValues = values.selected[type];

  const onChange = ({ target: { checked, value } }: ChangeEvent<HTMLInputElement>) => {
    setFilterValues((filterValues: FilterValues) =>
      produce(filterValues, (draft: any) => {
        if (value === FilterOption.All) {
          draft.selected[type] = [];
          if (checked) {
            filterOptions.forEach(({ value }: SelectOption) => {
              draft.selected[type].push(value);
            });
          }
        } else if (checked) {
          draft.selected[type].push(value);
          if (value !== FilterOption.All && draft.selected[type].length === options.length) {
            draft.selected[type].push(FilterOption.All);
          }
        } else {
          draft = remove(
            draft.selected[type],
            (item: string) =>
              item === value || (value !== FilterOption.All && item === FilterOption.All),
          );
        }
      }),
    );
  };

  return (
    <>
      {isEmpty(selectedValues) && (
        <MuiInputLabel
          classes={{ root: 'MuiInputLabel-select-filter' }}
          data-test-id={id}
          id={id}
          shrink={false}
        >
          {t('common:component.filter_select.please_select')}
        </MuiInputLabel>
      )}
      <MuiSelect
        multiple
        open={isOpen}
        data-test-id="filter-select-select"
        input={<MuiOutlinedInput notched={false} />}
        labelId={id}
        renderValue={(value: Array<string>) => getRenderValue(options, value)}
        value={selectedValues}
        IconComponent={() => getIconComponent(isOpen)}
        MenuProps={{
          classes: {
            list: 'MuiList-select-filter',
            paper: 'MuiPaper-select-filter',
          },
        }}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      >
        {filterOptions.map(({ label, value }: SelectOption) => (
          <MuiMenuItem classes={{ root: getClasses(hasAllOption) }} key={`menu-item-${value}`}>
            <LabeledCheckbox
              hasMargin={false}
              checkboxProps={{
                checked: selectedValues.includes(value),
                value,
                onChange,
              }}
              dataTestId={`checkbox-${value}`}
              indentWidth="none"
              label={label}
            />
          </MuiMenuItem>
        ))}
      </MuiSelect>
    </>
  );
};

export default Select;
