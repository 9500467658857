import { DefaultData } from './definition';

const defaultData: DefaultData = {
  scheduled_maintenances: [
    // {
    //   name: 'Scheduled Maintenance #1',
    //   shortlink: '#',
    // 	scheduled_for: Date.now(),
    // },
    // {
    //   name: 'Scheduled Maintenance #2',
    //   shortlink: '#',
    // 	scheduled_for: Date.now(),
    // },
  ],
  status: {
    indicator: 'none',
    description: 'All Systems Operational',
  },
};

export default defaultData;
