import { useFetchQueueQuery } from 'models/Queues';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableListData } from 'shared/components/EditableList';
import { LabeledEditableList } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select/components/Option/definition';
import { LabeledEntitiesListProps as Props } from './definition';

const QueueMembershipEditableList = ({ data, onChange, isDirty }: Props) => {
  const { t } = useTranslation();
  const { data: queueData } = useFetchQueueQuery();

  const rows = useMemo(() => {
    return (
      data &&
      queueData &&
      data
        // filter to remove all the queue_id that doesn't exit anymore on the Queue List data
        ?.filter((queueId: string) => queueData?.some((queueItem) => queueItem.id === queueId))
        .map((queueId: string) => {
          const queue = queueData?.find((queueItem) => queueItem.id === queueId);

          return [{ label: queue?.name || 'abc', value: queueId }];
        })
    );
  }, [data, queueData]);

  const listData: EditableListData = {
    columns: [
      {
        header: {
          text: t(
            'phone_system:containers.seats.section.call_center_options.queue_membership.title',
          ),
        },
        select: [
          {
            isFiltered: true,
            options: queueData?.map((queue) => ({
              value: queue.id,
              label: queue.name,
            })),
            size: 'large',
          },
        ],
      },
    ],
    rows: rows || [],
  };

  return (
    <LabeledEditableList
      isDirty={isDirty}
      editableListProps={{
        data: listData,
        onUpdate: (list: SelectOption[][]) =>
          onChange(list.map(([item]: SelectOption[]) => item.value)),
      }}
      labelWidth="none"
    />
  );
};

export default QueueMembershipEditableList;
