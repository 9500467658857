import { useCallback, useMemo, useState } from 'react';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import { DialogContext, DialogProps } from '.';
import defaultProps from './default';

export const DialogContextProvider = ({ children }: any) => {
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    ...defaultProps,
  });

  const close = () => setDialogProps({ open: false });

  const onClose = useCallback((newDialog?: DialogProps): void => {
    setDialogProps((prevDialog) => ({ ...prevDialog, ...newDialog }));
    close();
  }, []);

  const onOpen = useCallback(
    (newDialog: DialogProps): void =>
      setDialogProps((prevDialog) => ({
        ...prevDialog,
        onClose: () => close(),
        ...newDialog,
        open: true,
      })),
    [],
  );

  const { onConfirm, confirmButtonLabel, confirmMessage, ...rest } = dialogProps;

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }): void => {
    if (closeResponse.reason === 'deleteClicked') {
      onConfirm?.();
    }
    setDialogProps((prevDialog) => ({ ...prevDialog, open: false }));
    close();
  };

  const value = useMemo(() => ({ onClose, onOpen }), [onClose, onOpen]);

  return (
    <DialogContext.Provider value={value}>
      <Dialog
        renderActions={
          <DialogActions hasDelete deleteLabel={confirmButtonLabel} onAction={handleDialogAction} />
        }
        {...rest}
      >
        <span>{confirmMessage}</span>
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
};

export default DialogContextProvider;
