import CallflowContext from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import { DragSourceType } from 'models/Callflow/store/definition';
import { selectBranch, selectNode } from 'models/Callflow/store/selectors';
import { FunctionComponent, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Draggable from 'shared/components/Draggable';
import FlowArrow from '../FlowArrow';
import FlowChild from './components/FlowChild';
import FlowChildren from './components/FlowChildren';
import { FlowBranchProps as Props } from './definition';

const FlowBranch: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { id, refNodeCount }: Props = props;
  const {
    callflow: { id: callflowId, isActivated: isDragging },
    isPreview,
  } = useContext(CallflowContext);
  const node = useSelector((state) => selectBranch(state, callflowId, id));
  const parentNode = useSelector((state) => selectNode(state, callflowId, node.parentId));

  const draggableProps = {
    id,
    disabled: isPreview,
    data: {
      id,
      type: 'branch',
      source: DragSourceType.TREE,
    },
  };

  useEffect(() => {
    refNodeCount.current += 1;
  }, []);

  return (
    <>
      <FlowArrow node={node} parentNode={parentNode} isDragging={isDragging} />
      <Draggable {...draggableProps}>
        <FlowChild node={node} parentNode={parentNode} />
        <FlowChildren columns={Object.values(node.children).length}>
          <>
            {Object.values(node.children).map((childId: any, i: number) => (
              <div key={i}>
                {Object.values(node.children).length > 1 && <div className="branch-segment"></div>}
                <FlowBranch id={childId} refNodeCount={refNodeCount} />
              </div>
            ))}
          </>
        </FlowChildren>
      </Draggable>
    </>
  );
};

export default FlowBranch;
