// TimeOfDay/index.ts
import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { timeOfDayMutations } from './Mutations';
import { timeOfDayQueries } from './Queries';

const VALID: Record<string, string[]> = {
  CYCLE: ['date', 'weekly', 'monthly', 'yearly'], // removed unused 'daily'
  ORDINAL: ['first', 'second', 'third', 'fourth', 'fifth', 'last', 'every', 'range'],
  WDAYS: ['monday', 'tuesday', 'wensday', 'thursday', 'friday', 'saturday', 'sunday'], // removed unused 'wednesday'
};

export const schema = () =>
  Joi.object({
    name: Joi.string().min(1).max(128).required(),
    cycle: Joi.string()
      .valid(...VALID.CYCLE)
      .required(),
    start_date: Joi.date().required(),
    time_window_start: Joi.date().required(),
    time_window_stop: Joi.date().required(),
    interval: Joi.when(Joi.ref('cycle'), {
      is: Joi.not('yearly'),
      then: Joi.number().min(1).required(),
      otherwise: Joi.optional(),
    }),
    month: Joi.when(Joi.ref('cycle'), {
      is: Joi.valid('yearly'),
      then: Joi.number().min(1).max(12).required(),
      otherwise: Joi.optional(),
    }),
    ordinal: Joi.when(Joi.ref('cycle'), {
      switch: ['monthly', 'yearly'].map((cycle: string) => ({
        is: Joi.valid(cycle),
        then: Joi.string()
          .valid(...VALID.ORDINAL)
          .required(),
      })),
      otherwise: Joi.optional(),
    }),
    days: Joi.when(Joi.ref('cycle'), {
      switch: ['monthly', 'yearly'].map((cycle: string) => ({
        is: Joi.valid(cycle),
        then: Joi.when(Joi.ref('ordinal'), {
          switch: ['every', 'range'].map((ordinal: string) => ({
            is: Joi.valid(ordinal),
            then: Joi.array().items(Joi.number().min(1).max(31).required()),
          })),
          otherwise: Joi.optional(),
        }),
      })),
      otherwise: Joi.optional(),
    }),
    wdays: Joi.when(Joi.ref('cycle'), {
      switch: ['monthly', 'yearly'].map((cycle: string) => ({
        is: Joi.valid(cycle),
        then: Joi.when(Joi.ref('ordinal'), {
          switch: ['first', 'second', 'third', 'fourth', 'fifth', 'last'].map(
            (ordinal: string) => ({
              is: Joi.valid(ordinal),
              then: Joi.string()
                .valid(...VALID.WDAYS)
                .required(),
            }),
          ),
          otherwise: Joi.optional(),
        }),
      })),
      otherwise: Joi.optional(),
    }),
    end_date: Joi.when(Joi.ref('has_no_end_date'), {
      is: Joi.boolean().valid(false),
      then: Joi.date().required(),
      otherwise: Joi.optional(),
    }),
  })
    .messages(
      messages({
        'date.base': i18next.t('common:validation.date.base'),
      }),
    )
    .unknown(true);

export const { useFetchTimeOfDayQuery, useFetchTimeOfDayByIdQuery } = timeOfDayQueries;
export const {
  useCreateTimeOfDayMutation,
  useDeleteTimeOfDayByIdMutation,
  useUpdateTimeOfDayByIdMutation,
} = timeOfDayMutations;
