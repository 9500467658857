import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { StyledBulletProps as Props } from './definition';

const StyledBullet = styled(Box)<Props>`
  ${({ $isDefault }) => css`
    display: inline-block;
    height: 16px;

    ${$isDefault
      ? css`
          position: relative;
          top: 1px;
          padding: 0 6.75px;
          font-size: 18px;
        `
      : css`
          padding: 1px 3px;

          > svg {
            position: relative;
            top: 3px;
          }
        `}
  `};
`;

export default StyledBullet;
