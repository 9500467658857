import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import DashboardPanel from '../DashboardPanel';
import CountList from '../shared/components/CountList';
import { CountList as ICountList } from '../shared/components/CountList/definition';
import { DashboardPanelNumbersProps as Props } from './definition';

const DashboardPanelNumbers: FunctionComponent<Props> = (): JSX.Element => {
  const [seatData, setSeatData] = useState<ICountList>({});
  const { t } = useTranslation();

  const { data, isLoading } = useFetchPhoneNumbersQuery();

  // t(`phone_system:containers.dashboard.panel.numbers.types.assigned`)
  // t(`phone_system:containers.dashboard.panel.numbers.types.spare`)

  useEffect(() => {
    if (data) {
      const usageCount = Object.keys(data.usage).reduce((acc: any, val) => {
        acc[t(`phone_system:containers.dashboard.panel.numbers.types.${val}`)] = Object.values(
          data.usage[val],
        ).length;
        return acc;
      }, {});
      setSeatData(usageCount);
    }
  }, [data]);

  if (isLoading) return <Loading />;

  return (
    <DashboardPanel type="numbers">
      <CountList hasButtonMargin list={seatData} />
    </DashboardPanel>
  );
};

export default DashboardPanelNumbers;
