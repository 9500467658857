import { WidthType } from 'shared/utility/definition';
import labeledProps from '../../default';

const defaultProps = {
  ...labeledProps,
  inputWidth: 'large' as WidthType,
  inputProps: {
    name: 'upload',
    onChange: () => {},
  },
  label: '__TEXT_LABEL__',
  labelWidth: 'large' as WidthType,
  labelProps: {},
};

export default defaultProps;
