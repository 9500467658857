import { getAllCallflowActionConfigs } from 'models/Callflow/actions';
import { CallFlowActionInterface } from 'models/Callflow/definition';
import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from 'shared/components/SearchInput';
import Sections from './components/Sections';
import { ActionContainerProps as Props } from './definition';
import StyledActionContainer from './style';

export { CONSTANTS as ACTION_CONTAINER_CONSTANTS } from './style';

const ActionContainer: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isDragging } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const callflows = useMemo(() => getAllCallflowActionConfigs(), []);
  const [filteredActions, setFilteredActions] = useState<CallFlowActionInterface[]>(callflows);

  useEffect(() => {
    setFilteredActions(
      callflows.filter((action: CallFlowActionInterface) =>
        action.label.toLowerCase().trim().includes(search.toLowerCase().trim()),
      ),
    );
  }, [search]);

  return (
    <StyledActionContainer>
      <div>
        <h3>{t('common:actions')}</h3>
        <div>
          <SearchInput
            iconSize="small"
            inputProps={{
              size: 'small',
              value: search,
              width: 'auto',
              onChange: (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
              id: 'input-search-actions',
            }}
            onReset={() => setSearch('')}
          />
        </div>
        <Sections actions={filteredActions} isDragging={isDragging} />
      </div>
    </StyledActionContainer>
  );
};

export default ActionContainer;
