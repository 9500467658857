import { SEAT_TYPE } from 'constant';
import { Location } from '../definition';

/**
 * Find the count of all billable seats per location
 * • If this location has any seat types, iterate through them
 * • If the seat type is not admin, assign the billable seat count
 *
 * @param {Location} location Location object
 *
 * @return {number} Total count of billable seats of this location
 */
const getSeatCount = (location: Location): number => {
  const EXTRANEOUS_ID = [SEAT_TYPE.admin.id];
  return Object.entries(location.seat_types ?? {}).reduce((acc, [id, type]: (string | any)[]) => {
    if (!EXTRANEOUS_ID.includes(id) && type.billable) {
      acc += Object.keys(type.billable).length;
    }
    return acc;
  }, 0);
};

/**
 * Convert locations object to array
 * • Filter any extraneous location keys
 * • Assign id/location_type/seat_count values to the location object
 *
 * @param {any} data Locations array
 *
 * @return {Location[]} Locations array
 */
export const initData = ([data]: any): Location[] => {
  const EXTRANEOUS_ID = ['unknown'];
  return data
    ? Object.entries(data?.locations).reduce(
        (acc: Location[], [id, location]: (string | any)[]) => {
          if (!EXTRANEOUS_ID.includes(id)) {
            acc.push({
              ...location,
              id,
              location_type: location.location_type,
              seat_count: getSeatCount(location),
            });
          }
          return acc;
        },
        [],
      )
    : [];
};

export interface FullAddress {
  address_line_1: string;
  address_line_2?: string;
  address_city?: string;
  address_state?: string;
  address_country?: string;
}

export const fullAddressComposer = (row: Record<string, string>) => {
  return ['address_line_2', 'address_city', 'address_state', 'address_country']
    .reduce(
      (prev: string[], curr: string) => {
        if (row[curr]) {
          prev.push(row[curr]);
        }
        return prev;
      },
      [row.address_line_1],
    )
    .join(', ');
};
