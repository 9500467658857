import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledMaskedValueProps as Props } from './definition';

const StyledMaskedValue = styled.span<Props>`
  ${({ theme }) => css`
    ${mixin.flex({ isInline: true, justifyContent: 'space-between' })};
    min-width: 60px;

    span {
      ${mixin.font({ lineHeight: '0.8' })};
      display: inline-block;
      margin: 0 ${theme.spacing(1)} 0 0;
      white-space: nowrap;
    }

    svg {
      cursor: pointer;
      fill: ${theme.whitelabel.primary.color};
    }
  `}
`;

export default StyledMaskedValue;
