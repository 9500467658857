import StyledEditForm from 'apps/shared/components/StyledEditForm';
import useIsMounted from 'apps/shared/hooks/useIsMounted';
import { BaseSyntheticEvent, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import { DialogActionsCloseReasons } from 'shared/components/Dialog/definition';
import { useActionRow } from 'shared/hooks/useActionRow';
import { EditFormProps as Props } from './definition';

const EditForm = ({
  isPageDirty,
  children,
  entityLabel,
  entityName = '',
  formMethods,
  onCancel,
  onDelete,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState<boolean>(false);
  const handleOnDelete = () => setIsDeleteConfirmVisible(true);
  const isMounted = useIsMounted();

  const [isSaving, setIsSaving] = useState(false);

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    if (closeResponse.reason === 'deleteClicked') {
      onDelete && onDelete();
    }
    setIsDeleteConfirmVisible(false);
  };

  const onSaveHandler = async () => {
    setIsSaving(true);
    try {
      await onSave?.();
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const sipTrunkUrl = `/apps/sip-trunking/assigned/${id}`;
  const isSipTrunkEntity = entityLabel !== t('numbers:containers.numbers_in_use.label');

  const { ActionRow, actionRowProps } = useActionRow({
    hasDelete: !!onDelete,
    hasSave: true,
    isDirty: isPageDirty,
    isSaveDisabled: isSaving,
    breadcrumbData: [
      {
        text: entityLabel,
        url: isSipTrunkEntity ? sipTrunkUrl : !onCancel ? '..' : undefined,
      },
      {
        text: entityName,
      },
    ],
    onCancel,
    onDelete: handleOnDelete,
    onSave: onSaveHandler,
  });

  return (
    <StyledEditForm onSubmit={(e: BaseSyntheticEvent) => e.preventDefault()}>
      <Dialog
        title={t('phone_system:containers.shared.edit_form.delete.title', { entity: entityLabel })}
        open={isDeleteConfirmVisible}
        renderActions={<DialogActions hasDelete onAction={handleDialogAction} />}
        onClose={() => setIsDeleteConfirmVisible(false)}
      >
        <span>
          {t('phone_system:containers.shared.edit_form.delete.text', { name: entityName })}
        </span>
      </Dialog>
      <ActionRow {...actionRowProps} />
      <FormProvider {...formMethods}>{children}</FormProvider>
    </StyledEditForm>
  );
};

export default EditForm;
