import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const Device = {
  // GET
  Fetch: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/devices?paginate=${isPaginated}`, // TODO: Review GET param usage
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/devices/${id}`,
  FetchStatuses: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/devices/status`,

  FetchByOwnerId: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/users/${id}/devices`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/devices`,

  // PATCH
  Patch: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/devices/${id}`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/devices/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/devices/${id}`,

  // REBOOT
  Reboot: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/devices/${id}/sync`,
};
