import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class GroupPickupActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.GROUP_PICKUP,
      module: CallFlowModuleKey.GROUP_PICKUP,
      section: CallFlowSection.ADVANCED,
      rules: [
        {
          type: 'quantity',
          maxSize: 0,
        },
      ],
      iconProps: {
        name: 'groups-filled',
      },
      label: i18next.t('phone_system:containers.callflows.actions.group_pickup.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.group_pickup.tooltip'),
    });
  }

  static subtitle = (nodeData: any) => nodeData?.name;
}

export default GroupPickupActionConfig;
