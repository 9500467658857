import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledSearchForNumbers = styled(Box)`
  ${({ theme }) => css`
    div[role='row']:nth-of-type(2) div[role='cell'] {
      ${mixin.flex({ justifyContent: 'flex-start' })}

      > span {
        margin: ${theme.spacing(-2, 1.33, 0)}; //-12px 8px 0;
        user-select: none;
      }
    }
  `};
`;
