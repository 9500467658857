import { useState } from 'react';
import { DialogActionsCloseReasons } from 'shared/components/Dialog';
import { DataRestorationDialogState } from './definition';

export const useDataRestorationDialogState = (): DataRestorationDialogState => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onClose();
        break;
      default:
        break;
    }
  };

  return {
    isOpen,
    onOpen,
    onClose,
    onAction,
  };
};
