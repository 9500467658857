import { removeTreeNode } from 'models/Callflow/store/slice';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import Icon from 'shared/components/Icon';
import DeleteOptions from '../DeleteOptions';
import { Props } from './definition';
import { StyledFlowChildDelete } from './style';

const FlowChildDelete: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    deleteNodeRef,
    callflowId,
    hasDeleteOptions,
    isPreview,
    setHasDeleteOptions,
    node,
    setIsHoverNode,
    setIsHoverAll,
  }: Props = props;
  const { data: nodeData, isActivated: isDragging } = node;
  const { nodeId } = nodeData;
  const dispatch = useDispatch();

  const handleDelete = (includeDescendants = false): void => {
    setIsHoverNode(false);
    setIsHoverAll(false);
    dispatch(removeTreeNode({ id: callflowId, nodeId, includeDescendants }));
  };

  return (
    <StyledFlowChildDelete
      ref={deleteNodeRef}
      isDragging={isDragging}
      hasDeleteOptions={hasDeleteOptions}
    >
      {!isPreview && (
        // @ts-ignore
        <div onClick={() => setHasDeleteOptions((hasDeleteOptions) => !hasDeleteOptions)}>
          <Icon name="close" size={12} />
          <DeleteOptions
            isVisible={hasDeleteOptions}
            node={node}
            onHoverNode={setIsHoverNode}
            onHoverAll={setIsHoverAll}
            onNodeDelete={handleDelete}
          />
        </div>
      )}
    </StyledFlowChildDelete>
  );
};

export default FlowChildDelete;
