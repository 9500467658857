import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { RINGING_STATUSES } from '../../constants';

const schema = () =>
  Joi.object({
    nodeId: Joi.string(),
    presence_id: Joi.string().required(),
    status: Joi.string().valid(...RINGING_STATUSES),
  }).messages(messages());

export default schema;
