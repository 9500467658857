import { joiResolver } from '@hookform/resolvers/joi';
import VoicemailSelect from 'apps/PhoneSystem/shared/VoicemailSelect';
import { useFetchVoicemailsQuery } from 'models/Voicemail';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { CheckVoicemailDialogProps as Props, CheckVoicemailNodeData, Data } from './definition';
import schema from './schema';

const CheckVoicemailDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { data: voicemailData, isLoading } = useFetchVoicemailsQuery();
  const { handleSubmit, control, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;

  const submitHandler = (formData: Data) => {
    const selected = voicemailData?.find(({ id }) => id === formData.id);
    const nodeData: CheckVoicemailNodeData = {
      metadata: {
        name: selected?.mailbox,
        pvt_type: 'voicemail',
      },
      data: formData,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.check_voicemail.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <VoicemailSelect
        name="id"
        control={control}
        isLabelAbove
        label={t(
          'phone_system:containers.callflows.callflow_action_dialog.check_voicemail.add.vmbox',
        )}
      />
    </CallflowActionsDialog>
  );
};

export default CheckVoicemailDialog;
