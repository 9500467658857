import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'definition';
import { reducer } from 'models';
import { service as advancedProvisionerApi } from 'services/advancedProvisioner';
import { bizProvApi } from 'services/bizProv';
import { iamApi } from 'services/iam';
import { kazooApi } from 'services/kazoo';
import { service as legacyProvisionerApi } from 'services/legacyProvisioner';

export default configureStore({
  reducer,
  // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(advancedProvisionerApi.middleware)
      .concat(bizProvApi.middleware)
      .concat(iamApi.middleware)
      .concat(kazooApi.middleware)
      .concat(legacyProvisionerApi.middleware),
});

export const setupStore = (preloadedState: any = {}) => {
  return configureStore({
    reducer,
    preloadedState,
    // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(advancedProvisionerApi.middleware)
        .concat(bizProvApi.middleware)
        .concat(iamApi.middleware)
        .concat(kazooApi.middleware)
        .concat(legacyProvisionerApi.middleware),
  });
};

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
