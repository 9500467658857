import { defaultProps as editableListProps } from 'shared/components/EditableList';
import { WidthType } from 'shared/utility/definition';
import labeledProps from '../../default';

const defaultProps = {
  ...labeledProps,
  hasMargin: false,
  indentWidth: 'large' as WidthType,
  labelWidth: 'large' as WidthType,
  editableListProps,
};

export default defaultProps;
