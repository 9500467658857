import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class TimeOfDayResetActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.TIME_OF_DAY_RESET,
      module: CallFlowModuleKey.TIME_OF_DAY_RESET,
      section: CallFlowSection.TIME_OF_DAY,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'history',
      },
      label: i18next.t('phone_system:containers.callflows.actions.time_of_day_reset.name'),
    });
  }
}

export default TimeOfDayResetActionConfig;
