import * as mixin from 'shared/utility/mixin';
import { css } from 'styled-components';
import { CONSTANTS } from './constant';

export const style = (props: any) => css`
  padding: ${CONSTANTS.SHADOW_OFFSET.VERTICAL.TOP}px ${CONSTANTS.SHADOW_OFFSET.HORIZONTAL / 2}px
    ${CONSTANTS.SHADOW_OFFSET.VERTICAL.BOTTOM}px;

  > div {
    ${mixin.boxShadow({ offsetY: 2, blurRadius: 4 })};
    background-color: ${props.theme.core.background.grey.light.color};
  }
`;
