import { TABLE_CONSTANTS } from 'shared/components/Table';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledRowProps as Props } from './definition';

const StyledRow = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex()};
    width: ${props.width}px;
    border-top: ${TABLE_CONSTANTS.BORDER};

    > span {
      display: inline-block;
      height: ${TABLE_CONSTANTS.HEIGHT.CELL.TBODY};
      line-height: ${TABLE_CONSTANTS.HEIGHT.CELL.TBODY};
      padding: 0 ${props.theme.spacing(2)};
      border-right: ${TABLE_CONSTANTS.BORDER};
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;

      --span-offset: ${TABLE_CONSTANTS.WIDTH.ICON.ACTION} / ${props.cellCount};

      &:last-of-type {
        border: none;

        ${props.hasIcon &&
        css`
          ${mixin.flex({ justifyContent: 'space-around' })};
          width: ${TABLE_CONSTANTS.WIDTH.ICON.ACTION};
        `}
      }
    }
  `}
`;

export default StyledRow;
