import { joiResolver } from '@hookform/resolvers/joi';
import CallflowActionsDialog from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowActionsDialog/components/CallflowActionDialog';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledSelect } from 'shared/components/Labeled';
import { DEFAULT_KEY } from '../../constants';
import defaultProps from './default';
import { MenuKeyDialogForm, Props } from './definition';
import schema from './schema';
import translations from './translations';

const MenuKeyDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose } = { ...defaultProps, ...props };
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<MenuKeyDialogForm>({
    mode: 'onChange',
    defaultValues: {
      key: data.key ? data.key : DEFAULT_KEY,
    },
    resolver: joiResolver(schema()),
  });
  const { key, keys, title } = useMemo(() => translations(), []);

  const submitHandler = async (formData: MenuKeyDialogForm) => {
    onSave(formData.key, isDirty);
  };

  return (
    <CallflowActionsDialog
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper
        name="key"
        control={control}
        options={keys.filter(({ value }) => !data.usedKeys.includes(value) || value === data.key)}
      >
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={key.label}
            labelWidth="small"
            inputWidth="small"
            selectProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default MenuKeyDialog;
