import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import isUndefined from 'lodash/isUndefined';
import { FunctionComponent, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'shared/components/Button';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import { LabeledCheckbox, LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import { INTER_DIGIT_CONVERSION_FACTOR } from '../../constants';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, DisaDialogProps as Props, DisaNodeData } from './definition';
import schema from './schema';
import translations from './translations';

const DisaDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { isDirty },
  } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
      use_account_caller_id: !isUndefined(data?.use_account_caller_id)
        ? data.use_account_caller_id === false
        : defaultValues.use_account_caller_id,
      interdigit: data?.interdigit
        ? String(data.interdigit / INTER_DIGIT_CONVERSION_FACTOR)
        : defaultValues.interdigit,
    },
    resolver: joiResolver(schema()),
  });
  const {
    buttons,
    dialog,
    interdigit,
    max_digits,
    options,
    pin,
    preconnect_audio,
    preconnect_audios,
    retries,
    title,
    use_account_caller_id,
  } = useMemo(() => translations(), []);

  const submitConfirmationHandler = (formData: Data) => {
    if (formData.pin.trim().length === 0) {
      setShowConfirmationDialog(true);
    } else {
      submitHandler(formData);
    }
  };

  const submitHandler = (formData: Data) => {
    const {
      pin,
      retries,
      interdigit,
      max_digits,
      preconnect_audio,
      use_account_caller_id,
    } = formData;
    const newData: Data = {
      nodeId: data?.nodeId,
      pin,
      preconnect_audio,
      use_account_caller_id: !use_account_caller_id,
    };

    if (retries !== 'default') {
      newData.retries = retries;
    }

    if (interdigit && interdigit !== 'default') {
      newData.interdigit = String(+interdigit * INTER_DIGIT_CONVERSION_FACTOR);
    }

    if (max_digits !== 'default') {
      newData.max_digits = max_digits;
    }

    const nodeData: DisaNodeData = {
      data: newData,
    };

    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitConfirmationHandler)}
    >
      <Dialog
        open={showConfirmationDialog}
        title={dialog.title}
        onClose={() => setShowConfirmationDialog(false)}
        renderActions={
          <DialogActions
            rightButtons={
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => setShowConfirmationDialog(false)}
                  data-test-id="btn-disa-dialog-cancel"
                >
                  {buttons.cancel}
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => submitHandler(getValues())}
                  data-test-id="btn-disa-dialog-ok"
                >
                  {buttons.ok}
                </Button>
              </>
            }
          />
        }
      >
        <p>{dialog.content_0}</p>
      </Dialog>

      <HookFormInputWrapper name="pin" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={pin.label}
            inputWidth="small"
            inputProps={{
              ...formProps,
              id: 'input-disa-pin',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormSelectWrapper name="retries" control={control} options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={retries.label}
            tooltip={retries.tooltip}
            selectProps={{
              ...formProps,
              id: 'select-disa-retries',
            }}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormSelectWrapper name="interdigit" control={control} options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={interdigit.label}
            tooltip={interdigit.tooltip}
            selectProps={{
              ...formProps,
              id: 'select-disa-interdigit',
            }}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormSelectWrapper name="max_digits" control={control} options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={max_digits.label}
            tooltip={max_digits.tooltip}
            selectProps={{
              ...formProps,
              id: 'select-disa-max-digits',
            }}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormSelectWrapper name="preconnect_audio" control={control} options={preconnect_audios}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={preconnect_audio.label}
            tooltip={preconnect_audio.tooltip}
            selectProps={{
              ...formProps,
              id: 'select-preconnect-audio',
            }}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormInputWrapper name="use_account_caller_id" control={control} isCheckbox>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            isDirty={isDirty}
            feedback={feedback}
            indentWidth="none"
            label={use_account_caller_id.label}
            tooltip={use_account_caller_id.tooltip}
            checkboxProps={{
              ...formProps,
              id: 'checkbox-disa-keep-original-caller-id',
            }}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default DisaDialog;
