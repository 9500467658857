// Device Mutations
import { Device } from 'api/device';
import { RequestMethod } from 'definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const deviceMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createDevice: builder.mutation<Device, Partial<Device>>({
      query: ({ body }) => ({
        url: Device.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.DEVICE, id: 'LIST' }],
    }),
    patchDevice: builder.mutation<Device, Partial<Device>>({
      query: ({ id, body }) => ({
        url: Device.Patch(id),
        method: RequestMethod.Patch,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.DEVICE, id }],
    }),
    updateDevice: builder.mutation<Device, UpdateDeviceParams>({
      query: ({ id, body }) => ({
        url: Device.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.DEVICE, id }],
    }),
    deleteDevice: builder.mutation<Device, { id: string }>({
      query: ({ id }) => ({
        url: Device.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: [{ type: TAGS.DEVICE, id: 'LIST' }],
    }),
    rebootDevice: builder.mutation<Device, { id: string }>({
      query: ({ id }) => ({
        url: Device.Reboot(id),
        method: RequestMethod.Post,
      }),
      invalidatesTags: () => [{ type: TAGS.DEVICE }],
    }),
  }),
});
