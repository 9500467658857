import { SelectionPosition, SelectionType } from '../definition';

const defaultProps = {
  hasDragIcon: false,
  hasEditIcon: true,
  isSelected: false,
  selection: {
    position: SelectionPosition.Left,
    type: SelectionType.None,
    setRows: () => {},
  },
  onChangeSelection: () => {},
};

export default defaultProps;
