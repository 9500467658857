import { DynamicForm } from 'apps/AdvancedProvisioner/shared/components/DynamicForm';
import { FormData } from 'apps/AdvancedProvisioner/shared/components/DynamicForm/definition';
import { useSaveAndRebootDialog } from 'apps/AdvancedProvisioner/shared/hooks/useSaveAndRebootDialog';
import { getIsRegisteredDevice } from 'apps/PhoneSystem/shared/CallStatusIcon/utility';
import { unmaskMacAddress } from 'apps/shared/utility';
import {
  useFetchDeviceByMacAddressQuery,
  useFetchDeviceFilesByMacAddressQuery,
  useFetchDeviceTemplateByModelQuery,
  useUpdateDeviceByMacAddressMutation,
} from 'models/AdvancedProvisioner';
import { DeviceFileType, Devices } from 'models/AdvancedProvisioner/types';
import {
  useDeleteDeviceMutation,
  useFetchDevicesQuery,
  useFetchDeviceStatusesQuery,
  useRebootDeviceMutation,
} from 'models/Device';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FileDownloadDialog, { FileDownloadDialogType } from 'shared/components/FileDownloadDialog';
import { useToast } from 'shared/hooks/useToast';
import { RouteParams } from '../definition';
import { transformData } from '../List/utility';
import { useGetInheritTemplate } from './hooks/useGetInheritTemplate';
import { schema } from './schema';
import { getActionRowButtons } from './utility';

export const Edit = () => {
  const { macAddress = '' } = useParams<RouteParams>();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [isDialogOpen, setIsDialogOpen] = useState<Record<FileDownloadDialogType, boolean>>({
    [FileDownloadDialogType.Config]: false,
    [FileDownloadDialogType.Log]: false,
  });

  const [deleteDevice] = useDeleteDeviceMutation();
  const [rebootDevice] = useRebootDeviceMutation();
  const [updateDeviceByMacAddress] = useUpdateDeviceByMacAddressMutation();

  const { data: devicesData, isLoading: isLoadingDevices } = useFetchDevicesQuery();
  const { data: apDeviceData, isLoading: isLoadingApDevice } = useFetchDeviceByMacAddressQuery(
    macAddress,
  );
  const {
    data: deviceRegistrationsData,
    isLoading: isLoadingDeviceRegistrations,
  } = useFetchDeviceStatusesQuery();

  const updatedApDevicesData = transformData([apDeviceData] as Devices, devicesData)?.[0] ?? {};
  const isRebootDisabled = !getIsRegisteredDevice(updatedApDevicesData, deviceRegistrationsData);

  const { SaveAndRebootDialog, formSaveOverrideProps } = useSaveAndRebootDialog({
    isRebootDisabled,
  });

  const {
    brand = '',
    deviceId,
    family = '',
    mac_address = '',
    model = '',
    name = '',
    settings,
  } = updatedApDevicesData;

  const {
    data: deviceTemplateData,
    isLoading: isLoadingDeviceTemplate,
  } = useFetchDeviceTemplateByModelQuery(
    { brand, family, model },
    { skip: isLoadingApDevice || (!isLoadingApDevice && !updatedApDevicesData) },
  );
  const template = useGetInheritTemplate({
    deviceTemplate: deviceTemplateData?.template,
    deviceTemplateCluster: deviceTemplateData?.cluster,
  });

  const { data: configFileData } = useFetchDeviceFilesByMacAddressQuery({
    type: DeviceFileType.Config,
    macAddress,
  });
  const { data: logFileData } = useFetchDeviceFilesByMacAddressQuery({
    type: DeviceFileType.Log,
    macAddress,
  });

  const onButtonClick = {
    downloadConfigFiles: (isOpen: boolean) => {
      setIsDialogOpen((isDialogOpen: Record<FileDownloadDialogType, boolean>) => ({
        ...isDialogOpen,
        [FileDownloadDialogType.Config]: isOpen,
      }));
    },
    downloadLogFile: (isOpen: boolean) => {
      setIsDialogOpen((isDialogOpen: Record<FileDownloadDialogType, boolean>) => ({
        ...isDialogOpen,
        [FileDownloadDialogType.Log]: isOpen,
      }));
    },
    rebootDevice: async () => {
      try {
        await rebootDevice({ id: deviceId ?? '' }).unwrap();
        showToast.success(
          t('advanced_provisioner:containers.devices.edit.action_row.button.reboot.toast'),
        );
      } catch (exception) {
        showToast.error();
      }
    },
  };

  const onSubmit = async (formData: FormData): Promise<void> => {
    await updateDeviceByMacAddress({
      brand,
      family,
      mac_address: unmaskMacAddress(mac_address),
      model,
      name,
      settings: formData,
    }).unwrap();
  };

  const breadcrumbData = useMemo(
    () => [
      t('advanced_provisioner:containers.devices.edit.action_row.breadcrumb.all_devices'),
      name,
    ],
    [name, t],
  );

  return (
    <>
      <DynamicForm
        isLoading={
          isLoadingApDevice ||
          isLoadingDeviceRegistrations ||
          isLoadingDeviceTemplate ||
          isLoadingDevices
        }
        actionRowProps={{
          rightButtons: getActionRowButtons({ onButtonClick, options: { isRebootDisabled } }),
        }}
        breadcrumbData={breadcrumbData}
        data={settings}
        deleteButton={{
          confirmMessage: (
            <Trans
              i18nKey="advanced_provisioner:containers.devices.edit.delete_device_dialog.message"
              values={{ name }}
            />
          ),
          title: t('advanced_provisioner:containers.devices.edit.delete_device_dialog.title'),
          onClick: async () => {
            await deleteDevice({ id: deviceId ?? '' }).unwrap();
          },
        }}
        macAddress={macAddress}
        schema={schema}
        template={template}
        onFormSubmit={onSubmit}
        {...formSaveOverrideProps}
      />
      <FileDownloadDialog
        isOpen={isDialogOpen[FileDownloadDialogType.Config]}
        data={configFileData}
        type={FileDownloadDialogType.Config}
        onClose={() => onButtonClick.downloadConfigFiles(false)}
      />
      <FileDownloadDialog
        isOpen={isDialogOpen[FileDownloadDialogType.Log]}
        data={logFileData}
        type={FileDownloadDialogType.Log}
        onClose={() => onButtonClick.downloadLogFile(false)}
      />
      <SaveAndRebootDialog rebootHandler={onButtonClick.rebootDevice} />
    </>
  );
};
