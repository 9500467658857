import { getGraphqlErrorMessage } from 'services/bizProv';

/**
 * @description The utility to update Mattermost team as per the form state indicated.
 * @param deleteMattermostTeam the mutation for deleting mattermost team
 * @param enableMattermostTeam the mutation for enabling mattermost team
 * @param formState the form state
 * @param isTeamChatIsEnabledDirty the state of team chat enablement
 * @param openDataRestorationDialog the function to be triggered when needed to open the data restoration dialog
 */
export const updateMattermostTeam = async ({
  deleteMattermostTeam,
  enableMattermostTeam,
  formState,
  isTeamChatIsEnabledDirty,
  openDataRestorationDialog,
  showErrorMessage,
}: {
  deleteMattermostTeam: () => any;
  enableMattermostTeam: () => any;
  formState: Record<string, any>;
  isTeamChatIsEnabledDirty: boolean;
  openDataRestorationDialog?: () => void;
  showErrorMessage?: (errorMsg?: string) => void;
}) => {
  if (isTeamChatIsEnabledDirty) {
    try {
      const nextIsTeamChatEnabled = formState.ooma_info?.team_chat?.enabled;
      if (nextIsTeamChatEnabled) {
        const result = await enableMattermostTeam().unwrap();
        if (result?.mattermost?.enableTeam?.isRestored) {
          openDataRestorationDialog?.();
        }
      } else {
        await deleteMattermostTeam().unwrap();
      }
    } catch (e) {
      showErrorMessage?.(getGraphqlErrorMessage(e));
      return false;
    }
  }
  return true;
};
