// Account Queries
import { Account } from 'api/account';
import { LOCAL_STORAGE } from 'constant';
import { childResponseHandler, kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const accountQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAccounts: builder.query<AccountListResponse, void>({
      query: () => ({
        url: Account.FetchById(),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }: any) => ({ type: TAGS.ACCOUNT as const, id })),
              { type: TAGS.ACCOUNT, id: 'LIST' },
            ]
          : [{ type: TAGS.ACCOUNT, id: 'LIST' }],
    }),
    fetchAccount: builder.query<Account, void>({
      query: () => ({
        url: Account.FetchById(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.ACCOUNT }],
    }),
    fetchAccountById: builder.query<Account, { id?: string }>({
      query: ({ id }: { id: string }) => ({
        url: Account.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => [{ type: TAGS.ACCOUNT, id }],
    }),
    fetchRootAccount: builder.query<Account, void>({
      query: () => ({
        url: Account.FetchById(localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID) || ''),
        responseHandler,
      }),
      providesTags: () => [
        { type: TAGS.ACCOUNT, id: localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID) || '' },
      ],
    }),
    fetchAccountChildren: builder.query<AccountChildrenResponse, void>({
      query: () => ({
        url: Account.FetchChildrenById(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.ACCOUNT }],
    }),
    fetchAccountAncestorsById: builder.query<
      AccountAncestorsResponse,
      { id?: string; isPaginated?: boolean } | void
    >({
      query: ({ id, isPaginated }: { id?: string; isPaginated?: boolean } = {}) => ({
        url: Account.FetchAncestorsById(id, isPaginated),
        responseHandler: async (results) => childResponseHandler(results, id),
      }),
    }),
    fetchAccountBillingById: builder.query<
      AccountBillingResponse,
      { id?: string; isPaginated?: boolean } | void
    >({
      query: ({ id, isPaginated }: { id?: string; isPaginated?: boolean } = {}) => ({
        url: Account.FetchBillingById(id, isPaginated),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.ACCOUNT }],
    }),
    fetchAccountChildrenById: builder.query<
      AccountChildrenResponse,
      { id?: string; isPaginated?: boolean } | void
    >({
      query: ({ id, isPaginated }: { id?: string; isPaginated?: boolean } = {}) => ({
        url: Account.FetchChildrenById(id, isPaginated),
        responseHandler: async (results) => childResponseHandler(results, id),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }: any) => ({ type: TAGS.ACCOUNT as const, id })),
              { type: TAGS.ACCOUNT },
            ]
          : [{ type: TAGS.ACCOUNT }],
    }),
    fetchAccountConfigs: builder.query<AccountConfigsResponse, void>({
      query: () => ({
        url: Account.FetchConfigsById(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.ACCOUNT }],
    }),
    fetchAccountDescendantsById: builder.query<
      AccountChildrenResponse,
      { id?: string; isPaginated?: boolean } | void
    >({
      query: ({ id, isPaginated }: { id?: string; isPaginated?: boolean } = {}) => ({
        url: Account.FetchDescendantsById(id, isPaginated),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }: any) => ({ type: TAGS.ACCOUNT as const, id })),
              { type: TAGS.ACCOUNT },
            ]
          : [{ type: TAGS.ACCOUNT, id: 'LIST' }],
    }),
    fetchAccountHeroApps: builder.query<AccountHeroAppsResponse, { id?: string } | void>({
      query: ({ id }: { id?: string } = {}) => ({
        url: Account.FetchHeroAppsById(id),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.ACCOUNT }, { type: TAGS.ACCOUNT_HERO_APPS }],
    }),
    fetchAccountDesktopAppZone: builder.query<
      AccountDesktopAppZoneResponse,
      { id?: string } | void
    >({
      query: ({ id }: { id?: string } = {}) => ({
        url: Account.FetchDesktopAppZoneById(id),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.ACCOUNT }, { type: TAGS.ACCOUNT_DESKTOP_APP_ZONE }],
    }),
    fetchAccountSiteLocationUsers: builder.query<any, { id?: string; locationId?: string } | void>({
      query: ({ id, locationId }: { id?: string; locationId?: string } = {}) => ({
        url: Account.FetchSiteLocationUsersById(id, locationId),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.ACCOUNT }],
    }),
    searchAccount: builder.query<AccountChildrenResponse, { search: string }>({
      query: ({ search }: { search: string }) => ({
        url: Account.Search(search),
        responseHandler,
      }),
      providesTags: (result, error, { search }) => [{ type: TAGS.ACCOUNT, search }],
    }),
    fetchAccountCallerInfoMatch: builder.query<AccountCallerInfoMatch, void>({
      query: () => ({
        url: Account.FetchCallerInfoMatchById(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.CALLERINFOMATCH }, { type: TAGS.ACCOUNT }],
    }),
    fetchClusterById: builder.query<any, { id?: string }>({
      query: ({ id }: { id?: string } = {}) => ({
        url: Account.FetchClusterById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => [{ type: TAGS.ACCOUNT, id }],
    }),
    fetchAccountSeatCount: builder.query<AccountSeatCounts, void>({
      query: () => ({
        url: Account.FetchSeatCountById(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.ACCOUNT }],
    }),
    fetchAccountExternalApps: builder.query<AccountExternalAppsResponse, void>({
      query: () => ({
        url: Account.FetchExternalApps(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.ACCOUNT }],
    }),
  }),
});
