import i18next from 'i18next';
import { SelectOption } from 'shared/components/Select';
import { NumberType } from './definition';

/**
 * @name getDefaultValues
 * @description Get the form field default values.
 *
 * @param options
 *
 * @returns Default values
 */
export const getDefaultValues = (
  options: Record<NumberType, SelectOption>,
): Record<string, string | NumberType> => ({
  [NumberType.Extension]: '',
  [NumberType.Spare]: '',
  numberType: options.spare.value,
});

/**
 * @name getOptions
 * @description Get the number type options.
 *
 * @returns Options object
 */
export const getOptions = () => ({
  [NumberType.Extension]: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_edit_dialog.add_number.radio_option.extension',
    ),
    value: NumberType.Extension,
  },
  [NumberType.Spare]: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_edit_dialog.add_number.radio_option.spare_numbers',
    ),
    value: NumberType.Spare,
  },
});
