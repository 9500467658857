import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { Breakpoint } from '../../style';

const CONSTANTS = {
  WIDTH: '420px',
};

const StyledForm = styled.form`
  ${({ theme }) => css`
    ${mixin.borderRadius({ radius: 'half' })};
    ${mixin.boxShadow()};
    position: relative;
    width: ${CONSTANTS.WIDTH};
    margin: 0 auto;
    padding: ${theme.spacing(5)};
    background-color: ${theme.core.color.white};

    @media (min-width: ${Breakpoint.LARGE}) {
      top: 50%;
      margin: 0;
      transform: translateY(-50%);
    }

    a {
      ${mixin.transition({ property: 'border-bottom-color' })};
      border-bottom: 1px solid transparent;
      color: ${theme.core.color.blue.draggable};
      cursor: pointer;
      text-decoration: none;
      white-space: nowrap;
      user-select: none;

      &:hover {
        border-bottom-color: ${theme.core.color.blue.draggable};
      }
    }
  `}
`;

export default StyledForm;
