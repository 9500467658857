const defaultProps = {
  hasPagination: {
    bar: true,
    container: true,
    content: true,
    info: false,
    rowsPerPage: false,
  },
  hasPaginationPadding: {
    side: false,
  },
  rowCount: 10,
};

export default defaultProps;
