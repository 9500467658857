import { SESSION_STORAGE } from 'constant';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { useStorage } from '../useStorage';
import { Entry } from './definition';

export const usePaginationIndexStorage = ({
  tableId,
  gotoPage,
}: {
  tableId?: string;
  gotoPage: (index: number) => void;
}) => {
  const KEY = SESSION_STORAGE.TABLE.PAGINATION.INDEX;
  const { entries, path, primary, updateEntries } = useStorage({ key: KEY, tableId });

  const initPaginationIndex = useCallback(
    (index: number) => {
      // if zero index... initialize stored value
      if (
        (index === 0 && isEmpty(entries)) ||
        (index === 0 && !isEmpty(entries) && !path.includes(primary?.path))
      ) {
        sessionStorage.setItem(KEY, JSON.stringify([{ index, path }]));
      }

      if (!isEmpty(entries)) {
        const gotoIndex = entries.findIndex((entry: Entry) => entry.path === path);

        if (gotoIndex !== undefined) {
          gotoPage(entries[gotoIndex]?.index);
        }
      }
    },
    [KEY, entries, path, primary?.path, gotoPage],
  );

  const setPaginationIndex = useCallback(
    (index: number) => {
      updateEntries({ entry: { index, path }, key: KEY });
      gotoPage(index);
    },
    [KEY, path, gotoPage, updateEntries],
  );

  return { initPaginationIndex, setPaginationIndex };
};
