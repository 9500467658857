import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { ChangeEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import LabeledCheckbox from 'shared/components/Labeled/components/LabeledCheckbox';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'media_extension',
  'is_setup',
  'check_if_owner',
  'skip_instructions',
  'delete_after_notify',
  'require_pin',
  'skip_greeting',
  'announcement_only',
  'not_configurable',
  'oldest_message_first',
  'operator_number',
  'numbers',
];

const AdvancedSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  const mediaExtensionOptions = [
    {
      value: 'mp3',
      label: t('phone_system:containers.voicemails.section.advanced.media_extension.options.mp3'),
    },
    {
      value: 'wav',
      label: t('phone_system:containers.voicemails.section.advanced.media_extension.options.wav'),
    },
  ];

  return (
    <>
      <div role="row">
        <div role="cell">
          {/* Media Extension */}
          <HookFormSelectWrapper name="media_extension" options={mediaExtensionOptions}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                feedback={feedback}
                id="select-voicemail-advanced-media-extension"
                label={t(
                  'phone_system:containers.voicemails.section.advanced.media_extension.label',
                )}
                selectProps={formProps}
                selectWidth="medium"
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>

      <div role="row" className="one-column is-stack">
        <div role="cell">
          {/* Already Setup */}
          <HookFormInputWrapper name="is_setup" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                  id: 'checkbox-advanced-already-setup',
                }}
                feedback={feedback}
                label={t('phone_system:containers.voicemails.section.advanced.already_setup.label')}
              />
            )}
          </HookFormInputWrapper>

          {/* Announce only-mode */}
          <HookFormInputWrapper name="announcement_only" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                  id: 'checkbox-advanced-announce-onlymode',
                }}
                feedback={feedback}
                label={t(
                  'phone_system:containers.voicemails.section.advanced.announce_only_mode.label',
                )}
              />
            )}
          </HookFormInputWrapper>

          {/* Auto Login Enabled */}
          <HookFormInputWrapper name="check_if_owner" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                  id: 'checkbox-advanced-autologin-enabled',
                }}
                feedback={feedback}
                label={t(
                  'phone_system:containers.voicemails.section.advanced.auto_login_enabled.label',
                )}
                data-test-id="checkbox-advanced-autologin-enabled"
              />
            )}
          </HookFormInputWrapper>

          {/* Configure via menu */}
          <HookFormInputWrapper name="not_configurable" isCheckbox>
            {({ ref, checked, onChange, isDirty, feedback, ...formProps }) => {
              const onChangeHandler = (e: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
                e.target.checked = !isChecked;
                return onChange(e);
              };

              return (
                <LabeledCheckbox
                  isDirty={isDirty}
                  checkboxProps={{
                    ...formProps,
                    checked: !checked,
                    id: 'checkbox-advanced-config-menu',
                    onChange: onChangeHandler,
                  }}
                  feedback={feedback}
                  label={t(
                    'phone_system:containers.voicemails.section.advanced.configure_via_menu.label',
                  )}
                />
              );
            }}
          </HookFormInputWrapper>

          {/* Delete after Notification */}
          <HookFormInputWrapper name="delete_after_notify" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                  id: 'checkbox-advanced-delete-notification',
                }}
                feedback={feedback}
                label={t(
                  'phone_system:containers.voicemails.section.advanced.delete_after_notification.label',
                )}
              />
            )}
          </HookFormInputWrapper>

          {/* Require PIN */}
          <HookFormInputWrapper name="require_pin" isCheckbox>
            {({ ref, value, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                  id: 'checkbox-advanced-require-pin',
                }}
                label={t('phone_system:containers.voicemails.section.advanced.require_pin.label')}
              />
            )}
          </HookFormInputWrapper>

          {/* Skip Greeting */}
          <HookFormInputWrapper name="skip_greeting" isCheckbox>
            {({ ref, value, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                  id: 'checkbox-advanced-skip-greeting',
                }}
                label={t('phone_system:containers.voicemails.section.advanced.skip_greeting.label')}
              />
            )}
          </HookFormInputWrapper>

          {/* Skip Instructions */}
          <HookFormInputWrapper name="skip_instructions" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                  id: 'checkbox-advanced-skip-instructions',
                }}
                feedback={feedback}
                label={t(
                  'phone_system:containers.voicemails.section.advanced.skip_instructions.label',
                )}
              />
            )}
          </HookFormInputWrapper>

          {/* Sort voicemail box by oldest message first */}
          <HookFormInputWrapper name="oldest_message_first" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                hasMargin
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                  id: 'checkbox-advanced-sort-voicemail',
                }}
                feedback={feedback}
                label={t(
                  'phone_system:containers.voicemails.section.advanced.sort_oldest_first.label',
                )}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Operator Number */}
          <HookFormInputWrapper name="operator_number">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                inputProps={{
                  ...formProps,
                  id: 'input-advanced-operator-number',
                }}
                label={t(
                  'phone_system:containers.voicemails.section.advanced.operator_number.label',
                )}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* MWI Notification Number */}
          <HookFormInputWrapper name="numbers">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                inputProps={{
                  ...formProps,
                  id: 'input-advanced-mvi-number',
                  placeholder: t(
                    'phone_system:containers.voicemails.section.advanced.mwi_notification_number.placeholder',
                  ),
                }}
                label={t(
                  'phone_system:containers.voicemails.section.advanced.mwi_notification_number.label',
                )}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
    </>
  );
};

export default AdvancedSection;
