import { SECONDS_IN_DAY, SECONDS_IN_MINUTE } from 'constant';
import i18next from 'i18next';
import produce from 'immer';
import first from 'lodash/first';
import isArray from 'lodash/isArray';
import last from 'lodash/last';
import merge from 'lodash/merge';
import range from 'lodash/range';
import { OptionsType, OptionTypeBase } from 'shared/components/Select/definition';
import {
  dateToGregorian,
  dateToSeconds,
  gregorianToDate,
  secondsToDate,
} from 'shared/utility/date';
import { defaultValues } from '../default';
import { CycleType, EnabledType, FormFields, OrdinalType, WdaysType } from '../definition';

/**
 * @name convertWdays
 * @description Preserve the selected weekday when switching between `cycle` values:
 *              • if `cycle` value is 'weekly' and the `wdays` value is not an array... arrayify it!
 *              • if `cycle` value is 'monthly' or 'yearly', and the `wdays` value is an
 *                array... grab the first value!
 *
 * @param option - Currently selected `cycle` option (weekly/monthly/yearly)
 * @param getValues - React Hook Form's optimized helper for reading form values
 *
 * @return Weekday(s) selected.
 */
export const convertWdays = (
  { value }: OptionTypeBase,
  getValues: (value: string) => string,
): string | Array<string> | undefined => {
  const wdays = getValues(FormFields.Wdays);

  if (value === CycleType.Weekly) {
    return isArray(wdays) ? wdays : [wdays];
  }

  return isArray(wdays) ? first(wdays) : wdays;
};

/**
 * @name initData
 * @description Prepare backend/database `temporal_rules` data object in order
 *              for it to be consumed easily by the UI widgets.
 *
 * @param data - Database `temporal_rules` data object
 *
 * @return UI friendly `temporal_rules` data object.
 */
export const initData = (data: any): any =>
  produce(merge({}, defaultValues, data), (draft: any) => {
    draft.duration = {
      start: data.time_window_start,
      stop: data.time_window_stop,
    };
    draft.enabled =
      // eslint-disable-next-line no-nested-ternary
      typeof data.enabled === 'undefined'
        ? EnabledType.BasedOnTime
        : data.enabled
        ? EnabledType.ForcedOn
        : EnabledType.ForcedOff;
    draft.end_date = data.end_date ? gregorianToDate(data.end_date) : '';
    draft.has_no_end_date = !data.end_date;
    draft.is_all_day_event =
      data.time_window_start === 0 && data.time_window_stop === SECONDS_IN_DAY - SECONDS_IN_MINUTE;
    draft.range_start = first(data.days);
    draft.range_stop = last(data.days);
    draft.start_date = gregorianToDate(data.start_date);
    draft.time_window_start = secondsToDate(data.time_window_start);
    draft.time_window_stop = secondsToDate(data.time_window_stop);
    draft.wdays = data.cycle === CycleType.Weekly ? data.wdays : first(data.wdays);

    if (data.cycle === CycleType.Daily) {
      draft.cycle = CycleType.Date;
    }
  });

/**
 * @name saveData
 * @description Prepare React Hook Form `temporal_rules` data object in order
 *              for it to be saved to the database.
 *
 * @param {any} data - UI friendly `temporal_rules` data object
 *
 * @return {any} - Database `temporal_rules` data object.
 */
export const saveData = (data: any): any => {
  const getRangeValue = (value: string | Array<string>): number =>
    isArray(value) ? Number(first(value)) ?? 1 : Number(value);

  let days: Array<string>;
  switch (data.ordinal) {
    case OrdinalType.Every:
      days = data.days;
      break;
    case OrdinalType.Range:
      days = range(
        getRangeValue(data.range_start),
        getRangeValue(data.range_stop) + 1,
      ).map((value) => String(value));
      break;
    default:
      days = [];
  }

  // if `ordinal` value is 'range', remove `days`
  if (data.ordinal === OrdinalType.Range) {
    delete data.days;
  }

  return produce(data, (draft: any) => {
    draft.days = days;
    draft.enabled = [EnabledType.ForcedOff, EnabledType.ForcedOn].includes(data.enabled)
      ? data.enabled === EnabledType.ForcedOn
      : EnabledType.BasedOnTime;
    draft.end_date = data.end_date ? dateToGregorian(data.end_date) : data.end_date;
    draft.start_date = dateToGregorian(data.start_date);
    draft.time_window_start = dateToSeconds(data.time_window_start);
    draft.time_window_stop = dateToSeconds(data.time_window_stop);
    draft.wdays = isArray(data.wdays) ? data.wdays : [data.wdays];

    // if `cycle` value is 'date' (never) and `end_date` is populated, assign 'daily' to `cycle`
    if (draft.cycle === CycleType.Date && draft.end_date) {
      draft.cycle = CycleType.Daily;
    }

    // if `cycle` value is 'daily' or 'date' (never), remove `days`/`interval`/`ordinal`/`wdays`
    if (draft.cycle === CycleType.Daily || draft.cycle === CycleType.Date) {
      delete draft.days;
      delete draft.interval;
      delete draft.ordinal;
      delete draft.wdays;
    }

    // if `cycle` value is 'date' (never), remove `end_date`
    if (draft.cycle === CycleType.Date) {
      delete draft.end_date;
    }

    // if `cycle` value is 'weekly', remove `days`/`ordinal`
    if (draft.cycle === CycleType.Weekly) {
      delete draft.days;
      delete draft.ordinal;
    }

    // if `cycle` value is not 'yearly', remove `month`
    if (draft.cycle !== CycleType.Yearly) {
      delete draft.month;
    }

    if ([OrdinalType.Every, OrdinalType.Range].includes(draft.ordinal)) {
      // if `ordinal` value is 'every' or 'range', reset `wdays`
      draft.wdays = [];
    } else {
      // if `ordinal` value is not 'every' or 'range', remove `days`
      delete draft.days;
    }

    // if `end_date` value is null or `has_no_end_date` is checked, remove `end_date`
    if (draft.end_date === null || draft.has_no_end_date) {
      delete draft.end_date;
    }

    // if `enabled` value is 'based_on_time', remove `enabled`
    if (draft.enabled === EnabledType.BasedOnTime) {
      delete draft.enabled;
    }

    // always remove...
    delete draft.duration;
    delete draft.is_all_day_event;
    delete draft.has_no_end_date;
    delete draft.range_start;
    delete draft.range_stop;
  });
};

/**
 * @name getOptions
 * @description Get select dropdown options.
 *
 * @param name - Name of the dropdown to be populated
 * @param startAtDay - Value to begin in the dropdown to be populated (for use with days only)
 *
 * @return Select options.
 */
export const getOptions = (name: string, startAtDay = 1): OptionsType => {
  const OPTIONS: Record<string, Array<OptionTypeBase>> = {
    cycle: [
      {
        label: i18next.t(`phone_system:containers.time_of_day.cycle.date`),
        value: CycleType.Date,
      },
      {
        label: i18next.t(`phone_system:containers.time_of_day.cycle.weekly`),
        value: CycleType.Weekly,
      },
      {
        label: i18next.t(`phone_system:containers.time_of_day.cycle.monthly`),
        value: CycleType.Monthly,
      },
      {
        label: i18next.t(`phone_system:containers.time_of_day.cycle.yearly`),
        value: CycleType.Yearly,
      },
    ],
    enabled: [
      {
        label: i18next.t('phone_system:containers.time_of_day.enabled.based_on_time'),
        value: EnabledType.BasedOnTime,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.enabled.forced_on'),
        value: EnabledType.ForcedOn,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.enabled.forced_off'),
        value: EnabledType.ForcedOff,
      },
    ],
    month: [
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.1'), value: '1' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.2'), value: '2' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.3'), value: '3' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.4'), value: '4' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.5'), value: '5' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.6'), value: '6' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.7'), value: '7' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.8'), value: '8' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.9'), value: '9' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.10'), value: '10' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.11'), value: '11' },
      { label: i18next.t('phone_system:containers.time_of_day.month_numeric.12'), value: '12' },
    ],
    ordinal: [
      {
        label: i18next.t('phone_system:containers.time_of_day.ordinal.first'),
        value: OrdinalType.First,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.ordinal.second'),
        value: OrdinalType.Second,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.ordinal.third'),
        value: OrdinalType.Third,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.ordinal.fourth'),
        value: OrdinalType.Fourth,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.ordinal.fifth'),
        value: OrdinalType.Fifth,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.ordinal.last'),
        value: OrdinalType.Last,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.ordinal.every'),
        value: OrdinalType.Every,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.ordinal.range'),
        value: OrdinalType.Range,
      },
    ],
    wdays: [
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.monday.long'),
        value: WdaysType.Monday,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.tuesday.long'),
        value: WdaysType.Tuesday,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.wensday.long'),
        value: WdaysType.Wednesday,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.thursday.long'),
        value: WdaysType.Thursday,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.friday.long'),
        value: WdaysType.Friday,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.saturday.long'),
        value: WdaysType.Saturday,
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.sunday.long'),
        value: WdaysType.Sunday,
      },
    ],
    days: range(startAtDay, 32).map((day: number) => ({ label: day, value: String(day) })),
  };

  return OPTIONS[name];
};
