import labelProps from 'shared/components/InputLabel/default';
import labeledProps from 'shared/components/Labeled/default';
import { WidthType } from 'shared/utility/definition';

const defaultProps = {
  ...labeledProps,
  label: '__FEATURECODE_LABEL__',
  labelWidth: 'large' as WidthType,
  labelProps,
};

export default defaultProps;
