import { TableCellCheckmarkIcon, TableCellManageLink } from 'apps/shared/components/TableCell';
import i18next from 'i18next';
import { find } from 'lodash';
import { Cell, Column, Justify, TABLE_CONSTANTS } from 'shared/components/Table';

const getIcon = (isVisible: boolean) => <TableCellCheckmarkIcon isVisible={isVisible} />;

export const ringStrategies = () => [
  {
    label: i18next.t('call_center:containers.manage_queues.strategy.round_robin'),
    value: 'round_robin',
  },
  {
    label: i18next.t('call_center:containers.manage_queues.strategy.most_idle'),
    value: 'most_idle',
  },
  {
    label: i18next.t('call_center:containers.manage_queues.strategy.skills_based_round_robin'),
    value: 'skills_based_round_robin',
  },
];

export const columns = (): Array<Column> => [
  {
    Header: i18next.t('call_center:containers.manage_queues.table.columns.name') as string,
    accessor: 'name',
  },
  {
    Header: i18next.t(
      'call_center:containers.manage_queues.table.columns.number_of_agents',
    ) as string,
    Cell: ({
      value,
      row: {
        original: { id },
      },
    }: Cell<{ id: string }>) => <TableCellManageLink linkTo={`agents/${id}`} value={value} />,
    accessor: 'agents.length',
    id: `call_center.manage_queues.number_of_agents.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.LARGE,
  },
  {
    Header: i18next.t(
      'call_center:containers.manage_queues.table.columns.announced_position',
    ) as string,
    Cell: ({ value }: Cell<JSX.Element>) => getIcon(value),
    accessor: 'accessor.announcements.position_announcements_enabled',
    id: `call_center.manage_queues.announce_position.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.LARGE,
  },
  {
    Header: i18next.t(
      'call_center:containers.manage_queues.table.columns.announced_wait_time',
    ) as string,
    Cell: ({ value }: Cell<JSX.Element>) => getIcon(value),
    accessor: 'accessor.announcements.wait_time_announcements_enabled',
    id: `call_center.manage_queues.announce_wait_time.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.LARGE,
  },
  {
    Header: i18next.t('call_center:containers.manage_queues.table.columns.recording') as string,
    Cell: ({ value }: Cell<JSX.Element>) => getIcon(value),
    accessor: 'accessor.record_caller',
    id: `call_center.manage_queues.recording.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.LARGE,
  },
  {
    Header: i18next.t('call_center:containers.manage_queues.table.columns.callbacks') as string,
    Cell: ({ value }: Cell<JSX.Element>) => getIcon(value === '1'),
    accessor: 'breakout.dtmf',
    id: `call_center.manage_queues.callback.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.LARGE,
  },
  {
    Header: i18next.t('call_center:containers.manage_queues.table.columns.ring_strategy') as string,
    Cell: ({ value }: Cell<JSX.Element>) => find(ringStrategies(), { value })?.label ?? '',
    accessor: 'strategy',
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.LARGE,
  },
  {
    Header: i18next.t('call_center:containers.manage_queues.table.columns.wrap_ups') as string,
    accessor: 'agent_wrapup_time',
    id: `call_center.manage_queues.wrap_up.${Justify.Right}`,
  },
];

/**
 * @name prepareData
 * @description Add accessor properties to data array of objects, as original boolean values
 *              are not being used as intended by the React Table sort mechanism.
 *
 * @param data
 *
 * @returns Data array
 */
export const prepareData = (data: any) =>
  data
    ? data.map((queue: Queue) => ({
        ...queue,
        accessor: {
          announcements: {
            wait_time_announcements_enabled: queue.announcements?.wait_time_announcements_enabled
              ? 1
              : 0,
            position_announcements_enabled: queue.announcements?.position_announcements_enabled
              ? 1
              : 0,
          },
          record_caller: queue.record_caller ? 1 : 0,
        },
      }))
    : [];
