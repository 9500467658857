import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountLocations } from '../../../../Seats/List/hooks/useAccountLocations';
import { FilterType } from '../../definition';

const getOptions = (map: Record<string, string>) =>
  Object.entries(map).map(([value, label]) => ({ label, value }));
const getLabelByValue = (map: Record<string, string>) => (value: string) => map[value];

export const useE911InfoFilterOptions = () => {
  const { t } = useTranslation();
  const { locationOptions, getLocationLabelById } = useAccountLocations();

  /** The source filter type */
  const { sourceOptions, getSourceLabelByValue } = useMemo(() => {
    const map: Record<string, any> = {
      account: t('phone_system:containers.e911info.filter.source.option.label.account'),
      seat: t('phone_system:containers.e911info.filter.source.option.label.seat'),
      device: t('phone_system:containers.e911info.filter.source.option.label.device'),
    };
    return {
      sourceOptions: getOptions(map),
      getSourceLabelByValue: (value: string) =>
        t('phone_system:containers.e911info.filter.source.breadcrumb.label', { type: map[value] }),
    };
  }, [t]);

  /** The type filter type */
  const { typeOptions, getTypeLabelByValue } = useMemo(() => {
    const map = {
      seat: t('phone_system:containers.e911info.filter.type.option.label.seat'),
      device: t('phone_system:containers.e911info.filter.type.option.label.device'),
    };
    return {
      typeOptions: getOptions(map),
      getTypeLabelByValue: getLabelByValue(map),
    };
  }, [t]);

  return {
    [FilterType.SiteLocation]: {
      locationOptions,
      getLocationLabelById,
    },
    [FilterType.Source]: {
      sourceOptions,
      getSourceLabelByValue,
    },
    [FilterType.Type]: {
      typeOptions,
      getTypeLabelByValue,
    },
  };
};
