import { useTranslation } from 'react-i18next';
import { LabeledText } from 'shared/components/Labeled';

const NameIdFormat = () => {
  const { t } = useTranslation();
  return (
    <LabeledText
      id="input-name-id-format"
      label={t(
        'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.name_id_format.label',
      )}
      text={t(
        'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.name_id_format.text',
      )}
      tooltip={t(
        'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.name_id_format.tooltip',
      )}
      textWidth="auto"
    />
  );
};

export default NameIdFormat;
