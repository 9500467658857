import i18next from 'i18next';
import Joi from 'joi';
import { ABSOLUTE_CALLER_ID, ABSOLUTE_MODE_OPTIONS } from '../../../constants';

const schema = () =>
  Joi.object({
    key: Joi.when(Joi.ref('mode'), {
      is: Joi.boolean().valid(ABSOLUTE_CALLER_ID),
      then: Joi.string()
        .required()
        .messages({
          'string.empty': i18next.t('common:validation.general.required'),
        }),
      otherwise: Joi.optional(),
    }),
    mode: Joi.string()
      .valid(...ABSOLUTE_MODE_OPTIONS)
      .required()
      .messages({
        'string.empty': i18next.t('common:validation.general.required'),
        'any.only': i18next.t('common:validation.general.required'),
      }),
    usedKeys: Joi.array().items(Joi.string()),
  });

export default schema;
