import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import { ListItemUpperProps as Props } from './definition';
import StyledListItemUpper from './style';

export { CONSTANTS as LIST_ITEM_UPPER_CONSTANTS } from './style';

const ListItemUpper: FunctionComponent<Props> = ({ isOpen, name }: Props): JSX.Element => (
  <StyledListItemUpper isOpen={isOpen}>
    <span>{name}</span>
    <Icon name="chevron-up" subset={16} />
  </StyledListItemUpper>
);

export default ListItemUpper;
