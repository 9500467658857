/**
 * This maps existing app names to icons when an app name
 * is not represented in the existing list of icons
 */
export const map: { [key: string]: string } = {
  blacklists: 'call-blocking',
  callflows: 'phone-system',
  callcenter: 'call-center',
  'callcenter-ooma': 'call-center-1',
  callqueues: 'call-center-2',
  'callqueues-pro': 'call-center-pro',
  'callqueues-reporting': 'call-center-reporting',
  cdrs: 'cdrs',
  dialplans: 'dial-plans',
  msteams: 'ms-teams',
  keyman: 'dial-plans',
  userportal: 'user-portal',

  /** apps missing icons (temporarily using voip icon */
  'appexchange-submission': 'voip',
  'google-assistant': 'voip',
  'iframe-sample': 'voip',
  'integration-telnyx': 'voip',
};
