import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFileUploadProps as Props } from './definition';

const StyledFileUpload = styled.div<Props>`
  ${(props) => {
    const { isFileNameBelow, disabled, theme } = props;
    return css`
      ${mixin.flex(
        isFileNameBelow
          ? { alignItems: 'flex-start', direction: 'column' }
          : { justifyContent: 'flex-start' },
      )};

      input {
        display: none;
      }

      label {
        ${mixin.button({ props })};
        width: auto;

        ${disabled &&
        css`
          border: 1px solid ${theme.core.input.border.default};
          color: ${theme.core.input.border.default};
          pointer-events: none;
        `}
      }
    `;
  }}
`;

export default StyledFileUpload;
