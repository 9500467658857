import { DropType } from 'models/Callflow/store/definition';
import { selectCallflowDialogData } from 'models/Callflow/store/selectors';
import { addTreeNode, dismissActionDialog, updateTreeNode } from 'models/Callflow/store/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DialogActionsCloseReasons } from 'shared/components/Dialog';
import { DIALOG_COMPONENTS } from './components';
import { DialogData, DialogInfo, NodeData } from './definition';

const CallflowActionDialogRoot = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  if (!id) {
    throw new Error('id is required');
  }

  // Gets the information required for the dialog box
  // If the node exists, then it grabs the associated information
  // If the node does not exist, then it the data required for the node
  const dialogInfo: DialogInfo = useSelector((state) => selectCallflowDialogData(state, id));
  const isNewNode = dialogInfo && !('nodeId' in dialogInfo.data);
  const node = dialogInfo && !isNewNode ? undefined : { ...dialogInfo };

  // Detect whether the user action is drag and drop and the target node exists
  const isDragAndDrop = dialogInfo && 'targetNodeId' in dialogInfo.data;
  let isTargetNodeExists = false;
  if (isDragAndDrop) {
    isTargetNodeExists =
      (dialogInfo.data as DialogData)?.type === DropType.ROOT ||
      !!(dialogInfo.data as DialogData)?.targetNodeId;
  }

  const onSave = (payload: NodeData, isDirty = false) => {
    if (isNewNode) dispatch(addTreeNode({ ...payload, id, node: node?.data }));
    else dispatch(updateTreeNode({ ...payload, id, isDirty }));
    dispatch(dismissActionDialog({ id }));
  };

  const onClose = (
    handleSubmit: any,
    submitHandler: any,
    closeResponse: { reason: DialogActionsCloseReasons },
    closeAction: 'escapeKeyDown' | 'backdropClick',
  ) => {
    if (closeResponse.reason === 'saveClicked') {
      handleSubmit(submitHandler)();
      return;
    }
    if (!closeAction) dispatch(dismissActionDialog({ id, isCancel: true }));
  };

  if (dialogInfo?.data) {
    if (isDragAndDrop && !isTargetNodeExists) return null;
    const SpecificDialog = DIALOG_COMPONENTS[dialogInfo.type];
    return (
      <SpecificDialog onSave={onSave} onClose={onClose} data={isNewNode ? {} : dialogInfo.data} />
    );
  }

  return null;
};

export default CallflowActionDialogRoot;
