import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledRightItemsProps as Props } from './definition';

const StyledRightItems = styled(Box)<Props>`
  ${({ theme }) => css`
    ${mixin.flex({ justifyContent: 'flex-end' })};
    margin: 0 0 0 auto;

    > div:not(:first-of-type) {
      margin: ${theme.spacing(0, 0, 0, 4)};
    }
  `}
`;

export default StyledRightItems;
