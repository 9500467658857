import { BuyNumberFlowType } from './definition';

const meta = {
  local: {
    icon: 'local',
    label: 'common:component.buy_number_flow.local.label',
    slug: BuyNumberFlowType.Local,
  },
  tollFree: {
    icon: 'toll_free',
    label: 'common:component.buy_number_flow.toll_free.label',
    slug: BuyNumberFlowType.TollFree,
  },
};

export default meta;
