import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledAppLinkProps as Props } from './definition';

const StyledAppLink = styled.div<Props>`
  ${(props) => css`
    > a {
      ${mixin.borderRadius()};
      ${mixin.flex({ justifyContent: 'flex-start' })};
      ${mixin.transition([{ property: 'background-color' }, { property: 'transform' }])};
      padding: 12px;
      text-decoration: none;

      &:hover {
        background-color: ${props.theme.core.button.grey};
        transform: translateX(12px);
      }

      > span {
        margin: 0 0 0 12px;
        color: ${props.theme.whitelabel.primary.color};
        font-size: 1.4em;
      }

      ${props.hasMargin &&
      css`
        margin: 24px 0 0;
      `}
    }
  `}
`;

export default StyledAppLink;
