import { ForwardedRef, forwardRef, FunctionComponent } from 'react';
import InputLabel from 'shared/components/InputLabel';
import InputMessage from 'shared/components/InputMessage';
import CopyToClipboardButton from './components/CopyToClipboardButton';
import { DirtyIcon, InfoIcon } from './components/Icon';
import defaultProps from './default';
import { CoreLabeledProps as Props } from './definition';

export { default as LabeledButton } from './components/LabeledButton';
export { default as LabeledCheckbox } from './components/LabeledCheckbox';
export { default as LabeledEditableList } from './components/LabeledEditableList';
export { default as LabeledInput } from './components/LabeledInput';
export { default as LabeledPicker } from './components/LabeledPicker';
export { default as LabeledRadioGroup } from './components/LabeledRadioGroup';
export { default as LabeledSelect } from './components/LabeledSelect';
export { default as LabeledSelectTimeZone } from './components/LabeledSelectTimeZone';
export { default as LabeledSwitch } from './components/LabeledSwitch';
export { default as LabeledText } from './components/LabeledText';
export { default as LabeledTextarea } from './components/LabeledTextarea';
export type { Feedback } from './definition';
export { DirtyIcon, InfoIcon };

const Labeled: FunctionComponent<Props> = forwardRef(
  (props: Props, ref: ForwardedRef<unknown>): JSX.Element => {
    const {
      id,
      type,
      hasDirtyIcon,
      hasMargin,
      hasNoWrap,
      hasRightMargin,
      hasSmallMargin,
      isDirty,
      isLabelAbove,
      label,
      labelProps,
      labelWidth,
      inputWidth,
      sizeOffset,
      feedback,
      tooltip,
      tooltipWidth,
      copyToClipboard,
      children,
      dataTestId,
      styledComponent: StyledLabeled,
    }: Props = {
      ...defaultProps,
      ...props,
    };

    const isInline = ['featureCode', 'text'].includes(type as string); // TODO: Implement via defaultProps

    return (
      <StyledLabeled
        ref={ref}
        data-test-id={dataTestId}
        hasInfo={tooltip}
        hasMargin={hasMargin}
        hasNoWrap={hasNoWrap}
        hasRightMargin={hasRightMargin}
        hasSmallMargin={hasSmallMargin}
        inputWidth={inputWidth}
        isDirty={isDirty}
        isLabelAbove={isLabelAbove}
        labelWidth={labelWidth}
        leftMargin={labelProps?.leftMargin}
        sizeOffset={sizeOffset}
        type={type}
      >
        <InputLabel htmlFor={id} {...labelProps} isAbove={isLabelAbove} width={labelWidth}>
          {label}
        </InputLabel>
        <div>
          <div>
            {children}
            {feedback && <InputMessage message={feedback.message} type={feedback.type} />}
          </div>
          {!!copyToClipboard && type === 'text' && <CopyToClipboardButton text={copyToClipboard} />}
          {!!tooltip && <InfoIcon className={tooltipWidth} isInline={isInline} tooltip={tooltip} />}
          {hasDirtyIcon && <DirtyIcon isDirty={isDirty} isInline={isInline} />}
        </div>
      </StyledLabeled>
    );
  },
);

export default Labeled;
