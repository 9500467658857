import styled, { css } from 'styled-components';
import { StyledCallflowTreeProps as Props } from './definition';

const StyledCallflowTree = styled.div.attrs({ id: 'callflow-tree' })<Props>`
  ${(props) => css`
    padding: ${props.isPreview ? '0 36px' : '0'};
  `};
`;

export default StyledCallflowTree;
