import { CHARACTER } from 'constant';
import { FunctionComponent, useState } from 'react';
import Icon from 'shared/components/Icon';
import { MaskedValueProps as Props } from './definition';
import StyledMaskedValue from './style';

const MaskedValue: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { value } = props;
  const [isVisible, setVisible] = useState<boolean>(false);

  const MASK: Record<string, number | string> = {
    CHAR: '\u2022', // bullet
    COUNT: 4,
  };

  const isValidValue =
    (Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value !== '');

  let displayValue = `${MASK.CHAR} `.repeat(MASK.COUNT as number).trim();
  if (isVisible) {
    displayValue = Array.isArray(value) ? value.join(', ') : value;
  }

  return (
    <StyledMaskedValue data-test-id="apps-shared-table-cell-masked-value">
      {isValidValue ? (
        <>
          <span>{displayValue}</span>
          <Icon
            name={`visibility-${isVisible ? 'off' : 'on'}-filled`}
            size={20}
            onClick={() => setVisible((isVisible: boolean) => !isVisible)}
          />
        </>
      ) : (
        CHARACTER.EMDASH
      )}
    </StyledMaskedValue>
  );
};

export default MaskedValue;
