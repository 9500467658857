import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

const CONSTANTS = {
  MARGIN: {
    TOP: 100,
  },
  WIDTH: {
    MAX: 568,
  },
};

export const StyledDisabledSMSCreation = styled(Box)`
  ${({ theme }) => css`
    ${mixin.flex({ direction: 'column', justifyContent: 'flex-start' })};
    margin: ${CONSTANTS.MARGIN.TOP}px 0 0;
    gap: ${theme.spacing(2.66)};

    div {
      ${mixin.flex({ direction: 'column' })};
      max-width: ${CONSTANTS.WIDTH.MAX}px;
    }
  `}
`;

export const StyledText = styled(Box).attrs({ component: 'p' })`
  ${({ theme }) => css`
    margin: ${theme.spacing(1, 0, 0)};
    color: ${theme.brio.text.secondary};
    text-align: center;

    &,
    a {
      ${mixin.font({ lineHeight: '24px', size: 16, weight: 'normal' })};
    }
  `}
`;

export const StyledTitle = styled(Box).attrs({ component: 'span' })`
  font-size: 24px;
`;
