import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { testResponseArrayForErrors } from 'apps/PhoneSystem/components/EditForm/helper';
import { EditFormProps } from 'apps/PhoneSystem/definition';
import { StyledSinglePanel } from 'apps/PhoneSystem/style';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import { ADD_KEY } from 'constant';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import {
  addUserToDirectory,
  DIRECTORY_CONSTANTS,
  removeUserFromDirectory,
  schema,
  useCreateDirectoryMutation,
  useDeleteDirectoryMutation,
  useFetchDirectoryByIdQuery,
  useUpdateDirectoryMutation,
} from 'models/Directory';
import { ChangeEvent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { kazooApi, TAGS } from 'services/kazoo';
import { LabeledCheckbox, LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import store from 'store';
import LabeledEntitiesList from './components/LabeledEntitiesList';
import { defaultValues } from './default';
import { DTMFType, Fields, FormInput } from './definition';

const Edit = ({
  id,
  handleSaveSuccess,
  handleDeleteSuccess,
  handleCancel,
  setItemName,
}: EditFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();

  const { data, isLoading } = useFetchDirectoryByIdQuery({ id });
  const [createDirectory] = useCreateDirectoryMutation();
  const [updateDirectory] = useUpdateDirectoryMutation();
  const [deleteDirectory] = useDeleteDirectoryMutation();

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const {
    handleSubmit,
    setError,
    formState: { isDirty: isPageDirty },
    reset,
    watch,
  } = formMethods;

  const watchFields = {
    name: watch(Fields.name),
  };

  useEffect(() => {
    const formData = enhancedFormUtility.transformDataToFormData(data, defaultValues);
    reset(formData); // Populates form components once data is loaded.
    enhancedFormUtility.ensureFormData(formData, { root: Object.values(Fields) });
  }, [data, reset]);

  useEffect(
    () =>
      enhancedFormUtility.ensureFormDefaultValues(defaultValues, { root: Object.values(Fields) }),
    [],
  );

  const onSubmit: SubmitHandler<FormInput> = async (body) => {
    const postBody = omit(body, 'users');
    try {
      let updatedId = id;
      if (id === ADD_KEY) {
        const responseData = await createDirectory({ body: postBody }).unwrap();
        const { data: newData } = responseData;
        updatedId = newData.id;
      } else {
        await updateDirectory({ id, body: postBody }).unwrap();
      }

      const removed =
        (id === ADD_KEY
          ? []
          : data.users.filter(
              ({ user_id: u1, callflow_id: c1 }: any) =>
                !body.users.some(
                  ({ user_id: u2, callflow_id: c2 }: any) => isEqual(u2, u1) && isEqual(c2, c1),
                ),
            )) || [];
      const added =
        (id === ADD_KEY
          ? body.users
          : body.users.filter(
              ({ user_id: u1, callflow_id: c1 }: any) =>
                !data.users.some(
                  ({ user_id: u2, callflow_id: c2 }: any) => isEqual(u2, u1) && isEqual(c2, c1),
                ),
            )) || [];

      const directoryPromises = await Promise.all([
        ...removed.map((item: any) => removeUserFromDirectory(updatedId, item.user_id)),
        ...added.map((item: any) => addUserToDirectory(updatedId, item.user_id, item.callflow_id)),
      ]);

      store.dispatch(kazooApi.util.invalidateTags([{ type: TAGS.DIRECTORY, id }]));
      const numberOfRequests = removed.length + added.length;
      const numberOfErrors = testResponseArrayForErrors(directoryPromises);
      if (numberOfErrors > 0) {
        const errorMessage = t('common:multiple_requests.error', {
          numberOfRequests,
          numberOfErrors,
        });
        showToast.error(errorMessage);
      } else {
        handleSaveSuccess?.({ shouldRedirect: id === ADD_KEY });
      }
    } catch (exception) {
      showErrorMessage({ isFromException: true, errors: exception, setError });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDirectory({ id }).unwrap();
      handleDeleteSuccess?.();
    } catch (exception) {
      showToast.delete.error();
    }
  };

  const handleDTMFChange = (e: ChangeEvent<HTMLInputElement>, type: DTMFType) => {
    return type === DTMFType.min
      ? e.target.value === '' ||
        parseInt(e.target.value, 10) > DIRECTORY_CONSTANTS.INPUT.MIN_DTMF.MAX
        ? DIRECTORY_CONSTANTS.INPUT.MIN_DTMF.MIN
        : e.target.value
      : e.target.value === '' ||
        parseInt(e.target.value, 10) > DIRECTORY_CONSTANTS.INPUT.MAX_DTMF.MAX
      ? DIRECTORY_CONSTANTS.INPUT.MAX_DTMF.MIN
      : e.target.value;
  };

  const searchFieldOptions = [
    {
      label: t(
        'phone_system:containers.directories.field.fields_to_search.options.first_and_last_name',
      ),
      value: 'both',
    },
    {
      label: t('phone_system:containers.directories.field.fields_to_search.options.first_name'),
      value: 'first_name',
    },
    {
      label: t('phone_system:containers.directories.field.fields_to_search.options.last_name'),
      value: 'last_name',
    },
  ];

  const sortByOptions = [
    {
      label: t('phone_system:containers.directories.field.sort_by.options.first_name'),
      value: 'first_name',
    },
    {
      label: t('phone_system:containers.directories.field.sort_by.options.last_name'),
      value: 'last_name',
    },
  ];

  useEffect(() => {
    setItemName?.(watchFields.name);
  }, [watchFields.name, setItemName]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditForm
      entityLabel={t('phone_system:containers.directories.label')}
      entityName={data?.name}
      onCancel={handleCancel}
      onSave={handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }))}
      onDelete={id !== ADD_KEY ? handleDelete : undefined}
      isPageDirty={isPageDirty}
      formMethods={formMethods}
    >
      <EditPanel isSinglePanel>
        <StyledSinglePanel>
          <div>
            {/* Name */}
            <HookFormInputWrapper name={Fields.name}>
              {({ ref, feedback, isDirty, ...formProps }) => (
                <LabeledInput
                  isDirty={isDirty}
                  isLabelAbove
                  feedback={feedback}
                  label={t('phone_system:containers.directories.field.name.label')}
                  inputProps={{
                    ...formProps,
                  }}
                />
              )}
            </HookFormInputWrapper>

            {/* User Callflow list */}
            <HookFormInputWrapper name={Fields.users}>
              {({ ref, isDirty, feedback, value, onChange }) => (
                <LabeledEntitiesList
                  isDirty={isDirty as boolean}
                  feedback={feedback}
                  label={t('phone_system:containers.directories.field.users.label')}
                  data={value}
                  onChange={onChange}
                />
              )}
            </HookFormInputWrapper>

            {/* Minimum DTMF */}
            <HookFormInputWrapper name={Fields.min_dtmf}>
              {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
                <LabeledInput
                  isDirty={isDirty}
                  isLabelAbove
                  feedback={feedback}
                  label={t('phone_system:containers.directories.field.min_number_of_input.label')}
                  inputProps={{
                    ...formProps,
                    type: 'number',
                    value,
                    inputProps: {
                      min: DIRECTORY_CONSTANTS.INPUT.MIN_DTMF.MIN,
                    },
                    onChange: (e: ChangeEvent<HTMLInputElement>) => {
                      onChange(handleDTMFChange(e, DTMFType.min));
                    },
                  }}
                />
              )}
            </HookFormInputWrapper>

            {/* Maximum DTMF */}
            <HookFormInputWrapper name={Fields.max_dtmf}>
              {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
                <LabeledInput
                  isDirty={isDirty}
                  isLabelAbove
                  feedback={feedback}
                  label={t('phone_system:containers.directories.field.max_number_of_input.label')}
                  inputProps={{
                    ...formProps,
                    type: 'number',
                    value,
                    inputProps: {
                      max: DIRECTORY_CONSTANTS.INPUT.MAX_DTMF.MAX,
                    },
                    onChange: (e: ChangeEvent<HTMLInputElement>) => {
                      onChange(handleDTMFChange(e, DTMFType.max));
                    },
                  }}
                />
              )}
            </HookFormInputWrapper>

            {/* Confim Match */}
            <HookFormInputWrapper name={Fields.confirm_match} isCheckbox>
              {({ ref, isDirty, value, ...formProps }) => (
                <LabeledCheckbox
                  hasMargin
                  isDirty={isDirty}
                  indentWidth="none"
                  label={t('phone_system:containers.directories.field.confirm_match.label')}
                  checkboxProps={{
                    ...formProps,
                  }}
                />
              )}
            </HookFormInputWrapper>

            {/* Fields to search */}
            <HookFormSelectWrapper name={Fields.search_fields} options={searchFieldOptions}>
              {({ ref, feedback, isDirty, ...formProps }) => (
                <LabeledSelect
                  isDirty={isDirty}
                  isLabelAbove
                  feedback={feedback}
                  label={t('phone_system:containers.directories.field.fields_to_search.label')}
                  selectWidth="medium"
                  selectProps={{
                    ...formProps,
                  }}
                />
              )}
            </HookFormSelectWrapper>

            {/* Sort By */}
            <HookFormSelectWrapper name={Fields.sort_by} options={sortByOptions}>
              {({ ref, feedback, isDirty, ...formProps }) => (
                <LabeledSelect
                  isDirty={isDirty}
                  isLabelAbove
                  feedback={feedback}
                  label={t('phone_system:containers.directories.field.sort_by.label')}
                  selectWidth="medium"
                  selectProps={{
                    ...formProps,
                  }}
                />
              )}
            </HookFormSelectWrapper>
          </div>
        </StyledSinglePanel>
      </EditPanel>
    </EditForm>
  );
};

export default Edit;
