import { FixedSizeList } from 'react-window';
import { SELECT_CONSTANTS } from 'shared/components/Select/constant';
import styled from 'styled-components';

const StyledWindowedMenuList = styled(FixedSizeList).attrs({
  itemSize: SELECT_CONSTANTS.HEIGHT,
  width: '100%',
})``;

export default StyledWindowedMenuList;
