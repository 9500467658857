// Media Mutations
import { Media } from 'api/media';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';
import { alterMutationBody } from '.';

export const mediaMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createMedia: builder.mutation<Media, Partial<Media>>({
      query: ({ body }) => ({
        url: Media.Create(),
        method: RequestMethod.Put,
        body: { data: { ...alterMutationBody(body) } },
        responseHandler,
      }),
      invalidatesTags: [{ type: TAGS.MEDIA, id: 'LIST' }],
    }),
    updateMedia: builder.mutation<Media, UpdateMediaParams>({
      query: ({ id, body }) => ({
        url: Media.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...alterMutationBody(body) } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.MEDIA, id }],
    }),
    deleteMedia: builder.mutation<Media, ByIdParam>({
      query: ({ id }) => ({
        url: Media.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.MEDIA, id }],
    }),
    uploadMedia: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: Media.Upload(id),
        method: RequestMethod.Post,
        body: data,
        headers: {
          'content-type': 'application/x-base64',
        },
        responseHandler,
      }),
    }),
  }),
});
