import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { VALID_SIP_CODES } from '../../constants';

const schema = () =>
  Joi.object({
    code: Joi.string()
      .pattern(VALID_SIP_CODES)
      .message(
        i18next.t(
          'phone_system:containers.callflows.callflow_action_dialog.response.error.response_code',
        ),
      )
      .required(),
    media: Joi.string().empty(''),
    message: Joi.string().allow(''),
    nodeId: Joi.string(),
  }).messages(messages());

export default schema;
