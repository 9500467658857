import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/shared/components/EnhancedFormCore/components/EditForm';
import { useCallback, useEffect } from 'react';
import { FieldErrorsImpl, SubmitHandler, useForm } from 'react-hook-form';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { checkFormEntities } from 'shared/utility';
import { EnhancedFormCoreProps as Props } from './definition';
import { enhancedFormUtility } from './utility';

export const EnhancedFormCore = <Data extends Record<string, any>>({
  actionRowProps,
  breadcrumbData,
  data,
  deleteButton,
  dialogCtx,
  onBeforeResetFormData,
  onCancel,
  onCancelSuccess,
  onDeleteSuccess,
  onFormSubmit,
  onFormSubmitError,
  onFormValidationError,
  onIsDirtyChange,
  onSaveOverride,
  onSaveSuccess,

  // react-hooks-forms props
  children,
  defaultValues,
  schema,
}: Props<Data>) => {
  const { showErrorMessage } = useShowErrorMessage();

  const formMethods = useForm<any>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const {
    formState: { dirtyFields },
    setError,
    handleSubmit,
    reset,
  } = formMethods;
  const isPageDirty = checkFormEntities(dirtyFields);

  useEffect(() => {
    onIsDirtyChange?.(isPageDirty);
  }, [isPageDirty, onIsDirtyChange]);

  useEffect(() => {
    let formData = enhancedFormUtility.transformDataToFormData<Data>(data, defaultValues);

    if (onBeforeResetFormData) {
      formData = onBeforeResetFormData(formData);
    }

    // Populates form data once data is loaded.
    reset(formData);
  }, [data, defaultValues, onBeforeResetFormData, reset]);

  const onSubmit: SubmitHandler<any> = useCallback(
    async (formData: any) => {
      try {
        await onFormSubmit?.(formData); // Form data mutation request goes here
        onSaveSuccess();
      } catch (exception) {
        onFormSubmitError?.(exception);
        // Handling Form data mutation error from kazoo
        showErrorMessage({ isFromException: true, errors: exception, setError });
        console.error('Form submit error:', exception);
        throw exception;
      }
    },
    [onFormSubmit, onFormSubmitError, onSaveSuccess, setError, showErrorMessage],
  );

  const onValidationError = useCallback(
    (errors: FieldErrorsImpl<any>) => {
      onFormValidationError?.(errors);
      showErrorMessage({ errors, setError });
      console.error('Form validation error: ', errors);
    },
    [onFormValidationError, setError, showErrorMessage],
  );

  const saveHandler = handleSubmit(onSubmit, onValidationError);

  return (
    <EditForm
      actionRowProps={actionRowProps}
      breadcrumbData={breadcrumbData}
      deleteButton={deleteButton}
      dialogCtx={dialogCtx}
      formMethods={formMethods}
      isPageDirty={isPageDirty}
      onCancel={onCancel}
      onCancelSuccess={onCancelSuccess}
      onDeleteSuccess={onDeleteSuccess}
      onSave={saveHandler}
      onSaveOverride={onSaveOverride}
    >
      {children({ defaultValues })}
    </EditForm>
  );
};
