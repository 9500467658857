import type { Transition } from 'history';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Params, useNavigate, useParams } from 'react-router-dom';
import Icon from 'shared/components/Icon';
import defaultProps from './default';
import { SideNavItemIcon, SideNavItemProps as Props } from './definition';
import { StyledSideNavItemAnchor, StyledSideNavItemLink } from './style';

export type Tx = Transition & { retry: () => void };

const isSlugFirstOccurrenceInPath = (params: Readonly<Params<string>>, slug = ''): boolean => {
  const path = params?.['*'];
  const hash = slug?.replace('./', '');

  const firstSeperatorIdx = path?.indexOf('/') || -1;
  const firstParamOfPath =
    firstSeperatorIdx > -1 ? path?.substring(0, firstSeperatorIdx) : path?.substring(0);
  const isHashInFirstParam = firstParamOfPath === hash;

  return isHashInFirstParam;
};

const SideNavItem: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { label, icon, slug, isOpen, isToggle, onClick }: Props = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const isActive = isSlugFirstOccurrenceInPath(params, slug); // Tab only active when the slug is found as the first occurence in the path
  const className = isActive ? 'active' : '';

  const handleSideNavClick = () => {
    if (slug) {
      navigate(slug ?? '.');
    } else {
      onClick();
    }
  };

  const getElement = (icon: SideNavItemIcon, label: string): JSX.Element => (
    <>
      <Icon name={icon.name} size={icon.size ?? 23} />
      <span>{t(label)}</span>
    </>
  );

  return slug ? (
    <StyledSideNavItemLink onClick={handleSideNavClick} className={className}>
      {getElement(icon, label)}
    </StyledSideNavItemLink>
  ) : (
    <StyledSideNavItemAnchor isToggle={isToggle} isOpen={isOpen} onClick={handleSideNavClick}>
      {getElement(icon, label)}
    </StyledSideNavItemAnchor>
  );
};

export default SideNavItem;
