// Group Mutations
import { Group } from 'api/group';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const groupMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createGroup: builder.mutation<Group, Partial<Group>>({
      query: ({ body }) => ({
        url: Group.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.GROUP, id: 'LIST' }],
    }),
    updateGroup: builder.mutation<Group, UpdateGroupParams>({
      query: ({ id, body }) => ({
        url: Group.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.GROUP, id }],
    }),
    deleteGroup: builder.mutation<Group, ByIdParam>({
      query: ({ id }) => ({
        url: Group.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.GROUP, id }],
    }),
  }),
});
