const defaultProps = {
  isOpen: false,
  data: [],
  columns: [],
  onApply: () => {},
  onCancel: () => {},
  onClose: () => {},
};

export default defaultProps;
