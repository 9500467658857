import { ringStrategies } from 'apps/CallCenter/containers/ManageQueues/List/utility';
import Edit from 'apps/PhoneSystem/containers/Media/Edit';
import MediaSelect from 'apps/PhoneSystem/shared/MediaSelect';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { ADD_KEY } from 'constant';
import { FunctionComponent, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogLink, DialogType } from 'shared/components/Dialog';
import { LabeledCheckbox, LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import { field } from './definition';

const BasicSection: FunctionComponent = (): ReactElement => {
  const { t } = useTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  return (
    <>
      <div role="row">
        <div role="cell">
          {/* Queue Name */}
          <HookFormInputWrapper name={field.name}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.queue_name.label')}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'call_center:containers.manage_queues.fields.queue_name.placeholder',
                  ),
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Music on hold */}
          <MediaSelect
            name={field.moh}
            isClearable={false}
            label={t('call_center:containers.manage_queues.fields.music_on_hold.label')}
            tooltip={t('call_center:containers.manage_queues.fields.music_on_hold.tooltip')}
          />
          {/* Create Media */}
          <DialogLink
            isBelow
            indentWidth="large"
            text={t('phone_system:containers.menu.section.basics.create_media.label')}
            type="add"
            onClick={() => setIsEditDialogOpen(true)}
          />
          <Dialog
            open={isEditDialogOpen}
            title={t('phone_system:containers.menu.section.basics.create_media.title')}
            type={DialogType.XLarge}
            onClose={() => setIsEditDialogOpen(false)}
          >
            <Edit
              id={ADD_KEY}
              isEmbedded
              handleCancel={() => setIsEditDialogOpen(false)}
              handleDeleteSuccess={() => setIsEditDialogOpen(false)}
              handleSaveSuccess={() => setIsEditDialogOpen(false)}
            />
          </Dialog>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Call recording URL */}
          <HookFormInputWrapper name={field.callRecordingUrl}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.call_recording_url.label')}
                tooltip={t(
                  'call_center:containers.manage_queues.fields.call_recording_url.tooltip',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'call_center:containers.manage_queues.fields.call_recording_url.placeholder',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>

          {/* Call Recording Enabled */}
          <HookFormInputWrapper name={field.recordCaller} isCheckbox>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                hasMargin
                isBelow
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'call_center:containers.manage_queues.fields.call_recording_enabled.label',
                )}
                tooltip={t(
                  'call_center:containers.manage_queues.fields.call_recording_enabled.tooltip',
                )}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Strategy */}
          <HookFormSelectWrapper name={field.strategy} options={ringStrategies()}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.strategy.label')}
                tooltip={t('call_center:containers.manage_queues.fields.strategy.tooltip')}
                selectProps={{
                  ...formProps,
                  placeholder: t(
                    'call_center:containers.manage_queues.fields.strategy.placeholder',
                  ),
                }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
        <div role="cell">
          {/* Call Wrap-up Time */}
          <HookFormInputWrapper name={field.agentWrapupTime}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.agent_wrapup_time.label')}
                tooltip={t('call_center:containers.manage_queues.fields.agent_wrapup_time.tooltip')}
                inputProps={{
                  ...formProps,
                  adornment: {
                    type: 'text',
                    position: 'end',
                    value: 'Seconds(s)',
                  },
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Max Number of Calls */}
          <HookFormInputWrapper name={field.maxQueueSize}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.max_number_of_calls.label')}
                tooltip={t(
                  'call_center:containers.manage_queues.fields.max_number_of_calls.tooltip',
                )}
                inputProps={{
                  ...formProps,
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* Max Hold Time */}
          <HookFormInputWrapper name={field.connectionTimeout}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.max_hold_time.label')}
                tooltip={t('call_center:containers.manage_queues.fields.max_hold_time.tooltip')}
                inputProps={{
                  ...formProps,
                  adornment: {
                    type: 'text',
                    position: 'end',
                    value: 'Seconds(s)',
                  },
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Caller Exit Key */}
          <HookFormInputWrapper name={field.callerExitKey}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.caller_exit_key.label')}
                tooltip={t('call_center:containers.manage_queues.fields.caller_exit_key.tooltip')}
                inputProps={{
                  ...formProps,
                  inputProps: {
                    maxLength: 9,
                  },
                  placeholder: t(
                    'call_center:containers.manage_queues.fields.caller_exit_key.placeholder',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row" className="overline">
        <div role="cell">
          {/* Periodic position announcements to caller checkbox */}
          <HookFormInputWrapper name={field.positionAnnouncementsEnabled} isCheckbox>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'call_center:containers.manage_queues.fields.position_announcements_enabled.label',
                )}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Periodic average wait time announcements to caller checkbox */}
          <HookFormInputWrapper name={field.waitTimeAnnouncementsEnabled} isCheckbox>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'call_center:containers.manage_queues.fields.wait_time_announcements_enabled.label',
                )}
                checkboxProps={{
                  ...formProps,
                }}
                hasMargin
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Announcement Time */}
          <HookFormInputWrapper name={field.announcementsInterval}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.announcement_time.label')}
                inputProps={{
                  ...formProps,
                  adornment: {
                    type: 'text',
                    position: 'end',
                    value: 'Seconds(s)',
                  },
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      {/* Enforce queue wait time threshold */}
      <div role="row" className="overline">
        <div role="cell">
          <HookFormInputWrapper name={field.sendWaitTime} isCheckbox>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.send_wait_time.label')}
                checkboxProps={{
                  ...formProps,
                }}
                tooltip={t('call_center:containers.manage_queues.fields.send_wait_time.tooltip')}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      {/* Hide queue in dashboard */}
      {/* <div role="row">
        <div role="cell">
          <HookFormInputWrapper name={field.hideInDashboard} isCheckbox>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                feedback={feedback}
                label={t('call_center:containers.manage_queues.fields.hide_queue.label')}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div> */}
      <div role="row">
        <div role="cell">
          {/* No agent */}
          <HookFormInputWrapper name={field.enterWhenEmpty} isCheckbox>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'call_center:containers.manage_queues.fields.allow_callers_with_no_agents.label',
                )}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
    </>
  );
};

export default BasicSection;
