// PhoneNumber Mutations
import { PhoneNumber } from 'api/phoneNumber';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const phoneNumberMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createPhoneNumber: builder.mutation<PhoneNumber, Partial<PhoneNumber>>({
      query: ({ body }) => ({
        url: PhoneNumber.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.PHONENUMBER, id: 'LIST' }],
    }),

    addPhoneNumbersToCollection: builder.mutation<PhoneNumber, AddPhoneNumbersToCollectionParams>({
      query: ({ body }) => ({
        url: PhoneNumber.AddNumbersToCollection(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: [{ type: TAGS.PHONENUMBER, id: 'LIST' }],
    }),

    activateCollection: builder.mutation<PhoneNumber, PhoneNumberCollectionParams>({
      query: ({ body }) => ({
        url: PhoneNumber.Activate(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: [{ type: TAGS.PHONENUMBER, id: 'LIST' }],
    }),

    moveCollectionTo: builder.mutation<PhoneNumber, UpdatePhoneNumberParams>({
      query: ({ body, id }) => ({
        url: PhoneNumber.MoveCollectionTo(id),
        method: RequestMethod.Put,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: [{ type: TAGS.PHONENUMBER, id: 'LIST' }],
    }),

    updatePhoneNumber: builder.mutation<PhoneNumber, UpdatePhoneNumberParams>({
      query: ({ id, body }) => ({
        url: PhoneNumber.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.PHONENUMBER, id },
        { type: TAGS.PHONENUMBER, id: 'LIST' },
      ],
    }),

    deletePhoneNumber: builder.mutation<PhoneNumber, ByIdParam>({
      query: ({ id }) => ({
        url: PhoneNumber.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.PHONENUMBER, id },
        { type: TAGS.PHONENUMBER, id: 'LIST' },
      ],
    }),

    deleteList: builder.mutation<PhoneNumberResponse, PhoneNumberCollectionParams>({
      query: ({ body }) => ({
        url: PhoneNumber.DeleteList(),
        method: RequestMethod.Delete,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.PHONENUMBER, id: 'LIST' }],
    }),
  }),
});
