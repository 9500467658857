import { RowArray } from 'apps/shared/definition';
import { getTableDataFromJson } from 'apps/shared/utility';
import { columns } from 'apps/SipTrunking/utility';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import Loading from 'shared/components/Loading';
import Table, { SelectionPosition, SelectionType } from 'shared/components/Table';
import { AssignNumberDialogProps as Props } from './definition';

const AssignNumberDialog: FunctionComponent<Props> = ({
  isDialogOpen,
  onAssignNumber,
  setIsDialogOpen,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { data, isLoading, refetch: refetchPhoneNumbers } = useFetchPhoneNumbersQuery();
  const [selectedRows, setSelectedRows] = useState<RowArray>([]);

  const table = {
    columns: useMemo(() => columns().assignedNumbers.dialog, []),
    data: useMemo(() => getTableDataFromJson(data), [data]),
  };

  useEffect(() => {
    refetchPhoneNumbers();
  }, [isDialogOpen, refetchPhoneNumbers]);

  if (isLoading) {
    return <Loading />;
  }

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        setSelectedRows([]);
        setIsDialogOpen(false);
        break;
      case 'saveClicked':
        onAssignNumber(selectedRows);
        setSelectedRows([]);
        setIsDialogOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <Dialog
      hasTitle
      open={isDialogOpen}
      renderActions={
        <DialogActions
          cancelLabel={t('common:cancel')}
          saveButtonProps={{
            color: 'success',
            disabled: !selectedRows.length,
            label: t(
              'sip_trunking:containers.default.components.assigned_numbers_list.dialog.assign.save.label',
            ),
            variant: 'contained',
          }}
          onAction={handleDialogAction}
        />
      }
      title={t(
        'sip_trunking:containers.default.components.assigned_numbers_list.dialog.assign.title',
      )}
      type={DialogType.Large}
      onClose={() => setIsDialogOpen(false)}
    >
      <p>
        {t('sip_trunking:containers.default.components.assigned_numbers_list.dialog.assign.prompt')}
      </p>
      <Table
        hasActionRowMargin
        hasBorderRadius
        hasOuterBorder
        hasSearch
        hasActionRowBorder={false}
        hasActionRowPadding={{
          side: false,
        }}
        hasEditIcon={false}
        hasPaginationPadding={{
          side: false,
        }}
        columns={table.columns}
        data={table.data}
        selection={{
          hasBorder: true,
          position: SelectionPosition.Right,
          type: SelectionType.Multiple,
          setRows: setSelectedRows,
        }}
      />
    </Dialog>
  );
};

export default AssignNumberDialog;
