import { FunctionComponent } from 'react';
import { FlowContainerInnerProps as Props } from './definition';
import StyledFlowContainerInner from './style';

const FlowContainerInner: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { children, refZoom, isPreview } = props;

  return (
    <StyledFlowContainerInner ref={refZoom} hasZoom={!!refZoom} isPreview={isPreview}>
      {children}
    </StyledFlowContainerInner>
  );
};

export default FlowContainerInner;
