import { validatePhoneForE164 } from 'apps/shared/utility';
import { MEDIA_TYPE } from 'constant';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import EditableList from 'shared/components/EditableList';
import { Justify } from 'shared/components/EditableList/definition';
import FileUpload from 'shared/components/FileUpload';
import DownloadButton from 'shared/components/FileUpload/components/DownloadButton';
import { UploadFileType } from 'shared/components/FileUpload/definition';
import { LabeledRadioGroup, LabeledText } from 'shared/components/Labeled';
import { CSVToArray } from 'shared/utility';
import { CSV_INITIAL_DATA } from './constant';
import { AddNumbersProps as Props, ImportType } from './definition';
import StyledAddNumbers, { StyledDownloadButton } from './style';

const AddNumbers: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isOpen, onClose, onSave } = props;
  const [importType, setImportTyle] = useState<ImportType>(ImportType.Individual);
  const [individualList, setIndividualList] = useState<string[][]>([]);
  const [bulkList, setBulkList] = useState<string[][]>([]);
  const [fileUrl, setFileUrl] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    const blob = new Blob([CSV_INITIAL_DATA], { type: MEDIA_TYPE.TYPE.TEXT.CSV.WITH_CHARSET });
    const url = URL.createObjectURL(blob);
    setFileUrl(url);
  }, []);

  const validatePhoneNumbers = (numbers: string[][]) =>
    numbers
      .map((el) => el[0])
      .reduce(
        (result: [string[], string[]], number) => {
          const [valid, invalid] = result;
          if (validatePhoneForE164(number)) {
            valid.push(number);
          } else {
            invalid.push(number);
          }
          return result;
        },
        [[], []],
      );

  const [validNumbers, inValidNumbers] = useMemo(() => {
    return importType === ImportType.Individual
      ? validatePhoneNumbers(individualList)
      : validatePhoneNumbers(bulkList);
  }, [individualList, bulkList, importType]);

  const options = [
    {
      label: t('numbers:action_row.add_number.dialog.add_numbers.import_type.individual.label'),
      value: ImportType.Individual,
    },
    {
      label: t('numbers:action_row.add_number.dialog.add_numbers.import_type.bulk.label'),
      value: ImportType.Bulk,
    },
  ];

  const data = {
    columns: [
      {
        header: {
          text: t('numbers:action_row.add_number.dialog.add_numbers.table.title'),
          justify: Justify.Left,
        },
        input: {
          isTextarea: true,
          placeholder: t('numbers:action_row.add_number.dialog.add_numbers.table.placeholder'),
          height: 30,
        },
        width: 542,
      },
    ],
    rows: individualList,
  };

  const onFileUpload = (file: any) => {
    const reader = new FileReader();

    reader.onload = function () {
      if (typeof this.result === 'string') {
        const result = CSVToArray(this.result);
        const rows: string[][] = result.filter((number) => number[0] !== CSV_INITIAL_DATA);
        const numbers = rows.map((number) => [
          /^[+]/.test(number[0]) ? number[0] : `+${number[0]}`,
        ]);
        setBulkList(numbers);
      }
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onClose();
        break;
      case 'saveClicked':
        onSave(validNumbers, inValidNumbers);
        break;
      default:
        // Handle any other cases here
        break;
    }
  };

  return (
    <Dialog
      open={isOpen}
      renderActions={
        <DialogActions
          saveButtonProps={{
            color: 'primary',
            disabled: individualList.length === 0 && bulkList.length === 0,
            label: t('numbers:action_row.add_number.dialog.add_numbers.save_button.label'),
            variant: 'contained',
          }}
          onAction={handleDialogAction}
        />
      }
      title={t('numbers:action_row.add_number.dialog.add_numbers.title')}
      type={DialogType.Medium}
      onClose={onClose}
    >
      <StyledAddNumbers>
        <p>{t('numbers:action_row.add_number.dialog.add_numbers.description')}</p>

        <LabeledRadioGroup
          id="radio-absolute-mode"
          isLabelAbove
          label={t('common:options')}
          radioGroupProps={{
            onChange: (e) => setImportTyle(e.target.value as ImportType),
            defaultValue: ImportType.Individual,
          }}
          radioProps={options}
        />

        {importType === ImportType.Individual ? (
          <EditableList data={data} onUpdate={setIndividualList} />
        ) : (
          <>
            <FileUpload
              name="upload-csv-file"
              id="upload-csv-file"
              fileBorder
              fileType={UploadFileType.CSV}
              onChange={(e) => {
                if (e?.target.files?.length) {
                  const nextFile = e.target.files[0];
                  nextFile.lastUpload = Date.now();
                  onFileUpload(nextFile);
                }
              }}
              onDelete={() => {
                setFileUrl('');
                setBulkList([]);
              }}
            />
            <StyledDownloadButton>
              <DownloadButton fileName="template.csv" fileUrl={fileUrl} size={20}>
                <LabeledText
                  tooltip={t('numbers:action_row.add_number.dialog.add_numbers.csv_tooltip')}
                  textWidth="auto"
                  hasMargin={false}
                  text={t('numbers:action_row.add_number.dialog.add_numbers.csv_text')}
                  label=""
                  labelWidth="none"
                  labelProps={{}}
                />
              </DownloadButton>
            </StyledDownloadButton>
          </>
        )}
      </StyledAddNumbers>
    </Dialog>
  );
};

export default AddNumbers;
