// Faxbox Queries
import { Faxbox } from 'api/faxbox';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const faxboxQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchFaxboxes: builder.query<FaxboxResponse, void>({
      query: () => ({
        url: Faxbox.Fetch(),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.FAXBOX as const, id })),
              { type: TAGS.FAXBOX, id: 'LIST' },
            ]
          : [{ type: TAGS.FAXBOX, id: 'LIST' }],
    }),
    fetchFaxboxById: builder.query<Faxbox, ByIdParam>({
      query: ({ id }) => ({
        url: Faxbox.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.FAXBOX, id }];
      },
    }),
    fetchFaxboxesByOwnerId: builder.query<FaxboxResponse, ByIdParam>({
      query: ({ id }) => ({
        url: Faxbox.FetchByOwnerId(id),
        responseHandler,
      }),
      extraOptions: { maxRetries: 1 },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.FAXBOX as const, id })),
              { type: TAGS.FAXBOX, id: 'LIST' },
            ]
          : [{ type: TAGS.FAXBOX, id: 'LIST' }],
    }),
  }),
});
