import { RequestMethod } from 'definition';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { REQUEST_BODY_FORMATS } from '../../constants';

const httpVerbs = [RequestMethod.Get.toLowerCase(), RequestMethod.Post.toLowerCase()];

const schema = () =>
  Joi.object({
    custom_data: Joi.object().allow({}),
    format: Joi.string().valid(...REQUEST_BODY_FORMATS),
    http_verb: Joi.string().valid(...httpVerbs),
    nodeId: Joi.string(),
    retries: Joi.number().min(1).max(4),
    uri: Joi.string().uri().required(),
  }).messages(messages());

export default schema;
