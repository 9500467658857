import Icon from 'shared/components/Icon';
import { StyledWarningIcon } from './style';

const WarningIcon = () => (
  <StyledWarningIcon>
    <Icon name="warning-outlined" />
  </StyledWarningIcon>
);

export default WarningIcon;
