import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'shared/components/Table';
import { useToast } from 'shared/hooks/useToast';
import CONSTANTS from '../../constants';
import { prepareIncomingCallTableData } from '../../utility';
import ForfeitCallDialog from './components/ForfeitCallDialog';
import { DashboardIncomingCallsProps as Props } from './definition';
import StyledDashboardIncomingCalls from './style';
import { columns, onCopyCallIdToClipboard } from './utility';

const DashboardIncomingCalls: FunctionComponent<Props> = ({
  queuesIncomingCalls,
  selectedQueueId,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [forfeitCallId, setForfeitCallId] = useState('');
  const [forfeitQueueId, setForfeitQueueId] = useState(CONSTANTS.ALL_QUEUES_ID);

  const onDialogClose = () => {
    setIsDialogOpen(false);
    setForfeitCallId('');
    setForfeitQueueId(CONSTANTS.ALL_QUEUES_ID);
  };

  const onForfeitCallClick = (queueId: string, callId: string) => {
    setIsDialogOpen(true);
    setForfeitCallId(callId);
    setForfeitQueueId(queueId);
  };

  const tableData = useMemo(
    () =>
      prepareIncomingCallTableData({
        queuesIncomingCalls,
        selectedQueueId,
        onCopyCallIdToClipboard: onCopyCallIdToClipboard(showToast),
        onForfeitCallClick,
      }),
    [queuesIncomingCalls, selectedQueueId, showToast],
  );

  return (
    <StyledDashboardIncomingCalls data-test-id="call-center-dashboard-dashboard-incoming-calls">
      <Table
        hasSearch
        isBreadcrumbAbove
        manualPagination
        hasActionRowPadding={{
          bottom: true,
          side: false,
          top: true,
        }}
        hasCellPadding={false}
        hasEditIcon={false}
        hasHeadings={false}
        hasPagination={{
          container: false,
        }}
        hasSideMargin={false}
        columns={columns}
        data={tableData}
        noItemsFoundText={t(
          'call_center:containers.dashboard.section.incoming_calls.no_incoming_calls',
        )}
        pageSize={tableData.length}
        title={t('call_center:containers.dashboard.section.incoming_calls.title')}
      />
      <ForfeitCallDialog
        isDialogOpen={isDialogOpen}
        callId={forfeitCallId}
        queueId={forfeitQueueId}
        onDialogClose={onDialogClose}
      />
    </StyledDashboardIncomingCalls>
  );
};

export default DashboardIncomingCalls;
