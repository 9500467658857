import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export default styled(Box)`
  ${({ theme }) => css`
    ${mixin.borderRadius()};
    padding: ${theme.spacing(2)};
    border: 1px solid ${theme.core.input.border.default};
  `};
`;
