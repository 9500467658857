// import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { useFetchCallflowsQuery } from 'models/Callflow';
import { useEffect, useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import Loading from 'shared/components/Loading';
import { useResizedElementDOMRect } from 'shared/hooks/useResizedElementDOMRect';
import ListItem, { ExpandedRow } from './components/ListItem';
import { DrawerContentLowerProps as Props } from './definition';
import StyledDrawerContentLower from './style';
import { getItemSize, getListData } from './utility';

const DrawerContentLower = ({
  callflowId,
  quickViewCallflowIds,
  searchFilter = '',
  onEditClicked,
  onQuickViewClicked,
}: Props) => {
  const listRef = useRef<List<any> | null>(null);
  const {
    rect: { height },
    ref: contentRef,
  } = useResizedElementDOMRect();
  const [expandedRow, setExpandedRow] = useState<ExpandedRow>();
  const [listData, setListData] = useState<any>([]);
  const { data, isLoading } = useFetchCallflowsQuery();

  useEffect(() => {
    setListData(getListData({ callflowId, data, searchFilter }));
  }, [callflowId, data, searchFilter]);

  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [expandedRow]);

  useEffect(() => {
    setExpandedRow(undefined);
  }, [searchFilter]);

  return (
    <StyledDrawerContentLower ref={contentRef}>
      {isLoading && !height ? (
        <Loading />
      ) : (
        <List
          height={height}
          itemCount={listData.length}
          itemSize={getItemSize(expandedRow)}
          ref={listRef}
          width="100%"
        >
          {({ index, style }) => {
            const item = merge({}, listData[index], { index });
            return (
              <ListItem
                expandedRow={expandedRow}
                item={item}
                key={item.id}
                quickViewCallflowIds={quickViewCallflowIds}
                style={style}
                onEditClicked={onEditClicked}
                onQuickViewClicked={onQuickViewClicked}
                setExpandedRow={setExpandedRow}
              />
            );
          }}
        </List>
      )}
    </StyledDrawerContentLower>
  );
};

export default DrawerContentLower;
