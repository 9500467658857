import { useEffect, useRef, useState } from 'react';

export const useResizedElementDOMRect = () => {
  const ref = useRef<any>();
  const [rect, setRect] = useState<Omit<DOMRect, 'toJSON'>>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const { current } = ref;

    if (current) {
      const observer = new ResizeObserver(([entry]: Array<ResizeObserverEntry>) => {
        setRect(entry.target.getBoundingClientRect());
      });

      observer.observe(current);

      return () => observer.unobserve(current);
    }

    return () => {};
  }, [ref.current?.offsetHeight, ref.current?.offsetWidth]);

  return { rect, ref };
};
