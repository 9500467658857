import { ComponentProps } from 'shared/definition';

export enum DialogType {
  Config,
  Log,
}

export type Download = {
  /** File name */
  name: string;
  /** File url */
  url: string;
};

export interface FileDownloadDialogProps extends ComponentProps {
  /** Is the dialog open? */
  isOpen: boolean;
  /** Config/log data object */
  data: any;
  /** Type of file dialog (config/log) */
  type: DialogType;
  /** onClose event handler */
  onClose: () => void;
}
