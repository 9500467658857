export const defaultValues = {
  name: '',
  owner_id: '',
  member: {
    pins: [],
    join_muted: false,
    join_deaf: false,
  },
  play_entry_tone: false,
  play_exit_tone: false,
  play_name: false,
  conference_numbers: [],
};
