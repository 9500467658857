import { EndpointItemType, EndpointType } from 'apps/PhoneSystem/definition';
import TableSelector from 'apps/PhoneSystem/shared/TableSelector';
import { nameFormatter } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { useFetchGroupsQuery } from 'models/Group';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogLink,
  DialogType,
} from 'shared/components/Dialog';
import defaultProps from './default';
import { FieldValue, SharedDialogProps as Props } from './definition';
import translations from './translations';
import {
  getSelectedTableColumns,
  getSelectedUserCount,
  TransformDirection,
  transformMembers,
} from './utility';

const SharedDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasDialogLink,
    isDirty,
    maxSelectedUsers,
    qualifiedName,
    sharedName,
    value: selected,
    setValue: setSelected,
  } = { ...defaultProps, ...props };
  const { data: groupsData = [] } = useFetchGroupsQuery();
  const [exceededLimitDialogMode, setExceededLimitDialogMode] = useState<string | undefined>();
  const [isManageUsersDialogOpen, setIsManageUsersDialogOpen] = useState<boolean>(false);
  const [fieldValues, setFieldValues] = useState<Array<FieldValue>>([]);
  const [selectedUserCount, setSelectedUserCount] = useState<number>(0);
  const { exceeded_limit, manage_seats } = translations({ sharedName });

  const fieldArray = useMemo(
    () => ({
      fields: fieldValues,
      append: (object: FieldValue) => {
        setFieldValues((fieldValues: FieldValue[]) => [...fieldValues, object]);
      },
      remove: (index: number) => {
        setFieldValues((fieldValues: FieldValue[]) => {
          const values = [...fieldValues];
          values.splice(index, 1);
          return values;
        });
      },
      update: (index: number, object: FieldValue) => {
        setFieldValues((fieldValues: FieldValue[]) => {
          const values = [...fieldValues];
          values.splice(index, 1, object);
          return values;
        });
      },
    }),
    [fieldValues],
  );

  useEffect(() => {
    setFieldValues(transformMembers(TransformDirection.To, selected));
  }, [selected]);

  useEffect(() => {
    setSelectedUserCount(getSelectedUserCount(groupsData, fieldArray.fields));
  }, [fieldArray.fields, fieldArray.fields.length, groupsData, groupsData.length]);

  const handleAddItem = (item: EndpointItemType, endpointType: EndpointType) => {
    const newItem = {
      endpoint_type: endpointType,
      key: item.id,
      name: nameFormatter(endpointType, item),
      type: endpointType,
    };

    if (getSelectedUserCount(groupsData, [...fieldArray.fields, newItem]) > maxSelectedUsers) {
      setExceededLimitDialogMode(endpointType);
    } else {
      fieldArray.append(newItem);
    }
  };

  const handleDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    setIsManageUsersDialogOpen(false);
    switch (closeResponse.reason) {
      case 'saveClicked':
        setSelected(qualifiedName, transformMembers(TransformDirection.From, fieldValues), {
          shouldDirty: true,
        });
        break;
      case 'cancelClicked':
        setFieldValues(selected);
        break;
      default:
        break;
    }
  };

  const endpointTypes = useMemo(() => {
    return [EndpointType.User, EndpointType.Group];
  }, [sharedName]);

  return (
    <>
      {hasDialogLink && (
        <DialogLink
          hasMargin
          isDirty={isDirty}
          indentWidth="large"
          type="add"
          text={manage_seats.label}
          onClick={() => setIsManageUsersDialogOpen(true)}
        />
      )}
      <Dialog
        open={isManageUsersDialogOpen}
        renderActions={<DialogActions onAction={handleDialogAction} />}
        title={manage_seats.title}
        type={DialogType.XLarge}
        onClose={() => setIsManageUsersDialogOpen(false)}
      >
        <TableSelector
          manualSortBy
          disableSortBy
          hasActionRow
          id="shared-dialog"
          included={endpointTypes}
          selectionFieldArray={fieldArray}
          columnsSelected={getSelectedTableColumns({
            fieldArray,
            manageUsers: manage_seats,
            selectedUserCount,
            maxSelectedUsers,
          })}
          addItemHandler={handleAddItem}
        />
      </Dialog>
      <Dialog
        open={exceededLimitDialogMode !== undefined}
        title={exceeded_limit.title}
        onClose={() => setExceededLimitDialogMode(undefined)}
        renderActions={
          <DialogActions
            hasCancelOnly
            cancelLabel={exceeded_limit.cancel.label}
            onAction={() => setExceededLimitDialogMode(undefined)}
          />
        }
      >
        <p>
          {exceededLimitDialogMode !== undefined &&
            (exceededLimitDialogMode === 'group'
              ? exceeded_limit.content.group
              : exceeded_limit.content.user)}
        </p>
      </Dialog>
    </>
  );
};

export default SharedDialog;
