import { SectionTemplate } from 'apps/AdvancedProvisioner/shared/components/DynamicForm/definition';
import { useMemo } from 'react';

export const useFilteredSectionTemplate = (template: SectionTemplate) =>
  useMemo(
    () =>
      Object.entries(template).reduce((prev, [sectionKey, sectionTemplate]) => {
        if (!sectionTemplate.hidden) {
          prev[sectionKey] = sectionTemplate;
        }

        return prev;
      }, {} as SectionTemplate),
    [template],
  );
