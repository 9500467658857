import { MediaType } from 'apps/PhoneSystem/definition';
import i18next from 'i18next';
import round from 'lodash/round';
import { RefObject } from 'react';
import { ZoomMode } from './definition';

/**
 * @name getMediaType
 * @description Get the Media Type translation values.
 *
 * @returns Translation object.
 */
export const getMediaType = (): Record<MediaType, string> => ({
  [MediaType.Recording]: i18next.t('phone_system:shared.media_type.recording'),
  [MediaType.Tts]: i18next.t('phone_system:shared.media_type.tts'),
  [MediaType.Undefined]: i18next.t('phone_system:shared.media_type.undefined'),
  [MediaType.Upload]: i18next.t('phone_system:shared.media_type.upload'),
});

/**
 * @name sortSeatTypeSort
 * @description Seat Types alphabetically for display in dropdown listing
 *
 * @param a Unsorted Seat Type listing
 * @param b Unsorted Seat Type listing
 *
 * @returns Sorted Seat Type listing
 */
export const sortSeatType = (a: Record<string, any>, b: Record<string, any>): number =>
  a.label.localeCompare(b.label);

/**
 * @name updateZoom
 * @description Update zoom level (in or out) in a container element.
 *
 * @param mode Zoom in or out
 * @param ref Reference to zoom container
 * @param ref Current zoom level
 *
 * @returns {number} Updated zoom level.
 */
export const updateZoom = (
  mode: ZoomMode,
  ref: RefObject<HTMLDivElement>,
  zoom: number,
): number => {
  const scale =
    (mode === ZoomMode.IN && zoom < 1) || (mode === ZoomMode.OUT && zoom > 0.1)
      ? round(zoom + 0.1 * (mode === ZoomMode.IN ? 1 : -1), 1)
      : zoom;

  const element = ref?.current;
  if (element) {
    element.style.transform = `scale(${scale})`;
  }

  return scale;
};
