import { useFetchCallflowsQuery } from 'models/Callflow';
import { useFetchUsersQuery } from 'models/User';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableListData } from 'shared/components/EditableList';
import { LabeledEditableList } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { WidthType } from 'shared/utility/definition';
import { LabeledEntitiesListProps as Props } from './definition';

const LabeledEntitiesList: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isDirty, data, feedback, label, onChange } = props;
  const { t } = useTranslation();

  const { data: userData, isLoading: isLoadingUsers } = useFetchUsersQuery();
  const { data: callflowData, isLoading: isLoadingCallflows } = useFetchCallflowsQuery();

  const rows =
    data &&
    data?.map((row: any) => {
      const user = userData?.find((user) => user.id === row.user_id);
      const callflow = callflowData?.find((callflow) => callflow.id === row.callflow_id);

      return [
        { value: row.user_id, label: user ? `${user.first_name} ${user.last_name}` : '' },
        { value: row.callflow_id, label: callflow ? callflow.name : '' },
      ];
    });

  const convertToDirectoriesUsers = (items: any) =>
    items.map((item: any) => {
      const [user, callflow] = item;
      return { user_id: user.value, callflow_id: callflow.value };
    });

  const listData: EditableListData = {
    columns: [
      {
        header: {
          text: t('phone_system:containers.directories.field.users.entities_list.user.label'),
        },
        select: [
          {
            isFiltered: true,
            options: userData?.map((user) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
            })),
          },
        ],
      },
      {
        header: {
          text: t('phone_system:containers.directories.field.users.entities_list.callflow.label'),
        },
        select: [
          {
            options: callflowData?.map((callflow) => ({
              value: callflow.id,
              label: callflow.name,
            })),
            size: 'xlarge' as WidthType,
          },
        ],
      },
    ],
    rows: rows || [],
  };

  if (isLoadingUsers || isLoadingCallflows) {
    return <Loading hasBottomMargin />;
  }

  return (
    <LabeledEditableList
      isDirty={isDirty}
      isLabelAbove
      feedback={feedback}
      label={label}
      editableListProps={{
        data: listData,
        isWindowedSelect: true,
        onUpdate: (data: any) => onChange(convertToDirectoriesUsers(data)),
      }}
    />
  );
};

export default LabeledEntitiesList;
