import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';
import { Metadata } from '../store/definition';

class AgentAvailabilityActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.AGENT_AVAILABILITY,
      module: CallFlowModuleKey.AGENT_AVAILABILITY,
      section: CallFlowSection.CALL_CENTER,
      rules: [
        {
          type: 'quantity',
          maxSize: 2,
        },
      ],
      iconProps: {
        name: 'calendar',
      },
      label: i18next.t('phone_system:containers.callflows.actions.agent_availability.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.agent_availability.tooltip'),
    });
  }

  static subtitle = (nodeData: any, metadata: Metadata) => {
    let subtitle = metadata?.name + '';

    if (nodeData.var) {
      subtitle += ` (${nodeData.var})`;
    }

    return subtitle;
  };
}

export default AgentAvailabilityActionConfig;
