import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled, { css } from 'styled-components';
import { ACTION_CONTAINER_CONSTANTS } from './components/ActionContainer';
import { INTERACTION_BAR_CONSTANTS } from './components/InteractionBar';

const StyledEdit = styled.div.attrs({ id: 'edit-container' })`
  ${({ theme }) => css`
    section {
      position: relative;
      display: grid;
      --margin: ${theme.spacing(3)};
      grid-template:
        var(--margin) ${INTERACTION_BAR_CONSTANTS.HEIGHT.DEFAULT}px var(--margin) 1fr var(--margin)
        / 1fr ${ACTION_CONTAINER_CONSTANTS.WIDTH} var(--margin);
      width: calc(100vw - ${ACTION_CONTAINER_CONSTANTS.WIDTH});
      height: calc(100vh - (${HEADER_CONSTANTS.HEIGHT} + ${ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT}));
      overflow: auto hidden;

      .component-flow-container {
        grid-area: 1 / 1 / 6 / 4;
      }

      .component-callflow-actions-container {
        grid-area: 4 / 2 / 5 / 3;
      }

      .component-callflow-drawer {
        grid-area: 2 / 1 / 5 / 3;
        justify-self: flex-start;
      }

      .component-callflow-interaction-bar {
        grid-area: 2 / 2 / 3 / 3;
        justify-self: flex-end;
      }
    }
  `}
`;

export default StyledEdit;
