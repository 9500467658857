import i18next from 'i18next';

const translations = () => ({
  format: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.start_call_recording.add.format',
    ),
  },
  formats: [
    {
      value: 'mp3',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.start_call_recording.formats.mp3',
      ),
    },
    {
      value: 'wav',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.start_call_recording.formats.wav',
      ),
    },
  ],
  url: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.start_call_recording.add.url',
    ),
  },
  time_limit: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.start_call_recording.add.time_limit',
    ),
  },
  title: i18next.t(
    'phone_system:containers.callflows.callflow_action_dialog.start_call_recording.title',
  ),
});

export default translations;
