import { FilterGroup, useFilter } from 'apps/shared/hooks/useFilter';
import { FilterInputType } from 'apps/shared/hooks/useFilter/components/FilterDialogContent/definition';
import { ADD_KEY } from 'constant';
import { useFetchCallflowsQuery } from 'models/Callflow';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Dialog, { DialogType } from 'shared/components/Dialog';
import { FilterOption, FilterValues } from 'shared/components/FilterSelect';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import HelpDialog, { HelpDialogType } from '../components/HelpDialog';
import { FilterType } from './definition';
import { columns, getCallflowTypeLabel } from './utility';
import { CallflowType } from './utility/definition';
import { filterOptions } from './utility/filter';

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const { data, isLoading } = useFetchCallflowsQuery();

  const { filter } = useFilter({
    data,
    inputConfig: {
      filterDefaults: {
        [FilterType.Type]: [FilterOption.All, ...Object.values(CallflowType)],
      },
      filterGroup: FilterGroup.Callflows,
      items: {
        type: {
          compareFunction: (filterValues: FilterValues, { modules: [module] }) =>
            filterValues.selected?.[FilterType.Type].includes(CallflowType.Other)
              ? filterValues.selected?.[FilterType.Type].includes(module) ||
                !Object.values(CallflowType)
                  .filter((value: string) => value !== CallflowType.Other)
                  .includes(module)
              : filterValues.selected?.[FilterType.Type].includes(module),
          filterType: FilterType.Type,
          heading: t('phone_system:containers.callflows.filter.heading.type'),
          inputType: FilterInputType.Select,
          getBreadcrumbItemLabel: getCallflowTypeLabel,
          selectInputConfig: {
            isMulti: true,
            options: filterOptions(),
          },
        },
      },
    },
    setTableData,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table
      hasHelp
      hasSearch
      addButton={{
        hasAdd: true,
        label: t('phone_system:containers.callflows.table.action_row.button.add_callflow.label'),
        onAdd: () => navigate(`./${ADD_KEY}`),
      }}
      columns={columns()}
      data={tableData}
      filter={filter}
      help={{
        dialogComponent: (
          <Dialog open={isHelpOpen} type={DialogType.Help} onClose={() => setIsHelpOpen(false)}>
            <HelpDialog type={HelpDialogType.List} />
          </Dialog>
        ),
        iconTooltip: t('phone_system:containers.callflows.help'),
        setState: setIsHelpOpen,
      }}
      title={t('phone_system:containers.callflows.table.title')}
    />
  );
};

export default List;
