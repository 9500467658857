import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import { useFetchFaxboxesQuery } from 'models/Faxbox';
import { getFullNameFromUserListByUserId, useFetchUsersQuery } from 'models/User';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import { getCellValue, prepareData } from './utility';

const COLUMN_WIDTH = 300;

const List = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchFaxboxesQuery();
  const { data: usersData, isLoading: isLoadingUsers } = useFetchUsersQuery();

  const columns = useMemo(
    () => [
      {
        Header: t('phone_system:containers.faxbox.table.column.name'),
        accessor: 'name',
      },
      {
        Header: t('phone_system:containers.faxbox.table.column.owner'),
        accessor: ({ owner_id }: any) => getFullNameFromUserListByUserId(owner_id, usersData),
        width: COLUMN_WIDTH,
      },
      {
        Header: t('phone_system:containers.faxbox.table.column.inbound_notifications'),
        Cell: getCellValue,
        accessor: 'notifications.inbound.email.send_to',
        width: COLUMN_WIDTH,
      },
      {
        Header: t('phone_system:containers.faxbox.table.column.sent_notifications'),
        Cell: getCellValue,
        accessor: 'notifications.outbound.email.send_to',
        width: COLUMN_WIDTH,
      },
      {
        Header: t('phone_system:containers.faxbox.table.column.custom_smtp_address'),
        accessor: 'custom_smtp_email_address',
        width: COLUMN_WIDTH,
        Cell: ({ row: { original }, value }: any) => (
          <TableCellTruncatedValue maxWidth={COLUMN_WIDTH} text={value} />
        ),
      },
    ],
    [usersData, t],
  );

  if (isLoading || isLoadingUsers) {
    return <Loading />;
  }

  return (
    <Table
      hasSearch
      columns={columns}
      data={prepareData(data)}
      title={t('phone_system:containers.faxbox.table.title')}
    />
  );
};

export default List;
