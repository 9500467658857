import { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Dialog, { DialogActions, DialogType } from 'shared/components/Dialog';
import TimeZoneMapPicker from './components/TimeZoneMapPicker';
import defaultProps from './default';
import { TimeZoneMapPickerDialogProps as Props } from './definition';

const TimeZoneMapPickerDialog: FunctionComponent<Props> = (props: Props) => {
  const { open, onSave, onClose } = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const [timeZoneCityName, setTimeZoneCityName] = useState<string>('');

  const onTimeZoneChanged = useCallback((changedTimeZoneCityName: string) => {
    setTimeZoneCityName(changedTimeZoneCityName);
  }, []);

  const onSaveHandler = useCallback(() => {
    onSave({ timeZoneCityName });
    onClose();
  }, [timeZoneCityName]);

  const onCloseHandler = useCallback(() => {
    onClose();
  }, [timeZoneCityName]);

  return (
    <Dialog
      open={open}
      renderActions={
        <DialogActions
          rightButtons={
            <Button color="primary" variant="contained" onClick={onSaveHandler}>
              {t('common:confirm')}
            </Button>
          }
        />
      }
      title={t('common:component.time_zone_map_picker_dialog.header')}
      type={DialogType.Medium}
      onClose={onCloseHandler}
    >
      <TimeZoneMapPicker onTimeZoneChanged={onTimeZoneChanged} />
    </Dialog>
  );
};

export default TimeZoneMapPickerDialog;
