import {
  ABSOLUTE_MODE_DEFAULT,
  AVAILABLE,
  DEFAULT_KEY,
  MATCH_VALUE,
} from 'apps/PhoneSystem/containers/Callflows/Edit/components/SelectKeyDialog/constants';
import { v4 as uuidv4 } from 'uuid';
import { getCallflowActionConfig } from '../actions';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, QuantityRule } from '../definition';
import { Callflow, TreeNodeInterface } from './definition';

export class TreeNode implements TreeNodeInterface {
  isActivated = false;
  isInsertable = false;
  isDroppable = true;
  isMultinary = false;
  isTerminal = false;
  isDirty = false;
  isNew = false;
  actionName: CallFlowKey;
  callflow: CallFlowAction;
  parentId = '';
  key;
  module;
  children = {};
  data = {
    id: undefined,
    nodeId: '',
  };

  constructor(
    module: CallFlowModuleKey,
    data = { id: undefined, action: undefined, nodeId: undefined },
    key = DEFAULT_KEY,
    children = {},
  ) {
    this.data = {
      ...data,
      nodeId: data.nodeId ? data.nodeId : uuidv4(),
    };
    this.actionName = TreeNode.constructAction(module, data?.action, data?.id);
    this.callflow = getCallflowActionConfig(this.actionName);
    this.children = children;
    this.module = module;
    this.isMultinary = TreeNode.isMultinary(this.callflow);
    this.isTerminal = TreeNode.isTerminal(this.callflow);
    this.key = key;
  }

  static getDefaultKey(node: TreeNodeInterface) {
    const { actionName } = node;
    // These don't use the typical pattern of underscore for default
    const keyDialogDefaultOption: { [key: string]: string } = {
      [CallFlowKey.AGENT_AVAILABILITY]: AVAILABLE,
    };

    if (actionName in keyDialogDefaultOption) {
      return keyDialogDefaultOption[actionName];
    }
    // Weird situation where action block can actually be 2 different things
    if (actionName === CallFlowKey.MATCH_CALLER_ID) {
      if (node.data.use_absolute_mode) {
        return ABSOLUTE_MODE_DEFAULT;
      }
      return MATCH_VALUE;
    }

    return DEFAULT_KEY;
  }

  static getQuantityRule(action: CallFlowAction) {
    // Not in dictionary, default to false
    if (!action) return false;

    // There are no rules for this module
    if (!action.rules || action.rules.length < 1) return false;

    return action.rules.find((rule: QuantityRule) => rule.type === 'quantity');
  }

  static isMultinary(action: CallFlowAction) {
    const quantityRule = TreeNode.getQuantityRule(action);

    // There is no rule for quantity
    if (!quantityRule) return false;

    return quantityRule.maxSize > 1;
  }

  static isTerminal(action: CallFlowAction) {
    const quantityRule = TreeNode.getQuantityRule(action);

    // There is no rule for quantity
    if (!quantityRule) return false;

    return quantityRule.maxSize < 1;
  }

  static constructAction(module: CallFlowModuleKey, action = '', id = ''): CallFlowKey {
    // When module doesn't follow pattern
    switch (module) {
      case CallFlowModuleKey.MEDIA:
        return CallFlowKey.MEDIA;
      default:
        let actionName = '';

        // CALLFLOW-TODO: this is a temporary fix to get check voicemail work without id. Further investigation will be needed to see if it applies to all action blocks
        if (id || action === 'check') {
          actionName = 'id=*,';
        }

        if (action) {
          actionName += `action=${action},`;
        }

        actionName = actionName !== '' ? '[' + actionName.replace(/,$/, ']') : '[]';

        return <CallFlowKey>(module + actionName);
    }
  }

  static isLeaf(node: TreeNodeInterface) {
    return node.children && Object.values(node.children).length === 0;
  }

  /**
   * todo rewrite this to handle cases where it has a multinary better
   * Note: this will not work if there are branching children
   *
   * @param tree
   * @param activeNodeId
   * @returns
   */
  static findLeafNode(tree: Callflow, activeNodeId: string): TreeNodeInterface {
    const node = tree.nodes[activeNodeId];

    if (Object.keys(node.children).length > 0) {
      return TreeNode.findLeafNode(tree, Object.values(node.children)[0]);
    }

    return node;
  }
}
