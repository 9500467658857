import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';
import { Metadata } from '../store/definition';

class AgentRequiredSkillsActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.AGENT_REQUIRED_SKILLS,
      module: CallFlowModuleKey.AGENT_REQUIRED_SKILLS,
      section: CallFlowSection.CALL_CENTER,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'flag-outlined',
      },
      label: i18next.t('phone_system:containers.callflows.actions.agent_required_skills.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.agent_required_skills.tooltip'),
    });
  }

  static subtitle = (nodeData: any, metadata: Metadata) =>
    i18next.t('phone_system:containers.callflows.actions.agent_required_skills.subtitle', {
      add: nodeData?.add.length,
      remove: nodeData?.remove.length,
    });
}

export default AgentRequiredSkillsActionConfig;
