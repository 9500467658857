import MuiFormControl from '@mui/material/FormControl';
import { Autocomplete, Select } from './components';
import defaultProps from './default';
import { FilterSelectProps as Props } from './definition';
import { getClasses } from './utility';

export type { FilterValues } from './components/definition';
export { FilterOption } from './hooks/useFilterOptions';

const FilterSelect = (props: Props) => {
  const { hasAllOption, filter, isMultiSelect, options, setFilterValues } = {
    ...defaultProps,
    ...props,
  };
  const { type, values } = filter;

  return (
    <MuiFormControl classes={{ root: getClasses(isMultiSelect, values.selected[type]) }}>
      {isMultiSelect ? (
        <Select
          filter={filter}
          hasAllOption={hasAllOption}
          options={options}
          setFilterValues={setFilterValues}
        />
      ) : (
        <Autocomplete
          filter={filter}
          hasAllOption={hasAllOption}
          options={options}
          setFilterValues={setFilterValues}
        />
      )}
    </MuiFormControl>
  );
};

export default FilterSelect;
