const defaultProps = {
  hasDirtyIcon: true,
  hasMargin: true,
  isDirty: false,
  isLabelAbove: false,
  sizeOffset: 0,
  // feedback: {
  //   type: 'success' as MessageType,
  //   message:
  //     "Commander William Riker of the Starship Enterprise. I've had twelve years to think about it, and if I had it to do over again, I would have grabbed the phaser and pointed it at you instead of them.",
  // },
  // tooltip:
  //   "Commander William Riker of the Starship Enterprise. I've had twelve years to think about it, and if I had it to do over again, I would have grabbed the phaser and pointed it at you instead of them.",
};

export default defaultProps;
