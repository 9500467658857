import i18next from 'i18next';

const translations = () => ({
  buttons: {
    cancel: i18next.t('common:cancel'),
    ok: i18next.t('common:ok'),
  },
  dialog: {
    content_0: i18next.t('phone_system:containers.callflows.callflow_action_dialog.disa.content.0'),
    title: i18next.t('common:confirmation'),
  },
  interdigit: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.disa_interdigit_input.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.disa_interdigit_input.tooltip',
    ),
  },
  max_digits: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.disa_max_digits_input.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.disa_max_digits_input.tooltip',
    ),
  },
  options: [
    { value: 'default', label: i18next.t('common:default') },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
  ],
  preconnect_audio: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.preconnect_audio.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.preconnect_audio.tooltip',
    ),
  },
  preconnect_audios: [
    { value: 'dialtone', label: 'Dialtone' },
    { value: 'ringing', label: 'Ringing' },
  ],
  pin: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.disa_pin_input',
    ),
  },

  retries: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.disa_retries_input.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.disa_retries_input.tooltip',
    ),
  },
  title: i18next.t('phone_system:containers.callflows.callflow_action_dialog.disa.title'),
  use_account_caller_id: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.disa_keep_original_caller_id.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.disa.add.disa_keep_original_caller_id.tooltip',
    ),
  },
});

export default translations;
