import theme from '../core';

export default {
  styleOverrides: {
    root: {
      '.PrivatePickersYear-root button': {
        fontSize: '1rem',
        lineHeight: 1.15,
      },
      '.MuiButtonBase-root.Mui-selected, .PrivatePickersYear-root button.Mui-selected': {
        backgroundColor: theme.palette.success.main,
      },
    },
  },
};
