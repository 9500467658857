import { ComponentProps } from 'shared/definition';

export enum FeatureCodeGroupName {
  CallForward,
  DoNotDisturb,
  FollowMe,
  HotDesking,
  Misc,
  Parking,
}

type Props = ComponentProps;

export interface FeatureCodeGroupProps extends Props {
  /** Feature code group identifier */
  name: FeatureCodeGroupName;
  /** onChange event handler */
  onChange: (value: Record<string, string>) => void;
}

export type StyledFeatureCodeGroupProps = Props;
