import { URL } from 'constant';
import { FunctionComponent, useEffect, useState } from 'react';
import DashboardPanel from '../DashboardPanel';
import Maintenance from './components/Maintenance';
import Status from './components/Status';
import defaultData from './default';
import { DashboardPanelStatusProps as Props, DefaultData as Data } from './definition';

const DashboardPanelStatus: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const [data, setData] = useState<Data>(defaultData);

  useEffect(() => {
    fetch(URL.STATUS.SUMMARY)
      .then((resp) => resp.json())
      .then((res) => setData(res));
  }, []);

  return (
    <DashboardPanel type="status">
      <div className={data.scheduled_maintenances.length > 0 ? 'has-maintenance' : ''}>
        <Status data={data.status} />
        <Maintenance data={data.scheduled_maintenances} />
      </div>
    </DashboardPanel>
  );
};

export default DashboardPanelStatus;
