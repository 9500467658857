import i18next from 'i18next';
import Joi from 'joi';

const schema = () =>
  Joi.object({
    key: Joi.string()
      .required()
      .messages({
        'string.empty': i18next.t('common:validation.general.required'),
      }),
  });

export default schema;
