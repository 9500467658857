import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import StyledForm from '../StyledForm';

const CONSTANTS = {
  ICON_SIZE: '64px',
};

const StyledResetForm = styled(StyledForm)`
  ${({ theme }) => css`
    > div {
      &:last-of-type {
        ${mixin.flex({ direction: 'column' })};
        margin: ${theme.spacing(3, 0, 0)};
        color: ${theme.core.text.tertiary};

        span {
          margin: ${theme.spacing(0, 0, 2)};

          &:last-of-type {
            color: ${theme.core.color.red};
            cursor: pointer;
          }
        }
      }

      &.error {
        color: ${theme.core.color.orange};
        padding-bottom: 1em;

        svg {
          margin: ${theme.spacing(0, 1, 0, 0)};
        }
      }

      &.heading {
        border-bottom: 2px solid ${theme.core.background.grey.light.border};
        padding: ${theme.spacing(0, 0, 1)};
        margin: ${theme.spacing(0, 0, 5)};

        span {
          ${mixin.font({ size: 19, weight: 600 })};
        }
      }

      &.prompt {
        margin: ${theme.spacing(0, 0, 4)};

        span {
          ${mixin.font({ lineHeight: 1.25 })};
        }
      }

      &.reset-confirmation {
        ${mixin.font({ size: 18, weight: 500 })};
        padding: ${theme.spacing(0, 0, 6)};
        color: ${theme.core.text.dark};
        text-align: center;

        svg {
          width: ${CONSTANTS.ICON_SIZE};
          height: ${CONSTANTS.ICON_SIZE};
          margin: ${theme.spacing(3, 0, 0)};
          fill: ${theme.core.color.green.default};
        }
      }

      span {
        user-select: none;
      }
    }
  `}
`;

export default StyledResetForm;
