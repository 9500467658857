import MediaSelect from 'apps/PhoneSystem/shared/MediaSelect';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'music_on_hold.media_id',
  'contact.billing.name',
  'contact.billing.email',
  'contact.billing.number',
  'contact.technical.same_as_billing',
  'contact.technical.name',
  'contact.technical.email',
  'contact.technical.number',
];

const OptionsSection = () => {
  const { t } = useTranslation();
  const {
    formState: { dirtyFields },
    watch,
  } = useFormContext();

  return (
    <>
      <h2>
        {t(
          'phone_system:containers.account.submodule.settings.section.options.account_music_on_hold.heading',
        )}
      </h2>
      <div role="row">
        <div role="cell">
          <MediaSelect
            isClearable={Boolean(dirtyFields?.music_on_hold?.media_id)}
            name="music_on_hold.media_id"
            label={t(
              'phone_system:containers.account.submodule.settings.section.options.account_music_on_hold.media_id.label',
            )}
          />
        </div>
      </div>

      <h2>
        {t(
          'phone_system:containers.account.submodule.settings.section.options.billing_contact.heading',
        )}
      </h2>
      <div role="row">
        <div role="cell">
          {/* Billing Contact - Name */}
          <HookFormInputWrapper name="contact.billing.name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'phone_system:containers.account.submodule.settings.section.options.billing_contact.name',
                )}
                inputProps={{
                  ...formProps,
                }}
                feedback={feedback}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* Billing Contact - Number */}
          <HookFormInputWrapper name="contact.billing.number">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'phone_system:containers.account.submodule.settings.section.options.billing_contact.number',
                )}
                inputProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Billing Contact - Email */}
          <HookFormInputWrapper name="contact.billing.email">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'phone_system:containers.account.submodule.settings.section.options.billing_contact.email',
                )}
                inputProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>
        {t(
          'phone_system:containers.account.submodule.settings.section.options.technical_contact.heading',
        )}
      </h2>
      <div role="row">
        <div role="cell">
          {/* Same as Billign Contact */}
          <HookFormInputWrapper name="contact.technical.same_as_billing" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                hasMargin
                label={t(
                  'phone_system:containers.account.submodule.settings.section.options.technical_contact.same_as_billing',
                )}
                checkboxProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      {watch && !watch('contact.technical.same_as_billing') && (
        <>
          <div role="row">
            <div role="cell">
              {/* Technical Contact - Name */}
              <HookFormInputWrapper name="contact.technical.name">
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledInput
                    label={t(
                      'phone_system:containers.account.submodule.settings.section.options.technical_contact.name',
                    )}
                    inputProps={{
                      ...formProps,
                    }}
                    isDirty={isDirty}
                    feedback={feedback}
                  />
                )}
              </HookFormInputWrapper>
            </div>
            <div role="cell">
              {/* Technical Contact - Number */}
              <HookFormInputWrapper name="contact.technical.number">
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledInput
                    label={t(
                      'phone_system:containers.account.submodule.settings.section.options.technical_contact.number',
                    )}
                    inputProps={{
                      ...formProps,
                    }}
                    isDirty={isDirty}
                    feedback={feedback}
                  />
                )}
              </HookFormInputWrapper>
            </div>
          </div>
          <div role="row">
            <div role="cell">
              {/* Technical Contact - Email */}
              <HookFormInputWrapper name="contact.technical.email">
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledInput
                    label={t(
                      'phone_system:containers.account.submodule.settings.section.options.technical_contact.email',
                    )}
                    inputProps={{
                      ...formProps,
                    }}
                    isDirty={isDirty}
                    feedback={feedback}
                  />
                )}
              </HookFormInputWrapper>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OptionsSection;
