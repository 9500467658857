import i18next from 'i18next';

const translations = () => ({
  dialog: {
    title: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.components.table_selector.confirmation_dialog.title',
    ),
  },
});

export default translations;
