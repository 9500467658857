/* eslint-disable react-hooks/rules-of-hooks */
import { ExitConfirmationDialogContext } from 'apps/store/contexts';
import { useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from 'shared/hooks/useToast';
import { HandleDeleteSuccessParam, HandleSaveSuccessParam } from '../definition';

/**
 * A custom hook to accept a function component and inject
 * navigation props, and return a function component
 */
export const useNavigation = (Component: any, idKey?: string) => {
  /**
   * The WrappedComponent must be memoized to avoid unexpected unmount
   * of component when navigating children root under same parent.
   *
   * Note: useCallback is used to memoize the component constructor function
   * so it will not create a new component instance. Using React.memo can merely
   * avoid re-render for same props but still create a new component instance.
   */
  const WrappedComponent = useCallback(
    (props: any) => {
      const params = useParams();
      const id = params[idKey ?? 'id'];
      const navigate = useNavigate();
      const { showToast } = useToast();
      const exitConfirmationDialogCtx = useContext(ExitConfirmationDialogContext);

      const navProps = {
        id,
        handleCancelSuccess: () => {
          navigate('..');
        },
        handleDeleteSuccess: ({ hasToast = true, toastMessage }: HandleDeleteSuccessParam = {}) => {
          if (hasToast) {
            showToast.delete.success(toastMessage);
          }
          exitConfirmationDialogCtx.onIsDirtyChange?.(false); // Clear the nav blocker
          navigate('..');
        },
        handleSaveSuccess: ({
          hasToast = true,
          shouldRedirect = false,
          navigationOverride,
          toastMessage,
        }: HandleSaveSuccessParam = {}) => {
          if (hasToast) {
            showToast.success(toastMessage);
          }

          if (shouldRedirect) {
            exitConfirmationDialogCtx.onIsDirtyChange?.(false); // Clear the nav blocker
            if (navigationOverride) {
              navigate(navigationOverride.path, navigationOverride.options);
            } else {
              navigate('..');
            }
          }
        },
      };

      return <Component {...navProps} {...props} />;
    },
    [Component],
  );

  return WrappedComponent;
};
