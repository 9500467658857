import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { DIAL_STRATEGIES, ENDPOINT_TYPES } from '../../constants';

const schema = () =>
  Joi.object({
    delay: Joi.string(),
    endpoints: Joi.array().items(
      Joi.object({
        delay: Joi.number().min(0).required(),
        endpoint_type: Joi.string()
          .valid(...ENDPOINT_TYPES)
          .required(),
        id: Joi.string(),
        key: Joi.string(),
        name: Joi.string(),
        timeout: Joi.number().min(0).required(),
      }),
    ),
    ignore_forward: Joi.boolean(),
    name: Joi.string().required(),
    nodeId: Joi.string(),
    repeats: Joi.number().min(0).required(),
    ringback: Joi.string().allow(''),
    strategy: Joi.string()
      .valid(...DIAL_STRATEGIES)
      .required(),
    timeout: Joi.number().min(0).required(),
  }).messages(messages());

export default schema;
