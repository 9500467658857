import { themeColor } from 'theme';
import theme from '../../../core';

export default {
  '.MuiCardContent-root': {
    '&, > div, > div a': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    justifyContent: 'space-between',
    height: 'calc(100% - 20px)',
    '> div': {
      height: '100%',
      '&, a': {
        width: '100%',
      },
      a: {
        padding: '32px 24px 24px',
        backgroundColor: themeColor.core.background.grey.medium,
        borderRadius: 6,
        textDecoration: 'none',
        transition: theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.short,
        }),
        '&:hover': {
          backgroundColor: themeColor.whitelabel.secondary.color,
          span: {
            color: themeColor.core.color.white,
          },
          'svg path': {
            fill: themeColor.core.color.white,
          },
        },
        '&:not(:first-of-type)': {
          margin: '18px 0 0',
        },
        span: {
          margin: '24px 0 0',
          fontSize: 18,
          color: themeColor.core.text.dark,
          lineHeight: '1.2em',
          textAlign: 'center',
          transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.short,
          }),
        },
        'svg path': {
          fill: themeColor.core.text.dark,
          transition: theme.transitions.create(['fill'], {
            duration: theme.transitions.duration.short,
          }),
        },
      },
    },
    '.MuiTypography-h2': {
      margin: '0 auto 0 0',
    },
  },
};
