// Account Mutations
import { Account } from 'api/account';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const accountMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createAccountById: builder.mutation<Account, Partial<AccountBody>>({
      query: ({ id, body }) => ({
        url: Account.CreateById(id),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.ACCOUNT, id: 'LIST' }, { type: TAGS.ACCOUNT }],
    }),
    updateAccountCallerInfoMatchById: builder.mutation<
      AccountCallerInfoMatch,
      AccountCallerInfoMatchCreate
    >({
      query: ({ id, body: { screen_pop_enabled, screen_pop_platforms } }) => ({
        url: Account.FetchCallerInfoMatchById(id),
        method: RequestMethod.Post,
        body: {
          data: {
            screen_pop_enabled,
            screen_pop_platforms: screen_pop_platforms.map(({ newId, ...rest }) => rest),
          },
        },
      }),
      invalidatesTags: () => [{ type: TAGS.CALLERINFOMATCH }],
    }),

    deleteAccountById: builder.mutation<Account, ByIdParam>({
      query: ({ id }: ByIdParam) => ({
        url: Account.DeleteById(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.ACCOUNT, id }],
    }),
    demoteAsResellerByAccountId: builder.mutation<Account, ByIdParam>({
      query: ({ id }: ByIdParam) => ({
        url: Account.UpdateResellerFlagById(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.ACCOUNT, id }],
    }),
    patchAccountById: builder.mutation<Account, AccountBody>({
      query: ({ id, body }) => ({
        url: Account.PatchById(id),
        method: RequestMethod.Patch,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.ACCOUNT }],
    }),
    promoteToResellerByAccountId: builder.mutation<Account, ByIdParam>({
      query: ({ id }: ByIdParam) => ({
        url: Account.UpdateResellerFlagById(id),
        method: RequestMethod.Put,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.ACCOUNT, id }],
    }),
    updateAccountById: builder.mutation<Account, Partial<AccountBody>>({
      query: ({ id, body }) => ({
        url: Account.UpdateById(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.ACCOUNT, id },
        { type: TAGS.ACCOUNT },
      ],
    }),
    updateAccountHeroAppsById: builder.mutation<AccountHeroApps, AccountHeroAppsBody>({
      query: ({ id, body }) => ({
        url: Account.UpdateHeroAppsById(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.ACCOUNT, id },
        { type: TAGS.ACCOUNT_HERO_APPS },
      ],
    }),
    updateAccountDesktopAppZoneById: builder.mutation<
      AccountDesktopAppZone,
      AccountDesktopAppZoneBody
    >({
      query: ({ id, body }) => ({
        url: Account.UpdateDesktopAppZoneById(id),
        method: RequestMethod.Patch,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.ACCOUNT, id },
        { type: TAGS.ACCOUNT_DESKTOP_APP_ZONE },
      ],
    }),
  }),
});
