import { RowArray } from 'apps/shared/definition';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import Table, { SelectionPosition, SelectionType } from 'shared/components/Table';
import { AssignAgentsDialogProps as Props } from './definition';
import { columns, prepareTableData } from './utility';

const AssignAgentsDialog = ({
  isDialogOpen,
  queueAgents,
  users,
  onAssignAgents,
  setIsDialogOpen,
}: Props) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<RowArray>([]);

  const tableData: Array<Record<string, string>> = useMemo(
    () => (queueAgents && users ? prepareTableData(queueAgents, users) : []),
    [queueAgents, users],
  );

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        setSelectedRows([]);
        setIsDialogOpen(false);
        break;
      case 'saveClicked':
        onAssignAgents(selectedRows);
        setSelectedRows([]);
        setIsDialogOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <Dialog
      hasTitle
      open={isDialogOpen}
      renderActions={
        <DialogActions
          cancelLabel={t('common:cancel')}
          saveButtonProps={{
            color: 'success',
            label: t(
              'call_center:containers.manage_queues.components.agents_list.dialog.assign.save.label',
            ),
            variant: 'contained',
            disabled: !selectedRows.length,
          }}
          onAction={handleDialogAction}
        />
      }
      title={t('call_center:containers.manage_queues.components.agents_list.dialog.assign.title')}
      type={DialogType.Large}
      onClose={() => setIsDialogOpen(false)}
    >
      <p>{t('call_center:containers.manage_queues.components.agents_list.dialog.assign.prompt')}</p>
      <Table
        hasActionRowMargin
        hasBorderRadius
        hasOuterBorder
        hasSearch
        hasActionRowBorder={false}
        hasActionRowPadding={{
          side: false,
        }}
        hasEditIcon={false}
        hasPaginationPadding={{
          side: false,
        }}
        columns={columns()}
        data={tableData}
        selection={{
          hasBorder: true,
          position: SelectionPosition.Right,
          type: SelectionType.Multiple,
          setRows: setSelectedRows,
        }}
      />
    </Dialog>
  );
};

export default AssignAgentsDialog;
