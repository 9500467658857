import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledSummaryNumbersProps as Props } from './definition';

const StyledDialogContent = styled.div<Props>`
  ${(props) =>
    css`
      p {
        ${mixin.font({
          size: 13,
          style: 'italic',
          weight: 400,
          letterSpacing: '0.3px',
          lineHeight: '22px',
        })};
        margin: 0 0 ${props.theme.spacing(3)};
      }
      > div {
        ${mixin.flex({ alignItems: 'flex-start', justifyContent: 'space-between' })};

        > div {
          width: calc(100% / 2 - (18px / 2));
        }
      }
    `}
`;

export default StyledDialogContent;
