import { Box } from '@mui/material';
import VersionTag from 'apps/shared/components/VersionTag';
import { AppMeta } from 'apps/shared/definition';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SearchInput from 'shared/components/SearchInput';
import AppLink from './components/AppLink';
import NoItemsFound from './components/NoItemsFound';
import { AppPickerBodyProps as Props } from './definition';
import useAppListing from './hooks/useAppListing';
import StyledAppPickerBody from './style';

const AppPickerBody = ({ isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { appCount, searchApps } = useAppListing();
  const [apps, setApps] = useState<Array<AppMeta>>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    setApps(searchApps(searchValue));
  }, [appCount, searchValue]); // non-zero appCount triggers initial load

  return (
    <StyledAppPickerBody $isOpen={isOpen} $appCount={appCount}>
      <SearchInput
        hasAutoFocus={isOpen}
        inputProps={{
          placeholder: t('common:search'),
          value: searchValue,
          onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            setSearchValue(value.length > 0 ? value : ''),
        }}
        onReset={() => setSearchValue('')}
      />
      <Box>
        {apps.length ? (
          apps.map((meta: any) => (
            <AppLink
              isCurrent={meta.slug ? pathname.includes(meta.slug) : false}
              key={meta.slug}
              meta={meta}
              setIsOpen={setIsOpen}
            />
          ))
        ) : (
          <NoItemsFound />
        )}
      </Box>
      <VersionTag isSmall />
    </StyledAppPickerBody>
  );
};

export default AppPickerBody;
