import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/Numbers/components/EditForm';
import { EditFormProps as Props } from 'apps/PhoneSystem/definition';
import { useExitConfirmationDialog } from 'apps/shared/hooks/useExitConfirmationDialog';
import merge from 'lodash/merge';
import {
  schema,
  useFetchPhoneNumberByIdQuery,
  useUpdatePhoneNumberMutation,
} from 'models/PhoneNumber';
import { useCreateSMSMutation, useFetchSMSQuery } from 'models/SMS';
import { useFetchUsersQuery } from 'models/User';
import { FunctionComponent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import { defaultValues } from './default';
import { Failover, FailoverMethod, FormInput } from './definition';
import Form from './Form';
import { checkForSmsBoxOwner, updateEntities } from './utility';

const Edit: FunctionComponent<Props> = ({ id }: Props): JSX.Element => {
  const { data, isLoading } = useFetchPhoneNumberByIdQuery({ id });
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { sip } = useParams();
  const [hasError, setHasError] = useState<boolean>(false);
  const [updatePhoneNumber] = useUpdatePhoneNumberMutation();
  const [createSMS] = useCreateSMSMutation();

  // TODO: Optimize SMS fetch adding filter
  const { data: smsData } = useFetchSMSQuery();
  const { data: usersData } = useFetchUsersQuery();

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const {
    formState: { dirtyFields },
    getValues,
    handleSubmit,
    reset,
    setError,
    trigger,
  } = formMethods;
  const isPageDirty = checkFormEntities(dirtyFields);

  useEffect(() => {
    const failover: Failover = { method: FailoverMethod.inactive, value: '' };
    let smsOwner = '';

    if (data?.failover?.e164) {
      failover.method = FailoverMethod.e164;
      failover.value = data.failover.e164;
    } else if (data?.failover?.sip) {
      failover.method = FailoverMethod.sip;
      failover.value = data.failover.sip;
    }

    // convert e911 contact emails from array to string
    const e911 = Array.isArray(data?.e911?.notification_contact_emails)
      ? {
          notification_contact_emails: data?.e911?.notification_contact_emails?.join(','),
        }
      : {};

    if (smsData && usersData) {
      smsOwner = checkForSmsBoxOwner(smsData, usersData, id);
    }

    // populate form components once data is loaded
    reset(merge({}, defaultValues, data, { e911 }, { failover }, { sms_owner: smsOwner }));
  }, [data, getValues, id, reset, smsData, usersData]);

  const showSuccessfulMessage = (showSmsMessage: boolean, showPhoneNumberMessage: boolean) => {
    const smsMessage = t('numbers:containers.numbers_in_use.section.sms_enablement.save.success', {
      phone_number: id,
    });

    if (showSmsMessage && showPhoneNumberMessage) {
      showToast.success([
        t('numbers:containers.numbers_in_use.sms_trunk.save.success'),
        smsMessage,
      ]);
      return;
    }

    if (showSmsMessage) {
      showToast.success(smsMessage);
      return;
    }

    showToast.success(t('numbers:containers.numbers_in_use.save.success'));
  };

  const onSubmit: SubmitHandler<FormInput> = async (formData: any) => {
    const { failover, sms_owner, sms_owner_id, ...restData } = formData;
    const { method, e164, sip } = failover;
    const shouldCreateSms = dirtyFields.sms_owner && sms_owner_id;
    const shouldUpdatePhoneNumber = Boolean(
      Object.keys(dirtyFields).filter((item) => item !== 'sms_owner').length,
    );

    let saveData = restData;
    const smsSaveData = shouldCreateSms
      ? { owner: sms_owner_id, shared_box: false, numbers: [id] }
      : {};

    if (method === FailoverMethod.e164) {
      saveData = { ...restData, failover: { e164 } };
    } else if (method === FailoverMethod.sip) {
      saveData = { ...restData, failover: { sip } };
    }

    // convert e911 contact emails from string to array
    saveData.e911.notification_contact_emails =
      saveData.e911.notification_contact_emails !== ''
        ? saveData.e911.notification_contact_emails.split(',').map((email: string) => email.trim())
        : [];

    const result = await updateEntities(
      shouldCreateSms,
      shouldUpdatePhoneNumber,
      id,
      smsSaveData,
      saveData,
      createSMS,
      updatePhoneNumber,
    );

    if (result?.isSuccessfullySubmitted) {
      setHasError(false);
      showSuccessfulMessage(shouldCreateSms, shouldUpdatePhoneNumber);
    } else {
      setHasError(true);
      showErrorMessage({
        isFromException: true,
        errors: result.error,
        message: t('numbers:containers.numbers_in_use.save.error'),
        setError,
      });
    }
  };

  useExitConfirmationDialog({
    isDirty: isPageDirty,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditForm
      onSave={handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }))}
      isPageDirty={isPageDirty}
      entityLabel={sip || t('numbers:containers.numbers_in_use.label')}
      entityName={data?.id as string}
      formMethods={formMethods}
    >
      <Form id={id} hasError={hasError} />
    </EditForm>
  );
};

export default Edit;
