import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import kebabCase from 'lodash/kebabCase';
import { useFetchCallflowsQuery } from 'models/Callflow';
import { useFetchDevicesQuery } from 'models/Device';
import { useFetchMediasQuery } from 'models/Media';
import { useFetchMenusQuery } from 'models/Menu';
import { useFetchQueueQuery } from 'models/Queues';
import { useFetchUsersQuery } from 'models/User';
import { useFetchVoicemailsQuery } from 'models/Voicemail';
import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import { LabeledSelect } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import Loading from 'shared/components/Loading';
import { selectOptionMapper } from '../../../utility';
import { Option } from '../definition';
import { extractKeys } from '../utility';
import defaultProps from './default';
import { ApplicationVariableFormProps as Props } from './definition';
import { StyledApplicationVariableForm } from './style';

const ApplicationVariableForm: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { control, values, onRemoveHandler }: Props = { ...defaultProps, ...props };
  const { data: usersData, isLoading: usersLoading } = useFetchUsersQuery();
  const { data: voicemailData, isLoading: voicemailLoading } = useFetchVoicemailsQuery();
  const { data: devicesData, isLoading: devicesLoading } = useFetchDevicesQuery();
  const { data: mediaData, isLoading: mediaLoading } = useFetchMediasQuery();
  const { data: menuData, isLoading: menuLoading } = useFetchMenusQuery();
  const { data: queueData, isLoading: queueLoading } = useFetchQueueQuery();
  const { data: callFlowData, isLoading: callFlowLoading } = useFetchCallflowsQuery();
  const options: Option = {
    user: usersData
      ? usersData.map((data: any) => ({
          value: data.id,
          label: `${data.first_name} ${data.last_name}`,
        }))
      : [],
    vmbox: voicemailData ? voicemailData.map(selectOptionMapper) : [],
    device: devicesData ? devicesData.map(selectOptionMapper) : [],
    media: mediaData ? mediaData.map(selectOptionMapper) : [],
    menu: menuData ? menuData.map(selectOptionMapper) : [],
    queue: queueData ? queueData.map(selectOptionMapper) : [],
    callflow: callFlowData ? callFlowData.map(selectOptionMapper) : [],
  };

  if (
    usersLoading ||
    voicemailLoading ||
    devicesLoading ||
    mediaLoading ||
    menuLoading ||
    queueLoading ||
    callFlowLoading
  ) {
    return <Loading />;
  }

  const keys = extractKeys(values);

  return (
    <StyledApplicationVariableForm>
      {keys.map((key: number) => {
        const type = values[`type-${key}`];

        return (
          <div key={key}>
            <HookFormInputWrapper name={`type-${key}`} control={control}>
              {({ ref, isDirty, ...formProps }) => (
                <LabeledInput
                  isLabelAbove
                  label=""
                  inputWidth="small"
                  inputProps={{
                    ...formProps,
                    disabled: true,
                    id: `input-app-variable-type-${kebabCase(key.toString())}`,
                  }}
                />
              )}
            </HookFormInputWrapper>
            <HookFormInputWrapper name={`key-${key}`} control={control}>
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  isLabelAbove
                  label=""
                  inputWidth="small"
                  feedback={feedback}
                  inputProps={{
                    ...formProps,
                    id: `input-app-variable-key-${kebabCase(key.toString())}`,
                  }}
                />
              )}
            </HookFormInputWrapper>
            {!(type in options) ? (
              <HookFormInputWrapper name={`value-${key}`} control={control}>
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledInput
                    isLabelAbove
                    label=""
                    labelWidth="none"
                    inputWidth="medium"
                    feedback={feedback}
                    inputProps={{
                      ...formProps,
                      id: `input-app-variable-value-${kebabCase(key.toString())}`,
                    }}
                  />
                )}
              </HookFormInputWrapper>
            ) : (
              <HookFormSelectWrapper
                control={control}
                name={`value-${key}`}
                options={options[type] ?? []}
              >
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledSelect
                    isLabelAbove
                    label=""
                    feedback={feedback}
                    selectWidth="medium"
                    selectProps={{
                      ...formProps,
                      id: `select-app-variable-value-${kebabCase(key.toString())}`,
                    }}
                  />
                )}
              </HookFormSelectWrapper>
            )}
            <Icon
              hasHover
              name="minus-circle-outlined"
              size={22}
              themeColor="core.states.error.main"
              id="icon-minus-circle-outlined"
              onClick={onRemoveHandler.bind(null, key)}
            />
          </div>
        );
      })}
    </StyledApplicationVariableForm>
  );
};

export default ApplicationVariableForm;
