import { ADD_KEY, LOCAL_STORAGE, REGEX, URL_PARAM_MASQUERADING_ACCOUNT_KEY } from 'constant';

/**
 * @name getCurrentListingUrl
 * @description If a new account is being set and the URL has one or more 128 bit entity id, the new
 *              account ID URL should not contain the entityId. This is to prevent the masquerade
 *              from pointing to an entity the new account id has no access to. This also assumes
 *              a URL ending with an id. It also assumes a 128 bit entityId or a phone number.
 *
 * @example /apps/<appName>/<containerName>/entityId/<subContainer>/<128bitId>
 *
 * @returns URL.
 */
export const getCurrentListingUrl = (): string => {
  const getSlugs = () =>
    global.location.pathname.split('/').filter((slug: string) => slug !== '') ?? [];

  const slugs: Record<string, Array<string>> = {
    original: getSlugs(),
    temp: getSlugs(),
    redirect: [],
  };
  const lastSlug = slugs.original[slugs.original.length - 1] ?? '';

  let url = '';

  while (slugs.temp.length > 0) {
    const slug = slugs.temp.shift() as string;
    if (
      !slug?.match(REGEX.ID_128_BIT) &&
      !slug?.match(REGEX.PHONE_NUMBER) &&
      !slug?.startsWith(ADD_KEY)
    ) {
      slugs.redirect.push(slug);
    } else {
      break;
    }
  }

  const REDIRECT_TO_ROOT = ['agents', 'manage-queues'];

  if (REDIRECT_TO_ROOT.filter((slug: string) => slugs.redirect.includes(slug)).length > 0) {
    return url;
  }

  if (
    lastSlug.match(REGEX.ID_128_BIT) ||
    lastSlug.match(REGEX.PHONE_NUMBER) ||
    lastSlug.startsWith(ADD_KEY)
  ) {
    const accountId = localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID);
    url = `/${slugs.redirect.join('/')}?${URL_PARAM_MASQUERADING_ACCOUNT_KEY}=${accountId}`;
  }

  return url;
};
