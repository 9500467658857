import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledListItemLowerProps as Props } from './definition';

export const CONSTANTS = {
  HEIGHT: '40px',
  MARGIN: '8px',
};

const StyledListItemLower = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex({ justifyContent: 'space-evenly' })};
    ${mixin.transition({ property: 'margin', duration: 'fastest' })};
    height: 0;
    padding: 0 ${props.theme.spacing(3)};
    overflow: hidden;

    ${props.isOpen &&
    css`
      height: ${CONSTANTS.HEIGHT};
      margin: ${CONSTANTS.MARGIN} 0;

      > button:not(:first-of-type) {
        margin: 0 0 0 8px;
      }
    `}
  `};
`;

export default StyledListItemLower;
