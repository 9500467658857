import muiCardAgent from './muiCard-agent';
import muiCardDashboardPanel from './muiCard-dashboard-panel';
import muiCardImage from './muiCard-image';
import muiCardQueue from './muiCard-queue';

export { CONSTANTS as MUICARD_AGENT_CONSTANTS } from './muiCard-agent';
export { CONSTANTS as MUICARD_QUEUE_CONSTANTS } from './muiCard-queue';

export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      boxShadow: 'none',
      '&.MuiCard-agent': muiCardAgent,
      '&.MuiCard-dashboard-panel': muiCardDashboardPanel,
      '&.MuiCard-image': muiCardImage,
      '&.MuiCard-queue': muiCardQueue,
    },
  },
};
