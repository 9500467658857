const Props = {
  checked: false,
  id: '__SWITCH_ID__',
};

const defaultProps = {
  ...Props,
};

export const testProps = {
  ...Props,
  // onChange: (checked: boolean) => console.log(checked),
};

export default defaultProps;
