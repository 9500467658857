import { useDeleteMemberMutation } from 'models/Queues';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import { useToast } from 'shared/hooks/useToast';
import { ForfeitCallDialogProps as Props } from './definition';

const ForfeitCallDialog: FunctionComponent<Props> = ({
  isDialogOpen,
  onDialogClose,
  callId,
  queueId,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [deleteMember] = useDeleteMemberMutation();

  const onDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onDialogClose();
        break;
      case 'saveClicked':
        onForfeitCall();
        break;
    }
  };

  const onForfeitCall = async () => {
    try {
      await deleteMember({ id: queueId, callId });
      showToast.success(
        t('call_center:containers.dashboard.section.incoming_calls.toast_message.forfeit.success'),
      );
    } catch (exception) {
      showToast.error(
        t('call_center:containers.dashboard.section.incoming_calls.toast_message.forfeit.error'),
      );
    } finally {
      onDialogClose();
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      title={t('call_center:containers.dashboard.section.incoming_calls.dialog.title')}
      onClose={onDialogClose}
      renderActions={
        <DialogActions
          saveButtonProps={{
            color: 'error',
            label: t('call_center:containers.dashboard.section.incoming_calls.dialog.action.label'),
            variant: 'contained',
          }}
          onAction={onDialogAction}
        />
      }
    >
      <Box className="standard">
        {t('call_center:containers.dashboard.section.incoming_calls.dialog.content')}
      </Box>
    </Dialog>
  );
};

export default ForfeitCallDialog;
