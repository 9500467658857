import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { FLOW_ROOT_CONSTANTS } from '../FlowRoot';
import { StyledFlowArrowProps as Props } from './definition';

const StyledFlowArrow = styled.div.attrs({ className: 'flow-arrow' })<Props>`
  ${(props) => css`
    > div {
      ${mixin.borderRadius()};
      ${mixin.transition({ property: 'border-color', duration: 'medium' })};
      position: relative;
      display: grid;
      --size: 72px;
      --grid-size: ${`calc(var(--size) - ${FLOW_ROOT_CONSTANTS.DASHED_BORDER.SIZE} * 2)`};
      grid-template: var(--grid-size) / var(--grid-size);
      width: var(--size);
      height: var(--size);
      margin: 8px auto;
      border: ${FLOW_ROOT_CONSTANTS.DASHED_BORDER.SIZE} dashed transparent;

      > * {
        grid-area: 1 / 1 / 2 / 2;
      }

      > span {
        &:first-of-type {
          ${mixin.flex()};

          > svg {
            ${mixin.transition({ property: 'fill', duration: 'medium' })};
            fill: ${props.theme.core.divider.default};
          }
        }

        &:last-of-type {
          ${mixin.transition({ property: 'transform', duration: 'medium' })};
        }
      }

      ${props.isActivated &&
      css`
        border-color: ${props.theme.core.color.blue.draggable};

        ${props.isOver &&
        css`
          border-color: transparent;

          .callflow-plus {
            opacity: 0.7;
            transform: scale(2.35);
          }
        `};
      `};
    }
  `};
`;

export default StyledFlowArrow;
