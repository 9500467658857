import { themeColor } from 'theme/local';
import theme from 'theme/mui/core';

export const CONSTANTS = {
  WIDTH: 380,
  HEIGHT: 220,
};

export default {
  minWidth: CONSTANTS.WIDTH,
  width: CONSTANTS.WIDTH,
  height: CONSTANTS.HEIGHT,
  backgroundColor: theme.palette.common.white,
  border: `2px solid ${themeColor.kazoo.agent.state.logged_out}`,
  borderRadius: 8,
  '&.MuiCard-agent-connected': {
    borderColor: themeColor.kazoo.agent.state.connected,
  },
  '&.MuiCard-agent-connecting': {
    borderColor: themeColor.kazoo.agent.state.connecting,
  },
  '&.MuiCard-agent-logged_in': {
    borderColor: themeColor.kazoo.agent.state.logged_in,
  },
  '&.MuiCard-agent-outbound': {
    borderColor: themeColor.kazoo.agent.state.outbound,
  },
  '&.MuiCard-agent-paused': {
    borderColor: themeColor.kazoo.agent.state.paused,
  },
  '&.MuiCard-agent-ready': {
    borderColor: themeColor.kazoo.agent.state.ready,
  },
  '&.MuiCard-agent-wrapup': {
    borderColor: themeColor.kazoo.agent.state.wrapup,
  },
};
