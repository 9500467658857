import { PartialRecursive } from 'definition';
import produce from 'immer';
import merge from 'lodash/merge';
import { Settings, TemplateCluster } from 'models/AdvancedProvisioner/types';
import { Template } from '../shared/components/DynamicForm/definition';

/**
 * @description Extract the accounts settings object to template format
 * @param settings the settings data from the account settings
 * @example
 * convertAccountSettingsToTemplate({ lines: [{ basic: { ... } }] });
 * // { lines: { data: { basic: { data: { ... } } } } }
 */
export const convertAccountSettingsToTemplate = (settings: Settings): PartialRecursive<Template> =>
  produce(settings, (draft) => {
    Object.entries(draft).forEach(([tabKey, tabValue]) => {
      let originalTabValue = tabValue;

      // Account settings tab array could only contains one item. Extract the first item of the array if it exists.
      if (Array.isArray(tabValue)) {
        [originalTabValue] = tabValue;
      }

      draft[tabKey] = {
        data: Object.entries(originalTabValue).reduce((prevSection, [sectionKey, sectionValue]) => {
          prevSection[sectionKey] = {
            data: Object.entries(sectionValue).reduce((prevField, [fieldKey, fieldValue]) => {
              prevField[fieldKey] = {
                value: fieldValue,
                isInheritFromAccount: true,
              };
              return prevField;
            }, {} as Record<string, any>),
          };
          return prevSection;
        }, {} as Record<string, any>),
      };
    });
  }) as PartialRecursive<Template>;

/**
 * @description Convert the cluster data from the template to template format
 * @param templateCluster the cluster data from the template
 * @example
 * convertTemplateClusterToTemplate({ lines: { basic: { sip: { ... } }}});
 * // { lines: { data: { basic: { data: { ... } } } } }
 * @returns the cluster data in the template format
 */
export const convertTemplateClusterToTemplate = (templateCluster?: TemplateCluster) =>
  produce(templateCluster, (draft) => {
    if (!draft) {
      return {};
    }
    Object.entries(draft).forEach(([tabKey, tabValue]) => {
      draft[tabKey] = {
        data: Object.entries(tabValue).reduce((prevSection, [sectionKey, sectionValue]) => {
          prevSection[sectionKey] = {
            data: Object.entries(sectionValue).reduce((prevField, [fieldKey, fieldValue]) => {
              prevField[fieldKey] = {
                value: fieldValue,
              };
              return prevField;
            }, {} as Record<string, any>),
          };
          return prevSection;
        }, {} as Record<string, any>),
      };
    });
  }) as PartialRecursive<Template>;

/**
 * @description Get the template that consider inheritance from cluster settings. This is the final template that will be used to render the form.
 * @param template the template data
 * @param templateCluster the cluster data from the template
 * @returns the final template for render the form
 */
export const getInheritTemplate = (
  template?: Template,
  templateCluster?: TemplateCluster,
): Template | undefined => {
  if (!template) {
    return undefined;
  }
  const templateClusterTemplate = convertTemplateClusterToTemplate(templateCluster);

  return merge({}, template, templateClusterTemplate);
};

/**
 * @description Removes specified Tabs (keys) from the template object
 * @param template the template data
 * @returns new template data without the removed keys
 */
export const hideTabs = (template?: Template): Template | undefined => {
  const tabsToHide = ['settings.data.admin'];

  if (!template) {
    return undefined;
  }

  return produce(template, (draft: any) => {
    tabsToHide.forEach((tab) => {
      const keys = tab.split('.');
      let current = draft;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          delete current[key];
        } else {
          current = current[key];
        }
      });
    });
  });
};
