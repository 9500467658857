export const defaultCallflow = {
  flow: {
    data: {
      // "id": ""
    },
    module: 'user',
    children: {
      _: {
        data: {
          // "id": ""
        },
        module: 'voicemail',
        children: {},
      },
    } as any,
  },
  numbers: [] as string[],
};
