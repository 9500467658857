import { Box } from '@mui/material';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import { AppPickerHeadProps as Props } from './definition';
import StyledAppPickerHead from './style';

const AppPickerHead = ({ isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledAppPickerHead
      $isOpen={isOpen}
      onClick={() => setIsOpen((isOpen: SetStateAction<boolean>) => !isOpen)}
    >
      <Icon name="apps-filled" />
      <Box component="span">{t('common:component.header.app_picker.label')}</Box>
    </StyledAppPickerHead>
  );
};

export default AppPickerHead;
