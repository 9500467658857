import { FunctionComponent } from 'react';
import * as utility from 'shared/utility';
import defaultProps from './default';
import { CountListProps as Props } from './definition';
import StyledCountList from './style';

const CountList: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { hasButtonMargin, list } = { ...defaultProps, ...props };
  return (
    <StyledCountList hasButtonMargin={hasButtonMargin}>
      {Object.entries(list).map(([key, value]: (number | string)[], i: number) => (
        <li key={utility.generateKey(i, key as string)}>
          <span>{value}</span>
          <span>{key}</span>
        </li>
      ))}
    </StyledCountList>
  );
};

export default CountList;
