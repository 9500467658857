import { ACTION_PAUSE } from '../../constants';
import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  action: ACTION_PAUSE,
  timeout: 900,
  presence_id: '',
};
