export enum CallflowModule {
  CallForward = 'call_forward',
  DoNotDisturb = 'do_not_disturb',
  FollowMe = 'follow_me',
  GroupPickupFeature = 'group_pickup_feature',
  Hotdesk = 'hotdesk',
  Intercom = 'intercom',
  Move = 'move',
  Park = 'park',
  Privacy = 'privacy',
  Voicemail = 'voicemail',
}

/**
 * NOTE: Some of the `action` values below are double quoted
 * in order to remain compatible with Kazoo UI ¯\_(ツ)_/¯
 */
export enum FeatureCodeName {
  CallForwardActionActivate = 'call_forward[action=activate]',
  CallForwardActionDeactivate = 'call_forward[action=deactivate]',
  CallForwardActionToggle = 'call_forward[action=toggle]',
  CallForwardActionUpdate = 'call_forward[action=update]',
  DirectedExtPickup = 'directed_ext_pickup',
  DoNotDisturbActionActivate = 'donotdisturb[action="activate"]',
  DoNotDisturbActionDeactivate = 'donotdisturb[action="deactivate"]',
  DoNotDisturbActionToggle = 'donotdisturb[action="toggle"]',
  FollowMeActionActivate = 'followme[action="activate"]',
  FollowMeActionDeactivate = 'followme[action="deactivate"]',
  FollowMeActionToggle = 'followme[action="toggle"]',
  HotdeskActionLogin = 'hotdesk[action=login]',
  HotdeskActionLogout = 'hotdesk[action=logout]',
  HotdeskActionToggle = 'hotdesk[action=toggle]',
  Intercom = 'intercom',
  Move = 'move',
  ParkAndRetrieve = 'park_and_retrieve',
  PrivacyModeFull = 'privacy[mode=full]',
  Retrieve = 'retrieve',
  Valet = 'valet',
  VoicemailActionCheck = 'voicemail[action=check]',
  VoicemailActionDirect = 'voicemail[action="direct"]',
  VoicemailSingleMailboxLogin = 'voicemail[single_mailbox_login]',
}

export enum FieldValue {
  Id = 'id',
  IsEnabled = 'is_enabled',
  Key = 'key',
  Name = 'name',
  Number = 'number',
}

export interface FeatureCodeMeta {
  defaultValue: number | string;
  dialog?: {
    title: string;
  };
  label: string;
  startSymbol?: string;
}

export interface FeatureCodeCallflow {
  flow: {
    data: {
      action?: string;
      mode?: string;
      type?: string;
      single_mailbox_login?: boolean;
    };
    module: CallflowModule;
  };
  featurecode: {
    name: string;
    number?: number | string;
  };
  numbers?: Array<number | string | undefined>;
  patterns?: Array<string>;
}

export interface FormInput {
  [key: string]: {
    id?: string;
    is_enabled: boolean;
    name: string;
    number: string;
  };
}
