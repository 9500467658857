import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { WINDOW_UNITS } from '../../constants';

const schema = () =>
  Joi.object({
    id: Joi.string().required(),
    nodeId: Joi.string(),
    route_to_another_queue: Joi.boolean().optional(),
    var: Joi.when(Joi.ref('route_to_another_queue'), {
      is: Joi.boolean().valid(true),
      then: Joi.string().required(),
      otherwise: Joi.optional(),
    }),
    window: Joi.number().min(0).required(),
    window_unit: Joi.string().valid(...WINDOW_UNITS),
  }).messages(messages());

export default schema;
