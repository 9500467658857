import { RowArray } from 'apps/shared/definition';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import Table, { SelectionPosition, SelectionType } from 'shared/components/Table';
import { NumbersInUseDialogProps as Props } from './definition';

const NumbersInUseDialog = ({ isOpen, columns, data, onApply, onCancel, onClose }: Props) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<RowArray>([]);

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    if (closeResponse.reason === 'cancelClicked') {
      onCancel();
    } else if (closeResponse.reason === 'saveClicked') {
      onApply(selectedRows[0]);
    }
    onClose();
  };

  return (
    <Dialog
      hasTitle
      open={isOpen}
      renderActions={
        <DialogActions
          saveButtonProps={{
            color: 'secondary',
            label: t('common:continue'),
            role: 'button',
            variant: 'contained',
            disabled: !selectedRows.length,
          }}
          onAction={handleDialogAction}
        />
      }
      title={t('common:component.numbers_in_use_picker.components.numbers_in_use_dialog.title')}
      type={DialogType.XLarge}
      onClose={onClose}
    >
      <Table
        hasSearch
        hasBorderRadius
        hasActionRowBorder={false}
        hasEditIcon={false}
        hasPaginationPadding={{
          side: false,
        }}
        hasSideMargin={false}
        data={data}
        columns={columns}
        selection={{
          position: SelectionPosition.Right,
          type: SelectionType.Single,
          setRows: setSelectedRows,
        }}
      />
    </Dialog>
  );
};

export default NumbersInUseDialog;
