export interface ComponentProps {
  /** Element ID */
  id?: string;
  /** Element classname(s) */
  className?: string;
}

export enum KazooUserType {
  ADMIN = 'admin',
  USER = 'user',
}
