import { useEffect, useRef, useState } from 'react';
import Tooltip from 'shared/components/Tooltip';
import defaultProps from './default';
import { SkillsProps as Props } from './definition';
import StyledSkills from './style';

const Skills = (props: Props): JSX.Element => {
  const { skillsList, tooltipText, elementType }: Props = {
    ...defaultProps,
    ...props,
  };
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);

  const skills = skillsList?.join(', ');

  useEffect(() => {
    const { current } = textRef;

    if (current) {
      setIsTooltipVisible(current.scrollWidth > current.clientWidth);
    }
  }, [tooltipText]);

  return (
    <Tooltip title={isTooltipVisible ? skills : ''}>
      <StyledSkills component={elementType} ref={textRef}>
        {tooltipText}
      </StyledSkills>
    </Tooltip>
  );
};

export default Skills;
