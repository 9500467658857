import { useFetchAccountQuery } from 'models/Account';
import BridgeNumberSelect from './components/BridgeNumberSelect';
import { FormFields } from './definition';

const CellularEnablementSection = () => {
  const { data: accountData } = useFetchAccountQuery();

  const initialNumber = accountData?.[FormFields.BridgeNumber];

  return <BridgeNumberSelect initialNumber={initialNumber} />;
};

export default CellularEnablementSection;
