/**
 * @name createDesktopAvailableZones
 * @description Creates a list of available zones for the desktop, considering both `zoneOptions` and `currentSeatZone`.
 *
 * @param zoneOptions - An array of available zone options. It can be undefined if the endpoint fails
 * @param currentSeatZone - The currently selected seat zone
 *
 * @returns An array of zone options
 */
export const createDesktopAvailableZones = (
  zoneOptions: any,
  currentSeatZone: string | undefined,
) => {
  if (zoneOptions) {
    return zoneOptions;
  }
  if (currentSeatZone) {
    return [{ label: currentSeatZone, value: currentSeatZone }];
  }

  return [];
};
