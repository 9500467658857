import { useCallback } from 'react';
import { FieldComponent } from 'shared/components/AddressAutoComplete/definition';
import { generateFieldData } from 'shared/components/AddressAutoComplete/utility';

export const useAutofillFieldsFromAddressField = (
  setValue: any,
  fieldsComponentMapping: Array<FieldComponent>,
) =>
  useCallback(
    (placeResult) => {
      generateFieldData(placeResult, fieldsComponentMapping).forEach(({ fieldName, value }) => {
        setValue(fieldName, value, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      });
    },
    [fieldsComponentMapping, setValue],
  );
