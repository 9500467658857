import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import { CONSTANTS as HORIZONTAL_NAV_CONSTANTS } from 'apps/shared/components/HorizontalNav/components/NavItem/style';
import Box from 'shared/components/Box';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled, { css } from 'styled-components';
import { StyledCallCenterProps as Props } from './definition';

const StyledCallCenter = styled(Box)<Props>`
  ${({ $hasActionRow, $hasHeightStyling }) => css`
    display: grid;
    grid-template: ${HORIZONTAL_NAV_CONSTANTS.HEIGHT} 1fr / 1fr;
    height: 100%;
    overflow: clip;

    ${$hasHeightStyling &&
    css`
      > div:last-of-type {
        width: 100vw;
        height: calc(
          100vh -
            (
              ${HEADER_CONSTANTS.HEIGHT} + ${HORIZONTAL_NAV_CONSTANTS.HEIGHT} +
                ${$hasActionRow ? ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT : '0px'}
            )
        );
      }
    `}
  `};
`;

export default StyledCallCenter;
