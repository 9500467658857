import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';

const SmsOwnerUser: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div role="row">
      <div role="cell">
        {/* Phone number */}
        <HookFormInputWrapper name="id">
          {({ ref, isDirty, ...formProps }) => (
            <LabeledInput
              label={t(
                'numbers:containers.numbers_in_use.section.sms_enablement.phone_number.label',
              )}
              data-test-id="input-options-phone-number"
              id="input-options-phone-number"
              isDirty={isDirty}
              inputProps={{
                disabled: true,
                ...formProps,
              }}
            />
          )}
        </HookFormInputWrapper>
        {/* SMS Owner */}
        <HookFormInputWrapper name="sms_owner">
          {({ ref, isDirty, ...formProps }) => (
            <LabeledInput
              label={t('numbers:containers.numbers_in_use.section.sms_enablement.sms_owner.label')}
              data-test-id="input-options-phone-number"
              id="input-options-phone-number"
              isDirty={isDirty}
              inputProps={{
                disabled: true,
                ...formProps,
              }}
            />
          )}
        </HookFormInputWrapper>
      </div>
    </div>
  );
};

export default SmsOwnerUser;
