import { FunctionComponent } from 'react';
import SecondaryItems from './components/SecondaryItems';
import SideNavItem from './components/SideNavItem';
import { SideNavItemProps as ItemProps } from './components/SideNavItem/definition';
import { items } from './data';
import { SideNavProps as Props } from './definition';
import StyledSideNav from './style';

const SideNav: FunctionComponent<Props> = (): JSX.Element => (
  <StyledSideNav>
    {items
      .filter(({ isPrimary }: ItemProps): boolean => !!isPrimary)
      .map(
        ({ icon, label, slug }: ItemProps): JSX.Element => (
          <SideNavItem key={label} icon={icon} label={label} slug={`./${slug}`} />
        ),
      )}
    <SecondaryItems items={items} />
  </StyledSideNav>
);

export default SideNav;
