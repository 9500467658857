import { DialogType } from './definition';

const defaultProps = {
  open: false,
  hasClose: true,
  hasTitle: true,
  isContentSelectable: true,
  type: DialogType.Default,
};

export default defaultProps;
