import ListItemLower from './components/ListItemLower';
import ListItemUpper from './components/ListItemUpper';
import { ExpandedRow, ListItemProps as Props } from './definition';
import StyledListItem from './style';

export type { ExpandedRow } from './definition';

const ListItem = ({
  expandedRow: row,
  item: { id, index, name },
  quickViewCallflowIds,
  style,
  onEditClicked,
  onQuickViewClicked,
  setExpandedRow,
}: Props) => {
  const isOpen = row?.id === id;

  const onClick = () =>
    setExpandedRow((row: ExpandedRow) => (id === row?.id ? undefined : { id, index }));

  return (
    <StyledListItem isOpen={isOpen} style={style} onClick={onClick}>
      <ListItemUpper isOpen={isOpen} name={name} />
      <ListItemLower
        isOpen={isOpen}
        isQuickViewDisabled={quickViewCallflowIds.includes(id)}
        callflowId={id}
        onEditClicked={onEditClicked}
        onQuickViewClick={onQuickViewClicked}
      />
    </StyledListItem>
  );
};

export default ListItem;
