/**
 * The function calculate the columns width regardless of the content size
 * and returns the css `calc()` formula to be used for the grid column width
 *
 * @param {number} numOfColumns - The number of columns of the grid
 * @param {string} gridGap - The grid gap with unit of the grid (e.g. '2px')
 *
 * @returns {string} The css `calc()` formula to be used for the grid column width
 */
export const getGridColumnWidth = (numOfColumns: number, gridGap: string): string => {
  return `calc(100% / ${numOfColumns} - (${gridGap} * ${numOfColumns - 1} / ${numOfColumns}))`;
};
