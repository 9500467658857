import {
  DialogData,
  KeyDialogData,
} from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowActionsDialog/definition';
import { CallFlowAction, CallFlowKey } from '../definition';

export type NodeData = {
  id?: string;
  nodeId: string;
  use_absolute_mode?: boolean;
};

export interface CallFlowNode {
  children: Record<string, CallFlowNode | string>;
  data: NodeData;
  module: string;
}

interface ContactList {
  exclude: boolean;
}

interface UiMetadata {
  version: string;
  ui: string;
  origin: string;
}

export interface Metadata {
  [key: string]: {
    name: string;
    pvt_type: string;
  };
}

export interface CallFlowTree {
  ui_is_main_number_cf: boolean;
  flow: CallFlowNode;
  numbers: string[];
  name: string;
  /** The name field is an aggregated value that doesn't reflect network response. This field is to reveal if there is a name field from network response. Should be deleted on submit to server. */
  hasName?: boolean;
  contact_list: ContactList;
  ui_metadata?: UiMetadata;
  patterns: string[];
  id?: string;
  metadata: Metadata;
}

export interface TreeNodeInterface extends CallFlowNode {
  isActivated: boolean; // Appears as disabled
  isInsertable: boolean; // Can be inserted between two nodes
  isDroppable: boolean; // Can be dropped on node
  isMultinary: boolean; // Can have multiple children
  isTerminal: boolean; // Can't have children
  isNew: boolean; // Is the node new
  isDirty: boolean; // Is the node dirty
  actionName: CallFlowKey;
  callflow: CallFlowAction;
  children: Record<string, string>;
  data: any;
  key: string; // Parent key, set to DEFAULT_KEY by default
  parentId: string;
}

export type CallFlowTreeNodes = {
  [key: string]: TreeNodeInterface;
};

export interface DialogType {
  type: string;
  data?: DialogData;
  nodeId?: string;
}

export interface KeyDialogType {
  type: string;
  data?: KeyDialogData;
  parentNode?: TreeNodeInterface;
  nodeId?: string;
}

export interface Callflow extends CallFlowTree {
  nodes: CallFlowTreeNodes;
  root: string;
  isReadOnly: boolean;
  isActivated: boolean;
  isDroppable: boolean;
  isNew: boolean;
  isDirty: boolean;
  // data that shows up in the popup
  dialog?: DialogType;
  keyDialog?: KeyDialogType;
}

export enum DropType {
  ROOT = 'ROOT_DROP',
  ARROW = 'ARROW_DROP',
  NODE = 'NODE_DROP',
}

export enum DragSourceType {
  ACTION = 'action',
  TREE = 'tree',
}
