import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

const StyledCurrentUser = styled.div`
  ${(props) => css`
    span {
      ${mixin.font({ size: 16 })};
      color: ${props.theme.core.color.black};
      user-select: none;
      white-space: nowrap;
    }
  `}
`;

export default StyledCurrentUser;
