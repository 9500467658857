import MuiAutocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import produce from 'immer';
import merge from 'lodash/merge';
import { SyntheticEvent, useState } from 'react';
import Icon from 'shared/components/Icon';
import { SelectOption } from 'shared/components/Select';
import { FilterOption, useFilterOptions } from '../../hooks/useFilterOptions';
import { FilterValues } from '../definition';
import { AutocompleteProps as Props } from './definition';
import { getOptionByValue } from './utility';

const Autocomplete = ({
  hasAllOption,
  filter: { defaults, type, values },
  options,
  setFilterValues,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>(FilterOption.All);
  const { filterOptions } = useFilterOptions({ hasAllOption, options });

  const onChange = (option: SelectOption | null) => {
    if (option) {
      setFilterValues((filterValues: FilterValues) =>
        produce(filterValues, (draft: any) => {
          draft.selected[type] =
            option.value === FilterOption.All ? defaults[type] : [option.value];
        }),
      );
    }
  };

  return filterOptions.length > 0 ? (
    <MuiAutocomplete
      classes={{
        root: 'MuiAutocomplete-root-filter',
      }}
      clearIcon={<Icon name="cancel" subset={16} />}
      clearText=""
      closeText=""
      componentsProps={{
        clearIndicator: {
          onClick: () => setInputValue(''),
        },
        paper: {
          className: 'MuiPaper-autocomplete-filter',
        },
      }}
      data-test-id="filter-select-autocomplete"
      disableClearable={inputValue === ''}
      inputValue={inputValue}
      openText=""
      options={filterOptions}
      popupIcon={<Icon name="dropdown-down" size={21} />}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MuiTextField
          {...merge(params, {
            inputProps: {
              'data-test-id': 'filter-select-autocomplete-input',
              value: getOptionByValue(filterOptions, inputValue)
                ? getOptionByValue(filterOptions, inputValue)?.label
                : inputValue,
            },
          })}
        />
      )}
      value={getOptionByValue(filterOptions, values.selected[type][0])}
      onChange={(e: SyntheticEvent<Element, Event>, option) => onChange(option)}
      onInputChange={(e: SyntheticEvent<Element, Event>, value) => setInputValue(value)}
    />
  ) : (
    <></>
  );
};

export default Autocomplete;
