import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';
import { ByIdWithAccountIdParam } from 'models/definition';

export const Group = {
  // GET
  Fetch: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/groups?paginate=${isPaginated}`,
  FetchById: ({ accountId = ACCOUNT_ID_URL_PLACEHOLDER, id }: ByIdWithAccountIdParam) =>
    `accounts/${accountId}/groups/${id}`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/groups`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/groups/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/groups/${id}`,
};
