import { FormFields } from 'apps/PhoneSystem/containers/Account/Settings/components/CellularEnablementSection/definition';
import { useFetchAccountQuery } from 'models/Account';
import { useMemo } from 'react';

const useBridgeNumber = () => {
  const { data, isLoading } = useFetchAccountQuery();

  const bridgeNumber = useMemo(() => data?.[FormFields.BridgeNumber], [data]);

  return { bridgeNumber, isLoading };
};

export default useBridgeNumber;
