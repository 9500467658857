import {
  BuyNumberFlowComponent,
  BuyNumberFlowType,
} from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow/definition';
import { getCollectionList } from 'apps/shared/utility';
import { useActivateCollectionMutation } from 'models/PhoneNumber';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import defaultProps from './default';
import { WaitForServerProps as Props } from './definition';

const WaitForServer = (props: Props) => {
  const {
    hasNoRedirectOnComplete,
    component,
    flowOnCompleteRoute,
    message,
    selectedNumbers,
    navigateToNext,
    onFlowComplete,
    setAreaCode,
    setAvailableNumbers,
    setFlowType,
    setErrorCount,
    setPrefix,
    setSelectedNumbers,
  } = { ...defaultProps, ...props };
  const navigate = useNavigate();
  const [activateCollection] = useActivateCollectionMutation();

  const loadFromServer = useCallback(async () => {
    try {
      const numberCollection = getCollectionList({ data: selectedNumbers });
      if (component === BuyNumberFlowComponent.Activate) {
        const { data } = (await activateCollection(numberCollection)) as {
          data: any;
        };

        const successNumberArray = Object.keys(data?.success ?? {});
        const errorNumbersArray = Object.keys(data?.error ?? {});

        setErrorCount(selectedNumbers.length - successNumberArray.length);
        onFlowComplete({ success: successNumberArray, error: errorNumbersArray });

        if (successNumberArray.length > 0) {
          setAreaCode('');
          setPrefix('');
          setAvailableNumbers([]);
          setSelectedNumbers([]);

          if (hasNoRedirectOnComplete) {
            setFlowType(BuyNumberFlowType.Default);
          } else {
            navigateToNext();
          }
        } else if (hasNoRedirectOnComplete) {
          setFlowType(BuyNumberFlowType.Default);
        } else if (flowOnCompleteRoute) {
          navigate(flowOnCompleteRoute);
        }
      }
    } catch (exception) {
      console.error(exception);

      if (hasNoRedirectOnComplete) {
        setFlowType(BuyNumberFlowType.Default);
      } else if (flowOnCompleteRoute) {
        navigate(flowOnCompleteRoute);
      }
    }
  }, [
    hasNoRedirectOnComplete,
    flowOnCompleteRoute,
    component,
    selectedNumbers,
    activateCollection,
    navigate,
    navigateToNext,
    onFlowComplete,
    setAreaCode,
    setAvailableNumbers,
    setErrorCount,
    setFlowType,
    setPrefix,
    setSelectedNumbers,
  ]);

  useEffect(() => {
    loadFromServer();
  }, [loadFromServer]);

  return <Loading hasMargin hasLargeProgress label={message ?? ''} />;
};

export default WaitForServer;
