import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import defaultProps from './default';
import { EditCallflowMetaDialogProps as Props, Form } from './definition';

const EditCallflowMetaDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { onClose, isOpen, data }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { handleSubmit, reset, control } = useForm<Form>({
    defaultValues: {
      ...data,
    },
  });

  /** To sync with undo/redo history */
  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const handleDialogAction = ({ reason }: { reason: string }) => {
    if (reason === 'saveClicked') {
      handleSubmit(onSubmit)();
    } else {
      onClose(undefined);
    }
  };

  const onSubmit = (data: Form) => {
    onClose(data);
  };

  return (
    <Dialog
      title={t('phone_system:containers.callflows.callflow_edit_dialog.title')}
      open={isOpen}
      onClose={() => onClose()}
      renderActions={<DialogActions onAction={handleDialogAction} />}
    >
      <p>{t('phone_system:containers.callflows.callflow_edit_dialog.explain')}</p>

      {/** Callflow Name */}
      <HookFormInputWrapper name="name" control={control}>
        {({ ref, isDirty, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            id="input-callflow-edit-dialog-name"
            label={t('phone_system:containers.callflows.callflow_edit_dialog.name')}
            inputProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormInputWrapper>

      {/** Hide from Contact List */}
      <HookFormInputWrapper name="contact_list.exclude" control={control} isCheckbox>
        {({ ref, isDirty, ...formProps }) => (
          <LabeledCheckbox
            isBelow
            id="checkbox-callflow-edit-dialog-list"
            label={t('phone_system:containers.callflows.callflow_edit_dialog.list')}
            indentWidth="small"
            checkboxProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormInputWrapper>
    </Dialog>
  );
};

export default EditCallflowMetaDialog;
