import { useTranslation } from 'react-i18next';
import WarningIcon from './components/WarningIcon';
import { StyledWarningBar } from './style';

const WarningBar = () => {
  const { t } = useTranslation();
  return (
    <StyledWarningBar>
      <WarningIcon />
      {t(
        'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.delete_idp.confirm.warning',
      )}
    </StyledWarningBar>
  );
};

export default WarningBar;
