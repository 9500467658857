import BasicsSection, { fields as basicsFields } from './BasicsSection';
import CallerIdSection, { fields as callerIdFields } from './CallerIdSection';
import OptionsSection, { fields as optionsFields } from './OptionsSection';
import SMTPSection, { fields as smtpFields } from './SMTPSection';

const fields = {
  basics: basicsFields,
  callerId: callerIdFields,
  options: optionsFields,
  smtp: smtpFields,
};

const Section = {
  Basics: BasicsSection,
  CallerId: CallerIdSection,
  Options: OptionsSection,
  SMTP: SMTPSection,
};

export { fields, Section };
