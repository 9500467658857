import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { ADD_KEY } from 'constant';
import { BaseSyntheticEvent, FunctionComponent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from 'shared/components/Labeled';
import { FormInput } from '../../../../definition';
import { useHeroAppsList } from './hooks';
import { buildFieldName, buildPropName } from './utility';

export { useHeroAppsList } from './hooks';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export let fields: string[] = [];

const DesktopModulesSection: FunctionComponent<Props> = ({ id = ADD_KEY }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const heroAppsList = useHeroAppsList(id);

  useEffect(() => {
    // dynamically update dirty fields
    heroAppsList.forEach(({ id }: any): void => {
      fields.push(buildFieldName({ id }));
    });
  }, [heroAppsList]);

  const handleAppClick = (e: BaseSyntheticEvent, id: string): void => {
    /**
     * The hero apps data is stored in the user object as an array, `hero_apps`
     * (via the `accounts/{{account_id}}/users/{{user_id}}` endpoint)
     *
     * The fields being set below are:
     * • seat._temp_is_in_hero_apps[id] - temporary property which stores a
     *   boolean value, used as an adapter between RHF (which uses checkboxes)
     *   and the actual API object, which is array-based
     * • seat.hero_apps - which stores boolean values in an array (see above)
     */
    setValue(buildFieldName({ id, hasPrefix: true }), e.target.checked);
    setValue(
      'seat.hero_apps',
      Object.entries(getValues(buildPropName('IS_IN_HERO_APPS')))
        .map(([id, available]: any) => (available ? id : false))
        .filter((value: boolean | string) => value !== false),
    );
  };

  return (
    <>
      <h2>{t('phone_system:containers.seats.section.desktop_modules.heading.desktop_client')}</h2>
      <div role="row">
        <div role="cell">
          {/* Hero Apps */}
          {heroAppsList.map(
            ({ id, label }: Record<string, string>): JSX.Element => (
              <HookFormInputWrapper
                isCheckbox
                key={buildFieldName({ id, isKey: true })}
                name={buildFieldName({ id })}
              >
                {({ ref, isDirty, ...formProps }) => (
                  <LabeledCheckbox
                    isDirty={isDirty}
                    indentWidth="small"
                    label={label}
                    checkboxProps={{
                      ...formProps,
                      onClick: (e: BaseSyntheticEvent) => handleAppClick(e, id),
                    }}
                  />
                )}
              </HookFormInputWrapper>
            ),
          )}
        </div>
      </div>
    </>
  );
};

export default DesktopModulesSection;
