import { Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import { EditForm } from './components/EditForm';
import { IterateTable } from './components/IterateTable';
import { TabProps as Props } from './definition';

export const Tab = ({
  formProps,
  tabKey,
  template: { data: sectionTemplate, iterate = 0, text },
}: Props) => {
  const Components = {
    List: (
      <IterateTable
        sectionTemplate={sectionTemplate}
        iterate={iterate}
        tabKey={tabKey}
        tabName={text}
      />
    ),
    Edit: (
      <EditForm
        formProps={formProps}
        iterate={iterate}
        tabKey={tabKey}
        tabName={text}
        template={sectionTemplate}
      />
    ),
  };

  let routesList = [
    {
      element: Components.Edit,
      path: '/',
    },
  ];

  if (iterate === 1) {
    routesList = [
      { element: <Navigate to="./0" replace />, path: `/` },
      {
        element: Components.Edit,
        path: '/:iterate/',
      },
    ];
  }

  if (iterate > 1) {
    routesList = [
      { element: Components.List, path: '/' },
      {
        element: Components.Edit,
        path: '/:iterate/',
      },
    ];
  }

  const routes = useRoutes(routesList);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};
