import { getCallflowTypeLabel } from '.';
import { CallflowType } from './definition';

/**
 * @name filterOptions
 * @description Get the filter options list.
 *
 * @returns Filter options array.
 */
export const filterOptions = () =>
  Object.values(CallflowType).map((value: string) => ({
    label: getCallflowTypeLabel(value),
    value,
  }));
