import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { ACTION_LOGOUT } from '../../constants';

const schema = () =>
  Joi.object({
    action: Joi.string().valid(ACTION_LOGOUT),
    nodeId: Joi.string(),
    presence_id: Joi.string().required(),
  }).messages(messages());

export default schema;
