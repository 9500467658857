import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class MediaActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.MEDIA,
      module: CallFlowModuleKey.MEDIA,
      section: CallFlowSection.BASIC,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'music-note',
      },
      label: i18next.t('phone_system:containers.callflows.actions.media.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.media.tooltip'),
    });
  }
}

export default MediaActionConfig;
