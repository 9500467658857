import i18next from 'i18next';
import { SharedName } from './definition';

const translations = ({ sharedName }: { sharedName?: SharedName }) => {
  const translation = {
    exceeded_limit: {
      cancel: {
        label: i18next.t('common:close'),
      },
      content: {
        group: i18next.t(
          'phone_system:containers.voicemails.section.recipients.shared_vmbox.exceeded_limit.content.group',
        ),
        user: i18next.t(
          'phone_system:containers.voicemails.section.recipients.shared_vmbox.exceeded_limit.content.user',
        ),
      },
      title: i18next.t(
        'phone_system:containers.voicemails.section.recipients.shared_vmbox.exceeded_limit.title',
      ),
    },
    manage_seats: {
      label: i18next.t(
        'phone_system:containers.voicemails.section.recipients.shared_vmbox.manage_seats.label',
      ),
      table: {
        column: {
          // t('phone_system:containers.voicemails.section.recipients.shared_vmbox.manage_seats.table.column.selected')
          selected:
            'phone_system:containers.voicemails.section.recipients.shared_vmbox.manage_seats.table.column.selected',
        },
      },
      title: i18next.t(
        'phone_system:containers.voicemails.section.recipients.shared_vmbox.manage_seats.title',
      ),
    },
  };

  switch (sharedName) {
    case SharedName.Sms: {
      translation.manage_seats.label = i18next.t(
        'phone_system:containers.sms.section.recipients.shared_box.manage_seats.label',
      );
      break;
    }
    default: {
      break;
    }
  }

  return translation;
};

export default translations;
