import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledStatusProps as Props } from './definition';

const getColor = (props: any): string => {
  const COLOR: { [key: string]: string } = {
    none: props.theme.status.none,
    minor: props.theme.status.minor,
    major: props.theme.status.major,
    maintenance: props.theme.status.maintenance,
    critical: props.theme.status.critical,
  };
  return COLOR[props.indicator];
};

const StyledStatus = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex()};

    svg {
      width: 24px;

      circle {
        fill: ${getColor(props)};
      }
    }

    span {
      display: inline-block;
      margin: 0 0 0 12px;
      color: ${getColor(props)};
      flex-basis: calc(100% - 40px);
      font-weight: 600;
    }
  `}
`;

export default StyledStatus;
