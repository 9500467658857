export default {
  styleOverrides: {
    root: {
      '&.MuiAutocomplete-root-filter': {
        '.MuiAutocomplete-clearIndicator': {
          visibility: 'visible',
        },
      },
    },
  },
};
