import Box from 'shared/components/Box';
import styled from 'styled-components';

export const StyledIdpForm = styled(Box)`
  > div {
    justify-content: flex-start;

    // All input in the IDP form will have full width
    &[type='input'] {
      width: 100%;

      > div {
        flex: 1;

        > div:first-child,
        > div:first-child > div {
          width: 100%;
        }
      }
    }
  }
`;
