import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { schema } from 'normalizr';
import undoable from 'redux-undo';
import { CallFlowTree } from '../CallFlowTree';
import { initialState } from '../default';
import { Callflow, DragSourceType } from '../definition';
import { flowEntitySchema } from '../schema';
import { TreeNode } from '../TreeNode';
import { normalizeCallflow } from '../utility';

export const ORIGINAL_KEY = 'original';

const flowEntityChildrenSchema = new schema.Values(flowEntitySchema);

flowEntitySchema.define({ children: flowEntityChildrenSchema });

export const callflowAdapter = createEntityAdapter<Callflow>({});

export const callflowSlice = createSlice({
  name: 'callflows',
  initialState: callflowAdapter.getInitialState(initialState),
  reducers: {
    // Action Dialog
    maybeShowActionDialog(state, action) {
      const { id, data, type } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        tree.dialog = { data, type };
      }
    },
    dialogKeySet(state, action) {
      const { id, key } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        const callFlowTree = new CallFlowTree(tree);

        // If dialog exists, then we're adding new child
        if (tree.dialog) {
          tree.dialog.data = { ...tree.dialog.data, key };
          return;
        }

        const { keyDialog } = tree;
        const oldKey = keyDialog?.data?.key;

        // This situation we're moving existing node around the tree
        if (keyDialog?.nodeId && keyDialog?.parentNode?.data?.nodeId) {
          // we have to move the node from previous position to the key node
          const activeRootNode = tree.nodes[keyDialog.nodeId];
          const newParentNode = tree.nodes[keyDialog.parentNode.data.nodeId];

          callFlowTree.uniaryNodeHandler(newParentNode, activeRootNode, key);
        }

        // If the dialog does not exist, then we're updating existing node key
        if (oldKey) {
          const nodeId = keyDialog?.parentNode?.children[oldKey];
          // Need to update key in child node
          if (nodeId && tree.nodes?.[nodeId]?.key) {
            tree.nodes[nodeId].key = key;
          }
          // Need to update key in parent node
          const parentId = keyDialog?.parentNode?.data?.nodeId;

          if (parentId) {
            tree.nodes[parentId].children[key] = tree.nodes[parentId].children[oldKey];

            if (key !== oldKey && tree.nodes[parentId]) {
              delete tree.nodes[parentId].children[oldKey];
            }
          }
        }

        tree.isDirty = !callFlowTree.isEqual(state.entities[`${id}-${ORIGINAL_KEY}`]);
      }
    },
    dismissActionDialog(state, action) {
      const { id } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        tree.dialog = undefined;
      }
    },
    // Key Dialog
    maybeShowSelectKeyDialog(state, action) {
      const { id, data, parentNode, type } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        tree.keyDialog = { data, type, parentNode };
      }
    },
    dismissSelectKeyDialog(state, action) {
      const { id } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        tree.keyDialog = undefined;
      }
    },
    // Tree Methods
    addTreeNode(state, action) {
      const { id, data, metadata, node } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        const callFlowTree = new CallFlowTree(tree);
        const { targetNodeId, type, action } = node;
        const activeNode = { ...new TreeNode(action.module, data) };
        const targetNode = tree.nodes[targetNodeId];

        activeNode.isNew = true;
        activeNode.isDirty = true;

        tree.nodes[activeNode.data.nodeId] = activeNode;

        callFlowTree.insertTreeHandler(activeNode, targetNode, type);

        if (metadata && activeNode.data.id) {
          tree.metadata[activeNode.data.id] = metadata;
        }

        tree.isDirty = !callFlowTree.isEqual(state.entities[`${id}-${ORIGINAL_KEY}`]);
      }
    },
    removeTreeNode(state, action) {
      const { id, nodeId, includeDescendants } = action.payload;
      const tree = state.entities[id];
      const node = tree?.nodes[nodeId];

      if (tree && node) {
        const callFlowTree = new CallFlowTree(tree);

        if (includeDescendants) {
          callFlowTree.deleteBranch(node);
        } else {
          callFlowTree.deleteNode(node);
        }

        tree.isDirty = !callFlowTree.isEqual(state.entities[`${id}-${ORIGINAL_KEY}`]);
      }
    },
    updateTreeNode(state, action) {
      const { id, data, metadata, isDirty } = action.payload;
      const { nodeId } = data;
      const tree = state.entities[id];
      const node = tree?.nodes[nodeId];

      if (tree && node) {
        const callFlowTree = new CallFlowTree(tree);

        node.data = data;
        node.isDirty = isDirty;

        if (node.data?.id && metadata) {
          // Handle situation where we change object on existing node, the old metadata needs to be removed
          Object.keys(tree.metadata).forEach((metadataId) => {
            if (!Object.values(tree.nodes).filter((node) => node.data.id === metadataId).length) {
              callFlowTree.removeMetaDataById(metadataId);
            }
          });

          tree.metadata = { ...tree.metadata, [node.data.id]: metadata };
        }

        tree.isDirty = !callFlowTree.isEqual(state.entities[`${id}-${ORIGINAL_KEY}`]);
      }
    },
    updateTreeOrder(state, action) {
      const { id, activeNodeId, targetNodeId, type } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        const callFlowTree = new CallFlowTree(tree);
        const activeNode = tree.nodes[activeNodeId];
        const targetNode = tree.nodes[targetNodeId];

        callFlowTree.insertTreeHandler(activeNode, targetNode, type);

        tree.isDirty = !callFlowTree.isEqual(state.entities[`${id}-${ORIGINAL_KEY}`]);
      }
    },
    enableTreeDestinations(state, action) {
      const { id, activeNode } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        const callFlowTree = new CallFlowTree(tree);

        if (activeNode.type === DragSourceType.TREE) {
          callFlowTree.enableTreeMoveBranch(activeNode);
        }

        if (activeNode.type === DragSourceType.ACTION) {
          callFlowTree.enableTreeNewAction(activeNode);
        }
      }
    },
    disableTreeDestinations(state, action) {
      const { id } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        new CallFlowTree(tree).disableTree();
      }
    },
    // Call Flow Methods
    updateCallFlowName(state, action) {
      const { id, data } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        const { name, contact_list } = data;
        tree.name = name;
        tree.contact_list = { ...tree.contact_list, ...contact_list };
        tree.isDirty = !new CallFlowTree(tree).isEqual(state.entities[`${id}-${ORIGINAL_KEY}`]);
      }
    },
    addCallFlowNumber(state, action) {
      const { id, number } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        tree.numbers = [...new Set(tree.numbers).add(number)];
        tree.isDirty = !new CallFlowTree(tree).isEqual(state.entities[`${id}-${ORIGINAL_KEY}`]);
      }
    },
    removeCallFlowNumber(state, action) {
      const { id, number: numberToRemove } = action.payload;
      const tree = state.entities[id];

      if (tree) {
        tree.numbers = tree.numbers.filter((number) => number !== numberToRemove);
        tree.isDirty = !new CallFlowTree(tree).isEqual(state.entities[`${id}-${ORIGINAL_KEY}`]);
      }
    },
    createAddCallFlow(state, action) {
      const callFlow: Callflow = action.payload;

      callflowAdapter.upsertOne(state, callFlow);
      callflowAdapter.upsertOne(state, { ...callFlow, id: `${callFlow.id}-${ORIGINAL_KEY}` });
    },
    removeAddCallFlow(state, action) {
      callflowAdapter.removeOne(state, action.payload);
    },
    addCallFlow(state, action) {
      const { id, flow, ui_metadata, ...rest } = action.payload;

      if (flow) {
        const newFlow = cloneDeep(flow);
        // Adding original for diffing
        callflowAdapter.upsertOne(state, { id: `${id}-${ORIGINAL_KEY}`, ...flow, ...rest });

        const normalizedFlow = normalizeCallflow(newFlow);

        callflowAdapter.upsertOne(state, {
          id,
          isReadOnly: false,
          isActivated: false,
          isDirty: false,
          root: normalizedFlow.result,
          ...rest,
          ...normalizedFlow.entities,
        });
      }
    },
  },
  extraReducers: () => {},
});

export const undoableCallflowReducer = undoable(callflowSlice.reducer, {
  filter: (action, currentState, previousHistory) => {
    /**
     * Skip adding dismissActionDialog to the undo/redo stack if meets any of the conditions (OR) to avoid unwanted undo/redo step:
     * 1. Dialog is cancelled
     * 2. Is going to trigger the select key dialog as the step should be recorded in the dismissSelectKeyDialog
     */
    if (
      action.type === callflowSlice.actions.dismissActionDialog.type &&
      (action.payload.isCancel || currentState.entities[action.payload.id]?.keyDialog)
    ) {
      return false;
    }
    /**
     * Skip adding dismissSelectKeyDialog when dialog is cancelled
     */
    if (
      action.type === callflowSlice.actions.dismissSelectKeyDialog.type &&
      action.payload.isCancel
    ) {
      return false;
    }
    /** Only first addCallFlow dispatched on page load is added to the history. Other addCallFlow history will be handled by dismissActionDialog */
    if (
      action.type === callflowSlice.actions.addCallFlow.type &&
      Object.keys(previousHistory.past).length === 0 &&
      !previousHistory._latestUnfiltered
    ) {
      return true;
    }
    if (
      [
        callflowSlice.actions.createAddCallFlow.type,
        callflowSlice.actions.addCallFlowNumber.type,
        callflowSlice.actions.removeCallFlowNumber.type,
        callflowSlice.actions.updateCallFlowName.type,
        callflowSlice.actions.removeTreeNode.type,
        callflowSlice.actions.updateTreeOrder.type,
        /**
         * Using dismissActionDialog for the following actions since they will eventually dispatch dismissActionDialog and duplicate the stack:
         * 1. callflowSlice.actions.addTreeNode.type
         * 2. callflowSlice.actions.updateTreeNode.type
         * 3. callflowSlice.actions.addCallFlow.type (except the first addCallFlow on page load)
         */
        callflowSlice.actions.dismissActionDialog.type,
        /**
         * For node drop into Menu node and open the select key dialog
         */
        callflowSlice.actions.dismissSelectKeyDialog.type,
      ].includes(action.type)
    ) {
      return true;
    }
    return false;
  },
  ignoreInitialState: true,
});

export const {
  addTreeNode,
  dialogKeySet,
  disableTreeDestinations,
  dismissActionDialog,
  dismissSelectKeyDialog,
  enableTreeDestinations,
  maybeShowActionDialog,
  maybeShowSelectKeyDialog,
  removeTreeNode,
  updateTreeNode,
  updateTreeOrder,
  updateCallFlowName,
  addCallFlowNumber,
  removeCallFlowNumber,
  createAddCallFlow,
  removeAddCallFlow,
  addCallFlow,
} = callflowSlice.actions;
