import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import { AccountHeaderProps as Props } from './definition';
import StyledAccountHeader from './style';

const AccountHeader = ({ isLoading, isTreeVisible, name }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledAccountHeader $isLoading={isLoading} $isTreeVisible={isTreeVisible}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Icon name="graph-horizontal-outlined" />
          <Box>
            <Box component="span">
              {t('common:component.current_account.components.account_header.account')}
            </Box>
            <Box component="span">{name}</Box>
          </Box>
        </>
      )}
    </StyledAccountHeader>
  );
};

export default AccountHeader;
