import i18next from 'i18next';
import { buildFullNameFromUser } from 'models/User';

/**
 * @name getE911Address
 * @description Build the e911 address string for use in the CSV file.
 *
 * @param e911
 *
 * @returns e911 address string.
 */
export const getE911Address = (e911: Record<string, string>) => {
  const value = [];

  if (e911?.street_address) {
    if (e911?.extended_address) {
      value.push(e911.extended_address);
    }
    value.push(e911.street_address);
  }
  if (e911?.locality) {
    value.push(e911.locality);
  }
  if (e911?.region) {
    value.push(e911.region);
  }
  if (e911?.postal_code) {
    value.push(e911.postal_code);
  }

  return value.join(' ');
};

/**
 * @name getSeatProperties
 * @description Build a seat object for use in the CSV file.
 *
 * @param seat
 *
 * @returns Seat object.
 */
export const getSeatProperties = (seat: any) => ({
  caller_id: {
    external: seat?.caller_id?.external?.name ?? '',
    internal: seat?.caller_id?.internal?.name ?? '',
  },
  extension: seat?.username ?? '',
  name: buildFullNameFromUser(seat),
});

/**
 * @name getSeatsByCallflowId
 * @description Build a callflow id keyed seats object.
 *
 * @param callflowsData
 * @param seatsData
 *
 * @returns Seats object.
 */
const getSeatsByCallflowId = (callflowsData: Array<any>, seatsData: Array<any>): any => {
  const seats = seatsData?.reduce((acc: Record<string, any>, seat: any) => {
    acc[seat?.id] = { ...seat };
    return acc;
  }, {});

  return callflowsData?.reduce((acc: Record<string, any>, callflow: any) => {
    const { id, seat_id, user_id } = callflow;
    if (seats[seat_id] || seats[user_id]) {
      acc[id] = seats[seat_id] ?? seats[user_id];
    }
    return acc;
  }, {});
};

/**
 * @name sortE911Address
 * @description Sort the e911 service address values, moving
 *              undefined addresses to the bottom of the list.
 *
 * @param a e911 object
 * @param b e911 object
 *
 * @returns Sort integer value.
 */
export const sortE911Address = (a: any, b: any): number => {
  if (a.e911_address === '') {
    return 1;
  }
  if (b.e911_address === '') {
    return -1;
  }
  if (a.e911_address === b.e911_address) {
    return 0;
  }
  return a.e911_address.localeCompare(b.e911_address);
};

/**
 * @name getCsvBlob
 * @description Build the CSV data string.
 *
 * @param [object]
 * @property bridgeNumber
 * @property callflowData
 * @property phoneNumbersData
 * @property seatsData
 *
 * @returns CSV data blob.
 */
export const getCsvBlob = ({
  bridgeNumber,
  callflowsData = [],
  phoneNumbersData = {},
  seatsData = [],
}: {
  bridgeNumber?: string;
  callflowsData?: Array<any>;
  phoneNumbersData?: Record<string, any>;
  seatsData?: Array<any>;
}): Array<BlobPart> => {
  const seatsByCallflowId = getSeatsByCallflowId(callflowsData, seatsData);

  return [i18next.t('numbers:action_row.download.file.columns')].concat(
    Object.values(phoneNumbersData?.numbers ?? [])
      .map((number: any) => {
        const { e911, id, prepend, used_by_callflow_id } = number;
        return {
          ...number,
          is_assigned: Object.keys(phoneNumbersData?.usage?.assigned ?? {}).includes(id)
            ? i18next.t('numbers:action_row.download.file.column.assigned_spare.assigned')
            : i18next.t('numbers:action_row.download.file.column.assigned_spare.spare'),
          has_prepend: prepend?.enabled ?? '',
          e911_contact: e911?.customer_name ?? '',
          e911_address: getE911Address(e911),
          phone_number: `${id}${
            id === bridgeNumber
              ? ` ${i18next.t(
                  'numbers:action_row.download.file.column.phone_number.bridge_number',
                )}`
              : ''
          }`,
          seat: getSeatProperties(seatsByCallflowId?.[used_by_callflow_id]),
        };
      })
      .sort(sortE911Address)
      .map(
        ({
          e911_contact,
          e911_address,
          has_prepend,
          is_assigned,
          phone_number,
          seat,
          used_by = '',
        }: any) =>
          `${seat.name},${seat.extension},${phone_number},${is_assigned},${used_by},${seat.caller_id.external},${seat.caller_id.internal},${e911_contact},${e911_address},${has_prepend}\n`,
      ),
  );
};
