import { useLocation } from 'react-router-dom';
import { getEntries, getEntryIndex, getPrimaryEntry, setEntries, trimPathname } from './utility';

export const useStorage = ({ key, tableId }: { key: string; tableId?: string }) => {
  const { pathname } = useLocation();

  const entries = getEntries(key);
  const path = `${trimPathname(pathname)}${tableId ? `/${tableId}` : ''}`;
  const primary = getPrimaryEntry(key);
  const entryIndex = getEntryIndex(entries, path);

  const updateEntries = ({ entry, key }: { entry: any; key: string }) => {
    setEntries({ entries, entry, key });
  };

  return { entries, entryIndex, path, primary, updateEntries };
};
