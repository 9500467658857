import styled, { css } from 'styled-components';
import { StyledIncomingCallInfoProps as Props } from './definition';

export const StyledIncomingCallInfo = styled.div<Props>`
  ${({ priority }) => css`
    grid-column: 1 / ${!!priority && priority > 0 ? 2 : 3};
  `}
`;

export default StyledIncomingCallInfo;
