import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { LOCAL_STORAGE } from 'constant';
import i18next from 'i18next';
import { client } from './client';

export enum TAGS {}

export const bizProvApi = createApi({
  baseQuery: graphqlRequestBaseQuery({
    client,
    prepareHeaders: (headers: Headers) => {
      const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
      if (token) {
        headers.set('X-Auth-Token', token);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'biz_prov_api',
  tagTypes: Object.values(TAGS),
});

export const getGraphqlErrorMessage = (graphqlError: any): string | undefined => {
  return graphqlError?.message?.split(': {"response"')[0] ?? i18next.t('common:error');
};
