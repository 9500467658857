import { useFetchDevicesQuery, useFetchDeviceStatusesQuery } from 'models/Device';
import { useFetchUsersQuery } from 'models/User';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import { columns } from './utility';

const List = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useFetchDevicesQuery();
  const { data: statusesData, isLoading: isLoadingStatusesData } = useFetchDeviceStatusesQuery();
  const { data: usersData, isLoading: isLoadingUsers } = useFetchUsersQuery();

  if (isLoading || isLoadingStatusesData || isLoadingUsers) {
    return <Loading />;
  }

  return (
    <Table
      hasSearch
      columns={columns(isLoadingStatusesData, {
        deviceStatuses: statusesData,
        users: usersData,
      })}
      data={data}
      initialState={{ sortBy: { id: 'owner' } }}
      title={t('phone_system:containers.devices.table.title')}
    />
  );
};

export default List;
