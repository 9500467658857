import { defaultValues } from 'apps/PhoneSystem/containers/SMS/Edit/default';
import Form from 'apps/PhoneSystem/containers/SMS/Edit/Form';
import FormActionRow from 'apps/shared/components/FormActionRow';
import { FormContext } from 'apps/shared/components/FormContext';
import { ADD_KEY } from 'constant';
import { useFetchSMSByIdQuery } from 'models/SMS';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogContext } from 'store/contexts';
import DataProvider from '../../SeatEntityDataProvider';
import { transformData } from './utility';

const SMSEditView = () => {
  const { t } = useTranslation();
  const { smsId } = useParams() as any;
  const navigate = useNavigate();

  const { getValues, reset, setValue } = useFormContext();

  const dialogCtx = useContext(DialogContext);

  const isCreatingSMS = smsId.startsWith(ADD_KEY);

  const callDeleteSMS = async () => {
    if (isCreatingSMS) {
      const values = getValues();
      delete values.sms[smsId];
      reset({ ...values }, { keepDirty: true });
    } else {
      setValue(`sms.${smsId}._toDelete`, true, { shouldDirty: true });
    }
    navigate('..');
  };

  const renderPageWithData = (data?: any) => {
    const onDeleteSMS = () => {
      const { first_name: firstName, last_name: lastName, username } = getValues('seat');

      if (getValues(`sms.${smsId}.phone_number`)) {
        dialogCtx.onOpen({
          open: true,
          title: t('phone_system:containers.seats.sms.edit.actions.delete_sms_box.label'),
          confirmMessage: (
            <Trans
              i18nKey="phone_system:containers.seats.sms.edit.actions.delete_sms_box.text"
              components={{ bold: <strong /> }}
              values={{
                phone_number: data?.numbers?.length ? data.numbers[0] : '',
                user_owner: `${firstName} ${lastName}, ${username}`,
              }}
            />
          ),
          onConfirm: callDeleteSMS,
        });
      }
    };

    return (
      <>
        <FormActionRow
          button={{
            primary: {
              label: t('phone_system:containers.seats.sms.edit.actions.delete_sms_box.label'),
              onClick: onDeleteSMS,
            },
          }}
          title={t('phone_system:containers.seats.sms.edit.actions.editing_new_sms_box')}
        />
        <Form hasFormActionRow />
      </>
    );
  };

  return (
    <FormContext.Provider value={`sms.${smsId}.`}>
      {isCreatingSMS ? (
        renderPageWithData()
      ) : (
        <DataProvider
          id={smsId}
          fetchFunction={useFetchSMSByIdQuery}
          entity="sms"
          defaultValues={defaultValues}
          transformData={transformData}
        >
          {(data: any) => renderPageWithData(data)}
        </DataProvider>
      )}
    </FormContext.Provider>
  );
};

export default SMSEditView;
