import BasicsSection, { fields as basicsFields } from './BasicsSection';
import OptionsSection, { fields as optionsFields } from './OptionsSection';

const fields = {
  basics: basicsFields,
  options: optionsFields,
};

const Section = {
  Basics: BasicsSection,
  Options: OptionsSection,
};

export { fields, Section };
