import OptionsSection, { fields as optionsFields } from './OptionsSection';

const fields = {
  options: optionsFields,
};

const Section = {
  Options: OptionsSection,
};

export { fields, Section };
