import { Fragment, FunctionComponent } from 'react';
import Action from './components/Action';
import { IconProps } from './components/Action/components/Icon/definition';
import Separator from './components/Separator';
import defaultProps, { defaultElements } from './default';
import {
  InteractionBarElement,
  InteractionBarElementType,
  InteractionBarProps as Props,
} from './definition';
import StyledInteractionBar from './style';
import translations from './translations';

export { CONSTANTS as INTERACTION_BAR_CONSTANTS } from './components/Action/style';
export { InteractionBarElementType, InteractionBarType } from './definition';

const InteractionBar: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { hasDuplicate, hasUndo, hasRedo, type, onAction } = { ...defaultProps, ...props };
  const { elements } = translations();

  const getIsDisabled = (element: InteractionBarElementType) => {
    switch (element) {
      case InteractionBarElementType.DUPLICATE: {
        return !hasDuplicate;
      }
      case InteractionBarElementType.UNDO: {
        return !hasUndo;
      }
      case InteractionBarElementType.REDO: {
        return !hasRedo;
      }
      default:
        return false;
    }
  };

  return (
    <StyledInteractionBar type={type}>
      {defaultElements[type].map(
        ({ element, id, hasPadding, isWide, icon }: Partial<InteractionBarElement>, i: number) => {
          const cast = {
            id: id as string,
            icon: icon as IconProps,
            element: element as InteractionBarElementType,
          };

          return (
            <Fragment key={`${i}-${cast.id}`}>
              {cast.element !== InteractionBarElementType.SEPARATOR ? (
                <Action
                  id={cast.id}
                  hasPadding={hasPadding}
                  isDisabled={getIsDisabled(cast.element)}
                  isWide={isWide}
                  icon={cast.icon}
                  type={type}
                  label={elements[cast.element]?.label}
                  onClick={onAction?.[cast.element]}
                />
              ) : (
                <Separator />
              )}
            </Fragment>
          );
        },
      )}
    </StyledInteractionBar>
  );
};

export default InteractionBar;
