import { themeColor } from 'theme';
import theme from '../core';

export const CONSTANTS = {
  HEIGHT: {
    DEFAULT: 80,
    FILTER: 24,
    SMALL: 40,
  },
};

export default {
  styleOverrides: {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: CONSTANTS.HEIGHT.DEFAULT,
      padding: theme.spacing(4.66),
      borderRadius: theme.spacing(1, 1, 0, 0),
      color: theme.palette.common.black,
      userSelect: 'none',
      '&.MuiDialogTitle-filter': {
        height: CONSTANTS.HEIGHT.FILTER,
        padding: 0,
      },
      '&.MuiDialogTitle-short': {
        height: CONSTANTS.HEIGHT.SMALL,
        padding: theme.spacing(0.66, 0, 0),
        backgroundColor: themeColor.core.background.grey.medium,
      },
      span: {
        '&:first-of-type': {
          width: '90%',
          fontSize: 17,
          fontWeight: 600,
          lineHeight: 1.25,
        },
        '&:last-of-type:not(:first-of-type)': {
          margin: theme.spacing(1, -0.5, 0),
          svg: {
            cursor: 'pointer',
          },
        },
      },
    },
  },
};
