import { useMasqueradingAccountId } from 'apps/shared/hooks/useMasqueradingAccountId';
import { getUrlWithQueryParams } from '../utility';

/**
 * Returns a URL with query parameters (including the masquerading account ID)
 * @param to - The target URL
 * @returns The new URL with query parameters
 */
export const useUrlWithQueryParams = ({ to }: { to: string }) => {
  // The masquerading id must be fetched via the store instead of the url since it is added after the page started rendering
  const masqueradingAccountId = useMasqueradingAccountId();

  // Upsert the query params with the masquerading id
  const currentQueryParamsObject = Object.fromEntries(
    new global.URLSearchParams(global.location.search),
  );

  return getUrlWithQueryParams({ to, masqueradingAccountId, currentQueryParamsObject });
};
