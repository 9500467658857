import {
  DEFAULT_TIMEZONE_OPTION_VALUE,
  FORMAT,
  MILLISECONDS_IN_SECOND,
  SECONDS_GREGORIAN_OFFSET,
  SECONDS_IN_HOUR,
  SECONDS_IN_MINUTE,
} from 'constant';
import { addSeconds, format, startOfDay } from 'date-fns';
import i18next from 'i18next';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import spacetime from 'spacetime';

/**
 * @name dateToGregorian
 * @description Convert a Date instance into a Gregorian timestamp.
 *
 * @param date Date instance
 *
 * @return Gregorian timestamp
 */
export const dateToGregorian = (date: Date): number =>
  Math.floor(date.getTime() / MILLISECONDS_IN_SECOND) + SECONDS_GREGORIAN_OFFSET;

/**
 * @name dateToSeconds
 * @description Convert a Date object to seconds.
 *
 * @param date Date object
 *
 * @return Seconds from the start of the day
 */
export const dateToSeconds = (date: Date): number =>
  date.getHours() * SECONDS_IN_HOUR + date.getMinutes() * SECONDS_IN_MINUTE;

/**
 * @name getTimezoneLabelsWithDst
 * @description This function generates the timezone labels considering DST (Daylight Saving Time).
 *              The timezone map picker and timezone select data will need to use this map to get
 *              the correct timezone label.
 *
 * @param timezone
 *
 * @returns Timezone object; e.g., { label: "(GMT-7:00) Pacific Time", name: "America/Los_Angeles" }
 */
export const getTimezoneLabelsWithDst = (
  timezone: Array<string>,
): { label: string; name: string } => {
  const value = {
    label: i18next.t('common:component.labeled_select_time_zone.default_option'),
    name: DEFAULT_TIMEZONE_OPTION_VALUE,
  };

  try {
    const now = spacetime.now(timezone[0]);
    const tz = now.timezone();

    const min = tz.current.offset * 60;
    // eslint-disable-next-line no-bitwise
    const hr = `${(min / 60) ^ 0}:${min % 60 === 0 ? '00' : Math.abs(min % 60)}`;

    value.label = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${timezone[1]}`;
    value.name = tz.name;
  } catch {
    console.log(`Invalid timezone: ${timezone[0]}`);
  }

  return value;
};

/**
 * @name gregorianToDate
 * @description Convert a Gregorian timestamp into a Date instance.
 *
 * @param pTimestamp Gregorian timestamp
 *
 * @return Converted Date instance
 */
export const gregorianToDate = (pTimestamp: string | number): Date => {
  const timestamp = isString(pTimestamp) ? parseInt(pTimestamp, 10) : pTimestamp;
  if (Number.isNaN(timestamp) || !isNumber(timestamp)) {
    throw new Error('`timestamp` is not a valid Number');
  }
  return new Date((Math.floor(timestamp) - SECONDS_GREGORIAN_OFFSET) * MILLISECONDS_IN_SECOND);
};

/**
 * @name formatDate
 * @description Given a date mask, format a Gregorian timestamp.
 *
 * @param [value=0]
 * @param mask
 *
 * @returns Formatted date
 */
export const formatDate = (value = 0, mask = FORMAT.DATE): string =>
  format(gregorianToDate(value.toString()), mask);

/**
 * @name secondsToDate
 * @description Convert seconds to a Date object.
 *
 * @param seconds Seconds from the start of the day
 *
 * @return Date object
 */
export const secondsToDate = (seconds: number): Date =>
  new Date(addSeconds(startOfDay(new Date()), seconds));
