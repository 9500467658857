import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import {
  StyledSideNavItemAnchorProps as AnchorProps,
  StyledSideNavItemLinkProps as LinkProps,
} from './definition';

const styles = (props: any) => css`
  ${mixin.flex({ justifyContent: 'flex-start' })};
  ${mixin.transition({ property: 'transform', duration: 'fast' })};
  margin: 5px 0;
  padding: 15px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;

  span {
    ${mixin.font({ size: 16 })};
    ${mixin.transition({ property: 'color', duration: 'fast' })};
    color: ${props.theme.core.background.grey.dark};
    white-space: nowrap;
  }

  svg {
    ${mixin.transition({ property: 'fill', duration: 'fast' })};
    margin: 0 10px 0 0;
    fill: ${props.theme.core.background.grey.dark};
  }

  &.active {
    span {
      ${mixin.hover()}; // TODO: add to transition
      color: ${props.theme.whitelabel.primary.color};
    }

    svg {
      fill: ${props.theme.whitelabel.primary.color};
    }
  }

  &:not(.active):hover {
    span {
      ${mixin.hover()}; // TODO: add to transition
      color: ${props.theme.core.color.black};
    }

    svg {
      fill: ${props.theme.whitelabel.primary.color};
    }
  }

  ${props.isToggle
    ? css`
        margin: 0;
        padding: 14px 15px;
        border-bottom: 1px solid ${(props) => props.theme.core.border.color};

        span {
          color: ${props.theme.core.color.black};
        }

        svg {
          ${mixin.transition({ property: 'fill', duration: 'fast' })};
          margin: 0 12px 0 0;
          fill: ${props.theme.core.color.black};

          g {
            ${mixin.transition({ property: 'transform', duration: 'fast' })};
            transform: translate(0, 0) rotate(0);

            ${props.isOpen &&
            css`
              transform: translate(0, 0) rotate(-180deg);
            `};
          }
        }

        &:not(.active):hover span {
          color: ${props.theme.whitelabel.primary.color};
        }
      `
    : css`
        &:not(.active):hover {
          transform: translateY(-3px);
        }
      `};
`;

export const StyledSideNavItemAnchor = styled.div<AnchorProps>`
  ${(props) => styles(props)};
`;

export const StyledSideNavItemLink = styled.div<LinkProps>`
  ${(props) => styles(props)};
`;
