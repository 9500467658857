import { FormContext } from 'apps/shared/components/FormContext';
import { FunctionComponent } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
  UseFormSetValue,
} from 'react-hook-form';
import { Feedback } from 'shared/components/Labeled';
import { InputWrapperProps as Props } from './definition';

/**
 * InputWrapper
 * State management wrapper for input components. This component will fetch the form context
 * and pass down the relevant props to the input component. The RHF control can be passed as an
 * optional prop.
 * @param {Object} props
 * @param {ReactElement} props.children - The children of the component
 * @param {string} props.name - The name of the field, used as the key in RHF state
 * @param {Control} props.control - The RHF control
 * @param {Function} props.customIsDirty - Custom function which analyses dirtyFields directly to determine if the field is dirty
 *
 * @returns
 */
const FieldArrayWrapper: FunctionComponent<Props> = (props: Props): any => {
  const { children, name, control, watchField, customIsDirty } = props;
  const formContext = useFormContext();
  const {
    setValue,
    watch,
    formState: { dirtyFields },
    control: controlContext,
  } = formContext || {};

  return (
    <FormContext.Consumer>
      {(formContext) => {
        const contextName = !formContext ? name : `${formContext}${name}`;
        const watchValue = watchField
          ? !formContext
            ? watch(watchField)
            : watch(`${formContext}${watchField}`)
          : undefined;

        return (
          <Controller
            control={control || controlContext}
            name={contextName}
            render={({
              field,
              fieldState,
            }: {
              field: ControllerRenderProps;
              fieldState: ControllerFieldState;
            }) => {
              let { isDirty, error } = fieldState;

              if (!!customIsDirty) {
                isDirty = customIsDirty(dirtyFields, contextName);
              }

              let extended: {
                isDirty?: boolean;
                value?: [];
                setValue: UseFormSetValue<FieldValues>;
                qualifiedName: string;
                watchValue?: any;
                feedback?: Feedback;
              } = {
                isDirty,
                setValue,
                qualifiedName: contextName,
                watchValue: watchField && watchValue,
                value: (field.value != undefined ? field.value : []) as [],
              };

              if (error) {
                extended.feedback = {
                  type: 'error',
                  message: error.message || '',
                };
              }

              return children({ ...field, ...extended });
            }}
          />
        );
      }}
    </FormContext.Consumer>
  );
};

export default FieldArrayWrapper;
