const IAM_IDP_PATH = `idp/samlv2?kazoo=OE`;

export const IAM = {
  // GET
  FetchIdp: () => IAM_IDP_PATH,

  // POST
  CreateIdp: () => IAM_IDP_PATH,
  EnableSSO: (enabled: boolean) => `sso/enable?enabled=${enabled ?? false}`,

  // PUT
  UpdateIdp: () => IAM_IDP_PATH,

  // DELETE
  DeleteIdp: () => IAM_IDP_PATH,
};
