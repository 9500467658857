import MuiTypography, { TypographyProps as Props } from '@mui/material/Typography';
import { FunctionComponent } from 'react';

export type { TypographyProps } from '@mui/material/Typography';

const Typography: FunctionComponent<Props> = (props: Props): JSX.Element => (
  <MuiTypography {...props} />
);

export default Typography;
