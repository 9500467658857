import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledWarningBar = styled.div`
  ${({ theme }) => css`
    ${mixin.borderRadius()};
    ${mixin.flex({ justifyContent: 'flex-start' })};
    height: 48px;
    margin-bottom: ${theme.spacing(2)};
    background-color: ${theme.brio.warning.container};
    color: ${theme.brio.warning.main};
  `};
`;
