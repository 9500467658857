import { availableRecordings } from 'apps/PhoneSystem/containers/Media/Edit/components/TTSFormSection';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';

export const schema = () =>
  Joi.object({
    name: Joi.string().required().min(1).max(128),
    media_source: Joi.string().valid('recording', 'tts', 'upload').required(),
    streamable: Joi.boolean(),
    tts: Joi.when(Joi.ref('media_source'), {
      is: 'tts',
      then: Joi.object({
        text: Joi.string().required().min(1),
        voice: Joi.string().valid(...availableRecordings),
      }).unknown(true),
      otherwise: Joi.optional(),
    }),
    file: Joi.when(Joi.ref('media_source'), {
      is: 'upload',
      // The upload field is only for tracking local file uploaded. It is not reset on server data arrives.
      // So only when description field is empty (i.e., there is no file from server data), the upload field is required.
      then: Joi.when(Joi.ref('description'), {
        is: Joi.custom((value, helpers) => {
          if (!value) {
            return true;
          }
          return helpers.error('any.required');
        }),
        then: Joi.required(),
      }),
    }),
    description: Joi.string().max(128),
  })
    .unknown(true)
    .messages(messages());
