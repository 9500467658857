import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

/**
 * @name sortSeatsData
 * @description Sorts an array of seat data by last name.
 *
 * @param seatsData Array of seat data
 *
 * @returns Sorted array of seat data.
 */
export const sortSeatsData = (seatsData?: Seat) =>
  seatsData.slice().sort((a: Seat, b: Seat) => {
    if (a.last_name === '') {
      return 1;
    }
    if (b.last_name === '') {
      return -1;
    }
    if (a.last_name === b.last_name) {
      return 0;
    }
    return a.last_name.localeCompare(b.last_name);
  });

/**
 * @name getSeatsCsvBlob
 * @description Generates a CSV blob from seat data.
 *
 * @param [object]
 * @property seatsData - Array of seat data
 * @property seatTypes - Object containing seat types
 * @property locations - Object containing account locations
 *
 * @returns Array of BlobPart representing CSV data.
 */
export const getSeatsCsvBlob = ({
  seatsData,
  seatTypes,
  locations,
}: {
  seatsData?: Array<Seat>;
  seatTypes?: SeatType;
  locations?: Record<string, any>;
}): Array<BlobPart> => {
  if (isEmpty(seatsData) || seatsData?.length === 0 || isEmpty(seatTypes) || isEmpty(locations)) {
    return [];
  }

  return [
    i18next.t('phone_system:containers.seats.action_row.download.file.columns'),
    ...sortSeatsData(seatsData).map(
      ({ first_name, last_name, email, location, seat_type, timezone, username }: Seat) =>
        `${[
          first_name,
          last_name,
          email,
          username,
          seatTypes[seat_type]?.display_name,
          locations[location]?.display_name,
          timezone ?? i18next.t('common:component.labeled_select_time_zone.default_option'),
        ].join(',')}\n`,
    ),
  ];
};
