import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import { LabeledInput } from 'shared/components/Labeled';
import { ResetFormProps as Props } from './definition';
import StyledResetForm from './style';

const ResetForm: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isResetConfirmation, error, onClick, onSubmit }: Props = props;
  const { t } = useTranslation();

  return (
    <StyledResetForm>
      {error && (
        <Box className="error">
          <Icon name="warning-filled" size={14} themeColor="core.color.orange" />
          <Box component="span">{t(`common:auth.login.invalid_credentials`)}</Box>
        </Box>
      )}
      {isResetConfirmation ? (
        <>
          <Box className="reset-confirmation">
            <Icon name="email-outlined" />
            {t('common:auth.reset.confirmation')}
          </Box>

          <Button color="error" size="login" variant="contained" onClick={() => onClick(true)}>
            {t('common:auth.label.sign_in')}
          </Button>
        </>
      ) : (
        <>
          <Box className="heading" data-test-id="title-reset-password-form">
            <Box component="span">{t('common:auth.reset.reset_password')}</Box>
          </Box>

          <HookFormInputWrapper name="reset_username">
            {({ isDirty, feedback, ...formProps }) => (
              <LabeledInput
                hasDirtyIcon={false}
                isLabelAbove
                inputWidth="auto"
                label={t('common:auth.label.username')}
                id="input-reset-username"
                feedback={feedback}
                labelProps={{ required: true }}
                inputProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>

          <HookFormInputWrapper name="reset_account_name">
            {({ isDirty, feedback, ...formProps }) => (
              <LabeledInput
                hasDirtyIcon={false}
                isLabelAbove
                inputWidth="auto"
                label={t('common:auth.label.account_name')}
                id="input-reset-account-name"
                feedback={feedback}
                labelProps={{ required: true }}
                inputProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>

          <Button
            color="error"
            size="login"
            data-test-id="btn-login"
            variant="contained"
            onClick={onSubmit}
          >
            {t('common:auth.reset.reset_password')}
          </Button>

          <Box>
            <Box component="span" data-test-id="span-remember-your-password">
              {t('common:auth.reset.remember_your_password')}
            </Box>
            <Box component="span" data-test-id="link-sign-in" onClick={() => onClick(true)}>
              {t('common:auth.label.sign_in')}
            </Box>
          </Box>
        </>
      )}
    </StyledResetForm>
  );
};

export default ResetForm;
