import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import Icon from 'shared/components/Icon';
import { LabeledButton } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { FormInput } from '../../definition';
import EndpointsDialogContent from './components/EndpointsDialogContent';
import LabeledEndpointsList from './components/LabeledEndpointsList';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['name', 'endpoints'];

const OptionsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { getValues } = useFormContext();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
  };

  const handleDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    if (closeResponse.reason === 'cancelClicked') {
      handleDialogClose();
    }
  };

  return (
    <>
      <div role="row">
        <div role="cell">
          {/* Name */}
          <HookFormInputWrapper name="name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('phone_system:containers.groups.section.options.field.name.label')}
                labelWidth="auto"
                inputProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      {getValues('id') && Object.keys(getValues('endpoints')).length > 0 && (
        <div role="row">
          <div role="cell">
            <LabeledButton
              labelWidth="none"
              buttonProps={{
                children: t(
                  'phone_system:containers.groups.section.options.button.view_all_endpoints.label',
                ),
                variant: 'outlined',
                size: 'small',
                startIcon: <Icon name="visibility-on-filled" size={20} />,
                onClick: () => setIsDialogOpen(true),
              }}
              dataTestId={`${TEST_ID.COMMON.PREFIX.BUTTON}-view-all-endpoints`}
            />
          </div>
        </div>
      )}

      <h2>
        {t('phone_system:containers.groups.section.options.field.endpoints.manage_group.heading')}
      </h2>
      <p>
        {t('phone_system:containers.groups.section.options.field.endpoints.manage_group.intro')}
      </p>

      <div role="row" className="one-column">
        <div role="cell">
          {/* Endpoints List */}
          <HookFormInputWrapper name="endpoints">
            {({ ref, isDirty, feedback, value, onChange }) => (
              <LabeledEndpointsList
                isDirty={isDirty as boolean}
                data={value}
                feedback={feedback}
                onChange={onChange}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <Dialog
        open={isDialogOpen}
        dataTestId={`${TEST_ID.COMMON.PREFIX.DIALOG}-endpoints`}
        renderActions={
          <DialogActions
            hasCancelOnly
            cancelLabel={t('common:close')}
            onAction={handleDialogAction}
          />
        }
        title={t('phone_system:containers.groups.section.options.dialog.endpoints.title')}
        type={DialogType.GroupsEndpoints}
        onClose={handleDialogClose}
      >
        <EndpointsDialogContent data={getValues('endpoints')} />
      </Dialog>
    </>
  );
};

export default OptionsSection;
