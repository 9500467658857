import { FlippedType, IconSet, IconSubset } from './definition';

const defaultProps = {
  hasHover: false,
  isFlipped: {
    [FlippedType.Both]: false,
    [FlippedType.Horizontal]: false,
    [FlippedType.Vertical]: false,
  },
  set: 'brio' as IconSet,
  subset: 24 as IconSubset,
  scale: 1,
  size: 0,
  width: 0,
  height: 0,
};

export default defaultProps;
