import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'definition';

type AuthState = {
  user: User | null;
  auth_token: string | null;
  account_id: string | null;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, auth_token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { auth_token, data },
      }: PayloadAction<{ user: User; auth_token: string; data: any }>,
    ) => {
      state.user = data.owner_id;
      state.account_id = data.account_id;
      state.auth_token = auth_token;
    },
  },
});

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
