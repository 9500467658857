import { FilterGroup, useFilter } from 'apps/shared/hooks/useFilter';
import { FilterInputType } from 'apps/shared/hooks/useFilter/components/FilterDialogContent/definition';
import { useFetchAccountQuery } from 'models/Account';
import { useFetchDevicesQuery } from 'models/Device';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useFetchUsersQuery } from 'models/User';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOption } from 'shared/components/FilterSelect';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import Table from 'shared/components/Table';
import DownloadIcon from './components/DownloadIcon';
import { E911Row, FilterType, IsLoading } from './definition';
import { useE911InfoFilterOptions } from './hooks/useE911InfoFilterOptions';
import translations from './translations';
import { columns, processData, processDevices, processUsers } from './utility';

const List = () => {
  const { table } = translations();
  const { t } = useTranslation();
  const [data, setData] = useState<Array<E911Row>>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [devices, setDevices] = useState<Array<E911Row>>([]);
  const [users, setUsers] = useState<Array<E911Row>>([]);
  const [isLoading, setIsLoading] = useState<IsLoading>({
    data: true,
    devices: true,
    users: true,
  });

  const { data: accountData, isLoading: isLoadingAccount } = useFetchAccountQuery();
  const { data: devicesData, isLoading: isLoadingDevices } = useFetchDevicesQuery();
  const { data: numbersData, isLoading: isLoadingNumbers } = useFetchPhoneNumbersQuery();
  const { data: usersData, isLoading: isLoadingUsers } = useFetchUsersQuery();

  const {
    [FilterType.SiteLocation]: { locationOptions, getLocationLabelById },
    [FilterType.Source]: { sourceOptions, getSourceLabelByValue },
    [FilterType.Type]: { typeOptions, getTypeLabelByValue },
  } = useE911InfoFilterOptions();

  const { filter } = useFilter({
    data,
    inputConfig: {
      filterDefaults: {
        [FilterType.Type]: [
          FilterOption.All,
          ...typeOptions.map(({ value }: SelectOption) => value),
        ],
        [FilterType.SiteLocation]: [
          FilterOption.All,
          ...locationOptions.map(({ value }: SelectOption) => value),
        ],
        [FilterType.Source]: [
          FilterOption.All,
          ...sourceOptions.map(({ value }: SelectOption) => value),
        ],
        [FilterType.IsShowMissingInfo]: [FilterOption.All, FilterType.IsShowMissingInfo],
      },
      filterGroup: FilterGroup.E911Info,
      items: {
        [FilterType.Type]: {
          compareFunction: (filterValues, { type }) =>
            filterValues.selected?.[FilterType.Type]?.includes(type),
          filterType: FilterType.Type,
          heading: t('phone_system:containers.e911info.filter.heading.type'),
          inputType: FilterInputType.Select,
          getBreadcrumbItemLabel: getTypeLabelByValue,
          selectInputConfig: {
            isMulti: false,
            options: typeOptions,
          },
        },
        [FilterType.SiteLocation]: {
          compareFunction: (filterValues, { location }) =>
            filterValues.selected?.[FilterType.SiteLocation]?.includes(location ?? ''),
          filterType: FilterType.SiteLocation,
          heading: t('phone_system:containers.e911info.filter.heading.site_location'),
          inputType: FilterInputType.Select,
          getBreadcrumbItemLabel: getLocationLabelById,
          selectInputConfig: {
            isMulti: false,
            options: locationOptions,
          },
        },
        [FilterType.Source]: {
          compareFunction: (filterValues, { source }) =>
            filterValues.selected?.[FilterType.Source]?.includes(source),
          filterType: FilterType.Source,
          heading: t('phone_system:containers.e911info.filter.heading.source'),
          inputType: FilterInputType.Select,
          getBreadcrumbItemLabel: getSourceLabelByValue,
          selectInputConfig: {
            isMulti: false,
            options: sourceOptions,
          },
        },
        [FilterType.IsShowMissingInfo]: {
          compareFunction: (_, { e911 }) => !e911?.address,
          filterType: FilterType.IsShowMissingInfo,
          heading: t('phone_system:containers.e911info.filter.heading.show_missing_info_only'),
          inputType: FilterInputType.Checkbox,
          getBreadcrumbItemLabel: () =>
            t('phone_system:containers.e911info.filter.breadcrumb.missing_info_only'),
        },
      },
    },
    setTableData,
  });

  useEffect(() => {
    processDevices({ devicesData, users, setDevices, setIsLoading });
  }, [devicesData, users, table.columns.seat.notAssigned]);

  useEffect(() => {
    processUsers({ accountData, usersData, setUsers, setIsLoading });
  }, [accountData, usersData]);

  useEffect(() => {
    processData({
      devices,
      users,
      locations: accountData?.locations,
      numbers: numbersData?.usage.assigned,
      setData,
      setIsLoading,
    });
  }, [devices, accountData, numbersData, users]);

  if (
    isLoadingAccount ||
    isLoadingDevices ||
    isLoadingNumbers ||
    isLoadingUsers ||
    isLoading.devices ||
    isLoading.users ||
    isLoading.data
  ) {
    return <Loading />;
  }

  return (
    <Table
      hasSearch
      actionRowButtons={{
        right: <DownloadIcon data={tableData} />,
      }}
      columns={columns()}
      data={tableData}
      filter={filter}
      hasEditIcon={false}
      hasPagination={{ rowsPerPage: true }}
      title={table.title}
    />
  );
};

export default List;
