import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { logout, setLocalStorageAuth, useValidateResetIdMutation } from 'models/Auth';
import { usePatchSeatWithAccountIdMutation } from 'models/Seat';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LabeledInput } from 'shared/components/Labeled';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { messages } from 'shared/utility/validation';
import Button from '../../../../shared/components/Button';
import Dialog, { DialogActions } from '../../../../shared/components/Dialog';
import { HookFormInputWrapper } from '../HookForm';
import { PasswordResetDialogProps as Props } from './definition';

interface FormInput {}

/**
 * This dialog shows up via password reset link
 */
const PasswordResetDialog: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const recoveryKey = searchParams.get('recovery');

  const [open, setOpen] = useState<boolean>(false);
  const close = () => setOpen(false);

  const [patchSeatWithAccountId] = usePatchSeatWithAccountIdMutation();
  const [reset] = useValidateResetIdMutation();

  const formMethods = useForm({
    defaultValues: {},
    resolver: joiResolver(
      Joi.object({
        password: Joi.string().min(4).max(16).required(),
        confirm_password: Joi.string().required().valid(Joi.ref('password')),
      }).messages(
        messages({
          'any.only': 'passwords do not match', // TODO: i18n
        }),
      ),
    ),
  });

  const { handleSubmit, setError } = formMethods;

  const handlePasswordUpdate: SubmitHandler<FormInput> = async (formData: any) => {
    try {
      const authUser = await reset({
        body: {
          reset_id: recoveryKey,
        },
      }).unwrap();

      const userId = authUser?.data?.owner_id;
      const accountId = authUser?.data?.account_id;
      const authToken = authUser?.auth_token;

      if (userId) {
        const { password: newPassword } = formData;
        const updatedUserData = {
          password: newPassword,
          id: userId,
          require_password_update: false,
        };

        setLocalStorageAuth({
          auth_token: authToken,
          user_id: userId,
          account_id: accountId,
        });

        await patchSeatWithAccountId({
          id: userId,
          accountId,
          body: updatedUserData,
        }).unwrap();

        close();
        logout();
        navigate('/login?message_type=reset_successful');
      } else {
        throw new Error('Invalid recovery key');
      }
    } catch (exception: any) {
      if (exception?.status === 400) {
        showToast.error(t('common:auth.reset.dialog.error_message'));
      } else {
        showErrorMessage({ errors: exception, setError });
      }
    }
  };

  useEffect(() => {
    if (recoveryKey) {
      setOpen(true);
    }
  }, [recoveryKey]);

  return (
    <Dialog
      open={open}
      onClose={close}
      title={t('common:auth.reset.dialog.title')}
      renderActions={
        <DialogActions
          rightButtons={
            <>
              <Button color="primary" variant="outlined" onClick={close}>
                {t('common:cancel')}
              </Button>
              <Button
                color="success"
                variant="contained"
                onClick={handleSubmit(handlePasswordUpdate, (errors) =>
                  showErrorMessage({
                    errors,
                    message: t('common:auth.reset.dialog.error_message'),
                    setError,
                  }),
                )}
                data-test-id="btn-dialog-action-review"
              >
                {t('common:auth.reset.dialog.reset_button')}
              </Button>
            </>
          }
        />
      }
    >
      <FormProvider {...formMethods}>
        <p>
          <span>{t('common:auth.reset.dialog.message')}</span>
        </p>
        <HookFormInputWrapper name="password">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              label={t('phone_system:containers.seats.section.basics.new_password.label')}
              labelWidth="xmedium"
              hasDirtyIcon={false}
              isDirty={isDirty}
              feedback={feedback}
              inputProps={{
                ...formProps,
                type: 'password',
                placeholder: '******',
                autoComplete: 'new-password',
              }}
            />
          )}
        </HookFormInputWrapper>
        <HookFormInputWrapper name="confirm_password">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              label={t('phone_system:containers.seats.section.basics.confirm_password.label')}
              labelWidth="xmedium"
              hasDirtyIcon={false}
              isDirty={isDirty}
              feedback={feedback}
              inputProps={{
                ...formProps,
                type: 'password',
                placeholder: '******',
                autoComplete: 'new-password',
              }}
            />
          )}
        </HookFormInputWrapper>
      </FormProvider>
    </Dialog>
  );
};

export default PasswordResetDialog;
