import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledAppIconProps as Props } from './definition';

const StyledAppIcon = styled.div<Props>`
  ${(props) => css`
    ${mixin.transition({ property: 'opacity', duration: 'fastest' })};
    display: grid;

    ${props.isDisabled &&
    css`
      opacity: 0.5;
    `}

    img {
      grid-area: 1 / 1;
      width: ${props.size}px;
      height: ${props.size}px;

      ${props.hasShadow &&
      css`
        &:first-of-type:not(:last-of-type) {
          z-index: 10;
        }

        &:last-of-type {
          filter: drop-shadow(0 0 3px rgb(0 0 0 / 25%));
        }
      `}
    }
  `}
`;

export default StyledAppIcon;
