import { TableCellOverflowList } from 'apps/shared/components/TableCell';
import i18next from 'i18next';
import { compareStrings } from 'shared/utility';
import { Columns, GroupTableRow } from './definition';

export const columns: Columns = () => [
  {
    Header: i18next.t('phone_system:containers.groups.table.column.name'),
    accessor: 'name',
    width: 300,
  },
  {
    Header: i18next.t('phone_system:containers.groups.table.column.endpoints'),
    Cell: ({
      row: {
        original: { endpointsCombined },
      },
    }: {
      row: GroupTableRow;
    }) => (
      <TableCellOverflowList
        values={endpointsCombined ?? []}
        data-test-id="phone-system-groups-list-table-cell-users-list"
      />
    ),
    accessor: 'endpointsCombined',
    sortType: (rowA: GroupTableRow, rowB: GroupTableRow) =>
      compareStrings(
        rowA.original.endpointsCombined.join(''),
        rowB.original.endpointsCombined.join(''),
      ),
  },
];
