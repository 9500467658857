import i18next from 'i18next';

const translations = () => ({
  csv: {
    headers: {
      last_name: i18next.t(
        'phone_system:containers.account.submodule.site_locations.csv.headers.last_name',
      ),
      first_name: i18next.t(
        'phone_system:containers.account.submodule.site_locations.csv.headers.first_name',
      ),
      email: i18next.t(
        'phone_system:containers.account.submodule.site_locations.csv.headers.email',
      ),
      username: i18next.t(
        'phone_system:containers.account.submodule.site_locations.csv.headers.username',
      ),
      seat_type: i18next.t(
        'phone_system:containers.account.submodule.site_locations.csv.headers.seat_type',
      ),
      timezone: i18next.t(
        'phone_system:containers.account.submodule.site_locations.csv.headers.timezone',
      ),
    },
  },
});

export default translations;
