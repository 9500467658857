// Directory Mutations
import { Directory } from 'api/directory';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const directoryMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createDirectory: builder.mutation<Directory, Partial<Directory>>({
      query: ({ body }) => ({
        url: Directory.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.DIRECTORY, id: 'LIST' }],
    }),
    updateDirectory: builder.mutation<Directory, UpdateDirectoryParams>({
      query: ({ id, body }) => ({
        url: Directory.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.DIRECTORY, id }],
    }),
    deleteDirectory: builder.mutation<Directory, ByIdParam>({
      query: ({ id }) => ({
        url: Directory.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.DIRECTORY, id }],
    }),
  }),
});
