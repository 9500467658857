import i18next from 'i18next';
import { DialogProps } from '.';

const defaultProps: DialogProps = {
  confirmMessage: i18next.t('common:delete_item'),
  open: false,
  onConfirm: () => console.log('Confirm is currently unimplemented.'),
};

export default defaultProps;
