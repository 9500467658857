export interface SettingsSectionData {
  [section: string]: {
    [field: string]: string | number | boolean;
  };
}

export interface Settings {
  [tab: string]:
    | SettingsSectionData
    | Array<SettingsSectionData>
    | { [iterate: string | number]: SettingsSectionData };
}

export interface DeviceData {
  mac_address: string;
  name: string;
  brand: string;
  family: string;
  model: string;
  settings: Settings;
}

export type Devices = Array<DeviceData>;

export interface Template {
  [tab: string]: {
    /** The sections inside the tab */
    data: {
      [section: string]: {
        /** The fields inside the section */
        data: {
          [field: string]: {
            /** Only needed for `select` type input */
            options?: SelectOptions;
            /** The label of the field */
            text: string;
            /** The tooltip of the field */
            tooltip?: string;
            /** The type of the field */
            type: string;
            /** The default value of the field */
            value?: FieldValueType;
          };
        };
        /** Should the section be hidden? */
        hidden?: boolean;
        /** The label of the section */
        text: string;
      };
    };
    /** The repetition of the data (i.e., the sections) */
    iterate?: number;
    /** The label of the tab */
    text: string;
  };
}

export interface TemplateCluster {
  [tab: string]: {
    [section: string]: {
      [field: string]: FieldValueType;
    };
  };
}

export interface DeviceTemplateData {
  cluster: TemplateCluster;
  settings: Settings;
  template: Template;
}

export interface FetchAccountTemplateParams {
  id?: string;
}

export interface FetchDeviceTemplateByModelParams {
  brand: string;
  family: string;
  model: string;
}

export interface FetchDeviceFilesByMacAddressParams {
  type: DeviceFileType;
  macAddress: string;
}

export interface AccountTemplateData {
  cluster: TemplateCluster;
  settings: Settings;
  template: Template;
}

export interface AccountSettingsData {
  settings: Settings;
}

export type UpdateDeviceByMacAddressParams = DeviceData;

export type FieldValueType = string | boolean | number;

export type SelectOptions = { text: string; value: FieldValueType }[];

export interface UpdateAccountSettingsParams {
  body: AccountSettingsData;
}

export enum DeviceFileType {
  Config = 'device_config',
  Log = 'device_access_log',
}

export interface Phones {
  [brand: string]: {
    class: string;
    families: {
      [family: string]: {
        id: string;
        models: {
          [model: string]: {
            id: string;
            name: string;
          };
        };
        name: string;
      };
    };
    id: string;
    name: string;
  };
}

export interface SubaccountDevicesData {
  account_id: string;
}
