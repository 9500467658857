import { FunctionComponent } from 'react';
import { ExternalLinkProps as Props } from './definition';
import StyledExternalLink from './style';

const ExternalLink: FunctionComponent<Props> = ({ children, url }: Props): JSX.Element => (
  <StyledExternalLink href={url} rel="noopener noreferrer" target="_blank">
    {children}
  </StyledExternalLink>
);

export default ExternalLink;
