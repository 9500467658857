// Menu Mutations
import { Menu } from 'api/menu';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const menuMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createMenu: builder.mutation<Menu, Partial<Menu>>({
      query: ({ body }) => ({
        url: Menu.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.MENU, id: 'LIST' }],
    }),
    updateMenu: builder.mutation<Menu, UpdateMenuParams>({
      query: ({ id, body }) => ({
        url: Menu.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.MENU, id }],
    }),
    deleteMenu: builder.mutation<Menu, ByIdParam>({
      query: ({ id }) => ({
        url: Menu.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.MENU, id }],
    }),
  }),
});
