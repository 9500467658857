import produce from 'immer';
import isEmpty from 'lodash/isEmpty';

export const transformConferenceForForm = (data: any): any =>
  produce(data, (draft: any) => {
    if (!isEmpty(data.member.numbers)) {
      /**
       * Combine `conference_numbers` with `member.numbers` for compatibility with Kazoo UI
       */
      draft.conference_numbers = [...new Set([...data.member.numbers, ...data.conference_numbers])];
    }
  });

export const transformConferenceForSaving = (data: any): any => {
  const split = (value: string) => (value as string).split(/[, ]+/g); // Split by comma or space

  return produce(data, (draft: any) => {
    if (!Array.isArray(data.member.pins)) {
      draft.member.pins = data.member.pins === '' ? [] : split(data.member.pins);
    }

    if (!Array.isArray(data.conference_numbers)) {
      draft.conference_numbers =
        data.conference_numbers === '' ? [] : split(data.conference_numbers);
    }

    /**
     * Save `conference_numbers` value to `member.numbers`: Because Kazoo UI has been
     * saving this value exclusively to `member.numbers`, we'll need to do it here as well.
     *
     * This isn't ideal; c'est la vie.
     */
    draft.member.numbers = [...draft.conference_numbers];
  });
};
