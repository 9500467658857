export enum CallerIdProperty {
  Name = 'name',
  Number = 'number',
}

export enum CallerIdType {
  Emergency = 'emergency',
  External = 'external',
}

export enum FormFields {
  AddressCity = 'address_city',
  AddressCountry = 'address_country',
  AddressLine1 = 'address_line_1',
  AddressLine2 = 'address_line_2',
  AddressPostalCode = 'address_postal_code',
  AddressState = 'address_state',
  CallerId = 'caller_id',
  CallerIdEmergencyName = 'caller_id.emergency.name',
  CallerIdEmergencyNumber = 'caller_id.emergency.number',
  CallerIdExternalName = 'caller_id.external.name',
  CallerIdExternalNumber = 'caller_id.external.number',
  DisplayName = 'display_name',
  TimeZone = 'timezone',
}

export interface FormInput {
  // options
  [FormFields.AddressCity]: string;
  [FormFields.AddressCountry]: string;
  [FormFields.AddressLine1]: string;
  [FormFields.AddressLine2]: string;
  [FormFields.AddressPostalCode]: string;
  [FormFields.AddressState]: string;
  [FormFields.DisplayName]: string;
  [FormFields.TimeZone]: string;

  // caller id
  [FormFields.CallerId]: Record<
    CallerIdType,
    {
      name: null | string;
      number: null | string;
    } | null
  > | null;
}

export interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: StructuredFormatting;
}

export interface MainTextMatchedSubstrings {
  length: number;
  offset: number;
}

export interface StructuredFormatting {
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
  main_text: string;
  secondary_text: string;
}
