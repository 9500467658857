import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import Box from 'shared/components/Box';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { TABS_CONSTANTS } from 'theme/mui/components';
import { StyledTabsWrapperProps as Props } from './definition';

const StyledTabsWrapper = styled(Box)<Props>`
  ${({ $hasMargin, $hasMenuOffset, $hasScroll, $isCentered, theme }) => css`
    ${mixin.flex({ justifyContent: $isCentered ? 'center' : 'flex-start' })}
    width: 100${$hasScroll ? 'vw' : '%'};
    height: ${TABS_CONSTANTS.HEIGHT.TAB}px;
    border-bottom: 1px solid ${theme.core.border.color};

    ${$hasMargin &&
    css`
      margin-bottom: ${ACTION_ROW_CONSTANTS.GAP.VERTICAL};
    `}

    ${$hasMenuOffset &&
    css`
      width: calc(100vw - ${HEADER_CONSTANTS.LOGO.WIDTH});
    `}
  `};
`;

export default StyledTabsWrapper;
