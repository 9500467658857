import produce from 'immer';

/**
 * @description Returns a URL with query parameters added to it.
 *
 * @param to - The target URL.
 * @param masqueradingAccountId - The ID of the account masqueraded to
 * @param currentQueryParamsObject - The current query parameters as an object.
 * @returns The new URL with query parameters added to it.
 */
export const getUrlWithQueryParams = ({
  to,
  masqueradingAccountId,
  currentQueryParamsObject,
}: {
  to: string;
  masqueradingAccountId: string | null;
  currentQueryParamsObject: Record<string, string>;
}) => {
  const currentQueryParams = new global.URLSearchParams(
    produce(currentQueryParamsObject, (draft) => {
      if (masqueradingAccountId) {
        draft.m = masqueradingAccountId;
      }
    }),
  ).toString();

  return `${to}${currentQueryParams ? `?${currentQueryParams}` : ''}`;
};
