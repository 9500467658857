import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { RequestMethod } from 'definition';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, WebhookDialogProps as Props, WebhookNodeData } from './definition';
import schema from './schema';

const WebhookDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { handleSubmit, control, formState, watch } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;
  const isGetHttpVerb = watch('http_verb') === RequestMethod.Get.toLowerCase();
  const httpVerbs = [
    { value: RequestMethod.Get.toLowerCase(), label: RequestMethod.Get },
    { value: RequestMethod.Post.toLowerCase(), label: RequestMethod.Post },
  ];
  const retries = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
  ];
  const bodyFormats = [
    {
      value: 'form-data',
      label: t(
        'phone_system:containers.callflows.callflow_action_dialog.webhook.body_formats.form_data',
      ),
    },
    {
      value: 'json',
      label: t(
        'phone_system:containers.callflows.callflow_action_dialog.webhook.body_formats.json',
      ),
    },
  ];

  const submitHandler = (formData: Data) => {
    let { format, ...data }: Data & Partial<Data> = formData;
    const newData =
      data.http_verb !== RequestMethod.Get.toLowerCase()
        ? {
            ...data,
            format,
          }
        : data;
    const nodeData: WebhookNodeData = {
      metadata: {
        pvt_type: 'webhook',
      },
      data: newData,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.webhook.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <p>{t('phone_system:containers.callflows.callflow_action_dialog.webhook.explanation')}</p>
      <HookFormInputWrapper name="uri" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t('phone_system:containers.callflows.callflow_action_dialog.webhook.uri.label')}
            inputWidth="xlarge"
            inputProps={{
              ...formProps,
              id: 'input-webhook-uri',
            }}
            tooltip={t(
              'phone_system:containers.callflows.callflow_action_dialog.webhook.uri.tooltip',
            )}
          />
        )}
      </HookFormInputWrapper>
      <HookFormSelectWrapper name="http_verb" control={control} options={httpVerbs}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.webhook.http_verb.label',
            )}
            selectProps={{
              ...formProps,
              id: 'select-webhook-http-verb',
            }}
            selectWidth="xlarge"
            tooltip={t(
              'phone_system:containers.callflows.callflow_action_dialog.webhook.http_verb.tooltip',
            )}
          />
        )}
      </HookFormSelectWrapper>
      {!isGetHttpVerb && (
        <HookFormSelectWrapper name="format" control={control} options={bodyFormats}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledSelect
              isDirty={isDirty}
              feedback={feedback}
              isLabelAbove
              label={t(
                'phone_system:containers.callflows.callflow_action_dialog.webhook.format.label',
              )}
              selectProps={{
                ...formProps,
                id: 'select-webhook-format',
              }}
              selectWidth="xlarge"
              tooltip={t(
                'phone_system:containers.callflows.callflow_action_dialog.webhook.format.tooltip',
              )}
            />
          )}
        </HookFormSelectWrapper>
      )}
      <HookFormSelectWrapper name="retries" control={control} options={retries}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.webhook.retries.label',
            )}
            selectProps={{
              ...formProps,
              id: 'select-webhook-retries',
            }}
            selectWidth="xlarge"
            tooltip={t(
              'phone_system:containers.callflows.callflow_action_dialog.webhook.retries.tooltip',
            )}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default WebhookDialog;
