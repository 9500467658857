import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useMemo } from 'react';
import { SelectOption } from 'shared/components/Select';

export const useSpareNumbers = () => {
  const { data: phoneNumbersData, refetch: refetchPhoneNumbers } = useFetchPhoneNumbersQuery();

  const spareNumbersOptions: SelectOption[] = useMemo<SelectOption[]>(
    () =>
      phoneNumbersData?.usage?.spare
        ? Object.keys(phoneNumbersData.usage.spare).map((number: string) => ({
            label: number,
            value: number,
          }))
        : [],
    [phoneNumbersData],
  );

  return { spareNumbersOptions, refetchPhoneNumbers };
};
