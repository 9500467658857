import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const Media = {
  // GET
  Fetch: (isPaginated: boolean = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/media?paginate=${isPaginated}`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/media/${id}`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/media`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/media/${id}`,
  Upload: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/media/${id}/raw`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/media/${id}`,
};
