import { SESSION_STORAGE } from 'constant';
import { cloneDeep } from 'lodash';
import { RefObject } from 'react';
import { Row } from 'react-table';
import Checkbox from 'shared/components/Checkbox';
import { SelectionPosition, SelectionType } from '../components/definition';

export const MINIMUM_FILTER_LENGTH = 1; // minimum number of filter characters
export const SHARE_COLUMN_KEYWORD = 'share';
export const SEARCH_PARAM_KEYWORD = 'search';

/**
 * @name filterExpandedRowIds
 * @description Get a filtered (of duplicates/undefined values) list of expanded row ids.
 *
 * @param expandedRowIds
 *
 * @returns Filtered list of expanded row ids.
 */
export const filterExpandedRowIds = (
  expandedRowIds: RefObject<Array<Array<string>>>,
): Array<Array<string>> => {
  const rowIds = expandedRowIds.current;

  return rowIds
    ? rowIds.filter(
        (arrayA: Array<string>, i: number) =>
          i ===
          rowIds.findIndex(
            (arrayB: Array<string>) => JSON.stringify(arrayB) === JSON.stringify(arrayA),
          ),
      )
    : [];
};

/**
 * @name filterSubRows
 * @description Get the expandedRowId.
 *
 * @param rows
 * @param columnIds
 * @param filterValue
 * @param options
 *
 * @returns Filtered sub rows.
 */
export const filterSubRows = (
  rows: Array<Row>,
  columnIds: Array<string>,
  filterValue: string,
  options?: Record<string, Array<string>>,
): Array<Row> => {
  // cloning the rows so that original rows need not to be mutated
  const clonedRows = cloneDeep(rows);

  // say goodbye :)
  if (
    filterValue === '' ||
    filterValue === null ||
    filterValue === undefined ||
    (typeof filterValue === 'string' && filterValue.length <= MINIMUM_FILTER_LENGTH)
  ) {
    return clonedRows;
  }

  // in case of a complex filter, the parent component should provide the filter logic
  if (typeof filterValue !== 'string') {
    return [];
  }

  return clonedRows.filter((row: Row): boolean => {
    const { id, values, subRows } = row;

    /**
     * The default filter will consider only the basic data format like number and string.
     * The parent component should provide the filter method if the data are complex (like Arrays and Objects)
     * or the column structure contains customized cells. (See the cell property of react table columns)
     *
     * Below will use only the considered columns ids (see disableGlobalFilter in the column definition)
     * It will filter the value of the displayed row object, then add all the elements to a string separated
     * by space and an array of separate lowercase words without empty strings
     */
    const searchableValues = columnIds
      .map((columnId: string): number | string => values[columnId])
      .filter((value: number | string) => ['number', 'string'].includes(typeof value))
      .join(' ')
      .split(' ') // delete extra spaces between words
      .filter((value: string): boolean => value !== '')
      .join(' ')
      .toLocaleLowerCase();

    // determine if the search value matches the searchableValue string
    const hasFilterValues = filterValue
      .trim()
      .toLocaleLowerCase()
      .split(' ')
      .every((value: string) => searchableValues.includes(value));

    // if this row matches the search value, add its id to the filteredIds array
    if (hasFilterValues && Array.isArray(options?.filteredRowIds)) {
      options?.filteredRowIds.push(id);
    }

    // if there are subRows that match the search value...
    const hasSubRows =
      Array.isArray(subRows) && !!filterSubRows(subRows, columnIds, filterValue).length;

    return hasFilterValues || hasSubRows;
  });
};

/**
 * @name getColSpan
 * @description Get the colSpan value.
 *
 * @param columns Number of data columns
 * @param [object] options
 * @property hasDragIcon Is the drag icon visible?
 * @property hasEditIcon Is the edit icon visible?
 * @property hasSelection Is the checkbox visible?
 *
 * @returns Total number of table columns.
 */
export const getColSpan = (
  columns = 0,
  options: { hasDragIcon?: boolean; hasEditIcon?: boolean; hasSelection?: boolean } = {},
): number => columns + Object.values(options).filter((hasOption: boolean) => hasOption).length;

/**
 * @name getExpandedRowId
 * @description Get the expanded row id (during a search).
 *
 * @param rows
 * @param filterValue
 *
 * @returns Expanded row id.
 */
export const getExpandedRowId = (rows: Array<Row>, filterValue: string): Array<string> =>
  filterValue.length > MINIMUM_FILTER_LENGTH
    ? rows
        /**
         * filter: Ignore top level accounts
         * slice:  Only look at one account per row
         * map:    Remove the last segment of the row id (converting it to the parent id)
         */
        .filter((row: Row) => row.depth > 0)
        .slice(0, 1)
        .map(({ id }: Row) => id?.split('.').slice(0, -1).join('.'))
    : [];

/**
 * @name getSelectionCell
 * @description Get the table header selection cell element.
 *
 * @param [object] options
 * @property isChecked
 * @property rows
 * @property selectionType
 * @property onChange
 *
 * @returns Selection cell.
 */
export const getSelectionCell = ({
  isChecked,
  rows,
  selection,
  onChange,
}: {
  isChecked: boolean;
  rows: Array<Row>;
  selection: {
    position: SelectionPosition;
    type: SelectionType;
  };
  onChange: any;
}): JSX.Element =>
  rows.length && [SelectionType.Multiple, SelectionType.Single].includes(selection.type) ? (
    <th
      className="cell-action"
      data-test-id={`header-selection-cell-${selection.position}-${selection.type}`.toLowerCase()}
    >
      {selection.type === SelectionType.Multiple && (
        <Checkbox checked={isChecked} onChange={onChange.global} />
      )}
    </th>
  ) : (
    <></>
  );

/**
 * @name maybeSetPageSize
 * @description Set the table page size (under certain conditions).
 *
 * @param hasRowsPerPage
 * @param hasPaginationBar
 * @param pageSize
 * @param rowsLength
 * @param setPageSize
 */
export const maybeSetPageSize = (
  hasRowsPerPage: boolean | undefined,
  hasPaginationBar: boolean | undefined,
  pageSize: number | undefined,
  rowsLength: number | undefined,
  setPageSize: (pageSize: number) => void,
) => {
  const storageTablePagination = Number(
    sessionStorage.getItem(SESSION_STORAGE.TABLE.PAGINATION.SIZE),
  );

  if (hasRowsPerPage && storageTablePagination) {
    setPageSize(storageTablePagination);
  } else if ((hasRowsPerPage && pageSize) || (hasPaginationBar && pageSize)) {
    setPageSize(pageSize);
  } else if (!hasPaginationBar && rowsLength) {
    setPageSize(rowsLength);
  }
};
