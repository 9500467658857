import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledRowDataProps as Props } from './definition';

const StyledRowData = styled.tr<Props>`
  ${(props) => css`
    width: 100%;
    background-color: ${props.theme.core.color.white};

    ${props.isDragging &&
    css`
      ${mixin.flex({ justifyContent: 'flex-start' })};

      td {
        flex-basis: 100%;
        background-color: ${props.theme.core.background.grey.medium};
      }
    `}
  `}
`;

export default StyledRowData;
