import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { ACTION_DIRECT_PARK } from '../../constants';

const schema = () =>
  Joi.object({
    action: Joi.string().valid(ACTION_DIRECT_PARK),
    custom_presence_id: Joi.boolean(),
    max_slot_number: Joi.number().min(0).required(),
    nodeId: Joi.string(),
    slots: Joi.object().allow({}),
  }).messages(messages());

export default schema;
