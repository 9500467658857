import { Box } from '@mui/material';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledIframeAppProps } from './definition';

export const StyledIframeApp = styled(Box)<StyledIframeAppProps>`
  ${({ $hasError, theme }) => css`
    height: 100%;
    ${$hasError === 'true' &&
    css`
      ${mixin.flex()};
      padding: ${theme.spacing(5)};
    `}
  `}
`;
