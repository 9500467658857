import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class ConferenceServiceActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.CONFERENCE_SERVICE,
      module: CallFlowModuleKey.CONFERENCE_SERVICE,
      section: CallFlowSection.CONFERENCE,
      hasDialog: false,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'conference-outlined',
      },
      label: i18next.t('phone_system:containers.callflows.actions.conference_service.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.conference_service.tooltip'),
    });
  }
}

export default ConferenceServiceActionConfig;
