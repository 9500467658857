import {
  FieldValueType,
  InputTemplate,
  SectionTemplate,
  SelectOptions,
} from 'apps/AdvancedProvisioner/shared/components/DynamicForm/definition';
import get from 'lodash/get';
import { FC } from 'react';
import { Cell, Column } from 'shared/components/Table';
import { TableCellSelectValue } from '../components/TableCellSelectValue';
import { TableCellText } from '../components/TableCellText';

const advancedProvisionerTableColumnsMap: Record<string, string[]> = {
  block_outs: ['rule.data.number', 'rule.data.lines'],
  codecs: ['audio.data.primary_codec', 'audio.data.secondary_codec'],
  combo_keys: ['key.data.type', 'key.data.value', 'key.data.label'],
  dialnows_replaces: ['dialnow_rule.data.rule', 'dialnow_rule.data.lines'],
  feature_keys: ['key.data.type', 'key.data.value'],
  fxs_ports: ['port.data.enable', 'port.data.display_name'],
  handsets: ['dial_out.data.default_line', 'dial_out.data.lines'],
  line_keys: ['key.data.value', 'key.data.label'],
  lines: ['basic.data.display_name', 'basic.data.enable'],
  profiles: ['basic.data.transport', 'basic.data.expire'],
  top_combo_keys: ['key.data.type', 'key.data.value', 'key.data.label'],
  bottom_combo_keys: ['key.data.type', 'key.data.value', 'key.data.label'],
  // Side Car 1-6
  ...new Array(6).fill(null).reduce((prev, _, i) => {
    prev[`sidecar_${i + 1}`] = ['key.data.type', 'key.data.account'];
    return prev;
  }, {}),
};

/**
 * @description Get the cell component for the given field type.
 * @param fieldType the type of the field (e.g., select, text, password)
 * @param options select options
 * @param inheritValue inherited value from the template
 * @param selectCell override the default SelectCell
 * @param textCell override the default TextCell
 * @returns the cell component
 */
export const getTableCell = (
  fieldType?: string,
  options?: SelectOptions,
  inheritValue?: FieldValueType,
  selectCell?: FC<Cell<JSX.Element>> | JSX.Element,
  textCell?: FC<Cell<JSX.Element>> | JSX.Element,
) => {
  switch (fieldType) {
    case 'select':
      return (
        selectCell ??
        ((props: Cell<JSX.Element>) => (
          <TableCellSelectValue {...props} options={options} inheritValue={inheritValue} />
        ))
      );
    default:
      return textCell ?? TableCellText;
  }
};

/**
 * @description Get the table columns needed to be displayed for the given tab key.
 * @param tabKey key of the tab
 * @param sectionTemplate section template data of the tab
 * @returns table columns
 * @note This utility currently is storing the map in {@link advancedProvisionerTableColumnsMap} variable. Ideally, in the future, it should be fetched from the backend.
 */
export const getAdvancedProvisionerTabTableColumns = (
  tabKey: string,
  sectionTemplate: SectionTemplate,
) =>
  advancedProvisionerTableColumnsMap[tabKey]?.reduce((prev, currColumnPath) => {
    const currColumn = (get(sectionTemplate, currColumnPath) as unknown) as InputTemplate;
    if (currColumn) {
      const column = {
        Header: currColumn.text,
        accessor: currColumnPath.replaceAll('data.', ''),
        Cell: getTableCell(currColumn.type, currColumn.options, currColumn.value),
      };
      prev.push(column);
    }
    return prev;
  }, [] as Column[]) || [];
