import { CallFlowActionInterface, CallFlowKey } from '../definition';
import { Metadata } from '../store/definition';
import AddAgentAvailability from './addAgentAvailability';
import AddApplicationVariable from './addApplicationVariables';
import AddCallflow from './addCallflow';
import CallFlowActionCollectDTMFAdd from './addCallsetting';
import addDirectInwardSystemsAccess from './addCarrier';
import CallFlowActionConferenceAdd from './addConference';
import CallFlowActionConferenceServiceAdd from './addConferenceService';
import AddDevice from './addDevice';
import AddDirectory from './addDirectory';
import DistinctiveRing from './addDistinctiveRing';
import AddFaxBoxes from './addFax';
import AddGroupPickup from './addGroupPickup';
import AddManualPresence from './addManualPresense';
import AddMedia from './addMedia';
import AddMenu from './addMenu';
import CallFlowActionPageGroupAdd from './addPageGroup';
import AddDirectPark from './addPark';
import AddPivot from './addPivot';
import AddResponse from './addResponse';
import AddRingGroup from './addRingGroup';
import SeatAdd from './addSeat';
import AddTimeOfDay from './addTimeOfDay';
import AddVoicemail from './addVoicemail';
import AddWebhook from './addWebhook';
import AgentLogin from './agentLogin';
import AgentLogout from './agentLogout';
import AgentPause from './agentPause';
import AgentSkills from './agentRequiredSkill';
import AgentResume from './agentResume';
import CallNotification from './callNotification';
import CallFlowActionLanguageAdd from './changeLanguage';
import checkVoicemail from './checkVoicemail';
import DisableCallForwarding from './disableCallForwarding';
import DisableTimeOfDay from './disableTimeOfDay';
import DynamicCallerId from './dynamicCallerId';
import EnableCallForwarding from './enableCallForwarding';
import EnableTimeOfDay from './enableTimeOfDay';
import MatchCallerID from './matchCallerId';
import PrependCallerId from './prependCallerId';
import Queue from './queue';
import QueueLogin from './queueLogin';
import QueueLogout from './queueLogout';
import ResetPrependCallerId from './resetPrependCallerId';
import ResetTimeOfDay from './resetTimeOfDay';
import SetCallerId from './setCallerId';
import SetCallPriority from './setCallPriority';
import StartCallRecording from './startCallRecording';
import StopCallRecording from './stopCallRecording';
import UpdateCallForwarding from './updateCallForwarding';
import AddWaitTime from './waitTime';

// Determines the order actions are printed
const CallFlowActionDictionary: Record<CallFlowKey, any> = {
  // Basic
  [CallFlowKey.SEAT]: SeatAdd,
  [CallFlowKey.CALLFLOW]: AddCallflow,
  [CallFlowKey.RING_GROUP]: AddRingGroup,
  [CallFlowKey.MEDIA]: AddMedia,
  [CallFlowKey.MENU]: AddMenu,
  [CallFlowKey.VOICEMAIL]: AddVoicemail,
  [CallFlowKey.DEVICE]: AddDevice,
  [CallFlowKey.DIRECTORY]: AddDirectory,
  // Advanced
  [CallFlowKey.PIVOT]: AddPivot,
  [CallFlowKey.RESPONSE]: AddResponse,
  [CallFlowKey.MANUAL_PRESENCE]: AddManualPresence,
  [CallFlowKey.GROUP_PICKUP]: AddGroupPickup,
  [CallFlowKey.PARK]: AddDirectPark,
  [CallFlowKey.WEBHOOK]: AddWebhook,
  [CallFlowKey.CHECK_VOICEMAIL]: checkVoicemail,
  [CallFlowKey.APPLICATION_VARIABLE]: AddApplicationVariable,
  [CallFlowKey.DISTINCTIVE_RING]: DistinctiveRing,
  // Time of Day
  [CallFlowKey.TIME_OF_DAY]: AddTimeOfDay,
  [CallFlowKey.TIME_OF_DAY_DISABLE]: DisableTimeOfDay,
  [CallFlowKey.TIME_OF_DAY_ENABLE]: EnableTimeOfDay,
  [CallFlowKey.TIME_OF_DAY_RESET]: ResetTimeOfDay,
  // Call Center
  [CallFlowKey.AGENT_AVAILABILITY]: AddAgentAvailability,
  [CallFlowKey.AGENT_PAUSE]: AgentPause,
  [CallFlowKey.AGENT_RESUME]: AgentResume,
  [CallFlowKey.AGENT_LOGIN]: AgentLogin,
  [CallFlowKey.AGENT_LOGOUT]: AgentLogout,
  [CallFlowKey.QUEUE]: Queue,
  [CallFlowKey.QUEUE_LOGIN]: QueueLogin,
  [CallFlowKey.QUEUE_LOGOUT]: QueueLogout,
  [CallFlowKey.AGENT_REQUIRED_SKILLS]: AgentSkills,
  [CallFlowKey.SET_CALL_PRIORITY]: SetCallPriority,
  [CallFlowKey.WAIT_TIME]: AddWaitTime,
  // Call Forwarding
  [CallFlowKey.CALL_FORWARDING_UPDATE]: UpdateCallForwarding,
  [CallFlowKey.CALL_FORWARDING_ENABLE]: EnableCallForwarding,
  [CallFlowKey.CALL_FORWARDING_DISABLE]: DisableCallForwarding,
  // Caller-ID
  [CallFlowKey.CALLER_ID_DYNAMIC]: DynamicCallerId,
  [CallFlowKey.CALLER_ID_PREPEND]: PrependCallerId,
  [CallFlowKey.CALLER_ID_RESET_PREPEND]: ResetPrependCallerId,
  [CallFlowKey.SET_CALLER_ID]: SetCallerId,
  [CallFlowKey.MATCH_CALLER_ID]: MatchCallerID,
  // Call Recordings
  [CallFlowKey.CALL_RECORDING_START]: StartCallRecording,
  [CallFlowKey.CALL_RECORDING_STOP]: StopCallRecording,
  // Call Settings
  [CallFlowKey.COLLECT_DTMF]: CallFlowActionCollectDTMFAdd,
  [CallFlowKey.CHANGE_LANGUAGE]: CallFlowActionLanguageAdd,
  [CallFlowKey.PAGE_GROUP]: CallFlowActionPageGroupAdd,
  [CallFlowKey.CALL_NOTIFICATION]: CallNotification,
  // Carrier
  [CallFlowKey.DISA]: addDirectInwardSystemsAccess,
  // Conference
  [CallFlowKey.CONFERENCE]: CallFlowActionConferenceAdd,
  [CallFlowKey.CONFERENCE_SERVICE]: CallFlowActionConferenceServiceAdd,
  // Fax
  [CallFlowKey.FAX_BOX]: AddFaxBoxes,
};

export const getCallflowActionConfig = (actionName: CallFlowKey) => {
  if (actionName && CallFlowActionDictionary[actionName]) {
    return { ...new CallFlowActionDictionary[actionName]() };
  }
  return null;
};

export const getSubtitle = (actionName: CallFlowKey, nodeData: any, metadata: Metadata) => {
  if (CallFlowActionDictionary[actionName] && CallFlowActionDictionary[actionName].subtitle) {
    return CallFlowActionDictionary[actionName].subtitle(nodeData, metadata);
  }
  return '';
};

export const getAllCallflowActionConfigs = () => {
  let configs: CallFlowActionInterface[] = [];

  for (let config in CallFlowActionDictionary) {
    configs.push(getCallflowActionConfig(config as CallFlowKey));
  }

  return configs;
};

export default CallFlowActionDictionary;
