import { Reducer } from 'react';
import { Action, State } from './definition';

export const initialState: State = {
  isAddNumberDialogOpen: false,
  isLoadingDialogOpen: false,
  isSummaryNumberDialogOpen: false,
};

export const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'OPEN_ADD_NUMBER_DIALOG':
      return {
        ...state,
        isAddNumberDialogOpen: true,
        isLoadingDialogOpen: false,
        isSummaryNumberDialogOpen: false,
      };
    case 'OPEN_LOADING_DIALOG':
      return {
        ...state,
        isAddNumberDialogOpen: false,
        isLoadingDialogOpen: true,
        isSummaryNumberDialogOpen: false,
      };
    case 'OPEN_SUMMARY_NUMBER_DIALOG':
      return {
        ...state,
        isAddNumberDialogOpen: false,
        isLoadingDialogOpen: false,
        isSummaryNumberDialogOpen: true,
      };
    default:
      return state;
  }
};
