import { Box } from '@mui/material';
import { RouterLink } from 'apps/shared/components/RouterLink';
import { FunctionComponent } from 'react';
import AppIcon from '../AppIcon';
import defaultProps from './default';
import { AppLinkProps as Props } from './definition';
import StyledAppLink from './style';

const AppLink: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasMargin,
    meta: {
      icon,
      i18n: { label },
      slug,
      url,
    },
  } = { ...defaultProps, ...props };

  const app = () => (
    <>
      {!!icon && <AppIcon name={icon} />}
      <Box component="span">{label}</Box>
    </>
  );

  // TODO: Change <a> to Link component and resolve styling

  return (
    <StyledAppLink hasMargin={hasMargin}>
      {url ? (
        <a href={url} rel="noopener noreferrer" target="_blank">
          {app()}
        </a>
      ) : (
        <RouterLink to={`/apps/${slug}`}>{app()}</RouterLink>
      )}
    </StyledAppLink>
  );
};

export default AppLink;
