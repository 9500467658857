import { HEADING_CONSTANTS } from 'apps/PhoneSystem/containers/Account/components/Heading';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled, { css } from 'styled-components';
import { ACTION_CONTAINER_CONSTANTS } from '../..';
import { INTERACTION_BAR_CONSTANTS } from '../../../InteractionBar';
import { StyledSectionsProps as Props } from './definition';

const StyledSections = styled.div<Props>`
  ${(props) => css`
    width: 100%;
    max-height: ${`calc(100vh - (${HEADING_CONSTANTS.HEIGHT} + ${
      ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT
    } + ${INTERACTION_BAR_CONSTANTS.HEIGHT.DEFAULT}px + ${
      ACTION_CONTAINER_CONSTANTS.HEIGHT.HEADING
    } + ${ACTION_CONTAINER_CONSTANTS.HEIGHT.SEARCH} + ${
      ACTION_CONTAINER_CONSTANTS.BORDER * 2
    }px + ${props.theme.spacing(10)}))`};
    overflow: ${props.isDragging ? 'hidden' : 'auto'};
  `};
`;

export default StyledSections;
