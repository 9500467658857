import * as utility from 'shared/utility';
import { WidthType } from 'shared/utility/definition';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import theme from 'theme/mui/core';
import { StyledDialogLinkProps as Props } from './definition';

const CONSTANTS = {
  TRANSLATE: {
    X: theme.spacing(0.5),
    Y: theme.spacing(-3.33),
  },
};

const StyledDialogLink = styled.div<Props>`
  ${({ hasMargin, isBelow, isDisabled, indentWidth, theme }) => css`
    ${mixin.flex({ isInline: true, justifyContent: 'flex-start' })};
    ${mixin.font({ weight: 600 })};
    ${mixin.transition({ property: 'color' })};
    margin: ${theme.spacing(hasMargin ? 4 : 0)} 0 ${theme.spacing(3)}
      ${utility.getWidth({
        hasMargin: true,
        width: { input: 'none', label: indentWidth as WidthType },
        type: 'label',
      })};
    transform: translateX(${CONSTANTS.TRANSLATE.X});

    ${isBelow &&
    css`
      transform: translate(${CONSTANTS.TRANSLATE.X}, ${CONSTANTS.TRANSLATE.Y});
    `}

    ${isDisabled
      ? css`
          cursor: default;

          span {
            color: ${theme.brio.action.disabled};
          }

          svg {
            fill: ${theme.brio.action.disabled};
          }
        `
      : css`
          cursor: pointer;

          &:hover {
            span {
              color: ${theme.core.color.blue.draggable};
            }

            svg {
              fill: ${theme.core.color.blue.draggable};
            }
          }
        `}

    span {
      ${mixin.transition({ property: 'color' })};
      margin: ${theme.spacing(0, 0, 0, 1)};
      user-select: none;
    }

    svg {
      ${mixin.transition({ property: 'fill' })};
    }
  `}
`;

export default StyledDialogLink;
