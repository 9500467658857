import { FormContext } from 'apps/shared/components/FormContext';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LabeledCheckbox from 'shared/components/Labeled/components/LabeledCheckbox';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormFields, FormInput } from '../../definition';
import { DeviceFormFieldsGenerator } from '../../utility';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  FormFields.ContactListExclude,
  FormFields.ExcludeFromQueues,
  FormFields.IgnoreCompletedElsewhere,
  FormFields.MediaFaxOption,
  FormFields.RingtonesExternal,
  FormFields.RingtonesInternal,
];

const OptionsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const contextName = useContext(FormContext);
  const watchDeviceType = watch(`${contextName}device_type`);

  return (
    <>
      <div role="row">
        {/* Internal Ringtones */}
        {DeviceFormFieldsGenerator.shouldGenerate(
          FormFields.RingtonesInternal,
          watchDeviceType,
        ) && (
          <div role="cell">
            <HookFormInputWrapper name={FormFields.RingtonesInternal}>
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  isDirty={isDirty}
                  feedback={feedback}
                  inputProps={{
                    ...formProps,
                    id: 'input-options-internal-ringtones',
                  }}
                  label={t('phone_system:containers.devices.section.option.internal_ringtones')}
                />
              )}
            </HookFormInputWrapper>
          </div>
        )}

        {/* External Ringtones */}
        {DeviceFormFieldsGenerator.shouldGenerate(
          FormFields.RingtonesExternal,
          watchDeviceType,
        ) && (
          <div role="cell">
            <HookFormInputWrapper name={FormFields.RingtonesExternal}>
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  isDirty={isDirty}
                  feedback={feedback}
                  inputProps={{
                    ...formProps,
                    id: 'input-options-external-ringtones',
                  }}
                  label={t('phone_system:containers.devices.section.option.external_ringtones')}
                />
              )}
            </HookFormInputWrapper>
          </div>
        )}
      </div>

      <div role="row" className="one-column is-stack">
        <div role="cell">
          {/* Require Key Press */}
          {DeviceFormFieldsGenerator.shouldGenerate(
            FormFields.CallForwardKeepCallerId,
            watchDeviceType,
          ) && (
            <HookFormInputWrapper name={FormFields.CallForwardKeepCallerId} isCheckbox>
              {({ ref, isDirty, ...formProps }) => {
                return (
                  <LabeledCheckbox
                    isDirty={isDirty}
                    checkboxProps={{
                      ...formProps,
                      id: 'checkbox-options-keep-caller-id',
                    }}
                    label={t('phone_system:containers.devices.section.option.keep_caller_id.label')}
                    tooltip={t(
                      'phone_system:containers.devices.section.option.keep_caller_id.tooltip',
                    )}
                  />
                );
              }}
            </HookFormInputWrapper>
          )}

          {/* Keep Caller ID */}
          {DeviceFormFieldsGenerator.shouldGenerate(
            FormFields.CallForwardRequireKeypress,
            watchDeviceType,
          ) && (
            <HookFormInputWrapper name={FormFields.CallForwardRequireKeypress} isCheckbox>
              {({ ref, isDirty, ...formProps }) => {
                return (
                  <LabeledCheckbox
                    isDirty={isDirty}
                    checkboxProps={{
                      ...formProps,
                      id: 'checkbox-options-require-keypress',
                    }}
                    label={t(
                      'phone_system:containers.devices.section.option.require_keypress.label',
                    )}
                    tooltip={t(
                      'phone_system:containers.devices.section.option.require_keypress.tooltip',
                    )}
                  />
                );
              }}
            </HookFormInputWrapper>
          )}

          {/* T.38 */}
          {DeviceFormFieldsGenerator.shouldGenerate(FormFields.MediaFaxOption, watchDeviceType) && (
            <HookFormInputWrapper name={FormFields.MediaFaxOption} isCheckbox>
              {({ ref, isDirty, ...formProps }) => {
                return (
                  <LabeledCheckbox
                    isDirty={isDirty}
                    checkboxProps={{
                      ...formProps,
                      id: 'checkbox-options-T38',
                    }}
                    label={t('phone_system:containers.devices.section.option.t_38')}
                  />
                );
              }}
            </HookFormInputWrapper>
          )}

          {/* Hide from Contact List */}
          <HookFormInputWrapper name="contact_list.exclude" isCheckbox>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                  id: 'checkbox-options-hide-contact-list',
                }}
                label={t('phone_system:containers.devices.section.option.hide_from_contact_list')}
              />
            )}
          </HookFormInputWrapper>

          {/* Ignore Complete Elsewhere */}
          {DeviceFormFieldsGenerator.shouldGenerate(
            FormFields.IgnoreCompletedElsewhere,
            watchDeviceType,
          ) && (
            <HookFormInputWrapper name={FormFields.IgnoreCompletedElsewhere} isCheckbox>
              {({ ref, isDirty, ...formProps }) => (
                <LabeledCheckbox
                  isDirty={isDirty}
                  checkboxProps={{
                    ...formProps,
                    id: 'checkbox-options-ignore-complete-elsewhere',
                  }}
                  label={t(
                    'phone_system:containers.devices.section.option.ignore_complete_elsewhere',
                  )}
                />
              )}
            </HookFormInputWrapper>
          )}

          {/* Exclude from Queues */}
          {DeviceFormFieldsGenerator.shouldGenerate(
            FormFields.ExcludeFromQueues,
            watchDeviceType,
          ) && (
            <HookFormInputWrapper name={FormFields.ExcludeFromQueues} isCheckbox>
              {({ ref, isDirty, ...formProps }) => (
                <LabeledCheckbox
                  isDirty={isDirty}
                  checkboxProps={{
                    ...formProps,
                    id: 'checkbox-options-excluded-queues',
                  }}
                  label={t('phone_system:containers.devices.section.option.exclude_from_queues')}
                />
              )}
            </HookFormInputWrapper>
          )}
        </div>
      </div>
    </>
  );
};

export default OptionsSection;
