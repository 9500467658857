const defaultProps = {
  data: {
    columns: [],
    rows: [],
  },
  dataTestId: 'shared-editablelist',
  onUpdate: () => {},
};

export default defaultProps;
