import { DatePicker, DatePickerProps, TimePicker, TimePickerProps } from 'shared/components/Picker';
import * as utility from 'shared/utility';
import Labeled from '../..';
import StyledLabeled from '../../style';
import defaultProps from './default';
import { LabeledPickerProps as Props } from './definition';

const LabeledPicker = (props: Props) => {
  const {
    id,
    type,
    hasDirtyIcon,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    pickerWidth,
    datePickerProps,
    timePickerProps,
    feedback,
    tooltip,
    tooltipWidth,
  }: Props = {
    ...defaultProps,
    ...props,
  };

  const forId = id ?? utility.generateId(`picker-${type}`);

  return (
    <Labeled
      feedback={feedback}
      hasDirtyIcon={hasDirtyIcon}
      id={forId}
      inputWidth={pickerWidth}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={labelProps}
      labelWidth={labelWidth}
      styledComponent={StyledLabeled}
      tooltip={tooltip}
      tooltipWidth={tooltipWidth}
      type="picker"
    >
      {type === 'date' ? (
        <DatePicker id={forId} width={pickerWidth} {...(datePickerProps as DatePickerProps)} />
      ) : (
        <TimePicker id={forId} width={pickerWidth} {...(timePickerProps as TimePickerProps)} />
      )}
    </Labeled>
  );
};

export default LabeledPicker;
