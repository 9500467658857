import { ChangeEvent, memo } from 'react';
import Pagination from 'shared/components/Pagination';
import TEST_ID from 'shared/utility/testing/constants/testId';
import defaultProps from './default';
import { PaginationBarProps as Props } from './definition';

const PaginationBar = memo((props: Props) => {
  const {
    data: { pageCount, pageIndex, onClick },
  } = { ...defaultProps, ...props };

  return (
    <Pagination
      count={pageCount}
      data-test-id={TEST_ID.COMMON.SHARED.TABLE.PAGINATION.PAGINATION_BAR}
      page={pageIndex + 1}
      onChange={(e: ChangeEvent<unknown>, pageNumber: number) => onClick(pageNumber - 1)}
    />
  );
});

export default PaginationBar;
