const defaultProps = {
  options: {
    hasBottomMargin: false,
    hasMenuOffset: false,
    hasScroll: true,
    isCentered: true,
    control: {},
  },
};

export default defaultProps;
