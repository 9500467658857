// Account/index.ts
import { JOI } from 'constant';
import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { accountMutations } from './Mutations';
import { accountQueries } from './Queries';

export const schema: { [key: string]: any } = {
  account_type: Joi.string().required(),
  name: Joi.string().required(),
  realm: Joi.string().max(36).required(),
};

export const settingsSchema = () =>
  Joi.object({
    max_connect_failures: Joi.number()
      .required()
      .integer()
      .messages({
        'number.base': i18next.t('common:validation.general.required'),
      }),
    caller_id: Joi.object({
      internal: Joi.object({
        name: Joi.string().max(35).empty(''),
        number: Joi.string().max(35).empty(''),
      }).unknown(true),
      external: Joi.object({
        name: Joi.string().max(35).required(),
        number: Joi.string().max(35).required(),
      }).unknown(true),
      emergency: Joi.object({
        name: Joi.string().max(35).required(),
        number: Joi.string().max(35).required(),
      }).unknown(true),
    }).unknown(true),
    format_from_uri: Joi.boolean(),
    music_on_hold: Joi.object({
      media_id: Joi.string().max(2048).allow(''),
    }).unknown(true),
    contact: Joi.object({
      billing: Joi.object({
        name: Joi.string().empty(''),
        email: Joi.string().email(JOI.EMAIL).empty(''),
        number: Joi.string().empty(''),
      }).unknown(true),
      technical: Joi.object({
        same_as_billing: Joi.boolean(),
        name: Joi.string().empty(''),
        email: Joi.string().email(JOI.EMAIL).empty(''),
        number: Joi.string().empty(''),
      }).unknown(true),
    }).unknown(true),
    screen_pop_enabled: Joi.boolean(),
    screen_pop_platforms: Joi.array(),
  })
    .messages(
      messages({
        'string.email': i18next.t('common:validation.string.email_address_no_field'),
      }),
    )
    .unknown(true);

export const callerInfoMatchSchema = () =>
  Joi.object({
    display_name: Joi.string().required(),
    rule_name: Joi.string().required(),
    search_url: Joi.string()
      .required()
      .pattern(/^(?!.*(<organization number>|<instance name>))/),
    test_url: Joi.string().required(),
    id: Joi.string().allow(''),
    newId: Joi.string().allow(''),
  })
    .messages(
      messages({
        'string.pattern.base': i18next.t('common:validation.string.pattern_base_2'),
      }),
    )
    .unknown(true);

export { accountSlice } from './slice';

export const {
  useFetchAccountAncestorsByIdQuery,
  useFetchAccountBillingByIdQuery,
  useFetchAccountByIdQuery,
  useFetchAccountCallerInfoMatchQuery,
  useFetchAccountChildrenByIdQuery,
  useFetchAccountChildrenQuery,
  useFetchAccountConfigsQuery,
  useFetchAccountDescendantsByIdQuery,
  useFetchAccountDesktopAppZoneQuery,
  useFetchAccountExternalAppsQuery,
  useFetchAccountHeroAppsQuery,
  useFetchAccountQuery,
  useFetchAccountSeatCountQuery,
  useFetchAccountSiteLocationUsersQuery,
  useFetchAccountsQuery,
  useFetchClusterByIdQuery,
  useFetchRootAccountQuery,
  useLazyFetchAccountQuery,
  useSearchAccountQuery,
} = accountQueries;

export const {
  useCreateAccountByIdMutation,
  useDeleteAccountByIdMutation,
  useDemoteAsResellerByAccountIdMutation,
  usePatchAccountByIdMutation,
  usePromoteToResellerByAccountIdMutation,
  useUpdateAccountByIdMutation,
  useUpdateAccountCallerInfoMatchByIdMutation,
  useUpdateAccountDesktopAppZoneByIdMutation,
  useUpdateAccountHeroAppsByIdMutation,
} = accountMutations;
