import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import defaultProps from './default';
import { IconLinkProps as Props } from './definition';
import StyledIconLink from './style';

const IconLink: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isIconDisabled, isIconRotated, label, onClick }: Props = {
    ...defaultProps,
    ...props,
  };

  return (
    <StyledIconLink isIconDisabled={isIconDisabled} isIconRotated={isIconRotated}>
      <span onClick={() => onClick?.label?.()}>{label}</span>
      <span onClick={() => !isIconDisabled && onClick?.icon?.()}>
        <Icon name="chevron-down" size={14} subset={16} />
      </span>
    </StyledIconLink>
  );
};

export default IconLink;
