import Apps from 'apps';
import Login from 'apps/shared/components/Auth/components/Login';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import PrivateRoute from 'shared/components/PrivateRoute';

/**
 * @name getRouter
 * @description Build the route component.
 *
 * @returns Route component.
 */
export const getRouter = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/apps/*" element={<PrivateRoute />}>
          <Route path="*" element={<Apps />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate replace to="/apps" />} />
      </Route>,
    ),
  );
