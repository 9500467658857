import i18next from 'i18next';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { defaultDeviceValues } from '../default';
import { DeviceValues, FormFields } from '../definition';

/**
 * @name getFamiliesList
 * @description Parse the families object into an array of select option objects.
 *
 * @param data
 *
 * @returns Families listing.
 */
export const getFamiliesList = (data: any) =>
  data
    ? Object.keys(data).map((label: string) => ({
        label,
        options: Object.values(data[label].models)
          .map(({ name, id }: any) => ({
            label: name.toString(),
            value: id,
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label)),
      }))
    : [];

/**
 * @name getImagePath
 * @description Get path the brand/model image.
 *
 * @param name
 * @param isModel
 *
 * @returns Image path.
 */
export const getImagePath = (name: string, isModel?: boolean) =>
  `/images/device/${isModel ? `model/${name}.jpg` : `brand/${name}.png`}`;

/**
 * @name getModelValue
 * @description Build the (form field) device model value.
 *
 * @param [object]
 * @property data
 * @property hasEndpointProperties
 *
 * @returns Model value
 */
export const getModelValue = ({
  hasEndpointProperties = false,
  data,
}: {
  hasEndpointProperties?: boolean;
  data: DeviceValues | any;
}): string | undefined => {
  if (isEmpty(data)) {
    return undefined;
  }

  const getValue = (brand: string, family: string, model: string) =>
    brand && family && model ? `${brand}_${family}_${model}` : undefined;

  return hasEndpointProperties
    ? getValue(data.endpoint_brand, data.endpoint_family, data.endpoint_model)
    : getValue(data.brand, data.family, data.model);
};

/**
 * @name getOptionNotSet
 * @description Get "Not Set" option for use in Brands/Makes dropdown.
 *
 * @returns Not Set option object.
 */
export const getOptionNotSet = () => ({
  label: i18next.t('common:not_set'),
  value: 'not_set',
});

/**
 * @name getProvisionValues
 * @description Set the provisioner endpoint form values (brand, family, model);
 *              the device value MAY consist of `brand_model` OR `brand_family_model`,
 *              so we're going to play it safe and split the value using the separator
 *              (underscore) and count the number of values.
 *
 * @property value
 *
 * @returns Provision object.
 */
export const getProvisionValues = (value?: string) => {
  let { brand, family, model }: DeviceValues = defaultDeviceValues;

  if (typeof value === 'string') {
    const values = value.split('_');
    switch (values.length) {
      case 2:
        [brand, model] = values;
        break;
      case 3:
        [brand, family, model] = values;
        break;
      default:
        break;
    }
  }

  return { brand, family, model };
};

/**
 * @name getIsModelDirty
 * @description Determine if the model value is dirty.
 *
 * @param device
 *
 * @returns True or false.
 */
export const getIsModelDirty = (device: DeviceValues) =>
  device.brand === getOptionNotSet().value || Boolean(device.model);

/**
 * @name initDeviceValues
 * @description Get an initial provision (state) object.
 *
 * @returns Provision object.
 */
export const initDeviceValues = () => ({
  brand: getOptionNotSet().value,
  family: undefined,
  model: undefined,
});

/**
 * @name initFormFieldValues
 * @description Get the initial form field values for `provision`,
 *              `_temp_device_brand`, and `_temp_device_model`.
 *
 * @returns Form field object.
 */
export const initFormFieldValues = (data: any) => ({
  [FormFields.Provision]: {
    endpoint_brand: data.provision?.endpoint_brand ?? getOptionNotSet().value,
    endpoint_family: data.provision?.endpoint_family ?? undefined,
    endpoint_model: data.provision?.endpoint_model ?? undefined,
  },
  [FormFields.TempDeviceBrand]: data.provision?.endpoint_brand ?? getOptionNotSet().value,
  [FormFields.TempDeviceModel]: getModelValue({
    hasEndpointProperties: true,
    data: data.provision,
  }),
});

/**
 * @name createStateSetter
 * @description Update setBrands / setFamilies state variables.
 *
 * @param [object]
 * @property dialogValues
 * @property setBrands
 * @property setFamilies
 *
 * @param data
 */
export const createBrandStateSetter = ({
  dialogValues,
  setBrands,
  setFamilies,
}: {
  dialogValues: any;
  setBrands: any;
  setFamilies: any;
}) => (data: any) => {
  setBrands([
    getOptionNotSet(),
    ...Object.values(data)
      .map(({ name: label, id: value }: any) => ({
        image: getImagePath(value),
        label: capitalize(label),
        value,
      }))
      .sort((a: any, b: any) => (a.label > b.label ? 1 : -1)),
  ]);

  if (!isEmpty(dialogValues.brand) && dialogValues.brand !== getOptionNotSet().value) {
    setFamilies(getFamiliesList(data[dialogValues.brand as string]?.families ?? {}));
  }
};

/**
 * @name setProvisionValues
 * @description Set the provision device state values.
 *
 * @param [object]
 * @property hasTempDeviceModel
 * @property isModelDirty
 * @property contextName
 * @property deviceValues
 * @property setValue
 */
export const setProvisionValues = ({
  hasTempDeviceModel = false,
  isModelDirty = false,
  contextName,
  deviceValues,
  setValue,
}: {
  hasTempDeviceModel?: boolean;
  isModelDirty?: boolean;
  contextName: string;
  deviceValues: DeviceValues;
  setValue: (name: string, value?: string, options?: Record<string, boolean>) => void;
}): void => {
  const { brand, family, model } = deviceValues;
  const options = { shouldDirty: isModelDirty };

  setValue(`${contextName}${FormFields.ProvisionEndpointBrand}`, brand);
  setValue(
    `${contextName}${FormFields.ProvisionEndpointFamily}`,
    brand === getOptionNotSet().value ? undefined : family,
  );
  setValue(
    `${contextName}${FormFields.ProvisionEndpointModel}`,
    brand === getOptionNotSet().value ? undefined : model,
    options,
  );

  if (hasTempDeviceModel) {
    setValue(
      `${contextName}${FormFields.TempDeviceModel}`,
      getModelValue({ data: deviceValues }),
      options,
    );
  }
};
