import { useLocation } from 'react-router-dom';

const useHasActionRow = () => {
  const { pathname } = useLocation();
  const PATH_PREFIX = '/apps/call-center/';

  let hasActionRow = true;
  let hasHeightStyling = true;

  if (
    pathname === `${PATH_PREFIX}dashboard` ||
    pathname.match(new RegExp(`^${PATH_PREFIX}manage-queues/[a-z0-9]+$`, 'g')) ||
    pathname.match(new RegExp(`^${PATH_PREFIX}manage-queues/agents/[a-z0-9]+/[a-z0-9]+$`, 'g'))
  ) {
    hasActionRow = false;
  }

  if (pathname === `${PATH_PREFIX}manage-queues`) {
    hasHeightStyling = false;
  }

  return { hasActionRow, hasHeightStyling };
};

export default useHasActionRow;
