export enum field {
  agentWrapupTime = 'agent_wrapup_time',
  announcementsInterval = 'announcements.interval',
  callerExitKey = 'caller_exit_key',
  callRecordingUrl = 'call_recording_url',
  connectionTimeout = 'connection_timeout',
  enterWhenEmpty = 'enter_when_empty',
  hideInDashboard = 'hide_in_dashboard',
  maxQueueSize = 'max_queue_size',
  moh = 'moh',
  name = 'name',
  positionAnnouncementsEnabled = 'announcements.position_announcements_enabled',
  recordCaller = 'record_caller',
  sendWaitTime = 'send_wait_time',
  strategy = 'strategy',
  waitTimeAnnouncementsEnabled = 'announcements.wait_time_announcements_enabled',
}
