import usePersistentToggle from 'apps/CallCenter/shared/hooks/usePersistentToggle';
import { ContainerMeta } from 'apps/shared/definition';
import useCurrentAccountId from 'apps/shared/hooks/useCurrentAccountId';
import { LOCAL_STORAGE } from 'constant';
import { useFetchAgentsQuery, useFetchAgentsStatusQuery } from 'models/Agents';
import { useFetchQueueQuery, useFetchQueuesStatsSummaryQuery } from 'models/Queues';
import { FunctionComponent } from 'react';
import Loading from 'shared/components/Loading';
import DashboardAgents from './components/DashboardAgents';
import DashboardIncomingCalls from './components/DashboardIncomingCalls';
import DashboardQueues from './components/DashboardQueues';
import DashboardQueuesToggle from './components/DashboardQueuesToggle';
import CONSTANTS from './constants';
import { useSelectedQueueId } from './hooks/useSelectedQueueId';
import StyledDashboard from './style';
import { getQueuesSummary, prepareQueuesDataForDashboard } from './utility';

export const meta: ContainerMeta = {
  // t('call_center:containers.dashboard.label')
  icon: 'dashboard',
  label: 'call_center:containers.dashboard.label',
  slug: 'dashboard',
};

const Dashboard: FunctionComponent = (): JSX.Element => {
  const { data: agentsData, isLoading: isLoadingAgents } = useFetchAgentsQuery();
  const { data: agentsStatusData, isLoading: isLoadingAgentsStatus } = useFetchAgentsStatusQuery(
    undefined,
    CONSTANTS.QUERY.OPTIONS,
  );
  const { data: queuesData, isLoading: isLoadingQueues } = useFetchQueueQuery();
  const {
    data: queuesSummaryData,
    isLoading: isLoadingQueuesSummary,
  } = useFetchQueuesStatsSummaryQuery(undefined, CONSTANTS.QUERY.OPTIONS);

  const accountId = useCurrentAccountId();
  const { selectedQueueId, setSelectedQueueId } = useSelectedQueueId(accountId);

  const queue = queuesData?.find(({ id }: any) => id === selectedQueueId);

  const { toggle: hasQueues, onClick: onHideQueuesClick } = usePersistentToggle({
    storageKey: LOCAL_STORAGE.SHOW.QUEUES,
  });

  const queuesList =
    agentsData &&
    queuesData &&
    agentsStatusData &&
    queuesSummaryData &&
    prepareQueuesDataForDashboard(agentsData, agentsStatusData, queuesData, queuesSummaryData);

  const allQueuesSummary =
    agentsData &&
    agentsStatusData &&
    queuesSummaryData &&
    getQueuesSummary(agentsData, agentsStatusData, queuesSummaryData);

  if (isLoadingQueues || isLoadingAgents || isLoadingAgentsStatus || isLoadingQueuesSummary) {
    return <Loading />;
  }

  return (
    <StyledDashboard hasqueues={hasQueues?.toString()} data-test-id="call-center-dashboard">
      <DashboardQueues
        allQueuesSummary={allQueuesSummary}
        queuesList={queuesList}
        selectedQueueId={selectedQueueId}
        setSelectedQueueId={setSelectedQueueId}
      />
      <DashboardQueuesToggle hasQueues={hasQueues} onClick={onHideQueuesClick} />
      <DashboardIncomingCalls
        queuesIncomingCalls={queuesSummaryData?.Waiting}
        selectedQueueId={selectedQueueId}
      />
      <DashboardAgents
        agents={agentsData}
        agentsStatus={agentsStatusData}
        queue={queue}
        queuesSummary={queuesSummaryData}
      />
    </StyledDashboard>
  );
};

export default Dashboard;
