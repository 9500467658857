const defaultProps = {
  filter: {
    hasFilter: false,
    hasFilterValues: {
      applied: false,
      selected: false,
    },
    isDialogOpen: false,
    dialogContent: <></>,
    filterValues: {
      applied: {},
      selected: {},
    },
    onApply: () => {},
    onClose: () => {},
    onReset: () => {},
    setIsDialogOpen: () => {},
  },
};

export default defaultProps;
