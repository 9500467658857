import Joi from 'joi';
import { FormFields } from '../../definition';

export const schema = () => ({
  [FormFields.AddressCity]: Joi.string().required(),
  [FormFields.AddressCountry]: Joi.string().required(),
  [FormFields.AddressLine1]: Joi.string().required(),
  [FormFields.AddressLine2]: Joi.string().allow(''),
  [FormFields.AddressPostalCode]: Joi.string().required(),
  [FormFields.AddressState]: Joi.string().max(2).required(),
  [FormFields.DisplayName]: Joi.string().required(),
});
