// Callflow Mutations
import { Callflow } from 'api/callflow';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const callflowMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createCallflow: builder.mutation<Callflow, Partial<Callflow>>({
      query: ({ body }) => ({
        url: Callflow.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [
        { type: TAGS.CALLFLOW, id: 'LIST' },
        { type: TAGS.EXTENSION, id: 'LIST' },
        { type: TAGS.FEATURECODE, id: 'LIST' },
        { type: TAGS.PHONENUMBER, id: 'LIST' },
      ],
    }),
    createByAccountId: builder.mutation<Callflow, Partial<Callflow>>({
      query: ({ accountId, body }) => ({
        url: Callflow.CreateByAccountId(accountId),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [
        { type: TAGS.CALLFLOW, id: 'LIST' },
        { type: TAGS.EXTENSION, id: 'LIST' },
        { type: TAGS.FEATURECODE, id: 'LIST' },
        { type: TAGS.PHONENUMBER, id: 'LIST' },
      ],
    }),
    updateCallflow: builder.mutation({
      query: ({ id, body }) => ({
        url: Callflow.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.CALLFLOW, id },
        { type: TAGS.EXTENSION, id: 'LIST' },
        { type: TAGS.FEATURECODE, id: 'LIST' },
        { type: TAGS.PHONENUMBER, id: 'LIST' },
      ],
    }),
    patchCallflow: builder.mutation({
      query: ({ id, body }) => ({
        url: Callflow.Update(id),
        method: RequestMethod.Patch,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.CALLFLOW, id },
        { type: TAGS.EXTENSION, id: 'LIST' },
        { type: TAGS.FEATURECODE, id: 'LIST' },
        { type: TAGS.PHONENUMBER, id: 'LIST' },
      ],
    }),
    deleteCallflow: builder.mutation<Callflow, ByIdParam>({
      query: ({ id }) => ({
        url: Callflow.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: [
        { type: TAGS.CALLFLOW, id: 'LIST' },
        { type: TAGS.EXTENSION, id: 'LIST' },
        { type: TAGS.FEATURECODE, id: 'LIST' },
      ],
    }),
  }),
});
