import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { CONSTANTS } from './constant';
import { StyledAppLinkProps as Props } from './definition';

const StyledAppLink = styled(Box)<Props>`
  ${({ $isCurrent, theme }) => css`
    ${mixin.flex({ direction: 'column' })};
    ${mixin.font({ weight: 400 })};
    width: ${CONSTANTS.WIDTH};
    padding: ${theme.spacing(0, 1)};
    border-bottom: none;
    cursor: pointer;
    text-decoration: none;

    > div:last-of-type:not(:first-of-type),
    > span {
      margin: ${theme.spacing(1.33, 0, 0)};
    }

    span {
      display: inline-block;
      ${mixin.transition({ property: 'color' })};
      color: ${theme.core.color.black};
      text-align: center;

      ${$isCurrent &&
      css`
        ${mixin.hover()};
      `};
    }

    &:hover > span {
      ${mixin.hover()};
      color: ${theme.whitelabel.primary.color};
    }
  `}
`;

export default StyledAppLink;
