import { EndpointItemType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import { getEndpointIcon } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import Icon from 'shared/components/Icon';
import Input from 'shared/components/Input';
import { defaultValues } from '../default';

export const columns = (control: any, columns: any, endpointsFieldArray: any) => [
  {
    accessor: 'name',
    Header: columns.name.header,
    Cell: ({ row: { original } }: EndpointItemType) => (
      <TableCellTruncatedValue
        icon={getEndpointIcon(original.endpoint_type)}
        text={original.name}
      />
    ),
  },
  {
    accessor: 'delay',
    Header: columns.delay.header,
    Cell: ({ row: { index } }: { row: { index: number } }) => (
      <HookFormInputWrapper name={`endpoints.${index}.delay`} control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <Input
            feedback={feedback}
            inputProps={{ ...formProps, id: 'input-ring-group-selected-delays' }}
            type="number"
            width="xsmall"
          />
        )}
      </HookFormInputWrapper>
    ),
  },
  {
    accessor: 'timeout',
    Header: columns.timeout.header,
    Cell: ({ row: { index } }: { row: { index: number } }) => (
      <HookFormInputWrapper name={`endpoints.${index}.timeout`} control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <Input
            feedback={feedback}
            inputProps={{ ...formProps, id: 'input-ring-group-selected-timeouts' }}
            type="number"
            width="xsmall"
          />
        )}
      </HookFormInputWrapper>
    ),
  },
  {
    accessor: 'id',
    Header: '',
    Cell: ({ row: { index } }: { row: { index: number } }) => (
      <Icon
        hasHover
        name="minus-circle-outlined"
        size={22}
        themeColor="core.states.error.main"
        onClick={endpointsFieldArray.remove.bind(null, index)}
      />
    ),
  },
];

export const getLargestTimeout = (endpoints: Array<GroupEndpoint> | undefined = []) => {
  if (!endpoints.length) {
    return defaultValues.timeout;
  }

  const sortedTimeouts = [...endpoints].sort((a, b) => parseInt(a.timeout) - parseInt(b.timeout));
  return `${sortedTimeouts[sortedTimeouts.length - 1].timeout}`;
};
