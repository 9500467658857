// needed for TypeScript to recognize global `window.nemo` object
declare global {
  interface Window {
    nemo: any;
    newrelic: any;
  }
}

export enum AsyncStatus {
  Failed,
  Idle,
  Loading,
  Succeeded,
}

export enum CallRestrictionOptionType {
  Allow = 'allow',
  Deny = 'deny',
  Inherit = 'inherit',
}

export enum ClassifierSet {
  Caribbean = 'caribbean',
  DidUs = 'did_us',
  Emergency = 'emergency',
  EmergencyTest = 'emergency_test',
  EnterpriseAnswer = 'enterprise_answer',
  International = 'international',
  SocialSvc = 'social_svc',
  TollFreeUs = 'tollfree_us',
  TollUs = 'toll_us',
  Unknown = 'unknown',
}

export enum Env {
  Development = 'development',
  Production = 'production',
  Staging = 'staging',
}

export enum Provisioner {
  Advanced = 'advanced',
  New = 'new',
}

export enum RequestMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
}

export interface Classifier {
  emergency?: boolean;
  friendly_name?: string;
  pretty_print?: string;
  regex?: string;
}

export type CallRestriction = {
  [key in
    | 'closed_group'
    | ClassifierSet.Caribbean
    | ClassifierSet.DidUs
    | ClassifierSet.EmergencyTest
    | ClassifierSet.Emergency
    | ClassifierSet.EnterpriseAnswer
    | ClassifierSet.International
    | ClassifierSet.SocialSvc
    | ClassifierSet.TollUs
    | ClassifierSet.TollFreeUs
    | ClassifierSet.Unknown]: {
    action: CallRestrictionOptionType.Deny | CallRestrictionOptionType.Inherit;
  };
};

export type Classifiers = Record<
  string,
  false | Classifier | CallRestrictionOptionType.Allow | CallRestrictionOptionType.Deny
>;

export type DataTestId = {
  dataTestId?: string;
  'data-test-id'?: string;
};

export type Download = {
  name: string;
  url: string;
};

export type NemoConfig = {
  api: {
    account: string;
    iam: string;
    advancedProvisioner: string;
  };
  saml_sso: {
    account_params: {
      state: string;
      tenant: string;
      locale: string;
    };
  };
  env: Env;
};

export type RequestMethodAll = `${RequestMethod}`;

export type RootState = any;

/**
 * Generic type for a nested object path.
 *
 * Example:
 *
 * const obj = {
 *    a: 'abc',
 *    b: 'xyz',
 *    c: {
 *      d: '123'
 *    }
 * }
 *
 * type ObjKey = Paths<typeof obj>
 *
 * // Valid ObjKey values are ['a', 'b', 'c.d']
 */
type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

type Prev = [never, 0, 1, 2, 3, 4, 5];

export type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
    }[keyof T]
  : '';

/**
 * Generic type for a partial recursive object.
 */
export type PartialRecursive<T> = T extends object
  ? T extends Array<infer U>
    ? PartialRecursive<U>[]
    : { [K in keyof T]?: PartialRecursive<T[K]> }
  : T;
