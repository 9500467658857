import { useFetchAccountConfigsQuery } from 'models/Account';
import { useEffect, useRef } from 'react';

type SeatTypes = Record<string, string>;

const useSeatTypes = (): SeatTypes => {
  const { data } = useFetchAccountConfigsQuery();
  const refSeatTypes = useRef<SeatTypes>({});

  useEffect(() => {
    if (data) {
      refSeatTypes.current = Object.entries(data.seat_types).reduce(
        (acc: any, [key, value]: [key: string, value: { display_name: string }]) => {
          acc[key] = value.display_name;
          return acc;
        },
        {},
      );
    }
  }, [data]);

  return refSeatTypes.current;
};

export default useSeatTypes;
