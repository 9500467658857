import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { DRAWER_HEADER_CONSTANTS } from '../Header';
import { style } from '../mixin';
import { getValue } from '../utility';
import { StyledDrawerLowerProps as Props } from './definition';

const StyledDrawerLower = styled.div<Props>`
  ${({ isOpen, height }) => css`
    ${style};

    > div {
      ${mixin.borderRadius({ radius: 'default 20px 20px default' })};
      width: ${DRAWER_HEADER_CONSTANTS.HEIGHT}px;
      height: ${getValue(height)};
      overflow: hidden;
    }

    ${isOpen &&
    css`
      padding: 0;

      > div {
        width: 0;
      }
    `}
  `}
`;

export default StyledDrawerLower;
