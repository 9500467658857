import { useSearchAccountQuery } from 'models/Account';
import { useEffect, useState } from 'react';
import { AccountField, AccountInfoItem } from './definition';

export { AccountField } from './definition';

export const useAccountInfo = (accountId: string) => {
  const { data } = useSearchAccountQuery({ search: '' });
  const [accountInfo, setAccountInfo] = useState<Record<string, Array<string>>>({
    [AccountField.Name]: [],
    [AccountField.Realm]: [],
  });

  useEffect(() => {
    if (data) {
      setAccountInfo({
        [AccountField.Name]: data.name
          .filter(({ id }: AccountInfoItem) => id !== accountId)
          .map(({ name }: AccountInfoItem) => name),
        [AccountField.Realm]: data.name
          .filter(({ id }: AccountInfoItem) => id !== accountId)
          .map(({ realm }: AccountInfoItem) => realm),
      });
    }
  }, [data, accountId]);

  const isAccountFieldUnique = (field: AccountField, value: string): boolean =>
    !accountInfo[field].find((fieldValue: string) => value === fieldValue);

  return { isAccountFieldUnique };
};
