import get from 'lodash/get';
import { useCallback } from 'react';
import { findErrorMessages } from 'shared/utility/error';
import { useToast } from '../useToast';
import { Props } from './definition';

export type { ShowErrorMessage } from './definition';

export const useShowErrorMessage = () => {
  const { showToast } = useToast();

  const showErrorMessage = useCallback(
    ({
      isFromException,
      errors,
      message,
      overrideErrorKey,
      setError,
    }: Props['ShowErrorMessage']) => {
      showToast.error(message);

      const error = {
        data: get(errors, 'data', errors),
        messages: [],
      };

      if (error.data) {
        const { data, messages } = error;
        findErrorMessages(data, messages, isFromException);
      }

      error.messages.forEach((object) => {
        if (setError) {
          const { errorKey } = object;
          setError(overrideErrorKey?.(errorKey) ?? errorKey, object);
        }
      });
    },
    [showToast],
  );

  return { showErrorMessage };
};
