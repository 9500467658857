import { FunctionComponent } from 'react';
import FileUpload from 'shared/components/FileUpload';
import * as utility from 'shared/utility';
import Labeled from '../..';
import defaultProps from './default';
import { LabeledFileUploadProps as Props } from './definition';
import StyledLabeledFileUpload from './style';

const LabeledFileUpload: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    id,
    hasDirtyIcon,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    inputWidth,
    feedback,
    tooltip,
    tooltipWidth,
    inputProps: { onChange, ...inputProps },
  }: Props = {
    ...defaultProps,
    ...props,
  };

  const forId = id || utility.generateId('input-file');

  return (
    <Labeled
      id={forId}
      type="input"
      hasDirtyIcon={hasDirtyIcon}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={labelProps}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      feedback={feedback}
      tooltip={tooltip}
      tooltipWidth={tooltipWidth}
      styledComponent={StyledLabeledFileUpload}
    >
      <FileUpload
        {...inputProps}
        id={forId}
        onChange={(e) => {
          if (!e) {
            onChange?.();
          }
          if (e?.target.files?.length) {
            // Add a timestamp of upload to provide a field for detecting change of same file uploaded
            const nextFile = e.target.files[0];
            nextFile.lastUpload = Date.now();
            onChange?.(nextFile);
          }
        }}
      />
    </Labeled>
  );
};

export default LabeledFileUpload;
