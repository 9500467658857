import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import { ArrowProps as Props } from './definition';
import StyledArrow from './style';

const CONSTANTS = {
  HEIGHT: {
    LARGE: 48,
    SMALL: 30,
  },
};

const Arrow: FunctionComponent<Props> = ({ isMultinary }: Props): JSX.Element => (
  <StyledArrow isMultinary={isMultinary}>
    <Icon
      name="callflow-arrow-filled"
      set="custom"
      height={CONSTANTS.HEIGHT[isMultinary ? 'SMALL' : 'LARGE']}
    />
  </StyledArrow>
);

export default Arrow;
