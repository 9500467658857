import { BaseSyntheticEvent, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TEST_ID from 'shared/utility/testing/constants/testId';
import * as utility from '../../utility';
import Icon from '../Icon';
import InputLabel from '../InputLabel';
import {
  DeleteButton,
  DownloadButton,
  FileActions,
  FileData,
  FileIcon,
  FileInfo,
  FileName,
  FileSize,
  UploadButton,
} from './components';
import UploadButtonLabel from './components/UploadButtonLabel';
import defaultProps from './default';
import { FileUploadProps as Props, RemoteFile, UploadFileType } from './definition';
import StyledFileUpload from './style';
import { formatBytes } from './utility';

const defaultFileSize = 0;

const FileUpload = (props: Props) => {
  const {
    hasMargin,
    isDownloadOnly,
    isFileNameBelow,
    accept,
    fileBorder,
    fileType,
    id,
    label,
    name,
    remoteFile,
    value,
    onChange,
    onDelete,
    onDownloadStart,
  } = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const defaultFileName = t('common:component.file_upload.file_name');

  const attrBasedOnType = useMemo(() => {
    const defaultLabel = t('common:component.file_upload.label');
    const defaultAccept = '*/*';
    switch (fileType) {
      case UploadFileType.CSV:
        return {
          iconName: 'document-csv',
          label: t('phone_system:containers.call_blocking.field.upload.label'),
          accept: '.csv',
        };
      case UploadFileType.AUDIO:
        return {
          iconName: 'document-audio',
          label: defaultLabel,
          accept: 'audio/*',
        };
      case UploadFileType.FILE:
        return {
          iconName: 'document',
          label: defaultLabel,
          accept: defaultAccept,
        };
      default:
        return {
          iconName: 'document',
          label: defaultLabel,
          accept: defaultAccept,
        };
    }
  }, [fileType]);

  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [fileName, setFileName] = useState<string>(defaultFileName);
  const [fileSize, setFileSize] = useState<number>(defaultFileSize);
  const forId = id ?? utility.generateId('input-file');

  const fileInputRef = useRef<HTMLInputElement>();

  const formattedFileSize = useMemo(() => formatBytes(fileSize), [fileSize]);

  const clearFileStates = () => {
    setIsFileSelected(false);
    setFileUrl('');
    setFileName(defaultFileName);
    setFileSize(defaultFileSize);
  };

  const setRemoteFile = (file: RemoteFile) => {
    setFileUrl(file.downloadUrl);
    setFileName(file.name);
    setFileSize(file.size);
    setIsFileSelected(true);
  };

  const setFileState = (file: File) => {
    URL.revokeObjectURL(fileUrl);
    const blob = new Blob([file]);
    const url = URL.createObjectURL(blob);
    setFileUrl(url);
    setFileName(file.name);
    setFileSize(file.size);
    setIsFileSelected(true);
  };

  const handleUpload = (e: BaseSyntheticEvent) => {
    const file = e.target.files[0];
    if (file) {
      setFileState(file);
    } else if (!isFileSelected) {
      clearFileStates();
    }
    onChange?.(e);
  };

  useEffect(() => {
    if (value) {
      setFileState(value);
    }
  }, [value]);

  useEffect(() => {
    if (remoteFile) {
      setRemoteFile(remoteFile);
    }
  }, [remoteFile]);

  const handleDeleteUpload = () => {
    onDelete?.(fileInputRef.current?.files?.[0]);
    onChange?.();
    clearFileStates();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const isDownloadButtonVisible = isDownloadOnly || fileType === UploadFileType.AUDIO;

  return (
    <StyledFileUpload disabled={isFileSelected} isFileNameBelow={isFileNameBelow}>
      {!isDownloadOnly && (
        <>
          <InputLabel htmlFor={forId}>
            <UploadButton>
              <>
                <Icon name={attrBasedOnType.iconName} size={22} />
                <UploadButtonLabel>{label ?? attrBasedOnType.label}</UploadButtonLabel>
              </>
            </UploadButton>
          </InputLabel>
          <input
            ref={fileInputRef as RefObject<HTMLInputElement>}
            disabled={isFileSelected}
            type="file"
            id={forId}
            name={name}
            accept={accept ?? attrBasedOnType.accept}
            onChange={(e: BaseSyntheticEvent) => handleUpload(e)}
          />
        </>
      )}
      {isFileSelected && (
        <FileInfo fileBorder={fileBorder}>
          <>
            <FileIcon>
              <Icon
                data-test-id={TEST_ID.COMMON.FILE_UPLOAD_INPUT.FILE_ICON}
                name={attrBasedOnType.iconName}
              />
            </FileIcon>
            <FileData hasMargin={hasMargin}>
              <>
                <FileName>{fileName}</FileName>
                {!!fileSize && <FileSize>{formattedFileSize}</FileSize>}
              </>
            </FileData>
            <FileActions>
              <>
                {isDownloadButtonVisible && (
                  <DownloadButton
                    fileName={fileName}
                    fileUrl={fileUrl}
                    onDownloadStart={onDownloadStart}
                  />
                )}
                {!isDownloadOnly && <DeleteButton handleDeleteUpload={handleDeleteUpload} />}
              </>
            </FileActions>
          </>
        </FileInfo>
      )}
    </StyledFileUpload>
  );
};

export default FileUpload;
