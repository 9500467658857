import { LOCAL_STORAGE } from 'constant';
import { bizProvApi } from 'services/bizProv';
import { loginDocument } from './gql/document';
import { LoginMutationResponse } from './types';

export const bizProvMutations = bizProvApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginMutationResponse, void>({
      query: () => ({
        document: loginDocument,
        variables: {
          loginArgs: {
            oe_param: {
              token: localStorage.getItem(LOCAL_STORAGE.TOKEN),
            },
          },
        },
      }),
    }),
  }),
});
