import { denormalize, normalize } from 'normalizr';
import { v4 as uuidv4 } from 'uuid';
import { Callflow, CallFlowNode, CallFlowTreeNodes } from './definition';
import { flowEntitySchema } from './schema';

export const prepareCallflowForSaving = (callflow: Callflow) => {
  const cleanNodes = (nodes: CallFlowTreeNodes) =>
    Object.keys(nodes).reduce((acc: any, key: string) => {
      // Remove nodeId before saving to the back end
      const {
        data: { nodeId, ...newData },
        module,
        children,
      } = nodes[key];

      acc[key] = { data: newData, module, children };
      return acc;
    }, {});

  const cleanedNodes = cleanNodes(callflow.nodes);
  const flow: CallFlowNode =
    denormalize(callflow.root, flowEntitySchema, { nodes: cleanedNodes }) || {};
  // Remove tree properties that you don't want to save to back end
  const {
    root,
    isReadOnly,
    isActivated,
    nodes,
    isDirty,
    isDroppable,
    dialog,
    keyDialog,
    ...rest
  } = callflow;

  if (rest.name.trim() === '') {
    rest.name = rest.numbers.join(', ');
  }

  return {
    ...rest,
    flow,
  };
};

/**
 * todo Normalizer requires unique Id per object as does Drag and Drop
 * @param root
 */
function addNodeIds(root: any) {
  const stack: { nodeId: string }[] = [];

  function addNodeId({ data, children }: any) {
    stack.push(data);

    if (children) {
      for (const [key, child] of Object.entries(children)) {
        addNodeId(child);
      }
    }
  }

  addNodeId(root);

  while (stack.length) {
    const node = stack.pop();
    if (node) {
      // todo we don't want to save the nodeId to the back-end
      if (!node.nodeId) {
        node.nodeId = uuidv4();
      }
    }
  }

  return root;
}

export const normalizeCallflow = (flow: any): { result: string; entities: { nodes: any } } => {
  addNodeIds(flow);
  const normalized = normalize(flow, flowEntitySchema);
  /**
   * It would be really nice if this could be done in the schema definition but
   * I was unable to get it to work.
   */
  const addParentIds = (node: any, parentId?: string) => {
    if (parentId) {
      node.parentId = parentId;
    }

    Object.values(node.children).forEach((child: any) => {
      if (normalized && normalized.entities && normalized.entities.nodes) {
        addParentIds(normalized.entities.nodes[child], node.data.nodeId);
      }
    });
  };

  if (normalized && normalized.entities && normalized.entities.nodes) {
    addParentIds(normalized.entities.nodes[String(normalized.result) || ''], undefined);
  }
  // TODO:
  // - We will need to call "denormalize" when we want to convert this state object back to
  //   what Kazoo expects.
  // const de = denormalize(normalized.result, flowEntity, normalized.entities);

  return normalized as any;
};
