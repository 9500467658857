// Voicemail Queries
import { Voicemail } from 'api/voicemail';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const voicemailQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSharedVoicemails: builder.query<VoicemailResponse, void>({
      query: () => ({
        url: Voicemail.FetchShared(),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.VOICEMAIL, id: 'SHARED' }],
    }),
    fetchVoicemails: builder.query<VoicemailResponse, void>({
      query: () => ({
        url: Voicemail.Fetch(),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.VOICEMAIL as const, id })),
              { type: TAGS.VOICEMAIL, id: 'LIST' },
            ]
          : [{ type: TAGS.VOICEMAIL, id: 'LIST' }],
    }),
    fetchVoicemailById: builder.query<Voicemail, ByIdParam>({
      query: ({ id }) => ({
        url: Voicemail.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.VOICEMAIL, id }];
      },
    }),
    fetchVoicemailsByOwnerId: builder.query<VoicemailResponse, ByIdParam>({
      query: ({ id }) => ({
        url: Voicemail.FetchByOwnerId(id),
        responseHandler,
      }),
      extraOptions: { maxRetries: 1 },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.VOICEMAIL as const, id })),
              { type: TAGS.VOICEMAIL, id: 'LIST' },
            ]
          : [{ type: TAGS.VOICEMAIL, id: 'LIST' }],
    }),
  }),
});
