import { FieldValueType } from 'apps/AdvancedProvisioner/shared/components/DynamicForm/definition';

/**
 * @description Get the select label from the options by matching the value
 * @param selectOptions the options of the select
 * @param value the value of the option
 * @returns the label of the option
 */
export const getSelectLabel = (
  selectOptions:
    | {
        label: string;
        value: FieldValueType;
      }[]
    | undefined,
  value: FieldValueType | undefined,
) => `${selectOptions?.find((o) => `${o.value}` === `${value}`)?.label ?? ''}`;
