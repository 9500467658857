import { getMasqueradingAccountId } from 'apps/shared/utility/account/getMasqueradingAccountId';
import { isPinPassLinkEnabled } from 'apps/shared/utility/featureFlag';
import { useFetchIdpQuery } from 'models/IAM';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { LabeledText } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import Typography from 'shared/components/Typography';
import { useToast } from 'shared/hooks/useToast';
import DocumentLink from './components/DocumentLink';
import EnableSsoSwitch from './components/EnableSsoSwitch';
import { LinkPinPassCheckBox } from './components/LinkPinPassCheckBox';
import PublicKeyRow from './components/PublicKeyRow';
import RequestVerificationCertificateRow from './components/RequestVerificationCertificateRow';
import SetupIdpButton from './components/SetupIdpButton';
import SetupIdpDialog from './components/SetupIdpDialog';
import { SsoSectionProps as Props } from './definition';
import { INFO_LABEL_WIDTH } from './style';

const SsoSection = ({ accountId }: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { data: idpData, isLoading: isLoadingIdp } = useFetchIdpQuery();

  // SSO-TODO: Remove this after edit idp under masquerading is supported
  const isMasqueradingRef = useRef<boolean>(false);
  useEffect(() => {
    // Temporary solution. This is section will be removed in the near future.
    setTimeout(() => {
      isMasqueradingRef.current = !!getMasqueradingAccountId();
    }, 0);
  }, [idpData]);

  // Form states
  const isCreatingIdp = !idpData?.idp?.id;

  const { idp } = idpData || {};
  const {
    callbackUrl = '',
    metadataUrl = '',
    issuerUrl = '',
    signRequest = false,
    requestSigningKey: { publicKey = '', certificate = '' } = {},
  } = idp || {};

  // Local states
  const [isSetupDialogOpen, setIsSetupDialogOpen] = useState<boolean>(false);

  // Handlers
  const openSetupDialog = () => {
    setIsSetupDialogOpen(true);
  };

  const closeSetupDialog = () => {
    setIsSetupDialogOpen(false);
  };

  const saveSetupDialog = (message: string | undefined) => {
    closeSetupDialog();
    showToast.success(message);
  };

  return (
    <>
      {!isMasqueradingRef.current && (
        <>
          {isLoadingIdp ? (
            <Loading />
          ) : (
            <>
              <Typography variant="h2">
                {t(
                  'phone_system:containers.account.submodule.settings.section.sso.setup_idp.heading',
                )}
              </Typography>
              <Typography paragraph variant="inherit">
                {isCreatingIdp ? (
                  t(
                    'phone_system:containers.account.submodule.settings.section.sso.setup_idp.intro',
                  )
                ) : (
                  <DocumentLink />
                )}
              </Typography>
              {!isCreatingIdp && (
                <>
                  <Box className="one-column" role="row">
                    <Box role="cell">
                      <LabeledText
                        copyToClipboard={callbackUrl}
                        label={t(
                          'phone_system:containers.account.submodule.settings.section.sso.callback_url',
                        )}
                        labelWidth={INFO_LABEL_WIDTH}
                        text={callbackUrl}
                        textWidth="auto"
                      />
                    </Box>
                  </Box>
                  <Box className="one-column" role="row">
                    <Box role="cell">
                      <LabeledText
                        copyToClipboard={issuerUrl}
                        label={t(
                          'phone_system:containers.account.submodule.settings.section.sso.issuer_url',
                        )}
                        labelWidth={INFO_LABEL_WIDTH}
                        text={issuerUrl}
                        textWidth="auto"
                      />
                    </Box>
                  </Box>
                  <Box className="one-column" role="row">
                    <Box role="cell">
                      <LabeledText
                        copyToClipboard={metadataUrl}
                        label={t(
                          'phone_system:containers.account.submodule.settings.section.sso.metadata_url',
                        )}
                        labelWidth={INFO_LABEL_WIDTH}
                        text={metadataUrl}
                        textWidth="auto"
                      />
                    </Box>
                  </Box>
                  <PublicKeyRow publicKey={publicKey} signRequest={signRequest} />
                  <RequestVerificationCertificateRow
                    requestVerificationCertificate={certificate}
                    signRequest={signRequest}
                  />
                </>
              )}
              <SetupIdpButton isCreate={isCreatingIdp} onClick={openSetupDialog} />
            </>
          )}
          <SetupIdpDialog
            accountId={accountId}
            open={isSetupDialogOpen}
            onClose={closeSetupDialog}
            onSave={saveSetupDialog}
          />
        </>
      )}
      {(isMasqueradingRef.current || !isCreatingIdp) && (
        <>
          <Typography variant="h2">
            {t('phone_system:containers.account.submodule.settings.section.sso.enable_sso.heading')}
          </Typography>
          <Typography paragraph variant="inherit">
            {t('phone_system:containers.account.submodule.settings.section.sso.enable_sso.intro')}
          </Typography>
          <Box className="one-column" marginBottom={6} role="row">
            <Box role="cell">
              <EnableSsoSwitch />
            </Box>
          </Box>
        </>
      )}
      {isPinPassLinkEnabled() && (
        <>
          <Typography id="password-voicemail-pin" variant="h2">
            {t(
              'phone_system:containers.account.submodule.settings.section.sso.pin_pass_link.heading',
            )}
          </Typography>
          <Box component="div" marginBottom={1}>
            <em>
              <Typography variant="inherit">
                {t(
                  'phone_system:containers.account.submodule.settings.section.sso.pin_pass_link.intro',
                )}
              </Typography>
            </em>
          </Box>
          <Typography variant="inherit">
            <strong>
              {t(
                'phone_system:containers.account.submodule.settings.section.sso.pin_pass_link.remark',
              )}
            </strong>
          </Typography>
          <Box className="one-column" role="row">
            <Box role="cell">
              <LinkPinPassCheckBox />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default SsoSection;
