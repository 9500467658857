import { TableCellOverflowList } from 'apps/shared/components/TableCell';
import i18next from 'i18next';
import { Justify } from 'shared/components/Table';
import { getIsBlockingEnabled } from '../utility';
import TableCellEnableSwitch from './components/TableCellEnabledSwitch';

export const columns = () => [
  {
    Header: i18next.t('phone_system:containers.call_blocking.table.column.name'),
    accessor: 'name',
    width: 300,
  },
  {
    Header: i18next.t('phone_system:containers.call_blocking.table.column.numbers'),
    accessor: (row: any) => Object.keys(row.numbers).join(', '),
    Cell: ({ row }: any) => <TableCellOverflowList values={Object.keys(row.original.numbers)} />,
    width: undefined,
  },
  {
    Header: i18next.t('phone_system:containers.call_blocking.table.column.enabled'),
    accessor: 'isBlockingEnabled',
    id: `isBlockingEnabled.${Justify.Center}`,
    width: 160,
    maxWidth: 160,
    Cell: ({ row: { original } }: any) => <TableCellEnableSwitch data={original} />,
  },
];

export const prepareDataForTable = (
  data: Blocklist[] | undefined,
  accountData: { blacklists: string[] | undefined },
) => {
  if (!data) {
    return [];
  }
  return data.map((item: any) => {
    const newItem = { ...item };
    newItem.isBlockingEnabled = getIsBlockingEnabled(item.id, accountData?.blacklists);

    return newItem;
  });
};
