import { defaultProps as headerProps } from './components/Header';

const defaultProps = {
  hasPadding: false,
  isOpen: false,
  width: 320,
  height: 800,
  headerProps,
};

export default defaultProps;
