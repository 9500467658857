import { themeColor } from 'theme';
import theme from '../core';
import { MUICARD_AGENT_CONSTANTS } from './muiCard';

export default {
  styleOverrides: {
    root: {
      '&.MuiCardHeader-agent': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1.33),
        backgroundColor: themeColor.kazoo.agent.state.logged_out,
        color: theme.palette.common.white,
        '&.MuiCardHeader-agent-connected': {
          backgroundColor: themeColor.kazoo.agent.state.connected,
        },
        '&.MuiCardHeader-agent-connecting': {
          backgroundColor: themeColor.kazoo.agent.state.connecting,
        },
        '&.MuiCardHeader-agent-logged_in': {
          backgroundColor: themeColor.kazoo.agent.state.logged_in,
        },
        '&.MuiCardHeader-agent-outbound': {
          backgroundColor: themeColor.kazoo.agent.state.outbound,
        },
        '&.MuiCardHeader-agent-paused': {
          backgroundColor: themeColor.kazoo.agent.state.paused,
        },
        '&.MuiCardHeader-agent-ready': {
          backgroundColor: themeColor.kazoo.agent.state.ready,
        },
        '&.MuiCardHeader-agent-wrapup': {
          backgroundColor: themeColor.kazoo.agent.state.wrapup,
        },
        '.MuiCardHeader-action': {
          alignSelf: 'center',
          marginBottom: -2,
          marginTop: 0,
        },
        '.MuiCardHeaderTitle-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: MUICARD_AGENT_CONSTANTS.WIDTH - 92,
          '> div': {
            '&:first-of-type': {
              display: 'flex',
              flexDirection: 'column',
              marginLeft: theme.spacing(-1),
              span: {
                '&:first-of-type': {
                  lineHeight: 1.3,
                  userSelect: 'none',
                },
                '&:last-of-type': {
                  display: 'inline-block',
                  width: 180,
                  fontSize: 18,
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              },
            },
            '&:last-of-type': {
              width: 100,
              marginRight: theme.spacing(1),
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'right',
            },
          },
        },
      },
      '&.MuiCardHeader-queue': {
        paddingTop: theme.spacing(3.66),
        paddingBottom: theme.spacing(2.33),
        borderBottom: `1px solid ${themeColor.brio.other.divider}`,
        '.MuiCardHeader-content': {
          width: '90%',
          '.MuiTypography-root': {
            display: 'inline-block',
            width: '100%',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            userSelect: 'none',
            whiteSpace: 'nowrap',
          },
        },
        '.MuiCardHeader-action': {
          transform: 'translateY(4px)',
        },
      },
    },
  },
};
