import { ContainerMeta } from 'apps/shared/definition';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import List from './List';

export const meta: ContainerMeta = {
  // t('phone_system:containers.e911info.label')
  label: 'phone_system:containers.e911info.label',
  icon: '9-1-1-label',
  slug: 'emergency-info',
};

const E911Info = () => {
  const routes = useRoutes([{ path: '/', element: <List /> }]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};

export default E911Info;
