// AppsStore/index.ts
import { appsStoreMutations } from './Mutations';
import { appsStoreQueries } from './Queries';

export const {
  useFetchAppsQuery,
  useFetchAppByIdQuery,
  useFetchAppsBlocklistQuery,
} = appsStoreQueries;

export const { useUpdateAppsBlocklistMutation } = appsStoreMutations;
