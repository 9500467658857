// PhoneNumber Queries
import { PhoneNumber } from 'api/phoneNumber';
import { Classifiers, RequestMethod } from 'definition';
import { ByIdParam, ByPrefixParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const phoneNumberQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchPhoneNumbers: builder.query<PhoneNumber, void>({
      query: () => ({
        url: PhoneNumber.Fetch(),
        responseHandler,
      }),
      transformResponse: (response: any) => {
        const usage = Object.keys(response.numbers).reduce(
          (acc: { assigned?: any; spare?: any }, number: PhoneNumber) => {
            const phoneNumber = response.numbers[number];
            phoneNumber.id = number;
            if (phoneNumber.used_by || phoneNumber.used_by_callflow_id) {
              if (!acc.assigned) {
                acc.assigned = {};
              }
              acc.assigned[number] = phoneNumber;
              // LOUIS-TODO: This is a temporary fix for phone number API not returning "used_by" together with "used_by_callflow_id". Revert back when backend fix the issue.
              if (!phoneNumber.used_by) {
                phoneNumber.used_by = 'callflow';
              }
            } else {
              if (!acc.spare) {
                acc.spare = {};
              }
              acc.spare[number] = phoneNumber;
            }
            return acc;
          },
          {},
        );
        return {
          usage,
          numbers: response.numbers,
        };
      },
      providesTags: [{ type: TAGS.PHONENUMBER, id: 'LIST' }],
    }),
    checkAvailableCollection: builder.query<PhoneNumber, PhoneNumberCollectionParams>({
      query: ({ body }) => ({
        url: PhoneNumber.CheckAvailableCollection(),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
    }),
    fetchAvailableNumbersByPrefix: builder.query<PhoneNumber, ByPrefixParam>({
      query: ({ prefix }) => ({
        url: PhoneNumber.FetchAvailableByPrefix(prefix),
        responseHandler,
      }),
      keepUnusedDataFor: 1,
    }),
    fetchPhoneNumberById: builder.query<PhoneNumber, ByIdParam>({
      query: ({ id }) => ({
        url: PhoneNumber.FetchById(id),
        responseHandler,
      }),
      transformResponse: (response: any) => {
        const translatedResponse = {
          ...response,
        };
        return translatedResponse;
      },
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.PHONENUMBER, id }];
      },
    }),
    fetchInServiceAndPortInPhoneNumbers: builder.query<PhoneNumber, void>({
      query: () => ({
        url: PhoneNumber.FetchInServiceAndPortIn(),
        responseHandler,
      }),
      keepUnusedDataFor: 0,
    }),
    fetchClassifiers: builder.query<Classifiers, void>({
      query: () => ({
        url: PhoneNumber.FetchClassifiers(),
        responseHandler,
      }),
    }),
  }),
});
