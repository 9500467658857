import { DeviceType } from 'apps/PhoneSystem/definition';
import { Color } from './definitions';

export function getIsRegisteredDevice(currentDevice: any, deviceRegistrationsData: any) {
  const isRegisteredDeviceType = Object.values(DeviceType).includes(currentDevice.device_type);

  return (
    currentDevice.enabled &&
    (!isRegisteredDeviceType ||
      !!deviceRegistrationsData[currentDevice.deviceId ?? currentDevice.id])
  );
}

export function getColor(deviceRegistrationsData: any, currentDevice: any) {
  const isRegisteredDevice = getIsRegisteredDevice(currentDevice, deviceRegistrationsData);

  let color = Color.red;
  if (isRegisteredDevice) {
    color = Color.green;
  }

  if (!currentDevice.enabled) {
    color = Color.darkGray;
  }

  return color;
}
