import { themeColor } from 'theme';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      '&.MuiCardContent-agent': {
        paddingTop: theme.spacing(1.66),
        paddingBottom: theme.spacing(2.66),
        color: themeColor.brio.text.secondary,
        '> div': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:nth-of-type(1)': {
            marginBottom: theme.spacing(1),
            span: {
              color: themeColor.brio.text.primary,
              fontSize: 16,
              fontWeight: 600,
            },
          },
          '&:nth-of-type(2), &:nth-of-type(3)': {
            span: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          },
          '&:nth-of-type(2)': {
            span: {
              width: '100%',
              fontWeight: 600,
              lineHeight: 1.2,
            },
          },
          '&:nth-of-type(3)': {
            marginBottom: theme.spacing(1.33),
            div: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
            span: {
              width: '85%',
              lineHeight: 1.3,
            },
            svg: {
              fill: themeColor.brio.greyscale[600],
              transform: 'translateY(2px)',
              '&:last-of-type': {
                marginLeft: theme.spacing(1),
              },
            },
          },
          '&:nth-of-type(4), &:nth-of-type(5)': {
            span: {
              fontSize: 16,
            },
          },
          '&:nth-of-type(4)': {
            marginBottom: theme.spacing(1.33),
            paddingTop: theme.spacing(1.33),
            borderTop: `1px solid ${themeColor.brio.other.divider}`,
            span: {
              color: themeColor.brio.text.primary,
              fontWeight: 600,
            },
          },
          '&:nth-of-type(5)': {
            marginBottom: theme.spacing(1),
            strong: {
              color: themeColor.brio.text.primary,
              fontWeight: 600,
            },
          },
        },
      },
      '&.MuiCardContent-queue': {
        paddingTop: theme.spacing(1.66),
        paddingBottom: theme.spacing(1.66),
        '.MuiListItem-root': {
          padding: 0,
          color: themeColor.brio.text.secondary,
          userSelect: 'none',
          '> .MuiSvgIcon-root': {
            marginRight: theme.spacing(1.5),
          },
          '> .MuiTypography-root': {
            fontSize: 16,
            lineHeight: 1.4,
            '&:last-of-type': {
              marginLeft: 'auto',
            },
          },
          '&:first-of-type > p:last-of-type': {
            color: theme.palette.common.black,
          },
          '&:not(:last-of-type)': {
            padding: theme.spacing(0, 0, 1.5),
          },
        },
      },
    },
  },
};
