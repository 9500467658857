// Seat/index.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { callflowMutations } from 'models/Callflow/Mutations';
import { callflowQueries } from 'models/Callflow/Queries';
import { conferenceMutations } from 'models/Conference/Mutations';
import { conferenceQueries } from 'models/Conference/Queries';
import { deviceMutations } from 'models/Device/Mutations';
import { deviceQueries } from 'models/Device/Queries';
import { faxboxMutations } from 'models/Faxbox/Mutations';
import { faxboxQueries } from 'models/Faxbox/Queries';
import { mattermostMutations } from 'models/Mattermost/Mutations';
import { smsMutations } from 'models/SMS/Mutations';
import { smsQueries } from 'models/SMS/Queries';
import { voicemailMutations } from 'models/Voicemail/Mutations';
import { voicemailQueries } from 'models/Voicemail/Queries';
import store from 'store';
import { seatMutations } from './Mutations';
import { seatQueries } from './Queries';
import { getMattermostUserId } from './utility/getMattermostUserId';

export const {
  useFetchSeatsQuery,
  useListBySeatTypeQuery,
  useFetchSeatByIdQuery,
  useFetchSeatHeroAppsByIdQuery,
  useLazyFetchSeatsQuery,
  useLazyFetchSeatByIdQuery,
} = seatQueries;
export const {
  useUpdateSeatMutation,
  usePatchSeatMutation,
  useUpdateSeatHeroAppsMutation,
  useCreateSeatMutation,
  useDeleteSeatMutation,
  usePatchSeatWithAccountIdMutation,
} = seatMutations;

// Maybe move to a Thunks file
export const deleteSeat = createAsyncThunk('seat/deleteSeat', async (id: string) => {
  const { data: seatData } = await store.dispatch(
    seatQueries.endpoints.fetchSeatById.initiate({ id }),
  );

  // Remove mattermost user if exists.
  const mattermostUserId = getMattermostUserId(seatData);
  if (mattermostUserId) {
    const { error } = (await store.dispatch(
      mattermostMutations.endpoints.deleteMattermostUser.initiate({
        mattermostUserId,
      }),
    )) as any;

    console.error('Delete mattermost user failure', error);
  }

  // Fetch all entities
  const [
    { data: conferences },
    { data: faxboxes },
    { data: voicemails },
    { data: devices },
    { data: sms },
    { data: callflows },
  ] = await Promise.all([
    store.dispatch(conferenceQueries.endpoints.fetchConferencesByOwnerId.initiate({ id })),
    store.dispatch(faxboxQueries.endpoints.fetchFaxboxesByOwnerId.initiate({ id })),
    store.dispatch(voicemailQueries.endpoints.fetchVoicemailsByOwnerId.initiate({ id })),
    store.dispatch(deviceQueries.endpoints.fetchDevicesByOwnerId.initiate({ id })),
    store.dispatch(smsQueries.endpoints.fetchSMSByOwnerId.initiate({ id })),
    store.dispatch(
      callflowQueries.endpoints.fetchCallflowsByOwnerUsername.initiate({
        username: seatData.username,
      }),
    ),
  ]);

  let deletePromises: Array<any> = [];

  // Delete Conferences
  deletePromises = deletePromises.concat(
    conferences?.map((conf) =>
      store.dispatch(conferenceMutations.endpoints.deleteConference.initiate({ id: conf.id })),
    ),
  );

  // Delete Faxboxes
  deletePromises = deletePromises.concat(
    faxboxes?.map((faxbox) =>
      store.dispatch(faxboxMutations.endpoints.deleteFaxbox.initiate({ id: faxbox.id })),
    ),
  );

  // Delete Voicemails
  deletePromises = deletePromises.concat(
    voicemails?.map((vm) =>
      store.dispatch(voicemailMutations.endpoints.deleteVoicemail.initiate({ id: vm.id })),
    ),
  );

  // Delete Devices
  deletePromises = deletePromises.concat(
    devices?.map((device) =>
      store.dispatch(deviceMutations.endpoints.deleteDevice.initiate({ id: device.id })),
    ),
  );

  // Delete SMS (only owned by user)
  deletePromises = deletePromises.concat(
    sms?.map((sms) => store.dispatch(smsMutations.endpoints.deleteSMS.initiate({ id: sms.id }))),
  );

  // Delete Callflows
  deletePromises = deletePromises.concat(
    callflows?.map((callflow) =>
      store.dispatch(callflowMutations.endpoints.deleteCallflow.initiate({ id: callflow.id })),
    ),
  );

  const results = await Promise.all(deletePromises);

  // Delete seat
  results.push(await store.dispatch(seatMutations.endpoints.deleteSeat.initiate({ id })));

  return results;
});
