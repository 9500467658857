import { newCallFlow } from 'models/Callflow/store/default';
import { Callflow } from 'models/Callflow/store/definition';
import { createContext, PropsWithChildren } from 'react';
import Loading from 'shared/components/Loading';

interface Props {
  callflow: Callflow;
  isPreview?: boolean;
}

const CallflowContext = createContext({
  callflow: {
    ...newCallFlow,
  },
  isPreview: false,
});

export const CallflowContextProvider = (props: PropsWithChildren<Props>) => {
  const { children, callflow, isPreview = false } = props;

  if (!callflow) return <Loading />;

  return (
    <CallflowContext.Provider value={{ callflow, isPreview }}>{children}</CallflowContext.Provider>
  );
};

export default CallflowContext;
