import i18next from 'i18next';

const translations = () => ({
  apply_tos: [
    {
      value: 'original',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.caller_id_prepend.apply_tos.original',
      ),
    },
    {
      value: 'current',
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.caller_id_prepend.apply_tos.current',
      ),
    },
  ],
  apply_to: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.caller_id_prepend.apply_to.label',
    ),
    tooltip: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.caller_id_prepend.apply_to.tooltip',
    ),
  },
  caller_id_name_prefix: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.caller_id_prepend.caller_id_name_prefix.label',
    ),
  },
  caller_id_number_prefix: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.caller_id_prepend.caller_id_number_prefix.label',
    ),
  },
  title: i18next.t(
    'phone_system:containers.callflows.callflow_action_dialog.caller_id_prepend.title',
  ),
});

export default translations;
