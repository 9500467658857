import InputAdornment from '@mui/material/InputAdornment';
import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import Input from 'shared/components/Input';
import { Adornment } from 'shared/components/Input/definition';
import * as utility from 'shared/utility';
import Labeled from '../..';
import defaultProps from './default';
import { LabeledInputProps as Props } from './definition';
import StyledLabeledInput from './style';

const LabeledInput: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    id,
    hasDirtyIcon,
    hasMargin,
    hasSmallMargin,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    inputProps,
    inputWidth,
    feedback,
    tooltip,
    tooltipWidth,
  }: Props = {
    ...defaultProps,
    ...props,
  };
  const forId = id || utility.generateId('input');

  const _inputProps = { ...inputProps };
  if (inputProps.adornment) {
    const { type, position, value, onClick }: Adornment = inputProps.adornment;
    _inputProps[`${position}Adornment`] = (
      <InputAdornment position={position}>
        {type === 'icon' ? <Icon name={value} onClick={onClick} /> : value}
      </InputAdornment>
    );
  }

  return (
    <Labeled
      feedback={feedback}
      hasDirtyIcon={hasDirtyIcon}
      hasMargin={hasMargin}
      hasSmallMargin={hasSmallMargin}
      id={forId}
      inputWidth={inputWidth}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={labelProps}
      labelWidth={labelWidth}
      styledComponent={StyledLabeledInput}
      tooltip={tooltip}
      tooltipWidth={tooltipWidth}
      type="input"
    >
      <Input feedback={feedback} id={forId} width={inputWidth} {..._inputProps} />
    </Labeled>
  );
};

export default LabeledInput;
