import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class CallForwardingEnableActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.CALL_FORWARDING_ENABLE,
      module: CallFlowModuleKey.CALL_FORWARDING_ENABLE,
      section: CallFlowSection.CALL_FORWARDING,
      hasDialog: false,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'forward-filled',
      },
      label: i18next.t('phone_system:containers.callflows.actions.call_forwarding_enable.name'),
      tooltip: i18next.t(
        'phone_system:containers.callflows.actions.call_forwarding_enable.tooltip',
      ),
    });
  }
}

export default CallForwardingEnableActionConfig;
