import { callflowMutations } from './Mutations';
import { callflowQueries } from './Queries';

export const {
  useFetchCallflowsQuery,
  useLazyFetchCallflowsQuery,
  useFetchCallflowByIdQuery,
  useFetchCallflowsByOwnerUsernameQuery,
  useForceFetchCallflowByIdQuery,
  useLazyFetchCallflowsByOwnerUsernameQuery,
  useLazyFetchCallflowByIdQuery,
} = callflowQueries;

export const {
  useCreateCallflowMutation,
  useCreateByAccountIdMutation,
  useDeleteCallflowMutation,
  useUpdateCallflowMutation,
  usePatchCallflowMutation,
} = callflowMutations;
