import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledLocationField = styled(Box)`
  ${({ theme }) => css`
    ${mixin.flex({ justifyContent: 'space-between' })};

    div:first-of-type {
      margin-bottom: 0;
    }

    svg {
      margin: ${theme.spacing(1.66, 1.66, 1.66, 2)};
    }
  `}
`;
