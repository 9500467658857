import { useMemo } from 'react';
import { useGetFormContextValueParams, useGetFormContextValueResult } from '../../definition';

export const useGetFormContextValue = ({
  tabKey,
  iterate = 0,
  currentIterate = '0',
}: useGetFormContextValueParams): useGetFormContextValueResult =>
  useMemo(() => {
    const hasFormActionRow = iterate > 1;
    const hasIterateInContext = iterate > 0;
    return {
      hasFormActionRow,
      formActionRowIterate: Number(currentIterate) + 1,
      formContextValue: `${tabKey}${hasIterateInContext ? `.${currentIterate}` : ''}.`,
    };
  }, [currentIterate, iterate, tabKey]);
