import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Skills from '../Skills';
import Callback from './components/Callback';
import IncomingCall from './components/IncomingCall';
import defaultProps from './default';
import { TableCellIncomingCallProps as Props } from './definition';
import StyledTableCellIncomingCall from './style';

const TableCellIncomingCall: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { incomingCallLabel, isCallback, items, priority, skillsList, timestamp }: Props = {
    ...defaultProps,
    ...props,
  };
  const hasSkills = Boolean(skillsList?.length);
  const { t } = useTranslation();

  if (!incomingCallLabel) {
    return <Box />;
  }

  return (
    <StyledTableCellIncomingCall hasskills={hasSkills.toString()}>
      {isCallback ? <Callback /> : <Box />}
      <IncomingCall
        incomingCallLabel={incomingCallLabel}
        items={items}
        priority={priority}
        timestamp={timestamp}
      />
      <Box />
      <Skills
        skillsList={skillsList}
        tooltipText={
          hasSkills
            ? t('call_center:containers.dashboard.component.table_cell_incoming_call.skills', {
                skills: skillsList?.join(', '),
              })
            : ''
        }
        elementType="div"
      />
    </StyledTableCellIncomingCall>
  );
};

export default TableCellIncomingCall;
