import { AppMeta } from 'apps/shared/definition';

export const meta: AppMeta = {
  isIframeApp: true,
  i18n: {
    label: 'Keyman',
  },
  icon: 'dial-plans',
  name: 'keyman',
  slug: 'keyman',
};
