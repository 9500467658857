import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { AUDIO_WAY_OPTIONS, ENDPOINT_TYPES } from '../../constants';

const schema = () =>
  Joi.object({
    audio: Joi.string().valid(...AUDIO_WAY_OPTIONS),
    endpoints: Joi.array()
      .items(
        Joi.object({
          endpoint_type: Joi.string().valid(...ENDPOINT_TYPES),
          id: Joi.string().optional().allow(''),
          key: Joi.string(),
          name: Joi.string(),
          delay: Joi.number().min(0).required(),
          timeout: Joi.number().min(0).required(),
        }),
      )
      .min(0),
    name: Joi.string().required(),
    nodeId: Joi.string(),
    timeout: Joi.number().min(0).required(),
  }).messages(messages());

export default schema;
