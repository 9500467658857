import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider as Provider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { isEnv } from 'shared/utility';

const HelmetProvider = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState<string>('');
  const [urlFavicon, setUrlFavicon] = useState<string>('');

  useEffect(() => {
    setTitle(isEnv.production ? t('common:title.production') : t('common:title.staging'));
    setUrlFavicon(
      isEnv.production
        ? `/favicon.ico?v=${Math.random()}`
        : `/favicon_staging.ico?v=${Math.random()}`,
    );
  }, []);

  return (
    <Provider context={{}}>
      <Helmet>
        <title>{title}</title>
        <link rel="shortcut icon" href={urlFavicon} />
      </Helmet>
    </Provider>
  );
};

export default HelmetProvider;
