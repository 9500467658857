import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import { HORIZONTAL_NAV_CONSTANTS } from 'apps/shared/components/HorizontalNav';
import Box from 'shared/components/Box';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled, { css } from 'styled-components';
import { TABS_CONSTANTS } from 'theme/mui/components';
import { StyledEditPanelsProps as Props } from './definition';

export const StyledEditPanels = styled(Box)<Props>`
  ${({ $hasFormActionRow }) => css`
    > div:not(:first-of-type) {
      width: 100vw;
      height: calc(
        100vh -
          (
            ${HEADER_CONSTANTS.HEIGHT} + ${ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT} +
              ${HORIZONTAL_NAV_CONSTANTS.HEIGHT} +
              ${`${$hasFormActionRow ? TABS_CONSTANTS.HEIGHT.TAB : 0}px`}
          )
      );
    }
  `}
`;
