import { MarginType, WidthType } from 'shared/utility/definition';
import labeledProps from '../../default';

const defaultProps = {
  ...labeledProps,
  hasMargin: false,
  isCheckboxLeft: true,
  indentWidth: 'large' as WidthType,
  labelWidth: 'large' as WidthType,
  leftMargin: MarginType.none,
};

export default defaultProps;
