import i18next from 'i18next';
import Icon from 'shared/components/Icon';
import { SelectOption } from '../../../Select';

/**
 * @name getClasses
 * @description Get custom classes for the `MuiMenuItem` element.
 *
 * @param hasAllOption
 *
 * @returns Classes object.
 */
export const getClasses = (hasAllOption: boolean) => {
  const classes = ['MuiMenuItem-select-filter'];

  if (hasAllOption) {
    classes.push('MuiMenuItem-select-filter-has-all');
  }

  return classes.join(' ');
};

/**
 * @name getIconComponent
 * @description Get the icon component.
 *
 * @property isOpen
 *
 * @returns Icon component.
 */
export const getIconComponent = (isOpen: boolean) => {
  const classes = ['MuiSvgIcon-rotate'];
  if (isOpen) {
    classes.push('MuiSvgIcon-rotate-open');
  }

  return <Icon classes={{ root: classes.join(' ') }} name="dropdown-down" size={21} />;
};

/**
 * @name getRenderValue
 * @description Get the renderValue text.
 *
 * @property options
 * @property value
 *
 * @returns renderValue text.
 */
export const getRenderValue = (options: Array<SelectOption>, value: Array<string>) => {
  switch (true) {
    case value.length === 0:
      return i18next.t('common:component.filter_select.please_select');
    case value.length === 1:
      return options.find((option: SelectOption) => option.value === value[0])?.label;
    case value.length >= options.length:
      return i18next.t('common:component.filter_select.option.all');
    default:
      return i18next.t('common:component.filter_select.selected', { itemCount: value.length });
  }
};
