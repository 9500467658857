import { IframeProps } from './definition';
import { StyledIframe } from './style';

export const Iframe = ({ url, title, ...props }: IframeProps) => (
  <StyledIframe
    {...props}
    src={url}
    title={`iframe-app-${title}`}
    sandbox="allow-forms allow-popups allow-same-origin allow-downloads allow-scripts"
  />
);
