import { EditorState } from 'draft-js';
import { FunctionComponent, useEffect, useMemo } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import RichTextEditor, {
  draftContentToHtml,
  htmlStringToDraftContentState,
} from 'shared/components/RichTextEditor';
import { FormContext } from '../../FormContext';
import { RichTextEditorWrapperProps as Props } from './definition';

/**
 * @usage This hook form automatically set a form state of `{ [richTextStateName]: state }` to store the state of the rich text editor.
 * On form submit, The `richTextStateName` property is recommended to be transformed to html string and set to an appropriate field.
 * It should also be removed before sending to server.
 */
const RichTextEditorWrapper: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { control, name: htmlStringStateName, richTextStateName } = props;
  const formContext = useFormContext();
  const { control: controlContext, setValue, resetField } = formContext;

  const htmlStringState = useWatch({ name: htmlStringStateName });

  // Initialize editor with html string state
  useEffect(() => {
    setValue(richTextStateName, htmlStringToDraftContentState(htmlStringState));
  }, [htmlStringState]);

  // Generate default html string for shouldDirty comparison
  const defaultHtmlString = useMemo(
    () => draftContentToHtml(htmlStringToDraftContentState(htmlStringState)),
    [htmlStringState],
  );

  return (
    <FormContext.Consumer>
      {(formContext) => {
        const contextName = !formContext ? richTextStateName : `${formContext}${richTextStateName}`;

        const onEditorStateChange = (editorState: EditorState) => {
          const nextHtmlString = draftContentToHtml(editorState);
          const shouldReset = nextHtmlString === defaultHtmlString;
          if (shouldReset) {
            resetField(contextName, { defaultValue: editorState });
          } else {
            setValue(contextName, editorState, { shouldDirty: !shouldReset });
          }
        };

        return (
          <Controller
            control={control || controlContext}
            name={contextName}
            render={({
              field,
              fieldState: { isDirty },
            }: {
              field: ControllerRenderProps;
              fieldState: ControllerFieldState;
            }) => {
              const { value }: { value: EditorState | undefined } = field;
              const editorState = value || EditorState.createEmpty();

              return (
                <RichTextEditor
                  isDirty={isDirty}
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                />
              );
            }}
          />
        );
      }}
    </FormContext.Consumer>
  );
};

export default RichTextEditorWrapper;

export const transformRichTextStateToHtmlString = (editorState: EditorState) => {
  if (!editorState) {
    return '';
  }
  return draftContentToHtml(editorState);
};
