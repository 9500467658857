import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFlowChildSubtitleProps as Props } from './definition';

export const StyledFlowChildSubtitle = styled.div<Props>`
  ${(props) => css`
    grid-area: 3 / 1 / 4 / 4;
    ${mixin.flex({ alignItems: 'flex-end' })};
    text-align: center;

    > div {
      ${mixin.font({ size: 12 })};
    }

    span {
      ${mixin.font({ size: 12 })};
      ${mixin.transition({ property: 'color', duration: 'medium' })};

      overflow: hidden;
      padding: 0 4px 4px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.is-preview {
        color: ${props.theme.core.color.blue.draggable};
      }

      ${!props.isDragging &&
      props.hasDialog &&
      !props.isPreview &&
      css`
        cursor: pointer;

        &:hover {
          ${mixin.hover({ letterSpacing: -0.012 })};
          color: ${props.theme.core.color.blue.draggable};

          ${props.isDragging &&
          css`
            font-weight: normal;
            white-space: normal;
          `};
        }
      `}
    }
  `}
`;
