import MuiCardContent, { CardContentProps as Props } from '@mui/material/CardContent';
import { FunctionComponent } from 'react';

export type { CardContentProps } from '@mui/material/CardContent';

const CardContent: FunctionComponent<Props> = (props: Props): JSX.Element => (
  <MuiCardContent {...props} data-test-id="shared-card-component-card-content" />
);

export default CardContent;
