import { BuyNumberFlowType } from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow';
import { getFlowMeta } from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow/utility';

/**
 * @name getPathWhitelist
 * @description Retrieves a whitelist of unique paths from the flow meta constant
 *
 * @returns An array of unique paths
 */
export const getPathWhitelist = () =>
  [
    ...getFlowMeta()[BuyNumberFlowType.Local][0].route.default.split('/'),
    ...getFlowMeta()[BuyNumberFlowType.TollFree][0].route.default.split('/'),
  ].filter((item, index, flowMeta) => flowMeta.indexOf(item) === index);
