import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'shared/components/Drawer';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import TEST_ID from 'shared/utility/testing/constants/testId';
import CallflowContext from '../CallflowContext';
import DrawerContent from './components/DrawerContent';
import QuickViewContainer from './components/QuickViewContainer';
import { CALLFLOW_CONTAINER_CONSTANTS } from './components/QuickViewContainer/components/QuickViewCallflow/components/CallflowContainer';
import QuickViewDialog from './components/QuickViewDialog';
import { QuickViewDialogType } from './components/QuickViewDialog/definition';
import { CallflowDrawerProps as Props, Constants, QuickViewDialog as Dialog } from './definition';
import StyledCallflowDrawer from './style';

export const QUICKVIEW_CONSTANTS: Constants = {
  MAXIMUM: {
    EXPANDED: 2,
    MINIMIZED: 4,
  },
};

const DEFAULT_DIALOG_VALUES: Dialog = {
  expanded: false,
  minimized: false,
};

const CallflowDrawer: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<Dialog>(DEFAULT_DIALOG_VALUES);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [quickViewTotalCallflowIds, setQuickViewTotalCallflowIds] = useState<string[]>([]);
  const [quickViewExpandedCallflowIds, setQuickViewExpandedCallflowIds] = useState<string[]>([]);
  const refDrawer = useOutsideClick(() => {
    if (!Object.values(isDialogOpen).includes(true)) {
      setIsDrawerOpen(false);
    }
  });
  const {
    callflow: { id },
  } = useContext(CallflowContext);

  const openDialog = (type: QuickViewDialogType): void => {
    setIsDialogOpen((isDialogOpen) => ({
      ...isDialogOpen,
      [type]: true,
    }));
  };

  const handleCallflowClose = (callflowId: string): void => {
    setQuickViewExpandedCallflowIds((callflowIds: string[]) =>
      callflowIds.filter((id: string) => id !== callflowId),
    );
    setQuickViewTotalCallflowIds((callflowIds: string[]) =>
      callflowIds.filter((id: string) => id !== callflowId),
    );
  };

  const handleDialogAction = (): void => {
    setIsDialogOpen(DEFAULT_DIALOG_VALUES);
    setIsDrawerOpen(true);
  };

  const handleQuickViewClick = (callflowId: string): void => {
    setQuickViewExpandedCallflowIds((expandedCallflowIds: string[]) => {
      if (quickViewTotalCallflowIds.length >= QUICKVIEW_CONSTANTS.MAXIMUM.MINIMIZED) {
        openDialog(QuickViewDialogType.mimimized);
        return expandedCallflowIds;
      }

      if (expandedCallflowIds.length < QUICKVIEW_CONSTANTS.MAXIMUM.EXPANDED) {
        return [...expandedCallflowIds, callflowId];
      }

      openDialog(QuickViewDialogType.expanded);
      return expandedCallflowIds;
    });

    setQuickViewTotalCallflowIds((totalCallflowIds: string[]) => {
      if (quickViewExpandedCallflowIds.length >= QUICKVIEW_CONSTANTS.MAXIMUM.EXPANDED) {
        openDialog(QuickViewDialogType.expanded);
        return totalCallflowIds;
      }

      if (totalCallflowIds.length < QUICKVIEW_CONSTANTS.MAXIMUM.MINIMIZED) {
        return totalCallflowIds.includes(callflowId)
          ? totalCallflowIds
          : [...totalCallflowIds, callflowId];
      }

      openDialog(QuickViewDialogType.mimimized);
      return totalCallflowIds;
    });
  };

  const onEditClicked = () => setIsDrawerOpen(false);

  return (
    <>
      <StyledCallflowDrawer ref={refDrawer}>
        <Drawer
          testId={TEST_ID.PHONE_SYSTEM.CALLFLOW.DRAWER}
          isOpen={isDrawerOpen}
          height={CALLFLOW_CONTAINER_CONSTANTS.HEIGHT.DEFAULT}
          headerProps={{
            text: t('phone_system:containers.callflows.quick_view.title'),
            onCollapse: setIsDrawerOpen,
          }}
        >
          {isDrawerOpen && (
            <DrawerContent
              callflowId={id}
              quickViewCallflowIds={quickViewTotalCallflowIds}
              onQuickViewClicked={(callflowId: string) => handleQuickViewClick(callflowId)}
              onEditClicked={onEditClicked}
            />
          )}
        </Drawer>
        <QuickViewContainer
          isMainDrawerOpen={isDrawerOpen}
          callflowIds={quickViewTotalCallflowIds}
          expandedCallflowIds={quickViewExpandedCallflowIds}
          onCallflowClosed={(callflowId: string) => handleCallflowClose(callflowId)}
          onEditClicked={onEditClicked}
          setExpandedCallflowIds={setQuickViewExpandedCallflowIds}
        />
      </StyledCallflowDrawer>
      <QuickViewDialog
        isOpen={isDialogOpen.expanded}
        type={QuickViewDialogType.expanded}
        onClose={handleDialogAction}
      />
      <QuickViewDialog
        isOpen={isDialogOpen.minimized}
        type={QuickViewDialogType.mimimized}
        onClose={handleDialogAction}
      />
    </>
  );
};

export default CallflowDrawer;
