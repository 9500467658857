import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { ACTION_CHECK } from '../../constants';

const schema = () =>
  Joi.object({
    action: Joi.string().valid(ACTION_CHECK),
    callerid_match_login: Joi.boolean(),
    id: Joi.string().optional(),
    interdigit_timeout: Joi.number().min(0),
    max_message_length: Joi.number().min(0),
    nodeId: Joi.string(),
    single_mailbox_login: Joi.boolean(),
  }).messages(messages());

export default schema;
