import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'shared/components/Select';
import { FilterOption, UseFilterOptionsParams as Params } from './definition';

export { FilterOption };

export const useFilterOptions = ({ hasAllOption, options }: Params) => {
  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useState<Array<SelectOption>>([]);

  useEffect(() => {
    setFilterOptions(
      hasAllOption
        ? [
            { label: t('common:component.filter_select.option.all'), value: FilterOption.All },
          ].concat(options)
        : options,
    );
  }, [hasAllOption, options, t]);

  return { filterOptions };
};
