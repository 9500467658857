import { TextareaResize } from 'shared/components/Textarea';
import { WidthType } from 'shared/utility/definition';
import labeledProps from '../../default';

const defaultProps = {
  ...labeledProps,
  label: '__TEXTAREA_LABEL__',
  labelWidth: 'large' as WidthType,
  labelProps: {},
  inputWidth: 'large' as WidthType,
  inputProps: {},
  resize: TextareaResize.Vertical,
};

export default defaultProps;
