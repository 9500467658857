import { CHARACTER } from 'constant';
import Box from 'shared/components/Box';

const TableCellSeatName = ({ isUnassigned, name }: { isUnassigned: boolean; name: string }) => (
  <Box component={isUnassigned ? 'em' : 'span'} {...(isUnassigned ? { role: 'emphasis' } : {})}>
    {name || CHARACTER.EMDASH}
  </Box>
);

export default TableCellSeatName;
