import defaultProps from './default';
import { FileDataProps as Props } from './definition';
import { StyledFileData } from './style';

export default (props: Props) => {
  const { children, hasMargin } = { ...defaultProps, ...props };
  return (
    <StyledFileData id="fileupload-file-data" hasmargin={hasMargin.toString()}>
      {children}
    </StyledFileData>
  );
};
