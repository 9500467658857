import { usePatchAccountByIdMutation } from 'models/Account';
import { useDeleteIdpMutation } from 'models/IAM';
import { FunctionComponent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Dialog, { DialogType } from 'shared/components/Dialog';
import DialogActions, {
  DialogActionsCloseReasons,
} from 'shared/components/Dialog/components/DialogActions';
import { useToast } from 'shared/hooks/useToast';
import { FormFields as SsoSectionFormFields } from '../../../../definition';
import WarningBar from './components/WarningBar';
import { DeleteIdpButtonProps as Props } from './definition';

const DeleteIdpButton: FunctionComponent<Props> = ({ accountId, onDelete }: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const [deleteIdpById] = useDeleteIdpMutation();
  const [patchAccountById] = usePatchAccountByIdMutation();

  const onOpenConfirmDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  const onClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const onDeleteIdp = async () => {
    try {
      await patchAccountById({
        id: accountId,
        body: {
          [SsoSectionFormFields.SsoEnabled]: false,
        },
      }).unwrap();
      await deleteIdpById().unwrap();
      onDelete?.();
      onClose();
      showToast.success();
    } catch (exception) {
      console.log(exception);
      showToast.error();
    }
  };

  const handleDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onClose();
        break;
      case 'saveClicked':
        onDeleteIdp();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Button color="error" variant="outlined" onClick={onOpenConfirmDialog}>
        {t('common:delete')}
      </Button>
      <Dialog
        open={isConfirmDialogOpen}
        renderActions={
          <DialogActions
            cancelLabel={t('common:cancel')}
            saveButtonProps={{
              color: 'error',
              label: t('common:delete'),
              variant: 'contained',
            }}
            onAction={handleDialogAction}
          />
        }
        title={t('common:confirmation')}
        type={DialogType.Large}
        onClose={onClose}
      >
        <WarningBar />
        <p>
          <Trans i18nKey="phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.delete_idp.confirm.description">
            Are you sure you want to delete this Identity Provider? This might impact information
            available in your account and <strong>cannot be undone</strong>.{' '}
          </Trans>
        </p>
      </Dialog>
    </>
  );
};

export default DeleteIdpButton;
