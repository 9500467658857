import { themeColor } from 'theme';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      '&.MuiPagination-root': {
        '.MuiPagination-ul': {
          li: {
            height: 32,
            margin: 0,
            button: {
              margin: 0,
              padding: 0,
              color: themeColor.core.text.tertiary,
              fontSize: 14,
              transition: theme.transitions.create(['background-color', 'color'], {
                duration: theme.transitions.duration.standard,
              }),
            },
            '&:hover': {
              'button:not(:disabled)': {
                backgroundColor: themeColor.core.text.tertiary,
                color: `${themeColor.core.color.white} !important`,
              },
            },
            '&:not(:first-of-type):not(:last-of-type)': {
              backgroundColor: themeColor.core.background.grey.medium,
              button: {
                width: 28,
                minWidth: 28,
                borderRadius: 6,
                '&.Mui-selected': {
                  backgroundColor: themeColor.core.text.tertiary,
                  color: themeColor.core.color.white,
                },
              },
            },
            '&:nth-of-type(2)': {
              paddingLeft: theme.spacing(1.6666),
              borderTopLeftRadius: 16,
              borderBottomLeftRadius: 16,
            },
            '&:nth-last-of-type(2)': {
              paddingRight: theme.spacing(1.6666),
              borderTopRightRadius: 16,
              borderBottomRightRadius: 16,
            },
            '&:first-of-type, &:last-of-type': {
              width: 32,
              borderRadius: '50%',
              button: {
                backgroundColor: themeColor.core.background.grey.medium,
              },
            },
            '&:first-of-type': {
              margin: theme.spacing(0, 1, 0, 0),
            },
            '&:last-of-type': {
              margin: theme.spacing(0, 0, 0, 1),
            },
            '.MuiPaginationItem-ellipsis': {
              color: themeColor.core.text.tertiary,
            },
          },
        },
      },
    },
  },
};
