import { useTranslation } from 'react-i18next';
import Dialog, { DialogActions, DialogType } from 'shared/components/Dialog';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { DataRestorationDialogProps as Props } from './definition';

export const DataRestorationDialog = ({ isOpen, onClose, onAction }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      dataTestId={
        TEST_ID.PHONE_SYSTEM.ACCOUNT.SETTINGS.TEAMCHAT_ENABLEMENT_SECTION.DATA_RESTORATION_DIALOG
      }
      renderActions={
        <DialogActions cancelLabel={t('common:dismiss')} hasCancelOnly onAction={onAction} />
      }
      title={t(
        'phone_system:containers.account.submodule.settings.section.team_chat_enablement.data_restoration_dialog.title',
      )}
      type={DialogType.Large}
      onClose={onClose}
    >
      {t(
        'phone_system:containers.account.submodule.settings.section.team_chat_enablement.data_restoration_dialog.content',
      )}
    </Dialog>
  );
};
