import Joi from 'joi';
import { messages } from 'shared/utility/validation';

const schema = () =>
  Joi.object({
    alert_info: Joi.string().required(),
    nodeId: Joi.string(),
  }).messages(messages());

export default schema;
