import { joiResolver } from '@hookform/resolvers/joi';
import { BuyNumbers } from 'apps/shared/components/BuyNumbers';
import {
  HookFormInputWrapper,
  HookFormRadioGroupWrapper,
  HookFormSelectWrapper,
} from 'apps/shared/components/HookForm';
import { selectNumbers } from 'models/Callflow/store/selectors';
import { useFetchPhoneNumbersQuery, useLazyFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from 'shared/components/Box';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import { LabeledInput, LabeledRadioGroup, LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import { AddNumberDialogProps as Props, NumberType } from './definition';
import { schema } from './schema';
import { getDefaultValues, getOptions } from './utility';

const AddNumberDialog = ({ isOpen, onClose }: Props) => {
  const { id: callflowId = '' } = useParams();
  const { t } = useTranslation();
  const [numbers, setNumbers] = useState<Array<SelectOption>>([]);
  const newNumbers = useSelector((state) => selectNumbers(state, callflowId));
  const options = getOptions();
  const defaultValues = getDefaultValues(options);

  const { data, refetch: refetchPhoneNumbers } = useFetchPhoneNumbersQuery();
  const [fetchPhoneNumbers] = useLazyFetchPhoneNumbersQuery();

  const { control, clearErrors, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema(options)),
  });

  const onDialog = {
    action: async (closeResponse: { reason: DialogActionsCloseReasons }) => {
      switch (closeResponse.reason) {
        case 'saveClicked':
          handleSubmit((data: any) => {
            onDialog.close(data[watch('numberType')]);
          })();
          break;
        default:
          onDialog.close();
          break;
      }
    },
    close: (numberType?: NumberType) => {
      clearErrors();
      reset(defaultValues);
      onClose(numberType);
    },
  };

  const onFlowComplete = useCallback(
    ({ success }: any) => {
      fetchPhoneNumbers().then(() => {
        if (Array.isArray(success) && success.length >= 1) {
          setValue('spare', success[0], { shouldDirty: true });
        }
      });
    },
    [fetchPhoneNumbers, setValue],
  );

  useEffect(() => {
    if (data?.usage?.spare) {
      const filteredNumbers = Object.keys(data.usage.spare)
        .filter((number) => !newNumbers?.includes(number))
        .map((number: string) => ({ label: number, value: number }));

      setNumbers(filteredNumbers);
    }
  }, [data, newNumbers]);

  return (
    <Dialog
      title={t('phone_system:containers.callflows.callflow_edit_dialog.add_number.title')}
      open={isOpen}
      onClose={() => onDialog.close()}
      renderActions={<DialogActions onAction={onDialog.action} />}
    >
      {/** Options */}
      <HookFormRadioGroupWrapper
        name="numberType"
        control={control}
        options={Object.values(options)}
      >
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledRadioGroup
            isLabelAbove
            feedback={feedback}
            label={t('common:options')}
            radioGroupProps={{ ...formProps }}
            radioProps={Object.values(options)}
            data-test-id="radio-btn-add-numbers"
          />
        )}
      </HookFormRadioGroupWrapper>

      {/** Spare Numbers */}
      {watch('numberType') === options.spare.value && (
        <HookFormSelectWrapper name="spare" control={control} options={numbers}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <Box role="row" className="split split-with-button">
              <Box role="cell">
                <LabeledSelect
                  isLabelAbove
                  isDirty={isDirty}
                  data-test-id="select-spare-numbers"
                  feedback={feedback}
                  id="select-spare-numbers"
                  label={t(
                    'phone_system:containers.callflows.callflow_edit_dialog.add_number.input.spare_numbers',
                  )}
                  selectProps={{
                    ...formProps,
                    onMenuOpen: () => refetchPhoneNumbers(),
                  }}
                />
              </Box>
              <Box role="cell">
                <BuyNumbers hasMargin buttonVariant="outlined" onFlowComplete={onFlowComplete} />
              </Box>
            </Box>
          )}
        </HookFormSelectWrapper>
      )}

      {/** Extension */}
      {watch('numberType') === options.extension.value && (
        <HookFormInputWrapper name="extension" control={control}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isLabelAbove
              isDirty={isDirty}
              feedback={feedback}
              inputProps={{
                ...formProps,
                autoFocus: true,
                id: 'input-extension',
              }}
              label={t(
                'phone_system:containers.callflows.callflow_edit_dialog.add_number.input.extension',
              )}
            />
          )}
        </HookFormInputWrapper>
      )}
    </Dialog>
  );
};

export default AddNumberDialog;
