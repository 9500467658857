export const defaultValues = {
  name: '',
  record_pin: '',
  retries: '2',
  timeout: 10000,
  max_extension_length: '4',
  hunt: false,
  hunt_allow: '',
  hunt_deny: '',
  allow_record_from_offnet: false,
  suppress_media: false,
  media: {
    greeting: undefined,
    invalid_media: true,
    transfer_media: true,
    exit_media: true,
  },
};
