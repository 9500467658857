import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFlowChildHeadProps as Props } from './definition';

export const StyledFlowChildHead = styled.div.attrs({ className: 'flowchild-head' })<Props>`
  ${(props) => css`
    ${mixin.borderRadius({ radius: 'half' })};
    ${mixin.font({ size: 12, weight: 500, lineHeight: '14px' })};
    width: calc(var(--width) + 2px);
    height: 16px;
    line-height: 16px;
    margin: 0 auto 4px;
    background-color: ${props.theme.whitelabel.secondary.color};
    color: ${props.theme.whitelabel.secondary.color};
    color: ${props.theme.core.color.white};
    text-align: center;

    ${!props.isPreview &&
    css`
      cursor: pointer;
    `}
  `}
`;
