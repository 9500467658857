import { MEDIA_TYPE } from 'constant';
import i18next from 'i18next';

/**
 * @name getDownloadValues
 * @description  Generates the download values, including the name and a downloadable CSV URL.
 *
 * @param [object]
 * @property error - List of phone numbers that encountered errors
 * @property success - List of phone numbers purchased successfully
 *
 * @returns Object containing the name and URL for download.
 */
export const getDownloadValues = ({ error, success }: { error: string[]; success: string[] }) => {
  if (!error.length && !success.length) {
    return { name: '', url: '' };
  }

  const successfulPurchasedText = i18next.t(
    'numbers:action_row.add_number.dialog.summary_numbers.download.file.columns.successful_purchased',
  );
  const unsuccessfulPurchaseText = i18next.t(
    'numbers:action_row.add_number.dialog.summary_numbers.download.file.columns.unsuccessful_purchased',
  );

  const csvRows = success
    .map((number) => `"${number}",${successfulPurchasedText}\n`)
    .concat(error.map((number) => `${number},${unsuccessfulPurchaseText}\n`))
    .join('');

  const csvBlob = [
    i18next
      .t('numbers:action_row.add_number.dialog.summary_numbers.download.file.columns.title')
      .concat(csvRows),
  ];

  return {
    name: i18next.t('numbers:action_row.add_number.dialog.summary_numbers.download.file.name'),
    url: URL.createObjectURL(
      new Blob(csvBlob, {
        type: MEDIA_TYPE.TYPE.TEXT.CSV.WITH_CHARSET,
      }),
    ),
  };
};
