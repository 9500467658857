import useSetSelectValue from 'apps/PhoneSystem/hooks/useSetSelectValue';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import kebabCase from 'lodash/kebabCase';
import { useFetchMenusQuery } from 'models/Menu';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { SelectInstance } from 'react-select';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import defaultProps from './default';
import { MenuSelectProps as Props } from './definition';
import StyledMenuSelect from './style';

const MenuSelect: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { name, label, control, isLabelAbove, tooltip, itemName }: Props = {
    ...defaultProps,
    ...props,
  };
  const [menus, setMenus] = useState<Array<SelectOption>>([]);
  const { data, error, isLoading } = useFetchMenusQuery();
  const selectRef = useRef<SelectInstance>(null);

  useEffect(() => {
    if (data) {
      setMenus(data.map((menu) => ({ value: menu.id, label: menu.name })));
    }
  }, [data]);

  useSetSelectValue({ itemName, selectRef, options: menus });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledMenuSelect>
      <HookFormSelectWrapper control={control} name={name} options={menus}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove={isLabelAbove}
            label={label}
            tooltip={tooltip}
            selectProps={{
              ...formProps,
              id: `select-menu-${kebabCase(label)}`,
            }}
            ref={selectRef}
          />
        )}
      </HookFormSelectWrapper>
    </StyledMenuSelect>
  );
};

export default MenuSelect;
