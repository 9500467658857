import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { StyledTableCellIncomingCallProps as Props } from './definition';

const CONSTANTS = {
  GRID: {
    COLUMN: ['36px', '302px'],
    ROW: '40px',
  },
  HEIGHT: ['52px', '86px'],
  WIDTH: '338px',
};

const StyledTableCellIncomingCall = styled(Box)<Props>`
  ${({ hasskills, theme }) => css`
    display: grid;
    grid-template: ${CONSTANTS.GRID.ROW} / ${CONSTANTS.GRID.COLUMN[0]} ${CONSTANTS.GRID.COLUMN[1]};
    align-items: center;
    width: ${CONSTANTS.WIDTH};
    height: ${CONSTANTS.HEIGHT[hasskills === 'true' ? 1 : 0]};
    padding: ${theme.spacing(1)};
    overflow: clip;

    * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > div {
      margin: ${theme.spacing(1.33, 1, 1.33, 0)};
    }
  `}
`;

export default StyledTableCellIncomingCall;
