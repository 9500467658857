import Tab from '@mui/material/Tab';
import styled, { css } from 'styled-components';

const StyledTab = styled(Tab)`
  ${({ icon }) =>
    Boolean(icon) &&
    css`
      > div {
        order: 1;
      }
    `};
`;

export default StyledTab;
