import get from 'lodash/get';
import { DataSource } from '../definition';

export const transformData = (data: {
  tts?: { [key: string]: any };
  file?: File;
  content_length: number;
  content_type?: string;
  description?: string;
}) => {
  const dataSource = get(data, 'media_source');
  if (dataSource === DataSource.Upload) {
    if (data.tts) {
      delete data.tts;
    }
    if (data.file) {
      data.content_length = data.file.size;
    }
  } else if (dataSource === DataSource.Tts) {
    delete data.content_type;
    delete data.description;
  }
};
