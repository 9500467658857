import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { EditFormProps } from 'apps/PhoneSystem/definition';
import { StyledSinglePanel } from 'apps/PhoneSystem/style';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import { handleRequestError } from 'apps/shared/utility';
import { ADD_KEY } from 'constant';
import { useFetchAccountQuery } from 'models/Account';
import {
  schema,
  useCreateBlocklistMutation,
  useDeleteBlocklistMutation,
  useFetchBlocklistByIdQuery,
  useUpdateBlocklistMutation,
} from 'models/Blocklist';
import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UploadFileType } from 'shared/components/FileUpload/definition';
import { LabeledInput, LabeledSwitch } from 'shared/components/Labeled';
import LabeledFileUpload from 'shared/components/Labeled/components/LabeledFileUpload';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { CSVToArray } from 'shared/utility';
import { AlertContext } from 'store/contexts';
import useToggleEnabled from '../hooks/useToggleEnabled';
import { getIsBlockingEnabled } from '../utility';
import LabeledNumbersList, { convertToBlocklistNumbers } from './components/LabeledNumbersList';
import { defaultValues } from './default';
import { FormInput } from './definition';
import { filterCSVNumber } from './utility';

const Edit = ({ id, handleSaveSuccess, handleDeleteSuccess, handleCancel }: EditFormProps) => {
  const { t } = useTranslation();
  const { showErrorMessage } = useShowErrorMessage();
  const { toggleBlocklist } = useToggleEnabled();
  const alertCtx = useContext(AlertContext);

  const { data: account } = useFetchAccountQuery();
  const { data, isLoading } = useFetchBlocklistByIdQuery({ id }, { skip: id === ADD_KEY });
  const { data: accountData, isLoading: isLoadingAccountData } = useFetchAccountQuery();
  const [updateBlocklist] = useUpdateBlocklistMutation();
  const [deleteBlocklist] = useDeleteBlocklistMutation();
  const [createBlocklist] = useCreateBlocklistMutation();

  const formMethods = useForm<FormInput>({
    defaultValues,
    resolver: joiResolver(schema()),
  });
  const {
    handleSubmit,
    setError,
    formState: { isDirty, dirtyFields },
    getValues,
    reset,
  } = formMethods;
  const isPageDirty = isDirty && Object.keys(dirtyFields).length > 0; // TODO: This can be implemented better; let's try

  useEffect(() => {
    // Populates form components once data is loaded and keep the file
    reset({
      ...defaultValues,
      ...data,
      isBlockingEnabled: getIsBlockingEnabled(data?.id, accountData?.blacklists),
      file: getValues('file'),
    });
  }, [data, account, reset, accountData?.blacklists, getValues]);

  const handleDelete = async () => {
    reset({}, { keepValues: true, keepDirty: false });

    await alertCtx.call(deleteBlocklist, { id }).then(() => handleDeleteSuccess?.());
  };

  const onSubmit: SubmitHandler<FormInput> = async (blacklist: FormInput) => {
    const { isBlockingEnabled, ...newData } = blacklist;

    try {
      const updatedList: Blocklist | void =
        id === ADD_KEY
          ? await alertCtx.call(createBlocklist, { body: newData })
          : await alertCtx.call(updateBlocklist, { id, body: newData });

      await toggleBlocklist(isBlockingEnabled, updatedList);

      handleSaveSuccess?.({ shouldRedirect: id === ADD_KEY });
    } catch (exception) {
      handleRequestError(exception, setError);
    }
  };

  const onSave = handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }));

  const onFileUpload = (file: any) => {
    const reader = new FileReader();

    reader.onload = function (progressEvent: ProgressEvent<FileReader>) {
      if (typeof this.result === 'string') {
        const result = CSVToArray(this.result);
        const numbers: string[] = [...new Set(result.flatMap((x: string[]) => x))];

        const convertedBlocklistNumbers = convertToBlocklistNumbers(numbers);
        const oldNumbers = getValues('numbers') || [];
        const updatedNumbers = { ...convertedBlocklistNumbers, ...oldNumbers };

        formMethods.setValue('numbers', updatedNumbers, { shouldDirty: true });
      }
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  const onFileDelete = (file?: File) => {
    const reader = new FileReader();

    reader.onload = function (progressEvent: ProgressEvent<FileReader>) {
      if (typeof this.result === 'string') {
        const resultFromCSV = CSVToArray(this.result);

        // Unique numbers from CSV
        const numbersFromCSV: string[] = [...new Set(resultFromCSV.flatMap((x: string[]) => x))];
        const convertedBlocklistNumbers = convertToBlocklistNumbers(numbersFromCSV);

        // Filter number from CSV
        const oldNumbers = getValues('numbers') || [];
        const updatedNumbers = filterCSVNumber(oldNumbers, convertedBlocklistNumbers);

        formMethods.setValue('numbers', updatedNumbers, { shouldDirty: true });
      }
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  if (isLoading || isLoadingAccountData) {
    return <Loading />;
  }

  return (
    <EditForm
      entityLabel={t('phone_system:containers.call_blocking.label')}
      entityName={data?.name ?? ''}
      onSave={onSave}
      onDelete={id !== ADD_KEY ? handleDelete : undefined}
      onCancel={handleCancel}
      isPageDirty={isPageDirty}
      formMethods={formMethods}
    >
      <EditPanel isSinglePanel>
        <StyledSinglePanel>
          <div>
            {/* Name */}
            <HookFormInputWrapper name="name">
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  id="input-callblocking-name"
                  isDirty={isDirty}
                  isLabelAbove
                  feedback={feedback}
                  label={t('phone_system:containers.call_blocking.field.name.title')}
                  inputProps={{
                    ...formProps,
                  }}
                />
              )}
            </HookFormInputWrapper>

            {/* Enabled */}
            <HookFormInputWrapper name="isBlockingEnabled" isCheckbox>
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledSwitch
                  hasSmallMargin
                  isDirty={isDirty}
                  isSwitchLeft
                  feedback={feedback}
                  indentWidth="none"
                  label={t('phone_system:containers.call_blocking.field.enabled.title')}
                  switchProps={{
                    ...formProps,
                  }}
                />
              )}
            </HookFormInputWrapper>

            {/* Block Anonymous */}
            <HookFormInputWrapper name="should_block_anonymous" isCheckbox>
              {({ ref, value, isDirty, ...formProps }) => (
                <LabeledSwitch
                  hasMargin
                  isDirty={isDirty}
                  isSwitchLeft
                  indentWidth="none"
                  label={t('phone_system:containers.call_blocking.field.block_anonymous.title')}
                  switchProps={{
                    ...formProps,
                  }}
                />
              )}
            </HookFormInputWrapper>

            {/* Numbers */}
            <HookFormInputWrapper name="numbers">
              {({ value, isDirty, onChange }) => (
                <LabeledNumbersList data={value} onChange={onChange} isDirty={isDirty} />
              )}
            </HookFormInputWrapper>

            {/* Upload a CSV */}
            <HookFormInputWrapper name="file">
              {({ ref, onChange, value, ...formProps }) => (
                <LabeledFileUpload
                  {...formProps}
                  id="upload-csv-file"
                  label={t('phone_system:containers.call_blocking.field.upload.title')}
                  inputWidth="large"
                  isLabelAbove
                  inputProps={{
                    name: 'upload-csv-file',
                    value,
                    fileType: UploadFileType.CSV,
                    onChange: (file) => {
                      onChange(file);
                      onFileUpload(file);
                    },
                    onDelete: onFileDelete,
                  }}
                />
              )}
            </HookFormInputWrapper>
          </div>
        </StyledSinglePanel>
      </EditPanel>
    </EditForm>
  );
};

export default Edit;
