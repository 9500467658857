import i18next from 'i18next';

export const getDurationText = (hours: number, minutes: number) => ({
  hours:
    hours > 0
      ? `${hours} ${
          hours === 1
            ? i18next.t('phone_system:containers.time_of_day.component.duration.label.hour')
            : i18next.t('phone_system:containers.time_of_day.component.duration.label.hours')
        }`
      : '',
  minutes:
    minutes > 0
      ? `${minutes} ${i18next.t(
          'phone_system:containers.time_of_day.component.duration.label.minutes',
        )}`
      : '',
});
