import { ACCOUNT_ID_URL_PLACEHOLDER } from '../constant';

const Queues = {
  // GET
  Fetch: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues`,
  FetchById: (id: string, isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues/${id}/?paginate=${isPaginated}`,
  FetchUsers: (id: string, isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues/${id}/roster/?paginate=${isPaginated}`,
  FetchStatsSummary: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues/stats_summary`,

  // PUT
  create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues/`,
  addEavesdrop: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues/${id}/eavesdrop`,
  addCallEavesdrop: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues/eavesdrop`,

  // POST
  updateById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues/${id}`,
  updateUsers: (id: string, isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues/${id}/roster/?paginate=${isPaginated}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues/${id}`,
  DeleteMember: (id: string, callId: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/queues/${id}/members/${callId}`,
};

export default Queues;
