import i18next from 'i18next';
import { Cell, Justify, TABLE_CONSTANTS } from 'shared/components/Table';
import TableCellPresence from '../components/TableCellPresence';
import { UserPrivilege } from '../definition';
import { Columns } from './definition';

const COLUMN_WIDTH = 220;

export const columns = ({
  isLoading,
  isLoadingDevices,
  isLoadingDeviceStatuses,
  isSuccess,
  isSuccessDeviceStatuses,
  devicesData,
  deviceStatusesData,
  deviceStatusesError,
  error,
  seatTypes,
  getLocationLabelById,
}: Columns) => [
  {
    Header: i18next.t('phone_system:containers.seats.table.column.seat'),
    accessor: 'username',
    width: COLUMN_WIDTH,
  },
  {
    Header: i18next.t('phone_system:containers.seats.table.column.first_name'),
    accessor: 'first_name',
    width: COLUMN_WIDTH,
  },
  {
    Header: i18next.t('phone_system:containers.seats.table.column.last_name'),
    accessor: 'last_name',
    width: COLUMN_WIDTH,
  },
  {
    Header: i18next.t('phone_system:containers.seats.table.column.seat_type'),
    accessor: ({ seat_type }: any) => seatTypes[seat_type]?.display_name,
    width: COLUMN_WIDTH,
  },
  {
    Header: i18next.t('phone_system:containers.seats.table.column.seat_location'),
    accessor: ({ location }: any) => getLocationLabelById(location),
    width: COLUMN_WIDTH,
  },
  {
    Header: i18next.t('phone_system:containers.seats.table.column.user_privilege'),
    Cell: ({ value }: Cell<Record<UserPrivilege, string>>) => {
      /** Cannot use predefined hashmap as i18next translation executed after this function */
      switch (value) {
        case UserPrivilege.Admin:
          return i18next.t('phone_system:containers.seats.user_privilege.admin');
        case UserPrivilege.User:
          return i18next.t('phone_system:containers.seats.user_privilege.user');
        default:
          return '';
      }
    },
    accessor: 'priv_level',
    width: COLUMN_WIDTH,
  },
  {
    Header: i18next.t('phone_system:containers.seats.table.column.presence.header'),
    Cell: ({
      row: {
        original: { id },
      },
    }: any) => (
      <TableCellPresence
        isError={error || deviceStatusesError}
        isLoading={isLoading || isLoadingDevices || isLoadingDeviceStatuses}
        isSuccess={isSuccess && isSuccessDeviceStatuses}
        id={id}
        devicesData={devicesData}
        deviceStatusesData={deviceStatusesData}
      />
    ),
    id: `seats.presence.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
  },
];
