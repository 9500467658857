import { FunctionComponent } from 'react';
import { StatusProps as Props } from './definition';
import StyledStatus from './style';

const Status: FunctionComponent<Props> = ({
  data: { indicator, description },
}: Props): JSX.Element => (
  <StyledStatus indicator={indicator}>
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" />
    </svg>
    <span>{description}</span>
  </StyledStatus>
);

export default Status;
