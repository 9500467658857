import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledDashboardQueuesProps as Props } from './definition';

const StyledDashboardQueues = styled(Box)<Props>`
  ${({ theme }) => css`
    ${mixin.flex({
      justifyContent: 'flex-start',
      gap: theme.spacing(2.66),
    })};
    width: 100%;
    background-color: ${theme.brio.background.default};
  `}
`;

export default StyledDashboardQueues;
