import { LOCAL_STORAGE } from 'constant';
import { useFetchAccountConfigsQuery } from 'models/Account';
import { useListBySeatTypeQuery } from 'models/Seat';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button';
import Loading from 'shared/components/Loading';
import Typography from 'shared/components/Typography';
import DashboardPanel from '../DashboardPanel';
import CountList from '../shared/components/CountList';
import { CountList as ICountList } from '../shared/components/CountList/definition';
import { DashboardPanelSeatsProps as Props } from './definition';

const DashboardPanelSeats: FunctionComponent<Props> = (): JSX.Element => {
  const [seatTypeData, setSeatTypeData] = useState<ICountList>({});
  const { t } = useTranslation();
  let navigate = useNavigate();
  const currentAccountId =
    useSelector((state: any) => state.account.id) ||
    localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID);

  const {
    data: accountConfigs,
    error: accountSeatTypeErrors,
    isLoading: isAccountSeatTypesLoading,
  } = useFetchAccountConfigsQuery();
  const { data: seatTypeValues, error, isLoading } = useListBySeatTypeQuery({
    accountId: currentAccountId,
  });

  useEffect(() => {
    if (!accountConfigs || isLoading) return;

    setSeatTypeData(
      seatTypeValues.reduce((acc: ICountList, val: { key: string; value: number }) => {
        const configSeatType = accountConfigs.seat_types[val.key];
        // How is this going to be i18n-ed?
        // Should it be translated since the display_name is account based stored in the database.
        if (configSeatType) {
          acc[configSeatType.display_name] = val.value;
        }
        return acc;
      }, {}),
    );
  }, [accountConfigs, seatTypeValues]);

  if (isLoading) return <Loading />;

  return (
    <DashboardPanel type="seats">
      <>
        <div>
          <Typography variant="h2">
            {t('phone_system:containers.dashboard.panel.seats.heading.seats')}
          </Typography>
          <span>
            {Object.values(seatTypeData).reduce((acc: number, value: number) => acc + value, 0)}
          </span>
          <Button color="secondary" variant="contained" onClick={() => navigate('../seats')}>
            {t('phone_system:containers.dashboard.panel.seats.button.add_seat')}
          </Button>
        </div>
        <div>
          <Typography variant="h2">
            {t('phone_system:containers.dashboard.panel.seats.heading.service_plan')}
          </Typography>
          <CountList list={seatTypeData} />
        </div>
      </>
    </DashboardPanel>
  );
};

export default DashboardPanelSeats;
