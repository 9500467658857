import { themeColor } from 'theme';
import muiCardDashboardPanelAccount from './muiCard-dashboard-panel-account';
import muiCardDashboardPanelKnowledge from './muiCard-dashboard-panel-knowledge';
import muiCardDashboardPanelNumbers from './muiCard-dashboard-panel-numbers';
import muiCardDashboardPanelReporting from './muiCard-dashboard-panel-reporting';
import muiCardDashboardPanelSeats from './muiCard-dashboard-panel-seats';
import muiCardDashboardPanelStatus from './muiCard-dashboard-panel-status';

export default {
  padding: 24,
  boxShadow: '0 1px 4px 0 rgb(0 0 0 / 25%)',
  '.MuiCardContent-root': {
    height: 'calc(100% - 40px)',
    padding: 0,
    '.MuiTypography-h2': {
      margin: '0 0 24px',
      color: themeColor.core.text.dark,
      fontSize: 22,
      fontVariant: 'small-caps',
      fontWeight: 600,
      lineHeight: '0.5em',
      textTransform: 'lowercase',
      userSelect: 'none',
    },
  },
  '.MuiCardActions-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    padding: 0,
    margin: 'auto 0 0',
  },
  '&.MuiCard-dashboard-panel-account': muiCardDashboardPanelAccount,
  '&.MuiCard-dashboard-panel-knowledge': muiCardDashboardPanelKnowledge,
  '&.MuiCard-dashboard-panel-numbers': muiCardDashboardPanelNumbers,
  '&.MuiCard-dashboard-panel-reporting': muiCardDashboardPanelReporting,
  '&.MuiCard-dashboard-panel-seats': muiCardDashboardPanelSeats,
  '&.MuiCard-dashboard-panel-status': muiCardDashboardPanelStatus,
};
