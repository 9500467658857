export enum CycleType {
  Daily = 'daily',
  Date = 'date',
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly',
}

export enum DurationType {
  Start = 'start',
  Stop = 'stop',
}

export enum EnabledType {
  BasedOnTime = 'based_on_time',
  ForcedOff = 'forced_off',
  ForcedOn = 'forced_on',
}

export enum OrdinalType {
  First = 'first',
  Second = 'second',
  Third = 'third',
  Fourth = 'fourth',
  Fifth = 'fifth',
  Last = 'last',
  Every = 'every',
  Range = 'range',
}

export enum WdaysType {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wensday', // kazoo spelling
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export enum FormFields {
  Cycle = 'cycle',
  Days = 'days',
  Duration = 'duration',
  Enabled = 'enabled',
  EndDate = 'end_date',
  HasNoEndDate = 'has_no_end_date',
  Interval = 'interval',
  IsAllDayEvent = 'is_all_day_event',
  Month = 'month',
  Name = 'name',
  Ordinal = 'ordinal',
  RangeStart = 'range_start',
  RangeStop = 'range_stop',
  StartDate = 'start_date',
  TimeWindowStart = 'time_window_start',
  TimeWindowStop = 'time_window_stop',
  Wdays = 'wdays',
}

export interface FormInput {
  [FormFields.Cycle]: CycleType;
  [FormFields.Days]: Array<string>;
  [FormFields.Duration]: Record<DurationType, string>;
  [FormFields.Enabled]?: EnabledType;
  [FormFields.EndDate]?: string;
  [FormFields.HasNoEndDate]?: boolean;
  [FormFields.Interval]: string;
  [FormFields.IsAllDayEvent]?: boolean;
  [FormFields.Month]: string;
  [FormFields.Name]: string;
  [FormFields.Ordinal]: OrdinalType;
  [FormFields.RangeStart]?: string;
  [FormFields.RangeStop]?: string;
  [FormFields.StartDate]?: string;
  [FormFields.TimeWindowStart]?: string;
  [FormFields.TimeWindowStop]?: string;
  [FormFields.Wdays]: Array<WdaysType>;
}
