// Blocklist Queries
import { Blocklist } from 'api/blocklist';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const blocklistQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchBlocklists: builder.query<BlocklistResponse, void>({
      query: () => ({
        url: Blocklist.Fetch(),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.BLOCKLIST as const, id })),
              { type: TAGS.BLOCKLIST, id: 'LIST' },
            ]
          : [{ type: TAGS.BLOCKLIST, id: 'LIST' }],
    }),
    fetchBlocklistById: builder.query<Blocklist, { id: string }>({
      query: ({ id }: { id: string }) => ({
        url: Blocklist.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.BLOCKLIST, id }];
      },
    }),
  }),
});
