import { EndpointItemType, EndpointType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import useHorizontalTabs, { HorizontalTabItem } from 'apps/shared/hooks/useHorizontalTabs';
import { SEAT_TYPE } from 'constant';
import merge from 'lodash/merge';
import { useFetchDevicesQuery } from 'models/Device';
import { useFetchGroupsQuery } from 'models/Group';
import { useFetchUsersQuery } from 'models/User';
import { useMemo } from 'react';
import Loading from 'shared/components/Loading';
import TEST_ID from 'shared/utility/testing/constants/testId';
import TableSelection from '../TableSelection';
import defaultProps from './default';
import { TableTabsProps as Props } from './definition';
import { StyledTableTabs } from './style';
import translations from './translations';

const TableTabs = (props: Props) => {
  const {
    hasAdmins = true,
    hasUsersExtension,
    confirmations,
    fields,
    id,
    included,
    labels,
    rowCount,
    addItemHandler,
    setConfirmationMessage,
    setPendingItem,
  } = merge({}, defaultProps, props);
  const { tabs } = translations();
  const items: Array<HorizontalTabItem> = [];

  const { data: devicesData = [], isLoading: isLoadingDevices } = useFetchDevicesQuery();
  const { data: groupsData = [], isLoading: isLoadingGroups } = useFetchGroupsQuery();
  const { data: usersData = [], isLoading: isLoadingUsers } = useFetchUsersQuery();

  const data = useMemo(
    () =>
      hasAdmins
        ? usersData
        : usersData.filter((user: User) => user.seat_type !== SEAT_TYPE.admin.id),
    [hasAdmins, usersData],
  );

  const isDeviceOwnerAlreadyIncluded = (
    fields: Array<GroupEndpoint>,
    item: EndpointItemType,
    endpointType: EndpointItemType,
  ) => {
    switch (endpointType) {
      case EndpointType.User: {
        const selectedDevicesIds = fields
          .filter((endpoint: GroupEndpoint) => endpoint.endpoint_type === EndpointType.Device)
          .map((device) => device.key);
        const deviceOwnersIds = devicesData
          .filter((device: any) => selectedDevicesIds.includes(device.id))
          .map((device: any) => device.owner_id);
        return deviceOwnersIds.includes(item.id);
      }
      case EndpointType.Device: {
        const selectedUserIds = fields
          .filter((endpoint: GroupEndpoint) => endpoint.endpoint_type === EndpointType.User)
          .map((user) => user.key);
        const selectedDevice = devicesData.find((device: any) => device.id === item.id);
        return selectedUserIds.includes(selectedDevice.owner_id);
      }
      default:
        return false;
    }
  };

  const addSelectedItemWithCheck = (
    fields: Array<GroupEndpoint>,
    item: EndpointItemType,
    endpointType: EndpointType,
  ) => {
    let isIncluded = endpointType !== EndpointType.Group;

    // Check if adding duplicate user/device, if yes pop-up confirmation dialog will handle updating
    if (isIncluded) {
      isIncluded = isDeviceOwnerAlreadyIncluded(fields, item, endpointType);
      setPendingItem({ item, endpointType });

      if (isIncluded && endpointType === EndpointType.Device) {
        setConfirmationMessage(confirmations.ownerExists);
      } else if (isIncluded && endpointType === EndpointType.User) {
        setConfirmationMessage(confirmations.deviceExists);
      } else {
        setConfirmationMessage('');
      }
    }

    return isIncluded ? undefined : addItemHandler(item, endpointType);
  };

  if (included.includes(EndpointType.User)) {
    items.push({
      isDirty: false,
      component:
        data && !isLoadingUsers ? (
          <TableSelection
            hasUsersExtension={hasUsersExtension}
            data={data}
            heading={labels?.user ?? tabs.user.label}
            id={`${id}-users`}
            rowCount={rowCount}
            selectedOptions={fields}
            selectionKey={EndpointType.User}
            addSelected={addSelectedItemWithCheck}
          />
        ) : (
          <Loading />
        ),
      hash: 'users',
      label: tabs.user.label,
    });
  }

  if (included.includes(EndpointType.Device)) {
    items.push({
      isDirty: false,
      component:
        devicesData && !isLoadingDevices ? (
          <TableSelection
            data={devicesData}
            heading={labels?.device ?? tabs.device.label}
            id={`${id}-devices`}
            rowCount={rowCount}
            selectedOptions={fields}
            selectionKey={EndpointType.Device}
            addSelected={addSelectedItemWithCheck}
          />
        ) : (
          <Loading />
        ),
      hash: 'devices',
      label: tabs.device.label,
    });
  }

  if (included.includes(EndpointType.Group)) {
    items.push({
      isDirty: false,
      component:
        groupsData && !isLoadingGroups ? (
          <TableSelection
            data={groupsData}
            heading={labels?.group ?? tabs.group.label}
            id={`${id}-groups`}
            rowCount={rowCount}
            selectedOptions={fields}
            selectionKey={EndpointType.Group}
            addSelected={addSelectedItemWithCheck}
          />
        ) : (
          <Loading />
        ),
      hash: 'groups',
      label: tabs.group.label,
    });
  }

  const { Tabs, TabPanels } = useHorizontalTabs({
    items,
    options: {
      hasBottomMargin: true,
      hasScroll: false,
      isCentered: true,
    },
    onChange: () => {},
  });

  return (
    <StyledTableTabs
      data-test-id={TEST_ID.PHONE_SYSTEM.SHARED.SHARED_DIALOG.SELECTABLE_TABLE_CONTAINER}
    >
      {items.length > 1 && Tabs}
      {TabPanels}
    </StyledTableTabs>
  );
};

export default TableTabs;
