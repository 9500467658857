import { DEFAULT_KEY } from 'apps/PhoneSystem/containers/Callflows/Edit/components/SelectKeyDialog/constants';
import { current } from 'immer';
import isEqual from 'lodash/isEqual';
import { getCallflowActionConfig } from '../actions';
import { CallFlowAction } from '../definition';
import { Callflow, DropType, TreeNodeInterface } from './definition';
import { TreeNode } from './TreeNode';
import { prepareCallflowForSaving } from './utility';

// todo this should extend interface
export class CallFlowTree {
  private tree: Callflow;

  constructor(tree: Callflow) {
    this.tree = tree;
  }

  /**
   * Check whether the given node has no branching descendants
   *
   * @param tree
   * @param {Object} node - Callflow node
   * @returns {boolean} - Returns `true` if `node` has no branching descendants, else `false`
   */
  static hasNoBranchingDescendants(tree: Callflow, node: TreeNodeInterface): boolean {
    switch (Object.values(node.children).length) {
      case 0:
        return true;
      case 1:
        return tree
          ? CallFlowTree.hasNoBranchingDescendants(
              tree,
              tree?.nodes[String(Object.values(node.children)[0])],
            )
          : false;
      default:
        return false;
    }
  }

  static getDescendantNodeIds(tree: Callflow, rootNode: TreeNodeInterface) {
    const nodeIds: string[] = [rootNode.data.nodeId];
    const stack: string[] = [rootNode.data.nodeId];

    while (stack.length > 0) {
      const currentNodeId = stack.pop();

      if (currentNodeId) {
        const currentNode = tree.nodes[currentNodeId];

        Object.values(currentNode.children).forEach((nodeId) => {
          nodeIds.push(nodeId);
          stack.push(nodeId);
        });
      }
    }

    return nodeIds;
  }

  deleteBranch(targetNode: TreeNodeInterface) {
    const descendants: Array<string> = CallFlowTree.getDescendantNodeIds(this.tree, targetNode);

    descendants.forEach((nodeId: string) => {
      this.deleteNode(this.tree.nodes[nodeId]);
    });
  }

  deleteNode(targetNode: TreeNodeInterface) {
    const parentNode = this.tree.nodes[targetNode.parentId];

    if (this.tree.root === targetNode.data.nodeId) {
      // Root Node
      this.tree.root = targetNode.children?._;
    } else {
      // Inner Node
      // Need to retain the key when attaching children to parent
      const targetKey = targetNode.key;
      this.removeChild(parentNode, targetNode);
      // Should only occur when one child
      if (Object.keys(targetNode.children).length === 1) {
        const targetChildrenKeys = Object.keys(targetNode.children);
        const targetFirstChild = targetNode.children[targetChildrenKeys[0]];
        this.addChild(parentNode, this.tree.nodes[targetFirstChild], targetKey);
      }
    }

    this.removeMetaData(targetNode);

    delete this.tree.nodes[targetNode.data.nodeId];
  }

  enableTreeNewAction(activeNode: TreeNodeInterface) {
    const action: CallFlowAction = getCallflowActionConfig(activeNode.actionName);
    const isActionTerminal = TreeNode.isTerminal(action);

    this.tree.isActivated = true;
    this.tree.isDroppable = !isActionTerminal;

    Object.values(this.tree.nodes).forEach((node: TreeNodeInterface) => {
      const canInsert = !node.isTerminal && (node.isMultinary || TreeNode.isLeaf(node));

      node.isActivated = canInsert;
      node.isDroppable = canInsert;
      node.isInsertable = !isActionTerminal;
    });
  }

  enableTreeMoveBranch(activeRootNode: TreeNodeInterface) {
    const action: CallFlowAction = getCallflowActionConfig(activeRootNode.actionName);
    const isActionTerminal = TreeNode.isTerminal(action);
    const isActionMultinary = activeRootNode.isMultinary;
    const descendantNodeIds = CallFlowTree.getDescendantNodeIds(this.tree, activeRootNode);
    const hasNoBranchDescendants = CallFlowTree.hasNoBranchingDescendants(
      this.tree,
      activeRootNode,
    );
    const activeLeafNode = TreeNode.findLeafNode(this.tree, activeRootNode.data.nodeId);
    const activeLeafNodeIsTerminal = activeLeafNode.isTerminal;
    const activeLeafNodeIsMultinary = activeLeafNode.isMultinary;
    const isInsertable =
      !isActionTerminal &&
      !isActionMultinary &&
      hasNoBranchDescendants &&
      !activeLeafNodeIsTerminal &&
      !activeLeafNodeIsMultinary;

    this.tree.isActivated = true;
    this.tree.isDroppable = !activeLeafNodeIsTerminal;

    Object.values(this.tree.nodes).forEach((node: TreeNodeInterface) => {
      const isDescendant = descendantNodeIds.includes(node.data.nodeId);
      const canInsert = activeRootNode.isMultinary
        ? TreeNode.isLeaf(node) || node.isMultinary
        : node.isMultinary || TreeNode.isLeaf(node);

      node.isActivated = !node.isTerminal && canInsert && !isDescendant;
      node.isDroppable = !node.isTerminal && canInsert && !isDescendant;
      node.isInsertable = isInsertable && !isDescendant;
    });
  }

  disableTree() {
    this.tree.isActivated = false;
    this.tree.isDroppable = false;

    Object.values(this.tree.nodes).forEach((node: TreeNodeInterface) => {
      node.isActivated = false;
      node.isDroppable = false;
      node.isInsertable = false;
    });
  }

  /**
   * Ability to insert nodes into the tree
   *
   * @param activeRootNode
   * @param targetNode
   * @param dropType
   */
  insertTreeHandler(
    activeRootNode: TreeNodeInterface,
    targetNode: TreeNodeInterface,
    dropType: DropType,
  ) {
    /*
     * When inserting a branch, it could consist of many nodes
     * eg [parentNode] -> [rootNode] -> [node] -> [node] -> [leafNode]
     * so we need to update the first (rootNode) and last node in the branch (leafNode)
     */
    switch (dropType) {
      case DropType.ARROW:
        return this.arrowDropHandler(activeRootNode, targetNode);
      case DropType.NODE:
        return this.nodeDropHandler(activeRootNode, targetNode);
      case DropType.ROOT:
        return this.rootDropHandler(activeRootNode);
      default:
        return null;
    }
  }

  /**
   * Inserts activeRootNode to Root of the Tree
   *
   * @param activeRootNode
   */
  rootDropHandler(activeRootNode: TreeNodeInterface) {
    const rootId = this.tree.root;
    const activeId = activeRootNode.data.nodeId;
    const prevRootNode = this.tree.nodes[rootId];
    const activeLeafNode = TreeNode.findLeafNode(this.tree, activeId);
    const activeRootParentNode = this.tree.nodes[activeRootNode.parentId];

    if (rootId === activeId) {
      return;
    }

    this.tree.root = activeId;

    // If new node being dropped, then we don't need to worry about this
    if (activeRootParentNode) {
      this.removeChild(activeRootParentNode, activeRootNode);
    }

    // If starting from empty tree, we don't have to worry about this
    if (prevRootNode) {
      this.addChild(activeLeafNode, prevRootNode, TreeNode.getDefaultKey(activeLeafNode));
    }
  }

  /**
   * Inserts activeRootNode After targetNode
   *
   * @param activeRootNode
   * @param targetNode
   */
  nodeDropHandler(activeRootNode: TreeNodeInterface, targetNode: TreeNodeInterface) {
    const activeId = activeRootNode.data.nodeId;
    const activeLeafNode = TreeNode.findLeafNode(this.tree, activeId);

    // Dropping on self causes infinite loop
    if (activeRootNode === targetNode) {
      return;
    }
    // Issue with drop zones where this can happen
    if (activeLeafNode === targetNode) {
      return;
    }

    if (targetNode.isMultinary) {
      this.multinaryNodeHandler(targetNode, activeRootNode);
    } else {
      this.uniaryNodeHandler(targetNode, activeRootNode);
    }
  }

  /**
   * Inserts activeRootNode Before targetNode
   *
   * @param activeRootNode
   * @param targetNode
   */
  arrowDropHandler(activeRootNode: TreeNodeInterface, targetNode: TreeNodeInterface) {
    const activeId = activeRootNode.data.nodeId;
    const activeLeafNode = TreeNode.findLeafNode(this.tree, activeId);
    const activeRootParentNode = this.tree.nodes[activeRootNode.parentId];
    const targetNodeParent = this.tree.nodes[targetNode.parentId];

    // Dropped on root
    if (!targetNodeParent) {
      return this.rootDropHandler(activeRootNode);
    }

    const newChildNode = this.tree.nodes[targetNodeParent.children[DEFAULT_KEY]];

    // Dropping on self causes infinite loop
    if (activeRootNode === newChildNode) {
      return;
    }

    if (targetNodeParent.isMultinary) {
      const { key } = targetNode;

      // If new node being dropped, then we don't need to worry about this
      if (activeRootParentNode) {
        this.removeChild(activeRootParentNode, activeRootNode);
        this.removeChild(targetNodeParent, targetNode);
      }

      this.addChild(targetNodeParent, activeRootNode, key);
      this.addChild(activeLeafNode, targetNode);
    } else {
      const key = TreeNode.getDefaultKey(activeRootNode);

      // If new node being dropped, then we don't need to worry about this
      if (activeRootParentNode) {
        this.removeChild(activeRootParentNode, activeRootNode);
        this.removeChild(targetNodeParent, newChildNode);
      }

      this.addChild(targetNodeParent, activeRootNode);
      this.addChild(activeLeafNode, newChildNode, key);
    }
  }

  uniaryNodeHandler(
    targetNode: TreeNodeInterface,
    activeRootNode: TreeNodeInterface,
    key: string = DEFAULT_KEY,
  ) {
    const parentId = activeRootNode?.parentId ? activeRootNode.parentId : 'root';
    const activeRootParentNode = this.tree.nodes[parentId];

    // If new node being dropped, then we don't need to worry about this
    if (activeRootParentNode) {
      this.removeChild(activeRootParentNode, activeRootNode);
    }

    this.addChild(targetNode, activeRootNode, key);
  }

  multinaryNodeHandler(newParentNode: TreeNodeInterface, activeRootNode: TreeNodeInterface) {
    // Bring up select key dialog if can have multiple children
    // The saving of the node will have to be handled elsewhere
    const data = {
      usedKeys: Object.keys(newParentNode.children),
      key: '', // Key has to be blank to start
    };
    const action: CallFlowAction = getCallflowActionConfig(newParentNode.actionName);
    const type = action.key;
    const { nodeId } = activeRootNode.data;

    if (type) {
      this.tree.keyDialog = { data, parentNode: newParentNode, type, nodeId };
    }
  }

  isEqual(tree: any) {
    const { id: newId, flow, ...rest } = prepareCallflowForSaving(current(this.tree));
    const currentTree = { ...rest, ...flow };
    const { id: tempId, ...originalTree } = current(tree);

    return isEqual(originalTree, currentTree);
  }

  removeMetaData(node: TreeNodeInterface) {
    // Since metadata is only stored only once, multiple items might rely on the same
    // metadata so we need to check that no other nodes are using it before we delete
    const idsBeingUsed = Object.values(this.tree.nodes)
      .filter((element) => element.data.id)
      .flatMap((element) => element.data.id === node.data.id)
      .filter((element) => element);

    // Assuming metadata gets removed before the node
    if (idsBeingUsed.length === 1 && node.data.id) {
      delete this.tree.metadata[node.data.id];
    }
  }

  removeMetaDataById(id: string) {
    delete this.tree.metadata[id];
  }

  private addChild(
    parentNode: TreeNodeInterface,
    childNode: TreeNodeInterface,
    key: string = DEFAULT_KEY,
  ) {
    if (childNode && parentNode) {
      childNode.key = key;
      childNode.parentId = parentNode.data.nodeId;
      parentNode.children = { ...parentNode.children, [key]: childNode.data.nodeId };
    }

    return [parentNode, childNode];
  }

  private removeChild(parentNode: TreeNodeInterface, childNode: TreeNodeInterface) {
    if (parentNode) {
      parentNode.children = Object.fromEntries(
        Object.entries(parentNode.children).filter(([key, value]) => key !== childNode.key),
      );
    }

    childNode.key = DEFAULT_KEY;
    childNode.parentId = '';

    return [parentNode, childNode];
  }
}
