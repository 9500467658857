import LinearProgress from '@mui/material/LinearProgress';
import useDuration from 'apps/CallCenter/shared/hooks/useDuration';
import { formatDuration } from 'apps/shared/utility';
import merge from 'lodash/merge';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import Skills from '../../../Skills';
import { AgentCardStatusType } from '../../definition';
import defaultProps from './default';
import { CardContentProps as Props } from './definition';
import { getProgress, getValue } from './utility';

const CardContent: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    data: {
      hasCallback,
      activeCall,
      agentSkills,
      callsMissed,
      callsTaken,
      callsTotal,
      label,
      priority,
      statusType,
      timestamp,
    },
  } = merge({}, defaultProps, props);
  const { t } = useTranslation();
  const duration = useDuration(timestamp);

  return (
    <>
      <Box>
        <Box component="span">
          {getValue({
            statusType,
            value: label,
          })}
        </Box>
        <Box component="span">
          {getValue({
            statusType,
            statusTypes: [AgentCardStatusType.LOGGED_IN, AgentCardStatusType.READY],
            value: formatDuration(duration),
          })}
        </Box>
      </Box>
      <Box>
        <Box component="span">
          {activeCall ??
            t(
              'call_center:containers.dashboard.component.agent_card.component.card_content.no_active_call',
            )}
        </Box>
      </Box>
      <Box>
        <Skills
          elementType="span"
          skillsList={agentSkills}
          tooltipText={t(
            'call_center:containers.dashboard.component.agent_card.component.card_content.skills',
            {
              skills: agentSkills?.join(', '),
            },
          )}
        />
        <Box>
          {!!priority && priority > 0 && (
            <Tooltip
              title={t(
                'call_center:containers.dashboard.component.agent_card.component.card_content.priority',
                { priority },
              )}
            >
              <Icon name="star-filled" size={16} />
            </Tooltip>
          )}
          {hasCallback && (
            <Tooltip
              title={t(
                'call_center:containers.dashboard.component.agent_card.component.card_content.callback',
              )}
            >
              <Icon name="phone-callback-filled" size={15} />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box>
        <Box component="span">
          {t(
            'call_center:containers.dashboard.component.agent_card.component.card_content.total_calls',
          )}
        </Box>
        <Box component="span">
          {getValue({
            statusType,
            value: callsTotal,
            statusTypes: [AgentCardStatusType.LOGGED_IN],
          })}
        </Box>
      </Box>
      <Box>
        <Box component="span">
          <Trans
            i18nKey="call_center:containers.dashboard.component.agent_card.component.card_content.taken"
            values={{
              taken: getValue({
                statusType,
                value: callsTaken,
                statusTypes: [AgentCardStatusType.LOGGED_IN],
              }),
            }}
            components={{ bold: <strong /> }}
          />
        </Box>
        <Box component="span">
          <Trans
            i18nKey="call_center:containers.dashboard.component.agent_card.component.card_content.missed"
            values={{
              missed: getValue({
                statusType,
                value: callsMissed,
                statusTypes: [AgentCardStatusType.LOGGED_IN],
              }),
            }}
            components={{ bold: <strong /> }}
          />
        </Box>
      </Box>
      <LinearProgress
        classes={{
          root: `MuiLinearProgress-agent MuiLinearProgress-agent-${
            callsTotal > 0 ? statusType : AgentCardStatusType.LOGGED_OUT
          }`,
        }}
        variant="determinate"
        value={getProgress({ callsTaken, callsTotal })}
        data-test-id="call-center-component-agent-card-component-card-content-linear-progress"
      />
    </>
  );
};

export default CardContent;
