import * as mixin from 'shared/utility/mixin';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { StyledNavItemProps as Props } from './definition';

export const CONSTANTS = {
  HEIGHT: '50px',
};

const getActive = (props: any): FlattenSimpleInterpolation => css`
  span {
    color: ${props.theme.whitelabel.primary.color};
  }

  svg {
    fill: ${props.theme.whitelabel.primary.color};
  }
`;

const StyledNavItem = styled.div<Props>`
  ${(props) => css`
    user-select: none;

    &:not(:first-of-type) {
      margin: 0 0 0 24px;
    }

    > div {
      &:first-of-type {
        ${mixin.flex({ isInline: true })};
        height: ${CONSTANTS.HEIGHT};
        padding: 0 18px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          ${getActive(props)};
        }

        span {
          ${mixin.font({ size: 16, weight: 500 })};
          ${mixin.transition({ property: 'color' })};
          color: ${props.theme.core.color.grey.light};
          text-align: center;
        }

        svg {
          ${mixin.transition({ property: 'fill' })};
          fill: ${props.theme.core.color.grey.light};
        }
      }
    }

    ${props.isActive &&
    css`
      > div {
        &:first-of-type {
          ${getActive(props)};
          cursor: default;
        }

        &:last-of-type {
          height: 4px;
          margin: -2px 0 0;
          background-color: ${props.theme.whitelabel.primary.color};
          border-radius: 2px;
        }
      }
    `}
  `}
`;

export default StyledNavItem;
