import { themeColor } from 'theme';
import theme from '../../core';

export default {
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  fontWeight: 600,
  '&.MuiButton-font-weight-normal': {
    fontWeight: 400,
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  '&.MuiButton-containedError': {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  '&.MuiButton-containedInfo': {
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  '&.MuiButton-containedSuccess': {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    '&.Mui-disabled': {
      backgroundColor: themeColor.core.button.grey,
      color: themeColor.core.color.grey_b0b0b0,
    },
    '&.MuiButton-dropdown': {
      '&.MuiButton-dropdown-open': {
        '.MuiButton-endIcon': {
          '.MuiSvgIcon-root': {
            transform: 'rotate(180deg)',
          },
        },
      },
    },
  },
  '&.MuiButton-containedWarning': {
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
};
