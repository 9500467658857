import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import { ApplyButtonProps as Props } from './definition';

const ApplyButton = ({ isDisabled, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      classes={{ root: 'MuiButton-small' }}
      color="secondary"
      disabled={isDisabled}
      id="btn-filter-apply"
      variant="contained"
      onClick={onClick}
    >
      {t('common:apply')}
    </Button>
  );
};

export default ApplyButton;
