import { FORMAT } from 'constant';
import { format } from 'date-fns';
import { FunctionComponent } from 'react';
import ExternalLink from 'shared/components/ExternalLink';
import Icon from 'shared/components/Icon';
import defaultProps from './default';
import { MaintenanceProps as Props } from './definition';
import StyledMaintenance from './style';

const Maintenance: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data } = { ...defaultProps, ...props };
  const { shortlink, scheduled_for, name } = data[0] || {};

  return (
    <>
      {!!scheduled_for && (
        <StyledMaintenance>
          <Icon name="warning-filled" />
          <span>
            <ExternalLink url={shortlink}>{name}</ExternalLink>
            <span>{format(new Date(scheduled_for), FORMAT.STATUS_DATE)}</span>
          </span>
        </StyledMaintenance>
      )}
    </>
  );
};

export default Maintenance;
