import Fade from '@mui/material/Fade';
import { TooltipProps } from '@mui/material/Tooltip';
import { themeColor } from 'theme';
import theme from '../core';

export default {
  defaultProps: {
    arrow: true,
    placement: 'bottom' as TooltipProps['placement'],
    enterDelay: 400,
    enterNextDelay: 400,
    TransitionComponent: Fade,
    TransitionProps: { timeout: 200 },
  },
  styleOverrides: {
    arrow: {
      width: 16,
      fontSize: 16,
      '&::before': {
        backgroundColor: themeColor.whitelabel.secondary.color,
        boxSizing: 'border-box',
      },
    },
    tooltip: {
      padding: '7px 8px',
      color: theme.palette.common.white,
      backgroundClip: 'padding-box',
      backgroundColor: themeColor.whitelabel.secondary.color,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0 5px 10px rgba(0 0 0 / 20%)',
      fontSize: 13,
      lineHeight: '1.2em',
      '&.MuiTooltip-tooltip-small': {
        width: 240,
        maxWidth: 240,
      },
      '&.MuiTooltip-tooltip-medium': {
        width: 320,
        maxWidth: 320,
      },
      '&.MuiTooltip-tooltip-large': {
        width: 400,
        maxWidth: 400,
      },
    },
  },
};
