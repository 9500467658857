import i18next from 'i18next';
import Joi from 'joi';
import { REGEX_MODE_OPTIONS } from '../../../constants';

const schema = () =>
  Joi.object({
    key: Joi.string()
      .valid(...REGEX_MODE_OPTIONS)
      .required()
      .messages({
        'string.empty': i18next.t('common:validation.general.required'),
      }),
    mode: Joi.string().valid(...REGEX_MODE_OPTIONS),
    usedKeys: Joi.array().items(Joi.string()),
  });

export default schema;
