import { ForwardedRef, forwardRef } from 'react';
import { GroupBase, SelectInstance } from 'react-select';
import Select from '../Select';
import { WindowedSelect } from '../WindowedSelect';
import { Props } from './definition';

const EnhancedSelect = forwardRef((props, ref) => {
  const { isWindowed = false } = props;
  const SelectComponent = isWindowed ? WindowedSelect : Select;

  return <SelectComponent {...props} ref={ref} />;
}) as <Option, IsMulti extends boolean, GroupType extends GroupBase<Option> = GroupBase<Option>>(
  props: Props<Option, IsMulti, GroupType> & {
    ref?: ForwardedRef<SelectInstance<Option, IsMulti, GroupType>>;
  },
) => JSX.Element;

export default EnhancedSelect;
