import { ExpandedRow } from '../components/ListItem/definition';
import { CONSTANTS } from '../constant';

/**
 * @name getItemSize
 * @description Get the (default or expanded) height of the item row.
 *
 * @param index
 *
 * @returns Row height.
 */
export const getItemSize = (expandedRow?: ExpandedRow) => (index: number) =>
  index === expandedRow?.index ? CONSTANTS.HEIGHT.EXPANDED : CONSTANTS.HEIGHT.DEFAULT;

/**
 * @name getListData
 * @description Get the filtered and sorted dataset.
 *
 * @param [object]
 * @property callflowId
 * @property data
 * @property searchFilter
 *
 * @returns Filtered dataset.
 */
export const getListData = ({
  callflowId,
  data,
  searchFilter = '',
}: {
  callflowId: string;
  data?: CallflowResponse;
  searchFilter?: string;
}) =>
  data
    ?.filter((item: any) => item.id !== callflowId && item.name !== '')
    .filter(
      (item: any) =>
        searchFilter === '' ||
        item.id.includes(searchFilter.toLowerCase()) ||
        item.name.toLowerCase().includes(searchFilter.toLowerCase()),
    )
    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1)) ?? [];
