import { FunctionComponent } from 'react';
import Box from 'shared/components/Box';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { RightItemsProps as Props } from './definition';
import StyledRightItems from './style';

const RightItems: FunctionComponent<Props> = ({ items }: Props): JSX.Element => (
  <StyledRightItems data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-horizontal-nav-right-items`}>
    {items.map((component: JSX.Element, i: number) => {
      const id = `item-${i}`;
      return (
        <Box
          key={id}
          data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-horizontal-nav-right-items-${id}`}
        >
          {component}
        </Box>
      );
    })}
  </StyledRightItems>
);

export default RightItems;
