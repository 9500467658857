import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { MILLISECONDS_IN_SECOND } from 'constant';
import merge from 'lodash/merge';
import { useFetchCallflowByIdQuery, useUpdateCallflowMutation } from 'models/Callflow';
import { Callflow } from 'models/Callflow/store/definition';
import { ChangeEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import { LabeledInput } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import Typography from 'shared/components/Typography';
import { useToast } from 'shared/hooks/useToast';
import { defaultValues } from './default';
import { ConfigDialogProps as Props } from './definition';
import { schema } from './schema';
import { prepareDataForSave } from './utility';

const ConfigDialog = ({ isOpen, id, title, onClose }: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { data, isLoading } = useFetchCallflowByIdQuery({ id });
  const [updateCallflow] = useUpdateCallflowMutation();

  const { control, getValues, reset } = useForm<Partial<Callflow>>({
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const onAction = ({ reason }: { reason: DialogActionsCloseReasons }) => {
    if (reason === 'saveClicked') {
      updateCallflow({ id, body: prepareDataForSave(getValues()) })
        .then(() => {
          showToast.success();
        })
        .catch(() => {
          showToast.error();
        });
    }
    onClose();
  };

  const input = {
    getValue: (value: number) => (value ? value / MILLISECONDS_IN_SECOND : value),
    onChange: (value: string) => (value === '' ? '' : +value * MILLISECONDS_IN_SECOND),
  };

  useEffect(() => {
    if (data) {
      reset(merge({}, defaultValues, data));
    }
  }, [data, reset]);

  if (isLoading) {
    <Loading />;
  }

  return (
    <Dialog
      open={isOpen}
      renderActions={<DialogActions onAction={onAction} />}
      title={title}
      onClose={onAction}
    >
      <Typography>{t('phone_system:containers.feature_codes.dialog.config.text')}</Typography>

      <HookFormInputWrapper name="flow.data.default_ringback_timeout" control={control}>
        {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            isDirty={isDirty}
            feedback={feedback}
            inputProps={{
              type: 'number',
              value: input.getValue(value),
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                onChange(input.onChange(e.target.value)),
              ...formProps,
            }}
            label={t('phone_system:containers.feature_codes.dialog.config.timeout.ringback.label')}
            tooltip={t(
              'phone_system:containers.feature_codes.dialog.config.timeout.ringback.tooltip',
            )}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="flow.data.default_callback_timeout" control={control}>
        {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            isDirty={isDirty}
            feedback={feedback}
            inputProps={{
              type: 'number',
              value: input.getValue(value),
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                onChange(input.onChange(e.target.value)),
              ...formProps,
            }}
            label={t('phone_system:containers.feature_codes.dialog.config.timeout.callback.label')}
            tooltip={t(
              'phone_system:containers.feature_codes.dialog.config.timeout.callback.tooltip',
            )}
          />
        )}
      </HookFormInputWrapper>
    </Dialog>
  );
};

export default ConfigDialog;
