export const CONSTANTS = {
  ICON: {
    SIZE: 50,
  },
  LABEL: {
    LENGTH: {
      MAX: 20,
    },
  },
  WIDTH: '70px',
};
