import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import { CallerInfoMatchDeleteDialogProps as Props } from './definition';

const CallerInfoMatchDeleteDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { showDialog, onCancel, onConfirm } = props;
  const { t } = useTranslation();

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onCancel();
        break;
      case 'saveClicked':
        onConfirm();
        break;
      default:
        onCancel();
    }
  };

  const confirmButtonProps = {
    color: 'error',
    label: t('common:delete'),
    variant: 'contained',
  };

  return (
    <Dialog
      open={showDialog}
      title={t(
        'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.delete.title',
      )}
      onClose={onCancel}
      renderActions={
        <DialogActions saveButtonProps={confirmButtonProps} onAction={handleDialogAction} />
      }
    >
      <p>
        {t(
          'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.delete.content',
        )}
      </p>
    </Dialog>
  );
};

export default CallerInfoMatchDeleteDialog;
