import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { AUDIO_PRECONNECT_OPTIONS, NUMBER_OPTIONS } from '../../constants';

const schema = () =>
  Joi.object({
    interdigit: Joi.string().valid(...NUMBER_OPTIONS),
    max_digits: Joi.string().valid(...NUMBER_OPTIONS),
    nodeId: Joi.string(),
    pin: Joi.string().allow(''),
    preconnect_audio: Joi.string().valid(...AUDIO_PRECONNECT_OPTIONS),
    retries: Joi.string().valid(...NUMBER_OPTIONS),
    use_account_caller_id: Joi.boolean(),
  }).messages(messages());

export default schema;
