import Select from 'react-select';
import { WidthType } from 'shared/utility/definition';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { CONSTANTS } from './constant';
import { Props, StyledSelectProps } from './definition';

export const getStyles = ({ margin }: { margin: string }) => css`
  .react-select__menu-portal .react-select__menu {
    width: auto;
    min-width: 100%;
    margin-top: ${margin};
    margin-bottom: ${margin};

    .react-select__menu-list {
      padding: 0;
      white-space: nowrap;
    }
  }
`;

const StyledSelect = styled(Select)<StyledSelectProps & Props>`
  ${({ hasBorder, hasImages, size, theme }) => css`
    ${mixin.width({ type: 'select', width: size as WidthType })};

    &:focus,
    &:focus-visible {
      outline: none;
    }

    --height: ${CONSTANTS.HEIGHT}px;
    --margin: ${CONSTANTS.MARGIN}px;

    .react-select__control {
      min-height: var(--height);

      &,
      &:hover,
      &.react-select__control--is-active,
      &.react-select__control--is-focused {
        border-color: ${theme.core.input.border.default};
      }

      &.react-select__control--is-active,
      &.react-select__control--is-focused {
        box-shadow: none;
      }

      &.react-select__control--menu-is-open .react-select__dropdown-indicator svg {
        transform: rotate(180deg);
      }

      .react-select__single-value {
        color: ${theme.core.color.black};
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          margin-top: var(--margin);
          margin-bottom: var(--margin);
        }

        .react-select__indicator {
          padding: ${theme.spacing(1.33, 1)};

          svg {
            fill: ${theme.whitelabel.secondary.color};
          }

          &.react-select__clear-indicator {
            svg {
              ${mixin.transition({ property: 'transform' })};
              transform: translate(-3px, 0.5px);
            }

            &:hover svg {
              transform: translate(-3px, 0.5px) scale(1.2);
            }
          }

          &.react-select__dropdown-indicator svg {
            ${mixin.transition({ property: 'transform' })};
          }
        }
      }

      .react-select__value-container {
        height: ${hasImages ? 'calc(var(--height) - 2px)' : 'auto'};
        cursor: text;
        user-select: none;

        .react-select__multi-value {
          .react-select__multi-value__label > div {
            font-size: 12px;
          }

          .react-select__multi-value__remove {
            ${mixin.transition([
              { property: 'background-color', duration: 150 },
              { property: 'color', duration: 150 },
            ])};
            cursor: pointer;

            &:hover {
              background-color: ${theme.whitelabel.secondary.color};
              color: ${theme.core.color.white};
            }
          }
        }
      }

      ${!hasBorder &&
      css`
        &,
        &:hover,
        &.react-select__control--is-active,
        &.react-select__control--is-focused {
          border-color: transparent;
        }

        .react-select__indicators {
          .react-select__indicator-separator {
            background-color: transparent;
          }

          .react-select__indicator {
            padding: 0;
          }
        }

        .react-select__single-value {
          width: 120px;
          font-weight: 600;
        }
      `}
    }
  `};
` as typeof Select;

export default StyledSelect;
