import { CHARACTER } from 'constant';
import { AgentCardStatusType } from '../../definition';

/**
 * @name getProgress
 * @description Get the value for the linear progress bar.
 *
 * @param [object]
 * @property callsTaken
 * @property callsTotal
 *
 * @returns Numeric Value
 */
export const getProgress = ({
  callsTaken = 0,
  callsTotal = 0,
}: {
  callsTaken?: number;
  callsTotal?: number;
}): number => Math.round(callsTotal > 0 ? (callsTaken / callsTotal) * 100 : 0);

/**
 * @name getValue
 * @description Get the textual value, depending on the current card status.
 *
 * @param [object]
 * @property statusType
 * @property statusTypes
 * @property value
 *
 * @returns Textual value
 */
export const getValue = ({
  statusType,
  statusTypes = [],
  value = CHARACTER.EMDASH,
}: {
  statusType: AgentCardStatusType;
  statusTypes?: Array<AgentCardStatusType>;
  value?: number | string;
}): number | string =>
  [AgentCardStatusType.LOGGED_OUT, ...statusTypes].includes(statusType) ? CHARACTER.EMDASH : value;
