import { triggerDownload } from 'apps/shared/utility';
import { MEDIA_TYPE } from 'constant';
import { Download } from 'definition';
import kebabCase from 'lodash/kebabCase';
import { useFetchAccountConfigsQuery, useFetchAccountQuery } from 'models/Account';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { DownloadIconProps as Props } from './definition';
import { getSeatsCsvBlob } from './utility';

const DownloadIcon = ({ data: seatsData }: Props) => {
  const { t } = useTranslation();
  const downloadRef = useRef<Download>({ name: '', url: '' });
  const isDownload = Boolean(seatsData?.length);

  const { data: { name: accountName, locations } = {} } = useFetchAccountQuery();
  const { data: { seat_types: seatTypes } = {} } = useFetchAccountConfigsQuery();

  useEffect(() => {
    if (isDownload && accountName && seatTypes && locations) {
      downloadRef.current = {
        name: t('phone_system:containers.seats.action_row.download.file.name', {
          name: kebabCase(accountName),
        }),
        url: URL.createObjectURL(
          new Blob(getSeatsCsvBlob({ seatsData, seatTypes, locations }), {
            type: MEDIA_TYPE.TYPE.TEXT.CSV.WITH_CHARSET,
          }),
        ),
      };
    }
  }, [isDownload, accountName, locations, seatsData, seatTypes, t]);

  return (
    <Tooltip title={t('phone_system:containers.seats.action_row.download.tooltip')}>
      <Icon
        name="download"
        {...(isDownload
          ? { hasHover: true, onClick: () => triggerDownload(downloadRef.current) }
          : { color: 'disabled' })}
      />
    </Tooltip>
  );
};

export default DownloadIcon;
