import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';
import { DeviceFileType, FetchDeviceTemplateByModelParams } from 'models/AdvancedProvisioner/types';

export const api = {
  // GET devices
  FetchAccountSettings: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${accountId}`,
  FetchAccountTemplate: () => 'ui',
  FetchDeviceByMacAddress: (macAddress: string, accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `devices/${accountId}/${macAddress}`,
  FetchDeviceFilesByMacAddress: (
    type: DeviceFileType,
    macAddress: string,
    accountId: string = ACCOUNT_ID_URL_PLACEHOLDER,
  ) => `debug/accounts/${accountId}/${type}/${macAddress}`,
  FetchDevices: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) => `devices/${accountId}`,
  FetchDeviceTemplateByModel: ({ brand, family, model }: FetchDeviceTemplateByModelParams) =>
    `ui/${brand}/${family}/${model}`,
  FetchPhones: () => `phones`,
  FetchSubaccountDevice: (macAddress: string) => `devices/search/${encodeURIComponent(macAddress)}`,

  // POST devices
  UpdateByMacAddress: (macAddress: string, accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `devices/${accountId}/${macAddress}`,
  UpdateAccount: (accountId: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${accountId}`,
};
