import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledPickNumbers = styled(Box)`
  ${({ theme }) => css`
    > p {
      /** TODO: Create global font style object? (font styling lifted from useTabs/style) */
      ${mixin.font({
        letterSpacing: '0.3px',
        lineHeight: '22px',
        size: 13,
        style: 'italic',
        weight: 400,
      })};
      margin: ${theme.spacing(-1, 0.33, 3)};
    }

    > div {
      ${mixin.flex({ alignItems: 'flex-start', justifyContent: 'space-between' })};

      > div {
        width: calc(100% / 2 - (${theme.spacing(3)} / 2));
      }
    }
  `};
`;
