import styled, { css } from 'styled-components';
import { StyledTableSelectorProps as Props } from './definition';

const CONSTANTS = {
  GAP: '18px',
};

const width = (value?: number): string => (value ? `calc(${value}px - ${CONSTANTS.GAP})` : '1fr');

export const StyledTableSelector = styled.div<Props>`
  ${(props) => css`
    display: grid;
    grid-gap: ${CONSTANTS.GAP};
    grid-template: 1fr / ${width(props.width?.left)} ${width(props.width?.right)};
    align-items: flex-end;
  `};
`;
