import { Tabs } from 'apps/shared/components/EnhancedFormCore/components/Form/definition';
import { useMemo } from 'react';
import { DynamicInput } from '../../components/DynamicInput';
import { Section } from '../../components/Section';
import { useDynamicTabsParams } from '../../definition';

export const useDynamicTabs = ({ template }: useDynamicTabsParams) =>
  useMemo(() => {
    const composedTabs: Tabs = {};
    Object.entries(template).forEach(([sectionKey, { data, text }]) => {
      composedTabs[sectionKey] = {
        hash: sectionKey,
        label: text,
        component: (
          <Section sectionKey={sectionKey} template={data}>
            {({ fieldKey, fieldTemplate }) => (
              <DynamicInput fieldKey={fieldKey} template={fieldTemplate} />
            )}
          </Section>
        ),
      };
    });
    return composedTabs;
  }, [template]);
