import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

export enum TAGS {}

export const responseHandler = async (response: any) => {
  try {
    const data = await response.json();
    return data;
  } catch (e) {
    return {};
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${window.nemo?.api.biz_user}/`,
  prepareHeaders: (headers) => {
    headers.set('content-type', 'application/json');
    return headers;
  },
});

export const bizUserApi = createApi({
  reducerPath: 'biz_user_api',
  baseQuery: retry(baseQuery, { maxRetries: 0 }),
  endpoints: () => ({}),
  tagTypes: Object.values(TAGS),
});
