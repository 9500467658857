import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { FORMAT } from 'constant';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import Input from 'shared/components/Input';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import defaultProps from './default';
import { TimePickerProps as Props } from './definition';

export { default as timePickerDefaultProps } from './default';
export type { TimePickerProps } from './definition';

const TimePicker: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { id, disabled, value, width, renderInput, onChange, ...rest }: Props = {
    ...defaultProps,
    ...props,
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const refPicker = useOutsideClick(() => setIsOpen(false), 'div[role="dialog"]');
  const { t } = useTranslation();

  return (
    <div ref={refPicker}>
      <MuiTimePicker
        disableMaskedInput
        inputFormat={FORMAT.TIME.HOUR_MINUTES}
        open={isOpen}
        minutesStep={10}
        value={value || null}
        onChange={onChange}
        renderInput={({ InputProps, inputProps, ...rest }: any) => (
          <Input
            {...rest}
            inputProps={{
              ...inputProps,
              placeholder: t('common:component.time_picker.placeholder'),
            }}
            id={id}
            classes={{ root: 'MuiInputBase-picker MuiInputBase-picker-time' }}
            disabled={disabled}
            endAdornment={<Icon name="clock-outlined" size={20} />}
            width={width}
            onClick={() => setIsOpen((isOpen: boolean) => (disabled ? false : !isOpen))}
          />
        )}
        {...rest}
      />
    </div>
  );
};

export default TimePicker;
