import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledTrunkNameCell = styled.div`
  ${(props) => css`
    ${mixin.flex({ justifyContent: 'flex-start', alignItems: 'center', direction: 'row' })};
    height: fit-content;

    span {
      padding-left: ${props.theme.spacing(6)};
    }
  `};
`;
