import { MILLISECONDS_IN_SECOND, VALIDATION } from 'constant';
import i18next from 'i18next';
import Joi from 'joi';
import { validateRegexForSchema } from 'shared/utility';
import { messages } from 'shared/utility/validation';
import { menuMutations } from './Mutations';
import { menuQueries } from './Queries';

export const schema = () =>
  Joi.object({
    name: Joi.string().required().min(1).max(128),
    record_pin: Joi.string().allow('').empty('').min(3).max(6),
    media: Joi.object({
      greeting: Joi.string().min(3).max(2048),
    }).unknown(true),
    retries: Joi.number().integer().min(1).max(10),
    timeout: Joi.number()
      .integer()
      .min(VALIDATION.MENU.TIMEOUT.MIN)
      .max(VALIDATION.MENU.TIMEOUT.MAX)
      .messages({
        'number.min': i18next.t('common:validation.general.number_max_custom', {
          min: VALIDATION.MENU.TIMEOUT.MIN / MILLISECONDS_IN_SECOND,
          max: VALIDATION.MENU.TIMEOUT.MAX / MILLISECONDS_IN_SECOND,
        }),
        'number.max': i18next.t('common:validation.general.number_max_custom', {
          min: VALIDATION.MENU.TIMEOUT.MIN / MILLISECONDS_IN_SECOND,
          max: VALIDATION.MENU.TIMEOUT.MAX / MILLISECONDS_IN_SECOND,
        }),
      }),
    max_extension_length: Joi.number().when('record_pin', {
      is: Joi.exist(),
      then: Joi.number()
        .integer()
        .min(Joi.ref('record_pin.length'))
        .max(VALIDATION.MENU.EXTENSION_NUMBER_LENGTH.MAX)
        .messages({
          'number.min': i18next.t(
            'phone_system:containers.menu.section.options.field.max_extension_length.validation.number_min',
          ),
        }),
      otherwise: Joi.number()
        .integer()
        .min(VALIDATION.MENU.EXTENSION_NUMBER_LENGTH.MIN)
        .max(VALIDATION.MENU.EXTENSION_NUMBER_LENGTH.MAX),
    }),
    hunt: Joi.boolean(),
    hunt_allow: Joi.string().empty('').max(256).custom(validateRegexForSchema),
    hunt_deny: Joi.string().empty('').max(256).custom(validateRegexForSchema),
    allow_record_from_offnet: Joi.boolean(),
    suppress_media: Joi.boolean(),
  })
    .unknown(true)
    .messages(messages());

export const { useFetchMenusQuery, useFetchMenuByIdQuery } = menuQueries;
export const {
  useCreateMenuMutation,
  useUpdateMenuMutation,
  useDeleteMenuMutation,
} = menuMutations;
