import { useFetchAccountAncestorsByIdQuery, useFetchAccountByIdQuery } from 'models/Account';
import { selectAccountId } from 'models/Account/slice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { normalizeNameSegment } from '../../utility';
import { RealmData } from './definition';
import { getRealmData } from './utility';

const useBuildRealm = () => {
  const parentId = useSelector<string, string>(selectAccountId);
  const { data: accountData } = useFetchAccountByIdQuery({ id: parentId });
  const { data: ancestorData } = useFetchAccountAncestorsByIdQuery({ id: parentId });

  const data = useMemo(
    () =>
      getRealmData({
        accountData: { name: accountData?.name, realm: accountData?.realm },
        ancestorData,
      }),
    [accountData, ancestorData],
  );

  const buildRealm = data
    ? (name = '') =>
        `${normalizeNameSegment(name)}${data.length > 0 ? '.' : ''}${data
          ?.map(({ name, realm }: RealmData, i: number) =>
            i === 0 ? realm : normalizeNameSegment(name),
          )
          .reverse()
          .join('.')}`
    : undefined;

  return buildRealm;
};

export default useBuildRealm;
