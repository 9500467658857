import { EndpointItemType, EndpointType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import { buildExtensionAndFullNameFromUser, buildFullNameFromUser } from 'models/User';
import Icon from 'shared/components/Icon';

/**
 * @name getEndpointIcon
 * @description Get the endpoint icon depending on endpoint type.
 *
 * @param key
 *
 * @returns Endpoint icon element.
 */
export const getEndpointIcon = (key: EndpointType) => {
  const dictionary = {
    [EndpointType.Device]: 'desk-phone',
    [EndpointType.Email]: 'groups-filled',
    [EndpointType.Group]: 'groups-filled',
    [EndpointType.User]: 'person-filled',
  };
  return dictionary[key] ? <Icon name={dictionary[key]} size={22} /> : <></>;
};

/**
 * @name nameFormatter // TODO: Rename to `formatEndpointName`
 * @description Format endpoint name depending on endpoint type.
 *
 * @param endpoint
 * @param endpointType
 * @param hasUsersExtension
 *
 * @returns Endpoint name.
 */
export const nameFormatter = (
  endpointType: EndpointType,
  endpoint: EndpointItemType,
  hasUsersExtension = false,
): string => {
  switch (endpointType) {
    case EndpointType.Device:
    case EndpointType.Group:
      return endpoint.name;
    case EndpointType.User:
      return hasUsersExtension
        ? buildExtensionAndFullNameFromUser(endpoint)
        : buildFullNameFromUser(endpoint);
    default:
      return '';
  }
};

/**
 * @name remapEndpoints
 * @description Remap endpoint id to key property.
 *
 * @param data
 *
 * @returns Endpoint array.
 */
export const remapEndpoints = (data: any) =>
  data.endpoints.map(({ key, name, ...rest }: GroupEndpoint) => ({
    ...rest,
    id: key,
  }));
