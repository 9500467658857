import { Row } from 'shared/components/EditableTable';
import { TABLE_CONSTANTS } from 'shared/components/Table';
import styled, { css } from 'styled-components';
import { StyledStandardRowProps as Props } from './definition';

const StyledStandardRow = styled(Row)<Props>`
  ${(props) => css`
    > span {
      &:first-of-type {
        width: calc(100% - ${TABLE_CONSTANTS.WIDTH.ICON.ACTION});
        padding-left: ${props.theme.spacing(5)};
        text-align: start;
      }

      &:last-of-type {
        width: ${TABLE_CONSTANTS.WIDTH.ICON.ACTION};
      }
    }
  `}
`;

export default StyledStandardRow;
