import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFlowChildDeleteProps as Props } from './definition';

export const StyledFlowChildDelete = styled.div<Props>`
  ${(props) => css`
    grid-area: 1 / 3 / 4 / 4;
    position: relative;

    > div {
      position: absolute;
      ${mixin.flex({ alignItems: 'flex-start', justifyContent: 'flex-start' })};

      svg {
        ${mixin.transition({ property: 'fill', duration: 'medium' })};
        cursor: pointer;

        ${!props.isDragging &&
        css`
          &:hover {
            fill: ${props.theme.core.color.blue.draggable};
          }

          ${props.hasDeleteOptions &&
          css`
            fill: ${props.theme.core.color.blue.draggable};
          `}
        `}
      }

      div {
        margin: -7px 0 0 6px;
      }
    }
  `}
`;
