import { EndpointItemType, EndpointType } from 'apps/PhoneSystem/definition';
import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import union from 'lodash/union';
import { Trans } from 'react-i18next';
import Icon from 'shared/components/Icon';
import { Column } from 'shared/components/Table';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { getEndpointIcon } from '../../TableSelector/utility';
import { Member, TransformDirection } from './definition';

export { TransformDirection };
export type { Member };

/**
 * @name getSelectedTableColumns
 * @description ...
 *
 * @param [object]
 * @property fieldArray
 * @property manageUsers
 * @property selectedUserCount
 * @property maxSelectedUsers
 *
 * @return Column listing
 */
export const getSelectedTableColumns = ({
  fieldArray,
  manageUsers,
  selectedUserCount,
  maxSelectedUsers,
}: {
  fieldArray: any;
  manageUsers: any;
  selectedUserCount: number;
  maxSelectedUsers: number;
}): Array<Column> => [
  {
    accessor: 'name',
    Header: (
      <Trans
        i18nKey={manageUsers.table.column.selected}
        values={{ count: `(${selectedUserCount} / ${maxSelectedUsers})` }}
        components={{
          emphasis: selectedUserCount >= maxSelectedUsers ? <span className="emphasis" /> : <></>,
        }}
      />
    ),
    Cell: ({
      row: {
        original: { name, type },
      },
    }: EndpointItemType) => <TableCellTruncatedValue icon={getEndpointIcon(type)} text={name} />,
  },
  {
    accessor: 'id',
    Cell: ({ row: { index } }: { row: { index: number } }) => (
      <Icon
        data-test-id={`${TEST_ID.PHONE_SYSTEM.SHARED.SHARED_DIALOG.REMOVE_MEMBER_ICON}`}
        hasHover
        name="minus-circle-outlined"
        size={22}
        themeColor="core.states.error.main"
        onClick={() => fieldArray.remove(index)}
      />
    ),
  },
];

/**
 * @name getSelectedUserCount
 * @description ...
 *
 * @property groupsData
 * @property fields
 *
 * @return User count
 */
export const getSelectedUserCount = (groupsData: any, fields: Array<Record<string, any>>) => {
  const users: Record<string, Array<string>> = {
    single: [],
    group: [],
  };

  fields.forEach((user: any) => {
    switch (user.endpoint_type) {
      case EndpointType.User:
        users.single.push(user.key);
        break;
      case EndpointType.Group:
        users.group = union(
          users.group,
          groupsData.find((group: any) => group.id === user.key)?.endpoints_map.user,
        );
        break;
      default:
        break;
    }
  });

  return union(users.single, users.group).length;
};

/**
 * @name transformMembers
 * @description Transform shared voicemail members listing (for use with TableSelector)
 *              • TO: The `endpoint_type`, `key`, and `name` properties are needed for
 *                TableSelector to work as intended; an earlier version of this adaptor
 *                did not save `id` or `type` to the database--hence the fallback
 *                values below (`key` and `endpoint_type`, respectively)
 *              • FROM: We only save the `id`, `name`, and `type` properties to the database
 *
 * @param direction "FROM" or "TO" TableSelector
 * @param members List of members
 *
 * @return Transformed list of members
 */
export const transformMembers = (
  direction: TransformDirection,
  members: Array<Member>,
): Array<Member> => {
  switch (direction) {
    case TransformDirection.To:
      return members.map(({ id, endpoint_type, key, name, type }: Member) => {
        const _key = id ?? key;
        const _type = type ?? endpoint_type;
        return { name, endpoint_type: _type, key: _key, type: _type };
      });
    case TransformDirection.From:
      return members.map(({ key, name, type }: Member) => ({ id: key, name, type }));
    default:
      return [];
  }
};
