import { WidthType } from '../definition';

export const value: any = {
  borderRadius: {
    half: '3px',
    default: '6px',
    round: '50%',
  },
  transition: {
    duration: {
      fastest: 50,
      faster: 100,
      fast: 150,
      medium: 200,
      default: 300,
      slow: 500,
    },
  },
};

export const props: { [key: string]: { [key: string]: boolean | number | string | WidthType } } = {
  borderRadius: {
    radius: 'default',
  },
  boxShadow: {
    offsetX: 0,
    offsetY: 0,
    blurRadius: 3,
    spreadRadius: 0,
    alpha: 25,
  },
  flex: {
    isInline: false,
    alignContent: 'normal',
    alignItems: 'center',
    direction: 'row',
    gap: 0,
    justifyContent: 'center',
    wrap: 'nowrap',
  },
  grid: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  hover: {
    fontWeight: 600,
    letterSpacing: -0.015,
  },
  transition: {
    duration: 'default',
    timingFunction: 'ease-in-out',
    delay: 0,
  },
  width: {
    width: 'medium' as WidthType,
    type: 'input',
    offset: 0,
  },
};
