import { FormContext } from 'apps/shared/components/FormContext';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import useTabs from 'apps/shared/hooks/useTabs';
import produce from 'immer';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { getDeepKeys } from 'shared/utility';
import { FormProps as Props, Tabs, TabsConfigs, TabState } from './definition';
import { useEnsureFormDefaultValue } from './hooks';

const Form = ({ defaultValues = {}, tabs = {}, formFields = {} }: Props): JSX.Element => {
  const contextName = useContext(FormContext);

  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  useEnsureFormDefaultValue({ defaultValues, contextName, formFields });

  const getTabState = (mode: TabState, fields: string[] = []) =>
    getDeepKeys(mode === TabState.DIRTY ? dirtyFields : errors).filter((field: string) =>
      fields.map((field: string) => `${contextName}${field}`).includes(field),
    ).length > 0;

  const items = produce<Tabs, TabsConfigs>(tabs, (draft) => {
    Object.keys(draft).forEach((tab) => {
      draft[tab].isDirty = getTabState(TabState.DIRTY, formFields[tab]);
      draft[tab].isError = getTabState(TabState.ERROR, formFields[tab]);
    });
  }) as TabsConfigs;

  const { Tabs, TabPanels } = useTabs({ items: Object.values(items) });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Form;
