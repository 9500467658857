import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { LOCAL_STORAGE } from 'constant';
import { dynamicBaseQuery } from './utility/dynamicBaseQuery';

export enum TAGS {
  PHONES = 'LEGACY_PROV_PHONES',
}

const baseQuery = dynamicBaseQuery(
  fetchBaseQuery({
    baseUrl: window.nemo?.api.legacyProvisioner,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
      if (token) {
        headers.set('X-Auth-Token', token);
      }
      return headers;
    },
  }),
  true,
);

export const service = createApi({
  reducerPath: 'legacy_provisioner_api',
  baseQuery: retry(baseQuery, { maxRetries: 0 }),
  endpoints: () => ({}),
  tagTypes: Object.values(TAGS),
});

export const responseHandler = async (response: any) => {
  try {
    const data = await response.json();
    return data.data;
  } catch (e) {
    return {};
  }
};
