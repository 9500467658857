import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import DropdownButton from 'shared/components/DropdownButton';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import IncomingCallInfo from './components/IncomingCallInfo';
import { IncomingCallProps as Props } from './definition';
import StyledIncomingCall from './style';

const IncomingCall: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { items, incomingCallLabel, priority, timestamp } = props;
  const { t } = useTranslation();

  return (
    <StyledIncomingCall>
      <IncomingCallInfo
        incomingCallLabel={incomingCallLabel}
        priority={priority}
        timestamp={timestamp}
      />
      {!!priority && priority > 0 && (
        <Box style={{ transform: 'translateY(1px)' }}>
          <Tooltip
            title={t(
              'call_center:containers.dashboard.component.table_cell_incoming_call.priority',
              {
                priority,
              },
            )}
          >
            <Icon name="star-filled" size={20} themeColor="brio.text.secondary" />
          </Tooltip>
        </Box>
      )}
      {items && (
        <Box style={{ transform: 'translateY(2px)' }}>
          <DropdownButton
            hasIconButton
            icon="kebab-default"
            iconButtonTooltip={t(
              'call_center:containers.dashboard.component.table_cell_incoming_call.more_actions',
            )}
            items={items}
          />
        </Box>
      )}
    </StyledIncomingCall>
  );
};

export default IncomingCall;
