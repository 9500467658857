import { TableCellCheckmarkIcon } from 'apps/shared/components/TableCell';
import i18next from 'i18next';
import { Justify, TABLE_CONSTANTS } from 'shared/components/Table';

export const columns = () => [
  {
    Header: i18next.t(
      'numbers:containers.numbers_in_use.section.e911.site_selection.table.column.location_name',
    ),
    accessor: 'display_name',
    id: 'display_name',
    width: 180,
    maxWidth: 180,
  },
  {
    Header: i18next.t(
      'numbers:containers.numbers_in_use.section.e911.site_selection.table.column.street_address',
    ),
    Cell: ({
      row: {
        original: { address_line_1, address_line_2 },
      },
    }: any) => `${address_line_1.trim()}${address_line_2 ? `, ${address_line_2.trim()}` : ''}`,
    accessor: 'address_line_1',
    id: 'address_line_1',
    width: 180,
    maxWidth: 180,
  },
  {
    Header: i18next.t(
      'numbers:containers.numbers_in_use.section.e911.site_selection.table.column.city',
    ),
    accessor: 'address_city',
    id: 'address_city',
    width: 140,
    maxWidth: 140,
  },
  {
    Header: i18next.t(
      'numbers:containers.numbers_in_use.section.e911.site_selection.table.column.state_province',
    ),
    accessor: 'address_state',
    id: 'address_state',
    width: 140,
    maxWidth: 140,
  },
  {
    Header: i18next.t(
      'numbers:containers.numbers_in_use.section.e911.site_selection.table.column.country',
    ),
    accessor: 'address_country',
    id: 'address_country',
    width: 120,
    maxWidth: 120,
  },
  {
    Header: i18next.t('common:default'),
    Cell: ({
      row: {
        original: { key },
      },
    }: any) =>
      key === 'default' && (
        <TableCellCheckmarkIcon
          info={i18next.t(
            'numbers:containers.numbers_in_use.section.e911.site_selection.table.column.default.info',
          )}
        />
      ),
    accessor: (row: any) => row.id,
    id: `column.default.header.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
  },
];

export const setFieldValue = (setValue: any) => (name: string, value: string) => {
  setValue(name, value, {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: true,
  });
};
