import { SeatFormProps as Props } from 'apps/PhoneSystem/definition';
import { FormContext } from 'apps/shared/components/FormContext';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import useTabs from 'apps/shared/hooks/useTabs';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDeepKeys } from 'shared/utility';
import { fields, Section } from './components';

const Form = ({ hasFormActionRow }: Props) => {
  const { t } = useTranslation();
  const contextName = useContext(FormContext);
  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const hasIcon = (mode: string, tabFields: Array<string> = []) =>
    getDeepKeys(mode === 'dirty' ? dirtyFields : errors).filter((field: string) =>
      tabFields.map((field: string) => `${contextName}${field}`).includes(field),
    ).length > 0;

  const items = [
    {
      hash: 'basics',
      label: t('phone_system:containers.conferences.section.basics.label'),
      component: <Section.Basics />,
      isDirty: hasIcon('dirty', fields.basics),
      isError: hasIcon('error', fields.basics),
    },
    {
      hash: 'options',
      label: t('phone_system:containers.conferences.section.options.label'),
      component: <Section.Options />,
      isDirty: hasIcon('dirty', fields.options),
      isError: hasIcon('error', fields.options),
    },
  ];

  const { Tabs, TabPanels } = useTabs({
    items,
    options: { hasFormActionRow },
  });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Form;
