import MuiCheckbox, { CheckboxProps as Props } from '@mui/material/Checkbox';
import { forwardRef } from 'react';

export type { CheckboxProps } from '@mui/material/Checkbox';

const Checkbox = forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <MuiCheckbox ref={ref} {...props} />
));

export default Checkbox;
