import { ComponentProps } from 'shared/definition';

export enum QuickViewDialogType {
  expanded = 'expanded',
  mimimized = 'minimized',
}

interface Props extends ComponentProps {}

export interface QuickViewDialogProps extends Props {
  /** Is the dialog visible? */
  isOpen: boolean;
  /** Which dialog type is being displayed (expanded or minimized)? */
  type: QuickViewDialogType;
  /** onClose even handler */
  onClose: () => void;
}

export interface StyledQuickViewDialogProps extends Props {}
