import { useFetchAccountQuery } from 'models/Account';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useFetchSeatByIdQuery } from 'models/Seat';
import { useEffect, useState } from 'react';
import Alert from 'shared/components/Alert';
import Loading from 'shared/components/Loading';
import { AddressAlertProps as Props } from './definition';
import { assignAlertValues } from './utility';

const AddressAlert = ({
  hasMargin,
  isAccountLevel = false,
  number,
  seatId,
  setIsNumbersInUsePickerOpen,
}: Props) => {
  const [alert, setAlert] = useState<Record<string, any>>();

  const { data: accountData, isLoading: isLoadingAccount } = useFetchAccountQuery();
  const { data: numbersData, isLoading: isLoadingNumbers } = useFetchPhoneNumbersQuery();
  const { data: seatData, isLoading: isLoadingSeat } = useFetchSeatByIdQuery(
    { id: seatId ?? '' },
    { skip: !seatId },
  );

  useEffect(() => {
    assignAlertValues({
      isAccountLevel,
      data: {
        account: accountData,
        numbers: numbersData,
        seat: seatData,
      },
      number,
      setAlert,
      setIsNumbersInUsePickerOpen,
    });
  }, [
    isAccountLevel,
    number,
    accountData,
    numbersData,
    seatData,
    setAlert,
    setIsNumbersInUsePickerOpen,
  ]);

  if (isLoadingAccount || isLoadingNumbers || (seatId && isLoadingSeat)) {
    return <Loading />;
  }

  return (
    <Alert
      action={alert?.action}
      className="MuiAlert-address"
      hasMargin={hasMargin}
      message={alert?.message}
      severity={alert?.severity}
    />
  );
};

export default AddressAlert;
