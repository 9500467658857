import { TransformDirection, transformMembers } from 'apps/PhoneSystem/shared/SharedDialog/utility';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { FormInput } from '../definition';

/**
 * @name processMembers
 * @description Transform the `members` property and reassign.
 *
 * @param data
 */
export const processMembers = (data: any): void => {
  if (isEmpty(data)) {
    return;
  }

  data.members = Array.isArray(data.members)
    ? transformMembers(TransformDirection.To, data.members)
    : [];
};

/**
 * @name processNumbers
 * @description Concatenate the `numbers` property into a string and reassign.
 *
 * @param data
 * @param separator
 */
export const processNumbers = (data: any, separator = ','): void => {
  if (isEmpty(data)) {
    return;
  }

  data.numbers = Array.isArray(data?.numbers) ? data.numbers.join(separator) : '';
};

/**
 * @name transformDataForForm
 * @description Transform data for use in the form.
 *
 * @param data
 *
 * @returns Updated data object.
 */
export const transformDataForForm = (data: any) => {
  if (!isEmpty(data)) {
    const newData = cloneDeep(data);

    processMembers(newData);
    processNumbers(newData);

    return newData;
  }

  return data;
};

/**
 * @name transformVoicemailForSaving
 * @description Transform form data before saving.
 *
 * @param data
 *
 * @returns Updated data object.
 */
export const transformVoicemailForSaving = (voicemail: FormInput): FormInput => {
  // LOUIS-TODO: change to immer js
  const newVoicemail = cloneDeep(voicemail);

  if (newVoicemail.pin) {
    newVoicemail.pin = newVoicemail.pin.toString();
  }

  newVoicemail.members?.forEach((member, index) => {
    if (Object.prototype.hasOwnProperty.call(member, 'key')) {
      newVoicemail.members[index].id = member.key || '';
      delete newVoicemail.members[index].key;
    }
    if (Object.prototype.hasOwnProperty.call(member, 'endpoint_type')) {
      newVoicemail.members[index].type = member.endpoint_type || '';
      delete newVoicemail.members[index].endpoint_type;
    }
  });

  if (typeof newVoicemail.numbers === 'string') {
    newVoicemail.numbers = newVoicemail.numbers.split(',');
  }

  return newVoicemail;
};
