import TableCellIncomingCall from 'apps/CallCenter/shared/components/TableCellIncomingCall';
import i18next from 'i18next';
import { ShowToast } from 'shared/hooks/useToast';

export const columns = [
  {
    accessor: 'callLabel',
    Cell: ({
      row: {
        original: { isCallback, callLabel, items, priority, skills, timestamp },
      },
    }: any) => (
      <TableCellIncomingCall
        isCallback={isCallback}
        incomingCallLabel={callLabel}
        items={items}
        priority={priority}
        skillsList={skills}
        timestamp={timestamp}
      />
    ),
  },
];

export const onCopyCallIdToClipboard = (showToast: ShowToast) => async (callId: string) => {
  try {
    await navigator.clipboard.writeText(callId);
    showToast.success(
      i18next.t(
        'call_center:containers.dashboard.section.incoming_calls.toast_message.copy_clipboard.success',
      ),
    );
  } catch (exception) {
    showToast.error(
      i18next.t(
        'call_center:containers.dashboard.section.incoming_calls.toast_message.copy_clipboard.error',
      ),
    );
  }
};
