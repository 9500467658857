import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { TABLE_CONSTANTS } from '../Table';
import { StyledEditableTableProps as Props } from './definition';

const CONSTANTS = {
  BORDER: {
    RADIUS: '4px',
  },
};

const StyledEditableTable = styled.div<Props>`
  ${(props) => css`
    display: inline-block;
    margin: 0 0 ${props.theme.spacing(5)};

    &,
    > div {
      ${mixin.borderRadius({ radius: CONSTANTS.BORDER.RADIUS })};
    }

    > div {
      ${mixin.boxShadow()};
      background-color: ${props.theme.core.color.white};
      border: ${TABLE_CONSTANTS.BORDER};
      border-top: none;

      > div,
      > header {
        ${mixin.font({ weight: 600 })};
      }

      > header {
        height: ${TABLE_CONSTANTS.HEIGHT.CELL.TBODY};
        line-height: ${TABLE_CONSTANTS.HEIGHT.CELL.TBODY};
        text-align: center;
      }

      > main {
        &,
        > div:last-of-type,
        > div:last-of-type > span:first-of-type {
          border-bottom-left-radius: ${CONSTANTS.BORDER.RADIUS};
        }

        &,
        > div:last-of-type,
        > div:last-of-type > span:last-of-type {
          border-bottom-right-radius: ${CONSTANTS.BORDER.RADIUS};
        }
      }
    }

    > footer {
      ${mixin.flex({ justifyContent: 'flex-start' })}
      margin: ${props.theme.spacing(1)} 0 0;
    }
  `}
`;

export default StyledEditableTable;
