import { AgentCardDataType } from '../definition';

const defaultProps = {
  data: {
    [AgentCardDataType.CALLS_MISSED]: 0,
    [AgentCardDataType.CALLS_TAKEN]: 0,
    [AgentCardDataType.CALLS_TOTAL]: 0,
  },
  onClick: {
    hideAgent: () => {},
  },
};

export default defaultProps;
