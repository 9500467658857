import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled from 'styled-components';

const StyledEditPanels = styled.div`
  display: grid;
  grid-template: 49px 1fr / 1fr;
  height: 100%;
  overflow: clip;

  > div:not(:first-of-type) {
    width: calc(100vw - ${HEADER_CONSTANTS.LOGO.WIDTH});
    height: calc(
      100vh - (${HEADER_CONSTANTS.HEIGHT} + ${ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT} + 49px)
    );
  }
`;

export default StyledEditPanels;
