import { URL } from 'constant';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import Typography from 'shared/components/Typography';
import { isExternalURL } from 'shared/utility';
import { DashboardPanelProps as Props, PanelContent, PanelContentAll } from './definition';

const DashboardPanel: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { className, children, type }: Props = props;
  const { t } = useTranslation();
  let navigate = useNavigate();

  function handleClick(destination: string) {
    isExternalURL(destination) ? window.open(destination, '_blank') : navigate(destination);
  }

  const PANEL_CONTENT: PanelContentAll = {
    account: {
      label: t('phone_system:containers.dashboard.panel.account.heading.account_details'),
      button: {
        label: t('phone_system:containers.dashboard.panel.account.button.edit_account'),
        destination: '../account',
      },
    },
    knowledge: {
      label: t('phone_system:containers.dashboard.panel.knowledge.heading.knowledge_base'),
      button: {
        label: t('phone_system:containers.dashboard.panel.knowledge.button.submit_a_ticket'),
        destination: URL.SUPPORT.NEW_TICKET,
      },
    },
    numbers: {
      label: t('phone_system:containers.dashboard.panel.numbers.heading.numbers'),
      button: {
        label: t('phone_system:containers.dashboard.panel.numbers.button.buy_number'),
        destination: '../../numbers',
      },
    },
    reporting: {
      label: t('phone_system:containers.dashboard.panel.reporting.heading.reporting'),
      button: {
        label: t('phone_system:containers.dashboard.panel.reporting.button.open_reports'),
        destination: '.',
      },
    },
    status: {
      label: t('phone_system:containers.dashboard.panel.status.heading.service_status'),
    },
  };

  const { label, button }: PanelContent = (type && PANEL_CONTENT[type]) || { label: '' };

  return (
    <Card
      cardProps={{
        className: `MuiCard-dashboard-panel MuiCard-dashboard-panel-${type}${
          !!className ? ` ${className}` : ''
        }`,
      }}
      content={
        <>
          {label && <Typography variant="h2">{label}</Typography>}
          {children}
        </>
      }
      actions={
        button && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => button.destination && handleClick(button.destination)}
          >
            {button.label}
          </Button>
        )
      }
    />
  );
};

export default DashboardPanel;
