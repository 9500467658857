import Labeled from 'shared/components/Labeled';
import * as utility from 'shared/utility';
import FeatureCode, { FEATURE_CODE_CONSTANTS } from './components/FeatureCode';
import defaultProps from './default';
import { LabeledFeatureCodeProps as Props } from './definition';
import StyledLabeledFeatureCode from './style';

const LabeledFeatureCode = (props: Props) => {
  const {
    id,
    hasDirtyIcon,
    hasCogIcon,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    featureCodeProps,
    feedback,
    tooltip,
  } = {
    ...defaultProps,
    ...props,
  };
  const forId = id || utility.generateId('feature-code');

  return (
    <Labeled
      id={forId}
      type="featureCode"
      hasDirtyIcon={hasDirtyIcon}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={labelProps}
      labelWidth={labelWidth}
      inputWidth="large"
      feedback={feedback}
      tooltip={tooltip}
      sizeOffset={hasCogIcon ? FEATURE_CODE_CONSTANTS.WIDTH.ICON : 0}
      styledComponent={StyledLabeledFeatureCode}
    >
      <FeatureCode id={forId} {...featureCodeProps} />
    </Labeled>
  );
};

export default LabeledFeatureCode;
