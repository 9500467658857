import MediaSelect from 'apps/PhoneSystem/shared/MediaSelect';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LabeledCheckbox from 'shared/components/Labeled/components/LabeledCheckbox';
import { FormInput } from '../../../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'contact_list.exclude',
  'vm_to_email_enabled',
  'fax_to_email_enabled',
  'music_on_hold.media_id',
];

const AdvancedSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    formState: { dirtyFields },
    resetField,
    watch,
  } = useFormContext();
  const watchMusicOnHoldMediaId = watch('seat.music_on_hold.media_id');

  useEffect(() => {
    if (watchMusicOnHoldMediaId === undefined) {
      resetField('seat.music_on_hold.media_id');
    }
  }, [watchMusicOnHoldMediaId]);

  return (
    <>
      <h2>{t('phone_system:containers.seats.section.advanced.contact_visibility')}</h2>
      <div role="row">
        <div role="cell">
          {/* Hide From Contact List */}
          <HookFormInputWrapper name="contact_list.exclude" isCheckbox>
            {({ ref, value, isDirty, ...formProps }) => (
              <LabeledCheckbox
                indentWidth="small"
                label={t('phone_system:containers.seats.section.advanced.hide_from_contact_list')}
                checkboxProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.seats.section.advanced.email_notifications')}</h2>
      <div role="row">
        <div role="cell">
          {/* Voicemail Notification */}
          <HookFormInputWrapper name="vm_to_email_enabled" isCheckbox>
            {({ ref, value, isDirty, ...formProps }) => (
              <LabeledCheckbox
                indentWidth="small"
                label={t('phone_system:containers.seats.section.advanced.voicemail_notification')}
                checkboxProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>

          {/* Fax Notification */}
          <HookFormInputWrapper name="fax_to_email_enabled" isCheckbox>
            {({ ref, value, isDirty, ...formProps }) => (
              <LabeledCheckbox
                indentWidth="small"
                label={t('phone_system:containers.seats.section.advanced.fax_notification')}
                checkboxProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.seats.section.advanced.music_on_hold')}</h2>
      <div role="row">
        <div role="cell">
          {/* Music on Hold */}
          <MediaSelect
            isClearable={Boolean(dirtyFields?.seat?.music_on_hold?.media_id)}
            name="music_on_hold.media_id" // music_on_hold
            labelWidth="none"
          />
        </div>
      </div>
    </>
  );
};

export default AdvancedSection;
