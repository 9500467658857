import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFlowContainerProps as Props } from './definition';

const StyledFlowContainer = styled.article.attrs((props: Props) => ({
  className: `component-flow-container${props.isPreview ? '-preview' : ''}`,
}))<Props>`
  ${(props) => css`
    ${mixin.flex({ direction: 'column', justifyContent: 'flex-start', wrap: 'wrap' })};

    ${!props.isPreview &&
    css`
      overflow-x: auto; // horizontal scroll
    `}
  `};
`;

export default StyledFlowContainer;
