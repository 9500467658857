import { CardProps } from 'shared/components/Card';
import { ComponentProps } from 'shared/definition';

export enum AgentCardMenuItemType {
  LOG_IN_AGENT = 'log_in_agent',
  LOG_OUT_AGENT = 'log_out_agent',
  RESTART_AGENT = 'restart_agent',
}

export enum AgentCardStatusType {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  LOGGED_IN = 'logged_in',
  LOGGED_OUT = 'logged_out',
  OUTBOUND = 'outbound',
  PAUSED = 'paused',
  READY = 'ready',
  WRAP_UP = 'wrapup',
}

export interface AgentCardProps extends ComponentProps, Partial<CardProps> {
  /** Agent id */
  id: string;
  /** Data object */
  data: {
    /** Agent data */
    agent?: AgentResponse;
    /** Queue data */
    queue?: QueueHandled;
    /** Stats data */
    stats?: AgentStats;
    /** Status data */
    status?: AgentStatus;
  };
  /** onClick event handler object */
  onClick: {
    logInAgent?: () => void;
    logOutAgent?: () => void;
    restartAgent?: () => void;
  };
}
