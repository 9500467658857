/* eslint-disable react/jsx-no-useless-fragment */
import { useFetchAccountQuery } from 'models/Account';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import CopyToClipboardButton from 'shared/components/Labeled/components/CopyToClipboardButton';
import Loading from 'shared/components/Loading';
import DashboardPanel from '../DashboardPanel';
import { DashboardPanelAccountProps as Props } from './definition';

const DashboardPanelAccount: FunctionComponent<Props> = (): JSX.Element => {
  const { data, error, isLoading } = useFetchAccountQuery();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {data && (
        <DashboardPanel type="account" className={isOpen ? 'is-open' : ''}>
          <>
            <Box>
              <Box>
                <Box component="strong">
                  {t('phone_system:containers.dashboard.panel.account.label.account_name')}
                  <Icon
                    name="chevron-down-round"
                    size={20}
                    onClick={() => setIsOpen((isOpen) => !isOpen)}
                  />
                </Box>
                <Box component="span">{data.name}</Box>
              </Box>
              <Box>
                <Box component="strong">
                  {t('phone_system:containers.dashboard.panel.account.label.account_type')}
                </Box>
                <Box component="span">
                  {data.is_reseller
                    ? t('phone_system:containers.dashboard.panel.account.account_type.reseller')
                    : t('phone_system:containers.dashboard.panel.account.account_type.standard')}
                </Box>
              </Box>
              <Box>
                <Box component="strong">
                  {t('phone_system:containers.dashboard.panel.account.label.status')}
                </Box>
                <Box component="span">
                  {data.enabled
                    ? t('phone_system:containers.dashboard.panel.account.label.status_active')
                    : t('phone_system:containers.dashboard.panel.account.label.status_disabled')}
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <Box component="strong">
                  {t('phone_system:containers.dashboard.panel.account.label.account_id')}
                  <CopyToClipboardButton text={data.id} size={20} />
                </Box>
                <Box component="span">{data.id}</Box>
              </Box>
              <Box>
                <Box component="strong">
                  {t('phone_system:containers.dashboard.panel.account.label.account_realm')}
                  <CopyToClipboardButton text={data.realm} size={20} />
                </Box>
                <Box component="span">{data.realm}</Box>
              </Box>
            </Box>
          </>
        </DashboardPanel>
      )}
    </>
  );
};

export default DashboardPanelAccount;
