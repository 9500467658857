import styled, { css } from 'styled-components';
import { getStyle } from '../LabeledCheckbox';
import { StyledLabeledSwitchProps as Props } from './definition';

const StyledLabeledSwitch = styled.div<Props>`
  ${(props) => css`
    ${getStyle({ isLeft: props.isSwitchLeft as boolean, props, type: 'switch' })};
  `}
`;

export default StyledLabeledSwitch;
