import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import { Props } from './definition';
import { StyledFlowChildLabel } from './style';

const FlowChildLabel: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { node, handlePreview, isPreview }: Props = props;
  const { isActivated: isDragging } = node;
  const { callflow } = node;
  const iconProps = callflow?.iconProps ? callflow.iconProps : { name: 'settings-filled' };

  return (
    <StyledFlowChildLabel
      isDragging={isDragging}
      hasDialog={callflow?.hasDialog}
      isPreview={isPreview}
      onClick={() => !isPreview && callflow?.hasDialog && handlePreview()}
    >
      <Icon {...iconProps} />
      {callflow?.label && <span>{callflow.label}</span>}
    </StyledFlowChildLabel>
  );
};

export default FlowChildLabel;
