import labelProps from 'shared/components/InputLabel/default';
import labeledProps from 'shared/components/Labeled/default';
import { WidthType } from 'shared/utility/definition';
import { defaultProps as daysOfWeekProps } from './components/DaysOfWeek';

const defaultProps = {
  ...labeledProps,
  label: '__DAYSOFWEEK_LABEL__',
  labelProps: { ...labelProps, isAbove: true },
  labelWidth: 'large' as WidthType,
  daysOfWeekProps,
};

export default defaultProps;
