import { AppMeta } from 'apps/shared/definition';

export const meta: AppMeta = {
  isIframeApp: true,
  i18n: {
    label: 'QStats',
  },
  icon: 'qstats',
  name: 'qstats',
  slug: 'qstats',
};
