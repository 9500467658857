import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class SetCallPriorityActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.SET_CALL_PRIORITY,
      module: CallFlowModuleKey.SET_CALL_PRIORITY,
      section: CallFlowSection.CALL_CENTER,
      rules: [],
      iconProps: {
        name: 'star-outlined',
      },
      label: i18next.t('phone_system:containers.callflows.actions.set_call_priority.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.set_call_priority.tooltip'),
    });
  }

  static subtitle = (nodeData: any) => nodeData?.value;
}

export default SetCallPriorityActionConfig;
