import { useLazyFetchCallflowsByOwnerUsernameQuery } from 'models/Callflow';
import { useCallback } from 'react';

const useLazyFetchBridgeCallflow = (
  bridgeNumber: string,
  customFetchCallflowsByOwnerUsername?: () => any,
) => {
  const [fetchCallflowsByOwnerUsernameQuery] = useLazyFetchCallflowsByOwnerUsernameQuery();

  const fetchCallflowsByOwnerUsername =
    customFetchCallflowsByOwnerUsername ?? fetchCallflowsByOwnerUsernameQuery;

  const fetchBridgeCallflow = useCallback(async () => {
    const bridgeCallflow = ((await fetchCallflowsByOwnerUsername({
      username: bridgeNumber,
    }).unwrap()) as any)?.[0];

    return bridgeCallflow;
  }, [bridgeNumber, fetchCallflowsByOwnerUsername]);

  return fetchBridgeCallflow;
};

export default useLazyFetchBridgeCallflow;
