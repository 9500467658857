import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class QueueLoginActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.QUEUE_LOGIN,
      module: CallFlowModuleKey.QUEUE_LOGIN,
      section: CallFlowSection.CALL_CENTER,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'queue-join',
      },
      label: i18next.t('phone_system:containers.callflows.actions.queue_login.name'),
    });
  }
}

export default QueueLoginActionConfig;
