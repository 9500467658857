import { css, FlattenSimpleInterpolation } from 'styled-components';
import * as utility from '..';
import { WidthType } from '../definition';
import { props, value } from './default';
import {
  BorderRadius,
  BorderRadiusType,
  BoxShadow,
  Flex,
  Font,
  Grid,
  Hover,
  Transition,
  TransitionDurationType,
  Width,
} from './definition';

/**
 * mixin.borderRadius()
 *
 * @param {Object} [object] - borderRadius property object
 * @property {number|string} [radius] - radius: Specifies the amount of radius to apply to the corners of an element's outer border edge
 *
 * @example
 *
 * div {
 *   {mixin.borderRadius()}; // border-radius: 6px;
 * }
 *
 * div {
 *   {mixin.borderRadius({ radius: 'half' })}; // border-radius: 3px;
 * }
 *
 * div {
 *   {mixin.borderRadius({ radius: 'default default 0 0' })}; // border-radius: 6px 6px 0 0;
 * }
 *
 * @return {FlattenSimpleInterpolation} - CSS properties
 */
export const borderRadius = (
  {
    radius = (props.radius as unknown) as number | string | BorderRadiusType,
  }: BorderRadius = props.borderRadius,
): FlattenSimpleInterpolation => {
  const parseRadius = (radius: string): string => {
    Object.entries(value.borderRadius).forEach(([key, value]: string | unknown[]) => {
      const regexp = new RegExp(key as string, 'g');
      radius = radius.replace(regexp, value as string);
    });
    return radius;
  };
  return css`
    border-radius: ${typeof radius === 'string'
      ? radius in value.borderRadius
        ? value.borderRadius[radius]
        : parseRadius(radius)
      : `${radius}px`};
  `;
};

/**
 * mixin.boxShadow()
 *
 * @param {Object} [object] - boxShadow property object
 * @property {number} [offsetX] - offset-x: Specifies the horizontal distance. Negative values place the shadow to the left of the element
 * @property {number} [offsetY] - offset-y: Specifies the vertical distance. Negative values place the shadow above the element
 * @property {number} [blurRadius] - blur-radius: The larger this value, the bigger the blur, so the shadow becomes bigger and lighter. Negative values are not allowed
 * @property {number} [spreadRadius] - spread-radius: Positive values will cause the shadow to expand and grow bigger, negative values will cause the shadow to shrink
 * @property {number} [alpha] - rgb() alpha: A percentage, between 0 (full transparency) and 100 (full opacity)
 *
 * @example
 *
 * div {
 *   {mixin.boxShadow({ blurRadius: 10 })};
 * }
 *
 * @return {FlattenSimpleInterpolation} - CSS properties
 */
export const boxShadow = (
  {
    offsetX = props.boxShadow.offsetX as number,
    offsetY = props.boxShadow.offsetY as number,
    blurRadius = props.boxShadow.blurRadius as number,
    spreadRadius = props.boxShadow.spreadRadius as number,
    alpha = props.boxShadow.alpha as number,
  }: BoxShadow = props.boxShadow,
): FlattenSimpleInterpolation => css`
  box-shadow: ${offsetX}px ${offsetY}px ${blurRadius}px ${spreadRadius}px rgb(0 0 0 / ${alpha}%);
`;

/**
 * mixin.button()
 *
 * @param {Object} [object] - button property object
 * @property {boolean} [backgroundColor] - background-color: Specifies the background color
 * @property {boolean} [borderColor] - border-color: Specifies the border color
 * @property {boolean} [color] - color: Specifies the label color
 * @property {boolean} [props] - Passed for theme colors
 *
 * @return {FlattenSimpleInterpolation} - CSS properties
 */
export const button = ({
  backgroundColor,
  borderColor,
  color,
  props,
}: {
  backgroundColor?: string;
  borderColor?: string;
  color?: string;
  props: any;
}): FlattenSimpleInterpolation => css`
  ${borderRadius()};
  border: 1px solid ${borderColor ?? props.theme.whitelabel.secondary.color};
  cursor: pointer;
  user-select: none;

  ${props &&
  css`
    ${flex()};
    ${transition([
      { property: 'color', duration: 'medium' },
      { property: 'background-color', duration: 'medium' },
    ])};
    min-width: 140px;
    height: 40px; // TODO: Add global constant
    margin: 0;
    padding: 0 ${props.theme.spacing(2.5)};
    background-color: ${backgroundColor ?? props.theme.core.color.white};
    color: ${color ?? props.theme.whitelabel.secondary.color};

    &:hover {
      background-color: ${color ?? props.theme.whitelabel.secondary.color};
      color: ${backgroundColor ?? props.theme.core.color.white};
    }
  `}
`;

/**
 * mixin.stripe()
 *
 * @param {Object} [props] - props: Passed for theme colors
 * @param {?string} [match] - Pattern to match elements [even|odd]
 *
 * @return {FlattenSimpleInterpolation} - CSS properties
 */
export const stripe = (
  { props }: { props: any },
  match: string = 'even',
): FlattenSimpleInterpolation => css`
  &:nth-of-type(${match}) {
    background-color: ${utility.alphafy(props.theme.core.background.grey.light.color, 0.8)};
  }
`;

/**
 * @name mixin.flex
 *
 * @param [object] - flex property object
 * @property [isInline] - isInline: Specifies whether the flexbox element is treated as a flex or inline-flex element
 * @property [alignContent] - align-content: Specifies the distribution of space between and around content items along a flexbox's cross-axis or a grid's block axis.
 * @property [alignItems] - align-items: Specifies the alignment of items on the flex container cross-axis (i.e. the opposite axis of the axis specified by flex-direction [the main-axis])
 * @property [direction] - flex-direction: Specifies how flex items are placed in the flex container defining the main axis and the direction (normal or reversed)
 * @property [gap] - gap: Sets the gaps (gutters) between rows and columns
 * @property [justifyContent] - justify-content: Specifies how the browser distributes space between and around content items along the main-axis of a flex container
 * @property [wrap] - flex-wrap: Sets whether flex items are forced onto one line or can wrap onto multiple lines. If wrapping is allowed, it sets the direction that lines are stacked
 *
 * @return CSS properties
 *
 * @example
 *
 * div {
 *   {mixin.flex({ justifyContent: 'flex-start' })};
 * }
 */
export const flex = (
  {
    isInline = props.flex.isInline as boolean,
    alignContent = props.flex.alignContent as string,
    alignItems = props.flex.alignItems as string,
    direction = props.flex.direction as string,
    gap = props.flex.gap as number,
    justifyContent = props.flex.justifyContent as string,
    wrap = props.flex.wrap as string,
  }: Flex = props.flex,
): FlattenSimpleInterpolation => css`
  display: ${`${isInline ? 'inline-' : ''}flex`};
  flex-direction: ${direction};
  flex-wrap: ${wrap};
  align-content: ${alignContent};
  align-items: ${alignItems};
  justify-content: ${justifyContent};
  ${!!gap && `gap: ${gap}${typeof gap === 'number' ? 'px' : ''}`};
`;

/**
 * mixin.font()
 *
 * @param {Object} [object] - font property object
 * @property {number|string} [size] - font-size: Specifies the size of the font; if specified as a number, the units 'px' will automagically be appended to the value
 * @property {string} [style] - font-style: Specifies whether a font should be styled with a normal, italic, or oblique face
 * @property {string} [weight] - font-weight: Specifies the weight (or boldness) of the font
 * @property {string} [lineHeight] - line-height: Specifies the height of a line box; it's commonly used to set the distance between lines of text
 *
 * @return {FlattenSimpleInterpolation} - CSS properties
 *
 * @example
 *
 * div {
 *   {mixin.font({ size: 32, weight: 600 })};
 * }
 */
export const font = ({
  family,
  size,
  style,
  weight,
  lineHeight,
  letterSpacing,
}: Font = {}): FlattenSimpleInterpolation => css`
  ${!!family && `font-family: ${family}`};
  ${!!size && `font-size: ${size}${typeof size === 'number' ? 'px' : ''}`};
  ${!!style && `font-style: ${style}`};
  ${!!weight && `font-weight: ${weight}`};
  ${!!lineHeight && `line-height: ${lineHeight}${typeof lineHeight === 'number' ? 'em' : ''}`};
  ${!!letterSpacing && `letter-spacing: ${letterSpacing}`};
`;

/**
 * mixin.grid()
 *
 * @param {Object} [object] - grid property object
 * @property {string} [alignItems] - align-items
 * @property {string} [justifyContent] - justify-content
 *
 * @return {FlattenSimpleInterpolation} - CSS properties
 *
 * @example
 *
 * div {
 *   {mixin.grid({ justifyContent: 'center' })};
 * }
 */
export const grid = (
  {
    alignItems = props.grid.alignItems as string,
    justifyContent = props.grid.justifyContent as string,
  }: Grid = props.grid,
): FlattenSimpleInterpolation => css`
  display: grid;
  align-items: ${alignItems};
  justify-content: ${justifyContent};
`;

/**
 * mixin.hover
 *
 * For use with the hover pseudo-class of a text element
 *
 * @param {Object} [object] - hover property object
 * @property {string} [fontWeight] - font-weight: Specifies the weight (or boldness) of the font
 * @property {number} [letterSpacing] - letter-spacing: Specifies the horizontal spacing behavior between text characters
 *
 * @return {FlattenSimpleInterpolation} - CSS properties
 *
 * @example
 *
 * a:hover {
 *   {mixin.hover()};
 * }
 *
 * a:hover {
 *   {mixin.hover({ fontWeight: 600, letterSpacing: -0.015 })};
 * }
 */
export const hover = (
  {
    fontWeight = props.hover.fontWeight as number | string,
    letterSpacing = props.hover.letterSpacing as number,
  }: Hover = props.hover,
): FlattenSimpleInterpolation => css`
  font-weight: ${fontWeight};
  letter-spacing: ${letterSpacing}em;
`;

/**
 * mixin.transition()
 *
 * @param {Object|Object[]} object - transition property object
 * @property {string} [property] - Specifies the name of the CSS property to which a transition should be applied
 * @property {number|string} [duration] - transition-duration: Specifies the duration over which transitions should occur; accepts either [fastest|faster|fast|medium|default|slow] or a number
 * @property {string} [timingFunction] - transition-timing-function: Specifies a function to define how intermediate values for properties are computed
 * @property {number} [delay] - transition-delay: Defines how long to wait between the time a property is changed and the transition actually begins
 *
 * @return {FlattenSimpleInterpolation} - CSS properties
 *
 * This function will accept either an object or an array of objects, as specifed above--see examples below.
 *
 * @example
 *
 * span {
 *   {mixin.transition({ property: color, duration: 500 })};
 * }
 *
 * span {
 *   {mixin.transition([{ property: color, duration: 500 }, { property: opacity, duration: 250 }])};
 * }
 */
export const transition = (args: Transition | Transition[]): FlattenSimpleInterpolation => {
  const getTransition = (
    property: string,
    duration: number | TransitionDurationType = props.transition.duration as TransitionDurationType,
    timingFunction: string = props.transition.timingFunction as string,
    delay: number | string = props.transition.delay as number,
  ): string => {
    const _duration =
      typeof duration === 'string'
        ? duration in value.transition.duration
          ? `${value.transition.duration[duration]}ms`
          : duration
        : `${duration}ms`;
    const _delay = `${delay}${typeof delay === 'number' ? 'ms' : ''}`;
    return `${property} ${_duration} ${timingFunction} ${_delay}`;
  };

  return css`
    transition: ${Array.isArray(args)
      ? args
          .map((item: Transition) =>
            getTransition(
              item.property as string,
              ((item.duration as unknown) as number | TransitionDurationType) ||
                ((props.transition.duration as unknown) as number | TransitionDurationType),
              (item.timingFunction as string) || (props.transition.timingFunction as string),
              (item.delay as number | string) || (props.transition.delay as number | string),
            ),
          )
          .join(', ')
      : getTransition(args.property, args.duration, args.timingFunction, args.delay)};
  `;
};

/**
 * mixin.width()
 *
 * @param {Object} [object] - width property object
 * @property {WidthType} [width] - Specifies the element width [none|small|medium|large|auto]
 * @property {string} [type] - Specifies the element type [featureCode|input|label|picker|select]
 * @property {number} [offset] - Specifies any additional width
 *
 * @example
 *
 * div {
 *   {mixin.width({ width: 'medium', type: 'input' })};
 * }
 *
 * @return {FlattenSimpleInterpolation} - CSS properties
 */
export const width = ({
  width = props.width.width as WidthType,
  type = props.width.type as string,
  offset = props.width.offset as number,
}: Width): FlattenSimpleInterpolation => css`
  width: ${utility.getWidth({
    width: {
      input: type === 'label' ? 'none' : width,
      label: type === 'label' ? width : 'none',
    },
    type,
    offset,
  })};
`;
