import cloneDeep from 'lodash/cloneDeep';
import { callerInfoMatchSchema } from 'models/Account';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EditableTable from 'shared/components/EditableTable';
import { v4 as uuidv4 } from 'uuid';
import CallerInfoMatchDeleteDialog from '../CallerInfoMatchDeleteDialog';
import CallerInfoMatchDialog from '../CallerInfoMatchManageDialog';
import { defaultValues } from './default';
import { DialogFieldData, Platform } from './definition';
import StandardRow from './StandardRow';
import { getDisplayName, getSearchByValue } from './utiltity';

const CallerInfoMatchRules: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const platforms: Platform[] = getValues('screen_pop_platforms');

  const onSave = (data: DialogFieldData) => {
    const { id, ...rest } = data;
    const { display_name: name, search_url, rule_name } = rest;
    const search_by = getSearchByValue(rule_name, search_url);
    const display_name = getDisplayName(rule_name, name);
    const updatedPlatforms = [...platforms, { ...rest, search_by, display_name }];

    setValue('screen_pop_platforms', [...updatedPlatforms], {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onUpdate = (data: DialogFieldData) => {
    const { newId, id, ...rest } = data;
    const { display_name: name, search_url, rule_name } = rest;

    const search_by = getSearchByValue(rule_name, search_url);
    const display_name = getDisplayName(rule_name, name);

    const updatedPlatforms = cloneDeep(platforms).map((platform) => {
      if ((platform.id && platform.id === id) || platform.newId === newId) {
        const platform = { ...rest, search_by, display_name };
        return id ? { ...platform, id } : { ...platform, newId: uuidv4() };
      }
      return platform;
    });

    setValue('screen_pop_platforms', updatedPlatforms, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onDelete = (id: string) => {
    const updatedPlatforms = platforms.filter(
      (platform) => platform.id !== id && platform.newId !== id,
    );
    setValue('screen_pop_platforms', updatedPlatforms, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <EditableTable
      data={platforms}
      hasRowDelete={false}
      components={{
        dialog: {
          button: {
            save: { props: { label: t('common:save'), color: 'success', variant: 'contained' } },
            delete: { props: { variant: 'outlined' } },
          },
          component: CallerInfoMatchDialog,

          defaultValues,
          schema: callerInfoMatchSchema,
          delete: {
            component: CallerInfoMatchDeleteDialog,
          },
          title: {
            update: t(
              'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.manage.title',
            ),
            create: t(
              'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.manage.title',
            ),
          },

          onCancel: () => {},
          onDelete,
          onSave,
        },
        row: {
          component: StandardRow,
          title: 'display_name',
          onUpdate,
        },
      }}
      text={{
        button: t(
          'phone_system:containers.account.submodule.settings.section.caller_info_match.table.add_button',
        ),
        heading: t(
          'phone_system:containers.account.submodule.settings.section.caller_info_match.table.heading',
        ),
        columns: [
          t(
            'phone_system:containers.account.submodule.settings.section.caller_info_match.table.column.rule_name',
          ),
          t(
            'phone_system:containers.account.submodule.settings.section.caller_info_match.table.column.action',
          ),
        ],
        nodata: '',
      }}
    />
  );
};

export default CallerInfoMatchRules;
