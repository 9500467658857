import { SelectOption } from 'shared/components/Select';

export enum FilterOption {
  All = 'all',
}

export interface UseFilterOptionsParams {
  /** Include the 'All' option */
  hasAllOption?: boolean;
  /** Options (label/value) */
  options: Array<SelectOption>;
}
