import { FieldValueType } from 'models/AdvancedProvisioner/types';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';

// Register i18n keys
// t('advanced_provisioner:dynamic_form.edit_form.dynamic_input.inherit_type.accounts');
// t('advanced_provisioner:dynamic_form.edit_form.dynamic_input.inherit_type.defaults');
// t('advanced_provisioner:dynamic_form.edit_form.dynamic_input.password.tooltip.inherit_message');
// t('advanced_provisioner:dynamic_form.edit_form.dynamic_input.text.tooltip.inherit_message');
// t('advanced_provisioner:dynamic_form.edit_form.dynamic_input.select.tooltip.inherit_message');
// t('advanced_provisioner:dynamic_form.edit_form.dynamic_input.settings.admin.password.tooltip');

export interface useComposedTooltipParams {
  fieldValue: any;
  inheritValue?: FieldValueType;
  inputType: string;
  isInheritFromAccount?: boolean;
  tooltip?: string;
  fieldKey?: string;
}

const fieldKeyWithCustomTooltipMap: Record<string, string> = {
  'admin.password':
    'advanced_provisioner:dynamic_form.edit_form.dynamic_input.settings.admin.password.tooltip',
};

export const useComposedTooltip = ({
  fieldValue,
  inheritValue,
  inputType = 'text',
  isInheritFromAccount,
  tooltip,
  fieldKey,
}: useComposedTooltipParams) => {
  const { t } = useTranslation();
  const hasFieldValue = fieldValue !== null && fieldValue !== undefined && fieldValue !== '';
  const hasInheritValue =
    !hasFieldValue && inheritValue !== null && inheritValue !== undefined && inheritValue !== '';
  if (!hasInheritValue && !tooltip) {
    return undefined;
  }

  // Check for custom tooltip
  if (fieldKey && fieldKeyWithCustomTooltipMap[fieldKey]) {
    return (
      <Box component="span" whiteSpace="pre-line">
        {t(fieldKeyWithCustomTooltipMap[fieldKey])}
      </Box>
    );
  }

  return (
    <Box component="span" whiteSpace="pre-line">
      {hasInheritValue
        ? `${t(
            `advanced_provisioner:dynamic_form.edit_form.dynamic_input.${inputType}.tooltip.inherit_message`,
            {
              inheritValue: inputType !== 'password' ? inheritValue : '',
              inheritType: isInheritFromAccount
                ? `${t(
                    'advanced_provisioner:dynamic_form.edit_form.dynamic_input.inherit_type.accounts',
                  )}`
                : `${t(
                    'advanced_provisioner:dynamic_form.edit_form.dynamic_input.inherit_type.defaults',
                  )}`,
            },
          )}${tooltip ? '\n\n' : ''}`
        : ''}
      {tooltip ?? ''}
    </Box>
  );
};
