import { ForwardedRef, forwardRef, FunctionComponent } from 'react';
import defaultProps from './default';
import { CallflowContainerProps as Props } from './definition';
import StyledCallflowContainer from './style';

export { CONSTANTS as CALLFLOW_CONTAINER_CONSTANTS } from './style';

const CallflowContainer: FunctionComponent<Props> = forwardRef<any, Props>(
  (props: Props, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
    const { children } = { ...defaultProps, ...props };

    return (
      <StyledCallflowContainer ref={ref} isPreview>
        {children}
      </StyledCallflowContainer>
    );
  },
);

export default CallflowContainer;
