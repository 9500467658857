import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { ENDPOINT_TYPES } from '../../constants';

const schema = () =>
  Joi.object({
    users: Joi.array()
      .items(
        Joi.object({
          endpoint_type: Joi.string().valid(...ENDPOINT_TYPES),
          id: Joi.string().optional().allow(''),
          key: Joi.string(),
          name: Joi.string(),
        }),
      )
      .min(0),
    emails: Joi.string()
      .email({
        tlds: { allow: false },
        multiple: true,
      })
      .allow(''),
    nodeId: Joi.string(),
  }).messages(messages());

export default schema;
