import Joi from 'joi';
import { messages } from 'shared/utility/validation';

const schema = () =>
  Joi.object({
    nodeId: Joi.string(),
    regex: Joi.when(Joi.ref('use_absolute_mode'), {
      is: Joi.boolean().valid(false),
      then: Joi.string().required(),
    }),
    use_absolute_mode: Joi.boolean(),
  }).messages(messages());

export default schema;
