import { IAM } from 'api/iam';
import { RequestMethod } from 'definition';
import { iamApi, responseHandler, TAGS } from 'services/iam';

export const iamMutations = iamApi.injectEndpoints({
  endpoints: (builder) => ({
    createIdp: builder.mutation<IdpResponse, IdpRequestBody>({
      query: (body) => {
        return {
          url: IAM.CreateIdp(),
          method: RequestMethod.Post,
          body,
          responseHandler,
        };
      },
      invalidatesTags: (_, error) => (!error ? [{ type: TAGS.Idp }] : []),
    }),
    updateIdp: builder.mutation<IdpResponse, IdpRequestBody>({
      query: (body) => ({
        url: IAM.UpdateIdp(),
        method: RequestMethod.Put,
        body,
        responseHandler,
      }),
      invalidatesTags: () => [{ type: TAGS.Idp }],
    }),
    deleteIdp: builder.mutation<void, void>({
      query: () => ({
        url: IAM.DeleteIdp(),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: () => [{ type: TAGS.Idp }],
    }),
    enableSSO: builder.mutation<void, { enabled: boolean }>({
      query: ({ enabled }) => ({
        url: IAM.EnableSSO(enabled),
        method: RequestMethod.Post,
        responseHandler,
      }),
    }),
  }),
});
