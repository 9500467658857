import useHorizontalTabs from 'apps/shared/hooks/useHorizontalTabs';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { HorizontalTabsProps as Props } from './definition';
import { useGetHorizontalTabs } from './hooks';
import { StyledEditPanels } from './style';
import { getTabUrl, isLastSlugNumeric } from './utility';

export const HorizontalTabs = ({ template, macAddress, formProps }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const { Tabs, TabPanels } = useHorizontalTabs({
    items: useGetHorizontalTabs({
      dirtyFields,
      errors,
      formProps,
      template,
    }),
    options: {
      hasMenuOffset: false,
      isCentered: true,
    },
    onChange: (e: ChangeEvent<{}>, value: string) =>
      navigate(getTabUrl(macAddress, value), { replace: true }),
  });

  return (
    <StyledEditPanels $hasFormActionRow={isLastSlugNumeric(pathname)}>
      {Tabs}
      {TabPanels}
    </StyledEditPanels>
  );
};
