import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { BaseSyntheticEvent, useRef } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledPicker, LabeledSelect } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { OptionTypeBase } from 'shared/components/Select/definition';
import { dateToSeconds } from 'shared/utility/date';
import { MarginType } from 'shared/utility/definition';
import { CycleType, DurationType, FormFields, OrdinalType } from '../../definition';
import { convertWdays, getOptions } from '../../utility';
import Duration from './components/Duration';
import LabeledDaysOfWeek from './components/LabeledDaysOfWeek';
import translations from './translations';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  FormFields.Cycle,
  FormFields.Days,
  FormFields.Enabled,
  FormFields.EndDate,
  FormFields.HasNoEndDate,
  FormFields.Interval,
  FormFields.IsAllDayEvent,
  FormFields.Month,
  FormFields.Name,
  FormFields.Ordinal,
  FormFields.RangeStart,
  FormFields.RangeStop,
  FormFields.StartDate,
  FormFields.TimeWindowStart,
  FormFields.TimeWindowStop,
  FormFields.Wdays,
];

const OptionsSection = () => {
  const { t } = useTranslation();
  const { components, fields } = translations();
  const { control, getValues, resetField, setValue, watch } = useFormContext();
  const watchFields = watch();

  const previousRef = {
    time: useRef<Record<string, Date>>(),
    endDate: useRef<Record<string, Date>>(),
  };

  /**
   * Toggling "All Day Event" does the following:
   * • Saves the current start/stop times or if necessary, reverts them
   * • Disables/enables both start/stop time pickers
   */
  const onAllDayEventClick = ({ target: { checked } }: BaseSyntheticEvent) => {
    if (checked) {
      previousRef.time.current = {
        start: getValues(FormFields.TimeWindowStart),
        stop: getValues(FormFields.TimeWindowStop),
      };
      setValue(FormFields.TimeWindowStart, new Date().setHours(0, 0, 0, 0));
      setValue(FormFields.TimeWindowStop, new Date().setHours(23, 59, 59, 0));
    } else if (previousRef.time.current) {
      setValue(FormFields.TimeWindowStart, previousRef.time.current.start);
      setValue(FormFields.TimeWindowStop, previousRef.time.current.stop);
    } else {
      setValue(FormFields.TimeWindowStart, '');
      setValue(FormFields.TimeWindowStop, '');
      setValue(FormFields.Duration, { [DurationType.Start]: 0, [DurationType.Stop]: 0 });
    }
  };

  /**
   * Toggling "No End Date" does the following:
   * • Saves the current end date or if necessary, reverts it
   * • Disables/enables end date picker
   */
  const onEndDateClick = ({ target: { checked } }: BaseSyntheticEvent) => {
    if (checked) {
      previousRef.endDate.current = getValues(FormFields.EndDate);
      setValue(FormFields.EndDate, '');
    } else if (previousRef.endDate.current) {
      setValue(FormFields.EndDate, previousRef.endDate.current);
    } else {
      setValue(FormFields.EndDate, '');
    }
  };

  const onTimePickerAccept = (value: Date | null, type: DurationType) => {
    if (value) {
      setValue(FormFields.Duration, {
        ...getValues(FormFields.Duration),
        [type]: dateToSeconds(value),
      });
    }
  };

  const renderInput = () => <></>;

  return (
    <>
      <div className="underline" role="row">
        <div role="cell">
          {/* Name */}
          <HookFormInputWrapper name={FormFields.Name}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={formProps}
                isDirty={isDirty}
                label={fields.name.label}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div className="one-column" role="row">
        <div role="cell">
          <HookFormInputWrapper name={FormFields.StartDate}>
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledPicker
                datePickerProps={{
                  ...formProps,
                  onChange: (value) => onChange(value ?? ''),
                  renderInput,
                }}
                feedback={feedback}
                isDirty={isDirty}
                label={fields.start_date.label}
                pickerWidth="medium"
                type="date"
              />
            )}
          </HookFormInputWrapper>

          {/* End Date */}
          <HookFormInputWrapper name={FormFields.EndDate}>
            {({ ref, isDirty, feedback, onChange, ...formProps }) => {
              const disabled =
                !watchFields[FormFields.StartDate] || watchFields[FormFields.HasNoEndDate];
              return (
                <LabeledPicker
                  datePickerProps={{
                    ...formProps,
                    disabled,
                    readOnly: disabled,
                    onChange: (value) => onChange(value ?? ''),
                    renderInput,
                  }}
                  feedback={feedback}
                  isDirty={isDirty}
                  label={fields.end_date.label}
                  labelWidth="medium"
                  pickerWidth="medium"
                  type="date"
                />
              );
            }}
          </HookFormInputWrapper>

          {/* No End Date? */}
          <HookFormInputWrapper isCheckbox name={FormFields.HasNoEndDate}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isInline
                checkboxProps={{
                  ...formProps,
                  disabled: !watchFields[FormFields.StartDate],
                  onClick: (e: BaseSyntheticEvent) => onEndDateClick(e),
                }}
                feedback={feedback}
                indentWidth="auto"
                isDirty={isDirty}
                label={fields.has_no_end_date.label}
                labelProps={{ leftMargin: MarginType.xmedium }}
                labelWidth="large"
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div className="one-column" role="row">
        <div role="cell">
          {/* Start Time */}
          <HookFormInputWrapper name={FormFields.TimeWindowStart}>
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledPicker
                feedback={feedback}
                isDirty={isDirty}
                label={fields.time_window_start.label}
                pickerWidth="medium"
                timePickerProps={{
                  ...formProps,
                  disabled: watchFields[FormFields.IsAllDayEvent],
                  readOnly: watchFields[FormFields.IsAllDayEvent],
                  onAccept: (value) => onTimePickerAccept(value, DurationType.Start),
                  onChange: (value) => onChange(value ?? ''),
                  renderInput,
                }}
                type="time"
              />
            )}
          </HookFormInputWrapper>

          {/* End Time */}
          <HookFormInputWrapper name={FormFields.TimeWindowStop}>
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledPicker
                feedback={feedback}
                isDirty={isDirty}
                label={fields.time_window_stop.label}
                labelWidth="medium"
                pickerWidth="medium"
                timePickerProps={{
                  ...formProps,
                  disabled: watchFields[FormFields.IsAllDayEvent],
                  readOnly: watchFields[FormFields.IsAllDayEvent],
                  onAccept: (value) => onTimePickerAccept(value, DurationType.Stop),
                  onChange: (value) => onChange(value ?? ''),
                  renderInput,
                }}
                type="time"
              />
            )}
          </HookFormInputWrapper>

          {/* All Day Event? */}
          <HookFormInputWrapper isCheckbox name={FormFields.IsAllDayEvent}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isInline
                checkboxProps={{
                  ...formProps,
                  onClick: (e: BaseSyntheticEvent): void => onAllDayEventClick(e),
                }}
                feedback={feedback}
                indentWidth="auto"
                isDirty={isDirty}
                label={fields.is_all_day_event.label}
                labelProps={{ leftMargin: MarginType.xmedium }}
                labelWidth="large"
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div className="underline" role="row">
        <div role="cell">
          <Duration
            isAllDayEvent={watchFields[FormFields.IsAllDayEvent]}
            time={watchFields[FormFields.Duration]}
          />
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Repeats */}
          <HookFormSelectWrapper name={FormFields.Cycle} options={getOptions(FormFields.Cycle)}>
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledSelect
                feedback={feedback}
                isDirty={isDirty}
                label={fields.cycle.label}
                selectProps={{
                  ...formProps,
                  onChange: (option: OptionTypeBase) => {
                    onChange(option);
                    resetField(FormFields.Wdays, { defaultValue: convertWdays(option, getValues) });
                  },
                }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>

      {watchFields[FormFields.Cycle] !== CycleType.Date && (
        <>
          <div role="row">
            <div role="cell">
              {/* Every */}
              {watchFields[FormFields.Cycle] === CycleType.Yearly ? (
                <HookFormSelectWrapper
                  name={FormFields.Month}
                  options={getOptions(FormFields.Month)}
                >
                  {({ ref, isDirty, feedback, ...formProps }) => (
                    <LabeledSelect
                      feedback={feedback}
                      isDirty={isDirty}
                      label={fields.interval.label}
                      selectProps={formProps}
                    />
                  )}
                </HookFormSelectWrapper>
              ) : (
                <HookFormInputWrapper name={FormFields.Interval}>
                  {({ ref, isDirty, feedback, ...formProps }) => (
                    <LabeledInput
                      feedback={feedback}
                      inputProps={{
                        ...formProps,
                        adornment: {
                          type: 'text',
                          position: 'end',
                          value:
                            fields.interval.suffix[
                              watchFields[FormFields.Cycle] === CycleType.Monthly
                                ? 'months'
                                : 'weeks'
                            ],
                        },
                      }}
                      isDirty={isDirty}
                      label={fields.interval.label}
                    />
                  )}
                </HookFormInputWrapper>
              )}
            </div>
          </div>

          <div className="one-column" role="row">
            <div role="cell">
              {/* On */}
              {watchFields[FormFields.Cycle] === CycleType.Weekly ? (
                <Controller
                  control={control}
                  name={FormFields.Wdays}
                  render={({
                    field: { value, onChange },
                    fieldState: { isDirty },
                  }: {
                    field: Partial<ControllerRenderProps>;
                    fieldState: ControllerFieldState;
                  }) => (
                    <LabeledDaysOfWeek
                      daysOfWeekProps={{
                        data: value ?? [],
                        onChange: (value: Array<string>) => onChange?.(value),
                      }}
                      isDirty={isDirty}
                      label={components.days_of_week.label}
                      tooltip={components.days_of_week.tooltip}
                    />
                  )}
                />
              ) : (
                <>
                  <HookFormSelectWrapper
                    name={FormFields.Ordinal}
                    options={getOptions(FormFields.Ordinal)}
                  >
                    {({ ref, isDirty, feedback, ...formProps }) => (
                      <LabeledSelect
                        feedback={feedback}
                        isDirty={isDirty}
                        label={fields.ordinal.label}
                        selectProps={formProps}
                      />
                    )}
                  </HookFormSelectWrapper>

                  {![OrdinalType.Every, OrdinalType.Range].includes(
                    watchFields[FormFields.Ordinal],
                  ) && (
                    <HookFormSelectWrapper
                      name={FormFields.Wdays}
                      options={getOptions(FormFields.Wdays)}
                    >
                      {({ ref, isDirty, feedback, ...formProps }) => (
                        <LabeledSelect
                          feedback={feedback}
                          isDirty={isDirty}
                          labelProps={{ leftMargin: MarginType.small }}
                          labelWidth="none"
                          selectProps={{
                            ...formProps,
                            placeholder: components.on.wdays.placeholder,
                          }}
                          selectWidth="small"
                        />
                      )}
                    </HookFormSelectWrapper>
                  )}

                  {watchFields[FormFields.Ordinal] === OrdinalType.Every && (
                    <HookFormSelectWrapper
                      name={FormFields.Days}
                      options={getOptions(FormFields.Days)}
                    >
                      {({ ref, isDirty, feedback, onChange, ...formProps }) => (
                        <LabeledSelect
                          feedback={feedback}
                          isDirty={isDirty}
                          labelProps={{ leftMargin: MarginType.small }}
                          labelWidth="none"
                          selectProps={{
                            ...formProps,
                            onChange: (option: OptionTypeBase) => onChange([option]),
                          }}
                          selectWidth="small"
                        />
                      )}
                    </HookFormSelectWrapper>
                  )}

                  {watchFields[FormFields.Ordinal] === OrdinalType.Range && (
                    <>
                      <HookFormSelectWrapper
                        name={FormFields.RangeStart}
                        options={getOptions(FormFields.Days)}
                      >
                        {({ ref, isDirty, feedback, onChange, ...formProps }) => (
                          <LabeledSelect
                            feedback={feedback}
                            isDirty={isDirty}
                            labelProps={{ leftMargin: MarginType.small }}
                            labelWidth="none"
                            selectProps={{
                              ...formProps,
                              onChange: (option: OptionTypeBase) => {
                                onChange(option);
                                if (option.value > getValues(FormFields.RangeStop)) {
                                  setValue(FormFields.RangeStop, option.value);
                                }
                              },
                            }}
                            selectWidth="small"
                          />
                        )}
                      </HookFormSelectWrapper>

                      <HookFormSelectWrapper
                        name={FormFields.RangeStop}
                        options={getOptions(FormFields.Days, watchFields[FormFields.RangeStart])}
                      >
                        {({ ref, isDirty, feedback, ...formProps }) => (
                          <LabeledSelect
                            feedback={feedback}
                            isDirty={isDirty}
                            labelProps={{ leftMargin: MarginType.small }}
                            labelWidth="none"
                            selectProps={formProps}
                            selectWidth="small"
                          />
                        )}
                      </HookFormSelectWrapper>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}

      <div className="overline" role="row">
        <div role="cell">
          {/* Enabled */}
          <HookFormSelectWrapper name={FormFields.Enabled} options={getOptions(FormFields.Enabled)}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                feedback={feedback}
                isDirty={isDirty}
                label={fields.enabled.label}
                selectProps={formProps}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>
    </>
  );
};

export default OptionsSection;
