import i18next from 'i18next';

export const translations = () => ({
  prompt: i18next.t(
    'phone_system:containers.seats.section.basics.components.password_input.feedback.prompt',
  ),
  criteria: [
    i18next.t(
      'phone_system:containers.seats.section.basics.components.password_input.feedback.criteria.0',
    ),
    i18next.t(
      'phone_system:containers.seats.section.basics.components.password_input.feedback.criteria.1',
    ),
    i18next.t(
      'phone_system:containers.seats.section.basics.components.password_input.feedback.criteria.2',
    ),
    i18next.t(
      'phone_system:containers.seats.section.basics.components.password_input.feedback.criteria.3',
    ),
  ],
});
