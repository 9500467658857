import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import defaultProps from './default';
import { CheckmarkIconProps as Props } from './definition';

const CheckmarkIcon = (props: Props) => {
  const { isVisible, iconProps, info } = { ...defaultProps, ...props };
  const icon = <Icon name="check" size={22} {...iconProps} />;

  if (!isVisible) {
    return <></>;
  }

  return info ? <Tooltip title={info}>{icon}</Tooltip> : icon;
};

export default CheckmarkIcon;
