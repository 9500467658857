import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestVerificationCertificateRow as Props } from './definition';
import { StyledCertificateDownloadRow } from './styled';

const RequestVerificationCertificateRow: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { requestVerificationCertificate, signRequest } = props;

  return (
    <StyledCertificateDownloadRow
      signRequest={signRequest}
      label={t(
        'phone_system:containers.account.submodule.settings.section.sso.request_verification_certificate',
      )}
      certificate={requestVerificationCertificate}
      fileName={t(
        'phone_system:containers.account.submodule.settings.section.sso.request_verification_certificate_file_name',
      )}
    />
  );
};

export default RequestVerificationCertificateRow;
