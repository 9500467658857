import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const SMS = {
  // GET
  Fetch: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/oomasmsboxes?paginate=${isPaginated}`,
  FetchByOwnerId: (id: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/oomasmsboxes?filter_owner=${id}`,
  FetchByUserId: (id: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/oomasmsboxes/users/${id}/oomasmsboxes?localNumber=all`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/oomasmsboxes/${id}`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/oomasmsboxes`,

  // PATCH
  Patch: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/oomasmsboxes/${id}`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/oomasmsboxes/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/oomasmsboxes/${id}`,
};
