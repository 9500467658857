import { ForwardedRef, forwardRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupBase, SelectInstance } from 'react-select';
import { ClearIndicator, DropdownIndicator, Option } from './components';
import defaultProps from './default';
import { OptionTypeBase, Props } from './definition';
import StyledSelect from './style';
import { initStyles } from './utility';

export type { SelectOptgroup, SelectOption } from './components/Option';
export { getStyles as getSelectPortalStyles } from './style';
export type { Props };

const Select = forwardRef((props, ref) => {
  const {
    hasBorder,
    options,
    overrideComponents,
    placeholder: placeholderOverride,
    styles,
    onMenuOpen,
    ...rest
  } = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const translations = useMemo(
    () => ({
      select: (placeholderOverride as string) || t('common:component.select.placeholder.select'),
      search:
        (placeholderOverride as string) || t('common:component.select.placeholder.type_to_search'),
    }),
    [placeholderOverride, t],
  );

  const [placeholder, setPlaceholder] = useState<string>(translations.select);

  return (
    <StyledSelect
      ref={ref}
      {...rest}
      hasBorder={hasBorder}
      // @ts-ignore REACT-SELECT-5-TODO: Add this type to the styled component typing
      hasImages={options?.some((option) => (option as OptionTypeBase).image) ?? false}
      classNamePrefix="react-select"
      components={{
        ClearIndicator,
        DropdownIndicator,
        Option,
        ...overrideComponents,
      }}
      menuPlacement="auto"
      options={options}
      placeholder={placeholder}
      onMenuClose={() => setPlaceholder(translations.select)}
      onMenuOpen={() => {
        onMenuOpen?.();
        setPlaceholder(translations.search);
      }}
      // fix for dropdown list appearing under other components
      menuPortalTarget={document.body}
      menuPosition="fixed"
      styles={{ ...{ menu: initStyles, menuPortal: initStyles }, ...(styles ?? {}) }}
    />
  );
}) as <
  Option = unknown,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, GroupType> & {
    ref?: ForwardedRef<SelectInstance<Option, IsMulti, GroupType>>;
  },
) => JSX.Element;

export default Select;
