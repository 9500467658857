import i18next from 'i18next';
import { Column } from 'shared/components/Table';

export const prepareTableData = (queueAgents: string[], users: any[]) =>
  users
    .filter((user: any) => !queueAgents.includes(user.id) && user.priv_level !== 'admin')
    .map((user: any) => ({
      name: `${user.username}, ${user.first_name} ${user.last_name}`,
      id: user.id,
    }));

export const columns = (): Array<Column> => [
  {
    Header: i18next.t(
      'call_center:containers.manage_queues.components.agents_list.table.column.name',
    ) as string,
    accessor: 'name',
  },
];
