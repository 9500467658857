import { ADD_KEY } from 'constant';
import { FunctionComponent, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import SMSEditView from './EditView';
import SMSListView from './ListView';

type Props = {
  id: string;
};

const TabSMS: FunctionComponent<Props> = ({ id = ADD_KEY }: Props) => {
  const routes = useRoutes([
    { path: '/', element: <SMSListView id={id} /> },
    { path: '/:smsId/', element: <SMSEditView /> },
  ]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};

export default TabSMS;
