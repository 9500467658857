import i18next from 'i18next';

const translations = () => ({
  deleteModules: {
    current: i18next.t('phone_system:containers.callflows.delete_modules.current'),
    descendants: i18next.t('phone_system:containers.callflows.delete_modules.descendants'),
  },
});

export default translations;
