import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { useFetchPhonesQuery as useFetchAdvancedPhonesQuery } from 'models/AdvancedProvisioner';
import { useFetchPhonesQuery as useFetchLegacyPhonesQuery } from 'models/Provisioner';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Dialog, { DialogActions, DialogLink, DialogType } from 'shared/components/Dialog';
import { LabeledSelect, LabeledText } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { FormContext } from '../../components/FormContext';
import DeviceList, { DeviceFamily } from './components/DeviceList';
import DeviceText from './components/DeviceText';
import { defaultDeviceValues } from './default';
import { DeviceValues, FormFields } from './definition';
import { StyledDevicePickerField } from './style';
import {
  createBrandStateSetter,
  getFamiliesList,
  getIsModelDirty,
  getModelValue,
  getOptionNotSet,
  getProvisionValues,
  initDeviceValues,
  initFormFieldValues,
  setProvisionValues,
} from './utility';

export {
  FormFields as DevicePickerFormFields,
  getOptionNotSet,
  getModelValue,
  initFormFieldValues,
};

const useDevicePicker = () => {
  const { t } = useTranslation();
  const contextName = useContext(FormContext);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogValues, setDialogValues] = useState<DeviceValues>(defaultDeviceValues);
  const [brands, setBrands] = useState<Array<SelectOption>>([]);
  const [families, setFamilies] = useState<Array<DeviceFamily>>([]);

  const { data: advancedPhonesData } = useFetchAdvancedPhonesQuery();
  const {
    data: legacyPhonesData,
    isLoading: legacyPhonesDataIsLoading,
  } = useFetchLegacyPhonesQuery();

  const {
    formState: { dirtyFields },
    getValues,
    setValue,
    watch,
  } = useFormContext();

  const watchFields = useMemo(
    () => ({
      extraInfo: {
        provisioner: watch(`${contextName}${FormFields.ExtraInfoProvisioner}`),
      },
      provision: {
        brand: watch(`${contextName}${FormFields.ProvisionEndpointBrand}`),
        family: watch(`${contextName}${FormFields.ProvisionEndpointFamily}`),
        model: watch(`${contextName}${FormFields.ProvisionEndpointModel}`),
      },
    }),
    [
      contextName,
      watch(`${contextName}${FormFields.ExtraInfoProvisioner}`),
      watch(`${contextName}${FormFields.ProvisionEndpointBrand}`),
      watch(`${contextName}${FormFields.ProvisionEndpointFamily}`),
      watch(`${contextName}${FormFields.ProvisionEndpointModel}`),
      watch,
    ],
  );

  const isAdvancedProvisioner = useMemo(() => watchFields.extraInfo.provisioner, [
    watchFields.extraInfo.provisioner,
  ]);

  const provisionValues = useMemo(
    () => ({
      core: {
        contextName,
        deviceValues: initDeviceValues(),
        setValue,
      },
      get legacy() {
        return { hasTempDeviceModel: true, isModelDirty: true, ...provisionValues.core };
      },
    }),
    [contextName, setValue],
  );

  const on = useMemo(
    () => ({
      advanced: {
        cancel: () => {
          setDialogValues({ ...watchFields.provision });
          setFamilies([]);
          setIsDialogOpen(false);
        },
        change: {
          brand: (brand: string) => {
            setDialogValues({ brand, family: undefined, model: undefined });

            if (brand === watchFields.provision.brand) {
              setDialogValues({ ...watchFields.provision });
            }

            if (isEmpty(brand) || brand === getOptionNotSet().value) {
              setFamilies([]);
            }
          },
        },
        click: {
          model: (value: string) => setDialogValues(getProvisionValues(value)),
        },
        confirm: () => {
          let device: DeviceValues = initDeviceValues();

          if (dialogValues.brand && dialogValues.family && dialogValues.model) {
            device = { ...dialogValues };
          }

          setProvisionValues(
            merge({}, provisionValues.core, {
              isModelDirty: getIsModelDirty(device),
              deviceValues: { brand: device.brand, family: device.family, model: device.model },
            }),
          );

          setIsDialogOpen(false);
        },
      },
      legacy: {
        change: {
          brand: (value: string) => {
            if (value !== getOptionNotSet().value) {
              const families = getFamiliesList(legacyPhonesData?.[value]?.families);
              const [brand = getOptionNotSet().value, family, model] = !isEmpty(families)
                ? families[0].options[0].value.split('_')
                : [];
              setFamilies(families);
              setProvisionValues(
                merge({}, provisionValues.legacy, { deviceValues: { brand, family, model } }),
              );
            } else {
              setFamilies([]);
              setProvisionValues(
                merge({}, provisionValues.legacy, {
                  deviceValues: { brand: value, family: undefined, model: undefined },
                }),
              );
            }
          },
          model: (value: string) => {
            const [brand = value, family, model] = value.split('_');
            setProvisionValues(
              merge({}, provisionValues.legacy, { deviceValues: { brand, family, model } }),
            );
          },
        },
      },
    }),
    [
      dialogValues,
      legacyPhonesData,
      provisionValues.core,
      provisionValues.legacy,
      watchFields.provision,
      watchFields.provision.brand,
      setDialogValues,
      setIsDialogOpen,
    ],
  );

  useEffect(() => {
    const setBrandState = createBrandStateSetter({ dialogValues, setBrands, setFamilies });

    if (isAdvancedProvisioner) {
      if (advancedPhonesData) {
        setBrandState(advancedPhonesData);
      }
    } else if (legacyPhonesData) {
      setBrandState(legacyPhonesData);
    }
  }, [
    advancedPhonesData,
    legacyPhonesData,
    contextName,
    dialogValues,
    dialogValues.brand,
    getValues,
  ]);

  useEffect(() => {
    if (
      isEmpty(watchFields.provision.brand) ||
      watchFields.provision.brand === getOptionNotSet().value
    ) {
      setDialogValues(initDeviceValues());
      if (isAdvancedProvisioner) {
        setProvisionValues(provisionValues.core);
      }
    } else {
      setDialogValues({ ...watchFields.provision });
    }
    // `watchFields.provision` is excluded from the dependency array; included would trigger an infinite state-update loop
  }, [contextName, provisionValues.core, watchFields.provision.brand, setValue]);

  return {
    advanced: {
      dialog: (
        <Dialog
          open={isDialogOpen}
          renderActions={
            <DialogActions
              rightButtons={
                <>
                  <Button color="secondary" variant="outlined" onClick={on.advanced.cancel}>
                    {t('common:cancel')}
                  </Button>
                  <Button color="secondary" variant="contained" onClick={on.advanced.confirm}>
                    {t('common:confirm')}
                  </Button>
                </>
              }
            />
          }
          type={DialogType.DevicePicker}
          title={t('common:component.device_picker.device_make_model')}
          onClose={on.advanced.cancel}
          data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-usedevicepicker-dialog`}
        >
          <LabeledSelect<{ label: string; value: string }, false>
            hasSmallMargin={families.length === 0}
            isDirty={dialogValues.brand !== watchFields.provision.brand}
            label={t('common:component.device_picker.device_make')}
            labelProps={{ required: true }}
            labelWidth="auto"
            selectProps={{
              options: brands,
              value: {
                label:
                  brands.find((brand) => brand.value === dialogValues.brand)?.label ??
                  getOptionNotSet().label,
                value: dialogValues.brand ?? getOptionNotSet().value,
              },
              onChange: (option: { label: string; value: string } | null) => {
                on.advanced.change.brand(option?.value as string);
              },
            }}
          />
          <DeviceList
            model={getModelValue({ data: dialogValues })}
            families={families}
            onClick={on.advanced.click.model}
          />
        </Dialog>
      ),
      field: (
        <StyledDevicePickerField
          data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-usedevicepicker-field`}
        >
          <LabeledText
            hasNoWrap
            hasRightMargin
            isDirty={
              dirtyFields?.provision?.endpoint_model ||
              dirtyFields?.device?.[watch(`${contextName}id`)]?.provision?.endpoint_model
            }
            label={t('common:component.device_picker.device_make_model')}
            text={<DeviceText data={watchFields.provision} />}
          />
          <DialogLink
            isDisabled={brands.length === 0}
            onClick={brands.length > 0 ? () => setIsDialogOpen(true) : () => {}}
          />
          {brands.length === 0 && <Loading hasLabel={false} />}
        </StyledDevicePickerField>
      ),
    },
    legacy: (
      <>
        {legacyPhonesDataIsLoading && (
          <LabeledText
            label={t('common:component.device_picker.device_make_model')}
            text={<Loading />}
          />
        )}
        {brands.length > 0 && (
          <HookFormSelectWrapper name={FormFields.TempDeviceBrand} options={brands}>
            {({ ref, isDirty, onChange, ...formProps }) => (
              <LabeledSelect
                hasSmallMargin={families.length > 0}
                isDirty={isDirty}
                label={t('common:component.device_picker.device_make_model')}
                selectProps={{
                  ...formProps,
                  onChange: (option: SelectOption) => {
                    on.legacy.change.brand(option.value);
                    return onChange(option);
                  },
                }}
              />
            )}
          </HookFormSelectWrapper>
        )}
        {families.length > 0 && (
          <HookFormSelectWrapper name={FormFields.TempDeviceModel} options={families}>
            {({ ref, isDirty, value, onChange, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                label=""
                selectProps={{
                  ...formProps,
                  value: value ?? families[0].options[0],
                  onChange: (option: SelectOption) => {
                    on.legacy.change.model(option.value);
                    return onChange(option);
                  },
                }}
              />
            )}
          </HookFormSelectWrapper>
        )}
      </>
    ),
  };
};

export default useDevicePicker;
