import theme from '../../core';
import contained from './contained';
import outlined from './outlined';
import root from './root';
import text from './text';

export default {
  defaultProps: {
    disableFocusRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    root, // order matters
    contained,
    outlined,
    text,
    sizeSmall: {
      minWidth: 120,
      height: 32,
    },
    containedSizeLogin: {
      width: '100%',
      height: 50,
      fontSize: 20,
      fontWeight: 500,
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.standard,
      }),
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
    },
    outlinedSizeDrawer: {
      width: 130,
      minWidth: 130,
      height: 40,
      lineHeight: '40px',
    },
  },
};
