import { joiResolver } from '@hookform/resolvers/joi';
import CallflowActionsDialog from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowActionsDialog/components/CallflowActionDialog';
import { HookFormInputWrapper, HookFormRadioGroupWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledRadioGroup } from 'shared/components/Labeled';
import { ABSOLUTE_CALLER_ID, ABSOLUTE_MODE_DEFAULT, DEFAULT_KEY } from '../../../constants';
import defaultProps, { defaultValues } from './default';
import { AbsoluteModeProps as Props, Data } from './definition';
import schema from './schema';

const getMode = (data: any) =>
  data.key === '' || data.key === DEFAULT_KEY || data.key === ABSOLUTE_MODE_DEFAULT
    ? ABSOLUTE_MODE_DEFAULT
    : ABSOLUTE_CALLER_ID;

const getKey = (data: any) => (data?.key ? data.key : DEFAULT_KEY);

const AbsoluteMode: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { control, handleSubmit, watch, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
      key: getKey(data),
      mode: getMode(data),
    },
    resolver: joiResolver(schema()),
  });
  const options = [
    {
      label: t(
        'phone_system:containers.callflows.select_key_dialog.match_caller_id.absolute.add.default',
      ),
      value: ABSOLUTE_MODE_DEFAULT,
    },
    {
      label: t(
        'phone_system:containers.callflows.select_key_dialog.match_caller_id.absolute.add.caller_id',
      ),
      value: ABSOLUTE_CALLER_ID,
    },
  ];
  const watchUseMode = watch('mode');
  const { isDirty } = formState;

  const submitHandler = async (formData: Data) => {
    onSave(formData.mode === ABSOLUTE_CALLER_ID ? formData.key : DEFAULT_KEY, isDirty);
  };

  return (
    <CallflowActionsDialog
      title={t(
        'phone_system:containers.callflows.select_key_dialog.match_caller_id.absolute.title',
      )}
      handleClose={onClose!.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormRadioGroupWrapper name="mode" options={options} control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledRadioGroup
            isLabelAbove
            isDirty={isDirty}
            feedback={feedback}
            label={t('common:mode')}
            radioGroupProps={{ ...formProps }}
            radioProps={options}
          />
        )}
      </HookFormRadioGroupWrapper>

      {watchUseMode === ABSOLUTE_CALLER_ID && (
        <HookFormInputWrapper name="key" control={control}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              labelWidth="none"
              inputWidth="medium"
              inputProps={{ ...formProps }}
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default AbsoluteMode;
