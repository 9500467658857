import { AppMeta } from 'apps/shared/definition';

export const meta: AppMeta = {
  isIframeApp: true,
  i18n: {
    label: 'VQ Mon',
  },
  icon: 'vqmon',
  name: 'vqmon',
  slug: 'vqmon',
};
