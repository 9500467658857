import { themeColor } from 'theme';
import theme from 'theme/mui/core';

const MARGIN = 28;

export default {
  '.MuiCardContent-root': {
    '> div': {
      '&:first-of-type': {
        display: 'flex',
        margin: theme.spacing(6, 0, 0),
        div: {
          '&:first-of-type': {
            flex: 2,
          },
          svg: {
            marginLeft: theme.spacing(2),
            fill: themeColor.brio.ooma.graphite,
          },
        },
      },
      '&:last-of-type': {
        maxHeight: 0,
        margin: 0,
        opacity: 0,
        overflow: 'hidden',
        transition: theme.transitions.create(['margin', 'max-height', 'opacity'], {
          duration: theme.transitions.duration.shortest,
        }),
        '> div': {
          margin: theme.spacing(3.5, 0, 0),
          '> *': {
            wordWrap: 'break-word',
          },
        },
        svg: {
          '&, &:hover': {
            fill: themeColor.brio.ooma.graphite,
          },
        },
      },
      div: {
        flex: 1,
        '> *': {
          fontSize: 18,
          lineHeight: '1.2em',
        },
        strong: {
          fontWeight: 700,
          margin: theme.spacing(0, 0, 0.5),
          '&:first-of-type': {
            display: 'flex',
            alignItems: 'center',
            color: themeColor.whitelabel.primary.color,
          },
        },
        svg: {
          cursor: 'pointer',
          fill: themeColor.core.color.grey.pewter,
          transition: theme.transitions.create(['fill', 'transform'], {
            duration: theme.transitions.duration.shortest,
          }),
          '&:hover': {
            transform: 'rotateZ(0deg) scale(1.15)',
          },
        },
        'div:nth-of-type(2)': {
          color: 'inherit',
        },
      },
    },
  },
  '&.is-open .MuiCardContent-root > div': {
    '&:nth-of-type(1) svg': {
      transform: 'rotateZ(-180deg)',
      '&:hover': {
        fill: themeColor.brio.ooma.graphite,
        transform: 'rotateZ(-180deg) scale(1.15)',
      },
    },
    '&:nth-of-type(2)': {
      maxHeight: 190,
      margin: `0 0 ${MARGIN}px`,
      opacity: 1,
    },
  },
};
