import ListItem from '@mui/material/ListItem';
import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import Typography from 'shared/components/Typography';
import { QueueCardItemProps as Props } from './definition';

export type { QueueCardItem } from './definition';

const QueueCardItem: FunctionComponent<Props> = ({
  data: { icon, label, value },
}: Props): JSX.Element => (
  <ListItem component="div">
    <Icon name={icon} size={16} />
    <Typography>{label}</Typography>
    <Typography>{value}</Typography>
  </ListItem>
);

export default QueueCardItem;
