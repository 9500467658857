import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';

class AgentLoginActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.AGENT_LOGIN,
      module: CallFlowModuleKey.AGENT_LOGIN,
      section: CallFlowSection.CALL_CENTER,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'log-in',
      },
      label: i18next.t('phone_system:containers.callflows.actions.agent_login.name'),
    });
  }

  static subtitle = (nodeData: any) =>
    i18next.t('phone_system:containers.callflows.actions.agent_login.subtitle', {
      id: nodeData?.presence_id,
    });
}

export default AgentLoginActionConfig;
