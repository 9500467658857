import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import kebabCase from 'lodash/kebabCase';
import Box from '../Box';
import { LinkProps as Props } from './definition';

const List = ({ isBulleted, classes, items }: Props) => (
  <MuiList classes={classes}>
    {items?.map((item: any) => (
      <MuiListItem key={`list-item-${kebabCase(item)}`}>
        {isBulleted && <Box component="span">•</Box>}
        <Box component="span">{item}</Box>
      </MuiListItem>
    ))}
  </MuiList>
);

export default List;

//
