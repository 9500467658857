import { Loader } from '@googlemaps/js-api-loader';
import { IS_ENV } from 'constant';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';

// TODO: move these key to backend
export const GOOGLE_MAPS_API_KEY = !IS_ENV.PRODUCTION
  ? 'AIzaSyDc2r5qgxroQ87CUj-J-w72OWrO32TSwZU'
  : 'AIzaSyBwLy42NicyCwnu8-qK59hPpMYi9bAES1M';

declare let window: any;

const placesApiObj = window?.google?.maps?.places;

interface State {
  isLoaded: boolean;
  AutoCompleteService: any | null;
  placesService: any | null;
}

export default function useGoogleMapsPlacesApi() {
  const mapsRef = useRef(null);

  const [state, dispatch] = useReducer(
    (currentState: State, newState: State) => ({ ...currentState, ...newState }),
    {
      isLoaded: !!(placesApiObj && mapsRef.current),
      AutoCompleteService: placesApiObj
        ? new window.google.maps.places.AutocompleteService()
        : null,
      placesService: mapsRef.current
        ? new window.google.maps.places.PlacesService(mapsRef.current)
        : null,
    },
  );

  const loadServices = () => {
    mapsRef.current = new window.google.maps.Map(document.getElementById('map') as HTMLElement, {});

    dispatch({
      isLoaded: true,
      AutoCompleteService: new window.google.maps.places.AutocompleteService(),
      placesService: new window.google.maps.places.PlacesService(mapsRef.current),
    });
  };

  const initApi = useCallback(() => {
    if (!placesApiObj) {
      (async () => {
        const loader = new Loader({
          apiKey: GOOGLE_MAPS_API_KEY,
          version: 'weekly',
          libraries: ['places'],
        });

        loader
          .load()
          .then(() => {
            loadServices();
          })
          .catch((error) => {
            console.error('Google map API is not initialized correctly. Error -> ', error);
          });
      })();
    } else {
      loadServices();
    }
  }, []);

  const getPlacePredictions = useMemo(
    () =>
      throttle(async (request: { input: string }) => {
        try {
          if (!state.AutoCompleteService) {
            return new Error('autoCompleteService is not available');
          }
          const result = await state.AutoCompleteService.getPlacePredictions(request);
          return result.predictions;
        } catch (error) {
          return new Error('error with place predictions api');
        }
      }, 200),
    [state?.AutoCompleteService],
  );

  const getPlaceDetails = useCallback(
    (request: { placeId: string; fields: string[] }) =>
      new Promise((resolve, reject) => {
        try {
          if (!state.placesService) {
            reject(new Error('place service not available'));
          }

          state.placesService.getDetails(request, (placeResult: any, PlacesServiceStatus: any) => {
            if (!(PlacesServiceStatus === 'OK')) {
              reject(new Error('Error in places service'));
            }

            if (placeResult) {
              resolve(placeResult);
            }

            reject(new Error('data not available'));
          });
        } catch (error) {
          reject(new Error('place service not working'));
        }
      }),
    [state?.placesService],
  );

  useEffect(() => {
    if (state.isLoaded) {
      return;
    }

    initApi();
  }, [initApi, state.isLoaded]);

  return { ...state, load: initApi, getPlacePredictions, getPlaceDetails };
}
