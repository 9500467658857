import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { ACTION_START, AUDIO_FORMATS, HTTP_VERBS } from '../../constants';

const schema = () =>
  Joi.object({
    action: Joi.string().valid(ACTION_START),
    extra_metadata: Joi.array(),
    format: Joi.string()
      .valid(...AUDIO_FORMATS)
      .required(),
    method: Joi.string().valid(...HTTP_VERBS),
    nodeId: Joi.string(),
    record_on_answer: Joi.boolean(),
    record_on_bridge: Joi.boolean(),
    should_follow_transfer: Joi.boolean(),
    time_limit: Joi.number()
      .required()
      .min(0)
      .messages(
        messages({
          'number.unsafe': i18next.t('common:validation.general.number_max_custom', {
            min: 0,
            max: 16,
          }),
        }),
      ),
    url: Joi.string().uri().required(),
  }).messages(messages());

export default schema;
