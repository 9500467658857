import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { CALL_VARIABLES, CHANNELS } from '../../constants';

const schema = () =>
  Joi.object({
    channel: Joi.string().valid(...CHANNELS),
    nodeId: Joi.string(),
    value: Joi.number()
      .min(0)
      .messages(messages({ 'number.base': i18next.t('common:validation.general.required') })),
    variable: Joi.string().valid(...CALL_VARIABLES),
  }).messages(messages());

export default schema;
