import { UploadFileType } from './definition';

const defaultProps = {
  hasMargin: true,
  isFileNameBelow: true,
  fileType: UploadFileType.AUDIO,
  name: 'file-upload',
  onChange: () => {},
};

export default defaultProps;
