import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { CallerIdPrependDialogProps as Props, CallerIdPrependNodeData, Data } from './definition';
import schema from './schema';
import translations from './translations';

const CallerIdPrependDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { handleSubmit, control, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;
  const { apply_to, apply_tos, caller_id_name_prefix, caller_id_number_prefix, title } = useMemo(
    () => translations(),
    [],
  );

  const submitHandler = (formData: Data) => {
    const nodeData: CallerIdPrependNodeData = {
      metadata: {
        name: `${formData.caller_id_name_prefix} ${formData.caller_id_number_prefix}`,
        pvt_type: 'prepend_cid',
      },
      data: formData,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper name="caller_id_name_prefix" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={caller_id_name_prefix.label}
            inputWidth="small"
            inputProps={{
              ...formProps,
              id: `input-caller-id-prepend-name-prefix`,
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="caller_id_number_prefix" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={caller_id_number_prefix.label}
            inputWidth="small"
            inputProps={{
              ...formProps,
              id: 'input-caller-id-prepend-number-prefix',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormSelectWrapper name="apply_to" options={apply_tos} control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={apply_to.label}
            tooltip={apply_to.tooltip}
            selectProps={{
              ...formProps,
              id: 'select-caller-id-prepend-apply-to',
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default CallerIdPrependDialog;
