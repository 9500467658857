import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/CallCenter/components/EditForm';
import withNavigation from 'apps/shared/hocs/withNavigation';
import { useExitConfirmationDialog } from 'apps/shared/hooks/useExitConfirmationDialog';
import { ADD_KEY } from 'constant';
import {
  useCreateQueueMutation,
  useDeleteQueueMutation,
  useFetchQueueByIdQuery,
  useUpdateQueueMutation,
} from 'models/Queues';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { FieldErrors, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import { defaultValues } from './default';
import { FormInput, Props } from './definition';
import Form from './Form';
import { schema } from './schema';
import { normalizeDataForSaving, prepareDataForForm } from './utility';

// TODO: Cross check the required fields
const Edit: FunctionComponent<any> = ({
  id,
  handleSaveSuccess,
  handleDeleteSuccess,
}: Props): JSX.Element => {
  const isAdd = id === ADD_KEY;
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { data: queuesData, isLoading: isLoadingQueues } = useFetchQueueByIdQuery(id, {
    skip: isAdd || !id,
  });
  const [createQueue] = useCreateQueueMutation();
  const [updateQueue] = useUpdateQueueMutation();
  const [deleteQueue] = useDeleteQueueMutation();

  const formMethods = useForm<FormInput>({
    resolver: joiResolver(schema()),
    defaultValues,
  });

  const {
    formState: { dirtyFields },
    handleSubmit,
    reset,
    setError,
  } = formMethods;

  const isPageDirty = checkFormEntities(dirtyFields);

  useExitConfirmationDialog({ isDirty: isPageDirty });

  const getSubmitHandler: (isSaveButtonClicked?: boolean) => SubmitHandler<FormInput> = useCallback(
    (isSaveButtonClicked = false) => async (formData: FormInput) => {
      try {
        const updatedData = normalizeDataForSaving(formData);

        if (isAdd) {
          await createQueue({ body: updatedData }).unwrap();

          if (isSaveButtonClicked) {
            handleSaveSuccess?.({
              shouldRedirect: true,
              toastMessage: t(
                'call_center:containers.manage_queues.save.success.new_queue_created',
              ),
            });
          }
        } else {
          await updateQueue({ body: updatedData, id: updatedData.id }).unwrap();
          showToast.success();
        }
      } catch (exception) {
        showErrorMessage({ isFromException: true, errors: exception, setError });
      }
    },
    [isAdd, showToast, createQueue, handleSaveSuccess, setError, showErrorMessage, t, updateQueue],
  );

  const onError: SubmitErrorHandler<FormInput> = useCallback(
    (errors: FieldErrors) => showErrorMessage({ errors, setError }),
    [setError, showErrorMessage],
  );

  const handleDelete = async () => {
    try {
      if (queuesData) {
        await deleteQueue({
          id: queuesData.id,
        }).unwrap();

        handleDeleteSuccess?.();
      }
    } catch (exception) {
      showErrorMessage({ isFromException: true, errors: exception, setError });
    }
  };

  // Fill form queuesData from API response
  useEffect(() => {
    if (queuesData) {
      reset(prepareDataForForm(queuesData));
    }
  }, [queuesData, reset]);

  if (isLoadingQueues) {
    return <Loading />;
  }

  return (
    <EditForm
      isPageDirty={isPageDirty}
      breadcrumb={[
        t('call_center:containers.manage_queues.label'),
        queuesData?.name ?? t('call_center:containers.manage_queues.add_queues'),
      ]}
      deleteButton={{
        onClick: (!isAdd && handleDelete) || undefined,
      }}
      formMethods={formMethods}
      onSave={handleSubmit(getSubmitHandler(true), onError)}
    >
      <Form />
    </EditForm>
  );
};

export default withNavigation(Edit, true);
