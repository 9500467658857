import { DEFAULT_TIMEZONE_OPTION_VALUE } from 'constant';
import i18next from 'i18next';
import { getCitiesToTimezoneWithDstMap } from 'shared/components/TimeZoneMapPickerDialog/components/TimeZoneMapPicker/utility/index';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../../definition';
import { Metadata } from '../../store/definition';

class TimeOfDayAddActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.TIME_OF_DAY,
      module: CallFlowModuleKey.TIME_OF_DAY,
      section: CallFlowSection.TIME_OF_DAY,
      rules: [
        {
          type: 'quantity',
          maxSize: 9999,
        },
      ],
      iconProps: {
        name: 'clock-outlined',
      },
      label: i18next.t('phone_system:containers.callflows.actions.time_of_day_add.name'),
    });
  }

  static citiesToTimeZoneMap = getCitiesToTimezoneWithDstMap();

  static subtitle = (
    nodeData: any,
    metadata: Metadata, // TODO: Remove dead param?
  ) => {
    const defaultOption = i18next.t('common:component.labeled_select_time_zone.default_option');
    return nodeData?.timezone && nodeData.timezone !== DEFAULT_TIMEZONE_OPTION_VALUE
      ? this.citiesToTimeZoneMap[nodeData.timezone] ?? defaultOption
      : defaultOption;
  };
}

export default TimeOfDayAddActionConfig;
