import { LOCAL_STORAGE } from 'constant';
import { getMasqueradingAccountId } from './getMasqueradingAccountId';

export const getAccountId = () => {
  const masqueradingAccountId = getMasqueradingAccountId();

  const rootAccountId = localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID);

  const accountId = masqueradingAccountId || rootAccountId || undefined;
  return accountId;
};
