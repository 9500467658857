import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio from '@mui/material/Radio';
import { InfoIcon } from '../Labeled';
import { RadioProps as Props } from './definition';
import StyledRadio from './style';

export type { RadioProps } from './definition';

const Radio = ({ 'data-test-id': dataTestId, info, label, ...rest }: Props) => (
  <StyledRadio>
    <MuiFormControlLabel
      control={<MuiRadio {...rest} />}
      label={label}
      {...(dataTestId ? { 'data-test-id': dataTestId } : {})}
    />
    {info && <InfoIcon isInline tooltip={info} />}
  </StyledRadio>
);

export default Radio;
