import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled, { css } from 'styled-components';
import theme from 'theme/mui/core';
import { StyledCallflowContainerProps as Props } from './definition';

export const CONSTANTS = {
  HEIGHT: {
    DEFAULT: `calc(100vh - (${HEADER_CONSTANTS.HEIGHT} + ${
      ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT
    } + ${theme.spacing(3)} * 2))`,
    HEADER: '36px',
    OFFSET: `calc(${theme.spacing(2)} * 2)`,
  },
  WIDTH: 520,
};

const StyledCallflowContainer = styled.div<Props>`
  ${(props) => css`
    display: grid;
    height: calc(
      ${CONSTANTS.HEIGHT.DEFAULT} - (${CONSTANTS.HEIGHT.HEADER} + ${CONSTANTS.HEIGHT.OFFSET})
    );
    overflow: ${props.isPreview ? 'auto' : 'visible auto'};

    .component-callflow-interaction-bar {
      position: absolute;
      right: ${props.theme.spacing(6)};
      bottom: ${props.theme.spacing(6)};
    }
  `};
`;

export default StyledCallflowContainer;
