import Box from 'shared/components/Box';
import { OptionProps as Props } from './definition';
import StyledOption from './style';

export type { SelectOptgroup, SelectOption } from './definition';

const Option = (props: Props): JSX.Element => {
  const {
    data: { image, label },
    isSelected,
    innerProps,
    innerRef,
  } = props;

  return (
    <StyledOption ref={innerRef} isSelected={isSelected} {...innerProps}>
      {image && (
        <Box>
          <img src={image} alt={label} />
        </Box>
      )}
      {label}
    </StyledOption>
  );
};

export default Option;
