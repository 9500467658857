// Blocklist Queries
import { AppsStore } from 'api/appsStore';
import { ByAccountIdParam, ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

interface FetchAppByIdParam extends ByAccountIdParam, ByIdParam {}

export const appsStoreQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchApps: builder.query<AppsStore, ByAccountIdParam | void>({
      query: ({ accountId }: { accountId?: string } = {}) => ({
        url: AppsStore.Fetch(accountId),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.APPSSTORE }],
    }),
    fetchAppById: builder.query<AppsStore, FetchAppByIdParam>({
      query: ({ accountId, id }: { accountId?: string; id: string }) => ({
        url: AppsStore.FetchById(accountId, id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => [{ type: TAGS.APPSSTORE, id }],
    }),
    fetchAppsBlocklist: builder.query<AppsStore, ByAccountIdParam>({
      query: ({ accountId }: { accountId?: string }) => ({
        url: AppsStore.FetchBlocklist(accountId),
        responseHandler,
      }),
      providesTags: () => [{ type: TAGS.APPSSTORE }],
    }),
  }),
});
