import { useAddPhoneNumbersToCollectionMutation } from 'models/PhoneNumber';
import { FunctionComponent, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AddNumberDialog from './components/AddNumbers';
import LoadingDialog from './components/LoadingNumbers';
import SummaryNumberDialog from './components/SummaryNumbers';
import { AddNumbersProps as Props, SummaryList } from './definition';
import { initialState, reducer } from './reducer';

const AddNumbersFlow: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { setShowAddNumbersFlow, origin } = props;
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAddNumberDialogOpen, isLoadingDialogOpen, isSummaryNumberDialogOpen } = state;
  const [addPhoneNumbersToCollection] = useAddPhoneNumbersToCollectionMutation();
  const navigate = useNavigate();

  const [importedList, setImportedList] = useState<SummaryList>({
    success: [],
    error: [],
  });

  useEffect(() => {
    dispatch({ type: 'OPEN_ADD_NUMBER_DIALOG' });
  }, []);

  const onAddNumberDialogSave = async (validNumbers: string[], inValidNumbers: string[]) => {
    dispatch({ type: 'OPEN_LOADING_DIALOG' });
    if (validNumbers.length > 0) {
      try {
        const { success = {}, error = {} } = await addPhoneNumbersToCollection({
          body: {
            numbers: [...new Set(validNumbers)],
            carrier_name: 'local',
          },
        }).unwrap();
        setImportedList({
          success: Object.keys(success),
          error: [...Object.keys(error), ...inValidNumbers],
        });
      } catch (error) {
        setImportedList({ success: [], error: [...validNumbers, ...inValidNumbers] });
      }
    } else {
      setImportedList({ success: [], error: [...inValidNumbers] });
    }

    dispatch({ type: 'OPEN_SUMMARY_NUMBER_DIALOG' });
  };

  return (
    <>
      <AddNumberDialog
        isOpen={isAddNumberDialogOpen}
        onSave={onAddNumberDialogSave}
        onClose={() => setShowAddNumbersFlow(false)}
        onCancel={() => setShowAddNumbersFlow(false)}
      />
      <LoadingDialog isOpen={isLoadingDialogOpen} />
      <SummaryNumberDialog
        description={t('numbers:action_row.add_number.dialog.summary_numbers.description')}
        isOpen={isSummaryNumberDialogOpen}
        importedList={importedList}
        onClose={() => {
          setShowAddNumbersFlow(false);
          navigate(origin);
        }}
        title={t('numbers:action_row.add_number.dialog.summary_numbers.title')}
      />
    </>
  );
};

export default AddNumbersFlow;
