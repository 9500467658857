import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const Blocklist = {
  // GET
  Fetch: (isPaginated: boolean = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/blacklists?paginate=${isPaginated}`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/blacklists/${id}`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/blacklists`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/blacklists/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/blacklists/${id}`,
};
