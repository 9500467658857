import { useNavigation } from 'apps/PhoneSystem/shared/useNavigation';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Edit from './Edit';
import List from './List';

export { default as meta } from './meta';

const SMS = () => {
  const EditWithNav = useNavigation(Edit);
  const routes = useRoutes([
    { path: '/', element: <List /> },
    { path: '/:id', element: <EditWithNav /> },
  ]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};

export default SMS;
