import Box from 'shared/components/Box';
import { convertToKebabCase } from 'shared/utility';
import { DialogType } from '../definition';

export const buildItems = ({
  data = {},
  type,
}: {
  data: Record<string, any>;
  type: DialogType;
}) => {
  switch (type) {
    case DialogType.Config:
      return data.config_files
        ? Object.entries(data.config_files).map(([label, value]: any) => ({
            component: <Box component="pre">{value}</Box>,
            hash: `item-${convertToKebabCase(label)}`,
            label,
            value,
          }))
        : [];
    case DialogType.Log: {
      if (data.log_lines) {
        const value = data.log_lines.join('');
        return [
          {
            component: <Box component="pre">{value}</Box>,
            hash: 'item-log-txt',
            label: 'log.txt',
            value,
          },
        ];
      }
      return [];
    }
    default:
      return [];
  }
};
