/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useShowMasqueradingToast } from 'apps/shared/hooks/useShowMasqueradingToast';
import { setCurrentAccount } from 'apps/shared/utility/account';
import { ExitConfirmationDialogContext } from 'apps/store/contexts';
import { LOCAL_STORAGE } from 'constant';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import { useFetchAccountQuery } from 'models/Account';
import { accountQueries } from 'models/Account/Queries';
import { clearTableFilterSession, clearTablePaginationIndexSession } from 'models/Auth';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import SearchInput from 'shared/components/SearchInput';
import { AccountHeader, AccountInfo, AccountListLocal, AccountListRemote } from './components';
import { CurrentAccountProps as Props } from './definition';
import StyledCurrentAccount from './style';
import { getCurrentListingUrl } from './utility';

const CurrentAccount: FunctionComponent<Props> = ({ isNotRootAccount }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createExitConfirmationDialogHandler } = useContext(ExitConfirmationDialogContext);
  const { showMasqueradingToast } = useShowMasqueradingToast();

  const [isTreeVisible, setIsTreeVisible] = useState<boolean>(false);
  const [isUsingSearchApi, setIsUsingSearchApi] = useState<boolean>(false);
  const [dataList, setDataList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [stackDepth] = useState<number>(1);
  const [treeIdStack, setTreeIdStack] = useState<string[]>([]);

  const { data, isLoading, isFetching } = useFetchAccountQuery();
  const currentAccountId = useSelector((state: any) => state.account.id);

  let accountListRef: HTMLElement | null = null;
  let accountListToggleRef: HTMLElement | null = null;

  useEffect(() => {
    if (typeof treeIdStack[0] === 'undefined') {
      setTreeIdStack(() => {
        const stack = [];
        stack.push(data?.id as string);
        return stack;
      });
    }
  }, [data]);

  useEffect(() => {
    const rootAccountId = localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID);
    if (currentAccountId !== rootAccountId) {
      setAccount(currentAccountId);
    }
  }, [currentAccountId]);

  /** This useEffect is for the edge case of browser refresh losing the masquerading ancestry. */
  useEffect(() => {
    const fetchAncestors = async (queryId: string) => {
      const { data }: any = await dispatch(
        accountQueries.endpoints.fetchAccountAncestorsById.initiate({ id: queryId }),
      );
      setTreeIdStack(
        concat(
          data?.map((ancestor: { [key: string]: string }) => ancestor.id),
          queryId,
        ),
      );
    };

    if (currentAccountId && isEmpty(treeIdStack)) {
      fetchAncestors(currentAccountId);
    }
  }, [currentAccountId]);

  useEffect(() => {
    const clickHandler = (event: Event) => {
      setIsTreeVisible(false);
    };
    if (isTreeVisible) {
      document.addEventListener('click', clickHandler);
    }
    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, [isTreeVisible]);

  const setAccount = useCallback(
    (accountId?: string, accountName?: string) => {
      if (!accountId) {
        showMasqueradingToast();
      }

      const isValidId = typeof accountId === 'string';
      const id = isValidId ? accountId : localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID);
      setCurrentAccount(dispatch, id as string);
      setIsTreeVisible(false);
      setIsUsingSearchApi(false);
      setSearchValue('');

      if (accountName) {
        showMasqueradingToast(accountName ?? '');
      }

      setTreeIdStack(
        isValidId
          ? (treeIdStack: string[]) => {
              let stack = [...treeIdStack];
              stack.push(accountId);
              stack = [...new Set(stack)];
              return stack;
            }
          : [],
      );
    },
    [dispatch, showMasqueradingToast],
  );

  /**
   * TODO: Replace `clearTable[...]Session()` calls below
   * with `sessionStorage.clear()`
   */

  const handleAccountListItemClick = (item: any, parent: any) => {
    setTreeStack(parent, item.id);
    setIsTreeVisible(false);
    showMasqueradingToast(item.name);
    clearTableFilterSession();
    clearTablePaginationIndexSession();
    setAccount(item.id);
    navigate(getCurrentListingUrl());
  };

  const handleStopMasqueradingClick = () => {
    clearTableFilterSession();
    clearTablePaginationIndexSession();
    setAccount();
    navigate(getCurrentListingUrl());
  };

  const handleStartMasqueradingClick = (accountId?: string, accountName?: string) => {
    clearTableFilterSession();
    clearTablePaginationIndexSession();
    setAccount(accountId, accountName);
    navigate(getCurrentListingUrl());
  };

  const updateTree = () => {
    setTreeIdStack((treeIdStack: string[]) => {
      const stack = [...treeIdStack];
      stack.pop();
      return stack;
    });
    setIsTreeVisible(true);
  };

  const resetSearchValue = () => {
    setIsUsingSearchApi(false);
    setSearchValue('');
  };

  const updateSearchValue = (value = '') => {
    if (value.length === 0) {
      resetSearchValue();
      return;
    }
    setIsUsingSearchApi(true);
    setSearchValue(value);
  };

  const setTreeStack = (id: string, currentItemId: string) => {
    let parent = dataList.find((item: any) => item.id === id);
    const treeStackArray: string[] = [];
    while (parent) {
      treeStackArray.push(parent.id);
      parent = dataList.find((item: any) => item.id === parent.parentId);
    }
    setTreeIdStack((treeIdStack) => {
      const stack = [...treeIdStack, ...treeStackArray.reverse()];
      return stack;
    });
  };

  const getStackArray: any = (id: string, data: any[]) => {
    return data.map((item) => {
      if (item.id === id) {
        return item;
      }
      if (item.parent) {
        return getStackArray(id, item.parent);
      }
      return null;
    });
  };

  const setChildParentData = (id: string, parentId: string, data: any) => {
    setDataList((previousState: any) => {
      let newData = [...previousState];
      newData.push(...data);
      newData = [...new Set(newData)];
      return newData;
    });
  };

  return (
    <StyledCurrentAccount
      isTreeVisible={isTreeVisible}
      onClick={(event) => event.stopPropagation()}
    >
      <div
        ref={(node: HTMLInputElement) => {
          if (node) {
            accountListToggleRef = node;
          }
        }}
        onClick={createExitConfirmationDialogHandler?.(() => {
          // Push the setState to the next execution cycle to avoid the click event listener on the document set the state to false
          setTimeout(() => setIsTreeVisible(true), 0);
        })}
      >
        <AccountHeader
          isLoading={isLoading || isFetching}
          isTreeVisible={isTreeVisible}
          name={data?.name}
        />
      </div>
      <div
        ref={(node: HTMLInputElement) => {
          if (node) {
            accountListRef = node;
          }
        }}
      >
        {isNotRootAccount && (
          <Button
            className="MuiButton-font-weight-normal"
            variant="contained"
            size="small"
            startIcon={<Icon name="mask-filled" size={16} />}
            onClick={handleStopMasqueradingClick}
          >
            {t('common:component.current_account.stop_masquerading')}
          </Button>
        )}
        <div>
          <SearchInput
            hasAutoFocus
            isContainerVisible={isTreeVisible}
            inputProps={{
              id: 'search-account-picker',
              placeholder: t('common:search'),
              readOnly: false,
              value: searchValue,
              width: 'auto',
              onChange: (e: ChangeEvent<HTMLInputElement>) => updateSearchValue(e.target.value),
            }}
            tooltip={t('common:component.current_account.search.tooltip')}
            onReset={() => resetSearchValue()}
          />
        </div>
        {treeIdStack.length > 1 && (
          <span id="back-to-parent" onClick={() => updateTree()}>
            <Icon name="chevron-left" subset={16} />
            <span>{t('common:component.current_account.back_to_parent')}</span>
          </span>
        )}
        <AccountInfo />
        {isUsingSearchApi ? (
          <AccountListRemote search={searchValue} onClick={handleStartMasqueradingClick} />
        ) : (
          <AccountListLocal
            key={data?.id}
            accountId={treeIdStack[treeIdStack.length - 1] || (data?.id as string)}
            stackDepth={stackDepth}
            search={searchValue}
            onClick={handleAccountListItemClick}
            setChildParentData={setChildParentData}
          />
        )}
      </div>
    </StyledCurrentAccount>
  );
};

export default CurrentAccount;
