import {
  createArrayValue,
  createTranslateChangeHandler,
} from 'apps/PhoneSystem/components/EditForm/helper';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'member.join_muted',
  'play_entry_tone',
  'play_name',
  'member.join_deaf',
  'play_exit_tone',
  'conference_numbers',
];

const OptionsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <div role="row">
        <div role="cell">
          {/* Join Muted */}
          <HookFormInputWrapper name="member.join_muted" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                label={t(
                  'phone_system:containers.conferences.section.options.join_the_conference_muted',
                )}
                checkboxProps={{
                  ...formProps,
                  id: 'checkbox-options-join-muted',
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>

          {/* Play Entry Tone */}
          <HookFormInputWrapper name="play_entry_tone" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                label={t('phone_system:containers.conferences.section.options.play_entry_tone')}
                checkboxProps={{
                  ...formProps,
                  id: 'input-options-play-entry-tone',
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>

          {/* Play Name */}
          <HookFormInputWrapper name="play_name" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                label={t('phone_system:containers.conferences.section.options.play_name')}
                checkboxProps={{
                  ...formProps,
                  id: 'input-option-play-name',
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          {/* Join Deaf */}
          <HookFormInputWrapper name="member.join_deaf" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                label={t(
                  'phone_system:containers.conferences.section.options.join_the_conference_deaf',
                )}
                checkboxProps={{
                  ...formProps,
                  id: 'input-option-join-deaf',
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>

          {/* Play Exit Tone */}
          <HookFormInputWrapper name="play_exit_tone" isCheckbox>
            {({ ref, value, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                label={t('phone_system:containers.conferences.section.options.play_exit_tone')}
                checkboxProps={{
                  ...formProps,
                  id: 'input-option-play-exit-tone',
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.conferences.section.options.conference_server')}</h2>
      <div role="row">
        <div role="cell">
          {/* Numbers */}
          <HookFormInputWrapper name="conference_numbers">
            {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.conferences.section.options.numbers.label')}
                inputProps={{
                  value: createArrayValue(value),
                  onChange: createTranslateChangeHandler(onChange),
                  ...formProps,
                  placeholder: t(
                    'phone_system:containers.conferences.section.options.numbers.placeholder',
                  ),
                  id: 'input-option-numbers',
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
    </>
  );
};

export default OptionsSection;
