import SiteSelectionDialog from 'apps/Numbers/components/SiteSelectionDialog';
import { useFetchAccountBillingByIdQuery } from 'models/Account';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import Tooltip from 'shared/components/Tooltip';
import { useToast } from 'shared/hooks/useToast';
import { ApplySiteLocationDialogProps as Props } from './definition';
import StyledApplySiteLocationDialog from './style';
import { columns, setFieldValue } from './utility';

const ApplySiteLocationDialog: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { showToast } = useToast();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [locationsData, setLocationsData] = useState<any>([]);
  const { data, isLoading } = useFetchAccountBillingByIdQuery();

  const populateForm = (data: Record<string, any>): void => {
    const set = setFieldValue(setValue);

    set('e911.street_address', data.address_line_1?.trim());
    set('e911.extended_address', data.address_line_2?.trim());
    set('e911.region', data.address_state);
    set('e911.locality', data.address_city);
    set('e911.postal_code', data.address_postal_code);

    showToast.success(
      t('numbers:containers.numbers_in_use.section.e911.apply_site_location_success'),
    );
  };

  useEffect(() => {
    if (data?.length > 0) {
      const { locations } = data[0];
      const DEFAULT_DISPLAY_NAME = t(
        'numbers:containers.numbers_in_use.section.e911.default_account_location',
      );

      setLocationsData(
        Object.entries(locations).reduce((acc: Array<any>, [key]) => {
          if (key !== 'unknown' && locations[key]?.display_name !== DEFAULT_DISPLAY_NAME) {
            acc.push({ key, ...locations[key] });
          }
          return acc;
        }, []),
      );
    }
  }, [data, t]);

  const isButtonDisabled = useMemo(() => Object.keys(locationsData).length === 0, [locationsData]);

  const button = (
    <Button
      color="secondary"
      disabled={isButtonDisabled}
      role="button"
      startIcon={<Icon name="plus-circle-outlined" size={18} />}
      variant="text"
      onClick={() => setIsDialogOpen(true)}
    >
      {t('numbers:containers.numbers_in_use.section.e911.site_selection.title')}
    </Button>
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledApplySiteLocationDialog>
      <Box>
        {isButtonDisabled ? (
          <Tooltip
            title={t(
              'numbers:containers.numbers_in_use.section.e911.site_selection.no_location_info',
            )}
          >
            <Box component="span">{button}</Box>
          </Tooltip>
        ) : (
          button
        )}
      </Box>
      <SiteSelectionDialog
        isOpen={isDialogOpen}
        data={locationsData}
        columns={columns()}
        onApply={(selectedLocation: any) => populateForm(selectedLocation)}
        onCancel={() => setIsDialogOpen(false)}
        onClose={() => setIsDialogOpen(false)}
      />
    </StyledApplySiteLocationDialog>
  );
};

export default ApplySiteLocationDialog;
