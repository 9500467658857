import i18next from 'i18next';

export const messages = (overrides: Record<string, string> = {}) => ({
  'any.only': i18next.t('common:validation.general.required'),
  'any.required': i18next.t('common:validation.general.required'),
  'array.min': i18next.t('common:validation.general.required'),
  'date.base': i18next.t('common:validation.general.required'),
  'number.base': i18next.t('common:validation.general.number_only'),
  'number.max': i18next.t('common:validation.general.number_max'),
  'number.min': i18next.t('common:validation.general.number_min'),
  'string.base': i18next.t('common:validation.general.required'),
  'string.domain': i18next.t('common:validation.general.string_domain'),
  'string.email': i18next.t('common:validation.general.format_email'),
  'string.empty': i18next.t('common:validation.general.required'),
  'string.max': i18next.t('common:validation.general.string_max'),
  'string.min': i18next.t('common:validation.general.string_min'),
  'string.pattern.base': i18next.t('common:validation.string.pattern_base'),
  'string.uri': i18next.t('common:validation.general.uri'),
  ...overrides,
});
