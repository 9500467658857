import i18next from 'i18next';
import { CallFlowAction, CallFlowKey, CallFlowModuleKey, CallFlowSection } from '../definition';
import { Metadata } from '../store/definition';

class ManualPresenceActionConfig extends CallFlowAction {
  constructor() {
    super({
      key: CallFlowKey.MANUAL_PRESENCE,
      module: CallFlowModuleKey.MANUAL_PRESENCE,
      section: CallFlowSection.ADVANCED,
      rules: [
        {
          type: 'quantity',
          maxSize: 1,
        },
      ],
      iconProps: {
        name: 'person-status-1-outlined',
      },
      label: i18next.t('phone_system:containers.callflows.actions.manual_presence.name'),
      tooltip: i18next.t('phone_system:containers.callflows.actions.manual_presence.tooltip'),
    });
  }

  static subtitle = (nodeData: any, metadata: Metadata) => nodeData?.presence_id;
}

export default ManualPresenceActionConfig;
