// src/hooks/useCurrentAccountId.ts

import { LOCAL_STORAGE } from 'constant';
import { useSelector } from 'react-redux';

const useCurrentAccountId = (): string =>
  useSelector((state: any) => state.account.id) ||
  localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID);

export default useCurrentAccountId;
