import { EndpointType } from 'apps/PhoneSystem/definition';

export const ACTION_ACTIVATE = 'activate';
export const ACTION_CHECK = 'check';
export const ACTION_COMPOSE = 'compose';
export const ACTION_DEACTIVATE = 'deactivate';
export const ACTION_DIRECT_PARK = 'direct_park';
export const ACTION_DISABLE = 'disable';
export const ACTION_ENABLE = 'enable';
export const ACTION_LOGIN = 'login';
export const ACTION_LOGOUT = 'logout';
export const ACTION_PAUSE = 'paused';
export const ACTION_PREPEND = 'prepend';
export const ACTION_RESET = 'reset';
export const ACTION_RESUME = 'resume';
export const ACTION_START = 'start';
export const ACTION_STOP = 'stop';
export const ACTION_UPDATE = 'update';

export const AUDIO_FORMATS = ['mp3', 'wav'];
export const AUDIO_PRECONNECT_OPTIONS = ['dialtone', 'ringing'];
export const AUDIO_WAY_OPTIONS = ['one-way', 'two-way'];
export const APPLY_TO = ['original', 'current'];
export const CALL_VARIABLES = ['call_priority'];
export const CHANNELS = ['a'];
export const DIAL_STRATEGIES = ['simultaneous', 'single'];
export const ENDPOINT_TYPES = [EndpointType.Device, EndpointType.Group, EndpointType.User];
export const HTTP_METHODS = ['GET', 'POST'];
export const HTTP_VERBS = ['get', 'post', 'put'];
export const NUMBER_OPTIONS = ['default', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
export const REQUEST_FORMATS = ['twiml', 'kazoo'];
export const REQUEST_BODY_FORMATS = ['form', 'json', 'form-data'];
export const RINGING_STATUSES = ['idle', 'ringing', 'busy'];
export const TERMINATORS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
export const WINDOW_UNITS = ['minutes', 'hours'];

export const VALID_SIP_CODES = /^[1-6][0-9]{2}$/;
export const INTER_DIGIT_CONVERSION_FACTOR = 1000;
// todo Need to check with Aisling about this since both MonsterUI + KazooUI do this conversion
//  if we don't do it here then park action for callflows are no longer compatible
export const PARK_CONVERSION_FACTOR = 100;
export const VOICEMAIL = { MIN_MESSAGE_LENGTH: 0, MAX_MESSAGE_LENGTH: 600 }; // in seconds
