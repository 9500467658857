import { RowArray } from 'apps/shared/definition';
import { ChildAccount } from './definition';

export const dummyChildAccount: ChildAccount = {
  id: '',
  realm: '',
  descendants_count: 0,
  name: '',
  flags: [],
};

export const dummyData: RowArray = [
  {
    id: '+17787205943',
    prepend: { name: 'TestPrepend1' },
    cnam: { inbound_lookup: true, display_name: 'Sally Testuser3' },
    used_by: 'callflow5',
    e911: { street_address: '12224 Mirasol Pl' },
  },
  {
    id: '+17787205944',
    prepend: { name: 'TestPrepend1' },
    cnam: { inbound_lookup: true, display_name: 'Ken Testuser3' },
    used_by: 'callflow4',
    e911: { street_address: '12225 Mirasol Pl' },
  },
  {
    id: '+17787205945',
    prepend: { name: 'TestPrepend2' },
    cnam: { inbound_lookup: true, display_name: 'Dave Testuser2' },
    used_by: 'callflow3',
    e911: { street_address: '12226 Mirasol Pl' },
  },
  {
    id: '+17787205946',
    prepend: { name: 'TestPrepend2' },
    cnam: { inbound_lookup: true, display_name: 'Diane Testuser2' },
    used_by: 'callflow2',
    e911: { street_address: '12227 Mirasol Pl' },
  },
  {
    id: '+17787205947',
    prepend: { name: 'TestPrepend3' },
    cnam: { inbound_lookup: true, display_name: 'Steve Testuser1' },
    used_by: 'callflow1',
    e911: { street_address: '12228 Mirasol Pl' },
  },
  {
    id: '+17787205948',
    prepend: { name: 'TestPrepend3' },
    cnam: { inbound_lookup: true, display_name: 'Amanda Testuser1' },
    used_by: 'callflow0',
    e911: { street_address: '12229 Mirasol Pl' },
  },
];

export const dummyAccountData: Array<string> = [
  'TestAccount1',
  'TestAccount2',
  'TestAccount3',
  'TestAccount4',
];
