import {
  BuyNumberFlowType,
  BuyNumbersFlowState as Props,
} from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow/definition';
import get from 'lodash/get';
import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import LabeledSelect from 'shared/components/Labeled/components/LabeledSelect';
import Link from 'shared/components/Link';
import { SelectOption } from 'shared/components/Select';
import { AREA_CODE_OFFSET_LENGTH, TOLL_FREE_CODES, URL_FIND_AREA_CODE } from './constant';
import { StyledSearchForNumbers } from './style';

const SearchForNumbers = ({
  flowType,
  areaCode,
  prefix,
  setPrefix,
  setAreaCode,
  setAvailableNumbers,
}: Props) => {
  const { t } = useTranslation();
  const isTollFree = flowType === BuyNumberFlowType.TollFree;

  useEffect(() => setAvailableNumbers([]), [setAvailableNumbers]);

  return (
    <StyledSearchForNumbers>
      <Box role="row">
        <Box role="cell">
          <LabeledInput
            isLabelAbove
            inputProps={{
              disabled: true,
              value: 'United States',
              startAdornment: <Icon name="region-us" />,
            }}
            label={t(
              'common:component.buy_number_flow.components.search_for_numbers.field.country',
            )}
          />
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          {isTollFree && (
            <LabeledSelect
              isLabelAbove
              label={t(
                'common:component.buy_number_flow.components.search_for_numbers.field.area_code',
              )}
              labelProps={{ required: true }}
              selectProps={{
                options: TOLL_FREE_CODES,
                value: TOLL_FREE_CODES.find(
                  (currentCode: SelectOption) => currentCode.label === areaCode,
                ),
                onChange: (value: unknown): void => {
                  setAreaCode(get(value, 'value', ''));
                },
              }}
              selectWidth="small"
            />
          )}

          {flowType === BuyNumberFlowType.Local && (
            <LabeledInput
              isLabelAbove
              inputProps={{
                autoFocus: true,
                inputProps: {
                  maxLength: AREA_CODE_OFFSET_LENGTH,
                },
                value: areaCode,
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  setAreaCode(e.target.value.replace(/[^0-9]/g, '')),
              }}
              inputWidth="small"
              label={t(
                'common:component.buy_number_flow.components.search_for_numbers.field.area_code',
              )}
              labelProps={{ required: true }}
            />
          )}

          <Box component="span">&mdash;</Box>

          <LabeledInput
            isLabelAbove
            inputProps={{
              disabled: isTollFree,
              inputProps: {
                maxLength: AREA_CODE_OFFSET_LENGTH,
              },
              value: prefix,
              onChange: (e: ChangeEvent<HTMLInputElement>) => setPrefix(e.target.value),
            }}
            inputWidth="small"
            label={t('common:component.buy_number_flow.components.search_for_numbers.field.prefix')}
          />
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          <Link isExternal href={URL_FIND_AREA_CODE}>
            {t(
              'common:component.buy_number_flow.components.search_for_numbers.link.find_area_code',
            )}
          </Link>
        </Box>
      </Box>
    </StyledSearchForNumbers>
  );
};

export default SearchForNumbers;
