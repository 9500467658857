import { createTheme } from '@mui/material/styles';
import {
  MuiAlert,
  MuiAutocomplete,
  MuiBreadcrumbs,
  MuiButton,
  MuiButtonBase,
  MuiCalendarPicker,
  MuiCard,
  MuiCardContent,
  MuiCardHeader,
  MuiCheckbox,
  MuiCircularProgress,
  MuiClockPicker,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiFormControl,
  MuiFormControlLabel,
  MuiFormGroup,
  MuiInputBase,
  MuiInputLabel,
  MuiLinearProgress,
  MuiLink,
  MuiList,
  MuiMenu,
  MuiMenuItem,
  MuiPagination,
  MuiPaper,
  MuiRadio,
  MuiSvgIcon,
  MuiSwitch,
  MuiTab,
  MuiTabs,
  MuiTooltip,
  MuiTypography,
} from './components';
import theme from './core';

export default createTheme(theme, {
  components: {
    MuiAlert,
    MuiAutocomplete,
    MuiBreadcrumbs,
    MuiButton,
    MuiButtonBase,
    MuiCalendarPicker,
    MuiCard,
    MuiCardContent,
    MuiCardHeader,
    MuiCheckbox,
    MuiCircularProgress,
    MuiClockPicker,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogTitle,
    MuiFormControl,
    MuiFormControlLabel,
    MuiFormGroup,
    MuiInputBase,
    MuiInputLabel,
    MuiLinearProgress,
    MuiLink,
    MuiList,
    MuiMenu,
    MuiMenuItem,
    MuiPagination,
    MuiPaper,
    MuiRadio,
    MuiSvgIcon,
    MuiSwitch,
    MuiTab,
    MuiTabs,
    MuiTooltip,
    MuiTypography,
  },
});
