import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import defaultProps from './default';
import { DownloadIconProps as Props } from './definition';

const DownloadIcon: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { visible, info, iconProps } = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const icon = (
    <Icon hasHover name="download" themeColor="core.color.black" size={22} {...iconProps} />
  );

  return visible ? <Tooltip title={info ?? t('common:download')}>{icon}</Tooltip> : <></>;
};

export default DownloadIcon;
