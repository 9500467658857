import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledSwitch } from 'shared/components/Labeled';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['cnam.inbound_lookup', 'cnam.display_name'];

const CallerIdSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <h3>
        <span>{t('numbers:containers.numbers_in_use.section.caller_id.description.0')}</span>
        <strong>{t('numbers:containers.numbers_in_use.section.caller_id.description.1')}</strong>
      </h3>
      <div role="row">
        <div role="cell">
          {/* Username */}
          <HookFormInputWrapper name="cnam.display_name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'numbers:containers.numbers_in_use.section.caller_id.field.display_name.label',
                )}
                inputProps={{
                  placeholder: t(
                    'numbers:containers.numbers_in_use.section.caller_id.field.display_name.placeholder',
                  ),
                  ...formProps,
                }}
                feedback={feedback}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h3 className="space">
        {t('numbers:containers.numbers_in_use.section.caller_id.description.2')}
      </h3>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="cnam.inbound_lookup" isCheckbox>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSwitch
                isDirty={isDirty}
                label={t(
                  'numbers:containers.numbers_in_use.section.caller_id.field.inbound_lookup',
                )}
                switchProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
    </>
  );
};
export default CallerIdSection;
