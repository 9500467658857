import { WidthType } from 'shared/utility/definition';

const defaultProps = {
  hasBorder: true,
  overrideComponents: {},
  placeholder: '',
  size: 'medium' as WidthType,
};

export default defaultProps;
