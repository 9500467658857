import { ACTION_RESET } from '../../constants';
import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  action: ACTION_RESET,
  apply_to: 'current',
  caller_id_name_prefix: '',
  caller_id_number_prefix: '',
};
