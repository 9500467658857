import MuiButton, { ButtonProps as Props } from '@mui/material/Button';
import { ForwardedRef, forwardRef, FunctionComponent } from 'react';

export type { ButtonProps } from '@mui/material/Button';

const Button: FunctionComponent<Props> = forwardRef(
  (props: Props, ref: ForwardedRef<any>): JSX.Element => <MuiButton {...props} />,
);

export default Button;
