import { ADD_KEY } from 'constant';
import { useFetchAccountQuery } from 'models/Account';
import { useFetchBlocklistsQuery } from 'models/Blocklist';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Dialog, { DialogType } from 'shared/components/Dialog';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import HelpDialogContent from './components/HelpDialogContent';
import { columns, prepareDataForTable } from './utility';

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data = [], isLoading } = useFetchBlocklistsQuery();
  const { data: accountData, isLoading: isLodingAccountData } = useFetchAccountQuery();
  const tableData = data && accountData ? prepareDataForTable(data, accountData) : [];
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  if (isLoading || isLodingAccountData) {
    return <Loading />;
  }

  return (
    <Table
      hasHelp
      hasSearch
      hasAutoResetPage={false}
      columns={columns()}
      data={tableData}
      addButton={{
        hasAdd: true,
        label: t(
          'phone_system:containers.call_blocking.table.action_row.button.add_call_blocking.label',
        ),
        onAdd: () => navigate(`./${ADD_KEY}`),
      }}
      help={{
        dialogComponent: (
          <Dialog open={isHelpOpen} type={DialogType.Help} onClose={() => setIsHelpOpen(false)}>
            <HelpDialogContent />
          </Dialog>
        ),
        iconTooltip: t('phone_system:containers.call_blocking.help'),
        setState: setIsHelpOpen,
      }}
      title={t('phone_system:containers.call_blocking.table.title')}
    />
  );
};

export default List;
