import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/CallCenter/components/EditForm';
import useCurrentAccountId from 'apps/shared/hooks/useCurrentAccountId';
import { useExitConfirmationDialog } from 'apps/shared/hooks/useExitConfirmationDialog';
import { useFetchQueueQuery } from 'models/Queues';
import { useFetchUserByIdQuery, useUpdateUserMutation } from 'models/User';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { FieldErrors, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import { getQueueName } from '../AgentsList/utility/index';
import { defaultValues } from './default';
import { AgentsEditProps as Props, FormInput } from './definition';
import Form from './Form';
import { schema } from './schema';

const AgentsEdit: FunctionComponent<Props> = ({ id }: Props): JSX.Element => {
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const navigate = useNavigate();
  const { agentId } = useParams();
  const accountId = useCurrentAccountId();
  const { data, isLoading } = useFetchUserByIdQuery({ id: agentId || '', accountId });
  const { data: queuesData, isLoading: isLoadingQueues } = useFetchQueueQuery();
  const [updateUser] = useUpdateUserMutation();
  const formMethods = useForm<FormInput>({
    reValidateMode: 'onChange',
    resolver: joiResolver(schema()),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { dirtyFields },
    reset,
    setError,
  } = formMethods;

  const isPageDirty = checkFormEntities(dirtyFields);
  useExitConfirmationDialog({ isDirty: isPageDirty });

  useEffect(() => {
    // Populates form components once data is loaded.
    if (data) {
      reset({ ...data, acdc_min_call_duration: data?.acdc_min_call_duration?.toString() });
    }
  }, [data, reset]);

  const getSubmitHandler: () => SubmitHandler<FormInput> = useCallback(
    () => async (formData: FormInput) => {
      try {
        await updateUser({
          id: formData.id,
          body: formData,
        }).unwrap();

        showToast.success();
      } catch (exception) {
        showErrorMessage({ isFromException: true, errors: exception, setError });
      }
    },
    [showToast, setError, showErrorMessage, updateUser],
  );

  const onError: SubmitErrorHandler<FormInput> = useCallback(
    (errors: FieldErrors) => showErrorMessage({ errors, setError }),
    [setError, showErrorMessage],
  );

  if (isLoading || isLoadingQueues) {
    return <Loading />;
  }

  return (
    <EditForm
      isPageDirty={isPageDirty}
      breadcrumb={[
        queuesData ? getQueueName(id, queuesData) : '',
        `${data?.first_name} ${data?.last_name}`,
      ]}
      formMethods={formMethods}
      onCancel={() => navigate(`../${id}`)}
      onSave={handleSubmit(getSubmitHandler(), onError)}
    >
      <Form />
    </EditForm>
  );
};

export default AgentsEdit;
