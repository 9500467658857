import { FunctionComponent } from 'react';
import { Cell } from 'react-table';
import TEST_ID from 'shared/utility/testing/constants/testId';
import DragIcon from '../DragIcon';
import { RowDragProps as Props } from './definition';
import StyledRowDrag from './style';

const RowDrag: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    row: { cells, getRowProps },
    width,
  } = props;

  return (
    <StyledRowDrag {...getRowProps()} width={width} data-test-id={TEST_ID.COMMON.PREFIX.ROW.DRAG}>
      <>
        <td className="align-center">
          <DragIcon isDragging />
        </td>
        {cells.map(({ getCellProps, render }: Cell) => (
          <td {...getCellProps()}>{render('Cell')}</td>
        ))}
      </>
    </StyledRowDrag>
  );
};

export default RowDrag;
