import { columns, prepareData } from 'apps/CallCenter/containers/ManageQueues/List/utility';
import { RouterLink } from 'apps/shared/components/RouterLink';
import { useFetchQueuesWithDetailsQuery } from 'models/Queues';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';

const List: FunctionComponent = (): JSX.Element => {
  const { data, isLoading } = useFetchQueuesWithDetailsQuery();
  const { t } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table
      columns={columns()}
      data={prepareData(data)}
      hasSearch
      addButton={{
        hasAdd: true,
        component: (
          <Button
            // TODO: fix this later
            // @ts-ignore
            component={RouterLink}
            to="add"
            color="secondary"
            variant="contained"
            startIcon={<Icon name="plus-circle-outlined" size={18} />}
          >
            {t('call_center:containers.manage_queues.table.action_row.button.add_queue.label')}
          </Button>
        ),
      }}
    />
  );
};

export default List;
