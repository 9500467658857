import { FeatureCodeName } from '../../definition';
import { FeatureCodeGroupName } from './definition';

export const featureCodeGroups = {
  [FeatureCodeGroupName.CallForward]: [
    FeatureCodeName.CallForwardActionActivate,
    FeatureCodeName.CallForwardActionDeactivate,
    FeatureCodeName.CallForwardActionToggle,
    FeatureCodeName.CallForwardActionUpdate,
  ],
  [FeatureCodeGroupName.DoNotDisturb]: [
    FeatureCodeName.DoNotDisturbActionActivate,
    FeatureCodeName.DoNotDisturbActionDeactivate,
    FeatureCodeName.DoNotDisturbActionToggle,
  ],
  [FeatureCodeGroupName.FollowMe]: [
    FeatureCodeName.FollowMeActionActivate,
    FeatureCodeName.FollowMeActionDeactivate,
    FeatureCodeName.FollowMeActionToggle,
  ],
  [FeatureCodeGroupName.HotDesking]: [
    FeatureCodeName.HotdeskActionLogin,
    FeatureCodeName.HotdeskActionLogout,
    FeatureCodeName.HotdeskActionToggle,
  ],
  [FeatureCodeGroupName.Misc]: [
    FeatureCodeName.VoicemailActionCheck,
    FeatureCodeName.VoicemailActionDirect,
    FeatureCodeName.Intercom,
    FeatureCodeName.PrivacyModeFull,
    FeatureCodeName.DirectedExtPickup,
    FeatureCodeName.VoicemailSingleMailboxLogin,
    FeatureCodeName.Move,
  ],
  [FeatureCodeGroupName.Parking]: [
    FeatureCodeName.ParkAndRetrieve,
    FeatureCodeName.Valet,
    FeatureCodeName.Retrieve,
  ],
};
