export interface FormInput {
  confirm_match: boolean;
  max_dtmf: string;
  min_dtmf: string;
  name: string;
  users: Array<any>;
}

export enum DTMFType {
  max,
  min,
}

export enum Fields {
  confirm_match = 'confirm_match',
  max_dtmf = 'max_dtmf',
  min_dtmf = 'min_dtmf',
  name = 'name',
  search_fields = 'search_fields',
  sort_by = 'sort_by',
  users = 'users',
}
