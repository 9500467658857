import CircularProgress from '@mui/material/CircularProgress';
import { FunctionComponent } from 'react';
import TEST_ID from 'shared/utility/testing/constants/testId';
import Box from '../Box';
import defaultProps from './default';
import { LoadingProps as Props } from './definition';
import StyledLoading from './style';

const CONSTANTS = {
  PROGRESS: {
    SIZE: {
      DEFAULT: 24,
      LARGE: 60,
    },
  },
};

const Loading: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasBottomMargin,
    hasLabel,
    hasLargeProgress,
    hasMargin,
    color,
    label,
    labelSize,
    progressProps,
  } = {
    ...defaultProps,
    ...props,
  };

  return (
    <StyledLoading
      hasBottomMargin={hasBottomMargin}
      hasLargeProgress={hasLargeProgress}
      hasMargin={hasMargin}
      color={color}
      labelSize={labelSize}
      data-test-id={`${TEST_ID.COMMON.PREFIX.SHARED}-loading`}
    >
      <CircularProgress
        className={`MuiCircularProgress-loading-${hasLargeProgress ? 'large' : 'default'}`}
        color={color ? 'inherit' : 'secondary'}
        size={CONSTANTS.PROGRESS.SIZE[hasLargeProgress ? 'LARGE' : 'DEFAULT']}
        {...progressProps}
        data-test-id={`circular-progress-loading-${hasLargeProgress ? 'large' : 'default'}`}
      />
      {hasLabel && (
        <Box component="span" className="label">
          {label}
        </Box>
      )}
    </StyledLoading>
  );
};

export default Loading;
