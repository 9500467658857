import { cloneDeep } from 'lodash';
import { Cell } from 'shared/components/Table/definition';

export const prepareData = (data: FaxboxResponse | undefined) =>
  cloneDeep(data ?? []).map((item) => {
    if (item?.notifications?.inbound?.email?.send_to) {
      item.notifications.inbound.email.send_to = item.notifications.inbound.email.send_to.join(
        ', ',
      );
    }

    if (item?.notifications?.outbound?.email?.send_to) {
      item.notifications.outbound.email.send_to = item.notifications.outbound.email.send_to.join(
        ', ',
      );
    }

    return item;
  });

export const getCellValue = ({ value }: Cell<JSX.Element>) =>
  Array.isArray(value) ? value.join(', ') : value;
