export default {
  whitelabel: {
    primary: {
      color: 'rgb(207, 51, 57)', // #cf3339
    },
    secondary: {
      color: 'rgb(49, 62, 72)', // #313e48
    },
  },
};
