import { MarginType, WidthType } from 'shared/utility/definition';

const defaultProps = {
  isAbove: false,
  children: '__LABEL__',
  leftMargin: MarginType.none,
  rightMargin: MarginType.medium,
  width: 'large' as WidthType,
};

export default defaultProps;
