import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledAppCheckboxProps as Props } from './definition';

const StyledAppCheckbox = styled.div<Props>`
  ${(props) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};
    margin: 0 0 24px -10px;
    user-select: none;

    > span {
      ${mixin.font({ size: 16 })};
      ${mixin.transition({ property: 'color', duration: 'fastest' })};

      &:first-of-type {
        margin: 0 10px 0 0;
      }

      &:not(:first-of-type) {
        margin: 0 0 0 18px;
      }
    }

    > label {
      margin: 0 0 0 auto;

      > span {
        ${mixin.font({ size: 16 })};
      }
    }
  `}
`;

export default StyledAppCheckbox;
