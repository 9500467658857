import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useCallRestrictionsSection } from 'apps/shared/hooks/useCallRestrictionsSection';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from 'shared/components/Labeled';
import { SectionProps as Props } from '../definition';
import { FormFields } from './definition';

export const fields = Object.values(FormFields);

const CallRestrictionsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  const { component: callRestrictions } = useCallRestrictionsSection({
    hasClosedGroups: true,
    fields: FormFields,
  });

  return (
    <>
      {callRestrictions}

      <h2>
        {t(
          'accounts_manager:containers.accounts.section.call_restrictions.heading.three_party_conference',
        )}
      </h2>
      <div className="one-column" role="row">
        <div role="cell">
          {/* Three Party Conference */}
          <HookFormInputWrapper isCheckbox name={FormFields.ThreePartyConferenceMerge}>
            {({ ref, value, isDirty, ...formProps }) => (
              <LabeledCheckbox
                checkboxProps={formProps}
                isDirty={isDirty}
                label={t(
                  'accounts_manager:containers.accounts.section.call_restrictions.field.is_three_party_conference_immediate_merge_enabled.label',
                )}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
    </>
  );
};

export default CallRestrictionsSection;
