import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledPaginationProps as Props } from './definition';

const CONSTANTS = {
  HEIGHT: '80px', // 32px + (24px * 2)
};

const StyledPagination = styled(Box).attrs(() => ({
  className: 'component-table-pagination',
}))<Props>`
  ${({ $hasPadding, $isVisible, theme }) => css`
    ${mixin.flex(
      $isVisible.info || $isVisible.rowsPerPage ? { justifyContent: 'flex-start' } : {},
    )};
    height: ${CONSTANTS.HEIGHT};
    padding: ${theme.spacing(
      $hasPadding.top ? 4 : 0,
      $hasPadding.side ? 5 : 0,
      $hasPadding.bottom ? 4 : 0,
    )};
    background-color: ${theme.core.color.white};

    ${($isVisible.info || $isVisible.rowsPerPage) &&
    css`
      > *:not(nav) {
        margin-right: ${theme.spacing(6)};
      }

      > nav {
        margin-left: auto;
      }
    `}
  `}
`;

export default StyledPagination;
