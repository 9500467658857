import { getSelectPortalStyles } from 'shared/components/Select';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { MENU_CONSTANTS } from 'theme/mui/components';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  html,
  body {
    height: 100%;
    min-height: 100%;
    margin: 0;
  }

  body {
    ${getSelectPortalStyles({ margin: MENU_CONSTANTS.GAP })};

    * {
      box-sizing: border-box !important;
      font-family: 'Source Sans Pro', Helvetica, sans-serif;
      font-size: 14px;
      text-rendering: optimizelegibility;
    }
    
    > iframe {
      display: none; // TODO: Wth? Troubleshoot iframe injection
    }

    ul {
      margin: 0;
      padding: 0;
    }
  }
`;

export default GlobalStyle;
