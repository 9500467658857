import Icon from 'shared/components/Icon';
import { getColor } from './utility';

const CallStatusIcon = ({
  currentDevice,
  deviceRegistrationsData,
}: {
  currentDevice: any;
  deviceRegistrationsData: any;
}) => (
  <Icon
    name="call-filled"
    themeColor={`core.color.${getColor(deviceRegistrationsData, currentDevice)}`}
  />
);

export default CallStatusIcon;
