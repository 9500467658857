import { ACTION_COMPOSE } from '../../constants';
import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  action: ACTION_COMPOSE,
  callerid_match_login: false,
  id: '',
  interdigit_timeout: 2000,
  max_message_length: '500',
  single_mailbox_login: false,
  advanced_route: false,
  var: '',
};
