import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { TABLE_CONSTANTS } from '../..';
import { StyledRowDragProps as Props } from './definition';

const StyledRowDrag = styled.tr<Props>`
  ${(props) => css`
    ${mixin.flex({ justifyContent: 'flex-start' })};
    width: ${`calc(${props.width}px - 20px)`};
    margin: 0 0 0 -2px;

    &,
    td {
      height: calc(${TABLE_CONSTANTS.HEIGHT.CELL.TBODY} - 2px);
    }

    td {
      ${mixin.flex({ justifyContent: 'flex-start' })};
      width: ${TABLE_CONSTANTS.WIDTH.INPUT};
      background-color: ${props.theme.core.background.grey.medium};
      border-top: ${TABLE_CONSTANTS.BORDER};
      border-bottom: ${TABLE_CONSTANTS.BORDER};
      border-color: ${props.theme.core.divider.default};

      &:not(:first-of-type):not(:last-of-type) {
        padding: 0 ${props.theme.spacing(2)};
      }

      &:first-of-type {
        width: ${TABLE_CONSTANTS.WIDTH.ICON.DRAG};
        padding: 0 ${props.theme.spacing(1.33)};
        border-left: ${TABLE_CONSTANTS.BORDER};
        border-color: ${props.theme.core.divider.default};

        svg {
          cursor: grabbing;
        }
      }

      &:nth-of-type(2) {
        ${mixin.flex({ justifyContent: 'flex-start' })};
        flex-grow: 1;
        padding-left: 0 !important;

        svg {
          margin: 0 ${props.theme.spacing(1.5)} 0 0;
        }
      }

      &:last-of-type {
        justify-content: center;
        width: ${TABLE_CONSTANTS.WIDTH.ICON.ACTION};
        border-left: ${TABLE_CONSTANTS.BORDER};
        border-right: ${TABLE_CONSTANTS.BORDER};
        border-color: ${props.theme.core.divider.default};
      }

      input {
        height: ${TABLE_CONSTANTS.HEIGHT.INPUT};
      }
    }
  `}
`;

export default StyledRowDrag;
