import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FunctionComponent, memo } from 'react';
import { Trans } from 'react-i18next';
import { DirtyIcon } from '../Labeled';
import defaultProps from './default';
import { RichTextEditorProps as Props } from './definition';
import StyledRichTextEditor, { StyledEditor, StyledFooter } from './style';

const TOOLBAR_CONFIG = {
  options: ['inline'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
};

const RichTextEditor: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isDirty, editorState, onEditorStateChange, maxLength, wrapperId }: Props = {
    ...defaultProps,
    ...props,
  };

  const currTextLength = editorState.getCurrentContent().getPlainText().length;

  const isInputExceedMaxLength: (input: string) => boolean = (input) =>
    input.length + currTextLength > maxLength;

  return (
    <StyledRichTextEditor>
      <StyledEditor
        {...(wrapperId ? { wrapperId } : {})}
        editorState={editorState}
        handleBeforeInput={isInputExceedMaxLength}
        handlePastedText={isInputExceedMaxLength}
        toolbar={TOOLBAR_CONFIG}
        onEditorStateChange={onEditorStateChange}
      />
      <StyledFooter>
        <div>
          <span>{`${currTextLength}/${maxLength}`}</span>
          <span>{isDirty && <DirtyIcon isInline isDirty={isDirty} />}</span>
        </div>
        <div className="new_line_instruction">
          <Trans i18nKey="common:component.rich_text_editor.return_instruction">
            <strong>Shift + Return</strong> to add a new line; <strong>Return</strong> to add a new
            paragraph
          </Trans>
        </div>
      </StyledFooter>
    </StyledRichTextEditor>
  );
};

export default memo(RichTextEditor);

export const draftContentToHtml = (editorState: EditorState): string => {
  const contentState = editorState.getCurrentContent();
  const simpleText = contentState.getPlainText();

  if (!simpleText.trim()) {
    return '';
  }

  return draftToHtml(convertToRaw(contentState));
};

/** There is unknown spaces and linebreaks in the real default empty content. `trim()` is needed on the string to be compared with */
const DEFAULT_EMPTY_CONTENT_HTML = ['<p>', '</p>'];

export const htmlStringToDraftContentState = (html: string): EditorState => {
  // Preprocess Monster UI's editor html
  let _html = html || '';
  const trimmedHtml = _html.trim();
  const isHtmlNotFromNemo = !(
    trimmedHtml.startsWith(DEFAULT_EMPTY_CONTENT_HTML[0]) &&
    trimmedHtml.endsWith(DEFAULT_EMPTY_CONTENT_HTML[1])
  );
  if (isHtmlNotFromNemo) {
    _html = `<p>${trimmedHtml}</p>`;
  }

  const { contentBlocks, entityMap } = htmlToDraft(_html);

  const contentState = EditorState.createWithContent(
    ContentState.createFromBlockArray(contentBlocks, entityMap),
  );

  return contentState;
};

export const isContentEmpty = (html = ''): boolean => {
  const _html = html.trim();
  if (!_html) {
    return true;
  }
  return _html == DEFAULT_EMPTY_CONTENT_HTML.join('');
};
