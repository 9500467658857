import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';

export const schema = () =>
  Joi.object({
    acdc_min_call_duration: Joi.number()
      .min(0)
      .max(3600)
      .required()
      .messages(
        messages({
          'number.base': i18next.t('common:validation.general.required'),
        }),
      ),
  })
    .unknown(true)
    .messages(messages());
