import Box from 'shared/components/Box';
import InputLabel from 'shared/components/InputLabel';
import Tooltip from 'shared/components/Tooltip';
import { LabelProps as Props } from './definition';

const Label = ({ id, label, props, tooltip, width }: Props) => (
  <InputLabel {...props} width={width} {...(id ? { htmlFor: id } : {})}>
    {tooltip ? (
      <Tooltip title={tooltip}>
        <Box component="span">{label}</Box>
      </Tooltip>
    ) : (
      label
    )}
  </InputLabel>
);

export default Label;
