import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  custom_data: {},
  format: 'json',
  http_verb: 'get',
  retries: 1,
  uri: '',
};
