import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Queues from 'api/queues';
import { kazooApi, responseHandler, TAGS } from '../../services/kazoo';

export const queueQueries = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchQueue: builder.query<QueueResponse, void>({
      query: () => ({
        url: Queues.Fetch(),
        responseHandler,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.QUEUES as const, id })),
              { type: TAGS.QUEUES, id: 'LIST' },
            ]
          : [{ type: TAGS.QUEUES, id: 'LIST' }],
    }),
    fetchQueueById: builder.query<Queue, string>({
      query: (id) => ({
        url: Queues.FetchById(id),
        responseHandler,
      }),
      providesTags: (result, error, id) => {
        return [{ type: TAGS.QUEUES, id }];
      },
    }),
    fetchQueuesWithDetails: builder.query<QueueResponse, void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const QueuesResult = await fetchWithBQ(Queues.Fetch());
        if (QueuesResult.error) {
          return { error: QueuesResult.error as FetchBaseQueryError };
        }

        const queues = QueuesResult.data as any;

        const results = await queues.data.reduce(
          async (previousPromise: any, { id: queueId }: { id: string }) => {
            const queue = await previousPromise;
            const result = await fetchWithBQ(Queues.FetchById(queueId));
            const { data }: any = result.data ?? { data: undefined };

            if (data) {
              queue.push(data);
            }

            return queue;
          },
          Promise.resolve([]),
        );

        return results
          ? { data: results as QueueResponse }
          : { error: results.error as FetchBaseQueryError };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: TAGS.QUEUES as const, id })),
              { type: TAGS.QUEUES, id: 'LIST' },
            ]
          : [{ type: TAGS.QUEUES, id: 'LIST' }],
    }),
    fetchQueueUsers: builder.query<string[], { id: string }>({
      query: ({ id }) => ({
        url: Queues.FetchUsers(id),
        responseHandler,
      }),
      providesTags: (result, error, { id }) => {
        return [{ type: TAGS.QUEUES, id }];
      },
    }),
    fetchQueuesStatsSummary: builder.query<QueuesStatsSummary, void>({
      query: () => ({
        url: Queues.FetchStatsSummary(),
        responseHandler,
      }),
    }),
  }),
});
