import { WidthType } from 'shared/utility/definition';
import labeledProps from '../../default';

const defaultProps = {
  ...labeledProps,
  label: '__SELECT_LABEL__',
  labelWidth: 'large' as WidthType,
  labelProps: {},
  selectWidth: 'large' as WidthType,
  isWindowed: false,
};

export default defaultProps;
