import StyledEditForm from 'apps/shared/components/StyledEditForm';
import useIsMounted from 'apps/shared/hooks/useIsMounted';
import { BaseSyntheticEvent, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useActionRow } from 'shared/hooks/useActionRow';
import { useToast } from 'shared/hooks/useToast';
import { DialogContext } from 'store/contexts';
import { EditFormProps } from './definition';

const EditForm = ({
  actionRowProps: incomingActionRowProps,
  AuxButton,
  breadcrumbData,
  children,
  deleteButton,
  dialogCtx: overrideDialogCtx,
  formMethods,
  isPageDirty,
  onCancel,
  onDeleteSuccess,
  onCancelSuccess,
  onSave,
  onSaveOverride,
}: EditFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const isMounted = useIsMounted();
  const defaultDialogCtx = useContext(DialogContext);
  const dialogCtx = overrideDialogCtx || defaultDialogCtx;
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onButtonClick = {
    cancel: onCancel ?? onCancelSuccess,
    delete: () => {
      dialogCtx.onOpen({
        open: true,
        confirmMessage: deleteButton?.confirmMessage ?? (
          <Trans i18nKey="common:component.enhanced_form.delete_dialog.content" />
        ),
        title:
          deleteButton?.title ??
          t('common:component.enhanced_form.delete_dialog.title', {
            title: breadcrumbData[1],
          }),
        onConfirm: async () => {
          try {
            await deleteButton?.onClick?.();
            onDeleteSuccess();
          } catch (exception) {
            showToast.error(deleteButton?.deleteErrorMessage);
            console.error(exception);
          }
        },
      });
    },

    save: async () => {
      setIsSaving(true);
      try {
        await onSave?.();
      } finally {
        if (isMounted()) {
          setIsSaving(false);
        }
      }
    },
  };

  const { ActionRow, actionRowProps } = useActionRow({
    hasDelete: Boolean(deleteButton?.onClick),
    hasSave: true,
    isDeleteDisabled: deleteButton?.isDisabled,
    isDirty: isPageDirty,
    isSaveDisabled: isSaving,
    breadcrumbData: [
      {
        text: breadcrumbData[0],
        url: onButtonClick.cancel,
      },
      {
        text: breadcrumbData[1],
      },
    ],
    leftButtons: AuxButton,
    onCancel: onButtonClick.cancel,
    onDelete: onButtonClick.delete,
    onSave: (() => {
      if (!onSaveOverride) {
        return onButtonClick.save;
      }

      return onSaveOverride(onButtonClick.save);
    })(),
    ...incomingActionRowProps,
  });

  return (
    <StyledEditForm onSubmit={(e: BaseSyntheticEvent) => e.preventDefault()}>
      <ActionRow {...actionRowProps} />
      <FormProvider {...formMethods}>{children}</FormProvider>
    </StyledEditForm>
  );
};

export default EditForm;
