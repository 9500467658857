import Icon from 'shared/components/Icon';
import TEST_ID from 'shared/utility/testing/constants/testId';
import defaultProps from './default';
import { DeleteButtonProps as Props } from './definition';
import { StyledDeleteButton } from './style';

export default (props: Props) => {
  const { handleDeleteUpload } = {
    ...defaultProps,
    ...props,
  };
  return (
    <StyledDeleteButton>
      <Icon
        data-test-id={TEST_ID.COMMON.FILE_UPLOAD_INPUT.DELETE_BUTTON}
        name="delete-filled"
        themeColor="whitelabel.primary.color"
        size={26}
        hasHover
        onClick={handleDeleteUpload}
      />
    </StyledDeleteButton>
  );
};
