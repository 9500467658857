import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'definition';
import { callflowAdapter } from './slice';

export const {
  selectIds,
  selectEntities,
  selectAll: selectNotificationsMetadata,
  selectTotal,
  selectById: selectCallflowTreeById,
} = callflowAdapter.getSelectors((state: RootState) => state.callflows.present);

export const selectNode = createSelector(
  (state: any, callflowId: string, nodeId: string) =>
    selectCallflowTreeById(state, callflowId)?.nodes[nodeId],
  (node: any) => node,
);

export const selectNumbers = createSelector(
  (state: any, callflowId: string) => selectCallflowTreeById(state, callflowId)?.numbers,
  (numbers: any) => numbers,
);

export const selectBranch = createSelector(
  (state: any, callflowId: string, nodeId: string) => selectNode(state, callflowId, nodeId),
  (node: any) => node,
);
export const selectTreeNodeCount = createSelector(
  (state: any, callflowId: string) => {
    const nodes = selectCallflowTreeById(state, callflowId)?.nodes;
    return nodes ? Object.keys(nodes).length : 0;
  },
  (count: any) => count,
);
export const selectTreeRoot = createSelector(
  (state: any, callflowId: string) => selectCallflowTreeById(state, callflowId)?.root,
  (root: any) => root,
);
export const selectCallflowDialogData = createSelector(
  (state: any, callflowId: string, dialogProp = 'dialog') => {
    const tree: any = selectCallflowTreeById(state, callflowId);
    return tree && tree[dialogProp];
  },
  (tree: any) => tree,
);
export const selectCallflowMetadata = createSelector(
  (state: any, callflowId: string, nodeId: string | undefined) =>
    nodeId ? selectCallflowTreeById(state, callflowId)?.metadata[nodeId] : null,
  (metadata: any) => metadata,
);
export const selectIsTreeActive = createSelector(
  [selectCallflowTreeById],
  (callflowTree: any) => callflowTree.isActivated,
);
