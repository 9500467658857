export enum ConfigsId {
  Accounts = 'accounts',
  Voicemail = 'voicemail',
}

export enum ConfigsMutations {
  UpdateVoicemailConfigs = 'updateVoicemailConfigs',
}

export type Configs = any;

export type ConfigsResponse = Configs;

export interface UpdateConfigsParams {
  id: string;
  body: Partial<Configs>;
}
