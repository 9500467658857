import { DeviceType, SeatFormProps as Props } from 'apps/PhoneSystem/definition';
import { FormContext } from 'apps/shared/components/FormContext';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import { useCallRestrictionsSection } from 'apps/shared/hooks/useCallRestrictionsSection';
import useTabs from 'apps/shared/hooks/useTabs';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDeepKeys } from 'shared/utility';
import { fields, Section } from './components';
import { FormFields } from './definition';

const Form = ({ hasFormActionRow, apiData }: Props) => {
  const { t } = useTranslation();
  const contextName = useContext(FormContext);

  const {
    component: callRestrictionsSection,
    fields: callRestrictionsFields,
  } = useCallRestrictionsSection({ hasClosedGroups: true, fields: fields.callRestrictions });

  const {
    formState: { dirtyFields, errors },
    getValues,
  } = useFormContext();

  const hasIcon = (mode: string, tabFields: Array<string> = []) =>
    getDeepKeys(mode === 'dirty' ? dirtyFields : errors).filter((field: string) =>
      tabFields.map((field: string) => `${contextName}${field}`).includes(field),
    ).length > 0;

  const device_type = getValues(`${contextName}.device_type`);

  const sharedCallForwardFields = [
    FormFields.CallForwardKeepCallerId,
    FormFields.CallForwardRequireKeypress,
  ];

  const basicsFields =
    device_type === DeviceType.Landline
      ? [...fields.basics, ...sharedCallForwardFields]
      : fields.basics;

  const optionsFields = [DeviceType.Cellphone, DeviceType.Smartphone].includes(device_type)
    ? [...fields.options, ...sharedCallForwardFields]
    : fields.options;

  const sip_device_items = {
    basics: {
      hash: 'basics',
      label: t('phone_system:containers.devices.section.basics.label'),
      component: <Section.Basics />,
      isDirty: hasIcon('dirty', basicsFields),
      isError: hasIcon('error', basicsFields),
    },
    caller_id: {
      hash: 'caller_id',
      label: t('phone_system:containers.devices.section.caller_id.label'),
      component: <Section.CallerId />,
      isDirty: hasIcon('dirty', fields.callerId),
      isError: hasIcon('error', fields.callerId),
    },
    sip: {
      hash: 'sip',
      label: t('phone_system:containers.devices.section.sip.label'),
      component: <Section.SIP />,
      isDirty: hasIcon('dirty', fields.sip),
      isError: hasIcon('error', fields.sip),
    },
    av_section: {
      hash: 'av_section',
      label: t('phone_system:containers.devices.section.audio_video.label'),
      component: <Section.AudioVideo apiData={apiData} />,
      isDirty: hasIcon('dirty', fields.audioVideo),
      isError: hasIcon('error', fields.audioVideo),
    },
    call_restriction: {
      hash: 'call_restriction',
      label: t('phone_system:containers.devices.section.call_restriction.label'),
      component: callRestrictionsSection,
      isDirty: hasIcon('dirty', callRestrictionsFields),
      isError: hasIcon('error', callRestrictionsFields),
    },
    options: {
      hash: 'options',
      label: t('phone_system:containers.devices.section.option.label'),
      component: <Section.Options />,
      isDirty: hasIcon('dirty', optionsFields),
      isError: hasIcon('error', optionsFields),
    },
  };

  let items = Object.values(sip_device_items);

  switch (device_type) {
    case DeviceType.Cellphone:
    case DeviceType.Landline:
    case DeviceType.SipUri:
      items = [sip_device_items.basics, sip_device_items.options];
      break;
    case DeviceType.Fax:
      items = [
        sip_device_items.basics,
        sip_device_items.caller_id,
        sip_device_items.sip,
        sip_device_items.call_restriction,
        sip_device_items.options,
      ];
      break;
    case DeviceType.Smartphone:
      items = [
        sip_device_items.basics,
        sip_device_items.sip,
        sip_device_items.call_restriction,
        sip_device_items.options,
      ];
      break;
    case DeviceType.SipDevice:
    case DeviceType.Softphone:
      items = [
        sip_device_items.basics,
        sip_device_items.caller_id,
        sip_device_items.sip,
        sip_device_items.av_section,
        sip_device_items.call_restriction,
        sip_device_items.options,
      ];
      break;
    default:
      break;
  }

  const { Tabs, TabPanels } = useTabs({
    items,
    options: { hasFormActionRow },
  });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Form;
