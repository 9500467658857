import { EndpointItemType, EndpointType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import Icon from 'shared/components/Icon';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { nameFormatter } from '../TableSelector/utility';

export const columns = ({
  hasUsersExtension,
  heading,
  selectedOptions,
  selectionKey,
  addSelected,
  getEndpointIcon,
}: {
  hasUsersExtension?: boolean;
  heading: string;
  selectedOptions: Array<EndpointItemType>;
  selectionKey: EndpointType;
  addSelected: (
    fields: GroupEndpoint[],
    item: EndpointItemType,
    endpointType: EndpointType,
  ) => void;
  getEndpointIcon: (key: EndpointType) => JSX.Element;
}) => [
  {
    Header: heading,
    Cell: ({ row: { original } }: EndpointItemType) => (
      <TableCellTruncatedValue
        icon={getEndpointIcon(selectionKey)}
        text={nameFormatter(selectionKey, original, hasUsersExtension)}
      />
    ),
    accessor: (row: EndpointItemType) => nameFormatter(selectionKey, row, hasUsersExtension),
  },
  {
    Cell: ({ row: { original } }: { row: { original: EndpointItemType } }) => (
      <Icon
        hasHover
        data-test-id={TEST_ID.PHONE_SYSTEM.SHARED.SHARED_DIALOG.ADD_MEMBER_ICON}
        name="plus-circle-outlined"
        size={22}
        onClick={() => addSelected(selectedOptions, original, selectionKey)}
      />
    ),
    accessor: 'id',
    disableGlobalFilter: true,
  },
];
