import { defaultValues } from 'apps/PhoneSystem/containers/Devices/Edit/default';
import Form from 'apps/PhoneSystem/containers/Devices/Edit/Form';
import { transformDataForForm } from 'apps/PhoneSystem/containers/Devices/Edit/utility';
import { DeviceType } from 'apps/PhoneSystem/definition';
import { getIsRegisteredDevice } from 'apps/PhoneSystem/shared/CallStatusIcon/utility';
import FormActionRow from 'apps/shared/components/FormActionRow';
import { FormContext } from 'apps/shared/components/FormContext';
import { initFormFieldValues } from 'apps/shared/hooks/useDevicePicker';
import { ADD_KEY } from 'constant';
import { Provisioner } from 'definition';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import {
  useFetchDeviceByIdQuery,
  useFetchDeviceStatusesQuery,
  useRebootDeviceMutation,
} from 'models/Device';
import { useContext, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import { useToast } from 'shared/hooks/useToast';
import { generateRandomTextString, generateUserName, isModified } from 'shared/utility';
import { DialogContext } from 'store/contexts';
import { useCleanUpUnmodifiedEntities } from '../../hooks/useCleanupUnmodifiedEntities';
import DataProvider from '../../SeatEntityDataProvider';

const DevicesEditView = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { getValues, setValue, reset } = useFormContext();
  const [searchParams] = useSearchParams();
  const dialogCtx = useContext(DialogContext);
  const [rebootDevice] = useRebootDeviceMutation();
  const {
    data: deviceStatusesData,
    isLoading: isLoadingDeviceStatuses,
  } = useFetchDeviceStatusesQuery();

  const { id } = useParams() as any;
  const { data: deviceData, isLoading: isLoadingDeviceData } = useFetchDeviceByIdQuery(
    { id },
    { skip: id.startsWith(ADD_KEY) },
  );

  const { setOnCleanUp } = useCleanUpUnmodifiedEntities();

  const generatedDefaults = useMemo(() => {
    const sipValues = {
      ...defaultValues.sip,
      username: generateUserName(),
      password: generateRandomTextString(),
    };
    return cloneDeep({ ...defaultValues, sip: sipValues });
  }, [id]);

  useEffect(() => {
    if (id === ADD_KEY) {
      const deleteUnmodifiedDevice = (idToDelete: string, initialValues: any) => {
        const currentValues = getValues();
        if (initialValues && !isModified(initialValues, currentValues.device[idToDelete])) {
          delete currentValues.device[idToDelete];
          reset({ ...currentValues }, { keepDirty: true });
        }
      };

      // Add the timestamp to allow for multiple items to be created at a single time.
      const newId = `${ADD_KEY}-${Date.now()}`;
      navigate(`../${newId}`, { replace: true });

      const deviceType = (searchParams.get('type') ?? generatedDefaults.device_type) as DeviceType;
      const values = getValues();
      values.device[newId] = cloneDeep({
        ...generatedDefaults,
        device_type: deviceType,
        ...([DeviceType.SipDevice].includes(deviceType)
          ? { extra_info: { provisioner: Provisioner.Advanced } }
          : {}),
        id: newId,
      });
      setOnCleanUp(() => deleteUnmodifiedDevice(newId, values.device[newId]));

      reset({ ...values }, { keepDirty: true });
    }
  }, [id]);

  useEffect(() => {
    if (id !== ADD_KEY && deviceData) {
      const resetData = merge({}, getValues(), {
        device: {
          [id]: { ...initFormFieldValues(deviceData) },
        },
      });

      reset(resetData, { keepDirty: true }); // Populates form components once data is loaded.
    }
  }, [deviceData, id, getValues, reset]);

  const callDeleteDevice = async () => {
    if (id.startsWith(ADD_KEY)) {
      const values = getValues();
      delete values.device[id];
      reset({ ...values }, { keepDirty: true });
    } else {
      setValue(`device.${id}._toDelete`, true, { shouldDirty: true });
    }
    navigate('..');
  };

  const onDeleteDevice = () => {
    dialogCtx.onOpen({
      open: true,
      title: t('phone_system:containers.seats.devices.edit.actions.delete_device.label'),
      confirmMessage: `${t('phone_system:containers.shared.edit_form.delete.text', {
        name: deviceData?.name,
      })} ${t('common:delete_on_save')}`,
      onConfirm: callDeleteDevice,
    });
  };

  const callRebootDevice = async () => {
    try {
      const { status } = await rebootDevice({ id }).unwrap();
      if (status === 'success') {
        showToast.success(t('phone_system:containers.shared.edit_form.reboot.command.success'));
      }
    } catch (exception) {
      showToast.error();
    }
    navigate('..');
  };

  const onRebootDevice = () => {
    dialogCtx.onOpen({
      open: true,
      title: t('phone_system:containers.seats.devices.edit.actions.reboot_device.label'),
      confirmMessage: `${t('phone_system:containers.shared.edit_form.reboot.text', {
        name: deviceData?.name,
      })}`,
      onConfirm: callRebootDevice,
      confirmButtonLabel: t('common:reboot'),
    });
  };

  const isRebootEnabled =
    deviceData && deviceStatusesData && getIsRegisteredDevice(deviceData, deviceStatusesData);

  const renderPageWithData = (data?: any) => (
    <>
      <FormActionRow
        button={{
          primary: {
            label: t('phone_system:containers.seats.devices.edit.actions.delete_device.label'),
            onClick: onDeleteDevice,
          },
          secondary: {
            isDisabled: !isRebootEnabled,
            label: t('phone_system:containers.seats.devices.edit.actions.reboot_device.label'),
            tooltip: !isRebootEnabled
              ? t('phone_system:containers.seats.devices.edit.actions.reboot_device.tooltip')
              : '',
            onClick: onRebootDevice,
          },
        }}
        title={
          data?.name
            ? t('phone_system:containers.seats.devices.edit.actions.editing_device', {
                name: data.name,
              })
            : t('phone_system:containers.seats.devices.edit.actions.editing_new_device')
        }
      />
      <Form hasFormActionRow apiData={data} />
    </>
  );

  if (id === ADD_KEY || isLoadingDeviceData || isLoadingDeviceStatuses) {
    return <Loading hasMargin />;
  }

  return (
    <FormContext.Provider value={`device.${id}.`}>
      {id.startsWith(ADD_KEY) ? (
        renderPageWithData()
      ) : (
        <DataProvider
          id={id}
          fetchFunction={useFetchDeviceByIdQuery}
          entity="device"
          defaultValues={generatedDefaults}
          transformData={transformDataForForm}
        >
          {(data: any) => renderPageWithData(data)}
        </DataProvider>
      )}
    </FormContext.Provider>
  );
};

export default DevicesEditView;
