import trim from 'lodash/trim';

/**
 * @name getEntries
 * @description Get the current entries array.
 *
 * @param key
 *
 * @returns Current entries.
 */
export const getEntries = (key: string) =>
  sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key) as string) : [];

/**
 * @name getEntryIndex
 * @description Get the index of a previous entry (based on the current path).
 *
 * @param entries
 * @param path
 *
 * @returns Index of current entry.
 */
export const getEntryIndex = (entries: Array<any>, path: string) =>
  entries.findIndex((entry: any) => entry.path === path);

/**
 * @name getPrimaryEntry
 * @description Get the first/primary element from the current array.
 *
 * @param key
 *
 * @returns First array element.
 */
export const getPrimaryEntry = (key: string) => getEntries(key)?.[0] ?? {};

/**
 * @name setEntries
 * @description Update the session storage entries array.
 *
 * @param [object]
 * @property entries
 * @property entry
 * @property key
 */
export const setEntries = ({
  entries,
  entry,
  key,
}: {
  entries: Array<any>;
  entry: any;
  key: string;
}) => {
  const entryIndex = getEntryIndex(entries, entry.path);
  const stored = [...entries];

  if (entryIndex !== -1) {
    stored[entryIndex] = { ...entry };
  } else {
    stored.push(entry);
  }

  sessionStorage.setItem(key, JSON.stringify(stored));
};

/**
 * @name trimPathname
 * @description Get the (trimmed of left/right slashes) pathname value.
 *
 * @param pathname
 *
 * @returns Trimmed path.
 */
export const trimPathname = (pathname: string) => trim(pathname, '/');
