import { CallFlowKey } from 'models/Callflow/definition';
import { FunctionComponent } from 'react';
import AgentAvailabilityDialog from './AgentAvailabilityDialog';
import AgentLoginDialog from './AgentLoginDialog';
import AgentLogoutDialog from './AgentLogoutDialog';
import AgentPauseDialog from './AgentPauseDialog';
import RequiredAgentSkillsDialog from './AgentRequiredSkillsDialog';
import AgentResumeDialog from './AgentResumeDialog';
import ApplicationVariablesDialog from './ApplicationVariablesDialog';
import CallerIdDynamicCidDialog from './CallerIdDynamicCidDialog';
import CallerIdPrependDialog from './CallerIdPrependDialog';
import CallerIdResetPrependDialog from './CallerIdResetPrependDialog';
import CallflowDialog from './CallflowDialog';
import CallForwardingDisableDialog from './CallForwardingDisableDialog';
import CallForwardingEnableDialog from './CallForwardingEnableDialog';
import CallForwardingUpdateDialog from './CallForwardingUpdateDialog';
import CallNotificationDialog from './CallNotificationDialog';
import CallRecordingStopDialog from './CallRecordingStopDialog';
import CheckVoicemailDialog from './CheckVoicemailDialog';
import CollectDTMFDialog from './CollectDTMFDialog';
import ConferenceDialog from './ConferenceDialog';
import ConferenceServiceDialog from './ConferenceServiceDialog';
import DeviceDialog from './DeviceDialog';
import DirectoryDialog from './DirectoryDialog';
import DisaDialog from './DisaDialog';
import DistinctiveRingDialog from './DistinctiveRingDialog';
import FaxBoxDialog from './FaxBoxDialog';
import GroupPickupDialog from './GroupPickupDialog';
import LanguageDialog from './LanguageDialog';
import ManualPresenceDialog from './ManualPresenceDialog';
import MatchCallerIdDialog from './MatchCallerIdDialog';
import MediaDialog from './MediaDialog';
import MenuDialog from './MenuDialog';
import PageGroupDialog from './PageGroupDialog';
import ParKDialog from './ParKDialog';
import PivotDialog from './PivotDialog';
import QueueDialog from './QueueDialog';
import QueueLoginDialog from './QueueLoginDialog';
import QueueLogoutDialog from './QueueLogoutDialog';
import ResponseDialog from './ResponseDialog';
import RingGroupDialog from './RingGroupDialog';
import SeatDialog from './SeatDialog';
import SetCallerIdDialog from './SetCallerIdDialog';
import SetCallPriorityDialog from './SetCallPriorityDialog';
import StartCallRecordingDialog from './StartCallRecordingDialog';
import TimeOfDayDialog from './TimeOfDayDialog';
import TimeOfDayDisableDialog from './TimeOfDayDisableDialog';
import TimeOfDayEnableDialog from './TimeOfDayEnableDialog';
import TimeOfDayResetDialog from './TimeOfDayResetDialog';
import VoicemailDialog from './VoicemailDialog';
import WaitTimeDialog from './WaitTimeDialog';
import WebhookDialog from './WebhookDialog';
export const DIALOG_COMPONENTS: { [key: string]: FunctionComponent<any> } = {
  // Basic
  [CallFlowKey.SEAT]: SeatDialog,
  [CallFlowKey.CALLFLOW]: CallflowDialog,
  [CallFlowKey.RING_GROUP]: RingGroupDialog,
  [CallFlowKey.MEDIA]: MediaDialog,
  [CallFlowKey.MENU]: MenuDialog,
  [CallFlowKey.VOICEMAIL]: VoicemailDialog,
  [CallFlowKey.DEVICE]: DeviceDialog,
  [CallFlowKey.DIRECTORY]: DirectoryDialog,
  // Advanced
  [CallFlowKey.PIVOT]: PivotDialog,
  [CallFlowKey.RESPONSE]: ResponseDialog,
  [CallFlowKey.MANUAL_PRESENCE]: ManualPresenceDialog,
  [CallFlowKey.GROUP_PICKUP]: GroupPickupDialog,
  [CallFlowKey.PARK]: ParKDialog,
  [CallFlowKey.WEBHOOK]: WebhookDialog,
  [CallFlowKey.CHECK_VOICEMAIL]: CheckVoicemailDialog,
  [CallFlowKey.MATCH_CALLER_ID]: MatchCallerIdDialog,
  [CallFlowKey.APPLICATION_VARIABLE]: ApplicationVariablesDialog,
  [CallFlowKey.DISTINCTIVE_RING]: DistinctiveRingDialog,
  // Time of Day
  [CallFlowKey.TIME_OF_DAY]: TimeOfDayDialog,
  [CallFlowKey.TIME_OF_DAY_DISABLE]: TimeOfDayDisableDialog,
  [CallFlowKey.TIME_OF_DAY_ENABLE]: TimeOfDayEnableDialog,
  [CallFlowKey.TIME_OF_DAY_RESET]: TimeOfDayResetDialog,
  // Call Center
  [CallFlowKey.QUEUE]: QueueDialog,
  [CallFlowKey.QUEUE_LOGIN]: QueueLoginDialog,
  [CallFlowKey.QUEUE_LOGOUT]: QueueLogoutDialog,
  [CallFlowKey.AGENT_AVAILABILITY]: AgentAvailabilityDialog,
  [CallFlowKey.AGENT_LOGIN]: AgentLoginDialog,
  [CallFlowKey.AGENT_LOGOUT]: AgentLogoutDialog,
  [CallFlowKey.AGENT_PAUSE]: AgentPauseDialog,
  [CallFlowKey.AGENT_RESUME]: AgentResumeDialog,
  [CallFlowKey.AGENT_REQUIRED_SKILLS]: RequiredAgentSkillsDialog,
  [CallFlowKey.SET_CALL_PRIORITY]: SetCallPriorityDialog,
  [CallFlowKey.WAIT_TIME]: WaitTimeDialog,
  // Call Forwarding
  [CallFlowKey.CALL_FORWARDING_ENABLE]: CallForwardingEnableDialog,
  [CallFlowKey.CALL_FORWARDING_DISABLE]: CallForwardingDisableDialog,
  [CallFlowKey.CALL_FORWARDING_UPDATE]: CallForwardingUpdateDialog,
  // Caller-ID
  [CallFlowKey.CALLER_ID_PREPEND]: CallerIdPrependDialog,
  [CallFlowKey.CALLER_ID_RESET_PREPEND]: CallerIdResetPrependDialog,
  [CallFlowKey.CALLER_ID_DYNAMIC]: CallerIdDynamicCidDialog,
  [CallFlowKey.SET_CALLER_ID]: SetCallerIdDialog,
  // Call Recordings
  [CallFlowKey.CALL_RECORDING_START]: StartCallRecordingDialog,
  [CallFlowKey.CALL_RECORDING_STOP]: CallRecordingStopDialog,
  // Call Settings
  [CallFlowKey.COLLECT_DTMF]: CollectDTMFDialog,
  [CallFlowKey.CHANGE_LANGUAGE]: LanguageDialog,
  [CallFlowKey.PAGE_GROUP]: PageGroupDialog,
  [CallFlowKey.CALL_NOTIFICATION]: CallNotificationDialog,
  // Carrier
  [CallFlowKey.DISA]: DisaDialog,
  // Conference
  [CallFlowKey.CONFERENCE_SERVICE]: ConferenceServiceDialog,
  [CallFlowKey.CONFERENCE]: ConferenceDialog,
  // Fax
  [CallFlowKey.FAX_BOX]: FaxBoxDialog,
};
