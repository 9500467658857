import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { ADD_KEY } from 'constant';
import {
  schema,
  useCreateMenuMutation,
  useDeleteMenuMutation,
  useFetchMenuByIdQuery,
  useUpdateMenuMutation,
} from 'models/Menu';
import { createContext, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import { fields } from './components';
import { defaultValues } from './default';
import { FormInput, MenuEditProps as Props } from './definition';
import Form from './Form';
import { transformDataForSaving } from './utility';

export const DataContext = createContext({});

const Edit = ({
  id,
  handleSaveSuccess,
  handleDeleteSuccess,
  handleCancel: onCancel,
  setItemName,
}: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { data, isLoading } = useFetchMenuByIdQuery({ id });

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const {
    formState: { dirtyFields },
    handleSubmit,
    reset,
    setError,
  } = formMethods;
  const isPageDirty = checkFormEntities(dirtyFields);

  const [createMenu] = useCreateMenuMutation();
  const [updateMenu] = useUpdateMenuMutation();
  const [deleteMenu] = useDeleteMenuMutation();

  const onDelete = async () => {
    try {
      await deleteMenu({ id }).unwrap();
      handleDeleteSuccess?.();
    } catch (exception) {
      showToast.delete.error();
    }
  };

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (data: any) => {
      try {
        const body = transformDataForSaving(data);
        if (id === ADD_KEY) {
          await createMenu({ body }).unwrap();
        } else {
          await updateMenu({ id, body }).unwrap();
        }
        handleSaveSuccess?.({ shouldRedirect: id === ADD_KEY });
      } catch (exception) {
        showErrorMessage({ isFromException: true, errors: exception, setError });
      }
    },
    [id, createMenu, handleSaveSuccess, setError, showErrorMessage, updateMenu],
  );

  useEffect(() => {
    const formData = enhancedFormUtility.transformDataToFormData(data, defaultValues);
    reset(formData); // Populates form components once data is loaded.
    enhancedFormUtility.ensureFormData(formData, fields);
  }, [data, reset]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditForm
      isPageDirty={isPageDirty}
      entityLabel={t('phone_system:containers.menu.label')}
      entityName={data?.name}
      formMethods={formMethods}
      onCancel={onCancel}
      onDelete={id !== ADD_KEY ? onDelete : undefined}
      onSave={handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }))}
    >
      <DataContext.Provider value={data}>
        <Form setItemName={setItemName} />
      </DataContext.Provider>
    </EditForm>
  );
};

export default Edit;
