import { Fragment, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import { maybeTooltipComponent } from 'shared/utility';
import { FormActionRowProps as Props } from './definition';
import StyledFormActionRow from './style';

export { CONSTANTS as SUB_ACTION_ROW_CONSTANTS } from './style';

const FormActionRow = ({ button, title }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const on = useMemo(
    () => ({
      click: () => {
        on.exit();
        navigate('..');
      },
      exit: () => {},
    }),
    [navigate],
  );

  useEffect(() => {
    return () => on.exit();
  }, [on]);

  return (
    <StyledFormActionRow>
      <Box data-test-id="link-return-to-list">
        <Box aria-hidden="true" onClick={on.click}>
          <Icon name="chevron-left" subset={16} />
          <Box component="span">{t('common:component.form_action_row.label.link')}</Box>
        </Box>
      </Box>
      <Box>
        <Box component="span">{title}</Box>
        {['secondary', 'primary'].map((type: string) => {
          if (button?.[type]) {
            const { isDisabled, label, tooltip, onClick } = button[type];

            return (
              <Fragment key={`fragment-btn-${type}`}>
                {maybeTooltipComponent({
                  component: (
                    <Button
                      color={type === 'primary' ? 'error' : 'secondary'}
                      disabled={isDisabled}
                      size="small"
                      variant="outlined"
                      onClick={onClick}
                    >
                      {label}
                    </Button>
                  ),
                  tooltip,
                })}
              </Fragment>
            );
          }
          return <Fragment key={`fragment-btn-${type}`} />;
        })}
      </Box>
    </StyledFormActionRow>
  );
};

export default FormActionRow;
