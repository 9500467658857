import { FunctionComponent } from 'react';
import { DeleteOptionsProps as Props } from './definition';
import StyledDeleteOptions from './style';
import translations from './translations';

const DeleteOptions: FunctionComponent<Props> = ({
  isVisible,
  node,
  onHoverNode,
  onHoverAll,
  onNodeDelete,
}: Props): JSX.Element => {
  const nodeCount = Object.keys(node.children).length;
  const { deleteModules } = translations();

  return (
    <StyledDeleteOptions isVisible={isVisible}>
      {nodeCount <= 1 && (
        <span
          onMouseEnter={() => onHoverNode(true)}
          onMouseLeave={() => onHoverNode(false)}
          onClick={() => onNodeDelete()}
        >
          {deleteModules.current}
        </span>
      )}
      {nodeCount > 0 && (
        <span
          onMouseEnter={() => onHoverAll(true)}
          onMouseLeave={() => onHoverAll(false)}
          onClick={() => onNodeDelete(true)}
        >
          {deleteModules.descendants}
        </span>
      )}
    </StyledDeleteOptions>
  );
};

export default DeleteOptions;
