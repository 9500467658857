import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { LabeledSelect } from 'shared/components/Labeled';
import { InputProps } from '../definition';
import { InheritTooltipWrapper } from '../InheritTooltipWrapper';
import { useGetSelectOptions } from './hooks';
import { getFieldValue } from './utility/getFieldValue';

export const SelectInput = ({
  fieldKey,
  template: { text, tooltip, type, value, options, isInheritFromAccount },
}: InputProps): JSX.Element => {
  const selectOptions = useGetSelectOptions(options, value);
  return (
    <HookFormSelectWrapper options={selectOptions} name={fieldKey}>
      {({ ref, isDirty, feedback, ...formProps }) => (
        <InheritTooltipWrapper
          inheritValue={value}
          inputType={type}
          isInheritFromAccount={isInheritFromAccount}
          tooltip={tooltip}
          fieldValue={getFieldValue(formProps)}
        >
          {(tooltip) => (
            <LabeledSelect
              isDirty={isDirty}
              feedback={feedback}
              label={text}
              tooltip={tooltip}
              selectProps={{
                ...formProps,
                id: `select-${fieldKey}`,
              }}
            />
          )}
        </InheritTooltipWrapper>
      )}
    </HookFormSelectWrapper>
  );
};
