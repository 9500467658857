import { validateNumber } from 'apps/shared/utility';
import { CHARACTER, MILLISECONDS_IN_SECOND } from 'constant';
import { intervalToDuration } from 'date-fns';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledText } from 'shared/components/Labeled';
import defaultProps from './default';
import { DurationProps as Props } from './definition';
import { getDurationText } from './utility';

export type { Time as DurationTime } from './definition';

const Duration = (props: Props) => {
  const { isAllDayEvent, id, time } = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const buildDuration = useCallback(() => {
    const { start, stop }: { start: number; stop: number } = {
      start: Number(time.start),
      stop: Number(time.stop),
    };

    if (validateNumber(start) && validateNumber(stop)) {
      const duration = intervalToDuration({
        start: start * MILLISECONDS_IN_SECOND,
        end: stop * MILLISECONDS_IN_SECOND,
      });
      const hours = duration.hours ?? 0;
      const minutes = duration.minutes ?? 0;
      const text = getDurationText(hours, minutes);

      if (isAllDayEvent) {
        return t('phone_system:containers.time_of_day.component.duration.label.all_day');
      }

      if (hours > 0 || minutes > 0) {
        return `${text.hours}${hours > 0 && minutes > 0 ? ', ' : ''}${text.minutes}`;
      }
    }

    return CHARACTER.EMDASH;
  }, [isAllDayEvent, time, t]);

  return (
    <LabeledText
      id={id}
      label={t('phone_system:containers.time_of_day.component.duration.label.duration')}
      text={buildDuration()}
    />
  );
};

export default Duration;
