import { EndpointType } from 'apps/PhoneSystem/definition';

export enum GetLabelPrefixSeparator {
  Comma = ', ',
  Hyphen = ' - ',
}

export const getLabel = ({
  type,
  item,
  prefix: {
    isVisible: hasPrefix = true,
    separator: prefixSeparator = GetLabelPrefixSeparator.Comma,
  } = {},
  isSort = false,
}: {
  type: EndpointType;
  item: any;
  prefix?: {
    isVisible?: boolean;
    separator?: string;
  };
  isSort?: boolean;
}): string | undefined => {
  if (type === EndpointType.Device) {
    const label = {
      name: item.owner_name ?? item.name,
      sort: `${item.owner_name}${prefixSeparator}${item.name}`,
      verbose: `${item.name}${prefixSeparator}${item.owner_name}`,
    };

    if (isSort) {
      return label.sort;
    }

    return hasPrefix ? label.verbose : label.name;
  }
  if (type === EndpointType.User) {
    const label = {
      name: `${item?.first_name} ${item?.last_name}`,
      prefix: hasPrefix ? `${item?.username}${prefixSeparator}` : '',
    };
    return `${label.prefix}${label.name}`;
  }
};

export const getUsersFromEndpoints = (endpoints: any, data: any) => {
  if (!data?.endpoints) {
    return [];
  }

  return Object.entries(data?.endpoints)
    .map(([id, item]: any) => {
      if (item.type === EndpointType.Device) {
        const ownerId = endpoints.devices.data?.find((device: any) => device.id === id)?.owner_id;
        return {
          type: EndpointType.Device,
          deviceId: id,
          userId: ownerId,
        };
      }
      if (item.type === EndpointType.User) {
        return {
          type: EndpointType.User,
          userId: id,
        };
      }
    })
    .map(({ type, deviceId, userId }: any) => {
      const device = endpoints.devices.data?.find((device: any) => device.id === deviceId);
      const user = endpoints.users.data?.find((user: any) => user.id === userId);
      return type === EndpointType.Device ? device : user ?? device;
    })
    .map(
      (item: any) =>
        item &&
        (item.device_type
          ? getLabel({
              type: EndpointType.Device,
              prefix: { isVisible: false, separator: GetLabelPrefixSeparator.Hyphen },
              item,
            })
          : getLabel({
              type: EndpointType.User,
              prefix: { isVisible: false },
              item,
            })),
    );
};
