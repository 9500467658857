import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledDashboardQueuesToggleProps as Props } from './definition';

export const CONSTANTS = {
  HEIGHT: '40px',
};

const StyledDashboardQueuesToggle = styled(Box)<Props>`
  ${({ hasqueues, theme }) => css`
    ${mixin.flex()};
    ${mixin.borderRadius({ radius: theme.spacing(0, 0, 3.33, 3.33) })};
    ${mixin.boxShadow({ alpha: 5, offsetY: 2 })};
    height: ${CONSTANTS.HEIGHT};
    background-color: ${theme.brio.background.default};
    border: 1px solid ${theme.core.background.grey.light.border};
    border-top: 0;
    cursor: pointer;

    &:hover {
      p {
        color: ${theme.brio.ooma.burgundy};
      }

      svg {
        fill: ${theme.brio.ooma.burgundy};
      }
    }

    p {
      ${mixin.font({ size: 16, weight: 500 })};
      ${mixin.transition({ property: 'color', duration: 'faster' })};
    }

    svg {
      ${mixin.transition([
        { property: 'fill', duration: 'faster' },
        { property: 'transform', duration: 'medium' },
      ])};
      margin: ${theme.spacing(0, 1.5, 0, 0)};
      transform: rotate(0);

      ${hasqueues === 'true' &&
      css`
        transform: rotate(-180deg);
      `}
    }
  `}
`;

export default StyledDashboardQueuesToggle;
