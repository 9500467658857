import { useTranslation } from 'react-i18next';
import Dialog, { DialogActions, DialogType } from 'shared/components/Dialog';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { WarningDialogProps as Props } from './definition';

const WarningDialog = ({ isOpen, onAction, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      dataTestId={TEST_ID.PHONE_SYSTEM.ACCOUNT.SETTINGS.CELLULAR_ENABLEMENT_SECTION.WARNING_DIALOG}
      renderActions={
        <DialogActions
          cancelLabel={t('common:cancel')}
          saveButtonProps={{
            color: 'error',
            label: t(
              'phone_system:containers.account.submodule.settings.section.team_chat_enablement.warning_dialog.continue',
            ),
            variant: 'contained',
          }}
          onAction={onAction}
        />
      }
      title={t(
        'phone_system:containers.account.submodule.settings.section.team_chat_enablement.warning_dialog.title',
      )}
      type={DialogType.Large}
      onClose={onClose}
    >
      {t(
        'phone_system:containers.account.submodule.settings.section.team_chat_enablement.warning_dialog.content',
      )}
    </Dialog>
  );
};

export default WarningDialog;
