import { hasNumber } from 'apps/PhoneSystem/containers/Account/Settings/components/CellularEnablementSection/components/BridgeNumberSelect/utility';
import { BuyNumbers } from 'apps/shared/components/BuyNumbers';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useLazyFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { LabeledSelect } from 'shared/components/Labeled';
import { OptionTypeBase } from 'shared/components/Select/definition';
import { NumberSelectorProps as Props } from './definition';
import { useSpareNumbers } from './hooks/useSpareNumbers';

/**
 * @description The number picker with buy number button.
 *
 *              To ensure that the `defaultOption` prop works as intended, do not
 *              reset the form value to `undefined` or anything other than the
 *              value of `defaultOption`.
 */
export const NumberSelector = ({
  defaultOption,
  initialNumber,
  label,
  name,
  placeholder,
  onNumberChange,
}: Props) => {
  const { watch, resetField } = useFormContext();
  const { spareNumbersOptions, refetchPhoneNumbers } = useSpareNumbers();
  const [fetchPhoneNumbers] = useLazyFetchPhoneNumbersQuery();

  const watchNumber = watch(name);

  const options = useMemo(() => {
    let numberOptions = spareNumbersOptions;
    if (hasNumber(initialNumber)) {
      numberOptions = [{ label: initialNumber, value: initialNumber }, ...numberOptions];
    }
    if (defaultOption) {
      numberOptions = [defaultOption, ...numberOptions];
    }
    return numberOptions;
  }, [defaultOption, initialNumber, spareNumbersOptions]);

  useEffect(() => {
    if (!watchNumber) {
      resetField(name, { defaultValue: defaultOption?.value });
    }
  }, [watchNumber]);

  return (
    <HookFormSelectWrapper name={name} options={options}>
      {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
        <>
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            label={label}
            selectProps={{
              ...formProps,
              placeholder,
              value,
              onChange: (number: OptionTypeBase) => {
                onChange(number);
                onNumberChange?.({ number: number!.value });
              },
              onMenuOpen: () => refetchPhoneNumbers(),
            }}
          />
          <BuyNumbers
            buttonVariant="outlined"
            onFlowComplete={({ success }) => {
              fetchPhoneNumbers().then(() => {
                const number = success?.[0];
                onChange({ label: number, value: number });
                onNumberChange?.({ number });
              });
            }}
          />
        </>
      )}
    </HookFormSelectWrapper>
  );
};
