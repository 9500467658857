import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { StyledSkillsProps as Props } from './definition';

const StyledSkills = styled(Box)<Props>`
  ${({ theme }) => css`
    color: ${theme.brio.greyscale[600]},
    font-size: 14px,
  `}
`;

export default StyledSkills;
