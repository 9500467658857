import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledFlowChildrenProps as Props } from './definition';

export const StyledFlowChildren = styled.div<Props>`
  ${(props) => css`
    display: grid;
    grid-template: 1fr / repeat(${props.columns}, 1fr);
    --gap: 12px;
    gap: var(--gap);

    ${props.columns !== 1 &&
    css`
      > div {
        position: relative;

        > .branch-segment {
          position: absolute;
          width: calc(100% + var(--gap));
          margin: 28px 0 0;
          border-top: 8px solid ${props.theme.core.divider.default};
        }

        --offset: 4px;
        &:first-of-type:not(:last-of-type) > .branch-segment {
          ${mixin.borderRadius({ radius: 'default 0 0 0' })};
          left: calc(50% - var(--offset));
          right: 0;
          width: calc(50% + var(--offset) + var(--gap));
        }

        &:last-of-type:not(:first-of-type) > .branch-segment {
          ${mixin.borderRadius({ radius: '0 default 0 0' })};
          left: 0;
          right: calc(50% - var(--offset));
          width: calc(50% + var(--offset));
        }
      }
    `}
  `};
`;

export default StyledFlowChildren;
