import Input from '@mui/material/Input';
import { getFeedbackColor } from 'shared/utility';
import { WidthType } from 'shared/utility/definition';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import * as mixin from '../../utility/mixin';
import { MessageType } from '../InputMessage';
import { StyledInputProps as Props } from './definition';

export const CONSTANTS = {
  BORDER: 1,
  HEIGHT_INNER: 38,
  get HEIGHT() {
    return `${this.HEIGHT_INNER + this.BORDER * 2}px`;
  },
};

export const inputStyle = {
  height: (height: number = CONSTANTS.HEIGHT_INNER): FlattenSimpleInterpolation => css`
    height: ${height}px;
    line-height: ${height}px;
  `,
  wrapper: (props: any): FlattenSimpleInterpolation => css`
    border: ${CONSTANTS.BORDER}px solid
      ${props.feedback?.type
        ? getFeedbackColor(props, props.feedback?.type as MessageType)
        : props.theme.core.input.border.default};
    border-radius: 4px;
    background-color: ${props.theme.core.color.white};
  `,
};

export const input = (
  props: any,
  width: WidthType,
  type = 'input',
): FlattenSimpleInterpolation => css`
  ${inputStyle.wrapper(props)};
  ${mixin.width({ width, type })};

  input {
    ${inputStyle.height()};
  }
`;

const StyledInput = styled(Input)<Props>`
  ${(props) => css`
    ${input(props, props.width as WidthType)};
    outline: none;

    &::after,
    &::before {
      display: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
      margin: 0;
      -webkit-appearance: none;
    }

    input {
      padding: 0 12px;

      &:disabled {
        background-color: ${props.theme.core.background.grey.medium};
        color: ${props.theme.core.color.black};
      }

      &:focus,
      &:focus-visible {
        outline: none;
      }

      &[type='number'] {
        padding: 0 0 0 12px;
        -moz-appearance: textfield; // for Firefox
      }
    }
  `};
`;

export default StyledInput;
