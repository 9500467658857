import { ContainerMeta } from 'apps/shared/definition';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import List from './List';

export const meta: ContainerMeta = {
  // t('numbers:containers.spare_numbers.label')
  icon: 'spare_numbers',
  label: 'numbers:containers.spare_numbers.label',
  slug: 'spare-numbers',
};

const SpareNumbers = () => {
  const routes = useRoutes([{ path: '/*', element: <List /> }]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};

export default SpareNumbers;
