import Agents from 'api/agents';
import { RequestMethod } from 'definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const agentMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    updateAgent: builder.mutation<Device, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: Agents.UpdateById(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.AGENTS, id }],
    }),
    restartAgent: builder.mutation<Device, { id: string }>({
      query: ({ id }) => ({
        url: Agents.RestartById(id),
        method: RequestMethod.Post,
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.AGENTS, id }],
    }),
  }),
});
