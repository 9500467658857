import { Fields as CallRestrictionsFields } from 'apps/PhoneSystem/components/CallRestrictionsSection/definition';
import CallCenterOptions, {
  fields as callCenterOptionsFields,
} from 'apps/shared/components/CallCenterOptions';
import AdvancedSection, { fields as advancedFields } from './AdvancedSection';
import BasicsSection, { fields as basicsFields } from './BasicsSection';
import CallerIdSection, { fields as callerIdFields } from './CallerIdSection';
import CallRoutingSection, { fields as callRoutingFields } from './CallRoutingSection';
import DesktopModulesSection, { fields as desktopModulesFields } from './DesktopModulesSection';

const fields = {
  advanced: advancedFields,
  basics: basicsFields,
  callCenterOptions: callCenterOptionsFields,
  callerId: callerIdFields,
  callRestrictions: CallRestrictionsFields,
  callRouting: callRoutingFields,
  desktopModules: desktopModulesFields,
};

const Section = {
  Advanced: AdvancedSection,
  Basics: BasicsSection,
  CallCenterOptions,
  CallerId: CallerIdSection,
  CallRouting: CallRoutingSection,
  DesktopModules: DesktopModulesSection,
};

export { fields, Section };
