import { EndpointType } from 'apps/PhoneSystem/definition';
import { Recipient } from './definition';

export const mapUsersToForm = (recipients: Recipient[]) =>
  recipients
    ? recipients
        .filter((recipient: Recipient) => recipient.type === EndpointType.User)
        .map((recipient: Recipient) => ({
          key: recipient.id,
          endpoint_type: recipient.type,
        }))
    : [];

export const mapEmailsToForm = (recipients: Recipient[]) =>
  recipients
    ? recipients
        .filter((recipient: Recipient) => recipient.type === EndpointType.Email)
        .map((recipient: Recipient) => recipient.id)
        .join(',')
    : '';

export const mapUsersToNode = (users: any) =>
  users.map((user: Recipient) => ({
    type: EndpointType.User,
    id: user.key,
  }));

export const mapEmailsToNode = (emails: string) =>
  emails && emails.trim() !== ''
    ? emails
        .split(',')
        .filter((email) => email)
        .map((email) => ({
          type: EndpointType.Email,
          id: email.trim(),
        }))
    : [];
