import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { EditFormProps } from 'apps/PhoneSystem/definition';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { FormContext } from 'apps/shared/components/FormContext';
import { initFormFieldValues } from 'apps/shared/hooks/useDevicePicker';
import { mergeArraysCustomizer } from 'apps/shared/utility';
import { ADD_KEY } from 'constant';
import merge from 'lodash/merge';
import {
  schema,
  useDeleteDeviceMutation,
  useFetchDeviceByIdQuery,
  useUpdateDeviceMutation,
} from 'models/Device';
import { useCallback, useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import { defaultValues } from './default';
import { FormInput } from './definition';
import Form from './Form';
import { DataContext, transformDataForForm, transformDeviceForSaving } from './utility';

export { transformDeviceForSaving } from './utility';

const Edit = ({ id, handleSaveSuccess, handleDeleteSuccess, handleCancel }: EditFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { data, isLoading } = useFetchDeviceByIdQuery({ id }, { refetchOnMountOrArgChange: true });
  const contextName = useContext(FormContext);

  const [updateDevice] = useUpdateDeviceMutation();
  const [deleteDevice] = useDeleteDeviceMutation();

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const {
    formState: { dirtyFields },
    handleSubmit,
    reset,
    setError,
  } = formMethods;
  const isPageDirty = checkFormEntities(dirtyFields);

  const onDelete = async () => {
    try {
      await deleteDevice({ id }).unwrap();
      handleDeleteSuccess?.();
    } catch (exception) {
      showToast.delete.error();
    }
  };

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (data: FormInput) => {
      try {
        await updateDevice({ id, body: transformDeviceForSaving(data) }).unwrap();
        handleSaveSuccess?.();
      } catch (exception) {
        showErrorMessage({ isFromException: true, errors: exception, setError });
      }
    },
    [id, handleSaveSuccess, setError, showErrorMessage, updateDevice],
  );

  useEffect(() => {
    if (data) {
      const formData = merge({}, transformDataForForm(data), initFormFieldValues(data));

      const resetData = enhancedFormUtility.transformDataToFormData(
        formData as any,
        defaultValues,
        mergeArraysCustomizer,
      );

      reset(resetData); // Populates form components once data is loaded.
    }
  }, [data, contextName, reset]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditForm
      isPageDirty={isPageDirty}
      entityLabel={t('phone_system:containers.devices.label')}
      entityName={data?.name}
      formMethods={formMethods}
      onCancel={handleCancel}
      onDelete={id !== ADD_KEY ? onDelete : undefined}
      onSave={handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }))}
    >
      <DataContext.Provider value={data}>
        <Form />
      </DataContext.Provider>
    </EditForm>
  );
};

export default Edit;
