import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledSwitch } from 'shared/components/Labeled';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['prepend.name', 'prepend.enabled'];

const PrependSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('numbers:containers.numbers_in_use.section.prepend.description')}</h3>
      <div role="row">
        <div role="cell">
          {/* Username */}
          <HookFormInputWrapper name="prepend.name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                tooltip={t('numbers:containers.numbers_in_use.section.prepend.field.name.info')}
                label={t('numbers:containers.numbers_in_use.section.prepend.field.name.label')}
                inputProps={{
                  placeholder: t(
                    'numbers:containers.numbers_in_use.section.prepend.field.name.label',
                  ),
                  ...formProps,
                }}
              ></LabeledInput>
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="prepend.enabled" isCheckbox>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSwitch
                isDirty={isDirty}
                label={t('numbers:containers.numbers_in_use.section.prepend.field.enabled')}
                switchProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
    </>
  );
};

export default PrependSection;
