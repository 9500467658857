import { FunctionComponent } from 'react';
import Button from 'shared/components/Button';
import InputMessage from 'shared/components/InputMessage';
import Labeled from '../..';
import defaultProps from './default';
import { LabeledButtonProps as Props } from './definition';
import StyledLabeledButton from './style';

const LabeledButton: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasDirtyIcon,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    buttonProps,
    feedback,
    message,
    tooltip,
    tooltipWidth,
    dataTestId,
  }: Props = {
    ...defaultProps,
    ...props,
  };

  const _message = { ...defaultProps.message, ...message };

  // InputMessage icon overrides
  const ICON_NAME: { [key: string]: string } = {
    error: 'warning-filled',
  };

  return (
    <Labeled
      type="button"
      hasDirtyIcon={hasDirtyIcon}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={{ ...labelProps }}
      labelWidth={labelWidth}
      inputWidth="large"
      feedback={feedback}
      tooltip={tooltip}
      tooltipWidth={tooltipWidth}
      styledComponent={StyledLabeledButton}
    >
      <>
        {!!_message?.message && (
          <InputMessage {..._message} icon={ICON_NAME[_message.type]} isAbove isNoWrap />
        )}
        <Button {...buttonProps} data-test-id={dataTestId} />
      </>
    </Labeled>
  );
};

export default LabeledButton;
