import { setCurrentAccount as defaultSetCurrentAccount } from 'apps/shared/utility/account';
import { SEARCH_PARAM_KEYWORD } from 'shared/components/Table/utility';

export const onGoToAccount = ({
  accountId,
  accountName,
  dispatch,
  macAddress,
  setCurrentAccount = defaultSetCurrentAccount,
  setMatchedSubaccountId,
  setSearchParams,
  showMasqueradingToast,
}: {
  accountId: string;
  accountName: string;
  dispatch: any;
  macAddress: string;
  setCurrentAccount?: (dispatch: any, accountId: string) => void;
  setMatchedSubaccountId: (value: string | undefined) => void;
  setSearchParams: (value: string) => void;
  showMasqueradingToast: (accountName?: string | undefined) => void;
}) => {
  setCurrentAccount(dispatch, accountId);
  setMatchedSubaccountId(undefined);
  showMasqueradingToast(accountName);
  // TODO: Find a better way to wait until masquerading finish
  setTimeout(() => {
    setSearchParams(`${SEARCH_PARAM_KEYWORD}=${macAddress}`);
  }, 1000);
};
