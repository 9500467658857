import { useTranslation } from 'react-i18next';
import { PhoneNumberProps as Props } from './definition';
import { StyledPhoneNumber } from './style';

export default (props: Props) => {
  const { value } = props;
  const { t } = useTranslation();
  return (
    <span>
      {t('phone_system:containers.seats.sms.empty_sms.create_sms_box.dialog.message')}{' '}
      <StyledPhoneNumber>{value}</StyledPhoneNumber>
    </span>
  );
};
