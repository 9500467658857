import { URL } from 'constant';
import { TFunction } from 'i18next';

export const getLinkData = (t: TFunction) => [
  {
    icon: {
      name: 'contact-support-outlined',
      size: 48,
    },
    title: t('phone_system:containers.dashboard.panel.knowledge.link.articles'),
    url: URL.SUPPORT.HOME,
  },
];
