import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const Directory = {
  // GET
  Fetch: (isPaginated: boolean = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/directories?paginate=${isPaginated}`,
  FetchById: (id: string, isPaginated: boolean = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/directories/${id}?paginate=${isPaginated}?_=${Date.now()}`,

  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/directories`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/directories/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/directories/${id}`,
};
