import { SELECT_CONSTANTS } from 'shared/components/Select/constant';

/**
 * @name getHeight
 * @description Calculate the height of the select item menu container.
 *
 * @param itemCount
 * @param maxHeight
 *
 * @returns Menu container height.
 */
export const getHeight = (itemCount: number, maxHeight: number) =>
  itemCount > Math.floor(maxHeight / SELECT_CONSTANTS.HEIGHT)
    ? maxHeight
    : itemCount * SELECT_CONSTANTS.HEIGHT + SELECT_CONSTANTS.MARGIN;
