import { ComponentProps } from 'shared/definition';

export enum NumberType {
  Extension = 'extension',
  Spare = 'spare',
}

export interface AddNumberDialogProps extends ComponentProps {
  /** Is the dialog open? */
  isOpen: boolean;
  /** onClose event handler */
  onClose: (result?: string) => void;
}
