// Notification Mutations
import { Notification } from 'api/notification';
import { RequestMethod } from 'definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const notificationMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createNotification: builder.mutation<Notification, CreateNotificationParams>({
      query: ({ accountId, typeId, body }) => ({
        url: Notification.Create(accountId, typeId),
        method: RequestMethod.Put,
        // body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.NOTIFICATION, id: 'LIST' }],
    }),
    deleteNotification: builder.mutation<Notification, DeleteNotificationParams>({
      query: ({ accountId, typeId }) => ({
        url: Notification.Delete(accountId, typeId),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { typeId }) => [{ type: TAGS.NOTIFICATION, typeId }],
    }),
    updateNotification: builder.mutation<Notification, UpdateNotificationParams>({
      query: ({ accountId, typeId, body }) => ({
        url: Notification.Update(accountId, typeId),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { typeId }) => [{ type: TAGS.NOTIFICATION, typeId }],
    }),
  }),
});
