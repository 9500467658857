import TableCellShareIcon from 'apps/PhoneSystem/shared/TableCellSharedIcon';
import { TableCellCheckmarkIcon, TableCellMaskedValue } from 'apps/shared/components/TableCell';
import { useFetchAccountConfigsQuery } from 'models/Account';
import { getFullNameFromUserListByUserId, useFetchUsersQuery } from 'models/User';
import { useFetchSharedVoicemailsQuery, useFetchVoicemailsQuery } from 'models/Voicemail';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import Table, {
  Cell,
  Justify,
  SHARE_COLUMN_KEYWORD,
  TABLE_CONSTANTS,
} from 'shared/components/Table';

const List = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchVoicemailsQuery();
  const { data: accountData, isLoading: isLoadingAccountData } = useFetchAccountConfigsQuery();
  const { data: sharedData, isLoading: isLoadingSharedData } = useFetchSharedVoicemailsQuery();
  const { data: usersData, isLoading: isLoadingUsersData } = useFetchUsersQuery();

  const columns = useMemo(
    () => [
      {
        Header: t('phone_system:containers.voicemails.table.column.shared_vmbox'),
        Cell: ({ value }: Cell<JSX.Element>) => <TableCellShareIcon data={sharedData} id={value} />,
        accessor: 'id',
        id: `voicemails.${SHARE_COLUMN_KEYWORD}.${Justify.Center}`,
        disableSortBy: true,
        width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
      },
      {
        Header: t('phone_system:containers.voicemails.table.column.voicemail_box_name'),
        accessor: 'name',
      },
      {
        Header: t('phone_system:containers.voicemails.table.column.assigned_to'),
        accessor: ({ owner_id }: any) => getFullNameFromUserListByUserId(owner_id, usersData),
        width: 250,
      },
      {
        Header: t('phone_system:containers.voicemails.table.column.seat_type'),
        accessor: ({ owner_id }: any) => {
          const { seat_type } = usersData?.find((user: any) => owner_id === user.id) ?? {};
          return seat_type && accountData?.seat_types[seat_type]?.display_name;
        },
        width: 200,
      },
      {
        Header: t('phone_system:containers.voicemails.table.column.voicemail_number'),
        accessor: 'mailbox',
        width: 200,
      },
      {
        Header: t('phone_system:containers.voicemails.table.column.pin'),
        Cell: ({ value }: Cell<JSX.Element>) => <TableCellMaskedValue value={value} />,
        accessor: 'pin',
        disableGlobalFilter: true,
        width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
      },
      {
        Header: t('phone_system:containers.voicemails.table.column.transcriptions_enabled'),
        Cell: ({ value }: Cell<JSX.Element>) => <TableCellCheckmarkIcon isVisible={value} />,
        accessor: 'transcribe',
        id: `voicemails.transcriptions_enabled.${Justify.Center}`,
        width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.LARGE,
      },
    ],
    [accountData, sharedData, usersData, t],
  );

  if (isLoading || isLoadingAccountData || isLoadingSharedData || isLoadingUsersData) {
    return <Loading />;
  }

  return (
    <Table
      hasSearch
      columns={columns}
      data={data ?? []}
      initialState={{ sortBy: { id: 'name' } }}
      title={t('phone_system:containers.voicemails.table.title')}
    />
  );
};

export default List;
