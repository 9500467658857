import kebabCase from 'lodash/kebabCase';
import { ForwardedRef, forwardRef, FunctionComponent, useEffect, useMemo, useState } from 'react';
import TEST_ID from 'shared/utility/testing/constants/testId';
import defaultProps from './default';
import { IconProps as Props, IconSet, IconSubset, IconSvgProps } from './definition';
import StyledSvgIcon from './style';
import { buildClasses } from './utility';

export const DEFAULT_ICON = {
  SET: 'brio' as IconSet,
  SUBSET: 24 as IconSubset,
};
export { FlippedType } from './definition';
export type { IconProps, IconSet, IconSubset } from './definition';

const Icon: FunctionComponent<Props> = forwardRef(
  (props: Props, ref: ForwardedRef<any>): JSX.Element => {
    const {
      hasHover,
      isFlipped,
      name,
      set,
      subset,
      scale,
      size,
      width: reqWidth, // requested width
      height: reqHeight, // requested height
      themeColor,
      ...rest
    }: Props = { ...defaultProps, ...props };

    const defaultState = useMemo(
      () => ({
        width: subset,
        height: subset,
        viewBox: `0 0 ${subset} ${subset}`,
      }),
      [subset],
    );
    const [icon, setIcon] = useState<IconSvgProps>(defaultState);

    const isRequestedSize = !!size || !!reqWidth || !!reqHeight;

    useEffect(() => {
      const loadIcon = async () => {
        /* TODO: this is causing the performance hit each time an icon component
         * is imported as lazy import is are costly. Alternatively we can load
         * chunks lazily while app is bootstrapping
         */
        await import(`./icon/${set}/${subset}/${name}`)
          .then((icon) => {
            const { element, width, height, viewBox }: IconSvgProps = {
              ...defaultState,
              ...icon.default,
            };

            let _width = width || DEFAULT_ICON.SUBSET;
            let _height = height || DEFAULT_ICON.SUBSET;

            if ((!!size || !!reqWidth) && !!reqHeight && process.env.NODE_ENV !== 'production') {
              console.warn(
                `Icon '${name}' props width and height should not be specified simultaneously.`,
              );
            }

            if (isRequestedSize) {
              const aspectRatio = (height || 1) / (width || 1);

              if (!!size || !!reqWidth) {
                _width = size || reqWidth;
                _height = _width * aspectRatio;
              }

              if (reqHeight) {
                _height = reqHeight;
                _width = _height / aspectRatio;
              }
            }

            setIcon({ element, width: _width, height: _height, viewBox });
          })
          .catch((error) => {
            console.error(error.message);
          });
      };
      loadIcon();

      // cancel async setIcon call
      return () => setIcon({});
    }, [isRequestedSize, defaultState, name, reqHeight, reqWidth, set, size, subset]);

    return (
      <StyledSvgIcon
        ref={ref}
        classes={buildClasses(isFlipped)}
        id={`icon-${kebabCase(name)}`}
        hashover={hasHover.toString()}
        width={icon.width}
        height={icon.height}
        scale={isRequestedSize ? 1 : scale}
        themecolor={themeColor as string}
        viewBox={icon.viewBox}
        data-test-id={`${TEST_ID.COMMON.PREFIX.ICON}-${kebabCase(name)}`}
        {...rest}
      >
        <g>{icon.element}</g>
      </StyledSvgIcon>
    );
  },
);

export default Icon;
