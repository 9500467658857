// User Mutations
import { User } from 'api/user';
import { RequestMethod } from 'definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const userMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<User, Partial<User>>({
      query: ({ accountId, body }) => ({
        url: User.Create(accountId),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [{ type: TAGS.USER, id: 'LIST' }],
    }),
    updateUser: builder.mutation<User, UpdateUserParams>({
      query: ({ accountId, id, body }) => ({
        url: User.UpdateById(accountId, id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.USER, id },
        { type: TAGS.QUEUES },
        { type: TAGS.SEAT, id },
      ],
    }),
    patchUser: builder.mutation<User, UpdateUserParams>({
      query: ({ accountId, id, body }) => ({
        url: User.UpdateById(accountId, id),
        method: RequestMethod.Patch,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.USER, id }],
    }),
    deleteUser: builder.mutation<User, DeleteUserParams>({
      query: ({ accountId, id }) => ({
        url: User.DeleteById(accountId, id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: TAGS.USER, id }],
    }),
    createDesktopAppZone: builder.mutation<User, UpdateUserParams>({
      query: ({ accountId, id, body }) => ({
        url: User.CreateDesktopAppZone(accountId, id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
      }),
    }),
  }),
});
