import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledEmptySMSView = styled.div`
  ${() => css`
    ${mixin.flex({ direction: 'column' })}
    height: 100%;
  `}
`;

export const StyledTitle = styled.span`
  ${(props) => css`
    margin: ${props.theme.spacing(8)} 0 ${props.theme.spacing(6)};
    font-size: 24px;
  `}
`;
