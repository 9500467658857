import { Trans, useTranslation } from 'react-i18next';
import Dialog, { DialogActions, DialogType } from 'shared/components/Dialog';
import Typography from 'shared/components/Typography';
import { DialogProps as Props } from '../definition';

const SsoDisableDialog = ({ isDialogOpen, onClose, onDialogAction }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isDialogOpen}
      renderActions={
        <DialogActions
          cancelLabel={t('common:cancel')}
          saveButtonProps={{
            color: 'error',
            label: t('common:disable'),
            variant: 'contained',
          }}
          onAction={onDialogAction}
        />
      }
      title={t(
        'phone_system:containers.account.submodule.settings.section.authentication.components.enable_sso_switch.components.sso_disable_dialog.title',
      )}
      type={DialogType.Large}
      onClose={onClose}
    >
      <Typography paragraph>
        {t(
          'phone_system:containers.account.submodule.settings.section.authentication.components.enable_sso_switch.components.sso_disable_dialog.description.0',
        )}
      </Typography>
      <Typography paragraph>
        <Trans i18nKey="phone_system:containers.account.submodule.settings.section.authentication.components.enable_sso_switch.components.sso_disable_dialog.description.1" />
      </Typography>
      <Typography paragraph>
        <Trans i18nKey="phone_system:containers.account.submodule.settings.section.authentication.components.enable_sso_switch.components.sso_disable_dialog.description.2" />
      </Typography>
    </Dialog>
  );
};

export default SsoDisableDialog;
