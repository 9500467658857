import { WidthType } from 'shared/utility/definition';
import labeledProps from '../../default';

const defaultProps = {
  ...labeledProps,
  indentWidth: 'large' as WidthType,
  labelWidth: 'large' as WidthType,
  radioProps: [],
};

export default defaultProps;
