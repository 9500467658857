import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import merge from 'lodash/merge';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogType } from 'shared/components/Dialog';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import { getPresetRules } from '../CallerInfoMatchRules/utiltity';
import { CallerInfoMatchManageDialogProps as Props } from './definition';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields: string[] = ['caller_info_search', 'rule_name', 'open_url', 'test_url'];

const CallerInfoMatchManageDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { open, title, renderActions, onClose, list }: Props = {
    ...props,
  };
  const CALLER_INFO_MATCH_PRESET_RULES = useMemo(() => getPresetRules(), []);

  const { t } = useTranslation();
  const { control, watch, reset, getValues } = useFormContext();

  const OPTIONS: SelectOption[] = Object.values(CALLER_INFO_MATCH_PRESET_RULES).map(
    ({ rule_name, display_name }) => {
      return {
        label: rule_name === 'custom' ? t('common:custom') : display_name,
        value: rule_name,
      };
    },
  );

  const getCurrentRule = () =>
    list?.find((item) => item.id === getValues('id') || item.newId === getValues('newId'));

  const currentRule = useMemo(() => getCurrentRule(), [getValues('id'), getValues('newId'), list]);

  const selectorOptions = useMemo(() => {
    const ruleNames = list?.map((item) => item.rule_name);

    const presetRules = OPTIONS.filter(
      ({ value }) =>
        !ruleNames?.includes(value) || value === currentRule?.rule_name || value === 'custom',
    );
    return [...presetRules];
  }, [list, OPTIONS, currentRule]);

  useEffect(() => {
    const presetRuleValues = CALLER_INFO_MATCH_PRESET_RULES[watch('rule_name')];
    const id = getValues('id') || getValues('newId');
    const isRuleSame = getValues('rule_name') === currentRule?.rule_name;
    const fieldData = merge(
      {},
      getValues(),
      id && isRuleSame ? currentRule : { ...presetRuleValues },
    );
    reset(fieldData);
  }, [watch('rule_name'), getValues, reset, watch, list, currentRule]);

  return (
    <Dialog
      open={open}
      renderActions={renderActions}
      title={title}
      type={DialogType.Medium}
      onClose={onClose}
    >
      <p>
        {t(
          'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.manage.content',
        )}
      </p>
      <HookFormSelectWrapper name="rule_name" options={selectorOptions} control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            label={t(
              'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.manage.fields.rule_name.label',
            )}
            selectWidth="xlarge"
            selectProps={{
              ...formProps,
              id: 'select-account-caller-info-match-rule-name',
            }}
            labelProps={{ required: true }}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormInputWrapper name="display_name" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            label={t(
              'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.manage.fields.display_name.label',
            )}
            tooltip={t(
              'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.manage.fields.display_name.tooltip',
            )}
            inputWidth="xlarge"
            inputProps={{ ...formProps }}
            labelProps={{ required: true }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="search_url" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            label={t(
              'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.manage.fields.search_url.label',
            )}
            inputWidth="xlarge"
            tooltip={t(
              'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.manage.fields.search_url.tooltip',
              {
                name: '{{name}}',
                number: '{{number}}',
              },
            )}
            inputProps={{ ...formProps }}
            labelProps={{ required: true }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="test_url" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            label={t(
              'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.manage.fields.test_url.label',
            )}
            inputWidth="xlarge"
            tooltip={t(
              'phone_system:containers.account.submodule.settings.section.caller_info_match.dialogs.manage.fields.test_url.tooltip',
            )}
            inputProps={{ ...formProps }}
            labelProps={{ required: true }}
          />
        )}
      </HookFormInputWrapper>
    </Dialog>
  );
};

export default CallerInfoMatchManageDialog;
