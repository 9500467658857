import { createTheme } from '@mui/material/styles';
import themeColor from 'theme/local/color';
import themeCore from 'theme/local/core';

const theme = createTheme({
  palette: {
    primary: {
      main: themeColor.core.color.green.default,
    },
    secondary: {
      main: themeColor.whitelabel.secondary.color,
    },
    error: {
      main: themeColor.core.color.red,
    },
    info: {
      main: themeColor.core.color.blue.default,
    },
    success: {
      main: themeColor.core.color.green.default,
      dark: themeColor.core.color.green.dark,
    },
    warning: {
      main: themeColor.core.color.yellow,
    },
    neutral: {
      main: themeColor.brio.action.selected,
    },
  },
  shape: {
    borderRadius: 6,
  },
  spacing: themeCore.nemo.spacing,
  transitions: {
    duration: {
      short: 250,
      shorter: 200,
      shortest: 150,
      standard: 300,
    },
    easing: {
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  typography: {
    fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
    htmlFontSize: 14,
  },
});

export default theme;
