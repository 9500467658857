import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  collection_name: '',
  interdigit_timeout: '',
  max_digits: '1',
  terminator: '#',
  timeout: '5000',
};
