import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import defaultProps from './default';
import { InfoIconProps as Props } from './definition';
import StyledInfoIcon from './style';

const InfoIcon = (props: Props) => {
  const { className, isInHeader, isInline, isOutlined, isSmall, tooltip } = {
    ...defaultProps,
    ...props,
  };

  return (
    <StyledInfoIcon $isInHeader={isInHeader} $isInline={isInHeader || isInline}>
      <Tooltip className={className} title={tooltip}>
        <Icon name={`info-${isOutlined ? 'outlined' : 'filled'}`} size={isSmall ? 14 : 19} />
      </Tooltip>
    </StyledInfoIcon>
  );
};

export default InfoIcon;
