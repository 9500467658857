import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { TERMINATORS } from '../../constants';

const schema = () =>
  Joi.object({
    collection_name: Joi.string().optional().allow(''),
    interdigit_timeout: Joi.number().min(0).optional().allow(''),
    max_digits: Joi.number().min(0).required(),
    nodeId: Joi.string(),
    pin: Joi.string().allow(''),
    terminator: Joi.string().valid(...TERMINATORS),
    timeout: Joi.number().min(0).required(),
    use_account_caller_id: Joi.boolean(),
  }).messages(
    messages({
      'number.unsafe': i18next.t('common:validation.general.number_max_custom', {
        min: 0,
        max: 16,
      }),
    }),
  );

export default schema;
