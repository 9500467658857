import { HEADER_CONSTANTS } from 'apps/shared/components/Header';
import { HORIZONTAL_NAV_CONSTANTS } from 'apps/shared/components/HorizontalNav';
import Box from 'shared/components/Box';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled, { css } from 'styled-components';
import { MUICARD_QUEUE_CONSTANTS } from 'theme/mui/components/muiCard';
import { QUEUES_TOGGLE_CONSTANTS } from './components/DashboardQueuesToggle';
import { StyledDashboardProps as Props } from './definition';

const CONSTANTS = {
  INCOMING_CALLS: {
    GRID: {
      COLUMN: '380px',
    },
  },
};

const StyledDashboard = styled(Box)<Props>`
  ${({ hasqueues, theme }) => css`
    display: grid;
    grid-template-columns: ${CONSTANTS.INCOMING_CALLS.GRID.COLUMN} 1fr;

    --padding: ${theme.spacing(2.66)};

    ${hasqueues === 'true'
      ? css`
          grid-template-rows: calc(${MUICARD_QUEUE_CONSTANTS.HEIGHT}px + var(--padding) * 3) ${QUEUES_TOGGLE_CONSTANTS.HEIGHT} 1fr;
          grid-template-areas:
            'queues queues'
            'queues-toggle queues-toggle'
            'incoming-calls agents';
        `
      : css`
          grid-template-rows: ${QUEUES_TOGGLE_CONSTANTS.HEIGHT} 1fr;
          grid-template-areas:
            'queues-toggle queues-toggle'
            'incoming-calls agents';

          > div:nth-of-type(1) {
            display: none;
          }
        `};

    > div {
      &:nth-of-type(1),
      &:nth-of-type(3),
      &:nth-of-type(4) > div {
        padding: var(--padding);
      }

      &:nth-of-type(1) {
        grid-area: queues;
        overflow: auto hidden;
      }

      &:nth-of-type(2) {
        grid-area: queues-toggle;
      }

      &:nth-of-type(3),
      &:nth-of-type(4) {
        overflow: hidden;
      }

      &:nth-of-type(3) {
        grid-area: incoming-calls;

        .component-table-container {
          height: ${`calc(100vh - (${HEADER_CONSTANTS.HEIGHT} + ${
            HORIZONTAL_NAV_CONSTANTS.HEIGHT
          } + ${MUICARD_QUEUE_CONSTANTS.HEIGHT}px + ${
            ACTION_ROW_CONSTANTS.HEIGHT.BREADCRUMB_ABOVE
          } + ${theme.spacing(2.66)} * 5 + ${theme.spacing(2)}))`};
        }
      }

      &:nth-of-type(4) {
        grid-area: agents;
      }
    }
  `};
`;

export default StyledDashboard;
