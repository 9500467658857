import useSetSelectValue from 'apps/PhoneSystem/hooks/useSetSelectValue';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import kebabCase from 'lodash/kebabCase';
import { useFetchMediasQuery } from 'models/Media';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInstance } from 'react-select';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import defaultProps from './default';
import { MediaSelectProps as Props } from './definition';

export const SILENCE = {
  id: 'silence_stream://300000',
  name_key: 'phone_system:shared.media_select.options.silence',
};

const MediaSelect = (props: Props) => {
  const {
    hasDirtyIcon,
    hasSmallMargin,
    isBuildInMediaIncluded,
    isClearable,
    isLabelAbove,
    isTypeIncluded,
    control,
    itemName,
    label,
    labelWidth,
    name,
    tooltip,
    tooltipWidth,
  }: Props = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const [medias, setMedias] = useState<Array<SelectOption>>([]);
  const { data, isLoading } = useFetchMediasQuery();
  const selectRef = useRef<SelectInstance>(null);

  const includedMedia = [
    {
      name: t('phone_system:shared.media_select.options.default_music'),
      id: '',
    },
    {
      id: SILENCE.id,
      // t('phone_system:shared.media_select.options.silence')
      name: t(SILENCE.name_key),
    },
  ];

  useEffect(() => {
    if (data) {
      setMedias(
        [...(isBuildInMediaIncluded ? includedMedia : []), ...data].map((media) => {
          const label = isTypeIncluded
            ? `[${
                media.media_source === 'tts'
                  ? t('phone_system:shared.media_select.name_prepend.TTS')
                  : t('phone_system:shared.media_select.name_prepend.UPL')
              }] ${media.name}`
            : media.name;
          return { value: media.id, label };
        }),
      );
    }
  }, [data]);

  useSetSelectValue({ itemName, selectRef, options: medias });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <HookFormSelectWrapper name={name} options={medias} control={control}>
      {({ ref, feedback, isDirty, ...formProps }) => (
        <LabeledSelect
          hasDirtyIcon={hasDirtyIcon}
          hasSmallMargin={hasSmallMargin}
          isDirty={hasDirtyIcon && isDirty}
          isLabelAbove={isLabelAbove}
          feedback={feedback}
          labelWidth={labelWidth}
          label={label ?? t('phone_system:containers.media.field.media_source.title')}
          ref={selectRef}
          selectProps={{
            ...formProps,
            isClearable,
            id: `select-media-${kebabCase(label)}`,
          }}
          tooltip={tooltip}
          tooltipWidth={tooltipWidth}
        />
      )}
    </HookFormSelectWrapper>
  );
};

export default MediaSelect;
