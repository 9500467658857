import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import kebabCase from 'lodash/kebabCase';
import { useFetchTimeOfDayQuery } from 'models/TimeOfDay';
import { FunctionComponent, useEffect, useState } from 'react';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import defaultProps from './default';
import { TimeOfDaySelectProps as Props } from './definition';
import StyledTimeOfDaySelect from './style';

const TimeOfDaySelect: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { name, label, control } = { ...defaultProps, ...props };
  const [timeOfDayOptions, setTimeOfDayOptions] = useState<SelectOption[]>([]);
  const { data, isLoading } = useFetchTimeOfDayQuery();

  useEffect(() => {
    if (data) {
      setTimeOfDayOptions(
        data.map((timeOfDay) => ({ value: timeOfDay.id, label: timeOfDay.name })),
      );
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledTimeOfDaySelect>
      <HookFormSelectWrapper control={control} name={name} options={timeOfDayOptions}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            labelWidth="none"
            label={label}
            selectProps={{
              ...formProps,
              isMulti: true,
              id: `select-time-of-day-${kebabCase(label)}`,
            }}
          />
        )}
      </HookFormSelectWrapper>
    </StyledTimeOfDaySelect>
  );
};

export default TimeOfDaySelect;
