import { useCreateIdpMutation, useFetchIdpQuery, useUpdateIdpMutation } from 'models/IAM';
import { FunctionComponent, useEffect, useState } from 'react';
import { SubmitErrorHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import Loading from 'shared/components/Loading';
import { useToast } from 'shared/hooks/useToast';
import { FormFields } from '../../definition';
import DeleteIdpButton from './components/DeleteIdpButton';
import Subtitle from './components/Subtitle';
import defaultProps, { defaultFormState } from './default';
import { IdpFormState, SetupIDProviderDialogProps as Props } from './definition';
import { transformFormStateToIdpRequestBody, transformIdpResponseToFormState } from './utility';
import { useSetupIdpForm } from './utility/hooks';

const SetupIdpDialog: FunctionComponent<Props> = (props: Props) => {
  const {
    open,
    onClose: onCloseDialog,
    onSave: onSaveDialog,
    onDelete: onDeleteDialog,
    defaultValues,
  } = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const { showToast } = useToast();

  const [isCreateIdpLoading, setIsCreateIdpLoading] = useState<boolean>(false);
  const [isUpdateIdpLoading, setIsUpdateIdpLoading] = useState<boolean>(false);

  const { getValues: getAccountFormValues, watch: watchAccountForm } = useFormContext();

  const { FormBody, reset, isCreatingIdp, isFormDirty, handleSubmit } = useSetupIdpForm({
    defaultValues,
  });

  const { currentData: idpData } = useFetchIdpQuery();
  const [createIdp] = useCreateIdpMutation();
  const [updateIdp] = useUpdateIdpMutation();

  const onClose = (event?: unknown, reason?: string) => {
    if (reason && isCreateIdpLoading && ['backdropClick', 'escapeKeyDown'].includes(reason)) {
      return;
    }
    onCloseDialog();
    reset();
  };

  const accountId = watchAccountForm('id');

  const onDelete = async () => {
    onDeleteDialog?.();
    onCloseDialog();
  };

  useEffect(() => {
    const formData = transformIdpResponseToFormState(idpData);
    if (!idpData) {
      reset(defaultFormState);
    } else {
      reset(formData);
    }
  }, [idpData, reset]);

  const onSave = async (formData: IdpFormState) => {
    let isSuccess = true;
    if (isCreatingIdp) {
      setIsCreateIdpLoading(true);
      try {
        await createIdp(await transformFormStateToIdpRequestBody(formData)).unwrap();
      } catch (exception: any) {
        isSuccess = false;
        showToast.error(exception?.data?.message);
      } finally {
        setIsCreateIdpLoading(false);
      }
    } else {
      setIsUpdateIdpLoading(true);
      try {
        await updateIdp(
          await transformFormStateToIdpRequestBody(
            formData,
            getAccountFormValues(FormFields.SsoEnabled),
          ),
        ).unwrap();
      } catch (exception: any) {
        isSuccess = false;
        showToast.error(exception?.data?.message);
      } finally {
        setIsUpdateIdpLoading(false);
      }
    }
    if (isSuccess) {
      onSaveDialog(
        isCreatingIdp
          ? t(
              'phone_system:containers.account.submodule.settings.section.sso.setup_idp.setup_success',
            )
          : t(
              'phone_system:containers.account.submodule.settings.section.sso.setup_idp.edit_success',
            ),
      );
    }
  };

  const onError: SubmitErrorHandler<any> = () => showToast.error();

  const handleDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onClose();
        break;
      case 'saveClicked':
        handleSubmit(onSave, onError)();
        break;
      default:
        break;
    }
  };

  return (
    <Dialog
      hasTitle={!isCreateIdpLoading}
      open={open}
      {...(!isCreateIdpLoading && {
        renderActions: (
          <DialogActions
            cancelLabel={t('common:cancel')}
            {...(!isCreatingIdp && {
              leftButtons: <DeleteIdpButton accountId={accountId} onDelete={onDelete} />,
            })}
            saveButtonProps={{
              disabled: isUpdateIdpLoading || !isFormDirty,
              color: 'success',
              label: t('common:save'),
              variant: 'contained',
            }}
            onAction={handleDialogAction}
          />
        ),
      })}
      title={
        isCreatingIdp
          ? t(
              'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.title',
            )
          : t(
              'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.edit_title',
            )
      }
      type={DialogType.XLarge}
      onClose={onClose}
    >
      {!isCreateIdpLoading ? (
        <>
          <Subtitle>
            {t(
              'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.subtitle',
            )}
          </Subtitle>
          <FormBody />
        </>
      ) : (
        <Loading
          hasMargin
          hasLargeProgress
          label={t(
            'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.create_idp_loading',
          )}
        />
      )}
    </Dialog>
  );
};

export default SetupIdpDialog;
