import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import defaultProps from './default';
import { HeaderIconProps as Props } from './definition';

const HeaderIcon: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { name, color = defaultProps.color, size = defaultProps.size } = props;

  return (
    <Icon
      classes={{ root: 'MuiSvgIcon-removable-table-header' }}
      name={name}
      color={color}
      size={size}
      data-test-id="shared-removable-table-header-icon"
    />
  );
};

export default HeaderIcon;
