import i18next from 'i18next';
import { InteractionBarElementType } from './definition';

const translations = () => ({
  elements: {
    [InteractionBarElementType.DUPLICATE]: {
      label: i18next.t(
        `phone_system:containers.callflows.interaction_bar.elements.duplicate.label`,
      ),
    },
    [InteractionBarElementType.EDIT]: {
      label: i18next.t(`phone_system:containers.callflows.interaction_bar.elements.edit.label`),
    },
    [InteractionBarElementType.REDO]: {
      label: i18next.t(`phone_system:containers.callflows.interaction_bar.elements.redo.label`),
    },
    [InteractionBarElementType.UNDO]: {
      label: i18next.t(`phone_system:containers.callflows.interaction_bar.elements.undo.label`),
    },
    [InteractionBarElementType.ZOOM_IN]: {
      label: i18next.t(`phone_system:containers.callflows.interaction_bar.elements.zoom_in.label`),
    },
    [InteractionBarElementType.ZOOM_OUT]: {
      label: i18next.t(`phone_system:containers.callflows.interaction_bar.elements.zoom_out.label`),
    },
  },
});

export default translations;
