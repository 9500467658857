import { CallFlowActionInterface } from 'models/Callflow/definition';
import { FunctionComponent, memo } from 'react';
import Action from './components/Action';
import defaultProps from './default';
import { Props } from './definition';

const Actions: FunctionComponent<Props> = memo(
  (props: Props): JSX.Element => {
    const { actions }: Props = { ...defaultProps, ...props };
    return (
      <>
        {actions.map((action: CallFlowActionInterface) => (
          <Action key={action.label} actionData={action} />
        ))}
      </>
    );
  },
);

export default Actions;
