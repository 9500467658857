import Icon from 'shared/components/Icon';
import { SHARE_COLUMN_KEYWORD } from 'shared/components/Table';
import { TableCellShareIconProps as Props } from './definition';

const TableCellShareIcon = ({ data, id }: Props) =>
  data?.find(
    (data: Record<string, any>) => data.id === id && (!!data.shared_vmbox || !!data.shared_box),
  ) ? (
    <Icon data-test-id={`${SHARE_COLUMN_KEYWORD}-${id}`} name="check" size={22} />
  ) : (
    <></>
  );

export default TableCellShareIcon;
