import { CallFlowKey } from 'models/Callflow/definition';
import { FunctionComponent } from 'react';
import AgentAvailabilityKeyDialog from './AgentAvailabilityKeyDialog';
import MatchCallerIdKeyDialog from './MatchCallerIdKeyDialog';
import MenuKeyDialog from './MenuKeyDialog';
import TimeOfDayKeyDialog from './TimeOfDayKeyDialog';
import WaitTimeKeyDialog from './WaitTimeKeyDialog';

export const DIALOG_COMPONENTS: Record<string, FunctionComponent<any>> = {
  [CallFlowKey.AGENT_AVAILABILITY]: AgentAvailabilityKeyDialog,
  [CallFlowKey.MATCH_CALLER_ID]: MatchCallerIdKeyDialog,
  [CallFlowKey.MENU]: MenuKeyDialog,
  [CallFlowKey.TIME_OF_DAY]: TimeOfDayKeyDialog,
  [CallFlowKey.WAIT_TIME]: WaitTimeKeyDialog,
};
