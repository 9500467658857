import { ACTION_PREPEND } from '../../constants';
import dialogDefaultProps from '../../default';

const defaultProps = {};

export default { ...dialogDefaultProps, ...defaultProps };

export const defaultValues = {
  action: ACTION_PREPEND,
  apply_to: 'original',
  caller_id_name_prefix: '',
  caller_id_number_prefix: '',
};
