import { TABLE_CONSTANTS } from 'shared/components/Table';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledTableCellAccountProps as Props } from './definition';

const StyledTableCellAccount = styled.div<Props>`
  ${({ canExpand, isExpanded, column, depth, theme }) => css`
    ${mixin.flex({
      justifyContent: ['button', 'edit'].includes(column) ? 'center' : 'flex-start',
    })};
    min-height: ${TABLE_CONSTANTS.HEIGHT.CELL.TBODY};

    ${canExpand &&
    css`
      cursor: pointer;
    `};

    ${column === 'button' &&
    css`
      justify-content: center;
    `};

    ${['id', 'realm'].includes(column) &&
    css`
      padding-left: ${TABLE_CONSTANTS.PADDING.SORT};
    `};

    ${depth > 0 &&
    css`
      background-color: ${theme.core.background.grey.light.list};

      ${column === 'name' &&
      css`
        padding-left: ${depth * 24}px;
      `}
    `}

    > div {
      ${mixin.flex()};
      ${mixin.transition({ property: 'transform', duration: 'medium' })};
      width: ${TABLE_CONSTANTS.PADDING.SORT};
      transform: ${`rotate(${isExpanded ? 90 : 0}deg)`};
    }

    > span {
      ${canExpand && isExpanded && mixin.hover()};
    }
  `}
`;

export default StyledTableCellAccount;
