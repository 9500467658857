import { FunctionComponent, useState } from 'react';
import DrawerContentLower from './components/DrawerContentLower';
import DrawerContentUpper from './components/DrawerContentUpper';
import { DrawerContentProps as Props } from './definition';

const DrawerContent: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { callflowId, quickViewCallflowIds, onQuickViewClicked, onEditClicked } = props;
  const [searchFilter, setSearchFilter] = useState<string>('');

  return (
    <>
      <DrawerContentUpper setSearchFilter={setSearchFilter} />
      <DrawerContentLower
        callflowId={callflowId}
        searchFilter={searchFilter}
        quickViewCallflowIds={quickViewCallflowIds}
        onQuickViewClicked={onQuickViewClicked}
        onEditClicked={onEditClicked}
      />
    </>
  );
};

export default DrawerContent;
