import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import { FormFields } from '../../fields';

const EmailClaimInput = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const watchUseNameIdForEmail = watch(FormFields.UseNameIdForEmail);

  if (watchUseNameIdForEmail) {
    return null;
  }

  return (
    <HookFormInputWrapper name={FormFields.EmailClaim}>
      {({ ref, isDirty, feedback, ...formProps }) => (
        <LabeledInput
          id="input-email-claim"
          isDirty={isDirty}
          feedback={feedback}
          label={t(
            'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.email_claim.label',
          )}
          inputProps={{
            ...formProps,
            placeholder: t(
              'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.email_claim.label',
            ),
          }}
          tooltip={t(
            'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.email_claim.tooltip',
          )}
        />
      )}
    </HookFormInputWrapper>
  );
};

export default EmailClaimInput;
