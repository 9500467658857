import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { getValue } from '../utility';
import { StyledHeaderProps as Props } from './definition';

export const CONSTANTS = {
  HEIGHT: 36,
};

const StyledHeader = styled.header<Props>`
  ${({ isTextLeft, isVertical, height, theme, width }) => css`
    ${mixin.flex()};
    padding: 0 12px;
    user-select: none;

    > span {
      ${mixin.flex({ justifyContent: 'space-between' })};
      width: 100%;

      h3 {
        width: 100%;
        height: ${CONSTANTS.HEIGHT}px;
        margin: 0;
        line-height: 40px;
        font-weight: normal;
        text-align: ${isTextLeft ? 'left' : 'center'};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      span {
        ${mixin.flex()};
        width: 16px;
        cursor: pointer;
        transform: translateY(2px);

        svg {
          ${mixin.transition([
            { property: 'fill' },
            { property: 'transform', duration: 'fastest' },
          ])};
          position: relative;

          --offset: 5;
          &:first-of-type {
            left: calc(var(--offset) * 1px);
          }

          &:last-of-type {
            left: calc(var(--offset) * -1px);
          }
        }

        &:hover svg {
          fill: ${theme.core.color.red};
        }
      }
    }

    > svg {
      ${mixin.transition({ property: 'fill' })};
      margin: 3px 0 0 5px;
      cursor: pointer;
      transform: rotate(45deg);

      &:hover {
        fill: ${theme.core.color.red};
      }
    }

    ${isVertical &&
    css`
      --width: ${getValue(height ?? width)};
      width: var(--width);
      height: ${CONSTANTS.HEIGHT}px;
      --translate: calc((var(--width) - ${CONSTANTS.HEIGHT}px) / 2);
      transform: translate(calc(var(--translate) * -1), var(--translate)) rotate(-90deg);
      padding: 0 10px;

      > span {
        h3 {
          ${mixin.transition({ property: 'color' })};
          cursor: pointer;
          line-height: ${CONSTANTS.HEIGHT}px;
          text-align: center;
        }

        span {
          transform: rotate(-90deg) translate(1px, -1px);
        }

        &:hover {
          h3 {
            color: ${theme.core.color.red};
          }

          > span svg {
            fill: ${theme.core.color.red};
          }
        }
      }

      > svg {
        order: -1;
        margin: 0;
      }
    `}
  `}
`;

export default StyledHeader;
