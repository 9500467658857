import MuiRadioGroup, { RadioGroupProps as Props } from '@mui/material/RadioGroup';
import { FunctionComponent } from 'react';

export type { RadioGroupProps } from '@mui/material/RadioGroup';

const RadioGroup: FunctionComponent<Props> = (props: Props): JSX.Element => (
  <MuiRadioGroup {...props} />
);

export default RadioGroup;
