// SMS Mutations
import { SMS } from 'api/sms';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const smsMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createSMS: builder.mutation<SMS, Partial<CreateSMSParams>>({
      query: ({ body = {} }) => {
        return {
          url: SMS.Create(),
          method: RequestMethod.Put,
          responseHandler,
          body: { data: body },
        };
      },
      invalidatesTags: [{ type: TAGS.SMS, id: 'LIST' }],
    }),
    patchSMS: builder.mutation<SMS, UpdateSMSParams>({
      query: ({ id, body }) => ({
        url: SMS.Patch(id),
        method: RequestMethod.Patch,
        responseHandler,
        body: { data: body },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.SMS, id },
        { type: TAGS.SMS, id: 'LIST' },
      ],
    }),
    updateSMS: builder.mutation<SMS, UpdateSMSParams>({
      query: ({ id, body }) => ({
        url: SMS.Update(id),
        method: RequestMethod.Post,
        responseHandler,
        body: { data: body },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.SMS, id },
        { type: TAGS.SMS, id: 'LIST' },
      ],
    }),
    deleteSMS: builder.mutation<SMS, ByIdParam>({
      query: ({ id }) => ({
        url: SMS.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: [{ type: TAGS.SMS, id: 'LIST' }],
    }),
  }),
});
