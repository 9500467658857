import { useTranslation } from 'react-i18next';
import { ChildCountProps as Props } from './definition';
import StyledChildCount from './style';

export const ChildCount = ({ isExpanded, count }: Props) => {
  const { t } = useTranslation();

  return count > 0 ? (
    <StyledChildCount $isExpanded={isExpanded}>
      ({count} {count === 1 ? t('common:child') : t('common:children')})
    </StyledChildCount>
  ) : (
    <></>
  );
};
