import throttle from 'lodash/throttle';
import { BaseSyntheticEvent, RefObject, useEffect, useMemo, useState } from 'react';

const noop = () => {};

/**
 * @description This hook was discovered while researching Material UI's future ScrollSpy implementation.
 *              https://gist.github.com/iDVB/a041da210605f05e0b36ac03ed403c00
 *
 * @param callback Callback function
 * @param parentRef Parent element reference
 * @param wait Throttle delay/wait (in milliseconds)
 */
const useThrottledOnScroll = ({
  callback,
  parentRef: { current },
  wait = 50,
}: {
  callback: (e: BaseSyntheticEvent) => void;
  parentRef: RefObject<HTMLDivElement>;
  wait?: number;
}) => {
  const [validCurrent, setValidCurrent] = useState<HTMLDivElement>();
  const throttledCallback: any = useMemo(() => (callback ? throttle(callback, wait) : noop), [
    callback,
    wait,
  ]);

  useEffect(() => {
    if (current) {
      setValidCurrent(current);
    }
  }, [current]);

  useEffect(() => {
    if (throttledCallback === noop) {
      return undefined;
    }

    validCurrent?.addEventListener('scroll', throttledCallback);

    return () => {
      validCurrent?.removeEventListener('scroll', throttledCallback);
      throttledCallback.cancel?.();
    };
  }, [validCurrent, throttledCallback]);
};

export default useThrottledOnScroll;
