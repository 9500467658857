import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { EditFormProps } from 'apps/PhoneSystem/definition';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { ADD_KEY } from 'constant';
import isEmpty from 'lodash/isEmpty';
import {
  schema,
  useDeleteFaxboxMutation,
  useFetchFaxboxByIdQuery,
  useUpdateFaxboxMutation,
} from 'models/Faxbox';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { defaultValues } from './default';
import { FormInput } from './definition';
import Form from './Form';

const Edit = ({ id, handleSaveSuccess, handleDeleteSuccess, handleCancel }: EditFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { data, isLoading } = useFetchFaxboxByIdQuery({ id });

  const [updateFaxbox] = useUpdateFaxboxMutation();
  const [deleteFaxbox] = useDeleteFaxboxMutation();

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const {
    formState: { isDirty: isPageDirty },
    handleSubmit,
    reset,
    setError,
  } = formMethods;

  const handleDelete = async () => {
    try {
      await deleteFaxbox({ id }).unwrap();
      handleDeleteSuccess?.();
    } catch (exception) {
      showToast.delete.error();
    }
  };

  const onSubmit: SubmitHandler<FormInput> = async (body) => {
    try {
      if (isEmpty(body.caller_id)) {
        delete body.caller_id;
      }
      await updateFaxbox({ id, body }).unwrap();
      handleSaveSuccess?.();
    } catch (exception) {
      showErrorMessage({ isFromException: true, errors: exception, setError });
    }
  };

  const onSave = handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }));

  // Populates form components once data is loaded
  useEffect(() => {
    reset(enhancedFormUtility.transformDataToFormData(data, defaultValues));
  }, [data, reset]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditForm
      isPageDirty={isPageDirty}
      entityLabel={t('phone_system:containers.faxes.label')}
      entityName={data?.name}
      formMethods={formMethods}
      onCancel={handleCancel}
      onSave={onSave}
      {...(id !== ADD_KEY ? { onDelete: handleDelete } : {})}
    >
      <Form />
    </EditForm>
  );
};

export default Edit;
