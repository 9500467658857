import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { ADD_KEY } from 'constant';
import uniqBy from 'lodash/uniqBy';
import { useFetchConferencesByOwnerIdQuery } from 'models/Conference';
import { useFormContext } from 'react-hook-form';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import { FormInput } from '../../../definition';
import { columns } from './utility';

type Props = TabPanelProps<FormInput>;

const ConferenceListView = ({ id = ADD_KEY }: Props) => {
  const { getValues } = useFormContext();
  const { data, isLoading } = useFetchConferencesByOwnerIdQuery({ id });

  if (isLoading) {
    return <Loading />;
  }

  const tableData = uniqBy(
    [...Object.values(getValues(`conference`)), ...(data as any)],
    'id',
  ).filter((item) => !getValues(`conference.${item.id}._toDelete`));

  return <Table hasActionRow={false} columns={columns()} data={tableData || []} />;
};

export default ConferenceListView;
