import { AppMeta } from 'apps/shared/definition';

export const meta: AppMeta = {
  isIframeApp: true,
  i18n: {
    label: 'Call Logs',
  },
  icon: 'cdrs',
  name: 'cdrs',
  slug: 'cdrs',
};
