import useThrottledOnScroll from 'apps/shared/hooks/useThrottledOnScroll';
import kebabCase from 'lodash/kebabCase';
import {
  BaseSyntheticEvent,
  Children,
  cloneElement,
  isValidElement,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DirtyIcon } from 'shared/components/Labeled';
import { StyledTab, StyledTabPanel, StyledTabPanels, StyledTabs } from './components';
import { TabItem, useTabsProps as Props } from './definition';

export type { TabPanelProps } from './definition';

/**
 * @description Creates tabs from given items and options.
 *
 * @param [items] List of items to define the tabs
 * @property label The Label shown on each tab
 * @property hash A hash used to jump to a section
 * @property component The component to be shown for this tab / section
 * @param options Options for the tabs
 *
 * @returns Tabs object
 */
const useTabs = ({ items, options: { hasFormActionRow } = {} }: Props) => {
  const { t } = useTranslation();
  const parentRef = useRef<HTMLDivElement>(null);
  const sectionRefs = useRef<Array<HTMLDivElement>>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const on = {
    change: (e: BaseSyntheticEvent, i: number) => {
      const sectionRef = sectionRefs.current?.[i];

      if (parentRef.current && sectionRef) {
        parentRef.current.scrollTop = sectionRef.offsetTop;
      }

      setTabIndex(i);
    },
  };

  const Tabs = (
    <StyledTabs orientation="vertical" value={tabIndex} onChange={on.change}>
      {items.map(({ isDirty = false, isError = false, label }: TabItem) => (
        <StyledTab
          key={kebabCase(label)}
          label={label}
          icon={
            <DirtyIcon
              isInline
              isDirty={isDirty}
              isError={isError}
              tooltip={
                isError ? t('common:dirty_icon.error.tab') : t('common:dirty_icon.default.tab')
              }
            />
          }
        />
      ))}
    </StyledTabs>
  );

  const TabPanels = (
    <StyledTabPanels ref={parentRef} $hasFormActionRow={hasFormActionRow}>
      {items.map(({ component, description, hash }, i: number) => (
        <StyledTabPanel
          $hasDescription={Boolean(description)}
          key={hash}
          ref={(el: HTMLDivElement) => {
            sectionRefs.current[i] = el;
          }}
        >
          {Children.map(component, (child) =>
            isValidElement(child) ? cloneElement(child, { ...child.props }) : child,
          )}
        </StyledTabPanel>
      ))}
    </StyledTabPanels>
  );

  const updateSection = ({ target }: BaseSyntheticEvent, sectionPosition: Array<number>) => {
    if (target && sectionPosition.length > 0) {
      const positionScroll = Math.floor(target.scrollTop + target.offsetHeight / 2);
      const index = sectionPosition.findIndex((position: number) => position > positionScroll) - 1;
      setTabIndex(index >= 0 ? index : sectionPosition.length - 1);
    }
  };

  useThrottledOnScroll({
    callback: (e: BaseSyntheticEvent) =>
      updateSection(
        e,
        sectionRefs.current.filter((section) => !!section).map((section) => section.offsetTop),
      ),
    parentRef,
  });

  return { Tabs, TabPanels, activeTab: tabIndex, resetActiveTab: () => setTabIndex(0) };
};

export default useTabs;
