import { themeColor } from 'theme/local';
import theme from 'theme/mui/core';

export const CONSTANTS = {
  WIDTH: 310,
  HEIGHT: 235,
};

export default {
  width: CONSTANTS.WIDTH,
  minWidth: CONSTANTS.WIDTH,
  height: CONSTANTS.HEIGHT,
  cursor: 'pointer',
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    backgroundColor: themeColor.brio.action.hover,
  },
  '&.MuiCard-queue-selected': {
    backgroundColor: themeColor.brio.action.selected,
  },
};
