import Edit from 'apps/PhoneSystem/containers/Voicemails/Edit';
import VoicemailSelect from 'apps/PhoneSystem/shared/VoicemailSelect';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogLink, DialogType } from 'shared/components/Dialog';
import { useToast } from 'shared/hooks/useToast';
import { onEmbeddedEditSaveSuccess } from '../../../utility';
import { VoicemailSelectWrapper as Props } from './definition';

const VoicemailSelectWrapper: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { control, id }: Props = {
    ...props,
  };
  const { t } = useTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { showToast } = useToast();

  return (
    <>
      <div role="row" className="split split-with-link">
        <div role="cell">
          <VoicemailSelect
            name="id"
            control={control}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.voicemail.add.label',
            )}
            hasDirtyIcon
          />
        </div>
        <div role="cell">
          {id && (
            <DialogLink
              onClick={() => setIsEditDialogOpen(true)}
              text={t(
                'phone_system:containers.callflows.callflow_action_dialog.voicemail.edit.label',
              )}
            />
          )}
        </div>
      </div>
      <Dialog
        open={isEditDialogOpen}
        title={t('phone_system:containers.callflows.callflow_action_dialog.voicemail.edit.title')}
        type={DialogType.CallflowActions}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <Edit
          id={id}
          handleSaveSuccess={() => onEmbeddedEditSaveSuccess({ showToast, setIsEditDialogOpen })}
          handleCancel={() => setIsEditDialogOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default VoicemailSelectWrapper;
