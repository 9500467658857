import { FunctionComponent, useEffect, useState } from 'react';
import Icon from 'shared/components/Icon';
import * as utility from 'shared/utility';
import defaultProps from './default';
import { InputMessageProps as Props } from './definition';
import StyledInputMessage from './style';

export type { InputMessageProps, MessageType } from './definition';

const InputMessage: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { type, message, icon, ...rest }: Props = { ...defaultProps, ...props };
  const [messages, setMessages] = useState<string[]>([]);

  useEffect(() => {
    message && setMessages(typeof message === 'string' ? [message] : message);
  }, [message]);

  const ICON_NAME: { [key: string]: string } = {
    error: 'error-filled',
    secondary: 'error-filled',
    success: 'radiobutton-checked-filled',
    warning: 'warning-filled',
  };

  const getNamedIcon = (name: string): JSX.Element => <Icon key={name} name={name} size={14} />;

  return (
    <StyledInputMessage type={type} {...rest}>
      {messages.map((message: string, i: number) => (
        <div key={utility.generateKey(i, message)}>
          <>
            {!!icon ? (
              <>{typeof icon === 'string' ? getNamedIcon(icon) : icon}</>
            ) : (
              getNamedIcon(ICON_NAME[type])
            )}
          </>
          <span>{message}</span>
        </div>
      ))}
    </StyledInputMessage>
  );
};

export default InputMessage;
