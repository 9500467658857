import { FailoverMethod } from './definition';

const defaultProps = {};

export default defaultProps;

export const defaultValues = {
  id: '',
  used_by: '',
  prepend: {
    name: '',
    enabled: false,
  },
  e911: {
    customer_name: '',
    locality: '',
    notification_contact_emails: '',
    postal_code: '',
    region: '',
    street_address: '',
  },
  cnam: {
    inbound_lookup: false,
    display_name: '',
  },
  failover: {
    method: FailoverMethod.inactive,
    value: '',
  },
  sms_owner: '',
  used_by_callflow_id: '',
};
