/**
 * @name createNumbersSet
 * @description Creates a Set of phone numbers by combining data from callflowData with availableDIDS
 *
 * @param callflowData - An array of callflow data
 * @param availableDIDS - An array of available DID numbers
 *
 * @returns A Set containing the combined phone numbers and availableDIDS
 */
export const createNumbersSet = (callflowData: any, availableDIDS: string[]): Set<string> => {
  let numbers: string[] = [];

  if (callflowData?.[0]?.numbers) {
    numbers = callflowData[0].numbers.filter((number: string) => number.startsWith('+'));
  }

  return new Set([...numbers, ...availableDIDS]);
};
