import { FunctionComponent } from 'react';
import defaultProps from './default';
import { StandardRowProps as Props } from './definition';
import StyledStandardRow from './style';

const StandardRow: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onDelete, onUpdate }: Props = { ...defaultProps, ...props };
  return <StyledStandardRow cellCount={2} data={data} onUpdate={onUpdate} onDelete={onDelete} />;
};

export default StandardRow;
