import Box from 'shared/components/Box';
import * as utility from 'shared/utility';
import { WidthType } from 'shared/utility/definition';
import styled, { css } from 'styled-components';

const StyledComplexPasswordValidationFeedback = styled(Box)`
  ${({ theme }) => css`
    width: ${utility.getWidth({
      width: {
        input: 'large' as WidthType,
        label: 'none' as WidthType,
      },
    })};
    margin: ${theme.spacing(2.5, 0, 5.5, 36)};
  `};
`;

export default StyledComplexPasswordValidationFeedback;
