import VersionTag from 'apps/shared/components/VersionTag';
import { ContainerMeta } from 'apps/shared/definition';
import { FunctionComponent } from 'react';
import DashboardPanelAccount from './components/DashboardPanelAccount';
import DashboardPanelKnowledge from './components/DashboardPanelKnowledge';
import DashboardPanelNumbers from './components/DashboardPanelNumbers';
import DashboardPanelReporting from './components/DashboardPanelReporting';
import DashboardPanelSeats from './components/DashboardPanelSeats';
import DashboardPanelStatus from './components/DashboardPanelStatus';
import StyledDashboard from './style';

export const meta: ContainerMeta = {
  // t('phone_system:containers.dashboard.label')
  label: 'phone_system:containers.dashboard.label',
  icon: 'speed',
  slug: 'dashboard',
};

const Dashboard: FunctionComponent = (): JSX.Element => (
  <StyledDashboard>
    <div>
      <DashboardPanelAccount />
      <DashboardPanelStatus />
      <DashboardPanelSeats />
      <DashboardPanelNumbers />
      <DashboardPanelReporting />
      <DashboardPanelKnowledge />
    </div>
    <div>
      <VersionTag />
    </div>
  </StyledDashboard>
);

export default Dashboard;
