import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledUpperActionRowProps as Props } from './definition';

const CONSTANTS = {
  HEIGHT: '70px',
};

export { CONSTANTS as UPPER_ACTION_ROW_CONSTANTS };

const StyledUpperActionRow = styled(Box)<Props>`
  ${({ theme }) => css`
    ${mixin.flex({ gap: theme.spacing(2.66), justifyContent: 'flex-end' })};
    height: ${CONSTANTS.HEIGHT};
    padding: ${theme.spacing(0, 5)};
    border-bottom: 1px solid ${theme.core.border.color};

    div {
      margin: 0;
    }
  `}
`;

export default StyledUpperActionRow;
