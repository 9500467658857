import { Template } from 'apps/AdvancedProvisioner/shared/components/DynamicForm/definition';
import {
  convertAccountSettingsToTemplate,
  getInheritTemplate,
} from 'apps/AdvancedProvisioner/utility';
import merge from 'lodash/merge';
import { useFetchAccountSettingsQuery } from 'models/AdvancedProvisioner';
import { useMemo } from 'react';
import { useGetInheritTemplateParams } from '../definition';

/** A hook return the device template that consider inheritance from account settings */
export const useGetInheritTemplate = ({
  deviceTemplate,
  deviceTemplateCluster,
}: useGetInheritTemplateParams): Template | undefined => {
  const { data: { settings: accountSettings } = {} } = useFetchAccountSettingsQuery();

  return useMemo(() => {
    if (!deviceTemplate || !deviceTemplateCluster || !accountSettings) {
      return undefined;
    }

    // Convert tab arrays to objects
    const accountTemplate = convertAccountSettingsToTemplate(accountSettings);

    const deviceFinalTemplate = getInheritTemplate(deviceTemplate, deviceTemplateCluster);

    // Account template (converted from account settings) has higher priority than device template
    return merge({}, deviceFinalTemplate, accountTemplate) as Template;
  }, [deviceTemplate, deviceTemplateCluster, accountSettings]);
};
