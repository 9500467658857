import { Link, Text } from './components';
import { TableCellE911ValueProps as Props } from './definition';

const TableCellE911Value = ({ isSystemNumber, number, text }: Props) =>
  number && isSystemNumber ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link isMissing={!text.value} number={number} text={text.value || text.missing} />
  ) : (
    <Text text={text} />
  );

export default TableCellE911Value;
