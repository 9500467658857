import { themeColor } from '../..';
import theme from '../core';

export const CONSTANTS = {
  BORDER: 1,
  HEIGHT_INNER: 48,
  get HEIGHT() {
    return `${this.HEIGHT_INNER + this.BORDER}px`;
  },
};

export default {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 0,
      height: 'auto',
      minHeight: CONSTANTS.HEIGHT_INNER,
      margin: theme.spacing(0, 4),
      padding: theme.spacing(2, 2, 1),
      color: theme.palette.common.black,
      fontSize: 17,
      fontWeight: 500,
      letterSpacing: 0,
      opacity: 1,
      overflowWrap: 'anywhere',
      textTransform: 'none',
      transition: theme.transitions.create(['color'], {
        duration: theme.transitions.duration.standard,
      }),
      '&.Mui-selected, &:not(.Mui-selected):hover': {
        color: themeColor.whitelabel.primary.color,
      },
      '&.Mui-selected': {
        fontWeight: 600,
        letterSpacing: '-0.0125em',
      },
    },
  },
};
