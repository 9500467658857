import { RouterLink } from 'apps/shared/components/RouterLink';
import { LinkProps as Props } from './definition';
import { getTo } from './utility';

const Link = ({ isMissing, number, text }: Props) => (
  <RouterLink target="_blank" to={getTo(number)} {...(isMissing ? { className: 'error' } : {})}>
    {text}
  </RouterLink>
);

export default Link;
