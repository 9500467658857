import { style } from 'apps';
import styled, { css } from 'styled-components';
import { StyledDashboardProps as Props } from './definition';

const StyledDashboard = styled.div<Props>`
  ${(props) => css`
    ${style}
    background-color: ${props.theme.core.background.grey.light.text};

    > div:first-of-type {
      display: grid;
      grid-gap: 20px;
      grid-template: 120px 380px 320px 280px 380px 320px / 1fr;
      grid-template-areas:
        'status'
        'account'
        'seats'
        'numbers'
        'reporting'
        'knowledge';

      > div {
        min-width: 640px;

        &:nth-of-type(1) {
          grid-area: account;
        }

        &:nth-of-type(2) {
          grid-area: status;
        }

        &:nth-of-type(3) {
          grid-area: seats;
        }

        &:nth-of-type(4) {
          grid-area: numbers;
        }

        &:nth-of-type(5) {
          grid-area: reporting;
        }

        &:nth-of-type(6) {
          grid-area: knowledge;
        }
      }

      @media screen and (min-width: 1024px) {
        grid-template: 120px 380px 320px 280px 320px / repeat(3, 1fr);
        grid-template-areas:
          'status status status'
          'account account account'
          'seats seats numbers'
          'reporting reporting reporting'
          'knowledge knowledge knowledge';

        > div {
          min-width: 0;
        }
      }

      @media screen and (min-width: 1360px) {
        grid-template: 120px 130px 250px 480px / repeat(12, 1fr);
        grid-template-areas:
          'status status status status status status seats seats seats seats seats seats'
          'account account account account account account seats seats seats seats seats seats'
          'account account account account account account numbers numbers numbers numbers numbers numbers'
          'reporting reporting reporting reporting reporting reporting reporting reporting knowledge knowledge knowledge knowledge';
      }

      @media screen and (min-width: 1880px) {
        grid-template: 120px 380px 480px / repeat(12, 1fr);
        grid-template-areas:
          'status status status status status status seats seats seats seats numbers numbers'
          'account account account account account account seats seats seats seats numbers numbers'
          'reporting reporting reporting reporting reporting reporting reporting reporting reporting knowledge knowledge knowledge';
      }
    }
  `};
`;

export default StyledDashboard;
