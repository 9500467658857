import HorizontalNav from 'apps/shared/components/HorizontalNav';
import Page from 'apps/shared/components/Page';
import { Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import Error from 'shared/components/Error';
import Loading from 'shared/components/Loading';
import { AccountSettings } from './containers/AccountSettings';
import { metaAccountSettings } from './containers/AccountSettings/meta';
import { Devices } from './containers/Devices';
import { metaDevices } from './containers/Devices/meta';
import { AdvancedProvisionerProps as Props } from './definition';
import { StyledAdvancedProvisioner } from './style';

export const AdvancedProvisioner = ({ label }: Props): JSX.Element => {
  const { pathname } = useLocation();
  const hasHorizontalNav =
    pathname.endsWith(metaDevices.slug) || pathname.includes(metaAccountSettings.slug);

  const routes = useRoutes([
    { path: '/', element: <Navigate to={`./${metaDevices.slug}`} replace /> },
    { path: `/${metaDevices.slug}/*`, element: <Devices /> },
    { path: `/${metaAccountSettings.slug}/*`, element: <AccountSettings /> },
    { path: '*', element: <Error /> },
  ]);

  return (
    <Page label={label}>
      <StyledAdvancedProvisioner $hasHorizontalNav={hasHorizontalNav}>
        {hasHorizontalNav && <HorizontalNav hasIndent items={[metaDevices, metaAccountSettings]} />}
        <Suspense fallback={<Loading />}>{routes}</Suspense>
      </StyledAdvancedProvisioner>
    </Page>
  );
};
