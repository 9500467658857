import styled, { css } from 'styled-components';
import StyledLabeled from '../../style';
import { StyledLabeledTextProps as Props } from './definition';

const StyledLabeledText = styled(StyledLabeled)<Props>`
  ${({ hasNoWrap, hasRightMargin, theme }) => css`
    width: auto;

    ${hasRightMargin &&
    css`
      margin-right: ${theme.spacing(3)};
    `}

    > label {
      flex-grow: 0;
      flex-shrink: 0;
      margin-top: 0;
    }

    > div > div:first-child {
      margin: 2px 0 0;

      ${hasNoWrap
        ? css`
            white-space: nowrap;
          `
        : css`
            word-wrap: break-word;
          `}
    }
  `}
`;

export default StyledLabeledText;
