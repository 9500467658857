import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useUpdateFieldsParams as Params } from './definition';
import { updateFields } from './utility';

const useUpdateFields = ({
  hasUsersExtension = false,
  data,
  selectionFieldArray,
  selectionFieldArray: { fields },
  updateFieldsCore,
}: Params) => {
  const [hasFields, setHasFields] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(fields)) {
      setHasFields(true);
    }
  }, [fields]);

  useEffect(() => {
    updateFields({
      hasFields,
      hasUsersExtension,
      data: data.devices,
      selectionFieldArray,
      updateFieldsCore,
    });
  }, [hasFields, hasUsersExtension, data.devices, updateFieldsCore]); // Including `selectionFieldArray` will result in loop

  useEffect(() => {
    updateFields({
      hasFields,
      hasUsersExtension,
      data: data.groups,
      selectionFieldArray,
      updateFieldsCore,
    });
  }, [hasFields, hasUsersExtension, data.groups, updateFieldsCore]); // Including `selectionFieldArray` will result in loop

  useEffect(() => {
    updateFields({
      hasFields,
      hasUsersExtension,
      data: data.users,
      selectionFieldArray,
      updateFieldsCore,
    });
  }, [hasFields, hasUsersExtension, data.users, updateFieldsCore]); // Including `selectionFieldArray` will result in loop
};

export default useUpdateFields;
