import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledTabCallflowProps as Props } from './definition';

const StyledTabCallflow = styled.div<Props>`
  ${(props) => css`
    ${mixin.borderRadius({ radius: 'half' })};
    display: grid;
    --gap: 30px; // TODO: Add to constants
    grid-template: ${props.hasCallflow ? 'var(--gap) 40px 1fr / 1fr var(--gap)' : '1fr / 1fr'};
    height: calc(100% - var(--gap) * 2);
    margin: var(--gap);
    background-color: ${props.theme.core.background.grey.light.color};
    border: 1px solid ${props.theme.core.border.color};

    > div {
      grid-area: 2 / 1 / 3 / 2;
      justify-self: flex-end;
      z-index: 100;
    }

    > article {
      grid-area: 1 / 1 / 4 / 3;
      height: 100%;
      padding: calc(var(--gap) * 2) var(--gap) var(--gap);
      overflow: auto;
    }

    > span {
      ${mixin.font({ size: 18 })};
      justify-self: center;
      align-self: center;
    }
  `}
`;

export default StyledTabCallflow;
