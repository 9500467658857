// Notification/index.ts
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { notificationMutations } from './Mutations';
import { notificationQueries } from './Queries';

export const schema = (t: any) =>
  Joi.object({
    id: Joi.string().required(),
  })
    .unknown(true)
    .messages(messages());

export const { useFetchNotificationByIdQuery } = notificationQueries;

export const {
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useUpdateNotificationMutation,
} = notificationMutations;
