/* eslint-disable no-console */
import { FunctionComponent, useEffect, useState } from 'react';
import defaultProps, { defaultState } from './default';
import { AppIconImgProps, AppIconProps as Props } from './definition';
import StyledAppIcon from './style';

export { iconList } from './data';
export type { AppIconPhase, AppIconProps, AppIconSet, AppIconSubset } from './definition';

const AppIcon: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { hasShadow, isDisabled, name, set, subset, phase, size }: Props = {
    ...defaultProps,
    ...props,
  };
  const [appIcon, setAppIcon] = useState<AppIconImgProps>(defaultState);

  useEffect(() => {
    const loadAppIcon = async () => {
      await import(`./icon/${set}/${subset}/${name}.png`)
        .then(async (image) => {
          setAppIcon({ size: size || defaultState.size, src: { icon: image.default } });

          if (['alpha', 'beta'].includes(phase as string)) {
            await import(`./icon/${set}/${subset}/phase/${phase}.png`)
              .then((loadedImage) => {
                setAppIcon((appIcon: any) => ({
                  ...appIcon,
                  src: {
                    ...appIcon.src,
                    phase: loadedImage.default,
                  },
                }));
              })
              .catch((error) => {
                console.error(error.message);
              });
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    };
    loadAppIcon();

    // cancel async setAppIcon call
    return () => setAppIcon({});
  }, [name, set, size, phase, subset]);

  return (
    <StyledAppIcon
      hasShadow={hasShadow}
      isDisabled={isDisabled}
      data-test-id={`app-icon-${name}`}
      size={appIcon.size}
    >
      {appIcon.src?.phase && <img src={appIcon.src.phase} />}
      <img src={appIcon.src?.icon} />
    </StyledAppIcon>
  );
};

export default AppIcon;
