import { CallRestrictionOptionType, Classifiers, ClassifierSet } from 'definition';
import produce from 'immer';
import { cloneDeep } from 'lodash';
import { FormInput } from '../definition';

// TODO: make this method immutable
export const normalizeDataForSaving = (fromData: FormInput) => {
  const newData = cloneDeep(fromData);
  if (newData?.breakout?.enabled) {
    newData.breakout.dtmf = '1';
    delete newData.breakout.enabled;
  } else {
    delete newData.breakout;
  }

  if (newData?.breakout?.classifiers) {
    const normalized_classifiers: Record<any, any> = {};

    Object.keys(newData.breakout.classifiers).forEach((key: string) => {
      if (newData?.breakout?.classifiers?.[key] === 'deny') {
        // True values not written as they are implied
        normalized_classifiers[key] = false;
      }
    });

    newData.breakout.classifiers = normalized_classifiers;
  }

  if (typeof newData.caller_exit_key === 'string' && newData.caller_exit_key.length === 0) {
    delete newData.caller_exit_key;
  }

  delete newData.users;
  return newData;
};

export const prepareDataForForm = (apiData: any = {}): any =>
  produce(apiData, (draft: FormInput) => {
    const classifiers: Classifiers = {};
    Object.entries(draft?.breakout?.classifiers || {}).forEach(([classifierKey]) => {
      classifiers[classifierKey] = CallRestrictionOptionType.Deny;
    });

    Object.values(ClassifierSet).forEach((classifierKey) => {
      if (!Object.prototype.hasOwnProperty.call(classifiers, classifierKey)) {
        classifiers[classifierKey] = CallRestrictionOptionType.Allow;
      }
    });

    draft.breakout = { enabled: draft?.breakout?.dtmf === '1', classifiers };

    if (!draft.caller_exit_key) {
      draft.caller_exit_key = '';
    }

    if (Object.prototype.hasOwnProperty.call(apiData?.announcements || {}, 'interval')) {
      draft.announcements.interval = apiData.announcements.interval.toString();
    }

    if (Object.prototype.hasOwnProperty.call(apiData, 'agent_wrapup_time')) {
      draft.agent_wrapup_time = apiData.agent_wrapup_time.toString();
    }

    if (Object.prototype.hasOwnProperty.call(apiData, 'max_queue_size')) {
      draft.max_queue_size = apiData.max_queue_size.toString();
    }

    if (Object.prototype.hasOwnProperty.call(apiData, 'connection_timeout')) {
      draft.connection_timeout = apiData.connection_timeout.toString();
    }
  });
