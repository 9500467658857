export const CONSTANTS = {
  MARGIN: 6,
  SHADOW_OFFSET: {
    HORIZONTAL: 4,
    VERTICAL: {
      BOTTOM: 6,
      TOP: 2,
      get TOTAL() {
        return CONSTANTS.SHADOW_OFFSET.VERTICAL.TOP + CONSTANTS.SHADOW_OFFSET.VERTICAL.BOTTOM;
      },
    },
  },
};
