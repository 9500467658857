import { FunctionComponent } from 'react';
import AbsoluteMode from './AbsoluteMode';
import defaultProps from './default';
import { MatchCallerIdKeyDialogProps as Props } from './definition';
import RegexMode from './RegexMode';

const MatchCallerIdKeyDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose, parentNode }: Props = { ...defaultProps, ...props };

  const useAbsoluteMode = parentNode?.data?.use_absolute_mode;

  return useAbsoluteMode ? (
    <AbsoluteMode data={data} onSave={onSave} onClose={onClose} />
  ) : (
    <RegexMode data={data} onSave={onSave} onClose={onClose} />
  );
};

export default MatchCallerIdKeyDialog;
