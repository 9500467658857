export default {
  core: {
    background: {
      grey: {
        dark: 'rgb(84, 84, 84)', // #545454
        light: {
          border: 'rgb(218, 218, 218)', // dadada
          color: 'rgb(241, 241, 241)', // #f1f1f1
          list: 'rgb(245, 245, 245)', // f5f5f5
          text: 'rgb(248, 248, 248)', // #f8f8f8
        },
        medium: 'rgb(236, 236, 236)', // #ececec
      },
      white: {
        off: 'rgb(250, 250, 250)', // #fafafa
      },
    },
    border: {
      color: 'rgb(223, 223, 223)', // #dfdfdf
    },
    button: {
      border: 'rgb(216, 216, 216)', // #d8d8d8
      grey: 'rgb(227, 227, 227)', // #e3e3e3
    },
    color: {
      black: 'rgb(0, 0, 0)',
      black_141414: 'rgb(20, 20, 20)', // #141414
      blue: {
        default: 'rgb(0, 122, 255)', // #007aff
        draggable: 'rgb(34, 151, 255)', // #2297ff
      },
      green: {
        default: 'rgb(122, 178, 87)', // #7ab257
        dark: 'rgb(87, 142, 78)', // #578e4e
      },
      grey: {
        disabled: 'rgb(194, 194, 194)', // #c2c2c2
        light: 'rgb(134, 134, 134)', // #868686
        pewter: 'rgb(151, 151, 151)', // #979797
      },
      grey_b0b0b0: 'rgb(176, 176, 176)', // #b0b0b0
      grey_b5b5b5: 'rgb(181, 181, 181)', // #b5b5b5
      orange: 'rgb(253, 125, 65)', // #fd7d41
      red: 'rgb(229, 11, 11)', // #e50b0b
      white: 'rgb(255, 255, 255)',
      yellow: 'rgb(255, 186, 9)', // #ffba09
    },
    divider: {
      default: 'rgb(196, 196, 196)', // #c4c4c4
    },
    input: {
      border: {
        default: 'rgb(210, 210, 210)', // #d2d2d2
      },
    },
    states: {
      error: {
        main: 'rgb(221, 55, 48)', // #DD3730
      },
    },
    text: {
      dark: 'rgb(51, 51, 51)', // #333333
      tertiary: 'rgb(126, 126, 126)', // #7e7e7e
    },
  },
};
