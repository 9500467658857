import { themeColor } from 'theme';
import theme from '../../core';
import muiButtonDropdown from './components/muiButton-dropdown';

const CONSTANTS = {
  DEFAULT: {
    HEIGHT: 40,
    MIN_WIDTH: 150,
  },
  SMALL: {
    HEIGHT: 32,
    MIN_WIDTH: 'auto',
  },
};

export default {
  height: CONSTANTS.DEFAULT.HEIGHT,
  minWidth: CONSTANTS.DEFAULT.MIN_WIDTH,
  boxShadow: 'none',
  fontSize: theme.typography.fontSize,
  textTransform: 'none',
  whiteSpace: 'nowrap',
  '&:active, &:hover': {
    boxShadow: 'none',
  },
  '&.Mui-disabled': {
    backgroundColor: `${themeColor.core.button.grey} !important`,
    borderColor: `${themeColor.core.button.grey} !important`,
    color: `${themeColor.core.color.grey.disabled} !important`,
    pointerEvents: 'auto',
  },
  '&.MuiButton-dropdown': muiButtonDropdown,
  '&.MuiButton-small': {
    height: CONSTANTS.SMALL.HEIGHT,
    minWidth: CONSTANTS.SMALL.MIN_WIDTH,
  },
  '.MuiButton-startIcon': {
    margin: '0 10px 0 0',
  },
};
