import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import { generateKey } from 'shared/utility';
import defaultProps from './default';
import { RowProps as Props } from './definition';
import StyledRow from './style';

export type { RowProps } from './definition';

const Row: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { className, cellCount, data, width, onDelete, onUpdate }: Props = {
    ...defaultProps,
    ...props,
  };
  return (
    <StyledRow
      className={className}
      cellCount={cellCount}
      width={width || defaultProps.width}
      hasIcon={!!onDelete || !!onUpdate}
    >
      {data.map((item: string, i: number) => (
        <span key={generateKey(i, item)}>{item}</span>
      ))}
      {(onDelete || onUpdate) && (
        <span>
          {onUpdate && <Icon name="edit" color="secondary" hasHover onClick={() => onUpdate?.()} />}
          {onDelete && (
            <Icon
              hasHover
              color="error"
              name="minus-circle-outlined"
              size={22}
              onClick={() => onDelete?.()}
            />
          )}
        </span>
      )}
    </StyledRow>
  );
};

export default Row;
