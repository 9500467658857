import {
  fields,
  Section,
} from 'apps/PhoneSystem/containers/Seats/Edit/components/TabSeat/components/';
import { SeatFormProps as Props } from 'apps/PhoneSystem/definition';
import { FormContext } from 'apps/shared/components/FormContext';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import useTabs from 'apps/shared/hooks/useTabs';
import { FunctionComponent, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDeepKeys } from 'shared/utility';

const Form: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const contextName = useContext(FormContext);
  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const hasIcon = (mode: string, tabFields: Array<string> = []) =>
    getDeepKeys(mode === 'dirty' ? dirtyFields : errors).filter((field: string) =>
      tabFields.map((field: string) => `${contextName}${field}`).includes(field),
    ).length > 0;

  const items = [
    {
      hash: 'callCenterOptions',
      label: t(
        'call_center:containers.manage_queues.components.agents_edit.section.call_center_options.label',
      ),
      component: <Section.CallCenterOptions />,
      isDirty: hasIcon('dirty', fields.callCenterOptions),
      isError: hasIcon('error', fields.callCenterOptions),
    },
  ];

  const { Tabs, TabPanels } = useTabs({ items: Object.values(items) });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Form;
