import cloneDeep from 'lodash/cloneDeep';

const SIP_TRUNK_USED_BY_KEY = 'trunkstore';

/** This function transform frontend form data to match the backend API schema */
export const transformSMSForSaving = (_sms: Partial<SMS>) => {
  // LOUIS-TODO: Change to immer.js
  const sms = cloneDeep(_sms);

  if (!sms.numbers) {
    sms.numbers = [sms?.phone_number || ''];
  }

  // Remove temporary field
  delete sms.phone_number;

  return sms;
};

/**
 * @name checkSmsShareability
 * @description Utility to determine whether an SMS is shareable or not
 *
 * @param phoneNumber - The phone number
 * @param phoneNumbersData - phoneNumbers data
 * @returns Returns a boolean indicating whether the SMS is shareable
 */
export const checkSmsShareability = (phoneNumber: string | undefined, phoneNumbersData: any) => {
  const phoneNumberInfo = phoneNumbersData?.numbers?.[phoneNumber ?? ''];

  return phoneNumberInfo?.used_by !== SIP_TRUNK_USED_BY_KEY;
};

export const getPhoneNumberFieldName = (fieldPathPrefix: string) =>
  `${fieldPathPrefix ?? ''}phone_number`;
