import styled, { css } from 'styled-components';

const StyledAccountStatus = styled.div`
  ${(props) => css`
    > span {
      &:last-of-type {
        margin: 0 0 0 8px;
        font-style: italic;
      }
    }
  `}
`;

export default StyledAccountStatus;
