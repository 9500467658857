import { useFetchCallflowsQuery } from 'models/Callflow';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogLink } from 'shared/components/Dialog';
import Loading from 'shared/components/Loading';
import useBridgeNumber from 'shared/hooks/useBridgeNumber';
import NumbersInUseDialog from './components/NumbersInUseDialog';
import { NumbersInUsePickerProps as Props } from './definition';
import { columns, prepareDataForTable } from './utility';

const useNumbersInUsePicker = ({ onApply }: Props) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { data: callflowsData, isLoading: isLoadingCallflows } = useFetchCallflowsQuery();
  const { data: phoneNumbersData, isLoading: isLoadingPhoneNumbers } = useFetchPhoneNumbersQuery();
  const { bridgeNumber, isLoading: isLoadingBridgeNumber } = useBridgeNumber();

  return {
    numbersInUsePicker:
      isLoadingBridgeNumber || isLoadingCallflows || isLoadingPhoneNumbers ? (
        <Loading />
      ) : (
        <>
          <DialogLink
            isBelow
            icon="radiobutton-checked-outlined"
            iconSize={20}
            indentWidth="large"
            text={t('common:component.numbers_in_use_picker.button.label')}
            onClick={() => setIsDialogOpen(true)}
          />
          <NumbersInUseDialog
            columns={columns()}
            data={prepareDataForTable({ bridgeNumber, callflowsData, phoneNumbersData })}
            isOpen={isDialogOpen}
            onApply={onApply}
            onCancel={() => setIsDialogOpen(false)}
            onClose={() => setIsDialogOpen(false)}
          />
        </>
      ),
    setIsNumbersInUsePickerOpen: setIsDialogOpen,
  };
};

export default useNumbersInUsePicker;
