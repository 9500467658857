import { areTabFieldsDirty } from 'apps/PhoneSystem/containers/Voicemails/utility'; // TODO: Review implementation
import { FormContext } from 'apps/shared/components/FormContext';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import { useCallRestrictionsSection } from 'apps/shared/hooks/useCallRestrictionsSection';
import useTabs from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isSeatTypeAdmin } from '../../helper';
import { Props } from '../definition';
import { fields, Section } from './components';

const TabSeat: FunctionComponent<Props> = ({ id }: Props): JSX.Element => {
  const { t } = useTranslation();
  const contextName = 'seat.';
  const {
    formState: { dirtyFields, errors },
    getValues,
  } = useFormContext();

  const {
    component: callRestrictionsSection,
    fields: callRestrictionsFields,
  } = useCallRestrictionsSection({ hasClosedGroups: true, fields: fields.callRestrictions });

  const items = [
    {
      hash: 'basics',
      label: t('phone_system:containers.seats.section.basics.label'),
      component: <Section.Basics />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.basics, contextName),
      isError: areTabFieldsDirty(errors, fields.basics, contextName),
    },
    {
      hash: 'caller_id',
      label: t('phone_system:containers.seats.section.caller_id.label'),
      component: <Section.CallerId />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.callerId, contextName),
      isError: areTabFieldsDirty(errors, fields.callerId, contextName),
    },
    {
      hash: 'advanced',
      label: t('phone_system:containers.seats.section.advanced.label'),
      component: <Section.Advanced />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.advanced, contextName),
      isError: areTabFieldsDirty(errors, fields.advanced, contextName),
    },
    {
      hash: 'call-routing',
      label: t('phone_system:containers.seats.section.call_routing.label'),
      component: <Section.CallRouting />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.callRouting, contextName),
      isError: areTabFieldsDirty(errors, fields.callRouting, contextName),
    },
    {
      hash: 'call_restriction',
      label: t('phone_system:containers.seats.section.call_restriction.label'),
      component: callRestrictionsSection,
      isDirty: areTabFieldsDirty(dirtyFields, callRestrictionsFields, contextName),
      isError: areTabFieldsDirty(errors, callRestrictionsFields, contextName),
    },
    ...(!isSeatTypeAdmin(getValues('seat.seat_type'))
      ? [
          {
            hash: 'call-center-options',
            label: t('phone_system:containers.seats.section.call_center_options.label'),
            component: <Section.CallCenterOptions />,
            isDirty: areTabFieldsDirty(dirtyFields, fields.callCenterOptions, contextName),
            isError: areTabFieldsDirty(errors, fields.callCenterOptions, contextName),
          },
        ]
      : []),
    {
      hash: 'desktop_modules',
      label: t('phone_system:containers.seats.section.desktop_modules.label'),
      component: <Section.DesktopModules id={id} />,
      isDirty: areTabFieldsDirty(dirtyFields, fields.desktopModules, contextName),
      isError: areTabFieldsDirty(errors, fields.desktopModules, contextName),
    },
  ];

  const { Tabs, TabPanels } = useTabs({ items });

  return (
    <FormContext.Provider value="seat.">
      <EditPanel>
        {Tabs}
        {TabPanels}
      </EditPanel>
    </FormContext.Provider>
  );
};

export default TabSeat;
