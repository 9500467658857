import Input from 'shared/components/Input';
import Select, { SelectOption } from 'shared/components/Select';
import { List } from '../../../definition';
import { filterOptions } from '../../../utility';

/**
 * @name getFieldValue
 * @description Get the table cell's field value (either a flat value or a form element)
 *
 * @param {Object} [object]
 * @property {boolean} isEditable
 * @property {boolean} isFiltered
 * @property {number} columnIndex
 * @property {Array<SelectOption>} options
 * @property {any} value
 *
 * @return {string | JSX.Element}
 */
export const getFieldValue = ({
  isEditable = false,
  isFiltered = false,
  columnIndex,
  list,
  options,
  value,
}: {
  isEditable: boolean;
  isFiltered: boolean;
  columnIndex: number;
  list: List;
  options: Array<SelectOption>;
  value: any;
}): JSX.Element | string => {
  let _value = typeof value === 'object' ? value.label : value;

  if (isEditable) {
    _value =
      typeof value === 'object' ? (
        <Select
          options={[value, ...filterOptions({ isFiltered, columnIndex, list, options })]}
          defaultValue={value}
        />
      ) : (
        // TODO: Add Textarea support
        <Input value={value} />
      );
  }

  return _value;
};
