import i18next from 'i18next';

const translations = () => ({
  tabs: {
    user: {
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.components.table_selector.tabs.users',
      ),
    },
    device: {
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.components.table_selector.tabs.devices',
      ),
    },
    group: {
      label: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.components.table_selector.tabs.groups',
      ),
    },
  },
});

export default translations;
