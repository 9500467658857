import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { SEAT_TYPE } from 'constant';
import { useFetchAccountConfigsQuery } from 'models/Account';
import { FunctionComponent, useEffect, useState } from 'react';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import { sortSeatType } from '../utility';
import { SeatTypeSelectProps as Props } from './definition';

const SeatTypeSelect: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isAdmin, isLabelAbove, name, label, tooltip }: Props = props;
  const [seatTypeOptions, setSeatTypeOptions] = useState<Array<SelectOption>>([]);
  const { data, isLoading } = useFetchAccountConfigsQuery();

  useEffect(() => {
    data &&
      setSeatTypeOptions(
        Object.keys(data.seat_types)
          .filter((value: string) =>
            isAdmin ? value === SEAT_TYPE.admin.id : value !== SEAT_TYPE.admin.id,
          )
          .map((value: string) => ({
            value,
            label: data.seat_types[value]?.display_name,
          }))
          .sort(sortSeatType),
      );
  }, [isAdmin, data]);

  if (isLoading) return <Loading />;

  return (
    <HookFormSelectWrapper name={name} options={seatTypeOptions}>
      {({ ref, isDirty, ...formProps }) => (
        <LabeledSelect
          isLabelAbove={isLabelAbove}
          label={label}
          tooltip={tooltip}
          selectProps={{
            ...formProps,
            isDisabled: isAdmin,
          }}
          isDirty={isDirty}
        />
      )}
    </HookFormSelectWrapper>
  );
};

export default SeatTypeSelect;
