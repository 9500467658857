import { CallRestrictionOptionType } from 'definition';
import i18next from 'i18next';

/**
 * @name getClassifierFormName
 * @description Get the name used in the React Hook Form wrapper component.
 *
 * @param [object]
 * @property hasAction
 * @property name
 * @property root
 *
 * @returns React Hook Form wrapper name.
 */
export const getClassifierFormName = ({
  hasAction,
  name,
  root,
}: {
  hasAction?: boolean;
  name: string;
  root: string;
}): string => (name !== '' && root !== '' ? `${root}.${name}${hasAction ? '.action' : ''}` : '');

/**
 * @name options
 * @description Get the options used in the call restriction select fields.
 *
 * @param [object]
 * @property hasInherit
 *
 * @returns Call restriction option objects.
 */
export const options = ({ hasInherit }: { hasInherit?: boolean } = {}) => [
  {
    label: i18next.t('common:component.call_restrictions_section.options.inherit'),
    value: hasInherit ? CallRestrictionOptionType.Inherit : CallRestrictionOptionType.Allow,
  },
  {
    label: i18next.t('common:component.call_restrictions_section.options.deny'),
    value: CallRestrictionOptionType.Deny,
  },
];
