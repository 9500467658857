import { triggerDownload } from 'apps/shared/utility';
import { useFetchPhoneNumberByIdQuery } from 'models/PhoneNumber';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import { maybeTooltipComponent } from 'shared/utility';
import { DownloadEndpointsButtonProps as Props } from './definition';
import StyledDownloadEndpointsButton from './style';
import { getDownloadValues } from './utility';

const DownloadEndpointsButton = ({
  isNumberAssignedToAccount,
  assignedDevices,
  assignedUsers,
}: Props) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const phoneNumber = getValues('id');
  const { data, isLoading } = useFetchPhoneNumberByIdQuery({ id: phoneNumber });
  const isButtonDisabled =
    !isNumberAssignedToAccount && !assignedDevices?.length && !assignedUsers?.length;

  const onClick = async () => {
    triggerDownload(
      getDownloadValues({
        data,
        phoneNumber,
        isNumberAssignedToAccount,
        assignedDevices,
        assignedUsers,
      }),
    );
  };

  if (isLoading) {
    return <Loading hasLabel={false} />;
  }

  return (
    <StyledDownloadEndpointsButton>
      <Box role="row">
        <Box role="cell">
          {maybeTooltipComponent({
            component: (
              <Button
                disabled={isButtonDisabled}
                onClick={onClick}
                startIcon={<Icon name="download" size={18} />}
              >
                {t(
                  'numbers:containers.numbers_in_use.section.e911_endpoints.download.button.label',
                )}
              </Button>
            ),
            tooltip: isButtonDisabled
              ? t(
                  'numbers:containers.numbers_in_use.section.e911_endpoints.download.button.tooltip',
                )
              : '',
          })}
        </Box>
      </Box>
    </StyledDownloadEndpointsButton>
  );
};

export default DownloadEndpointsButton;
