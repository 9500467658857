import Typography from '@mui/material/Typography';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import { LabeledCheckbox } from 'shared/components/Labeled';
import { FormFields } from '../../definition';
import { getIsDisabled } from './utility';

export const LinkPinPassCheckBox = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const openConfirmDialog = () => setIsConfirmDialogOpen(true);
  const onClose = () => {
    setIsConfirmDialogOpen(false);
  };
  const onUnlinkPinPass = () => {
    setValue(FormFields.PinPassLink, false, { shouldDirty: true });
    onClose();
  };
  const handleDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onClose();
        break;
      case 'saveClicked':
        onUnlinkPinPass();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <HookFormInputWrapper isCheckbox name={FormFields.PinPassLink}>
        {({ ref, isDirty, onChange, value, ...formProps }) => {
          const isDisabled = getIsDisabled(value, isDirty);
          return (
            <LabeledCheckbox
              checkboxProps={{
                ...formProps,
                onChange: (e) => {
                  const isChecked = e.target.checked;
                  if (!isChecked && !isDirty) {
                    openConfirmDialog();
                  } else {
                    onChange(isChecked);
                  }
                },
                value,
              }}
              indentWidth="none"
              isDirty={isDirty}
              isDisabled={isDisabled}
              label={t(
                'phone_system:containers.account.submodule.settings.section.sso.pin_pass_link.checkbox_label',
              )}
              {...(isDisabled
                ? {
                    labelTooltip: t(
                      'phone_system:containers.account.submodule.settings.section.sso.pin_pass_link.checkbox_disabled_tooltip',
                    ),
                  }
                : {})}
            />
          );
        }}
      </HookFormInputWrapper>
      <Dialog
        open={isConfirmDialogOpen}
        renderActions={
          <DialogActions
            cancelLabel={t('common:cancel')}
            saveButtonProps={{
              color: 'error',
              label: t(
                'phone_system:containers.account.submodule.settings.section.sso.pin_pass_link.unlink',
              ),
              variant: 'contained',
            }}
            onAction={handleDialogAction}
          />
        }
        title={t(
          'phone_system:containers.account.submodule.settings.section.sso.pin_pass_link.confirm_dialog.heading',
        )}
        type={DialogType.Large}
        onClose={onClose}
      >
        <Typography sx={{ whiteSpace: 'pre-line' }} variant="inherit">
          <Trans i18nKey="phone_system:containers.account.submodule.settings.section.sso.pin_pass_link.confirm_dialog.description" />
        </Typography>
      </Dialog>
    </>
  );
};
