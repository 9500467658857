import { FunctionComponent } from 'react';
import { NoItemsTextProps as Props } from './definition';
import StyledNoItemsText from './style';

export { DashboardItemType } from './definition';

const NoItemsText: FunctionComponent<Props> = ({ type, value }: Props): JSX.Element => (
  <StyledNoItemsText data-test-id={`call-center-dashboard-dashboard-${type}-no-items-text`}>
    {value}
  </StyledNoItemsText>
);

export default NoItemsText;
