import Box from 'shared/components/Box';
import { SelectOption } from 'shared/components/Select';
import Typography from 'shared/components/Typography';
import DeviceModel from './components/DeviceModel';
import { DeviceFamilyProps as Props } from './definition';
import StyledDeviceFamily from './style';

const DeviceFamily = ({ label, model, models, onClick }: Props) => (
  <StyledDeviceFamily>
    <Typography variant="h2">{label}</Typography>
    {models?.length && models.length > 0 && (
      <Box component="ul">
        {models?.map((modelData: SelectOption) => (
          <DeviceModel key={modelData.value} data={modelData} model={model} onClick={onClick} />
        ))}
      </Box>
    )}
  </StyledDeviceFamily>
);

export default DeviceFamily;
