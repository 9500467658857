import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const CONSTANTS = {
  GAP: '24px',
};

export const style = css`
  ${mixin.flex({
    direction: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  })};
  padding: 0 0 ${CONSTANTS.GAP};

  > div {
    &:first-of-type {
      padding: ${CONSTANTS.GAP};
    }

    &:last-of-type {
      display: flex;
      flex-direction: row-reverse;
      padding: 0 ${CONSTANTS.GAP};
    }
  }
`;

const StyledApps = styled.div`
  ${(props) => css`
    ${style};
    ${mixin.flex({ direction: 'column', alignItems: 'stretch', justifyContent: 'space-between' })};
    width: 100vw;
    height: 100vh;
    --padding: 24px;
    padding: 0 0 PADDING;

    > div:first-of-type {
      ${mixin.flex({ isInline: true, direction: 'column', alignItems: 'flex-start' })};

      > a {
        ${mixin.flex({ isInline: true })};
        ${mixin.transition([{ property: 'color' }, { property: 'transform' }])};
        margin: 0 0 18px;
        padding: 12px;
        color: rgba(0, 0, 0, 0.64); // TODO: Replace with standard grey from palette
        text-decoration: none;

        &:hover {
          color: ${props.theme.core.color.black};
          transform: translateX(-12px);

          > span {
            color: ${props.theme.whitelabel.primary.color};
          }
        }

        > span {
          ${mixin.font({ size: 20, weight: 500 })};
          ${mixin.transition({ property: 'color' })};
          margin: 0 0 0 12px;
        }
      }
    }
  `}
`;

export default StyledApps;
