import { ContainerMeta } from 'apps/shared/definition';

const meta: ContainerMeta = {
  // t('phone_system:containers.time_of_day.label')
  label: 'phone_system:containers.time_of_day.label',
  icon: 'calendar',
  slug: 'timeofday',
};

export default meta;
