import { useTranslation } from 'react-i18next';
import DropdownButton from 'shared/components/DropdownButton';
import Icon from 'shared/components/Icon';
import { maybeTooltipActionRowButton } from 'shared/hooks/useActionRow/utility';
import { MoveToDropdownButtonProps as Props } from './definition';

const MoveToDropdownButton = ({
  hasSelectedRows,
  info,
  items,
  label,
  noItemsText,
  tooltip,
}: Props) => {
  const { t } = useTranslation();

  return maybeTooltipActionRowButton({
    hasSelectedRows,
    component: (
      <DropdownButton
        hasSearch
        isMenuRight
        isDisabled={!hasSelectedRows}
        buttonProps={{
          color: hasSelectedRows ? 'secondary' : 'primary',
          startIcon: <Icon name="forward-outlined" size={21} />,
          variant: 'outlined',
        }}
        info={info}
        items={items}
        label={label ?? t('common:move_to')}
        noItemsText={noItemsText}
      />
    ),
    tooltip,
  });
};

export default MoveToDropdownButton;
