import HorizontalNav from 'apps/shared/components/HorizontalNav';
import Page from 'apps/shared/components/Page';
import { Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Error from 'shared/components/Error';
import Loading from 'shared/components/Loading';
import Dashboard, { meta as metaDashboard } from './containers/Dashboard';
import ManageQueues, { meta as metaManageQueues } from './containers/ManageQueues';
import { CallCenterProps as Props } from './definition';
import useHasActionRow from './shared/hooks/useHasActionRow';
import StyledCallCenter from './style';

const CallCenter = ({ label }: Props) => {
  const { hasActionRow, hasHeightStyling } = useHasActionRow();

  const routes = useRoutes([
    { path: '/', element: <Navigate to={`./${metaDashboard.slug}`} replace /> },
    { path: `/${metaDashboard.slug}/`, element: <Dashboard /> },
    { path: `/${metaManageQueues.slug}/*`, element: <ManageQueues /> },
    { path: '*', element: <Error /> },
  ]);

  return (
    <Page label={label}>
      <StyledCallCenter $hasActionRow={hasActionRow} $hasHeightStyling={hasHeightStyling}>
        <HorizontalNav hasIndent items={[metaDashboard, metaManageQueues]} />
        <Suspense fallback={<Loading />}>{routes}</Suspense>
      </StyledCallCenter>
    </Page>
  );
};

export default CallCenter;
