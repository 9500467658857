import CallflowContext from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import { selectTreeRoot } from 'models/Callflow/store/selectors';
import { FunctionComponent, useContext } from 'react';
import { useSelector } from 'react-redux';
import FlowBranch from '../FlowBranch';
import { CallflowTreeProps as Props } from './definition';
import StyledCallflowTree from './style';

const CallflowTree: FunctionComponent<Props> = ({ refNodeCount }: Props): JSX.Element => {
  const {
    isPreview,
    callflow: { id: callflowId },
  } = useContext(CallflowContext);
  const root = useSelector((state: any) => selectTreeRoot(state, callflowId));

  return (
    <StyledCallflowTree isPreview={isPreview}>
      {root && <FlowBranch id={root} refNodeCount={refNodeCount} />}
    </StyledCallflowTree>
  );
};

export default CallflowTree;
