import { EndpointItemType } from 'apps/PhoneSystem/definition';
import { getEndpointIcon } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableType } from 'shared/components/Table';
import defaultProps from './default';
import { TableSelectionProps as Props } from './definition';
import { columns } from './utility';

const TableSelection = (props: Props) => {
  const {
    hasPagination,
    hasPaginationPadding,
    hasUsersExtension,
    data,
    heading,
    id,
    rowCount,
    selectionKey,
    selectedOptions,
    addSelected,
  } = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const [dataFiltered, setDataFiltered] = useState<EndpointItemType[]>([]);

  useEffect(() => {
    if (data && selectedOptions) {
      const selectedIds = selectedOptions
        .filter((option) => option.endpoint_type === selectionKey)
        .map((option) => option.key);
      setDataFiltered(data.filter((item: EndpointItemType) => !selectedIds.includes(item.id)));
    }
  }, [data, selectedOptions, selectionKey, setDataFiltered]);

  return (
    <Table
      hasSearch
      hasEditIcon={false}
      hasPagination={hasPagination}
      hasPaginationPadding={hasPaginationPadding}
      hasSideMargin={false}
      isScrollable={false}
      columns={columns({
        hasUsersExtension,
        heading,
        selectedOptions,
        selectionKey,
        addSelected,
        getEndpointIcon,
      })}
      data={dataFiltered || []}
      id={id}
      initialState={{ pageSize: rowCount }}
      searchPlaceholder={t('common:component.table_selector.search_placeholder')}
      title={heading}
      type={TableType.Selector}
    />
  );
};

export default TableSelection;
