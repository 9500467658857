import { agentMutations } from './Mutations';
import { queueQueries } from './Queries';

export const {
  useFetchAgentByIdQuery,
  useFetchAgentsQuery,
  useFetchAgentsStatsQuery,
  useFetchAgentsStatusQuery,
  useFetchAgentStatusByIdQuery,
} = queueQueries;

export const { useUpdateAgentMutation, useRestartAgentMutation } = agentMutations;
