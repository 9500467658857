import MuiPagination, { PaginationProps as Props } from '@mui/material/Pagination';
import { FunctionComponent } from 'react';

export type { PaginationProps } from '@mui/material/Pagination';

const Button: FunctionComponent<Props> = (props: Props): JSX.Element => (
  <MuiPagination {...props} />
);

export default Button;
