import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledCheckbox from 'shared/components/Labeled/components/LabeledCheckbox';

type Props = {
  section: any;
  name: string;
  initialValues: Array<string>;
};

/**
 * Build Enabled codec checkbox
 *
 * @param section
 * @param name
 * @returns
 */
// eslint-disable-next-line react/no-unstable-nested-components
const CodecEnabledCheckbox = ({ section, name, initialValues }: Props) => {
  const { t } = useTranslation();

  return (
    <HookFormInputWrapper name={`media.${section}.codecs`} key={name} isCheckbox>
      {({ ref, value, onChange, isDirty, ...formProps }) => {
        const hasValue = value && value.includes(name);
        const fieldDirty = value.includes(name) !== initialValues.includes(name);

        const handleClick = (event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
          onChange(
            checked
              ? [...value, event.target.id]
              : value.filter((item: string) => item != event.target.id),
          );

        if (!value) return <></>;

        return (
          <LabeledCheckbox
            label={t(`common:supported.codecs.${section}.${name.toLowerCase()}`)}
            indentWidth="small"
            isDirty={fieldDirty}
            checkboxProps={{
              ...formProps,
              onChange: handleClick,
              id: name,
              checked: hasValue,
              value,
            }}
          />
        );
      }}
    </HookFormInputWrapper>
  );
};

export default CodecEnabledCheckbox;
