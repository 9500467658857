import merge from 'lodash/merge';
import { memo } from 'react';
import PaginationBar from './components/PaginationBar';
import PaginationInfo from './components/PaginationInfo';
import TableRowsPerPage from './components/TableRowsPerPage';
import defaultProps from './default';
import { PaginationProps as Props } from './definition';
import StyledPagination from './style';
import { getPageCount } from './utility';

export type { HasPadding as HasPaginationPadding, HasPagination } from './definition';

const Pagination = memo((props: Props) => {
  const {
    hasPadding,
    isVisible,
    dataSize,
    infoText,
    table: { pageIndex, pageSize, setPageSize },
    onClick,
  } = merge({}, defaultProps, props);

  const pageCount = getPageCount(dataSize, Number(pageSize));

  return isVisible.container ? (
    <StyledPagination
      $hasPadding={hasPadding}
      $isVisible={isVisible}
      data-test-id="shared-table-pagination"
    >
      {isVisible.content && pageCount > 0 && (
        <>
          {isVisible.rowsPerPage && (
            <TableRowsPerPage pageSize={pageSize} setPageSize={setPageSize} />
          )}
          {isVisible.info && (
            <PaginationInfo data={{ dataSize, pageIndex, pageSize }} infoText={infoText} />
          )}
          {isVisible.bar && <PaginationBar data={{ pageCount, pageIndex, onClick }} />}
        </>
      )}
    </StyledPagination>
  ) : (
    <></>
  );
});

export default Pagination;
