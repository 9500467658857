import { ChangeEvent } from 'react';
import Box from 'shared/components/Box';
import Checkbox from 'shared/components/Checkbox';
import Radio from 'shared/components/Radio';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { SelectionPosition, SelectionType } from '../../definition';

/**
 * @name getSelectionCell
 * @description Get the table body row selection cell element.
 *
 * @param [object] options
 * @property isSelected
 * @property index
 * @property selectionType
 * @property onSelectionChange
 *
 * @returns Selection cell.
 */
export const getSelectionCell = ({
  isSelected,
  index,
  selection,
  onChangeSelection,
}: {
  isSelected: boolean;
  index: number;
  selection: {
    position: SelectionPosition;
    type: SelectionType;
  };
  onChangeSelection: (e: ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element => {
  const dataTestId = `${TEST_ID.COMMON.PREFIX.ROW.DATA.SELECTION_CELL}-${selection.position}-${
    selection.type
  }${typeof index !== 'undefined' ? `-${index}` : ''}`.toLowerCase();

  let element;
  switch (selection.type) {
    case SelectionType.Multiple:
      element = (
        <Box>
          <Checkbox
            checked={isSelected}
            data-test-id={`${dataTestId}-checkbox`}
            onChange={onChangeSelection}
          />
        </Box>
      );
      break;
    case SelectionType.Single:
      element = (
        <Radio
          checked={isSelected}
          data-test-id={`${dataTestId}-radio`}
          label=""
          onChange={onChangeSelection}
        />
      );
      break;
    default:
      break;
  }

  return element ? (
    <td className="cell-action" role="cell" data-test-id={dataTestId}>
      {element}
    </td>
  ) : (
    <></>
  );
};
