import { useNavigation } from 'apps/PhoneSystem/shared/useNavigation';
import { ContainerMeta } from 'apps/shared/definition';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Edit from './Edit';
import List from './List';

export const meta: ContainerMeta = {
  // t('accounts_manager:containers.accounts.label')
  label: 'accounts_manager:containers.accounts.label',
  icon: 'accounts',
  slug: 'accounts',
};

const Accounts = () => {
  const EditWithNav = useNavigation(Edit);
  const routes = useRoutes([
    { path: '/', element: <List /> },
    { path: '/:id', element: <EditWithNav /> },
  ]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};

export default Accounts;
