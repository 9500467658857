import BasicsSection from './BasicsSection';
import { field as basicSectionFields } from './BasicsSection/definition';
import { Fields as CallbacksFields } from './CallbacksSection/definition';
import NotificationsSection from './NotificationsSection';
import { field as notificationFields } from './NotificationsSection/definition';

const fields = {
  basic: Object.values(basicSectionFields),
  callbacks: CallbacksFields,
  notifications: Object.values(notificationFields),
};

const Section = {
  Basics: BasicsSection,
  Notifications: NotificationsSection,
};

export { fields, Section };
