import Icon from 'shared/components/Icon';
import theme from '../core';

export default {
  defaultProps: {
    'aria-label': 'breadcrumb',
    separator: <Icon name="chevron-right-outlined" size={16} />,
  },
  styleOverrides: {
    root: {
      '&.MuiBreadcrumbs-actionrow': {
        backgroundColor: theme.palette.common.white,
        ol: {
          flexWrap: 'nowrap',
          margin: theme.spacing(0, 3, 0, 0),
          li: {
            '*': {
              display: 'inline-block',
              color: theme.palette.common.black,
              fontSize: 19,
              fontWeight: 600,
              lineHeight: '1.2em',
              whiteSpace: 'nowrap',
            },
            '&.MuiBreadcrumbs-separator': {
              margin: theme.spacing(0, 1.33),
              transform: 'translateY(1px)',
            },
            'a, div': {
              borderBottom: '2px solid transparent',
              cursor: 'pointer',
              textDecoration: 'none',
              transition: theme.transitions.create(['border-bottom-color'], {
                duration: theme.transitions.duration.shorter,
              }),
              '&:hover': {
                borderBottomColor: theme.palette.common.black,
              },
            },
            'p > span': {
              '&, *': {
                fontSize: 16,
              },
              fontWeight: 500,
              strong: {
                fontWeight: 600,
              },
            },
            '&:last-of-type:not(:first-of-type) .MuiTypography-root': {
              borderBottom: `2px solid ${theme.palette.common.black}`,
            },
          },
        },
        '&.MuiBreadcrumbs-actionrow-above p': {
          fontSize: 16,
          fontWeight: 600,
        },
      },
    },
  },
};
