import { EditPanel } from 'apps/shared/components/StyledEditForm';
import useTabs from 'apps/shared/hooks/useTabs';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkFormEntities } from 'shared/utility';
import { Section } from '../../components/section';
import { sipTrunkingEditProps as Props } from './definition';

const Form = (props: Props) => {
  const { t } = useTranslation();

  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const items = {
    basics: {
      hash: 'basics',
      label: t('accounts_manager:containers.accounts.section.basics.label'),
      component: <Section.Basics />,
      isDirty: checkFormEntities(dirtyFields),
      isError: checkFormEntities(errors),
    },
  };

  const { Tabs, TabPanels } = useTabs({ items: Object.values(items) });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Form;
