import { DRAWER_CONSTANTS } from 'shared/components/Drawer';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { CALLFLOW_CONTAINER_CONSTANTS } from './components/QuickViewCallflow/components/CallflowContainer';
import { StyledQuickViewContainerProps as Props } from './definition';

const StyledQuickViewContainer = styled.div<Props>`
  ${({ theme }) => css`
    ${mixin.flex({
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    })};
    height: calc(
      ${CALLFLOW_CONTAINER_CONSTANTS.HEIGHT.DEFAULT} +
        ${DRAWER_CONSTANTS.SHADOW_OFFSET.VERTICAL.TOTAL}px
    );
    margin: ${theme.spacing(0, 0, 0, 1)};
    overflow: hidden;
  `}
`;

export default StyledQuickViewContainer;
