import { FormContext } from 'apps/shared/components/FormContext';
import { FunctionComponent } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { Feedback } from 'shared/components/Labeled';
import { InputWrapperProps as Props } from './definition';

/**
 * InputWrapper
 * State management wrapper for input components. This component will fetch the form context
 * and pass down the relevant props to the input component. The RHF control can be passed as an
 * optional prop.
 * @param {Object} props
 * @param {ReactElement} props.children - The children of the component
 * @param {string} props.name - The name of the field, used as the key in RHF state
 * @param {boolean} props.isCheckbox - Whether the field is a checkbox
 * @param {boolean} props.isSwitch - Whether the field is a switch
 * @param {Control} props.control - The RHF control
 * @param {Function} props.customIsDirty - Custom function which analyses dirtyFields directly to determine if the field is dirty
 *
 * @returns
 */
const InputWrapper: FunctionComponent<Props> = (props: Props): any => {
  const { children, name, isCheckbox, isSwitch, control, customIsDirty } = props;
  const formContext = useFormContext();

  const { formState: { dirtyFields = {} } = {}, control: controlContext } = formContext || {};

  return (
    <FormContext.Consumer>
      {(formContext) => {
        const contextName = !formContext ? name : `${formContext}${name}`;

        return (
          <Controller
            control={control || controlContext}
            name={contextName}
            render={({
              field,
              fieldState,
            }: {
              field: ControllerRenderProps;
              fieldState: ControllerFieldState;
            }) => {
              let { isDirty, error } = fieldState;

              // TODO: Review necessity of `customIsDirty`
              if (!!customIsDirty) {
                isDirty = customIsDirty(dirtyFields, contextName);
              }

              let extended: {
                defaultChecked?: boolean;
                isDirty?: boolean;
                value?: string;
                checked?: boolean;
                feedback?: Feedback;
              } = {
                isDirty,
                value: (field.value != undefined ? field.value : '') as string,
              };

              // TODO: Don't really need this check, just increases complexity
              if (isCheckbox || isSwitch) {
                extended.checked = field.value || false;
              }

              if (error) {
                extended.feedback = {
                  type: 'error',
                  message: error.message || '',
                };
              }

              return children({ ...field, ...extended });
            }}
          />
        );
      }}
    </FormContext.Consumer>
  );
};

export default InputWrapper;
