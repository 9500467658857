import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import { TableCellPresenceProps as Props } from './definition';

const TableCellPresence = ({
  isError,
  isLoading,
  isSuccess,
  id,
  devicesData,
  deviceStatusesData,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  if (isError) {
    return t('phone_system:containers.seats.table.column.presence.error');
  }

  if (isLoading) {
    return <Loading />;
  }

  let color = 'grey.dark';
  if (isSuccess) {
    const data = devicesData ? devicesData.filter(({ owner_id }: any) => owner_id === id) : [];

    if (data.length > 0 && data.some((device: any) => device.enabled)) {
      color = 'red';
    }

    if (data.some((device: any) => device.enabled && deviceStatusesData[device.id])) {
      color = 'green.default';
    }
  }

  return <Icon name="call-filled" themeColor={`core.color.${color}`} />;
};

export default TableCellPresence;
