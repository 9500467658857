import { CallflowContextProvider } from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import { QUICKVIEW_CONSTANTS } from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowDrawer';
import FlowContainer from 'apps/PhoneSystem/containers/Callflows/Edit/components/FlowContainer';
import { updateZoom } from 'apps/PhoneSystem/shared/utility';
import { ZoomMode } from 'apps/PhoneSystem/shared/utility/definition';
import { ExitConfirmationDialogContext } from 'apps/store/contexts';
import merge from 'lodash/merge';
import { useFetchCallflowByIdQuery } from 'models/Callflow';
import { addCallFlow } from 'models/Callflow/store/slice';
import { FunctionComponent, memo, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Drawer, { drawerProps, DRAWER_CONSTANTS } from 'shared/components/Drawer';
import { DRAWER_HEADER_CONSTANTS } from 'shared/components/Drawer/components/Header';
import Loading from 'shared/components/Loading';
import theme from 'theme/mui/core';
import InteractionBar, {
  InteractionBarElementType,
  InteractionBarType,
} from '../../../../../InteractionBar';
import CallflowContainer, { CALLFLOW_CONTAINER_CONSTANTS } from './components/CallflowContainer';
import defaultProps from './default';
import { QuickViewCallflowProps as Props, Size, Style } from './definition';

const QuickViewCallflow: FunctionComponent<Props> = memo(
  (props: Props): JSX.Element => {
    const {
      hasExpandedChanged,
      isMainDrawerOpen,
      callflowId,
      tab,
      onClose,
      onCollapse,
      onEditClicked,
    } = {
      ...defaultProps,
      ...props,
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { createExitConfirmationDialogHandler } = useContext(ExitConfirmationDialogContext);
    const { data, isLoading } = useFetchCallflowByIdQuery({ id: callflowId });
    const [, setZoom] = useState<number>(1);
    const refZoom = useRef<HTMLDivElement>(null);
    const refCallflow = useRef<HTMLDivElement>(null);

    /**
     * TODO: Review/Refactor the use of `useFetchCallflowByIdQuery` above
     * and the `useEffect` call below. This pattern is also implemented in
     * the `TabCallflow` component.
     *
     * The `dispatch` call below is used specifically in order for the
     * `CallflowTree` component's internal value of `root` to be assigned
     * a valid value (i.e., not `undefined`) and therefore, render properly.
     */
    useEffect(() => {
      if (data) {
        dispatch(addCallFlow(data));
      }
    }, [data, dispatch]);

    // calculate the (absolute left) position of each open quickview window
    const left: number =
      DRAWER_CONSTANTS.MARGIN * 3 + // margin (from main container left edge)
      (isMainDrawerOpen ? drawerProps.width : DRAWER_HEADER_CONSTANTS.HEIGHT) + // width of main window
      (tab.count > 0
        ? DRAWER_HEADER_CONSTANTS.HEIGHT +
          DRAWER_CONSTANTS.MARGIN +
          DRAWER_CONSTANTS.SHADOW_OFFSET.HORIZONTAL
        : 0) + // width of quickview tabs
      (DRAWER_CONSTANTS.MARGIN + DRAWER_CONSTANTS.SHADOW_OFFSET.HORIZONTAL) * (tab.index + 1) + // margin (always)
      CALLFLOW_CONTAINER_CONSTANTS.WIDTH * tab.index; // quickview width (all except first quickview window)

    // calculate the size for drawer element
    const size: Size = {
      drawer: {
        height: tab.isOpen ? CALLFLOW_CONTAINER_CONSTANTS.HEIGHT.DEFAULT : tab.width,
        width: CALLFLOW_CONTAINER_CONSTANTS.WIDTH,
      },
    };

    // assign style attribute (override) values for both drawer main and tab elements
    const style: Style = {
      main: tab.isOpen
        ? merge(
            {
              position: 'absolute',
              top: theme.spacing(3),
              left: `${left}px`,
            },
            tab.index === QUICKVIEW_CONSTANTS.MAXIMUM.EXPANDED - 1
              ? { paddingRight: theme.spacing(3) }
              : {},
          )
        : {},
      tab:
        !tab.isOpen && tab.width
          ? { height: `${tab.width + DRAWER_CONSTANTS.SHADOW_OFFSET.VERTICAL.TOTAL}px` }
          : {},
    };

    const handleInteractionBarAction = {
      [InteractionBarElementType.EDIT]:
        createExitConfirmationDialogHandler?.(() => {
          onEditClicked();
          navigate(`../${callflowId}`);
        }) ?? (() => {}),
      [InteractionBarElementType.ZOOM_IN]: () => {
        setZoom((zoom: number) => updateZoom(ZoomMode.IN, refZoom, zoom));
      },
      [InteractionBarElementType.ZOOM_OUT]: () => {
        setZoom((zoom: number) => updateZoom(ZoomMode.OUT, refZoom, zoom));
      },
    };

    return (
      <Drawer
        hasPadding
        isOpen={tab.isOpen}
        width={size.drawer.width}
        height={size.drawer.height}
        headerProps={{
          isSmall: true,
          isTextLeft: true,
          height: tab?.width,
          text: data?.name,
          onClose,
          onCollapse,
        }}
        style={style}
      >
        <CallflowContainer ref={refCallflow}>
          <>
            {isLoading ? (
              <Loading />
            ) : (
              <CallflowContextProvider isPreview callflow={data}>
                <FlowContainer
                  isForceUpdate={hasExpandedChanged}
                  refCallflow={refCallflow}
                  refZoom={refZoom}
                />
              </CallflowContextProvider>
            )}
            <InteractionBar
              type={InteractionBarType.QUICK_VIEW}
              onAction={{
                [InteractionBarElementType.EDIT]:
                  handleInteractionBarAction[InteractionBarElementType.EDIT],
                [InteractionBarElementType.ZOOM_IN]:
                  handleInteractionBarAction[InteractionBarElementType.ZOOM_IN],
                [InteractionBarElementType.ZOOM_OUT]:
                  handleInteractionBarAction[InteractionBarElementType.ZOOM_OUT],
              }}
            />
          </>
        </CallflowContainer>
      </Drawer>
    );
  },
);

export default QuickViewCallflow;
