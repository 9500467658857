import useLocalStorage from 'apps/shared/hooks/useLocalStorage';
import { getRootAccountId } from 'apps/shared/utility/account/getRootAccountId';
import { LOCAL_STORAGE } from 'constant';
import { useFetchAccountByIdQuery, useFetchAccountQuery } from 'models/Account';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import Loading from 'shared/components/Loading';
import { isContentEmpty } from 'shared/components/RichTextEditor';
import RichTextViewer from 'shared/components/RichTextViewer';

/**
 * This dialog appears when the root account has an announcement
 */
const AnnouncementDialog = () => {
  const { t } = useTranslation();
  const rootAccountId = getRootAccountId();
  const [open, setOpen] = useState<boolean>(false);
  const onClose = () => setOpen(false);

  const [isAnnouncementRead, setIsAnnouncementRead] = useLocalStorage<boolean>(
    LOCAL_STORAGE.ANNOUNCEMENT.IS_READ,
    false,
  );

  const {
    data: currentAccountData,
    isLoading: isLoadingCurrentAccount,
    isSuccess: isSuccessCurrentAccount,
  } = useFetchAccountQuery();

  const shouldSkip = (() => {
    if (isSuccessCurrentAccount) {
      return currentAccountData?.id === rootAccountId;
    }
    return true;
  })();

  const { data: rootAccountData, isLoading: isLoadingRootAccount } = useFetchAccountByIdQuery(
    { id: rootAccountId ?? '' },
    { skip: shouldSkip },
  );

  const announcement = (
    (!isLoadingRootAccount && rootAccountData) ||
    (!isLoadingCurrentAccount && currentAccountData)
  )?.announcement;

  const hasAnnouncement = announcement && !isContentEmpty(announcement);

  useEffect(() => {
    if (!isAnnouncementRead && !isLoadingCurrentAccount && !isLoadingRootAccount) {
      setIsAnnouncementRead(true);
      setOpen(hasAnnouncement);
    }
  }, [
    hasAnnouncement,
    isAnnouncementRead,
    isLoadingCurrentAccount,
    isLoadingRootAccount,
    setIsAnnouncementRead,
  ]);

  if (isLoadingCurrentAccount || isLoadingRootAccount) {
    return <Loading />;
  }

  if (!hasAnnouncement) {
    return <></>;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('common:component.announcement')}
      renderActions={
        <DialogActions
          rightButtons={
            <Button color="primary" variant="contained" onClick={onClose}>
              {t('common:ok')}
            </Button>
          }
        />
      }
    >
      <RichTextViewer text={announcement} />
    </Dialog>
  );
};

export default AnnouncementDialog;
