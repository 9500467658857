import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const Account = {
  // GET
  FetchById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}`,
  FetchAncestorsById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER, isPaginated = false) =>
    `accounts/${id}/tree?paginate=${isPaginated}`,
  FetchBillingById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER, isPaginated = false) =>
    `accounts/${id}/billing?paginate=${isPaginated}`,
  FetchChildrenById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER, isPaginated = false) =>
    `accounts/${id}/children?paginate=${isPaginated}`,
  FetchConfigsById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}/configs/accounts`,
  FetchDescendantsById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER, isPaginated = false) =>
    `accounts/${id}/descendants?paginate=${isPaginated}`,
  FetchDesktopAppZoneById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `accounts/${id}/configs/hero`,
  FetchHeroAppsById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}/hero/apps`,
  FetchSiteLocationUsersById: (
    id: string = ACCOUNT_ID_URL_PLACEHOLDER,
    locationId = 'default',
    isPaginated = false,
  ) => `accounts/${id}/users/list_by_location/${locationId}?paginate=${isPaginated}`,
  FetchCallerInfoMatchById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `accounts/${id}/hero/screen_pop`,
  FetchClusterById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}/configs/cluster`,
  FetchSeatCountById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}/seat_count`,
  FetchExternalApps: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `accounts/${id}/configs/admin_portal`,

  Search: (search: string) =>
    `/search/multi?t=account&by_id=${search}&by_name=${search}&by_realm=${search}`,

  // PUT
  CreateById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}`,

  // POST
  UpdateById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}`,
  UpdateHeroAppsById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}/hero/apps`,
  UpdateDesktopAppZoneById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) =>
    `accounts/${id}/configs/hero`,

  // DELETE
  DeleteById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}`,

  // PATCH
  PatchById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}`,

  // DELETE / PUT
  UpdateResellerFlagById: (id: string = ACCOUNT_ID_URL_PLACEHOLDER) => `accounts/${id}/reseller`,
};
