import TEST_ID from 'shared/utility/testing/constants/testId';
import { SelectionPosition, SelectionType } from './components/definition';
import { TableType } from './style/definition';

const defaultProps = {
  hasActionRow: true,
  hasActionRowMargin: false,
  hasActionRowPadding: {
    bottom: true,
    side: true,
    top: true,
  },
  hasActionRowBorder: true,
  hasAutoResetPage: true,
  hasAutoSort: true,
  hasCellPadding: true,
  hasCheckbox: false,
  hasDragIcon: false,
  hasEditIcon: true,
  hasHeadings: true,
  hasOuterBorder: false,
  hasPagination: {
    bar: true,
    container: true,
    content: true,
    info: true,
    rowsPerPage: false,
  },
  hasPaginationPadding: {
    bottom: true,
    side: true,
    top: true,
  },
  hasSideMargin: true,
  hasSubRowFilter: false,
  isScrollable: false,
  addButton: {
    hasAdd: false,
    onAdd: () => {},
  },
  dataTestId: TEST_ID.COMMON.SHARED.TABLE.CONTAINER.DEFAULT,
  disableSortBy: false,
  manualPagination: false,
  manualSortBy: false,
  pageSize: 10,
  paginateExpandedRows: true,
  selection: {
    hasBorder: false,
    indices: [],
    position: SelectionPosition.Left,
    type: SelectionType.None,
    setRows: () => {},
  },
  type: TableType.Default,
};

export default defaultProps;
