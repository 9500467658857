import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useTranslation } from 'react-i18next';
import { UploadFileType } from 'shared/components/FileUpload/definition';
import LabeledFileUpload from 'shared/components/Labeled/components/LabeledFileUpload';
import { FormFields } from '../../fields';

const X509CertificateInput = () => {
  const { t } = useTranslation();

  return (
    <HookFormInputWrapper name={FormFields.X509Certificate}>
      {({ ref, onChange, value, ...formProps }) => {
        return (
          <LabeledFileUpload
            {...formProps}
            id="upload-sso-x509-certificate"
            label={t(
              'phone_system:containers.account.submodule.settings.section.sso.setup_idp.dialog.x509_certificate',
            )}
            labelProps={{ required: true }}
            inputProps={{
              name: FormFields.X509Certificate,
              value,
              fileType: UploadFileType.FILE,
              onChange: (file) => {
                onChange(file ?? null); // This must be null as undefined will not change the value
              },
            }}
          />
        );
      }}
    </HookFormInputWrapper>
  );
};

export default X509CertificateInput;
