import { ExitConfirmationDialogContext } from 'apps/store/contexts';
import { useContext, useEffect } from 'react';
import { DialogContext } from 'store/contexts';
import { useExitConfirmationDialogParams } from './definition';

export const useExitConfirmationDialog = ({
  isDirty,
  pathWhitelist,
  onExit,
}: useExitConfirmationDialogParams) => {
  const dialogCtx = useContext(DialogContext);
  const exitConfirmationDialogCtx = useContext(ExitConfirmationDialogContext);

  const { blocker, onIsDirtyChange, onPathWhitelistChange, openDialog } = exitConfirmationDialogCtx;

  // Listen to isDirty param change
  useEffect(() => {
    onIsDirtyChange?.(isDirty);
  }, [isDirty, onIsDirtyChange]);

  // Listen to isDirty param change
  useEffect(() => {
    onPathWhitelistChange?.(pathWhitelist ?? []);
  }, [onPathWhitelistChange, pathWhitelist]);

  // Clean up on component unmount. Keep the dependency list empty.
  useEffect(
    () => () => {
      onIsDirtyChange?.(false);
      onPathWhitelistChange?.([]);
    },
    [],
  );

  useEffect(() => {
    if (blocker?.state === 'blocked') {
      openDialog?.();
    }
  }, [dialogCtx, onIsDirtyChange, blocker, openDialog]);

  // Exporting the function for modifying states of the nav blocker
  return {
    onIsDirtyChange,
  };
};
