import { LOCAL_STORAGE, SESSION_STORAGE } from 'constant';
import { IamCredentials, IamTokens, LocalstorageAuthData, RememberMeAuthData } from './definition';
import { authMutations } from './Mutations';
import { authSlice } from './slice';

export { authSlice };

export const { useLoginMutation, useResetMutation, useValidateResetIdMutation } = authMutations;

export const { setCredentials } = authSlice.actions;

const clearKazooAuth = () => {
  localStorage.removeItem(LOCAL_STORAGE.TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID);
  localStorage.removeItem(LOCAL_STORAGE.AUTH.USER_ID);
};

const clearIamAuth = () => {
  localStorage.removeItem(LOCAL_STORAGE.IAM.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.IAM.REFRESH_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.IAM.REGISTRATION_ID);
};

const clearBizProvAuth = () => {
  localStorage.removeItem(LOCAL_STORAGE.BIZPROV.AUTH_TOKEN);
};

export const clearAuth = () => {
  clearKazooAuth();
  clearBizProvAuth();
  clearIamAuth();
};

export const setLocalStorageAuth = ({ account_id, user_id, auth_token }: LocalstorageAuthData) => {
  localStorage.setItem(LOCAL_STORAGE.TOKEN, auth_token);
  localStorage.setItem(LOCAL_STORAGE.AUTH.ACCOUNT_ID, account_id);
  localStorage.setItem(LOCAL_STORAGE.AUTH.USER_ID, user_id);
};

export const setIamCredentials = (iamCredentials: IamCredentials) => {
  const { registration_id, ...iamTokens } = iamCredentials;

  iamTokens && setIamTokens(iamTokens);

  localStorage.setItem(LOCAL_STORAGE.IAM.REGISTRATION_ID, String(registration_id));
};

export const setIamTokens = (iamTokens: IamTokens) => {
  iamTokens.access_token &&
    localStorage.setItem(LOCAL_STORAGE.IAM.ACCESS_TOKEN, iamTokens.access_token);
  iamTokens.refresh_token &&
    localStorage.setItem(LOCAL_STORAGE.IAM.REFRESH_TOKEN, iamTokens.refresh_token);
};

export const getIamCredentials = (): IamCredentials => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE.IAM.ACCESS_TOKEN) as string;
  const refreshToken = localStorage.getItem(LOCAL_STORAGE.IAM.REFRESH_TOKEN) as string;
  const registrationId = Number(localStorage.getItem(LOCAL_STORAGE.IAM.REGISTRATION_ID));

  return {
    access_token: accessToken,
    refresh_token: refreshToken,
    registration_id: registrationId,
  };
};

export const clearE911NotificationData = () => {
  localStorage.removeItem(LOCAL_STORAGE.E911_NOTIFICATION);
};
export const clearAnnouncementData = () => {
  localStorage.removeItem(LOCAL_STORAGE.ANNOUNCEMENT.IS_READ);
};

export const setRememberMeAuthData = ({ username, account_name }: RememberMeAuthData) => {
  localStorage.setItem(LOCAL_STORAGE.AUTH.USERNAME, username);
  localStorage.setItem(LOCAL_STORAGE.AUTH.ACCOUNT_NAME, account_name);
};

export const clearRememberMeAuthData = () => {
  localStorage.removeItem(LOCAL_STORAGE.AUTH.USERNAME);
  localStorage.removeItem(LOCAL_STORAGE.AUTH.ACCOUNT_NAME);
};

/**
 * TODO: The individual `sessionStorage.removeItem()` calls below
 * should be replaced with a single call to `sessionStorage.clear()`;
 * any values that need to persist should be stored via `localStorage`
 * and handled appropriately.
 */

export const clearTableFilterSession = () => {
  sessionStorage.removeItem(SESSION_STORAGE.TABLE.FILTER);
};

export const clearTablePaginationIndexSession = () => {
  sessionStorage.removeItem(SESSION_STORAGE.TABLE.PAGINATION.INDEX);
};

export const clearTablePaginationSession = () => {
  clearTablePaginationIndexSession();
  sessionStorage.removeItem(SESSION_STORAGE.TABLE.PAGINATION.SIZE);
};

const clearCallCenterCallQueuesSession = () => {
  sessionStorage.removeItem(SESSION_STORAGE.CALL_CENTER_CALL_QUEUES);
};

export const logout = () => {
  clearTableFilterSession();
  clearTablePaginationSession();
  clearCallCenterCallQueuesSession();
  clearAuth();
  clearE911NotificationData();
  clearAnnouncementData();
};
