import * as mixin from 'shared/utility/mixin';
import { css } from 'styled-components';
import { StyledTableProps as Props } from '../../definition';
import { CONSTANTS } from '../constants';
import { TableType } from '../definition';
import { justify } from './utility';

export const removable = ({ hasActionIcon, hasFooter, theme, type }: Props) => css`
  ${type === TableType.Removable &&
  css`
    table {
      ${mixin.borderRadius()};
      padding: 0;
      border: ${CONSTANTS.BORDER};

      thead,
      tfoot {
        position: sticky;
        z-index: 10;
      }

      tbody {
        display: block;
        overflow: hidden auto;
        height: calc(
          calc(${CONSTANTS.HEIGHT.CELL.TBODY} * ${CONSTANTS.ROW_COUNT}) - ${CONSTANTS.HEIGHT.BORDER} -
            ${hasFooter ? CONSTANTS.HEIGHT.CELL.TBODY : '0px'}
        );
        margin: 0 0 -${CONSTANTS.HEIGHT.BORDER}; // hide tr:last-of-type td border-bottom

        tr {
          display: flex;
          user-select: none;

          td {
            ${hasActionIcon
              ? css`
                  text-align: center;

                  &:first-of-type:not(:last-of-type) {
                    width: calc(100% - ${CONSTANTS.WIDTH.ICON.ACTION}) !important;
                    padding: 0 ${theme.spacing(2)};
                  }

                  &:last-of-type:not(:first-of-type) {
                    width: ${CONSTANTS.WIDTH.ICON.ACTION} !important;
                    border-left: ${CONSTANTS.HEIGHT.BORDER} solid ${theme.core.border.color};
                  }
                `
              : css`
                  width: 100%;
                  padding: 0 ${theme.spacing(6)};
                  text-align: left;
                `}
          }

          &.is-empty {
            &:hover td {
              background-color: transparent;
            }

            &,
            td {
              display: inline-block;
              height: inherit;
              min-height: inherit;
              text-align: center;
              white-space: normal;
            }

            td {
              ${mixin.flex()};
              border: inherit;
              word-break: normal;
              padding: ${theme.spacing(2)};

              > span {
                font-style: italic;
                line-height: 1.3em;
              }
            }
          }
        }
      }

      tfoot {
        bottom: 0;
        height: ${CONSTANTS.HEIGHT.CELL.TBODY};

        tr td {
          height: auto; // TODO: Fix table component tfoot styling
          line-height: normal; // TODO: Fix table component tfoot styling
          border: none;
          border-top: ${CONSTANTS.BORDER};

          div {
            ${mixin.flex({ justifyContent: 'space-between' })};
            padding: 0 ${theme.spacing(2)};
            user-select: none;

            span {
              ${mixin.font({ style: 'normal', weight: 600 })};
              color: ${theme.core.color.black};
            }
          }
        }
      }

      thead {
        top: 0;

        tr th {
          ${justify({ isFlex: true, isHeader: true })};
          ${mixin.flex()};
          top: 0;
          height: ${CONSTANTS.HEIGHT.CELL.TBODY};
          padding: 0 ${theme.spacing(2)};
          text-transform: none;
        }
      }
    }
  `}
`;
