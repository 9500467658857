import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

const StyledFeatureCodes = styled(Box)`
  ${({ theme }) => css`
    margin: 0 auto;
    padding: 0;

    /* ${mixin.borderRadius({ radius: 'half' })};
    width: 100%;
    margin: 0; // 0 auto;
    padding: ${theme.spacing(3, 0, 0)};
    background-color: ${theme.core.background.grey.light.color};
    border: 1px solid ${theme.core.border.color};
    overflow: auto; */

    div[role='row'] {
      ${mixin.flex({ alignItems: 'flex-start', gap: 36 })};

      p {
        ${mixin.font({ size: 16, weight: 600 })};
        margin: ${theme.spacing(0, 0, 3)};
        color: ${theme.core.color.red};
      }
    }
  `}
`;

export default StyledFeatureCodes;
