import ConfirmSelectionDialog from 'apps/shared/components/ConfirmSelectionDialog';
import { RowArray } from 'apps/shared/definition';
import { columns, extractTrunkId } from 'apps/SipTrunking/utility';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import {
  useFetchAllConnectivityQuery,
  useUpdateConnectivityByIdMutation,
} from 'models/Connectivity';
import { ConnectivityResponse } from 'models/Connectivity/types';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useFetchSMSQuery } from 'models/SMS';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import Table, { Column, Justify, SelectionType } from 'shared/components/Table';
import { useActionRow } from 'shared/hooks/useActionRow';
import { useToast } from 'shared/hooks/useToast';
import AssignNumberDialog from './components/AssignNumberDialog';
import { AssignedNumbersListProps as Props, NumbersMap, TableDataItem } from './definition';
import StyledAssignedNumbersList from './style';
import { buildActionRowButtons, getDialogProps, getDidObjects, hasNumberAnSmsBox } from './utility';

const AssignedNumbersList = ({ id }: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();

  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState<boolean>(false);
  const [isDialogUnassign, setIsDialogUnassign] = useState<boolean>(false);
  const [confirmData, setConfirmData] = useState<RowArray>([]);
  const [moveToIndex, setMoveToIndex] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<RowArray>([]);

  const { data, isLoading } = useFetchAllConnectivityQuery();
  const { data: phoneNumbersData, isLoading: isLoadingPhoneNumbers } = useFetchPhoneNumbersQuery();
  const { data: smsData, isLoading: isLoadingSms } = useFetchSMSQuery();

  const [updateConnectivity] = useUpdateConnectivityByIdMutation();

  const servers = data?.[0]?.servers;
  const server = useMemo(
    () => ({
      index: extractTrunkId(id), // TODO: Rename [extract|get][Server|Trunk]Index
      get dids() {
        return servers?.[server.index]?.DIDs ?? [];
      },
      get name() {
        return servers?.[server.index]?.server_name ?? '';
      },
    }),
    [id, servers],
  );

  const table: Record<string, Array<Column> | Array<TableDataItem>> = {
    columns: useMemo(() => columns().assignedNumbers.list, []),
    data: useMemo(
      () =>
        Object.keys(server.dids)
          .filter((did: string) => phoneNumbersData?.numbers?.[did])
          .map((did: string) => ({
            hasSmsBox: smsData && hasNumberAnSmsBox(smsData, did),
            id: did,
            name: server.name,
          })),
      [phoneNumbersData, server, smsData],
    ),
  };

  const onAssignNumber = useCallback(
    async (numbersToAssign: RowArray) => {
      if (numbersToAssign.length) {
        const newNumbers = numbersToAssign.reduce((acc: NumbersMap, value: any) => {
          acc[value.id] = { force_outbound: false };
          return acc;
        }, {});

        const connectivityData = cloneDeep(data?.[0]);
        connectivityData.servers[server.index].DIDs = { ...server.dids, ...newNumbers };

        const response: any = await updateConnectivity({
          id: connectivityData.id,
          body: connectivityData,
        });

        if (response.data) {
          showToast.success(
            t(
              'sip_trunking:containers.default.components.assigned_numbers_list.dialog.assign.success.0',
              { count: Object.keys(newNumbers).length },
            ),
          );
        }
      }
    },
    [data, server.dids, server.index, showToast, t, updateConnectivity],
  );

  const onUnassignNumber = async () => {
    try {
      if (confirmData.length) {
        const connectivityData = cloneDeep(data?.[0]);
        const newServers = { ...server.dids };

        confirmData.forEach(({ id }) => delete newServers[id]);

        connectivityData.servers[server.index].DIDs = { ...newServers };

        const response: any = await updateConnectivity({
          id: connectivityData.id,
          body: connectivityData,
        });

        if (response.data) {
          showToast.success(
            t(
              'sip_trunking:containers.default.components.assigned_numbers_list.dialog.unassign.success.0',
              { count: confirmData.length },
            ),
          );
        }
        setSelectedRows([]);
      }
    } catch (exception) {
      showToast.error(
        t('sip_trunking:containers.default.components.assigned_numbers_list.dialog.unassign.error'),
      );
    } finally {
      setConfirmData([]);
    }
  };

  const onMove = async () => {
    try {
      const didObjects = getDidObjects({
        didList: confirmData.map((didObj: Record<string, string>) => didObj.id),
        didObject: servers[server.index].DIDs,
      });

      const connectivityData = (cloneDeep(data) as ConnectivityResponse)[0];
      connectivityData.servers[server.index].DIDs = didObjects.from;
      connectivityData.servers[moveToIndex].DIDs = merge(
        {},
        connectivityData.servers[moveToIndex].DIDs,
        didObjects.to,
      );

      const response: any = await updateConnectivity({
        id: connectivityData.id,
        body: connectivityData,
      });

      if (response.error) {
        throw response.error;
      }

      showToast.success(
        t(
          'sip_trunking:containers.default.components.assigned_numbers_list.dialog.move.success.0',
          {
            count: confirmData.length,
            name: servers[moveToIndex].server_name,
          },
        ),
      );

      setSelectedRows([]);
    } catch (exception) {
      showToast.error(
        t('sip_trunking:containers.default.components.assigned_numbers_list.dialog.move.error'),
      );
    } finally {
      setConfirmData([]);
    }
  };

  const { ActionRow, actionRowProps } = useActionRow({
    hasDelete: false,
    hasHelp: false,
    hasSave: false,
    breadcrumbData: [
      {
        text: t('sip_trunking:containers.default.components.assigned_numbers_list.title'),
        url: '..',
      },
      {
        text: server.name,
      },
    ],
  });

  if (isLoading || isLoadingPhoneNumbers || isLoadingSms) {
    return <Loading />;
  }

  return (
    <StyledAssignedNumbersList>
      <ActionRow {...actionRowProps} />
      <Table
        hasSearch
        hasEditIcon={false}
        actionRowButtons={{
          left: buildActionRowButtons({
            selectedRows,
            serverName: server.name,
            servers,
            setConfirmData,
            setIsAssignDialogOpen,
            setIsDialogUnassign,
            setMoveToIndex,
          }),
        }}
        actionRowButtonAlignment={{
          left: Justify.Left,
        }}
        columns={table.columns}
        data={table.data}
        selection={{
          type: SelectionType.Multiple,
          setRows: setSelectedRows,
        }}
        title=""
      />
      <AssignNumberDialog
        isDialogOpen={isAssignDialogOpen}
        onAssignNumber={onAssignNumber}
        setIsDialogOpen={setIsAssignDialogOpen}
      />
      <ConfirmSelectionDialog
        {...getDialogProps({
          isDialogUnassign,
          confirmData,
          serverName: {
            from: server.name,
            to: servers?.[moveToIndex].server_name,
          },
          on: { move: onMove, unassign: onUnassignNumber },
          setConfirmData,
        })}
      />
    </StyledAssignedNumbersList>
  );
};

export default AssignedNumbersList;
