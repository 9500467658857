import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationInfoProps as Props } from './definition';
import StyledPaginationInfo from './style';

const PaginationInfo = memo((props: Props) => {
  const {
    data: { dataSize, pageIndex, pageSize },
    infoText,
  } = props;
  const { t } = useTranslation();

  const page = pageIndex + 1;
  const chunk = {
    start: pageIndex * pageSize + 1,
    finish: page * pageSize,
  };

  return dataSize ? (
    <StyledPaginationInfo>
      {infoText
        ? t(infoText, { count: dataSize })
        : t('common:component.table.pagination.pagination_info.text', {
            lower: dataSize > page ? chunk.start : page,
            higher: chunk.finish < dataSize ? chunk.finish : dataSize,
            total: dataSize,
          })}
    </StyledPaginationInfo>
  ) : (
    <></>
  );
});

export default PaginationInfo;
