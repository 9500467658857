import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';
import { StyledPaginationInfoProps as Props } from './definition';

const StyledPaginationInfo = styled(Box)<Props>`
  ${({ theme }) => css`
    ${mixin.flex()};
    margin: ${theme.spacing(0, 5, 0, 0)};
    color: ${theme.core.color.black};
    font-weight: 600;
    user-select: none;
    white-space: nowrap;
  `}
`;

export default StyledPaginationInfo;
