export const BRIDGE_CALLFLOW_MODULE = 'ooma_cellular';

export const CALLFLOW_ACTION = {
  CONFERENCE_SERVICE: {
    DATA: {
      isNew: true,
      contact_list: {},
      flow: {
        children: {},
        data: {},
        module: 'conference',
      },
      metadata: {},
      name: 'Conference Service',
      get numbers() {
        return [CALLFLOW_ACTION.CONFERENCE_SERVICE.EXTENSION];
      },
      patterns: [],
      ui_is_main_number_cf: true,
    },
    EXTENSION: '000001',
  },
  NO_MATCH: {
    DATA: {
      featurecode: {},
      flow: {
        children: {},
        data: {},
        module: 'offnet',
      },
      get numbers() {
        return [CALLFLOW_ACTION.NO_MATCH.NUMBER];
      },
    },
    NUMBER: 'no_match',
  },
};
