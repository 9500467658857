import i18next from 'i18next';
import Button from 'shared/components/Button';
import DropdownButton from 'shared/components/DropdownButton';
import { maybeTooltipComponent } from 'shared/utility';

/**
 * @name getActionRowButtons
 * @description Get the custom action row buttons.
 *
 * @property onButtonClick
 * @property options
 *
 * @returns Action row custom buttons.
 */
export const getActionRowButtons = ({
  onButtonClick,
  options: { isRebootDisabled },
}: {
  onButtonClick: Record<string, any>;
  options: Record<string, boolean>;
}) => (
  <>
    {maybeTooltipComponent({
      component: (
        <Button disabled={isRebootDisabled} variant="outlined" onClick={onButtonClick.rebootDevice}>
          {i18next.t('advanced_provisioner:containers.devices.edit.action_row.button.reboot.label')}
        </Button>
      ),
      tooltip: isRebootDisabled
        ? i18next.t('advanced_provisioner:containers.devices.edit.action_row.button.reboot.tooltip')
        : '',
    })}
    <DropdownButton
      buttonProps={{
        variant: 'outlined',
      }}
      icon=""
      items={[
        {
          label: i18next.t(
            'advanced_provisioner:containers.devices.edit.action_row.button.files.items.log_file',
          ),
          onClick: () => onButtonClick.downloadLogFile(true),
        },
        {
          label: i18next.t(
            'advanced_provisioner:containers.devices.edit.action_row.button.files.items.configuration_files',
          ),
          onClick: () => onButtonClick.downloadConfigFiles(true),
        },
      ]}
      label={i18next.t(
        'advanced_provisioner:containers.devices.edit.action_row.button.files.label',
      )}
    />
  </>
);
