import theme from '../core';

export default {
  styleOverrides: {
    root: {
      width: 24,
      height: 24,
      '&.MuiSvgIcon-dropdown-button-left': {
        width: 18,
        height: 18,
        margin: `0 ${theme.spacing(2)} 0 0`,
      },
      '&.MuiSvgIcon-dropdown-button-right': {
        width: 18,
        height: 18,
        margin: `0 0 0 ${theme.spacing(2)}`,
      },
      '&.MuiSvgIcon-flipped': {
        '&.MuiSvgIcon-flipped-both': {
          transform: 'scale(-1, -1)',
        },
        '&.MuiSvgIcon-flipped-horizontal': {
          transform: 'scaleX(-1)',
        },
        '&.MuiSvgIcon-flipped-vertical': {
          transform: 'scaleY(-1)',
        },
      },
      '&.MuiSvgIcon-removable-table-header': {
        margin: `0 ${theme.spacing(1)} 0 0`,
      },
    },
  },
};
