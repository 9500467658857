import * as mixin from 'shared/utility/mixin';
import { css } from 'styled-components';
import { CONSTANTS } from './constant';

const hover = (theme: any) => css`
  background-color: ${theme.core.background.grey.medium};

  svg {
    fill: ${theme.whitelabel.primary.color};
  }
`;

export const style = {
  default: css`
    height: calc(${CONSTANTS.HEIGHT} - 1px);
    background-color: inherit;
    user-select: none;
  `,
  item: ({
    isOpen = false,
    display = 'block',
    theme,
  }: {
    isOpen?: boolean;
    display?: string;
    theme: any;
  }) => ({
    default: css`
      ${mixin.transition({ property: 'background-color' })};
      cursor: pointer;

      svg {
        ${mixin.transition({ property: 'fill' })};
        fill: ${theme.core.background.grey.dark};
      }

      &:hover {
        ${hover(theme)};
      }

      ${isOpen &&
      css`
        ${hover(theme)};
      `}
    `,
    toggleable: {
      body: css`
        ${mixin.borderRadius({ radius: 'half 0 half half' })};
        ${mixin.boxShadow({ alpha: 8, blurRadius: 6, offsetY: 6 })};
        display: ${isOpen ? display : 'none'};
        position: absolute;
        top: ${CONSTANTS.HEIGHT};
        right: -1px;
        background-color: ${theme.core.background.grey.medium};
        border: 1px solid ${theme.core.divider.default};
        border-top: none;
        z-index: 1000;
      `,
      head: css`
        ${mixin.flex()};
        ${mixin.transition({ property: 'background-color' })};
        width: 100%;
        height: calc(${CONSTANTS.HEIGHT} - 1px);
        padding: ${theme.spacing(0, 4)};
        cursor: pointer;
        user-select: none;

        svg {
          margin: ${theme.spacing(0, 2, 0, 0)};
        }

        ${isOpen &&
        css`
          background-color: ${theme.core.background.grey.medium};
          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: inherit;
            height: 1px;
            background-color: ${theme.core.background.grey.medium};
          }
        `};
      `,
    },
  }),
};
