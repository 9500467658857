import { DialogProps as MUIDialogProps } from '@mui/material/Dialog';

export type { DialogActionsCloseReasons } from './components/DialogActions/definition';

export enum DialogType {
  Default = 'default',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',

  // custom types
  CallflowActions = 'callflow-actions',
  CallflowPreview = 'callflow-preview',
  DevicePicker = 'device-picker',
  DownloadFile = 'download-file',
  Filter = 'filter',
  GroupsEndpoints = 'groups-endpoints',
  Help = 'help',
}

export interface DialogProps extends MUIDialogProps {
  /** Display the close icon? */
  hasClose?: boolean;
  /** Display the title text? */
  hasTitle?: boolean;
  /** Is the content text selectable? */
  isContentSelectable?: boolean;
  /** data-test-id value */
  dataTestId?: string;
  /** Actions for cancel or save */
  renderActions?: JSX.Element;
  /** Title text */
  title?: string;
  /** Dialog type */
  type?: DialogType;
}

export type DialogState = Record<string, boolean>;
