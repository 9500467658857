import cloneDeep from 'lodash/cloneDeep';
import { useFetchDevicesQuery } from 'models/Device';
import { useFetchUsersQuery } from 'models/User';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useEndpoints = () => {
  const devices = useFetchDevicesQuery();
  const users = useFetchUsersQuery();
  const [devicesData, setDeviceData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const endpoints = useMemo(() => {
    /**
     * If the device `owner_id` property exists, add the
     * owner (user) name to each device's data object.
     */
    const devicesWithOwners = devices.data?.map((device: any) => {
      const NO_OWNER = t('phone_system:containers.groups.hooks.use_endpoints.no_owner');

      const clonedDevice = cloneDeep(device);
      clonedDevice.owner_name = NO_OWNER;

      if (clonedDevice.owner_id) {
        const owner = users.data?.find((user: any) => user.id === clonedDevice.owner_id);

        // if it exists, assign friendly owner (user) name
        clonedDevice.owner_name =
          owner?.first_name || owner?.last_name
            ? `${owner.first_name}${
                owner.last_name && `${owner.first_name ? ' ' : ''}${owner.last_name}`
              }`
            : NO_OWNER;
      }

      return clonedDevice;
    });

    return { devices: { ...devices, data: devicesWithOwners }, users };
  }, [devices, users, t]);

  useEffect(() => {
    if (devicesData) {
      setIsLoading(false);
    }
  }, [devicesData]);

  useEffect(() => {
    setDeviceData(endpoints.devices.data);
  }, [endpoints]);

  return { endpoints, isLoading };
};

export default useEndpoints;
