import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { StyledFileDataProps as Props } from './definition';

const CONSTANTS = {
  WIDTH: {
    MAX: '200px',
  },
};

export const StyledFileData = styled(Box).withConfig({
  shouldForwardProp: (props) => !['hasmargin'].includes(props),
})<Props>`
  ${({ hasmargin, theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: ${CONSTANTS.WIDTH.MAX};
    margin: ${theme.spacing(hasmargin === 'true' ? 3 : 0, 10, hasmargin === 'true' ? 3 : 0, 3)};
    line-height: 20px;
    word-break: break-word;
  `}
`;
