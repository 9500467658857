import merge from 'lodash/merge';
import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled, { css } from 'styled-components';
import { HEADER_CONSTANTS } from '../../../Header';
import defaultProps from './default';
import { StyledEditPanelProps as Props } from './definition';

const EditPanel = styled.div<Props>`
  ${(props) => {
    const { hasPadding, isFeatureCodesPanel, isSinglePanel, theme } = merge(
      {},
      defaultProps,
      props,
    );

    return css`
      display: flex;
      height: 100%;
      padding: ${theme.spacing(
        hasPadding?.top ? 5 : 0,
        hasPadding?.side ? 5 : 0,
        hasPadding?.bottom ? 5 : 0,
      )};
      overflow: hidden;

      ${(isFeatureCodesPanel || isSinglePanel) &&
      css`
        overflow: auto;
      `};

      ${isFeatureCodesPanel &&
      css`
        justify-content: flex-start;
        height: ${`calc(100vh - (${HEADER_CONSTANTS.HEIGHT} + ${ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT}))`};
      `};

      ${isSinglePanel &&
      css`
        justify-content: center;
      `};
    `;
  }}
`;

export default EditPanel;
